import {
    useVaptConfigUpdateMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    MESSAGE as CONFIG_MESSAGE,
    TEXT as CONFIG_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/configuration/device-config'
import {
    DeviceConfigModal,
    VAPTConfigUpdateKeys
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer,
    FormStyledComponents as Form
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    setRefetch
} from '@slices/watchdog/configuration/device-configuration/vapt/details'
import {
    useFormik
} from 'formik'

const UpdateConfirmationWindow = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [
        configUpdate, configUpdateMutation
    ] = useVaptConfigUpdateMutation()

    /** call formiks */
    const updateFormik = useFormik({
        initialValues: INITIAL_VALUES.VAPT.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.VAPT.UPDATE,
        onSubmit: () => {
        }
    })

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData.vapt?.updateConfirm) {
            configUpdate({
                authToken: newToken,
                id: modal.formData.vapt.updateConfirm?.id,
                assetName: updateFormik.values.assetName,
                deviceId: updateFormik.values.deviceId
            })
        }
    }, [
        modal.formData.vapt?.updateConfirm,
        updateFormik.values
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (configUpdateMutation.data) {
            const data = configUpdateMutation.data
            if (data.status) {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // close the modal AND reload the data.
                dispatch(closeModal(modal))
                dispatch(setRefetch(true))
            } else {
                toast.error(
                    CONFIG_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [configUpdateMutation.data])

    useEffect(() => {
        if (configUpdateMutation.error) {
            console.error(configUpdateMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [configUpdateMutation.error])

    const SubmitButton = useMemo(() => {
        const buttonContent = configUpdateMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        CONFIG_TEXT.VAPT_UPDATE_ASSET.FORM.LOADING_BUTTON
                    }</span>
                </SpinnerContainer>
            )
            : CONFIG_TEXT.VAPT_UPDATE_ASSET.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'danger'}
                onClick={submitData}
                disabled={configUpdateMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [configUpdateMutation.isLoading])

    useEffect(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const activeField: VAPTConfigUpdateKeys = 'assetName'
        const deviceIdField: VAPTConfigUpdateKeys = 'deviceId'

        if (modal.formData.vapt?.updateConfirm) {
            updateFormik.setFieldValue(activeField, modal.formData.vapt?.updateConfirm?.assetName)
            updateFormik.setFieldValue(deviceIdField, modal.formData.vapt?.updateConfirm?.deviceId)
        }
    }, [modal.formData.vapt?.updateConfirm])

    const AssetNameInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-12 col-md-3 ps-0'}
                    htmlFor={CONFIG_TEXT.VAPT_UPDATE_ASSET.FORM.ASSET.ID}>
                    {CONFIG_TEXT.VAPT_UPDATE_ASSET.FORM.ASSET.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.assetName)}
                    name={'assetName'}
                    id={CONFIG_TEXT.VAPT_UPDATE_ASSET.FORM.ASSET.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.assetName}
                />
                <Form.Feedback
                    className={'col-md-9 offset-md-3'}
                    errors={Boolean(updateFormik.errors.assetName)} >
                    {
                        updateFormik.errors.assetName ? updateFormik.errors.assetName : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.assetName, updateFormik.errors.assetName])

    return (<div>
        <h5>{CONFIG_TEXT.VAPT_UPDATE_ASSET.TITLE}</h5>
        <small className={'d-block my-4'}>
            {CONFIG_TEXT.VAPT_UPDATE_ASSET.CONFIRMATION}
        </small>
        <Form.Main onSubmit={updateFormik.handleSubmit} className={'my-0 px-0'}>
            {AssetNameInput}
            <div className={'row justify-content-end'}>
                <div className={'col-auto mb-2 mb-md-0 text-center'}>
                    {SubmitButton}
                </div>
                <div className={'col-auto'}>
                    <Button mode={'secondary'} onClick={() => {
                        dispatch(closeModal(modal))
                    }}>{TEXT.MODAL.CLOSE}</Button>
                </div>
            </div>
        </Form.Main>
    </div>)
}

export default UpdateConfirmationWindow

import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    ScanResultColumn,
    SimilarHostColumn,
    ScanResultKeys
} from '@interfaces/dashboard/soc/ext-vss'
import { LabelValuePair } from '@interfaces/main/root'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

/** vss will also use this. */

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 30 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    // FAULTY_CALL: 'EXT-VSS call returned faulty.',
    FAULTY_CALL: 'VSS call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MODAL: 'Fetching modal data...',
        PORT_DETAILS: 'Fetching port details data...',
        ISSUE_DETAILS: 'Fetching issue details data...',
        SIMILAR_HOSTS: 'Fetching similar hosts data...',
        ISSUES_OVERTIME: 'Fetching issues over time data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        GROUP_ISSUES: {
            LABEL: 'Group Issues',
            ID: 'PRINT_OPTIONS__GROUP_ISSUES_ONLY'
        },
        ISSUE_DETAILS: {
            LABEL: 'Issue Details',
            ID: 'PRINT_OPTIONS__ISSUE_DETAILS_ONLY'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        SEVERITY_INDEX: 'Severity Index',
        MANAGEMENT_SUMMARY: 'Management Summary',
        VULNERABILITY_OVERVIEW: 'Vulnerability Overview',
        ASSESSED_PORTS: 'Assessed Ports',
        ASSESSED_HOSTS: 'Assessed Hosts',
        ISSUES_PER_CATEGORY: 'Issues Per Category',
        ISSUES_PER_IP: 'Issues Per IP',
        DISCOVERED_ISSUES: 'Discovered Issues',
        VULNERABILITY_DESCRIPTION: 'Vulnerability Description',
        DESCRIPTION: 'Description',
        ISSUES_OVERTIME: 'Issues Over Time',
        SIMILAR_HOSTS: 'Other Hosts with Same Issue'
    }
}

/** for ports table but as a table cell in a tbody instead of a thead.
 * as for the hosts table, the asset is the title and the value pair
 * is an array of ports.
 */
export const PORT_COLUMNS: Omit<LabelValuePair, 'value'>[] = [
    { label: 'UDP' },
    { label: 'TCP' }
]

export const SCAN_RESULT_COLUMNS: ScanResultColumn[] = (() => {
    const columnNames: Omit<ScanResultColumn, 'arrange' | 'include'>[] = [
        {
            label: 'IP',
            value: 'IP'
        },
        {
            label: 'Port',
            value: 'Port'
        },
        {
            label: 'Threat',
            value: 'Threat'
        },
        {
            label: 'CVSS',
            value: 'CVSS'
        },
        {
            label: 'Name',
            value: 'Name'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: ScanResultColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

/** to display a table of similar hosts but no code snippet for sorting
 * or searching. To decrease render times for generating reports AND because
 * the data already exists in the scan/clean results property, user will no longer
 * require to initiate the similarHosts api call.
 */

// export const SIMILAR_HOSTS_COLUMNS: SimilarHostColumn[] = [
//     {
//         label: 'IP',
//         value: 'ip'
//     },
//     {
//         label: 'Port',
//         value: 'port'
//     },
//     {
//         label: 'Proto',
//         value: 'proto'
//     },
//     {
//         label: 'Rate',
//         value: 'rate'
//     },
//     {
//         label: 'Severity',
//         value: 'severity'
//     }
// ]

export const SIMILAR_HOSTS_COLUMNS: SimilarHostColumn[] = [
    {
        label: 'IP',
        value: 'IP'
    },
    {
        label: 'Port',
        value: 'Port'
    },
    {
        label: 'Protocol',
        value: 'Protocol'
    },
    {
        label: 'Severity',
        value: 'CVSS'
    },
    {
        label: 'CVSS',
        value: 'CVSS'
    }
]

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */
export const SCAN_RESULT_KEYS: ScanResultKeys[] = [
    'CVSS', 'Description', 'Family', 'IP', 'Name',
    'Port', 'Protocol', 'Service', 'Threat'
]

import {
    getBGColor,
    getColorPreset
} from '@constants/main/method'
import {
    SIDEBAR_COLLAPSED,
    SIDEBAR_EXPANDED
} from '@constants/main/root'
import {
    PrintBorderProps,
    ButtonProps,
    ContainerProps,
    DashboardCardProps,
    DashboardContainerProps,
    FormValidationProps,
    HoverFilterProps,
    MenuItemRowProps,
    ProfileThemeSelectionProps,
    SidebarMenuContainerProps,
    TableProps,
    TextProps,
    SectionCardProps,
    TableColorCellProps,
    ReportStepTabProps
} from '@styles/styled'
import Color from 'color'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

/**
 * it is encourage to use currently styled properties from react-bootstrap,
 * react-responsive-modal, react-toastify unless it is a color, font change.
 * you can only assign class names from bootstrap-grid in html components.
 * replace inline styling with styled-components. one more thing, if div elements
 * requires more than just class properties from bootstrap.css, you can
 * create a component for it. also because we have so many modules,
 * it will also be a design decision to group the components this way.
 * though it can be lengthy at times, it gives developer flexibility in styling
 * components that have very similar css properties.
 */

/**
 * refer to https://www.w3schools.com/css/css_rwd_viewport.asp on why
 * fixed widths OR applying a minimum widths to scroll horizontally are vices.
*/

/***/

/** required for th elements when using the Table styled-component */
const HEADER_ROW_HEIGHT = 55

/** update where you can select a background color by level. */
export const Container = styled.div<ContainerProps>`
    /* padding: 2em 3.5em; */
    border-radius: 0.5em;
    /** add a transparent option IF bgIndex === -1 */
    background: ${
        (props) => {
            return props.bgIndex !== undefined
                ? props.theme.mode.backgroundColors[props.bgIndex]
                : props.bgIndex === -1
                    ? 'transparent'
                    : props.theme.mode.backgroundColors[1]
        }
    };
`

export const VerticalBarContainer = styled(Container)`
    & > .row {
        & > div {
            width: 11em;
        }
    }
`

/** usually for paragraphs */
export const Text = styled.span<TextProps>`

    font-size: ${
        (props) => {
            let size
            switch (props.size) {
                case 'xxs': size = '0.5em'; break
                case 'xs': size = '0.75em'; break
                case 'sm': size = '0.85em'; break
                case 'md': size = '0.95em'; break
                case 'lg': size = '1.25em'; break
                case 'xl': size = '1.5em'; break
                default: size = '1em'; break
            }
            return size
        }
    };

    color: ${
        (props) => {
            let color
            switch (props.mode) {
                case 'primary': color = props.theme.style.buttonTypeColors.primary; break
                case 'secondary': color = props.theme.style.buttonTypeColors.secondary; break
                case 'success': color = props.theme.style.buttonTypeColors.success; break
                case 'warning': color = props.theme.style.buttonTypeColors.warning; break
                case 'danger': color = props.theme.style.buttonTypeColors.danger; break
                case 'info': color = props.theme.style.buttonTypeColors.info; break
                default:break
            }
            return color
        }
    }
`

export const GaugePercentage = styled(Text)`
    position: relative;
    bottom: 1em;
`

export const SpinnerContainer = styled.div`
    * {
        vertical-align: middle;
    }
`

export const Button = styled.button<ButtonProps>`
    background-color: ${
        (props) => {
            let color
            switch (props.mode) {
                case 'primary': color = props.theme.style.buttonTypeColors.primary; break
                case 'secondary': color = props.theme.style.buttonTypeColors.secondary; break
                case 'success': color = props.theme.style.buttonTypeColors.success; break
                case 'warning': color = props.theme.style.buttonTypeColors.warning; break
                case 'danger': color = props.theme.style.buttonTypeColors.danger; break
                case 'info': color = props.theme.style.buttonTypeColors.info; break
                default:break
            }
            return color
        }
    };
    font-size: ${
        (props) => {
            let size
            switch (props.size) {
                case 'sm': size = '0.8em'; break
                default: size = '1em'; break
            }
            return size
        }
    };
    padding-block: ${
        (props) => {
            let size
            switch (props.size) {
                case 'sm': size = '0.35em'; break
                default: size = '0.6em'; break
            }
            return size
        }
    };
    padding-inline: ${
        (props) => {
            let size
            switch (props.size) {
                case 'sm': size = '0.6em'; break
                default: size = '0.9em'; break
            }
            return size
        }
    };
`

export const LinksRow = styled.div`
    font-size: 0.7em;
    margin-top: 1em;
    /** label is white. the links are primary */

    ul {
        list-style-type: none;
        margin-bottom: 0;
    }

    li {
        float:left;
    }

    li:not(:nth-child(1)) {
        color: ${ (props) => props.theme.style.buttonTypeColors.primary } ;
        cursor: pointer; 

        &.selected {
            color: ${ (props) => props.theme.mode.backgroundColors[9] } ;
            cursor: text;
        }
    }

    li:not(:last-child):after {
        content:"-";
        margin-inline: 0.5em;
    }
`

export const DetailedDashboardLinks = styled.div`
    font-size: 0.8em;
    /** label is white. the links are primary */

    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }

    li {
        float:left;
        cursor: pointer; 
        margin-inline: 0.5em;

        &:hover:not(.selected) {
            color: ${ (props) => props.theme.style.buttonTypeColors.primary } ;
        }

        &.selected {
            cursor: text;
            border-bottom: ${
                props => {
                    return ['0.2em solid', props.theme.style.buttonTypeColors.primary].join(' ')
                }
            };
        }
    }
`
/**
 * a search input with a search icon on the left.
 * currently used in multiple modules.
 *  */
export const SearchRow = styled.div`
    border-bottom: ${ props => ['0.1em solid', props.theme.mode.backgroundColors[9]].join(' ') };

    & input {
        border: 0em;
        padding-block: 0.2em;
        font-size: 0.8em;

        &:focus {
            box-shadow: 0 0 0 0 black;
        }
    }

    //if the input box is a react-datepicker
    & .react-datepicker-wrapper {
        > .react-datepicker__input-container {
            > input {
                width: 14em;
                font-size: 0.8em;
            }
        }
    }
`

/**
 *on smaller screen sizes, apply a fixed width and apply an overflow-x scroll
 *to cater to numerous columns
*/
export const Table = styled.div<TableProps & ContainerProps>`
    background: ${
        (props) => {
            return props.bgIndex !== undefined
                ? props.theme.mode.backgroundColors[props.bgIndex]
                : props.theme.mode.backgroundColors[1]
        }
    };
    /* padding-block: 1em; */
    padding-inline: 1em;

    max-width: ${ (props) => { return props.inModal && '80vw' } };

    max-height: ${ (props) => [props.height ? props.height + 'vh' : ''].join('') };
    overflow-x: overlay;
    -webkit-overflow-scrolling: touch;

    @media print {
        /** only the table container and the th cells have background colors assigned. */
        background: none;
        overflow: unset;
        max-height: none; 

        th {
            /** added !important because it won't be removed when printing. */
            background: none !important;
        }
    }
    
    table {
        color: inherit;
        
        /** Update November 3: Developer didn't see much significance when commented out.
        comment above solved most concerns below */

        /** Update November 2: When a minimum width is applied to a table while at modals, 
        the modal itself will not have its width adjusted by the window width. The solution is
        to apply the parent div a max-width AND overflow-auto. The parent div in this context
        is Table. Use calc to get the window width and reduce by this much.
         */

        /* warning: it should be discouraged to apply a width in a div col element
        if it is greater than the parent width as it will wrap. this was an issue
        back in the ohs-dashboard system. the safest solution is to apply it when 
        it is out of grid. In this example, the sidebar column sets to be in position
        absolute under the mediaQuery sm. */

        /* while applying a width fixes the general problem, it can only do so much 
        when there are so many columns. Solution is to pass in a number to serve as a
        multiplier for the base width in the root constants file
        */

        /** for printing reports / debugging in puppeteer, style the ff 
        properties on print.
         */

         > thead {
             > tr {
                > th {
                    padding-block: 0.7em;
                    white-space: nowrap;
                    background: ${
                        (props) => {
                            return props.bgIndex !== undefined
                                ? props.theme.mode.backgroundColors[props.bgIndex]
                                : props.theme.mode.backgroundColors[1]
                        }
                    };
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    > input, small, span {
                        vertical-align: middle;
                    }

                    //old version has this and td have a cursor pointer.
                    cursor: pointer;
                }
            }
        }

        > tbody {
             > tr {
                > td {
                    white-space: nowrap;
                    @media print {
                        white-space: pre-wrap;
                    }

                    padding-block: 0.3rem;
                    cursor: pointer;

                    //properties added to table cell for OWASP risk classification table
                    &.owaspRisk {
                        &.none-text {
                            color: ${ (props) => props.theme.style.colorPresets.darkGrey }
                        }

                        &.info-text  {
                            color: ${ (props) => props.theme.style.colorPresets.lightGrey }
                        }
                        &.low-text  {
                            color: ${ (props) => props.theme.style.colorPresets.yellow }
                        }
                        &.medium-text  {
                            color: ${ (props) => props.theme.style.colorPresets['amber-1'] }
                        }
                        &.high-text  {
                            color: ${ (props) => props.theme.style.colorPresets.red }
                        }
                    }

                    /** checkbox by default will be a little larger. */
                    > input[type=checkbox] {
                        transform: scale(1.5);
                    }

                }
            }

            > tr.group-parent {}

            > tr.group-child {
                > td {
                    background: ${
                        (props) => {
                            const bgIndex = props.bgIndex
                            return bgIndex !== undefined
                                ? props.theme.mode.backgroundColors[bgIndex + 1]
                                : props.theme.mode.backgroundColors[2]
                        }
                    };
                }
            }

            tr.message {
                > td:nth-child(1) {
                    padding-bottom: 1.5em;

                    > * {
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    /** for table flipped properties */

    &.table-flipped {
        > table {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
        }
    }
`

export const FilterCell = styled.div`
    background: ${ (props) => props.theme.mode.backgroundColors[1] };
    border: ${ props => ['0.1em solid', props.theme.mode.backgroundColors[9]].join(' ') };
    border-radius: 0.3em;

    &.not {
        border-color: ${ props => props.theme.style.buttonTypeColors.danger };
    }

    & svg {
        cursor: pointer;
        opacity: 0
    }

    &:hover svg {
        opacity: 1
    }
`

/** in cells, you can include OR exclude that filter. */
export const HoverFilter = styled.div<HoverFilterProps>`
    display:flex;
    align-items: center;
    position: relative;
    
    > div:nth-child(1) {
        background: ${
        (props) => props.bgIndex !== undefined
            ? props.theme.mode.backgroundColors[props.bgIndex]
            : props.theme.mode.backgroundColors[1]
        };
        font-size: 1.1em;
        opacity:0;
        position:absolute;
        z-index:1;

        &:hover {
            opacity:1;
        }

        & svg {
            cursor: pointer;
            font-size:1em;
        }
    }

`

/**
 * styling component for form interfaces. if one wants something else,
 * they can create their own through another export const based on module name
 */
export const FormStyledComponents = {
    Main: styled.form`
        margin: 1.2em 0em;
        /** has background color by default. override if you wish. */
        background: ${ (props) => props.theme.mode.backgroundColors[1] };
        padding-block: 0.1em;
        padding-inline: 1em;
    `,
    Group: styled.div`
        margin: 0.8em 0.2em;
        &:not(:first-child) {
            /* margin-top: 1.5em; */
        }
    `,

    Label: styled.label`
        font-size: 0.8em;
        display: block;
        margin-bottom: 0.3em;
    `,
    // change properties if prop.errors is true.
    Input: styled.input<FormValidationProps>`
        border-color: ${ (props) => props.errors && props.theme.style.errorColor };
        height: 2.8em;
        font-size: 0.8em;
    `,
    TableInput: styled.input<FormValidationProps>`
        border-color: ${ (props) => props.errors && props.theme.style.errorColor };
        font-size: 1em;
        padding-block: 0.3em;
        padding-inline: 0.5em;
    `,
    TextArea: styled.textarea<FormValidationProps>`
        border-color: ${ (props) => props.errors && props.theme.style.errorColor };
    `,
    DatePicker: styled(DatePicker)<FormValidationProps>`
        border-color: ${ (props) => props.errors && props.theme.style.errorColor };
        height: 2.8em;
        font-size: 0.8em;
    `,
    // change properties if prop.errors is true.
    Feedback: styled.span<FormValidationProps>`
        color: ${ (props) => props.errors && props.theme.style.errorColor };
        display: block;
        font-size: 0.8em;
        padding-top: 0.3em;
    `,
    Button: styled(Button)`
        padding-block: 0.7em;
        padding-inline: 2em;
    `,
    TextCheckbox: styled.div<FormValidationProps>`
        border: ${ props => ['0.1em solid', props.theme.mode.backgroundColors[9]].join(' ') };
        border-color: ${ (props) => props.errors && props.theme.style.errorColor };
        height: 2.8em;
        font-size: 0.8em;
        white-space: nowrap;
        border-radius: 0.3em;

        > input {
            width: 90%;
            border-width: 0px;
            vertical-align: middle;
        }

        > input[type=checkbox] {
            width: 10%;
        }
    `,
    Radio: styled.input`
        vertical-align: middle;
    `,
    Checkbox: styled.input`
        vertical-align: middle;
    `
}

/** styling component for login page */
export const LoginStyledComponents = {
    Logo: styled.img`
        height: 4em;
    `,
    Container: styled(Container)`
        padding-block: 2em;
        padding-inline: 3em;
        width: 80%;
        max-width: 30em;
        /* width: 45em; */
    `,
    Input: styled(FormStyledComponents.Input)`
        height: 3.3em;
    `,
    Button: styled(FormStyledComponents.Button)`

        @media only screen and (min-width: ${ props => props.theme.style.mediaQueries.xs }) {
            /* padding-block: 1.1em;
            padding-inline: 4em; */
            padding-block: 0.8em;
            padding-inline: 3em;
        }

    `
}

export const SidebarStyledComponents = {
    MenuColumn: styled.div<SidebarMenuContainerProps>`
        background: ${ props => props.theme.mode.backgroundColors[1] };
        /* width: ${ props => props.toggle ? '300px' : '50px' }; */
        width: ${
            props => props.toggle
                ? [SIDEBAR_EXPANDED, 'px'].join('')
                : [SIDEBAR_COLLAPSED, 'px'].join('')
        };
        height: 100vh;

        @media only screen and (min-width: ${ props => props.theme.style.mediaQueries.md }) {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            //could be higher depending on the other components
            z-index: 3; /* Ensure the sidebar appears on top */
        }
        
        .menu-item-container {
            overflow-y: scroll;
            max-height: 80vh;
        }

        /** sidebar is hidden in smaller sizes with added props */
        @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.md }) {
            z-index: 2;
            position: fixed;
            display: ${ props => props.toggle ? 'block' : 'none' };
        }
    `,

    MenuItemRow: styled.div<MenuItemRowProps>`  

        cursor: pointer;

        background: ${
            (props) => {
                return props.selected && (
                    [props.theme.style.buttonTypeColors.primary, '20'].join('')
                )
            }
        };

        border-right: ${
            (props) => {
                return props.selected && (
                    ['0.8em solid', props.theme.style.buttonTypeColors.primary].join(' ')
                )
            }
        };

        div:first-child svg { 

            //as of Sept 2, icons will be given a primary color
            color: ${
                (props) => {
                    let color
                    switch (props.type) {
                        case 'title': color = props.theme.style.buttonTypeColors.primary
                    }

                    if (props.selected) {
                        color = props.theme.style.buttonTypeColors.primary
                    }

                    return color
                }
            } ;

            font-size: ${
                (props) => {
                    switch (props.type) {
                        case 'title': return '1.6em'
                        case 'subtitle': return '1em'
                        default: return '1em'
                    }
                }
            };
            margin-block: ${
                (props) => {
                    switch (props.type) {
                        case 'title': return '0.6em'
                        case 'subtitle': return '0.5em'
                        default: return '1em'
                    }
                }
            };
            margin-inline: ${
                (props) => {
                    switch (props.type) {
                        case 'title': return '0em'
                        case 'subtitle': return '0.28em'
                        default: return '1em'
                    }
                }
            };
        }

        div:nth-child(2) span {
            font-size: ${ (props) => props.type === 'title' ? '1.4em' : '0.9em' };
        }

        //dropdown item color is primary
        div:nth-child(3) svg {
            font-size: 1.4em;
            color: ${ (props) => props.theme.style.buttonTypeColors.primary } ;
        }

        //when hovering the item row, change to primary color
        &:hover {
            div:first-child svg { 
                color: ${ (props) => props.theme.style.buttonTypeColors.primary } ;
            }
        }
    `,
    Footer: styled.small`
        font-size: 0.5em;
    `,
    FooterLogo: styled.img`
        height: 2.65em;
    `
}

export const HeaderStyledComponents = {
    /**
     * in order for the home row component to scroll properly
     * you need to assign the header row one a fixed height of 55px
     * then Home row with a calculation of 100% height of the parent
     * component minus this row
     * */
    Container: styled.div`

        height: ${ HEADER_ROW_HEIGHT }px;
        
        padding-block: 0.95em;
        background: ${
            (props) => {
                return props.theme.mode.backgroundColors[1]
            }
        };

        & > div > div:nth-child(1) svg {
            cursor: pointer;
            font-size: 1.6em;
            display: none;
            color: ${ (props) => props.theme.style.buttonTypeColors.primary } ;

            @media only screen and (
                max-width: ${ props => props.theme.style.mediaQueries.md }
                ) {
                display: inline
            }
        }

        & > div >  div:nth-child(2) svg {
            cursor: pointer;
            font-size: 1.6em;
        }
    `
}

export const DashboardFooter = {
    Main: styled.div`
        border-width: 0em;
        border-style: solid;
        border-top-width: 0.1em;
    `,
    DetailedDashboardButton: styled.div`
        border-radius: 0.5em;
        border-width: 0.1em;
        border-style: solid;
        margin-block-start: 1em;
    `
}

export const ContentColumn = styled.div<SidebarMenuContainerProps>`

    @media only screen and (min-width: ${ props => props.theme.style.mediaQueries.md }) {
        margin-inline-start: ${ props => [SIDEBAR_COLLAPSED, 'px'].join('') }
    }
    @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.md }) {
        margin-inline-start: 0px
    }
`

export const NavigationBarStyledComponents = {
    Controller: styled.div`
        background: ${ props => [props.theme.mode.backgroundColors[1]].join(' ') };
    `,
    Tab: styled.div`
        & h6 {
            font-size: 1em;
            &:hover{
                // max-width: initial;
            }
        }
    `,
    Link: styled.a`

        text-decoration: none;

        &.selected{
            border-bottom: ${ (props) => `3px solid ${ props.theme.style
        .buttonTypeColors.primary }` } ;
        }

        &:hover{
            color: ${ (props) => `${ props.theme.style.buttonTypeColors.primary }` } ;
            transition: all .0s;
        }
    `,
    Label: styled.span`

        color: ${ props => [props.theme.mode.fontColor].join(' ') };

        & > h6 {
                max-width: 150px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
        }
    `,
    Close: styled.span`
        color: ${ props => [props.theme.mode.fontColor].join(' ') };

        &:hover {
            cursor: pointer;
            * {
                // color: $blue !important;
                color: ${ (props) => `${ props.theme.style.buttonTypeColors.primary }` } ;
                transition: all .0s;
            }
        }
    `
}

export const ProfileStyledComponents = {
    QRImg: styled.img`
        width: 10em;
    `,
    ThemeLabel: styled(FormStyledComponents.Label)`
        display: inline;
    `,
    ThemeSelect: styled.div`
        border: ${ props => ['0.1em solid', props.theme.mode.backgroundColors[9]].join(' ') };;
        border-radius: 0.3em;
    `,
    ThemePreview: styled.div<ProfileThemeSelectionProps>`
        height: 3.5em;
        background: ${
            (props) => {
                return getBGColor(
                    props.activeStyle,
                    props.activeMode,
                    0
                )
            }
        };
    `,
    ThemeBars: styled.div<ProfileThemeSelectionProps>`
        width: 10em;

        & > div {
            height: 0.5em;
        }

        & > div:nth-child(1) {
            background: ${
                (props) => {
                    return getBGColor(
                        props.activeStyle,
                        props.activeMode,
                        1
                    )
                }
            };
        }
        
        & > div:nth-child(2) {
            background: ${
                (props) => {
                    return getBGColor(
                        props.activeStyle,
                        props.activeMode,
                        2
                    )
                }
            };
        }
        
        & > div:nth-child(3) {
            background: ${
                (props) => {
                    return getBGColor(
                        props.activeStyle,
                        props.activeMode,
                        3
                    )
                }
            };
        }
    `

}

/** to be used by box status and monitor modules. */
export const DashboardStyledComponents = {
    Container: styled.div<DashboardContainerProps>`
        width: 100%;
        max-width: ${
            (props) => {
                let size
                switch (props.size) {
                    case 'xs': size = '8.5em'; break
                    case 'sm': size = '13.5em'; break
                    case 'md': size = '23.5em'; break
                    case 'lg': size = '28.5em'; break
                    case 'xl': size = '33.5em'; break
                    default: size = '23.5em'; break
                }
                return size
            }
        };

        /** added because you want dashboard monitor cards to have that max-height */
        /* max-height: 150px; */

        @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.xs }) {
            max-width: initial;
        }
    `,

    /** strings are used to assign a color to cards. As of March 29, when recreating the
     * pcidss module, a score is determined to assign the color. ColorPresets would no
     * longer be usable in this case.
     *
     * The solution is to inherit the properties of the Dashboard.Card and modify
     * border-color, --fadeIn, box-shadow, :hover. No font-color changes required.
     */
    Card: styled.div<DashboardCardProps>`

        border: 0.14em solid;
        border-radius: 0.3em;
        cursor: pointer;

        padding-top: 0.15rem;
        padding-bottom: 0.15rem;

        padding-right: 0.5rem;
        padding-left: 0.5rem;

        border-color: ${
            (props) => {
                return Color(
                    getColorPreset(props.activeStyle, props.color)
                ).darken(0.25).hex()
            }
        };

        --fadeOut: ${
            (props) => {
                return getBGColor(
                    props.activeStyle,
                    props.activeMode,
                    0
                )
            }
        };

        --fadeIn: ${
            (props) => {
                return Color(
                    getColorPreset(props.activeStyle, props.color)
                ).fade(0.6).hex()
            }
        };


        background: linear-gradient(180deg, var(--fadeOut) 61%, var(--fadeIn) 150%);

        box-shadow: ${
            (props) => [
                '0em 0em 0.2rem',
                getColorPreset(props.activeStyle, props.color)
            ].join(' ')
        };

        /** if there is no colorType, add dark color properties. */
        background-color: ${
            (props) => {
                if (props.color === 'darkGrey') {
                    return Color(
                        getColorPreset(props.activeStyle, props.color)
                    ).darken(0.25).hex()
                }
            }
        };
        
        /** color is always dark grey regardless of theme. */
        color: ${
            (props) => {
                if (props.color === 'darkGrey') {
                    return getColorPreset(props.activeStyle, props.color)
                }
            }
        };

        /** hover except on darkGrey */
        &:hover {
            box-shadow: ${
                (props) => {
                    if (props.color !== 'darkGrey') {
                        return [
                            '0em 0em 0.4rem',
                            Color(
                                getColorPreset(props.activeStyle, props.color)
                            ).saturate(0.5).hex()
                        ].join(' ')
                    }
                }
            };
        }

        /* enable icons on hovering the card */
        & span > svg {
            cursor: pointer;
            opacity: 0
        }

        &:hover span > svg {
            opacity: 1
        }

    `,
    Title: styled.span`
        font-size: 0.8em;
        text-transform: uppercase;

    `,
    Heading: styled.span<DashboardCardProps>`
        font-size: 1.2em;
        color: ${
            (props) => {
                return getColorPreset(props.activeStyle, props.color)
            }
        };
    `,
    Subtitle: styled.span`
        font-size: 0.9em;
    `,
    Footer: styled.span`
        font-size: 0.6em;
    `
}

/** strings are used to assign a color to cards. As of March 29, when recreating the
 * pcidss module, a score is determined to assign the color. ColorPresets would no
 * longer be usable in this case.
 *
 * The solution is to inherit the properties of the Dashboard.Card and modify
 * border-color, --fadeIn, box-shadow, :hover. No font-color changes required.
 */
export const SectionCard = styled.div<SectionCardProps>`
    border: 0.18em solid;
    border-radius: 0.3em;
    cursor: pointer;

    padding-top: 0.15rem;
    padding-bottom: 0.15rem;

    padding-right: 0.5rem;
    padding-left: 0.5rem;

    border-color: ${
        (props) => {
            return Color(props.color).darken(0.25).hex()
        }
    };


    /** if there is no colorType, add dark color properties. */
    
    /** hover except on darkGrey */
    &:hover {
        box-shadow: ${
            (props) => {
                return [
                    '0em 0em 0.4rem',
                    Color(props.color).saturate(0.5).hex()
                ].join(' ')
            }
        };
    }

    /* enable icons on hovering the card */
    & span > svg {
        cursor: pointer;
        opacity: 0
    }

    &:hover span > svg {
        opacity: 1
    }
`

export const CircularProgressContainer = styled.div`
    position: absolute;
    z-index: 2;
    width: 5em;
    /* text-align: right;
    font-size: 2em; */
`

export const MapInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    width: 2em;
    text-align: right;
    font-size: 2em;
`

export const ErrorMessage = styled.div`
    overflow-wrap: anywhere;
    font-size: 0.7em;
`

export const CollapsibleText = styled.div`

    & > span, div {
        display:inline-block;
        vertical-align:middle;
    }

    & > span:not(:first-child), div:not(:first-child) {
        margin-left: 0.5em;
    }
`

export const PrewrapAll = styled.div`
    * {
        white-space: pre-wrap;
    }
    /** ul, li was causing a lot of space */
    ul, ol, dl, li {
        white-space: normal;
    }
`

export const PrintLogo = styled.img`
    max-width: 120px;
    max-height: 50px;
`

export const PrintBorder = styled.div<PrintBorderProps>`
    height: 35px;
    margin-bottom: 9px;
    
    background: ${ props => {
    const colorPresets = props.theme.style.colorPresets
    switch (props.colorType) {
        case 'blue':
            return colorPresets.blue
        case 'green':
            return colorPresets.green
        case 'yellow':
            return colorPresets.yellow
        case 'amber-1':
            return colorPresets['amber-1']
        case 'amber-2':
            return colorPresets['amber-2']
        case 'red':
            return colorPresets.red
        case 'white':
            return colorPresets.white
        case 'lightGrey':
            return colorPresets.lightGrey
        case 'grey':
            return colorPresets.grey
        case 'darkGrey':
            return colorPresets.darkGrey
        case 'black':
            return colorPresets.black
        default:
            break
    }

    return props.theme.style.buttonTypeColors.primary
} };
`

/** we are going to set a border left property instead of a background.
 * because height content is dynamic unlike width.
 */
export const EventCoverPage = styled.div<PrintBorderProps>`

    font-family: 'Open Sans';

    border-left-width: 50px;

    border-left-color: ${
    props => {
        const colorPresets = props.theme.style.colorPresets
        switch (props.colorType) {
            case 'blue':
                return colorPresets.blue
            case 'green':
                return colorPresets.green
            case 'yellow':
                return colorPresets.yellow
            case 'amber-1':
                return colorPresets['amber-1']
            case 'amber-2':
                return colorPresets['amber-2']
            case 'red':
                return colorPresets.red
            case 'white':
                return colorPresets.white
            case 'lightGrey':
                return colorPresets.lightGrey
            case 'grey':
                return colorPresets.grey
            case 'darkGrey':
                return colorPresets.darkGrey
            case 'black':
                return colorPresets.black
            default:
                break
        }

        return props.theme.style.buttonTypeColors.primary
    } };

    ${ PrintLogo } {
        max-width: initial;
        max-height: initial;
        min-width: 240px;
        min-height: 100px;
    }

    .title {
        font-size: 3.5em;
        margin-bottom: 5%;
    }

    .subtitle {
        font-size: 1.6em;
        margin-bottom: 15%;
    }

    .annotations {
        margin-bottom: 50%;

        span {
            font-size: 1.3em;
        }
    }

`

export const EventContentPage = styled.div`
    font-family: 'Source Sans Pro';

    ${ PrintBorder } {
        height: 5.5em;
    }

    //override border properties. 
    .border-bottom {
        border-bottom-style: dotted !important;
        border-bottom-width: 2px !important;
    }

    .content {
        margin: 60px 80px;
    }
`

export const PrintMargin = styled.div`
    margin-inline: 4.5rem;
`

/** assign to specific containers if necessary. */
export const PageBreakInside = styled.div`
    page-break-inside:avoid !important;
`
/** page break container unconditionally. */
export const PageBreak = styled.div`
    page-break-before: always;
`

export const TableColorCell = styled.td<TableColorCellProps>`
    /** !important was added because bootstrap is being prioritized
    for table body cells.*/
    background: ${ props => props.color } !important;
    width: 2em;
`

/** component for event reports interface. A tab container. */

export const ReportStepTab = styled.li<ReportStepTabProps>`
    border-bottom: ${
        props => {
            if (props.isValid !== undefined && props.isValid === false) {
                return ['0.2em solid', props.theme.style.buttonTypeColors.danger].join(' ')
            }
        }
    }
`

export const ReportStepNavigation = styled.div`
    font-size: 0.8em;
    /** label is white. the links are primary */

    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }

    ${ ReportStepTab } {
        float:left;
        cursor: pointer; 
        padding-inline: 1em;
        padding-block: 0.5em;

        &:hover:not(.selected) {
            color: ${ (props) => props.theme.style.buttonTypeColors.primary } ;
        }

        &.selected {
            cursor: text;
            border-bottom: ${
                props => {
                    return ['0.2em solid', props.theme.style.buttonTypeColors.primary].join(' ')
                }
            };

            background: ${ (props) => props.theme.mode.backgroundColors[2] } ;
        }
    }
`

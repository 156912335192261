import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    MeanMitigation,
    PersistIssueColumn,
    PersistIssueKeys
} from '@interfaces/dashboard/tac/evm'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 30 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    // FAULTY_CALL: 'EXT-VSS call returned faulty.',
    FAULTY_CALL: 'EVM call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    EMPTY_RECOMMENDATIONS: 'No recommendations found for this section...',
    FETCH: {
        MODAL: 'Fetching modal data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        MEAN_MITIGATION_TIME: 'Mean Mitigation Time',
        ISSUES_OVERTIME: 'Issues Over Time',
        PERSISTENT_OVERVIEW: 'Persistent Important Vulnerability Overview > 21 Days'
    }
}

export const MEAN_MITIGATION_COLUMNS: {label: string, value: keyof MeanMitigation }[] = [
    {
        label: 'Name',
        value: 'Name'
    }, {
        label: 'Threat',
        value: 'Threat'
    }, {
        label: 'IP',
        value: 'IP'
    }, {
        label: 'Fixed Time',
        value: 'fixedTime'
    }, {
        label: 'Time Needed (Days)',
        value: 'timeNeeded'
    }
]

export const PERSIST_ISSUES_COLUMNS: PersistIssueColumn[] = (() => {
    const columnNames: Omit<PersistIssueColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'timestamp'
        },
        {
            label: 'IP',
            value: 'IP'
        },
        {
            label: 'Port',
            value: 'port'
        },
        {
            label: 'Severity',
            value: 'severity'
        },
        {
            label: 'CVSS',
            value: 'CVSS'
        },
        {
            label: 'Name',
            value: 'Name'
        }

    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: PersistIssueColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */
export const PERSIST_ISSUE_KEYS: PersistIssueKeys[] = [
    'CVSS', 'IP', 'Name', 'port', 'severity', 'timestamp'
]

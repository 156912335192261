
import {
    useGetModalDetailsMutation
} from '@apis/dashboard/soc/oss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as OSS_MESSAGE,
    TEXT as OSS_TEXT
} from '@constants/dashboard/soc/oss'
import {
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import HorizontalGauge from '@features/dashboard/soc/oss/HorizontalGauge'
import { MonitorModal } from '@interfaces/dashboard/monitor'
import {
    ControlState,
    GetModalDetailsRequest
} from '@interfaces/dashboard/soc/oss'
import { TokenAuth } from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    selectCollapsibles,
    selectSearchParams,
    setCollapsibles,
    setCurrentParams,
    setRefetch,
    toggleCollapsible
} from '@slices/dashboard/soc/oss/details'
import { selectToken } from '@slices/main/token'
import {
    CollapsibleText,
    Container,
    PrewrapAll,
    SpinnerContainer,
    Text
} from '@styles/components'
import parse from 'html-react-parser'
import _ from 'lodash'
import React, {
    createRef,
    useContext,
    useEffect,
    useMemo,
    useRef
} from 'react'
import {
    FaAngleDown,
    FaAngleUp,
    FaExclamationTriangle
} from 'react-icons/fa'
import { toast } from 'react-toastify'

const OSSDetails = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const collapsibles = useAppSelector(selectCollapsibles)
    const horizontalGaugeEls = useRef<React.RefObject<HTMLCanvasElement>[]>([])

    /** will be initiailized multiple times. */
    const [getModalDetails, getModalDetailsMutation] = useGetModalDetailsMutation()

    useEffect(() => {
        if (getModalDetailsMutation.error) {
            console.error(getModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalDetailsMutation.error])

    /** WILL HAVE date ranges like in the parent modal. Starting ranges are the data
     *  of ip address being passed over */

    const unsubscribeGetModalDetails = () => {
        const unsubscribeMutation = getModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalDetails()
        let getModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: GetModalDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            actionType: modal.serviceTypeFormData
                                ?.oss?.details?.actionType || 'Behavior',
                            controlCategory: modal.serviceTypeFormData
                                ?.oss?.details?.controlCategory || 'Data',
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalDetailsPromise = getModalDetails(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalDetailsPromise && getModalDetailsPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalDetailsMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalDetailsMutation.isSuccess
    ])

    useEffect(() => {
        return () => {
            dispatch(setCollapsibles([]))
        }
    }, [])

    const SecureScoreDetails = useMemo(() => {
        const secureScores = getModalDetailsMutation.data?.data || []

        return (
            getModalDetailsMutation.isLoading
                ? <small className={'d-block text-center py-2'}>
                    <SpinnerContainer>
                        <span className={'spinner-border spinner-border-sm'}></span>
                        <span className={'ms-2'}>{OSS_MESSAGE.FETCH.DETAILS}</span>
                    </SpinnerContainer>
                </small>
                : secureScores.length
                    ? _.map(secureScores, (secureScore, index) => {
                        const highRiskIcon = secureScore.highRisk
                            ? <Text size={'md'} mode={'warning'} className={'ps-3'}>
                                <FaExclamationTriangle />
                            </Text>
                            : ''

                        const title = <Text size={'xs'}>
                            {secureScore.title}
                        </Text>

                        let controlState: ControlState['state'] = 'Default'
                        /** sort the controlState array by timestamp */
                        const controlStatesSorted = secureScore.controlState.sort((a, b) => {
                            return (a.updatedDateTime || 0) - (b.updatedDateTime || 0)
                        }).filter((obj) => {
                            return obj.updatedDateTime
                        })

                        if (controlStatesSorted.length > 0) {
                            controlState = controlStatesSorted[0].state
                        }

                        /** you want to filter out empty date times too */
                        horizontalGaugeEls.current[index] = createRef()
                        const chartEl = horizontalGaugeEls.current[index]

                        const DescriptionSegment = parse(secureScore.description || TEXT.NONE)
                        const RemediationSegment = parse(secureScore.remediation || TEXT.NONE)

                        const key = [
                            'ossSecureScore-', index
                        ].join('')

                        return <Container bgIndex={2} key={key} className={'mb-2'}>
                            <div onClick={() => {
                                dispatch(toggleCollapsible({
                                    boolean: !collapsibles[index],
                                    index: index
                                }))
                            }} className={'row align-items-center justify-content-between'}>
                                <div className={'col-6'}>
                                    <div className={'row align-items-center'}>
                                        <div className={'col-auto'}>
                                            {highRiskIcon}
                                        </div>
                                        <div className={'col'}>
                                            {title}
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div className={'row align-items-center'}>
                                        <div className={'col-9'}>
                                            <HorizontalGauge
                                                controlState={controlState}
                                                totalScore={secureScore.score}
                                                maxScore={secureScore.maxScore}
                                                chartEl={chartEl}
                                            />
                                        </div>
                                        <div className={'col-3'}>
                                            {/* collapsible but it's an arrow */}
                                            <CollapsibleText>
                                                <Text size={'xs'} className={'icon pointer '}>
                                                    {collapsibles[index]
                                                        ? <FaAngleUp/>
                                                        : <FaAngleDown/> }
                                                </Text>
                                            </CollapsibleText>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* row of html content */}
                            {collapsibles[index]
                                ? <Container bgIndex={2} className={'px-3'}>
                                    <div className={'row justify-content-center'}>
                                        <div className={'col'}>
                                            <div className={'mt-2 mb-3 px-3'}>
                                                <span className={'d-block mb-2 text-capitalize'}>
                                                    {OSS_TEXT.SECTIONS.DETAILS.DESCRIPTION}
                                                </span>
                                                <Text size={'xs'} className={'d-block mb-2'}>
                                                    <PrewrapAll>{DescriptionSegment}</PrewrapAll>
                                                </Text>
                                            </div>
                                            <div className={'mt-2 px-3'}>
                                                <span className={'d-block mb-2 text-capitalize'}>
                                                    {OSS_TEXT.SECTIONS.DETAILS.REMEDIATION}
                                                </span>
                                                <Text size={'xs'} className={'d-block mb-2'}>
                                                    <PrewrapAll>{RemediationSegment}</PrewrapAll>
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                                : ''}
                        </Container>
                    })
                    : <Container bgIndex={2}>
                        <Text size={'xs'} className={'d-inline-block px-3 py-2'}>
                            {OSS_MESSAGE.EMPTY_RECOMMENDATIONS}
                        </Text>
                    </Container>)
    }, [getModalDetailsMutation, collapsibles])

    return (
        <div className={'mt-4 mt-lg-0'}>
            {SecureScoreDetails}
        </div>
    )
}

export default OSSDetails

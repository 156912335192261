import { useGetModalMutation } from '@apis/dashboard/tac/imm-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as IMM_MESSAGE,
    TEXT as IMM_TEXT
} from '@constants/dashboard/tac/imm'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import IncidentCategoriesTable from '@features/dashboard/tac/imm/IncidentCategoriesTable'
import IncidentCostsTable from '@features/dashboard/tac/imm/IncidentCostsTable'
import IncidentDatasTable from '@features/dashboard/tac/imm/IncidentDataTable'
import IncidentSeverityChart from '@features/dashboard/tac/imm/IncidentSeverityChart'
import MeanIncidentResponseChart from '@features/dashboard/tac/imm/MeanIncidentResponseChart'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import {
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    IncidentData,
    ModalRequest
} from '@interfaces/dashboard/tac/imm'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    resetImm,
    selectCurrentParams,
    selectPrintOptions,
    selectSearchParams,
    setCurrentParams,
    setEndDate,
    setLogo,
    setRefetch,
    setRemoveFilters,
    setStartDate
} from '@slices/dashboard/tac/imm'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    Container,
    DashboardStyledComponents as Dashboard,
    SpinnerContainer
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    differenceInSeconds,
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import { FaFilePdf } from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'
const ImmModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)
    const currentParams = useAppSelector(selectCurrentParams)
    const searchParams = useAppSelector(selectSearchParams)
    const printOptions = useAppSelector(selectPrintOptions)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** will be initiailized multiple times. */
    const [getModal, getModalMutation] = useGetModalMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalMutation()

    const [getModalPrevious, getModalPreviousMutation] = useGetModalMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalMutation.error])

    useEffect(() => {
        if (getModalPreviousMutation.error) {
            console.error(getModalPreviousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalPreviousMutation.error])
    /** after useEffect to throw errors, create fetchData script.
     * NEW CHANGE from old system. make each call a separate script.
     * Was done because client requested to individually have refresh button on
     * each chart.
     *
     * UPDATE: only apply this case WHEN it needs to be. Because this module
     * has one refresh button that executes all api calls at once, put it all in once place.
    */

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetModalPrevious = () => {
        const unsubscribeMutation = getModalPrevious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const newRanges = getUtcRanges(searchParams.ranges)
                    const requestData: ModalRequest & TokenAuth = {
                        authToken: token.value,
                        deviceid: modal.card.deviceid,
                        service_type: modal.card.service_type,
                        in_face: modal.card.in_face,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchData = () => {
        unsubscribeGetModal()
        unsubscribeGetModalPrevious()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getModalPreviousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            service_type: modal.card.service_type,
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalPromise = getModal(requestData)

                        /** get the difference of the current ranges as preqrequisite to
                         * the previous range api call.
                         */

                        const difference = Math.abs(differenceInSeconds(
                            fromUnixTime(newRanges.start), fromUnixTime(newRanges.end)
                        ))

                        getModalPreviousPromise = getModalPrevious({
                            ...requestData,
                            time_from: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.start),
                                    { seconds: difference }
                                )
                            ).toString(),
                            time_to: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.end),
                                    { seconds: difference }
                                )
                            ).toString()
                        })
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
            getModalPreviousPromise && getModalPreviousPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalMutation.isSuccess &&
            getModalPreviousMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalMutation.isSuccess,
        getModalPreviousMutation.isSuccess
    ])

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data
        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && Array.isArray(data?.data) &&
        Array.isArray(data?.data[0]) &&
        (data?.data || []).length <= 0) {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { years: 1 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))
                dispatch(setRefetch(true))

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalMutation.isLoading &&
        getModalPreviousMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update mdr bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [
        getModalMutation,
        getModalPreviousMutation
    ])

    const PrintOptionsContent = useMemo(() => {
        const RemoveFiltersLabel = (
            <label
                className={'col-9'}
                htmlFor={IMM_TEXT.PRINT_OPTIONS.REMOVE_FILTERS.ID}>
                {IMM_TEXT.PRINT_OPTIONS.REMOVE_FILTERS.LABEL}
            </label>
        )
        const RemoveFiltersInput = (
            <input
                type={'checkbox'}
                onChange={() => {
                    dispatch(setRemoveFilters(
                        !printOptions.removeFilters
                    ))
                }}
                checked={printOptions.removeFilters}
            />
        )

        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={IMM_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {IMM_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalMutation.data
        const hasNoData = Array.isArray(data?.data) && (data?.data || []).length <= 0

        return <div>
            <div className={'align-items-center row mb-2'}>
                {RemoveFiltersLabel}
                <div className={'col-3'}>
                    {RemoveFiltersInput}
                </div>
            </div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalMutation,
        queueId,
        queues,
        printOptions,
        currentParams
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        PrintOptionsButton
    ])

    const setStatcardColor: (value:number) => ColorPresets = (value) => {
        let color: ColorPresets = 'grey'

        if (value >= 4) color = 'red'
        else if (value >= 2) color = 'amber-1'
        else if (value >= 1) color = 'yellow'
        else if (value >= 0) color = 'green'

        return color
    }

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = {
            title: modal.card.details.title || '',
            line_1: modal.card.details.line_1 || '',
            line_2: modal.card.details.line_2 || '',
            heading: modal.card.details.heading || '',
            state: modal.card.details.state || '',
            state_details: modal.card.details.state_details || '',
            colorType: modal.card.details.colorType || 'grey'
        }

        let totalIncidentCount = 0
        let totalIncidentCost = 0
        let averageIncidentCost = 0
        const incidentData: IncidentData[] = []

        const responseData = getModalMutation.data?.data[0]

        /** BUG FOUND:
         * index.js:1 Warning: Cannot update a component (`TACMonitors`)
         * while rendering a different component (`ImmModal`). To locate the
         * bad setState() call inside `ImmModal`
         *
         * Cause: Segmentation fault from dividing a number by zero.
         *
         * Fix: inspect division operations at code snippet
         */
        if (responseData) {
            /** don't waste time performing calculations if the incidentCount is zero */

            totalIncidentCount = responseData.length

            _.forEach(responseData, ({ data }) => {
                incidentData.push(data.incidentData)
            })

            // to determine the statcardColor score, different severities is a score.
            const average = totalIncidentCount
                ? _.reduce(incidentData, (a, b) => {
                    switch (b.severity) {
                        case 'Low':a += 1; break
                        case 'Medium':a += 2; break
                        case 'High':a += 3; break
                        case 'Critical':a += 4; break
                        default:break
                    }

                    return a
                }, 0) / totalIncidentCount
                : 0

            dispatch(changeModalColor({
                modal: modal,
                colorType: setStatcardColor(average)
            }))

            totalIncidentCost = _.reduce(incidentData, (a, b) => {
                a += (b.hours_spent * b.hourly_cost)
                return a
            }, 0)

            averageIncidentCost = totalIncidentCount
                ? totalIncidentCost / totalIncidentCount
                : 0
        }

        data.heading = [
            Math.round(totalIncidentCount),
            _.join(_.drop(_.split(data.heading, ' '), 1), ' ')
        ].join(' ')

        data.line_1 = [
            Math.round(totalIncidentCost),
            _.join(_.drop(_.split(data.line_1, ' '), 1), ' ')
        ].join(' ')

        data.line_2 = [
            Math.round(averageIncidentCost),
            _.join(_.drop(_.split(data.line_2, ' '), 1), ' ')
        ].join(' ')

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{IMM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ data.state || 'NO STATE' }
                    : ${ data.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        return () => {
            dispatch(resetImm())
        }
    }, [])

    /** incident severity chart. */
    const IncidentSeverity = useMemo(() => {
        const incidentData: IncidentData[] = []

        const responseData = getModalMutation.data?.data[0]

        if (responseData) {
            _.forEach(responseData, ({ data }) => {
                incidentData.push(data.incidentData)
            })
        }

        return <div>
            <IncidentSeverityChart
                data={incidentData}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const IncidentCategories = useMemo(() => {
        const current: IncidentData[] = []
        const previous: IncidentData[] = []

        const currentResponseData = getModalMutation.data?.data[0]
        const previousResponseData = getModalPreviousMutation.data?.data[0]

        if (currentResponseData) {
            _.forEach(currentResponseData, ({ data }) => {
                current.push(data.incidentData)
            })
        }

        if (previousResponseData) {
            _.forEach(previousResponseData, ({ data }) => {
                previous.push(data.incidentData)
            })
        }

        return <div>
            <IncidentCategoriesTable
                current={current}
                previous={previous}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    // create two mean incident responses. they are line charts with different
    // modes determined by the data sent.
    const MeanIncidentResponseOne = useMemo(() => {
        const incidentData: IncidentData[] = []

        const responseData = getModalMutation.data?.data[0]

        if (responseData) {
            _.forEach(responseData, ({ data }) => {
                incidentData.push(data.incidentData)
            })
        }

        return <div>
            <MeanIncidentResponseChart
                data={incidentData}
                incidentResponseMode={0}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const MeanIncidentResponseTwo = useMemo(() => {
        const incidentData: IncidentData[] = []

        const responseData = getModalMutation.data?.data[0]

        if (responseData) {
            _.forEach(responseData, ({ data }) => {
                incidentData.push(data.incidentData)
            })
        }

        return <div>
            <MeanIncidentResponseChart
                data={incidentData}
                incidentResponseMode={1}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const MeanIncidentResponses = useMemo(() => {
        const content =
            <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                {MeanIncidentResponseOne}
                {MeanIncidentResponseTwo}
            </Container>

        return content
    }, [
        MeanIncidentResponseOne,
        MeanIncidentResponseTwo
    ])

    const IncidentCosts = useMemo(() => {
        const current: IncidentData[] = []
        const previous: IncidentData[] = []

        const currentResponseData = getModalMutation.data?.data[0]
        const previousResponseData = getModalPreviousMutation.data?.data[0]

        if (currentResponseData) {
            _.forEach(currentResponseData, ({ data }) => {
                current.push(data.incidentData)
            })
        }

        if (previousResponseData) {
            _.forEach(previousResponseData, ({ data }) => {
                previous.push(data.incidentData)
            })
        }

        return <div>
            <IncidentCostsTable
                current={current}
                previous={previous}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const DataTable = useMemo(() => {
        const incidentData: IncidentData[] = []

        const responseData = getModalMutation.data?.data[0]

        if (responseData) {
            _.forEach(responseData, ({ data }) => {
                incidentData.push(data.incidentData)
            })
        }

        return <div>
            <IncidentDatasTable
                data={incidentData}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)
    return <div>
        {/* header render */}
        <div className={'row justify-content-between mb-3'}>
            <div className={'col-auto mb-2'}>
                {modal.card.details.title || ''}
            </div>
            <div className={'col-auto pe-5 pe-lg-2'}>
                {ActionButtons}
            </div>
        </div>

        <div className={'row mb-3 align-items-center'}>
            <div className={'col-12 col-md-6'}>
                {Statcard}
            </div>
            <div className={'col-12 col-md-6'}>
                {IncidentSeverity}
            </div>
        </div>

        <div className={'min-width-fix'}>
            <div className={'row mb-3'}>
                <div className={'col-12 col-lg-5'}>
                    <span className={'d-inline-block mb-2'}>
                        {IMM_TEXT.SECTIONS.INCIDENT_CATEGORIES}
                    </span>
                    {IncidentCategories}
                </div>
                <div className={'col-12 col-lg-7'}>
                    <span className={'d-inline-block mb-2'}>
                        {IMM_TEXT.SECTIONS.MEAN_INCIDENT_RESPONSE_TIMES}
                    </span>
                    {MeanIncidentResponses}
                </div>
            </div>
        </div>

        <div className={'min-width-fix mt-2'}>
            <span className={'d-inline-block mb-2'}>
                {IMM_TEXT.SECTIONS.INCIDENT_COSTS}
            </span>
            {IncidentCosts}
        </div>

        <div className={'min-width-fix'}>
            {/* table stuff */}
            {DataTable}
        </div>

    </div>
}

export default ImmModal

import { RootState } from '@app/store'
import {
    COMPARISON_OPERATORS,
    DEFAULT_TABLE_DATA
} from '@constants/main/root'
import {
    COLUMNS
} from '@constants/watchdog/account-data/order'
import {
    FilterInterface,
    RefreshTime
} from '@interfaces/main/root'
import {
    OrderDevice,
    OrderDeviceColumn,
    OrderDeviceFilter,
    OrderDeviceState
} from '@interfaces/watchdog/account-data/order'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import deepEqual from 'deep-equal'
import _ from 'lodash'

const initialState : OrderDeviceState = {
    tableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: COLUMNS.DEVICEID,
        filters: [],
        filtered: [],
        paginated: [],
        filterInterface: {
            show: false,
            keys: [],
            comparisonOperators: _.map(COMPARISON_OPERATORS, ({ value }) => value),
            values: [],
            selected: {
                key: '',
                comparison: COMPARISON_OPERATORS[0].value,
                values: []
            }
        }
    }
}

export const slice = createSlice({
    name: 'orderDevice',
    initialState: initialState,
    reducers: {
        setPage: (state: OrderDeviceState, action: PayloadAction<number>) => {
            state.tableData.page = action.payload
        },
        setCount: (state: OrderDeviceState, action: PayloadAction<number>) => {
            state.tableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: OrderDeviceState, action: PayloadAction<RefreshTime>) => {
            state.tableData.interval = action.payload
        },
        setSearch: (state: OrderDeviceState, action: PayloadAction<string>) => {
            state.tableData.search = action.payload
        },
        setFilters: (state: OrderDeviceState, action: PayloadAction<OrderDeviceFilter[]>) => {
            state.tableData.filters = action.payload
        },
        /** adds a filter */
        addFilter: (state: OrderDeviceState, action: PayloadAction<OrderDeviceFilter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                filter.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.tableData.filters.push(action.payload)
            }
        },
        /** removes a filter */
        removeFilter: (state: OrderDeviceState, action: PayloadAction<OrderDeviceFilter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                deepEqual(filter.value, action.payload.value)
                )
            })
        },
        /** removes ALL filters */
        removeFilters: (state: OrderDeviceState) => {
            state.tableData.filters = []
        },
        setRefetch: (state: OrderDeviceState, action: PayloadAction<boolean>) => {
            state.tableData.refetch = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setColumns: (state: OrderDeviceState, action: PayloadAction<OrderDeviceColumn[]>) => {
            state.tableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setColumnInclude: (state: OrderDeviceState, action: PayloadAction<{
            value: OrderDeviceColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setColumnArrange: (state: OrderDeviceState, action: PayloadAction<{
            value: OrderDeviceColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setFiltered: (state: OrderDeviceState, action: PayloadAction<OrderDevice[]>) => {
            state.tableData.filtered = action.payload
        },
        setPaginated: (state: OrderDeviceState, action: PayloadAction<OrderDevice[]>) => {
            state.tableData.paginated = action.payload
        },
        toggleFilterInterface: (
            state: OrderDeviceState, action: PayloadAction<FilterInterface['show']>) => {
            state.tableData.filterInterface.show = action.payload
        },
        /**
         * assigns an array of strings to represent key names from one object of
         * a response call.
         */
        setFilterKeys: (
            state: OrderDeviceState,
            action: PayloadAction<FilterInterface['keys']>) => {
            state.tableData.filterInterface.keys = action.payload
        },
        /**
         * assigns an array of strings to represent values of a selected key name above
         */
        setFilterValues: (
            state: OrderDeviceState,
            action: PayloadAction<FilterInterface['values']>) => {
            state.tableData.filterInterface.values = action.payload
        },
        /**
         * assign selected.key a value for filtering.
         */
        setSelectedKey: (
            state: OrderDeviceState, action: PayloadAction<FilterInterface['selected']['key']>
        ) => {
            state.tableData.filterInterface.selected.key = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedComparison: (
            state: OrderDeviceState,
            action: PayloadAction<FilterInterface['selected']['comparison']>
        ) => {
            state.tableData.filterInterface.selected.comparison = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedValues: (
            state: OrderDeviceState, action: PayloadAction<FilterInterface['selected']['values']>
        ) => {
            state.tableData.filterInterface.selected.values = action.payload
        },
        resetOrderDevice: (state: OrderDeviceState) => {
            state.tableData = initialState.tableData
        }
    }
})

export const {
    setFiltered,
    setPaginated,
    setPage,
    setCount,
    setColumns,
    setColumnInclude,
    setColumnArrange,
    setIntervalObj,
    setSearch,
    setFilters,
    addFilter,
    removeFilter,
    removeFilters,
    setRefetch,
    toggleFilterInterface,
    setFilterKeys,
    setFilterValues,
    setSelectedKey,
    setSelectedComparison,
    setSelectedValues,
    resetOrderDevice
} = slice.actions

export const selectTableData = (state: RootState) => state.orderDevice.tableData
export default slice.reducer

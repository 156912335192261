
import {
    DEFAULT_ASVS_CATS,
    MESSAGE as WSS_MESSAGE
} from '@constants/dashboard/soc/wss'
import {
    ASVSCats
} from '@interfaces/dashboard/soc/wss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query'
import {
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useMemo
} from 'react'
import { FaCircle } from 'react-icons/fa'

interface ComponentProps {
    data: ASVSCats | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const OWASPClassificationTable = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const DataTable = useMemo(() => {
        const ASVSCats = data || DEFAULT_ASVS_CATS

        // now get those keys and perform a custom sort.
        const orderedKeys = _.keys(ASVSCats).sort((a, b) => {
            if (
                _.parseInt(a.substring(1)) > _.parseInt(b.substring(1))
            ) {
                return 1
            } else if (
                _.parseInt(a.substring(1)) < _.parseInt(b.substring(1))
            ) {
                return -1
            } else {
                return 0
            }
        }) as (keyof ASVSCats)[]

        const content = <Table
            className={'table-striped table-hover table-flipped px-0'}
        >
            <table className={'table'}>
                <tbody>
                    {
                        _.map(orderedKeys, (label, index) => {
                            const key = [
                                'owasp-risk-classification-td-', index
                            ].join('')

                            // get asvsCat object.
                            const asvsCat = ASVSCats[label]
                            let className = ''

                            // assign class based on value.
                            if (asvsCat.value <= 0) className = 'none-text'
                            else if (asvsCat.value === 1) className = 'info-text'
                            else if (asvsCat.value === 2) className = 'low-text'
                            else if (asvsCat.value === 3) className = 'medium-text'
                            else if (asvsCat.value >= 4) className = 'high-text'

                            return (
                                <tr key={key}>
                                    <td className={[
                                        'owaspRisk',
                                        className
                                    ].join(' ')}>
                                        <FaCircle/>
                                    </td>
                                    <td>{label}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        data
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

OWASPClassificationTable.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default OWASPClassificationTable

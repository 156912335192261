
import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as RDD_MESSAGE
} from '@constants/dashboard/soc/rdd'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    Aggregation
} from '@interfaces/dashboard/monitor'
import {
    IPAddressResponse,
    MacVendorResponse
} from '@interfaces/dashboard/soc/rdd'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    SpinnerContainer
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    PieController,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'

interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>
    macVendorData: MacVendorResponse | undefined,
    macVendorDataPrevious: MacVendorResponse | undefined,
    ipAddressData: IPAddressResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const DeviceTypesChart = ({
    chartEl, macVendorData, macVendorDataPrevious, ipAddressData,
    isLoading, isSuccess, error
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(PieController, ArcElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const currentData = macVendorData?.aggregations || {
            3: {
                doc_count_error_upper_bound: 0,
                sum_other_doc_count: 0,
                buckets: []
            }
        }

        const ipAddresses = ipAddressData?.data || []

        const data = _.intersectionWith(
            currentData['3'].buckets,
            ipAddresses,
            (arrVal, otherVal) => {
                return arrVal.key === otherVal.mac_type
            }
        )

        let graph: Chart<'pie', {rddDetails: Aggregation}[], string>

        const datasets: typeof graph.data.datasets = [{
            data: _.map(data, (bucket) => {
                return {
                    rddDetails: bucket
                }
            }),
            // random color because of each key.
            backgroundColor: _.map(
                data,
                (_i, index) => CHART_COLORS[index]
            ),
            borderWidth: 0,
            parsing: {
                key: 'rddDetails.doc_count'
            }
        }]

        if (data.length && chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'pie',
                data: {
                    labels: _.map(data, (bucket) => bucket.key),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            display: false,
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            display: false,
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        }
                    }
                }

            })

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.height = CHART_HEIGHT.md
            chartEl.current.style.maxHeight = CHART_HEIGHT.md
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [
        macVendorData,
        ipAddressData
    ])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row my-2'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{RDD_MESSAGE.FETCH.MAC_VENDORS}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

DeviceTypesChart.propTypes = {
    macVendorData: PropTypes.object,
    macVendorDataPrevious: PropTypes.object,
    ipAddressData: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default DeviceTypesChart


import { URL } from '@constants/main/root'
import {
    CreateReportSectionRequest,
    CreateReportSectionResponse,
    DownloadSectionRequest,
    DownloadSectionResponse,
    GenerateSectionRequest,
    GenerateSectionResponse,
    GetCompleteReportRequest,
    GetCompleteReportResponse,
    GetMainKeyResponse,
    GetReportsRequest,
    GetReportsResponse,
    GetSectionsRequest,
    GetSectionsResponse
} from '@interfaces/main/print'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const dashboardPdfApi = createApi({
    reducerPath: 'dashboardPdfApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.PRINT_API
    }),
    endpoints: (builder) => ({
        createReportSections: builder.mutation<CreateReportSectionResponse,
            CreateReportSectionRequest & TokenAuth
            >({
                query: (body) => {
                    const formData: CreateReportSectionRequest = {
                        card: body.card,
                        searchParams: body.searchParams,
                        printOptions: body.printOptions,
                        stylesheet: body.stylesheet,
                        theme: body.theme,
                        isPrinting: body.isPrinting
                    }

                    return {
                        url: 'api/v1/dashboard/createReportSections',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getSections: builder.mutation<GetSectionsResponse,
            GetSectionsRequest & TokenAuth
            >({
                query: (body) => {
                    const formData: GetSectionsRequest = {
                        reportId: body.reportId
                    }

                    return {
                        url: 'api/v1/dashboard/getSections',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getReports: builder.mutation<GetReportsResponse,
            GetReportsRequest & TokenAuth
            >({
                query: (body) => {
                    const formData: GetReportsRequest = {}

                    return {
                        url: 'api/v1/dashboard/getReports',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        generateSection: builder.mutation<GenerateSectionResponse,
            GenerateSectionRequest & TokenAuth
                >({
                    query: (body) => {
                        const formData: GenerateSectionRequest = {
                            sectionId: body.sectionId
                        }

                        return {
                            url: 'api/v1/dashboard/generateSection',
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        generateAllSections: builder.mutation<GenerateSectionResponse,
            GetSectionsRequest & TokenAuth
                >({
                    query: (body) => {
                        const formData: GetSectionsRequest = {
                            reportId: body.reportId
                        }

                        return {
                            url: 'api/v1/dashboard/generateAllSections',
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        downloadSection: builder.mutation<DownloadSectionResponse,
            DownloadSectionRequest & TokenAuth
                >({
                    query: (body) => {
                        const formData: DownloadSectionRequest = {
                            sectionId: body.sectionId
                        }

                        return {
                            url: 'api/v1/dashboard/downloadSection',
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        downloadCompleteReport: builder.mutation<GetCompleteReportResponse,
            GetCompleteReportRequest & TokenAuth
                >({
                    query: (body) => {
                        const formData: GetCompleteReportRequest = {
                            reportId: body.reportId,
                            withoutCrash: body.withoutCrash
                        }

                        return {
                            url: 'api/v1/dashboard/downloadCompleteReport',
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }
                }),
        getMainKey: builder.mutation<GetMainKeyResponse,
            GetSectionsRequest & TokenAuth
                >({
                    query: (body) => {
                        const formData: GetSectionsRequest = {
                            reportId: body.reportId
                        }

                        return {
                            url: 'api/v1/dashboard/getMainKey',
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${ body.authToken }`,
                                'Content-Type': 'application/json'
                            },
                            body: formData
                        }
                    }

                })
    })
})

export const {
    useCreateReportSectionsMutation,
    useGenerateSectionMutation,
    useDownloadSectionMutation,
    useDownloadCompleteReportMutation,
    useGetSectionsMutation,
    useGenerateAllSectionsMutation,
    useGetReportsMutation,
    useGetMainKeyMutation
} = dashboardPdfApi


import {
    EVENT_ROUTES
} from '@constants/main/routes'
import FlowMenu from '@features/watchdog/soc-data/flow/FlowMenu'
import React from 'react'

const FlowMenuNoProps = () => {
    return <FlowMenu />
}

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: EVENT_ROUTES.FLOW.link,
        component: FlowMenuNoProps
    },
    {
        exact: true,
        path: [
            EVENT_ROUTES.FLOW.link,
            '/:deviceid',
            '/:flowid'
        ].join(''),
        component: FlowMenuNoProps
    }
]

export default Routes

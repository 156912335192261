import _ from 'lodash'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    DEFAULT_TABLE_DATA,
    COMPARISON_OPERATORS
} from '@constants/main/root'
import {
    INITIAL_SEARCH_PARAMS,
    CLEAN_RESULT_COLUMNS
} from '@constants/dashboard/soc/wss'
import {
    CleanResultColumn,
    ExtWssMainState,
    CleanResult,
    CleanResultFilter,
    FixedCollapsibles
} from '@interfaces/dashboard/soc/wss'

import {
    RefreshTime,
    FilterInterface
} from '@interfaces/main/root'
import deepEqual from 'deep-equal'

const initialState : ExtWssMainState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    /** this is just to provide printOptions optional properties
     * on start. If you want the properties to be mandatory,
     * modify them in the state's interface.
     */
    printOptions: {
        logo: '',
        addIssueDetails: false,
        groupIssues: false,
        mainReportOnly: false,
        removeFilters: false
    },
    expandText: false,
    tableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: CLEAN_RESULT_COLUMNS,
        filters: [],
        filtered: [],
        paginated: [],
        filterInterface: {
            show: false,
            keys: [],
            comparisonOperators: _.map(COMPARISON_OPERATORS, ({ value }) => value),
            values: [],
            selected: {
                key: '',
                comparison: COMPARISON_OPERATORS[0].value,
                values: []
            }
        }
    },
    fixedCollapsibles: {
        issueCategories: false,
        owaspAsvsClassification: false,
        issueOverview: false
    }
}

export const slice = createSlice({
    name: 'wssMain',
    initialState: initialState,
    reducers: {
        setStartDate: (state: ExtWssMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: ExtWssMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: ExtWssMainState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setLogo: (state: ExtWssMainState, action: PayloadAction<string>) => {
            state.printOptions.logo = action.payload
        },
        setIssueDetails: (state: ExtWssMainState, action: PayloadAction<boolean>) => {
            state.printOptions.addIssueDetails = action.payload
        },
        setGroupIssues: (state: ExtWssMainState, action: PayloadAction<boolean>) => {
            state.printOptions.groupIssues = action.payload
        },
        setMainReportOnly: (state: ExtWssMainState, action: PayloadAction<boolean>) => {
            state.printOptions.mainReportOnly = action.payload
        },
        setRemoveFilters: (state: ExtWssMainState, action: PayloadAction<boolean>) => {
            state.printOptions.removeFilters = action.payload
        },
        setExpandText: (state: ExtWssMainState, action: PayloadAction<boolean>) => {
            state.expandText = action.payload
        },
        setPage: (state: ExtWssMainState, action: PayloadAction<number>) => {
            state.tableData.page = action.payload
        },
        setCount: (state: ExtWssMainState, action: PayloadAction<number>) => {
            state.tableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: ExtWssMainState, action: PayloadAction<RefreshTime>) => {
            state.tableData.interval = action.payload
        },
        setSearch: (state: ExtWssMainState, action: PayloadAction<string>) => {
            state.tableData.search = action.payload
        },
        setFilters: (state: ExtWssMainState, action: PayloadAction<CleanResultFilter[]>) => {
            state.tableData.filters = action.payload
        },
        /** adds a filter */
        addFilter: (state: ExtWssMainState, action: PayloadAction<CleanResultFilter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                    filter.sort === action.payload.sort &&
                    filter.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.tableData.filters.push(action.payload)
            }
        },
        /** removes a filter */
        removeFilter: (state: ExtWssMainState, action: PayloadAction<CleanResultFilter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                deepEqual(filter.value, action.payload.value)
                )
            })
        },
        /** removes ALL filters */
        removeFilters: (state: ExtWssMainState) => {
            state.tableData.filters = []
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setColumns: (state: ExtWssMainState, action: PayloadAction<CleanResultColumn[]>) => {
            state.tableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setColumnInclude: (state: ExtWssMainState, action: PayloadAction<{
            value: CleanResultColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setColumnArrange: (state: ExtWssMainState, action: PayloadAction<{
            value: CleanResultColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setFiltered: (state: ExtWssMainState, action: PayloadAction<CleanResult[]>) => {
            state.tableData.filtered = action.payload
        },
        setPaginated: (state: ExtWssMainState, action: PayloadAction<CleanResult[]>) => {
            state.tableData.paginated = action.payload
        },
        setCurrentParams: (state: ExtWssMainState, action: PayloadAction<
            ExtWssMainState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        toggleCollapsible: (state: ExtWssMainState, action: PayloadAction<{
            key: keyof FixedCollapsibles,
            value: boolean
        }>) => {
            state.fixedCollapsibles[action.payload.key] = action.payload.value
        },
        toggleFilterInterface: (
            state: ExtWssMainState, action: PayloadAction<FilterInterface['show']>) => {
            state.tableData.filterInterface.show = action.payload
        },
        /**
         * assigns an array of strings to represent key names from one object of
         * a response call.
         */
        setFilterKeys: (state: ExtWssMainState, action: PayloadAction<FilterInterface['keys']>) => {
            state.tableData.filterInterface.keys = action.payload
        },
        /**
         * assigns an array of strings to represent values of a selected key name above
         */
        setFilterValues: (
            state: ExtWssMainState, action: PayloadAction<FilterInterface['values']>) => {
            state.tableData.filterInterface.values = action.payload
        },
        /**
         * assign selected.key a value for filtering.
         */
        setSelectedKey: (
            state: ExtWssMainState, action: PayloadAction<FilterInterface['selected']['key']>
        ) => {
            state.tableData.filterInterface.selected.key = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedComparison: (
            state: ExtWssMainState, action: PayloadAction<FilterInterface['selected']['comparison']>
        ) => {
            state.tableData.filterInterface.selected.comparison = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedValues: (
            state: ExtWssMainState, action: PayloadAction<FilterInterface['selected']['values']>
        ) => {
            state.tableData.filterInterface.selected.values = action.payload
        },
        resetWssMain: (state: ExtWssMainState) => {
            state.fixedCollapsibles = initialState.fixedCollapsibles
            state.printOptions = initialState.printOptions
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
            state.tableData = initialState.tableData
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setLogo,
    setIssueDetails,
    setGroupIssues,
    setMainReportOnly,
    setRemoveFilters,
    setExpandText,
    setPage,
    setCount,
    setIntervalObj,
    setSearch,
    setFilters,
    addFilter,
    removeFilter,
    removeFilters,
    setColumns,
    setColumnInclude,
    setColumnArrange,
    setFiltered,
    setPaginated,
    setCurrentParams,
    toggleCollapsible,
    toggleFilterInterface,
    setFilterKeys,
    setFilterValues,
    setSelectedKey,
    setSelectedComparison,
    setSelectedValues,
    resetWssMain
} = slice.actions

export const selectSearchParams = (state: RootState) => state.wssMain.searchParams
export const selectCurrentParams = (state: RootState) => state.wssMain.currentParams
export const selectPrintOptions = (state: RootState) => state.wssMain.printOptions
export const selectExpandText = (state: RootState) => state.wssMain.expandText
export const selectTableData = (state: RootState) => state.wssMain.tableData
export const selectFixedCollapsibles = (state: RootState) => state.wssMain.fixedCollapsibles

export default slice.reducer


import {
    useGetIPAddressMutation,
    useGetMacAddressMutation,
    useGetMacNewMutation,
    useGetMacReappearMutation,
    useGetMacVendorMutation,
    useGetModalHeaderMutation
} from '@apis/dashboard/soc/rdd-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as RDD_MESSAGE,
    TEXT as RDD_TEXT
} from '@constants/dashboard/soc/rdd'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import DeviceTypesChart from '@features/dashboard/soc/rdd/DeviceTypesChart'
import DeviceTypesTable from '@features/dashboard/soc/rdd/DeviceTypesTable'
import IPAddressDataTable from '@features/dashboard/soc/rdd/IPAddressDataTable'
import MacNewTable from '@features/dashboard/soc/rdd/MacNewTable'
import MacReappearTable from '@features/dashboard/soc/rdd/MacReappearTable'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import {
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/rdd'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    resetRddMain,
    selectCurrentParams,
    selectPrintOptions,
    selectSearchParams,
    selectTableData,
    setCurrentParams,
    setEndDate,
    setLogo,
    setMainReportOnly,
    setRefetch,
    setRemoveFilters,
    setStartDate
} from '@slices/dashboard/soc/rdd/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    Container,
    DashboardStyledComponents as Dashboard,
    SpinnerContainer
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    differenceInSeconds,
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaFileCsv,
    FaFilePdf
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'

const RDDModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const tableData = useAppSelector(selectTableData)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** will be initiailized multiple times. */
    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalHeaderMutation()

    const [getMacVendorPrevious, getMacVendorPreviousMutation] = useGetMacVendorMutation()
    const [getMacVendor, getMacVendorMutation] = useGetMacVendorMutation()
    // reason for having ip address is to filter out new and reappear results.
    const [getMacAddress, getMacAddressMutation] = useGetMacAddressMutation()
    const [getMacNew, getMacNewMutation] = useGetMacNewMutation()
    const [getMacReappear, getMacReappearMutation] = useGetMacReappearMutation()
    const [getIPAddress, getIPAddressMutation] = useGetIPAddressMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getMacVendorMutation.error) {
            console.error(getMacVendorMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getMacVendorMutation.error])

    useEffect(() => {
        if (getMacVendorPreviousMutation.error) {
            console.error(getMacVendorPreviousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getMacVendorPreviousMutation.error])

    useEffect(() => {
        if (getMacAddressMutation.error) {
            console.error(getMacAddressMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getMacAddressMutation.error])

    useEffect(() => {
        if (getMacNewMutation.error) {
            console.error(getMacNewMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getMacNewMutation.error])

    useEffect(() => {
        if (getMacReappearMutation.error) {
            console.error(getMacReappearMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getMacReappearMutation.error])

    useEffect(() => {
        if (getIPAddressMutation.error) {
            console.error(getIPAddressMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIPAddressMutation.error])

    /** after useEffect to throw errors, create fetchData script.
     * NEW CHANGE from old system. make each call a separate script.
     * Was done because client requested to individually have refresh button on
     * each chart.
     *
     * UPDATE: only apply this case WHEN it needs to be. Because this module
     * has one refresh button that executes all api calls at once, put it all in once place.
    */

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const newRanges = getUtcRanges(searchParams.ranges)
                    const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                        authToken: token.value,
                        deviceid: modal.card.deviceid,
                        in_face: modal.card.in_face,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIPAddress = () => {
        const unsubscribeMutation = getIPAddress({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMacVendor = () => {
        const unsubscribeMutation = getMacVendor({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMacVendorPrevious = () => {
        const unsubscribeMutation = getMacVendorPrevious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMacAddress = () => {
        const unsubscribeMutation = getMacAddress({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMacNew = () => {
        const unsubscribeMutation = getMacNew({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMacReappear = () => {
        const unsubscribeMutation = getMacReappear({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const fetchData = () => {
        unsubscribeGetModalHeader()
        unsubscribeGetIPAddress()
        unsubscribeGetMacVendor()
        unsubscribeGetMacVendorPrevious()
        unsubscribeGetMacAddress()
        unsubscribeGetMacNew()
        unsubscribeGetMacReappear()

        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIPAddressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMacVendorPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMacVendorPreviousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMacAddressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMacNewPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMacReappearPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalHeaderPromise = getModalHeader(requestData)

                        const difference = Math.abs(differenceInSeconds(
                            fromUnixTime(newRanges.start), fromUnixTime(newRanges.end)
                        ))
                        getMacVendorPromise = getMacVendor(requestData)
                        getMacVendorPreviousPromise = getMacVendorPrevious({
                            ...requestData,
                            time_from: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.start),
                                    { seconds: difference }
                                )
                            ).toString(),
                            time_to: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.end),
                                    { seconds: difference }
                                )
                            ).toString()
                        })
                        getMacAddressPromise = getMacAddress(requestData)
                        getMacNewPromise = getMacNew(requestData)
                        getMacReappearPromise = getMacReappear(requestData)
                        getIPAddressPromise = getIPAddress(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
            getIPAddressPromise && getIPAddressPromise.abort()
            getMacVendorPromise && getMacVendorPromise.abort()
            getMacVendorPreviousPromise && getMacVendorPreviousPromise.abort()
            getMacAddressPromise && getMacAddressPromise.abort()
            getMacNewPromise && getMacNewPromise.abort()
            getMacReappearPromise && getMacReappearPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalHeaderMutation.isSuccess &&
            getIPAddressMutation.isSuccess &&
            getMacVendorMutation.isSuccess &&
            getMacVendorPreviousMutation.isSuccess &&
            getMacAddressMutation.isSuccess &&
            getMacNewMutation.isSuccess &&
            getMacReappearMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalHeaderMutation.isSuccess,
        getIPAddressMutation.isSuccess,
        getMacVendorMutation.isSuccess,
        getMacVendorPreviousMutation.isSuccess,
        getMacAddressMutation.isSuccess,
        getMacNewMutation.isSuccess,
        getMacReappearMutation.isSuccess
    ])

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data
        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && data?.data.heading === '0 Total Devices') {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 1 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))
                dispatch(setRefetch(true))

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalHeaderMutation.isLoading ||
            getMacVendorPreviousMutation.isLoading ||
            getMacVendorMutation.isLoading ||
            getMacAddressMutation.isLoading ||
            getMacNewMutation.isLoading ||
            getMacReappearMutation.isLoading ||
            getIPAddressMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update mdr bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [
        getModalHeaderMutation,
        getMacVendorPreviousMutation,
        getMacVendorMutation,
        getMacAddressMutation,
        getMacNewMutation,
        getMacReappearMutation,
        getIPAddressMutation
    ])

    const CsvExportButton = useMemo(() => {
        const ipAddressData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            modal.card.service_type,
            '_', modal.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = ipAddressData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                onClick={() => {
                    if (disableExport) return false
                }}
                filename={filename}
                data={ipAddressData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        modal.card,
        currentParams.ranges
    ])

    const PrintOptionsContent = useMemo(() => {
        const MainReportLabel = (
            <label
                className={'col-9'}
                htmlFor={RDD_TEXT.PRINT_OPTIONS.MAIN_REPORT_ONLY.ID}>
                {RDD_TEXT.PRINT_OPTIONS.MAIN_REPORT_ONLY.LABEL}
            </label>
        )
        const MainReportInput = (
            <input
                className={'pointer'}
                type={'checkbox'}
                onChange={() => {
                    dispatch(setMainReportOnly(
                        !printOptions.mainReportOnly
                    ))
                }}
                checked={printOptions.mainReportOnly}
            />
        )

        const RemoveFiltersLabel = (
            <label
                className={'col-9'}
                htmlFor={RDD_TEXT.PRINT_OPTIONS.REMOVE_FILTERS.ID}>
                {RDD_TEXT.PRINT_OPTIONS.REMOVE_FILTERS.LABEL}
            </label>
        )
        const RemoveFiltersInput = (
            <input
                type={'checkbox'}
                onChange={() => {
                    dispatch(setRemoveFilters(
                        !printOptions.removeFilters
                    ))
                }}
                checked={printOptions.removeFilters}
            />
        )

        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={RDD_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {RDD_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getIPAddressMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <div className={'align-items-center row mb-2'}>
                {MainReportLabel}
                <div className={'col-3'}>
                    {MainReportInput}
                </div>

            </div>
            <div className={'align-items-center row mb-2'}>
                {RemoveFiltersLabel}
                <div className={'col-3'}>
                    {RemoveFiltersInput}
                </div>
            </div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getIPAddressMutation,
        queueId,
        queues,
        printOptions,
        currentParams
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data: ModalHeaderResponse['data'] = getModalHeaderMutation.data?.data || {
            title: modal.card.details.title || '',
            line_1: modal.card.details.line_1 || '',
            line_2: modal.card.details.line_2 || '',
            heading: modal.card.details.heading || '',
            colorType: modal.card.details.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{RDD_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ modal.card.details.state || 'NO STATE' }
                    : ${ modal.card.details.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        dispatch(changeModalColor({
            modal: modal,
            colorType: data?.colorType || modal.card.details.colorType || 'grey'
        }))
    }, [
        modal.card,
        getModalHeaderMutation
    ])

    useEffect(() => {
        return () => {
            dispatch(resetRddMain())
        }
    }, [])

    const deviceTypesChartEl = useRef<HTMLCanvasElement>(null)

    const DeviceTypes = useMemo(() => {
        return <>
            <div className={'col-md-8 col-12'}>
                <DeviceTypesTable
                    macVendorData={getMacVendorMutation.data}
                    macVendorDataPrevious={getMacVendorPreviousMutation.data}
                    ipAddressData={getIPAddressMutation.data}
                    isLoading={getMacVendorMutation.isLoading}
                    isSuccess={getMacVendorMutation.isSuccess}
                    error={getMacVendorMutation.error}
                    modal={modal}
                    addModal={addModal}
                    closeModal={closeModal}
                    changeModalColor={changeModalColor}
                />
            </div>
            <div className={'col-md-4 col-12'}>
                <DeviceTypesChart
                    chartEl={deviceTypesChartEl}
                    macVendorData={getMacVendorMutation.data}
                    macVendorDataPrevious={getMacVendorPreviousMutation.data}
                    ipAddressData={getIPAddressMutation.data}
                    isLoading={getMacVendorMutation.isLoading}
                    isSuccess={getMacVendorMutation.isSuccess}
                    error={getMacVendorMutation.error}
                    modal={modal}
                    addModal={addModal}
                    closeModal={closeModal}
                    changeModalColor={changeModalColor}
                />
            </div>
        </>
    }, undefined)

    const MacNew = useMemo(() => {
        return (
            <MacNewTable
                macNewData={getMacNewMutation.data}
                isLoading={getMacNewMutation.isLoading}
                isSuccess={getMacNewMutation.isSuccess}
                error={getMacNewMutation.error}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
            />
        )
    }, undefined)

    const MacReappear = useMemo(() => {
        return (
            <MacReappearTable
                macReappearData={getMacReappearMutation.data}
                isLoading={getMacReappearMutation.isLoading}
                isSuccess={getMacReappearMutation.isSuccess}
                error={getMacReappearMutation.error}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
            />
        )
    }, undefined)

    const IPAddressTable = useMemo(() => {
        return (
            <IPAddressDataTable
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getIPAddressMutation.data}
                isLoading={getIPAddressMutation.isLoading}
                isSuccess={getIPAddressMutation.isSuccess}
                error={getIPAddressMutation.error}
            />
        )
    }, undefined)
    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {Statcard}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <span className={'d-block'}>
                    {RDD_TEXT.SECTIONS.MAC_VENDOR}
                </span>

                <div className={'row align-items-center mb-2'}>
                    {DeviceTypes}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        <span className={'d-block'}>
                            {RDD_TEXT.SECTIONS.MAC_NEW}
                        </span>
                        {MacNew}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        <span className={'d-block'}>
                            {RDD_TEXT.SECTIONS.MAC_REAPPEARED}
                        </span>
                        {MacReappear}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <span className={'d-block mb-2'}>
                    {RDD_TEXT.SECTIONS.IP_ADDRESS}
                </span>
                {IPAddressTable}
            </div>

        </div>
    )
}

export default RDDModal

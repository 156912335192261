
import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'
import {
    SOURCE_FILE_EXTENSION_DOUGHNUT_COLUMNS,
    SHAREPOINT_CLIENT_IP_DOUGHNUT_COLUMNS,
    MESSAGE as O365_MESSAGE
} from '@constants/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    hideChartTooltip,
    showChartTooltip
} from '@constants/main/method'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    EventType,
    SharePointEventKeys
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    Bucket,
    Doc,
    O365DetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    selectFixedCollapsibles,
    addModal,
    selectCurrentParams,
    selectDashboardData,
    addBool
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/o365/details'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    HoverFilter,
    SpinnerContainer,
    Table
} from '@styles/components'
import {
    ArcElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    DoughnutController,
    Tooltip,
    BarController,
    BarElement,
    TimeScale,
    Title
} from 'chart.js'
import {
    add,
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { createStylesheet } from '@styles/themes'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

import uniqueString from 'unique-string'
import {
    FaSearchPlus,
    FaSearchMinus
} from 'react-icons/fa'
import Tippy from '@tippyjs/react'

interface ComponentProps {
    eventType: Extract<EventType, 'SourceFileExtension.Doughnut' | 'SharepointClientIP.Doughnut'>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const O365DoughnutChart = ({
    eventType, isLoading, isSuccess, error
}:ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)

    const dispatch = useAppDispatch()

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const [
        chartInstance,
        setChartInstance
    ] = useState<Chart<'doughnut', { docCount: number }[], string>>()

    /** colors for both chart and table. will be set in chart initialization but will
     * only target lifecycle of table. doing so in chart will trigger an infinite render loop.
    */
    const [, setChartColors] = useState<string[]>([])

    /** i don't want to duplicate the code snippet so we'll just create
     * this separately. Plus the pseudo code ended up taking up more than 300 lines.
     * we end up having to make each chart a separate file BUT at least it's somewhere else.
     *
     * UPDATE: 9/16/2022. Ended up being one chart instance with multiple switch cases.
     */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** There was an attempt to make a useMemo BUT realized that
     * the lifecycle would be inconsistent. we want a cleanup function
     * before creating the chart instance for every lifecycle.
     *
     * one more thing, only memoize the data on performance leaks.
     * doing so ignoring this reminder led to chart tooltip drawing
     * errors especially when you have useRefs to create visualizations.
     *
     * this occured when memozing the table (an alternative to display
     * data counts).
     *
     * TypeError: Cannot read properties of undefined (reading 'handleEvent')
     * this error above occurs when the dispatches on that onclick event
     * are in the wrong order.
     *
     * Issue with search params where you update the ranges. It
     * will mess up the form data for fetching either o365 details or data id
     * when selecting the first OR last dataset
     * in the charts OR the tables below them.
     */

    const setDetailsModal = (
        startKey: Date,
        endKey: Date,
        serviceTypeFormData: ServiceTypeFormData
    ) => {
        /** ERROR: you'll get more data than the current doc_count
         * because it will use the timezone offset again and
         * change these values. You don't want this.
         *
         * Fix is to add the key_as_string values with the timezone
         * offset multiplying it by -1 first.
         */

        const timezoneOffset = new Date().getTimezoneOffset()

        // don't fetch if either keys are undefined.
        if (startKey && endKey) {
            const startDate = getUnixTime(
                add(
                    startKey,
                    { minutes: timezoneOffset * -1 }
                )
            )

            const endDate = getUnixTime(
                add(
                    endKey,
                    { minutes: timezoneOffset * -1 }
                )
            )

            dispatch(setDetailsStartDate(startDate))
            dispatch(setDetailsEndDate(endDate))

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                card: currentParams.card,
                operation: 'DETAILS',
                serviceTypeFormData: serviceTypeFormData,
                isBorderWide: true
            }))
        } else {
            console.error('Date keys are missing. Not adding modal')
        }
    }

    /** we already have an array of issueAlert. We don't need a zoom chart for this. */
    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const buckets = _.orderBy(
            dashboardData[eventType]
                ?.aggregations?.[2]?.buckets || []
            , ['doc_count'], ['desc'])
        let graph: Chart<'doughnut', { docCount: number; }[], string>

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const colors: string[] = _.map(
                buckets, (_i, index) => CHART_COLORS[index]
            )

            /** added at 9/23/2022. create chart sorting by doc_count
             * in descending order.
             */

            const datasets: typeof graph.data.datasets = [{
                data: _.map(buckets, (bucket) => {
                    return { docCount: bucket.doc_count }
                }),
                // random color because of each key.
                backgroundColor: colors,
                borderWidth: 0,
                parsing: {
                    key: 'docCount'
                }
            }]

            setChartColors(colors)

            graph = new Chart(chartEl.current, {
                type: 'doughnut',
                data: {
                    labels: _.map(buckets, (bucket) => bucket.key.toString()),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        }
                    }
                }
            })

            graph.options.onClick = (event) => {
                if (event.native) {
                    const points = graph.getElementsAtEventForMode(
                        event.native, 'nearest', { intersect: true }, true
                    )

                    if (points.length) {
                        const firstPoint = points[0]
                        /**
                         * get timestamp and other values from issueAlert,
                         */
                        const labels = graph.data.labels || []

                        const detailsContent:O365DetailsForm = {
                            event_type: eventType,
                            q: currentParams.q
                        }

                        switch (eventType) {
                            case 'SharepointClientIP.Doughnut':
                                detailsContent.ip = labels[firstPoint.index] || ''
                                break
                            case 'SourceFileExtension.Doughnut':
                                detailsContent.extension = labels[firstPoint.index] || ''
                                break
                            default:
                                detailsContent.operation = labels[firstPoint.index] || ''
                                break
                        }

                        const serviceTypeFormData:ServiceTypeFormData = {
                            o365: {
                                details: detailsContent
                            }
                        }

                        const ranges: { start:Date, end: Date } = {
                            // these default values are never going to be used anyway
                            // start: new Date(), end: new Date()
                            start: fromUnixTime(currentParams.ranges.start),
                            end: fromUnixTime(currentParams.ranges.end)
                        }

                        /** date ranges are the ones in the query. */
                        setDetailsModal(
                            ranges.start,
                            ranges.end,
                            serviceTypeFormData
                        )
                    }
                }
            }

            chartEl.current.style.height = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        dashboardData[eventType]
    ])

    const DataTable = useMemo(() => {
        const buckets = _.orderBy(
            dashboardData[eventType]
                ?.aggregations?.[2]?.buckets || []
            , ['doc_count'], ['desc'])

        const cellBody = (
            dataObject: Bucket,
            property: keyof Bucket
        ) => {
            let cellContent: Bucket[keyof Bucket] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            /** these zoom dispatches will function differently.
             * regardless of table cell, the same property will be
             * added.
             */

            const zoomAction = (not: boolean) => {
                let sort: SharePointEventKeys

                /** keys to use here have to be property names
                 * when querying at the database. for example,
                 * instead of operation, you use Operation.
                 */

                switch (eventType) {
                    case 'SharepointClientIP.Doughnut':
                        sort = 'ClientIP'
                        break
                    case 'SourceFileExtension.Doughnut':
                        sort = 'SourceFileExtension'
                        break
                    default:
                        sort = 'Operation'
                        break
                }

                dispatch(addBool({
                    not: not,
                    sort: sort,
                    value: [dataObject.key as string]
                }))
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                <HoverFilter bgIndex={2}>
                    <div className={'pe-2 py-1'}>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                            {zoomIn}
                        </Tippy>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                            {zoomOut}
                        </Tippy>
                    </div>
                    <div>
                        {cellContent}
                    </div>
                </HoverFilter>
            )
        }

        let eventTypeColumns: {
            label: string;
            value: keyof Doc;
        }[] = []

        switch (eventType) {
            case 'SharepointClientIP.Doughnut':
                eventTypeColumns = SHAREPOINT_CLIENT_IP_DOUGHNUT_COLUMNS

                break
            case 'SourceFileExtension.Doughnut':
                eventTypeColumns = SOURCE_FILE_EXTENSION_DOUGHNUT_COLUMNS
                break
            default:
                break
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(eventTypeColumns,
                                ({ label }, index) => {
                                    const key = [
                                        'o365Activity-th-', index
                                    ].join('')
                                    return <th key={key}><small>{label}</small></th>
                                })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(buckets, (dataObject, rowIndex, arr) => {
                            const onClick = () => {
                                const ranges: { start:Date, end: Date } = {
                                    // these default values are never going to be used anyway
                                    // start: new Date(), end: new Date()
                                    start: fromUnixTime(currentParams.ranges.start),
                                    end: fromUnixTime(currentParams.ranges.end)
                                }

                                const detailsContent:O365DetailsForm = {
                                    event_type: eventType,
                                    q: currentParams.q
                                }

                                switch (eventType) {
                                    case 'SharepointClientIP.Doughnut':
                                        detailsContent.ip = dataObject.key.toString() || ''
                                        break
                                    case 'SourceFileExtension.Doughnut':
                                        detailsContent.extension = dataObject.key.toString() || ''
                                        break
                                    default:
                                        detailsContent.operation = dataObject.key.toString() || ''
                                        break
                                }

                                const serviceTypeFormData:ServiceTypeFormData = {
                                    o365: {
                                        details: detailsContent
                                    }
                                }

                                setDetailsModal(
                                    ranges.start,
                                    ranges.end,
                                    serviceTypeFormData
                                )
                            }

                            return (
                                <tr
                                    key={'o365Activity-tr-' + rowIndex}
                                    onMouseOver={() => {
                                        showChartTooltip(0, rowIndex, chartInstance)
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(chartInstance)
                                    }}
                                    onClick={onClick}
                                >
                                    {
                                        _.map(
                                            eventTypeColumns,
                                            (column, cellIndex) => {
                                                return (
                                                    <td key={[
                                                        'o365Activity-td-' + rowIndex +
                                            '-' + cellIndex
                                                    ].join('')}
                                                    >
                                                        {cellBody(dataObject, column.value)}
                                                    </td>
                                                )
                                            })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            buckets.length
                ? content
                : EmptyCellContent
        )
    }, [
        dashboardData[eventType],
        chartInstance
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles[eventType]
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
        )

        let fetchMessage: string = ''
        switch (eventType) {
            case 'SharepointClientIP.Doughnut':
                fetchMessage = O365_MESSAGE.FETCH.SHAREPOINT_CLIENT_IP_DOUGHNUT
                break
            case 'SourceFileExtension.Doughnut':
                fetchMessage = O365_MESSAGE.FETCH.SOURCE_FILE_EXTENSION_DOUGHNUT
                break
            default:
                break
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        fetchMessage
                    }</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

O365DoughnutChart.propTypes = {
    modal: PropTypes.object,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default O365DoughnutChart

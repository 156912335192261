import {
    useGetDeviceConfigMutation,
    useGetM365ConfigMutation,
    useGetVAPTConfigMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    TEXT as CONFIG_TEXT
} from '@constants/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
/** tables are created in separate components. */
import {
    addModal,
    closeModal,
    removeModal,
    resetMain as resetConfigMain,
    selectModals
} from '@slices/watchdog/configuration/device-configuration/main'
import _ from 'lodash'
import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import { toast } from 'react-toastify'

import DeviceConfigTable from
    '@features/watchdog/configuration/device-configuration/DeviceConfigTable'
import DoM365UpdateLocations from
    '@features/watchdog/configuration/device-configuration/m365/locations/DoM365UpdateLocations'
import M365Details from
    '@features/watchdog/configuration/device-configuration/m365/M365Details'
import DoM365Update from
    '@features/watchdog/configuration/device-configuration/m365/update/DoM365Update'
import M365UpdateSuccess from
    '@features/watchdog/configuration/device-configuration/m365/update/M365UpdateSuccess'
import M365DataTable from
    '@features/watchdog/configuration/device-configuration/M365DataTable'
import RemoveConfirmationWindow from
    '@features/watchdog/configuration/device-configuration/vapt/remove/RemoveConfirmationWindow'
import DoSchedule from
    '@features/watchdog/configuration/device-configuration/vapt/schedule/DoSchedule'
import UpdateConfirmationWindow from
    '@features/watchdog/configuration/device-configuration/vapt/update/UpdateConfirmationWindow'
import VAPTDetails from
    '@features/watchdog/configuration/device-configuration/vapt/VAPTDetails'
import VAPTDataTable from
    '@features/watchdog/configuration/device-configuration/VAPTDataTable'
import DeviceConfigDetails from
    '@features/watchdog/configuration/device-configuration/device/DeviceConfigDetails'
import ConfirmationMessage from
    '@features/watchdog/configuration/device-configuration/device/emailAlerts/ConfirmationMessage'
import SuccessMessage from
    '@features/watchdog/configuration/device-configuration/device/update/SuccessMessage'
import UpdateConfirmationMessage from
    '@features/watchdog/configuration/device-configuration/device/update/UpdateConfirmationMessage'
import { resetM365 as resetConfigM365 } from
    '@slices/watchdog/configuration/device-configuration/m365'
import { resetDevice as resetConfigDevice } from
    '@slices/watchdog/configuration/device-configuration/device-config'
import { resetVAPT as resetVAPTMain } from
    '@slices/watchdog/configuration/device-configuration/vapt/main'
const ConfigMenu = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    const [getM365Config, getM365ConfigMutation] = useGetM365ConfigMutation()
    const [getVAPTConfig, getVAPTConfigMutation] = useGetVAPTConfigMutation()
    const [getDeviceConfig, getDeviceConfigMutation] = useGetDeviceConfigMutation()

    const unsubscribeGetM365Config = () => {
        const unsubscribeMutation = getM365Config({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetVAPTConfig = () => {
        const unsubscribeMutation = getVAPTConfig({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetDeviceConfig = () => {
        const unsubscribeMutation = getDeviceConfig({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetM365Config()
        unsubscribeGetVAPTConfig()
        unsubscribeGetDeviceConfig()
        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getM365ConfigPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getVAPTConfigPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getDeviceConfigPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getM365ConfigPromise = getM365Config({
                        authToken: newToken
                    })

                    getVAPTConfigPromise = getVAPTConfig({
                        authToken: newToken
                    })

                    getDeviceConfigPromise = getDeviceConfig({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getM365ConfigPromise && getM365ConfigPromise.abort()
            getVAPTConfigPromise && getVAPTConfigPromise.abort()
            getDeviceConfigPromise && getDeviceConfigPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getM365ConfigMutation.error) {
            console.error(getM365ConfigMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getM365ConfigMutation.error])

    useEffect(() => {
        if (getVAPTConfigMutation.error) {
            console.error(getVAPTConfigMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getVAPTConfigMutation.error])

    useEffect(() => {
        if (getDeviceConfigMutation.error) {
            console.error(getDeviceConfigMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDeviceConfigMutation.error])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'M365_VIEW') {
                    component = <M365Details
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'M365_DETAILS_UPDATE') {
                    component = <DoM365Update
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'M365_DETAILS_UPDATE_SUCCESS') {
                    component = <M365UpdateSuccess
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'M365_LOCATION_UPDATE') {
                    component = <DoM365UpdateLocations
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'VAPT_VIEW') {
                    component = <VAPTDetails
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'VAPT_REMOVE_ASSET') {
                    component = <RemoveConfirmationWindow
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'VAPT_DO_SCHEDULE') {
                    component = <DoSchedule
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'VAPT_UPDATE_ASSET') {
                    component = <UpdateConfirmationWindow
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'DEVICE_CONFIG_VIEW') {
                    component = <DeviceConfigDetails
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'DEVICE_CONFIG_UPDATE') {
                    component = <UpdateConfirmationMessage
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'DEVICE_CONFIG_UPDATE_SUCCESS') {
                    component = <SuccessMessage
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'DEVICE_CONFIG_EMAIL_ALERTS_UPDATE') {
                    component = <ConfirmationMessage
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    /** reset validation data on unmount */
    useEffect(() => {
        // make sure you set this dependency to false on unmount
        return () => {
            dispatch(resetConfigMain())
            dispatch(resetConfigM365())
            dispatch(resetConfigDevice())
            dispatch(resetVAPTMain())
            // dispatch(resetVAPTDetails())
        }
    }, [])

    return (
        <div>
            <small className={'d-block my-2'}>
                {CONFIG_TEXT.SEARCH.M365}
            </small>
            <M365DataTable
                addModal={addModal}
                data={getM365ConfigMutation.data?.data}
                isLoading={getM365ConfigMutation.isLoading}
                isSuccess={getM365ConfigMutation.isSuccess}
                error={getM365ConfigMutation.error}
            />
            <small className={'d-block my-2'}>
                {CONFIG_TEXT.SEARCH.VAPT}
            </small>
            <VAPTDataTable
                addModal={addModal}
                data={getVAPTConfigMutation.data?.data}
                isLoading={getVAPTConfigMutation.isLoading}
                isSuccess={getVAPTConfigMutation.isSuccess}
                error={getVAPTConfigMutation.error}
            />
            <small className={'d-block my-2'}>
                {CONFIG_TEXT.SEARCH.DEVICE_CONFIG}
            </small>
            <DeviceConfigTable
                addModal={addModal}
                data={getDeviceConfigMutation.data?.data}
                isLoading={getDeviceConfigMutation.isLoading}
                isSuccess={getDeviceConfigMutation.isSuccess}
                error={getDeviceConfigMutation.error}
            />
            {
                renderModals
            }
        </div>
    )
}
export default ConfigMenu

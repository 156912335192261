import {
    MutationContext
} from '@root/MutationProvider'
import {
    selectToken
} from '@slices/main/token'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'

import {
    useGetReportDataMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppSelector
} from '@app/hook'
import {
    tryParseJSON
} from '@constants/main/method'
import {
    TEXT as PRINT_TEXT
} from '@constants/main/print'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    PRINT_CHECK_TIMER
} from '@constants/main/root'
import {
    ApprovedBy,
    DecodedReportParam,
    FileID,
    PredefinedAnswer,
    PredefinedQuestion,
    ReportData,
    ReportParams
} from '@interfaces/watchdog/soc-data/event'
import {
    EventContentPage,
    PageBreak,
    PageBreakInside,
    PrintBorder,
    PrintLogo
} from '@styles/components'
import {
    Base64
} from 'js-base64'
import { useDebouncedCallback } from 'use-debounce'

import _ from 'lodash'

import { format } from 'date-fns'
import { useParams } from 'react-router'
const ContentPage = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const token = useAppSelector(selectToken)

    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl] = useState<string>('')

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    const [getReportData, getReportDataMutation] = useGetReportDataMutation()
    const { reportObj } = useParams<ReportParams>()

    /** we want the latest reportData for each reportStep, if there is no
     * data for a reportStep, just provide <unanswered> OR an empty string.
     */
    const [latestReportData, setLatestReportData] = useState<ReportData[]>([])

    const getOuterHTML = useCallback((data: FileID['fileData']['data']) => {
        if (data.fileType === 'text/plain') {
            const code = document.createElement('code')
            code.textContent = Base64.decode(data.fileContent)
            return code.outerHTML
        } else if (data.fileType === 'image/jpeg') {
            const img = document.createElement('img')
            img.src = 'data:image/jpeg;base64,' + data.fileContent
            return img.outerHTML
        } else {
            return ''
        }
    }, [])

    /** fetch report data */
    const unsubscribeGetReportData = () => {
        const unsubscribeMutation = getReportData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = useCallback(() => {
        unsubscribeGetReportData()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getReportData({
                        authToken: newToken,
                        reportId: decodedObj.id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }, [token.valid, reportObj])

    useEffect(() => {
        return fetchData()
    }, [token.valid, reportObj])

    useEffect(() => {
        if (
            getReportDataMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getReportDataMutation.data
    ])

    useEffect(() => {
        if (
            getReportDataMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getReportDataMutation.error
    ])

    useEffect(() => {
        const reportSteps = getReportDataMutation.data?.reportSteps || []
        const reportData = getReportDataMutation.data?.reportData || []

        const result: ReportData[] = []

        /** step 1: iterate through reportSteps. in each iteration, go
         * to the report data and find the latest record. Once it was found,
         * push to a useState containing ReportData[]. Otherwise, just
         * provide an empty template of reportData.
         */

        _.forEach(reportSteps, (reportStep) => {
            const findLatestData: ReportData | undefined = _.maxBy(_.filter(reportData,
                (o) => { return o.reportSection === reportStep.reportSection }
            ), (o) => o.answerTimestamp)

            if (findLatestData) {
                result.push(findLatestData)
            } else {
                result.push({
                    answerGiven: '',
                    answerTimestamp: '0',
                    fileIds: [],
                    reportId: '',
                    reportQuestion: reportStep.reportQuestion,
                    reportSection: reportStep.reportSection,
                    reportStep: reportStep.reportStep,
                    reviewNotes: ''
                })
            }
        })

        setLatestReportData(result)
    }, [getReportDataMutation.data])

    const Document = useMemo(() => {
        const reportContent = getReportDataMutation.data?.reportContent

        return <table>
            <tbody>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.DOCUMENT.TITLE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{
                        _.trim(_.split(reportContent?.reportName, '|')[1])
                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.DOCUMENT.SUBTITLE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{
                        _.trim(_.split(reportContent?.reportName, '|')[0])
                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.DOCUMENT.CLASSIFICATION
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{
                        reportContent?.approvedBy[0].createNotes
                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.DOCUMENT.AUTHOR
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{
                        reportContent?.approvedBy[0].userId
                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.DOCUMENT.VERSION
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{
                        reportContent?.approvedBy[0].reportVersion
                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.DOCUMENT.VERSION_DATE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{
                        reportContent?.approvedBy[0].createdOn
                            ? format(
                                reportContent?.approvedBy[0].createdOn,
                                DATE_FORMAT_TIME
                            )
                            : ''
                    }</td>
                </tr>
            </tbody>
        </table>
    }, [getReportDataMutation.data])

    const VersionControl = useMemo(() => {
        const reportContent = getReportDataMutation.data?.reportContent

        // should be split into three parts. createdBy, reviewedBy, approvedBy
        let arr: {title:string, data: ApprovedBy[]}[] = []

        if (reportContent) {
            arr = [
                {
                    title: 'Created By',
                    data: reportContent.createdBy
                },
                {
                    title: 'Reviewed By',
                    data: reportContent.reviewedBy
                },
                {
                    title: 'Approved By',
                    data: reportContent.approvedBy
                }
            ]
        }

        return <table>
            <thead>
                <tr className={'border-bottom'}>
                    <th className={'pb-2 pl-1 pt-2 font-weight-bolder'}>{
                        PRINT_TEXT.PRINT_REPORT.VERSION_CONTROL.VERSION
                    }</th>
                    <th className={'pb-2 pl-1 pt-2 font-weight-bolder'}>{
                        PRINT_TEXT.PRINT_REPORT.VERSION_CONTROL.CHANGE
                    }</th>
                    <th className={'pb-2 pl-1 pt-2 font-weight-bolder'}>{
                        PRINT_TEXT.PRINT_REPORT.VERSION_CONTROL.AUTHOR
                    }</th>
                </tr>
            </thead>
            {
                _.map(arr, (obj, index) => {
                    const key = [
                        'reportContent-th-', index
                    ].join('')

                    return <div key={key}>
                        <caption className={'pb-0 d-inline-block'}>{obj.title}</caption>
                        <tbody>
                            {
                                _.map(obj.data, (obj, titleIndex) => {
                                    const titleKey = [
                                        'title-th-', titleIndex
                                    ].join('')

                                    return <tr key={titleKey} className={'border-bottom'}>
                                        <td className={'border-top-0'}>{obj.reportVersion}</td>
                                        <td className={'border-top-0'}>{obj.createNotes}</td>
                                        <td className={'border-top-0'}>{obj.userId}</td>
                                    </tr>
                                }
                                )
                            }
                        </tbody>
                    </div>
                })
            }

        </table>
    }, [getReportDataMutation.data])

    const TableOfContents = useMemo(() => {
        const reportSteps = getReportDataMutation.data?.reportSteps || []

        return (
            <ul className={'pl-3'}>
                {
                    _.map(reportSteps, (reportStepObj, index) => {
                        const key = [
                            'reportStep-a-', index
                        ].join('')

                        return (
                            <a className={'text-dark'}
                                key={key}
                                href={'#'.concat(reportStepObj.reportSection.toString())}
                            >
                                <li className={'list-unstyled'}>
                                    <h5 className={'d-inline pr-2'}>{
                                        reportStepObj.reportSection
                                    }</h5>
                                    <h4 className={'d-inline-block'}>{
                                        reportStepObj.reportQuestion
                                    }</h4>
                                </li>
                            </a>
                        )
                    })
                }
            </ul>
        )
    }, [getReportDataMutation.data])

    const ReportContent = useMemo(() => {
        const reportSteps = getReportDataMutation.data?.reportSteps || []

        return _.map(reportSteps, (reportStep, index) => {
            const latestRecord = _.find(latestReportData, (obj) => {
                return obj.reportSection === reportStep.reportSection
            })

            const answerGiven = latestRecord?.answerGiven
                ? JSON.parse(
                    Base64.decode(latestRecord.answerGiven)
                ) as unknown as string[]
                : [] as string[]

            const headerContent = <>
                <div className={'col-auto pl-0'}>
                    {reportStep.reportSection}
                </div>
                <div className={'col-auto px-0 font-weight-bolder'}>
                    {reportStep.reportQuestion}
                </div>
            </>

            const header = index
                ? <PageBreakInside id={reportStep.reportSection.toString()} className={'row mt-3'}>
                    {headerContent}
                </PageBreakInside>
                : <PageBreak id={reportStep.reportSection.toString()} className={'row'}>
                    {headerContent}
                </PageBreak>

            let content: JSX.Element = <></>

            switch (reportStep.reportAnswerType) {
                case 'predefined': {
                    const renderContent = (predefObj: PredefinedQuestion, predObjIndex: number) => {
                        const key = [
                            'predefinedQuestion-', index
                        ].join('')

                        const predefinedAnswer = answerGiven[predObjIndex]

                        return <div className={'row'} key={key}>
                            <div className={'col pr-0'}>{predefObj.Question}</div>
                            <div
                                className={'col'}
                                ref={ (r) => {
                                    let content = predefinedAnswer.replace(
                                        /\n/g, '<br />'
                                    )

                                    _.forEach(latestRecord?.fileIds || [], (obj) => {
                                        const target = '[id=' + obj.fileId + ']'

                                        const idMatch = content.slice(
                                            content.indexOf(target),
                                            content.indexOf(target) + target.length
                                        )

                                        content = _.replace(
                                            content,
                                            new RegExp(idMatch, 'g'),
                                            getOuterHTML(obj.fileData.data)
                                        )
                                    })

                                    if (r) {
                                        r.textContent = content
                                    }
                                } }

                            ></div>
                        </div>
                    }

                    content = <> {
                        _.map(reportStep.reportPredefinedQuestion, renderContent)
                    } </>

                    break
                }
                case 'open': {
                    content = <div className={'row'}>
                        <div className={'col'}
                            ref={ (r) => {
                                let content = answerGiven[0].replace(
                                    /\n/g, '<br />'
                                )

                                _.forEach(latestRecord?.fileIds || [], (obj) => {
                                    const target = '[id=' + obj.fileId + ']'

                                    const idMatch = content.slice(
                                        content.indexOf(target),
                                        content.indexOf(target) + target.length
                                    )

                                    content = _.replace(
                                        content,
                                        new RegExp(idMatch, 'g'),
                                        getOuterHTML(obj.fileData.data)
                                    )
                                })

                                if (r) {
                                    r.textContent = content
                                }
                            } }
                        >

                        </div>
                    </div>

                    break
                }
                case 'checkbox': {
                    const renderContent = (predefObj: PredefinedAnswer, predObjIndex: number) => {
                        const key = [
                            'predefinedAnswer-', index
                        ].join('')

                        const found = _.includes(answerGiven, predefObj.predefinedAnswer)

                        return <div className={'row'} key={key}>
                            <div className={'col-auto pr-0'}>
                                <input
                                    type={'checkbox'}
                                    checked={found}
                                    value={predefObj.predefinedAnswer}
                                />
                            </div>
                            <div
                                className={'col'}
                            >
                                {
                                    predefObj.predefinedAnswer
                                }
                            </div>
                        </div>
                    }

                    content = <> {
                        _.map(reportStep.reportPredefinedAnswer, renderContent)
                    } </>

                    break
                }
                case 'radio': {
                    const renderContent = (predefObj: PredefinedAnswer, predObjIndex: number) => {
                        const key = [
                            'predefinedAnswer-', index
                        ].join('')

                        const found = _.includes(answerGiven, predefObj.predefinedAnswer)

                        return <div className={'row'} key={key}>
                            <div className={'col-auto pr-0'}>
                                <input
                                    type={'radio'}
                                    checked={found}
                                    value={predefObj.predefinedAnswer}
                                />
                            </div>
                            <div
                                className={'col'}
                            >
                                {
                                    predefObj.predefinedAnswer
                                }
                            </div>
                        </div>
                    }

                    content = <> {
                        _.map(reportStep.reportPredefinedAnswer, renderContent)
                    } </>

                    break
                }
                case 'bullet': {
                    content = <div className={'row'}>
                        <div className={'col'} >
                            <ul className={'mb-0'}>
                                {
                                    _.map(answerGiven, (answer) => {
                                        return <li
                                            ref={ (r) => {
                                                let content = answer.replace(
                                                    /\n/g, '<br />'
                                                )

                                                _.forEach(latestRecord?.fileIds || [], (obj) => {
                                                    const target = '[id=' + obj.fileId + ']'

                                                    const idMatch = content.slice(
                                                        content.indexOf(target),
                                                        content.indexOf(target) + target.length
                                                    )

                                                    content = _.replace(
                                                        content,
                                                        new RegExp(idMatch, 'g'),
                                                        getOuterHTML(obj.fileData.data)
                                                    )
                                                })

                                                if (r) {
                                                    r.textContent = content
                                                }
                                            } }
                                        >

                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    break
                }

                default:
                    break
            }

            return [header, content]
        })
    }, [getReportDataMutation.data, latestReportData])

    const Author = useMemo(() => {
        // const reportContent = getReportDataMutation.data?.reportContent

        return <table>
            <tbody>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.NAME
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.FUNCTION
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.EMAIL
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.ORGANIZATION
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.ADDRESS
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.TELEPHONE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.SIGNATURE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
            </tbody>
        </table>
    }, [getReportDataMutation.data])

    const Requester = useMemo(() => {
        // const reportContent = getReportDataMutation.data?.reportContent

        return <table>
            <tbody>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.NAME
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.FUNCTION
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.EMAIL
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.ORGANIZATION
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.ADDRESS
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.TELEPHONE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
                <tr className={'border-bottom'}>
                    <td className={'border-top-0'}>{
                        PRINT_TEXT.PRINT_REPORT.AUTHOR.SIGNATURE
                    }</td>
                    <td className={'border-top-0 font-weight-bolder'}>{

                    }</td>
                </tr>
            </tbody>
        </table>
    }, [getReportDataMutation.data])

    return <div>
        {isPrintComplete
            ? <div
                id={'printComplete'}
            ></div>
            : ''}
        {hasCrashed
            ? <div
                id={'hasCrashed'}
            ></div>
            : ''}
        {/* main modals will have a border */}
        <EventContentPage>
            <PrintBorder id={'printBorder'} colorType={'blue'}/>
            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            <div className={'content'}>
                {/* document section */}
                <div>
                    <h3 className={'text-black-50 font-weight-bold mb-0 text-uppercase'}>
                        {PRINT_TEXT.PRINT_REPORT.DOCUMENT.MAIN}
                    </h3>
                    {Document}
                </div>

                {/* version control section */}
                <div>
                    <h3 className={'text-black-50 font-weight-bold mb-0 text-uppercase'}>
                        {PRINT_TEXT.PRINT_REPORT.VERSION_CONTROL.MAIN}
                    </h3>
                    {VersionControl}
                </div>

                <div className={'row'}>
                    <div className={'col'}>
                        <h3 className={'text-black-50 font-weight-bold mb-0 text-uppercase'}>
                            {PRINT_TEXT.PRINT_REPORT.AUTHOR.MAIN}
                        </h3>
                        {Author}

                    </div>
                    <div className={'col'}>
                        <h3 className={'text-black-50 font-weight-bold mb-0 text-uppercase'}>
                            {PRINT_TEXT.PRINT_REPORT.REQUESTER.MAIN}
                        </h3>
                        {Requester}
                    </div>
                </div>

                {/* page-break here */}
                <PageBreak>
                    <h1 className={'text-black-50 font-weight-bold mb-0 text-uppercase'}>
                        {PRINT_TEXT.PRINT_REPORT.EVENT_SUMMARY}
                    </h1>
                    {TableOfContents}
                </PageBreak>

                {ReportContent}
            </div>

        </EventContentPage>
    </div>
}

export default ContentPage

import {
    useVaptConfigAddMutation,
    useVaptConfigDetailsMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    MESSAGE as CONFIG_MESSAGE,
    TEXT as CONFIG_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/configuration/device-config'
import {
    DeviceConfigModal
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
/** tables are created in separate components. */
import {
    Button,
    FormStyledComponents as Form
} from '@styles/components'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'

import DetailsTable from '@features/watchdog/configuration/device-configuration/vapt/DetailsTable'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const VAPTDetails = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const token = useAppSelector(selectToken)

    const [vaptConfigAdd, vaptConfigAddMutation] = useVaptConfigAddMutation()

    const [
        getVAPTConfigDetails,
        getVAPTConfigDetailsMutation
    ] = useVaptConfigDetailsMutation()

    /** call formiks */
    const addFormik = useFormik({
        initialValues: INITIAL_VALUES.VAPT.ADD,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.VAPT.ADD,
        onSubmit: async () => {
            const newToken = await revalidateToken()

            if (modal.formData.vapt?.details?.id) {
                vaptConfigAdd({
                    assetName: addFormik.values.assetName,
                    deviceId: addFormik.values.deviceId,
                    authToken: newToken
                })
            }
        }
    })

    useEffect(() => {
        if (vaptConfigAddMutation.data) {
            const data = vaptConfigAddMutation.data
            if (data.status) {
                toast.success(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
                /** when done, display the success message in a modal instead of
                 * a toast and remove this modal
                 */
            } else {
                toast.error(
                    CONFIG_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [vaptConfigAddMutation.data])

    useEffect(() => {
        if (vaptConfigAddMutation.error) {
            console.error(vaptConfigAddMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [vaptConfigAddMutation.error])

    const AssetInput = useMemo(() => {
        return (
            <div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Form.Label
                            htmlFor={CONFIG_TEXT.VAPT_ADD_ASSET.FORM.ASSET.ID}
                            className={'d-inline-block'}
                        >
                            {CONFIG_TEXT.VAPT_ADD_ASSET.FORM.ASSET.LABEL}
                        </Form.Label>

                        <Form.Input
                            readOnly={true}
                            disabled={true}
                            errors={Boolean(addFormik.errors.assetName)}
                            name={'asset'}
                            id={CONFIG_TEXT.VAPT_ADD_ASSET.FORM.ASSET.ID}
                            onChange={addFormik.handleChange}
                            value={addFormik.values.assetName}
                        />
                    </div>
                    <div className={'col-auto'}>
                        <Button
                            type={'submit'}
                            mode={'primary'}
                        >
                            {CONFIG_TEXT.VAPT_ADD_ASSET.FORM.SUBMIT_BUTTON}
                        </Button>
                    </div>
                </div>
                <Form.Feedback errors={Boolean(addFormik.errors.assetName)} >{
                    addFormik.errors.assetName ? addFormik.errors.assetName : null
                }</Form.Feedback>
            </div>
        )
    }, [addFormik.values.assetName, addFormik.errors.assetName])

    useEffect(() => {
        if (getVAPTConfigDetailsMutation.error) {
            console.error(getVAPTConfigDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getVAPTConfigDetailsMutation.error])

    const unsubscribeGetVAPTConfigDetails = () => {
        const unsubscribeMutation = getVAPTConfigDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetVAPTConfigDetails()
        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getVAPTConfigDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            const deviceid = modal.formData.vapt?.details?.deviceId || ''

            if (token.valid && deviceid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getVAPTConfigDetailsPromise = getVAPTConfigDetails({
                        authToken: newToken,
                        deviceId: deviceid
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getVAPTConfigDetailsPromise && getVAPTConfigDetailsPromise.abort()
        }
    }, [token.valid])

    return (<div>
        <Form.Main onSubmit={addFormik.handleSubmit}>
            {AssetInput}
        </Form.Main>
        <DetailsTable
            modal={modal}
            addModal={addModal}
            closeModal={closeModal}
            data={getVAPTConfigDetailsMutation.data?.data}
            isLoading={getVAPTConfigDetailsMutation.isLoading}
            isSuccess={getVAPTConfigDetailsMutation.isSuccess}
            error={getVAPTConfigDetailsMutation.error}
        />
    </div>)
}

export default VAPTDetails


import {
    useViewQuestionnaireMutation,
    useGetComplianceTypesMutation
} from '@apis/watchdog/virtual-ciso/questionnaire-information-api'
import {
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    VALIDATION_SCHEMA,
    TEXT as QUESTIONNAIRE_TEXT
} from '@constants/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import MenuLinks from '@features/main/MenuLinks'
import { LabelValuePair } from '@interfaces/main/root'
import {
    QuestionnaireParams
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    selectTabs
} from '@slices/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    FormStyledComponents as Form
} from '@styles/components'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'

const QuestionnaireView = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)

    const { questionnaireID } = useParams<QuestionnaireParams>()

    /** fetch other calls. */
    const [viewQuestionnaire, viewQuestionnaireMutation] = useViewQuestionnaireMutation()
    const [getComplianceTypes, getComplianceTypesMutation] = useGetComplianceTypesMutation()

    const questionnaireFormik = useFormik({
        initialValues: INITIAL_VALUES.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.UPDATE,
        onSubmit: () => {
        }
    })

    // 5d9731e27f689b7e5bcae003 is questionnaire id edit just to check.
    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.ID}
                    checked={questionnaireFormik.values.active}
                    disabled={true}
                />
                <Form.Feedback
                    errors={Boolean(questionnaireFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        questionnaireFormik.errors.active ? questionnaireFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [questionnaireFormik.values.active, questionnaireFormik.errors.active])

    const NameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(questionnaireFormik.errors.name)}
                    name={'name'}
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.ID}
                    value={questionnaireFormik.values.name}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(questionnaireFormik.errors.name)} >{
                    questionnaireFormik.errors.name ? questionnaireFormik.errors.name : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [questionnaireFormik.values.name, questionnaireFormik.errors.name])

    const ComplianceTypeInput = useMemo(() => {
        let arr = [] as LabelValuePair[]

        if (getComplianceTypesMutation.data) {
            arr = getComplianceTypesMutation.data.cTypes
        }

        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.LABEL}
                </Form.Label>
                <Select
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.ID}
                    errors={Boolean(questionnaireFormik.errors.complianceLines)}
                    options={arr}
                    value={_.find(
                        arr,
                        (e) => e.value === questionnaireFormik.values.complianceLines
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
                <Form.Feedback errors={Boolean(questionnaireFormik.errors.complianceLines)} >{
                    questionnaireFormik.errors.complianceLines
                        ? questionnaireFormik.errors.complianceLines
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getComplianceTypesMutation.data,
        questionnaireFormik.values.complianceLines,
        questionnaireFormik.errors.complianceLines
    ])

    const setDefaultFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = viewQuestionnaireMutation.data

        if (data) {
            questionnaireFormik.setValues({
                active: data.active,
                name: data.name,
                complianceLines: data.compliance[0].type_id
            })
        }
    }, [
        viewQuestionnaireMutation.data
    ])

    useEffect(() => {
        setDefaultFormData()
    }, [
        viewQuestionnaireMutation.data
    ])

    const unsubscribeViewQuestionnaire = () => {
        const unsubscribeMutation = viewQuestionnaire({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetComplianceTypes = () => {
        const unsubscribeMutation = getComplianceTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeViewQuestionnaire()
        unsubscribeGetComplianceTypes()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let viewQuestionnairePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getComplianceTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && questionnaireID) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    viewQuestionnairePromise = viewQuestionnaire({
                        authToken: newToken,
                        id: questionnaireID
                    })
                    getComplianceTypesPromise = getComplianceTypes({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            viewQuestionnairePromise && viewQuestionnairePromise.abort()
            getComplianceTypesPromise && getComplianceTypesPromise.abort()
        }
    }, [token.valid, questionnaireID])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (viewQuestionnaireMutation.error) {
            console.error(viewQuestionnaireMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [viewQuestionnaireMutation.error])

    useEffect(() => {
        if (getComplianceTypesMutation.error) {
            console.error(getComplianceTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getComplianceTypesMutation.error])

    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={questionnaireFormik.handleSubmit}>
                        {NameInput}
                        {ComplianceTypeInput}
                        {ActiveInput}
                    </Form.Main>
                </div>
            </div>
        </div>
    )
}
export default QuestionnaireView

import { COMPLIANCE_ROUTES } from '@constants/main/routes'
import QuestionnaireMenu from
    '@features/watchdog/virtual-ciso/compliance-questionnaire/QuestionnaireMenu'
import QuestionMenu from
    '@features/watchdog/virtual-ciso/compliance-questionnaire/QuestionMenu'
import QuestionAnswer from
    '@features/watchdog/virtual-ciso/compliance-questionnaire/answer/QuestionAnswer'
const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: COMPLIANCE_ROUTES.MAIN.link,
        component: QuestionnaireMenu
    },
    {
        exact: true,
        path: [
            COMPLIANCE_ROUTES.QUESTIONS.link,
            '/:questionnaireID'
        ].join(''),
        component: QuestionMenu
    },
    {
        exact: true,
        path: [
            COMPLIANCE_ROUTES.ANSWER.link,
            '/:questionnaireID/:customerID'
        ].join(''),
        component: QuestionAnswer
    }
]

export default Routes

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

/**
 * load reducers that retrieve data from localStorage first.
 */

import { loginApi } from '@apis/main/login-api'
import { sidebarApi } from '@apis/main/sidebar-api'
import { tokenApi } from '@apis/main/token-api'
import { profileApi } from '@apis/main/profile-api'
import { partnerApi } from '@apis/watchdog/account-data/partner-api'
import { orderApi } from '@apis/watchdog/account-data/order-api'
import { eventApi } from '@apis/watchdog/soc-data/event-api'
import { filterApi } from '@apis/watchdog/soc-data/filter-api'
import { flowApi } from '@apis/watchdog/soc-data/flow-api'
import { boxApi } from '@apis/watchdog/configuration/box-api'
import { dashboardApi } from '@apis/dashboard/dashboard-api'
import { mdrApi } from '@apis/dashboard/soc/mdr-api'
import { rddApi } from '@apis/dashboard/soc/rdd-api'
import { extVssApi } from '@apis/dashboard/soc/ext-vss-api'
import { vssApi } from '@apis/dashboard/soc/vss-api'
import { o365Api } from '@apis/dashboard/soc/o365-api'
import { azureApi } from '@apis/dashboard/soc/azure-api'
import { wssApi } from '@apis/dashboard/soc/wss-api'
import { ossApi } from '@apis/dashboard/soc/oss-api'
import { pcidssApi } from '@apis/dashboard/grc/pcidss-api'
import { gdprApi } from '@apis/dashboard/grc/gdpr-api'
import { nistApi } from '@apis/dashboard/grc/nist-api'
import { evmApi } from '@apis/dashboard/tac/evm-api'
import { vmmApi } from '@apis/dashboard/tac/vmm-api'
import { immApi } from '@apis/dashboard/tac/imm-api'
import { deviceConfigApi } from '@apis/watchdog/configuration/device-config-api'
import { complianceApi } from '@apis/watchdog/virtual-ciso/compliance-questionnaire-api'
import { questionnaireInfoApi } from
    '@apis/watchdog/virtual-ciso/questionnaire-information-api'
import { dashboardPdfApi } from '@apis/dashboard/pdf-api'

import headerNavReducer from '@slices/main/headerNav'
import tokenReducer from '@slices/main/token'
import settingsReducer from '@slices/main/settings'
import loginReducer from '@slices/main/login'
import sidebarReducer from '@slices/main/sidebar'
import profileReducer from '@slices/main/profile'
import partnerReducer from '@slices/watchdog/account-data/partner'
import orderReducer from '@slices/watchdog/account-data/order/main'
import orderDeviceReducer from '@slices/watchdog/account-data/order/orderDevice'
import eventReducer from '@slices/watchdog/soc-data/event/main'
import eventGroupReducer from '@slices/watchdog/soc-data/event/group'
import eventReportReducer from '@slices/watchdog/soc-data/event/report'
import filterReducer from '@slices/watchdog/soc-data/filter'
import complianceQuestionnaireReducer from
    '@slices/watchdog/virtual-ciso/compliance-questionnaire/questionnaire'
import complianceQuestionReducer from
    '@slices/watchdog/virtual-ciso/compliance-questionnaire/question'
import questionInfoQuestionnaireReducer from
    '@slices/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import questionInfoQuestionReducer from
    '@slices/watchdog/virtual-ciso/questionnaire-information/question'
import flowReducer from '@slices/watchdog/soc-data/flow'
import boxReducer from '@slices/watchdog/configuration/box'
import configMainReducer from '@slices/watchdog/configuration/device-configuration/main'
import configM365Reducer from '@slices/watchdog/configuration/device-configuration/m365'
import configVAPTDetailReducer from
    '@slices/watchdog/configuration/device-configuration/vapt/details'
import configVAPTMainReducer from '@slices/watchdog/configuration/device-configuration/vapt/main'
import configDeviceReducer from '@slices/watchdog/configuration/device-configuration/device-config'
import routerReducer, { routerMiddleware } from '@slices/main/router'

/** dashboard slices */
import grcReducer from '@slices/dashboard/grc'
import socReducer from '@slices/dashboard/soc'
import tacReducer from '@slices/dashboard/tac'
import rddMainReducer from '@slices/dashboard/soc/rdd/main'
import rddDetailsReducer from '@slices/dashboard/soc/rdd/details'
import ossMainReducer from '@slices/dashboard/soc/oss/main'
import ossDetailsReducer from '@slices/dashboard/soc/oss/details'
import mdrMainReducer from '@slices/dashboard/soc/mdr/main'
import mdrDetailsReducer from '@slices/dashboard/soc/mdr/details'
import mdrDataIdReducer from '@slices/dashboard/soc/mdr/dataId'
import mdrDbMainReducer from '@slices/dashboard/soc/mdr/detailedDashboard/main'
import extVssMainReducer from '@slices/dashboard/soc/ext-vss/main'
import extVssVulnDescReducer from '@slices/dashboard/soc/ext-vss/vulnDesc'
import vssMainReducer from '@slices/dashboard/soc/vss/main'
import vssVulnDescReducer from '@slices/dashboard/soc/vss/vulnDesc'
import o365MainReducer from '@slices/dashboard/soc/o365/main'
import o365DetailsReducer from '@slices/dashboard/soc/o365/details'
import o365DataIdReducer from '@slices/dashboard/soc/o365/dataId'
import o365DbMainReducer from '@slices/dashboard/soc/o365/detailedDashboard/main'
import o365DbSharepointReducer from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import o365DbExchangeReducer from '@slices/dashboard/soc/o365/detailedDashboard/exchange'
import azureMainReducer from '@slices/dashboard/soc/azure/main'
import azureDetailsReducer from '@slices/dashboard/soc/azure/details'
import azureDataIdReducer from '@slices/dashboard/soc/azure/dataId'
import azureDbMainReducer from '@slices/dashboard/soc/azure/detailedDashboard/main'
import azureDbKubernetesReducer from '@slices/dashboard/soc/azure/detailedDashboard/kubernetes'
import wssMainReducer from '@slices/dashboard/soc/wss/main'
import wssDescReducer from '@slices/dashboard/soc/wss/wssDesc'
import pcidssMainReducer from '@slices/dashboard/grc/pcidss/main'
import pcidssDetailsReducer from '@slices/dashboard/grc/pcidss/details'
import gdprMainReducer from '@slices/dashboard/grc/gdpr/main'
import gdprDetailsReducer from '@slices/dashboard/grc/gdpr/details'
import nistMainReducer from '@slices/dashboard/grc/nist/main'
import nistDetailsReducer from '@slices/dashboard/grc/nist/details'
import evmReducer from '@slices/dashboard/tac/evm'
import immReducer from '@slices/dashboard/tac/imm'
import vmmReducer from '@slices/dashboard/tac/vmm'
import sectionPrintReducer from '@slices/main/print/section'
import printReportsReducer from '@slices/main/print/reportsMenu/main'
import printReportDetailsReducer from '@slices/main/print/reportsMenu/details'
import printQueueReducer from '@slices/main/print/queue'
// global state is initialized here. Store is what is called here in redux terminology.
export const store = configureStore({
    reducer: {
        headerNav: headerNavReducer,
        token: tokenReducer,
        settings: settingsReducer,
        login: loginReducer,
        sidebar: sidebarReducer,
        profile: profileReducer,
        partner: partnerReducer,
        order: orderReducer,
        orderDevice: orderDeviceReducer,
        event: eventReducer,
        eventGroup: eventGroupReducer,
        eventReport: eventReportReducer,
        filter: filterReducer,
        flow: flowReducer,
        box: boxReducer,
        configMain: configMainReducer,
        configM365: configM365Reducer,
        configVAPTDetail: configVAPTDetailReducer,
        configVAPTMain: configVAPTMainReducer,
        configDevice: configDeviceReducer,
        grc: grcReducer,
        soc: socReducer,
        tac: tacReducer,
        rddMain: rddMainReducer,
        rddDetails: rddDetailsReducer,
        ossMain: ossMainReducer,
        ossDetails: ossDetailsReducer,
        mdrMain: mdrMainReducer,
        mdrDetails: mdrDetailsReducer,
        mdrDataId: mdrDataIdReducer,
        mdrDbMain: mdrDbMainReducer,
        extVssMain: extVssMainReducer,
        extVssVulnDesc: extVssVulnDescReducer,
        vssMain: vssMainReducer,
        vssVulnDesc: vssVulnDescReducer,
        o365Main: o365MainReducer,
        o365Details: o365DetailsReducer,
        o365DataId: o365DataIdReducer,
        o365DbMain: o365DbMainReducer,
        o365DbSharepoint: o365DbSharepointReducer,
        o365DbExchange: o365DbExchangeReducer,
        azureMain: azureMainReducer,
        azureDetails: azureDetailsReducer,
        azureDataId: azureDataIdReducer,
        azureDbMain: azureDbMainReducer,
        azureDbKubernetes: azureDbKubernetesReducer,
        wssMain: wssMainReducer,
        wssDesc: wssDescReducer,
        pcidssMain: pcidssMainReducer,
        pcidssDetails: pcidssDetailsReducer,
        gdprMain: gdprMainReducer,
        gdprDetails: gdprDetailsReducer,
        nistMain: nistMainReducer,
        nistDetails: nistDetailsReducer,
        evm: evmReducer,
        imm: immReducer,
        vmm: vmmReducer,
        sectionPrint: sectionPrintReducer,
        printReports: printReportsReducer,
        printReportDetails: printReportDetailsReducer,
        printQueue: printQueueReducer,
        complianceQuestionnaire: complianceQuestionnaireReducer,
        complianceQuestion: complianceQuestionReducer,
        questionInfoQuestionnaire: questionInfoQuestionnaireReducer,
        questionInfoQuestion: questionInfoQuestionReducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [sidebarApi.reducerPath]: sidebarApi.reducer,
        [tokenApi.reducerPath]: tokenApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [partnerApi.reducerPath]: partnerApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [eventApi.reducerPath]: eventApi.reducer,
        [filterApi.reducerPath]: filterApi.reducer,
        [flowApi.reducerPath]: flowApi.reducer,
        [boxApi.reducerPath]: boxApi.reducer,
        [deviceConfigApi.reducerPath]: deviceConfigApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [mdrApi.reducerPath]: mdrApi.reducer,
        [rddApi.reducerPath]: rddApi.reducer,
        [ossApi.reducerPath]: ossApi.reducer,
        [extVssApi.reducerPath]: extVssApi.reducer,
        [vssApi.reducerPath]: vssApi.reducer,
        [o365Api.reducerPath]: o365Api.reducer,
        [azureApi.reducerPath]: azureApi.reducer,
        [azureApi.reducerPath]: azureApi.reducer,
        [wssApi.reducerPath]: wssApi.reducer,
        [pcidssApi.reducerPath]: pcidssApi.reducer,
        [gdprApi.reducerPath]: gdprApi.reducer,
        [nistApi.reducerPath]: nistApi.reducer,
        [evmApi.reducerPath]: evmApi.reducer,
        [immApi.reducerPath]: immApi.reducer,
        [vmmApi.reducerPath]: vmmApi.reducer,
        [dashboardPdfApi.reducerPath]: dashboardPdfApi.reducer,
        [complianceApi.reducerPath]: complianceApi.reducer,
        [questionnaireInfoApi.reducerPath]: questionnaireInfoApi.reducer,
        router: routerReducer
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(
            // you could just use MiddlewareArray but the default
            // one includes Immutability check middleware,
            // Serializability check middleware and redux-thunk
            loginApi.middleware,
            sidebarApi.middleware,
            tokenApi.middleware,
            profileApi.middleware,
            partnerApi.middleware,
            orderApi.middleware,
            eventApi.middleware,
            filterApi.middleware,
            flowApi.middleware,
            boxApi.middleware,
            deviceConfigApi.middleware,
            dashboardApi.middleware,
            mdrApi.middleware,
            rddApi.middleware,
            ossApi.middleware,
            extVssApi.middleware,
            vssApi.middleware,
            o365Api.middleware,
            azureApi.middleware,
            wssApi.middleware,
            pcidssApi.middleware,
            gdprApi.middleware,
            nistApi.middleware,
            evmApi.middleware,
            immApi.middleware,
            vmmApi.middleware,
            dashboardPdfApi.middleware,
            complianceApi.middleware,
            questionnaireInfoApi.middleware,
            routerMiddleware
        )
    }
})

setupListeners(store.dispatch)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {history: historyState, token: tokenState, login: loginState}
export type AppDispatch = typeof store.dispatch

import { RootState } from '@app/store'
import { tryParseJSON } from '@constants/main/method'
import {
    DecodedJWT,
    Roletype,
    TokenData,
    TokenDetails,
    TokenState
} from '@interfaces/main/token'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

/**
 * An object api validation and access to components
 * NOTE: setting in the localStorage should be done first before updating the state in the store.
 * any operations in between can be performed.
 *
 * 1.) The initial value is a string from localStorage
 * but with a valid boolean value of false to encourage revalidation when the root component mounts.
 * Technically, we can't tell if the current token is valid or not as arbritary declartion of
 * true or false is assigned to the localStorage('token').
 *
 * 2.) To let other api calls use revalidated tokens, do an await on the tokenValid call and pass it
 * to the api as as a parameter to maintain respect to the react flow
 *  and avoid cheating via debounce.
 *
 */

/**
 * generic function declaration below.
 */

/**
 * this will replace the old Auth.js where it originally had functions but not the token itself.
 * the slice here will have both the token state and any functions involving it.
 * this should be able to be called in api files. also will not have static typing
 */

const getFromLocalStorage : () => TokenData = () => {
    /** Coming from print pdf operations using sessionStorage instead of localStorage.
     * If localStorage token is undefined, get it from sessionStorage otherwise
     * initialize an empty string.
     */
    const tokenStr = localStorage.getItem('token') || sessionStorage.getItem('token') || ''

    return {
        value: tokenStr,
        valid: false,
        details: {
            sub: '',
            iat: 0,
            exp: 0,
            mfa: false,
            aid: []
        }
    }
}

const initialState: TokenState = {
    data: getFromLocalStorage(),
    showIdle: false,
    showExpire: false
}

export const slice = createSlice({
    name: 'token',
    initialState: initialState,
    reducers: {
        /**
         * setter for token property. also sets data in
         * localStorage.
         *  */
        setToken: (state: TokenState, action: PayloadAction<Omit<TokenData, 'details' >>) => {
            state.data.value = action.payload.value
            state.data.valid = action.payload.valid
            /** before setting the aid property, we should decode the string
             * and parse the aid string as an array of objects. we still need to
             * check.
            */
            const tokenDetails = jwtDecode(action.payload.value) as DecodedJWT
            const roleTypes = tryParseJSON(tokenDetails.aid)

            const details = {
                sub: tokenDetails.sub,
                iat: tokenDetails.iat,
                exp: tokenDetails.exp,
                mfa: tokenDetails.mfa,
                aid: []
            } as TokenDetails

            /** checking if it's an empty object from tryParseJSON as a falsy return
             * is an array.
             */

            if (_.isArray(roleTypes)) {
                details.aid = roleTypes as Roletype[]
            }
            state.data.details = details

            localStorage.setItem('token', action.payload.value)
        },

        /** individual setter for idle and expire booleans */
        setShowIdle: (state: TokenState, action: PayloadAction<boolean>) => {
            state.showIdle = action.payload
        },
        setShowExpire: (state: TokenState, action: PayloadAction<boolean>) => {
            state.showExpire = action.payload
        },
        /**
         * reset token state and empties localStorage token property.
         *  */
        resetToken: (state: TokenState) => {
            state.data.value = ''
            state.data.valid = false
            localStorage.removeItem('token')

            /** setting token data is separate from idle and expire properties */
        }
    }
})

export const {
    setToken,
    setShowIdle,
    setShowExpire,
    resetToken
} = slice.actions

export const selectToken = (state: RootState) => state.token.data
export const selectShowIdle = (state: RootState) => state.token.showIdle
export const selectShowExpire = (state: RootState) => state.token.showExpire

export default slice.reducer

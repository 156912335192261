import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'
import {
    ChangePasswordValues,
    LoginValues,
    MFAValues,
    ResetPasswordValues
} from '@interfaces/main/login'
import * as Yup from 'yup'

export const MESSAGE = {
}

/** BEM convention for using ids for labels and input fields */
/** change form format is LABEL: '', ID: '' */
export const TEXT = {
    FORCE_PASSWORD: {
        TITLE: 'Reset your Password',
        MESSAGE: 'Your password has expired. Please change it.',
        FORM: {
            OLD_PASSWORD: {
                LABEL: 'Old Password',
                ID: 'FORCE_PASSWORD__OLD_PASSWORD'
            },
            NEW_PASSWORD: {
                LABEL: 'New Password',
                ID: 'FORCE_PASSWORD__NEW_PASSWORD'
            },
            RETYPE_PASSWORD: {
                LABEL: 'Retype Password',
                ID: 'FORCE_PASSWORD__RETYPE_PASSWORD'
            },
            /** submit buttons are also part of the form */
            LOADING_BUTTON: 'Changing',
            SUBMIT_BUTTON: 'Change Password'
        }
    },
    LOGIN: {
        TITLE: 'Reset your Password',
        MESSAGE: 'Sign in to your account',
        FORM: {
            EMAIL: {
                LABEL: 'Email',
                ID: 'LOGIN__EMAIL'
            },
            PASSWORD: {
                LABEL: 'Password',
                ID: 'LOGIN__PASSWORD'
            },
            LOADING_BUTTON: 'Logging In',
            SUBMIT_BUTTON: 'Log In'
        },
        WELCOME: 'Welcome Back'
    },
    RESET_PASSWORD: {
        TITLE: 'Reset your Password',
        FORM: {
            EMAIL: {
                LABEL: 'Email',
                ID: 'RESET_PASSWORD__EMAIL'
            },
            LOADING_BUTTON: 'Resetting',
            SUBMIT_BUTTON: 'Reset Password'
        }
    },
    MFA: {
        TITLE: 'Two-step verification',
        MESSAGES: [
            'Thanks for keeping your account secure',
            'Check your Google Authenticator application'
        ],
        FORM: {
            PIN_CODE: {
                LABEL: 'Pin Code',
                ID: 'MFA__PIN_CODE'
            },
            LOADING_BUTTON: 'Validating',
            SUBMIT_BUTTON: 'Sign In Securely',
            CANCEL_BUTTON: 'Cancel'
        }
    }
}

/** FORMIK CONSTANTS */
/** for components after initialization of slices, declare selectors at the start. */

/**
 * design choice was made with regards to state vs formik values. Developer does
 * not personally like duplicate data properties existing in the system. Therefore,
 * keep form validation and states separate. In this example, we removed
 * the email and password fields from its respective slice.
 * But because the formik will only be initialized when the page it is called
 * mounts, data will be lost when unmounted unlike the state from redux.
 * Can benefit from hiding important information like emails, passwords,
 * credit card info and other kinds of account data.  plus we can give a warning
 * modal that data will be lost if the component is unmounted. as for fetching
 * information in update interfaces, APIs will be called every time to cater
 * to multiple pcs in the event that they are updating the same data row at once.

* form syntax for formik. just to make sure that the type format is correct.
* one more thing, even if the required syntax for the body argument is similar
* to this, retain them as separate pieces.
* */

export const LOGIN_FORMIK_INITIAL_VALUES: LoginValues = {
    email: '',
    password: ''
}

export const LOGIN_VALIDATION_SCHEMA = Yup.object({
    email: Yup.string()
        .email(ROOT_MESSAGE.ERROR.FORM.EMAIL)
        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    password: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
})

export const MFA_FORMIK_INITIAL_VALUES: MFAValues = {
    mfaCode: ''
}

export const MFA_VALIDATION_SCHEMA = Yup.object({
    mfaCode: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
})

export const CHANGE_PASSWORD_FORMIK_INITIAL_VALUES: ChangePasswordValues = {
    oldPassword: '',
    newPassword: '',
    retypePassword: ''
}

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = Yup.object({
    oldPassword: Yup.string()
        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    newPassword: Yup.string()
        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    retypePassword: Yup
        .string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
        .oneOf([Yup.ref('newPassword')], ROOT_MESSAGE.ERROR.FORM.PASSWORD_MATCH)
})

export const RESET_PASSWORD_FORMIK_INITIAL_VALUES: ResetPasswordValues = {
    email: ''
}

export const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object({
    email: Yup.string()
        .email(ROOT_MESSAGE.ERROR.FORM.EMAIL)
        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
})

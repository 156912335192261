import { RootState } from '@app/store'
import { DEFAULT_TABLE_DATA } from '@constants/main/root'
import {
    SORT_OPTIONS,
    ARRANGE_OPTIONS
} from '@constants/dashboard/monitor'
import {
    ArrangeOption,
    MonitorModal,
    Card,
    MonitorState,
    SortOption,
    DetailedCard
} from '@interfaces/dashboard/monitor'
import {
    ColorPresets,
    RefreshTime
} from '@interfaces/main/root'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState : MonitorState = {
    tableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        arrange: ARRANGE_OPTIONS[0],
        sort: SORT_OPTIONS[0],
        hideGrey: false,
        statcards: {
            filtered: [],
            detailed: []
        },
        detailedStatcards: {
            filtered: [],
            paginated: []
        }
    },
    /** should still navigate to other event modules */
    modals: []

}

export const slice = createSlice({
    name: 'tac',
    initialState: initialState,
    reducers: {
        setArrange: (state: MonitorState, action: PayloadAction<ArrangeOption>) => {
            state.tableData.arrange = action.payload
        },
        setSort: (state: MonitorState, action: PayloadAction<SortOption>) => {
            state.tableData.sort = action.payload
        },
        toggleHideGrey: (state: MonitorState, action: PayloadAction<boolean>) => {
            state.tableData.hideGrey = action.payload
        },
        setPage: (state: MonitorState, action: PayloadAction<number>) => {
            state.tableData.page = action.payload
        },
        setCount: (state: MonitorState, action: PayloadAction<number>) => {
            state.tableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: MonitorState, action: PayloadAction<RefreshTime>) => {
            state.tableData.interval = action.payload
        },
        setSearch: (state: MonitorState, action: PayloadAction<string>) => {
            state.tableData.search = action.payload
        },
        setRefetch: (state: MonitorState, action: PayloadAction<boolean>) => {
            state.tableData.refetch = action.payload
        },
        setFilteredStatcards: (state: MonitorState, action: PayloadAction<Card[]>) => {
            state.tableData.statcards.filtered = action.payload
        },
        pushDetailedCard: (state: MonitorState, action: PayloadAction<DetailedCard>) => {
            state.tableData.statcards.detailed.push(action.payload)
        },
        setFilteredDetailedStatcards: (
            state: MonitorState,
            action: PayloadAction<DetailedCard[]>
        ) => {
            state.tableData.detailedStatcards.filtered = action.payload
        },
        setPaginatedDetailedStatcards: (
            state: MonitorState,
            action: PayloadAction<DetailedCard[]>
        ) => {
            state.tableData.detailedStatcards.paginated = action.payload
        },
        addModal: (state: MonitorState, action: PayloadAction<MonitorModal>) => {
            state.modals.push(action.payload)
        },
        closeModal: (state: MonitorState, action: PayloadAction<MonitorModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.open = false
            }
        },
        removeModal: (state: MonitorState, action: PayloadAction<MonitorModal>) => {
            _.remove(state.modals, modal => {
                return (
                    modal.id === action.payload.id
                )
            })
        },
        /** don't forget to change the modal color if you can. Was made because
         * of service type api calls can be a different color from the statcard.
         * Was seen at ext-vss.
         */
        changeModalColor: (state: MonitorState, action: PayloadAction<{
            modal: MonitorModal,
            colorType: ColorPresets
        }>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.modal.id
            )
            if (modal) {
                modal.card.details.colorType = action.payload.colorType
            }
        },
        resetTac: (state: MonitorState) => {
            state.modals = initialState.modals
            // reset all table data EXCEPT for statcards AND detailedStatcards
            state.tableData = {
                ...initialState.tableData,
                statcards: state.tableData.statcards,
                detailedStatcards: state.tableData.detailedStatcards
            }
        }
    }
})

export const {
    setArrange,
    setSort,
    toggleHideGrey,
    setFilteredStatcards,
    pushDetailedCard,
    setFilteredDetailedStatcards,
    setPaginatedDetailedStatcards,
    setPage,
    setCount,
    setIntervalObj,
    setSearch,
    setRefetch,
    addModal,
    closeModal,
    removeModal,
    changeModalColor,
    resetTac
} = slice.actions

export const selectTableData = (state: RootState) => state.tac.tableData
export const selectModals = (state: RootState) => state.tac.modals

export default slice.reducer


import {
    useViewQuestionnaireMutation,
    useGetComplianceTypesMutation
} from '@apis/watchdog/virtual-ciso/questionnaire-information-api'
import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    VALIDATION_SCHEMA,
    TEXT as QUESTIONNAIRE_TEXT
} from '@constants/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import MenuLinks from '@features/main/MenuLinks'
import UpdateConfirmation from
    // eslint-disable-next-line max-len
    '@features/watchdog/virtual-ciso/questionnaire-information/questionnaire/update/UpdateConfirmation'
import { LabelValuePair } from '@interfaces/main/root'
import {
    UpdateQuestionnaireKeys,
    UpdateQuestionnaireValues,
    QuestionnaireFormData,
    QuestionnaireParams
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    selectTabs,
    selectModals,
    addModal,
    closeModal,
    removeModal
} from '@slices/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    FormStyledComponents as Form,
    Button
} from '@styles/components'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

const QuestionnaireUpdate = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect
    const dispatch = useAppDispatch()

    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    const { questionnaireID } = useParams<QuestionnaireParams>()

    /** fetch other calls. */
    const [viewQuestionnaire, viewQuestionnaireMutation] = useViewQuestionnaireMutation()
    const [
        getComplianceTypes,
        getComplianceTypesMutation
    ] = useGetComplianceTypesMutation()

    const questionnaireFormik = useFormik({
        initialValues: INITIAL_VALUES.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.UPDATE,
        onSubmit: () => {
            if (questionnaireID) {
                const formData: QuestionnaireFormData = {
                    updateConfirm: {
                        formValues: questionnaireFormik.values,
                        id: questionnaireID
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'QUESTIONNAIRE_INFO_EDIT',
                    formData: formData,
                    isBorderWide: false
                }))
            }
        }

    })

    const strictSetValue = useCallback((
        label: UpdateQuestionnaireKeys,
        value: UpdateQuestionnaireValues[UpdateQuestionnaireKeys]
    ) => {
        questionnaireFormik.setFieldValue(
            label,
            value
        )
    }, [])

    // 5d9731e27f689b7e5bcae003 is partner id edit just to check.
    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.ID}
                    onChange={questionnaireFormik.handleChange}
                    checked={questionnaireFormik.values.active}
                />
                <Form.Feedback
                    errors={Boolean(questionnaireFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        questionnaireFormik.errors.active ? questionnaireFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [questionnaireFormik.values.active, questionnaireFormik.errors.active])

    const NameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(questionnaireFormik.errors.name)}
                    name={'name'}
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.ID}
                    onChange={questionnaireFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('name', e.target.value.trim())
                    }}
                    value={questionnaireFormik.values.name}
                />
                <Form.Feedback errors={Boolean(questionnaireFormik.errors.name)} >{
                    questionnaireFormik.errors.name ? questionnaireFormik.errors.name : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [questionnaireFormik.values.name, questionnaireFormik.errors.name])

    const ComplianceTypeInput = useMemo(() => {
        let arr = [] as LabelValuePair[]

        if (getComplianceTypesMutation.data) {
            arr = getComplianceTypesMutation.data.cTypes
        }

        const fieldValue: UpdateQuestionnaireKeys = 'complianceLines'

        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.LABEL}
                </Form.Label>
                <Select
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.ID}
                    errors={Boolean(questionnaireFormik.errors.complianceLines)}
                    options={arr}
                    value={_.find(
                        arr,
                        (e) => e.value === questionnaireFormik.values.complianceLines
                    )}
                    onChange={(e) => {
                        if (e) {
                            questionnaireFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(questionnaireFormik.errors.complianceLines)} >{
                    questionnaireFormik.errors.complianceLines
                        ? questionnaireFormik.errors.complianceLines
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getComplianceTypesMutation.data,
        questionnaireFormik.values.complianceLines,
        questionnaireFormik.errors.complianceLines
    ])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                    >{QUESTIONNAIRE_TEXT.UPDATE.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={() => {
                            questionnaireFormik.resetForm()
                        }}
                    >{TEXT.FORM.RESET}</Button>
                </div>

            </Form.Group>
        )
    }, undefined)

    const setDefaultFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = viewQuestionnaireMutation.data

        if (data) {
            questionnaireFormik.setValues({
                active: data.active,
                name: data.name,
                complianceLines: data.compliance[0].type_id
            })
        }
    }, [
        viewQuestionnaireMutation.data
    ])

    useEffect(() => {
        setDefaultFormData()
    }, [
        viewQuestionnaireMutation.data
    ])

    const unsubscribeViewQuestionnaire = () => {
        const unsubscribeMutation = viewQuestionnaire({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const unsubscribeGetComplianceTypes = () => {
        const unsubscribeMutation = getComplianceTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeViewQuestionnaire()
        unsubscribeGetComplianceTypes()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let viewQuestionnairePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getComplianceTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && questionnaireID) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    viewQuestionnairePromise = viewQuestionnaire({
                        authToken: newToken,
                        id: questionnaireID
                    })
                    getComplianceTypesPromise = getComplianceTypes({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            viewQuestionnairePromise && viewQuestionnairePromise.abort()
            getComplianceTypesPromise && getComplianceTypesPromise.abort()
        }
    }, [token.valid])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (viewQuestionnaireMutation.error) {
            console.error(viewQuestionnaireMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [viewQuestionnaireMutation.error])

    useEffect(() => {
        if (getComplianceTypesMutation.error) {
            console.error(getComplianceTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getComplianceTypesMutation.error])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'QUESTIONNAIRE_INFO_EDIT') {
                    component = <UpdateConfirmation
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={questionnaireFormik.handleSubmit}>
                        {NameInput}
                        {ComplianceTypeInput}
                        {ActiveInput}
                        {DetailsFormButtons}
                    </Form.Main>
                </div>
            </div>
            {renderModals}
        </div>
    )
}
export default QuestionnaireUpdate

import {
    useDoFileUploadMutation,
    useDoReportAnswerMutation,
    useGetReportDataMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { tryParseJSON } from '@constants/main/method'
import { DEFAULT_QUEUE } from '@constants/main/print'
import {
    ACTION_MUTATION_PROMISE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    EVENT_STATE_GROUPINGS,
    INITIAL_VALUES,
    MESSAGE as EVENT_MESSAGE,
    TEXT as EVENT_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/soc-data/event'
import DoFinalizeReview from '@features/watchdog/soc-data/reports/DoFinalizeReview'
import DoRequestReportReview from '@features/watchdog/soc-data/reports/DoRequestReportReview'
import DoReviewReviewed from '@features/watchdog/soc-data/reports/DoReviewReviewed'
import AnswerHistory from '@features/watchdog/soc-data/reports/questionTypes/AnswerHistory'
import Bullet from '@features/watchdog/soc-data/reports/questionTypes/Bullet'
import Checkbox from '@features/watchdog/soc-data/reports/questionTypes/Checkbox'
import FileHistory from '@features/watchdog/soc-data/reports/questionTypes/FileHistory'
import FileUpload from '@features/watchdog/soc-data/reports/questionTypes/FileUpload'
import Open from '@features/watchdog/soc-data/reports/questionTypes/Open'
import Predefined from '@features/watchdog/soc-data/reports/questionTypes/Predefined'
import Radio from '@features/watchdog/soc-data/reports/questionTypes/Radio'
import ReviewNotesHistory from
    '@features/watchdog/soc-data/reports/questionTypes/ReviewNotesHistory'
import {
    AnswerReportValues,
    DecodedReportParam,
    ReportData,
    ReportFormData,
    ReportParams,
    ReportStepInput
} from '@interfaces/watchdog/soc-data/event'
import { MutationContext } from '@root/MutationProvider'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { selectToken } from '@slices/main/token'
import {
    addModal,
    closeModal,
    removeModal,
    selectModals
} from '@slices/watchdog/soc-data/event/report'
import {
    Button,
    Container,
    FormStyledComponents as Form,
    ReportStepNavigation,
    ReportStepTab,
    Text
} from '@styles/components'
import deepEqual from 'deep-equal'
import {
    FormikProps,
    useFormik
} from 'formik'
import { Base64 } from 'js-base64'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

const ReportInterface = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** each reportStep can have a fileIds property defined or not so it would need to be
     * an array of objects with the fileIds property containing HTMLInputElement[]
     */
    const fileInputRefs = useRef<{
        reportSection: number,
        fileIds: HTMLInputElement[]
    }[]>([])

    const { reportObj } = useParams<ReportParams>()

    const [currentReportSection, setCurrentReportSection] = useState<number>(1.0)

    const [getReportData, getReportDataMutation] = useGetReportDataMutation()
    const [doReportAnswer] = useDoReportAnswerMutation()
    const [doFileUpload] = useDoFileUploadMutation()

    /** we want to monitor ALL the report steps. We want to validate each element of the array
     * individually. Unfortunately, the formik.validateField method returns void AND the yup
     * validate method has a limited scope to display error messages on a callback function.
     *
     * We have to use formik to track the entire array of reportSteps. The challenge is that
     * this is a dynamic form.
     *
     * Make sure you create a copy of the reportSteps AND replace the appropriate values
     * with the appropriate data from the latest timestamp. Like the edit modules,
     * the scope of this data is only in this component.
     *
     * UPDATE: November 25, 2022. Unfortunately, you cannot have an array as the first level
     * in the formik initialization. Create an object with that as a value of a key instead.
    */

    const reportStepsFormik: FormikProps<AnswerReportValues> =
    useFormik<AnswerReportValues>({
        initialValues: INITIAL_VALUES.REPORT_ANSWER,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.REPORT_ANSWER,
        onSubmit: () => {}
    })

    /** redirect users after the 4 operations above to the created reports route */

    /** fetch report data */
    const unsubscribeGetReportData = () => {
        const unsubscribeMutation = getReportData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = useCallback(() => {
        unsubscribeGetReportData()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getReportData({
                        authToken: newToken,
                        reportId: decodedObj.id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }, [token.valid, reportObj])

    useEffect(() => {
        return fetchData()
    }, [token.valid, reportObj])

    /** handler to run  */

    /** after the response, set the report data to the reportSteps in your reducer */
    useEffect(() => {
        const data = getReportDataMutation.data

        if (data) {
            // step one: create reportSteps.
            // step two: iterate through each of the reportData and look for the
            // latest record of the reportStep if there is any.

            const reportData = getReportDataMutation.data?.reportData || []

            const result: ReportStepInput[] = _.map(data.reportSteps, (obj) => {
                /** initial values before modifiying properties */

                /** assign inputId values based on reportAnswerType */

                let inputAnswers: string[] = []
                let inputCollapsibles: boolean[] = []
                let inputIds: string[] = []
                let inputReviewNotes: string[] = []

                const latestData: ReportData | undefined = _.maxBy(_.filter(
                    _.cloneDeep(reportData),
                    (o) => { return o.reportSection === obj.reportSection }
                ), (o) => o.answerTimestamp)

                switch (obj.reportAnswerType) {
                    case 'predefined':

                        inputAnswers = inputReviewNotes = Array(
                            obj.reportPredefinedQuestion.length
                        ).fill('')
                        inputCollapsibles = Array(
                            obj.reportPredefinedQuestion.length
                        ).fill(true)

                        inputIds = _.map(obj.reportPredefinedQuestion, () => {
                            return uniqueString()
                        })
                        break

                    case 'open':
                    case 'radio':
                        inputAnswers = inputReviewNotes = Array(1).fill('')
                        inputCollapsibles = Array(1).fill(true)
                        inputIds = [uniqueString()]
                        break
                    case 'checkbox':
                        /** multiple answers but only have one error and review note */
                        inputReviewNotes = Array(1).fill('')
                        inputAnswers = []
                        inputCollapsibles = Array(1).fill(true)
                        inputIds = [uniqueString()]
                        break
                    case 'bullet':
                        /** get values from latest report Data instead. */
                        break
                    default:
                        break
                }

                const result: ReportStepInput = {
                    reportSection: obj.reportSection,
                    fileIds: [],
                    reportStepInput: {
                        answerGiven: inputAnswers,
                        collapsible: inputCollapsibles,
                        id: inputIds,
                        reviewNote: inputReviewNotes
                    }
                }

                if (latestData) {
                    result.fileIds = _.map(latestData.fileIds, (o) => o.fileId)

                    result.reportStepInput.answerGiven = JSON.parse(
                        Base64.decode(latestData.answerGiven)
                    ) as unknown as string[]

                    result.reportStepInput.reviewNote = JSON.parse(
                        Base64.decode(latestData.reviewNotes)
                    ) as unknown as string[]

                    if (obj.reportAnswerType === 'bullet') {
                        result.reportStepInput.collapsible = Array(
                            result.reportStepInput.answerGiven.length
                        ).fill(true)

                        result.reportStepInput.id = _.map(
                            result.reportStepInput.answerGiven, () => {
                                return uniqueString()
                            })
                    }
                }

                return result
            })

            reportStepsFormik.setValues({
                reportSteps: result,
                submissionMode: 0
            })

            setCurrentReportSection(result[0].reportSection)

            /** after setting the report steps, in a separate useEffect, you want to set
             * the reportData.
             */
        }
    }, [
        getReportDataMutation.data
    ])

    /** handler to check for any changes with the reportSection */

    const checkForChanges = useCallback((value: ReportStepInput) => {
        const reportData = getReportDataMutation.data?.reportData || []
        const reportSteps = getReportDataMutation.data?.reportSteps || []

        let result:boolean = false

        /** let's get the other information of the active report step */
        const foundInfo = _.find(reportSteps,
            (o) => o.reportSection === value.reportSection
        )

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        // console.log(foundInfo)
        // console.log(decodedObj)

        // step 1: get the report step with the latest timestamp
        // AND matching the report section. Make sure you get the first 12 digits
        // to properly parse the timestamp.

        if (foundInfo && decodedObj) {
            const latestData: ReportData | undefined = _.maxBy(_.filter(reportData,
                (o) => { return o.reportSection === foundInfo.reportSection }
            ), (o) => o.answerTimestamp)

            if (latestData) {
                const answerGiven = JSON.parse(
                    Base64.decode(latestData.answerGiven)
                ) as unknown as string[]

                const reviewNotes = JSON.parse(
                    Base64.decode(latestData.reviewNotes)
                ) as unknown as string[]

                /** we want to AVOID submitting answers that haven't changed. */

                /** NOTE: when submitting an answer, you'd only do this based
                 * on the event state. conditionOne requires either 0 or 6, conditionThree requires
                 * the filesAllowed prop to be truthy and conditionTwo needs either 5 or 7.
                 */

                /** check if the value of answerGiven is
                 * DEEP equal to the latest report step. */
                const conditionOne = _.includes([0, 6], decodedObj.eventState) && !(deepEqual(
                    value.reportStepInput.answerGiven, answerGiven
                ))

                /** checks for reviewNotes */
                const conditionTwo = _.includes([5, 7], decodedObj.eventState) && !(deepEqual(
                    value.reportStepInput.reviewNote, reviewNotes
                ))

                /** checks the reportStep if the filesAllowed property is truthy.
                 * and also checks for any filelist modifications. Make sure to
                 * filter falsy values during inspection.
                 */

                const conditionThree = _.includes([0, 6], decodedObj.eventState) &&
                foundInfo.filesAllowed && !(deepEqual(
                    _.filter(value.fileIds, (o) => o),
                    _.filter(
                        _.map(latestData.fileIds, (o) => o.fileId),
                        (o) => o
                    )
                ))

                if (conditionOne || conditionTwo || conditionThree) {
                    /** now you can allow the user to do whatever. */
                    result = true
                }
            } else {
                // no latest data record means that it's a fresh new reportstep.
                // you can return true.

                /**
                 * LOOPHOLE DETECTED:
                 * this statement will be met over and over again if the reportData
                 * doesn't refresh after sending an answer. Make sure that after
                 * every reportAnswer call, refresh the data.
                 */
                result = true
            }
        } else {
            // show a toast error that the report step to find is missing.
            toast.error(
                EVENT_MESSAGE.NO_REPORT_STEP,
                { ...TOASTIFY_DEFAULT_OPTIONS }
            )
        }

        return result
    }, [
        getReportDataMutation.data
    ])

    /** handler to initiate submitAnswer. Parameter should be the active report step.
     * use the reportSection to find the values from the formik. Should only be used
     * individually and not on batch.
     */
    const submitAnswer = useCallback(async (
        values: ReportStepInput
    ) => {
        const newToken = await revalidateToken()

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        const reportSteps = getReportDataMutation.data?.reportSteps || []

        const foundReportStep = _.find(reportSteps, (obj) => {
            return obj.reportSection === values.reportSection
        })

        if (decodedObj && foundReportStep) {
            doReportAnswer({
                /** id for the entire report. you can only get this from the url params. */
                reportId: decodedObj.id,
                /** should be the selected report step. */
                reportSection: foundReportStep.reportSection,
                reportQuestion: foundReportStep.reportQuestion,
                reportStep: foundReportStep.reportStep,
                /** make sure you are submitting the formik values. */
                fileIds: JSON.stringify(values.fileIds),
                reportAnswer: Base64.encode(
                    JSON.stringify(values.reportStepInput.answerGiven)
                ),
                reviewNotes: Base64.encode(
                    JSON.stringify(values.reportStepInput.reviewNote)
                ),
                authToken: newToken
            }).unwrap().then((data) => {
                if (data.message) {
                    toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    /** make sure to call this so the responses reportData will be lengthy. */
                    fetchData()
                } else {
                    toast.error(
                        EVENT_MESSAGE.FAULTY_CALL,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                }
            })
        }
    }, [])

    // useEffect(() => {
    //     if (doReportAnswerMutation.data) {
    //         const data = doReportAnswerMutation.data
    //         if (data.message) {
    //             toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
    //         } else {
    //             toast.error(
    //                 EVENT_MESSAGE.FAULTY_CALL,
    //                 { ...TOASTIFY_DEFAULT_OPTIONS }
    //             )
    //         }
    //     }
    // }, [
    //     doReportAnswerMutation.data
    // ])

    /** handler to submit all answers. This is assuming the form values have been validated. */

    const submitAllAnswers = useCallback(async () => {
        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        const reportSteps = getReportDataMutation.data?.reportSteps || []

        const newToken = await revalidateToken()

        await Promise.all(
            _.map(reportStepsFormik.values.reportSteps, async (reportStep) => {
                const pass = checkForChanges(reportStep)

                const foundReportStep = _.find(
                    reportSteps, o => o.reportSection === reportStep.reportSection
                )

                if (pass && foundReportStep) {
                    /** BUG: lots of toast success messages
                     * wil show since you are submitting all the answers
                     * at once. useEffect has been removed and a unwrap().then()
                     * invocation takes its place.
                     */

                    const promise = doReportAnswer({
                        reportId: decodedObj.id,
                        /** should be the selected report step. */
                        reportSection: foundReportStep.reportSection,
                        reportQuestion: foundReportStep.reportQuestion,
                        reportStep: foundReportStep.reportStep,
                        /** make sure you are submitting the formik values. */
                        fileIds: JSON.stringify(reportStep.fileIds),
                        reportAnswer: Base64.encode(
                            JSON.stringify(reportStep.reportStepInput.answerGiven)
                        ),
                        reviewNotes: Base64.encode(
                            JSON.stringify(reportStep.reportStepInput.reviewNote)
                        ),
                        authToken: newToken
                    })

                    /** no need to unwrap the promise or show a response. We don't want
                     * to be spammed success toast messages.
                     */

                    return promise
                }
            })
        )
        /** make sure to call this so the responses reportData will be lengthy. You only
         * need to do this once after making multiple reportAnswer calls.
         */
        fetchData()
    }, [
        reportObj,
        getReportDataMutation.data,
        reportStepsFormik.values
    ])

    /** FOR ALL HANDLERS THAT ADD A MODAL WITH A PRECONDITION TO SUBMIT ANSWERS,
     * MAKE SURE THAT THOSE BUTTONS ALSO STAY DISABLED WHEN THOSE CALLS ARE LOADING.
     */

    const setToRequestReportReview = useCallback(async () => {
        /** iterates the report steps in parallel and performs the following tasks:
         * checks if EITHER the answers given in the form ARE NOT EQUAL
         * to that when the data was retrieved. This is to avoid making
         * the reportAnswer call if no changes were made.
         */

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        /** let's be aware of this line. When you close the modal and click
         * on the button that invokes this method again, it should be expected
         * that no answers will be submitted again because all answers have
         * been checked for no changes.
         */
        await submitAllAnswers()
        /** now submit it for review */
        if (decodedObj) {
            const formData: ReportFormData = {
                requestReportReviewConfirm: {
                    formValues: decodedObj
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'REQUEST_REPORT_REVIEW',
                formData: formData,
                isBorderWide: false
            }))
        }
    }, [
        reportObj,
        getReportDataMutation.data
    ])

    /** now set a review to be reviewed again. submit the answers again for some reason. */
    const setToReviewReviewed = useCallback(async () => {
        /** iterates the report steps in parallel and performs the following tasks:
         * checks if EITHER the answers given in the form ARE NOT EQUAL
         * to that when the data was retrieved. This is to avoid making
         * the reportAnswer call if no changes were made.
         */

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        await submitAllAnswers()
        /** now submit it for review */
        if (decodedObj) {
            const formData: ReportFormData = {
                reviewReviewedConfirm: {
                    formValues: decodedObj
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'REVIEW_REVIEWED',
                formData: formData,
                isBorderWide: false
            }))
        }
    }, [
        reportObj,
        getReportDataMutation.data

    ])

    const setToSubmitReview = useCallback(async () => {
        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        if (decodedObj) {
            const formData: ReportFormData = {
                submitReviewedReportConfirm: {
                    formValues: decodedObj
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'SUBMIT_REVIEWED_REPORT',
                formData: formData,
                isBorderWide: false
            }))
        }
    }, [
        reportObj,
        getReportDataMutation.data
    ])

    const finalizeReport = useCallback(async () => {
        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        if (decodedObj) {
            const formData: ReportFormData = {
                finalizeReportConfirm: {
                    formValues: decodedObj
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'FINALIZE_REPORT',
                formData: formData,
                isBorderWide: false
            }))
        }
    }, [
        reportObj,
        getReportDataMutation.data
    ])

    /** as a precondition of switching to another report step:
     * 1.) check for changes.
     * 2.) validate
     * 3.) submit answer.
     *
     * NOTE: make sure you are NOT passing in the report step you are going to.
     * Use the report step before navigation.
     */

    const navigationPrecondition = useCallback(async () => {
        const foundReportStep = _.find(reportStepsFormik.values.reportSteps, (obj) => {
            return obj.reportSection === currentReportSection
        })

        if (foundReportStep) {
            const dataCheck = checkForChanges(foundReportStep)

            const hasErrors = await reportStepsFormik.validateForm()

            if (dataCheck && (hasErrors.reportSteps?.length || []) <= 0) {
                submitAnswer(foundReportStep)
            }
        }
    }, [reportStepsFormik, currentReportSection])

    /** handler to upload files. */
    const uploadFile = async (
        values: ReportStepInput,
        fileIndex: number
    ) => {
        // revalidate the token first.
        const newToken = await revalidateToken()

        /** find the fileIds array using the reportSection */
        const fileIds = _.find(fileInputRefs.current, (o) => {
            return o.reportSection === values.reportSection
        })

        /** now select the fileId using the index */

        const file = fileIds?.fileIds[fileIndex]?.files?.[0] || null

        if (file) {
            doFileUpload({
                authToken: newToken,
                fileData: file
            })
        }
    }

    /** script to handle printing pdfs. */
    const addToPrintQueue = () => {
        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        const id = uniqueString()
        setQueueId(id)

        /** reportName is needed to name the report. */

        if (decodedObj) {
            /** you only need the reportId */
            dispatch(addQueue({
                ...DEFAULT_QUEUE,
                id: id,
                details: {
                    eventReports: {
                        reportId: decodedObj.id,
                        reportName: decodedObj.reportName
                    }
                }
            }))
        }
    }

    /** you want to create a modal here to see your answer history. will be added
     * in the renderModals component.
     */

    /** creating file hint component */
    const FileHint = useMemo(() => {
        return <Text size={'xxs'}>
            {EVENT_TEXT.REPORT_INTERFACE.FILE_HINT}
        </Text>
    }, [])

    const ReportSteps = useMemo(() => {
        const steps = _.map(reportStepsFormik.values.reportSteps, (value, index) => {
            const key = [
                'reportStep-', index
            ].join('')

            const errors = reportStepsFormik.errors.reportSteps?.[index]

            return (
                /** figure out a way to show report steps that have failed validation */
                <ReportStepTab
                    key={key}
                    className={[value.reportSection === currentReportSection
                        ? 'selected'
                        : ''].join(' ')}
                    isValid={
                        errors !== undefined ? Boolean(errors) : undefined
                    } onClick={() => {
                        // when clicked, update the step of what is currently selected.
                        navigationPrecondition()
                        setCurrentReportSection(value.reportSection)
                    }}>
                    <Text size={'md'}>
                        {value.reportSection}
                    </Text>
                </ReportStepTab>
            )
        })

        return (
            <ReportStepNavigation className={'pb-5'}>
                <ul>
                    {steps}
                </ul>
            </ReportStepNavigation>
        )
    }, [
        reportStepsFormik.values,
        reportStepsFormik.errors,
        currentReportSection
    ])

    const MainContent = useMemo(() => {
        /** what do you have from this point: formik data, response data (which comprises
         * of the report step information AND the report data for answer history) and the current
         * report section selected.
         */

        const reportStepInfo = _.find(getReportDataMutation.data?.reportSteps || [], (obj) => {
            return obj.reportSection === currentReportSection
        })

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        let content = <></>

        if (reportStepInfo) {
            switch (reportStepInfo?.reportAnswerType) {
                case 'predefined':
                    content = <Predefined
                        decodedReportParams={decodedObj}
                        reportStepsFormik={reportStepsFormik}
                        currentReportSection={currentReportSection}
                        addModal={addModal}
                        responseData={getReportDataMutation.data}
                    />
                    break
                case 'open':
                    content = <Open
                        decodedReportParams={decodedObj}
                        reportStepsFormik={reportStepsFormik}
                        currentReportSection={currentReportSection}
                        addModal={addModal}
                        responseData={getReportDataMutation.data}
                    />
                    break
                case 'radio':
                    content = <Radio
                        decodedReportParams={decodedObj}
                        reportStepsFormik={reportStepsFormik}
                        currentReportSection={currentReportSection}
                        addModal={addModal}
                        responseData={getReportDataMutation.data}
                    />
                    break
                case 'checkbox':
                    content = <Checkbox
                        decodedReportParams={decodedObj}
                        reportStepsFormik={reportStepsFormik}
                        currentReportSection={currentReportSection}
                        addModal={addModal}
                        responseData={getReportDataMutation.data}
                    />
                    break
                case 'bullet':
                    content = <Bullet
                        decodedReportParams={decodedObj}
                        reportStepsFormik={reportStepsFormik}
                        currentReportSection={currentReportSection}
                        addModal={addModal}
                        responseData={getReportDataMutation.data}
                    />
                    break
                default: break
            }
        }

        return content
    }, [
        currentReportSection,
        reportStepsFormik.values,
        reportStepsFormik.errors,
        getReportDataMutation.data
    ])

    const FileUploadContent = useMemo(() => {
        /** what do you have from this point: formik data, response data (which comprises
         * of the report step information AND the report data for answer history) and the current
         * report section selected.
         */

        const reportStepInfo = _.find(getReportDataMutation.data?.reportSteps || [], (obj) => {
            return obj.reportSection === currentReportSection
        })

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        return <div>
            {
                reportStepInfo?.filesAllowed && _.includes(
                    EVENT_STATE_GROUPINGS.GROUP_1,
                    decodedObj.eventState
                )
                    ? FileHint
                    : ''
            }
            {
                reportStepInfo?.filesAllowed && _.includes(
                    EVENT_STATE_GROUPINGS.GROUP_1,
                    decodedObj.eventState
                )
                    ? <FileUpload
                        decodedReportParams={decodedObj}
                        reportStepsFormik={reportStepsFormik}
                        currentReportSection={currentReportSection}
                        addModal={addModal}
                        responseData={getReportDataMutation.data}
                        fileInputRefs={fileInputRefs}
                        uploadFile={uploadFile}
                    />
                    : ''
            }
        </div>
    }, [
        currentReportSection,
        reportStepsFormik.values,
        reportStepsFormik.errors,
        getReportDataMutation.data,
        FileHint
    ])

    const submitData = () => {
        switch (reportStepsFormik.values.submissionMode) {
            case 0:
                setToRequestReportReview()
                break
            case 1:
                setToReviewReviewed()
                break
            case 2:
                finalizeReport()
                break
            case 3:
                setToSubmitReview()
                break
            case 4:
                addToPrintQueue()
                break

            default:
                break
        }
    }

    const DynamicSubmitButtons = useMemo(() => {
        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        const SubmitReportForReview = <Button
            size={'md'}
            type={'button'}
            mode={'primary'}
            onClick={() => {
                reportStepsFormik.setFieldValue('submissionMode', 0, false)
                reportStepsFormik.handleSubmit()
            }}
        >
            {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.SUBMIT_REPORT_FOR_REVIEW}
        </Button>

        const SubmitRevisions = <Button
            size={'md'}
            type={'button'}
            mode={'primary'}
            onClick={() => {
                reportStepsFormik.setFieldValue('submissionMode', 1, false)
                reportStepsFormik.handleSubmit()
            }}
        >
            {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.SUBMIT_REVISIONS}
        </Button>
        const FinalizeReport = <Button
            size={'md'}
            type={'button'}
            mode={'primary'}
            onClick={() => {
                reportStepsFormik.setFieldValue('submissionMode', 2, false)
                reportStepsFormik.handleSubmit()
            }}
        >
            {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.FINALIZE_REPORT}
        </Button>
        const SubmitReviewedReportForReview = <Button
            size={'md'}
            type={'button'}
            mode={'primary'}
            onClick={() => {
                reportStepsFormik.setFieldValue('submissionMode', 3, false)
                reportStepsFormik.handleSubmit()
            }}
        >
            {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.SUBMIT_REVIEWED_REPORT_FOR_REVIEW}
        </Button>

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const GeneratePDF = <Button
            size={'md'}
            disabled={queue?.isLoading === true}
            type={'button'}
            mode={'primary'}
            onClick={() => {
                reportStepsFormik.setFieldValue('submissionMode', 4, false)
                reportStepsFormik.handleSubmit()
            }}
        >
            {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.GENERATE_PDF}
        </Button>

        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    {
                        _.includes([0], decodedObj.eventState) ? SubmitReportForReview : ''
                    }
                    {
                        _.includes([5, 7], decodedObj.eventState) ? SubmitRevisions : ''
                    }
                    {
                        _.includes([5, 7], decodedObj.eventState) ? FinalizeReport : ''
                    }
                    {
                        _.includes([6], decodedObj.eventState) ? SubmitReviewedReportForReview : ''
                    }
                    {
                        _.includes([10], decodedObj.eventState) ? GeneratePDF : ''
                    }
                </div>
            </Form.Group>
        )
    }, [reportObj, queues, queueId])

    const renderModals = useMemo(() => {
        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
                 * return modal with variable as a child.
                 */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'ANSWER_HISTORY') {
                    component = <AnswerHistory
                        decodedReportParams={decodedObj}
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                        reportStepsFormik={reportStepsFormik}
                    />
                } else if (modal.operation === 'FILE_HISTORY') {
                    component = <FileHistory
                        decodedReportParams={decodedObj}
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                        reportStepsFormik={reportStepsFormik}
                    />
                } else if (modal.operation === 'FINALIZE_REPORT') {
                    component = <DoFinalizeReview
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'REQUEST_REPORT_REVIEW') {
                    component = <DoRequestReportReview
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'REVIEW_HISTORY') {
                    component = <ReviewNotesHistory
                        decodedReportParams={decodedObj}
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                        reportStepsFormik={reportStepsFormik}
                    />
                } else if (modal.operation === 'REVIEW_REVIEWED') {
                    component = <DoReviewReviewed
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else if (modal.operation === 'SUBMIT_REVIEWED_REPORT') {
                    component = <DoRequestReportReview
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else {
                    return ''
                }
                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['xs', 'primary'].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    return (
        <div>
            <Text size={'sm'} className={'d-block my-1'}>{EVENT_TEXT.REPORT_INTERFACE.TITLE}</Text>
            <Form.Main className={'mt-1 px-0'} onSubmit={() => {
                submitData()
            }}>
                {ReportSteps}
                {/* now show the report step based on what's active. Previously, all steps
                are shown with hidden attributes which is undesirable. */}
                <Container className={'px-2'}>
                    {MainContent}
                    {/** now we create a section for file uploads. */}
                    {FileUploadContent}
                    {/** now create prev and next buttons. */}
                    <div className={'justify-content-end row'}>
                        <div className={'col-auto'}>
                            <Button size={'sm'} mode={'primary'} type={'button'} onClick={() => {
                                const stepIndex = _.findIndex(
                                    reportStepsFormik.values.reportSteps, (obj) => {
                                        return obj.reportSection === currentReportSection
                                    })

                                const reportStepInfo = getReportDataMutation.data
                                    ?.reportSteps[stepIndex - 1]

                                if (reportStepInfo) {
                                    setCurrentReportSection(reportStepInfo.reportSection)
                                }
                            }}>
                                {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.PREVIOUS_REPORT_STEP}
                            </Button>
                        </div>
                        <div className={'col-auto'}>
                            <Button size={'sm'} mode={'primary'} type={'button'} onClick={() => {
                                const stepIndex = _.findIndex(
                                    reportStepsFormik.values.reportSteps, (obj) => {
                                        return obj.reportSection === currentReportSection
                                    })

                                const reportStepInfo = getReportDataMutation.data
                                    ?.reportSteps[stepIndex + 1]

                                if (reportStepInfo) {
                                    setCurrentReportSection(reportStepInfo.reportSection)
                                }
                            }}>
                                {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.NEXT_REPORT_STEP}
                            </Button>
                        </div>
                    </div>
                </Container>
                {DynamicSubmitButtons}
            </Form.Main>

            {renderModals}
        </div>
    )
}

export default ReportInterface

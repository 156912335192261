import {
    useGetBillingTypesListMutation,
    useGetBoxTypesListMutation,
    useGetCurrencyTypesListMutation,
    useGetOrderDataMutation,
    useGetOrderTypesListMutation,
    useGetPartnerDiscountMutation,
    useGetPartnersListMutation,
    useGetProductTypesListMutation
} from '@apis/watchdog/account-data/order-api'
import {
    useGetPartnerDetailsMutation
} from '@apis/watchdog/account-data/partner-api'
import {
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    COLUMNS,
    INITIAL_VALUES,
    TEXT as ORDER_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/account-data/order'
import MenuLinks from '@features/main/MenuLinks'
import {
    BillingType,
    BoxType,
    // CurrencyType,
    FormOrderLine,
    OrderParams,
    OrderType,
    ProductType,
    UpdateOrderKeys,
    UpdateOrderValues
} from '@interfaces/watchdog/account-data/order'
import {
    PartnerList
} from '@interfaces/watchdog/account-data/partner'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    selectTabs
} from '@slices/watchdog/account-data/order/main'
import {
    FormStyledComponents as Form,
    Table
} from '@styles/components'
import {
    fromUnixTime
} from 'date-fns'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'

const OrderUpdate = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)

    const { orderid } = useParams<OrderParams>()

    /** fetch other calls. */
    const [getPartnersList, getPartnersListMutation] = useGetPartnersListMutation()
    const [getCurrencyTypesList, getCurrencyTypesListMutation] = useGetCurrencyTypesListMutation()
    const [getOrderTypesList, getOrderTypesListMutation] = useGetOrderTypesListMutation()
    const [getBillingTypesList, getBillingTypesListMutation] = useGetBillingTypesListMutation()
    const [getProductTypesList, getProductTypesListMutation] = useGetProductTypesListMutation()
    const [getBoxTypesList, getBoxTypesListMutation] = useGetBoxTypesListMutation()
    const [getCustomerDiscount, getCustomerDiscountMutation] = useGetPartnerDiscountMutation()
    const [getCustomerDetails, getCustomerDetailsMutation] = useGetPartnerDetailsMutation()
    const [getPartnerDetails] = useGetPartnerDetailsMutation()
    const [getOrderData, getOrderDataMutation] = useGetOrderDataMutation()

    const orderFormik = useFormik({
        initialValues: INITIAL_VALUES.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.UPDATE,
        onSubmit: () => {
        }
    })

    const strictSetValue = useCallback((
        label: UpdateOrderKeys,
        value: UpdateOrderValues[UpdateOrderKeys]
    ) => {
        orderFormik.setFieldValue(
            label,
            value
        )
    }, [])

    const OrderTypeInput = useMemo(() => {
        let orderStates = [] as OrderType[]

        if (getOrderTypesListMutation.data) {
            orderStates = getOrderTypesListMutation.data.orderTypes
        }

        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.ORDER_STATE.LABEL}
                </Form.Label>
                <Select
                    options={orderStates}
                    value={_.find(
                        orderStates,
                        (e) => Number(e.value) === orderFormik.values.order_type
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
            </Form.Group>
        )
    }, [
        getOrderTypesListMutation.data,
        orderFormik.values.order_type,
        orderFormik.errors.order_type
    ])

    const NameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.NAME.LABEL}
                </Form.Label>
                <Form.Input
                    value={orderFormik.values.name}
                    readOnly={true}
                />
            </Form.Group>
        )
    }, [orderFormik.values.name, orderFormik.errors.name])

    const SetupInstructionsInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.SETUP_INSTRUCTIONS.LABEL}
                </Form.Label>
                <Form.Input
                    value={orderFormik.values.setupInstructions}
                    readOnly={true}
                />
            </Form.Group>
        )
    }, [orderFormik.values.setupInstructions, orderFormik.errors.setupInstructions])

    const EmailInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.EMAIL.LABEL}
                </Form.Label>
                <Form.Input
                    value={orderFormik.values.email}
                    readOnly={true}
                />
            </Form.Group>
        )
    }, [orderFormik.values.email, orderFormik.errors.email])

    const CommentInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-12 ps-0'}
                >
                    {ORDER_TEXT.UPDATE.FORM.COMMENT.LABEL}
                </Form.Label>
                <Form.TextArea
                    className={'col'}
                    value={orderFormik.values.comment}
                    readOnly={true}

                />
            </Form.Group>
        )
    }, [orderFormik.values.comment, orderFormik.errors.comment])

    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                >
                    {ORDER_TEXT.UPDATE.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    type={'checkbox'}
                    checked={orderFormik.values.active}
                    disabled={true}
                />
            </Form.Group>
        )
    }, [orderFormik.values.active, orderFormik.errors.active])

    const ActivationDateInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.ACTIVATION_DATE.LABEL}
                </Form.Label>
                <Form.DatePicker
                    selected={fromUnixTime(orderFormik.values.activation_date)}
                    onChange={() => {}}
                    readOnly={true}
                />
            </Form.Group>
        )
    }, [orderFormik.values.activation_date, orderFormik.errors.activation_date])

    // const CurrencyInput = useMemo(() => {
    //     /** FROM BDGDB-267, can't use currency types here anymore but
    //      * the billed to's currencies instead.
    //      */
    //     let currencyTypes = [] as CurrencyType[]

    //     if (getPartnerDetailsMutation.data) {
    //         currencyTypes = getPartnerDetailsMutation.data?.partnerDetails.currencies
    //     }

    //     return (
    //         <Form.Group>
    //             <Form.Label>
    //                 {ORDER_TEXT.UPDATE.FORM.CURRENCY.LABEL}
    //             </Form.Label>
    //             <Select
    //                 options={currencyTypes}
    //                 value={_.find(
    //                     currencyTypes,
    //                     (e) => Number(e.value) === orderFormik.values.currency
    //                 )}
    //                 styles={{
    //                     ...reactSelect.styles
    //                 }}
    //                 theme={reactSelect.theme}
    //                 isDisabled={true}
    //             />
    //         </Form.Group>
    //     )
    // }, [
    //     getPartnerDetailsMutation.data,
    //     orderFormik.values.currency,
    //     orderFormik.errors.currency
    // ])

    const CustomerNameInput = useMemo(() => {
        let partners = [] as PartnerList[]

        if (getPartnersListMutation.data) {
            partners = getPartnersListMutation.data.partnerData
        }

        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.CUSTOMER_NAME.LABEL}
                </Form.Label>
                <Select
                    options={partners}
                    value={_.find(
                        partners,
                        (e) => e.value === orderFormik.values.customer_name
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
            </Form.Group>
        )
    }, [
        getPartnersListMutation.data,
        orderFormik.values.customer_name,
        orderFormik.errors.customer_name
    ])

    const PartnerNameInput = useMemo(() => {
        let partners = [] as PartnerList[]

        if (getPartnersListMutation.data) {
            partners = getPartnersListMutation.data.partnerData
        }

        return (
            <Form.Group>
                <Form.Label>
                    {ORDER_TEXT.UPDATE.FORM.PARTNER_NAME.LABEL}
                </Form.Label>
                <Select
                    options={partners}
                    value={_.find(
                        partners,
                        (e) => e.value === orderFormik.values.partner_name
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
            </Form.Group>
        )
    }, [
        getPartnersListMutation.data,
        orderFormik.values.partner_name,
        orderFormik.errors.partner_name
    ])

    const OrderListTable = useMemo(() => {
        /** create interface no longer just a text field */

        let boxTypes = [] as BoxType[]
        let productTypes = [] as ProductType[]
        let billingTypes = [] as BillingType[]

        if (getBoxTypesListMutation.data) {
            boxTypes = getBoxTypesListMutation.data.boxTypes
        }

        if (getProductTypesListMutation.data) {
            productTypes = getProductTypesListMutation.data.productTypes
        }

        if (getBillingTypesListMutation.data) {
            billingTypes = getBillingTypesListMutation.data.billingTypes
        }

        const thead = (
            <thead>
                <tr>
                    <th>{TEXT.SEQUENCE}</th>
                    {
                        _.map(COLUMNS.ORDERLINE, (column, index) => {
                            return (
                                <th key={'order-th-' + index}>
                                    {column.value}
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
        )

        const renderInput = (
            key: keyof FormOrderLine,
            value: FormOrderLine[keyof FormOrderLine],
            orderLineIndex:number
        ) => {
            switch (key) {
                case 'which_box': return (
                    <Select
                        options={boxTypes}
                        value={_.find(
                            boxTypes,
                            (e) => e.value === value
                        )}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                        isDisabled={true}
                    />
                )

                case 'which_product' : return (
                    <Select
                        options={productTypes}
                        value={_.find(
                            productTypes,
                            (e) => e.value === value
                        )}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                        isDisabled={true}
                    />
                )

                case 'billing_type' : return (
                    <Select
                        options={billingTypes}
                        value={_.find(
                            billingTypes,
                            (e) => e.value === value
                        )}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                        isDisabled={true}
                    />
                )

                default: return (
                    <Form.TableInput
                        type={'text'}
                        value={value}
                        readOnly={true}
                    />
                )
            }
        }

        const renderRow = (
            orderLine: FormOrderLine,
            orderLineIndex: number
        ) => {
            return <tr key={'orderline-row-' + orderLineIndex}>
                <td>{orderLineIndex + 1}</td>
                {
                    _.map(COLUMNS.ORDERLINE, (column, columnIndex) => {
                        return (
                            <td key={'orderLine-' + orderLineIndex + 'input-' + columnIndex}>
                                {
                                    renderInput(
                                        column.value,
                                        orderLine[column.value],
                                        orderLineIndex)
                                }
                            </td>
                        )
                    })
                }
            </tr>
        }

        const tbody = (
            <tbody>
                {
                    _.map(orderFormik.values.orderLines, (orderLine, orderLineIndex) => {
                        return renderRow(orderLine, orderLineIndex)
                    })
                }
            </tbody>
        )

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    {thead}
                    {tbody}
                </table>
            </Table>
        )
    }, [
        orderFormik.values.orderLines,
        getBoxTypesListMutation.data,
        getProductTypesListMutation.data,
        getBillingTypesListMutation.data
    ])

    const unsubscribeGetPartners = () => {
        const unsubscribeMutation = getPartnersList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetCurrencyTypes = () => {
        const unsubscribeMutation = getCurrencyTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOrderTypes = () => {
        const unsubscribeMutation = getOrderTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetBillingTypes = () => {
        const unsubscribeMutation = getBillingTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetProductTypes = () => {
        const unsubscribeMutation = getProductTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetBoxTypes = () => {
        const unsubscribeMutation = getBoxTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnersDetails = () => {
        const unsubscribeMutation = getOrderData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetPartners()
        unsubscribeGetCurrencyTypes()
        unsubscribeGetOrderTypes()
        unsubscribeGetBillingTypes()
        unsubscribeGetProductTypes()
        unsubscribeGetBoxTypes()
        unsubscribeGetPartnersDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getPartnersPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getCurrencyTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOrderTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getBillingTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getProductTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getBoxTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOrderDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && orderid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getPartnersPromise = getPartnersList({
                        authToken: newToken,
                        partner_type: [
                            { type: '1' },
                            { type: '2' },
                            { type: '3' },
                            { type: '4' }
                        ]
                    })
                    getCurrencyTypesPromise = getCurrencyTypesList({
                        authToken: newToken
                    })
                    getOrderTypesPromise = getOrderTypesList({
                        authToken: newToken
                    })
                    getBillingTypesPromise = getBillingTypesList({
                        authToken: newToken
                    })
                    getProductTypesPromise = getProductTypesList({
                        authToken: newToken
                    })
                    getBoxTypesPromise = getBoxTypesList({
                        authToken: newToken
                    })
                    getOrderDataPromise = getOrderData({
                        authToken: newToken,
                        orderId: orderid
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPartnersPromise && getPartnersPromise.abort()
            getCurrencyTypesPromise && getCurrencyTypesPromise.abort()
            getOrderTypesPromise && getOrderTypesPromise.abort()
            getBillingTypesPromise && getBillingTypesPromise.abort()
            getProductTypesPromise && getProductTypesPromise.abort()
            getBoxTypesPromise && getBoxTypesPromise.abort()
            getOrderDataPromise && getOrderDataPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        /** if dependency updates, all discount rows will update the default value. */
        let getCustomerDiscountPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getCustomerDiscountPromise = getCustomerDiscount({
                        authToken: newToken,
                        partnerID: orderFormik.values.partner_name
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCustomerDiscountPromise && getCustomerDiscountPromise.abort()
        }
    }, [orderFormik.values.partner_name])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (getCustomerDiscountMutation.data) {
            const data = getCustomerDiscountMutation.data
            /** iterate each orderList and update those values IF empty, NOT zero. */
            const orderLines = _.cloneDeep(orderFormik.values.orderLines)
            const fieldValue: UpdateOrderKeys = 'orderLines'

            _.forEach(orderLines, (orderLine) => {
                if (!orderLine.discount) {
                    orderLine.discount = data.partnerDetails.discount.toString()
                }
            })

            orderFormik.setFieldValue(fieldValue, orderLines)

            /** also, no need to set or anything but, when you update an
             *  orderline, this becomes the default value */
        }
    }, [getCustomerDiscountMutation.data])

    useEffect(() => {
        if (getCurrencyTypesListMutation.error) {
            console.error(getCurrencyTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCurrencyTypesListMutation.error])

    useEffect(() => {
        if (getOrderTypesListMutation.error) {
            console.error(getOrderTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOrderTypesListMutation.error])

    useEffect(() => {
        if (getBillingTypesListMutation.error) {
            console.error(getBillingTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBillingTypesListMutation.error])

    useEffect(() => {
        if (getProductTypesListMutation.error) {
            console.error(getProductTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getProductTypesListMutation.error])

    useEffect(() => {
        if (getBoxTypesListMutation.error) {
            console.error(getBoxTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBoxTypesListMutation.error])

    useEffect(() => {
        if (getCustomerDiscountMutation.error) {
            console.error(getCustomerDiscountMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCustomerDiscountMutation.error])

    /** lifecycle to prevent an orderState value of zero. */
    useEffect(() => {
        if (orderFormik.values.order_type <= 0) {
            // default order will always be 3.
            strictSetValue('order_type', 3)
        }
    }, [orderFormik.values.order_type])

    // when values.customerName is updated, automatically retrieve its partnerid
    // and fetch its partnerData to retrieve its partnerOf value.

    const unsubscribeCustomerDetails = () => {
        const unsubscribeMutation = getCustomerDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeCustomerDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getCustomerDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid && orderFormik.values.customer_name
            ) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getCustomerDetailsPromise = getCustomerDetails({
                        authToken: newToken,
                        // looks like the call is associated with partner_name and not customer_name
                        // so far
                        partnerID: orderFormik.values.customer_name
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCustomerDetailsPromise && getCustomerDetailsPromise.abort()
        }
    }, [
        token.valid,
        orderFormik.values.customer_name
    ])

    const unsubscribePartnerDetails = () => {
        const unsubscribeMutation = getCustomerDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribePartnerDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getPartnerDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid && orderFormik.values.partner_name
            ) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getPartnerDetailsPromise = getPartnerDetails({
                        authToken: newToken,
                        // looks like the call is associated with partner_name and not customer_name
                        // so far
                        partnerID: orderFormik.values.partner_name
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPartnerDetailsPromise && getPartnerDetailsPromise.abort()
        }
    }, [
        token.valid,
        orderFormik.values.partner_name
    ])

    useEffect(() => {
        if (
            getCustomerDetailsMutation.data?.partnerDetails &&
            getPartnersListMutation.data?.partnerData.length
        ) {
            const partnerList = getPartnersListMutation.data?.partnerData
            const partnerData = getCustomerDetailsMutation.data?.partnerDetails

            const partnerOf = _.find(partnerList, (obj) => {
                return obj.value === partnerData.partner_of
            })

            strictSetValue(
                'partner_name',
                partnerOf?.value || ''
            )
        }
    }, [
        getPartnersListMutation.data,
        getCustomerDetailsMutation.data
    ])

    const setDefaultFormData = useCallback(() => {
        /**
         * if this is truthy, you need to orderFormik.setFieldValue into formik.
         */

        const data = getOrderDataMutation.data
        const partners = getPartnersListMutation.data?.partnerData || []
        const currencyTypes = getCurrencyTypesListMutation.data?.currencyTypes || []
        const orderTypes = getOrderTypesListMutation.data?.orderTypes || []
        const billingTypes = getBillingTypesListMutation.data?.billingTypes || []
        const productTypes = getProductTypesListMutation.data?.productTypes || []
        const boxTypes = getBoxTypesListMutation.data?.boxTypes || []

        if (data?.orderData?.find((o) => o.order_id === orderid)) {
            const result = data.orderData[0]

            const customerName = _.find(partners, (obj) => {
                return obj.label === result.company_name
            })

            const partnerName = _.find(partners, (obj) => {
                return obj.label === result.partner_name
            })

            const currency = _.find(currencyTypes, (obj) => {
                return obj.value === result.orderCurrency
            })

            const orderType = _.find(orderTypes, (obj) => {
                return obj.value === result.order_state_type.toString()
            })

            const orderLines: FormOrderLine[] = _.map(
                _.cloneDeep(result.orderLines.orderData), (obj) => {
                    const result: FormOrderLine = {
                        qty: obj.qty.toString(),
                        which_box: _.find(boxTypes, (boxObj) => {
                            return boxObj.label === obj.box_type
                        })?.value || '',
                        which_product: _.find(productTypes, (productObj) => {
                            return productObj.label === obj.product_type
                        })?.value || '',
                        billing_type: _.find(billingTypes, (billingTypeObj) => {
                            return billingTypeObj.label === obj.billing_type
                        })?.value || '',
                        discount: obj.discount.toString(),
                        orderline_id: obj.orderline_id
                    }
                    return result
                }
            )

            orderFormik.setValues({
                activation_date: result.activationTime,
                active: result.active,
                comment: decodeURIComponent(result.comment),
                currency: Number(currency?.value || '0'),
                customer_name: customerName?.value || '',
                email: result.email,
                name: decodeURIComponent(result.name),
                orderLines: orderLines,
                order_type: Number(orderType?.value || '0'),
                partner_name: partnerName?.value || '',
                setupInstructions: decodeURIComponent(result.setupInstructions)

            })
        }
    }, [
        getOrderDataMutation.data,
        getPartnersListMutation.data,
        getCurrencyTypesListMutation.data,
        getOrderTypesListMutation.data,
        getBillingTypesListMutation.data,
        getProductTypesListMutation.data,
        getBoxTypesListMutation.data,
        getCustomerDiscountMutation.data
    ])

    useEffect(() => {
        setDefaultFormData()
    }, [
        getOrderDataMutation.data,
        getPartnersListMutation.data,
        getCurrencyTypesListMutation.data,
        getOrderTypesListMutation.data,
        getBillingTypesListMutation.data,
        getProductTypesListMutation.data,
        getBoxTypesListMutation.data,
        getCustomerDiscountMutation.data
    ])

    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={orderFormik.handleSubmit}>
                        {CustomerNameInput}
                        {PartnerNameInput}
                        {/* {CurrencyInput} */}
                        {OrderTypeInput}
                        {ActivationDateInput}
                        {ActiveInput}
                        {NameInput}
                        {SetupInstructionsInput}
                        {EmailInput}
                        {CommentInput}
                        {OrderListTable}
                    </Form.Main>
                </div>
            </div>
        </div>
    )
}
export default OrderUpdate

import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    GetM365ConfigRequest,
    GetVAPTConfigRequest,
    VAPTConfigAddRequest,
    GetAlertEmailsRequest,
    SetAlertEmailsRequest,
    VAPTDoScheduleRequest,
    GetAvailabilityRequest,
    GetDeviceConfigRequest,
    SetAvailabilityRequest,
    VAPTConfigRemoveRequest,
    M365ConfigUpdateRequest,
    VAPTConfigUpdateRequest,
    M365ConfigDetailsRequest,
    VAPTConfigDetailsRequest,
    DeviceConfigUpdateRequest,
    DeviceConfigDetailsRequest,
    O365GetTrustedLocationRequest,
    O365SetTrustedLocationRequest,
    GetM365ConfigResponse,
    GetVAPTConfigResponse,
    VAPTConfigAddResponse,
    GetAlertEmailsResponse,
    SetAlertEmailsResponse,
    VAPTDoScheduleResponse,
    GetAvailabilityResponse,
    GetDeviceConfigResponse,
    SetAvailabilityResponse,
    VAPTConfigRemoveResponse,
    M365ConfigUpdateResponse,
    VAPTConfigUpdateResponse,
    M365ConfigDetailsResponse,
    VAPTConfigDetailsResponse,
    DeviceConfigUpdateResponse,
    DeviceConfigDetailsResponse,
    O365GetTrustedLocationResponse,
    O365SetTrustedLocationResponse
} from '@interfaces/watchdog/configuration/device-config'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const deviceConfigApi = createApi({
    reducerPath: 'deviceConfigApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getM365Config: builder.mutation<GetM365ConfigResponse, GetM365ConfigRequest & TokenAuth>({
            query: (body) => {
                const formData: GetM365ConfigRequest = {}

                return {
                    url: 'api/getM365Config',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getM365ConfigDetails: builder.mutation<M365ConfigDetailsResponse,
            M365ConfigDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: M365ConfigDetailsRequest = {
                        id: body.id
                    }

                    return {
                        url: 'api/getM365Config/details',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        m365ConfigUpdate: builder.mutation<M365ConfigUpdateResponse,
        M365ConfigUpdateRequest & TokenAuth>({
            query: (body) => {
                const formData: M365ConfigUpdateRequest = {
                    id: body.id,
                    active: body.active,
                    clientId: body.clientId,
                    deviceId: body.deviceId,
                    tenant: body.tenant,
                    tenantId: body.tenantId,
                    thumbprint: body.thumbprint
                }

                return {
                    url: 'api/getM365Config/update',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        o365GetTrustedLocation: builder.mutation<O365GetTrustedLocationResponse,
        O365GetTrustedLocationRequest & TokenAuth>({
            query: (body) => {
                const formData: O365GetTrustedLocationRequest = {
                    id: body.id,
                    deviceid: body.deviceid
                }

                return {
                    url: 'api/o365/getTrustedLocations',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        o365SetTrustedLocation: builder.mutation<O365SetTrustedLocationResponse,
        O365SetTrustedLocationRequest & TokenAuth>({
            query: (body) => {
                const formData: O365SetTrustedLocationRequest = {
                    id: body.id,
                    deviceid: body.deviceid,
                    locations: body.locations
                }

                return {
                    url: 'api/o365/setTrustedLocations',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getVAPTConfig: builder.mutation<GetVAPTConfigResponse,
        GetVAPTConfigRequest & TokenAuth>({
            query: (body) => {
                const formData: GetVAPTConfigRequest = {}

                return {
                    url: 'api/getVAPTConfig',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        vaptConfigDetails: builder.mutation<VAPTConfigDetailsResponse,
        VAPTConfigDetailsRequest & TokenAuth>({
            query: (body) => {
                const formData: VAPTConfigDetailsRequest = {
                    deviceId: body.deviceId
                }

                return {
                    url: 'api/getVAPTConfigDetails',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        vaptConfigRemove: builder.mutation<VAPTConfigRemoveResponse,
        VAPTConfigRemoveRequest & TokenAuth>({
            query: (body) => {
                const formData: VAPTConfigRemoveRequest = {
                    id: body.id
                }

                return {
                    url: 'api/getVAPTConfigRemove',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        vaptConfigUpdate: builder.mutation<VAPTConfigUpdateResponse,
        VAPTConfigUpdateRequest & TokenAuth>({
            query: (body) => {
                const formData: VAPTConfigUpdateRequest = {
                    id: body.id,
                    deviceId: body.deviceId,
                    assetName: body.assetName
                }

                return {
                    url: 'api/getVAPTConfigUpdate',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        vaptConfigAdd: builder.mutation<VAPTConfigAddResponse,
        VAPTConfigAddRequest & TokenAuth>({
            query: (body) => {
                const formData: VAPTConfigAddRequest = {
                    deviceId: body.deviceId,
                    assetName: body.assetName
                }

                return {
                    url: 'api/getVAPTConfigAdd',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        vaptDoSchedule: builder.mutation<VAPTDoScheduleResponse,
        VAPTDoScheduleRequest & TokenAuth>({
            query: (body) => {
                const formData: VAPTDoScheduleRequest = {
                    deviceid: body.deviceid,
                    which: body.which
                }

                return {
                    url: 'api/vapt/doSchedule',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getDeviceConfig: builder.mutation<GetDeviceConfigResponse,
        GetDeviceConfigRequest & TokenAuth>({
            query: (body) => {
                const formData: GetDeviceConfigRequest = {
                }

                return {
                    url: 'api/getDeviceConfig',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getDeviceConfigDetails: builder.mutation<DeviceConfigDetailsResponse,
        DeviceConfigDetailsRequest & TokenAuth>({
            query: (body) => {
                const formData: DeviceConfigDetailsRequest = {
                    id: body.id
                }

                return {
                    url: 'api/getDeviceConfig/details',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        deviceConfigUpdate: builder.mutation<DeviceConfigUpdateResponse,
        DeviceConfigUpdateRequest & TokenAuth>({
            query: (body) => {
                const formData: DeviceConfigUpdateRequest = {
                    id: body.id,
                    deviceId: body.deviceId,
                    location: body.location,
                    country: body.country,
                    city: body.city
                }

                return {
                    url: 'api/getDeviceConfig/update',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getAvailability: builder.mutation<GetAvailabilityResponse,
        GetAvailabilityRequest & TokenAuth>({
            query: (body) => {
                const formData: GetAvailabilityRequest = {
                    id: body.id
                }

                return {
                    url: 'api/device/getAvailability',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        setAvailability: builder.mutation<SetAvailabilityResponse,
        SetAvailabilityRequest & TokenAuth>({
            query: (body) => {
                const formData: SetAvailabilityRequest = {
                    id: body.id,
                    available: body.available

                }

                return {
                    url: 'api/device/setAvailability',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getAlertEmails: builder.mutation<GetAlertEmailsResponse,
        GetAlertEmailsRequest & TokenAuth>({
            query: (body) => {
                const formData: GetAlertEmailsRequest = {
                    id: body.id
                }

                return {
                    url: 'api/device/getAlertEmails',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        setAlertEmails: builder.mutation<SetAlertEmailsResponse,
        SetAlertEmailsRequest & TokenAuth>({
            query: (body) => {
                const formData: SetAlertEmailsRequest = {
                    id: body.id,
                    emailAlerts: body.emailAlerts
                }

                return {
                    url: 'api/device/setAlertEmails',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        })
    })
})

export const {
    useDeviceConfigUpdateMutation,
    useGetAlertEmailsMutation,
    useGetAvailabilityMutation,
    useGetDeviceConfigDetailsMutation,
    useGetDeviceConfigMutation,
    useGetM365ConfigDetailsMutation,
    useGetM365ConfigMutation,
    useGetVAPTConfigMutation,
    useM365ConfigUpdateMutation,
    useO365GetTrustedLocationMutation,
    useO365SetTrustedLocationMutation,
    useSetAlertEmailsMutation,
    useSetAvailabilityMutation,
    useVaptConfigAddMutation,
    useVaptConfigDetailsMutation,
    useVaptConfigRemoveMutation,
    useVaptConfigUpdateMutation,
    useVaptDoScheduleMutation
} = deviceConfigApi

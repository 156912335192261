// might subject to change i think since I don't prefer url params.

import { RouteConfig } from '@constants/main/root'

export const LOGIN_ROUTES: Record<string, RouteConfig> = {
    LOGIN: {
        link: '/login',
        name: 'Login'
    },
    LOGOUT: {
        link: '/logout',
        name: 'Logout'
    },
    FORCE_PASSWORD: {
        link: '/force-password',
        name: 'Force Password'
    },
    RESET_PASSWORD: {
        link: '/reset-password',
        name: 'Reset Password'
    },
    MFA: {
        link: '/mfaPass',
        name: 'MFA'
    },
    MAIN: {
        link: '/',
        name: 'Main'
    }
}
export const PARTNER_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/partners',
        name: 'Partners - Main'
    },
    ADD: {
        link: '/partners-add',
        name: 'Partners - Add'
    },
    VIEW: {
        link: '/partners-view',
        name: 'Partners - View'
    },
    UPDATE: {
        link: '/partners-update',
        name: 'Partners - Update'
    }
}

export const ORDER_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/orders',
        name: 'Orders - Main'
    },
    ADD: {
        link: '/orders-add',
        name: 'Orders - Add'
    },
    VIEW: {
        link: '/orders-view',
        name: 'Orders - View'
    },
    UPDATE: {
        link: '/orders-update',
        name: 'Orders - Update'
    },
    DISPATCH: {
        link: '/orders-dispatch',
        name: 'Orders - Dispatch'
    },
    DEVICEID_SEARCH: {
        link: '/orders-deviceid-search',
        name: 'Orders - DeviceID Search'
    }
}

export const DASHBOARD_ACCOUNT_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/dashboard-accounts',
        name: 'Dashboard Accounts - Main'
    }
}

export const CONFIGURATION_STATUS_ROUTES: Record<string, RouteConfig> = {
    BOX: {
        link: '/box-config-status',
        name: 'Configuration Status - Box'
    },
    DEVICE_CONFIG: {
        link: '/device-configurations',
        name: 'Configuration Status - Device'
    }
}

export const SOC_MONITOR_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/soc-monitors',
        name: 'SOC Monitors - Main'
    }
}

export const TACTICAL_MONITOR_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/tac-monitors',
        name: 'Tactical Monitors - Main'
    }
}

export const GRC_MONITOR_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/grc-monitors',
        name: 'GRC Monitors - Main'
    }
}

export const PROFILE_ROUTES: Record<string, RouteConfig> = {
    PASSWORD: {
        link: '/profile',
        name: 'Profile - Password'
    },
    MFA: {
        link: '/mfa',
        name: 'Profile - MFA'
    },
    SETTINGS: {
        link: '/settings',
        name: 'Profile - Settings'
    }
}

export const EVENT_ROUTES: Record<string, RouteConfig> = {
    CURRENT: {
        link: '/current-events',
        name: 'Events - Current'
    },
    ESCALATED: {
        link: '/escalated-events',
        name: 'Events - Escalated'
    },
    UNCLASSIFIED: {
        link: '/unclassified-events',
        name: 'Events - Unclassified'
    },
    SEARCH: {
        link: '/events-search',
        name: 'Events - Search'
    },
    FLOW: {
        link: '/flow-search',
        name: 'Events - Flow'
    }
}

export const REPORT_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/event-reports',
        name: 'Reports - Main'
    },
    CREATED_REPORTS: {
        link: '/created-reports',
        name: 'Reports - Created Reports'
    },
    REPORT_INTERFACE: {
        link: '/report-interface',
        name: 'Reports - Report Interface'
    }
}

export const FILTER_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/event-filters',
        name: 'Filters - Main'
    },
    ADD: {
        link: '/event-filters-add',
        name: 'Filters - Add'
    }
}

/**
 * if you are reading this, make sure you move those parameters from here
 * to their respective route files.
 */

export const MONITORING_STATUS_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/box-status',
        name: 'Monitoring Status - Main'
    }
}

export const MDR_DETAILED_DASHBOARD_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/mdr/detailed-dashboard/:deviceid/main',
        name: 'MDR Detailed Dashboard - Main'
    }
}

export const O365_DETAILED_DASHBOARD_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/o365/detailed-dashboard/:deviceid/main',
        name: 'O365 Detailed Dashboard - Main'
    },
    SHAREPOINT: {
        link: '/o365/detailed-dashboard/:deviceid/sharepoint',
        name: 'O365 Detailed Dashboard - SharePoint'
    },
    EXCHANGE: {
        link: '/o365/detailed-dashboard/:deviceid/exchange',
        name: 'O365 Detailed Dashboard - Exchange'
    }
}

// deviceid can also be "soc"
export const AZURE_DETAILED_DASHBOARD_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/azure/detailed-dashboard/:deviceid/main',
        name: 'Azure Detailed Dashboard - Main'
    },
    KUBERNETES: {
        link: '/azure/detailed-dashboard/:deviceid/kubernetes',
        name: 'Azure Detailed Dashboard - Kubernetes'
    }
}

// UPDATE: we can use the questionnaireId to get the compliance and userids.
// the urls don't need to be long anymore.
export const COMPLIANCE_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/compliance-questionnaire',
        name: 'Compliance - Main'
    },
    QUESTIONS: {
        link: '/compliance-questions',
        name: 'Compliance - Questions'
    },
    ANSWER: {
        link: '/compliance-answer',
        name: 'Compliance - Answer'
    }
}

export const QUESTIONNAIRE_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/questionnaire-information',
        name: 'Questionnaire - Main'
    },
    ADD: {
        link: '/questionnaire-information-add',
        name: 'Questionnaire - Add'
    },
    VIEW: {
        link: '/questionnaire-information-view',
        name: 'Questionnaire - View'
    },
    UPDATE: {
        link: '/questionnaire-information-update',
        name: 'Questionnaire - Update'
    }
}

export const QUESTION_ROUTES: Record<string, RouteConfig> = {
    MAIN: {
        link: '/questions/:questionnaireid',
        name: 'Questions - Main'
    },
    ADD: {
        link: '/questions-add/:questionnaireid',
        name: 'Questions - Add'
    },
    VIEW: {
        link: '/questions-view',
        name: 'Questions - View'
    },
    UPDATE: {
        link: '/questions-update',
        name: 'Questions - Update'
    }
}
// PRINT ROUTES. not part of sidebar logic.
export const PRINT_ROUTES = {
    /** routes to show a list of generated reports. Separate them by system. For now
     * we have monitor cards and then event reports
     */
    MENU: {
        DASHBOARD: '/print-reports/dashboard-monitors',
        EVENT_REPORT: '/print-reports/event-reports'
    },
    EVENT_REPORT: {
        COVER: '/print/event-report/cover/:reportId',
        CONTENT: '/print/event-report/content/:reportId'
    },
    EVENT_DETAILS: {
        COVER: '/print/event-details/cover/:reportId',
        CONTENT: '/print/event-details/content/:reportId'
    },
    DASHBOARD: {
        VSS: {
            MAIN: '/print/bdg-vss',
            DETAILS: '/print/bdg-vss/details'
        },
        EXT_VSS: {
            MAIN: '/print/bdg-ext-vss',
            DETAILS: '/print/bdg-ext-vss/details'
        },
        EXT_WSS: {
            MAIN: '/print/bdg-ext-wss',
            DETAILS: '/print/bdg-ext-wss/details'
        },
        RDD: {
            MAIN: '/print/bdg-rdd'
        },
        OSS: {
            MAIN: '/print/bdg-oss',
            DETAILS: '/print/bdg-oss/details'
        },
        O365: {
            MAIN: '/print/bdg-o365'
        },
        O365_DETAILED: {
            MAIN: '/print/bdg-o365/main',
            SHAREPOINT: '/print/bdg-o365/sharepoint',
            EXCHANGE: '/print/bdg-o365/exchange'
        },
        MDR: {
            MAIN: '/print/bdg-mdr'
        },
        MDR_DETAILED: {
            MAIN: '/print/bdg-mdr/main'
        },
        CSM_NIST: {
            MAIN: '/print/bdg-csm-nist',
            DETAILS: '/print/bdg-csm-nist/details'
        },
        CSM_GDPR: {
            MAIN: '/print/bdg-csm-gdpr',
            DETAILS: '/print/bdg-csm-gdpr/details'
        },
        CSM_PCIDSS: {
            MAIN: '/print/bdg-csm-pcidss',
            DETAILS: '/print/bdg-csm-pcidss/details'
        },
        IMM: {
            MAIN: '/print/bdg-imm'
        },
        VMM: {
            MAIN: '/print/bdg-vmm'
        },
        EXT_VMM: {
            MAIN: '/print/bdg-ext-vmm'
        },
        O365_AZURE: {
            MAIN: '/print/bdg-o365-azure'
        },
        O365_AZURE_DETAILED: {
            MAIN: '/print/bdg-o365-azure/main',
            KUBERNETES: '/print/bdg-o365-azure/kubernetes'
        }
    }
}

import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    IncidentCategories,
    IncidentColumn,
    IncidentDataKeys
} from '@interfaces/dashboard/tac/imm'
import { LabelValuePair } from '@interfaces/main/root'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { years: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    // FAULTY_CALL: 'EXT-VSS call returned faulty.',
    FAULTY_CALL: 'IMM call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    EMPTY_RECOMMENDATIONS: 'No recommendations found for this section...',
    FETCH: {
        MODAL: 'Fetching modal data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        INCIDENT_CATEGORIES: 'Incident Categories',
        MEAN_INCIDENT_RESPONSE_TIMES: 'Mean Incident Response Times',
        INCIDENT_COSTS: 'Incident Costs'
    }
}

export const INCIDENT_CATEGORIES: IncidentCategories[] = [
    'Denial of Service', 'Physical Theft/Loss',
    'Insider Misuse', 'Social Engineering',
    'Malware', 'Hacking'
]

export const INCIDENT_CATEGORIES_HEADERS: Omit<LabelValuePair, 'value'>[] = [
    { label: 'Type' },
    { label: 'Current Period' },
    { label: 'Previous Period' }
]

export const INCIDENT_COSTS_HEADERS = INCIDENT_CATEGORIES_HEADERS

export const INCIDENT_COLUMNS: IncidentColumn[] = (() => {
    const columnNames: Omit<IncidentColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Title',
            value: 'title'
        },
        {
            label: 'Category',
            value: 'category'
        },
        {
            label: 'Severity',
            value: 'severity'
        },
        {
            label: 'Hourly Cost',
            value: 'hourly_cost'
        },
        {
            label: 'Hours Spent',
            value: 'hours_spent'
        }

    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: IncidentColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */
export const INCIDENT_DATA_KEYS: IncidentDataKeys[] = [
    'category', 'hourly_cost', 'hours_spent', 'severity', 'title'
]

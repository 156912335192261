
import {
    useGetModalMutation
} from '@apis/dashboard/grc/nist-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as NIST_MESSAGE,
    TEXT as NIST_TEXT
} from '@constants/dashboard/grc/nist'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import ComplianceIndexChart from '@features/dashboard/grc/nist/ComplianceIndexChart'
import ComplianceLineChart from '@features/dashboard/grc/nist/ComplianceLineChart'
import CoverageGauge from '@features/dashboard/grc/nist/CoverageGauge'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import ReadMore from '@features/main/ReadMore'
import {
    CalculatedChapterData,
    Identifier,
    ModalRequest
} from '@interfaces/dashboard/grc/nist'
import {
    MonitorModal,
    NistDetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/grc/nist/details'
import {
    resetNistMain,
    selectCalculatedChapterData,
    selectCurrentParams,
    selectExpandText,
    selectPrintOptions,
    selectSearchParams,
    setCalculatedChapterData,
    setCurrentParams,
    setEndDate,
    setExpandText,
    setLogo,
    setRefetch,
    setStartDate
} from '@slices/dashboard/grc/nist/main'

import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    SpinnerContainer,
    Text,
    Button
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    createRef,
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import {
    FaFilePdf
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'

const NistModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const searchParams = useAppSelector(selectSearchParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const calculatedChapterData = useAppSelector(selectCalculatedChapterData)
    const expandText = useAppSelector(selectExpandText)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** will be initiailized multiple times. */
    const [getModal, getModalMutation] = useGetModalMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalMutation.error])

    /** after useEffect to throw errors, create fetchData script.
     * NEW CHANGE from old system. make each call a separate script.
     * Was done because client requested to individually have refresh button on
     * each chart.
     *
     * UPDATE: only apply this case WHEN it needs to be. Because this module
     * has one refresh button that executes all api calls at once, put it all in once place.
    */

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const newRanges = getUtcRanges(searchParams.ranges)
                    const requestData: ModalRequest & TokenAuth = {
                        authToken: token.value,
                        deviceid: modal.card.deviceid,
                        service_type: modal.card.service_type,
                        chapterIdentifier: 'all',
                        in_face: modal.card.in_face,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchData = () => {
        unsubscribeGetModal()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            service_type: modal.card.service_type,
                            chapterIdentifier: 'all',
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalPromise = getModal(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalMutation.isSuccess
    ])

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data

        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && (data?.data || []).length <= 0) {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 1 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))
                dispatch(setRefetch(true))

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update mdr bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [
        getModalMutation
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={NIST_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {NIST_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalMutation,
        currentParams,
        queueId,
        queues,
        printOptions
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = {
            title: modal.card.details.title || '',
            line_1: modal.card.details.line_1 || '',
            line_2: modal.card.details.line_2 || '',
            heading: modal.card.details.heading || '',
            state: modal.card.details.state || '',
            state_details: modal.card.details.state_details || '',
            colorType: modal.card.details.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{NIST_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ data.state || 'NO STATE' }
                    : ${ data.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        return () => {
            dispatch(resetNistMain())
        }
    }, [])

    // a method to return a ColorPreset based on the score.
    const setStatcardColor: (value: number) => ColorPresets = (value) => {
        if (value < 30) return 'red'
        else if (value < 50) return 'amber-1'
        else if (value < 75) return 'amber-2'
        else if (value >= 75) return 'green'
        else return 'grey'
    }

    /** useEffect to merge chapterData and nistDatasets */
    useEffect(() => {
        const nistDatasets = getModalMutation.data?.data || []
        const chapterData = getModalMutation.data?.chapterData || []

        const result: CalculatedChapterData[] = _.map(
            _.cloneDeep(chapterData) as CalculatedChapterData[], (chapterDataObj, index, arr) => {
                // in each chapterDataObj, we want to get the scores of the alineas found in
                // paragraphData. beacuse each alineas has multiple pages, we need to get
                // their averages too.

                if (chapterDataObj.chapterIdentifier === 'NIST') {
                    // in this case, you want to go through the chapterData array
                    // and get all the pages each.

                    const uniquePages: string[] = _.uniq(
                        _.reduce(arr, (storage: string[], { chapterData }) => {
                            _.forEach(chapterData, ({ paragraphData }) => {
                                _.forEach(paragraphData, ({ translationtoNist }) => {
                                    _.forEach(translationtoNist, ({ subcat, page }) => {
                                        storage.push(subcat || page || '')
                                    })
                                })
                            })

                            return storage
                        }, [])
                    )

                    // adding a new property called scoreData
                    // console.log(`got all the pages from all
                    //  chapters for the nist calculations`, uniquePages)
                    chapterDataObj.scoreData = _.map(
                        nistDatasets, ({ lastupdate, nistData }) => {
                            // replace map with creating simplified data from scratch.
                            type NistData = {lastupdate: number, data: Identifier[]}
                            const filtered: NistData[] = []
                            const simplified: NistData[] = []
                            // issue counts are all the instance found but the score is NOT 4.

                            let complianceNumerator: number = 0
                            let complianceDenominator: number = 0
                            let complianceScore: number = 0

                            let coverageNumerator: number = 0
                            let coverageDenominator: number = 0
                            let coverageScore: number = 0

                            /** push more objects to nistData property. */
                            _.forEach(nistData, ({ data }) => {
                                const pages = _.filter(
                                    data,
                                    ({ identifier }) => _.includes(uniquePages, identifier)
                                )

                                filtered.push({
                                    lastupdate: lastupdate,
                                    data: pages
                                })
                            })

                            _.forEach(filtered, ({ data }) => {
                                const removeZeroes = _.filter(
                                    data, ({ score }) => score > 0
                                )

                                simplified.push({
                                    lastupdate: lastupdate,
                                    data: removeZeroes
                                })
                            })

                            // console.log(`data has been filtered to get the pages from all
                            // chapters' paragraphs at timestamp ${ lastupdate }: `, filtered)
                            // console.log('filtered data with removed zeroes: ', simplified)

                            // not using simplified data here.
                            complianceNumerator = _.reduce(simplified, (total, { data }) => {
                                const chapterSum = _.reduce(data, (a, b) => {
                                    a += b.score
                                    return a
                                }, 0)
                                total += chapterSum
                                return total
                            }, 0)

                            complianceDenominator = _.reduce(simplified, (total, { data }) => {
                                const chapterSum = data.length * 4
                                total += chapterSum
                                return total
                            }, 0)

                            // nested reduce with complaince formula,
                            // then average it after adding all value.
                            complianceScore = complianceNumerator / complianceDenominator

                            coverageNumerator = _.reduce(filtered, (total, { data }) => {
                                const chapterTotal = _.filter(data, (a) => Boolean(a.score)).length
                                total += chapterTotal
                                return total
                            }, 0)

                            coverageDenominator = _.reduce(filtered, (total, { data }) => {
                                const chapterSum = data.length
                                total += chapterSum
                                return total
                            }, 0)

                            coverageScore = coverageNumerator / coverageDenominator

                            return ({
                                lastupdate: lastupdate,
                                complianceScore: complianceScore * 100,
                                coverageScore: coverageScore * 100
                            })

                            // end of script.
                        }
                    )

                    chapterDataObj.averageTotalComplianceScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.complianceScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0

                    chapterDataObj.averageTotalCoverageScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.coverageScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0
                } else {
                    _.forEach(chapterDataObj.chapterData, ({ paragraphData }) => {
                        _.forEach(paragraphData, (alineaObj) => {
                            alineaObj.pages = _.map(alineaObj.translationtoNist,
                                ({ subcat, page }) => {
                                    return subcat || page || ''
                                })
                        })
                    })

                    // from here you already have a new property called
                    // .paragraphData[index].pages where its an array of pages
                    // expected value for chapter 2 is 4 alinea scores to be
                    // computed to complianceScore and coverage score
                    chapterDataObj.scoreData = _.map(
                        nistDatasets, ({ lastupdate, nistData }) => {
                            let alineaScores: number[] = []
                            // from here, you need to get the scores
                            // with these pages above.
                            // filter -> flat for each paragraphData
                            // object. paragraphObj.alineaScore

                            _.forEach(
                                chapterDataObj.chapterData,
                                (paragraphObj) => {
                                    _.forEach(paragraphObj.paragraphData, (alineaObj) => {
                                        const filtered: Identifier[] = _.flattenDeep(
                                            _.map(nistData, ({ data }) => {
                                                return _.filter(data, ({ identifier }) => {
                                                    return _.includes(alineaObj.pages, identifier)
                                                })
                                            })
                                        )

                                        // console.log(`Scores retrieved
                                        // with pages flattened: `, filtered)

                                        alineaObj.alineaScore = filtered.length
                                            ? _.reduce(filtered, (a, b) => {
                                                a += b.score
                                                return a
                                            }, 0) / filtered.length
                                            : 0
                                        // you now have the score of oneAlinea
                                        // in each paragraphObj.paragraphData array.
                                    })

                                    // get all alineaScores from each paragraphObj.
                                    // paragraphData array and flat after.
                                    paragraphObj.groupedAlineaScores = _.map(
                                        paragraphObj.paragraphData,
                                        ({ alineaScore }) => {
                                            return alineaScore
                                        }
                                    )

                                    // console.log(`All alineaScores from
                                    // paragraph is grouped into one`,
                                    // paragraphObj.groupedAlineaScores)
                                }
                            )

                            _.forEach(
                                chapterDataObj.chapterData,
                                ({ groupedAlineaScores }) => {
                                    alineaScores = _.concat(alineaScores, groupedAlineaScores)
                                }
                            )

                            // console.log('alineaScores are: ', alineaScores)

                            // calculate complaince and coverage scores;
                            const complianceScore = alineaScores.length
                                ? _.reduce(alineaScores, (a, b) => {
                                    a += b
                                    return a
                                }, 0) / (alineaScores.length * 4)
                                : 0

                            const coverageScore = alineaScores.length
                                ? _.filter(alineaScores, (a) => {
                                    return Boolean(a)
                                }).length / (alineaScores.length)
                                : 0

                            return ({
                                lastupdate: lastupdate,
                                complianceScore: complianceScore * 100,
                                coverageScore: coverageScore * 100
                            })
                        }
                    )

                    chapterDataObj.averageTotalComplianceScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.complianceScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0

                    chapterDataObj.averageTotalCoverageScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.coverageScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0
                }
                return chapterDataObj
            })

        dispatch(setCalculatedChapterData(result))
    }, [
        getModalMutation.data
    ])

    /** useEffect to update statcard header color */
    useEffect(() => {
        if (calculatedChapterData.length) {
            const nistData = _.find(calculatedChapterData,
                ({ chapterIdentifier }) => {
                    return chapterIdentifier === 'NIST'
                }
            )

            // BUG: score already set to zero before loading the data.
            // change modal colors ONLY after.
            const score = nistData?.averageTotalComplianceScore || 0

            dispatch(changeModalColor({
                modal: modal,
                colorType: setStatcardColor(score)
            }))
        }
    }, [calculatedChapterData])

    /** compliance index bar chart */
    const complianceIndexBarEl = useRef<HTMLCanvasElement>(null)

    const ComplianceIndexBar = useMemo(() => {
        const nistObj = _.find(
            calculatedChapterData,
            ({ chapterIdentifier }) => chapterIdentifier === 'NIST'
        )
        const score = nistObj?.averageTotalComplianceScore || 0

        return <div>
            <ComplianceIndexChart
                score={score}
                chartEl={complianceIndexBarEl}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    /** retrieving summary data from two sources. */
    const NistSummary = useMemo(() => {
        const overallObj = calculatedChapterData.find(
            ({ chapterIdentifier }) => (chapterIdentifier === 'NIST')
        )

        const content = <div>
            <span className={'d-inline-block mb-2'}>
                {overallObj?.titleName || overallObj?.chapterName}
            </span>
            <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            overallObj
                                ? <ReadMore
                                    expandText={expandText.NIST}
                                    toggleExpandText={(toggle) => {
                                        dispatch(setExpandText({
                                            key: 'NIST',
                                            value: toggle
                                        }))
                                    }}
                                >
                                    {overallObj.summaryText}
                                </ReadMore>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{NIST_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [
        getModalMutation.data,
        expandText,
        calculatedChapterData
    ])

    const CoverageSummary = useMemo(() => {
        const coverageObj = calculatedChapterData.find(
            ({ chapterIdentifier }) => (chapterIdentifier === 'coverage')
        )

        const content = <div>
            <span className={'d-inline-block mb-2'}>
                {coverageObj?.titleName || coverageObj?.chapterName}
            </span>
            <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            coverageObj
                                ? <ReadMore
                                    expandText={expandText.NIST}
                                    toggleExpandText={(toggle) => {
                                        dispatch(setExpandText({
                                            key: 'coverage',
                                            value: toggle
                                        }))
                                    }}
                                >
                                    {coverageObj.summaryText}
                                </ReadMore>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{NIST_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [
        getModalMutation.data,
        expandText,
        calculatedChapterData
    ])

    /** create a line chart */
    const ComplianceScoresLineChart = useMemo(() => {
        return (
            <ComplianceLineChart
                calculatedChapterData={calculatedChapterData}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        )
    }, [
        calculatedChapterData,
        getModalMutation.data
    ])

    /** now create an array of containers containing a gauge
     * and an index bar chart.
     */

    // an array of objects with the gaugeEl and a indexEl
    const scoreContainersEls = useRef<{
        gaugeEl: React.RefObject<HTMLCanvasElement>,
        indexEl: React.RefObject<HTMLCanvasElement>,
    }[]>([])

    const GaugeScoreContainers = useMemo(() => {
        // get calculated chapter data but filter out the nist chapterIdentifier,
        const filtered = _.filter(
            calculatedChapterData, ({ chapterIdentifier }) => {
                return !_.includes(['NIST', 'coverage'], chapterIdentifier)
            }
        )
        return _.map(filtered, (obj, index) => {
            const key = [
                'gaugeScore-', index
            ].join('')

            const Title = <span className={'d-inline-block mb-2'}>
                {obj.titleName || obj.chapterName}
            </span>

            scoreContainersEls.current[index] = {
                gaugeEl: createRef(),
                indexEl: createRef()
            }

            const gaugeChartEl = scoreContainersEls.current[index].gaugeEl

            const CoverageContainer = <div>
                <Text size={'sm'} className={'d-inline-block mb-2'}>
                    {NIST_TEXT.SECTIONS.GAUGE_CONTAINERS.COVERAGE}
                </Text>
                <CoverageGauge
                    activeValue={obj.averageTotalCoverageScore}
                    chartEl={gaugeChartEl}
                    isLoading={getModalMutation.isLoading}
                    isSuccess={getModalMutation.isSuccess}
                    error={getModalMutation.error}
                />
            </div>

            const indexChartEl = scoreContainersEls.current[index].indexEl

            const ComplianceContainer = <div>
                <Text size={'sm'} className={'d-inline-block mb-2'}>
                    {NIST_TEXT.SECTIONS.GAUGE_CONTAINERS.COMPLIANCE}
                </Text>
                <ComplianceIndexChart
                    score={obj.averageTotalComplianceScore}
                    chartEl={indexChartEl}
                    isLoading={getModalMutation.isLoading}
                    isSuccess={getModalMutation.isSuccess}
                    error={getModalMutation.error}
                />
            </div>
            return <div className={'col-12 col-lg-6 mb-3'} key={key} onClick={() => {
                // add a modal.
                dispatch(setDetailsStartDate(currentParams.ranges.start))
                dispatch(setDetailsEndDate(currentParams.ranges.end))

                const detailsContent:NistDetailsForm = {
                    selectedChapter: obj
                }

                const serviceTypeFormData:ServiceTypeFormData = {
                    nist: {
                        details: detailsContent
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    card: modal.card,
                    operation: 'DETAILS',
                    serviceTypeFormData: serviceTypeFormData,
                    isBorderWide: true
                }))
            }}>
                {Title}
                <Container bgIndex={2} className={'px-3 py-2'}>
                    <div className={'row'}>
                        <div className={'col-sm-8 col-12'}>
                            {CoverageContainer}
                        </div>
                        <div className={'col-sm-4 col-12'}>
                            {ComplianceContainer}
                        </div>
                    </div>
                </Container>
            </div>
        })
    }, undefined)

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col-12 col-md-9 col-lg-10'}>
                    {Statcard}
                </div>
                <div className={'col-12 col-md-3 col-lg-2'}>
                    {ComplianceIndexBar}
                </div>
            </div>

            <div className={'min-width-fix'}>
                {NistSummary}

            </div>

            <div className={'min-width-fix'}>
                {CoverageSummary}

            </div>

            <div className={'min-width-fix'}>
                {ComplianceScoresLineChart}
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-3 align-items-end'} >
                    {GaugeScoreContainers}

                </div>
            </div>

        </div>
    )
}

export default NistModal

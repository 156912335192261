
import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as MDR_MESSAGE,
    ISSUE_HISTORY_ROWS
} from '@constants/dashboard/soc/mdr/main'
import { getColorFromStylesheet } from '@constants/main/method'
import {
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING,
    DEFAULT_LEGEND_BOX_WIDTH
} from '@constants/main/root'
import {
    IssueHistoryResponse
} from '@interfaces/dashboard/soc/mdr/main'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import {
    Container,
    SpinnerContainer,
    ErrorMessage
} from '@styles/components'
import {
    ArcElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    DoughnutController,
    Tooltip,
    BarController,
    BarElement
} from 'chart.js'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo
} from 'react'
import { createStylesheet } from '@styles/themes'

import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>
    chartInstance: Chart<'doughnut', {
        issueCount: number;
    }[], string> | undefined,
    setChartInstance: React.Dispatch<React.SetStateAction<Chart<'doughnut', {
        issueCount: number;
    }[], string> | undefined>>
    issueHistoryData: IssueHistoryResponse| undefined,
    issueHistoryPreviousData: IssueHistoryResponse| undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssueHistoryChart = ({
    chartEl, chartInstance, setChartInstance,
    issueHistoryData, issueHistoryPreviousData, isLoading, isSuccess, error
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const currentData = issueHistoryData?.data || {
            noIssue: 0,
            lowIssue: 0,
            mediumIssue: 0,
            highIssue: 0,
            severeIssue: 0,
            criticalIssue: 0
        }

        // create data using ISSUE_HISTORY_ROWS as template.
        const data = _.map(
            _.cloneDeep(ISSUE_HISTORY_ROWS), (obj) => {
                return {
                    ...obj,
                    docCount: currentData[obj.value] || 0
                }
            }
        )

        let graph: Chart<'doughnut', {issueCount: number}[], string>

        const datasets: typeof graph.data.datasets = [{
            data: _.map(currentData, (value) => {
                return {
                    issueCount: value
                }
            }),
            // assign colors by severity
            backgroundColor: _.map(data, (obj) => {
                return getColorFromStylesheet(stylesheet.style, obj.color)
            }),
            borderWidth: 0,
            parsing: {
                key: 'issueCount'
            }
        }]

        if (chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'doughnut',
                data: {
                    labels: _.map(data, (obj) => obj.label),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                boxWidth: DEFAULT_LEGEND_BOX_WIDTH,
                                color: stylesheet.mode.fontColor
                            },
                            align: 'center',
                            position: 'bottom'
                            /** BUGS found when updating chart elements inside
                             * event handlers for all plugins. They do not render
                             * properly even if the script is correct.
                             *
                             * Tested this vs using outside elements.
                             */
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                }
                            }
                        }
                    }
                }

            })

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.height = CHART_HEIGHT.md
            chartEl.current.style.maxHeight = CHART_HEIGHT.md

            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [issueHistoryData])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row my-2'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.ISSUE_HISTORY}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssueHistoryChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssueHistoryChart

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as MDR_MESSAGE,
    ISSUE_ALERTED_COLUMNS
} from '@constants/dashboard/soc/mdr/main'
import {
    DATE_FORMAT_TIME,
    MESSAGE
} from '@constants/main/root'
import {
    MdrDetailsForm,
    MonitorModal,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    IssueAlert,
    IssueAlertedResponse
} from '@interfaces/dashboard/soc/mdr/main'
import { ColorPresets } from '@interfaces/main/root'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/mdr/main'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/mdr/details'
import {
    Container,
    SpinnerContainer,
    Table,
    ErrorMessage
} from '@styles/components'
import { format } from 'date-fns'
import _ from 'lodash'
import React, {
    useMemo
} from 'react'
import uniqueString from 'unique-string'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    data: IssueAlertedResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssueAlertedTable = ({
    data, isLoading, isSuccess, error,
    modal, addModal, closeModal, changeModalColor
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)

    const dispatch = useAppDispatch()

    const DataTable = useMemo(() => {
        const currentData = data?.data || []

        const cellBody = (
            dataObject: IssueAlert,
            property: keyof IssueAlert
        ) => {
            let cellContent: IssueAlert[keyof IssueAlert] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'timestamp':
                    cellContent = format(new Date(dataObject[property]), DATE_FORMAT_TIME)
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(ISSUE_ALERTED_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'issueAlerted-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        currentData.length
                            ? _.map(currentData, (dataObject, rowIndex) => {
                                const onClick = () => {
                                    dispatch(setDetailsStartDate(currentParams.ranges.start))
                                    dispatch(setDetailsEndDate(currentParams.ranges.end))

                                    const detailsContent:MdrDetailsForm = {
                                        event_type: '',
                                        lat: '',
                                        lon: '',
                                        q: '',
                                        signature: dataObject.title,
                                        src_ip: dataObject.src_ip
                                    }

                                    const serviceTypeFormData:ServiceTypeFormData = {
                                        mdr: {
                                            details: detailsContent
                                        }
                                    }

                                    dispatch(addModal({
                                        id: uniqueString(),
                                        open: true,
                                        card: modal.card,
                                        operation: 'DETAILS',
                                        serviceTypeFormData: serviceTypeFormData,
                                        isBorderWide: true
                                    }))
                                }

                                return (
                                    <tr
                                        key={'issueAlerted-row-' + rowIndex}
                                        onClick={onClick}
                                    >
                                        {
                                            _.map(ISSUE_ALERTED_COLUMNS, (column, cellIndex) => {
                                                return (
                                                    <td key={[
                                                        'issueAlerted-cell-' + rowIndex +
                                            '-' + cellIndex
                                                    ].join('')}
                                                    >
                                                        {cellBody(dataObject, column.value)}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                            : <tr>
                                <td
                                    colSpan={ISSUE_ALERTED_COLUMNS.length}
                                    className={'text-center'}>
                                    {MESSAGE.DATA.EMPTY_DATA}
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        data,
        currentParams.ranges
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.ISSUES_ALERTED}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssueAlertedTable.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssueAlertedTable


import {
    useGetIssueAlertedMutation,
    useGetModalHeaderMutation,
    useGetIssueCountMutation,
    useGetIssueHistoryMutation,
    useGetIssueTableMutation
} from '@apis/dashboard/soc/mdr-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as MDR_MESSAGE,
    TEXT as MDR_TEXT
} from '@constants/dashboard/soc/mdr/main'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { MDR_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import {
    DetailedSearchParams,
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/mdr/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectPrintOptions,
    selectSearchParams,
    selectTableData,
    setEndDate,
    selectFixedCollapsibles,
    setLogo,
    setRefetch,
    setStartDate,
    toggleCollapsible,
    resetMdrMain,
    setCurrentParams,
    selectCurrentParams
} from '@slices/dashboard/soc/mdr/main'
import { push } from 'connected-react-router'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    Container,
    DashboardStyledComponents as Dashboard,
    DashboardFooter as Footer,
    SpinnerContainer,
    Text,
    CollapsibleText
} from '@styles/components'
import Tippy from '@tippyjs/react'
import { Chart } from 'chart.js'
import {
    differenceInSeconds,
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaFileCsv,
    FaDirections,
    FaFilePdf,
    FaPlus,
    FaMinus
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import IssueDataTable from '@features/dashboard/soc/mdr/IssueDataTable'
import IssueHistoryTable from '@features/dashboard/soc/mdr/IssueHistoryTable'
import IssueHistoryChart from '@features/dashboard/soc/mdr/IssueHistoryChart'
import IssueCountChart from '@features/dashboard/soc/mdr/IssueCountChart'
import IssueAlertedTable from '@features/dashboard/soc/mdr/IssueAlertedTable'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'

const MDRModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const setMdrDetailedDashboardParams = rootContext.setMdrDetailedDashboardParams

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const tableData = useAppSelector(selectTableData)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** will be initiailized multiple times. */
    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalHeaderMutation()

    const [getIssueHistoryPrevious, getIssueHistoryPreviousMutation] = useGetIssueHistoryMutation()
    const [getIssueHistory, getIssueHistoryMutation] = useGetIssueHistoryMutation()
    // reason for having ip address is to filter out new and reappear results.
    const [getIssueAlerted, getIssueAlertedMutation] = useGetIssueAlertedMutation()
    const [getIssueCount, getIssueCountMutation] = useGetIssueCountMutation()
    const [getIssueTable, getIssueTableMutation] = useGetIssueTableMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getIssueHistoryMutation.error) {
            console.error(getIssueHistoryMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueHistoryMutation.error])

    useEffect(() => {
        if (getIssueHistoryPreviousMutation.error) {
            console.error(getIssueHistoryPreviousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueHistoryPreviousMutation.error])

    useEffect(() => {
        if (getIssueAlertedMutation.error) {
            console.error(getIssueAlertedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueAlertedMutation.error])

    useEffect(() => {
        if (getIssueCountMutation.error) {
            console.error(getIssueCountMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueCountMutation.error])

    useEffect(() => {
        if (getIssueTableMutation.error) {
            console.error(getIssueTableMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueTableMutation.error])

    /** after useEffect to throw errors, create fetchData script.
     * NEW CHANGE from old system. make each call a separate script.
     * Was done because client requested to individually have refresh button on
     * each chart.
     *
     * UPDATE: only apply this case WHEN it needs to be. Because this module
     * has one refresh button that executes all api calls at once, put it all in once place.
    */

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueTable = () => {
        const unsubscribeMutation = getIssueTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueHistory = () => {
        const unsubscribeMutation = getIssueHistory({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueHistoryPrevious = () => {
        const unsubscribeMutation = getIssueHistoryPrevious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueAlerted = () => {
        const unsubscribeMutation = getIssueAlerted({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueCount = () => {
        const unsubscribeMutation = getIssueCount({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const newRanges = getUtcRanges(searchParams.ranges)
                    const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                        authToken: token.value,
                        deviceid: modal.card.deviceid,
                        in_face: modal.card.in_face,
                        q: '',
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchData = () => {
        unsubscribeGetModalHeader()
        unsubscribeGetIssueTable()
        unsubscribeGetIssueHistory()
        unsubscribeGetIssueHistoryPrevious()
        unsubscribeGetIssueAlerted()
        unsubscribeGetIssueCount()

        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueHistoryPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueHistoryPreviousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueAlertedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueCountPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            in_face: modal.card.in_face,
                            q: '',
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalHeaderPromise = getModalHeader(requestData)

                        getIssueHistoryPromise = getIssueHistory(requestData)

                        /** get the difference of the current ranges as preqrequisite to
                         * the previous range api call.
                         */

                        const difference = Math.abs(differenceInSeconds(
                            fromUnixTime(newRanges.start), fromUnixTime(newRanges.end)
                        ))
                        getIssueHistoryPreviousPromise = getIssueHistoryPrevious({
                            ...requestData,
                            time_from: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.start),
                                    { seconds: difference }
                                )
                            ).toString(),
                            time_to: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.end),
                                    { seconds: difference }
                                )
                            ).toString()
                        })
                        getIssueAlertedPromise = getIssueAlerted(requestData)
                        getIssueCountPromise = getIssueCount(requestData)
                        getIssueTablePromise = getIssueTable(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
            getIssueTablePromise && getIssueTablePromise.abort()
            getIssueHistoryPromise && getIssueHistoryPromise.abort()
            getIssueHistoryPreviousPromise && getIssueHistoryPreviousPromise.abort()
            getIssueAlertedPromise && getIssueAlertedPromise.abort()
            getIssueCountPromise && getIssueCountPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalHeaderMutation.isSuccess &&
            getIssueHistoryPreviousMutation.isSuccess &&
            getIssueHistoryMutation.isSuccess &&
            getIssueAlertedMutation.isSuccess &&
            getIssueCountMutation.isSuccess &&
            getIssueTableMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalHeaderMutation.isSuccess,
        getIssueHistoryPreviousMutation.isSuccess,
        getIssueHistoryMutation.isSuccess,
        getIssueAlertedMutation.isSuccess,
        getIssueCountMutation.isSuccess,
        getIssueTableMutation.isSuccess
    ])

    const setParams = () => {
        /** keep in mind that you want to get the startDate and endDate values
         * of this modal.
        */
        const params: Omit<DetailedSearchParams, 'refetch'> = {
            /** use currentParams */
            ranges: {
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            },
            card: {
                details: {
                    ...modal.card.details,
                    colorType: getModalHeaderMutation.data?.data.colorType ||
                            modal.card.details.colorType || 'grey'
                },
                deviceid: modal.card.deviceid,
                in_face: modal.card.in_face,
                service_type: modal.card.service_type
            },
            q: '',
            boolList: []
        }

        const newRoute = _.split(MDR_DETAILED_DASHBOARD_ROUTES.MAIN.link, ':deviceid')

        setMdrDetailedDashboardParams(params)

        dispatch(push([
            newRoute[0],
            modal.card.deviceid,
            newRoute[1]
        ].join('')))
    }

    /** redirect button to detailed dashboard */

    const DetailedDashboardButton = (
        <span
            onClick={() => {
                setParams()
            }}
            className={'icon pointer mb-2 d-inline-block ms-2'}
        >
            <FaDirections/>
        </span>
    )

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data
        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && data?.data.heading === '0 Total Events') {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 1 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))
                dispatch(setRefetch(true))

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalHeaderMutation.isLoading ||
            getIssueHistoryPreviousMutation.isLoading ||
            getIssueHistoryMutation.isLoading ||
            getIssueAlertedMutation.isLoading ||
            getIssueCountMutation.isLoading ||
            getIssueTableMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update mdr bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [
        getModalHeaderMutation,
        getIssueHistoryPreviousMutation,
        getIssueHistoryMutation,
        getIssueAlertedMutation,
        getIssueCountMutation,
        getIssueTableMutation
    ])

    const CsvExportButton = useMemo(() => {
        const ipAddressData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            modal.card.service_type,
            '_', modal.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = ipAddressData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                filename={filename}
                onClick={() => {
                    if (disableExport) return false
                }}
                data={ipAddressData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        modal.card,
        currentParams.ranges
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={MDR_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {MDR_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalHeaderMutation.data
        const hasNoData = data?.data.heading === '0 Total Events'

        return <div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalHeaderMutation,
        queueId,
        queues,
        printOptions,
        currentParams
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.REDIRECTS.MDR_DETAILED}</div>}>
                    <div className={'d-inline-block'}>{DetailedDashboardButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        DetailedDashboardButton,
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        type Header = ModalHeaderResponse['data'] & { title: string }
        const data: Header = {
            title: modal.card.details.title || '',
            ...getModalHeaderMutation.data?.data || {
                line_1: modal.card.details.line_1 || '',
                line_2: modal.card.details.line_2 || '',
                heading: modal.card.details.heading || '',
                colorType: modal.card.details.colorType || 'grey'
            }
        }

        let issueCount = 0

        if (getIssueHistoryMutation.data) {
            issueCount = getIssueHistoryMutation.data.data.criticalIssue +
            getIssueHistoryMutation.data.data.highIssue +
            getIssueHistoryMutation.data.data.severeIssue

            // replace the zero with the combined count of severeIssue to highIssue.
            data.line_2 = [
                issueCount,
                _.join(_.drop(_.split(data.line_2, ' '), 1), ' ')
            ].join(' ')
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ modal.card.details.state || 'NO STATE' }
                    : ${ modal.card.details.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalHeaderMutation,
        getIssueHistoryMutation,
        getIssueHistoryPreviousMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        dispatch(changeModalColor({
            modal: modal,
            colorType: data?.colorType || modal.card.details.colorType || 'grey'
        }))
    }, [
        modal.card,
        getModalHeaderMutation
    ])

    /** CREATE COMPONENTS BY EVENT TYPE HERE. */
    useEffect(() => {
        return () => {
            dispatch(resetMdrMain())
        }
    }, [])

    const issueHistoryChartEl = useRef<HTMLCanvasElement>(null)
    /** moving chart instance to useState so other components can access
     * chart data.
     */
    const [
        issueHistoryChartInstance,
        setIssueHistoryChartInstance
    ] = useState<Chart<'doughnut', {issueCount: number}[], string>>()

    const IssueHistory = useMemo(() => {
        return <div className={'row align-items-center'}>
            <div className={'col-md-8 col-12'}>
                <IssueHistoryTable
                    chartEl={issueHistoryChartEl}
                    chartInstance={issueHistoryChartInstance}
                    setChartInstance={setIssueHistoryChartInstance}
                    issueHistoryData={getIssueHistoryMutation.data}
                    issueHistoryPreviousData={getIssueHistoryPreviousMutation.data}
                    isLoading={getIssueHistoryMutation.isLoading}
                    isSuccess={getIssueHistoryMutation.isSuccess}
                    error={getIssueHistoryMutation.error}
                />
            </div>
            <div className={'col-md-4 col-12'}>
                <IssueHistoryChart
                    chartEl={issueHistoryChartEl}
                    chartInstance={issueHistoryChartInstance}
                    setChartInstance={setIssueHistoryChartInstance}
                    issueHistoryData={getIssueHistoryMutation.data}
                    issueHistoryPreviousData={getIssueHistoryPreviousMutation.data}
                    isLoading={getIssueHistoryMutation.isLoading}
                    isSuccess={getIssueHistoryMutation.isSuccess}
                    error={getIssueHistoryMutation.error}
                />
            </div>
        </div>
    }, undefined)

    const IssueAlerted = useMemo(() => {
        return (
            <IssueAlertedTable
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getIssueAlertedMutation.data}
                isLoading={getIssueAlertedMutation.isLoading}
                isSuccess={getIssueAlertedMutation.isSuccess}
                error={getIssueAlertedMutation.error}
            />
        )
    }, undefined)

    const issueCountChartEl = useRef<HTMLCanvasElement>(null)
    const [
        issueCountChartInstance,
        setIssueCountChartInstance
    ] = useState<Chart<'bar', number[], string>>()

    const IssueCount = useMemo(() => {
        return (
            <IssueCountChart
                chartEl={issueCountChartEl}
                chartInstance={issueCountChartInstance}
                setChartInstance={setIssueCountChartInstance}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                issueCountData={getIssueCountMutation.data}
                isLoading={getIssueCountMutation.isLoading}
                isSuccess={getIssueCountMutation.isSuccess}
                error={getIssueCountMutation.error}
            />
        )
    }, undefined)

    const IssueTable = useMemo(() => {
        return (
            <IssueDataTable
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getIssueTableMutation.data}
                isLoading={getIssueTableMutation.isLoading}
                isSuccess={getIssueTableMutation.isSuccess}
                error={getIssueTableMutation.error}
            />
        )
    }, undefined)

    /** reset data on unmount */
    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {Statcard}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <span className={'d-inline-block mb-2'}>
                    {MDR_TEXT.SECTIONS.ISSUE_HISTORY}
                </span>
                {IssueHistory}
            </div>

            <div className={'min-width-fix'}>
                <span className={'d-inline-block mb-2'}>
                    {MDR_TEXT.SECTIONS.ISSUES_ALERTED}
                </span>
                {IssueAlerted}
            </div>

            <div className={'min-width-fix mb-3'}>
                <CollapsibleText className={'mb-2'}>
                    <span>
                        {MDR_TEXT.SECTIONS.ISSUE_TABLE}
                    </span>
                    {/* first time adding a collapse component for a table */}
                    <Text size={'xs'} onClick={() => {
                        dispatch(toggleCollapsible({
                            key: 'issueCount',
                            value: !fixedCollapsibles.issueCount
                        }))
                    }} className={'icon pointer'}>
                        {fixedCollapsibles.issueCount ? <FaMinus/> : <FaPlus/> }
                    </Text>
                </CollapsibleText>
                {IssueCount}
                {/* table stuff */}
                {IssueTable}
            </div>
            <Footer.Main className={'justify-content-center mb-3 row'}>
                <div
                    className={'col-12 col-md-6'}
                >
                    <Footer.DetailedDashboardButton
                        onClick={() => {
                            setParams()
                        }}
                        className={'pointer py-4 text-center text-uppercase'}
                    >
                        {MONITOR_TEXT.CARD.DETAILED_DASHBOARD}
                    </Footer.DetailedDashboardButton>
                </div>
            </Footer.Main>
        </div>
    )
}

export default MDRModal

/**
 * YOU START HERE.
 * Documentation for the new developer:
 *
 * Repo link: https://gitlab.r007.nl/bluedog/watchdog-3
 *
 * This is Watchdog-3, a third version of the Watchdog dashboard that allows you to add soc data,
 * account data, virtual-ciso, and view the dashboard monitors to generate reports.
 * The logic for generating reports is in a separate repository called, PDF Geneartion 31.
 * Link is: https://gitlab.r007.nl/bluedog/pdf-generation-31
 *
 * NOTE: Production is currently
 *
 * Before we proceed, kindly take note of the following:
 * (GIT CONNECTION TEST AND REPOSITORY CLONING)
 *
 * - PLEASE READ THIS ITEM BEFORE PERFORMING ANY ACTION,
 *
 * - For security measures, make sure you have 2FA enabled and included on your mobile device
 * to use google authenticator.
 *
 * - Make sure in the Emails tab, link it to your company account so that you can get any updates
 * from gitlab.
 *
 * - Follow general instructions to add an SSH key and include it to your local computer.
 *
 *  - After being granted access to the watchdog repository, kindly confirm your ssh connection
 * from your local computer.
 *
 * - NOTE: branch structure at the moment is root -> dev -> test -> acc. master is meant for
 * pushing your files without any git related files in as final reference. As of reading this,
 * ROOT BRANCH is the one that has the latest commit history so clone that particular branch.
 * The associated pipeline files are in the dev branch since the pipeline is running there.
 *
 * I really tried everything else but because you need the gitlab user credentials to go into
 * the container registry, stick to this.
 *
 * - Since you are using Gitlab, you need to have a project access token so that your
 * pipelines will be able to run without any authentication issues in your config. (.gitlab-ci.yml)
 *
 * - If you go to your user settings and select the access tokens tab, you will see that
 * you need to have a project access token active. Kindly follow general instructions on creating
 * one. This token can and will be used in all projects if you are the one pushing your commits
 * to the remote repository. You can do a test in the gitlab configuration through a test job
 * and display the predefined variable CI_REGISTRY_USER as long as you have a container registry
 * set up in your specific repository furthermore For better assurance, also use GITLAB_USER_NAME.
 *
 * Copy that specific project access token and add it to your project's settings in the CI/CD tab.
 * Make sure that it is not protected. In the project, it is named: CI_PROJECT_ACCESS_TOKEN.
 *
 * - For the runner, you need to have access to the cloud instance named DigitalOcean where you can
 * check IF the gitlab instance is running. (I don't recall being the one responsible for any
 * related infrastructure. I was only instructed to learn about Gitlab on my own.)
 * To easily create a project-based runner, (even though I've made one already for you), I'll just
 * list down the directions I did according to Gitlab's official instructions (adding extra steps)
 *
 * AGAIN: i already did this, i'm just sharing how i did it.
 * 1.) after creating your project-based runner, you are given a text snippet to keep before it
 * disappears. keep it in a notepad.
 * 2.) in your local machine, install doctl and chocolatey (so you can install helm) too. The goal
 * here first is to make sure your local machine is connected to the digitalOcean's instance
 * where it also contains kubernetes (where one is already made prior to reading this.)
 * 3.) you want to do the command "doctl auth init" first. NOTE: you must have access to the
 * digitalOcean instance to create a PERSONAL TOKEN. meaning you have to create a new token
 * and keep it somewhere in your local machine because you'll never get to see it again.
 * 4.) typing the command "doctl auth list" will show that you are authenticated from your
 *  local machine. Now, make sure you create the namespace for where to store your gitlab-runner
 * instance. (in this case I named gitlab-runner-instance-2). Then get the values.yaml file
 * from the "Configuring GitLab Runner using the Helm Chart" section and go to the gitlabUrl
 * section and replace it with the url of the gitlab instance.
 * Run the ff command from site:
 *
 *
 * helm install --namespace gitlab-runner-instance-2
 * gitlab-runner -f <VALUES.YAML> gitlab/gitlab-runner
 *
 * this will create the resources to make your runner active.
 *
 * Also, you can't run your gitlab instance without digitalOcean running an instance of kubernetes
 * where we deploy our applications in pods. (Technically deployments, running replica sets,
 * and then managing pods.)
 *
 * You can use the Kubernetes dashboard to monitor the status of your resources and identify errors
 * through event logs OR by logging into the pods themselves for a more direct output. Sometimes,
 * you might encounter container registry errors or project related errors due to NPM dependencies
 * installed associated with the specific image used in your Dockerfile. I highly advise to
 * be very careful with how your pipelines are running and checking if the site itself is active as
 * a good measure.
 *
 * Also something I took care of:
 * If you are getting a serviceaccount permission error, this means you need to create YAML
 * resources to proceed with the pipeline. In my case, I had to create a Role and RoleBinding
 *  resource
 * to the default serviceaccount of the gitlab-runner-instance 2 namespace. And a clusterRole and
 * clusterRole binding for said account too. NOTE: be careful when granting permission in
 * both levels.
 * please be sure to gradually grant permissions when necessary.
 *
 * I also had to enable oontainer registry by going to the console of the droplet,
 *
 * I also updated the .gitlab-ci.yml to make sure that we are in the namespace we are in.
 *
 * LASTLY, check your firewall configuration to allow port 5050 for the container registry
 *
 */

/**
 *  Note: These are disclaimers regarding the code design for Watchdog-3.
 *
 * Used the latest version of Node for Watchdog-3 and the PDF report. 16.14.2
 * FOR THE OLD VERSION OF WATCHDOG THAT'S BEING USEDM. 10.24.1
 *
 * This project was created
 * using the React framework, specifically the reduxjs-toolkit for its global state feature and
 * control regardless of what component you're in. It also uses typescript, styled-components,
 * immutability, and react-components provided by NPM.
 *
 * Because there are so many files to look over, thanks to Visual Studio Code and the ff extensions
 * installed, this should help you check on reference on variables especially on the slices
 * included in the react-redux store. I highly advice to go over one line at a time for it will help
 * you sum it all up together.
 *
 * Extensions installed: Docker, ESLint, Javascript and Typescript Nightly, and
 * vscode-styled-components.
 *
 * Also, check the API page in these npm modules used to check on other props that you can use.
 */

import {
    useAppSelector
} from '@app/hook'
import { store } from '@app/store'
import IdleWindow from '@features/main/IdleWindow'
import loginRoutes from '@routes/main/login-routes'
import { history } from '@slices/main/router'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import RootStyle from '@styles/rootStyle'
import { createStylesheet } from '@styles/themes'
import 'bootstrap/dist/css/bootstrap.css'
import {
    ConnectedRouter
} from 'connected-react-router'
import _ from 'lodash'
import 'rc-pagination/assets/index.css'
import 'rc-switch/assets/index.css'
import React, {
    useMemo
} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'react-responsive-modal/styles.css'
import {
    Route,
    Switch
} from 'react-router'
import {
    ToastContainer
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { ThemeProvider } from 'styled-components'
import 'tippy.js/dist/border.css'
import 'tippy.js/dist/tippy.css'

import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet/dist/leaflet.css'

import L from 'leaflet'

import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import marker from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import 'react-circular-progressbar/dist/styles.css'

import MutationProvider from '@root/MutationProvider'
import QueueController from '@features/main/print/QueueController'

import {
    selectHomeMain
} from '@slices/main/print/section'
import {
    selectQueues
} from '@slices/main/print/queue'
const DefaultIcon = L.icon({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
})

L.Marker.prototype.options.icon = DefaultIcon

/** The following comments you see moving forward are simply personal notes but are important to
 * keep the application running and to avoid render crashes.
 */

/**
 * proceed with root tree idea with two conditions.
 * if you are going to use the print system or the default system.
 * Mobile design is mandatory. You can also decide to create an entirely new
 * responsive container as long as the content like text boxes, containers
 * and interfaces are inherited from one source.
 * ROOT SYSTEM - requires a sidebar system AND the primary component to show. It's only these two.
 * the sidebar system will only show if PRINT is disabled and if user is authenticated.
 * to cater tabs again, the default browser will be utilized. so each tab is an
 * instance of the system. open in a new tab option is encouraged because this is
 * a data-driven single page component for authentication, the response data
 * will be kept in the store AND in localStorage to defend from
 * any attempted localStorage manipulation. The token from the store will always be used.
 * In every refresh, the token will be validated through the localStorage and
 * set in the store if it is still valid.
*/

/**
 * creating a context outside the root component. can start of with a function
 * following type rules. Since functions are not serializable data, you can
 * use a context provider as long as the data is not subject to change.
 * */

/** typescript gut to explicitly determine the type of the element to render as. */
export const Root = () => {
    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    const print = useAppSelector(selectHomeMain)
    const queues = useAppSelector(selectQueues)
    const routes = useMemo(() => {
        /** concat the routes and map them. */
        const routes = _.concat(
            [],
            loginRoutes
        )

        /** ideally, if you want to generate a key
         * for components in a map, you need stable keys
         * retrieved from a database since that data will
         * always be associated with it.
         *
         * in this case where you aren't using data from
         * the backend, you can use a string prefix + index
        */

        return (
            <Switch>
                {
                    routes.map((routeObj, index) => {
                        return (
                            <Route
                                key={['rootRoute-', index].join('')}
                                exact={routeObj.exact}
                                path={routeObj.path}
                                component={routeObj.component}
                            />
                        )
                    })
                }
            </Switch>
        )
    }, [])

    /**
     * because we have a theme and style settings change, we have to add a
     * theme provider and assign properties. Call the style name and then
     * the theme. */
    return (
        <ThemeProvider theme={createStylesheet(style, mode)}>
            <MutationProvider>
                <RootStyle />
                <ToastContainer />
                {/* idle logic component to logout users when session timer expires */}

                {/** from here where a bug is found when generating reports
                 * IDLE TIMER still exists. Kindly remove idle timer when print is enabled.
                 *
                 * To avoid session expirations from the front-end side, just check
                 * if the queue property from the print state IS lengthy.
                 */}
                {
                    ((print.isPrinting) || queues.length)
                        ? <></>
                        : <IdleWindow />
                }
                {/* place print report component to global scale. Even if you logged out,
                you can still get your report.
                */}
                {/* Root Component aka Router */}
                <QueueController />
                <ConnectedRouter history={history}>
                    {routes}
                </ConnectedRouter>
            </MutationProvider>
        </ThemeProvider>
    )
}

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>
    , document.getElementById('root')
)

// will be kept in reserve until i can see a use for service workers.
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
    })
}

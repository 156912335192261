import {
    MutationContext
} from '@root/MutationProvider'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import {
    toast
} from 'react-toastify'
/** OTHER FILE IMPORTS */
import { useDoFinalizeReportMutation } from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as EVENT_MESSAGE,
    TEXT as EVENT_TEXT
} from '@constants/watchdog/soc-data/event'
import {
    ReportModal
} from '@interfaces/watchdog/soc-data/event'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import { REPORT_ROUTES } from '@constants/main/routes'
import {
    push
} from 'connected-react-router'

const DoFinalizeReview = ({ modal, addModal, closeModal } : {
    modal: ReportModal,
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    closeModal: ActionCreatorWithPayload<ReportModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [
        doFinalizeReport, doFinalizeReportMutation
    ] = useDoFinalizeReportMutation()

    const finalizeData = useCallback(async () => {
        const newToken = await revalidateToken()
        if (modal.formData?.finalizeReportConfirm) {
            const values = modal.formData.finalizeReportConfirm.formValues

            doFinalizeReport({
                /** id for the entire report. you can only get this from the url params. */
                reportId: values.id,
                authToken: newToken
            })

            dispatch(push(
                REPORT_ROUTES.CREATED_REPORTS.link
            ))
        }
    }, [])

    useEffect(() => {
        if (doFinalizeReportMutation.data) {
            const data = doFinalizeReportMutation.data
            if (data.message) {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(closeModal(modal))
            } else {
                toast.error(
                    EVENT_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [doFinalizeReportMutation.data])

    useEffect(() => {
        if (doFinalizeReportMutation.error) {
            console.error(doFinalizeReportMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doFinalizeReportMutation.error])

    const FinalizeButton = useMemo(() => {
        const buttonContent = doFinalizeReportMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        EVENT_TEXT.FINALIZE_REPORT.FORM.LOADING_BUTTON
                    }</span>
                </SpinnerContainer>
            )
            : EVENT_TEXT.FINALIZE_REPORT.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={finalizeData}
                disabled={doFinalizeReportMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [doFinalizeReportMutation.isLoading])

    return (
        <div>
            <h5>{EVENT_TEXT.FINALIZE_REPORT.TITLE}</h5>
            <small className={'d-block my-4'}>
                {EVENT_TEXT.FINALIZE_REPORT.MESSAGE}
            </small>
            <div className={'row justify-content-end'}>
                <div className={'col-auto mb-2 mb-md-0 text-center'}>
                    {FinalizeButton}
                </div>
                <div className={'col-auto'}>
                    <Button mode={'secondary'} onClick={() => {
                        dispatch(closeModal(modal))
                    }}>{TEXT.MODAL.CLOSE}</Button>
                </div>
            </div>
        </div>
    )
}

export default DoFinalizeReview


import {
    useAppSelector
} from '@app/hook'
import {
    MEAN_MITIGATION_COLUMNS,
    MESSAGE as EVM_MESSAGE,
    TEXT as EVM_TEXT
} from '@constants/dashboard/tac/evm'
import {
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    MeanMitigation,
    Severities
} from '@interfaces/dashboard/tac/evm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams,
    selectFixedCollapsibles
} from '@slices/dashboard/tac/evm'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    PageBreakInside,
    SpinnerContainer,
    Table
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'

interface ComponentProps {
    data: MeanMitigation[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
    isPrint?:boolean
}

const MeanMitigationTimeChart = ({
    isPrint, data, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)

    useEffect(() => {
        /** evmediately register chartjs plugins */
        Chart.register(LineController, LineElement, Legend, PointElement,
            CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'line', { meanMitigation: MeanMitigation, x: Date; y: number; }[], Date>

        const vulnerabilities: {
            label: string,
            threat: Severities,
            color: string
        }[] = [
            {
                label: 'Critical',
                threat: 'critical',
                color: stylesheet.style.colorPresets.red
            },
            {
                label: 'High',
                threat: 'high',
                color: stylesheet.style.colorPresets['amber-1']
            },
            {
                label: 'Medium',
                threat: 'medium',
                color: stylesheet.style.colorPresets['amber-2']
            },
            {
                label: 'Low',
                threat: 'low',
                color: stylesheet.style.colorPresets.yellow
            }
        ]

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        const meanMitigation = data || []

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const datasets: typeof graph.data.datasets = _.map(
                vulnerabilities,
                (category, index) => {
                    return ({
                        data: _.map(meanMitigation, (obj) => {
                            return {
                                meanMitigation: obj,
                                x: fromUnixTime(obj.fixedTime),
                                y: obj.timeNeeded
                            }
                        }),
                        label: category.label,
                        fill: false,
                        borderColor: category.color,
                        backgroundColor: category.color,
                        borderWidth: 1
                    })
                })

            graph = new Chart(chartEl.current, {
                type: 'line',
                data: {
                    labels: [
                        ranges.start,
                        ranges.end
                    ],
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: stylesheet.mode.fontColor
                            },
                            position: 'top',
                            align: 'center'
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const dataset = datasets[tooltipItem.datasetIndex]
                                    const data = dataset.data[tooltipItem.dataIndex]
                                    const meanMitigation = data.meanMitigation
                                    const convertToDays = Math.round(data.y / 86400)

                                    return [
                                        meanMitigation.Name,
                                        ['(', meanMitigation.IP, ')'].join(''),
                                        ':',
                                        convertToDays,
                                        'days'
                                    ].join(' ')
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: function (value) {
                                    return Math.round(Number(value) / 86400)
                                }
                            },
                            beginAtZero: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.0
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            // setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        data
    ])

    const DataTable = useMemo(() => {
        const meanMitigation = data || []

        const cellBody = (
            dataObject: MeanMitigation,
            property: keyof MeanMitigation
        ) => {
            let cellContent: MeanMitigation[keyof MeanMitigation] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'fixedTime':
                    cellContent = dataObject.fixedTime
                        ? format(
                            new Date(dataObject.fixedTime),
                            DATE_FORMAT_TIME
                        )
                        : ''
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(MEAN_MITIGATION_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'meanMitigation-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(meanMitigation, (dataObject, rowIndex, arr) => {
                            return (
                                <tr
                                    key={'meanMitigation-tr-' + rowIndex}
                                >
                                    {
                                        _.map(MEAN_MITIGATION_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td className={'text-wrap'} key={[
                                                    'meanMitigation-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            meanMitigation.length
                ? content
                : EmptyCellContent
        )
    }, [
        data
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = !isPrint
            ? <Container bgIndex={2} className={'mb-3'}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.meanMitigation
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
            : <div>
                <PageBreakInside className={'row'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {EVM_TEXT.SECTIONS.MEAN_MITIGATION_TIME}
                        </span>
                        <Container bgIndex={2} className={'mb-2'}>
                            <canvas ref={chartEl}/>
                        </Container>
                    </div>
                </PageBreakInside>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.meanMitigation
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EVM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

MeanMitigationTimeChart.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default MeanMitigationTimeChart

import { RootState } from '@app/store'
import { PROFILE_ROUTES } from '@constants/main/routes'
import {
    SettingsState,
    UserData
} from '@interfaces/main/profile'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

const initialState: SettingsState = {
    /** tabs property is fixed here. no setter needed. */
    tabs: [
        {
            name: 'Password',
            link: PROFILE_ROUTES.PASSWORD.link
        },
        {
            name: 'Multi-Factor Authentication',
            link: PROFILE_ROUTES.MFA.link
        },
        {
            name: 'Settings',
            link: PROFILE_ROUTES.SETTINGS.link
        }
    ],
    mfaMessage: '',
    userData: {
        mfa: false,
        mfaInitial: false,
        imageData: ''
    }
}

export const slice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setMfaMessage: (state: SettingsState, action: PayloadAction<string>) => {
            state.mfaMessage = action.payload
        },
        setUserData: (state: SettingsState, action: PayloadAction<UserData>) => {
            state.userData = action.payload
        },
        setMfa: (state: SettingsState, action: PayloadAction<boolean>) => {
            state.userData.mfa = action.payload
        },
        setMfaInitial: (state: SettingsState, action: PayloadAction<boolean>) => {
            state.userData.mfaInitial = action.payload
        },
        setImageData: (state: SettingsState, action: PayloadAction<string>) => {
            state.userData.imageData = action.payload
        },
        resetProfile: (state: SettingsState) => {
            state.mfaMessage = initialState.mfaMessage
            state.tabs = initialState.tabs
            state.userData = initialState.userData
        }
    }
})

export const {
    setUserData,
    setMfaMessage,
    setMfa,
    setMfaInitial,
    setImageData,
    resetProfile
} = slice.actions

export const selectTabs = (state: RootState) => state.profile.tabs
export const selectMfaMessage = (state: RootState) => state.profile.mfaMessage
export const selectUserData = (state: RootState) => state.profile.userData
export default slice.reducer

import {
    useAppSelector
} from '@app/hook'
import { MESSAGE as EXT_VSS_MESSAGE } from '@constants/dashboard/soc/ext-vss'
import {
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    DEFAULT_LEGEND_BOX_WIDTH
} from '@constants/main/root'
import {
    FamilyData,
    ModalData
} from '@interfaces/dashboard/soc/ext-vss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    SpinnerContainer,
    ErrorMessage
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo
} from 'react'

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    issuesPerIpChartEl: React.RefObject<HTMLCanvasElement>
    issuesPerCategoryChartEl: React.RefObject<HTMLCanvasElement>
    data: ModalData | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssuesPerCategoryChart = ({
    issuesPerIpChartEl, issuesPerCategoryChartEl, data, isLoading, isSuccess, error
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const familyData = data?.FamilyData

        let graph: Chart<'bar', number[], string>

        /** create classifications */
        const vulnerabilities: {
            threat: keyof Omit<FamilyData, 'Family'>,
            color: string
        }[] = [
            {
                threat: 'Critical',
                color: stylesheet.style.colorPresets.red
            },
            {
                threat: 'High',
                color: stylesheet.style.colorPresets['amber-1']
            },
            {
                threat: 'Medium',
                color: stylesheet.style.colorPresets['amber-2']
            },
            {
                threat: 'Low',
                color: stylesheet.style.colorPresets.yellow
            }
        ]

        const labels = _.map(familyData, ({ Family }) => Family)

        const datasets: typeof graph.data.datasets = _.map(vulnerabilities, (obj) => {
            return ({
                data: familyData
                    ? _.map(familyData, family => family[obj.threat])
                    : [],
                label: obj.threat,
                backgroundColor: obj.color,
                borderWidth: 0,
                maxBarThickness: DEFAULT_BAR_THICKNESS
            })
        })

        if (issuesPerCategoryChartEl.current) {
            graph = new Chart(issuesPerCategoryChartEl.current, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                boxWidth: DEFAULT_LEGEND_BOX_WIDTH,
                                color: stylesheet.mode.fontColor
                            },
                            align: 'center',
                            position: 'bottom'
                            /** BUGS found when updating chart elements inside
                             * event handlers for all plugins. They do not render
                             * properly even if the script is correct.
                             *
                             * Tested this vs using outside elements.
                             */
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        }
                    }
                }

            })
        }

        return () => {
            graph && graph.destroy()
        }
    }, [data])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row my-2'}>
                    <canvas className={'col-auto'} ref={issuesPerCategoryChartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssuesPerCategoryChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssuesPerCategoryChart

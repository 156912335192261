import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    GetQuestionnairesRequest,
    GetQuestionnairesResponse,
    DeleteQuestionnaireRequest,
    DeleteQuestionnaireResponse,
    AddQuestionnaireRequest,
    AddQuestionnaireResponse,
    GetComplianceTypesRequest,
    GetComplianceTypesResponse,
    ViewQuestionnaireRequest,
    ViewQuestionnaireResponse,
    UpdateQuestionnaireRequest,
    UpdateQuestionnaireResponse,
    ComplianceArticlesRequest,
    ComplianceArticlesResponse
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    AddQuestionRequest,
    AddQuestionResponse,
    UpdateQuestionRequest,
    UpdateQuestionResponse,
    DeleteQuestionRequest,
    DeleteQuestionResponse
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/question'

import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const questionnaireInfoApi = createApi({
    reducerPath: 'questionnaireInfoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getQuestionnaire: builder
            .mutation<GetQuestionnairesResponse, GetQuestionnairesRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetQuestionnairesRequest = {}

                    return {
                        url: 'q/getQuestionnaires',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getComplianceTypes: builder
            .mutation<GetComplianceTypesResponse, GetComplianceTypesRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetComplianceTypesRequest = {}

                    return {
                        url: 'q/getComplianceTypes',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        deleteQuestionnaire: builder
            .mutation<DeleteQuestionnaireResponse, DeleteQuestionnaireRequest & TokenAuth>({
                query: (body) => {
                    const formData: DeleteQuestionnaireRequest = {
                        id: body.id
                    }

                    return {
                        url: 'q/deleteQuestionnaire',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        viewQuestionnaire: builder
            .mutation<ViewQuestionnaireResponse, ViewQuestionnaireRequest & TokenAuth>({
                query: (body) => {
                    const formData: ViewQuestionnaireRequest = {
                        id: body.id
                    }

                    return {
                        url: 'q/getQuestionnaire',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        addQuestionnaire: builder
            .mutation<AddQuestionnaireResponse, AddQuestionnaireRequest & TokenAuth >({
                query: (body) => {
                    const formData: AddQuestionnaireRequest = {
                        active: body.active,
                        complianceLines: body.complianceLines,
                        name: body.name

                    }

                    return {
                        url: 'be/doAddQuestionnaire',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateQuestionnaire: builder
            .mutation<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest & TokenAuth >({
                query: (body) => {
                    const formData: UpdateQuestionnaireRequest = {
                        active: body.active,
                        complianceLines: body.complianceLines,
                        name: body.name,
                        id: body.id

                    }

                    return {
                        url: 'be/doUpdateQuestionnaire',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        addQuestion: builder
            .mutation<AddQuestionResponse, AddQuestionRequest & TokenAuth >({
                query: (body) => {
                    const formData: AddQuestionRequest = {
                        questionnaire_id: body.questionnaire_id,
                        title: body.title,
                        description: body.description,
                        question: body.question,
                        type_id: body.type_id,
                        active: body.active,
                        complianceLines: body.complianceLines,
                        answerLines: body.answerLines
                    }

                    return {
                        url: 'be/doAddQuestion',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        updateQuestion: builder
            .mutation<UpdateQuestionResponse, UpdateQuestionRequest & TokenAuth >({
                query: (body) => {
                    const formData: UpdateQuestionRequest = {
                        id: body.id,
                        questionnaire_id: body.questionnaire_id,
                        title: body.title,
                        description: body.description,
                        question: body.question,
                        type_id: body.type_id,
                        active: body.active,
                        complianceLines: body.complianceLines,
                        answerLines: body.answerLines
                    }

                    return {
                        url: 'be/doUpdateQuestion',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        deleteQuestion: builder
            .mutation<DeleteQuestionResponse, DeleteQuestionRequest & TokenAuth >({
                query: (body) => {
                    const formData: DeleteQuestionRequest = {
                        id: body.id
                    }

                    return {
                        url: 'be/deleteQuestion',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getComplianceArticles: builder
            .mutation<ComplianceArticlesResponse, ComplianceArticlesRequest & TokenAuth >({
                query: (body) => {
                    const formData: ComplianceArticlesRequest = {
                        id: body.id
                    }

                    return {
                        url: 'be/getComplianceArticles',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetQuestionnaireMutation,
    useDeleteQuestionnaireMutation,
    useAddQuestionnaireMutation,
    useGetComplianceTypesMutation,
    useViewQuestionnaireMutation,
    useUpdateQuestionnaireMutation,
    useAddQuestionMutation,
    useDeleteQuestionMutation,
    useUpdateQuestionMutation,
    useGetComplianceArticlesMutation
} = questionnaireInfoApi

import React from 'react'

import { MAX_LINES } from '@constants/main/root'
import Truncate from 'react-truncate'

const ReadMore = ({
    children, expandText, toggleExpandText
}: {
    children: any, expandText: boolean, toggleExpandText: (toggle : boolean) => void
}) => {
    return (
        <>
            <Truncate
                className={!expandText ? 'truncate-class-fix small' : 'small'}
                lines={!expandText && MAX_LINES}
                ellipsis={<small>
                    {'...'}{' '}
                    <a href={'#'} className={'text-info'} onClick={
                        () => toggleExpandText(true)}
                    >
                        {'Read More'}
                    </a>
                </small>
                }
                trimWhitespace={true}
            >
                {children}
            </Truncate>
            {/* in pdfs, this should be removed */}
            {expandText &&
                <small>{' '}
                    <a href={'#'} className={'text-info'} onClick={
                        () => toggleExpandText(false)}
                    >
                        {'Read Less'}
                    </a>
                </small>
            }
        </>
    )
}

export default ReadMore

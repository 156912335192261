/** be best done from scratch INSTEAD OF copy pasting from a print component
 * where it came from a modal
 */
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalHeaderMutation,
    useGetAttachmentDoughnutMutation,
    useGetClientInfoActivityMutation,
    useGetExchangeClientIPDoughnutMutation,
    useGetExchangeDetailsActivityMutation,
    useGetExchangeDetailsRulesMutation,
    useGetExchangeEventsMutation,
    useGetExchangeUserActivityLocationsMutation,
    useGetExchangeUserIdCountedTableMutation,
    useGetFromActivityMutation,
    useGetSubjectCountedTableMutation,
    useGetToActivityMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    PRINT_REPORT_TITLE,
    TEXT as O365_EXCHANGE_TEXT
} from '@constants/dashboard/soc/o365/detailedDashboard/exchange'
import {
    MESSAGE as O365_MESSAGE
} from '@constants/dashboard/soc/o365/main'
import {
    getUtcRanges,
    testImage,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import ExchangeUserActivityMap from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/ExchangeUserActivityMap'
import O365BarChart from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/print/O365BarChart'
import {
    CardDetails,
    LuceneQuery
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    FixedCollapsibles,
    GetDetailedDashboardRequest,
    ExchangeEventFilter
} from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import {
    GetModalRequest
    // ModalHeaderResponse
} from '@interfaces/dashboard/soc/o365/main'

import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'

import {
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles,
    selectMapData,
    setCurrentParams,
    setIsComplete,
    setMapData,
    setScrollId,
    setTotalRecords,
    toggleCollapsible,
    setAttachmentDoughnutData,
    setClientInfoActivityData,
    setExchangeClientIPDoughnutData,
    setExchangeDetailsActivityData,
    setExchangeDetailsRulesData,
    setExchangeUserActivityLocationsData,
    setExchangeUserIdCountedTableData,
    setFromActivityData,
    setSubjectCountedTableData,
    setToActivityData,
    setExchangeEventsData
} from '@slices/dashboard/soc/o365/detailedDashboard/exchange'
// import {
//     selectMode,
//     selectStyle
// } from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    FilterCell,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import { Buffer } from 'buffer'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectO365DetailedExchange,
    setCardDetails
} from '@slices/main/print/section'
import { useDebouncedCallback } from 'use-debounce'
import O365DoughnutChart from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/print/O365DoughnutChart'
import ExchangeUserIdDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/print/ExchangeUserIdDataTable'
import SubjectDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/print/SubjectDataTable'

const O365Modal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    // const mode = useAppSelector(selectMode)
    // const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)
    const mapData = useAppSelector(selectMapData)

    const dashboardMain = useAppSelector(selectO365DetailedExchange)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [
        getAttachmentDoughnut,
        getAttachmentDoughnutMutation
    ] = useGetAttachmentDoughnutMutation()

    const [
        getClientInfoActivity,
        getClientInfoActivityMutation
    ] = useGetClientInfoActivityMutation()

    const [
        getExchangeClientIPDoughnut,
        getExchangeClientIPDoughnutMutation
    ] = useGetExchangeClientIPDoughnutMutation()

    const [
        getExchangeDetailsActivity,
        getExchangeDetailsActivityMutation
    ] = useGetExchangeDetailsActivityMutation()

    const [
        getExchangeDetailsRules,
        getExchangeDetailsRulesMutation
    ] = useGetExchangeDetailsRulesMutation()

    const [
        getExchangeEvents,
        getExchangeEventsMutation
    ] = useGetExchangeEventsMutation()

    const [
        getExchangeUserActivityLocations,
        getExchangeUserActivityLocationsMutation
    ] = useGetExchangeUserActivityLocationsMutation()

    const [
        getExchangeUserIdCountedTable,
        getExchangeUserIdCountedTableMutation
    ] = useGetExchangeUserIdCountedTableMutation()

    const [
        getFromActivity,
        getFromActivityMutation
    ] = useGetFromActivityMutation()

    const [
        getSubjectCountedTable,
        getSubjectCountedTableMutation
    ] = useGetSubjectCountedTableMutation()

    const [
        getToActivity,
        getToActivityMutation
    ] = useGetToActivityMutation()

    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getAttachmentDoughnutMutation.error) {
            console.error(getAttachmentDoughnutMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAttachmentDoughnutMutation.error])

    useEffect(() => {
        if (getClientInfoActivityMutation.error) {
            console.error(getClientInfoActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getClientInfoActivityMutation.error])

    useEffect(() => {
        if (getExchangeClientIPDoughnutMutation.error) {
            console.error(getExchangeClientIPDoughnutMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeClientIPDoughnutMutation.error])

    useEffect(() => {
        if (getExchangeDetailsActivityMutation.error) {
            console.error(getExchangeDetailsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeDetailsActivityMutation.error])

    useEffect(() => {
        if (getExchangeDetailsRulesMutation.error) {
            console.error(getExchangeDetailsRulesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeDetailsRulesMutation.error])

    useEffect(() => {
        if (getExchangeEventsMutation.error) {
            console.error(getExchangeEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeEventsMutation.error])

    useEffect(() => {
        if (getExchangeUserActivityLocationsMutation.error) {
            console.error(getExchangeUserActivityLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeUserActivityLocationsMutation.error])

    useEffect(() => {
        if (getExchangeUserIdCountedTableMutation.error) {
            console.error(getExchangeUserIdCountedTableMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeUserIdCountedTableMutation.error])

    useEffect(() => {
        if (getFromActivityMutation.error) {
            console.error(getFromActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getFromActivityMutation.error])

    useEffect(() => {
        if (getSubjectCountedTableMutation.error) {
            console.error(getSubjectCountedTableMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSubjectCountedTableMutation.error])

    useEffect(() => {
        if (getToActivityMutation.error) {
            console.error(getToActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getToActivityMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetDetailedDashboardRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        const should: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => obj.not),
            turnIntoLuceneQuery)

        /**
         * for the print version, makie sure that the printOptions properties are defined.
         */

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: dashboardMain.searchParams?.search || ''
                ? Buffer
                    .from(dashboardMain.searchParams?.search || '')
                    .toString('base64')
                : '',
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        if (
            dashboardMain.searchParams &&
            dashboardMain.card
        ) {
            const newRanges = getUtcRanges({
                start: dashboardMain.searchParams.timeFrom,
                end: dashboardMain.searchParams.timeTo
            })

            /** details will be {} as we will get
         * the details data from dashboardCardDetails
         */

            dispatch(setCurrentParams({
                ranges: newRanges,
                q: dashboardMain.searchParams.search || '',
                card: {
                    deviceid: dashboardMain.card.deviceid,
                    in_face: dashboardMain.card.inFace,
                    service_type: dashboardMain.card.serviceType,
                    details: {}
                },
                boolList: (dashboardMain.searchParams.filters || []) as ExchangeEventFilter[]
            }))
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** i'm glad we check for dashboardMain.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetAttachmentDoughnut = () => {
        const unsubscribeMutation = getAttachmentDoughnut({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setAttachmentDoughnutData(undefined))
    }

    const unsubscribeGetClientInfoActivity = () => {
        const unsubscribeMutation = getClientInfoActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setClientInfoActivityData(undefined))
    }

    const unsubscribeGetExchangeClientIPDoughnut = () => {
        const unsubscribeMutation = getExchangeClientIPDoughnut({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeClientIPDoughnutData(undefined))
    }

    const unsubscribeGetExchangeDetailsActivity = () => {
        const unsubscribeMutation = getExchangeDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeDetailsActivityData(undefined))
    }

    const unsubscribeGetExchangeDetailsRules = () => {
        const unsubscribeMutation = getExchangeDetailsRules({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeDetailsRulesData(undefined))
    }

    const unsubscribeGetExchangeEvents = () => {
        const unsubscribeMutation = getExchangeEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeEventsData(undefined))
    }

    const unsubscribeGetExchangeUserActivityLocations = () => {
        const unsubscribeMutation = getExchangeUserActivityLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeUserActivityLocationsData(undefined))
    }

    const unsubscribeGetExchangeUserIdCountedTable = () => {
        const unsubscribeMutation = getExchangeUserIdCountedTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeUserIdCountedTableData(undefined))
    }

    const unsubscribeGetFromActivity = () => {
        const unsubscribeMutation = getFromActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setFromActivityData(undefined))
    }

    const unsubscribeGetSubjectCountedTable = () => {
        const unsubscribeMutation = getSubjectCountedTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setSubjectCountedTableData(undefined))
    }

    const unsubscribeGetToActivity = () => {
        const unsubscribeMutation = getToActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setToActivityData(undefined))
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchAttachmentDoughnutData = () => {
        unsubscribeGetAttachmentDoughnut()
        let getAttachmentDoughnutPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAttachmentDoughnutPromise = getAttachmentDoughnut(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAttachmentDoughnutPromise && getAttachmentDoughnutPromise.abort()
        }
    }

    const fetchClientInfoActivityData = () => {
        unsubscribeGetClientInfoActivity()
        let getClientInfoActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getClientInfoActivityPromise = getClientInfoActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getClientInfoActivityPromise && getClientInfoActivityPromise.abort()
        }
    }

    const fetchExchangeClientIPDoughnutData = () => {
        unsubscribeGetExchangeClientIPDoughnut()
        let getExchangeClientIPDoughnutPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeClientIPDoughnutPromise = getExchangeClientIPDoughnut(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeClientIPDoughnutPromise && getExchangeClientIPDoughnutPromise.abort()
        }
    }

    const fetchExchangeDetailsActivityData = () => {
        unsubscribeGetExchangeDetailsActivity()
        let getExchangeDetailsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeDetailsActivityPromise = getExchangeDetailsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeDetailsActivityPromise && getExchangeDetailsActivityPromise.abort()
        }
    }

    const fetchExchangeDetailsRulesData = () => {
        unsubscribeGetExchangeDetailsRules()
        let getExchangeDetailsRulesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeDetailsRulesPromise = getExchangeDetailsRules(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeDetailsRulesPromise && getExchangeDetailsRulesPromise.abort()
        }
    }

    const fetchExchangeEventsData = () => {
        unsubscribeGetExchangeEvents()
        let getExchangeEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeEventsPromise = getExchangeEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeEventsPromise && getExchangeEventsPromise.abort()
        }
    }

    const fetchExchangeUserActivityLocationsData = () => {
        unsubscribeGetExchangeUserActivityLocations()
        let getExchangeUserActivityLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeUserActivityLocationsPromise =
                 getExchangeUserActivityLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeUserActivityLocationsPromise &&
            getExchangeUserActivityLocationsPromise.abort()
        }
    }

    const fetchExchangeUserIdCountedTableData = () => {
        unsubscribeGetExchangeUserIdCountedTable()
        let getExchangeUserIdCountedTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeUserIdCountedTablePromise = getExchangeUserIdCountedTable(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeUserIdCountedTablePromise && getExchangeUserIdCountedTablePromise.abort()
        }
    }

    const fetchFromActivityData = () => {
        unsubscribeGetFromActivity()
        let getFromActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFromActivityPromise = getFromActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFromActivityPromise && getFromActivityPromise.abort()
        }
    }

    const fetchSubjectCountedTableData = () => {
        unsubscribeGetSubjectCountedTable()
        let getSubjectCountedTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSubjectCountedTablePromise = getSubjectCountedTable(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSubjectCountedTablePromise && getSubjectCountedTablePromise.abort()
        }
    }

    const fetchToActivityData = () => {
        unsubscribeGetToActivity()
        let getToActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getToActivityPromise = getToActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getToActivityPromise && getToActivityPromise.abort()
        }
    }

    /** on token revalidation and if the dashboardMain dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])

    useEffect(() => {
        if (!dashboardData['Attachments.Doughnut']) {
            return fetchAttachmentDoughnutData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['ClientInfo.Activity']) {
            return fetchClientInfoActivityData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['ExchangeClientIP.Doughnut']) {
            return fetchExchangeClientIPDoughnutData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['ExchangeDetails.Activity']) {
            return fetchExchangeDetailsActivityData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['ExchangeDetails.Rules']) {
            return fetchExchangeDetailsRulesData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['Exchange.Events']) {
            return fetchExchangeEventsData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['ExchangeUserActivity.Locations']) {
            return fetchExchangeUserActivityLocationsData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['ExchangeUserId.CountedTable']) {
            return fetchExchangeUserIdCountedTableData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['From.Activity']) {
            return fetchFromActivityData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['Subject.CountedTable']) {
            return fetchSubjectCountedTableData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['To.Activity']) {
            return fetchToActivityData()
        }
    }, [token.valid, dashboardMain])

    /** no need for chart zoom lifecycles. */

    useEffect(() => {
        let isMounted = true
        if (getAttachmentDoughnutMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setAttachmentDoughnutData(
                getAttachmentDoughnutMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getAttachmentDoughnutMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getClientInfoActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setClientInfoActivityData(
                getClientInfoActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getClientInfoActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getExchangeClientIPDoughnutMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeClientIPDoughnutData(
                getExchangeClientIPDoughnutMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getExchangeClientIPDoughnutMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getExchangeDetailsActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeDetailsActivityData(
                getExchangeDetailsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getExchangeDetailsActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getExchangeDetailsRulesMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeDetailsRulesData(
                getExchangeDetailsRulesMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getExchangeDetailsRulesMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getExchangeEventsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeEventsData(
                getExchangeEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getExchangeEventsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getExchangeUserActivityLocationsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeUserActivityLocationsData(
                getExchangeUserActivityLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getExchangeUserActivityLocationsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getExchangeUserIdCountedTableMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeUserIdCountedTableData(
                getExchangeUserIdCountedTableMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getExchangeUserIdCountedTableMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getFromActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setFromActivityData(
                getFromActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getFromActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSubjectCountedTableMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setSubjectCountedTableData(
                getSubjectCountedTableMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSubjectCountedTableMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getToActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setToActivityData(
                getToActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getToActivityMutation.isSuccess])

    /** if searchParams change, set all refetch values to true. */

    /** if dashboardMain change, set all refetch values to true. */

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        // type Header = ModalHeaderResponse['data'] & { title: string }
        // const data: Header = {
        //     title: dashboardCardDetails?.title || '',
        //     ...getModalHeaderMutation.data?.data || {
        //         line_1: dashboardCardDetails?.line_1 || '',
        //         line_2: dashboardCardDetails?.line_2 || '',
        //         heading: dashboardCardDetails?.heading || '',
        //         colorType: dashboardCardDetails?.colorType || 'grey'
        //     }
        // }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        // const Heading = (
        //     <Dashboard.Heading
        //         color={data.colorType || 'darkGrey'}
        //         activeMode={mode}
        //         activeStyle={style}
        //         className={'d-block mb-3'}
        //     >
        //         {data.heading || 'NO HEADING'}
        //     </Dashboard.Heading>
        // )

        // const Subtitle1 = (
        //     <Dashboard.Subtitle className={'d-block mb-1'}>
        //         {data.line_1 || 'NO LINE 1'}
        //     </Dashboard.Subtitle>
        // )

        // const Subtitle2 = (
        //     <Dashboard.Subtitle className={'d-block mb-2'}>
        //         {data.line_2 || 'NO LINE 2'}
        //     </Dashboard.Subtitle>
        // )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {/* should be removed in detailed dashboard reports */}
                {/* {Heading}
                {Subtitle1}
                {Subtitle2} */}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** add a onClick event where all the charts below will
     * add a o365Details modal.
     */

    /* apply other components here */

    const AttachmentsDoughnut = useMemo(() => {
        return <div>
            {
                dashboardData['Attachments.Doughnut']?.aggregations?.[2]?.buckets?.length
                    ? <O365DoughnutChart
                        eventType={'Attachments.Doughnut'}
                        isLoading={getAttachmentDoughnutMutation.isLoading}
                        isSuccess={getAttachmentDoughnutMutation.isSuccess}
                        error={getAttachmentDoughnutMutation.error}
                    />
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_DATA}
                        </Text>
                    </Container>
            }
        </div>
    }, undefined)

    const ClientInfoActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'ClientInfo.Activity'}
                isLoading={getClientInfoActivityMutation.isLoading}
                isSuccess={getClientInfoActivityMutation.isSuccess}
                error={getClientInfoActivityMutation.error}
            />
        </div>
    }, undefined)

    const ExchangeClientIPDoughnut = useMemo(() => {
        return <div>
            {
                dashboardData['Attachments.Doughnut']?.aggregations?.[2]?.buckets?.length
                    ? <O365DoughnutChart
                        eventType={'ExchangeClientIP.Doughnut'}
                        isLoading={getExchangeClientIPDoughnutMutation.isLoading}
                        isSuccess={getExchangeClientIPDoughnutMutation.isSuccess}
                        error={getExchangeClientIPDoughnutMutation.error}
                    />
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_DATA}
                        </Text>
                    </Container>
            }
        </div>
    }, undefined)

    const ExchangeDetailsActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'ExchangeDetails.Activity'}
                isLoading={getExchangeDetailsActivityMutation.isLoading}
                isSuccess={getExchangeDetailsActivityMutation.isSuccess}
                error={getExchangeDetailsActivityMutation.error}
            />
        </div>
    }, undefined)

    const ExchangeDetailsRules = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'ExchangeDetails.Rules'}
                isLoading={getExchangeDetailsRulesMutation.isLoading}
                isSuccess={getExchangeDetailsRulesMutation.isSuccess}
                error={getExchangeDetailsRulesMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (dashboardData['ExchangeUserActivity.Locations']) {
            dispatch(setTotalRecords(dashboardData['ExchangeUserActivity.Locations'].totalRecords))
            dispatch(setIsComplete(dashboardData['ExchangeUserActivity.Locations'].isComplete))
            dispatch(setScrollId(dashboardData['ExchangeUserActivity.Locations'].scrollId))
            dispatch(setMapData(dashboardData['ExchangeUserActivity.Locations'].data))
        }
    }, [dashboardData['ExchangeUserActivity.Locations']])

    const ExchangeUserActivityLocations = useMemo(() => {
        return <PageBreakInside>
            <span className={'d-block mb-2'}>
                {O365_EXCHANGE_TEXT.SECTIONS.EXCHANGE_USER_ACTIVITY_LOCATIONS}
            </span>

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <div className={'col'}>
                            <ExchangeUserActivityMap
                                isPrint={true}
                            />
                        </div>
                    </div>
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </PageBreakInside>
    }, undefined)

    const ExchangeUserIdCountedTable = useMemo(() => {
        return (<PageBreakInside className={'mb-3'}>
            <span className={'d-block mb-2'}>
                {O365_EXCHANGE_TEXT.SECTIONS.EXCHANGE_USER_ID_COUNTED_TABLE}
            </span>
            <ExchangeUserIdDataTable
                isLoading={getExchangeUserIdCountedTableMutation.isLoading}
                isSuccess={getExchangeUserIdCountedTableMutation.isSuccess}
                error={getExchangeUserIdCountedTableMutation.error}
            />
        </PageBreakInside>)
    }, undefined)

    const FromActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'From.Activity'}
                isLoading={getFromActivityMutation.isLoading}
                isSuccess={getFromActivityMutation.isSuccess}
                error={getFromActivityMutation.error}
            />
        </div>
    }, undefined)

    const SubjectCountedTable = useMemo(() => {
        return (<div>
            <span className={'d-block mb-2'}>
                {O365_EXCHANGE_TEXT.SECTIONS.SUBJECT_COUNTED_TABLE}
            </span>
            <SubjectDataTable
                isLoading={getSubjectCountedTableMutation.isLoading}
                isSuccess={getSubjectCountedTableMutation.isSuccess}
                error={getSubjectCountedTableMutation.error}
            />
        </div>)
    }, undefined)

    const ToActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'To.Activity'}
                isLoading={getToActivityMutation.isLoading}
                isSuccess={getToActivityMutation.isSuccess}
                error={getToActivityMutation.error}
            />
        </div>
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getAttachmentDoughnutMutation.data &&
            getClientInfoActivityMutation.data &&
            getExchangeClientIPDoughnutMutation.data &&
            getExchangeDetailsActivityMutation.data &&
            getExchangeDetailsRulesMutation.data &&
            getExchangeEventsMutation.data &&
            getExchangeUserActivityLocationsMutation.data &&
            getExchangeUserIdCountedTableMutation.data &&
            getFromActivityMutation.data &&
            getSubjectCountedTableMutation.data &&
            getToActivityMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getAttachmentDoughnutMutation.data,
        getClientInfoActivityMutation.data,
        getExchangeClientIPDoughnutMutation.data,
        getExchangeDetailsActivityMutation.data,
        getExchangeDetailsRulesMutation.data,
        getExchangeEventsMutation.data,
        getExchangeUserActivityLocationsMutation.data,
        getExchangeUserIdCountedTableMutation.data,
        getFromActivityMutation.data,
        getSubjectCountedTableMutation.data,
        getToActivityMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getAttachmentDoughnutMutation.error ||
            getClientInfoActivityMutation.error ||
            getExchangeClientIPDoughnutMutation.error ||
            getExchangeDetailsActivityMutation.error ||
            getExchangeDetailsRulesMutation.error ||
            getExchangeEventsMutation.error ||
            getExchangeUserActivityLocationsMutation.error ||
            getExchangeUserIdCountedTableMutation.error ||
            getFromActivityMutation.error ||
            getSubjectCountedTableMutation.error ||
            getToActivityMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getAttachmentDoughnutMutation.error,
        getClientInfoActivityMutation.error,
        getExchangeClientIPDoughnutMutation.error,
        getExchangeDetailsActivityMutation.error,
        getExchangeDetailsRulesMutation.error,
        getExchangeEventsMutation.error,
        getExchangeUserActivityLocationsMutation.error,
        getExchangeUserIdCountedTableMutation.error,
        getFromActivityMutation.error,
        getSubjectCountedTableMutation.error,
        getToActivityMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    const qComponent = useMemo(() => {
        return (
            <div className={'row justify-content-between'}>
                <Text size={'sm'} className={'col-auto'}>
                    {TEXT.SEARCH.QUERY.LABEL}
                </Text>
                <Text size={'sm'} className={'col ps-0'}>
                    {currentParams.q}
                </Text>
            </div>
        )
    }, [currentParams.q])

    const boolListComponent = useMemo(() => {
        return (
            <div className={'row mb-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(currentParams.boolList, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }, [currentParams.boolList])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                {/* for detailed dashboard reports, include the search
                query and filter queries at the very beginning */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col'}>
                        {
                            [
                                PRINT_REPORT_TITLE,
                                '-',
                                _.capitalize(
                                    dashboardMain
                                        .searchParams
                                        ?.detailedDashboard
                                )
                            ].join(' ')
                        }
                    </div>
                </div>
                {
                    currentParams.q
                        ? qComponent
                        : ''
                }
                {
                    currentParams.boolList.length
                        ? boolListComponent
                        : ''
                }
                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <div className={'min-width-fix'}>
                    {AttachmentsDoughnut}
                </div>

                <div className={'min-width-fix'}>
                    {ExchangeClientIPDoughnut}
                </div>

                <div className={'min-width-fix'}>
                    {ExchangeDetailsActivity}
                </div>

                <div className={'min-width-fix'}>
                    {ExchangeUserActivityLocations}
                </div>

                <div className={'min-width-fix'}>
                    {FromActivity}
                </div>

                <div className={'min-width-fix'}>
                    {ToActivity}
                </div>

                <div className={'min-width-fix'}>
                    {ClientInfoActivity}
                </div>

                <div className={'min-width-fix'}>
                    {ExchangeDetailsRules}
                </div>

                <div className={'min-width-fix'}>
                    {ExchangeUserIdCountedTable}
                </div>

                <div className={'min-width-fix'}>
                    {SubjectCountedTable}
                </div>

            </PrintMargin>

        </div>
    )
}

export default O365Modal

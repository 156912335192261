import { MutationContext } from '@root/MutationProvider'
import Tippy from '@tippyjs/react'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo
} from 'react'
import {
    FaArrowDown,
    FaArrowUp,
    FaCopy,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdManageSearch,
    MdRefresh
} from 'react-icons/md'
import { useIdleTimer } from 'react-idle-timer'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'

/** OTHER FILE IMPORTS */
import {
    useGetFlowIDMutation
} from '@apis/watchdog/soc-data/flow-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    getColorWithSeverity,
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    COMPARISON_OPERATORS,
    DATE_FORMAT_TIME,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    INTERVALS,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { EVENT_ROUTES } from '@constants/main/routes'
import {
    ACTIONS,
    ACTIVE_TYPES,
    FLOW_KEYS,
    MESSAGE as FLOW_MESSAGE,
    TEXT as FLOW_TEXT
} from '@constants/watchdog/soc-data/flow'
import MenuLinks from '@features/main/MenuLinks'
import AlertDetails from '@features/watchdog/soc-data/flow/alert/AlertDetails'
import { DetailedCard } from '@interfaces/dashboard/monitor'
import {
    ComparisonOperator
} from '@interfaces/main/root'
import { GetEventDetailsResponse } from '@interfaces/watchdog/soc-data/event'
import {
    Flow,
    FlowKeys,
    FlowParams
} from '@interfaces/watchdog/soc-data/flow'
import { selectToken } from '@slices/main/token'
import {
    addModal as addEventModal
} from '@slices/watchdog/soc-data/event/main'
import {
    addFilter,
    addModal as addFlowModal,
    closeModal as closeFlowModal,
    removeFilter,
    removeFilters,
    removeModal as removeFlowModal,
    selectDeviceId,
    selectFlowId,
    selectModals,
    selectTableData,
    selectTabs,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setDeviceId,
    setFiltered,
    setFilterKeys,
    setFilterValues,
    setFlowId,
    setIntervalObj,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    toggleFilterInterface
} from '@slices/watchdog/soc-data/flow'
import {
    Button,
    Container,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import copy from 'copy-to-clipboard'
import { format } from 'date-fns'
import flatten from 'flat'
import { AiOutlineClose } from 'react-icons/ai'
import { Modal } from 'react-responsive-modal'

/** first component to attempt table pagination data */

const FlowMenu = ({ eventDetail } : {eventDetail?: GetEventDetailsResponse['eventDetail']}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const flowParams = useParams<FlowParams>()

    const dispatch = useAppDispatch()
    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)
    const deviceid = useAppSelector(selectDeviceId)
    const flowid = useAppSelector(selectFlowId)

    const tableData = useAppSelector(selectTableData)

    const [getFlowID, getFlowIDMutation] = useGetFlowIDMutation()

    /**
     * another request was to create an interval to refresh
     * the data but inherits idleTimer behavior. the idleTimer
     * would only be re-rendered if tableData.interval changes.
     */

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    /**
     * to further understand this, the callback uses the current value
     * of the store in that lifecycle. previous example was only data
     * from mount. any useEffect that calls this would always have
     * the same input.
     */
    const unsubscribeGetFlowID = () => {
        const unsubscribeMutation = getFlowID({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = (urlParams?:FlowParams) => {
        unsubscribeGetFlowID()
        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            const args = {
                deviceid: urlParams?.deviceid || deviceid,
                flowid: urlParams?.flowid || flowid
            }

            if (token.valid) {
                const newToken = await revalidateToken()

                /** select whether we use the urlParams if truthy
                 * or by default, the state values from the store.
                 */

                if (isMounted) {
                    promise = getFlowID({
                        authToken: newToken,
                        deviceid: args.deviceid,
                        flowid: args.flowid
                    })
                }
            }
        }
        call()
        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    /**
     * optimizing table data the following ways:
     * debounce filtering and pagination data. - check.
     */

    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: Flow,
            property: keyof Flow
        ) => {
            let cellContent: Flow[keyof Flow] = ''
            let rawValue: Flow[keyof Flow] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'timestamp':
                    cellContent = format(new Date(dataObject.timestamp), DATE_FORMAT_TIME)
                    rawValue = dataObject.timestamp
                    break

                case 'src_ip':
                    cellContent = [
                        dataObject[property], ' ',
                        dataObject.protocol, ':',
                        dataObject.src_port].join('')
                    break

                case 'dest_ip':
                    cellContent = [
                        dataObject[property], ' ',
                        dataObject.protocol, ':',
                        dataObject.dest_port].join('')
                    break

                default:
                    rawValue = cellContent = dataObject[property]
                    break
            }

            const addMultipleFilters = (
                include:boolean,
                filters:{
                    sort: keyof Flow, value: string | number
                }[]
            ) => {
                _.forEach(filters, (obj) => {
                    dispatch(addFilter({
                        not: include,
                        sort: obj.sort,
                        value: [obj.value as string]
                    }))
                })
            }

            const zoomAction = (not: boolean) => {
                if (property === 'src_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'src_ip',
                            value: dataObject.src_ip
                        },
                        {
                            sort: 'src_port',
                            value: dataObject.src_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else if (property === 'dest_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'dest_ip',
                            value: dataObject.dest_ip
                        },
                        {
                            sort: 'dest_port',
                            value: dataObject.dest_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else {
                    addMultipleFilters(not, [
                        {
                            sort: property,
                            value: rawValue
                        }
                    ])
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, rowIndex) => {
                const actionButtons = _.map(ACTIONS, (action, cellIndex) => {
                    const onClick = () => { }
                    return (
                        <td key={[
                            'cell-',
                            rowIndex,
                            '-',
                            (cellIndex + tableData.columns.length)
                        ].join('')}>
                            <Button
                                onClick={onClick}
                                size={'sm'}
                                mode={action.mode}>
                                {action.label}
                            </Button>
                        </td>
                    )
                })

                const createOnClick: (
                    dataObject: Flow,
                    eventDetail?: GetEventDetailsResponse['eventDetail']
                ) => () => void = (dataObject, eventDetail) => {
                    return () => {
                        /** check if the avaiable types from constants
                         * matches the type of the dataObject. if
                         * falsy, show a toast message.
                         */
                        if (ACTIVE_TYPES.includes(dataObject.type)) {
                            /** if NOT a modal */
                            if (eventDetail) {
                                const detailedCard: DetailedCard = {
                                    deviceid: eventDetail._source.beat?.name || '',
                                    service_type: 'bdg-mdr',
                                    in_face: eventDetail._source.in_iface || '',
                                    details: {
                                        // color is based on eventObj.severity
                                        colorType: getColorWithSeverity(
                                            dataObject.severity
                                        )
                                    }
                                }

                                dispatch(addEventModal({
                                    id: uniqueString(),
                                    open: true,
                                    operation: 'DATA_ID',
                                    card: detailedCard,
                                    eventDetails: eventDetail,
                                    isBorderWide: false
                                }))
                            } else {
                                dispatch(addFlowModal({
                                    id: uniqueString(),
                                    open: true,
                                    flowObj: dataObject,
                                    isBorderWide: false
                                }))
                            }
                        } else {
                            toast.info(
                                [
                                    FLOW_MESSAGE.TYPE_UNAVAILABLE[0],
                                    dataObject.type,
                                    FLOW_MESSAGE.TYPE_UNAVAILABLE[1]
                                ].join(' ')
                                , { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }
                }

                return (
                    <tr key={'row-' + rowIndex}>
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    onClick={createOnClick(dataObject, eventDetail)}>
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                        {actionButtons}
                    </tr>
                )
            })
        )

        return (
            getFlowIDMutation.isLoading
                ? LoadingContent
                : getFlowIDMutation.isSuccess
                    ? (
                        tableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(getFlowIDMutation.error)
        )
    }, [tableData, getFlowIDMutation])

    /**
     * this useEffect is only RAN once on unmount and the parameters
     * are already defined in the first lifecycle. Run the fetchdata script.
     * another case was added. if you have a modal parameter, you will pass
     * in the required data and execute said script.
     * when mounted, perform fetchData api
     * but you also need to do this when you want to manually refresh.
     * use a property in a store for this.
     * */
    useEffect(() => {
        if (eventDetail) {
            dispatch(setDeviceId(
                eventDetail?._source.beat?.name || ''
            ))
            dispatch(setFlowId(
                eventDetail?._source.flow_id.toString() || ''
            ))

            return fetchData({
                deviceid: eventDetail?._source.beat?.name,
                flowid: eventDetail?._source.flow_id.toString()
            })
        } else if (flowParams.deviceid && flowParams.flowid) {
            // then call a dispatch.
            dispatch(setDeviceId(flowParams.deviceid))
            dispatch(setFlowId(flowParams.flowid))

            return fetchData(flowParams)
        }
    }, [token.valid])

    useEffect(() => {
        if (tableData.refetch) {
            return fetchData()
        }
    }, [tableData.refetch])

    /** reset deviceid and flowid in state */
    useEffect(() => {
        return () => {
            dispatch(setDeviceId(''))
            dispatch(setFlowId(''))
        }
    }, [])

    useEffect(() => {
        /**
         * in case that the refetch value is true meaning that
         * you did a refresh, set it to false now. It should be
         * safe to abort the promise as the call finished.
         *
         * and just to make sure refetch is setting when it has to,
         * we are also going to check if refetch is truthy, even
         * when it's not added as a dependency.
         * */
        if (getFlowIDMutation.isSuccess && tableData.refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getFlowIDMutation.isSuccess
    ])

    /** please refer to smartSearch in method.ts to know why use-debounce
     * works in react compared to basic debounce packages.
     */

    /**
     * thanks to the react inspector's time travelling feature for redux store
     * we can assure that the setSearch dispatch will be performed after a debounce
     */

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        const filteredData = smartSearch(
            getFlowIDMutation.data?.devicedata.results || [],
            tableData.filters,
            tableData.search
        ) as Flow[]
        const orderedData = smartOrder(filteredData, tableData.columns) as Flow[]

        /**
             * set the filtered data to tableData.filtered.
             * any sort or pagination should be done in a separate useEffect
             * */
        dispatch(setFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    // const paginateData = useDebouncedCallback(() => {
    const paginateData = () => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as Flow[]

        dispatch(setPaginated(result))
    // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        setData()
    }, [
        /** refreshed data from fetch invocation */
        getFlowIDMutation.data,
        /** when tableData.filters updates */
        tableData.filters,
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    useEffect(() => {
        if (getFlowIDMutation.error) {
            console.error(getFlowIDMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getFlowIDMutation.error])

    useEffect(() => {
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    const DeviceIdInput = useMemo(() => {
        return (
            <input
                className={'col'}
                type={'text'}
                id={FLOW_TEXT.SEARCH.DEVICEID.ID}
                onChange={(e) => {
                    dispatch(
                        setDeviceId(e.target.value)
                    )
                }}
            />
        )
    }, [
        deviceid
    ])

    const FlowIdInput = useMemo(() => {
        return (
            <input
                className={'col'}
                type={'text'}
                id={FLOW_TEXT.SEARCH.FLOWID.ID}
                onChange={(e) => {
                    dispatch(
                        setFlowId(e.target.value)
                    )
                }}
            />
        )
    }, [
        flowid
    ])

    const refreshByClick = () => {
        dispatch(setRefetch(true))
        refreshTimer.reset()
    }

    const shareableURLPopup = useMemo(() => {
        const content = (
            <div className={'container-fluid px-0'}>
                <div className={'align-items-center row'}>
                    <div className={'col'}>
                        <small className={'col-auto pe-0'}>{FLOW_TEXT.SHARE_URL.COPY}</small>
                        <small
                            className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                            onClick={() => {
                                copy([
                                    EVENT_ROUTES.FLOW.link,
                                    '/', deviceid,
                                    '/', flowid
                                ].join(''))
                            }}>
                            <FaCopy />
                        </small>
                    </div>
                </div>
                <div className={'row mt-1'}>
                    <input
                        className={'col mx-3'}
                        readOnly
                        value={[
                            EVENT_ROUTES.FLOW.link,
                            '/', deviceid,
                            '/', flowid
                        ].join('')}
                    />
                </div>
            </div>
        )
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={content}
            >
                <Button
                    mode={'primary'}
                    size={'sm'}
                    disabled={!deviceid || !flowid}
                >
                    {FLOW_TEXT.SEARCH.GENERATE_URL}
                </Button>
            </Tippy>
        )
    }, [
        deviceid,
        flowid
    ])

    const SearchInterface = useMemo(() => {
        return (
            <Container className={'pb-2 pt-1 px-2 mt-1'}>
                <div className={'align-items-center row mt-2'}>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <label
                                className={'small col-4 pe-0'}
                                htmlFor={FLOW_TEXT.SEARCH.DEVICEID.ID}
                            >
                                {FLOW_TEXT.SEARCH.DEVICEID.LABEL}
                            </label>
                            <div className={'col pe-4'}>
                                <SearchRow className={'align-items-center row py-1'}>
                                    {DeviceIdInput}
                                </SearchRow>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <label
                                className={'small col-4 pe-0'}
                                htmlFor={FLOW_TEXT.SEARCH.FLOWID.ID}
                            >
                                {FLOW_TEXT.SEARCH.FLOWID.LABEL}
                            </label>
                            <div className={'col pe-4'}>
                                <SearchRow className={'align-items-center row py-1'}>
                                    {FlowIdInput}
                                </SearchRow>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'align-items-center row mt-2'}>
                    <div className={'col-12 col-md'}>
                        {/* none */}
                    </div>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <div className={'col-4 pe-0'} ></div>
                            <div className={'col px-0'}>
                                <Button
                                    className={'mb-2 mb-md-0 me-2'}
                                    mode={'primary'}
                                    size={'sm'}
                                    onClick={refreshByClick}
                                >
                                    {FLOW_TEXT.SEARCH.SEARCH}
                                </Button>
                                {/* a button to generate a shareable url. */}
                                {shareableURLPopup}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }, [
        deviceid,
        flowid
    ])

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const data = getFlowIDMutation.data?.devicedata.results || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = data[0]
            ? _.filter(_.keys(
                flatten(data[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : FLOW_KEYS

        dispatch(
            setFilterKeys(result)
        )
    }, [
        getFlowIDMutation.data
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as FlowKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    return (
        <div>
            { !eventDetail ? <MenuLinks tabs={tabs} /> : '' }
            {/* contains search interface for flow data */}
            {
                !eventDetail
                    ? <div className={'align-items-center row mt-1'}>
                        <div className={'col-12'}>
                            {SearchInterface}
                        </div>
                    </div>
                    : ''
            }

            {/* this row contains an interval selection */}
            <div className={'justify-content-end row mt-3'}>
                <div className={'col-auto'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <small className={'col col-md-auto'}>{TEXT.TABLE.REFRESH}</small>
                        <Select
                            className={'col-auto px-0'}
                            options={INTERVALS}
                            value={_.find(
                                INTERVALS,
                                (e) => e.value === tableData.interval.value
                            )}
                            onChange={(e) => {
                                if (e) {
                                    dispatch(setIntervalObj(e))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles,
                                ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                            }}
                            theme={reactSelect.theme}
                        />
                        <span
                            className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                            onClick={() => {
                                dispatch(setRefetch(true))
                                refreshTimer.reset()
                            }}>
                            <MdRefresh />
                        </span>
                    </div>
                </div>
            </div>
            {/* in this row, show the search bar on the left
            and the pagination on the right */}
            <div className={'align-items-center justify-content-between row mt-3'}>
                <div className={'col-12 col-md px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                            <FaSearch />
                        </label>
                        <input
                            className={'col'}
                            type={'text'}
                            id={TEXT.SEARCH.SEARCH.ID}
                            defaultValue={tableData.search}
                            onChange={(e) => {
                                setSearchDebounce(e.target.value)
                            }}
                            placeholder={'Search'}
                        />
                        {/* when this is clicked, a popup window will show up
                        it was previously a tippy but now it's an interface below
                         to utilize space. */}
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                            <Text size={'xl'}
                                className={'d-inline-block col-auto pe-0'}
                                onClick={() => {
                                    dispatch(
                                        toggleFilterInterface(!tableData.filterInterface.show)
                                    )
                                }}
                            >
                                <MdManageSearch />
                            </Text>
                        </Tippy>
                    </SearchRow>
                </div>

                {/* this is the dropdown for page count */}
                <div className={'col-12 col-md-auto'}>
                    <div className={'align-items-center justify-content-between row'}>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                <Select
                                    className={'col-auto'}
                                    options={ROW_COUNT_SELECTION}
                                    value={_.find(
                                        ROW_COUNT_SELECTION,
                                        (e) => e.value === tableData.count
                                    )}
                                    onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                        if (e) {
                                            dispatch(setCount(e.value))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.SMALL
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                            <Pagination

                                current={tableData.page}
                                total={tableData.filtered.length}
                                pageSize={tableData.count}
                                onChange={(current) => {
                                    dispatch(setPage(current))
                                }}
                                locale={EN_US}
                            />
                        </div>

                    </div>

                </div>
            </div>
            {/* row to show when kubernetes search toggles */}
            {
                tableData.filterInterface.show
                    ? (
                        <div className={'mt-2'}>
                            {FilterInterface}
                        </div>
                    )
                    : ''
            }
            {/*
                this row contains a filters where you can include or exclude. can also clear all
                since this will become a component shared, pass the removeFilter AND
                removeFilters actions as props.
            */}
            <div className={'row mt-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(tableData.filters, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                        <Text size={'xs'} className={'ps-2'} onClick={() => {
                                            dispatch(removeFilter(filter))
                                        }}>
                                            <FaTimes />
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
                <span
                    className={[
                        'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                        tableData.filters.length ? 'd-block' : 'd-none'
                    ].join(' ')}
                    onClick={() => {
                        dispatch(removeFilters())
                    }}
                >
                    {/* clear all filters button. baseline, not center */}
                    <FaTimes />
                </span>
            </div>
            {/*
                then populate tbody using the paginated result. attempting to
                iterate using the columns data. failed because typescript
                discourages implicit declaration, including calling property names
                of objects using variables INSTEAD of a coding in the exact name.
                it would be best to perform explicit declaration
                as the opposite defeats the purpose of using Typescript.

                but if there is a fair justification to use this, just use 'any' on the
                paginated object as long as you avoid expected crashes by adding conditions.

                if there is no padding at the table cells, the table would not wrap from inside
                a column.

            */}
            <div className={'justify-content-center row mt-3'}>
                <div className={'col'}>
                    <Table
                        bgIndex={eventDetail ? 3 : 1}
                        className={[
                            'table-striped table-hover'
                        ].join(' ')}
                        height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                    >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {TableHead}
                                    {/* action section is created here. */}
                                    {
                                        ACTIONS.length
                                            ? (<th colSpan={ACTIONS.length}>
                                                <small>{TEXT.TABLE.ACTIONS}</small>
                                            </th>)
                                            : <th className={'d-none'}></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TableBody}
                            </tbody>
                        </table>
                    </Table>
                </div>
            </div>

            {
                // display iteration if not a modal.
                !eventDetail
                    ? _.map(modals, (modal, index) => {
                        const key = [
                            'modal-', index
                        ].join('')

                        let component: ReactElement<any, any> = <></>
                        let classNames = {
                            modal: ''
                        }

                        if (
                            modal.flowObj &&
                        modal.flowObj.type === 'alert'
                        ) {
                            classNames = {
                                modal: ['md', 'primary'].join(' ')
                            }
                            component = <AlertDetails
                                modal={modal}
                            />
                        } else {
                            return ''
                        }

                        return <Modal
                            key={key}
                            classNames={classNames}
                            open={modal.open}
                            center focusTrapped={false}
                            onAnimationEnd={() => {
                                if (!modal.open) {
                                    dispatch(removeFlowModal(modal))
                                }
                            }}
                            onClose={() => {
                                dispatch(closeFlowModal(modal))
                            }}
                            closeIcon={<AiOutlineClose />}>
                            {component}
                        </Modal>
                    })
                    : ''
            }

        </div>
    )
}
export default FlowMenu

/**
 * create event report interface with pdf
 * create questionnaire module
 * create pdf report format
 */
import {
    OrderAction,
    OrderColumn,
    OrderLineColumn,
    OrderKeys,
    AddOrderValues,
    UpdateOrderValues,
    OrderDeviceColumn,
    DispatchOrderValues,
    OrderDispatchColumn,
    AddOrderKeys,
    UpdateOrderKeys,
    DispatchOrderKeys
} from '@interfaces/watchdog/account-data/order'
import _ from 'lodash'
import * as Yup from 'yup'
import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'
import { NavigationTab } from '@interfaces/main/root'
import { ORDER_ROUTES } from '@constants/main/routes'
import { getUnixTime } from 'date-fns'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Order call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when orderID is undefined */
    UNDEFINED_ID: 'Empty orderID detected.'
}

const ADD_FORM = {
    ORDER_STATE: {
        LABEL: 'Order State',
        ID: 'ADD__ORDER_STATE'
    },
    NAME: {
        LABEL: 'Contact Name',
        ID: 'ADD__NAME'
    },
    SETUP_INSTRUCTIONS: {
        LABEL: 'Setup Instructions Email',
        ID: 'ADD__SETUP_INSTRUCTIONS'
    },
    EMAIL: {
        LABEL: 'User & Alert Email',
        ID: 'ADD__EMAIL'
    },
    COMMENT: {
        LABEL: 'Comment',
        ID: 'ADD__COMMENT'
    },
    ACTIVE: {
        LABEL: 'Is Active?',
        ID: 'ADD__ACTIVE'
    },
    ACTIVATION_DATE: {
        LABEL: 'Activation Date',
        ID: 'ADD__ACTIVATION_DATE'
    },
    CURRENCY: {
        LABEL: 'Order Currency',
        ID: 'ADD__CURRENCY'
    },
    CUSTOMER_NAME: {
        LABEL: 'Sold To',
        ID: 'ADD__CUSTOMER_NAME'
    },
    PARTNER_NAME: {
        LABEL: 'Billed To',
        ID: 'ADD__PARTNER_NAME'
    },
    ADD_ORDER_LINE: 'Add Order Line',
    REMOVE_ORDER_LINE: 'Remove'
}

export const DISPATCH_FORM = {
    COMPANY_NAME: {
        LABEL: 'Sold To',
        ID: 'COMPANY_NAME'
    },
    PARTNER_NAME: {
        LABEL: 'Company Name',
        ID: 'PARTNER_NAME'
    }
}

export const TEXT = {
    ADD: {
        /** confirmation label for adding a order */
        TITLE: 'Add Order Confirmation',
        /** confirmation message for adding a order */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit the order data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Adding Order',
            SUBMIT_BUTTON: 'Add Order'
        }
    },
    UPDATE: {
        /** confirmation label for updating a order */
        TITLE: 'Update Order Confirmation',
        /** confirmation message for updating a order */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit the order data?',
        FORM: {
            /** only add fields that are unique to the update interface
             * just inherit the rest from the add interface.
             */
            ...ADD_FORM,
            LOADING_BUTTON: 'Updating Order',
            SUBMIT_BUTTON: 'Update Order'
        }
    },
    DELETE: {
        /** confirmation label for updating a order */
        TITLE: 'Delete Order Confirmation',
        /** confirmation message for updating a order */
        CONFIRMATION_MESSAGE: 'Are you sure you want to delete the order data?',
        FORM: {
            LOADING_BUTTON: 'Deleting Order',
            SUBMIT_BUTTON: 'Delete Order'
        }
    },
    DISPATCH: {
        /** confirmation label for updating a order */
        TITLE: 'Dispatch Order Confirmation',
        /** confirmation message for updating a order */
        CONFIRMATION_MESSAGE: 'Are you sure you want to dispatch the order data?',
        FORM: {
            ...DISPATCH_FORM,
            GENERATE_UUID_BUTTON: 'Generate UUID',
            LOADING_BUTTON: 'Deleting Order',
            SUBMIT_BUTTON: 'Delete Order'
        }
    }
}

export const MENU_TABS: NavigationTab[] = [
    {
        name: 'Menu',
        link: ORDER_ROUTES.MAIN.link
    },
    {
        name: 'Add',
        link: ORDER_ROUTES.ADD.link
    },
    {
        name: 'Deviceid Search',
        link: ORDER_ROUTES.DEVICEID_SEARCH.link
    }
]

export const ACTIONS: OrderAction[] = [
    // {
    //     label: 'View',
    //     value: 'ORDER_VIEW',
    //     mode: 'primary'
    // },
    {
        label: 'Update',
        value: 'ORDER_UPDATE',
        mode: 'primary'
    },
    // BDGDB-273
    // {
    //     label: 'Delete',
    //     value: 'ORDER_DELETE',
    //     mode: 'danger'
    // },
    {
        label: 'Dispatch',
        value: 'ORDER_DISPATCH',
        mode: 'primary'
    }
]

/** the value of property can only be the property names
 * of the Orders interface, so it should be throwing
 * errors if the string wasn't any of those keys.
 */

export const COLUMNS: {
    MAIN: OrderColumn[],
    DEVICEID: OrderDeviceColumn[],
    ORDERLINE: OrderLineColumn[],
    DISPATCH: OrderDispatchColumn[]
} = {
    MAIN: (() => {
        // BDGDB-273 do-over.
        // const columnNames: Omit<OrderColumn, 'arrange' | 'include'>[] = [
        //     {
        //         label: 'Billed To',
        //         value: 'partner_name'
        //     }, {
        //         label: 'Sold To',
        //         value: 'customer_name'
        //     }, {
        //         label: 'Customer Company',
        //         value: 'company_name'
        //     }, {
        //         label: 'Order State',
        //         value: 'order_state'
        //     }
        // ]

        const columnNames: Omit<OrderColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Order Date',
                value: 'activationTime'
            },
            {
                label: 'Sold To',
                value: 'customer_name'
            },
            {
                label: 'Order State',
                value: 'order_state'
            },
            {
                label: 'Comment',
                value: 'comment'
            },
            {
                label: 'Box Type',
                value: 'orderLines'
            },
            {
                label: 'Product Type',
                value: 'orderLines'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: OrderColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        // now make sure activationTime is enabled and sorted desc,
        result[0].arrange = 'desc'
        result[0].include = true

        return result
    })(),
    DEVICEID: (() => {
        const columnNames: Omit<OrderDeviceColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Device ID',
                value: 'deviceId'
            }, {
                label: 'Order ID',
                value: 'orderId'
            }, {
                label: 'Product Name',
                value: 'productName'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: OrderDeviceColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    ORDERLINE: [
        {
            label: 'Quantity',
            value: 'qty'
        }, {
            label: 'Box Type',
            value: 'which_box'
        }, {
            label: 'Product Type',
            value: 'which_product'
        }, {
            label: 'Billing Type',
            value: 'billing_type'
        }, {
            label: 'Discount',
            value: 'discount'
        }
    ],
    DISPATCH: [
        {
            label: 'Box Name',
            value: 'box_name'
        }, {
            label: 'Box Type',
            value: 'box_type'
        }, {
            label: 'Serial Code',
            value: 'serial_code'
        }, {
            label: 'Config Code',
            value: 'config_code'
        }
    ]
}

export const KEYS: {
    MAIN: OrderKeys[]
} = {
    MAIN: [
        'activationTime', 'active', 'comment', 'company_name', 'customer_name',
        'email', 'name', 'orderCurrency', 'order_state', 'order_state_type',
        'partner_name'
    ]
}

export const INITIAL_VALUES: {
    ADD: AddOrderValues,
    UPDATE: UpdateOrderValues,
    DISPATCH: DispatchOrderValues
} = {
    ADD: {
        activation_date: getUnixTime(new Date()),
        // changes default value from false to true
        active: true,
        comment: '',
        currency: 0,
        customer_name: '',
        email: '',
        name: '',
        orderLines: [],
        // default value is always 3
        order_type: 3,
        partner_name: '',
        setupInstructions: ''
    },
    UPDATE: {
        activation_date: getUnixTime(new Date()),
        active: false,
        comment: '',
        currency: 0,
        customer_name: '',
        email: '',
        name: '',
        orderLines: [],
        order_type: 0,
        partner_name: '',
        setupInstructions: ''
    },
    DISPATCH: {
        orderLines: []
    }
}

/**
 * another added design restriction, don't use the addModal dispatch
 * attempted to do so and we had to import components and convert file to tsx.
 * SOLUTION: just use a boolean and initiate a useEffect in the component called
 * to add the components yourself. That means any api calls will be relocated
 * there.
 * */

export const ORDER_LINES_SCHEMA_OBJECT = {
    qty: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
    which_box: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
    which_product: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
    billing_type: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
    discount: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY)
}

export const VALIDATION_SCHEMA: {
    ADD: OptionalObjectSchema<Record<AddOrderKeys, Yup.AnySchema>>
    UPDATE: OptionalObjectSchema<Record<UpdateOrderKeys, Yup.AnySchema>>
    DISPATCH: OptionalObjectSchema<Record<DispatchOrderKeys, Yup.AnySchema>>
} = {
    ADD: Yup.object({
        customer_name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        partner_name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        currency: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        // less than 5 only on add. edit allows all.
        order_type: Yup.number()
            .required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
            .lessThan(5, ROOT_MESSAGE.ERROR.FORM.ORDER_STATE_LS_5),
        // should be a future date. this isn't working atm.
        // activation_date: Yup.date().min(moment(),MESSAGE.ERROR.FORM.FUTURE_DATE),
        activation_date: Yup.mixed()
            .test('Future date Test',
                ROOT_MESSAGE.ERROR.FORM.FUTURE_DATE, (value) => {
                    const today = new Date()
                    const midnightToday = new Date(
                        today.getFullYear(), today.getMonth(), today.getDate()
                    )

                    const unixTimestamp = Math.floor(midnightToday.getTime() / 1000)

                    return value >= unixTimestamp
                }),
        active: Yup.boolean().default(false),
        name: Yup.string(),
        setupInstructions: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        email: Yup.string().email(ROOT_MESSAGE.ERROR.FORM.EMAIL)
            .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        comment: Yup.string(),
        orderLines: Yup.array().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY).of(
            Yup.object().shape(ORDER_LINES_SCHEMA_OBJECT)
        )
    }),
    UPDATE: Yup.object({
        customer_name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        partner_name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        currency: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        order_type: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        /** overwrite activationDate with no rules.
         *  No sense to have a rule after adding a new order */
        activation_date: Yup.string(),
        active: Yup.boolean().default(false),
        name: Yup.string(),
        setupInstructions: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        email: Yup.string().email(ROOT_MESSAGE.ERROR.FORM.EMAIL)
            .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        comment: Yup.string(),
        orderLines: Yup.array().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY).of(
            Yup.object().shape({
                ...ORDER_LINES_SCHEMA_OBJECT,
                orderline_id: Yup.string()
            }))
    }),

    DISPATCH: Yup.object({
        orderLines: Yup.array().required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY).of(
            Yup.object().shape({
                // id becomes required here because we are expected to have a value.
                orderlineid: Yup.string()
                    .required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
                box_name: Yup.string()
                    .required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY).matches(/(-0x)/),
                box_type: Yup.string()
                    .required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
                serial_code: Yup.string()
                    .required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY),
                config_code: Yup.string()
                    .required(ROOT_MESSAGE.ERROR.FORM.ORDER_LINE_EMPTY)
            })
        )
    })

}

import {
    useGetCardDetailsMutation,
    useGetCardsMutation
} from '@apis/dashboard/dashboard-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ARRANGE_OPTIONS,
    MESSAGE as MONITOR_MESSAGE,
    SORT_OPTIONS,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    paginate,
    sendUnavailableModalMessage,
    smartSearch
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    INTERVALS,
    MESSAGE,
    ROW_COUNT_SELECTION,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import GdprDetails from '@features/dashboard/grc/gdpr/GdprDetails'
import GdprModal from '@features/dashboard/grc/gdpr/GdprModal'
import NistDetails from '@features/dashboard/grc/nist/NistDetails'
import NistModal from '@features/dashboard/grc/nist/NistModal'
import PcidssDetails from '@features/dashboard/grc/pcidss/PcidssDetails'
import PcidssModal from '@features/dashboard/grc/pcidss/PcidssModal'
import {
    Card,
    DetailedCard
} from '@interfaces/dashboard/monitor'
import { MutationContext } from '@root/MutationProvider'
import {
    addModal,
    changeModalColor,
    closeModal,
    pushDetailedCard,
    removeModal,
    selectModals,
    selectTableData,
    setArrange,
    setCount,
    setFilteredDetailedStatcards,
    setFilteredStatcards,
    setIntervalObj,
    setPage,
    setPaginatedDetailedStatcards,
    setRefetch,
    setSearch,
    setSort,
    toggleHideGrey
} from '@slices/dashboard/grc'
import {
    setEndDate as setGdprEndDate,
    setStartDate as setGdprStartDate
} from '@slices/dashboard/grc/gdpr/main'
import {
    setEndDate as setNistEndDate,
    setStartDate as setNistStartDate
} from '@slices/dashboard/grc/nist/main'
import {
    setEndDate as setPcidssEndDate,
    setStartDate as setPcidssStartDate
} from '@slices/dashboard/grc/pcidss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    DashboardStyledComponents as Dashboard,
    SearchRow
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import {
    FaSearch
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { useIdleTimer } from 'react-idle-timer'
import Modal from 'react-responsive-modal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'

const GRCMonitors = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const tableData = useAppSelector(selectTableData)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    const [getCards, getCardsMutation] = useGetCardsMutation()
    const [getCardDetails] = useGetCardDetailsMutation()

    const unsubscribeGetCards = () => {
        const unsubscribeMutation = getCards({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetCards()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getCards({
                        authToken: newToken,
                        modal_type: 'grc'
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    /** we will have two kinds of smartSearch functions here.
     * one to filter tableData.detailedStatcards AND one to
     * filter getCardsMutation.data
     */

    const setCardData = useDebouncedCallback(() => {
        /** filter getCardsMutation.data */
        // const filteredData = smartSearch(
        //     getCardsMutation.data?.cards || [], [], tableData.search
        // ) as DetailedCard[]

        /** no need to use smartOrder since detailedStatcards will be used
         * to render the page.
         */

        /** there can be a bug where statcard data will stop loading
         * BASED on the input value of search. Scenario 1.
         *
         * While you are loading statcard data, if one decides to type in
         * a state detail, tableData.filtered will be empty, promises
         * will be aborted and setDetailedCardData will be left with nothing.
         *
         * This can bad if users type that info immediately once the components
         * starts to render for the first time and coming back to see nothing
         * happened.
         *
         * This would have never been an issue if we had an API call that gets
         * the card data with the details already.
         */

        dispatch(setFilteredStatcards(
            getCardsMutation.data?.cards || []
        ))
    }, DEBOUNCE_SEARCH_TIME)

    /** once tableData.statcards.filtered has data, we want to
     * in chunks push those detailed versions to tableData.statcards.detailed
     */

    const addDetails = () => {
        let isMounted = true
        const promises = [] as typeof ACTION_MUTATION_PROMISE[]

        const getDetails = (card: Card) => {
            if (isMounted) {
                // execute calls that were filtered out.
                /** don't bother executing cardDetails if data already
                 * exists.
                 */
                // check if detailed doesn't have said data.
                const found = _.find(tableData.statcards.detailed, (obj) => {
                    return obj.deviceid === card.deviceid &&
                            obj.in_face === card.in_face &&
                            obj.service_type === card.service_type
                })
                if (!found) {
                    const promise = getCardDetails({
                        authToken: token.value,
                        deviceid: card.deviceid,
                        in_face: card.in_face,
                        service_type: card.service_type
                    })

                    promises.push(promise)

                    promise.unwrap()
                        .then((data) => {
                            dispatch(pushDetailedCard({
                            // ...data.data[0]
                                deviceid: data.deviceid,
                                in_face: data.in_face,
                                service_type: data.service_type,
                                details: data.data?.[0] || {
                                    colorType: 'darkGrey'
                                }
                            }))
                        })

                    // make sure your map iteration is returning a value.
                    // otherwise, await will be useless here.
                    return promise
                } else {
                    return null
                }
            } else {
                throw new Error()
            }
        }
        /** we are going to use a try catch block to allow code execution
         * to end midway.
         */
        try {
            const call = async () => {
                for (const chunk of _.chunk(tableData.statcards.filtered, 10)) {
                    await Promise.all(_.map(chunk, getDetails))
                }
            }
            call()
        } catch (e) {}

        return () => {
            isMounted = false
            // unmount promises
            promises.forEach((promise) => {
                promise && promise.abort()
            })
        }
    }

    /** smartSearch for tableData.detailedStatcards.filtered */
    const setDetailedCardData = useDebouncedCallback(() => {
        // besides the smartSearch, also hide grey cards including those that don't have a color
        const filteredData = smartSearch(
            tableData.statcards.detailed, [], tableData.search
        ) as DetailedCard[]

        const filteredColors = _.filter(filteredData, (card) => {
            if (tableData.hideGrey) {
                // if the card has a colortype AND isn't grey OR darkGrey
                return (card.details.colorType &&
                    (card.details.colorType !== 'grey' &&
                    card.details.colorType !== 'darkGrey')
                )
            } else {
                return true
            }
        }) as DetailedCard[]

        // switch case orders.

        let sort = tableData.sort.value as string
        if (tableData.sort.value === 'title') {
            sort = 'details.title'
        } else if (tableData.sort.value === 'location') {
            sort = 'details.location'
        }

        let orderedData: DetailedCard[] = []

        if (tableData.sort.value === 'colorType') {
            orderedData = _.sortBy(filteredColors, (card) => {
                switch (card.details.colorType) {
                    case 'green': return 0
                    case 'yellow': return 1
                    case 'amber-2': return 2
                    case 'amber-1': return 3
                    case 'red': return 4
                    case 'grey': return 5
                    default: return 6
                }
            })
            orderedData = tableData.arrange.value === 'desc'
                ? _.reverse(orderedData)
                : orderedData
        } else {
            orderedData = _.orderBy(
                filteredColors, [sort], [tableData.arrange.value]
            ) as DetailedCard[]
        }

        dispatch(setFilteredDetailedStatcards(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    /** after this paginate your results. */
    const paginateData = () => {
        const result = paginate(
            tableData.detailedStatcards.filtered,
            tableData.page,
            tableData.count
        ) as DetailedCard[]

        dispatch(setPaginatedDetailedStatcards(result))
    }

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    useEffect(() => {
        if (getCardsMutation.error) {
            console.error(getCardsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getCardsMutation.error])

    /** useEffect on token validation. Initiate call. */
    useEffect(() => {
        if (getCardsMutation.isSuccess && tableData.refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getCardsMutation.isSuccess
    ])

    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (tableData.refetch) {
            // empty statcards.
            dispatch(setFilteredDetailedStatcards([]))
            dispatch(setFilteredStatcards([]))
            return fetchData()
        }
    }, [tableData.refetch])

    /** once getCards is called OR if the search box triggered an onChange event,
     * call setCardData so tableData.statcards.filtered has data
     */
    useEffect(() => {
        setCardData()
    }, [
        getCardsMutation.data,
        tableData.search
    ])

    /** once tableData.statcards.filtered has data, call addDetails.
     * Don't forget to create a cleanup effect to abort the promises.
     */
    useEffect(() => {
        return addDetails()
    }, [
        tableData.statcards.filtered
    ])

    /** with tableData.statcards.detailed has data, now we can apply smartSearch
     * to it for tableData.detailedStatcards.filtered
     */

    useEffect(() => {
        setDetailedCardData()
    }, [
        tableData.statcards.detailed,
        tableData.hideGrey,
        tableData.search,
        tableData.sort,
        tableData.arrange
    ])

    useEffect(() => {
        paginateData()
    }, [
        tableData.detailedStatcards.filtered,
        tableData.page,
        tableData.count
    ])

    const DashboardCards = useMemo(() => {
        return (
            _.map(tableData.detailedStatcards.paginated, (card, index) => {
                const key = [
                    'dashboard-card-', index
                ].join('')

                const Title = (
                    <Dashboard.Title className={'d-block'}>
                        {card.details.title || 'NO TITLE'}
                    </Dashboard.Title>
                )

                const Heading = (
                    <Dashboard.Heading
                        color={card.details.colorType || 'darkGrey'}
                        activeMode={mode}
                        activeStyle={style}
                        className={'d-block mb-3'}
                    >
                        {card.details.heading || 'NO HEADING'}
                    </Dashboard.Heading>
                )

                const Subtitle1 = (
                    <Dashboard.Subtitle className={'d-block mb-1'}>
                        {card.details.line_1 || 'NO LINE 1'}
                    </Dashboard.Subtitle>
                )

                const Subtitle2 = (
                    <Dashboard.Subtitle className={'d-block mb-2'}>
                        {card.details.line_2 || 'NO LINE 2'}
                    </Dashboard.Subtitle>
                )

                const Footer1 = (
                    <div className={'row'}>
                        <Dashboard.Footer className={'col mb-1'}>
                            {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ card.deviceid }`}
                        </Dashboard.Footer>
                        <Dashboard.Footer className={'col text-end mb-1'}>
                            {`${ MONITOR_TEXT.CARD.LOCATION }
                    : ${ card.details.location || 'NO LOCATION' }`}
                        </Dashboard.Footer>
                    </div>
                )

                const lastUpdatePass = isValid(Number(card.details.lastUpdate)) &&
                Number(card.details.lastUpdate) > 0

                const Footer2 = (
                    <div className={'row'}>
                        <Dashboard.Footer className={'col mb-1'}>
                            {`${ card.details.state || 'NO STATE' }
                            : ${ card.details.state_details ||
                            'NO STATE DETAILS' }`}
                        </Dashboard.Footer>
                        <Dashboard.Footer className={'col text-end mb-1'}>
                            {`${
                                lastUpdatePass
                                    ? format(
                                        fromUnixTime(Number(card.details.lastUpdate)),
                                        DATE_FORMAT_TIME
                                    )
                                    : 'No Date'
                            }`}
                        </Dashboard.Footer>
                    </div>
                )

                const onClick = () => {
                    /** thanks to Redux being a global store, we can
                     * define the range dates here the components mount.
                     * NOTE: don't show modal if the lastUpdate value is not a date
                     */
                    let addModalPass = true

                    /** i made a mistake. i should be updating
                     * the timestamps by today and 1 day ago.
                     */
                    if (lastUpdatePass) {
                        const startDate = getUnixTime(
                            sub(
                                // fromUnixTime( Number(card.details.lastUpdate)),
                                new Date(),
                                { days: 1 }
                            )
                        )
                        // const endDate = Number(card.details.lastUpdate)
                        const endDate = getUnixTime(new Date())

                        if (card.service_type === 'bdg-csm-pcidss') {
                            dispatch(setPcidssStartDate(startDate))
                            dispatch(setPcidssEndDate(endDate))
                        } else if (card.service_type === 'bdg-csm-gdpr') {
                            dispatch(setGdprStartDate(startDate))
                            dispatch(setGdprEndDate(endDate))
                        } else if (card.service_type === 'bdg-csm-nist') {
                            dispatch(setNistStartDate(startDate))
                            dispatch(setNistEndDate(endDate))
                        } else {
                            console.error(MONITOR_MESSAGE.MODAL_UNAVAILABLE)
                            toast.error(
                                MONITOR_MESSAGE.MODAL_UNAVAILABLE,
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                            addModalPass = false
                        }

                        // when clicked, a modal will be pushed.
                        if (addModalPass) {
                            /** i don't want to push empty modals. */
                            dispatch(addModal({
                                id: uniqueString(),
                                open: true,
                                card: card,
                                operation: 'MAIN',
                                isBorderWide: true
                            }))
                        }
                    } else {
                        // toast a message where lastUpdate is invalid.
                        toast.error(
                            MONITOR_MESSAGE.INVALID_DATE,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }

                const TitleContainer = (
                    <div className={`align-items-center
                        justify-content-between mb-3 row`}>
                        <div className={'col-auto'}>{Title}</div>
                    </div>
                )

                return (
                    <Dashboard.Container
                        size={'lg'}
                        key={key}
                        className={'col-auto mb-4'}
                        onClick={onClick}
                    >
                        <Dashboard.Card
                            color={card.details.colorType || 'darkGrey'}
                            activeMode={mode}
                            activeStyle={style}
                        >
                            {TitleContainer}
                            {Heading}
                            {Subtitle1}
                            {Subtitle2}
                            {Footer1}
                            {Footer2}
                        </Dashboard.Card>
                    </Dashboard.Container>
                )
            })
        )
    }, [tableData.detailedStatcards.paginated])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>
                const message = sendUnavailableModalMessage(
                    modal.card.service_type,
                    modal.operation
                )

                if (modal.card.service_type === 'bdg-csm-pcidss') {
                    if (modal.operation === 'DETAILS') {
                        component = <PcidssDetails
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'MAIN') {
                        component = <PcidssModal
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                            changeModalColor={changeModalColor}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // just in case cause we don't want an empty modal to show.
                        return ''
                    }
                } else if (modal.card.service_type === 'bdg-csm-gdpr') {
                    if (modal.operation === 'DETAILS') {
                        component = <GdprDetails
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'MAIN') {
                        component = <GdprModal
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                            changeModalColor={changeModalColor}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // just in case cause we don't want an empty modal to show.
                        return ''
                    }
                } else if (modal.card.service_type === 'bdg-csm-nist') {
                    if (modal.operation === 'DETAILS') {
                        component = <NistDetails
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'MAIN') {
                        component = <NistModal
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                            changeModalColor={changeModalColor}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // just in case cause we don't want an empty modal to show.
                        return ''
                    }
                } else {
                    return ''
                }
                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            modal.card.details.colorType || 'darkGrey',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    return (
        <div>
            {/* interface goes here. */}
            <div className={'justify-content-end row mt-3'}>
                <div className={'col-auto'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <div className={'col-auto mb-3'}>
                            <div className={'align-items-center row'}>
                                <small className={'col col-md-auto'}>
                                    {MONITOR_TEXT.SEARCH.HIDE_GREY}
                                </small>
                                <input type={'checkbox'}
                                    onChange={() => {
                                        dispatch(
                                            toggleHideGrey(!tableData.hideGrey)
                                        )
                                    }}
                                    checked={tableData.hideGrey}
                                />
                            </div>
                        </div>
                        <div className={'col-auto mb-3'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col col-md-auto text-nowrap'}>
                                    {MONITOR_TEXT.SEARCH.SORT}
                                </small>
                                <Select
                                    className={'col-auto px-0'}
                                    options={SORT_OPTIONS}
                                    value={_.find(
                                        SORT_OPTIONS,
                                        (e) => e.value === tableData.sort.value
                                    )}
                                    onChange={(e) => {
                                        if (e) {
                                            dispatch(setSort(e))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-auto mb-3'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col col-md-auto text-nowrap'}>
                                    {MONITOR_TEXT.SEARCH.ARRANGE}
                                </small>
                                <Select
                                    className={'col-auto px-0'}
                                    options={ARRANGE_OPTIONS}
                                    value={_.find(
                                        ARRANGE_OPTIONS,
                                        (e) => e.value === tableData.arrange.value
                                    )}
                                    onChange={(e) => {
                                        if (e) {
                                            dispatch(setArrange(e))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'justify-content-end row'}>
                <div className={'col-auto'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <small className={'col col-md-auto'}>{TEXT.TABLE.REFRESH}</small>
                        <Select
                            className={'col-auto px-0'}
                            options={INTERVALS}
                            value={_.find(
                                INTERVALS,
                                (e) => e.value === tableData.interval.value
                            )}
                            onChange={(e) => {
                                if (e) {
                                    dispatch(setIntervalObj(e))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles,
                                ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                            }}
                            theme={reactSelect.theme}
                        />
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                            <span
                                className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                                onClick={() => {
                                    dispatch(setRefetch(true))
                                    refreshTimer.reset()
                                }}>
                                <MdRefresh />
                            </span>
                        </Tippy>
                    </div>
                </div>
            </div>
            <div className={'align-items-center justify-content-between row mt-3'}>
                <div className={'col-12 col-md px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                            <FaSearch />
                        </label>
                        <input
                            className={'col'}
                            type={'text'}
                            id={TEXT.SEARCH.SEARCH.ID}
                            defaultValue={tableData.search}
                            onChange={(e) => {
                                setSearchDebounce(e.target.value)
                            }}
                            placeholder={'Search'}
                        />
                    </SearchRow>
                </div>

                {/* this is the dropdown for page count */}
                <div className={'col-12 col-md-auto'}>
                    <div className={'align-items-center justify-content-between row'}>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                <Select
                                    className={'col-auto'}
                                    options={ROW_COUNT_SELECTION}
                                    value={_.find(
                                        ROW_COUNT_SELECTION,
                                        (e) => e.value === tableData.count
                                    )}
                                    onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                        if (e) {
                                            dispatch(setCount(e.value))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.SMALL
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                            <Pagination
                                current={tableData.page}
                                total={tableData.detailedStatcards.filtered.length}
                                pageSize={tableData.count}
                                onChange={(current) => {
                                    dispatch(setPage(current))
                                }}
                                locale={EN_US}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* populate the array of cards */}

            <div className={'justify-content-center row mt-3'}>
                {/* map box data to cards */}
                {DashboardCards}
            </div>
            {
                renderModals
            }
        </div>
    )
}

export default GRCMonitors

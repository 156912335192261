/**
 * the purpose of this component is to search for the section you
 * want to download. Because requestData.formData.resultData is different,
 * go use a search box to quickly filter the result of your choice.
 *
 * For example, you want to get parts of the report that contain a specific
 * vector url.
 *
 * By default, faulty sections will show up first. Clicking on view will scroll
 * the page
 */

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    SCAN_RESULT_COLUMNS as EXT_VSS_SCAN_RESULT_COLUMNS
} from '@constants/dashboard/soc/ext-vss'
import {
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    ACTIONS,
    COLUMNS,
    TEXT as PRINT_TEXT
} from '@constants/main/print'
import {
    ACTION_MUTATION_PROMISE,
    COMPARISON_OPERATORS,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS,
    TOASTIFY_PRINT_OPTIONS
} from '@constants/main/root'
import {
    ReportModal,
    Section
} from '@interfaces/main/print'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    addFilter,
    removeFilter,
    removeFilters,
    selectTableData,
    setColumnArrange,
    setColumnInclude,
    setColumns,
    setCount,
    setFiltered,
    setFilterKeys,
    setFilterValues,
    setPage,
    setPaginated,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    setShowFailed,
    toggleFilterInterface,
    setRefetch,
    removeLoadingId,
    addLoadingId,
    selectLoadingQueue
} from '@slices/main/print/reportsMenu/details'
import flatten from 'flat'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    useDebouncedCallback
} from 'use-debounce'

import {
    ComparisonOperator
} from '@interfaces/main/root'

import { MutationContext } from '@root/MutationProvider'

import {
    Button,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'

import Pagination from 'rc-pagination'

import {
    useDownloadCompleteReportMutation,
    useDownloadSectionMutation,
    useGenerateSectionMutation,
    useGetSectionsMutation,
    useGetMainKeyMutation
} from '@apis/dashboard/pdf-api'
import { Buffer } from 'buffer'
import {
    MdManageSearch
} from 'react-icons/md'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useIdleTimer } from 'react-idle-timer'
import { selectToken } from '@slices/main/token'
import {
    SECTION_DETAIL_COLUMNS as GDPR_SECTION_DETAIL_COLUMNS
} from '@constants/dashboard/grc/gdpr'

/** closing the modal sequentially unmounting this component
 * will interrupt the calls below. There are reasons for doing this
 * such as that the user is in a rush to perform tasks in this application.
 * To solve this, remove the mutation element and perform the entire
 * operation in one lifecycle.
 */

const SectionDetails = ({ modal, addModal, closeModal } : {
    modal: ReportModal,
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    closeModal: ActionCreatorWithPayload<ReportModal, string>,
}) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const loadingQueue = useAppSelector(selectLoadingQueue)

    const tableData = useAppSelector(selectTableData)
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const [downloadSection] = useDownloadSectionMutation()
    const [generateSection] = useGenerateSectionMutation()
    const [downloadCompleteReport] = useDownloadCompleteReportMutation()
    const [getSections, getSectionsMutation] = useGetSectionsMutation()
    const [getMainKey] = useGetMainKeyMutation()

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    const unsubscribeGetSections = () => {
        const unsubscribeMutation = getSections({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMainKey = () => {
        const unsubscribeMutation = getMainKey({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetSections()
        unsubscribeGetMainKey()
        let getSectionPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let mainKeyPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted && modal.report) {
                    getSectionPromise = getSections({
                        authToken: newToken,
                        reportId: modal.report.id
                    })

                    mainKeyPromise = getMainKey({
                        authToken: newToken,
                        reportId: modal.report.id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getSectionPromise && getSectionPromise.abort()
            mainKeyPromise && mainKeyPromise.abort()
        }
    // }, [token.valid])
    }

    /** template for entire toast. */
    const messageTemplate = useCallback((content: JSX.Element) => {
        return <div className={'row align-items-center'}>
            <SpinnerContainer className={'col-auto pe-2'}>
                <span className={'spinner-border'}></span>
            </SpinnerContainer>
            <div className={'col'}>
                <span>{content}</span>
            </div>
        </div>
    }, [])

    const fetchSectionBuffer = async (section: Section) => {
        dispatch(addLoadingId(section.id))

        const id = toast.info(
            messageTemplate(
                <>{PRINT_TEXT.DOWNLOAD.ONE.START}</>
            ), {
                ...TOASTIFY_PRINT_OPTIONS.ONGOING
            }
        )

        const newToken = await revalidateToken()

        const result = await downloadSection({
            sectionId: section.id,
            authToken: newToken
        }).unwrap()

        dispatch(removeLoadingId(section.id))

        if (result.success && result.reportContents) {
            const buffer = Buffer.from(result.reportContents, 'base64')

            const blob = new Blob([buffer], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)

            link.download = section.key
            link.click()

            toast.update(id, {
                render: result.message,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'success'
            })
        } else {
            // display failure message.
            toast.update(id, {
                render: result.message,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'error'
            })
        }
    }

    const repairSection = async (section: Section) => {
        dispatch(addLoadingId(section.id))

        const id = toast.info(
            messageTemplate(
                <>{PRINT_TEXT.REPAIR_SECTION.START}</>
            ), {
                ...TOASTIFY_PRINT_OPTIONS.ONGOING
            }
        )

        const newToken = await revalidateToken()

        const result = await generateSection({
            sectionId: section.id,
            authToken: newToken
        }).unwrap().catch(
            (error) => {
                if (error) {
                    console.error(error)
                    toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
                }
            }
        )

        dispatch(removeLoadingId(section.id))

        if (result) {
            if (result.success) {
                toast.update(id, {
                    render: result.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'success'
                })

                /** after success, fetch sections again
                 */
                fetchData()
            } else {
                // display failure message.
                toast.update(id, {
                    render: result.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'error'
                })
            }
        }
    }

    /** this code snippett exists IN PrintReport.tsx AND ResultMessage.tsx.
     * Find a way to simplify it. Be mindful of toast code snippets as they
     * are different.
     */
    const fetchCompleteReport = async (
        id: string,
        withoutCrash: boolean
    ) => {
        /** before anything else, make sure that the download button associated
         * in that is disabled to avoid spamming. This includes all toast instances.
         */
        dispatch(addLoadingId(id))

        /** set progress toast. */
        const toastId = toast.info(
            messageTemplate(
                <>{withoutCrash
                    ? PRINT_TEXT.DOWNLOAD.INCOMPLETE.START
                    : PRINT_TEXT.DOWNLOAD.COMPLETE.START}</>
            ), {
                ...TOASTIFY_PRINT_OPTIONS.ONGOING
            }
        )

        const newToken = await revalidateToken()

        const mainKey = await getMainKey({
            reportId: id,
            authToken: newToken
        }).unwrap()

        downloadCompleteReport({
            reportId: id,
            authToken: newToken,
            withoutCrash: withoutCrash
        }).unwrap().then((data) => {
            dispatch(removeLoadingId(id))

            if (data.success) {
                const buffer = Buffer.from(data.reportContents, 'base64')

                const blob = new Blob([buffer], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)

                link.download = mainKey.data
                link.click()

                toast.update(toastId, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'success'
                })
            } else {
                // display failure message.
                toast.update(toastId, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'error'
                })
            }
        })
    }

    useEffect(() => {
        return fetchData()
    }, [])

    useEffect(() => {
        if (tableData.refetch) {
            return fetchData()
        }
    }, [tableData.refetch])

    useEffect(() => {
        /**
         * in case that the refetch value is true meaning that
         * you did a refresh, set it to false now. It should be
         * safe to abort the promise as the call finished.
         *
         * and just to make sure refetch is setting when it has to,
         * we are also going to check if refetch is truthy, even
         * when it's not added as a dependency.
         * */
        if (getSectionsMutation.isSuccess && tableData.refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getSectionsMutation.isSuccess
    ])
    /** based on service type, start setting value of columns. */
    useEffect(() => {
        const data = getSectionsMutation.data
        // we are getting the service type from the first index of the sections
        // array, then we traverse into requestData.formData.resultData.
        const section = data?.data.find((obj) => {
            // return basically anything except order 0.
            // But if all is left to find is order 0 then don't do anything.
            return obj.order !== 0
        })

        if (section) {
            switch (section?.requestData.formData.main.card?.serviceType) {
                case 'bdg-csm-gdpr':
                case 'bdg-csm-nist':
                case 'bdg-csm-pcidss':
                {
                    dispatch(setColumns(
                        _.concat(GDPR_SECTION_DETAIL_COLUMNS, COLUMNS.DASHBOARD_DETAILS)
                    ))

                    /** then set keys for filterInterface. */
                    const result = section
                        ? _.filter(_.keys(
                            flatten(section.requestData.formData.resultData)
                        ), (str) => {
                            return !_.includes(str, 'id')
                        })
                        : []

                    dispatch(
                        setFilterKeys(result)
                    )
                    break
                }
                case 'bdg-ext-vss':
                case 'bdg-vss': {
                    dispatch(setColumns(
                        _.concat(EXT_VSS_SCAN_RESULT_COLUMNS, COLUMNS.DASHBOARD_DETAILS)
                    ))

                    /** then set keys for filterInterface. */
                    const result = section
                        ? _.filter(_.keys(
                            flatten(section.requestData.formData.resultData)
                        ), (str) => {
                            return !_.includes(str, 'id')
                        })
                        : []

                    dispatch(
                        setFilterKeys(result)
                    )
                    break
                }
                case 'bdg-o365':
                case 'bdg-o365-azure':
                default: {
                    dispatch(setColumns(COLUMNS.DASHBOARD_DETAILS))

                    /** then set keys for filterInterface. */
                    const result: string[] = []

                    dispatch(
                        setFilterKeys(result)
                    )
                    break
                }
            }
        }
    }, [getSectionsMutation.data])

    /** please refer to smartSearch in method.ts to know why use-debounce
     * works in react compared to basic debounce packages.
     */

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        let result: Section[] = []

        if (tableData.showFailed) {
            result = _.filter(getSectionsMutation.data?.data || [], (obj) => {
                return obj.hasCrashed === true
            })
        } else {
            result = getSectionsMutation.data?.data || []
        }

        const filteredData = smartSearch(
            result,
            tableData.filters,
            tableData.search
        ) as Section[]
        const orderedData = smartOrder(filteredData, tableData.columns) as Section[]

        /**
         * set the filtered data to tableData.filtered.
         * any sort or pagination should be done in a separate useEffect
         * */
        dispatch(setFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    // const paginateData = useDebouncedCallback(() => {
    const paginateData = () => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as Section[]

        dispatch(setPaginated(result))
        // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        setData()
    }, [
        getSectionsMutation.data,
        /** when tableData.filters updates */
        tableData.filters,
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns,
        /** when tableData.showFailed updates */
        tableData.showFailed
    ])

    useEffect(() => {
        /** you now will paginate your data here. */
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort),
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            /** Section and RequestQuery.formData.resultData (any) */
            dataObject: Section,
            property: string
        ) => {
            let cellContent: string | number = ''
            let rawValue: string | number = ''

            const definedProperties: (typeof property)[] = [
                'order', 'isComplete', 'hasCrashed'
            ]

            /** switch case if you want to display something differently */
            switch (property) {
                case 'order':
                    rawValue = cellContent = dataObject.order
                    break
                case 'isComplete':
                    rawValue = cellContent = dataObject.isComplete.toString()
                    break
                case 'hasCrashed':
                    rawValue = cellContent = dataObject.hasCrashed.toString()
                    break
                default: {
                    // it is from resultData.
                    const resultData = dataObject.requestData.formData.resultData
                    cellContent = '-'
                    // check if truthy first.
                    if (resultData) {
                    // check if dataObject[property] is a string or number.
                        const value = resultData[property]
                        if (['string', 'number'].includes(typeof value)) {
                            rawValue = cellContent = value
                        }
                    }
                    break
                }
            }

            const zoomAction = (not: boolean) => {
                if (_.includes(definedProperties, property)) {
                    dispatch(addFilter({
                        not: not,
                        sort: property,
                        value: [String(rawValue)]
                    }))
                } else {
                    dispatch(addFilter({
                        not: not,
                        sort: [
                            'requestData.formData.resultData.',
                            property
                        ].join(''),
                        value: [String(rawValue)]
                    }))
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, rowIndex) => {
                const actionButtons =
                        _.map(ACTIONS, (action, cellIndex) => {
                            const cellKey = [
                                'section-cell-' + rowIndex +
                                '-' + (1 + cellIndex + tableData.columns.length)
                            ].join('')

                            const onClick = () => {
                                /** action to download this specific section. */
                                if (action.value === 'DOWNLOAD_PDF') {
                                    fetchSectionBuffer(dataObject)
                                } else if (action.value === 'REPAIR_SECTIONS') {
                                    repairSection(dataObject)
                                }
                            }

                            const disableRepair: boolean = (
                                action.value === 'REPAIR_SECTIONS' &&
                                dataObject.hasCrashed === false
                            )

                            return (
                                <td key={cellKey}>
                                    <Button
                                        onClick={onClick}
                                        size={'sm'}
                                        mode={action.mode}
                                        disabled={disableRepair
                                            ? true
                                            : _.some(
                                                loadingQueue,
                                                queue => queue === dataObject.id
                                            )}>
                                        {action.label}
                                    </Button>
                                </td>
                            )
                        })

                return (
                    <tr key={'section-row-' + rowIndex}>
                        {/* if order is zero, display a colspan main page */}
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'section-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}>
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                        {actionButtons}
                    </tr>
                )
            })
        )

        return (
            tableData.paginated.length
                ? cellContent
                : EmptyCellContent
        )
    }, [tableData, loadingQueue])

    return (
        <div>
            <div className={'justify-content-end row mt-5'}>
                <div className={'col-auto'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <div className={'col-auto ps-0'}>
                            <div className={'row align-items-center'}>
                                <small className={'col-auto'} >
                                    {PRINT_TEXT.SECTION_DETAILS.FAILED_SECTIONS}
                                </small>
                                <input type={'checkbox'}
                                    onChange={() => {
                                        dispatch(
                                            setShowFailed(!tableData.showFailed)
                                        )
                                    }}
                                    checked={tableData.showFailed}
                                />
                            </div>
                        </div>
                        <div className={'col col-md-auto'}>
                            <Button
                                mode={'primary'}
                                size={'sm'}
                                disabled={
                                    _.some(
                                        loadingQueue,
                                        queue => queue === modal.report?.id
                                    )
                                }
                                onClick={() => {
                                    // not using tableData.filtered here intentionally.
                                    const hasFaulty = _.filter(
                                        modal.report?.sections || [], (obj) => {
                                            return obj.hasCrashed === true
                                        }).length

                                    if (hasFaulty > 0) {
                                        dispatch(addModal({
                                            id: uniqueString(),
                                            open: true,
                                            operation: 'INCOMPLETE_REPORT',
                                            isBorderWide: true,
                                            report: modal.report
                                        }))
                                    } else {
                                        if (modal.report) {
                                            fetchCompleteReport(
                                                modal.report?.id, false
                                            )
                                        }
                                    }
                                }}
                            >
                                {PRINT_TEXT.DOWNLOAD.DOWNLOAD_ALL}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* in this row, show the search bar on the left
            and the pagination on the right */}
            <div className={'align-items-center justify-content-between row mt-3'}>
                <div className={'col-12 col-md px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                            <FaSearch />
                        </label>
                        <input
                            className={'col'}
                            type={'text'}
                            id={TEXT.SEARCH.SEARCH.ID}
                            defaultValue={tableData.search}
                            onChange={(e) => {
                                setSearchDebounce(e.target.value)
                            }}
                            placeholder={'Search'}
                        />
                        {/* when this is clicked, a popup window will show up
                        it was previously a tippy but now it's an interface below
                         to utilize space. */}
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                            <Text size={'xl'}
                                className={'d-inline-block col-auto pe-0'}
                                onClick={() => {
                                    dispatch(
                                        toggleFilterInterface(
                                            !tableData.filterInterface.show
                                        )
                                    )
                                }}
                            >
                                <MdManageSearch />
                            </Text>
                        </Tippy>
                    </SearchRow>
                </div>

                {/* this is the dropdown for page count */}
                <div className={'col-12 col-md-auto'}>
                    <div className={'align-items-center justify-content-between row'}>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                <Select
                                    className={'col-auto'}
                                    options={ROW_COUNT_SELECTION}
                                    value={_.find(
                                        ROW_COUNT_SELECTION,
                                        (e) => e.value === tableData.count
                                    )}
                                    onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                        if (e) {
                                            dispatch(setCount(e.value))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.SMALL
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                            <Pagination

                                current={tableData.page}
                                total={tableData.filtered.length}
                                pageSize={tableData.count}
                                onChange={(current) => {
                                    dispatch(setPage(current))
                                }}
                                locale={EN_US}
                            />
                        </div>

                    </div>

                </div>
            </div>
            {/* row to show when kubernetes search toggles */}
            {
                tableData.filterInterface.show
                    ? (
                        <div className={'mt-2'}>
                            {FilterInterface}
                        </div>
                    )
                    : ''
            }
            {/*
                this row contains a filters where you can include or exclude. can also clear all
                since this will become a component shared, pass the removeFilter AND
                removeFilters actions as props.
            */}
            <div className={'row mt-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(tableData.filters, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                        <Text size={'xs'} className={'ps-2'} onClick={() => {
                                            dispatch(removeFilter(filter))
                                        }}>
                                            <FaTimes />
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
                <span
                    className={[
                        'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                        tableData.filters.length ? 'd-block' : 'd-none'
                    ].join(' ')}
                    onClick={() => {
                        dispatch(removeFilters())
                    }}
                >
                    {/* clear all filters button. baseline, not center */}
                    <FaTimes />
                </span>
            </div>
            {/*
                then populate tbody using the paginated result. attempting to
                iterate using the columns data. failed because typescript
                discourages implicit declaration, including calling property names
                of objects using variables INSTEAD of a coding in the exact name.
                it would be best to perform explicit declaration
                as the opposite defeats the purpose of using Typescript.

                but if there is a fair justification to use this, just use 'any' on the
                paginated object as long as you avoid expected crashes by adding conditions.

            */}
            <div className={'justify-content-center row mt-3'}>
                <div className={'col'}>
                    <Table
                        className={'table-striped table-hover'}
                        height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                        bgIndex={1}
                        inModal={true}
                    >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {TableHead}
                                    {/* action section is created here. */}
                                    {
                                        ACTIONS.length
                                            ? (<th colSpan={ACTIONS.length}>
                                                <small>{TEXT.TABLE.ACTIONS}</small>
                                            </th>)
                                            : <th className={'d-none'}></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TableBody}
                            </tbody>
                        </table>
                    </Table>
                </div>
            </div>
        </div>
    )
}
export default SectionDetails

import {
    ChapterDataColumn,
    DetailedLineChart
} from '@interfaces/dashboard/grc/nist'
import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    // FAULTY_CALL: 'EXT-VSS call returned faulty.',
    FAULTY_CALL: 'NIST call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    EMPTY_RECOMMENDATIONS: 'No recommendations found for this section...',
    FETCH: {
        MODAL: 'Fetching modal data...',
        DETAILS: 'Fetching details...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        OVERALL_COMPLIANCE: 'NIST - Overall Compliance',
        OVERALL_COVERAGE: 'NIST - Overall Coverage',
        COMPLIANCE_SCORES: 'Compliance Scores',
        COVERAGE_SCORES: 'Coverage Scores',
        GAUGE_CONTAINERS: {
            COVERAGE: 'Coverage',
            COMPLIANCE: 'Compliance'
        }
    }
}

export const DETAILED_LINE_CHART_COLUMNS: {
    value: keyof DetailedLineChart,
    label: string
}[] = [
    {
        label: 'Name',
        value: 'label'
    },
    {
        label: 'Average',
        value: 'data'
    }
]

/** section detail table */
export const SECTION_DETAIL_COLUMNS: ChapterDataColumn[] = (() => {
    const columnNames: Omit<ChapterDataColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Name',
            value: 'chapterName'
        },
        {
            label: 'Identifier',
            value: 'chapterIdentifier'
        },
        {
            label: 'Compliance Score',
            value: 'averageTotalComplianceScore'
        },
        {
            label: 'Coverage Score',
            value: 'averageTotalCoverageScore'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: ChapterDataColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

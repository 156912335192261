
import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as O365_MESSAGE,
    ATTACHMENT_DOUGHNUT_COLUMNS,
    EXCHANGE_CLIENT_IP_DOUGHNUT_COLUMNS,
    TEXT as O365_TEXT
} from '@constants/dashboard/soc/o365/detailedDashboard/exchange'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    Bucket,
    Doc
} from '@interfaces/dashboard/monitor'
import {
    EventType
} from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectDashboardData,
    selectFixedCollapsibles
} from '@slices/dashboard/soc/o365/detailedDashboard/exchange'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    PageBreakInside,
    SpinnerContainer,
    Table,
    TableColorCell
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'

interface ComponentProps {
    eventType: Extract<EventType, 'Attachments.Doughnut' | 'ExchangeClientIP.Doughnut'>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const O365DoughnutChart = ({
    eventType, isLoading, isSuccess, error
}:ComponentProps) => {
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const [
        chartInstance,
        setChartInstance
    ] = useState<Chart<'doughnut', { docCount: number }[], string>>()

    /** colors for both chart and table. will be set in chart initialization but will
     * only target lifecycle of table. doing so in chart will trigger an infinite render loop.
    */
    const [chartColors, setChartColors] = useState<string[]>([])

    /** i don't want to duplicate the code snippet so we'll just create
     * this separately. Plus the pseudo code ended up taking up more than 300 lines.
     * we end up having to make each chart a separate file BUT at least it's somewhere else.
     *
     * UPDATE: 9/16/2022. Ended up being one chart instance with multiple switch cases.
     */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** There was an attempt to make a useMemo BUT realized that
     * the lifecycle would be inconsistent. we want a cleanup function
     * before creating the chart instance for every lifecycle.
     *
     * one more thing, only memoize the data on performance leaks.
     * doing so ignoring this reminder led to chart tooltip drawing
     * errors especially when you have useRefs to create visualizations.
     *
     * this occured when memozing the table (an alternative to display
     * data counts).
     *
     * TypeError: Cannot read properties of undefined (reading 'handleEvent')
     * this error above occurs when the dispatches on that onclick event
     * are in the wrong order.
     *
     * Issue with search params where you update the ranges. It
     * will mess up the form data for fetching either o365 details or data id
     * when selecting the first OR last dataset
     * in the charts OR the tables below them.
     */

    /** we already have an array of issueAlert. We don't need a zoom chart for this. */
    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const buckets = _.orderBy(
            dashboardData[eventType]
                ?.aggregations?.[2]?.buckets || []
            , ['doc_count'], ['desc'])
        let graph: Chart<'doughnut', { docCount: number; }[], string>

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const colors: string[] = _.map(
                buckets, (_i, index) => CHART_COLORS[index]
            )

            const datasets: typeof graph.data.datasets = [{
                data: _.map(buckets, (bucket) => {
                    return { docCount: bucket.doc_count }
                }),
                // random color because of each key.
                backgroundColor: colors,
                borderWidth: 0,
                parsing: {
                    key: 'docCount'
                }
            }]

            setChartColors(colors)

            graph = new Chart(chartEl.current, {
                type: 'doughnut',
                data: {
                    labels: _.map(buckets, (bucket) => bucket.key.toString()),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        dashboardData[eventType]
    ])

    const DataTable = useMemo(() => {
        const buckets = _.orderBy(
            dashboardData[eventType]
                ?.aggregations?.[2]?.buckets || []
            , ['doc_count'], ['desc'])

        const cellBody = (
            dataObject: Bucket,
            property: keyof Bucket
        ) => {
            let cellContent: Bucket[keyof Bucket] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return (
                <div>
                    {cellContent}
                </div>
            )
        }

        let eventTypeColumns: {
            label: string;
            value: keyof Doc;
        }[] = []

        switch (eventType) {
            case 'ExchangeClientIP.Doughnut':
                eventTypeColumns = EXCHANGE_CLIENT_IP_DOUGHNUT_COLUMNS

                break
            case 'Attachments.Doughnut':
                eventTypeColumns = ATTACHMENT_DOUGHNUT_COLUMNS
                break
            default:
                break
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        <th></th>
                        {
                            _.map(eventTypeColumns,
                                ({ label }, index) => {
                                    const key = [
                                        'o365Activity-th-', index
                                    ].join('')
                                    return <th key={key}><small>{label}</small></th>
                                })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(buckets, (dataObject, rowIndex, arr) => {
                            return (
                                <tr
                                    key={'o365Activity-tr-' + rowIndex}
                                >
                                    <TableColorCell color={chartColors[rowIndex]} key={[
                                        'tableColorCell-', rowIndex
                                    ].join('')} />
                                    {
                                        _.map(
                                            eventTypeColumns,
                                            (column, cellIndex) => {
                                                return (
                                                    <td key={[
                                                        'o365Activity-td-' + rowIndex +
                                            '-' + cellIndex
                                                    ].join('')}
                                                    >
                                                        {cellBody(dataObject, column.value)}
                                                    </td>
                                                )
                                            })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            buckets.length
                ? content
                : EmptyCellContent
        )
    }, [
        dashboardData[eventType],
        chartInstance,
        chartColors
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        let titleComponent = ''

        switch (eventType) {
            case 'ExchangeClientIP.Doughnut':
                titleComponent = O365_TEXT.SECTIONS.EXCHANGE_CLIENT_IP_DOUGHNUT
                break
            case 'Attachments.Doughnut':
                titleComponent = O365_TEXT.SECTIONS.ATTACHMENT_DOUGHNUT
                break
        }

        const content = (
            /** removed Container tag and transferred
             * to the chart instance
             * Also found pdf bug where the title wasn't performing page-break
             * along with the chart instance. decided to move it here instead from
             * O365Modal.tsx
             */
            <div>
                <PageBreakInside className={'row'}>
                    <div className={'col'}>
                        <span className={'d-block mb-2'}>
                            {titleComponent}
                        </span>
                        <Container bgIndex={2} className={'mb-2'}>
                            <canvas ref={chartEl}/>
                        </Container>
                    </div>
                </PageBreakInside>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles[eventType]
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </div>
        )

        let fetchMessage: string = ''
        switch (eventType) {
            case 'ExchangeClientIP.Doughnut':
                fetchMessage = O365_MESSAGE.FETCH.EXCHANGE_CLIENT_IP_DOUGHNUT
                break
            case 'Attachments.Doughnut':
                fetchMessage = O365_MESSAGE.FETCH.ATTACHMENT_DOUGHNUT
                break
            default:
                break
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        fetchMessage
                    }</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

O365DoughnutChart.propTypes = {
    modal: PropTypes.object,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default O365DoughnutChart

import {
    EVENT_ROUTES,
    REPORT_ROUTES
} from '@constants/main/routes'
import CurrentEvents from '@features/watchdog/soc-data/event/CurrentEvents'
import EscalatedEvents from '@features/watchdog/soc-data/event/EscalatedEvents'
import EventSearch from '@features/watchdog/soc-data/event/EventSearch'
import ReportRequested from '@features/watchdog/soc-data/event/ReportRequested'
import CreatedReports from '@features/watchdog/soc-data/reports/CreatedReports'
import ReportInterface from '@features/watchdog/soc-data/reports/ReportInterface'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: EVENT_ROUTES.CURRENT.link,
        component: CurrentEvents
    },
    {
        exact: true,
        path: EVENT_ROUTES.ESCALATED.link,
        component: EscalatedEvents
    },
    {
        exact: true,
        path: REPORT_ROUTES.MAIN.link,
        component: ReportRequested
    },
    {
        exact: true,
        path: EVENT_ROUTES.SEARCH.link,
        component: EventSearch
    },
    {
        exact: true,
        path: REPORT_ROUTES.CREATED_REPORTS.link,
        component: CreatedReports
    },
    {
        exact: true,
        path: [
            REPORT_ROUTES.REPORT_INTERFACE.link,
            '/:reportObj'
        ].join(''),
        component: ReportInterface
    }
]

export default Routes

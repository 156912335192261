
import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as IMM_MESSAGE
} from '@constants/dashboard/tac/imm'
import {
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    IncidentData,
    MeanIncidentClassifications
} from '@interfaces/dashboard/tac/imm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams
} from '@slices/dashboard/tac/imm'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    CategoryScale,
    Chart,
    ChartDatasetProperties,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'

interface ComponentProps {
    data: IncidentData[] | undefined,
    incidentResponseMode: 0 | 1,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const MeanIncidentResponseChart = ({
    data, incidentResponseMode, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(LineController, LineElement, Legend, PointElement,
            CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'line', { tooltipTitle:string, x: Date; y: number; }[], Date>

        let classifications: MeanIncidentClassifications[] = []

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        const incidentData = data || []

        if (incidentResponseMode === 0) {
            classifications = [
                {
                    label: 'Incident Discovery Time',
                    color: stylesheet.style.eventType.alertColor
                },
                {
                    label: 'Time Between Incidents',
                    color: stylesheet.style.eventType.dhcpColor
                },
                {
                    label: 'Incident Recovery Time',
                    color: stylesheet.style.eventType.dnsColor
                }
            ]
        } else {
            classifications = [
                {
                    label: 'Incident Containment Time',
                    color: stylesheet.style.eventType.fileinfoColor
                },
                {
                    label: 'Incident Eradication Time',
                    color: stylesheet.style.eventType.flowColor
                }
            ]
        }

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const datasets: typeof graph.data.datasets = _.map(
                classifications,
                (category, index) => {
                    let scores: ChartDatasetProperties<'line',
                    { tooltipTitle: string, x: Date; y: number; }[]>['data'] = []

                    const sortedData: IncidentData[] = incidentData.sort((a, b) => {
                        if (category.label === 'Incident Discovery Time') {
                            return a.timelines.discovered - b.timelines.discovered
                        } else if (category.label === 'Time Between Incidents') {
                            return a.timelines.started - b.timelines.started
                        } else if (category.label === 'Incident Recovery Time') {
                            return a.timelines.resolved - b.timelines.resolved
                        } else if (category.label === 'Incident Containment Time') {
                            return a.timelines.contained - b.timelines.contained
                        } else if (category.label === 'Incident Eradication Time') {
                            return a.timelines.eradicated - b.timelines.eradicated
                        }

                        return 0
                    })

                    scores = _.map(sortedData, (incidentObj) => {
                        let timestamp: Date = new Date()
                        let value: number = 0

                        if (category.label === 'Incident Discovery Time') {
                            timestamp = fromUnixTime(incidentObj.timelines.discovered)
                            value = incidentObj.timelines.discovered - incidentObj.timelines.started
                        } else if (category.label === 'Time Between Incidents') {
                            timestamp = fromUnixTime(incidentObj.timelines.started)
                            if (incidentData[index + 1]) {
                                value = incidentData[index + 1].timelines.started -
                                incidentObj.timelines.started
                            } else if (incidentData[index - 1]) {
                                // console.log("previous object: ",arr[index-1])
                                value = incidentObj.timelines.started -
                                incidentData[index - 1].timelines.started
                            }
                        } else if (category.label === 'Incident Recovery Time') {
                            timestamp = fromUnixTime(incidentObj.timelines.resolved)
                            value = incidentObj.timelines.resolved -
                             incidentObj.timelines.discovered
                        } else if (category.label === 'Incident Containment Time') {
                            timestamp = fromUnixTime(incidentObj.timelines.contained)
                            value = incidentObj.timelines.contained -
                                incidentObj.timelines.discovered
                        } else if (category.label === 'Incident Eradication Time') {
                            timestamp = fromUnixTime(incidentObj.timelines.eradicated)
                            value = incidentObj.timelines.eradicated -
                                incidentObj.timelines.discovered
                        }

                        return {
                            tooltipTitle: [
                                incidentObj.title,
                                ['(', incidentObj.category, ')'].join('')
                            ].join(' '),
                            x: timestamp,
                            y: value
                        }
                    })

                    return ({
                        data: scores,
                        label: category.label,
                        fill: false,
                        borderColor: category.color,
                        backgroundColor: category.color,
                        borderWidth: 1
                    })
                })

            graph = new Chart(chartEl.current, {
                type: 'line',
                data: {
                    labels: [
                        ranges.start,
                        ranges.end
                    ],
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: stylesheet.mode.fontColor
                            },
                            position: 'top',
                            align: 'center'
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const dataset = datasets[tooltipItem.datasetIndex]
                                    const data = dataset.data[tooltipItem.dataIndex]
                                    const tooltipTitle = data.tooltipTitle
                                    const convertToHours = Math.round(data.y * 100) / 100

                                    return [
                                        tooltipTitle, ':', convertToHours, 'hours'
                                    ].join(' ')
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            beginAtZero: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.0
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            // setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        data
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{IMM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

MeanIncidentResponseChart.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default MeanIncidentResponseChart

import type { RootState } from '@app/store'
import { Reducer } from '@reduxjs/toolkit'
import {
    connectRouter,
    routerMiddleware as middleware,
    RouterState
} from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { AnyAction } from 'redux'

export const history = createBrowserHistory()
export const routerMiddleware = middleware(history)

export default connectRouter(history) as Reducer<RouterState, AnyAction>

export const selectRouter = (state: RootState) => state.router

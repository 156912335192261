import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { getUrlName } from '@constants/main/method'
import {
    addNewTab,
    removeTab,
    resetActiveTabs,
    selectActiveTabs,
    storeActiveTabs
} from '@slices/main/headerNav'
import { selectRouter } from '@slices/main/router'

import { NavigationBarStyledComponents as Nav } from '@styles/components'
import Tippy from '@tippyjs/react'
import { push } from 'connected-react-router'
import _ from 'lodash'

import React, { useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'

const NavigationBar = () => {
    const dispatch = useAppDispatch()
    const activeTabs = useAppSelector(selectActiveTabs)
    const router = useAppSelector(selectRouter)

    useEffect(() => {
        dispatch(storeActiveTabs())
    }, [activeTabs])

    useEffect(() => {
        return () => {
            dispatch(resetActiveTabs())
        }
    }, [])

    /** when a router.location.pathname is updated, add it to the list. */
    useEffect(() => {
        // console.log('url: ', router.location.pathname)
        // console.log('name: ', getUrlName(router.location.pathname))

        if (router.location.pathname !== '/') {
            dispatch(addNewTab({
                link: router.location.pathname,
                name: getUrlName(router.location.pathname) || router.location.pathname
            }))
        }
    }, [router.location.pathname])

    return <div className={'col-auto'}>
        <Nav.Controller className={'row p-2'}>
            {
                _.map(activeTabs, (o, index, arr) => {
                    const key = index
                    // don't put them in a group, each link should be its own tab.
                    // for convenience
                    const active = o.link === router.location.pathname

                    /**
                 * when closing the tab, don't forget to redirect the user
                 * to the tab on the left.
                 */
                    const closeRedirect = () => {
                    // redirect the user to the tab on the left.
                        const tabToLeft = arr[index - 1]
                        // OR redirect the user to the tab on the right
                        // if the index is zero.
                        const tabToRight = arr[index + 1]

                        // in cases where you are in between, left holds more priority than right.
                        if (tabToLeft) {
                            dispatch(push(tabToLeft.link))
                        } else if (tabToRight) {
                            dispatch(push(tabToRight.link))
                        }
                        // then close it.
                        dispatch(removeTab(o))

                        // before dispatch, if there is only one tab,
                        // redirect to ""
                        if (arr.length === 1) {
                            dispatch(push(''))
                        }
                    }

                    return <Tippy
                        key={key}
                        className={'tippy-box'}
                        arrow
                        content={<div>{o.name}</div>}>
                        <Nav.Tab className={'col-auto'}>
                            <div className={'row justify-content-between'}>
                                <Nav.Link onClick={(e) => {
                                    active ? e.preventDefault() : dispatch(push(o.link))
                                }} className={`col-auto pb-1 ${ active ? 'selected' : '' }`}>
                                    <Nav.Label>
                                        <h6 className={'m-0'}>{o.name}</h6>
                                    </Nav.Label>
                                </Nav.Link>
                                <Nav.Close className={'col-auto'} onClick={closeRedirect}>
                                    <FaTimes />
                                </Nav.Close>
                            </div>
                        </Nav.Tab>
                    </Tippy>
                })
            }
        </Nav.Controller>
    </div>
}

export default NavigationBar

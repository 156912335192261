import { ORDER_ROUTES } from '@constants/main/routes'
import OrderAdd from '@features/watchdog/account-data/order/add/OrderAdd'
import OrderMenu from '@features/watchdog/account-data/order/OrderMenu'
import OrderUpdate from '@features/watchdog/account-data/order/update/OrderUpdate'
import OrderView from '@features/watchdog/account-data/order/OrderView'
import OrderDispatch from '@features/watchdog/account-data/order/dispatch/OrderDispatch'
import OrderDevices from '@features/watchdog/account-data/order/OrderDevices'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: ORDER_ROUTES.MAIN.link,
        component: OrderMenu
    },
    {
        exact: true,
        path: ORDER_ROUTES.ADD.link,
        component: OrderAdd
    },
    {
        exact: true,
        path: [
            ORDER_ROUTES.UPDATE.link,
            '/:orderid'
        ].join(''),
        component: OrderUpdate
    },
    {
        exact: true,
        path: [
            ORDER_ROUTES.VIEW.link,
            '/:orderid'
        ].join(''),
        component: OrderView
    },
    {
        exact: true,
        path: [
            ORDER_ROUTES.DISPATCH.link,
            '/:orderid'
        ].join(''),
        component: OrderDispatch
    },
    {
        exact: true,
        path: [
            ORDER_ROUTES.DEVICEID_SEARCH.link
        ].join(''),
        component: OrderDevices
    }
]

export default Routes

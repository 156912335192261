import {
    DashboardData,
    AzureEventColumn,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    AzureDetailedSearchParams
} from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import {
    Doc
} from '@interfaces/dashboard/monitor'
import { CHART_ZOOM } from '@constants/dashboard/monitor'
import _ from 'lodash'
import {
    getUnixTime,
    sub
} from 'date-fns'

export const EVENT_TYPES: EventType[] = ['Azure.AzureActivity', 'Azure.CommonSecurityLog',
    'Azure.EventTypes', 'Azure.Events', 'Azure.Locations', 'Azure.ProtectionStatus',
    'Azure.SecurityEvent', 'Azure.SysLog.Computer', 'Azure.SysLog.ProcessName', 'Azure.Update']

/** was _.Dictionary<EventTypeBooleans> but used type assertion. */
export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

export const DEFAULT_CHART_ZOOMS = _.zipObject(
    EVENT_TYPES,
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_SEARCH_PARAMS: AzureDetailedSearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    /** note that the refetches are now from separate event types.
     * so if you change the parameters from date ranges and search queries,
     * then everything refreshes,
    */
    refetch: _.cloneDeep(DEFAULT_REFETCH),
    q: '',

    /** for displaying filters. */
    boolList: [],
    /** scripts won't be executed if deviceid IS an empty string, */
    card: {
        details: {},
        deviceid: '',
        in_face: '',
        service_type: 'bdg-bas'
    }
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Azure Detailed call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        AZURE_EVENT_TYPES: 'Fetching Event Types data...',
        AZURE_PROTECTION_STATUS: 'Fetching Protection Status data...',
        AZURE_SYSLOG_PROCESS_NAME: 'Fetching System Log Process Name data...',
        AZURE_SYSLOG_COMPUTER: 'Fetching System Log Computer data...',
        AZURE_COMMON_SECURITY_LOG: 'Fetching Common Security Log data...',
        AZURE_AZURE_ACTIVITY: 'Fetching Azure Activity data...',
        AZURE_SECURITY_EVENT: 'Fetching Security Event data...',
        AZURE_UPDATE: 'Fetching Update data...',
        AZURE_LOCATIONS: 'Fetching Locations data...',
        AZURE_EVENTS: 'Fetching Azure Events data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        AZURE_EVENT_TYPES: 'Event Types',
        AZURE_PROTECTION_STATUS: 'Protection Status',
        AZURE_SYSLOG_PROCESS_NAME: 'System Log Process Name',
        AZURE_SYSLOG_COMPUTER: 'System Log Computer',
        AZURE_COMMON_SECURITY_LOG: 'Common Security Log',
        AZURE_AZURE_ACTIVITY: 'Azure Activity',
        AZURE_SECURITY_EVENT: 'Security Event',
        AZURE_UPDATE: 'Update',
        AZURE_LOCATIONS: 'Locations',
        AZURE_EVENTS: 'All Azure Events This Period'
    }
}

// type
export const AZURE_EVENT_TYPES_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Type',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

// computer
export const AZURE_COMMON_SECURITY_LOG_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Computer',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const AZURE_SYSLOG_COMPUTER_COLUMNS: {label: string
    , value: keyof Doc }[] = AZURE_COMMON_SECURITY_LOG_COLUMNS
export const AZURE_PROTECTION_STATUS_COLUMNS: {label: string
    , value: keyof Doc }[] = AZURE_COMMON_SECURITY_LOG_COLUMNS
export const AZURE_SECURITY_EVENT_COLUMNS: {label: string
    , value: keyof Doc }[] = AZURE_COMMON_SECURITY_LOG_COLUMNS
export const AZURE_UPDATE_COLUMNS: {label: string
    , value: keyof Doc }[] = AZURE_COMMON_SECURITY_LOG_COLUMNS

// processname
export const AZURE_SYSLOG_PROCESS_NAME_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Process Name',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

// resourcegroup
export const AZURE_AZURE_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Resource Group',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const AZURE_EVENTS_COLUMNS: AzureEventColumn[] = (() => {
    const columnNames: Omit<AzureEventColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'Category',
            value: 'Category'
        },
        {
            label: 'Computer',
            value: 'Computer'
        },
        {
            label: 'Process Name',
            value: 'ProcessName'
        },
        {
            label: 'Reason',
            value: 'Reason'
        },
        {
            label: 'Resource Group',
            value: 'ResourceGroup'
        },
        {
            label: 'Source System',
            value: 'SourceSystem'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: AzureEventColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const DASHBOARD_DATA: DashboardData = {
    'Azure.AzureActivity': undefined,
    'Azure.CommonSecurityLog': undefined,
    'Azure.EventTypes': undefined,
    'Azure.Events': undefined,
    'Azure.Locations': undefined,
    'Azure.ProtectionStatus': undefined,
    'Azure.SecurityEvent': undefined,
    'Azure.SysLog.Computer': undefined,
    'Azure.SysLog.ProcessName': undefined,
    'Azure.Update': undefined
}

export const PRINT_REPORT_TITLE = 'Microsoft 365 Azure Detailed Dashboard'

import { RootState } from '@app/store'
import {
    AZURE_EVENTS_COLUMNS,
    DEFAULT_REFETCH,
    DEFAULT_CHART_ZOOMS,
    INITIAL_SEARCH_PARAMS,
    DASHBOARD_DATA as INITIAL_DASHBOARD_DATA
} from '@constants/dashboard/soc/azure/detailedDashboard/main'
import { DEFAULT_TABLE_DATA } from '@constants/main/root'
import {
    DetailedCard,
    MonitorModal,
    Location,
    ChartZoomBucket,
    ChartZoom
} from '@interfaces/dashboard/monitor'
import {
    AzureEventColumn,
    AzureEventFilter,
    EventTypeBooleans,
    FixedCollapsibles,
    AzureDetailedDashboardState,
    EventTypeChartZooms,
    AzureEvent,
    AzureEventsResponse,
    AzureUpdateResponse,
    AzureLocationsResponse,
    AzureEventTypesResponse,
    AzureSecurityEventResponse,
    AzureAzureActivityResponse,
    AzureProtectionStatusResponse,
    AzureSysLogComputerResponse,
    AzureCommonSecurityLogResponse,
    AzureSysLogProcessNameResponse
} from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import { RefreshTime } from '@interfaces/main/root'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import deepEqual from 'deep-equal'
import _ from 'lodash'
import { tryParseJSON } from '@constants/main/method'
import { SCROLL_ID_DATA } from '@constants/dashboard/monitor'

/** here's the plan for detailed dashboards with different device ids.
 * types are the following: main, sharepoint, exchange and kubernetes.
 */

/**
 * Modified at October 6, 2022: Getting detailed dashboard localStorage
 * will now be an array of DetailedSearchParams that are all partial
 * due to data manipulation implementing strict checks. Moved to the detailed
 * dashboard component since we need the useParams method.
 */

const initialState : AzureDetailedDashboardState = {
    /** don't forget to store entire searchParams object in localStorage for
     * page refresh. UseEffect hook with searchParams as the dependency
     * to continuously update all in one place.
     */
    // searchParams: getFromLocalStorage(),
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    /** this is just to provide printOptions optional properties
     * on start. If you want the properties to be mandatory,
     * modify them in the state's interface.
     */
    printOptions: {
        logo: ''
    },
    tableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: AZURE_EVENTS_COLUMNS,
        filtered: [],
        paginated: []
    },
    fixedCollapsibles: _.cloneDeep(DEFAULT_REFETCH),
    /** for chartjs instances when zooming in or zooming out.
     * but we don't need to do it since we will just unmount the entire
     * thing anyway.
     */
    chartZooms: _.cloneDeep(DEFAULT_CHART_ZOOMS),
    /** azure map where the rest of the data is retrieved in scrollid. */
    mapData: _.cloneDeep(SCROLL_ID_DATA),
    modals: [],
    dashboardData: _.cloneDeep(INITIAL_DASHBOARD_DATA)
}

export const slice = createSlice({
    name: 'azureDbMain',
    initialState: initialState,
    reducers: {
        /** will be used in azure modal and selecting different device types. */
        setLocalStorage: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureDetailedDashboardState['currentParams']>
        ) => {
            /**
             * added in October 7, 2022:
             * since the localStorage is an array of instances where the user
             * navigated to the detailed dashboard for each device id,
             * we will check if there is no instance yet and add accordingly
             */

            // get the current value of the localStorage
            const userJson = localStorage.getItem('azure-db-main-search-params')

            if (userJson === null) {
                localStorage.setItem(
                    'azure-db-main-search-params',
                    JSON.stringify([
                        action.payload
                    ])
                )
                console.log('Storage nonexistant. Creating new item.')
            } else {
                const result = tryParseJSON(userJson) as unknown as
                AzureDetailedDashboardState['currentParams'][]

                /** check if the variable is an array. */
                if (_.isArray(result)) {
                    /** then we find the object using the deviceid as our conditional check */
                    const foundIndex = _.findIndex(result, (obj) => {
                        return obj.card.deviceid === action.payload.card.deviceid
                    })

                    /** don't do !foundIndex. do foundIndex < 0 */

                    if (foundIndex < 0) {
                        // console.log('pushing instance')
                        result.push(action.payload)
                    } else {
                        // if it already exists, just update the values and update
                        // the localStorage
                        // console.log('updating instance')
                        result[foundIndex] = action.payload
                    }

                    localStorage.setItem(
                        'azure-db-main-search-params',
                        JSON.stringify(result)
                    )
                }
            }
        },
        setSearchParams: (state: AzureDetailedDashboardState, action: PayloadAction<
            AzureDetailedDashboardState['searchParams']>
        ) => {
            state.searchParams = action.payload
        },
        setStartDate: (state: AzureDetailedDashboardState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: AzureDetailedDashboardState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: AzureDetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeBooleans,
            value: boolean
        }>) => {
            state.searchParams.refetch[action.payload.key] = action.payload.value
        },
        setLogo: (state: AzureDetailedDashboardState, action: PayloadAction<string>) => {
            state.printOptions.logo = action.payload
        },
        setCard: (state: AzureDetailedDashboardState, action: PayloadAction<DetailedCard>) => {
            state.searchParams.card = action.payload
        },
        setQ: (state: AzureDetailedDashboardState, action: PayloadAction<string>) => {
            state.searchParams.q = action.payload
        },
        /** shouldn't be confused with adding filters to table data. */
        setBoolList: (state: AzureDetailedDashboardState,
            action: PayloadAction<AzureEventFilter[]>) => {
            state.searchParams.boolList = action.payload
        },
        addBool: (state: AzureDetailedDashboardState, action: PayloadAction<AzureEventFilter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.searchParams.boolList, bool => {
                return (
                    bool.not === action.payload.not &&
                    bool.sort === action.payload.sort &&
                    bool.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.searchParams.boolList.push(action.payload)
            }
        },
        removeBool: (state: AzureDetailedDashboardState,
            action: PayloadAction<AzureEventFilter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.searchParams.boolList, bool => {
                return (
                    bool.not === action.payload.not &&
                    bool.sort === action.payload.sort &&
                    deepEqual(bool.value, action.payload.value)
                )
            })
        },
        removeBoolList: (state: AzureDetailedDashboardState) => {
            state.searchParams.boolList = []
        },
        setCurrentParams: (state: AzureDetailedDashboardState, action: PayloadAction<
            AzureDetailedDashboardState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        setPage: (state: AzureDetailedDashboardState, action: PayloadAction<number>) => {
            state.tableData.page = action.payload
        },
        setCount: (state: AzureDetailedDashboardState, action: PayloadAction<number>) => {
            state.tableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: AzureDetailedDashboardState,
            action: PayloadAction<RefreshTime>) => {
            state.tableData.interval = action.payload
        },
        setSearch: (state: AzureDetailedDashboardState, action: PayloadAction<string>) => {
            state.tableData.search = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setColumns: (state: AzureDetailedDashboardState,
            action: PayloadAction<AzureEventColumn[]>) => {
            state.tableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setColumnInclude: (state: AzureDetailedDashboardState, action: PayloadAction<{
            value: AzureEventColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setColumnArrange: (state: AzureDetailedDashboardState, action: PayloadAction<{
            value: AzureEventColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setFiltered: (state: AzureDetailedDashboardState, action: PayloadAction<AzureEvent[]>) => {
            state.tableData.filtered = action.payload
        },
        setPaginated: (state: AzureDetailedDashboardState, action: PayloadAction<AzureEvent[]>) => {
            state.tableData.paginated = action.payload
        },
        toggleCollapsible: (state: AzureDetailedDashboardState, action: PayloadAction<{
            key: keyof FixedCollapsibles,
            value: boolean
        }>) => {
            state.fixedCollapsibles[action.payload.key] = action.payload.value
        },
        setChartZoomLevel: (state: AzureDetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: number
        }>) => {
            state.chartZooms[action.payload.key].zoomLevel = action.payload.value
        },
        setChartTitle: (state: AzureDetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: string
        }>) => {
            state.chartZooms[action.payload.key].title = action.payload.value
        },
        setChartBucket3: (state: AzureDetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: ChartZoomBucket
        }>) => {
            state.chartZooms[action.payload.key].bucket = action.payload.value
        },
        setChartZoom: (state: AzureDetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: ChartZoom
        }>) => {
            state.chartZooms[action.payload.key] = action.payload.value
        },
        /** set totalRecords on initial result and after every scrollid,
         * update the array. that's it.
         */
        setTotalRecords: (state: AzureDetailedDashboardState, action: PayloadAction<number>) => {
            state.mapData.totalRecords = action.payload
        },
        setIsComplete: (state: AzureDetailedDashboardState, action: PayloadAction<boolean>) => {
            state.mapData.isComplete = action.payload
        },
        setScrollId: (state: AzureDetailedDashboardState, action: PayloadAction<string>) => {
            state.mapData.scrollId = action.payload
        },
        setMapData: (state: AzureDetailedDashboardState, action: PayloadAction<Location[]>) => {
            state.mapData.data = action.payload
        },
        addModal: (state: AzureDetailedDashboardState, action: PayloadAction<MonitorModal>) => {
            state.modals.push(action.payload)
        },
        closeModal: (state: AzureDetailedDashboardState, action: PayloadAction<MonitorModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.open = false
            }
        },
        removeModal: (state: AzureDetailedDashboardState, action: PayloadAction<MonitorModal>) => {
            _.remove(state.modals, modal => {
                return (
                    modal.id === action.payload.id
                )
            })
        },
        /** the only times you will set the data to undefined are when
         * you navigate to this page (dashboard card, modal, and the sidebar component)
         */
        setAzureEventTypesData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureEventTypesResponse | undefined>
        ) => {
            state.dashboardData['Azure.EventTypes'] = action.payload
        },
        setAzureProtectionStatusData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureProtectionStatusResponse | undefined>
        ) => {
            state.dashboardData['Azure.ProtectionStatus'] = action.payload
        },
        setAzureSysLogProcessNameData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureSysLogProcessNameResponse | undefined>
        ) => {
            state.dashboardData['Azure.SysLog.ProcessName'] = action.payload
        },
        setAzureSysLogComputerData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureSysLogComputerResponse | undefined>
        ) => {
            state.dashboardData['Azure.SysLog.Computer'] = action.payload
        },
        setAzureCommonSecurityLogData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureCommonSecurityLogResponse | undefined>
        ) => {
            state.dashboardData['Azure.CommonSecurityLog'] = action.payload
        },
        setAzureAzureActivityData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureAzureActivityResponse | undefined>
        ) => {
            state.dashboardData['Azure.AzureActivity'] = action.payload
        },
        setAzureSecurityEventData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureSecurityEventResponse | undefined>
        ) => {
            state.dashboardData['Azure.SecurityEvent'] = action.payload
        },
        setAzureUpdateData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureUpdateResponse | undefined>
        ) => {
            state.dashboardData['Azure.Update'] = action.payload
        },
        setAzureEventsData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureEventsResponse | undefined>
        ) => {
            state.dashboardData['Azure.Events'] = action.payload
        },
        setAzureLocationsData: (
            state: AzureDetailedDashboardState,
            action: PayloadAction<AzureLocationsResponse | undefined>
        ) => {
            state.dashboardData['Azure.Locations'] = action.payload
        },
        resetAzureDetailed: (state: AzureDetailedDashboardState) => {
            /** October 5, 2022: simplified bug issues into case scenarios:
             *
             *  CASE:
             * - Logs in to dashboard.
             * - Selects dashboard card.
             * - Modifies search parameters
             * - Dashboard refreshes results.
             * - Logs out and in again
             * - Navigates to dashboard via url.
             *
             * Detailed dashboard localStorage should ONLY be cleared
             * if the current deviceid is different from the selected
             * dashboard card's.
             *
             * For the state, only reset it IF the user is deauthenticated.
             * For the properties, preserve search parameters.
             *
             * Addressing an issue where one navigates the same url
             * but with different deviceids IN multiple tabs at once.
             *
             * Avoid using sessionStorage as multiple tabs can interchange
             * on what cards they want to use.
             *
             * We can make the item an array of instances
             * where in the user navigated to the detailed dashboard using
             * that specific deviceid name AT LEAST once.
             */

            state.chartZooms = initialState.chartZooms
            state.currentParams = initialState.currentParams
            state.dashboardData = initialState.dashboardData
            state.fixedCollapsibles = initialState.fixedCollapsibles
            state.mapData = initialState.mapData
            state.modals = initialState.modals
            state.printOptions = initialState.printOptions
            state.searchParams = initialState.searchParams
            state.tableData = initialState.tableData
        }
    }
})

export const {
    setLocalStorage,
    setSearchParams,
    setStartDate,
    setEndDate,
    setCard,
    setRefetch,
    setLogo,
    setQ,
    setBoolList,
    addBool,
    removeBool,
    removeBoolList,
    setCurrentParams,
    setPage,
    setCount,
    setIntervalObj,
    setSearch,
    setColumns,
    setColumnInclude,
    setColumnArrange,
    setFiltered,
    setPaginated,
    setChartZoomLevel,
    setChartTitle,
    setChartBucket3,
    setChartZoom,
    setTotalRecords,
    setIsComplete,
    setScrollId,
    setMapData,
    addModal,
    closeModal,
    removeModal,
    toggleCollapsible,
    setAzureAzureActivityData,
    setAzureCommonSecurityLogData,
    setAzureEventTypesData,
    setAzureLocationsData,
    setAzureProtectionStatusData,
    setAzureSecurityEventData,
    setAzureSysLogComputerData,
    setAzureSysLogProcessNameData,
    setAzureUpdateData,
    setAzureEventsData,
    resetAzureDetailed
} = slice.actions

export const selectSearchParams = (state: RootState) => state
    .azureDbMain.searchParams
export const selectCurrentParams = (state: RootState) => state
    .azureDbMain.currentParams
export const selectPrintOptions = (state: RootState) => state
    .azureDbMain.printOptions
export const selectTableData = (state: RootState) => state
    .azureDbMain.tableData
export const selectFixedCollapsibles = (state: RootState) => state
    .azureDbMain.fixedCollapsibles
export const selectChartZooms = (state: RootState) => state
    .azureDbMain.chartZooms
export const selectMapData = (state: RootState) => state
    .azureDbMain.mapData
export const selectModals = (state: RootState) => state.azureDbMain.modals
export const selectDashboardData = (state: RootState) => state
    .azureDbMain.dashboardData

export default slice.reducer

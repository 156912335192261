
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    smartOrder,
    smartSearch
} from '@constants/main/method'

import { PERSIST_ISSUE_KEYS } from '@constants/dashboard/tac/vmm'
import {
    DEBOUNCE_SEARCH_TIME,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    PersistIssue
} from '@interfaces/dashboard/tac/vmm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams,
    selectTableData,
    setFiltered,
    setFilterKeys,
    setFilterValues,
    setSelectedValues
} from '@slices/dashboard/tac/vmm'
import {
    FilterCell,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import flatten from 'flat'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo
} from 'react'
import {
    FaSearch
} from 'react-icons/fa'
import { useDebouncedCallback } from 'use-debounce'

interface ComponentProps {
    data: PersistIssue[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const PersistIssuesTable = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const dispatch = useAppDispatch()

    const currentParams = useAppSelector(selectCurrentParams)
    const tableData = useAppSelector(selectTableData)

    const setData = useDebouncedCallback(() => {
        const result = data || []
        if (data) {
            const filteredData = smartSearch(
                result,
                tableData.filters,
                tableData.search
            ) || [] as PersistIssue[]
            const orderedData = smartOrder(filteredData, tableData.columns) as PersistIssue[]

            dispatch(setFiltered(orderedData))
        }
    }, DEBOUNCE_SEARCH_TIME)

    useEffect(() => {
        setData()
    }, [
        data,
        tableData.filters,
        tableData.search,
        tableData.columns
    ])

    /** ip address table with usual features. */
    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const label = (
                    <small>
                        {column.label}
                    </small>
                )

                return (
                    <th key={'column-' + index}>
                        {label}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: PersistIssue,
            property: keyof PersistIssue
        ) => {
            let cellContent: PersistIssue[keyof PersistIssue] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return (
                <div>
                    {cellContent}
                </div>
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.filtered, (dataObject, rowIndex) => {
                return (
                    <tr key={'persistIssue-row-' + rowIndex}>
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'persistIssue-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    >
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })
        )

        return (
            isLoading
                ? LoadingContent
                : isSuccess
                    ? (
                        tableData.filtered.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(error)
        )
    }, [
        tableData, currentParams.ranges,
        isLoading, isSuccess, error
    ])

    /** reset data on unmount */

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const arr = data || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = arr[0]
            ? _.filter(_.keys(
                flatten(arr[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : PERSIST_ISSUE_KEYS

        dispatch(
            setFilterKeys(result)
        )
    }, [
        data
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    return (
        <div>
            {/* table stuff */}
            {tableData.search
                ? <div className={'align-items-center justify-content-between row'}>
                    <div className={'col-12 px-4'}>
                        <SearchRow className={'align-items-center row'}>
                            <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                                <FaSearch />
                            </label>
                            <input
                                className={'col'}
                                type={'text'}
                                id={TEXT.SEARCH.SEARCH.ID}
                                defaultValue={tableData.search}
                                placeholder={'Search'}
                            />
                        </SearchRow>
                    </div>
                </div>
                : '' }
            {
                tableData.filters.length
                    ? <div className={'row mt-2'}>
                        {/* display columns aka a filter shere */}
                        <div className={'col ps-4'}>
                            <div className={'row'}>
                                {
                                    _.map(tableData.filters, (filter, index) => {
                                        const comparisonString = filter.value.length > 1
                                            ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                            : ':'
                                        return (
                                            <FilterCell key={'filter-' + index} className={
                                                ['col-auto me-2 px-2 mt-1',
                                                    filter.not ? 'not' : ''].join(' ')
                                            }>
                                                <Text size={'xs'}>
                                                    {[
                                                        [
                                                            filter.not ? '!' : '',
                                                            filter.sort
                                                        ].join(''),
                                                        comparisonString,
                                                        filter.value.join(', ')
                                                    ].join(' ')}
                                                </Text>
                                            </FilterCell>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    : ''
            }

            <div className={'justify-content-center row mt-3'}>
                <div className={'col'}>
                    <Table
                        className={'table-striped table-hover px-0'}
                        height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                        bgIndex={2}
                    >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {TableHead}
                                </tr>
                            </thead>
                            <tbody>
                                {TableBody}
                            </tbody>
                        </table>
                    </Table>
                </div>
            </div>

        </div>
    )
}

export default PersistIssuesTable

import _ from 'lodash'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    O365DataIdState
} from '@interfaces/dashboard/soc/o365/main'

import { INITIAL_SEARCH_PARAMS } from '@constants/dashboard/soc/o365/main'

const initialState : O365DataIdState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    )
}

export const slice = createSlice({
    name: 'o365DataId',
    initialState: initialState,
    reducers: {
        setStartDate: (state: O365DataIdState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: O365DataIdState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: O365DataIdState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setCurrentParams: (state: O365DataIdState, action: PayloadAction<
            O365DataIdState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        resetO365DataId: (state: O365DataIdState) => {
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setCurrentParams,
    resetO365DataId
} = slice.actions

export const selectSearchParams = (state: RootState) => state.o365DataId.searchParams
export const selectCurrentParams = (state: RootState) => state.o365DataId.currentParams

export default slice.reducer

import _ from 'lodash'
import {
    AZURE_DETAILED_DASHBOARD_ROUTES,
    COMPLIANCE_ROUTES,
    CONFIGURATION_STATUS_ROUTES,
    DASHBOARD_ACCOUNT_ROUTES,
    EVENT_ROUTES,
    FILTER_ROUTES,
    GRC_MONITOR_ROUTES,
    MDR_DETAILED_DASHBOARD_ROUTES,
    MONITORING_STATUS_ROUTES,
    O365_DETAILED_DASHBOARD_ROUTES,
    ORDER_ROUTES,
    PARTNER_ROUTES,
    PROFILE_ROUTES,
    QUESTIONNAIRE_ROUTES,
    QUESTION_ROUTES,
    REPORT_ROUTES,
    SOC_MONITOR_ROUTES,
    TACTICAL_MONITOR_ROUTES
} from '@constants/main/routes'

import { PageTab } from '@interfaces/main/sidebar'

export const PAGE_TAB_SELECTIONS: PageTab[] = [
    {
        name: 'Partners',
        links: _.values(PARTNER_ROUTES).map((route) => route.link)
    },
    {
        name: 'Orders',
        links: _.values(ORDER_ROUTES).map((route) => route.link)
    },
    {
        name: 'Configuration Status',
        links: _.values(CONFIGURATION_STATUS_ROUTES).map((route) => route.link)
    },
    {
        name: 'Dashboard Accounts',
        links: _.values(DASHBOARD_ACCOUNT_ROUTES).map((route) => route.link)
    },
    {
        name: 'SOC Monitors',
        links: _.values(SOC_MONITOR_ROUTES).map((route) => route.link)
    },
    {
        name: 'Tactical Monitors',
        links: _.values(TACTICAL_MONITOR_ROUTES).map((route) => route.link)
    },
    {
        name: 'GRC Monitors',
        links: _.values(GRC_MONITOR_ROUTES).map((route) => route.link)
    },
    {
        name: 'Profile',
        links: _.values(PROFILE_ROUTES).map((route) => route.link)
    },
    {
        name: 'Events',
        links: _.values(EVENT_ROUTES).map((route) => route.link)
    },
    {
        name: 'Reports',
        links: _.values(REPORT_ROUTES).map((route) => route.link)
    },
    {
        name: 'Filters',
        links: _.values(FILTER_ROUTES).map((route) => route.link)
    },
    {
        name: 'Monitoring Status',
        links: _.values(MONITORING_STATUS_ROUTES).map((route) => route.link)
    },
    {
        name: 'MDR Detailed Dashboard',
        links: _.values(MDR_DETAILED_DASHBOARD_ROUTES).map((route) => route.link)
    },
    {
        name: '365 Detailed Dashboard',
        links: _.values(O365_DETAILED_DASHBOARD_ROUTES).map((route) => route.link)
    },
    {
        name: 'Azure Detailed Dashboard',
        links: _.values(AZURE_DETAILED_DASHBOARD_ROUTES).map((route) => route.link)
    },
    {
        name: 'Questionnaires',
        links: _.values(COMPLIANCE_ROUTES).map((route) => route.link)
    },
    {
        name: 'Manage Questions',
        links: _.concat(
            _.values(QUESTIONNAIRE_ROUTES).map((route) => route.link),
            _.values(QUESTION_ROUTES).map((route) => route.link)
        )
    }
]

import {
    useVaptConfigRemoveMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as CONFIG_MESSAGE,
    TEXT as CONFIG_TEXT
} from '@constants/watchdog/configuration/device-config'
import {
    DeviceConfigModal
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    setRefetch
} from '@slices/watchdog/configuration/device-configuration/vapt/details'

const RemoveConfirmationWindow = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [
        configRemove, configRemoveMutation
    ] = useVaptConfigRemoveMutation()

    /** call formiks */

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData.vapt?.removeConfirm) {
            configRemove({
                authToken: newToken,
                id: modal.formData.vapt.removeConfirm?.id
            })
        }
    }, [
        modal.formData.vapt?.removeConfirm
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (configRemoveMutation.data) {
            const data = configRemoveMutation.data
            if (data.status) {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // close the modal AND reload the data.
                dispatch(closeModal(modal))
                dispatch(setRefetch(true))
            } else {
                toast.error(
                    CONFIG_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [configRemoveMutation.data])

    useEffect(() => {
        if (configRemoveMutation.error) {
            console.error(configRemoveMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [configRemoveMutation.error])

    const SubmitButton = useMemo(() => {
        const buttonContent = configRemoveMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        CONFIG_TEXT.VAPT_REMOVE.FORM.LOADING_BUTTON
                    }</span>
                </SpinnerContainer>
            )
            : CONFIG_TEXT.VAPT_REMOVE.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'danger'}
                onClick={submitData}
                disabled={configRemoveMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [configRemoveMutation.isLoading])

    return (<div>
        <h5>{CONFIG_TEXT.VAPT_REMOVE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {
                [
                    CONFIG_TEXT.VAPT_REMOVE.CONFIRMATION[0],
                    modal.formData.vapt?.removeConfirm?.assetName,
                    CONFIG_TEXT.VAPT_REMOVE.CONFIRMATION[1]
                ].join(' ')
            }
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default RemoveConfirmationWindow

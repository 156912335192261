import { useTokenValidMutation } from '@apis/main/token-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { LOGIN_ROUTES } from '@constants/main/routes'
import { MutationContextInterface } from '@interfaces/main/root'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    resetToken,
    selectToken,
    setToken
} from '@slices/main/token'
import { createStylesheet } from '@styles/themes'
import 'bootstrap/dist/css/bootstrap.css'
import Color from 'color'
import {
    push
} from 'connected-react-router'
import 'rc-pagination/assets/index.css'
import 'rc-switch/assets/index.css'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-responsive-modal/styles.css'
import { defaultTheme as reactSelectTheme } from 'react-select'
import {
    toast
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'tippy.js/dist/border.css'
import 'tippy.js/dist/tippy.css'

import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet/dist/leaflet.css'

import L from 'leaflet'

import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import marker from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import 'react-circular-progressbar/dist/styles.css'

import {
    resetMdrDetailed as resetMdrMainDetailed,
    selectSearchParams as selectMdrMainSearchParams,
    setLocalStorage as setMdrMainLocalStorage
} from '@slices/dashboard/soc/mdr/detailedDashboard/main'

import {
    resetO365Detailed as resetO365MainDetailed,
    selectSearchParams as selectO365MainSearchParams,
    setLocalStorage as setO365MainLocalStorage
} from '@slices/dashboard/soc/o365/detailedDashboard/main'

import {
    resetO365Detailed as resetO365SharepointDetailed,
    selectSearchParams as selectO365SharepointSearchParams,
    setLocalStorage as setO365SharepointLocalStorage
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'

import {
    resetO365Detailed as resetO365ExchangeDetailed,
    selectSearchParams as selectO365ExchangeSearchParams,
    setLocalStorage as setO365ExchangeLocalStorage
} from '@slices/dashboard/soc/o365/detailedDashboard/exchange'

import {
    resetAzureDetailed as resetAzureMainDetailed,
    selectSearchParams as selectAzureMainSearchParams,
    setLocalStorage as setAzureMainLocalStorage
} from '@slices/dashboard/soc/azure/detailedDashboard/main'

import { AzureK8sEventFilter } from '@interfaces/dashboard/soc/azure/detailedDashboard/kubernetes'
import { AzureEventFilter } from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import { MdrEventFilter } from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'
import { ExchangeEventFilter } from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import { Office365EventFilter } from '@interfaces/dashboard/soc/o365/detailedDashboard/main'
import { SharepointEventFilter } from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    resetAzureDetailed as resetAzureKubernetesDetailed,
    selectSearchParams as selectAzureKubernetesSearchParams,
    setLocalStorage as setAzureKubernetesLocalStorage
} from '@slices/dashboard/soc/azure/detailedDashboard/kubernetes'

const DefaultIcon = L.icon({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
})

L.Marker.prototype.options.icon = DefaultIcon

export const MutationContext = React.createContext<MutationContextInterface>({
    revalidateToken: () => new Promise((resolve) => resolve('')),
    setMdrDetailedDashboardParams: (_params) => {},
    setO365DetailedDashboardParams: (_params) => {},
    setAzureDetailedDashboardParams: (_params) => {},
    reactSelect: {
        theme: reactSelectTheme,
        styles: {}
    }
})

const MutationProvider = ({ children } : {
    children: JSX.Element[]
}) => {
    const dispatch = useAppDispatch()

    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    const [tokenValid] = useTokenValidMutation()
    const token = useAppSelector(selectToken)

    const mdrMainSearchParams = useAppSelector(selectMdrMainSearchParams)

    const o365MainSearchParams = useAppSelector(selectO365MainSearchParams)
    const o365SharepointSearchParams = useAppSelector(selectO365SharepointSearchParams)
    const o365ExchangeSearchParams = useAppSelector(selectO365ExchangeSearchParams)

    const azureMainSearchParams = useAppSelector(selectAzureMainSearchParams)
    const azureKubernetesSearchParams = useAppSelector(selectAzureKubernetesSearchParams)

    const mutationContextValues: MutationContextInterface = {
        revalidateToken: async () => {
            let revalidatedToken = ''

            await tokenValid({})
                .unwrap()
                .then((data) => {
                    if (data.code === 'token_valid') {
                        dispatch(setToken({
                            value: data.token,
                            valid: true
                        }))

                        revalidatedToken = data.token
                    } else if (data.code === 'token_expired') {
                    /**
                         * if value string is truthy but valid status is falsy, display
                         * toast message and then redirect to login page.
                         * perform token revalidation here.
                        */

                        // show toast, reset content and redirect.
                        toast.error(
                            data.description, { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                        dispatch(resetToken())
                        dispatch(push(LOGIN_ROUTES.LOGIN.link))
                    }
                })
                // prioritizes the FetchBaseQueryError type
                .catch((error) => {
                    if (error) {
                        console.error(error)
                        // display toast  but i don't know what the default error response is.
                        toast.error(
                            MESSAGE.ERROR.DATA.CALL_FAILED,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                        // reset the token data regardless.
                        dispatch(resetToken())
                    }
                })

            return revalidatedToken
        },
        /** setting detailed dashboard parameters (including all different branches). */
        setMdrDetailedDashboardParams: (obj) => {
            if (
                mdrMainSearchParams.card.deviceid !== obj.card.deviceid
            /**
            * decided not to check service_type (because it's implied that
            * the detailed dashboard's localStorage key name is the "service_type")
            * and the in_face
            */
            ) {
                // will replace the localStorage.
                dispatch(setMdrMainLocalStorage({
                    boolList: obj.boolList as MdrEventFilter[],
                    card: obj.card,
                    q: obj.q,
                    ranges: obj.ranges
                }))

                /** reset the state before setting the preferred parameters. */
                dispatch(resetO365MainDetailed())
            }
        },
        setO365DetailedDashboardParams: (obj) => {
            /** From azure detailed dashboard slices. simplified logic from
             * where you set card search parameters. Update localStorage
             * only if the current state's deviceId from the searchParams
             * property is different from that of which we are passing.
             */

            if (
                o365MainSearchParams.card.deviceid !== obj.card.deviceid
            /**
            * decided not to check service_type (because it's implied that
            * the detailed dashboard's localStorage key name is the "service_type")
            * and the in_face
            */
            ) {
                // will replace the localStorage.
                dispatch(setO365MainLocalStorage({
                    boolList: obj.boolList as Office365EventFilter[],
                    card: obj.card,
                    q: obj.q,
                    ranges: obj.ranges
                }))

                /** reset the state before setting the preferred parameters. */
                dispatch(resetMdrMainDetailed())
            }

            if (
                o365SharepointSearchParams.card.deviceid !== obj.card.deviceid
            /**
            * decided not to check service_type (because it's implied that
            * the detailed dashboard's localStorage key name is the "service_type")
            * and the in_face
            */
            ) {
                // will replace the localStorage.
                dispatch(setO365SharepointLocalStorage({
                    boolList: obj.boolList as SharepointEventFilter[],
                    card: obj.card,
                    q: obj.q,
                    ranges: obj.ranges
                }))

                /** reset the state before setting the preferred parameters. */
                dispatch(resetO365SharepointDetailed())
            }

            if (
                o365ExchangeSearchParams.card.deviceid !== obj.card.deviceid
            /**
            * decided not to check service_type (because it's implied that
            * the detailed dashboard's localStorage key name is the "service_type")
            * and the in_face
            */
            ) {
                // will replace the localStorage.
                dispatch(setO365ExchangeLocalStorage({
                    boolList: obj.boolList as ExchangeEventFilter[],
                    card: obj.card,
                    q: obj.q,
                    ranges: obj.ranges
                }))

                /** reset the state before setting the preferred parameters. */
                dispatch(resetO365ExchangeDetailed())
            }
        },
        setAzureDetailedDashboardParams: (obj) => {
            /** From azure detailed dashboard slices. simplified logic from
             * where you set card search parameters. Update localStorage
             * only if the current state's deviceId from the searchParams
             * property is different from that of which we are passing.
             */

            if (
                azureMainSearchParams.card.deviceid !== obj.card.deviceid
                /**
                * decided not to check service_type (because it's implied that
                * the detailed dashboard's localStorage key name is the "service_type")
                * and the in_face
                */
            ) {
                // will replace the localStorage.
                dispatch(setAzureMainLocalStorage({
                    boolList: obj.boolList as AzureEventFilter[],
                    card: obj.card,
                    q: obj.q,
                    ranges: obj.ranges
                }))

                /** reset the state before setting the preferred parameters. */
                dispatch(resetAzureMainDetailed())
            }

            if (
                azureKubernetesSearchParams.card.deviceid !== obj.card.deviceid
                /**
                * decided not to check service_type (because it's implied that
                * the detailed dashboard's localStorage key name is the "service_type")
                * and the in_face
                */
            ) {
                // will replace the localStorage.
                dispatch(setAzureKubernetesLocalStorage({
                    boolList: obj.boolList as AzureK8sEventFilter[],
                    card: obj.card,
                    q: obj.q,
                    ranges: obj.ranges
                }))

                /** reset the state before setting the preferred parameters. */
                dispatch(resetAzureKubernetesDetailed())
            }
        },
        /**
         * because react-select encourages us to use their styles api to select the properties
         * we are going to create default styles and overwrite the theme.
         * */
        reactSelect: {
            theme: () => {
                const stylesheet = createStylesheet(style, mode)

                const primary = stylesheet.style.buttonTypeColors.primary

                reactSelectTheme.colors = {
                    ...reactSelectTheme.colors,
                    primary: primary,
                    primary75: Color(primary).lighten(0.10).hex(),
                    primary50: Color(primary).lighten(0.25).hex(),
                    primary25: Color(primary).lighten(0.50).hex()
                }

                return reactSelectTheme
            },
            styles: {
                container: (provided, state) => {
                    const removePadding = state.selectProps.removePadding

                    provided.fontSize = '0.8em'
                    /**
                     * width inherited from parent element
                     * can specify a fixed width in a root constants file if wanted.
                     * */
                    provided.width = '100%'

                    if (removePadding === true) {
                        provided.paddingRight = 0
                        provided.paddingLeft = 0
                    }

                    return provided
                },
                control: (provided, state) => {
                    const stylesheet = createStylesheet(style, mode)
                    const buttonType = stylesheet.style.buttonTypeColors

                    const borderOnly = state.selectProps.borderOnly

                    provided[':hover'] = {
                        ...provided[':hover']
                    }
                    provided.backgroundColor = 'transparent'

                    provided.borderColor = state.selectProps.errors
                        ? buttonType.danger
                        : provided.borderColor

                    if (borderOnly) {
                        provided.borderWidth = '0'

                        if (borderOnly === 'bottom') {
                            provided.borderBottomWidth = '1px'
                        }
                    }

                    return provided
                },
                singleValue: (provided) => ({
                    ...provided,
                    color: 'inherit'
                }),
                multiValue: (provided) => {
                    return ({
                        ...provided,
                        backgroundColor: 'transparent',
                        color: 'inherit'
                    })
                },
                multiValueLabel: (provided) => ({
                    ...provided,
                    color: 'inherit'
                }),
                input: (provided) => ({
                    ...provided,
                    color: 'inherit'
                }),
                // indicatorsContainer: (provided) => ({
                //     ...provided,
                //     color: 'inherit'
                // }),
                menu: (provided) => {
                    const stylesheet = createStylesheet(style, mode)
                    const backgroundColors = stylesheet.mode.backgroundColors
                    return {
                        ...provided,
                        color: 'inherit',
                        backgroundColor: backgroundColors?.[0] || 'initial',
                        // zIndex: 2,
                        zIndex: 9999
                    }
                },
                menuList: (provided) => {
                    const stylesheet = createStylesheet(style, mode)
                    const backgroundColors = stylesheet.mode.backgroundColors
                    return {
                        ...provided,
                        color: 'inherit',
                        backgroundColor: backgroundColors?.[0] || 'initial'
                    }
                },
                menuPortal: (base) => ({
                    ...base,
                    fontSize: '0.8em',
                    zIndex: 9999
                })
            }
        }
    }

    /**
     * signature of mutation are as follows:
     * trigger contains, abort, unwrap and unsubscribe.
     * abort is for terminating the active call, unwrapping executes the function
     * and provides a response. unsubscribe can be manually done here which
     * means removing the cache data in the store.
     */

    /**
     * a script to revalidate token information using a call if the condition is met
     * if false, will always be redirected to the login page. Make sure all data is reset.
     *
     * there's two cases that will be met by the end of its invocation.
     * valid, invalid and logged out.
     *
     * valid - token is valid and continues and codebase.
     * invalid - token is invalid and redirects to login page.
     *
     * if the user renders a logout component, token data is reset
     * but doesn't require any revalidation.
     *  */

    /**
     *  unfortunately, you can't execute async/await action in reducers since creating slices
     *  is meant for handling state logic alone. any api calls associated with the slice
     *  should be done outside preferrably on components where rendering takes place.
     */

    useEffect(() => {
        /** the new way of executing a call or more.
         * even if no arguments are provided, an empty object should be passed.
         * also, we only need to make this instance once to avoid duplicate code.
         */
        /** attempting to recycle token revalidation snippet using store. */
        // if value string is empty, immediately redirect to login page.
        if (!token.value) dispatch(push(LOGIN_ROUTES.LOGIN.link))
        else {
            mutationContextValues.revalidateToken()
        }
    }, [])

    return <MutationContext.Provider value={mutationContextValues} >
        {children}
    </MutationContext.Provider>
}

export default MutationProvider

import { MDR_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import MDRDetailedDashboard from '@features/dashboard/soc/mdr/detailed-dashboard/MDRMain'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: MDR_DETAILED_DASHBOARD_ROUTES.MAIN.link,
        component: MDRDetailedDashboard
    }
]

export default Routes

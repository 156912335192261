import {
    DashboardData,
    ExchangeEventColumn,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    O365DetailedSearchParams,
    ExchangeUserIdColumn,
    SubjectColumn
} from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import {
    Doc
} from '@interfaces/dashboard/monitor'
import { CHART_ZOOM } from '@constants/dashboard/monitor'
import _ from 'lodash'
import {
    getUnixTime,
    sub
} from 'date-fns'

export const EVENT_TYPES: EventType[] = ['Attachments.Doughnut', 'ClientInfo.Activity',
    'Exchange.Events', 'ExchangeClientIP.Doughnut', 'ExchangeDetails.Activity',
    'ExchangeDetails.Rules', 'ExchangeUserActivity.Locations', 'ExchangeUserId.CountedTable',
    'From.Activity', 'Subject.CountedTable', 'To.Activity']

/** was _.Dictionary<EventTypeBooleans> but used type assertion. */
export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

export const DEFAULT_CHART_ZOOMS = _.zipObject(
    EVENT_TYPES,
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_SEARCH_PARAMS: O365DetailedSearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    /** note that the refetches are now from separate event types.
     * so if you change the parameters from date ranges and search queries,
     * then everything refreshes,
    */
    refetch: _.cloneDeep(DEFAULT_REFETCH),
    q: '',

    /** for displaying filters. */
    boolList: [],
    /** scripts won't be executed if deviceid IS an empty string, */
    card: {
        details: {},
        deviceid: '',
        in_face: '',
        service_type: 'bdg-bas'
    }
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'O365 Detailed call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        ATTACHMENT_DOUGHNUT: 'Fetching Attachment Doughnut data...',
        EXCHANGE_CLIENT_IP_DOUGHNUT: 'Fetching Client IP data...',
        EXCHANGE_DETAILS_ACTIVITY: 'Fetching Details Activity data...',
        EXCHANGE_USER_ACTIVITY_LOCATIONS: 'Fetching User Actvity Locations data...',
        FROM_ACTIVITY: 'Fetching From Activity data...',
        TO_ACTIVITY: 'Fetching To Activity data...',
        CLIENT_INFO_ACTIVITY: 'Fetching Client Info Activity data...',
        EXCHANGE_DETAILS_RULES: 'Fetching Details Rules data...',
        SUBJECT_COUNTED_TABLE: 'Fetching Subject data...',
        EXCHANGE_USER_ID_COUNTED_TABLE: 'Fetching User ID data...',
        EXCHANGE_EVENTS: 'Fetching Exchange Events data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        ATTACHMENT_DOUGHNUT: 'Attachments',
        EXCHANGE_CLIENT_IP_DOUGHNUT: 'Client IP Address',
        EXCHANGE_DETAILS_ACTIVITY: 'Exchange',
        EXCHANGE_USER_ACTIVITY_LOCATIONS: 'User Activity Locations',
        FROM_ACTIVITY: 'From',
        TO_ACTIVITY: 'To',
        CLIENT_INFO_ACTIVITY: 'Client Info',
        EXCHANGE_DETAILS_RULES: 'Rules',
        SUBJECT_COUNTED_TABLE: 'Filenames',
        EXCHANGE_USER_ID_COUNTED_TABLE: 'User IDs',
        EXCHANGE_EVENTS: 'All Office365 Events This Period'
    }
}

export const ATTACHMENT_DOUGHNUT_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Attachments',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const EXCHANGE_CLIENT_IP_DOUGHNUT_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'IP',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const EXCHANGE_DETAILS_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Operation',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const FROM_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'From',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const TO_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'To',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const CLIENT_INFO_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'User Agent',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const EXCHANGE_DETAILS_RULES_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Rule',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const SUBJECT_COUNTED_TABLE_COLUMNS: SubjectColumn[] = (() => {
    const columnNames: Omit<SubjectColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Subject',
            value: 'key'
        },
        {
            label: 'Count',
            value: 'doc_count'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: SubjectColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const EXCHANGE_USER_ID_COLUMNS: ExchangeUserIdColumn[] = (() => {
    const columnNames: Omit<ExchangeUserIdColumn, 'arrange' | 'include'>[] = [
        {
            label: 'User',
            value: 'key'
        },
        {
            label: 'Count',
            value: 'doc_count'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: ExchangeUserIdColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const EXCHANGE_EVENTS_COLUMNS: ExchangeEventColumn[] = (() => {
    const columnNames: Omit<ExchangeEventColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'User',
            value: 'UserId'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        },
        {
            label: 'Operation',
            value: 'Operation'
        },
        {
            label: 'Workload',
            value: 'Workload'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: ExchangeEventColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const DASHBOARD_DATA: DashboardData = {
    'Attachments.Doughnut': undefined,
    'ClientInfo.Activity': undefined,
    'Exchange.Events': undefined,
    'ExchangeClientIP.Doughnut': undefined,
    'ExchangeDetails.Activity': undefined,
    'ExchangeDetails.Rules': undefined,
    'ExchangeUserActivity.Locations': undefined,
    'ExchangeUserId.CountedTable': undefined,
    'From.Activity': undefined,
    'Subject.CountedTable': undefined,
    'To.Activity': undefined
}

export const PRINT_REPORT_TITLE = 'Microsoft 365 Detailed Dashboard'

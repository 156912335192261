
import {
    useGetComplianceTypesMutation,
    useGetComplianceArticlesMutation,
    useViewQuestionnaireMutation
} from '@apis/watchdog/virtual-ciso/questionnaire-information-api'
import {
    useGetQuestionsMutation,
    useGetQTypesMutation
} from '@apis/watchdog/virtual-ciso/compliance-questionnaire-api'
import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    VALIDATION_SCHEMA,
    TEXT as QUESTIONNAIRE_TEXT
} from '@constants/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    INITIAL_VALUES as QUESTION_INITIAL_VALUES,
    VALIDATION_SCHEMA as QUESTION_VALIDATION_SCHEMA,
    TEXT as QUESTION_TEXT,
    COLUMNS
} from '@constants/watchdog/virtual-ciso/questionnaire-information/question'
import AddConfirmation from
    '@features/watchdog/virtual-ciso/questionnaire-information/question/add/AddConfirmation'
import { LabelValuePair } from '@interfaces/main/root'
import {
    AddQuestionKeys,
    AddQuestionValues,
    QuestionFormData,
    QuestionParams
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/question'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    selectModals,
    addModal,
    closeModal,
    removeModal
} from '@slices/watchdog/virtual-ciso/questionnaire-information/question'
import {
    Button,
    FormStyledComponents as Form,
    Table,
    Text
} from '@styles/components'
import {
    useFormik,
    FormikErrors
} from 'formik'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import {
    Answer,
    Question
} from '@interfaces/watchdog/virtual-ciso/compliance-questionnaire'

const QuestionAdd = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect
    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    /** useState to refetch all data. */
    const [refetch, setRefetch] = useState<boolean>(false)

    const { questionnaireID } = useParams<QuestionParams>()

    /** fetch other calls. */
    const [viewQuestionnaire, viewQuestionnaireMutation] = useViewQuestionnaireMutation()
    const [getQuestions, getQuestionsMutation] = useGetQuestionsMutation()
    const [getComplianceTypes, getComplianceTypesMutation] = useGetComplianceTypesMutation()
    const [
        getComplianceArticles,
        getComplianceArticlesMutation
    ] = useGetComplianceArticlesMutation()
    const [getQTypes, getQTypesMutation] = useGetQTypesMutation()

    const questionnaireFormik = useFormik({
        initialValues: INITIAL_VALUES.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.UPDATE,
        onSubmit: () => {
        }
    })

    const questionFormik = useFormik({
        initialValues: QUESTION_INITIAL_VALUES.ADD,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: QUESTION_VALIDATION_SCHEMA.ADD,
        onSubmit: () => {
            if (questionnaireID) {
                const formData: QuestionFormData = {
                    addConfirm: {
                        formValues: questionFormik.values,
                        questionnaire_id: questionnaireID
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'QUESTION_EDIT',
                    formData: formData,
                    isBorderWide: false
                }))
            }
        }
    })

    const strictSetValue = useCallback((
        label: AddQuestionKeys,
        value: AddQuestionValues[AddQuestionKeys]
    ) => {
        questionnaireFormik.setFieldValue(
            label,
            value
        )
    }, [])

    /** needed to show questionnaire data first before question contents. */

    // 5d9731e27f689b7e5bcae003 is questionnaire id edit just to check.
    const QuestionnaireActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.ACTIVE.ID}
                    checked={questionnaireFormik.values.active}
                    disabled={true}
                />
                <Form.Feedback
                    errors={Boolean(questionnaireFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        questionnaireFormik.errors.active ? questionnaireFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [questionnaireFormik.values.active, questionnaireFormik.errors.active])

    const NameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(questionnaireFormik.errors.name)}
                    name={'name'}
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.NAME.ID}
                    value={questionnaireFormik.values.name}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(questionnaireFormik.errors.name)} >{
                    questionnaireFormik.errors.name ? questionnaireFormik.errors.name : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [questionnaireFormik.values.name, questionnaireFormik.errors.name])

    const ComplianceTypeInput = useMemo(() => {
        let arr = [] as LabelValuePair[]

        if (getComplianceTypesMutation.data) {
            arr = getComplianceTypesMutation.data.cTypes
        }

        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.ID}>
                    {QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.LABEL}
                </Form.Label>
                <Select
                    id={QUESTIONNAIRE_TEXT.UPDATE.FORM.COMPLIANCE_TYPE.ID}
                    errors={Boolean(questionnaireFormik.errors.complianceLines)}
                    options={arr}
                    value={_.find(
                        arr,
                        (e) => e.value === questionnaireFormik.values.complianceLines
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
                <Form.Feedback errors={Boolean(questionnaireFormik.errors.complianceLines)} >{
                    questionnaireFormik.errors.complianceLines
                        ? questionnaireFormik.errors.complianceLines
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getComplianceTypesMutation.data,
        questionnaireFormik.values.complianceLines,
        questionnaireFormik.errors.complianceLines
    ])

    /** Now display question inputs. */

    const TitleInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTION_TEXT.ADD.FORM.TITLE.ID}>
                    {QUESTION_TEXT.ADD.FORM.TITLE.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(questionFormik.errors.title)}
                    name={'title'}
                    id={QUESTION_TEXT.ADD.FORM.TITLE.ID}
                    onChange={questionnaireFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('title', e.target.value.trim())
                    }}
                    value={questionFormik.values.title}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(questionFormik.errors.title)} >{
                    questionFormik.errors.title ? questionFormik.errors.title : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [questionFormik.values.title, questionFormik.errors.title])

    const DescriptionInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0'}
                >
                    {QUESTION_TEXT.ADD.FORM.DESCRIPTION.LABEL}
                </Form.Label>
                <Form.TextArea
                    errors={Boolean(questionFormik.errors.description)}
                    className={'col'}
                    name={'description'}
                    id={QUESTION_TEXT.ADD.FORM.DESCRIPTION.ID}
                    onChange={questionFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('description', e.target.value.trim())
                    }}
                    value={questionFormik.values.description}
                />
            </Form.Group>
        )
    }, [questionFormik.values.description, questionFormik.errors.description])

    const QuestionInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0'}
                >
                    {QUESTION_TEXT.ADD.FORM.QUESTION.LABEL}
                </Form.Label>
                <Form.TextArea
                    errors={Boolean(questionFormik.errors.question)}
                    className={'col'}
                    name={'question'}
                    id={QUESTION_TEXT.ADD.FORM.QUESTION.ID}
                    onChange={questionFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('question', e.target.value.trim())
                    }}
                    value={questionFormik.values.question}
                />
            </Form.Group>
        )
    }, [questionFormik.values.question, questionFormik.errors.question])

    const QTypeInput = useMemo(() => {
        let arr = [] as LabelValuePair[]

        if (getQTypesMutation.data) {
            arr = getQTypesMutation.data.qTypes
        }

        const fieldValue: AddQuestionKeys = 'type_id'

        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTION_TEXT.ADD.FORM.QTYPE.ID}>
                    {QUESTION_TEXT.ADD.FORM.QTYPE.LABEL}
                </Form.Label>
                <Select
                    id={QUESTION_TEXT.ADD.FORM.QTYPE.ID}
                    errors={Boolean(questionFormik.errors.type_id)}
                    options={arr}
                    value={_.find(
                        arr,
                        (e) => e.value === questionFormik.values.type_id
                    )}
                    onChange={(e) => {
                        if (e) {
                            questionFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(questionFormik.errors.type_id)} >{
                    questionFormik.errors.type_id
                        ? questionFormik.errors.type_id
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getQTypesMutation.data,
        questionFormik.values.type_id,
        questionFormik.errors.type_id
    ])

    const QuestionActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={QUESTION_TEXT.ADD.FORM.ACTIVE.ID}>
                    {QUESTION_TEXT.ADD.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={QUESTION_TEXT.ADD.FORM.ACTIVE.ID}
                    onChange={questionFormik.handleChange}
                    checked={questionFormik.values.active}
                />
                <Form.Feedback
                    errors={Boolean(questionFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        questionFormik.errors.active ? questionFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [questionFormik.values.active, questionFormik.errors.active])

    const ComplianceArticleInput = useMemo(() => {
        let arr = [] as LabelValuePair[]

        if (getComplianceArticlesMutation.data) {
            arr = getComplianceArticlesMutation.data.cArticles
        }

        const fieldValue: AddQuestionKeys = 'complianceLines'

        return (
            <Form.Group>
                <Form.Label htmlFor={QUESTION_TEXT.ADD.FORM.COMPLIANCE_ARTICLE.ID}>
                    {QUESTION_TEXT.ADD.FORM.COMPLIANCE_ARTICLE.LABEL}
                </Form.Label>
                <Select
                    isMulti
                    id={QUESTION_TEXT.ADD.FORM.COMPLIANCE_ARTICLE.ID}
                    errors={Boolean(questionFormik.errors.complianceLines)}
                    options={arr}
                    value={_.filter(
                        arr,
                        (e) => _.includes(
                            _.map(questionFormik.values.complianceLines, obj => obj.value),
                            e.value
                        )
                    )}
                    onChange={(e) => {
                        if (e) {
                            questionFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(questionFormik.errors.complianceLines)} >{
                    questionFormik.errors.complianceLines
                        ? questionFormik.errors.complianceLines
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getComplianceArticlesMutation.data,
        questionFormik.values.complianceLines,
        questionFormik.errors.complianceLines
    ])

    /** answer lines. */

    const AnswerListTable = useMemo(() => {
        /** create interface no longer just a text field */

        let questions = [] as Question[]

        const fieldValue: AddQuestionKeys = 'answerLines'

        if (getQuestionsMutation.data) {
            questions = getQuestionsMutation.data.questions
        }

        const thead = (
            <thead>
                <tr>
                    <th>{TEXT.SEQUENCE}</th>
                    {
                        _.map(COLUMNS.ANSWER_LINES, (column, index) => {
                            return (
                                <th key={'answer-th-' + index}>
                                    <Text size={'xs'}>
                                        {column.label}
                                    </Text>
                                </th>
                            )
                        })
                    }
                    {/* for remove button */}
                    <th></th>
                </tr>
            </thead>
        )

        const renderInput = (
            key: keyof Answer,
            value: Answer[keyof Answer],
            answerLineIndex:number
        ) => {
            const selectOnChange = (e: any) => {
                if (e) {
                    questionFormik.setFieldValue(
                        [fieldValue,
                            '[',
                            answerLineIndex.toString(),
                            '].',
                            key].join(''),
                        e.value
                    )
                }
            }

            const errors = (questionFormik.errors.answerLines ||
                []) as FormikErrors<Answer>[]

            switch (key) {
                case 'nextq': return (
                    <Select
                        errors={Boolean(
                            errors[answerLineIndex]?.nextq
                        )}
                        options={_.map(questions, e => {
                            return {
                                label: e.question,
                                value: e.id
                            }
                        })}
                        value={_.find(
                            questions,
                            (e) => e.id === value
                        )}
                        onChange={selectOnChange}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                    />
                )

                default: return (
                    <input
                        type={'text'}
                        name={[
                            'answerLines[',
                            answerLineIndex.toString(),
                            '].', key
                        ].join('')}
                        onChange={questionFormik.handleChange}
                        onBlur={(e) => {
                            questionFormik.setFieldValue(
                                [
                                    'answerLines[',
                                    answerLineIndex.toString(),
                                    '].', key
                                ].join(''),
                                e.target.value.trim()
                            )
                        }}
                        value={value}
                    />
                )
            }
        }

        const renderRow = (
            answerLine: Answer,
            answerLineIndex: number
        ) => {
            return <tr key={'answerLine-row-' + answerLineIndex}>
                <td>{answerLineIndex}</td>
                {
                    _.map(COLUMNS.ANSWER_LINES, (column, columnIndex) => {
                        return (
                            <td key={'answerLine-' + answerLineIndex + 'input-' + columnIndex}>
                                {
                                    renderInput(
                                        column.value,
                                        answerLine[column.value],
                                        answerLineIndex)
                                }
                            </td>
                        )
                    })
                }
                <td>
                    <Button
                        onClick={() => {
                            const fieldValue: AddQuestionKeys = 'answerLines'
                            const arr = [...questionFormik.values.answerLines]
                            arr.splice(answerLineIndex, 1)
                            questionFormik.setFieldValue(fieldValue, arr)
                        }}
                        size={'sm'}
                        mode={'danger'}>
                        {QUESTION_TEXT.ADD.FORM.REMOVE_ANSWER_LINE}
                    </Button>
                </td>
            </tr>
        }

        const tbody = (
            <tbody>
                {
                    _.map(questionFormik.values.answerLines, (answerLine, answerLineIndex) => {
                        return renderRow(answerLine, answerLineIndex)
                    })
                }
            </tbody>
        )

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    {thead}
                    {tbody}
                </table>
            </Table>
        )
    }, [
        questionFormik.values.answerLines,
        getQuestionsMutation.data
    ])

    const AddAnswerLineButton = useMemo(() => {
        const fieldValue: AddQuestionKeys = 'answerLines'
        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    onClick={() => {
                        const answerLines = _.cloneDeep(questionFormik.values.answerLines)

                        const defaultObj: Answer = {
                            name: '',
                            value: '',
                            nextq: ''
                        }

                        answerLines.push(defaultObj)

                        questionFormik.setFieldValue(fieldValue, answerLines)
                    }}
                    mode={'primary'}
                    type={'button'}
                >{QUESTION_TEXT.ADD.FORM.ADD_ANSWER_LINE}</Form.Button>
            </Form.Group>
        )
    }, [
        questionFormik.values.answerLines
    ])

    /** Button to refresh the question data. */
    const RefreshButton = useMemo(() => {
        return (
            <Form.Group className={'row'}>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'primary'}
                        onClick={() => {
                            setRefetch(true)
                        }}
                    >{QUESTION_TEXT.ADD.FORM.REFRESH_BUTTON }</Button>
                </div>
            </Form.Group>
        )
    }, [])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                    >{QUESTION_TEXT.ADD.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={() => {
                            questionFormik.resetForm()
                        }}
                    >{TEXT.FORM.RESET}</Button>
                </div>
            </Form.Group>
        )
    }, undefined)

    const setDefaultQuestionnaireFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = viewQuestionnaireMutation.data

        if (data) {
            questionnaireFormik.setValues({
                active: data.active,
                name: data.name,
                complianceLines: data.compliance[0].type_id
            })
        }
    }, [
        viewQuestionnaireMutation.data
    ])

    useEffect(() => {
        setDefaultQuestionnaireFormData()
    }, [
        viewQuestionnaireMutation.data
    ])

    const unsubscribeViewQuestionnaire = () => {
        const unsubscribeMutation = viewQuestionnaire({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const unsubscribeGetQuestions = () => {
        const unsubscribeMutation = getQuestions({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetComplianceTypes = () => {
        const unsubscribeMutation = getComplianceTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetComplianceArticles = () => {
        const unsubscribeMutation = getComplianceArticles({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetQTypes = () => {
        const unsubscribeMutation = getQTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeViewQuestionnaire()
        unsubscribeGetQuestions()
        unsubscribeGetComplianceTypes()
        unsubscribeGetQTypes()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let viewQuestionnairePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getQuestionsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getComplianceTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getQTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && questionnaireID) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    viewQuestionnairePromise = viewQuestionnaire({
                        authToken: newToken,
                        id: questionnaireID
                    })

                    getQuestionsPromise = getQuestions({
                        authToken: newToken,
                        questionnaire_id: questionnaireID
                    })

                    getComplianceTypesPromise = getComplianceTypes({
                        authToken: newToken
                    })

                    getQTypesPromise = getQTypes({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            viewQuestionnairePromise && viewQuestionnairePromise.abort()
            getQuestionsPromise && getQuestionsPromise.abort()
            getComplianceTypesPromise && getComplianceTypesPromise.abort()
            getQTypesPromise && getQTypesPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [
        token.valid
    ])

    /** separate useEffect for refetch */
    useEffect(() => {
        if (refetch) {
            return fetchData()
        }
    }, [refetch])

    useEffect(() => {
        unsubscribeGetComplianceArticles()

        let getComplianceArticlesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const data = viewQuestionnaireMutation.data

        const call = async () => {
            if (token.valid && data?.compliance[0]?.type_id) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getComplianceArticlesPromise = getComplianceArticles({
                        authToken: newToken,
                        id: data.compliance[0].type_id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getComplianceArticlesPromise && getComplianceArticlesPromise.abort()
        }
    }, [
        token.valid, viewQuestionnaireMutation.data
    ])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (viewQuestionnaireMutation.error) {
            console.error(viewQuestionnaireMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [viewQuestionnaireMutation.error])

    useEffect(() => {
        if (getQuestionsMutation.error) {
            console.error(getQuestionsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getQuestionsMutation.error])

    useEffect(() => {
        if (getComplianceTypesMutation.error) {
            console.error(getComplianceTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getComplianceTypesMutation.error])

    useEffect(() => {
        if (getComplianceArticlesMutation.error) {
            console.error(getComplianceArticlesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getComplianceArticlesMutation.error])

    useEffect(() => {
        if (getQTypesMutation.error) {
            console.error(getQTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getQTypesMutation.error])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'QUESTION_ADD') {
                    component = <AddConfirmation
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={questionFormik.handleSubmit}>
                        {RefreshButton}
                        {NameInput}
                        {ComplianceTypeInput}
                        {QuestionnaireActiveInput}
                        {TitleInput}
                        {DescriptionInput}
                        {QuestionInput}
                        {QTypeInput}
                        {QuestionActiveInput}
                        {ComplianceArticleInput}
                        {AddAnswerLineButton}
                        {AnswerListTable}
                        {DetailsFormButtons}
                    </Form.Main>
                </div>
            </div>
            {renderModals}
        </div>
    )
}
export default QuestionAdd


import { useGetCurrencyTypesListMutation } from '@apis/watchdog/account-data/order-api'
import {
    useGetPartnerDetailsMutation,
    useGetPartnersListMutation,
    useGetPartnerStateListMutation,
    useGetPartnerTypesListMutation
} from '@apis/watchdog/account-data/partner-api'
import {
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    VALIDATION_SCHEMA,
    TEXT as PARTNER_TEXT
} from '@constants/watchdog/account-data/partner'
import MenuLinks from '@features/main/MenuLinks'
import { CurrencyType } from '@interfaces/watchdog/account-data/order'
import {
    PartnerList,
    PartnerParams,
    PartnerStateList,
    PartnerTypeList
} from '@interfaces/watchdog/account-data/partner'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    selectTabs
} from '@slices/watchdog/account-data/partner'
import {
    FormStyledComponents as Form
} from '@styles/components'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'

const PartnerUpdate = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)

    const { partnerID } = useParams<PartnerParams>()

    /** fetch other calls. */
    const [getPartnerStateList, getPartnerStateListMutation] = useGetPartnerStateListMutation()
    const [getPartnerTypesList, getPartnerTypesListMutation] = useGetPartnerTypesListMutation()
    const [getPartnersList, getPartnersListMutation] = useGetPartnersListMutation()
    const [getPartnerDetails, getPartnerDetailsMutation] = useGetPartnerDetailsMutation()
    const [getCurrencyTypesList, getCurrencyTypesListMutation] = useGetCurrencyTypesListMutation()

    const partnerFormik = useFormik({
        initialValues: INITIAL_VALUES.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.UPDATE,
        onSubmit: () => {
        }
    })

    // 5d9731e27f689b7e5bcae003 is partner id edit just to check.
    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={PARTNER_TEXT.UPDATE.FORM.ACTIVE.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={PARTNER_TEXT.UPDATE.FORM.ACTIVE.ID}
                    checked={partnerFormik.values.active}
                    disabled={true}
                />
                <Form.Feedback
                    errors={Boolean(partnerFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        partnerFormik.errors.active ? partnerFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.active, partnerFormik.errors.active])

    const CompanyNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.COMPANY_NAME.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.COMPANY_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.companyName)}
                    name={'companyName'}
                    id={PARTNER_TEXT.UPDATE.FORM.COMPANY_NAME.ID}
                    value={partnerFormik.values.companyName}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.companyName)} >{
                    partnerFormik.errors.companyName ? partnerFormik.errors.companyName : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.companyName, partnerFormik.errors.companyName])

    const PartnerStateInput = useMemo(() => {
        let partnerStateTypes = [] as PartnerStateList[]

        if (getPartnerStateListMutation.data) {
            partnerStateTypes = getPartnerStateListMutation.data.partnerStateTypes
        }

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.PARTNER_STATE.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.PARTNER_STATE.LABEL}
                </Form.Label>
                <Select
                    id={PARTNER_TEXT.UPDATE.FORM.PARTNER_STATE.ID}
                    errors={Boolean(partnerFormik.errors.partnerState)}
                    options={partnerStateTypes}
                    value={_.find(
                        partnerStateTypes,
                        (e) => e.value === partnerFormik.values.partnerState
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerState)} >{
                    partnerFormik.errors.partnerState ? partnerFormik.errors.partnerState : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnerStateListMutation.data,
        partnerFormik.values.partnerState,
        partnerFormik.errors.partnerState
    ])

    const PartnerNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.PARTNER_NAME.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.PARTNER_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.partnerName)}
                    name={'partnerName'}
                    id={PARTNER_TEXT.UPDATE.FORM.PARTNER_NAME.ID}
                    value={partnerFormik.values.partnerName}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerName)} >{
                    partnerFormik.errors.partnerName ? partnerFormik.errors.partnerName : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.partnerName, partnerFormik.errors.partnerName])

    const EmailInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.EMAIL.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.EMAIL.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.email)}
                    name={'email'}
                    type={'email'}
                    id={PARTNER_TEXT.UPDATE.FORM.EMAIL.ID}
                    value={partnerFormik.values.email}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.email)} >{
                    partnerFormik.errors.email ? partnerFormik.errors.email : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.email, partnerFormik.errors.email])

    const StreetNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.STREET_NAME.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.STREET_NAME.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.streetName)}
                    name={'streetName'}
                    id={PARTNER_TEXT.UPDATE.FORM.STREET_NAME.ID}
                    value={partnerFormik.values.streetName}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.streetName)} >{
                    partnerFormik.errors.streetName ? partnerFormik.errors.streetName : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.streetName, partnerFormik.errors.streetName])

    const PostalCodeInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.POSTAL_CODE.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.POSTAL_CODE.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.postalCode)}
                    name={'postalCode'}
                    id={PARTNER_TEXT.UPDATE.FORM.POSTAL_CODE.ID}
                    value={partnerFormik.values.postalCode}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.postalCode)} >{
                    partnerFormik.errors.postalCode ? partnerFormik.errors.postalCode : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.postalCode, partnerFormik.errors.postalCode])

    const CityInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.CITY.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.CITY.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.city)}
                    name={'city'}
                    id={PARTNER_TEXT.UPDATE.FORM.CITY.ID}
                    value={partnerFormik.values.city}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.city)} >{
                    partnerFormik.errors.city ? partnerFormik.errors.city : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.city, partnerFormik.errors.city])

    const CountryInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.COUNTRY.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.COUNTRY.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.country)}
                    name={'country'}
                    id={PARTNER_TEXT.UPDATE.FORM.COUNTRY.ID}
                    value={partnerFormik.values.country}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.country)} >{
                    partnerFormik.errors.country ? partnerFormik.errors.country : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.country, partnerFormik.errors.country])

    // const DiscountInput = useMemo(() => {
    //     return (
    //         <Form.Group>
    //             <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.DISCOUNT.ID}>
    //                 {PARTNER_TEXT.UPDATE.FORM.DISCOUNT.LABEL}

    //             </Form.Label>
    //             <Form.Input
    //                 errors={Boolean(partnerFormik.errors.discount)}
    //                 name={'discount'}
    //                 id={PARTNER_TEXT.UPDATE.FORM.DISCOUNT.ID}
    //                 value={partnerFormik.values.discount}
    //                 readOnly={true}
    //             />
    //             <Form.Feedback errors={Boolean(partnerFormik.errors.discount)} >{
    //                 partnerFormik.errors.discount ? partnerFormik.errors.discount : null
    //             }</Form.Feedback>
    //         </Form.Group>
    //     )
    // }, [partnerFormik.values.discount, partnerFormik.errors.discount])

    const PartnerTypeInput = useMemo(() => {
        let partnerTypes = [] as PartnerTypeList[]

        if (getPartnerTypesListMutation.data) {
            partnerTypes = getPartnerTypesListMutation.data.partnerTypes
        }

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.PARTNER_TYPE.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.PARTNER_TYPE.LABEL}
                </Form.Label>
                <Select
                    id={PARTNER_TEXT.UPDATE.FORM.PARTNER_TYPE.ID}
                    errors={Boolean(partnerFormik.errors.partnerType)}
                    options={partnerTypes}
                    value={_.find(
                        partnerTypes,
                        (e) => e.value === partnerFormik.values.partnerType
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerType)} >{
                    partnerFormik.errors.partnerType ? partnerFormik.errors.partnerType : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnerTypesListMutation.data,
        partnerFormik.values.partnerType,
        partnerFormik.errors.partnerType
    ])

    const PartnerOfInput = useMemo(() => {
        let partners = [] as PartnerList[]

        if (getPartnersListMutation.data) {
            partners = getPartnersListMutation.data.partnerData
        }

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.PARTNER_OF.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.PARTNER_OF.LABEL}

                </Form.Label>
                <Select
                    id={PARTNER_TEXT.UPDATE.FORM.PARTNER_OF.ID}
                    errors={Boolean(partnerFormik.errors.partnerOf)}
                    options={partners}
                    value={_.find(
                        partners,
                        (e) => e.value === partnerFormik.values.partnerOf
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerOf)} >{
                    partnerFormik.errors.partnerOf ? partnerFormik.errors.partnerOf : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnersListMutation.data,
        partnerFormik.values.partnerOf,
        partnerFormik.errors.partnerOf
    ])

    const setDefaultFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = getPartnerDetailsMutation.data
        const partnerStateTypes = getPartnerStateListMutation.data?.partnerStateTypes || []
        const partnerTypes = getPartnerTypesListMutation.data?.partnerTypes || []
        const partnerData = getPartnersListMutation.data?.partnerData || []

        if (data) {
            const partnerState = _.find(partnerStateTypes, (partnerStateType) => {
                return partnerStateType.label === data.partnerDetails.partner_state
            })

            const partnerType = _.find(partnerTypes, (partnerType) => {
                return partnerType.label === data.partnerDetails.partner_type
            })

            // idk why it was partner.label before. was partnerDetails.partner_of a string name.
            const partnerOf = _.find(partnerData, (partner) => {
                return partner.value === data.partnerDetails.partner_of
            })

            partnerFormik.setValues({
                active: data.partnerDetails.active,
                city: data.partnerDetails.address.city,
                companyName: data.partnerDetails.company_name,
                country: data.partnerDetails.address.country,
                discount: data.partnerDetails.discount.toString(),
                email: data.partnerDetails.email,
                partnerName: data.partnerDetails.name,
                partnerOf: partnerOf?.value || '',
                partnerState: partnerState?.value || '',
                partnerType: partnerType?.value || '',
                postalCode: data.partnerDetails.address.postalcode,
                streetName: data.partnerDetails.address.street,
                currencies: data.partnerDetails.partnerCurrency
            })
        }
    }, [
        getPartnerDetailsMutation.data,
        getPartnerStateListMutation.data,
        getPartnerTypesListMutation.data,
        getPartnersListMutation.data
    ])

    const CurrenciesSelect = useMemo(() => {
        let currencyTypes = [] as CurrencyType[]

        if (getCurrencyTypesListMutation.data) {
            currencyTypes = getCurrencyTypesListMutation.data.currencyTypes
        }

        const isDisabled = partnerFormik.values.partnerType === '2' &&
        partnerFormik.values.partnerOf.length > 0

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.UPDATE.FORM.CURRENCIES.ID}>
                    {PARTNER_TEXT.UPDATE.FORM.CURRENCIES.LABEL}

                </Form.Label>
                <Select
                    // isMulti

                    id={PARTNER_TEXT.UPDATE.FORM.CURRENCIES.ID}

                    errors={Boolean(partnerFormik.errors.currencies)}
                    options={!isDisabled ? currencyTypes : []}
                    value={partnerFormik.values.currencies}

                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={isDisabled}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.currencies)} >{
                    partnerFormik.errors.currencies ? partnerFormik.errors.currencies : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getCurrencyTypesListMutation.data,
        partnerFormik.values.currencies,
        partnerFormik.errors.currencies,
        partnerFormik.values.partnerType,
        partnerFormik.values.partnerOf
    ])

    useEffect(() => {
        setDefaultFormData()
    }, [
        getPartnerDetailsMutation.data,
        getPartnerStateListMutation.data,
        getPartnerTypesListMutation.data,
        getPartnersListMutation.data
    ])

    const unsubscribeGetCurrencyTypes = () => {
        const unsubscribeMutation = getCurrencyTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnerStateList = () => {
        const unsubscribeMutation = getPartnerStateList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnerTypesList = () => {
        const unsubscribeMutation = getPartnerTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnersList = () => {
        const unsubscribeMutation = getPartnersList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnersDetails = () => {
        const unsubscribeMutation = getPartnerDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetCurrencyTypes()
        unsubscribeGetPartnerStateList()
        unsubscribeGetPartnerTypesList()
        unsubscribeGetPartnersList()
        unsubscribeGetPartnersDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getCurrencyTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnerStatePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnerTypesListPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnersListPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnerDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && partnerID) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getCurrencyTypesPromise = getCurrencyTypesList({
                        authToken: newToken
                    })
                    getPartnerStatePromise = getPartnerStateList({
                        authToken: newToken
                    })
                    getPartnerTypesListPromise = getPartnerTypesList({
                        authToken: newToken
                    })
                    getPartnersListPromise = getPartnersList({
                        authToken: newToken,
                        partnerType: [
                            /** from BDGDB-269 where we filter out the
                             * partners should only be distributors  */
                            // { type: '2' },
                            { type: '3' }
                        ]
                    })

                    getPartnerDetailsPromise = getPartnerDetails({
                        authToken: newToken,
                        partnerID: partnerID
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCurrencyTypesPromise && getCurrencyTypesPromise.abort()
            getPartnerStatePromise && getPartnerStatePromise.abort()
            getPartnerTypesListPromise && getPartnerTypesListPromise.abort()
            getPartnersListPromise && getPartnersListPromise.abort()
            getPartnerDetailsPromise && getPartnerDetailsPromise.abort()
        }
    }, [token.valid, partnerID])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (getCurrencyTypesListMutation.error) {
            console.error(getCurrencyTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCurrencyTypesListMutation.error])

    useEffect(() => {
        if (getPartnerStateListMutation.error) {
            console.error(getPartnerStateListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPartnerStateListMutation.error])

    useEffect(() => {
        if (getPartnerTypesListMutation.error) {
            console.error(getPartnerTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPartnerTypesListMutation.error])

    useEffect(() => {
        if (getPartnersListMutation.error) {
            console.error(getPartnersListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPartnersListMutation.error])

    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={partnerFormik.handleSubmit}>
                        {CompanyNameInput}
                        {PartnerNameInput}
                        {EmailInput}
                        {StreetNameInput}
                        {PostalCodeInput}
                        {CityInput}
                        {CountryInput}
                        {PartnerStateInput}
                        {PartnerTypeInput}
                        {PartnerOfInput}
                        {CurrenciesSelect}
                        {/* {DiscountInput} */}
                        {ActiveInput}
                    </Form.Main>
                </div>
            </div>
        </div>
    )
}
export default PartnerUpdate

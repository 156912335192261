import { RootState } from '@app/store'
import { URL } from '@constants/main/root'
import {
    ChangePasswordRequest,
    ChangePasswordResponse,
    DoAuthRequest,
    DoAuthResponse,
    DoValidateMFARequest,
    ResetPasswordRequest,
    ResetPasswordResponse
} from '@interfaces/main/login'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

/**
 * api calls will have their own interfaces to create separation from redux
 * state due to variable assignment in event handlers. For example, the app
 * property is not part of the login state but is required in the call below.
 * Also, hardcoded values are discouraged unless if it will be invoked in more
 * than one component except for api JS files.
 */

/**
 * default parameters for the query property in fetchBaseQuery are:
 * {
 *      url:"",
 *      method:"",
 *      body:{},
 *      headers:{},
 *      params:{},
 *      responseHandler: (response) => response.json() ,
 *      validateStatus: (response, result) => response.status === 200 && !result.isError,
 * }
 *
 * also requires prepareHeaders to pass in an authentication token from state.
 */

/**
 * call to authenticate login data.
 * */
export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API,
        /** this header will be used if no body parameter is defined */
        prepareHeaders: (headers, { getState }) => {
            const state = (getState() as RootState)
            // if falsy, don't set an authorization header
            if (state.token.data.value) {
                headers.set('Authorization', `Bearer ${ state.token.data.value }`)
            }
            return headers
        }
    }),

    endpoints: (builder) => ({
        // will not use build.query unless method type is get or undefined.
        doAuth: builder.mutation<DoAuthResponse, DoAuthRequest>({
            query: (body) => {
                const formData: DoAuthRequest = {
                    email: body.email,
                    password: body.password,
                    app: body.app
                }

                return {
                    url: 'api/doAuth',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: `{
                        "email":"${ formData.email }",
                        "password":"${ formData.password }",
                        "app":"${ formData.app }"
                    }`
                }
            }
        }),
        /** response data when wrong can be a partial version of doAuth response */
        doValidateMFA: builder.mutation<Partial<DoAuthResponse>, DoValidateMFARequest & TokenAuth>({
            query: (body) => {
                const formData: DoValidateMFARequest = {
                    token: body.token,
                    app: body.app
                }

                return {
                    url: 'be/doValidateMFA',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        changePassword: builder
            .mutation<ChangePasswordResponse, ChangePasswordRequest & TokenAuth>({
                query: (body) => {
                    const formData: ChangePasswordRequest = {
                        currentPassword: body.currentPassword,
                        newPassword: body.newPassword,
                        newPassword2: body.newPassword2
                    }

                    return {
                        url: 'be/changePassword',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        doPasswordReset: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
            query: (body) => {
                const formData: ResetPasswordRequest = {
                    email: body.email
                }

                return {
                    url: 'api/doPasswordReset',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        })
    })
})

export const {
    useDoAuthMutation,
    useDoValidateMFAMutation,
    useChangePasswordMutation,
    useDoPasswordResetMutation
} = loginApi

import {
    QUESTIONNAIRE_ROUTES,
    QUESTION_ROUTES
} from '@constants/main/routes'

import QuestionnaireMenu from
    '@features/watchdog/virtual-ciso/questionnaire-information/questionnaire/QuestionnaireMenu'
import QuestionnaireView from
    '@features/watchdog/virtual-ciso/questionnaire-information/questionnaire/QuestionnaireView'
import QuestionnaireAdd from
    '@features/watchdog/virtual-ciso/questionnaire-information/questionnaire/add/QuestionnaireAdd'
import QuestionnaireUpdate from
    // eslint-disable-next-line max-len
    '@features/watchdog/virtual-ciso/questionnaire-information/questionnaire/update/QuestionnaireUpdate'
import QuestionMenu from
    '@features/watchdog/virtual-ciso/questionnaire-information/question/QuestionMenu'
import QuestionAdd from
    '@features/watchdog/virtual-ciso/questionnaire-information/question/add/QuestionAdd'
import QuestionView from
    '@features/watchdog/virtual-ciso/questionnaire-information/question/QuestionView'
import QuestionUpdate from
    '@features/watchdog/virtual-ciso/questionnaire-information/question/update/QuestionUpdate'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: QUESTIONNAIRE_ROUTES.MAIN.link,
        component: QuestionnaireMenu
    }, {
        exact: true,
        path: QUESTIONNAIRE_ROUTES.ADD.link,
        component: QuestionnaireAdd
    }, {
        exact: true,
        path: QUESTIONNAIRE_ROUTES.VIEW.link,
        component: QuestionnaireView
    }, {
        exact: true,
        path: QUESTIONNAIRE_ROUTES.UPDATE.link,
        component: QuestionnaireUpdate
    },
    {
        exact: true,
        path: QUESTION_ROUTES.MAIN.link,
        component: QuestionMenu
    }, {
        exact: true,
        path: QUESTION_ROUTES.ADD.link,
        component: QuestionAdd
    }, {
        exact: true,
        path: QUESTION_ROUTES.VIEW.link,
        component: QuestionView
    }, {
        exact: true,
        path: QUESTION_ROUTES.UPDATE.link,
        component: QuestionUpdate
    }
]

export default Routes

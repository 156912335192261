
import {
    useGetModalDataMutation,
    useGetModalLineChartMutation
} from '@apis/dashboard/soc/oss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as OSS_MESSAGE,
    TEXT as OSS_TEXT
} from '@constants/dashboard/soc/oss'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import DataGauge from '@features/dashboard/soc/oss/DataGauge'
import HistoricalSecureScoresChart from '@features/dashboard/soc/oss/HistoricalSecureScoresChart'
import HorizontalGauge from '@features/dashboard/soc/oss/HorizontalGauge'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import {
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    GetModalRequest,
    Header
} from '@interfaces/dashboard/soc/oss'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    resetOssMain,
    selectCurrentParams,
    selectPrintOptions,
    selectSearchParams,
    setCurrentParams,
    setEndDate,
    setLogo,
    setExpandDetails,
    setRefetch,
    setStartDate
} from '@slices/dashboard/soc/oss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    Container,
    DashboardStyledComponents as Dashboard,
    GaugePercentage,
    SpinnerContainer,
    Text,
    ErrorMessage
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import {
    FaFilePdf
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'

const OSSModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)
    const currentParams = useAppSelector(selectCurrentParams)
    const searchParams = useAppSelector(selectSearchParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** will be initiailized multiple times. */
    const [getModalLineChart, getModalLineChartMutation] = useGetModalLineChartMutation()
    const [getModalData, getModalDataMutation] = useGetModalDataMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalLineChartMutation()

    useEffect(() => {
        if (getModalLineChartMutation.error) {
            console.error(getModalLineChartMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalLineChartMutation.error])

    useEffect(() => {
        if (getModalDataMutation.error) {
            console.error(getModalDataMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalDataMutation.error])

    /** after useEffect to throw errors, create fetchData script.
     * NEW CHANGE from old system. make each call a separate script.
     * Was done because client requested to individually have refresh button on
     * each chart.
     *
     * UPDATE: only apply this case WHEN it needs to be. Because this module
     * has one refresh button that executes all api calls at once, put it all in once place.
    */

    const unsubscribeGetModalLineChart = () => {
        const unsubscribeMutation = getModalLineChart({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetModalData = () => {
        const unsubscribeMutation = getModalData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const newRanges = getUtcRanges(searchParams.ranges)
                    const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                        authToken: token.value,
                        deviceid: modal.card.deviceid,
                        in_face: modal.card.in_face,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchData = () => {
        unsubscribeGetModalLineChart()
        unsubscribeGetModalData()

        let getModalLineChartPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getModalDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalLineChartPromise = getModalLineChart(requestData)
                        getModalDataPromise = getModalData(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalLineChartPromise && getModalLineChartPromise.abort()
            getModalDataPromise && getModalDataPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalLineChartMutation.isSuccess &&
            getModalDataMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalLineChartMutation.isSuccess,
        getModalDataMutation.isSuccess
    ])

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data
        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && (data?.data || []).length <= 0) {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 30 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))
                dispatch(setRefetch(true))

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalLineChartMutation.isLoading ||
            getModalDataMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update mdr bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [
        getModalLineChartMutation,
        getModalDataMutation
    ])

    const PrintOptionsContent = useMemo(() => {
        const ExpandDetailsLabel = (
            <label
                className={'col-9'}
                htmlFor={OSS_TEXT.PRINT_OPTIONS.EXPAND_DETAILS.ID}>
                {OSS_TEXT.PRINT_OPTIONS.EXPAND_DETAILS.LABEL}
            </label>
        )
        const ExpandDetailsInput = (
            <input
                className={'pointer'}
                type={'checkbox'}
                onChange={() => {
                    dispatch(setExpandDetails(
                        !printOptions.expandDetails
                    ))
                }}
                checked={printOptions.expandDetails}
            />
        )

        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={OSS_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {OSS_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalLineChartMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <div className={'align-items-center row mb-2'}>
                {ExpandDetailsLabel}
                <div className={'col-3'}>
                    {ExpandDetailsInput}
                </div>

            </div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalLineChartMutation,
        queueId,
        queues,
        printOptions,
        currentParams
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data: Header = getModalDataMutation.data?.data.header || {
            title: modal.card.details.title || '',
            line_1: modal.card.details.line_1 || '',
            line_2: modal.card.details.line_2 || '',
            heading: modal.card.details.heading || '',
            state: modal.card.details.state || '',
            state_details: modal.card.details.state_details || '',
            colorType: modal.card.details.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ data.state || 'NO STATE' }
                    : ${ data.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalDataMutation.isLoading
                        ? getModalDataMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalDataMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalDataMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalDataMutation.data?.data
        dispatch(changeModalColor({
            modal: modal,
            colorType: data?.header.colorType || modal.card.details.colorType || 'grey'
        }))
    }, [
        modal.card,
        getModalDataMutation
    ])

    useEffect(() => {
        return () => {
            dispatch(resetOssMain())
        }
    }, [])

    const overallScoresEl = useRef<HTMLCanvasElement>(null)
    const allTenantsEl = useRef<HTMLCanvasElement>(null)
    const totalSeatsEl = useRef<HTMLCanvasElement>(null)
    const industryTypesEl = useRef<HTMLCanvasElement>(null)

    const ComparisonScores = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore
        /** get numbers immediately */
        const overallScoreCurrent = secureScore?.scores.currentScore.currentScore || 0
        const overallScoreMax = secureScore?.scores.currentScore.maxScore || 0
        const allTenantsScore = secureScore?.scores.allTenants.averageScore || 0
        const totalSeatsScore = secureScore?.scores.TotalSeats.averageScore || 0
        const industryTypesScore = secureScore?.scores.IndustryTypes.averageScore || 0

        return <Container bgIndex={2}>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.OVERALL_SCORE}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={overallScoreCurrent}
                        maxScore={overallScoreMax}
                        chartEl={overallScoresEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.ALL_TENANTS}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={allTenantsScore}
                        maxScore={0}
                        chartEl={allTenantsEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.TOTAL_SEATS}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={totalSeatsScore}
                        maxScore={0}
                        chartEl={totalSeatsEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.INDUSTRY_TYPES}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={industryTypesScore}
                        maxScore={0}
                        chartEl={industryTypesEl}
                    />
                </div>
            </div>
        </Container>
    }, [getModalDataMutation.data])

    const dataEl = useRef<HTMLCanvasElement>(null)
    const identityEl = useRef<HTMLCanvasElement>(null)
    const appsEl = useRef<HTMLCanvasElement>(null)
    const deviceEl = useRef<HTMLCanvasElement>(null)

    const DataScores = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore
        /** get numbers immediately */
        const dataTotalScore = secureScore?.Data.totalScore || 0
        const dataMaxScore = secureScore?.Data.maxScore || 0

        const identityTotalScore = secureScore?.Identity.totalScore || 0
        const identityMaxScore = secureScore?.Identity.maxScore || 0

        const appsTotalScore = secureScore?.Apps.totalScore || 0
        const appsMaxScore = secureScore?.Apps.maxScore || 0

        const deviceTotalScore = secureScore?.Device.totalScore || 0
        const deviceMaxScore = secureScore?.Device.maxScore || 0

        return <Container bgIndex={2}>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.DATA}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={dataTotalScore}
                        maxScore={dataMaxScore}
                        chartEl={dataEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.IDENTITY}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={identityTotalScore}
                        maxScore={identityMaxScore}
                        chartEl={identityEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.APPS}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={appsTotalScore}
                        maxScore={appsMaxScore}
                        chartEl={appsEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-md-5 col-4 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.DEVICE}
                    </Text>
                </div>
                <div className={'col-md-7 col-8 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={deviceTotalScore}
                        maxScore={deviceMaxScore}
                        chartEl={deviceEl}
                    />
                </div>
            </div>
        </Container>
    }, [getModalDataMutation.data])

    /** line chart */
    const HistoricalSecureScores = useMemo(() => {
        return <HistoricalSecureScoresChart
            modal={modal}
            addModal={addModal}
            closeModal={closeModal}
            changeModalColor={changeModalColor}
            data={getModalLineChartMutation.data?.data}
            isLoading={getModalLineChartMutation.isLoading}
            isSuccess={getModalLineChartMutation.isSuccess}
            error={getModalLineChartMutation.error}
        />
    }, undefined)

    /** now we need 4 sections (Data, Identity, Apps, Device) with 3 gauge
     *  charts (Behavior, Config, Review) each   */

    const dataBehaviorEl = useRef<HTMLCanvasElement>(null)
    const DataBehaviorScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Data.Behavior.totalScore || 0
        const maxScore = secureScore?.Data.Behavior.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.BEHAVIOR}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={dataBehaviorEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Behavior'}
                controlCategory={'Data'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const dataConfigEl = useRef<HTMLCanvasElement>(null)
    const DataConfigScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Data.Config.totalScore || 0
        const maxScore = secureScore?.Data.Config.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.CONFIG}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={dataConfigEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Config'}
                controlCategory={'Data'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const dataReviewEl = useRef<HTMLCanvasElement>(null)
    const DataReviewScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Data.Review.totalScore || 0
        const maxScore = secureScore?.Data.Review.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.REVIEW}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={dataReviewEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Review'}
                controlCategory={'Data'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const identityBehaviorEl = useRef<HTMLCanvasElement>(null)
    const IdentityBehaviorScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Identity.Behavior.totalScore || 0
        const maxScore = secureScore?.Identity.Behavior.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.BEHAVIOR}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={identityBehaviorEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Behavior'}
                controlCategory={'Identity'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const identityConfigEl = useRef<HTMLCanvasElement>(null)
    const IdentityConfigScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Identity.Config.totalScore || 0
        const maxScore = secureScore?.Identity.Config.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.CONFIG}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={identityConfigEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Config'}
                controlCategory={'Identity'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const identityReviewEl = useRef<HTMLCanvasElement>(null)
    const IdentityReviewScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Identity.Review.totalScore || 0
        const maxScore = secureScore?.Identity.Review.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.REVIEW}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={identityReviewEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Review'}
                controlCategory={'Identity'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const appsBehaviorEl = useRef<HTMLCanvasElement>(null)
    const AppsBehaviorScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Apps.Behavior.totalScore || 0
        const maxScore = secureScore?.Apps.Behavior.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.BEHAVIOR}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={appsBehaviorEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Behavior'}
                controlCategory={'Apps'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const appsConfigEl = useRef<HTMLCanvasElement>(null)
    const AppsConfigScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Apps.Config.totalScore || 0
        const maxScore = secureScore?.Apps.Config.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.CONFIG}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={appsConfigEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Config'}
                controlCategory={'Apps'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const appsReviewEl = useRef<HTMLCanvasElement>(null)
    const AppsReviewScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Apps.Review.totalScore || 0
        const maxScore = secureScore?.Apps.Review.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.REVIEW}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={appsReviewEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Review'}
                controlCategory={'Apps'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const deviceBehaviorEl = useRef<HTMLCanvasElement>(null)
    const DeviceBehaviorScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Device.Behavior.totalScore || 0
        const maxScore = secureScore?.Device.Behavior.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.BEHAVIOR}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={deviceBehaviorEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Behavior'}
                controlCategory={'Device'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const deviceConfigEl = useRef<HTMLCanvasElement>(null)
    const DeviceConfigScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Device.Config.totalScore || 0
        const maxScore = secureScore?.Device.Config.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.CONFIG}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={deviceConfigEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Config'}
                controlCategory={'Device'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    const deviceReviewEl = useRef<HTMLCanvasElement>(null)
    const DeviceReviewScore = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore

        const totalScore = secureScore?.Device.Review.totalScore || 0
        const maxScore = secureScore?.Device.Review.maxScore || 0

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {OSS_TEXT.SECTIONS.SCORES.REVIEW}
            </Text>
            <DataGauge
                totalScore={totalScore}
                maxScore={maxScore}
                chartEl={deviceReviewEl}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                actionType={'Review'}
                controlCategory={'Device'}
            />
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(getModalDataMutation.error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !getModalDataMutation.isLoading
                ? getModalDataMutation.isSuccess
                    ? content
                    : getModalDataMutation.error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {Statcard}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-3'}>
                    <div className={'col-md-6 col-12'}>
                        <span className={'d-inline-block mb-2'}>
                            {OSS_TEXT.SECTIONS.COMPARISON.TITLE}
                        </span>
                        {ComparisonScores}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        <span className={'d-inline-block mb-2'}>
                            {OSS_TEXT.SECTIONS.DATA_SEGMENTS.TITLE}
                        </span>
                        {DataScores}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <span className={'d-inline-block mb-2'}>
                    {OSS_TEXT.SECTIONS.HISTORICAL_SECURE_SCORES}
                </span>
                {HistoricalSecureScores}
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {OSS_TEXT.SECTIONS.CATEGORIES.DATA}
                        </span>
                        <div className={'row'}>
                            <div className={'col-lg-4 col-12'}>
                                {DataBehaviorScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {DataConfigScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {DataReviewScore}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {OSS_TEXT.SECTIONS.CATEGORIES.IDENTITY}
                        </span>
                        <div className={'row'}>
                            <div className={'col-lg-4 col-12'}>
                                {IdentityBehaviorScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {IdentityConfigScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {IdentityReviewScore}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {OSS_TEXT.SECTIONS.CATEGORIES.APPS}
                        </span>
                        <div className={'row'}>
                            <div className={'col-lg-4 col-12'}>
                                {AppsBehaviorScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {AppsConfigScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {AppsReviewScore}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {OSS_TEXT.SECTIONS.CATEGORIES.DEVICE}
                        </span>
                        <div className={'row'}>
                            <div className={'col-lg-4 col-12'}>
                                {DeviceBehaviorScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {DeviceConfigScore}
                            </div>
                            <div className={'col-lg-4 col-12'}>
                                {DeviceReviewScore}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OSSModal

import { RootState } from '@app/store'
import { } from '@constants/main/routes'
import { ALL_DEVICES_OPTION } from '@constants/watchdog/soc-data/event'
import {
    BoxState
} from '@interfaces/watchdog/configuration/box'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

const initialState : BoxState = {
    boxname: ALL_DEVICES_OPTION,
    sort: 'priority',
    filter: 'online',
    arrange: 'asc',
    refetch: false,
    boxData: [],

    tabs: []
}

export const slice = createSlice({
    name: 'box',
    initialState: initialState,
    reducers: {
        setBoxname: (state: BoxState, action: PayloadAction<BoxState['boxname']>) => {
            state.boxname = action.payload
        },
        setSort: (state: BoxState, action: PayloadAction<BoxState['sort']>) => {
            state.sort = action.payload
        },
        setFilter: (state: BoxState, action: PayloadAction<BoxState['filter']>) => {
            state.filter = action.payload
        },
        setArrange: (state: BoxState, action: PayloadAction<BoxState['arrange']>) => {
            state.arrange = action.payload
        },
        setRefetch: (state: BoxState, action: PayloadAction<boolean>) => {
            state.refetch = action.payload
        },
        setBoxData: (state: BoxState, action: PayloadAction<BoxState['boxData']>) => {
            state.boxData = action.payload
        },
        resetBox: (state: BoxState) => {
            state.arrange = initialState.arrange
            state.boxData = initialState.boxData
            state.boxname = initialState.boxname
            state.filter = initialState.filter
            state.refetch = initialState.refetch
            state.sort = initialState.sort
            state.tabs = initialState.tabs
        }
    }
})

export const {
    setBoxname,
    setSort,
    setFilter,
    setArrange,
    setRefetch,
    setBoxData,
    resetBox
} = slice.actions

export const selectBoxname = (state: RootState) => state.box.boxname
export const selectSort = (state: RootState) => state.box.sort
export const selectFilter = (state: RootState) => state.box.filter
export const selectArrange = (state: RootState) => state.box.arrange
export const selectRefetch = (state: RootState) => state.box.refetch
export const selectBoxData = (state: RootState) => state.box.boxData

export default slice.reducer

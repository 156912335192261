import {
    QuestionAction,
    QuestionColumn,
    AddQuestionKeys,
    AddQuestionValues,
    UpdateQuestionKeys,
    UpdateQuestionValues,
    AnswerLineColumn
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/question'
import _ from 'lodash'
import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'

import * as Yup from 'yup'
import {
    QuestionKeys
} from '@interfaces/watchdog/virtual-ciso/compliance-questionnaire'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Question call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when question is undefined */
    UNDEFINED_ID: 'Empty question ID detected.'

}

const ADD_FORM = {
    TITLE: {
        LABEL: 'Title',
        ID: 'ADD__TITLE'
    },
    QTYPE: {
        LABEL: 'Question Type',
        ID: 'ADD__QTYPE'
    },
    DESCRIPTION: {
        LABEL: 'Description',
        ID: 'ADD__DESCRIPTION'
    },
    QUESTION: {
        LABEL: 'Question',
        ID: 'ADD__QUESTION'
    },
    ACTIVE: {
        LABEL: 'Active',
        ID: 'ADD__ACTIVE'
    },

    COMPLIANCE_ARTICLE: {
        LABEL: 'Compliance Article',
        ID: 'ADD__COMPLIANCE_ARTICLE'
    },
    ADD_ANSWER_LINE: 'Add Answer Line',
    REMOVE_ANSWER_LINE: 'Remove',
    REFRESH_BUTTON: 'Refresh Data'
}

export const TEXT = {
    ADD: {
        /** confirmation label for adding a question */
        TITLE: 'Add Question Confirmation',
        /** confirmation message for adding a question */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit the question data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Adding Question',
            SUBMIT_BUTTON: 'Add Question'
        }
    },
    UPDATE: {
        /** confirmation label for updating a question */
        TITLE: 'Update Question Confirmation',
        /** confirmation message for updating a question */
        CONFIRMATION_MESSAGE: 'Are you sure you want to update the question data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Updating Question',
            SUBMIT_BUTTON: 'Update Question'
        }
    },
    DELETE: {
        /** confirmation label for updating a question */
        TITLE: 'Delete Question Confirmation',
        /** confirmation message for updating a question */
        CONFIRMATION_MESSAGE: 'Are you sure you want to delete the question data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Deleting Question',
            SUBMIT_BUTTON: 'Delete Question'
        }
    }
}
export const ACTIONS: {
    QUESTION: QuestionAction[],
} = {
    QUESTION: [
        // {
        //     label: 'View',
        //     value: 'QUESTION_VIEW',
        //     mode: 'primary'
        // },
        {
            label: 'Edit',
            value: 'QUESTION_EDIT',
            mode: 'primary'
        },
        {
            label: 'Delete',
            value: 'QUESTION_DELETE',
            mode: 'primary'
        },
        {
            label: 'Questions',
            value: 'QUESTION_VIEW',
            mode: 'primary'
        }
    ]
}

export const COLUMNS: {
    QUESTION: QuestionColumn[],
    ANSWER_LINES: AnswerLineColumn[],
} = {
    QUESTION: (() => {
        const columnNames: Omit<QuestionColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Question Title',
                value: 'title'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: QuestionColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    ANSWER_LINES: [
        {
            label: 'Name',
            value: 'name'
        },
        {
            label: 'Value',
            value: 'value'
        },
        {
            label: 'Next Question',
            value: 'nextq'
        }
    ]

}

export const KEYS: {
    QUESTION: QuestionKeys[],
} = {
    QUESTION: [
        'title'
    ]
}

const DEFAULT_FORM_VALUES: AddQuestionValues = {
    title: '',
    description: '',
    question: '',
    type_id: '',
    active: true,
    complianceLines: [],
    answerLines: []
}

export const INITIAL_VALUES: {
    ADD: AddQuestionValues,
    UPDATE: UpdateQuestionValues,
} = {
    ADD: _.cloneDeep(DEFAULT_FORM_VALUES),
    UPDATE: _.cloneDeep(DEFAULT_FORM_VALUES)
}

export const ANSWERS_SCHEMA_OBJECT = {
    name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.ANSWER_LINE_INCOMPLETE),
    value: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.ANSWER_LINE_INCOMPLETE),
    // nextq: Yup.string().required(ANSWERS_INCOMPLETE_MESSAGE),
    // creating a question when there are no questions to choose is an exception.
    nextq: Yup.string()
}

const DEFAULT_VALIDATION_SCHEMA = {
    title: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    description: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    question: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    type_id: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    active: Yup.boolean().default(false),
    // an array of compliance article ids.
    complianceLines: Yup.array().required(ROOT_MESSAGE.ERROR.FORM.EMPTY).of(
        Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
    ),
    // an array of objects.
    answerLines: Yup.array().required(ROOT_MESSAGE.ERROR.FORM.ANSWER_LINE_EMPTY).of(
        Yup.object().shape({
            ...ANSWERS_SCHEMA_OBJECT
        })
    )
}

export const VALIDATION_SCHEMA: {
    ADD: OptionalObjectSchema<Record<AddQuestionKeys, Yup.AnySchema>>,
    UPDATE: OptionalObjectSchema<Record<UpdateQuestionKeys, Yup.AnySchema>>,
} = {
    ADD: Yup.object(_.cloneDeep(DEFAULT_VALIDATION_SCHEMA)),
    UPDATE: Yup.object(_.cloneDeep(DEFAULT_VALIDATION_SCHEMA))
}

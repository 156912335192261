import {
    useAppDispatch
} from '@app/hook'
import {
    TEXT as EVENT_TEXT
} from '@constants/watchdog/soc-data/event'
import {
    TEXT as COMPLIANCE_TEXT
} from '@constants/watchdog/virtual-ciso/compliance-questionnaire'
import {
    AnswerReportValues,
    DecodedReportParam,
    GetReportDataResponse,
    ReportFormData,
    ReportModal,
    ReportStepInput
} from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    Button,
    Table
} from '@styles/components'
import {
    FormikProps
} from 'formik'
import _ from 'lodash'
import React, { useMemo } from 'react'
import uniqueString from 'unique-string'

interface ComponentProps {
    decodedReportParams: DecodedReportParam
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    reportStepsFormik: FormikProps<AnswerReportValues>,
    responseData: GetReportDataResponse | undefined
    currentReportSection: number,
    fileInputRefs: React.MutableRefObject<{
        reportSection: number;
        fileIds: HTMLInputElement[];
    }[]>,
    uploadFile: (values: ReportStepInput, fileIndex: number) => Promise<void>
}

/** one thing we need to figure out, we need to have ids for inputs and collapsible buttons.
 * so we will be creating them in the formAnswerValues initialization.
 */

const FileUpload = ({
    decodedReportParams, addModal, currentReportSection,
    reportStepsFormik, responseData, fileInputRefs, uploadFile
} : ComponentProps) => {
    const dispatch = useAppDispatch()

    /** let's make a useMemo to get the reportStep information */
    const reportStepInfo = useMemo(() => {
        return _.find(responseData?.reportSteps || [], (obj) => {
            return obj.reportSection === currentReportSection
        })
    }, [
        responseData, currentReportSection
    ])

    const stepIndex = useMemo(() => {
        return _.findIndex(reportStepsFormik.values.reportSteps, (obj) => {
            return obj.reportSection === currentReportSection
        })
    }, [
        reportStepsFormik.values, currentReportSection
    ])

    const stepInput = useMemo(() => {
        return reportStepsFormik.values.reportSteps[stepIndex]
    }, [
        reportStepsFormik.values, stepIndex
    ])

    const ProofFiles = useMemo(() => {
        const foundFileRefs = _.find(fileInputRefs.current, (o) => {
            return o.reportSection === currentReportSection
        })

        const renderRow = (proofFile: string, index: number) => {
            const removeButton = <Button
                type={'button'}
                onClick={() => {
                    const arr = [...stepInput.fileIds]
                    arr.splice(index, 1)
                    reportStepsFormik.setFieldValue(
                        [
                            'reportSteps',
                            '[', stepIndex.toString(), '].',
                            'fileIds'
                        ].join(''), arr
                    )
                }}
                size={'sm'}
                mode={'danger'}
            >
                {COMPLIANCE_TEXT.ANSWER.FORM.REMOVE_PROOF_FILE}
            </Button>

            const addButton = <div>
                <input
                    ref={(e) => {
                        if (e && foundFileRefs) {
                            foundFileRefs.fileIds[index] = e
                        }
                    }}
                    type={'file'}
                    id={COMPLIANCE_TEXT.ANSWER.FORM.UPLOAD_PROOF_FILE.ID}
                    className={'d-none'}
                    onChange={() => uploadFile(stepInput, index)}
                />
                <Button
                    type={'button'}

                    size={'sm'}
                    mode={'primary'}
                >
                    <label
                        className={'mb-0'}
                        htmlFor={COMPLIANCE_TEXT.ANSWER.FORM.UPLOAD_PROOF_FILE.ID}
                    >
                        {COMPLIANCE_TEXT.ANSWER.FORM.UPLOAD_PROOF_FILE.LABEL}
                    </label>
                </Button>
            </div>

            return (
                <tr>
                    <td>
                        {COMPLIANCE_TEXT.ANSWER.FORM.PROOFFILE_ID}
                    </td>
                    <td>
                        <input
                            readOnly
                            type={'text'}
                            value={proofFile}
                        />
                    </td>
                    <td>
                        {
                            proofFile && index
                                ? removeButton
                                : addButton
                        }
                    </td>
                    <td>
                        <Button
                            type={'button'}

                            onClick={() => {
                                const formData: ReportFormData = {
                                    answerHistory: {
                                        data: responseData?.reportData || [],
                                        reportStep: {
                                            stepIndex: stepIndex,
                                            inputIndex: index,
                                            reportSection: reportStepInfo
                                                ?.reportSection || 1
                                        }
                                    }
                                }

                                dispatch(addModal({
                                    id: uniqueString(),
                                    open: true,
                                    operation: 'FILE_HISTORY',
                                    formData: formData,
                                    isBorderWide: false
                                }))
                            }}
                            size={'sm'}
                            mode={'primary'}
                        >
                            {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.FILE_HISTORY_BUTTON}
                        </Button>
                    </td>
                </tr>
            )
        }

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    <tbody>
                        <tr>
                            <td colSpan={3}></td>
                            <td>
                                {<Button
                                    type={'button'}

                                    onClick={() => {
                                        const arr = _.cloneDeep(stepInput.fileIds)

                                        reportStepsFormik.setFieldValue(
                                            [
                                                'reportSteps',
                                                '[', stepIndex.toString(), '].',
                                                'fileIds'
                                            ].join(''), [...arr, '']
                                        )
                                    }}
                                    size={'sm'}
                                    mode={'primary'}
                                >
                                    {COMPLIANCE_TEXT.ANSWER.FORM.ADD_PROOF_FILE}
                                </Button>}
                            </td>
                        </tr>
                        {
                            _.map(stepInput.fileIds, renderRow)
                        }
                    </tbody>
                </table>
            </Table>
        )
    }, [
        stepInput.fileIds
    ])

    return <div>
        {ProofFiles}
    </div>
}

export default FileUpload

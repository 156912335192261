import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector
} from 'react-redux'
import type {
    RootState,
    AppDispatch
} from '@app/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// makes sure that we are type referencing from the very start properly.
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

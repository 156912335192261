
import {
    useAppSelector
} from '@app/hook'
import {
    AZURE_EVENT_TYPES_COLUMNS,
    AZURE_COMMON_SECURITY_LOG_COLUMNS,
    AZURE_SYSLOG_COMPUTER_COLUMNS,
    AZURE_PROTECTION_STATUS_COLUMNS,
    AZURE_SECURITY_EVENT_COLUMNS,
    AZURE_UPDATE_COLUMNS,
    AZURE_SYSLOG_PROCESS_NAME_COLUMNS,
    AZURE_AZURE_ACTIVITY_COLUMNS,
    MESSAGE as AZURE_MESSAGE,
    TEXT as AZURE_TEXT
} from '@constants/dashboard/soc/azure/detailedDashboard/main'
import {
    assignIntervalTick,
    createIntervals
} from '@constants/main/method'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_CHART_PADDING,
    DEFAULT_INTERVAL,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    Bucket,
    Doc
} from '@interfaces/dashboard/monitor'
import {
    EventType
} from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import {
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles
} from '@slices/dashboard/soc/azure/detailedDashboard/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    PageBreakInside,
    SpinnerContainer,
    Table,
    TableColorCell
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    format,
    fromUnixTime,
    getTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'

import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    eventType: Exclude<EventType, 'Azure.Locations' | 'Azure.Events'>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const O365BarChart = ({ eventType, isLoading, isSuccess, error }:ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const [
        chartInstance,
        setChartInstance
    ] = useState<Chart<'bar', { x: string; y: number; }[], string>>()

    /** colors for both chart and table. will be set in chart initialization but will
     * only target lifecycle of table. doing so in chart will trigger an infinite render loop.
    */
    const [chartColors, setChartColors] = useState<string[]>([])

    /** i don't want to duplicate the code snippet so we'll just create
     * this separately. Plus the pseudo code ended up taking up more than 300 lines.
     * we end up having to make each chart a separate file BUT at least it's somewhere else.
     *
     * UPDATE: 9/16/2022. Ended up being one chart instance with multiple switch cases.
     */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** There was an attempt to make a useMemo BUT realized that
     * the lifecycle would be inconsistent. we want a cleanup function
     * before creating the chart instance for every lifecycle.
     *
     * one more thing, only memoize the data on performance leaks.
     * doing so ignoring this reminder led to chart tooltip drawing
     * errors especially when you have useRefs to create visualizations.
     *
     * this occured when memozing the table (an alternative to display
     * data counts).
     *
     * TypeError: Cannot read properties of undefined (reading 'handleEvent')
     * this error above occurs when the dispatches on that onclick event
     * are in the wrong order.
     *
     * Issue with search params where you update the ranges. It
     * will mess up the form data for fetching either o365 details or data id
     * when selecting the first OR last dataset
     * in the charts OR the tables below them.
     */

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const currentData = dashboardData[eventType]
            ?.aggregations?.[2]?.buckets || []
        const fixedInterval = dashboardData[eventType]
            ?.fixedInterval || DEFAULT_INTERVAL
        let graph: Chart<'bar', { x: string; y: number; }[], string>

        /** find a max value at this grouped data would be code inefficient
         * so we will remove the max property.
         */

        if (chartEl.current) {
            /** if there is only one current data record, the default value would be
             * the same interval.
             */
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            if (currentData.length >= 2) {
                ranges.start = new Date(
                    currentData[0].key_as_string ||
                    fromUnixTime(currentParams.ranges.start)
                )
                ranges.end = new Date(
                    currentData[currentData.length - 1].key_as_string ||
                    fromUnixTime(currentParams.ranges.end)
                )
            }

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            _.forEach(currentData, (bucket2) => {
                const dateString = bucket2.key_as_string

                if (dateString) {
                    /** iterate the intervals array we made. */
                    for (let index = 0; index < intervals.length; index++) {
                        /** get the current and subsequent interval values */
                        const intervalOne = new Date(intervals[index])
                        const intervalTwo = new Date(intervals[index + 1])

                        /** check if dateString exists in intervals. If found
                         * break the loop and proceed to the next iteration.
                         */
                        const found = _.find(intervals, interval => {
                            return _.isEqual(
                                format(new Date(dateString), DATE_FORMAT_TIME),
                                interval
                            )
                        })

                        if (found) {
                            break
                        }

                        /** taken from mdr bar chart. Check if bucket2.key_as_string
                         * is inbetween said intervals. If it does, insert it via
                         * splice AND break the loop. Continue until you find one.
                         */

                        if (
                            isValid(intervalTwo) &&
                            isWithinInterval(
                                new Date(dateString),
                                {
                                    start: intervalOne,
                                    end: intervalTwo
                                }
                            )
                        ) {
                            /** MAKE SURE YOU are inserting the dateString in the
                             * correct format
                             */
                            intervals.splice(
                                index, 0, format(
                                    new Date(dateString), DATE_FORMAT_TIME
                                )
                            )
                            break
                        }
                    }
                }
            })
            const colors: string[] = []

            const datasets: typeof graph.data.datasets = _.reduce(
                intervals, (
                    storage: typeof graph.data.datasets,
                    interval
                ) => {
                    /** check if the timestamp exists in our interval,
                     * otherwise, add that to the dataset.
                     */

                    const bucket2: Bucket = _.find(currentData, (bucket) => {
                        const dateString = bucket.key_as_string
                        if (dateString) {
                            return _.isEqual(
                                format(new Date(dateString),
                                    DATE_FORMAT_TIME), interval
                            )
                        } else {
                            return false
                        }
                    }) || {
                        key_as_string: interval,
                        key: getTime(new Date(interval)),
                        doc_count: 0,
                        3: {
                            doc_count_error_upper_bound: 0,
                            sum_other_doc_count: 0,
                            buckets: []
                        }
                    }

                    // go to bucket['3'].buckets array, iterate
                    // again and check the key property of each third bucket.
                    bucket2[3]?.buckets && _.forEach(bucket2[3].buckets, (bucket3, index) => {
                        // if the property doesn't exist in storage, create
                        // one and increment the value that came with it.
                        const found = _.find(storage, obj => obj.label === bucket3.key)

                        if (!found) {
                            const color = CHART_COLORS[
                                _.random(0, CHART_COLORS.length)
                            ]

                            colors.push(color)

                            const newEntry: typeof graph.data.datasets[0] = {
                                data: [{
                                    /** note that we are using the interval
                                     * value we created and not bucket2.key
                                     */
                                    x: interval,
                                    y: bucket3.doc_count
                                }],
                                label: bucket3.key,
                                /** visual inconsistency with colors
                                 * for they might blend and be perceived
                                 * as one. Generate a random color
                                 * from a specific range
                                 */
                                backgroundColor: color,
                                normalized: true,
                                parsing: false,
                                /** removed barThickness: flex since there's no
                                 * responsiveness needed here. Decided on a value
                                 * of 8
                                 */
                                barThickness: 8,
                                maxBarThickness: 15,
                                barPercentage: 0.7
                            }

                            storage.push(newEntry)
                        } else {
                            found.data.push({
                                /** note that we are using the interval
                                 * value we created and not bucket2.key
                                 */
                                x: interval,
                                y: bucket3.doc_count
                            })
                        }
                    })

                    return storage
                }, []
            )

            setChartColors(colors)

            /** debugging if the chart is really rendering the response data.
             * if datasets !== currentData, then something's wrong when you
             * were initializing the chart instance
             */
            // console.log('Event type:', eventType)
            // console.log('Data: ', currentData)
            // console.log('Intervals: ', intervals)
            // console.log('Datasets: ', datasets)

            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: true,
                            text: [
                                TEXT.ZERO_ZOOM_LEVEL, '-',
                                format(ranges.start, DATE_FORMAT_TIME), 'to',
                                format(ranges.end, DATE_FORMAT_TIME)
                            ].join(' '),
                            color: stylesheet.mode.fontColor
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            /** added stacked: true so that bars
                             * will be more visible in their groups.
                             */
                            stacked: true
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,

                                display: false
                            },
                            /** added stacked: true so that bars
                             * will be more visible in their groups.
                             */
                            stacked: true,
                            min: 0
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        dashboardData[eventType]
    ])

    const DataTable = useMemo(() => {
        const currentData = dashboardData[eventType]
            ?.aggregations?.[2]?.buckets || []
        const fixedInterval = dashboardData[eventType]
            ?.fixedInterval || DEFAULT_INTERVAL

        /** BUG: Indexing to show a chart tooltip is incorrect when
         * hovering at a chart where there are datasets with
         * a value of zero.
         */

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        if (currentData.length >= 2) {
            ranges.start = new Date(
                currentData[0].key_as_string ||
                fromUnixTime(currentParams.ranges.start)
            )
            ranges.end = new Date(
                currentData[currentData.length - 1].key_as_string ||
                fromUnixTime(currentParams.ranges.end)
            )
        }

        const intervals = _.map(
            createIntervals(ranges, fixedInterval),
            (date) => format(date, DATE_FORMAT_TIME)
        )

        _.forEach(currentData, (bucket2) => {
            const dateString = bucket2.key_as_string

            if (dateString) {
                /** iterate the intervals array we made. */
                for (let index = 0; index < intervals.length; index++) {
                    /** get the current and subsequent interval values */
                    const intervalOne = new Date(intervals[index])
                    const intervalTwo = new Date(intervals[index + 1])

                    /** check if dateString exists in intervals. If found
                     * break the loop and proceed to the next iteration.
                     */
                    const found = _.find(intervals, interval => {
                        return _.isEqual(
                            format(new Date(dateString), DATE_FORMAT_TIME),
                            interval
                        )
                    })

                    if (found) {
                        break
                    }

                    /** taken from mdr bar chart. Check if bucket2.key_as_string
                     * is inbetween said intervals. If it does, insert it via
                     * splice AND break the loop. Continue until you find one.
                     */

                    if (
                        isValid(intervalTwo) &&
                        isWithinInterval(
                            new Date(dateString),
                            {
                                start: intervalOne,
                                end: intervalTwo
                            }
                        )
                    ) {
                        /** MAKE SURE YOU are inserting the dateString in the
                         * correct format
                         */
                        intervals.splice(
                            index, 0, format(
                                new Date(dateString), DATE_FORMAT_TIME
                            )
                        )
                        break
                    }
                }
            }
        })

        let eventTypeColumns: {
            label: string;
            value: keyof Doc;
        }[] = []

        switch (eventType) {
            case 'Azure.AzureActivity':
                eventTypeColumns = AZURE_AZURE_ACTIVITY_COLUMNS
                break
            case 'Azure.CommonSecurityLog':
                eventTypeColumns = AZURE_COMMON_SECURITY_LOG_COLUMNS
                break
            case 'Azure.EventTypes':
                eventTypeColumns = AZURE_EVENT_TYPES_COLUMNS
                break
            case 'Azure.ProtectionStatus':
                eventTypeColumns = AZURE_PROTECTION_STATUS_COLUMNS
                break
            case 'Azure.SecurityEvent':
                eventTypeColumns = AZURE_SECURITY_EVENT_COLUMNS
                break
            case 'Azure.SysLog.Computer':
                eventTypeColumns = AZURE_SYSLOG_COMPUTER_COLUMNS
                break
            case 'Azure.SysLog.ProcessName':
                eventTypeColumns = AZURE_SYSLOG_PROCESS_NAME_COLUMNS
                break
            case 'Azure.Update':
                eventTypeColumns = AZURE_UPDATE_COLUMNS
                break
            default:
                break
        }

        /** for the table, we just want the key name and the value, so Doc[] + color */
        const tableRows: (Doc & {color: string})[] = _.reduce(
            intervals, (
                storage: (Doc & {color: string})[],
                interval
            ) => {
                /** taken from old codesnippet where we find the timestamp
                 * of the current bucket.
                 */
                const bucket2: Bucket = _.find(currentData, (bucket) => {
                    const dateString = bucket.key_as_string
                    if (dateString) {
                        return _.isEqual(
                            format(new Date(dateString),
                                DATE_FORMAT_TIME), interval
                        )
                    } else {
                        return false
                    }
                }) || {
                    key_as_string: interval,
                    key: getTime(new Date(interval)),
                    doc_count: 0,
                    3: {
                        doc_count_error_upper_bound: 0,
                        sum_other_doc_count: 0,
                        buckets: []
                    }
                }

                // go to bucket['3'].buckets array, iterate
                // again and check the key property of each third bucket.
                bucket2[3]?.buckets && _.forEach(bucket2[3].buckets, (bucket3, index) => {
                    // if the property doesn't exist in storage, create
                    // one and increment the value that came with it.
                    const found = _.find(storage, obj => obj.key === bucket3.key)

                    if (!found) {
                        storage.push({
                            ...bucket3,
                            color: chartColors[index]
                        })
                    } else {
                        found.doc_count += bucket3.doc_count
                    }
                })

                return storage
            }, []
        )

        const cellBody = (
            dataObject: Bucket,
            property: keyof Bucket
        ) => {
            let cellContent: Bucket[keyof Bucket] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'key_as_string':
                    cellContent = dataObject.key_as_string
                        ? format(
                            new Date(dataObject.key_as_string),
                            DATE_FORMAT_TIME
                        )
                        : ''
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        <th></th>
                        {
                            _.map(eventTypeColumns, ({ label }, index) => {
                                const key = [
                                    'o365Activity-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        /** before mapping, sort by count -1 */
                        _.map(
                            _.orderBy(tableRows, ['doc_count'], ['desc']),
                            (row, rowIndex) => {
                                return (
                                    <tr
                                        key={'o365Activity-tr-' + rowIndex}>
                                        <TableColorCell color={chartColors[rowIndex]} key={[
                                            'tableColorCell-', rowIndex
                                        ].join('')} />
                                        {
                                            _.map(eventTypeColumns, (column, cellIndex) => {
                                                return (
                                                    <td key={[
                                                        'o365Activity-td-' + rowIndex +
                                            '-' + cellIndex
                                                    ].join('')}
                                                    >
                                                        {cellBody(row, column.value)}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </table>
        </Table>

        return (
            currentData.length
                ? content
                : ''
        )
    }, [
        dashboardData[eventType],
        chartInstance
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        let titleComponent = ''

        switch (eventType) {
            case 'Azure.AzureActivity':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_AZURE_ACTIVITY
                break
            case 'Azure.CommonSecurityLog':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_COMMON_SECURITY_LOG
                break
            case 'Azure.EventTypes':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_EVENT_TYPES
                break
            case 'Azure.ProtectionStatus':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_PROTECTION_STATUS
                break
            case 'Azure.SecurityEvent':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_SECURITY_EVENT
                break
            case 'Azure.SysLog.Computer':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_SYSLOG_COMPUTER
                break
            case 'Azure.SysLog.ProcessName':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_SYSLOG_PROCESS_NAME
                break
            case 'Azure.Update':
                titleComponent = AZURE_TEXT.SECTIONS.AZURE_UPDATE
                break
            default:
                break
        }

        const content = (
            /** removed Container tag and transferred
             * to the chart instance
             * Also found pdf bug where the title wasn't performing page-break
             * along with the chart instance. decided to move it here instead from
             * O365Modal.tsx
             */
            <div>
                <PageBreakInside className={'row'}>
                    <div className={'col'}>
                        <span className={'d-block mb-2'}>
                            {titleComponent}
                        </span>
                        <Container bgIndex={2} className={'mb-2'}>
                            <canvas ref={chartEl}/>
                        </Container>
                    </div>
                </PageBreakInside>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles[eventType]
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </div>
        )

        let fetchMessage: string = ''
        switch (eventType) {
            case 'Azure.AzureActivity':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_AZURE_ACTIVITY
                break
            case 'Azure.CommonSecurityLog':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_COMMON_SECURITY_LOG
                break
            case 'Azure.EventTypes':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_EVENT_TYPES
                break
            case 'Azure.ProtectionStatus':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_PROTECTION_STATUS
                break
            case 'Azure.SecurityEvent':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_SECURITY_EVENT
                break
            case 'Azure.SysLog.Computer':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_SYSLOG_COMPUTER
                break
            case 'Azure.SysLog.ProcessName':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_SYSLOG_PROCESS_NAME
                break
            case 'Azure.Update':
                fetchMessage = AZURE_MESSAGE.FETCH.AZURE_UPDATE
                break
            default:
                break
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{fetchMessage}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !dashboardData[eventType]
                ? !isLoading
                    ? isSuccess
                        ? content
                        : error ? ErrorContent : ''
                    : LoadingContent
                : content
        )
    }, undefined)

    return <>{DataContent}</>
}

O365BarChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default O365BarChart

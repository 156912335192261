
import {
    useAppSelector
} from '@app/hook'
import {
    DEFAULT_ISSUE_ALERT,
    ISSUES_DETECTED_COLUMNS,
    MESSAGE as O365_MESSAGE,
    TEXT as O365_TEXT
} from '@constants/dashboard/soc/o365/main'
import {
    createIntervals
} from '@constants/main/method'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_CHART_PADDING,
    DEFAULT_INTERVAL,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    DataAggreggation,
    IssueAlert
} from '@interfaces/dashboard/soc/o365/main'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams,
    selectFixedCollapsibles
} from '@slices/dashboard/soc/o365/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    PageBreakInside,
    SpinnerContainer,
    Table,
    TableColorCell
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    format,
    fromUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'

interface ComponentProps {
    data: DataAggreggation | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssuesDetectedChart = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const [
        chartInstance,
        setChartInstance
    ] = useState<Chart<'bar', { issueAlert: IssueAlert, x: string; y: number; }[], string>>()

    /** colors for both chart and table. will be set in chart initialization but will
     * only target lifecycle of table. doing so in chart will trigger an infinite render loop.
    */
    const [chartColors, setChartColors] = useState<string[]>([])

    /** i don't want to duplicate the code snippet so we'll just create
     * this separately. Plus the pseudo code ended up taking up more than 300 lines.
     * we end up having to make each chart a separate file BUT at least it's somewhere else.
     *
     * UPDATE: 9/16/2022. Ended up being one chart instance with multiple switch cases.
     */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** There was an attempt to make a useMemo BUT realized that
     * the lifecycle would be inconsistent. we want a cleanup function
     * before creating the chart instance for every lifecycle.
     *
     * one more thing, only memoize the data on performance leaks.
     * doing so ignoring this reminder led to chart tooltip drawing
     * errors especially when you have useRefs to create visualizations.
     *
     * this occured when memozing the table (an alternative to display
     * data counts).
     *
     * TypeError: Cannot read properties of undefined (reading 'handleEvent')
     * this error above occurs when the dispatches on that onclick event
     * are in the wrong order.
     *
     * Issue with search params where you update the ranges. It
     * will mess up the form data for fetching either o365 details or data id
     * when selecting the first OR last dataset
     * in the charts OR the tables below them.
     */

    /** we already have an array of issueAlert. We don't need a zoom chart for this. */
    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const issueAlerts = data?.data || []
        /** this will only be a categorical bar chart with different colors. */
        let aggreggation : _.Dictionary<IssueAlert[]> = {}
        let graph: Chart<'bar', { issueAlert: IssueAlert, x: string; y: number; }[], string>

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            if (issueAlerts.length >= 2) {
                ranges.start = new Date(issueAlerts[0]._source['@timestamp'])
                ranges.end = new Date(issueAlerts[issueAlerts.length - 1]._source['@timestamp'])
            }

            const fixedInterval = DEFAULT_INTERVAL

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            /** let's group the data here just in case there are records
             * with the same timestamp.
             */

            aggreggation = _.groupBy(issueAlerts, (obj) => {
                for (let index = 0; index < intervals.length; index++) {
                    const value = intervals[index]

                    const intervalOne = new Date(value)
                    const intervalTwo = new Date(intervals[index + 1])
                    if (
                        isValid(intervalTwo) &&
                        isWithinInterval(
                            new Date(obj._source['@timestamp']),
                            {
                                start: intervalOne,
                                end: intervalTwo
                            }
                        )
                    ) {
                        return format(intervalOne, DATE_FORMAT_TIME)
                    }
                }
                console.warn('Timestamp is not within interval')
                return format(new Date(obj._source['@timestamp']), DATE_FORMAT_TIME)
            })

            /** assign colors to be used in both the chart and the table. */
            const backgroundColors = _.map(
                issueAlerts, (_i, index) => CHART_COLORS[index]
            )

            setChartColors(backgroundColors)

            const datasets: typeof graph.data.datasets = [{
                data: _.map(intervals, (interval) => {
                    const found = _.find(aggreggation, (value, key) => {
                        return isValid(new Date(key)) && _.isEqual(
                            format(new Date(key), DATE_FORMAT_TIME),
                            interval
                        )
                    }) || []

                    return {
                        issueAlert: found?.[0] || DEFAULT_ISSUE_ALERT,
                        x: interval,
                        y: found.length
                    }
                }),
                // random color because of each key.
                backgroundColor: backgroundColors,
                normalized: true,
                barThickness: 'flex',
                maxBarThickness: 15,
                barPercentage: 0.7

            }]

            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: _.map(issueAlerts, (obj) => obj._source['@timestamp']),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: true,
                            text: [
                                TEXT.ZERO_ZOOM_LEVEL, '-',
                                format(ranges.start, DATE_FORMAT_TIME), 'to',
                                format(ranges.end, DATE_FORMAT_TIME)
                            ].join(' '),
                            color: stylesheet.mode.fontColor
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                display: false,
                                color: stylesheet.mode.fontColor
                            },
                            /** added stacked: true so that bars
                             * will be more visible in their groups.
                             */
                            stacked: true
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            /** added stacked: true so that bars
                             * will be more visible in their groups.
                             */
                            stacked: true
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        data
    ])

    const DataTable = useMemo(() => {
        const issueAlerts = data?.data || []

        const cellBody = (
            dataObject: IssueAlert['_source'],
            property: keyof IssueAlert['_source']
        ) => {
            let cellContent: IssueAlert['_source'][keyof IssueAlert['_source']] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case '@timestamp':
                    cellContent = format(
                        new Date(dataObject['@timestamp']),
                        DATE_FORMAT_TIME
                    )
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return (
                <div>
                    {cellContent}
                </div>
            )
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        <th></th>
                        {
                            _.map(ISSUES_DETECTED_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'o365Activity-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(issueAlerts, (dataObject, rowIndex, arr) => {
                            return (
                                <tr
                                    key={'o365Activity-tr-' + rowIndex}
                                >

                                    <TableColorCell color={chartColors[rowIndex]} key={[
                                        'tableColorCell-', rowIndex
                                    ].join('')} />

                                    {
                                        _.map(ISSUES_DETECTED_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'o365Activity-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject._source, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        return (
            issueAlerts.length
                ? content
                : ''
        )
    }, [
        data,
        chartInstance,
        chartColors
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            /** removed Container tag to save print ink and transferred
             * to the chart instance
             */
            <div>
                <PageBreakInside className={'row'}>
                    <div className={'col'}>
                        <span className={'d-block mb-2'}>
                            {O365_TEXT.SECTIONS.ISSUES_DETECTED}
                        </span>
                        <Container bgIndex={2} className={'mb-2'}>
                            <canvas ref={chartEl}/>
                        </Container>
                    </div>
                </PageBreakInside>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles['Issues.Detected']
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </div>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.ISSUES_DETECTED}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <>{DataContent}</>
}

IssuesDetectedChart.propTypes = {
    modal: PropTypes.object,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssuesDetectedChart


import {
    ISSUE_HISTORY_COLUMNS,
    MESSAGE as MDR_MESSAGE,
    ISSUE_HISTORY_ROWS
} from '@constants/dashboard/soc/mdr/main'
import {
    assignDelta,
    assignValue,
    hideChartTooltip,
    showChartTooltip
} from '@constants/main/method'
import {
    IssueSeverityValues,
    IssueHistoryResponse
} from '@interfaces/dashboard/soc/mdr/main'
import {
    Container,
    SpinnerContainer,
    ErrorMessage,
    Table
} from '@styles/components'
import { Chart } from 'chart.js'
import _ from 'lodash'
import React, { useMemo } from 'react'

import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>
    chartInstance: Chart<'doughnut', {
        issueCount: number;
    }[], string> | undefined,
    setChartInstance: React.Dispatch<React.SetStateAction<Chart<'doughnut', {
        issueCount: number;
    }[], string> | undefined>>
    issueHistoryData: IssueHistoryResponse| undefined,
    issueHistoryPreviousData: IssueHistoryResponse| undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssueHistoryTable = ({
    chartEl,
    chartInstance,
    setChartInstance,
    issueHistoryData,
    issueHistoryPreviousData,
    isLoading, isSuccess, error
} : ComponentProps) => {
    const DataTable = useMemo(() => {
        const currentData = issueHistoryData?.data || {
            noIssue: 0,
            lowIssue: 0,
            mediumIssue: 0,
            highIssue: 0,
            severeIssue: 0,
            criticalIssue: 0
        }

        const previousData = issueHistoryPreviousData?.data || {
            noIssue: 0,
            lowIssue: 0,
            mediumIssue: 0,
            highIssue: 0,
            severeIssue: 0,
            criticalIssue: 0
        }

        const tableData: {key: IssueSeverityValues, docCount: number, prevDocCount: number}[] = []

        _.forEach(
            currentData
            , (currentVal, currentKey) => {
                tableData.push({
                    key: currentKey as IssueSeverityValues,
                    docCount: currentVal,
                    prevDocCount: _.find(previousData, (_previousValue, previousKey) => {
                        return previousKey === currentKey
                    }) || 0
                })
            })

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(ISSUE_HISTORY_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'issueHistory-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(ISSUE_HISTORY_ROWS, (rowObj, index) => {
                            const key = [
                                'issueHistory-td-', index
                            ].join('')

                            // let's do a find on tableData and get the object we need.
                            const obj = _.find(tableData, (value) => {
                                return value.key === rowObj.value
                            }) || {
                                key: 'noIssue',
                                docCount: 0,
                                prevDocCount: 0
                            }

                            // calculate percentage.
                            const percentage = (
                                (obj.docCount - obj.prevDocCount) /
                             obj.prevDocCount
                            ) * 100
                            let deltaStatus = ''

                            const deltaResult = assignDelta(percentage)
                            switch (deltaResult) {
                                case 'positive' : deltaStatus = 'positive-inverse'; break
                                case 'negative' : deltaStatus = 'negative-inverse'; break
                                default: deltaStatus = deltaResult; break
                            }

                            /** now that we have access to the chart component
                             * we can use the datasets and pass in the chart to create
                             * tooltips.
                             */
                            return (
                                <tr
                                    key={key}
                                    onMouseOver={() => {
                                        showChartTooltip(0, index, chartInstance)
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(chartInstance)
                                    }}
                                >
                                    <td>{rowObj.label}</td>
                                    <td className={'pe-4'}>{
                                        <div className={'row align-items-center'}>
                                            <span className={'col-6'}>
                                                {obj.docCount}
                                            </span>
                                            <span className={[
                                                'col-6 delta',
                                                deltaStatus
                                            ].join(' ')}>
                                                {assignValue(percentage)}
                                            </span>
                                        </div>
                                    }</td>
                                    <td>{obj.prevDocCount}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>
        return content
    }, [
        issueHistoryPreviousData,
        issueHistoryData,
        chartInstance
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.ISSUE_HISTORY}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssueHistoryTable.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssueHistoryTable

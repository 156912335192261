import { RootState } from '@app/store'
import {
    MenuList,
    PageTab,
    SidebarState
} from '@interfaces/main/sidebar'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/*
    abortSignals when called to abort the first time will stay
    that way until a new abortController instance has been created.
*/

const initialState: SidebarState = {
    showSidebar: false,
    activeTab: {
        name: '',
        links: []
    },
    menu: {}
}

export const slice = createSlice({
    name: 'sidebar',
    initialState: initialState,
    reducers: {
        toggleSidebar: (state: SidebarState, action: PayloadAction<boolean>) => {
            state.showSidebar = action.payload
        },
        setActiveTab: (state: SidebarState, action: PayloadAction<PageTab>) => {
            state.activeTab = action.payload
        },
        // initialize abortController at components and pass them to the function,
        setMenu: (state: SidebarState, action: PayloadAction<MenuList>) => {
            state.menu = action.payload
        },
        resetSidebar: (state: SidebarState) => {
            state.activeTab = initialState.activeTab
            state.menu = initialState.menu
            state.showSidebar = initialState.showSidebar
        }
    }
})

export const { toggleSidebar, setActiveTab, setMenu, resetSidebar } = slice.actions

export const selectShowSidebar = (state: RootState) => state.sidebar.showSidebar
export const selectActiveTab = (state: RootState) => state.sidebar.activeTab
export const selectMenu = (state: RootState) => state.sidebar.menu

export default slice.reducer

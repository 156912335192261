import { RootState } from '@app/store'
import { tryParseJSON } from '@constants/main/method'
import { SettingsState } from '@interfaces/main/settings'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

/**
 * An object where it contains user data from a successful login.
 * NOTE: remove the token property from it before setting in. Then add the object to localStorage
 * initial value is a JSON string from localStorage parsed by JSON.
 */

const getFromLocalStorage: () => SettingsState = () => {
    /** Coming from print pdf operations using sessionStorage instead of localStorage.
     * If localStorage token is undefined, get it from sessionStorage otherwise
     * initialize an empty string.
     */
    const userJson = localStorage.getItem('settings') || sessionStorage.getItem('settings')
    /**
     * we need to check if the userJSON is not an empty string,
     * if the string as a parameter in tryParseJSON returns true,
     * BUT also check if the theme and style properties exist.
     */

    if (userJson !== null) {
        const obj = tryParseJSON(userJson)
        if (
            _.has(obj, 'mode') && _.has(obj, 'style')
        ) {
            return obj as unknown as SettingsState
        }
    }

    return {
        mode: 'dark',
        style: 'bluedog'
    }
}

const initialState: SettingsState = getFromLocalStorage()

export const slice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setSettings: (state: SettingsState, action: PayloadAction<SettingsState>) => {
            state.mode = action.payload.mode
            state.style = action.payload.style

            localStorage.setItem('settings', JSON.stringify(action.payload))
        },
        setMode: (state: SettingsState, action: PayloadAction<string>) => {
            state.mode = action.payload
        },
        setStyle: (state: SettingsState, action: PayloadAction<string>) => {
            state.style = action.payload
        },
        resetSettings: (state: SettingsState) => {
            state.mode = initialState.mode
            state.style = initialState.style
            localStorage.removeItem('settings')
        }
    }
})

export const {
    setSettings,
    setMode,
    setStyle,
    resetSettings
} = slice.actions

export const selectMode = (state: RootState) => state.settings.mode
export const selectStyle = (state: RootState) => state.settings.style

export default slice.reducer

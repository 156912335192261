
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetAzureAdMutation,
    useGetFilesAccessMutation,
    useGetFilesMaliciousMutation,
    useGetFilesSharedMutation,
    useGetIssuesDetectedMutation,
    useGetModalHeaderMutation,
    useGetOffice365UsersMutation,
    useGetPolicyRelatedMutation,
    useGetUserActivityMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as O365_MESSAGE
} from '@constants/dashboard/soc/o365/main'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import IssuesDetectedChart from '@features/dashboard/soc/o365/print/IssuesDetectedChart'
import O365BarChart from '@features/dashboard/soc/o365/print/O365BarChart'
import Office365UsersChart from '@features/dashboard/soc/o365/print/Office365UsersChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    FixedCollapsibles,
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/o365/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'
import {
    selectCurrentParams,
    selectFixedCollapsibles,
    setCurrentParams,
    toggleCollapsible
} from '@slices/dashboard/soc/o365/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer
} from '@styles/components'
import {
    differenceInDays,
    format,
    fromUnixTime,
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectO365Main,
    setCardDetails
} from '@slices/main/print/section'

import { useDebouncedCallback } from 'use-debounce'

const O365Modal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectO365Main)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [getAzureAd, getAzureAdMutation] = useGetAzureAdMutation()
    const [getFilesAccess, getFilesAccessMutation] = useGetFilesAccessMutation()
    const [getFilesMalicious, getFilesMaliciousMutation] = useGetFilesMaliciousMutation()
    const [getFilesShared, getFilesSharedMutation] = useGetFilesSharedMutation()
    const [getIssuesDetected, getIssuesDetectedMutation] = useGetIssuesDetectedMutation()
    const [getOffice365Users, getOffice365UsersMutation] = useGetOffice365UsersMutation()
    const [getPolicyRelated, getPolicyRelatedMutation] = useGetPolicyRelatedMutation()
    const [getUserActivity, getUserActivityMutation] = useGetUserActivityMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getAzureAdMutation.error) {
            console.error(getAzureAdMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureAdMutation.error])

    useEffect(() => {
        if (getFilesAccessMutation.error) {
            console.error(getFilesAccessMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getFilesAccessMutation.error])

    useEffect(() => {
        if (getFilesMaliciousMutation.error) {
            console.error(getFilesMaliciousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getFilesMaliciousMutation.error])

    useEffect(() => {
        if (getFilesSharedMutation.error) {
            console.error(getFilesSharedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getFilesSharedMutation.error])

    useEffect(() => {
        if (getIssuesDetectedMutation.error) {
            console.error(getIssuesDetectedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getIssuesDetectedMutation.error])

    useEffect(() => {
        if (getOffice365UsersMutation.error) {
            console.error(getOffice365UsersMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOffice365UsersMutation.error])

    useEffect(() => {
        if (getPolicyRelatedMutation.error) {
            console.error(getPolicyRelatedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPolicyRelatedMutation.error])

    useEffect(() => {
        if (getUserActivityMutation.error) {
            console.error(getUserActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getUserActivityMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetModalRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        // cloneDeep searchParams.ranges for modification.
        const ranges = _.cloneDeep({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })
        if (eventType && eventType === 'Office365.Users') {
            const dayDifference = differenceInDays(
                ranges.end, ranges.start
            )

            if (dayDifference < 30) {
                ranges.start = getUnixTime(sub(ranges.start, { days: 30 }))
            }
        }

        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        dispatch(setCurrentParams({
            ranges: newRanges
        }))

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString()
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureAd = () => {
        const unsubscribeMutation = getAzureAd({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFilesAccess = () => {
        const unsubscribeMutation = getFilesAccess({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFilesMalicious = () => {
        const unsubscribeMutation = getFilesMalicious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFilesShared = () => {
        const unsubscribeMutation = getFilesShared({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssuesDetected = () => {
        const unsubscribeMutation = getIssuesDetected({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOffice365Users = () => {
        const unsubscribeMutation = getOffice365Users({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPolicyRelated = () => {
        const unsubscribeMutation = getPolicyRelated({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetUserActivity = () => {
        const unsubscribeMutation = getUserActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchAzureAdData = () => {
        unsubscribeGetAzureAd()
        let getAzureAdPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureAdPromise = getAzureAd(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureAdPromise && getAzureAdPromise.abort()
        }
    }

    const fetchFileAccessData = () => {
        unsubscribeGetFilesAccess()
        let getFileAccessPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFileAccessPromise = getFilesAccess(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFileAccessPromise && getFileAccessPromise.abort()
        }
    }

    const fetchFileMaliciousData = () => {
        unsubscribeGetFilesMalicious()
        let getFileMaliciousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFileMaliciousPromise = getFilesMalicious(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFileMaliciousPromise && getFileMaliciousPromise.abort()
        }
    }

    const fetchFileSharedData = () => {
        unsubscribeGetFilesShared()
        let getFileSharedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFileSharedPromise = getFilesShared(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFileSharedPromise && getFileSharedPromise.abort()
        }
    }

    const fetchIssuesDetectedData = () => {
        unsubscribeGetIssuesDetected()
        let getIssuesDetectedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getIssuesDetectedPromise = getIssuesDetected(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getIssuesDetectedPromise && getIssuesDetectedPromise.abort()
        }
    }

    const fetchOffice365UsersData = () => {
        unsubscribeGetOffice365Users()
        let getOffice365UsersPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                /** The date range should be at least 30 days.
                 * Can check by date range difference by day count.
                */
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken, 'Office365.Users')
                getOffice365UsersPromise = getOffice365Users(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOffice365UsersPromise && getOffice365UsersPromise.abort()
        }
    }

    const fetchPolicyRelatedData = () => {
        unsubscribeGetPolicyRelated()
        let getPolicyRelatedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getPolicyRelatedPromise = getPolicyRelated(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getPolicyRelatedPromise && getPolicyRelatedPromise.abort()
        }
    }

    const fetchUserActivityData = () => {
        unsubscribeGetUserActivity()
        let getUserActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getUserActivityPromise = getUserActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getUserActivityPromise && getUserActivityPromise.abort()
        }
    }

    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureAdData()
    }, [token.valid])
    useEffect(() => {
        return fetchFileAccessData()
    }, [token.valid])
    useEffect(() => {
        return fetchFileMaliciousData()
    }, [token.valid])
    useEffect(() => {
        return fetchFileSharedData()
    }, [token.valid])
    useEffect(() => {
        return fetchIssuesDetectedData()
    }, [token.valid])
    useEffect(() => {
        return fetchOffice365UsersData()
    }, [token.valid])
    useEffect(() => {
        return fetchPolicyRelatedData()
    }, [token.valid])
    useEffect(() => {
        return fetchUserActivityData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        type Header = ModalHeaderResponse['data'] & { title: string }
        const data: Header = {
            title: dashboardCardDetails?.title || '',
            ...getModalHeaderMutation.data?.data || {
                line_1: dashboardCardDetails?.line_1 || '',
                line_2: dashboardCardDetails?.line_2 || '',
                heading: dashboardCardDetails?.heading || '',
                colorType: dashboardCardDetails?.colorType || 'grey'
            }
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** now we create our charts. */
    const UserActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'User.Activity'}
                data={getUserActivityMutation.data}
                isLoading={getUserActivityMutation.isLoading}
                isSuccess={getUserActivityMutation.isSuccess}
                error={getUserActivityMutation.error}
            />
        </div>
    }, undefined)

    const AzureAD = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Azure.AD'}
                data={getAzureAdMutation.data}
                isLoading={getAzureAdMutation.isLoading}
                isSuccess={getAzureAdMutation.isSuccess}
                error={getAzureAdMutation.error}
            />
        </div>
    }, undefined)

    const Office365Users = useMemo(() => {
        return <div>
            <Office365UsersChart
                data={getOffice365UsersMutation.data}
                isLoading={getOffice365UsersMutation.isLoading}
                isSuccess={getOffice365UsersMutation.isSuccess}
                error={getOffice365UsersMutation.error}
            />
        </div>
    }, undefined)

    const IssuesDetected = useMemo(() => {
        return <div>
            <IssuesDetectedChart
                data={getIssuesDetectedMutation.data}
                isLoading={getIssuesDetectedMutation.isLoading}
                isSuccess={getIssuesDetectedMutation.isSuccess}
                error={getIssuesDetectedMutation.error}
            />
        </div>
    }, undefined)

    const FilesAccess = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Files.Access'}
                data={getFilesAccessMutation.data}
                isLoading={getFilesAccessMutation.isLoading}
                isSuccess={getFilesAccessMutation.isSuccess}
                error={getFilesAccessMutation.error}
            />
        </div>
    }, undefined)

    const FilesShared = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Files.Shared'}
                data={getFilesSharedMutation.data}
                isLoading={getFilesSharedMutation.isLoading}
                isSuccess={getFilesSharedMutation.isSuccess}
                error={getFilesSharedMutation.error}
            />
        </div>
    }, undefined)

    const PolicyRelated = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Policy.Related'}
                data={getPolicyRelatedMutation.data}
                isLoading={getPolicyRelatedMutation.isLoading}
                isSuccess={getPolicyRelatedMutation.isSuccess}
                error={getPolicyRelatedMutation.error}
            />
        </div>
    }, undefined)

    const FilesMalicious = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Files.Malicious'}
                data={getFilesMaliciousMutation.data}
                isLoading={getFilesMaliciousMutation.isLoading}
                isSuccess={getFilesMaliciousMutation.isSuccess}
                error={getFilesMaliciousMutation.error}
            />
        </div>
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getAzureAdMutation.data &&
            getFilesAccessMutation.data &&
            getFilesMaliciousMutation.data &&
            getFilesSharedMutation.data &&
            getIssuesDetectedMutation.data &&
            getOffice365UsersMutation.data &&
            getPolicyRelatedMutation.data &&
            getUserActivityMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getAzureAdMutation.data,
        getFilesAccessMutation.data,
        getFilesMaliciousMutation.data,
        getFilesSharedMutation.data,
        getIssuesDetectedMutation.data,
        getOffice365UsersMutation.data,
        getPolicyRelatedMutation.data,
        getUserActivityMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getAzureAdMutation.error ||
            getFilesAccessMutation.error ||
            getFilesMaliciousMutation.error ||
            getFilesSharedMutation.error ||
            getIssuesDetectedMutation.error ||
            getOffice365UsersMutation.error ||
            getPolicyRelatedMutation.error ||
            getUserActivityMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getAzureAdMutation.error,
        getFilesAccessMutation.error,
        getFilesMaliciousMutation.error,
        getFilesSharedMutation.error,
        getIssuesDetectedMutation.error,
        getOffice365UsersMutation.error,
        getPolicyRelatedMutation.error,
        getUserActivityMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <div className={'min-width-fix'}>
                    {UserActivity}
                </div>

                <div className={'min-width-fix'}>
                    {AzureAD}
                </div>

                <div className={'min-width-fix'}>
                    {Office365Users}
                </div>

                <div className={'min-width-fix'}>
                    {IssuesDetected}
                </div>

                <div className={'min-width-fix'}>
                    {FilesAccess}
                </div>

                <div className={'min-width-fix'}>
                    {FilesShared}
                </div>

                <div className={'min-width-fix'}>
                    {PolicyRelated}
                </div>

                <div className={'min-width-fix'}>
                    {FilesMalicious}
                </div>
            </PrintMargin>

        </div>
    )
}

export default O365Modal

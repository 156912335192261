import { LOGIN_ROUTES } from '@constants/main/routes'
import HomePage from '@features/main/HomePage'
import ForcePasswordPage from '@features/main/login/ForcePasswordPage'
import LoginPage from '@features/main/login/LoginPage'
import LogoutPage from '@features/main/login/LogoutPage'
import MFAPage from '@features/main/login/MFAPage'
import ResetPasswordPage from '@features/main/login/ResetPasswordPage'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    /**
    * a past issue is that during redirect, switch logic is not applied
    * this is because of a switch instance being created not within the
    * codebase of where the routes are also rendered. in this case,
    * even if the Switch component was rendered in index.tsx and this
    * component was a child to that, said issue is shown.
    */
    {
        exact: true,
        path: LOGIN_ROUTES.LOGIN.link,
        component: LoginPage
    },
    {
        exact: true,
        path: LOGIN_ROUTES.LOGOUT.link,
        component: LogoutPage
    },
    {
        exact: true,
        path: LOGIN_ROUTES.MFA.link,
        component: MFAPage
    },
    {
        exact: true,
        path: LOGIN_ROUTES.FORCE_PASSWORD.link,
        component: ForcePasswordPage
    },
    {
        exact: true,
        path: LOGIN_ROUTES.RESET_PASSWORD.link,
        component: ResetPasswordPage
    },
    {
        exact: false,
        path: LOGIN_ROUTES.MAIN.link,
        component: HomePage
    }

]

export default Routes

import { MutationContext } from '@root/MutationProvider'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
/** OTHER FILE IMPORTS */
import { useDeleteFilterMutation } from '@apis/watchdog/soc-data/filter-api'
import { useAppDispatch } from '@app/hook'
import {
    MESSAGE as FILTER_MESSAGE,
    TEXT as FILTER_TEXT
} from '@constants/watchdog/soc-data/filter'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { FilterModal } from '@interfaces/watchdog/soc-data/filter'
import {
    setRefetch
} from '@slices/watchdog/soc-data/filter'
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const FilterDelete = ({ modal, addModal, closeModal } : {
    modal: FilterModal,
    addModal: ActionCreatorWithPayload<FilterModal, string>,
    closeModal: ActionCreatorWithPayload<FilterModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [deleteFilter, deleteFilterMutation] = useDeleteFilterMutation()

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.filterObj) {
            deleteFilter({
                authToken: newToken,
                id: modal.filterObj._id.$oid
            })
        }
    }, [
        modal.filterObj
    ])

    const SubmitButton = useMemo(() => {
        const buttonContent = deleteFilterMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{FILTER_TEXT.DELETE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : FILTER_TEXT.DELETE.FORM.SUBMIT_BUTTON
        return (
            <Button
                onClick={() => {
                    submitData()
                }}
                mode={'primary'}
                disabled={deleteFilterMutation.isLoading}
            >{buttonContent}</Button>
        )
    }, [deleteFilterMutation.isLoading])

    useEffect(() => {
        if (deleteFilterMutation.data) {
            const data = deleteFilterMutation.data
            if (data.status) {
                toast.success(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
                /** what you usually do is disable the validation, refetch the data
                 * and empty the selected object
                 */
                dispatch(closeModal(modal))
                dispatch(setRefetch(true))
            } else {
                toast.error(
                    FILTER_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [deleteFilterMutation.data])

    useEffect(() => {
        if (deleteFilterMutation.error) {
            console.error(deleteFilterMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [deleteFilterMutation.error])

    return (
        <div>
            <h5>{FILTER_TEXT.DELETE.TITLE}</h5>
            <small className={'d-block my-2'}>
                {FILTER_TEXT.DELETE.MESSAGE}
            </small>
            <div className={'row justify-content-end'}>
                <div className={'col-auto mb-2 mb-md-0 text-center'}>
                    {SubmitButton}
                </div>
                <div className={'col-auto'}>
                    <Button mode={'secondary'} onClick={() => {
                        dispatch(closeModal(modal))
                    }}>{'Close'}</Button>
                </div>
            </div>
        </div>
    )
}

export default FilterDelete


import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetIssueAlertedMutation,
    useGetIssueCountMutation,
    useGetIssueHistoryMutation,
    useGetIssueTableMutation,
    useGetModalHeaderMutation
} from '@apis/dashboard/soc/mdr-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as MDR_MESSAGE,
    TEXT as MDR_TEXT
} from '@constants/dashboard/soc/mdr/main'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import IssueHistoryChart from '@features/dashboard/soc/mdr/IssueHistoryChart'
import IssueHistoryTable from '@features/dashboard/soc/mdr/IssueHistoryTable'
import IssueAlertedTable from '@features/dashboard/soc/mdr/print/IssueAlertedTable'
import IssueCountChart from '@features/dashboard/soc/mdr/print/IssueCountChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    FixedCollapsibles,
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/mdr/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'
import {
    selectCurrentParams,
    selectFixedCollapsibles,
    setCurrentParams,
    toggleCollapsible
} from '@slices/dashboard/soc/mdr/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer
} from '@styles/components'
import {
    differenceInSeconds,
    format,
    fromUnixTime,
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectMdrMain,
    setCardDetails
} from '@slices/main/print/section'

import { Chart } from 'chart.js'
import { useDebouncedCallback } from 'use-debounce'

const MDRModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectMdrMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [getIssueHistoryPrevious, getIssueHistoryPreviousMutation] = useGetIssueHistoryMutation()
    const [getIssueHistory, getIssueHistoryMutation] = useGetIssueHistoryMutation()
    // reason for having ip address is to filter out new and reappear results.
    const [getIssueAlerted, getIssueAlertedMutation] = useGetIssueAlertedMutation()
    const [getIssueCount, getIssueCountMutation] = useGetIssueCountMutation()
    const [getIssueTable, getIssueTableMutation] = useGetIssueTableMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getIssueHistoryMutation.error) {
            console.error(getIssueHistoryMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getIssueHistoryMutation.error])

    useEffect(() => {
        if (getIssueHistoryPreviousMutation.error) {
            console.error(getIssueHistoryPreviousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getIssueHistoryPreviousMutation.error])

    useEffect(() => {
        if (getIssueAlertedMutation.error) {
            console.error(getIssueAlertedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getIssueAlertedMutation.error])

    useEffect(() => {
        if (getIssueCountMutation.error) {
            console.error(getIssueCountMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getIssueCountMutation.error])

    useEffect(() => {
        if (getIssueTableMutation.error) {
            console.error(getIssueTableMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getIssueTableMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueTable = () => {
        const unsubscribeMutation = getIssueTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueHistory = () => {
        const unsubscribeMutation = getIssueHistory({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueHistoryPrevious = () => {
        const unsubscribeMutation = getIssueHistoryPrevious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueAlerted = () => {
        const unsubscribeMutation = getIssueAlerted({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssueCount = () => {
        const unsubscribeMutation = getIssueCount({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalHeader()
        unsubscribeGetIssueTable()
        unsubscribeGetIssueHistory()
        unsubscribeGetIssueHistoryPrevious()
        unsubscribeGetIssueAlerted()
        unsubscribeGetIssueCount()

        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueHistoryPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueHistoryPreviousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueAlertedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssueCountPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                        dashboardMain.card &&
                        dashboardMain.searchParams
                    ) {
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            in_face: dashboardMain.card.inFace,
                            q: '',
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalHeaderPromise = getModalHeader(requestData)

                        getIssueHistoryPromise = getIssueHistory(requestData)

                        /** get the difference of the current ranges as preqrequisite to
                         * the previous range api call.
                         */

                        const difference = Math.abs(differenceInSeconds(
                            fromUnixTime(newRanges.start), fromUnixTime(newRanges.end)
                        ))
                        getIssueHistoryPreviousPromise = getIssueHistoryPrevious({
                            ...requestData,
                            time_from: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.start),
                                    { seconds: difference }
                                )
                            ).toString(),
                            time_to: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.end),
                                    { seconds: difference }
                                )
                            ).toString()
                        })
                        getIssueAlertedPromise = getIssueAlerted(requestData)
                        getIssueCountPromise = getIssueCount(requestData)
                        getIssueTablePromise = getIssueTable(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
            getIssueTablePromise && getIssueTablePromise.abort()
            getIssueHistoryPromise && getIssueHistoryPromise.abort()
            getIssueHistoryPreviousPromise && getIssueHistoryPreviousPromise.abort()
            getIssueAlertedPromise && getIssueAlertedPromise.abort()
            getIssueCountPromise && getIssueCountPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        type Header = ModalHeaderResponse['data'] & { title: string }
        const data: Header = {
            title: dashboardCardDetails?.title || '',
            ...getModalHeaderMutation.data?.data || {
                line_1: dashboardCardDetails?.line_1 || '',
                line_2: dashboardCardDetails?.line_2 || '',
                heading: dashboardCardDetails?.heading || '',
                colorType: dashboardCardDetails?.colorType || 'grey'
            }
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    const issueHistoryChartEl = useRef<HTMLCanvasElement>(null)
    /** moving chart instance to useState so other components can access
     * chart data.
     */
    const [
        issueHistoryChartInstance,
        setIssueHistoryChartInstance
    ] = useState<Chart<'doughnut', {issueCount: number}[], string>>()

    const IssueHistory = useMemo(() => {
        return <div className={'row align-items-center'}>
            <div className={'col-8'}>
                <IssueHistoryTable
                    chartEl={issueHistoryChartEl}
                    chartInstance={issueHistoryChartInstance}
                    setChartInstance={setIssueHistoryChartInstance}
                    issueHistoryData={getIssueHistoryMutation.data}
                    issueHistoryPreviousData={getIssueHistoryPreviousMutation.data}
                    isLoading={getIssueHistoryMutation.isLoading}
                    isSuccess={getIssueHistoryMutation.isSuccess}
                    error={getIssueHistoryMutation.error}
                />
            </div>
            <div className={'col-4'}>
                <IssueHistoryChart
                    chartEl={issueHistoryChartEl}
                    chartInstance={issueHistoryChartInstance}
                    setChartInstance={setIssueHistoryChartInstance}
                    issueHistoryData={getIssueHistoryMutation.data}
                    issueHistoryPreviousData={getIssueHistoryPreviousMutation.data}
                    isLoading={getIssueHistoryMutation.isLoading}
                    isSuccess={getIssueHistoryMutation.isSuccess}
                    error={getIssueHistoryMutation.error}
                />
            </div>
        </div>
    }, undefined)

    const IssueAlerted = useMemo(() => {
        return (
            <IssueAlertedTable
                data={getIssueAlertedMutation.data}
                isLoading={getIssueAlertedMutation.isLoading}
                isSuccess={getIssueAlertedMutation.isSuccess}
                error={getIssueAlertedMutation.error}
            />
        )
    }, undefined)

    const issueCountChartEl = useRef<HTMLCanvasElement>(null)
    const [
        issueCountChartInstance,
        setIssueCountChartInstance
    ] = useState<Chart<'bar', number[], string>>()

    const IssueCount = useMemo(() => {
        return (
            <IssueCountChart
                chartEl={issueCountChartEl}
                chartInstance={issueCountChartInstance}
                setChartInstance={setIssueCountChartInstance}
                issueCountData={getIssueCountMutation.data}
                isLoading={getIssueCountMutation.isLoading}
                isSuccess={getIssueCountMutation.isSuccess}
                error={getIssueCountMutation.error}
            />
        )
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getIssueHistoryPreviousMutation.data &&
            getIssueHistoryMutation.data &&
            getIssueAlertedMutation.data &&
            getIssueCountMutation.data &&
            getIssueTableMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getIssueHistoryPreviousMutation.data,
        getIssueHistoryMutation.data,
        getIssueAlertedMutation.data,
        getIssueCountMutation.data,
        getIssueTableMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getIssueHistoryPreviousMutation.error ||
            getIssueHistoryMutation.error ||
            getIssueAlertedMutation.error ||
            getIssueCountMutation.error ||
            getIssueTableMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getIssueHistoryPreviousMutation.error,
        getIssueHistoryMutation.error,
        getIssueAlertedMutation.error,
        getIssueCountMutation.error,
        getIssueTableMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <PageBreakInside className={'min-width-fix'}>
                    <span className={'d-inline-block mb-2'}>
                        {MDR_TEXT.SECTIONS.ISSUE_HISTORY}
                    </span>
                    {IssueHistory}
                </PageBreakInside>

                <PageBreakInside className={'min-width-fix'}>
                    <span className={'d-inline-block mb-2'}>
                        {MDR_TEXT.SECTIONS.ISSUES_ALERTED}
                    </span>
                    {IssueAlerted}
                </PageBreakInside>

                <div className={'min-width-fix'}>
                    {IssueCount}
                </div>

            </PrintMargin>

        </div>
    )
}

export default MDRModal

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { LOGIN_ROUTES } from '@constants/main/routes'
import SidebarComponent from '@features/main/SidebarComponent'
import dashboardRoutes from '@routes/dashboard/dashboard-routes'
import profileRoutes from '@routes/main/profile-routes'
import orderRoutes from '@routes/watchdog/account-data/order-routes'
import partnerRoutes from '@routes/watchdog/account-data/partner-routes'
import boxRoutes from '@routes/watchdog/configuration/box-routes'
import deviceConfigRoutes from
    '@routes/watchdog/configuration/device-config-routes'
import eventRoutes from '@routes/watchdog/soc-data/event-routes'
import filterRoutes from '@routes/watchdog/soc-data/filter-routes'
import flowRoutes from '@routes/watchdog/soc-data/flow-routes'
import mdrRoutes from '@routes/dashboard/soc/mdr-routes'
import o365Routes from '@routes/dashboard/soc/o365-routes'
import azureRoutes from '@routes/dashboard/soc/azure-routes'
import printRoutes from '@routes/main/print-routes'
import complianceRoutes from '@routes/watchdog/virtual-ciso/compliance-questionnaire-routes'
import questionnaireInfoRoutes from '@routes/watchdog/virtual-ciso/questionnaire-information-routes'
import { history } from '@slices/main/router'
import {
    selectShowSidebar,
    toggleSidebar
} from '@slices/main/sidebar'
import { selectToken } from '@slices/main/token'
import {
    ContentColumn,
    HeaderStyledComponents as Header
} from '@styles/components'
import {
    ConnectedRouter,
    push
} from 'connected-react-router'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import * as Ri from 'react-icons/ri'
import {
    Route,
    Switch
} from 'react-router'

import {
    selectHomeMain
} from '@slices/main/print/section'
import NavigationBar from '@features/main/NavigationBar'

const HomePage = () => {
    const token = useAppSelector(selectToken)
    const showSidebar = useAppSelector(selectShowSidebar)
    const print = useAppSelector(selectHomeMain)
    const dispatch = useAppDispatch()

    const routes = useMemo(() => {
        const routes = _.concat(
            [],
            profileRoutes,
            partnerRoutes,
            orderRoutes,
            filterRoutes,
            eventRoutes,
            flowRoutes,
            boxRoutes,
            deviceConfigRoutes,
            dashboardRoutes,
            mdrRoutes,
            o365Routes,
            azureRoutes,
            printRoutes,
            complianceRoutes,
            questionnaireInfoRoutes
        )

        return (
            <Switch>
                {
                    routes.map((routeObj, index) => {
                        return (
                            <Route
                                key={['homeRoute-', index].join('')}
                                exact={routeObj.exact}
                                path={routeObj.path}
                                component={routeObj.component}
                            />
                        )
                    })
                }
            </Switch>
        )
    }, [])

    const sidebar = useMemo(() => {
        if (token.value) return <SidebarComponent/>
    }, [token, print])

    const headerRow = useMemo(() => {
        return <Header.Container className={'col-auto'}>
            <div className={'row justify-content-between'}>
                {/* show expand sidebar button at smaller sizes. */}
                <div
                    className={'col-auto'}
                    onClick={() => {
                        dispatch(toggleSidebar(!showSidebar))
                    }}
                >
                    {showSidebar ? <Ri.RiMenu3Line /> : <Ri.RiMenu2Line />}
                </div>
                <div className={'col-auto'} onClick={() => {
                    dispatch(push(LOGIN_ROUTES.LOGOUT.link))
                }}>
                    {/* logout button */}
                    <FaSignOutAlt />
                </div>
            </div>
        </Header.Container>
    }, [showSidebar, print])

    return (
        /** show the navigation bar if the deviceId or the reportId is
         * missing in the print state. */
        <div>{!print.isPrinting
            ? (
                <div className={'container-fluid'}>
                    {/** NOTE: there can be specific screen sizes that wraps the content column
                     * will apply flex-nowrap class as a precaution but it will overlap based on
                     * the content. overflow-hidden is also assigned here because of wide tables
                    * especially with its own overflow */}
                    <div className={'row h-100'}>
                        { /* Sidebar. don't render if user is not validated
                        and/or print mode is true */ }
                        { sidebar }

                        { /* we just want to render the print component without any parents.
                        bug found where not everything was printed as a result of height
                        properties and scrolls. */ }
                        <ContentColumn toggle={showSidebar} className={'col'}>

                            <div className={'row flex-column'}>
                                {/* add a header bar. */}
                                {headerRow}
                                {/* we are brining back the navigation bar again. */}
                                <NavigationBar />
                                {/* <Home.Row className="row py-2"> */}
                                <div className={'col'}>
                                    <ConnectedRouter history={history}>
                                        {routes}
                                    </ConnectedRouter>
                                </div>
                            </div>

                        </ContentColumn>
                    </div>
                </div>
            )
            : (
                <ConnectedRouter history={history}>
                    {routes}
                </ConnectedRouter>
            )}
        </div>
    )
}

export default HomePage

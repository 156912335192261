
import {
    useGetExchangeActivityMutation,
    useGetGeneralActivityMutation,
    useGetOperationsActivityMutation,
    useGetOffice365EventsMutation,
    useGetSecurityCenterActivityMutation,
    useGetSharepointActivityMutation,
    useGetWorkloadActivityMutation,
    useGetLoginLocationsMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    CHART_ZOOM,
    MESSAGE as MONITOR_MESSAGE,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    INITIAL_SEARCH_PARAMS,
    TEXT as O365_TEXT
} from '@constants/dashboard/soc/o365/detailedDashboard/main'
import {
    getLocalRanges,
    getUtcRanges,
    sendUnavailableModalMessage,
    tryParseJSON,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    GetDetailedDashboardRequest,
    O365DetailedSearchParams
} from '@interfaces/dashboard/soc/o365/detailedDashboard/main'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectPrintOptions,
    selectSearchParams,
    selectTableData,
    setEndDate,
    selectFixedCollapsibles,
    setLogo,
    setRefetch,
    setStartDate,
    setLocalStorage,
    selectChartZooms,
    resetO365Detailed,
    selectModals,
    toggleCollapsible,
    setChartZoomLevel,
    closeModal,
    removeModal,
    setCurrentParams,
    addModal,
    selectCurrentParams,
    removeBool,
    removeBoolList,
    setChartZoom,
    setTotalRecords,
    setIsComplete,
    setScrollId,
    setMapData,
    selectMapData,
    setQ,
    selectDashboardData,
    setExchangeActivityData,
    setGeneralActivityData,
    setOperationsActivityData,
    setOffice365EventsData,
    setSecurityCenterActivityData,
    setSharepointActivityData,
    setWorkloadActivityData,
    setLoginLocationsData,
    setSearchParams
} from '@slices/dashboard/soc/o365/detailedDashboard/main'
import { selectToken } from '@slices/main/token'
import {
    Button,
    CircularProgressContainer,
    CollapsibleText,
    Container,
    DetailedDashboardLinks,
    FilterCell,
    MapInfoContainer,
    SearchRow,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaFileCsv,
    FaFilePdf,
    FaInfoCircle,
    FaMinus,
    FaPlus,
    FaSearch,
    FaSearchMinus,
    FaTimes
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import Modal from 'react-responsive-modal'
import O365DataID from '@features/dashboard/soc/o365/O365DataID'
import O365Details from '@features/dashboard/soc/o365/O365Details'
import { AiOutlineClose } from 'react-icons/ai'
import O365BarChart from
    '@features/dashboard/soc/o365/detailed-dashboard/main/O365BarChart'
import {
    LuceneQuery
} from '@interfaces/dashboard/monitor'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import { O365_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import { selectRouter } from '@slices/main/router'
import O365EventsDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/main/O365EventsDataTable'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'
import { CircularProgressbar } from 'react-circular-progressbar'
import LoginLocationsMap from
    '@features/dashboard/soc/o365/detailed-dashboard/main/LoginLocationsMap'
import { Buffer } from 'buffer'
import flatten from 'flat'
import DoEscalate from '@features/watchdog/soc-data/event/DoEscalate'
import { EventModal } from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const O365Main = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const dispatch = useAppDispatch()
    const router = useAppSelector(selectRouter)

    const token = useAppSelector(selectToken)

    /** we will always have a url parameter for the deviceid so IF and
     * only IF the deviceid is falsy, then we will use this value.
     */
    const params = useParams<{deviceid: string }>()

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const tableData = useAppSelector(selectTableData)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const modals = useAppSelector(selectModals)
    const chartZooms = useAppSelector(selectChartZooms)
    const mapData = useAppSelector(selectMapData)
    const dashboardData = useAppSelector(selectDashboardData)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    const [getExchangeActivity, getExchangeActivityMutation] = useGetExchangeActivityMutation()
    const [getGeneralActivity, getGeneralActivityMutation] = useGetGeneralActivityMutation()
    const [
        getOperationsActivity,
        getOperationsActivityMutation
    ] = useGetOperationsActivityMutation()
    const [getOffice365Events, getOffice365EventsMutation] = useGetOffice365EventsMutation()
    const [
        getSecurityCenterActivity,
        getSecurityCenterActivityMutation
    ] = useGetSecurityCenterActivityMutation()
    const [
        getSharepointActivity,
        getSharepointActivityMutation
    ] = useGetSharepointActivityMutation()
    const [getWorkloadActivity, getWorkloadActivityMutation] = useGetWorkloadActivityMutation()
    const [getLoginLocations, getLoginLocationsMutation] = useGetLoginLocationsMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    /** removed because deviceid alone is not enough to
     * query the detailed dashboard.
     */
    // useEffect(() => {
    //     // check if deviceid in searchParams is falsy.
    //     if (!(searchParams.card.deviceid) && params.deviceid) {
    //         dispatch(setDeviceId(params.deviceid))
    //     }
    // }, [params])

    useEffect(() => {
        /** we retrieve the localStorage key */
        const userJson = localStorage.getItem('o365-db-main-search-params')

        if (userJson !== null) {
            const result = tryParseJSON(userJson) as unknown as
            Omit<O365DetailedSearchParams, 'refetch'>[]

            /** check if the variable is an array. */
            if (_.isArray(result)) {
                /** then we find the object using the deviceid as our conditional check */
                const found = _.find(result, (obj) => {
                    return obj.card.deviceid === params.deviceid
                })

                if (found) {
                    /**
                     * once found, we want to check if the object has those keys.
                     * without this check, and because those properties are set
                     * to required, render crashes will occur.
                     *
                     * Update at October 7, 2022: Attempted to add nested object properties
                     * to strictly check the data from localStorage. It will work with maxDepth 2.
                     *
                     */

                    const requiredKeys = _.keys(
                        flatten(
                            // exclude refetch property from checking.
                            _.omit(INITIAL_SEARCH_PARAMS, ['refetch', 'boolList'])
                        )
                    )

                    if (_.every(
                        requiredKeys,
                        _.partial(
                            _.has,
                            flatten(found, { maxDepth: 2 })
                        ))
                    ) {
                        /** make sure you include the default refetch values. */
                        dispatch(setSearchParams({
                            ...found,
                            refetch: INITIAL_SEARCH_PARAMS.refetch
                        }))
                    } else {
                        // display warning message that the found
                        // object is missing some properties that
                        // the component might crash.
                        toast.warning(
                            MONITOR_MESSAGE.MISSING_KEYS_ON_CARD,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                } else {
                    // otherwise, display error message that none was found
                    // and should go select a dashboard card from monitors.
                    toast.error(
                        MONITOR_MESSAGE.MISSING_CARD,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                }
            } else {
                toast.error(
                    MONITOR_MESSAGE.INCORRECT_DETAILED_DASHBOARD_DATA,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [params])
    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getExchangeActivityMutation.error) {
            console.error(getExchangeActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Exchange.Activity',
                value: false
            }))
        }
    }, [getExchangeActivityMutation.error])

    useEffect(() => {
        if (getGeneralActivityMutation.error) {
            console.error(getGeneralActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'General.Activity',
                value: false
            }))
        }
    }, [getGeneralActivityMutation.error])

    useEffect(() => {
        if (getOperationsActivityMutation.error) {
            console.error(getOperationsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Operations.Activity',
                value: false
            }))
        }
    }, [getOperationsActivityMutation.error])

    useEffect(() => {
        if (getOffice365EventsMutation.error) {
            console.error(getOffice365EventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Office365.Events',
                value: false
            }))
        }
    }, [getOffice365EventsMutation.error])

    useEffect(() => {
        if (getSecurityCenterActivityMutation.error) {
            console.error(getSecurityCenterActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SecurityCenter.Activity',
                value: false
            }))
        }
    }, [getSecurityCenterActivityMutation.error])

    useEffect(() => {
        if (getSharepointActivityMutation.error) {
            console.error(getSharepointActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Sharepoint.Activity',
                value: false
            }))
        }
    }, [getSharepointActivityMutation.error])

    useEffect(() => {
        if (getWorkloadActivityMutation.error) {
            console.error(getWorkloadActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Workload.Activity',
                value: false
            }))
        }
    }, [getWorkloadActivityMutation.error])

    useEffect(() => {
        if (getLoginLocationsMutation.error) {
            console.error(getLoginLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Login.Locations',
                value: false
            }))
        }
    }, [getLoginLocationsMutation.error])

    /** useEffects to set the data. */

    /** a useEffect for searchParams to update the localStorage item. Changed dependency
     * to currentParams so when the page refreshes, you are using parameters
     * from the latest search instance.
     */
    useEffect(() => {
        /** BUG FOUND at October 11, 2021: default search params
         * on mount is also being added to localStorage. kindly
         * avoid this by checking if service type and inface
         * is lengthy.
         *
         * Also utc issues because of date ranges. Convert to local
         * before setting into localStorage
         */

        if (currentParams.card.deviceid && currentParams.card.details.title) {
            const oldRanges = getLocalRanges({
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            })

            dispatch(setLocalStorage({
                ...currentParams,
                ranges: oldRanges
            }))
        }
    }, [currentParams])

    /** can also be from a modal card. */
    /** these are now separate fetchData scripts per client's requests. */
    const createRequestData: (newToken: string) => Omit<GetDetailedDashboardRequest, 'event_type'>
    & TokenAuth = (newToken) => {
        const newRanges = getUtcRanges(searchParams.ranges)

        const should: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => obj.not),
            turnIntoLuceneQuery)
        /**
         * Issue with search params where you update the ranges and q. It
         * will mess up the form data for fetching either o365 details or data id
         * when selecting the first OR last dataset
         * in the charts OR the tables below them.
         *
         * This can also confuse users who changed the searchParams ranges but
         * didn't execute fetch api calls. To fix this, set a dispatch where
         * you are setting the current range.
         */

        return {
            authToken: newToken,
            deviceid: searchParams.card.deviceid,
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: Buffer
                .from(searchParams.q)
                .toString('base64'),
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        const newRanges = getUtcRanges(searchParams.ranges)

        dispatch(setCurrentParams({
            ranges: newRanges,
            q: searchParams.q,
            card: searchParams.card,
            boolList: searchParams.boolList
        }))
    }

    /** i'm glad we check for searchParams.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetExchangeActivity = () => {
        const unsubscribeMutation = getExchangeActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeActivityData(undefined))
    }

    const unsubscribeGetOperationsActivity = () => {
        const unsubscribeMutation = getOperationsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setOperationsActivityData(undefined))
    }

    const unsubscribeGetLoginLocations = () => {
        const unsubscribeMutation = getLoginLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setLoginLocationsData(undefined))
    }

    const unsubscribeGetGeneralActivity = () => {
        const unsubscribeMutation = getGeneralActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setGeneralActivityData(undefined))
    }

    const unsubscribeGetSecurityCenterActivity = () => {
        const unsubscribeMutation = getSecurityCenterActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSecurityCenterActivityData(undefined))
    }

    const unsubscribeGetSharepointActivity = () => {
        const unsubscribeMutation = getSharepointActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointActivityData(undefined))
    }

    const unsubscribeGetWorkloadActivity = () => {
        const unsubscribeMutation = getWorkloadActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setWorkloadActivityData(undefined))
    }

    const unsubscribeGetOffice365Events = () => {
        const unsubscribeMutation = getOffice365Events({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setOffice365EventsData(undefined))
    }

    const fetchExchangeActivityData = () => {
        unsubscribeGetExchangeActivity()
        let getExchangeActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeActivityPromise = getExchangeActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeActivityPromise && getExchangeActivityPromise.abort()
        }
    }

    const fetchOperationsActivityData = () => {
        unsubscribeGetOperationsActivity()
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        const unsubscribeMutation = getOperationsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()

        let getOperationsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getOperationsActivityPromise = getOperationsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOperationsActivityPromise && getOperationsActivityPromise.abort()
        }
    }

    const fetchLoginLocationsData = () => {
        unsubscribeGetLoginLocations()
        let getLoginLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getLoginLocationsPromise = getLoginLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getLoginLocationsPromise && getLoginLocationsPromise.abort()
        }
    }

    const fetchGeneralActivityData = () => {
        unsubscribeGetGeneralActivity()
        let getGeneralActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getGeneralActivityPromise = getGeneralActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getGeneralActivityPromise && getGeneralActivityPromise.abort()
        }
    }

    const fetchSecurityCenterActivityData = () => {
        unsubscribeGetSecurityCenterActivity()
        let getSecurityCenterActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSecurityCenterActivityPromise = getSecurityCenterActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSecurityCenterActivityPromise && getSecurityCenterActivityPromise.abort()
        }
    }

    const fetchSharepointActivityData = () => {
        unsubscribeGetSharepointActivity()
        let getSharepointActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointActivityPromise = getSharepointActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointActivityPromise && getSharepointActivityPromise.abort()
        }
    }

    const fetchWorkloadActivityData = () => {
        unsubscribeGetWorkloadActivity()
        let getWorkloadActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getWorkloadActivityPromise = getWorkloadActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getWorkloadActivityPromise && getWorkloadActivityPromise.abort()
        }
    }

    const fetchOffice365EventsData = () => {
        unsubscribeGetOffice365Events()
        let getOffice365EventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getOffice365EventsPromise = getOffice365Events(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOffice365EventsPromise && getOffice365EventsPromise.abort()
        }
    }

    /** on token revalidation and if the searchParams.card dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */

    useEffect(() => {
        if (!dashboardData['Exchange.Activity']) {
            return fetchExchangeActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Operations.Activity']) {
            return fetchOperationsActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Login.Locations']) {
            return fetchLoginLocationsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['General.Activity']) {
            return fetchGeneralActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SecurityCenter.Activity']) {
            return fetchSecurityCenterActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Sharepoint.Activity']) {
            return fetchSharepointActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Workload.Activity']) {
            return fetchWorkloadActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Office365.Events']) {
            return fetchOffice365EventsData()
        }
    }, [token.valid, searchParams.card])

    /**
     * when component is mounted, do not execute calls if there is already
     * dashboard data.
     *
     * detailed dashboard soc has slowdowns when navigating AND refreshing.
     * fix is to empty the data when refetch is true.
     */

    useEffect(() => {
        if (searchParams.refetch['Exchange.Activity']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Exchange.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchExchangeActivityData()
        }
    }, [searchParams.refetch['Exchange.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getExchangeActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Exchange.Activity']) {
                dispatch(setRefetch({
                    key: 'Exchange.Activity',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setExchangeActivityData(
                getExchangeActivityMutation.data
            ))
        }

        return () => {
            isMounted = false
        }
    }, [getExchangeActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Operations.Activity']) {
            dispatch(setChartZoom({
                key: 'Operations.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchOperationsActivityData()
        }
    }, [searchParams.refetch['Operations.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getOperationsActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Operations.Activity']) {
                dispatch(setRefetch({
                    key: 'Operations.Activity',
                    value: false
                }))
            }
            dispatch(setOperationsActivityData(
                getOperationsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getOperationsActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Login.Locations']) {
            /** don't forget to empty the mapData. */
            dispatch(setTotalRecords(0))
            dispatch(setIsComplete(false))
            dispatch(setScrollId(''))
            dispatch(setMapData([]))
            return fetchLoginLocationsData()
        }
    }, [searchParams.refetch['Login.Locations']])

    useEffect(() => {
        let isMounted = true
        if (getLoginLocationsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Login.Locations']) {
                dispatch(setRefetch({
                    key: 'Login.Locations',
                    value: false
                }))
            }
            dispatch(setLoginLocationsData(
                getLoginLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getLoginLocationsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['General.Activity']) {
            dispatch(setChartZoom({
                key: 'General.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchGeneralActivityData()
        }
    }, [searchParams.refetch['General.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getGeneralActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['General.Activity']) {
                dispatch(setRefetch({
                    key: 'General.Activity',
                    value: false
                }))
            }
            dispatch(setGeneralActivityData(
                getGeneralActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getGeneralActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SecurityCenter.Activity']) {
            dispatch(setChartZoom({
                key: 'SecurityCenter.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSecurityCenterActivityData()
        }
    }, [searchParams.refetch['SecurityCenter.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getSecurityCenterActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SecurityCenter.Activity']) {
                dispatch(setRefetch({
                    key: 'SecurityCenter.Activity',
                    value: false
                }))
            }
            dispatch(setSecurityCenterActivityData(
                getSecurityCenterActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSecurityCenterActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Sharepoint.Activity']) {
            dispatch(setChartZoom({
                key: 'Sharepoint.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSharepointActivityData()
        }
    }, [searchParams.refetch['Sharepoint.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getSharepointActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Sharepoint.Activity']) {
                dispatch(setRefetch({
                    key: 'Sharepoint.Activity',
                    value: false
                }))
            }
            dispatch(setSharepointActivityData(
                getSharepointActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Workload.Activity']) {
            dispatch(setChartZoom({
                key: 'Workload.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchWorkloadActivityData()
        }
    }, [searchParams.refetch['Workload.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getWorkloadActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Workload.Activity']) {
                dispatch(setRefetch({
                    key: 'Workload.Activity',
                    value: false
                }))
            }
            dispatch(setWorkloadActivityData(
                getWorkloadActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getWorkloadActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Office365.Events']) {
            dispatch(setChartZoom({
                key: 'Office365.Events',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchOffice365EventsData()
        }
    }, [searchParams.refetch['Office365.Events']])

    useEffect(() => {
        let isMounted = true
        if (getOffice365EventsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Office365.Events']) {
                dispatch(setRefetch({
                    key: 'Office365.Events',
                    value: false
                }))
            }
            dispatch(setOffice365EventsData(
                getOffice365EventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getOffice365EventsMutation.isSuccess])

    /** if searchParams change, set all refetch values to true. */

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    /** refresh button where all fetchData scripts are executed
     * add a tippy warning just incase.
     */
    const RefreshWarningContent = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            dispatch(setRefetch({
                key: 'Exchange.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'General.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Login.Locations',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Office365.Events',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Operations.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SecurityCenter.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Sharepoint.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Workload.Activity',
                value: true
            }))
        }

        return <div>
            <div className={'row'}>
                <small className={'col'}>
                    {MONITOR_MESSAGE.DETAILED_DASHBOARD_REFRESH_ALL}
                </small>
            </div>
            <div className={'row flex-row-reverse mt-1'}>
                <div className={'col-auto'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        onClick={confirmClickEvent}
                    >{TEXT.YES}</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        mode={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            toggleConfirm(false)
                        }}
                    >{TEXT.NO}</Button>
                </div>
            </div>
        </div>
    }, [showConfirm])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getExchangeActivityMutation.isLoading ||
            getGeneralActivityMutation.isLoading ||
            getOperationsActivityMutation.isLoading ||
            getOffice365EventsMutation.isLoading ||
            getSecurityCenterActivityMutation.isLoading ||
            getSharepointActivityMutation.isLoading ||
            getWorkloadActivityMutation.isLoading ||
            getLoginLocationsMutation.isLoading

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={RefreshWarningContent}
                onClickOutside={() => toggleConfirm(false)}
            >
                <span
                    className={[
                        'icon mb-2 d-inline-block ms-2',
                        disableRefresh ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }} >
                    <MdRefresh />
                </span>
            </Tippy>
        )
    }, [
        showConfirm,
        RefreshWarningContent,
        getExchangeActivityMutation,
        getGeneralActivityMutation,
        getOperationsActivityMutation,
        getOffice365EventsMutation,
        getSecurityCenterActivityMutation,
        getSharepointActivityMutation,
        getWorkloadActivityMutation,
        getLoginLocationsMutation
    ])

    /** csv exports detailed dashboard event data. */
    const CsvExportButton = useMemo(() => {
        const eventsData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            currentParams.card.service_type,
            '_', currentParams.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = eventsData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                filename={filename}
                onClick={() => {
                    if (disableExport) return false
                }}
                data={eventsData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        currentParams.card
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={O365_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {O365_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        /** there will be some reminders for printing reports
         * with the filter and search logic specific to detailed dashboards.
         * They will be defined in the monitor constants file.
         */

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getOffice365EventsMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <Text size={'sm'}>
                {MONITOR_TEXT.DETAILED_DASHBOARD.PRINT_REMINDERS}
            </Text>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */
                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: searchParams.card.deviceid,
                                        inFace: searchParams.card.in_face,
                                        serviceType: searchParams.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end,
                                        filters: currentParams.boolList,
                                        search: currentParams.q,
                                        detailedDashboard: 'main'
                                    }
                                },
                                /** detailed dashboard isn't a modal,
                                 * so we don't need it.
                                 *
                                 * To fill in the following properties for printOptions:
                                 * - detailedDashboard is provided at constants file of
                                 * this module.
                                 * - mustNot and should is already provided via
                                 *  useEffect with boolList.
                                 */
                                printOptions: printOptions
                            },
                            count: (getOffice365EventsMutation.data?.data || []).length
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        searchParams,
        getOffice365EventsMutation,
        currentParams,
        queueId,
        queues,
        printOptions
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update o365. */}
                <div className={'d-inline-block'}>{RefreshButton}</div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    useEffect(() => {
        return () => {
            dispatch(resetO365Detailed())
        }
    }, [])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
         * return modal with variable as a child.
         */
                let component: ReactElement<any, any> = <></>
                const message = sendUnavailableModalMessage(
                    modal.card.service_type,
                    modal.operation
                )
                if (modal.card.service_type === 'bdg-o365') {
                    if (modal.operation === 'DETAILS') {
                        component = <O365Details
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'DATA_ID') {
                        component = <O365DataID
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'EVENT_ESCALATE') {
                        // change modal values.
                        const updatedModal: EventModal = {
                            eventObj: modal.serviceTypeFormData?.o365?.eventEscalate?.eventObj,
                            id: modal.id,
                            open: modal.open,
                            operation: modal.operation,
                            isBorderWide: modal.isBorderWide
                        }

                        component = <DoEscalate
                            modal={updatedModal}
                            addModal={addModal as ActionCreatorWithPayload<EventModal, string>}
                            closeModal={closeModal as ActionCreatorWithPayload<EventModal, string>}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        return ''
                    }
                } else {
                    return ''
                }
                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['md',
                            modal.card.details.colorType || 'darkGrey',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    /** display statcard information at the right. */
    const CardInfo = useMemo(() => {
        return <div className={'text-nowrap'}>
            <div className={'row'}>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.DEVICE_ID}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.deviceid}</Text>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.IN_FACE}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.in_face}</Text>
            </div>
            <div className={'row'}>
                <Text size={'xs'} className={'col-auto'}>{
                    MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.LOCATION}
                </Text>
                <Text size={'xs'} className={'col'}>
                    {currentParams.card.details.location || ''}
                </Text>
            </div>
        </div>
    }, [currentParams.card])

    /** add a onClick event where all the charts below will
     * add a o365Details modal.
     */
    const ExchangeActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.EXCHANGE_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Exchange.Activity',
                        value: !fixedCollapsibles['Exchange.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Exchange.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Exchange.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Exchange.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Exchange.Activity'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Exchange.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Exchange.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Exchange.Activity'}
                isLoading={getExchangeActivityMutation.isLoading}
                isSuccess={getExchangeActivityMutation.isSuccess}
                error={getExchangeActivityMutation.error}
            />
        </div>
    }, undefined)

    const OperationsActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.OPERATIONS_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Operations.Activity',
                        value: !fixedCollapsibles['Operations.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Operations.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Operations.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Operations.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Operations.Activity'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Operations.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Operations.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Operations.Activity'}
                isLoading={getOperationsActivityMutation.isLoading}
                isSuccess={getOperationsActivityMutation.isSuccess}
                error={getOperationsActivityMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (dashboardData['Login.Locations']) {
            dispatch(setTotalRecords(dashboardData['Login.Locations'].totalRecords))
            dispatch(setIsComplete(dashboardData['Login.Locations'].isComplete))
            dispatch(setScrollId(dashboardData['Login.Locations'].scrollId))
            dispatch(setMapData(dashboardData['Login.Locations'].data))
        }
    }, [dashboardData['Login.Locations']])

    const ProgressBar = useMemo(() => {
        const value = mapData.data.length
        const maxValue = mapData.totalRecords || 1

        // console.log(value)
        // console.log(maxValue)
        // console.log([(value / (maxValue) * 100).toFixed(1), '%'].join(''))

        return mapData.isComplete
            ? <MapInfoContainer>
                <FaInfoCircle />
            </MapInfoContainer>
            : <CircularProgressContainer>{
                <CircularProgressbar
                    background={true}
                    strokeWidth={4}
                    value={value}
                    maxValue={maxValue}
                    text={[(value / (maxValue) * 100).toFixed(1), '%'].join('')}
                />}
            </CircularProgressContainer>
    }, [mapData])

    const O365Locations = useMemo(() => {
        const value = mapData.data.length
        const maxValue = mapData.totalRecords || 1

        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.O365_LOCATIONS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['Login.Locations'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Login.Locations',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>

            {/* data empty message adjusted at 9/22/2022. display
            no geoip message if empty. Don't waste space */}

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{[
                                value, 'of', maxValue
                            ].join(' ')}</div>}>
                            {ProgressBar}
                        </Tippy>
                        <div className={'col'}>
                            <LoginLocationsMap/>
                        </div>
                    </div>
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </div>
    }, undefined)

    const GeneralActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.GENERAL_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'General.Activity',
                        value: !fixedCollapsibles['General.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['General.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['General.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'General.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['General.Activity'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['General.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'General.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'General.Activity'}
                isLoading={getGeneralActivityMutation.isLoading}
                isSuccess={getGeneralActivityMutation.isSuccess}
                error={getGeneralActivityMutation.error}
            />
        </div>
    }, undefined)

    const SecurityCenterActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SECURITY_CENTER_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'SecurityCenter.Activity',
                        value: !fixedCollapsibles['SecurityCenter.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['SecurityCenter.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['SecurityCenter.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SecurityCenter.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['SecurityCenter.Activity'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['SecurityCenter.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'SecurityCenter.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'SecurityCenter.Activity'}
                isLoading={getSecurityCenterActivityMutation.isLoading}
                isSuccess={getSecurityCenterActivityMutation.isSuccess}
                error={getSecurityCenterActivityMutation.error}
            />
        </div>
    }, undefined)

    const SharepointActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SHAREPOINT_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Sharepoint.Activity',
                        value: !fixedCollapsibles['Sharepoint.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Sharepoint.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Sharepoint.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Sharepoint.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Sharepoint.Activity'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Sharepoint.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Sharepoint.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Sharepoint.Activity'}
                isLoading={getSharepointActivityMutation.isLoading}
                isSuccess={getSharepointActivityMutation.isSuccess}
                error={getSharepointActivityMutation.error}
            />
        </div>
    }, undefined)

    const WorkloadActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.WORKLOAD_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Workload.Activity',
                        value: !fixedCollapsibles['Workload.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Workload.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Workload.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Workload.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Workload.Activity'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Workload.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Workload.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Workload.Activity'}
                isLoading={getWorkloadActivityMutation.isLoading}
                isSuccess={getWorkloadActivityMutation.isSuccess}
                error={getWorkloadActivityMutation.error}
            />
        </div>
    }, undefined)

    /** moved to a separate file. Minimal requirement to pass other data. */
    const O365EventsTable = useMemo(() => {
        return (<div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.O365_EVENTS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['Office365.Events']
                        ? 'disabled'
                        : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Office365.Events',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <O365EventsDataTable
                addModal={addModal}
                closeModal={closeModal}
                isLoading={getOffice365EventsMutation.isLoading}
                isSuccess={getOffice365EventsMutation.isSuccess}
                error={getOffice365EventsMutation.error}
            />
        </div>)
    }, undefined)

    const DashboardLinks = useMemo(() => {
        const links = _.map(_.toPairs(O365_DETAILED_DASHBOARD_ROUTES),
            ([key, link], index) => {
                const chunks = _.split(link.link, ':deviceid')
                const newRoute = [
                    chunks[0],
                    currentParams.card.deviceid,
                    chunks[1]
                ].join('')

                const match = newRoute === router.location.pathname
                return (
                    <li
                        key={['detailedDashboardTabs-', index].join('')}
                        className={[match ? 'selected' : ''].join(' ')}
                        onClick={() => {
                            !match && dispatch(push(newRoute))
                        }}
                    >
                        {key}
                    </li>
                )
            }
        )
        return (
            <DetailedDashboardLinks>
                <ul>
                    {links}
                </ul>
            </DetailedDashboardLinks>
        )
    }, [router.location.pathname, currentParams.card])

    return <div>
        <div className={'align-items-center justify-content-between mb-3 mt-3 row'}>
            <div className={'col-auto'}>
                {DashboardLinks}
            </div>
            <div className={'col-auto'}>
                {CardInfo}
            </div>
        </div>
        {/* we want to see the search parameters we edit before even doing the calls.
        the important thing is that we have the latest instance of the search parameters
        AFTER the latest api call */}
        <div className={'row'}>
            <div className={'col-12 col-md px-4'}>
                <SearchRow className={'align-items-center row mb-2'}>
                    <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                        <FaSearch />
                    </label>
                    <input
                        className={'col'}
                        type={'text'}
                        defaultValue={searchParams.q}
                        id={TEXT.SEARCH.SEARCH.ID}
                        onChange={(e) => {
                            dispatch(setQ(e.target.value))
                        }}
                        /** if enter is pressed, render refresh warning */
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                // always set to true because you are pressing enter on the input
                                toggleConfirm(true)
                            }
                        }}
                        placeholder={'Search'}
                    />
                </SearchRow>
            </div>
            <div className={'col-auto pe-5 pe-lg-2'}>
                {ActionButtons}
            </div>
        </div>
        {/* filter interface */}
        <div className={'row mb-2'}>
            {/* display columns aka a filter shere */}
            <div className={'col ps-4'}>
                <div className={'row'}>
                    {
                        _.map(searchParams.boolList, (bool, index) => {
                            return (
                                <FilterCell key={'filter-' + index} className={
                                    ['col-auto me-2 px-2 mt-1',
                                        bool.not ? 'not' : ''].join(' ')
                                }>
                                    <Text size={'xs'}>
                                        {[
                                            bool.not ? '!' : '',
                                            bool.sort,
                                            ': ',
                                            bool.value
                                        ]
                                            .join('')}
                                    </Text>
                                    <Text size={'xs'} className={'ps-2'} onClick={() => {
                                        dispatch(removeBool(bool))
                                    }}>
                                        <FaTimes />
                                    </Text>
                                </FilterCell>
                            )
                        })
                    }
                </div>
            </div>
            <span
                className={[
                    'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                    searchParams.boolList.length ? 'd-block' : 'd-none'
                ].join(' ')}
                onClick={() => {
                    dispatch(removeBoolList())
                }}
            >
                {/* clear all filters button. baseline, not center */}
                <FaTimes />
            </span>
        </div>
        <div className={'row'}>
            <div className={'col-12 col-md-6'}>
                {OperationsActivity}
                {SharepointActivity}
                {WorkloadActivity}
                {GeneralActivity}
            </div>
            <div className={'col-12 col-md-6'}>
                {O365Locations}
                {ExchangeActivity}
                {SecurityCenterActivity}
            </div>
        </div>
        <div className={'min-width-fix mb-3'}>
            {/* table stuff */}
            {O365EventsTable}
        </div>
        {
            renderModals
        }
    </div>
}

export default O365Main

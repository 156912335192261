import {
    INITIAL_VALUES,
    SEVERITY_TYPES,
    TEXT as FILTER_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/soc-data/filter'
import {
    FilterModal,
    PreserveActions,
    PreserveRefs,
    PreserveState,
    UpdateFilterKeys
} from '@interfaces/watchdog/soc-data/filter'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    FormStyledComponents as Form
} from '@styles/components'
import { useFormik } from 'formik'
import produce from 'immer'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef
} from 'react'
import Select from 'react-select'

const FilterUpdate = ({ modal, addModal, closeModal } : {
    modal: FilterModal,
    addModal: ActionCreatorWithPayload<FilterModal, string>,
    closeModal: ActionCreatorWithPayload<FilterModal, string>
}) => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const reactSelect = rootContext.reactSelect

    const checkboxRefs = useRef({
        hostname: null,
        boxname: null,
        srcIp: null,
        destIp: null
    } as PreserveRefs)

    const [preserveState, preserveStateDispatch] = useReducer(
        (state: PreserveState, action: PreserveActions) => {
            switch (action.type) {
                case 'PRESERVE_HOSTNAME': {
                    return produce(state, draft => { draft.hostname = action.value })
                }
                case 'PRESERVE_BOXNAME': {
                    return produce(state, draft => { draft.boxname = action.value })
                }
                case 'PRESERVE_SRCIP': {
                    return produce(state, draft => { draft.srcIp = action.value })
                }
                case 'PRESERVE_DESTIP': {
                    return produce(state, draft => { draft.destIp = action.value })
                }
            }
        }, {
            hostname: '',
            boxname: '',
            srcIp: '',
            destIp: ''
        }
    )

    /** fetch other calls. */

    const updateFormik = useFormik({
        initialValues: INITIAL_VALUES.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.UPDATE,
        onSubmit: () => {
        }
    })

    const EventNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.EVENT_NAME.ID}>
                    {FILTER_TEXT.ADD.FORM.EVENT_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.eventname)}
                    name={'eventname'}
                    id={FILTER_TEXT.ADD.FORM.EVENT_NAME.ID}
                    value={updateFormik.values.eventname}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.eventname)} >{
                    updateFormik.errors.eventname ? updateFormik.errors.eventname : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.eventname, updateFormik.errors.eventname])

    const HostNameInput = useMemo(() => {
        /**
         * when updateFormik.handleChange is invoked, you have to update the value
         * of the preserveState via a useEffect with this property as a dependency
         */

        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.HOSTNAME.ID}>
                    {FILTER_TEXT.ADD.FORM.HOSTNAME.LABEL}
                </Form.Label>
                <Form.TextCheckbox
                    errors={Boolean(updateFormik.errors.hostname)}
                >
                    <input
                        ref={(r) => {
                            checkboxRefs.current.hostname = r
                        }}
                        type={'checkbox'}
                        disabled={true}
                    />
                    <input
                        name={'hostname'}
                        id={FILTER_TEXT.ADD.FORM.HOSTNAME.ID}
                        value={updateFormik.values.hostname}
                        readOnly={true}
                    />
                </Form.TextCheckbox>
                <Form.Feedback errors={Boolean(updateFormik.errors.hostname)} >{
                    updateFormik.errors.hostname ? updateFormik.errors.hostname : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        updateFormik.values.hostname,
        updateFormik.errors.hostname,
        preserveState.hostname
    ])

    const BoxNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.BOXNAME.ID}>
                    {FILTER_TEXT.ADD.FORM.BOXNAME.LABEL}
                </Form.Label>
                <Form.TextCheckbox
                    errors={Boolean(updateFormik.errors.boxname)}
                >
                    <input
                        ref={(r) => {
                            checkboxRefs.current.boxname = r
                        }}
                        type={'checkbox'}
                        disabled={true}
                    />
                    <input
                        name={'boxname'}
                        id={FILTER_TEXT.ADD.FORM.BOXNAME.ID}
                        value={updateFormik.values.boxname}
                        readOnly={true}
                    />
                </Form.TextCheckbox>
                <Form.Feedback errors={Boolean(updateFormik.errors.boxname)} >{
                    updateFormik.errors.boxname ? updateFormik.errors.boxname : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        updateFormik.values.boxname,
        updateFormik.values.srcIp,
        updateFormik.values.destIp,
        updateFormik.values.value,
        updateFormik.errors.boxname,
        preserveState.boxname
    ])

    const SrcIpInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.SRC_IP.ID}>
                    {FILTER_TEXT.ADD.FORM.SRC_IP.LABEL}

                </Form.Label>
                <Form.TextCheckbox
                    errors={Boolean(updateFormik.errors.srcIp)}
                >
                    <input
                        ref={(r) => {
                            checkboxRefs.current.srcIp = r
                        }}
                        type={'checkbox'}
                        disabled={true}
                    />
                    <input
                        name={'srcIp'}
                        id={FILTER_TEXT.ADD.FORM.SRC_IP.ID}
                        value={updateFormik.values.srcIp}
                        readOnly={true}
                    />
                </Form.TextCheckbox>
                <Form.Feedback errors={Boolean(updateFormik.errors.srcIp)} >{
                    updateFormik.errors.srcIp ? updateFormik.errors.srcIp : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        updateFormik.values.srcIp,
        updateFormik.errors.srcIp,
        preserveState.srcIp
    ])

    const DestIpInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.DEST_IP.ID}>
                    {FILTER_TEXT.ADD.FORM.DEST_IP.LABEL}

                </Form.Label>
                <Form.TextCheckbox
                    errors={Boolean(updateFormik.errors.destIp)}
                >
                    <input
                        ref={(r) => {
                            checkboxRefs.current.destIp = r
                        }}
                        type={'checkbox'}
                        disabled={true}
                    />
                    <input
                        name={'destIp'}
                        id={FILTER_TEXT.ADD.FORM.DEST_IP.ID}
                        value={updateFormik.values.destIp}
                        readOnly={true}
                    />
                </Form.TextCheckbox>
                <Form.Feedback errors={Boolean(updateFormik.errors.destIp)} >{
                    updateFormik.errors.destIp ? updateFormik.errors.destIp : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        updateFormik.values.destIp,
        updateFormik.errors.destIp,
        preserveState.destIp
    ])

    const TypeInput = useMemo(() => {
        const severityValues = SEVERITY_TYPES

        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.TYPE.ID}>
                    {FILTER_TEXT.ADD.FORM.TYPE.LABEL}

                </Form.Label>
                <Select
                    id={FILTER_TEXT.ADD.FORM.TYPE.ID}
                    errors={Boolean(updateFormik.errors.type)}
                    options={severityValues}
                    value={_.find(
                        severityValues,
                        (e) => e.value === updateFormik.values.type
                    )}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                    isDisabled={true}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.type)} >{
                    updateFormik.errors.type ? updateFormik.errors.type : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        updateFormik.values.type,
        updateFormik.errors.type
    ])

    const ValueInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={FILTER_TEXT.ADD.FORM.VALUE.ID}>
                    {FILTER_TEXT.ADD.FORM.VALUE.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.value)}
                    name={'value'}
                    id={FILTER_TEXT.ADD.FORM.VALUE.ID}
                    value={updateFormik.values.value}
                    readOnly={true}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.value)} >{
                    updateFormik.errors.value ? updateFormik.errors.value : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.value, updateFormik.errors.value])

    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={FILTER_TEXT.UPDATE.FORM.IS_ACTIVE.ID}>
                    {FILTER_TEXT.UPDATE.FORM.IS_ACTIVE.LABEL}

                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={FILTER_TEXT.UPDATE.FORM.IS_ACTIVE.ID}
                    disabled={true}
                    checked={updateFormik.values.active}
                />
                <Form.Feedback
                    errors={Boolean(updateFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        updateFormik.errors.active ? updateFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.active, updateFormik.errors.active])

    useEffect(() => {
        // only update while the checkbox is checked.

        /** don't empty fields if the hostname is empty.
         * however this check is only done with the updateFormik.values.hostname
         * dependency alone */

        if (checkboxRefs.current.hostname !== null &&
            checkboxRefs.current.hostname.checked
        ) {
            preserveStateDispatch({
                type: 'PRESERVE_HOSTNAME',
                value: updateFormik.values.hostname
            })
        }
    }, [updateFormik.values.hostname])

    useEffect(() => {
        if (checkboxRefs.current.boxname !== null &&
            checkboxRefs.current.boxname.checked
        ) {
            preserveStateDispatch({
                type: 'PRESERVE_BOXNAME',
                value: updateFormik.values.boxname
            })
        }
    }, [updateFormik.values.boxname])

    useEffect(() => {
        if (checkboxRefs.current.srcIp !== null &&
            checkboxRefs.current.srcIp.checked
        ) {
            preserveStateDispatch({
                type: 'PRESERVE_SRCIP',
                value: updateFormik.values.srcIp
            })
        }
    }, [updateFormik.values.srcIp])

    useEffect(() => {
        if (checkboxRefs.current.destIp !== null &&
            checkboxRefs.current.destIp.checked
        ) {
            preserveStateDispatch({
                type: 'PRESERVE_DESTIP',
                value: updateFormik.values.destIp
            })
        }
    }, [updateFormik.values.destIp])

    /** assign value to updateFormik.values.value when updateFormik.values.type changes */
    useEffect(() => {
        const fieldValue: UpdateFilterKeys = 'value'

        if (updateFormik.values.type === 'filter') {
            updateFormik.setFieldValue(fieldValue, 0)
        } else if (updateFormik.values.type === 'adjust') {
            updateFormik.setFieldValue(fieldValue, 1)
        }
    }, [updateFormik.values.type])

    /** when srcIp, destIp, value are truthy, perform a click event
     * of the boxname checkbox ONLY if it wasn't checked
     */

    useEffect(() => {
        if (
            Boolean(updateFormik.values.srcIp ||
                updateFormik.values.destIp ||
                updateFormik.values.value) &&
            checkboxRefs.current.boxname !== null &&
            checkboxRefs.current.boxname.checked === false
        ) {
            checkboxRefs.current.boxname.click()
        }
    }, [updateFormik.values.srcIp, updateFormik.values.destIp, updateFormik.values.value])

    useEffect(() => {
        if (modal.filterObj) {
            const boxnameField: UpdateFilterKeys = 'boxname'
            const destIpField: UpdateFilterKeys = 'destIp'
            const eventnameField: UpdateFilterKeys = 'eventname'
            const hostnameField: UpdateFilterKeys = 'hostname'
            const srcIpField: UpdateFilterKeys = 'srcIp'
            const typeField: UpdateFilterKeys = 'type'
            const valueField: UpdateFilterKeys = 'value'
            const activeField: UpdateFilterKeys = 'active'

            updateFormik.setFieldValue(boxnameField, modal.filterObj.boxname)
            updateFormik.setFieldValue(destIpField, modal.filterObj.dest_ip)
            updateFormik.setFieldValue(eventnameField, modal.filterObj.eventName)
            updateFormik.setFieldValue(hostnameField, modal.filterObj.hostname)
            updateFormik.setFieldValue(srcIpField, modal.filterObj.src_ip)
            updateFormik.setFieldValue(typeField, modal.filterObj.type)
            updateFormik.setFieldValue(valueField, modal.filterObj.value)
            updateFormik.setFieldValue(activeField, modal.filterObj.active)
        }
    }, [modal.filterObj])

    return (
        <div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <Form.Main onSubmit={updateFormik.handleSubmit}>
                        <small className={'d-block my-2'}>
                            {FILTER_TEXT.INTERFACE}
                        </small>
                        {EventNameInput}
                        {HostNameInput}
                        {BoxNameInput}
                        {SrcIpInput}
                        {DestIpInput}
                        {TypeInput}
                        {ValueInput}
                        {ActiveInput}
                    </Form.Main>
                </div>
            </div>
        </div>
    )
}
export default FilterUpdate

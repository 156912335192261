
import {
    useGetDetailedAzureK8sEventsMutation,
    useGetDetailedAzureKubeEventsMutation,
    useGetDetailedAzureKubeMonAgentEventsMutation
} from '@apis/dashboard/soc/azure-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    CHART_ZOOM,
    MESSAGE as MONITOR_MESSAGE,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    INITIAL_SEARCH_PARAMS,
    TEXT as AZURE_TEXT
} from '@constants/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    getLocalRanges,
    getUtcRanges,
    sendUnavailableModalMessage,
    tryParseJSON,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    AzureDetailedSearchParams,
    GetDetailedDashboardRequest
} from '@interfaces/dashboard/soc/azure/detailedDashboard/kubernetes'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectPrintOptions,
    selectSearchParams,
    selectTableData,
    setEndDate,
    selectFixedCollapsibles,
    setLogo,
    setRefetch,
    setStartDate,
    setLocalStorage,
    selectChartZooms,
    resetAzureDetailed,
    selectModals,
    toggleCollapsible,
    setChartZoomLevel,
    closeModal,
    removeModal,
    setCurrentParams,
    addModal,
    selectCurrentParams,
    removeBool,
    removeBoolList,
    setChartZoom,
    setQ,
    selectDashboardData,
    setAzureK8sEventsData,
    setAzureKubeEventsData,
    setAzureKubeMonAgentEventsData,
    setSearchParams
} from '@slices/dashboard/soc/azure/detailedDashboard/kubernetes'
import { selectToken } from '@slices/main/token'
import {
    Button,
    CollapsibleText,
    DetailedDashboardLinks,
    FilterCell,
    SearchRow,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaFileCsv,
    FaFilePdf,
    FaMinus,
    FaPlus,
    FaSearch,
    FaSearchMinus,
    FaTimes
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import Modal from 'react-responsive-modal'
import AzureDataID from '@features/dashboard/soc/azure/AzureDataID'
import AzureDetails from '@features/dashboard/soc/azure/AzureDetails'
import { AiOutlineClose } from 'react-icons/ai'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import { AZURE_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import { selectRouter } from '@slices/main/router'
import { LuceneQuery } from '@interfaces/dashboard/monitor'
import AzureBarChart from
    '@features/dashboard/soc/azure/detailed-dashboard/kubernetes/AzureBarChart'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'
import { Buffer } from 'buffer'
import AzureK8sEventsDataTable from
    '@features/dashboard/soc/azure/detailed-dashboard/kubernetes/AzureK8sEventsDataTable'
import flatten from 'flat'
import DoEscalate from '@features/watchdog/soc-data/event/DoEscalate'
import { EventModal } from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const AzureKubernetes = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const dispatch = useAppDispatch()
    const router = useAppSelector(selectRouter)

    const token = useAppSelector(selectToken)

    /** we will always have a url parameter for the deviceid so IF and
     * only IF the deviceid is falsy, then we will use this value.
     */
    const params = useParams<{deviceid: string }>()

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const tableData = useAppSelector(selectTableData)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const modals = useAppSelector(selectModals)
    const chartZooms = useAppSelector(selectChartZooms)
    const dashboardData = useAppSelector(selectDashboardData)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    const [getDetailedAzureKubeEvents,
        getDetailedAzureKubeEventsMutation
    ] = useGetDetailedAzureKubeEventsMutation()
    const [getDetailedAzureKubeMonAgentEvents,
        getDetailedAzureKubeMonAgentEventsMutation
    ] = useGetDetailedAzureKubeMonAgentEventsMutation()
    const [getDetailedAzureK8sEvents,
        getDetailedAzureK8sEventsMutation
    ] = useGetDetailedAzureK8sEventsMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    /** removed because deviceid alone is not enough to
     * query the detailed dashboard.
     */
    // useEffect(() => {
    //     // check if deviceid in searchParams is falsy.
    //     if (!(searchParams.card.deviceid) && params.deviceid) {
    //         dispatch(setDeviceId(params.deviceid))
    //     }
    // }, [params])

    useEffect(() => {
        /** we retrieve the localStorage key */
        const userJson = localStorage.getItem('azure-db-kubernetes-search-params')

        if (userJson !== null) {
            const result = tryParseJSON(userJson) as unknown as
             Omit<AzureDetailedSearchParams, 'refetch'>[]

            /** check if the variable is an array. */
            if (_.isArray(result)) {
                /** then we find the object using the deviceid as our conditional check */
                const found = _.find(result, (obj) => {
                    return obj.card.deviceid === params.deviceid
                })

                if (found) {
                    /**
                     * once found, we want to check if the object has those keys.
                     * without this check, and because those properties are set
                     * to required, render crashes will occur.
                     *
                     * Update at October 7, 2022: Nested object properties
                     * are required to meet strict conditions.
                     */

                    const requiredKeys = _.keys(
                        flatten(
                            // exclude refetch property from checking.
                            _.omit(INITIAL_SEARCH_PARAMS, ['refetch', 'boolList'])
                        )
                    )

                    if (_.every(
                        requiredKeys,
                        _.partial(
                            _.has,
                            flatten(found, { maxDepth: 2 })
                        ))
                    ) {
                        dispatch(setSearchParams({
                            ...found,
                            refetch: INITIAL_SEARCH_PARAMS.refetch
                        }))
                    } else {
                        // display warning message that the found
                        // object is missing some properties that
                        // the component might crash.
                        toast.warning(
                            MONITOR_MESSAGE.MISSING_KEYS_ON_CARD,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                } else {
                    // otherwise, display error message that none was found
                    // and should go select a dashboard card from monitors.
                    toast.error(
                        MONITOR_MESSAGE.MISSING_CARD,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                }
            } else {
                toast.error(
                    MONITOR_MESSAGE.INCORRECT_DETAILED_DASHBOARD_DATA,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [params])

    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getDetailedAzureKubeEventsMutation.error) {
            console.error(getDetailedAzureKubeEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.KubeEvents',
                value: false
            }))
        }
    }, [getDetailedAzureKubeEventsMutation.error])

    useEffect(() => {
        if (getDetailedAzureKubeMonAgentEventsMutation.error) {
            console.error(getDetailedAzureKubeMonAgentEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.KubeMonAgentEvents',
                value: false
            }))
        }
    }, [getDetailedAzureKubeMonAgentEventsMutation.error])

    useEffect(() => {
        if (getDetailedAzureK8sEventsMutation.error) {
            console.error(getDetailedAzureK8sEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.k8s.Events',
                value: false
            }))
        }
    }, [getDetailedAzureK8sEventsMutation.error])

    /** useEffects to set the data. */

    /** a useEffect for searchParams to update the localStorage item. Changed dependency
     * to currentParams so when the page refreshes, you are using parameters
     * from the latest search instance.
     */
    useEffect(() => {
        /** BUG FOUND at October 11, 2021: default search params
         * on mount is also being added to localStorage. kindly
         * avoid this by checking if service type and inface
         * is lengthy.
         *
         *
         */

        if (currentParams.card.deviceid && currentParams.card.details.title) {
            const oldRanges = getLocalRanges({
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            })

            dispatch(setLocalStorage({
                ...currentParams,
                ranges: oldRanges
            }))
        }
    }, [currentParams])

    /** can also be from a modal card. */
    /** these are now separate fetchData scripts per client's requests. */
    const createRequestData: (newToken: string) => Omit<GetDetailedDashboardRequest, 'event_type'>
    & TokenAuth = (newToken) => {
        const newRanges = getUtcRanges(searchParams.ranges)

        const should: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => obj.not),
            turnIntoLuceneQuery)
        /**
         * Issue with search params where you update the ranges and q. It
         * will mess up the form data for fetching either o365 details or data id
         * when selecting the first OR last dataset
         * in the charts OR the tables below them.
         *
         * This can also confuse users who changed the searchParams ranges but
         * didn't execute fetch api calls. To fix this, set a dispatch where
         * you are setting the current range.
         */

        return {
            authToken: newToken,
            deviceid: searchParams.card.deviceid,
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: Buffer
                .from(searchParams.q)
                .toString('base64'),
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        const newRanges = getUtcRanges(searchParams.ranges)

        dispatch(setCurrentParams({
            ranges: newRanges,
            q: searchParams.q,
            card: searchParams.card,
            boolList: searchParams.boolList
        }))
    }

    /** i'm glad we check for searchParams.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetAzureKubeEvents = () => {
        const unsubscribeMutation = getDetailedAzureKubeEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setAzureKubeEventsData(undefined))
    }

    const unsubscribeGetAzureKubeMonAgentEvents = () => {
        const unsubscribeMutation = getDetailedAzureKubeMonAgentEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setAzureKubeMonAgentEventsData(undefined))
    }

    const unsubscribeGetAzureK8sEvents = () => {
        const unsubscribeMutation = getDetailedAzureK8sEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setAzureK8sEventsData(undefined))
    }

    const fetchAzureKubeEventsData = () => {
        unsubscribeGetAzureKubeEvents()
        let getAzureKubeEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeEventsPromise = getDetailedAzureKubeEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeEventsPromise && getAzureKubeEventsPromise.abort()
        }
    }

    const fetchAzureKubeMonAgentEventsData = () => {
        unsubscribeGetAzureKubeMonAgentEvents()
        let getAzureKubeMonAgentEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeMonAgentEventsPromise = getDetailedAzureKubeMonAgentEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeMonAgentEventsPromise && getAzureKubeMonAgentEventsPromise.abort()
        }
    }

    const fetchAzureK8sEventsData = () => {
        unsubscribeGetAzureK8sEvents()
        let getAzureK8sEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureK8sEventsPromise = getDetailedAzureK8sEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureK8sEventsPromise && getAzureK8sEventsPromise.abort()
        }
    }

    /** on token revalidation and if the searchParams.card dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */

    useEffect(() => {
        if (!dashboardData['Azure.KubeEvents']) {
            return fetchAzureKubeEventsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Azure.KubeMonAgentEvents']) {
            return fetchAzureKubeMonAgentEventsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['Azure.k8s.Events']) {
            return fetchAzureK8sEventsData()
        }
    }, [token.valid, searchParams.card])
    /**
     * when component is mounted, do not execute calls if there is already
     * dashboard data.
     *
     * detailed dashboard soc has slowdowns when navigating AND refreshing.
     * fix is to empty the data when refetch is true.
     */

    useEffect(() => {
        if (searchParams.refetch['Azure.KubeEvents']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.KubeEvents',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureKubeEventsData()
        }
    }, [searchParams.refetch['Azure.KubeEvents']])

    useEffect(() => {
        let isMounted = true
        if (getDetailedAzureKubeEventsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Azure.KubeEvents']) {
                dispatch(setRefetch({
                    key: 'Azure.KubeEvents',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setAzureKubeEventsData(
                getDetailedAzureKubeEventsMutation.data
            ))
        }

        return () => {
            isMounted = false
        }
    }, [getDetailedAzureKubeEventsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Azure.KubeMonAgentEvents']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.KubeMonAgentEvents',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureKubeMonAgentEventsData()
        }
    }, [searchParams.refetch['Azure.KubeMonAgentEvents']])

    useEffect(() => {
        let isMounted = true
        if (getDetailedAzureKubeMonAgentEventsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Azure.KubeMonAgentEvents']) {
                dispatch(setRefetch({
                    key: 'Azure.KubeMonAgentEvents',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setAzureKubeMonAgentEventsData(
                getDetailedAzureKubeMonAgentEventsMutation.data
            ))
        }

        return () => {
            isMounted = false
        }
    }, [getDetailedAzureKubeMonAgentEventsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['Azure.k8s.Events']) {
            dispatch(setChartZoom({
                key: 'Azure.k8s.Events',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureK8sEventsData()
        }
    }, [searchParams.refetch['Azure.k8s.Events']])

    useEffect(() => {
        let isMounted = true
        if (getDetailedAzureK8sEventsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['Azure.k8s.Events']) {
                dispatch(setRefetch({
                    key: 'Azure.k8s.Events',
                    value: false
                }))
            }
            dispatch(setAzureK8sEventsData(
                getDetailedAzureK8sEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getDetailedAzureK8sEventsMutation.isSuccess])

    /** if searchParams change, set all refetch values to true. */

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    /** refresh button where all fetchData scripts are executed
     * add a tippy warning just incase.
     */
    const RefreshWarningContent = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            dispatch(setRefetch({
                key: 'Azure.KubeEvents',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.KubeMonAgentEvents',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.k8s.Events',
                value: true
            }))
        }

        return <div>
            <div className={'row'}>
                <small className={'col'}>
                    {MONITOR_MESSAGE.DETAILED_DASHBOARD_REFRESH_ALL}
                </small>
            </div>
            <div className={'row flex-row-reverse mt-1'}>
                <div className={'col-auto'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        onClick={confirmClickEvent}
                    >{TEXT.YES}</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        mode={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            toggleConfirm(false)
                        }}
                    >{TEXT.NO}</Button>
                </div>
            </div>
        </div>
    }, [showConfirm])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getDetailedAzureKubeEventsMutation.isLoading ||
            getDetailedAzureKubeMonAgentEventsMutation.isLoading ||
            getDetailedAzureK8sEventsMutation.isLoading

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={RefreshWarningContent}
                onClickOutside={() => toggleConfirm(false)}
            >
                <span
                    className={[
                        'icon mb-2 d-inline-block ms-2',
                        disableRefresh ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }} >
                    <MdRefresh />
                </span>
            </Tippy>
        )
    }, [
        showConfirm,
        RefreshWarningContent,
        getDetailedAzureKubeEventsMutation,
        getDetailedAzureKubeMonAgentEventsMutation,
        getDetailedAzureK8sEventsMutation
    ])

    /** csv exports detailed dashboard event data. */
    const CsvExportButton = useMemo(() => {
        const eventsData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            currentParams.card.service_type,
            '_', currentParams.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = eventsData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                filename={filename}
                onClick={() => {
                    if (disableExport) return false
                }}
                data={eventsData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        currentParams.card
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={AZURE_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {AZURE_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        /** there will be some reminders for printing reports
         * with the filter and search logic specific to detailed dashboards.
         * They will be defined in the monitor constants file.
         */

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getDetailedAzureK8sEventsMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <Text size={'sm'}>
                {MONITOR_TEXT.DETAILED_DASHBOARD.PRINT_REMINDERS}
            </Text>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */
                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: searchParams.card.deviceid,
                                        inFace: searchParams.card.in_face,
                                        serviceType: searchParams.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end,
                                        filters: currentParams.boolList,
                                        search: currentParams.q,
                                        detailedDashboard: 'main'
                                    }
                                },
                                /** detailed dashboard isn't a modal,
                                 * so we don't need it.
                                 *
                                 * To fill in the following properties for printOptions:
                                 * - detailedDashboard is provided at constants file of
                                 * this module.
                                 * - mustNot and should is already provided via
                                 *  useEffect with boolList.
                                 */
                                printOptions: printOptions
                            },
                            count: (getDetailedAzureK8sEventsMutation.data?.data || []).length
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        searchParams,
        getDetailedAzureK8sEventsMutation,
        currentParams,
        queueId,
        queues,
        printOptions
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update azure. */}
                <div className={'d-inline-block'}>{RefreshButton}</div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    useEffect(() => {
        return () => {
            dispatch(resetAzureDetailed())
        }
    }, [])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
         * return modal with variable as a child.
         */
                let component: ReactElement<any, any> = <></>
                const message = sendUnavailableModalMessage(
                    modal.card.service_type,
                    modal.operation
                )
                if (modal.card.service_type === 'bdg-o365-azure') {
                    if (modal.operation === 'DETAILS') {
                        component = <AzureDetails
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'DATA_ID') {
                        component = <AzureDataID
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'EVENT_ESCALATE') {
                        // change modal values.
                        const updatedModal: EventModal = {
                            eventObj: modal.serviceTypeFormData?.azure?.eventEscalate?.eventObj,
                            id: modal.id,
                            open: modal.open,
                            operation: modal.operation,
                            isBorderWide: modal.isBorderWide
                        }

                        component = <DoEscalate
                            modal={updatedModal}
                            addModal={addModal as ActionCreatorWithPayload<EventModal, string>}
                            closeModal={closeModal as ActionCreatorWithPayload<EventModal, string>}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        return ''
                    }
                } else {
                    return ''
                }
                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['md',
                            modal.card.details.colorType || 'darkGrey',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    /** display statcard information at the right. */
    const CardInfo = useMemo(() => {
        return <div className={'text-nowrap'}>
            <div className={'row'}>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.DEVICE_ID}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.deviceid}</Text>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.IN_FACE}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.in_face}</Text>
            </div>
            <div className={'row'}>
                <Text size={'xs'} className={'col-auto'}>{
                    MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.LOCATION}
                </Text>
                <Text size={'xs'} className={'col'}>
                    {currentParams.card.details.location || ''}
                </Text>
            </div>
        </div>
    }, [currentParams.card])

    /** add a onClick event where all the charts below will
     * add a azureDetails modal.
     */
    const AzureKubeEvents = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.KUBE_EVENTS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.KubeEvents',
                        value: !fixedCollapsibles['Azure.KubeEvents']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.KubeEvents'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.KubeEvents'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.KubeEvents',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.KubeEvents'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.KubeEvents'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.KubeEvents',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.KubeEvents'}
                isLoading={getDetailedAzureKubeEventsMutation.isLoading}
                isSuccess={getDetailedAzureKubeEventsMutation.isSuccess}
                error={getDetailedAzureKubeEventsMutation.error}
            />
        </div>
    }, undefined)

    const AzureKubeMonAgentEvents = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.KUBE_MON_AGENT_EVENTS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.KubeMonAgentEvents',
                        value: !fixedCollapsibles['Azure.KubeMonAgentEvents']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.KubeMonAgentEvents'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.KubeMonAgentEvents'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.KubeMonAgentEvents',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.KubeMonAgentEvents'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.KubeMonAgentEvents'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.KubeMonAgentEvents',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.KubeMonAgentEvents'}
                isLoading={getDetailedAzureKubeMonAgentEventsMutation.isLoading}
                isSuccess={getDetailedAzureKubeMonAgentEventsMutation.isSuccess}
                error={getDetailedAzureKubeMonAgentEventsMutation.error}
            />
        </div>
    }, undefined)

    /** moved to a separate file. Minimal requirement to pass other data. */
    const AzureK8sEventsTable = useMemo(() => {
        return (<div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.AZURE_K8S_EVENTS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.k8s.Events']
                        ? 'disabled'
                        : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.k8s.Events',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <AzureK8sEventsDataTable
                addModal={addModal}
                closeModal={closeModal}
                isLoading={getDetailedAzureK8sEventsMutation.isLoading}
                isSuccess={getDetailedAzureK8sEventsMutation.isSuccess}
                error={getDetailedAzureK8sEventsMutation.error}
            />
        </div>)
    }, undefined)

    const DashboardLinks = useMemo(() => {
        const links = _.map(_.toPairs(AZURE_DETAILED_DASHBOARD_ROUTES),
            ([key, link], index) => {
                const chunks = _.split(link.link, ':deviceid')
                const newRoute = [
                    chunks[0],
                    currentParams.card.deviceid,
                    chunks[1]
                ].join('')

                const match = newRoute === router.location.pathname
                return (
                    <li
                        key={['detailedDashboardTabs-', index].join('')}
                        className={[match ? 'selected' : ''].join(' ')}
                        onClick={() => {
                            !match && dispatch(push(newRoute))
                        }}
                    >
                        {key}
                    </li>
                )
            }
        )
        return (
            <DetailedDashboardLinks>
                <ul>
                    {links}
                </ul>
            </DetailedDashboardLinks>
        )
    }, [router.location.pathname, currentParams.card])

    return <div>
        <div className={'align-items-center justify-content-between mb-3 mt-3 row'}>
            <div className={'col-auto'}>
                {DashboardLinks}
            </div>
            <div className={'col-auto'}>
                {CardInfo}
            </div>
        </div>
        {/* we want to see the search parameters we edit before even doing the calls.
        the important thing is that we have the latest instance of the search parameters
        AFTER the latest api call */}
        <div className={'row'}>
            <div className={'col-12 col-md px-4'}>
                <SearchRow className={'align-items-center row mb-2'}>
                    <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                        <FaSearch />
                    </label>
                    <input
                        className={'col'}
                        type={'text'}
                        defaultValue={searchParams.q}
                        id={TEXT.SEARCH.SEARCH.ID}
                        onChange={(e) => {
                            dispatch(setQ(e.target.value))
                        }}
                        /** if enter is pressed, render refresh warning */
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                // always set to true because you are pressing enter on the input
                                toggleConfirm(true)
                            }
                        }}
                        placeholder={'Search'}
                    />
                </SearchRow>
            </div>
            <div className={'col-auto pe-5 pe-lg-2'}>
                {ActionButtons}
            </div>
        </div>
        {/* filter interface */}
        <div className={'row mb-2'}>
            {/* display columns aka a filter shere */}
            <div className={'col ps-4'}>
                <div className={'row'}>
                    {
                        _.map(searchParams.boolList, (bool, index) => {
                            return (
                                <FilterCell key={'filter-' + index} className={
                                    ['col-auto me-2 px-2 mt-1',
                                        bool.not ? 'not' : ''].join(' ')
                                }>
                                    <Text size={'xs'}>
                                        {[
                                            bool.not ? '!' : '',
                                            bool.sort,
                                            ': ',
                                            bool.value
                                        ]
                                            .join('')}
                                    </Text>
                                    <Text size={'xs'} className={'ps-2'} onClick={() => {
                                        dispatch(removeBool(bool))
                                    }}>
                                        <FaTimes />
                                    </Text>
                                </FilterCell>
                            )
                        })
                    }
                </div>
            </div>
            <span
                className={[
                    'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                    searchParams.boolList.length ? 'd-block' : 'd-none'
                ].join(' ')}
                onClick={() => {
                    dispatch(removeBoolList())
                }}
            >
                {/* clear all filters button. baseline, not center */}
                <FaTimes />
            </span>
        </div>
        <div className={'row'}>
            <div className={'col-12 col-md-6'}>
                {AzureKubeEvents}
            </div>
            <div className={'col-12 col-md-6'}>
                {AzureKubeMonAgentEvents}
            </div>
        </div>
        <div className={'min-width-fix mb-3'}>
            {/* table stuff */}
            {AzureK8sEventsTable}
        </div>
        {
            renderModals
        }
    </div>
}

export default AzureKubernetes

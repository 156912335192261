import { URL } from '@constants/main/root'
/** we are going to use the wss interface because the type checks are the same. */
import {
    IssueDetailsRequest,
    IssueDetailsResponse,
    IssuesOvertimeRequest,
    IssuesOvertimeResponse,
    ModalRequest,
    ModalResponse,
    SimilarHostsRequest,
    SimilarHostsResponse
} from '@interfaces/dashboard/soc/wss'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const wssApi = createApi({
    reducerPath: 'wssApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** there should be 5 api calls. */
        getModal: builder
            .mutation<ModalResponse, ModalRequest & TokenAuth>({
                query: (body) => {
                    const formData: ModalRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/ext-wss/modal',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getIssueDetails: builder
            /** same request as above */
            .mutation<IssueDetailsResponse, IssueDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: IssueDetailsRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        // number
                        lastupdate: body.lastupdate,
                        id: body.id,
                        name: body.name
                    }

                    return {
                        url: 'api/getData/ext-wss/issuedetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getSimilarHosts: builder
            /** same request as above */
            .mutation<SimilarHostsResponse, SimilarHostsRequest & TokenAuth>({
                query: (body) => {
                    const formData: SimilarHostsRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        // number
                        lastupdate: body.lastupdate,
                        id: body.id,
                        name: body.name
                    }

                    return {
                        url: 'api/getData/ext-wss/similarhosts',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getIssuesOvertime: builder
            /** same request as above */
            .mutation<IssuesOvertimeResponse, IssuesOvertimeRequest & TokenAuth>({
                query: (body) => {
                    const formData: IssuesOvertimeRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        // number
                        lastupdate: body.lastupdate,
                        id: body.id,
                        name: body.name
                    }

                    return {
                        url: 'api/getData/ext-wss/issuesovertime',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetIssueDetailsMutation,
    useGetIssuesOvertimeMutation,
    useGetModalMutation,
    useGetSimilarHostsMutation
} = wssApi

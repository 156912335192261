import { RootState } from '@app/store'
import {
    PrintQueue,
    QueueState
} from '@interfaces/main/print'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'
import React from 'react'

const initialState : QueueState = {
    queues: []
}

export const slice = createSlice({
    name: 'printQueue',
    initialState: initialState,
    reducers: {
        /** dispatch to add a queue */
        addQueue: (state: QueueState, action: PayloadAction<PrintQueue>) => {
            state.queues.push(action.payload)
        },
        /** dispatch to remove a queue */
        removeQueue: (state: QueueState, action: PayloadAction<PrintQueue>) => {
            /** we need to find a filter object that matches. */
            _.remove(state.queues, queue => {
                return (
                    queue.id === action.payload.id
                )
            })
        },
        /** dispatch to update reportId */
        updateReportId: (state: QueueState, action: PayloadAction<{
            queue: PrintQueue,
            value: string
        }>) => {
            const queue = _.find(
                state.queues,
                (modal) => modal.id === action.payload.queue.id
            )

            if (queue) {
                queue.reportId = action.payload.value
            }
        },
        /** dispatch to update isUninitialized */
        updateIsUninitialized: (state: QueueState, action: PayloadAction<{
            queue: PrintQueue,
            value: boolean
        }>) => {
            const queue = _.find(
                state.queues,
                (modal) => modal.id === action.payload.queue.id
            )

            if (queue) {
                queue.isUninitialized = action.payload.value
            }
        },
        /** dispatch to update isPrinting */
        updateIsLoading: (state: QueueState, action: PayloadAction<{
                    queue: PrintQueue,
                    value: boolean
                }>) => {
            const queue = _.find(
                state.queues,
                (modal) => modal.id === action.payload.queue.id
            )

            if (queue) {
                queue.isLoading = action.payload.value
            }
        },
        /** dispatch to update isComplete */
        updateIsSuccess: (state: QueueState, action: PayloadAction<{
            queue: PrintQueue,
            value: boolean
        }>) => {
            const queue = _.find(
                state.queues,
                (modal) => modal.id === action.payload.queue.id
            )

            if (queue) {
                queue.isSuccess = action.payload.value
            }
        },
        /** dispatch to update isPrinting */
        updateIsFaulty: (state: QueueState, action: PayloadAction<{
            queue: PrintQueue,
            value: boolean
        }>) => {
            const queue = _.find(
                state.queues,
                (modal) => modal.id === action.payload.queue.id
            )

            if (queue) {
                queue.isFaulty = action.payload.value
            }
        },
        /** dispatch to update toast */
        updateToast: (state: QueueState, action: PayloadAction<{
                    queue: PrintQueue,
                    value: React.ReactText
                }>) => {
            const queue = _.find(
                state.queues,
                (modal) => modal.id === action.payload.queue.id
            )

            if (queue) {
                queue.toast = action.payload.value
            }
        },
        updateQueues: (state: QueueState, action: PayloadAction<PrintQueue[]>) => {
            state.queues = action.payload
        },
        /** dispatch to reset the state */
        resetQueues: (state: QueueState) => {
            state.queues = initialState.queues
        }
    }
})

export const {
    addQueue,
    removeQueue,
    updateReportId,
    updateIsUninitialized,
    updateIsSuccess,
    updateIsLoading,
    updateIsFaulty,
    updateToast,
    updateQueues,
    resetQueues
} = slice.actions

export const selectQueues = (state: RootState) => state.printQueue.queues

export default slice.reducer

import { URL } from '@constants/main/root'
import {
    GetModalDetailsRequest,
    GetModalDetailsResponse,
    GetModalRequest,
    ModalDataResponse,
    ModalLineChartResponse,
    EventType
} from '@interfaces/dashboard/soc/oss'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    FetchArgs,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

const getOssModalData: (
    body:Omit<GetModalRequest, 'event_type'>,
    eventType:EventType
) => GetModalRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        in_face: body.in_face,
        time_from: body.time_from,
        time_to: body.time_to
    }
}

const createOssQuery: (
    formData: GetModalRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/oss/modal',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

export const ossApi = createApi({
    reducerPath: 'ossApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({

        getModalData: builder
            .mutation<ModalDataResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getOssModalData(body, 'modal.Data')
                    return createOssQuery(formData, body.authToken)
                }
            }),
        getModalLineChart: builder
            .mutation<ModalLineChartResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getOssModalData(body, 'modal.Linechart')
                    return createOssQuery(formData, body.authToken)
                }
            }),
        getModalDetails: builder
            .mutation<GetModalDetailsResponse, GetModalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetModalDetailsRequest = {
                        deviceid: body.deviceid,
                        controlCategory: body.controlCategory,
                        actionType: body.actionType,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/oss/modalDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetModalLineChartMutation,
    useGetModalDataMutation,
    useGetModalDetailsMutation
} = ossApi

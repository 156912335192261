import {
    SortOption,
    FilterOption,
    ArrangeOption
} from '@interfaces/watchdog/configuration/box'
export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Box call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.'
}

export const TEXT = {
    SEARCH: {
        BOXNAME: {
            LABEL: 'Box Name',
            ID: 'SEARCH__BOXNAME'
        },
        SORT: {
            LABEL: 'Sort',
            ID: 'SEARCH__SORT'
        },
        FILTER: {
            LABEL: 'Filter',
            ID: 'SEARCH__FILTER'
        },
        ARRANGE: {
            LABEL: 'Arrange',
            ID: 'SEARCH__ARRANGE'
        },
        SEARCH: 'Refresh'
    }
}

export const SORT_OPTIONS: SortOption[] = [
    {
        label: 'Priority',
        value: 'priority'
    },
    {
        label: 'Name',
        value: 'deviceid'
    },
    {
        label: 'Count',
        value: 'boxData'
    },
    {
        label: 'Box Status',
        value: 'boxStatus'
    }
]

export const FILTER_OPTIONS: FilterOption[] = [
    {
        label: 'None',
        value: ''
    }, /* show all */
    {
        label: 'Online',
        value: 'online'
    }, /* show those that are online */
    {
        label: 'Availability',
        value: 'available'
    } /* show those that are available */
]

export const ARRANGE_OPTIONS: ArrangeOption[] = [
    {
        label: 'Descending',
        value: 'desc'
    },
    {
        label: 'Ascending',
        value: 'asc'
    }
]

import { RootState } from '@app/store'
import { SCROLL_ID_DATA } from '@constants/dashboard/monitor'
import {
    DASHBOARD_DATA as INITIAL_DASHBOARD_DATA,
    DEFAULT_CHART_ZOOMS,
    DEFAULT_REFETCH,
    EXCHANGE_EVENTS_COLUMNS,
    EXCHANGE_USER_ID_COLUMNS,
    INITIAL_SEARCH_PARAMS,
    SUBJECT_COUNTED_TABLE_COLUMNS
} from '@constants/dashboard/soc/o365/detailedDashboard/exchange'
import { tryParseJSON } from '@constants/main/method'
import {
    DEFAULT_TABLE_DATA
} from '@constants/main/root'
import {
    ChartZoom,
    ChartZoomBucket,
    DetailedCard,
    Location,
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    AttachmentDoughnutResponse,
    ClientInfoActivityResponse,
    EventTypeBooleans,
    EventTypeChartZooms,
    ExchangeClientIPDoughnutResponse,
    ExchangeDetailsActivityResponse,
    ExchangeDetailsRulesResponse,
    ExchangeEvent,
    ExchangeEventColumn,
    ExchangeEventFilter,
    ExchangeEventsResponse,
    ExchangeUserActivityLocationsResponse,
    ExchangeUserId,
    ExchangeUserIdColumn,
    ExchangeUserIdCountedTableResponse,
    FixedCollapsibles,
    FromActivityResponse,
    O365DetailedDashboardState,
    Subject,
    SubjectColumn,
    SubjectCountedTableResponse,
    ToActivityResponse
} from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import {
    RefreshTime
} from '@interfaces/main/root'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import deepEqual from 'deep-equal'
import _ from 'lodash'

/** here's the plan for detailed dashboards with different device ids.
 * types are the following: main, sharepoint, exchange and kubernetes.
 */

/**
 * Modified at October 6, 2022: Getting detailed dashboard localStorage
 * will now be an array of DetailedSearchParams that are all partial
 * due to data manipulation implementing strict checks. Moved to the detailed
 * dashboard component since we need the useParams method.
 */

const initialState : O365DetailedDashboardState = {
    /** don't forget to store entire searchParams object in localStorage for
     * page refresh. UseEffect hook with searchParams as the dependency
     * to continuously update all in one place.
     */
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    /** this is just to provide printOptions optional properties
     * on start. If you want the properties to be mandatory,
     * modify them in the state's interface.
     */
    printOptions: {
        logo: ''
    },
    exchangeUserIdTableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: EXCHANGE_USER_ID_COLUMNS,
        filtered: [],
        paginated: []
    },
    subjectTableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: SUBJECT_COUNTED_TABLE_COLUMNS,
        filtered: [],
        paginated: []
    },
    exchangeEventsTableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: EXCHANGE_EVENTS_COLUMNS,
        filtered: [],
        paginated: []
    },
    fixedCollapsibles: _.cloneDeep(DEFAULT_REFETCH),
    /** for chartjs instances when zooming in or zooming out.
     * but we don't need to do it since we will just unmount the entire
     * thing anyway.
     */
    chartZooms: _.cloneDeep(DEFAULT_CHART_ZOOMS),
    /** o365 map where the rest of the data is retrieved in scrollid. */
    mapData: _.cloneDeep(SCROLL_ID_DATA),
    modals: [],
    dashboardData: _.cloneDeep(INITIAL_DASHBOARD_DATA)
}

export const slice = createSlice({
    name: 'o365DbExchange',
    initialState: initialState,
    reducers: {
        /** will be used in o365 modal and selecting different device types. */
        setLocalStorage: (
            state: O365DetailedDashboardState,
            action: PayloadAction<O365DetailedDashboardState['currentParams']>
        ) => {
            /**
             * added in October 7, 2022:
             * since the localStorage is an array of instances where the user
             * navigated to the detailed dashboard for each device id,
             * we will check if there is no instance yet and add accordingly
             */

            // get the current value of the localStorage
            const userJson = localStorage.getItem('o365-db-exchange-search-params')
            if (userJson === null) {
                localStorage.setItem(
                    'o365-db-exchange-search-params',
                    JSON.stringify([
                        action.payload
                    ])
                )
                console.log('Storage nonexistant. Creating new item.')
            } else {
                const result = tryParseJSON(userJson) as unknown as
                O365DetailedDashboardState['currentParams'][]

                /** check if the variable is an array. */
                if (_.isArray(result)) {
                    /** then we find the object using the deviceid as our conditional check */
                    const foundIndex = _.findIndex(result, (obj) => {
                        return obj.card.deviceid === action.payload.card.deviceid
                    })

                    /** don't do !foundIndex. do foundIndex < 0 */

                    if (foundIndex < 0) {
                        // console.log('pushing instance')
                        result.push(action.payload)
                    } else {
                        // if it already exists, just update the values and update
                        // the localStorage
                        // console.log('updating instance')
                        result[foundIndex] = action.payload
                    }

                    localStorage.setItem(
                        'o365-db-exchange-search-params',
                        JSON.stringify(result)
                    )
                }
            }
        },
        setSearchParams: (state: O365DetailedDashboardState, action: PayloadAction<
            O365DetailedDashboardState['searchParams']>
        ) => {
            state.searchParams = action.payload
        },
        setStartDate: (state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: O365DetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeBooleans,
            value: boolean
        }>) => {
            state.searchParams.refetch[action.payload.key] = action.payload.value
        },
        setLogo: (state: O365DetailedDashboardState, action: PayloadAction<string>) => {
            state.printOptions.logo = action.payload
        },
        setCard: (state: O365DetailedDashboardState, action: PayloadAction<DetailedCard>) => {
            state.searchParams.card = action.payload
        },
        setQ: (state: O365DetailedDashboardState, action: PayloadAction<string>) => {
            state.searchParams.q = action.payload
        },
        /** shouldn't be confused with adding filters to table data. */
        setBoolList: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeEventFilter[]>) => {
            state.searchParams.boolList = action.payload
        },
        addBool: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeEventFilter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.searchParams.boolList, bool => {
                return (
                    bool.not === action.payload.not &&
                    bool.sort === action.payload.sort &&
                    bool.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.searchParams.boolList.push(action.payload)
            }
        },
        removeBool: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeEventFilter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.searchParams.boolList, bool => {
                return (
                    bool.not === action.payload.not &&
                    bool.sort === action.payload.sort &&
                    deepEqual(bool.value, action.payload.value)
                )
            })
        },
        removeBoolList: (state: O365DetailedDashboardState) => {
            state.searchParams.boolList = []
        },
        setCurrentParams: (state: O365DetailedDashboardState, action: PayloadAction<
            O365DetailedDashboardState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        /** for ExchangeUserId.CountedTable. No filters. */
        setExchangeUserIdPage: (
            state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.exchangeUserIdTableData.page = action.payload
        },
        setExchangeUserIdCount: (
            state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.exchangeUserIdTableData.count = action.payload
        },
        setExchangeUserIdSearch: (
            state: O365DetailedDashboardState, action: PayloadAction<string>) => {
            state.exchangeUserIdTableData.search = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setExchangeUserIdColumns: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeUserIdColumn[]>) => {
            state.exchangeUserIdTableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setExchangeUserIdColumnInclude: (state: O365DetailedDashboardState, action: PayloadAction<{
            value: ExchangeUserIdColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.exchangeUserIdTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setExchangeUserIdColumnArrange: (state: O365DetailedDashboardState, action: PayloadAction<{
            value: ExchangeUserIdColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.exchangeUserIdTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setExchangeUserIdFiltered: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeUserId[]>) => {
            state.exchangeUserIdTableData.filtered = action.payload
        },
        setExchangeUserIdPaginated: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeUserId[]>) => {
            state.exchangeUserIdTableData.paginated = action.payload
        },
        /** for Subject.CountedTable */
        setSubjectPage: (state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.subjectTableData.page = action.payload
        },
        setSubjectCount: (state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.subjectTableData.count = action.payload
        },
        setSubjectSearch: (state: O365DetailedDashboardState, action: PayloadAction<string>) => {
            state.subjectTableData.search = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setSubjectColumns: (
            state: O365DetailedDashboardState, action: PayloadAction<SubjectColumn[]>) => {
            state.subjectTableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setSubjectColumnInclude: (state: O365DetailedDashboardState, action: PayloadAction<{
            value: SubjectColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.subjectTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setSubjectColumnArrange: (state: O365DetailedDashboardState, action: PayloadAction<{
            value: SubjectColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.subjectTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setSubjectFiltered: (
            state: O365DetailedDashboardState, action: PayloadAction<Subject[]>) => {
            state.subjectTableData.filtered = action.payload
        },
        setSubjectPaginated: (
            state: O365DetailedDashboardState, action: PayloadAction<Subject[]>) => {
            state.subjectTableData.paginated = action.payload
        },
        /** for sharepoint.Events. */
        setExchangeEventPage: (
            state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.exchangeEventsTableData.page = action.payload
        },
        setExchangeEventCount: (
            state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.exchangeEventsTableData.count = action.payload
        },
        /** sets interval object. */
        setExchangeEventIntervalObj: (
            state: O365DetailedDashboardState, action: PayloadAction<RefreshTime>) => {
            state.exchangeEventsTableData.interval = action.payload
        },
        setExchangeEventSearch: (
            state: O365DetailedDashboardState, action: PayloadAction<string>) => {
            state.exchangeEventsTableData.search = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setExchangeEventColumns: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeEventColumn[]>) => {
            state.exchangeEventsTableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setExchangeEventColumnInclude: (state: O365DetailedDashboardState, action: PayloadAction<{
            value: ExchangeEventColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.exchangeEventsTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setExchangeEventColumnArrange: (state: O365DetailedDashboardState, action: PayloadAction<{
            value: ExchangeEventColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.exchangeEventsTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setExchangeEventFiltered: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeEvent[]>) => {
            state.exchangeEventsTableData.filtered = action.payload
        },
        setExchangeEventPaginated: (
            state: O365DetailedDashboardState, action: PayloadAction<ExchangeEvent[]>) => {
            state.exchangeEventsTableData.paginated = action.payload
        },
        toggleCollapsible: (state: O365DetailedDashboardState, action: PayloadAction<{
            key: keyof FixedCollapsibles,
            value: boolean
        }>) => {
            state.fixedCollapsibles[action.payload.key] = action.payload.value
        },
        setChartZoomLevel: (state: O365DetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: number
        }>) => {
            state.chartZooms[action.payload.key].zoomLevel = action.payload.value
        },
        setChartTitle: (state: O365DetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: string
        }>) => {
            state.chartZooms[action.payload.key].title = action.payload.value
        },
        setChartBucket3: (state: O365DetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: ChartZoomBucket
        }>) => {
            state.chartZooms[action.payload.key].bucket = action.payload.value
        },
        setChartZoom: (state: O365DetailedDashboardState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: ChartZoom
        }>) => {
            state.chartZooms[action.payload.key] = action.payload.value
        },
        /** set totalRecords on initial result and after every scrollid,
         * update the array. that's it.
         */
        setTotalRecords: (state: O365DetailedDashboardState, action: PayloadAction<number>) => {
            state.mapData.totalRecords = action.payload
        },
        setIsComplete: (state: O365DetailedDashboardState, action: PayloadAction<boolean>) => {
            state.mapData.isComplete = action.payload
        },
        setScrollId: (state: O365DetailedDashboardState, action: PayloadAction<string>) => {
            state.mapData.scrollId = action.payload
        },
        setMapData: (state: O365DetailedDashboardState, action: PayloadAction<Location[]>) => {
            state.mapData.data = action.payload
        },
        addModal: (state: O365DetailedDashboardState, action: PayloadAction<MonitorModal>) => {
            state.modals.push(action.payload)
        },
        closeModal: (state: O365DetailedDashboardState, action: PayloadAction<MonitorModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.open = false
            }
        },
        removeModal: (state: O365DetailedDashboardState, action: PayloadAction<MonitorModal>) => {
            _.remove(state.modals, modal => {
                return (
                    modal.id === action.payload.id
                )
            })
        },
        /** the only times you will set the data to undefined are when
         * you navigate to this page (dashboard card, modal, and the sidebar component)
         */
        setAttachmentDoughnutData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<AttachmentDoughnutResponse | undefined>
        ) => {
            state.dashboardData['Attachments.Doughnut'] = action.payload
        },
        setExchangeClientIPDoughnutData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ExchangeClientIPDoughnutResponse | undefined>
        ) => {
            state.dashboardData['ExchangeClientIP.Doughnut'] = action.payload
        },
        setExchangeDetailsActivityData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ExchangeDetailsActivityResponse | undefined>
        ) => {
            state.dashboardData['ExchangeDetails.Activity'] = action.payload
        },
        setExchangeUserActivityLocationsData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ExchangeUserActivityLocationsResponse | undefined>
        ) => {
            state.dashboardData['ExchangeUserActivity.Locations'] = action.payload
        },
        setFromActivityData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<FromActivityResponse | undefined>
        ) => {
            state.dashboardData['From.Activity'] = action.payload
        },
        setToActivityData: (
            state: O365DetailedDashboardState, action: PayloadAction<ToActivityResponse | undefined>
        ) => {
            state.dashboardData['To.Activity'] = action.payload
        },
        setClientInfoActivityData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ClientInfoActivityResponse | undefined>
        ) => {
            state.dashboardData['ClientInfo.Activity'] = action.payload
        },
        setExchangeDetailsRulesData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ExchangeDetailsRulesResponse | undefined>
        ) => {
            state.dashboardData['ExchangeDetails.Rules'] = action.payload
        },
        setSubjectCountedTableData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<SubjectCountedTableResponse | undefined>
        ) => {
            state.dashboardData['Subject.CountedTable'] = action.payload
        },
        setExchangeUserIdCountedTableData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ExchangeUserIdCountedTableResponse | undefined>
        ) => {
            state.dashboardData['ExchangeUserId.CountedTable'] = action.payload
        },
        setExchangeEventsData: (
            state: O365DetailedDashboardState,
            action: PayloadAction<ExchangeEventsResponse | undefined>
        ) => {
            state.dashboardData['Exchange.Events'] = action.payload
        },
        resetO365Detailed: (state: O365DetailedDashboardState) => {
            /** October 5, 2022: simplified bug issues into case scenarios:
             *
             *  CASE:
             * - Logs in to dashboard.
             * - Selects dashboard card.
             * - Modifies search parameters
             * - Dashboard refreshes results.
             * - Logs out and in again
             * - Navigates to dashboard via url.
             *
             * Detailed dashboard localStorage should ONLY be cleared
             * if the current deviceid is different from the selected
             * dashboard card's.
             *
             * For the state, only reset it IF the user is deauthenticated.
             * For the properties, preserve search parameters.
             *
             * Addressing an issue where one navigates the same url
             * but with different deviceids IN multiple tabs at once.
             *
             * Avoid using sessionStorage as multiple tabs can interchange
             * on what cards they want to use.
             *
             * We can make the item an array of instances
             * where in the user navigated to the detailed dashboard using
             * that specific deviceid name AT LEAST once.
             */

            state.chartZooms = initialState.chartZooms
            state.currentParams = initialState.currentParams
            state.dashboardData = initialState.dashboardData
            state.fixedCollapsibles = initialState.fixedCollapsibles
            state.mapData = initialState.mapData
            state.modals = initialState.modals
            state.printOptions = initialState.printOptions
            state.searchParams = initialState.searchParams
            state.exchangeUserIdTableData = initialState.exchangeUserIdTableData
            state.subjectTableData = initialState.subjectTableData
            state.exchangeEventsTableData = initialState.exchangeEventsTableData
        }
    }
})

export const {
    setLocalStorage,
    setSearchParams,
    setStartDate,
    setEndDate,
    setCard,
    setRefetch,
    setLogo,
    setQ,
    setBoolList,
    addBool,
    removeBool,
    removeBoolList,
    setCurrentParams,
    /** ExchangeUserId table data */
    setExchangeUserIdPage,
    setExchangeUserIdCount,
    setExchangeUserIdSearch,
    setExchangeUserIdColumns,
    setExchangeUserIdColumnInclude,
    setExchangeUserIdColumnArrange,
    setExchangeUserIdFiltered,
    setExchangeUserIdPaginated,
    /** Subject table data */
    setSubjectPage,
    setSubjectCount,
    setSubjectSearch,
    setSubjectColumns,
    setSubjectColumnInclude,
    setSubjectColumnArrange,
    setSubjectFiltered,
    setSubjectPaginated,
    /** sharepoint events table data */
    setExchangeEventPage,
    setExchangeEventCount,
    setExchangeEventIntervalObj,
    setExchangeEventSearch,
    setExchangeEventColumns,
    setExchangeEventColumnInclude,
    setExchangeEventColumnArrange,
    setExchangeEventFiltered,
    setExchangeEventPaginated,
    setChartZoomLevel,
    setChartTitle,
    setChartBucket3,
    setChartZoom,
    setTotalRecords,
    setIsComplete,
    setScrollId,
    setMapData,
    addModal,
    closeModal,
    removeModal,
    setAttachmentDoughnutData,
    setClientInfoActivityData,
    setExchangeClientIPDoughnutData,
    setExchangeDetailsActivityData,
    setExchangeDetailsRulesData,
    setExchangeUserActivityLocationsData,
    setExchangeUserIdCountedTableData,
    setFromActivityData,
    setSubjectCountedTableData,
    setToActivityData,
    setExchangeEventsData,
    toggleCollapsible,
    resetO365Detailed
} = slice.actions

export const selectSearchParams = (state: RootState) => state
    .o365DbExchange.searchParams
export const selectCurrentParams = (state: RootState) => state
    .o365DbExchange.currentParams
export const selectPrintOptions = (state: RootState) => state
    .o365DbExchange.printOptions
export const selectExchangeUserIdTableData = (state: RootState) => state
    .o365DbExchange.exchangeUserIdTableData
export const selectSubjectTableData = (state: RootState) => state
    .o365DbExchange.subjectTableData
export const selectExchangeEventTableData = (state: RootState) => state
    .o365DbExchange.exchangeEventsTableData
export const selectFixedCollapsibles = (state: RootState) => state
    .o365DbExchange.fixedCollapsibles
export const selectChartZooms = (state: RootState) => state
    .o365DbExchange.chartZooms
export const selectMapData = (state: RootState) => state
    .o365DbExchange.mapData
export const selectModals = (state: RootState) => state.o365DbExchange.modals
export const selectDashboardData = (state: RootState) => state
    .o365DbExchange.dashboardData

export default slice.reducer

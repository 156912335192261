import {
    PartnerAction,
    PartnerColumn,
    PartnerKeys,
    AddPartnerValues,
    UpdatePartnerValues,
    AddPartnerKeys,
    UpdatePartnerKeys
} from '@interfaces/watchdog/account-data/partner'
import _ from 'lodash'
import * as Yup from 'yup'
import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Partner call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when partnerID is undefined */
    UNDEFINED_ID: 'Empty partnerID detected.'

}

const ADD_FORM = {
    ACTIVE: {
        LABEL: 'Is Active?',
        ID: 'ADD__ACTIVE'
    },
    COMPANY_NAME: {
        LABEL: 'Company Name',
        ID: 'ADD__COMPANY_NAME'
    },
    PARTNER_STATE: {
        LABEL: 'Partner State',
        ID: 'ADD__PARTNER_STATE'
    },
    PARTNER_NAME: {
        LABEL: 'Partner Name',
        ID: 'ADD__PARTNER_NAME'
    },
    EMAIL: {
        LABEL: 'E-mail',
        ID: 'ADD__EMAIL'
    },
    STREET_NAME: {
        LABEL: 'Street Name',
        ID: 'ADD__STREET_NAME'
    },
    POSTAL_CODE: {
        LABEL: 'Postal Code',
        ID: 'ADD__POSTAL_CODE'
    },
    CITY: {
        LABEL: 'City',
        ID: 'ADD__CITY'
    },
    COUNTRY: {
        LABEL: 'Country',
        ID: 'ADD__COUNTRY'
    },
    DISCOUNT: {
        LABEL: 'Discount',
        ID: 'ADD__DISCOUNT'
    },
    PARTNER_TYPE: {
        LABEL: 'Partner Type',
        ID: 'ADD__PARTNER_TYPE'
    },
    PARTNER_OF: {
        LABEL: 'Partner Of',
        ID: 'ADD__PARTNER_OF'
    },
    CURRENCIES: {
        // LABEL: 'Currencies to Support',
        LABEL: 'Currency',
        ID: 'ADD__CURRENCIES'
    }
}

export const TEXT = {
    ADD: {
        /** confirmation label for adding a partner */
        TITLE: 'Add Partner Confirmation',
        /** confirmation message for adding a partner */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit the partner data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Adding Partner',
            SUBMIT_BUTTON: 'Add Partner'
        }
    },
    UPDATE: {
        /** confirmation label for updating a partner */
        TITLE: 'Update Partner Confirmation',
        /** confirmation message for updating a partner */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit the partner data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Updating Partner',
            SUBMIT_BUTTON: 'Update Partner'
        }
    },
    DELETE: {
        /** confirmation label for updating a partner */
        TITLE: 'Delete Partner Confirmation',
        /** confirmation message for updating a partner */
        CONFIRMATION_MESSAGE: 'Are you sure you want to delete the partner data?',
        FORM: {
            LOADING_BUTTON: 'Deleting Partner',
            SUBMIT_BUTTON: 'Delete Partner'
        }
    }
}

export const ACTIONS: PartnerAction[] = [
    // {
    //     label: 'View',
    //     value: 'PARTNER_VIEW',
    //     mode: 'primary'
    // },
    {
        label: 'Update',
        value: 'PARTNER_UPDATE',
        mode: 'primary'
    },
    {
        label: 'Delete',
        value: 'PARTNER_DELETE',
        mode: 'danger'
    }
]

/** the value of property can only be the property names
 * of the Partners interface, so it should be throwing
 * errors if the string wasn't any of those keys.
 */

export const COLUMNS: {
    MAIN: PartnerColumn[],
} = {
    MAIN: (() => {
        const columnNames: Omit<PartnerColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Company Name',
                value: 'company_name'
            }, {
                label: 'Partner Name',
                value: 'partner_name'
            }, {
                label: 'Partner State',
                value: 'partner_state'
            }, {
                label: 'Partner Type',
                value: 'partner_type'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: PartnerColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })()
}

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */
export const KEYS: {
    MAIN: PartnerKeys[]
} = {
    MAIN: [
        'active', 'city', 'company_name', 'country', 'discount', 'email',
        'partner_name', 'partner_of', 'partner_state', 'partner_type',
        'postalcode', 'street_name'
    ]
}

/**
 * the following routes require a formik hook
 * /partners-add, /partners-view, /partners-update
 * they all share the same format to respect CRUD.
 * rely on an operation property in the partner state
 * when navigating pages.
 */

/**
 * another added design restriction, don't use the addModal dispatch
 * attempted to do so and we had to import components and convert file to tsx.
 * SOLUTION: just use a boolean and initiate a useEffect in the component called
 * to add the components yourself. That means any api calls will be relocated
 * there.
 * */

const DEFAULT_FORM_VALUES: AddPartnerValues = {
    // changes default value from false to true
    active: true,
    companyName: '',
    partnerState: '',
    partnerName: '',
    email: '',
    streetName: '',
    postalCode: '',
    city: '',
    country: '',
    // changes default value from '' to '0'
    discount: '0',
    partnerType: '',
    partnerOf: '',
    currencies: []
}

export const INITIAL_VALUES: {
    ADD: AddPartnerValues,
    UPDATE: UpdatePartnerValues
} = {
    ADD: _.cloneDeep(DEFAULT_FORM_VALUES),
    UPDATE: _.cloneDeep(DEFAULT_FORM_VALUES)
}

const DEFAULT_VALIDATION_SCHEMA = {
    active: Yup.boolean().default(false),
    companyName: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    partnerState: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    partnerName: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    email: Yup.string()
        .email(ROOT_MESSAGE.ERROR.FORM.EMAIL)
        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    streetName: Yup.string(),
    postalCode: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    discount: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    partnerType: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    partnerOf: Yup.string().when('partnerType', {
        is: '2',
        then: Yup.string().required('partnerOf is required when partnerType is a reseller'),
        otherwise: Yup.string()
    }),
    currencies: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string().required('Currency label is required'),
                value: Yup.string().required('Currency value is required')
            })
        )
        .test('atLeastOneCurrency', 'At least one currency is required', function (value) {
            const { partnerType, partnerOf } = this.parent

            if (partnerType === '2' && (!partnerOf || partnerOf.trim().length === 0)) {
                if (Array.isArray(value) && value.length > 0 &&
                    value.every(item => item.value && item.value.length > 0)
                ) {
                    return true
                }
                return this.createError({
                    // eslint-disable-next-line max-len
                    message: 'Is a seller, partner of is empty so at least one currency is required',
                    path: 'currencies'
                })
            } else if (partnerType === '2' && (partnerOf.trim().length > 0)) {
                if (Array.isArray(value) && value.length <= 0) {
                    return true
                }
                return this.createError({
                    // eslint-disable-next-line max-len
                    message: 'Is a seller, partner of is defined so there should not be a currency',
                    path: 'currencies'
                })
            } else if (partnerType === '3' && !partnerOf) {
                if (Array.isArray(value) && value.length > 0 &&
                value.every(item => item.value && item.value.length > 0)
                ) {
                    return true
                }

                return this.createError({
                    message: 'Is a distributor so at least one currency is required',
                    path: 'currencies'
                })
            }

            return true
        })
}

export const VALIDATION_SCHEMA: {
    ADD: OptionalObjectSchema<Record<AddPartnerKeys, Yup.AnySchema>>
    UPDATE: OptionalObjectSchema<Record<UpdatePartnerKeys, Yup.AnySchema>>
} = {
    ADD: Yup.object(_.cloneDeep(DEFAULT_VALIDATION_SCHEMA)),
    UPDATE: Yup.object(_.cloneDeep(DEFAULT_VALIDATION_SCHEMA))
}

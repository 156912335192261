
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MAC_NEW_COLUMNS,
    MESSAGE as RDD_MESSAGE
} from '@constants/dashboard/soc/rdd'
import {
    MonitorModal,
    RddDetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    MacReappearResponse
} from '@interfaces/dashboard/soc/rdd'
import {
    ColorPresets
} from '@interfaces/main/root'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/rdd/details'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/rdd/main'
import {
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useMemo
} from 'react'
import uniqueString from 'unique-string'

interface ComponentProps {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    macReappearData: MacReappearResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const MacReappearTable = ({
    macReappearData, isLoading, isSuccess, error,
    modal, addModal, closeModal, changeModalColor
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)

    const dispatch = useAppDispatch()

    const DataTable = useMemo(() => {
        const data = macReappearData?.differenceList || []

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            /** also uses MAC_NEW_COLUMNS */
                            _.map(MAC_NEW_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'macReappear-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(data, (difference, index) => {
                            const key = [
                                'macReappear-td-', index
                            ].join('')

                            const onClick = () => {
                                dispatch(setDetailsStartDate(currentParams.ranges.start))
                                dispatch(setDetailsEndDate(currentParams.ranges.end))

                                const detailsContent:RddDetailsForm = {
                                    mac_add: difference.mac_addr,
                                    mac_type: difference.mac_type
                                }

                                const serviceTypeFormData:ServiceTypeFormData = {
                                    rdd: {
                                        details: detailsContent
                                    }
                                }

                                dispatch(addModal({
                                    id: uniqueString(),
                                    open: true,
                                    card: modal.card,
                                    operation: 'DETAILS',
                                    serviceTypeFormData: serviceTypeFormData,
                                    isBorderWide: true
                                }))
                            }

                            return (
                                <tr onClick={onClick} key={key}>
                                    <td>{difference.mac_addr}</td>
                                    <td>{difference.mac_type}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{RDD_MESSAGE.FETCH.MAC_REAPPEAR}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, [
        macReappearData,
        currentParams
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{RDD_MESSAGE.FETCH.MAC_REAPPEAR}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess ? DataTable : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

MacReappearTable.propTypes = {
    macReappearData: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default MacReappearTable

import {
    useUpdateOrderMutation
} from '@apis/watchdog/account-data/order-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as ORDER_MESSAGE,
    TEXT as ORDER_TEXT
} from '@constants/watchdog/account-data/order'
import {
    OrderModal
} from '@interfaces/watchdog/account-data/order'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const UpdateConfirmation = ({ modal, addModal, closeModal } : {
    modal: OrderModal,
    addModal: ActionCreatorWithPayload<OrderModal, string>,
    closeModal: ActionCreatorWithPayload<OrderModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [updateOrder, updateOrderMutation] = useUpdateOrderMutation()

    /** call formiks */

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData?.updateConfirm) {
            const id = modal.formData.updateConfirm.id
            const values = modal.formData.updateConfirm.formValues

            updateOrder({
                orderID: id,
                authToken: newToken,
                order_type: values.order_type,
                name: values.name,
                setupInstructions: values.setupInstructions,
                email: values.email,
                comment: encodeURIComponent(values.comment),
                active: values.active,
                activation_date: values.activation_date,
                currency: Number(values.currency),
                customer_name: values.customer_name,
                partner_name: values.partner_name,
                // shouldn't be a string.
                orderLines: values.orderLines
            })
        }
    }, [
        modal.formData
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (updateOrderMutation.data) {
            const data = updateOrderMutation.data
            if (data.statusMessage) {
                toast.success(data.statusMessage, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(closeModal(modal))
            } else {
                toast.error(
                    ORDER_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [updateOrderMutation.data])

    useEffect(() => {
        if (updateOrderMutation.error) {
            console.error(updateOrderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateOrderMutation.error])

    /** confirmation window for both update locations AND update m365 */
    const SubmitButton = useMemo(() => {
        const buttonContent = updateOrderMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{ORDER_TEXT.UPDATE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : ORDER_TEXT.UPDATE.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={submitData}
                disabled={updateOrderMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [updateOrderMutation.isLoading])

    return (<div>
        <h5>{ORDER_TEXT.UPDATE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {ORDER_TEXT.UPDATE.CONFIRMATION_MESSAGE}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default UpdateConfirmation

import { RootState } from '@app/store'
import { URL } from '@constants/main/root'
import { TokenResponse } from '@interfaces/main/token'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

/** checks validation for existing token. expected an object with the token and code (status) */
export const tokenApi = createApi({
    reducerPath: 'tokenApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API,
        prepareHeaders: (headers, { getState }) => {
            const state = (getState() as RootState)
            // if falsy, don't set an authorization header
            if (state.token.data.value) {
                headers.set('Authorization', `Bearer ${ state.token.data.value }`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        // will not use build.query unless method type is get or undefined.
        tokenValid: builder.mutation<TokenResponse, unknown>({
            query: () => {
                return {
                    url: 'api/tokenValid'
                }
            }
        })
    })
})

export const { useTokenValidMutation } = tokenApi


import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE as O365_MESSAGE,
    ONEDRIVE_DETAILS_ACTIVITY_COLUMNS,
    SHAREPOINT_DETAILS_ACTIVITY_COLUMNS,
    SITE_URL_ACTIVITY_COLUMNS,
    SOURCE_FILE_EXTENSION_ACTIVITY_COLUMNS
} from '@constants/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    assignIntervalTick,
    createIntervals,
    hideChartTooltip,
    showChartTooltip
} from '@constants/main/method'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_CHART_PADDING,
    DEFAULT_INTERVAL,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import { OVERALL_COLUMNS } from '@constants/dashboard/monitor'
import {
    EventType,
    SharePointEventKeys
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    Bucket,
    Doc,
    O365DetailsForm,
    ServiceTypeFormData,
    ChartZoomBucket
} from '@interfaces/dashboard/monitor'
import {
    selectFixedCollapsibles,
    setChartZoomLevel,
    setChartTitle,
    setChartBucket3,
    selectChartZooms,
    addModal,
    selectCurrentParams,
    addBool,
    selectDashboardData
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/o365/details'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    HoverFilter,
    SpinnerContainer,
    Table
} from '@styles/components'
import {
    ArcElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    DoughnutController,
    Tooltip,
    BarController,
    BarElement,
    TimeScale,
    Title
} from 'chart.js'
import {
    add,
    format,
    fromUnixTime,
    getTime,
    getUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { createStylesheet } from '@styles/themes'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

import uniqueString from 'unique-string'
import {
    FaSearchPlus,
    FaSearchMinus
} from 'react-icons/fa'
import Tippy from '@tippyjs/react'

interface ComponentProps {
    eventType: Exclude<EventType, 'SharePoint.Events' | 'SharepointUserActivity.Locations' |
    'SharepointUserId.CountedTable' | 'SourceFileName.CountedTable' |
    'SourceFileExtension.Doughnut' | 'SharepointClientIP.Doughnut'>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const O365BarChart = ({ eventType, isLoading, isSuccess, error }:ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const chartZooms = useAppSelector(selectChartZooms)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)

    const dispatch = useAppDispatch()

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const [
        chartInstance,
        setChartInstance
    ] = useState<Chart<'bar', { x: string; y: number; }[], string>>()

    const zoomEl = useRef<HTMLCanvasElement>(null)
    const [
        zoomInstance,
        setZoomInstance
    ] = useState<Chart<'bar', number[], string>>()

    /** colors for both chart and table. will be set in chart initialization but will
     * only target lifecycle of table. doing so in chart will trigger an infinite render loop.
    */
    const [chartColors, setChartColors] = useState<string[]>([])

    /** i don't want to duplicate the code snippet so we'll just create
     * this separately. Plus the pseudo code ended up taking up more than 300 lines.
     * we end up having to make each chart a separate file BUT at least it's somewhere else.
     *
     * UPDATE: 9/16/2022. Ended up being one chart instance with multiple switch cases.
     */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** There was an attempt to make a useMemo BUT realized that
     * the lifecycle would be inconsistent. we want a cleanup function
     * before creating the chart instance for every lifecycle.
     *
     * one more thing, only memoize the data on performance leaks.
     * doing so ignoring this reminder led to chart tooltip drawing
     * errors especially when you have useRefs to create visualizations.
     *
     * this occured when memozing the table (an alternative to display
     * data counts).
     *
     * TypeError: Cannot read properties of undefined (reading 'handleEvent')
     * this error above occurs when the dispatches on that onclick event
     * are in the wrong order.
     *
     * Issue with search params where you update the ranges. It
     * will mess up the form data for fetching either o365 details or data id
     * when selecting the first OR last dataset
     * in the charts OR the tables below them.
     */

    /** function to dispatch chartZooms state data */
    const setChartZoom = (
        startKey: Date,
        endKey: Date,
        chartBucket: ChartZoomBucket) => {
        const startString = format(startKey, DATE_FORMAT_TIME)
        const endString = format(endKey, DATE_FORMAT_TIME)

        dispatch(setChartTitle({
            key: eventType,
            // show both dates.
            value: [
                startString, 'to', endString
            ].join(' ')
        }))
        dispatch(setChartBucket3({
            key: eventType,
            value: chartBucket
        }))

        /** to mark the date ranges:
         * start date: selected point.
         * end date: next point. needed for o365 details
         * and o365 data id.
         */

        dispatch(setChartZoomLevel({
            key: eventType,
            value: 1
        }))
    }

    const setDetailsModal = (serviceTypeFormData: ServiceTypeFormData) => {
        const startKey = chartZooms[eventType].bucket?.key_as_string
        const endKey = chartZooms[eventType].bucket?.end_key_as_string

        /** ERROR: you'll get more data than the current doc_count
         * because it will use the timezone offset again and
         * change these values. You don't want this.
         *
         * Fix is to add the key_as_string values with the timezone
         * offset multiplying it by -1 first.
         */

        const timezoneOffset = new Date().getTimezoneOffset()

        // don't fetch if either keys are undefined.
        if (startKey && endKey) {
            const startDate = getUnixTime(
                add(
                    new Date(startKey),
                    { minutes: timezoneOffset * -1 }
                )
            )

            const endDate = getUnixTime(
                add(
                    new Date(endKey),
                    { minutes: timezoneOffset * -1 }
                )
            )

            dispatch(setDetailsStartDate(startDate))
            dispatch(setDetailsEndDate(endDate))

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                card: currentParams.card,
                operation: 'DETAILS',
                serviceTypeFormData: serviceTypeFormData,
                isBorderWide: true
            }))
        } else {
            console.error('Date keys are missing. Not adding modal')
        }
    }

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const currentData = dashboardData[eventType]
            ?.aggregations?.[2]?.buckets || []
        const fixedInterval = dashboardData[eventType]
            ?.fixedInterval || DEFAULT_INTERVAL
        let graph: Chart<'bar', { bucket: Bucket, x: string; y: number; }[], string>

        /** find a max value at this grouped data would be code inefficient
         * so we will remove the max property.
         */

        if (chartEl.current) {
            /** if there is only one current data record, the default value would be
             * the same interval.
             */
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            if (currentData.length >= 2) {
                ranges.start = new Date(
                    currentData[0].key_as_string ||
                    fromUnixTime(currentParams.ranges.start)
                )
                ranges.end = new Date(
                    currentData[currentData.length - 1].key_as_string ||
                    fromUnixTime(currentParams.ranges.end)
                )
            }

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            _.forEach(currentData, (bucket2) => {
                const dateString = bucket2.key_as_string

                if (dateString) {
                    /** iterate the intervals array we made. */
                    for (let index = 0; index < intervals.length; index++) {
                        /** get the current and subsequent interval values */
                        const intervalOne = new Date(intervals[index])
                        const intervalTwo = new Date(intervals[index + 1])

                        /** check if dateString exists in intervals. If found
                         * break the loop and proceed to the next iteration.
                         */
                        const found = _.find(intervals, interval => {
                            return _.isEqual(
                                format(new Date(dateString), DATE_FORMAT_TIME),
                                interval
                            )
                        })

                        if (found) {
                            break
                        }

                        /** taken from mdr bar chart. Check if bucket2.key_as_string
                         * is inbetween said intervals. If it does, insert it via
                         * splice AND break the loop. Continue until you find one.
                         */

                        if (
                            isValid(intervalTwo) &&
                            isWithinInterval(
                                new Date(dateString),
                                {
                                    start: intervalOne,
                                    end: intervalTwo
                                }
                            )
                        ) {
                            /** MAKE SURE YOU are inserting the dateString in the
                             * correct format
                             */
                            intervals.splice(
                                index, 0, format(
                                    new Date(dateString), DATE_FORMAT_TIME
                                )
                            )
                            break
                        }
                    }
                }
            })

            const datasets: typeof graph.data.datasets = [
                {
                    data: _.map(intervals, (interval) => {
                        const found: Bucket = _.find(currentData, (bucket) => {
                            const dateString = bucket.key_as_string
                            if (dateString) {
                                return _.isEqual(
                                    format(new Date(dateString),
                                        DATE_FORMAT_TIME), interval
                                )
                            } else {
                                return false
                            }
                        }) || {
                            key_as_string: interval,
                            key: getTime(new Date(interval)),
                            doc_count: 0,
                            3: {
                                doc_count_error_upper_bound: 0,
                                sum_other_doc_count: 0,
                                buckets: []
                            }
                        }

                        return {
                            bucket: found,
                            x: interval,
                            y: found.doc_count
                        }
                    }),
                    backgroundColor: stylesheet.style.buttonTypeColors.primary,
                    normalized: true,
                    parsing: false,
                    barThickness: 'flex',
                    maxBarThickness: 15,
                    barPercentage: 0.7
                }
            ]

            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: true,
                            text: [
                                TEXT.ZERO_ZOOM_LEVEL, '-',
                                format(ranges.start, DATE_FORMAT_TIME), 'to',
                                format(ranges.end, DATE_FORMAT_TIME)
                            ].join(' '),
                            color: stylesheet.mode.fontColor
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,

                                display: false
                            },
                            min: 0
                        }
                    }
                }
            })

            graph.options.onClick = (event) => {
                if (event.native) {
                    const points = graph.getElementsAtEventForMode(
                        event.native, 'nearest', { intersect: true }, true
                    )

                    if (points.length) {
                        const firstPoint = points[0]

                        const data = graph.data
                            .datasets[firstPoint.datasetIndex].data
                        const value = data[firstPoint.index]
                        // can be undefined due to index selector
                        const nextValue = data[firstPoint.index + 1]

                        /** initiate zoom in AND set bucket3 */
                        /** not all bucket2 records have a bucket3
                         * so you don't want to zoom in if this was
                         * the case.
                         */
                        if (value.bucket) {
                            const endKey = nextValue?.bucket?.key_as_string
                                ? nextValue?.bucket?.key_as_string
                                : format(
                                    fromUnixTime(currentParams.ranges.end),
                                    DATE_FORMAT_TIME
                                )

                            const chartBucket: ChartZoomBucket = {
                                ...value.bucket,
                                end_key_as_string: endKey
                            }

                            /**
                             * console.log('chart bucket to check if count is
                             * correct:', value.bucket)
                             *
                             * culprit found at the sharepointDashboard calls.
                             * doc_count and bucket length mismatch on some
                             * entries. IF YOU ARE READING THIS, it's fine.
                             * you'll assess the queries once you have
                             * access to the backend.
                             */

                            setChartZoom(
                                new Date(value.x),
                                new Date(endKey),
                                chartBucket
                            )
                        }
                    }
                }
            }

            chartEl.current.style.height = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        dashboardData[eventType]
    ])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const buckets = _.orderBy(
            chartZooms[eventType].bucket?.[3]?.buckets || [],
            ['doc_count'], ['desc']
        )
        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'bar', number[], string>

        /** you only want this chart to be created when there's data. */
        if (
            zoomEl.current &&
            chartZooms[eventType].zoomLevel === 1
        ) {
            const colors: string[] = _.map(
                buckets, (_i, index) => CHART_COLORS[index]
            )

            const datasets: typeof graph.data.datasets = [{
                data: _.map(buckets, (bucket) => bucket.doc_count),
                // random color because of each key.
                backgroundColor: colors,
                normalized: true,
                barThickness: 'flex',
                maxBarThickness: 15,
                barPercentage: 0.7
            }]

            setChartColors(colors)

            graph = new Chart(zoomEl.current, {
                type: 'bar',
                data: {
                    labels: _.map(buckets, (bucket) => bucket.key),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: true,
                            text: chartZooms[eventType].title,
                            color: stylesheet.mode.fontColor
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                display: false,
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        }
                    }
                }
            })

            graph.options.onClick = (event) => {
                if (event.native) {
                    const points = graph.getElementsAtEventForMode(
                        event.native, 'nearest', { intersect: true }, true
                    )

                    if (points.length) {
                        const firstPoint = points[0]
                        const label = (graph.data.labels || [])[firstPoint.index] || ''

                        const detailsContent:O365DetailsForm = {
                            event_type: eventType,
                            q: currentParams.q
                        }

                        switch (eventType) {
                            case 'SiteUrl.Activity':
                                detailsContent.site = label
                                break
                            case 'SourceFileExtension.Activity':
                                detailsContent.extension = label
                                break
                            default:
                                detailsContent.operation = label
                                break
                        }

                        const serviceTypeFormData:ServiceTypeFormData = {
                            o365: {
                                details: detailsContent
                            }
                        }
                        setDetailsModal(serviceTypeFormData)
                    }
                }
            }

            zoomEl.current.style.height = CHART_HEIGHT.md
            setZoomInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setZoomInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        chartZooms[eventType]
    ])

    const DataTable = useMemo(() => {
        const currentData = dashboardData[eventType]
            ?.aggregations?.[2]?.buckets || []
        const fixedInterval = dashboardData[eventType]
            ?.fixedInterval || DEFAULT_INTERVAL

        /** BUG: Indexing to show a chart tooltip is incorrect when
         * hovering at a chart where there are datasets with
         * a value of zero.
         */

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        if (currentData.length >= 2) {
            ranges.start = new Date(
                currentData[0].key_as_string ||
                fromUnixTime(currentParams.ranges.start)
            )
            ranges.end = new Date(
                currentData[currentData.length - 1].key_as_string ||
                fromUnixTime(currentParams.ranges.end)
            )
        }

        const intervals = _.map(
            createIntervals(ranges, fixedInterval),
            (date) => format(date, DATE_FORMAT_TIME)
        )

        _.forEach(currentData, (bucket2) => {
            const dateString = bucket2.key_as_string

            if (dateString) {
                /** iterate the intervals array we made. */
                for (let index = 0; index < intervals.length; index++) {
                    /** get the current and subsequent interval values */
                    const intervalOne = new Date(intervals[index])
                    const intervalTwo = new Date(intervals[index + 1])

                    /** check if dateString exists in intervals. If found
                     * break the loop and proceed to the next iteration.
                     */
                    const found = _.find(intervals, interval => {
                        return _.isEqual(
                            format(new Date(dateString), DATE_FORMAT_TIME),
                            interval
                        )
                    })

                    if (found) {
                        break
                    }

                    /** taken from mdr bar chart. Check if bucket2.key_as_string
                     * is inbetween said intervals. If it does, insert it via
                     * splice AND break the loop. Continue until you find one.
                     */

                    if (
                        isValid(intervalTwo) &&
                        isWithinInterval(
                            new Date(dateString),
                            {
                                start: intervalOne,
                                end: intervalTwo
                            }
                        )
                    ) {
                        /** MAKE SURE YOU are inserting the dateString in the
                         * correct format
                         */
                        intervals.splice(
                            index, 0, format(
                                new Date(dateString), DATE_FORMAT_TIME
                            )
                        )
                        break
                    }
                }
            }
        })

        const cellBody = (
            dataObject: Bucket,
            property: keyof Bucket
        ) => {
            let cellContent: Bucket[keyof Bucket] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'key_as_string':
                    cellContent = dataObject.key_as_string
                        ? format(
                            new Date(dataObject.key_as_string),
                            DATE_FORMAT_TIME
                        )
                        : ''
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(OVERALL_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'o365Bar-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(currentData, (dataObject, rowIndex) => {
                            // can be undefined
                            const nextValue = currentData[rowIndex + 1]
                            const endKey = nextValue?.key_as_string
                                ? nextValue?.key_as_string
                                : format(
                                    fromUnixTime(currentParams.ranges.end),
                                    DATE_FORMAT_TIME
                                )

                            const chartBucket: ChartZoomBucket = {
                                ...dataObject,
                                end_key_as_string: endKey
                            }

                            const onClick = () => {
                                /** invalid date exception will crash the system */
                                if (dataObject[3] && dataObject.key_as_string) {
                                    setChartZoom(
                                        new Date(dataObject.key_as_string),
                                        new Date(endKey),
                                        chartBucket
                                    )
                                }
                            }

                            return (
                                <tr
                                    key={'o365Bar-tr-' + rowIndex}
                                    onMouseOver={() => {
                                        const correctIndex = _.findIndex(intervals, (interval) => {
                                            const dateString = dataObject.key_as_string

                                            if (dateString) {
                                                return _.isEqual(
                                                    format(new Date(dateString),
                                                        DATE_FORMAT_TIME), interval
                                                )
                                            } else {
                                                return false
                                            }
                                        })
                                        if (correctIndex > -1) {
                                            showChartTooltip(0, correctIndex, chartInstance)
                                        }
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(chartInstance)
                                    }}
                                    onClick={onClick}
                                >
                                    {
                                        _.map(OVERALL_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'o365Bar-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            currentData.length
                ? content
                : EmptyCellContent
        )
    }, [
        dashboardData[eventType],
        chartInstance
    ])

    const ZoomTable = useMemo(() => {
        const buckets = _.orderBy(
            chartZooms[eventType].bucket?.[3]?.buckets || [],
            ['doc_count'], ['desc']
        )

        const cellBody = (
            dataObject: Doc,
            property: keyof Doc
        ) => {
            let cellContent: Doc[keyof Doc] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            /** these zoom dispatches will function differently.
             * regardless of table cell, the same property will be
             * added.
             */

            const zoomAction = (not: boolean) => {
                let sort: SharePointEventKeys

                /** keys to use here have to be property names
                 * when querying at the database. for example,
                 * instead of operation, you use Operation.
                 */

                switch (eventType) {
                    case 'SiteUrl.Activity':
                        sort = 'Site'
                        break
                    case 'SourceFileExtension.Activity':
                        sort = 'SourceFileExtension'
                        break
                    default:
                        sort = 'Operation'
                        break
                }

                dispatch(addBool({
                    not: not,
                    sort: sort,
                    value: [dataObject.key as string]
                }))
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                <HoverFilter bgIndex={2}>
                    <div className={'pe-2 py-1'}>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                            {zoomIn}
                        </Tippy>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                            {zoomOut}
                        </Tippy>
                    </div>
                    <div>
                        {cellContent}
                    </div>
                </HoverFilter>
            )
        }

        let eventTypeColumns: {
            label: string;
            value: keyof Doc;
        }[] = []

        switch (eventType) {
            case 'SiteUrl.Activity':
                eventTypeColumns = SITE_URL_ACTIVITY_COLUMNS
                break
            case 'OneDriveDetails.Activity':
                eventTypeColumns = ONEDRIVE_DETAILS_ACTIVITY_COLUMNS
                break
            case 'SharePointDetails.Activity':
                eventTypeColumns = SHAREPOINT_DETAILS_ACTIVITY_COLUMNS
                break
            case 'SourceFileExtension.Activity':
                eventTypeColumns = SOURCE_FILE_EXTENSION_ACTIVITY_COLUMNS
                break
            default:
                break
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(eventTypeColumns, ({ label }, index) => {
                                const key = [
                                    'chartZoom-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(buckets, (dataObject, rowIndex) => {
                            const onClick = () => {
                                const detailsContent:O365DetailsForm = {
                                    event_type: eventType,
                                    q: currentParams.q
                                }

                                switch (eventType) {
                                    case 'SiteUrl.Activity':
                                        detailsContent.site = dataObject.key
                                        break
                                    case 'SourceFileExtension.Activity':
                                        detailsContent.extension = dataObject.key
                                        break
                                    default:
                                        detailsContent.operation = dataObject.key
                                        break
                                }

                                const serviceTypeFormData:ServiceTypeFormData = {
                                    o365: {
                                        details: detailsContent
                                    }
                                }

                                setDetailsModal(serviceTypeFormData)
                            }

                            return (
                                <tr
                                    key={'chartZoom-tr-' + rowIndex}
                                    onMouseOver={() => {
                                        showChartTooltip(0, rowIndex, zoomInstance)
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(zoomInstance)
                                    }}
                                    onClick={onClick}
                                >
                                    {
                                        _.map(eventTypeColumns, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'chartZoom-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            buckets.length
                ? content
                : EmptyCellContent
        )
    }, [
        chartZooms[eventType],
        chartColors,
        zoomInstance
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3', chartZooms[eventType].zoomLevel === 0 ? '' : 'd-none'
                // 'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles[eventType]
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
        )

        let fetchMessage: string = ''
        switch (eventType) {
            case 'SiteUrl.Activity':
                fetchMessage = O365_MESSAGE.FETCH.SITE_URL_ACTIVITY
                break
            case 'OneDriveDetails.Activity':
                fetchMessage = O365_MESSAGE.FETCH.ONEDRIVE_DETAILS_ACTIVITY
                break
            case 'SharePointDetails.Activity':
                fetchMessage = O365_MESSAGE.FETCH.SHAREPOINT_DETAILS_ACTIVITY
                break
            case 'SourceFileExtension.Activity':
                fetchMessage = O365_MESSAGE.FETCH.SOURCE_FILE_EXTENSION_ACTIVITY
                break
            default:
                break
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{fetchMessage}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !dashboardData[eventType]
                ? !isLoading
                    ? isSuccess
                        ? content
                        : error ? ErrorContent : ''
                    : LoadingContent
                : content
        )
    }, undefined)

    const ZoomContent = (
        <Container bgIndex={2} className={[
            'mb-3', chartZooms[eventType].zoomLevel > 0 ? '' : 'd-none'
            // 'mb-3'
        ].join(' ')}>
            <div className={'row'}>
                <canvas className={'col-auto'} ref={zoomEl}/>
            </div>
            {/* first instance of including a table version of this chart. */}
            {fixedCollapsibles[eventType]
                ? <div className={'row'}>
                    <div className={'col pb-3'}>{ZoomTable}</div>
                </div>
                : ''}
        </Container>
    )

    return <div>
        {DataContent}
        {ZoomContent}
    </div>
}

O365BarChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default O365BarChart

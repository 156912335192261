import {
    useGetDeviceConfigDetailsMutation,
    useGetAlertEmailsMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    COLUMNS,
    INITIAL_VALUES,
    TEXT as CONFIG_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/configuration/device-config'
import {
    ConfigFormData,
    DeviceConfigModal,
    DeviceConfigUpdateKeys,
    EmailAlertLine,
    SetAlertEmailsKeys
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
/** tables are created in separate components. */
import {
    Button,
    FormStyledComponents as Form,
    Table
} from '@styles/components'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    FormikErrors,
    useFormik
} from 'formik'

const DeviceConfigDetails = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const [
        getDeviceConfigDetails,
        getDeviceConfigDetailsMutation
    ] = useGetDeviceConfigDetailsMutation()

    const [
        getEmailAlerts,
        getEmailAlertsMutation
    ] = useGetAlertEmailsMutation()

    /** call formiks */
    const updateFormik = useFormik({
        initialValues: INITIAL_VALUES.DEVICE_CONFIG.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.DEVICE_CONFIG.UPDATE,
        onSubmit: () => {
            if (modal.formData.deviceConfig?.details?.id) {
                const configFormData:ConfigFormData = {
                    deviceConfig: {
                        updateConfirm: {
                            formValues: updateFormik.values,
                            id: modal.formData.deviceConfig?.details?.id
                        }
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'DEVICE_CONFIG_UPDATE',
                    formData: configFormData,
                    isBorderWide: true
                }))
            }
        }
    })

    const emailFormik = useFormik({
        initialValues: INITIAL_VALUES.DEVICE_CONFIG.EMAIL_ALERT,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.DEVICE_CONFIG.EMAIL_ALERT,
        onSubmit: () => {
            if (modal.formData.deviceConfig?.details?.id) {
                const configFormData:ConfigFormData = {
                    deviceConfig: {
                        updateEmailAlertConfirm: {
                            formValues: emailFormik.values,
                            id: modal.formData.deviceConfig?.details?.id
                        }
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'DEVICE_CONFIG_EMAIL_ALERTS_UPDATE',
                    formData: configFormData,
                    isBorderWide: true
                }))
            }
        }
    })

    const DeviceIdInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.DEVICEID.ID}>
                    {CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.DEVICEID.LABEL}
                </Form.Label>
                <Form.Input
                    readOnly={true}
                    disabled={true}
                    errors={Boolean(updateFormik.errors.deviceId)}
                    name={'deviceId'}
                    id={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.DEVICEID.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.deviceId}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.deviceId)} >{
                    updateFormik.errors.deviceId ? updateFormik.errors.deviceId : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.deviceId, updateFormik.errors.deviceId])

    const LocationInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.LOCATION.ID}>
                    {CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.LOCATION.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.location)}
                    name={'location'}
                    id={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.LOCATION.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.location}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.location)} >{
                    updateFormik.errors.location ? updateFormik.errors.location : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.location, updateFormik.errors.location])

    const CountryInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.COUNTRY.ID}>
                    {CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.COUNTRY.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.country)}
                    name={'country'}
                    id={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.COUNTRY.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.country}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.country)} >{
                    updateFormik.errors.country ? updateFormik.errors.country : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.country, updateFormik.errors.country])

    const CityInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.CITY.ID}>
                    {CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.CITY.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.city)}
                    name={'city'}
                    id={CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.CITY.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.city}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.city)} >{
                    updateFormik.errors.city ? updateFormik.errors.city : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.city, updateFormik.errors.city])
    /** inputs for submitting an array of emailAlerts */

    const EmailAlertsListTable = useMemo(() => {
        /** create interface no longer just a text field */

        const thead = (
            <thead>
                <tr>
                    <th>{TEXT.SEQUENCE}</th>
                    {
                        _.map(COLUMNS.DEVICE_CONFIG.EMAIL_ALERT, (column, index) => {
                            return (
                                <th key={'device-config-emailAlert-th-' + index}>
                                    {column.value}
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
        )

        const renderInput = (
            key: keyof EmailAlertLine,
            value: EmailAlertLine[keyof EmailAlertLine],
            index:number
        ) => {
            const fieldValue: SetAlertEmailsKeys = 'emailAlerts'

            const errors = (emailFormik.errors.emailAlerts ||
                []) as FormikErrors<EmailAlertLine>[]

            if (typeof value === 'boolean') {
                return <Form.TableInput
                    errors={Boolean(
                        errors[index]?.active
                    )}
                    name={[
                        fieldValue,
                        '[',
                        index.toString(),
                        '].', key
                    ].join('')}
                    type={'checkbox'}
                    onChange={emailFormik.handleChange}
                    checked={value}
                />
            } else {
                <Form.TableInput
                    errors={Boolean(
                        errors[index]?.[key] || false
                    )}
                    type={'text'}
                    name={[
                        fieldValue,
                        '[',
                        index.toString(),
                        '].', key
                    ].join('')}
                    onChange={emailFormik.handleChange}
                    onBlur={(e) => {
                        emailFormik.setFieldValue(
                            [
                                'emailAlerts[',
                                index.toString(),
                                '].', key
                            ].join(''),
                            e.target.value.trim()
                        )
                    }}
                    value={value}
                />
            }
        }

        const renderRow = (
            obj: EmailAlertLine,
            index: number
        ) => {
            return <tr key={'emailAlertLine-row-' + index}>
                <td>{index}</td>
                {
                    _.map(COLUMNS.DEVICE_CONFIG.EMAIL_ALERT, (column, columnIndex) => {
                        return (
                            <td key={'emailAlertLine-' +
                            index + 'input-' +
                            columnIndex}>
                                {
                                    renderInput(
                                        column.value,
                                        obj[column.value],
                                        index)
                                }
                            </td>
                        )
                    })
                }
            </tr>
        }

        const tbody = (
            <tbody>
                {
                    _.map(emailFormik.values.emailAlerts, (
                        obj, index) => {
                        return renderRow(obj, index)
                    })
                }
            </tbody>
        )

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    {thead}
                    {tbody}
                </table>
            </Table>
        )
    }, [
        emailFormik.values.emailAlerts
    ])

    const AddEmailAlertLineButton = useMemo(() => {
        const fieldValue: SetAlertEmailsKeys = 'emailAlerts'
        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    onClick={() => {
                        const emailAlerts = _.cloneDeep(emailFormik.values.emailAlerts)

                        const defaultObj: EmailAlertLine = {
                            email: '',
                            name: '',
                            active: false
                        }

                        emailAlerts.push(defaultObj)

                        emailFormik.setFieldValue(fieldValue, emailAlerts)
                    }}
                    mode={'primary'}
                    type={'button'}
                >
                    {
                        CONFIG_TEXT.DEVICE_CONFIG_EMAIL_ALERT_UPDATE
                            .FORM.ADD_EMAIL_ALERT
                    }
                </Form.Button>
            </Form.Group>
        )
    }, [
        emailFormik.values.emailAlerts
    ])

    useEffect(() => {
        if (getDeviceConfigDetailsMutation.error) {
            console.error(getDeviceConfigDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDeviceConfigDetailsMutation.error])

    useEffect(() => {
        if (getEmailAlertsMutation.error) {
            console.error(getEmailAlertsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getEmailAlertsMutation.error])

    const unsubscribeGetDeviceConfigDetails = () => {
        const unsubscribeMutation = getDeviceConfigDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetEmailAlerts = () => {
        const unsubscribeMutation = getEmailAlerts({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetDeviceConfigDetails()
        unsubscribeGetEmailAlerts()
        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getDeviceConfigDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getEmailAlertsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            const id = modal.formData.deviceConfig?.details?.id

            if (token.valid && id) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getDeviceConfigDetailsPromise = getDeviceConfigDetails({
                        authToken: newToken,
                        id: id
                    })

                    getEmailAlertsPromise = getEmailAlerts({
                        authToken: newToken,
                        id: id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getDeviceConfigDetailsPromise && getDeviceConfigDetailsPromise.abort()
            getEmailAlertsPromise && getEmailAlertsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const locationField: DeviceConfigUpdateKeys = 'location'
        const cityField: DeviceConfigUpdateKeys = 'city'
        const deviceIdField: DeviceConfigUpdateKeys = 'deviceId'
        const countryField: DeviceConfigUpdateKeys = 'country'

        const data = getDeviceConfigDetailsMutation.data

        if (data?.data?.length) {
            updateFormik.setFieldValue(cityField, data.data[0].location.city)
            updateFormik.setFieldValue(deviceIdField, data.data[0].deviceId)
            updateFormik.setFieldValue(countryField, data.data[0].location.country)
            updateFormik.setFieldValue(locationField, data.data[0].location.location)
        }
    }, [getDeviceConfigDetailsMutation.data])

    useEffect(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const emailAlertsField: SetAlertEmailsKeys = 'emailAlerts'

        const data = getEmailAlertsMutation.data

        if (data) {
            updateFormik.setFieldValue(emailAlertsField, data.data)
        }
    }, [getEmailAlertsMutation.data])

    return (<div>
        <Form.Main onSubmit={updateFormik.handleSubmit}>
            {DeviceIdInput}
            {LocationInput}
            {CountryInput}
            {CityInput}
            <Button
                type={'submit'}
                mode={'primary'}
            >{CONFIG_TEXT.DEVICE_CONFIG_UPDATE.FORM.SUBMIT_BUTTON }</Button>
        </Form.Main>
        <Form.Main onSubmit={emailFormik.handleSubmit}>
            {AddEmailAlertLineButton}
            {EmailAlertsListTable}
            <Button
                type={'submit'}
                mode={'primary'}
            >{CONFIG_TEXT.DEVICE_CONFIG_EMAIL_ALERT_UPDATE.FORM.SUBMIT_BUTTON }</Button>
        </Form.Main>
    </div>)
}

export default DeviceConfigDetails

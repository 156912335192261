import {
    DashboardData,
    Office365EventColumn,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    O365DetailedSearchParams
} from '@interfaces/dashboard/soc/o365/detailedDashboard/main'
import {
    Doc
} from '@interfaces/dashboard/monitor'
import { CHART_ZOOM } from '@constants/dashboard/monitor'
import _ from 'lodash'
import {
    getUnixTime,
    sub
} from 'date-fns'
import { GetModalDetailsRequest } from '@interfaces/dashboard/soc/o365/main'

export const EVENT_TYPES: EventType[] = ['Operations.Activity', 'General.Activity',
    'Exchange.Activity', 'Workload.Activity', 'Sharepoint.Activity', 'SecurityCenter.Activity',
    'Login.Locations', 'Office365.Events']

/** was _.Dictionary<EventTypeBooleans> but used type assertion. */
export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

export const DEFAULT_CHART_ZOOMS = _.zipObject(
    EVENT_TYPES,
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_SEARCH_PARAMS: O365DetailedSearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    /** note that the refetches are now from separate event types.
     * so if you change the parameters from date ranges and search queries,
     * then everything refreshes,
    */
    refetch: _.cloneDeep(DEFAULT_REFETCH),
    q: '',

    /** for displaying filters. */
    boolList: [],
    /** scripts won't be executed if deviceid IS an empty string, */
    card: {
        details: {},
        deviceid: '',
        in_face: '',
        service_type: 'bdg-bas'
    }
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'O365 Detailed call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        OPERATIONS_ACTIVITY: 'Fetching Operations Activity data...',
        GENERAL_ACTIVITY: 'Fetching General Activity data...',
        EXCHANGE_ACTIVITY: 'Fetching Exchange Activity data...',
        WORKLOAD_ACTIVITY: 'Fetching Workload Activity data...',
        SHAREPOINT_ACTIVITY: 'Fetching Sharepoint Activity data...',
        SECURITY_CENTER_ACTIVITY: 'Fetching Security Center Activity data...',
        LOGIN_LOCATIONS: 'Fetching Login Locations data...',
        O365_EVENTS: 'Fetching Office 365 Events data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        OPERATIONS_ACTIVITY: 'Operational Activity',
        GENERAL_ACTIVITY: 'General Activity',
        EXCHANGE_ACTIVITY: 'Exchange Activity',
        WORKLOAD_ACTIVITY: 'Workload Activity',
        SHAREPOINT_ACTIVITY: 'Sharepoint and OneDrive Activity',
        SECURITY_CENTER_ACTIVITY: 'Security Center',
        O365_LOCATIONS: 'Login Locations',
        O365_EVENTS: 'All Office365 Events This Period'
    }
}

export const OPERATIONS_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Operation',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const GENERAL_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = OPERATIONS_ACTIVITY_COLUMNS
export const EXCHANGE_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = OPERATIONS_ACTIVITY_COLUMNS
export const WORKLOAD_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = [
    {
        label: 'Workload',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]
export const SHAREPOINT_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = OPERATIONS_ACTIVITY_COLUMNS
export const SECURITY_CENTER_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = [
    {
        label: 'Title',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const O365_EVENTS_COLUMNS: Office365EventColumn[] = (() => {
    const columnNames: Omit<Office365EventColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Detection Method',
            value: 'DetectionMethod'
        },
        {
            label: 'Operation',
            value: 'Operation'
        },
        {
            label: 'Result Status',
            value: 'ResultStatus'
        },
        {
            label: 'Workload',
            value: 'Workload'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        },
        {
            label: 'Geoip',
            value: 'geoip'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: Office365EventColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

/** specific fields renamed when querying detailed dashboard data. UNUSED. */
export const FILTER_QUERIES: {param: keyof GetModalDetailsRequest, query: string}[] = [
    {
        param: 'workload',
        query: 'Workload'
    },
    {
        param: 'operation',
        query: 'Operation'
    },
    {
        param: 'title',
        query: 'title'
    },
    {
        param: 'extension',
        query: 'SourceFileExtension'
    },
    {
        param: 'ip',
        query: 'ClientIP'
    },
    {
        param: 'site',
        query: 'Site'
    },
    {
        param: 'attachments',
        query: 'hasAttachments'
    },
    {
        param: 'from',
        query: 'from.emailAddress.address'
    },
    {
        param: 'to',
        query: 'toRecipients.emailAddress.address'
    },
    {
        param: 'useragent',
        query: 'ClientInfoString'
    },
    {
        param: 'rule',
        query: 'Rule'
    },
    {
        param: 'subject',
        query: 'exchangeActions.action'
    },
    {
        param: 'user',
        query: 'UserId'
    },
    {
        param: 'file',
        query: 'File'
    }
    // {
    //     param: 'computer',
    //     query: 'Computer'
    // },
    // {
    //     param: 'resourcegroup',
    //     query: 'ResourceGroup'
    // },
    // {
    //     param: 'processname',
    //     query: 'ProcessName'
    // },
    // {
    //     param: 'type',
    //     query: 'event_type'
    // }
]

export const DASHBOARD_DATA: DashboardData = {
    'Operations.Activity': undefined,
    'General.Activity': undefined,
    'Exchange.Activity': undefined,
    'Workload.Activity': undefined,
    'Sharepoint.Activity': undefined,
    'SecurityCenter.Activity': undefined,
    'Login.Locations': undefined,
    'Office365.Events': undefined
}

export const PRINT_REPORT_TITLE = 'Microsoft 365 Detailed Dashboard'


import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as NIST_MESSAGE
} from '@constants/dashboard/grc/nist'
import {
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer,
    VerticalBarContainer
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartTypeRegistry,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'

interface ComponentProps {
    score: number | undefined,
    chartEl: React.RefObject<HTMLCanvasElement>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const ComplianceIndexChart = ({
    score, chartEl, isLoading, isSuccess, error
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)
        let graph: Chart<keyof ChartTypeRegistry, number[], string>

        const labels = ['']
        const datasets: typeof graph.data.datasets = [
            {
                data: [score
                    ? Math.round(score * 100) / 100
                    : 0],
                datalabels: {
                    anchor: 'end',
                    backgroundColor: stylesheet.style.colorPresets.grey,
                    color: stylesheet.mode.fontColor,
                    padding: {
                        top: 4,
                        bottom: 4,
                        left: 10,
                        right: 10
                    },
                    formatter: function (value, _context) {
                        return [value ? value.toFixed(2) : 0, '%'].join('')
                        // return context.dataIndex + ': ' + Math.round(value*100) + '%';
                    }
                },
                backgroundColor: 'transparent'
            },
            {
                data: [100.01],
                backgroundColor: 'transparent',
                datalabels: {
                    display: false
                },
                maxBarThickness: 20
                // minBarLength: 5,
            }
        ]

        if (chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                plugins: [
                    ChartDataLabels,
                    {
                        id: 'responsiveGradient',

                        afterLayout: function (chart, options) {
                            const scales = chart.scales

                            let color = chart.ctx.createLinearGradient(0, 0, 0, 0)

                            // consolePrint.innerHTML = JSON.stringify(scales)

                            if (scales) {
                                color = chart.ctx.createLinearGradient(
                                    scales.x.left,
                                    scales.y.bottom,
                                    scales.x.right,
                                    scales.y.top
                                )
                            }

                            // create a linear gradient with the dimentions of the scale
                            color.addColorStop(0, stylesheet.style.colorPresets.red)
                            color.addColorStop(0.5, stylesheet.style.colorPresets.yellow)
                            color.addColorStop(1, stylesheet.style.colorPresets.green)
                            // changes the background color option
                            chart.data.datasets[1].backgroundColor = color
                        }
                    }
                ],
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y - 30
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        },
                        title: {
                            display: false
                        },
                        datalabels: {
                            display: true
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            stacked: true
                        },
                        y: {
                            grid: {
                            // display: false,
                                color: stylesheet.mode.fontColor,
                                drawBorder: false
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                stepSize: 25,
                                callback: function (value, _index, _ticks) {
                                    return [value, '%'].join('')
                                }
                            },
                            beginAtZero: true,
                            max: 100,
                            min: 0
                        }
                    }
                }

            })

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.maxHeight = CHART_HEIGHT.sm
            chartEl.current.style.height = CHART_HEIGHT.sm

            // setVulnerabilityIndexBarChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setVulnerabilityIndexBarChartInstance(undefined)
            graph && graph.destroy()
        }
    }, undefined)

    const DataContent = useMemo(() => {
        const content = (
            <VerticalBarContainer bgIndex={2}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto'}>
                        <canvas ref={chartEl}/>
                    </div>
                </div>
            </VerticalBarContainer>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{NIST_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

ComplianceIndexChart.propTypes = {
    score: PropTypes.number,
    chartEl: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default ComplianceIndexChart

import {
    useCreateReportSectionsMutation,
    useDownloadCompleteReportMutation,
    useGenerateAllSectionsMutation,
    useGetMainKeyMutation,
    useGetSectionsMutation
} from '@apis/watchdog/pdf-api'

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    RECHECK_SECTION_INTERVAL,
    TEXT as PRINT_TEXT
} from '@constants/main/print'
import {
    MESSAGE,
    TOASTIFY_PRINT_OPTIONS
} from '@constants/main/root'

import { PrintQueue } from '@interfaces/main/print'
import { MutationContext } from '@root/MutationProvider'
import {
    removeQueue,
    updateIsFaulty,
    updateIsLoading,
    updateIsSuccess,
    updateIsUninitialized,
    updateReportId,
    updateToast
} from '@slices/main/print/queue'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    SpinnerContainer
} from '@styles/components'
import { Buffer } from 'buffer'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import { toast } from 'react-toastify'

const PrintReport = ({ queue } : {queue: PrintQueue}) => {
    /** BUG FOUND: when generating reports simultaneously, all operations
     * will be aborted if one of them finishes. Solution is to avoid aborting
     * those promises. You will receive a memory leak error as a side effect though.
     */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const token = useAppSelector(selectToken)

    const [
        createReportSections,
        createReportSectionsMutation
    ] = useCreateReportSectionsMutation()

    const [getSections, getSectionsMutation] = useGetSectionsMutation()

    const [
        downloadCompleteReport,
        downloadCompleteReportMutation
    ] = useDownloadCompleteReportMutation()

    const [
        generateAllSections,
        generateAllSectionsMutation
    ] = useGenerateAllSectionsMutation()

    const [getMainKey, getMainKeyMutation] = useGetMainKeyMutation()

    /** Will be placed in index.tsx to be rendered via a map of queues
     * if one wants to print multiple modules.
    */

    /** template for entire toast. */
    const messageTemplate = useCallback((content: JSX.Element) => {
        return <div className={'row align-items-center'}>
            <SpinnerContainer className={'col-auto pe-2'}>
                <span className={'spinner-border'}></span>
            </SpinnerContainer>
            <div className={'col'}>
                <span>{content}</span>
            </div>
        </div>
    }, [])

    /** useState to preserve the createReportSections message. */
    const [progressMessage, setProgressMessage] = useState<string | undefined>()

    /** NOW, if ever the browser abruptly shuts down, you can have a button
     * in the interface to generate all the reports again.
     *
     * ONLY do this if any of the reports haven't finished generating aka
     * queue.isSuccess is false.
     */

    /** If you wonder why your requests are done twice with and without
     * headers AND body is because React is in Strict Mode as your components will render twice in
     *  development environments. This is for error/warning detection.
     */

    /**
     * we tested that in most cases reportId and queue.toast would be left undefined.
     * So while it is necessary to include those as dependencies, make sure those
     * lifecycles isn't running more than it should.
     */

    useEffect(() => {
        const data = createReportSectionsMutation.data

        if (data && queue.toast) {
            if (data.success) {
                // display notification that the report is being generated.

                /** save the original response message for the progress bar */

                /** you can update the queue.toast as long as it's truthy */
                queue.toast && toast.update(queue.toast, {
                    render: messageTemplate(
                        <>{data.message}</>
                    )
                })

                setProgressMessage(data.message)
                dispatch(updateReportId({
                    queue: queue,
                    value: data.reportId || ''
                }))
            } else {
                // display failure message.
                toast.update(queue.toast, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'error'
                })

                // after displaying the failure message. remove this from the queue.
                dispatch(removeQueue(queue))
            }
        }
    }, [
        createReportSectionsMutation.data,
        queue.toast
    ])

    /** in all occurences of errors, make sure you setGeneratingReport(false) */

    useEffect(() => {
        const error = createReportSectionsMutation.error

        if (error && queue.toast) {
        /** if you are from here, execution stopped as an unexpected
         * error occured. (forgot properly name the api to be added
         * to the global store.)
         */
            console.error(error)
            toast.update(queue.toast, {
                render: MESSAGE.ERROR.DATA.CALL_FAILED,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'error'
            })

            // after displaying the failure message. remove this from the queue.
            dispatch(removeQueue(queue))
        }
    }, [
        createReportSectionsMutation.error,
        queue.toast
    ])

    const getKey = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        // let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && queue.reportId) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getMainKey({
                        authToken: newToken,
                        reportId: queue.reportId
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            // promise && promise.abort()
        }
    }

    useEffect(() => {
        // console.log('Report ID: ', reportId)
        if (queue.reportId) {
            return getKey()
        }
    }, [queue.reportId])

    useEffect(() => {
        /** proceed to generate all the sections and use getSections
         * to update the progress via interval.
         */
        // if (createReportSectionsMutation.isSuccess) {
        //     console.log('createReportSections succeeded')
        // } else {
        //     console.log('createReportSections NOT succeeded')
        // }
        // it's undefined. <-- Save for documentation.
        // console.log('Report ID WITHOUT it as a dependency: ', reportId)

        if (
            createReportSectionsMutation.isSuccess &&
            queue.reportId
        ) {
            // let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
            let isMounted = true

            // console.log('Time to generate all sections.')
            const call = async () => {
                const newToken = await revalidateToken()

                if (isMounted) {
                    generateAllSections({
                        authToken: newToken,
                        reportId: queue.reportId
                    })
                }
            }
            call()

            return () => {
                isMounted = false
                // promise && promise.abort()
            }
        }
    }, [
        createReportSectionsMutation.isSuccess,
        queue.reportId
    ])

    useEffect(() => {
        const data = generateAllSectionsMutation.data

        if (data && queue.toast) {
            if (data.success === false) {
                // display failure message.
                toast.update(queue.toast, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'error'
                })

                // after displaying the failure message. remove this from the queue.
                dispatch(removeQueue(queue))
            }
        }
    }, [
        generateAllSectionsMutation.data,
        queue.toast
    ])

    useEffect(() => {
        const error = generateAllSectionsMutation.error

        if (error && queue.toast) {
        /** if you are from here, execution stopped as an unexpected
         * error occured. (forgot properly name the api to be added
         * to the global store.)
         */
            console.error(error)
            toast.update(queue.toast, {
                render: MESSAGE.ERROR.DATA.CALL_FAILED,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'error'
            })

            // after displaying the failure message. remove this from the queue.
            dispatch(removeQueue(queue))
        }
    }, [
        generateAllSectionsMutation.error,
        queue.toast
    ])

    const recheckSections = () => {
        let intervalId: NodeJS.Timer
        // let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && queue.reportId) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    intervalId = setInterval(() => {
                        getSections({
                            authToken: newToken,
                            reportId: queue.reportId
                        })
                    }, RECHECK_SECTION_INTERVAL)
                }
                // console.log('interval id after initialization is: ', intervalId)
            }
        }
        call()

        return () => {
            /** necessary to avoid infinite getSection loops */
            // console.log('interval id in cleanup is: ', intervalId)
            // if (intervalId) {
            //     console.log('can clear interval.')
            // }
            isMounted = false
            // promise && promise.abort()
            clearInterval(intervalId)
        }
    }

    /** a lifecycle where we run the getSections api call repeatedly and clear it
     * IF completeReport is true.
     */
    useEffect(() => {
        if (queue.reportId && generateAllSectionsMutation.isLoading) {
            return recheckSections()
        }
    }, [
        // BUG: getSections calls the setInterval a second time just after
        // downloading the complete report causing an infinite loop.

        // BUG: if generateAllSections finishes AND the progress hasn't been 100
        // It stops from there and the process is stuck. Make new useEffect for
        // isSuccess
        generateAllSectionsMutation.isLoading,
        queue.reportId
    ])

    /** INTENDED TO BE A SEPARATE ONE. */
    useEffect(() => {
        if (generateAllSectionsMutation.isSuccess) {
            const call = async () => {
                // console.log(`making last getSections call as all
                // sections have been completely generated`)
                if (token.valid && queue.reportId) {
                    const newToken = await revalidateToken()

                    getSections({
                        authToken: newToken,
                        reportId: queue.reportId
                    })
                }
            }
            call()
        }
    }, [generateAllSectionsMutation.isSuccess])

    useEffect(() => {
        /** if the call was successful, we update the progressbar toast
         * otherwise, display a NEW toast and let the interval continue.
         */

        const data = getSectionsMutation.data

        if (
            data && queue.toast &&
            progressMessage && queue.reportId
        ) {
            if (data.success) {
                /** we filter the sections by a truthy queue.isSuccess check */

                const sections = data.data || []

                const numberOfComplete: number = _.filter(sections, (obj) => {
                    return obj.isComplete
                }).length

                const sum = numberOfComplete / sections.length * 100

                const div = <div>
                    <p>{progressMessage}</p>
                    <span>{PRINT_TEXT.GENERATING_SECTIONS.PROGRESS(sum)}</span>
                </div>

                toast.update(queue.toast, {
                    render: () => messageTemplate(
                        <>{div}</>
                    )
                })

                /** if the sum is greater than 100, make the call to
                 * downloadCompleteReport by setting queue.isSuccess to true.
                 */

                if (sum >= 100) {
                    dispatch(updateIsSuccess({
                        queue: queue,
                        value: true
                    }))
                }

                /** IF there is at least one section that have hasCrashed as true,
                 * set setIsIncomplete to true. Doing this will render
                 * a modal component fetching the same sections again
                 * showing which ones are faulty.
                 *
                 * Will be helpful if there are a lot of sections and one
                 * wants to make a decision once downloadCompleteReport mutation
                 * has finished/crashed.
                 */

                /** won't necessarily return a boolean but the section itself */
                const hasCrashed = _.find(sections, (obj) => {
                    return obj.hasCrashed
                })

                if (hasCrashed) {
                    dispatch(updateIsFaulty({
                        queue: queue,
                        value: true
                    }))

                    /** don't forget to dismiss the progressBar as the confirmation
                     * message component will take its place
                     */
                    toast.dismiss(queue.toast)
                }
            } else {
                data.message && toast.error(data.message, {
                    ...TOASTIFY_PRINT_OPTIONS.END
                })

                // decided not to remove the queue from here since this is done in
                // an interval so it will eventually load again.
                // dispatch(removeQueue(queue))
            }
        }
    }, [
        getSectionsMutation.data,
        queue.toast,
        progressMessage,
        queue.reportId
    ])

    useEffect(() => {
        const error = getSectionsMutation.error

        if (error && queue.toast) {
            /** if you are from here, execution stopped as an unexpected
             * error occured. (forgot properly name the api to be added
             * to the global store.)
             */
            console.error(error)
            toast.update(queue.toast, {
                render: MESSAGE.ERROR.DATA.CALL_FAILED,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'error'
            })

            // also decided not to remove the queue since this isn't a primary call.
            // dispatch(removeQueue(queue))
        }
    }, [
        getSectionsMutation.error,
        queue.toast
    ])

    useEffect(() => {
        // let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (queue.isSuccess && queue.reportId) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (isMounted) {
                    downloadCompleteReport({
                        authToken: newToken,
                        reportId: queue.reportId,
                        withoutCrash: false
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            // promise && promise.abort()
        }
    }, [queue.isSuccess, queue.reportId])

    useEffect(() => {
        const data = downloadCompleteReportMutation.data

        // console.log('Report ID for downloading the complete report: ', queue.reportId)
        // console.log(
        //     'Download complete report data for report id above is: '
        //     , downloadCompleteReportMutation.data
        // )
        /** bug found here: report ids are different but some of them
         * proceed to get the report of the first that finishes.
         *
         * Before citing the source of the problem, endpoints from a mutation will always have
         * a cache if the parameters are the same when being submitted so
         * it will be used as response data instead. you can see that
         * createReportSections, getMainKey all have different values.
         *
         * Upon further testing, all mutations are working independently from
         * their PrintReport components.
         *
         * SOURCE FOUND: mainKey, generateReportSections and downloadCompleteReport
         * calls are being made even if they have finished execution.
         *
         * Thanks to immutability in our framework, the memory address of these
         * objects remain intact. What's actually causing this is the key prop
         * assigned in our _.map function at QueueController.tsx
         *
         * It will be recognized as a new component and will go through the entire
         * process as if it was new.
         *
         * To fix this, make sure that before you iterate your queues, you already
         * have a key name assigned that doesn't rely on an index number that
         * the iterator provides. This way, (read from reactjs.org) your components
         * will have a stable identity that it will no longer rerender.
         *
         */

        if (data && queue.toast && queue.isSuccess === true) {
            if (data.success) {
                const key = getMainKeyMutation.data?.data || ''

                // console.log('key is: ', key)

                if (key) {
                    // you got the report so the toast can finally autoClose.

                    const buffer = Buffer.from(data.reportContents, 'base64')

                    const blob = new Blob([buffer], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)

                    /** by this lifecycle we can access section data
                     * as it has been called at least once.
                     */
                    link.download = key
                    link.click()

                    toast.update(queue.toast, {
                        render: data.message,
                        ...TOASTIFY_PRINT_OPTIONS.END,
                        type: 'success'
                    })

                    // the report is complete so remove this from the queue.
                    dispatch(removeQueue(queue))
                } else {
                    /** in case there aren't any, do a toast message that a key wasn't found */
                    toast.update(queue.toast, {
                        render: PRINT_TEXT.MAIN_KEY.EMPTY,
                        ...TOASTIFY_PRINT_OPTIONS.END,
                        type: 'error'
                    })

                    // after displaying the failure message. remove this from the queue.
                    dispatch(removeQueue(queue))
                }
            } else {
                // display failure message.
                toast.update(queue.toast, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'error'
                })

                // after displaying the failure message. remove this from the queue.
                dispatch(removeQueue(queue))
            }
        }
    }, [
        downloadCompleteReportMutation.data,
        queue.toast
    ])

    useEffect(() => {
        const error = downloadCompleteReportMutation.error

        if (error && queue.toast) {
        /** if you are from here, execution stopped as an unexpected
         * error occured. (forgot properly name the api to be added
         * to the global store.)
         */
            console.error(error)
            toast.update(queue.toast, {
                render: MESSAGE.ERROR.DATA.CALL_FAILED,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'error'
            })

            // after displaying the failure message. remove this from the queue.
            dispatch(removeQueue(queue))
        }
    }, [
        downloadCompleteReportMutation.error,
        queue.toast
    ])

    const generateReport = async () => {
        dispatch(updateIsLoading({
            queue: queue,
            value: true
        }))

        dispatch(updateIsUninitialized({
            queue: queue,
            value: false
        }))

        /**
         * make the call to PDF URL.
         * */
        const newToken = await revalidateToken()
        /**
         * create report sections. expected response
         * should be the reports that were submitted
         * to the database but all objectids are turned to strings.
         */

        // we tried having an array of toast ids in the global slice but
        // will be unusable when modularizing the codebase in lifecycles.
        // solution is to put it in one lifecycle.

        /** start updating the queue.toast useState. */

        const id = toast.info(
            messageTemplate(
                <>{PRINT_TEXT.CREATE_SECTIONS.START}</>
            ), {
                ...TOASTIFY_PRINT_OPTIONS.ONGOING
            }
        )

        dispatch(updateToast({
            queue: queue,
            value: id
        }))

        /**
         * update: because we just created our detailed dashboard component,
         * we will also include a conditional check for it while prioritizing
         * main modals.
         */
        if (
            queue.details.eventReports && queue.details.printOptions
        ) {
            console.log(PRINT_TEXT.DOWNLOAD.MODE.EVENT_REPORT)
            createReportSections({
                authToken: newToken,
                reportId: queue.details.eventReports.reportId,
                reportName: queue.details.eventReports.reportName,
                stylesheet: style,
                theme: mode,
                isPrinting: true
            })
        } else {
            toast.update(id, {
                render: PRINT_TEXT.DOWNLOAD.MISSING_PARAMETERS,
                ...TOASTIFY_PRINT_OPTIONS.END,
                type: 'error'
            })
            // after displaying the failure message. remove this from the queue.
            dispatch(removeQueue(queue))
        }
    }

    useEffect(() => {
        const initial = queue.isLoading === false && queue.isUninitialized === true

        /** will proceed automatically if count is less than 100 */
        if (initial) {
            generateReport()
        }
    }, [queue.isLoading, queue.isUninitialized])

    /** if queue.isFaulty is true, display a NEW toast message where you are
     * recommended to go to ReportMenu.tsx to view reports that have crashed
     * sections.
     *
     */

    useEffect(() => {
        if (queue.isFaulty) {
            toast.error(PRINT_TEXT.GENERATING_SECTIONS.FAULTY_SECTIONS, {
                ...TOASTIFY_PRINT_OPTIONS.END
            })
        }
    }, [queue.isFaulty])

    return (
        <div>
        </div>

    )
}

export default PrintReport


import Color from 'color'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

    /** NOTE: always use overflow: overlay to remove that padding the elemtnt's scrollbar creates */

    /** NOTE: no need to call table-responsive anymore since 
    Table component has those properties. */

    /* since the easiest way to import google-fonts
    is through url, know the font you are going to import
    place it in the themes.tsx file first. */

    /** z-index assignment record
        react-select menu -> 2
        react-datepicker menu -> 2
        sidebar component on smaller screen size - 2
        QuickFilter component -> 1
        sticky table headers -> 1
     */

    html {
        /* this was applied because tables with a minimum width
        EVEN if it had an overflow-x auto property assigned
        makes the content scroll to the right. This was necessary
        for smaller screen sizes to scroll through large data tables */
        /* overflow-x: hidden;
        overflow-y: hidden; */
    }

    body {
        font-family: ${ props => props.theme.style.fontFamily };
        font-size: ${ props => props.theme.style.fontSize };
        background: ${ props => props.theme.mode.backgroundColors[0] };
        color: ${ props => props.theme.mode.fontColor };
    }

    //empty the border and background properties in input boxes
    input {
        background: transparent;
        border: ${ props => ['0.1em solid', props.theme.mode.backgroundColors[9]].join(' ') };;
        border-radius: 0.3em;
        color: ${ props => props.theme.mode.fontColor };
        padding-block: 0.5em;
        padding-inline: 0.9em;
        width: 100%;
        transition: border-color 0.15s ease-in-out, 
            box-shadow 0.15s ease-in-out;

        :focus {
            border-color: ${ (props) => [props.theme.style.buttonTypeColors.primary].join('') };
            box-shadow: ${
                (props) => {
                    return (
                        [
                            '0 0 0 0.2rem',
                            props.theme.style.buttonTypeColors.primary + '20'
                        ].join(' ')
                    )
                }
            };
        }

        &[type=radio] {
            width: initial;
        }

        &[type=checkbox] {
            width: initial;
        }
    }
    
    input:focus-visible {
        outline-width: 0px;
    }

    textarea {
        font-size: 0.8em;
        background: transparent;
        border: ${ props => ['0.1em solid', props.theme.mode.backgroundColors[9]].join(' ') };;
        border-radius: 0.3em;
        color: ${ props => props.theme.mode.fontColor };
        padding-block: 0.5em;
        padding-inline: 0.9em;
        width: 100%;
        transition: border-color 0.15s ease-in-out, 
            box-shadow 0.15s ease-in-out;

        :focus {
            border-color: ${ (props) => [props.theme.style.buttonTypeColors.primary].join('') };
            box-shadow: ${
                (props) => {
                    return (
                        [
                            '0 0 0 0.2rem',
                            props.theme.style.buttonTypeColors.primary + '20'
                        ].join(' ')
                    )
                }
            };
        }

        height: 7em;
        resize: none;

        &.textarea-large {
            height: 18em;
        }

    }

    textarea:focus-visible {
        outline-width: 0px;
    }


    button {
        color: ${ props => props.theme.mode.fontColor };
        border: 0px solid black;
        border-radius: 0.3em;
        font-weight: bold;

        //make sure default disabled button bg is prioritized.
        :disabled {
            opacity: 0.5;
        }
    }

    //make sure all texts have an overflow-wrap property
    span, h1, h2, h3, h4, h5, h6, small, strong {
        overflow-wrap: anywhere;
    }

    tr{
        vertical-align: middle;
    }

    td {
        font-size: 0.8em;
    }

    mark {
        background: ${ props => props.theme.style.colorPresets.yellow };
    }

    pre code {
        font-size: 0.8em !important;
    }

    canvas {
        cursor: pointer;
    }

    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    *::-webkit-scrollbar-track {
        background: transparent;
    }
    *::-webkit-scrollbar-thumb {
        background-color: ${ props => props.theme.mode.backgroundColors[5] };
        border-radius: 20px;
    }
    *::-webkit-scrollbar-corner {
        background: transparent
    }

    //style overrides from third party modules

    .tippy-box {
        background: ${ props => props.theme.mode.backgroundColors[1] };
        color: ${ props => props.theme.mode.fontColor };
        border: 0.1em solid;
        border-color: ${ props => props.theme.mode.backgroundColors[2] };
    }

    .tippy-arrow {
        color: ${ props => props.theme.mode.backgroundColors[1] };
    }

    .Toastify__toast--default {
        background: ${ props => props.theme.mode.backgroundColors[1] };
        color: ${ props => props.theme.mode.fontColor };
        border: 0.1em solid;
        border-color: ${ props => props.theme.mode.backgroundColors[2] };
    }

    /* the last 4 toastify types should always have a font color of white
    regardless of theme selection. */
    .Toastify__toast--info {
        background: ${ props => props.theme.style.buttonTypeColors.primary };
        color: ${ props => props.theme.style.colorPresets.white };
    }

    .Toastify__toast--success {
        background: ${ props => props.theme.style.buttonTypeColors.success };
        color: ${ props => props.theme.style.colorPresets.white };
    }

    .Toastify__toast--warning {
        background: ${ props => props.theme.style.buttonTypeColors.warning };
        color: ${ props => props.theme.style.colorPresets.white };
    }

    .Toastify__toast--error {
        background: ${ props => props.theme.style.buttonTypeColors.danger };
        color: ${ props => props.theme.style.colorPresets.white };
    }

    .react-responsive-modal-closeButton {
        font-size: 2em;
        top: -0.8em;
        right: -1.2em;

        @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.md }) {
            top: 0.5em;
            right: 0.4em;
        }

    }

    .react-responsive-modal-modal {

        --maxDefaultWidth: ${
            props => props.theme.style.mediaQueries.lg
        };

        --maxExtraSmallWidth: ${
            props => props.theme.style.mediaQueries.xs
        };

        --maxSmallWidth: ${
            props => props.theme.style.mediaQueries.sm
        };

        --maxMediumWidth: ${
            props => props.theme.style.mediaQueries.md
        };

        --maxLargeWidth: ${
            props => props.theme.style.mediaQueries.lg
        };
        
        background: ${ props => props.theme.mode.backgroundColors[1] };
        max-width: calc(var(--maxDefaultWidth) - 200px );
        width: 100%;
        border-radius: 10px;
        border-top-style: solid;
        border-top-width: 20px;
        border-top-color: ${ props => props.theme.style.buttonTypeColors.primary };

        //was responsible for cropping out any content outside the modal. set to revert.
        overflow-y: revert;

        @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.md }) {
            width: auto;
        }

        &.xs {
            max-width: calc(var(--maxExtraSmallWidth) - 76px );
            
            @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.xs }) {
                width: auto;
            }
        }

        &.sm {
            max-width: calc(var(--maxSmallWidth) - 50px );
            
            @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.sm }) {
                width: auto;
            }
        }

        &.md {
            max-width: calc(var(--maxMediumWidth) - 50px );
            
            @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.md }) {
                width: auto;
            }
        }

        &.lg {
            max-width: calc(var(--maxLargeWidth) - 50px );

            @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.lg }) {
                width: auto;
            }
        }

        &.primary {
            border-top-color: ${ props => props.theme.style.buttonTypeColors.primary };
        }

        &.info {
            border-top-color: ${ props => props.theme.style.buttonTypeColors.info };
        }
        
        &.success {
            border-top-color: ${ props => props.theme.style.buttonTypeColors.success };
        }
        
        &.warning {
            border-top-color: ${ props => props.theme.style.buttonTypeColors.warning };
        }
        
        &.danger {
            border-top-color: ${ props => props.theme.style.buttonTypeColors.danger };
        }

        //I KNOW THE COLORS are the same but for clarity, we'll include color names.
        &.blue {
            border-top-color: ${ props => props.theme.style.colorPresets.blue };
        }

        &.green {
            border-top-color: ${ props => props.theme.style.colorPresets.green };
        }

        &.yellow {
            border-top-color: ${ props => props.theme.style.colorPresets.yellow };
        }

        &.amber-2 {
            border-top-color: ${ props => props.theme.style.colorPresets['amber-2'] };
        }

        &.amber-1 {
            border-top-color: ${ props => props.theme.style.colorPresets['amber-1'] };
        }

        &.red {
            border-top-color: ${ props => props.theme.style.colorPresets.red };
        }

        &.grey {
            border-top-color: ${ props => props.theme.style.colorPresets.grey };
        }

        &.darkGrey {
            border-top-color: ${ props => props.theme.style.colorPresets.darkGrey };
        }

        &.wide-border-top {
            border-top-width: 2.5em;
        }

        @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.md }) {
            width: auto;
        }

    }

    .rc-switch-checked {
        border: ${ props => ['0.1em solid', props.theme.style.buttonTypeColors.primary].join(' ') };
        background-color: ${ props => props.theme.style.buttonTypeColors.primary };
    }

    .rc-pagination {
        font-size: 0.8em;

        & > li {
            /** needed for smaller device sizes */
            margin-bottom: 0.5em;
        }
    }

    .rc-pagination-prev .rc-pagination-item-link, 
    .rc-pagination-next .rc-pagination-item-link,
    .rc-pagination-item
    {
        background-color: transparent;
        border-radius: 0.3em;
        border-width: 0.1em;
        border-style: solid;
        border-color: initial;
        transition: none;
        font-family: inherit;
        font-size: inherit;
    }

    .rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next
    {
        button {
            color: ${ props => props.theme.mode.fontColor };
        }
    }

    .rc-pagination-disabled .rc-pagination-item-link, 
    .rc-pagination-disabled:hover .rc-pagination-item-link, 
    .rc-pagination-disabled:focus .rc-pagination-item-link {
        color: ${ props => [props.theme.mode.backgroundColors[9] + '60'].join(' ') };
    }

    .rc-pagination-item:focus, .rc-pagination-item:active, 
    .rc-pagination-item:hover, .rc-pagination-item-active {
        border-color: ${ (props) => [props.theme.style.buttonTypeColors.primary].join('') };
        transition: none;
    }

    .rc-pagination-item:focus a, .rc-pagination-item:active a, 
    .rc-pagination-item:hover a, .rc-pagination-item-active a {
        /* added important because bootstrap reboot overridden this property */
        color: ${ (props) => [props.theme.style.buttonTypeColors.primary].join('') } !important;
    }

    .react-datepicker-popper {
        z-index: 2;
    }

 
    
    .table > :not(caption) > * > * {
        /* padding: 0; */
    }
    
    @media only screen and (max-width: ${ props => props.theme.style.mediaQueries.xs }) {
        body {
            font-size:15px;
        }
    }

    /**
    * a component where the div component contains a clickable pointer.
    */
    .pointer {
        cursor: pointer;
    }

    /**

     */
    .icon {
        color: ${ props => props.theme.mode.fontColor };

        svg {
            font-size: 1.8em;
        }



        &.small svg {
            /* font-size: 0.8em; */
            font-size: 1.2em;
        }
        /** an icon can be disabled by opacity */
        &.disabled {
            cursor: default;
            & svg {
                opacity: 0.2
            }
        }

        /** if it also has a pointer class, do hover font color change */
        &.pointer:hover{
            color: ${ (props) => [props.theme.style.buttonTypeColors.primary].join('') };
        }
    }

    /** bootstrap overrides */
    a.icon {
        color: inherit;
    }

    //add a styling for success statuses. changes font-color.
    .text {
        &.success {
            color: ${ props => props.theme.style.buttonTypeColors.success };
        }

        &.warning {
            color: ${ props => props.theme.style.buttonTypeColors.warning };
        }

        &.danger {
            color: ${ props => props.theme.style.buttonTypeColors.danger };
        }
    }

    .progress-bar{
        background: ${ (props) => [props.theme.style.buttonTypeColors.primary].join('') };
    }

    /** bugs found here with & AND :after selector. culprits were found (content used single quotes
        but should be using double quoutes, &::after selectors, and escaping front slash). */
    .delta {
        padding-block: 0.5em;
        color: ${ props => props.theme.style.colorPresets.white };
        &.positive, &.negative-inverse {
            background: ${ props => props.theme.style.colorPresets.green };
        }
        &.negative, &.positive-inverse {
            background: ${ props => props.theme.style.colorPresets.red };
        }
        &.neutral, &.infinite {
            background: ${ props => props.theme.style.colorPresets.grey };
        }
        &.nonnumerical {
            background: ${ props => props.theme.style.colorPresets.darkGrey };
        }
    }

    .delta.nonnumerical::after {
        content: "-";
    }

    .delta.positive::after, .delta.positive-inverse::after {
        content: '\\25B2';
    }

    .delta.negative::after, .delta.negative-inverse::after {
        content: '\\25BC';
    }
    
    /**
    UPDATE: November 8, 2021.
    If you have an element that has a width GREATER THAN the screen width, extra space
    at the bottom will be created at the root element, the solution is to assign the parent
    of the target overflow-x: overlay AND assigning a max-width if necessary. doing it 
    anywhere else will not work and even if you did, users will be confused on where to scroll.

    In nested element contexts, you have to consider getting the parent element's padding and 
    margin values (both left and right) and include it in the calc() when assign a max-width

    the class below is a fix for elements with react-responsive-modal-modal as one of its ancestors.
    why 5.3 rem? the following conditions has to be met when applying this fix:
    1.) 4.8 rem is comming from react-responsive-modal-modal padding-inline
    2.) the column this is being called from which is --bs-gutter-x: 1.5rem;
    3.) SHOULD BE BEST ASSIGNED at the first child of the first div element with the class name .col
    4.) Avoid assigning this class along with flexbox classes
     */
    .react-responsive-modal-modal .min-width-fix {
        --bs-gutter-x: 1.5rem;
        /* overflow-x: overlay; */
        max-width: calc(100vw - 4.8rem - calc(var(--bs-gutter-x) * .5));
    }

    .react-responsive-modal-container {
        /* overflow-x: auto; */
    }

    /** react datepicker style overrides */

    .react-datepicker {
        font-family: ${ props => props.theme.style.fontFamily };
    }

    /** applied this property so elements next to this datepicker won't line break */
    .react-datepicker__tab-loop {
        display: inline-block;
    }

    .react-datepicker-wrapper, .react-datepicker__input-container {
        /** frtom inline-block to inline so we can use the custom input property to make
        it a block property if chosen to. */
        display: inline;
    }

    .react-datepicker, 
    .react-datepicker__time-container .react-datepicker__time {
        background: ${ props => props.theme.mode.backgroundColors[1] };
        color: ${ props => props.theme.mode.fontColor };
    }
    
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
        border-bottom-color: ${ props => props.theme.mode.backgroundColors[5] };
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-bottom-color: ${ props => props.theme.mode.backgroundColors[2] };
    }

    .react-datepicker__navigation-icon {
        top: 5px;
    }

    .react-datepicker__year-read-view--down-arrow, 
    .react-datepicker__month-read-view--down-arrow, 
    .react-datepicker__month-year-read-view--down-arrow, 
    .react-datepicker__navigation-icon::before,
    .react-datepicker__time-container,
    .react-datepicker {
        border-color: ${ props => props.theme.mode.backgroundColors[5] };
    }

    .react-datepicker__header {
        background: ${ props => props.theme.mode.backgroundColors[2] };
        border-bottom-color: ${ props => props.theme.mode.backgroundColors[5] };
        border-top-left-radius: 0rem;
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: 0rem;
    }

    .react-datepicker .title {
        background: ${ props => props.theme.mode.backgroundColors[2] };
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }


    .react-datepicker__current-month,
    .react-datepicker-time__header, 
    .react-datepicker-year-header {
        color: ${ props => props.theme.mode.fontColor };
    }

    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name {
        color: ${ props => props.theme.mode.fontColor };
    }

    .react-datepicker__day--selected, 
    .react-datepicker__month-text--selected, 
    .react-datepicker__month-text--in-selecting-range, 
    .react-datepicker__month-text--in-range, 
    .react-datepicker__quarter-text--selected, 
    .react-datepicker__quarter-text--in-selecting-range, 
    .react-datepicker__quarter-text--in-range, 
    .react-datepicker__year-text--selected, 
    .react-datepicker__year-text--in-selecting-range, 
    .react-datepicker__year-text--in-range,
    .react-datepicker__time-container 
    .react-datepicker__time 
    .react-datepicker__time-box 
    ul.react-datepicker__time-list 
    li.react-datepicker__time-list-item--selected {
        //added !important to add priority.
        background: ${ (props) => [
            props.theme.style.buttonTypeColors.primary, '!important'
        ].join('') };
        color: ${ (props) => [props.theme.mode.fontColor].join('') };
    }

    .react-datepicker__day--in-selecting-range, 
    .react-datepicker__day--in-range  {
        background: ${
            (props) => [
                Color(props.theme.style.buttonTypeColors.primary).fade(0.50)
            ].join('')
        };
        color: ${ (props) => [props.theme.mode.fontColor].join('') };
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end  {
        background: ${ (props) => [props.theme.style.buttonTypeColors.secondary].join('') };
        color: ${ (props) => [props.theme.mode.fontColor].join('') };
    }

    .react-datepicker__day:hover, 
    .react-datepicker__month-text:hover, 
    .react-datepicker__quarter-text:hover, 
    .react-datepicker__year-text:hover {
        background: ${
            (props) => [
                Color(props.theme.style.buttonTypeColors.primary).fade(0.50)
            ].join('')
        };
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select
     {
        border-radius: 0.3em;
        background: transparent;
        color: ${ (props) => [props.theme.mode.fontColor].join('') };
    }

    .react-datepicker__month-select option,
    .react-datepicker__year-select option {
        background: ${ props => props.theme.mode.backgroundColors[2] };
        color: ${ (props) => [props.theme.mode.fontColor].join('') };

    }

    .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
    .react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range, 
        .react-datepicker__month-text--in-range, 
        .react-datepicker__quarter-text--in-range, 
        .react-datepicker__year-text--in-range
    ), 
    .react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range, 
        .react-datepicker__month-text--in-range, 
        .react-datepicker__quarter-text--in-range, 
        .react-datepicker__year-text--in-range), 
        .react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range, 
        .react-datepicker__month-text--in-range, 
        .react-datepicker__quarter-text--in-range, 
        .react-datepicker__year-text--in-range
    ),     
    .react-datepicker__time-container 
    .react-datepicker__time 
    .react-datepicker__time-box 
    ul.react-datepicker__time-list 
    li.react-datepicker__time-list-item:hover{
        background: ${
            (props) => [
                Color(props.theme.style.buttonTypeColors.primary).fade(0.50)
            ].join('')
        };
    }

    .spinner-border-lg {
        width: 4rem;
        height: 4rem;
    }

    /** adjusted because other poppers were behind leaflet panes. */
    .leaflet-pane {
        z-index: 0
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke:  ${ (props) => [
            props.theme.style.buttonTypeColors.primary
        ].join('') };
    }

    /** for gauge bars */
    
    .marker {
        padding-block: 0.1em;
        padding-inline: 0.35em;
        text-align: center;
        color: ${ props => props.theme.mode.fontColor };;
        background: ${ props => props.theme.style.buttonTypeColors.secondary };
        border-radius: 0.5em;
    }

    .marker > span {
        vertical-align: middle;
        font-size: 0.8em;
    }

    /** for react-truncate */
    .truncate-class-fix {
        /* white-space: nowrap; */
        overflow: hidden;
        display: block;
    }

    .truncate-class-fix br {
        display: none;
    }

    .truncate-class-fix span::after {
        content:" "
    }

    /** this will scale the html content according to the dimensions
    of the paper to be printed at. Make sure puppeteer has cssPageSize
    set to true */
    /* @page {
        size: A4;
    } */
    
    /** these properties only apply to PDF reports when comparing this to Watchdog 2.0 
    apply !important attribute to assure those changes are met.*/
    @media print {

        html, body {
            font-size: 14px !important;
            line-height: 1.2 !important;
        }

        a {
            text-decoration: initial !important;
            pointer-events: initial !important;
            cursor: pointer !important;
            color: initial;
        }

        table { page-break-inside:auto !important }
        tr  { page-break-inside:avoid !important; page-break-after:auto !important }
        thead { display:table-header-group !important; }
        tfoot { display:table-footer-group !important; }

        .table td {
            white-space: pre-wrap !important;
        }

        /** just to not confuse anyone, if you are expecting the Table component
        from ./components.tsx to also wrap, it will as long as the styling above
        retains its !important modifier. */

        /** to save print ink, table components will lose their background colors.
        Normally reports won't have background colors in their tables. Changes
        made in Table styled at components.tsx */
    }
`

export default GlobalStyle

import { RootState } from '@app/store'
import { LoginState } from '@interfaces/main/login'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/*
    Redux Toolkit allows us to write "mutating" logic in reducers. It
    doesn't actually mutate the state because it uses the immer library,
    which detects changes to a "draft state" and produces a brand new
    immutable state based off those changes
*/

/**
 * Reducers functions are restricted to setters. Any involvment with api calls must
 * be done elsewhere. For example, login form submission must be performed in the
 * login component. As shown, even if the component is unmounted, the function running
 * is still existant in the thread.
 */

/** JSDoc - api documentation generator.
 *  Slice for user access.
*/

/**
 * Since we are using typescript, it is assured that every property in each state
 * should have a defined type to avoid crashes more often than it used to.
 * Be mindful of Array declarations from API fetches. Those return results are unpredictible
 * so setting a type check is not feasible.
 */

/***/

const getFromLocalStorage : () => LoginState = () => {
    /** Coming from print pdf operations using sessionStorage instead of localStorage.
     * If localStorage token is undefined, get it from sessionStorage otherwise
     * initialize an empty string.
     */
    const defaultRoute = localStorage.getItem('defaultRoute') ||
        sessionStorage.getItem('defaultRoute') || ''
    const mfaToken = localStorage.getItem('mfaToken') ||
        sessionStorage.getItem('mfaToken') || ''
    const forcePasswordToken = localStorage.getItem('forcePasswordToken') ||
        sessionStorage.getItem('forcePasswordToken') || ''

    return {
        defaultRoute,
        mfaToken,
        forcePasswordToken
    }
}

const initialState: LoginState = getFromLocalStorage()

export const slice = createSlice({
    name: 'login',
    initialState,
    /** jsdoc annotations are done in the reducers themselves since the state,action parameters
     *  have been defined by redux) */
    /** adding payloadAction helps actions with data types */
    reducers: {
        setDefaultRoute: (state: LoginState, action: PayloadAction<string>) => {
            state.defaultRoute = action.payload
            localStorage.setItem('defaultRoute', action.payload)
        },
        setMfaToken: (state: LoginState, action: PayloadAction<string>) => {
            state.mfaToken = action.payload
            localStorage.setItem('mfaToken', action.payload)
        },
        setForcePasswordToken: (state: LoginState, action: PayloadAction<string>) => {
            state.forcePasswordToken = action.payload
            localStorage.setItem('forcePasswordToken', action.payload)
        },
        // reset three properties individually after a successful authentication
        resetDefaultRoute: (state: LoginState) => {
            state.defaultRoute = ''
            localStorage.removeItem('defaultRoute')
        },
        resetMfaToken: (state: LoginState) => {
            state.mfaToken = ''
            localStorage.removeItem('mfaToken')
        },
        resetForcePasswordToken: (state: LoginState) => {
            state.forcePasswordToken = ''
            localStorage.removeItem('forcePasswordToken')
        }

    },
    extraReducers: {}
})

/**
 * actions to export from slice
 */
export const {
    setDefaultRoute,
    setMfaToken,
    setForcePasswordToken,
    resetDefaultRoute,
    resetMfaToken,
    resetForcePasswordToken
} = slice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: LoginState) => state.counter.value)`
export const selectDefaultRoute = (state: RootState) => state.login.defaultRoute
export const selectMfaToken = (state: RootState) => state.login.mfaToken
export const selectForcePasswordToken = (state: RootState) => state.login.forcePasswordToken

export default slice.reducer

import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    IssueAlertColumn,
    IssueSeverityLabels,
    IssueSeverityValues,
    IssueTableColumn,
    IssueCountColumn,
    MdrDetailColumn,
    IssueTableKeys,
    MdrDetailKeys
} from '@interfaces/dashboard/soc/mdr/main'
import {
    ColorPresets,
    LabelValuePair
} from '@interfaces/main/root'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'MDR call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MODAL_HEADER: 'Fetching Header data...',
        ISSUE_HISTORY: 'Fetching Issue History data...',
        ISSUES_ALERTED: 'Fetching Issues Alerted data...',
        ISSUE_COUNT: 'Fetching Issue Count data...',
        ISSUE_TABLE: 'Fetching Issue Table data...',
        MODAL_DETAILS: 'Fetching modal details...',
        DATA_ID: 'Fetching data id...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        MODAL_HEADER: 'Header Data',
        ISSUE_HISTORY: 'Detected Issues This Period',
        ISSUES_ALERTED: 'Alerted Issues',
        ISSUE_COUNT: 'Issue Count',
        ISSUE_TABLE: 'Events in the Last 24 Hours'
    }
}
/** these are issue history rows. */
export const ISSUE_HISTORY_COLUMNS: Omit<LabelValuePair, 'value'>[] = [
    { label: 'Severity' },
    { label: 'Current Period' },
    { label: 'Previous Period' }
]

/** the previous version showed issues in color. */
export const ISSUE_HISTORY_ROWS: {
    label: IssueSeverityLabels, value: IssueSeverityValues, color: ColorPresets
}[] = [
    {
        label: 'No Issue',
        value: 'noIssue',
        color: 'green'
    },
    {
        label: 'Low Issue',
        value: 'lowIssue',
        color: 'amber-2'
    },
    {
        label: 'Medium Issue',
        value: 'mediumIssue',
        color: 'amber-2'
    },
    {
        label: 'High Issue',
        value: 'highIssue',
        color: 'amber-1'
    },
    {
        label: 'Severe Issue',
        value: 'severeIssue',
        color: 'amber-1'
    },
    {
        label: 'Critical Issue',
        value: 'criticalIssue',
        color: 'red'
    }
]

export const ISSUE_ALERTED_COLUMNS: IssueAlertColumn[] = [
    {
        label: 'Timestamp',
        value: 'timestamp'
    },
    {
        label: 'Title',
        value: 'title'
    },
    {
        label: 'Severity',
        value: 'severity'
    },
    {
        label: 'Source I.P.',
        value: 'src_ip'
    },
    {
        label: 'Destination I.P.',
        value: 'dest_ip'
    },
    {
        label: 'City Name',
        value: 'city_name'
    }
]

export const ISSUE_COUNT_COLUMNS: IssueCountColumn[] = [
    {
        label: 'Issue',
        value: 'issue'
    },
    {
        label: 'Count',
        value: 'count'
    }
]

export const ISSUE_TABLE_COLUMNS: IssueTableColumn[] = (() => {
    const columnNames: Omit<IssueTableColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Category',
            value: 'category'
        },
        // { label: 'Flow ID', value: 'flowid' },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'Signature',
            value: 'signature'
        },
        {
            label: 'Source I.P.',
            value: 'src_ip'
        },
        {
            label: 'Source Port',
            value: 'src_port'
        },
        {
            label: 'Protocol',
            value: 'proto'
        },
        {
            label: 'Destination I.P.',
            value: 'dest_ip'
        },
        {
            label: 'Destination Port',
            value: 'dest_port'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: IssueTableColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const MDR_DETAILS_COLUMNS: MdrDetailColumn[] = (() => {
    const columnNames: Omit<MdrDetailColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Category',
            value: 'category'
        },
        {
            label: 'Device ID',
            value: 'deviceid'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        },
        // { label: 'Flow ID', value: 'flowid' },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'Signature',
            value: 'signature'
        },
        {
            label: 'Source I.P.',
            value: 'src_ip'
        },
        {
            label: 'Source Port',
            value: 'src_port'
        },
        {
            label: 'Protocol',
            value: 'proto'
        },
        {
            label: 'Destination I.P.',
            value: 'dest_ip'
        },
        {
            label: 'Destination Port',
            value: 'dest_port'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: MdrDetailColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const ISSUE_TABLE_KEYS: IssueTableKeys[] = [
    'Timestamp', 'category', 'dest_ip', 'dest_port', 'flowid', 'geoip.continent_code',
    'geoip.country_code2', 'geoip.country_code3', 'geoip.country_name', 'geoip.ip',
    'geoip.latitude', 'geoip.location.lat', 'geoip.location.lon', 'geoip.longitude',
    'geoip.timezone', 'proto', 'signature', 'src_ip', 'src_port'
]

// still created only to be consistent with the previous initialization.
export const MDR_DETAILS_KEYS: MdrDetailKeys[] = []


import { useChangePasswordMutation } from '@apis/main/login-api'
import {
    useAppSelector
} from '@app/hook'
import {
    TEXT as LOGIN_TEXT,
    CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
    CHANGE_PASSWORD_VALIDATION_SCHEMA
} from '@constants/main/login'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import MenuLinks from '@features/main/MenuLinks'
import { MutationContext } from '@root/MutationProvider'
import {
    selectTabs
} from '@slices/main/profile'
import {
    FormStyledComponents as Form,
    SpinnerContainer
} from '@styles/components'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const Password = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    /** expected data is: changePassword formik. that's it. */
    const tabs = useAppSelector(selectTabs)

    const [changePassword, changePasswordMutation] = useChangePasswordMutation()

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA,
        onSubmit: async (values) => {
            const newToken = await revalidateToken()

            await changePassword({
                authToken: newToken,
                currentPassword: values.oldPassword,
                newPassword: values.newPassword,
                newPassword2: values.retypePassword
            })
        }
    })

    useEffect(() => {
        if (changePasswordMutation.data) {
            const data = changePasswordMutation.data
            if (data.status === 'success') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            // then empty form.
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [changePasswordMutation.data])

    useEffect(() => {
        if (changePasswordMutation.error) {
            console.error(changePasswordMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [changePasswordMutation.error])

    const OldPasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={LOGIN_TEXT.FORCE_PASSWORD.FORM.OLD_PASSWORD.ID}>
                    {LOGIN_TEXT.FORCE_PASSWORD.FORM.OLD_PASSWORD.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(errors.oldPassword)}
                    name={'oldPassword'}
                    type={'password'}
                    id={LOGIN_TEXT.FORCE_PASSWORD.FORM.OLD_PASSWORD.ID}
                    onChange={handleChange}
                    value={values.oldPassword}
                />
                <Form.Feedback errors={Boolean(errors.oldPassword)} >{
                    errors.oldPassword ? errors.oldPassword : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.oldPassword, errors.oldPassword])

    const NewPasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={LOGIN_TEXT.FORCE_PASSWORD.FORM.NEW_PASSWORD.ID}>
                    {LOGIN_TEXT.FORCE_PASSWORD.FORM.NEW_PASSWORD.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(errors.newPassword)}
                    name={'newPassword'}
                    type={'password'}
                    id={LOGIN_TEXT.FORCE_PASSWORD.FORM.NEW_PASSWORD.LABEL}
                    onChange={handleChange}
                    value={values.newPassword}
                />
                <Form.Feedback errors={Boolean(errors.newPassword)} >{
                    errors.newPassword ? errors.newPassword : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.newPassword, errors.newPassword])

    const RetypePasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={LOGIN_TEXT.FORCE_PASSWORD.FORM.RETYPE_PASSWORD.ID}>
                    {LOGIN_TEXT.FORCE_PASSWORD.FORM.RETYPE_PASSWORD.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(errors.retypePassword)}
                    name={'retypePassword'}
                    type={'password'}
                    id={LOGIN_TEXT.FORCE_PASSWORD.FORM.RETYPE_PASSWORD.ID}
                    onChange={handleChange}
                    value={values.retypePassword}
                />
                <Form.Feedback errors={Boolean(errors.retypePassword)} >{
                    errors.retypePassword ? errors.retypePassword : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.retypePassword, errors.retypePassword])

    const SubmitButton = useMemo(() => {
        const buttonContent = changePasswordMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{LOGIN_TEXT.FORCE_PASSWORD.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : LOGIN_TEXT.FORCE_PASSWORD.FORM.SUBMIT_BUTTON
        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={changePasswordMutation.isLoading}
                >{buttonContent}</Form.Button>
            </Form.Group>
        )
    }, [changePasswordMutation.isLoading])
    return (
        <div className={'container-fluid'}>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={handleSubmit}>
                        {OldPasswordInput}
                        {NewPasswordInput}
                        {RetypePasswordInput}
                        {SubmitButton}
                    </Form.Main>
                </div>
            </div>
        </div>
    )
}
export default Password

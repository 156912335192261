import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalMutation
} from '@apis/dashboard/soc/wss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { TEXT as MONITOR_TEXT } from '@constants/dashboard/monitor'
import {
    MESSAGE as WSS_MESSAGE,
    TEXT as WSS_TEXT
} from '@constants/dashboard/soc/wss'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import CleanResultsDataTable from '@features/dashboard/soc/wss/print/CleanResultsDataTable'
import IssueCategoriesChart from '@features/dashboard/soc/wss/IssueCategoriesChart'
import IssueOverviewChart from '@features/dashboard/soc/wss/IssueOverviewChart'
import OWASPClassificationChart from '@features/dashboard/soc/wss/OWASPClassificationChart'
import OWASPClassificationTable from '@features/dashboard/soc/wss/OWASPClassificationTable'
import SeverityConfidenceTable from '@features/dashboard/soc/wss/SeverityConfidenceTable'
import VulnerabilityIndexBarChart from '@features/dashboard/soc/wss/VulnerabilityIndexBarChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    ModalHeader,
    ModalRequest,
    CleanResultFilter,
    FixedCollapsibles
} from '@interfaces/dashboard/soc/wss'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectCardDetails,
    selectWssMain,
    setCardDetails
} from '@slices/main/print/section'
import {
    resetWssMain,
    selectCurrentParams,
    setCurrentParams,
    setFilters,
    setSearch,
    toggleCollapsible,
    selectFixedCollapsibles
} from '@slices/dashboard/soc/wss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import {
    differenceInSeconds,
    format,
    fromUnixTime,
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'
import { Chart } from 'chart.js'

const WssModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectWssMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    /** will be initiailized multiple times. */
    const [getModal, getModalMutation] = useGetModalMutation()
    const [getModalPrevious, getModalPreviousMutation] = useGetModalMutation()

    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalMutation.error])

    useEffect(() => {
        if (getModalPreviousMutation.error) {
            console.error(getModalPreviousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalPreviousMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetModalPrevious = () => {
        const unsubscribeMutation = getModalPrevious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetModal()
        unsubscribeGetModalPrevious()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getModalPreviousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                    dashboardMain.card &&
                    dashboardMain.searchParams
                    ) {
                    /** at this point we will no longer use the default
                         * values for searchParams.
                         */
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            service_type: dashboardMain.card.serviceType,
                            in_face: dashboardMain.card.inFace,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        const difference = Math.abs(differenceInSeconds(
                            fromUnixTime(newRanges.start), fromUnixTime(newRanges.end)
                        ))

                        getModalPromise = getModal(requestData)
                        getModalPreviousPromise = getModalPrevious({
                            ...requestData,
                            time_from: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.start),
                                    { seconds: difference }
                                )
                            ).toString(),
                            time_to: getUnixTime(
                                sub(
                                    fromUnixTime(newRanges.end),
                                    { seconds: difference }
                                )
                            ).toString()
                        })
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
            getModalPreviousPromise && getModalPreviousPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = getModalMutation.data

        const statcardData: ModalHeader = data?.header || {
            title: dashboardCardDetails?.title || '',
            line_1: dashboardCardDetails?.line_1 || '',
            line_2: dashboardCardDetails?.line_2 || '',
            heading: dashboardCardDetails?.heading || '',
            colorType: dashboardCardDetails?.colorType || 'grey',
            location: dashboardCardDetails?.location || '',
            lastUpdate: Number(dashboardCardDetails?.lastUpdate) || 0,
            state: dashboardCardDetails?.state || '',
            state_details: dashboardCardDetails?.state_details || ''
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={statcardData.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {statcardData.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {statcardData.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {statcardData.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = !_.isArray(getModalMutation.data)
            ? getModalMutation.data
            : undefined
        setBorderColor(
            data?.header.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalMutation
    ])

    useEffect(() => {
        return () => {
            dispatch(resetWssMain())
        }
    }, [])

    /** create a vulnerability index bar chart. this was previously done
     * with a chartjs instance but... we can use div elements
     * where the marker's position can be determined by passing a prop.
     */
    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    const SeverityConfidence = useMemo(() => {
        return <SeverityConfidenceTable
            data={getModalMutation.data?.data}
            dataPrevious={getModalPreviousMutation.data?.data}
            isLoading={getModalMutation.isLoading}
            isSuccess={getModalMutation.isSuccess}
            error={getModalMutation.error}
        />
    }, undefined)

    const VulnerabilityIndexBar = useMemo(() => {
        return <div>
            <VulnerabilityIndexBarChart
                data={getModalMutation.data?.data}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const ExecutiveSummary = useMemo(() => {
        const currentData = !_.isArray(getModalMutation.data?.data)
            ? getModalMutation.data?.data
            : undefined

        const content = (
            <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            currentData && currentData.executiveSummary.certainty
                                ? <Text size={'sm'}>
                                    {currentData?.executiveSummary.certainty}<br /><br />
                                    {currentData?.executiveSummary.completeness}<br /><br />
                                    {currentData?.executiveSummary.coverage}<br /><br />
                                    {currentData?.executiveSummary.overall}<br /><br />
                                    {currentData?.executiveSummary.scope}<br /><br />
                                    {currentData?.executiveSummary.severity}
                                </Text>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [getModalMutation.data])

    const issueCategoriesChartEl = useRef<HTMLCanvasElement>(null)
    const [
        issueCategoriesChartInstance,
        setIssueCategoriesChartInstance
    ] = useState<Chart<'bar', number[], string>>()
    /** issues categories chart and table components */
    const IssueCategories = useMemo(() => {
        const currentData = !_.isArray(getModalMutation.data?.data)
            ? getModalMutation.data?.data
            : undefined

        return <IssueCategoriesChart
            isPrint={true}
            chartEl={issueCategoriesChartEl}
            chartInstance={issueCategoriesChartInstance}
            setChartInstance={setIssueCategoriesChartInstance}
            data={currentData?.CWEData}
            isLoading={getModalMutation.isLoading}
            isSuccess={getModalMutation.isSuccess}
            error={getModalMutation.error}
        />
    }, undefined)

    const owaspClassificationChartEl = useRef<HTMLCanvasElement>(null)
    const [
        owaspClassificationChartInstance,
        setOWASPClassificationChartInstance
    ] = useState<Chart<'bar', number[], string>>()

    /** owasp risk classification chart and table */
    const OWASPClassification = useMemo(() => {
        const currentData = !_.isArray(getModalMutation.data?.data)
            ? getModalMutation.data?.data
            : undefined

        return <OWASPClassificationChart
            isPrint={true}
            chartEl={owaspClassificationChartEl}
            chartInstance={owaspClassificationChartInstance}
            setChartInstance={setOWASPClassificationChartInstance}
            data={currentData?.theASVSCats}
            isLoading={getModalMutation.isLoading}
            isSuccess={getModalMutation.isSuccess}
            error={getModalMutation.error}
        />
    }, undefined)

    const OWASPRiskClassification = useMemo(() => {
        const currentData = !_.isArray(getModalMutation.data?.data)
            ? getModalMutation.data?.data
            : undefined

        return <OWASPClassificationTable
            data={currentData?.theASVSCats}
            isLoading={getModalMutation.isLoading}
            isSuccess={getModalMutation.isSuccess}
            error={getModalMutation.error}
        />
    }, undefined)

    const issueOverviewChartEl = useRef<HTMLCanvasElement>(null)
    const [
        issueOverviewChartInstance,
        setIssueOverviewChartInstance
    ] = useState<Chart<'bar', number[], string>>()

    /** issues overview */
    const IssueOverview = useMemo(() => {
        const currentData = !_.isArray(getModalMutation.data?.data)
            ? getModalMutation.data?.data
            : undefined

        return <IssueOverviewChart
            isPrint={true}
            chartEl={issueOverviewChartEl}
            chartInstance={issueOverviewChartInstance}
            setChartInstance={setIssueOverviewChartInstance}
            data={currentData?.issueNameData}
            isLoading={getModalMutation.isLoading}
            isSuccess={getModalMutation.isSuccess}
            error={getModalMutation.error}
        />
    }, undefined)

    const CleanResultsTable = useMemo(() => {
        return (
            <CleanResultsDataTable
                printOptions={dashboardMain.printOptions}
                data={getModalMutation.data}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        )
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** useEffect to apply searchParams.filterList to tableData.filters
     * Currently printOptions has no retain order option but will be added
     * once requested as a feature ticket
     */
    useEffect(() => {
        dispatch(setFilters(
            (dashboardMain.searchParams?.filters || []) as CleanResultFilter[]
        ))
    }, [dashboardMain.searchParams?.filters])

    /** useEffect to apply searchParams.search to tableData.search */
    useEffect(() => {
        dispatch(setSearch(
            dashboardMain.searchParams?.search || ''
        ))
    }, [dashboardMain.searchParams?.search])

    return (

        // NOTE: no need for responsiveness here anymore. Go with one width class.
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* the rest of the content will be surrounded by a container with padding. */}

            <PrintMargin>

                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                <div className={'row mb-2 align-items-center'}>
                    <div className={'col-12 mb-2'}>
                        {Statcard}
                    </div>
                    <div className={'col-9'}>
                        {SeverityConfidence}
                    </div>
                    {/* ideally should be a fixed width in all screen sizes
                so we will add a div inside and apply that fixed width */}
                    <div className={'col-3'}>
                        {VulnerabilityIndexBar}
                    </div>
                </div>

                {/* layout for adding a title and the content changed because of collapse button
            line break on smaller devices */}
                <PageBreakInside className={'min-width-fix mb-3'}>
                    <span className={'d-inline-block mb-2'}>
                        {WSS_TEXT.SECTIONS.EXECUTIVE_SUMMARY}
                    </span>
                    {ExecutiveSummary}
                </PageBreakInside>

                {/* min-width-fix issues IF toggle device toolbar is disabled and horizontal
            resize is used. use the device toolbar from now on for more accurate results.
            also found at existing modals. */}
                <div className={'min-width-fix mb-2'}>
                    {IssueCategories}
                </div>

                <div className={'min-width-fix mb-2'}>
                    {OWASPClassification}
                </div>

                <PageBreakInside className={'min-width-fix'}>
                    <span className={'d-inline-block mb-2'}>
                        {WSS_TEXT.SECTIONS.OWASP_ASVS_RISK_CLASSIFICATION}
                    </span>
                    {OWASPRiskClassification}
                </PageBreakInside>

                <div className={'min-width-fix mb-2'}>
                    {IssueOverview}
                </div>
                {
                    !dashboardMain.printOptions.mainReportOnly
                        ? <PageBreakInside className={'min-width-fix'}>
                            {/* table stuff */}
                            {CleanResultsTable}
                        </PageBreakInside>
                        : ''
                }
            </PrintMargin>

        </div>
    )
}

export default WssModal

import {
    useGetM365ConfigDetailsMutation,
    useO365GetTrustedLocationMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    COLUMNS,
    INITIAL_VALUES,
    TEXT as CONFIG_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/configuration/device-config'
import {
    ConfigFormData,
    DeviceConfigModal,
    M365LocationLine,
    O365SetTrustedLocationKeys
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'

/** tables are created in separate components. */
import {
    Button,
    FormStyledComponents as Form,
    Table,
    Text
} from '@styles/components'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    FormikErrors,
    useFormik
} from 'formik'

const M365Details = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const [
        getM365ConfigDetails,
        getM365ConfigDetailsMutation
    ] = useGetM365ConfigDetailsMutation()

    const [
        o365GetTrustedLocation,
        o365GetTrustedLocationMutation
    ] = useO365GetTrustedLocationMutation()

    /** call formiks */
    const updateFormik = useFormik({
        initialValues: INITIAL_VALUES.M365.UPDATE,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.M365.UPDATE,
        onSubmit: () => {
            const configFormData:ConfigFormData = {
                m365: {
                    updateConfirm: {
                        formValues: updateFormik.values,
                        id: modal.formData.m365?.details?.id || ''
                    }
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'M365_DETAILS_UPDATE',
                formData: configFormData,
                isBorderWide: true
            }))
        }
    })

    const trustedLocationFormik = useFormik({
        initialValues: INITIAL_VALUES.M365.LOCATIONS,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.M365.LOCATIONS,
        onSubmit: () => {
            const configFormData:ConfigFormData = {
                m365: {
                    updateLocationConfirm: {
                        formValues: trustedLocationFormik.values,
                        id: modal.formData.m365?.details?.id || '',
                        deviceId: modal.formData.m365?.details?.deviceId || ''
                    }
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'M365_LOCATION_UPDATE',
                formData: configFormData,
                isBorderWide: true
            }))
        }
    })

    const DeviceIdInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.M365_UPDATE.FORM.DEVICEID.ID}>
                    {CONFIG_TEXT.M365_UPDATE.FORM.DEVICEID.LABEL}
                </Form.Label>
                <Form.Input
                    readOnly={true}
                    disabled={true}
                    errors={Boolean(updateFormik.errors.deviceId)}
                    name={'deviceId'}
                    id={CONFIG_TEXT.M365_UPDATE.FORM.DEVICEID.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.deviceId}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.deviceId)} >{
                    updateFormik.errors.deviceId ? updateFormik.errors.deviceId : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.deviceId, updateFormik.errors.deviceId])

    const TenantIdInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.M365_UPDATE.FORM.TENANTID.ID}>
                    {CONFIG_TEXT.M365_UPDATE.FORM.TENANTID.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.tenantId)}
                    name={'tenantId'}
                    id={CONFIG_TEXT.M365_UPDATE.FORM.TENANTID.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.tenantId}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.tenantId)} >{
                    updateFormik.errors.tenantId ? updateFormik.errors.tenantId : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.tenantId, updateFormik.errors.tenantId])

    const TenantInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.M365_UPDATE.FORM.TENANT.ID}>
                    {CONFIG_TEXT.M365_UPDATE.FORM.TENANT.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.tenant)}
                    name={'tenant'}
                    id={CONFIG_TEXT.M365_UPDATE.FORM.TENANT.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.tenant}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.tenant)} >{
                    updateFormik.errors.tenant ? updateFormik.errors.tenant : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.tenant, updateFormik.errors.tenant])

    const ClientIdInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.M365_UPDATE.FORM.CLIENTID.ID}>
                    {CONFIG_TEXT.M365_UPDATE.FORM.CLIENTID.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.clientId)}
                    name={'clientId'}
                    id={CONFIG_TEXT.M365_UPDATE.FORM.CLIENTID.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.clientId}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.clientId)} >{
                    updateFormik.errors.clientId ? updateFormik.errors.clientId : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.clientId, updateFormik.errors.clientId])

    const ThumbprintInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={CONFIG_TEXT.M365_UPDATE.FORM.THUMBPRINT.ID}>
                    {CONFIG_TEXT.M365_UPDATE.FORM.THUMBPRINT.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(updateFormik.errors.thumbprint)}
                    name={'thumbprint'}
                    id={CONFIG_TEXT.M365_UPDATE.FORM.THUMBPRINT.ID}
                    onChange={updateFormik.handleChange}
                    value={updateFormik.values.thumbprint}
                />
                <Form.Feedback errors={Boolean(updateFormik.errors.thumbprint)} >{
                    updateFormik.errors.thumbprint ? updateFormik.errors.thumbprint : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.thumbprint, updateFormik.errors.thumbprint])

    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={CONFIG_TEXT.M365_UPDATE.FORM.ACTIVE.ID}>
                    {CONFIG_TEXT.M365_UPDATE.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={CONFIG_TEXT.M365_UPDATE.FORM.ACTIVE.ID}
                    onChange={updateFormik.handleChange}
                    checked={updateFormik.values.active}
                />
                <Form.Feedback
                    errors={Boolean(updateFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        updateFormik.errors.active ? updateFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [updateFormik.values.active, updateFormik.errors.active])

    /** inputs for submitting an array of locations */

    const LocationListTable = useMemo(() => {
        /** create interface no longer just a text field */

        const thead = (
            <thead>
                <tr>
                    <th>
                        <Text
                            size={'xs'}
                            className={'text-uppercase'}
                        >
                            {TEXT.SEQUENCE}
                        </Text>
                    </th>
                    {
                        _.map(COLUMNS.M365.LOCATION_LINE, (column, index) => {
                            return (
                                <th key={'m365-location-th-' + index}>
                                    <Text
                                        size={'xs'}
                                        className={'text-uppercase'}
                                    >
                                        {column.value}
                                    </Text>
                                </th>
                            )
                        })
                    }
                    <th></th>
                </tr>
            </thead>
        )

        const renderInput = (
            key: keyof M365LocationLine,
            value: M365LocationLine[keyof M365LocationLine],
            locationLineIndex:number
        ) => {
            const fieldValue: O365SetTrustedLocationKeys = 'locations'

            const locationErrors = (trustedLocationFormik.errors.locations ||
                []) as FormikErrors<M365LocationLine>[]

            switch (key) {
                default: return (
                    <Form.TableInput
                        errors={Boolean(
                            locationErrors[locationLineIndex]?.[key] || false
                        )}
                        type={'text'}
                        name={[
                            fieldValue,
                            '[',
                            locationLineIndex.toString(),
                            '].', key
                        ].join('')}
                        onChange={trustedLocationFormik.handleChange}
                        onBlur={(e) => {
                            trustedLocationFormik.setFieldValue(
                                [
                                    'locations[',
                                    locationLineIndex.toString(),
                                    '].', key
                                ].join(''),
                                e.target.value.trim()
                            )
                        }}
                        value={value}
                    />
                )
            }
        }

        const renderRow = (
            locationLine: M365LocationLine,
            locationLineIndex: number
        ) => {
            return <tr key={'locationLine-row-' + locationLineIndex}>
                <td>{locationLineIndex}</td>
                {
                    _.map(COLUMNS.M365.LOCATION_LINE, (column, columnIndex) => {
                        return (
                            <td key={'locationLine-' + locationLineIndex + 'input-' + columnIndex}>
                                {
                                    renderInput(
                                        column.value,
                                        locationLine[column.value],
                                        locationLineIndex)
                                }
                            </td>
                        )
                    })
                }
                <td>
                    <Button
                        onClick={() => {
                            // remove current location line.
                            const locations = _.cloneDeep(trustedLocationFormik.values.locations)

                            locations.splice(locationLineIndex, 1)

                            trustedLocationFormik.setValues({
                                locations: locations
                            })
                        }}
                        size={'sm'}
                        mode={'danger'}>
                        {TEXT.TABLE.BUTTONS.REMOVE}
                    </Button>
                </td>
            </tr>
        }

        const tbody = (
            <tbody>
                {
                    _.map(trustedLocationFormik.values.locations, (
                        locationLine, locationLineIndex) => {
                        return renderRow(locationLine, locationLineIndex)
                    })
                }
            </tbody>
        )

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    {thead}
                    {tbody}
                </table>
            </Table>
        )
    }, [
        trustedLocationFormik.values.locations
    ])

    const AddM365LocationLineButton = useMemo(() => {
        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    onClick={() => {
                        const locations = _.cloneDeep(trustedLocationFormik.values.locations)

                        const defaultObj: M365LocationLine = {
                            city: '',
                            country: '',
                            user: ''
                        }

                        locations.push(defaultObj)

                        trustedLocationFormik.setValues({
                            locations: locations
                        })
                    }}
                    mode={'primary'}
                    type={'button'}
                >{CONFIG_TEXT.M365_LOCATION_UPDATE.FORM.ADD_LOCATION_LINE}</Form.Button>
            </Form.Group>
        )
    }, [
        trustedLocationFormik.values.locations
    ])

    useEffect(() => {
        if (getM365ConfigDetailsMutation.error) {
            console.error(getM365ConfigDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getM365ConfigDetailsMutation.error])

    useEffect(() => {
        if (o365GetTrustedLocationMutation.error) {
            console.error(o365GetTrustedLocationMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [o365GetTrustedLocationMutation.error])

    const unsubscribeGetM365ConfigDetails = () => {
        const unsubscribeMutation = getM365ConfigDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeO365GetTrustedLocation = () => {
        const unsubscribeMutation = o365GetTrustedLocation({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetM365ConfigDetails()
        unsubscribeO365GetTrustedLocation()
        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getM365ConfigDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let o365GetTrustedLocationPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            const id = modal.formData.m365?.details?.id
            const deviceid = modal.formData.m365?.details?.deviceId || ''

            if (token.valid && id) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getM365ConfigDetailsPromise = getM365ConfigDetails({
                        authToken: newToken,
                        id: id
                    })

                    o365GetTrustedLocationPromise = o365GetTrustedLocation({
                        authToken: newToken,
                        deviceid: deviceid,
                        id: id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getM365ConfigDetailsPromise && getM365ConfigDetailsPromise.abort()
            o365GetTrustedLocationPromise && o365GetTrustedLocationPromise.abort()
        }
    }, [token.valid])

    const setDefaultDetailFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = getM365ConfigDetailsMutation.data

        if (data) {
            updateFormik.setValues({
                active: data.data.active,
                clientId: data.data.clientId,
                deviceId: data.data.deviceId,
                tenant: data.data.tenant,
                tenantId: data.data.tenantId,
                thumbprint: data.data.thumbprint
            })
        }
    }, [getM365ConfigDetailsMutation.data])

    useEffect(() => {
        setDefaultDetailFormData()
    }, [getM365ConfigDetailsMutation.data])

    const setDefaultLocationFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = o365GetTrustedLocationMutation.data

        if (data?.data?.length) {
            const locations: M365LocationLine[] = _.map(data.data, (obj) => {
                return {
                    country: obj.country,
                    city: obj.cities[0].city,
                    user: obj.cities[0].users[0].user
                }
            })

            trustedLocationFormik.setValues({
                locations: locations
            })
        }
    }, [o365GetTrustedLocationMutation.data])

    useEffect(() => {
        setDefaultLocationFormData()
    }, [o365GetTrustedLocationMutation.data])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                    >{CONFIG_TEXT.M365_UPDATE.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={setDefaultDetailFormData}
                    >{TEXT.FORM.RESET }</Button>
                </div>

            </Form.Group>
        )
    }, undefined)

    const LocationFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                    >{CONFIG_TEXT.M365_LOCATION_UPDATE.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={setDefaultLocationFormData}
                    >{TEXT.FORM.RESET }</Button>
                </div>

            </Form.Group>
        )
    }, undefined)

    return (<div>
        <Form.Main onSubmit={updateFormik.handleSubmit} className={'mb-0'}>
            {DeviceIdInput}
            {TenantIdInput}
            {TenantInput}
            {ClientIdInput}
            {ThumbprintInput}
            {ActiveInput}
            {DetailsFormButtons}
        </Form.Main>
        <Form.Main onSubmit={trustedLocationFormik.handleSubmit} className={'mt-0'}>
            {AddM365LocationLineButton}
            {LocationListTable}
            {LocationFormButtons}
        </Form.Main>
    </div>)
}

export default M365Details


import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    DEBOUNCE_SEARCH_TIME,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    SharepointUserId
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams,
    selectDashboardData,
    selectSharepointUserIdTableData,
    setSharepointUserIdFiltered
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo
} from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface ComponentProps {
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const SharepointUserIdsDataTable = ({
    isLoading, isSuccess, error
} : ComponentProps) => {
    const dispatch = useAppDispatch()

    const currentParams = useAppSelector(selectCurrentParams)
    const tableData = useAppSelector(selectSharepointUserIdTableData)
    const dashboardData = useAppSelector(selectDashboardData)

    const setData = useDebouncedCallback(() => {
        const filteredData = smartSearch(
            dashboardData['SharepointUserId.CountedTable']
                ?.aggregations?.[2]?.buckets || [],
            [],
            tableData.search
        ) as SharepointUserId[]

        const orderedData = smartOrder(filteredData,
            tableData.columns) as SharepointUserId[]

        /**
         * set the filtered data to tableData.filtered.
         * any sort or pagination should be done in a separate useEffect
         * */
        dispatch(setSharepointUserIdFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    useEffect(() => {
        setData()
    }, [
        /** refreshed data from fetch invocation */
        dashboardData['SharepointUserId.CountedTable'],
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    /** ip address table with usual features. */
    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const label = (
                    <small>
                        {column.label}
                    </small>
                )

                return (
                    <th key={'column-' + index}>
                        {label}
                    </th>
                )
            })
        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: SharepointUserId,
            property: keyof SharepointUserId
        ) => {
            let cellContent: SharepointUserId[keyof SharepointUserId] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return (
                <div>
                    {cellContent}
                </div>
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length} className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>
        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length} className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.filtered, (dataObject, rowIndex) => {
                return (
                    <tr
                        key={'o365Event-row-' + rowIndex}
                    >
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'o365Event-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    >
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })
        )

        const content = (
            tableData.filtered.length
                ? cellContent
                : EmptyCellContent
        )

        return (
            !dashboardData['SharepointUserId.CountedTable']
                ? isLoading
                    ? LoadingContent
                    : isSuccess
                        ? content
                        : JSON.stringify(error)
                : content
        )
    }, [
        tableData, currentParams.ranges,
        isLoading, isSuccess, error
    ])

    return <div>
        <div className={'justify-content-center row mt-3'}>
            <div className={'col'}>
                <Table
                    className={'table-striped table-hover'}
                    height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                    bgIndex={2}
                >
                    <table className={'table'}>
                        <thead>
                            <tr>
                                {TableHead}
                            </tr>
                        </thead>
                        <tbody>
                            {TableBody}
                        </tbody>
                    </table>
                </Table>
            </div>
        </div>
    </div>
}

export default SharepointUserIdsDataTable

import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    AddOrderRequest,
    AddOrderResponse,
    DelOrderRequest,
    DelOrderResponse,
    GetBillingTypesListRequest,
    GetBillingTypesListResponse,
    GetBoxTypesListRequest,
    GetBoxTypesListResponse,
    GetCurrencyTypesListRequest,
    GetCurrencyTypesListResponse,
    GetOrderDeviceRequest,
    GetOrderDeviceResponse,
    GetOrderTypesListRequest,
    GetOrderTypesListResponse,
    GetOrdersRequest,
    GetOrdersResponse,
    GetPartnerDiscountRequest,
    GetPartnerDiscountResponse,
    GetPartnersListRequest,
    GetPartnersListResponse,
    GetProductTypesListRequest,
    GetProductTypesListResponse,
    InitiateDispatchRequest,
    InitiateDispatchResponse,
    OrderDevice,
    UpdateOrderRequest,
    UpdateOrderResponse,
    UpdateOrderStateRequest,
    UpdateOrderStateResponse
} from '@interfaces/watchdog/account-data/order'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getOrderDevice: builder.mutation<GetOrderDeviceResponse,
        GetOrderDeviceRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'api/orders/getOrderDevice',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {}
                }
            }
        }),
        getOrders: builder.mutation<GetOrdersResponse, GetOrdersRequest & TokenAuth>({
            query: (body) => {
                const formData: GetOrdersRequest = {
                    limit: body.limit,
                    skip: body.skip
                }

                return {
                    url: 'be/getOrders',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getOrderData: builder.mutation<GetOrdersResponse,
         Pick<OrderDevice, 'orderId'> & TokenAuth>({
             query: (body) => {
                 const formData: Pick<OrderDevice, 'orderId'> = {
                     orderId: body.orderId
                 }

                 return {
                     url: 'be/getOrders',
                     method: 'POST',
                     headers: {
                         Authorization: `Bearer ${ body.authToken }`,
                         'Content-Type': 'application/json'
                     },
                     body: formData
                 }
             }
         }),
        getPartnersList: builder
            .mutation<GetPartnersListResponse, GetPartnersListRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPartnersListRequest = {
                        partner_type: body.partner_type
                    }

                    return {
                        url: 'be/getPartnersList',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getCurrencyTypesList: builder
            .mutation<GetCurrencyTypesListResponse, GetCurrencyTypesListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getCurrencyTypesList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        getOrderTypesList: builder
            .mutation<GetOrderTypesListResponse, GetOrderTypesListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getOrderTypesList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        getBillingTypesList: builder
            .mutation< GetBillingTypesListResponse, GetBillingTypesListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getBillingTypesList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        getProductTypesList: builder
            .mutation< GetProductTypesListResponse, GetProductTypesListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getProductTypesList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        getBoxTypesList: builder
            .mutation< GetBoxTypesListResponse, GetBoxTypesListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getBoxTypesList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        getPartnerDiscount: builder
            .mutation<GetPartnerDiscountResponse, GetPartnerDiscountRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPartnerDiscountRequest = {
                        partnerID: body.partnerID
                    }

                    return {
                        url: 'be/getPartnerDiscount',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        delOrder: builder.mutation<DelOrderResponse, DelOrderRequest & TokenAuth>({
            query: (body) => {
                const formData: DelOrderRequest = {
                    orderID: body.orderID
                }

                return {
                    url: 'be/delOrder',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        addOrder: builder.mutation<AddOrderResponse, AddOrderRequest & TokenAuth>({
            query: (body) => {
                const formData: AddOrderRequest = {
                    order_type: body.order_type,
                    name: body.name,
                    setupInstructions: body.setupInstructions,
                    email: body.email,
                    comment: body.comment,
                    active: body.active,
                    activation_date: body.activation_date,
                    currency: body.currency,
                    customer_name: body.customer_name,
                    partner_name: body.partner_name,
                    orderLines: body.orderLines
                }

                return {
                    url: 'be/addOrder',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        updateOrder: builder.mutation<UpdateOrderResponse, UpdateOrderRequest & TokenAuth>({
            query: (body) => {
                const formData: UpdateOrderRequest = {
                    orderID: body.orderID,
                    order_type: body.order_type,
                    name: body.name,
                    setupInstructions: body.setupInstructions,
                    email: body.email,
                    comment: body.comment,
                    active: body.active,
                    activation_date: body.activation_date,
                    currency: body.currency,
                    customer_name: body.customer_name,
                    partner_name: body.partner_name,
                    orderLines: body.orderLines
                }

                return {
                    url: 'be/updateOrder',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        updateOrderState: builder
            .mutation<UpdateOrderStateResponse, UpdateOrderStateRequest & TokenAuth>({
                query: (body) => {
                    const formData: UpdateOrderStateRequest = {
                        orderID: body.orderID,
                        order_type: body.order_type
                    }

                    return {
                        url: 'be/updateOrderState',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        initiateDispatch: builder
            .mutation<InitiateDispatchResponse, InitiateDispatchRequest & TokenAuth>({
                query: (body) => {
                    const formData: InitiateDispatchRequest = {
                        order_id: body.order_id,
                        orderLines: body.orderLines
                    }

                    return {
                        url: 'be/initiateDispatch',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetOrdersMutation,
    useGetOrderDataMutation,
    useGetPartnersListMutation,
    useGetCurrencyTypesListMutation,
    useGetOrderTypesListMutation,
    useGetBillingTypesListMutation,
    useGetProductTypesListMutation,
    useGetBoxTypesListMutation,
    useGetPartnerDiscountMutation,
    useDelOrderMutation,
    useAddOrderMutation,
    useUpdateOrderMutation,
    useUpdateOrderStateMutation,
    useInitiateDispatchMutation,
    useGetOrderDeviceMutation
} = orderApi

import {
    DATE_FORMAT_TIME,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    Button,
    Table
} from '@styles/components'
import React, { useMemo } from 'react'
import {
    COLUMNS,
    EVENT_STATE_GROUPINGS,
    TEXT as EVENT_TEXT
} from '@constants/watchdog/soc-data/event'
import {
    AnswerReportValues,
    DecodedReportParam,
    ReportData,
    ReportModal
} from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import _ from 'lodash'
import { Base64 } from 'js-base64'
import { format } from 'date-fns'
import { FormikProps } from 'formik'

const ReviewNotesHistory = ({
    decodedReportParams, modal, addModal,
    closeModal, reportStepsFormik
}: {
    decodedReportParams: DecodedReportParam
    modal: ReportModal,
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    closeModal: ActionCreatorWithPayload<ReportModal, string>,
    reportStepsFormik: FormikProps<AnswerReportValues>,
}) => {
    /** there won't be a table search logic here. Just a sort by the answer timestamp and
     * a button to set the value AND close the modal.
     */

    const TableHead = useMemo(() => {
        return (
            _.map(COLUMNS.REVIEW_NOTES_HISTORY, (column, index) => {
                const label = (
                    <small>
                        {column.label}
                    </small>
                )

                return (
                    <th key={'column-' + index}>
                        {label}
                    </th>
                )
            })

        )
    }, [])

    const TableBody = useMemo(() => {
        /** filter answer history by reportSection */
        const data = _.filter(modal.formData.answerHistory?.data || [],
            (o) => {
                return o.reportSection === modal.formData.answerHistory
                    ?.reportStep.reportSection
            }
        )

        const cellBody = (
            dataObject: ReportData,
            property: keyof ReportData,
            inputIndex: number
        ) => {
            let cellContent: ReportData[keyof ReportData] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'answerTimestamp': {
                    // If it fails, remove last 4 numbers because moment
                    // doesn't support microseconds.
                    cellContent = format(new Date(dataObject.answerTimestamp), DATE_FORMAT_TIME)

                    break
                }
                case 'reviewNotes': {
                    // If it fails, remove last 4 numbers because moment
                    // doesn't support microseconds.
                    const arr = JSON.parse(
                        Base64.decode(dataObject.reviewNotes)
                    ) as unknown as string[]
                    cellContent = arr[inputIndex]

                    break
                }
                default:
                    cellContent = dataObject[property]
                    break
            }

            return (
                <div>
                    {cellContent}
                </div>
            )
        }

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={COLUMNS.REVIEW_NOTES_HISTORY.length + 1}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const orderedData = _.orderBy(data, ['answerTimestamp'], ['desc'])

        const cellContent = (
            _.map(orderedData, (dataObject, rowIndex) => {
                const cellKey = [
                    'reviewNotesHistory-cell-' + rowIndex +
                            '-' + (1 + COLUMNS.REVIEW_NOTES_HISTORY.length)
                ].join('')

                return (
                    <tr key={'reviewNotesHistory-row-' + rowIndex}>
                        {
                            _.map(COLUMNS.REVIEW_NOTES_HISTORY, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'reviewNotesHistory-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}>
                                        {cellBody(
                                            dataObject, column.value,
                                            modal.formData.answerHistory
                                                ?.reportStep.inputIndex || 0
                                        )}
                                    </td>
                                )
                            })
                        }
                        <td key={cellKey}>
                            <Button
                                type={'button'}

                                /** only group 1 event states can set a value. */
                                disabled = {
                                    !_.includes(
                                        EVENT_STATE_GROUPINGS.GROUP_2,
                                        decodedReportParams.eventState
                                    )
                                }
                                onClick={() => {
                                    // decode and parse the base64 string.
                                    const reviewNotes = JSON.parse(
                                        Base64.decode(dataObject.reviewNotes)
                                    ) as unknown as string[]

                                    _.includes(
                                        EVENT_STATE_GROUPINGS.GROUP_3,
                                        decodedReportParams.eventState
                                    ) && reportStepsFormik.setFieldValue(
                                        [
                                            'reportSteps',
                                            '[', modal.formData.answerHistory
                                                ?.reportStep.stepIndex.toString(), '].',
                                            'reportStepInput.',
                                            'reviewNotes[',
                                            modal.formData.answerHistory
                                                ?.reportStep.inputIndex.toString(),
                                            ']'
                                        ].join(''),
                                        reviewNotes[modal.formData.answerHistory
                                            ?.reportStep.inputIndex || 0]
                                    )
                                }}
                                size={'sm'}
                                mode={'primary'}>
                                {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.REVIEW_NOTE_HISTORY_SET}
                            </Button>
                        </td>
                    </tr>
                )
            })
        )

        return (
            modal.formData.answerHistory?.data.length
                ? cellContent
                : EmptyCellContent
        )
    }, [])

    return <div>
        {
            _.includes(EVENT_STATE_GROUPINGS.GROUP_1, decodedReportParams.eventState)
                ? <h6>
                    {EVENT_TEXT.REPORT_INTERFACE.HISTORY_HINT}
                </h6>
                : ''
        }
        <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.MEDIUM}
            bgIndex={1}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {TableHead}
                        {/* action section is created here. */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {TableBody}
                </tbody>
            </table>
        </Table>
    </div>
}

export default ReviewNotesHistory

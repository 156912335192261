import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    CreateFilterRequest,
    CreateFilterResponse,
    DeleteFilterRequest,
    DeleteFilterResponse,
    GetFiltersRequest,
    GetFiltersResponse,
    UpdateFilterRequest,
    UpdateFilterResponse
} from '@interfaces/watchdog/soc-data/filter'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const filterApi = createApi({
    reducerPath: 'filterApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getFilters: builder.mutation<GetFiltersResponse, GetFiltersRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getFilters',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        deviceid: body.deviceid
                    }
                }
            }
        }),
        createFilter: builder.mutation<CreateFilterResponse, CreateFilterRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/createFilter',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        eventname: body.eventname,
                        hostname: body.hostname,
                        boxname: body.boxname,
                        src_ip: body.srcIp,
                        dest_ip: body.destIp,
                        type: body.type,
                        value: body.value
                    }
                }
            }
        }),
        deleteFilter: builder.mutation<DeleteFilterResponse, DeleteFilterRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/deleteFilter',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: body.id
                    }
                }
            }
        }),
        updateFilter: builder.mutation<UpdateFilterResponse, UpdateFilterRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/updateFilter',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: body.id,
                        eventname: body.eventname,
                        hostname: body.hostname,
                        boxname: body.boxname,
                        src_ip: body.srcIp,
                        dest_ip: body.destIp,
                        type: body.type,
                        value: (body.value).toFixed(1),
                        active: body.active
                    }
                }
            }
        })
    })
})

export const {
    useCreateFilterMutation,
    useDeleteFilterMutation,
    useGetFiltersMutation,
    useUpdateFilterMutation
} = filterApi


import {
    useGetDataIdMutation,
    useGetFlowIdMutation
} from '@apis/dashboard/soc/mdr-api'
import {
    useGetEventsMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    DATA_ID_EXCLUSIONS,
    MESSAGE as DASHBOARD_MESSAGE,
    TEXT as DASHBOARD_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as MDR_MESSAGE /* TEXT as MDR_TEXT */
} from '@constants/dashboard/soc/mdr/main'
import {
    getUtcRanges,
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    CHART_HEIGHT
} from '@constants/main/root'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { displayUnpredictable } from '@features/main/DisplayUnpredictable'
import {
    MonitorModal,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    GetDataIdRequest
} from '@interfaces/dashboard/soc/mdr/main'
import { TokenAuth } from '@interfaces/main/root'
import {
    Flow,
    GetFlowIDRequest
} from '@interfaces/watchdog/soc-data/flow'
import { MutationContext } from '@root/MutationProvider'
import {
    addFilter,
    removeFilter,
    removeFilters,
    selectSearchParams,
    selectFlowTableData,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setEndDate,
    setFiltered,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setStartDate,
    resetMdrDataId,
    setCurrentParams
} from '@slices/dashboard/soc/mdr/dataId'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { useMediaQuery } from 'react-responsive'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'
import L from 'leaflet'
import 'leaflet-providers'
import 'leaflet.markercluster/dist/leaflet.markercluster-src'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { AiTwotoneAlert } from 'react-icons/ai'

const MDRDataID = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    /** no use for currentParams yet unless there is an event that requires it. */
    const flowTableData = useAppSelector(selectFlowTableData)
    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    /** will be initiailized multiple times. */
    const [getDataId, getDataIdMutation] = useGetDataIdMutation()
    const [getFlowId, getFlowIdMutation] = useGetFlowIdMutation()
    const [getEvents] = useGetEventsMutation()

    useEffect(() => {
        if (getDataIdMutation.error) {
            console.error(getDataIdMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getDataIdMutation.error])

    useEffect(() => {
        if (getFlowIdMutation.error) {
            console.error(getFlowIdMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getFlowIdMutation.error])

    /** WILL HAVE date ranges like in the parent modal. Starting ranges are the data
     *  of ip address being passed over */

    const unsubscribeGetDataId = () => {
        const unsubscribeMutation = getDataId({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFlowId = () => {
        const unsubscribeMutation = getFlowId({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetDataId()
        unsubscribeGetFlowId()

        let getDataIdPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getFlowIdPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const dataIdRequestData: GetDataIdRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            id: modal.serviceTypeFormData
                                ?.mdr?.dataId?.id || '',
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()

                        }

                        const flowIdRequestData: GetFlowIDRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            flowid: modal.serviceTypeFormData
                                ?.mdr?.dataId?.flowid || '',
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getDataIdPromise = getDataId(dataIdRequestData)
                        getFlowIdPromise = getFlowId(flowIdRequestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getDataIdPromise && getDataIdPromise.abort()
            getFlowIdPromise && getFlowIdPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getDataIdMutation.isSuccess &&
            getFlowIdMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getDataIdMutation.isSuccess,
        getFlowIdMutation.isSuccess
    ])

    /** button to escalate the selected data ID. Confirmation window needed. */
    const EscalateButton = useMemo(() => {
        const disableRefresh = getDataIdMutation.isLoading
        return (
            <span
                onClick={async () => {
                    const newRanges = getUtcRanges(searchParams.ranges)

                    // get other relevant data using search events api call.
                    const newToken = await revalidateToken()
                    const eventData = await getEvents({
                        authToken: newToken,
                        type: 'current',
                        filters: false,
                        fromTime: newRanges.start.toString(),
                        toTime: newRanges.end.toString(),
                        boxname: modal.card.deviceid,
                        eventid: modal.serviceTypeFormData
                            ?.azure?.dataId?.id || ''
                    }).unwrap()

                    if (eventData.eventData[0]) {
                        const serviceTypeFormData:ServiceTypeFormData = {
                            azure: {
                                eventEscalate: {
                                    eventObj: {
                                        clusterid: eventData.eventData[0].clusterid,
                                        timestamp: eventData.eventData[0].timestamp,
                                        hostname: eventData.eventData[0].hostname,
                                        eventname: eventData.eventData[0].eventname,
                                        src_ip: eventData.eventData[0].src_ip,
                                        src_port: eventData.eventData[0].src_port,
                                        dest_ip: eventData.eventData[0].dest_ip,
                                        dest_port: eventData.eventData[0].dest_port,
                                        protocol: eventData.eventData[0].protocol,
                                        flow_id: eventData.eventData[0].flow_id,
                                        id: eventData.eventData[0].id,
                                        severity: eventData.eventData[0].severity,
                                        domain: eventData.eventData[0].domain
                                    }
                                }
                            }
                        }

                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            card: modal.card,
                            operation: 'EVENT_ESCALATE',
                            serviceTypeFormData: serviceTypeFormData,
                            isBorderWide: true
                        }))
                    } else {
                        toast.error(
                            DASHBOARD_MESSAGE.EVENT_NOT_FOUND,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <AiTwotoneAlert/>
            </span>
        )
    }, [modal, getDataIdMutation, searchParams])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getDataIdMutation.isLoading || getFlowIdMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update mdr bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [getDataIdMutation, getFlowIdMutation])

    const ActionButtons = useMemo(() => {
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{DASHBOARD_TEXT.ESCALATE.LABEL}</div>}>
                    <div className={'d-inline-block'}>{EscalateButton}</div>
                </Tippy>
            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        EscalateButton
    ])

    /** create two components. one to display the data id contents (ALL OF IT)
     * and a leaflet instance.
     */
    const descriptionEl = useRef<HTMLDivElement>(null)

    const DataIdContent = useMemo(() => {
        const response = getDataIdMutation.data?.data

        // recursive methods are necessary if the data is too dynamic
        // static typing will not work here.

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.DATA_ID}</span>
                </SpinnerContainer>
            </small>
        )

        // could be any value at this point.
        const rowContent = (key: any, label: string, value: any) => {
            return (
                <div key={key} className={'row mb-1 justify-content-between'}>
                    <Text size={'xs'} className={'col-6 text-capitalize'}>{label}</Text>
                    <Text size={'xs'} className={'col-6'}>{value || TEXT.NONE}</Text>
                </div>
            )
        }

        // show all except for ["payload","payload_printable","packet","http_request_body",
        // "http_response_body","http_request_body_printable","http_response_body_printable"]
        const content = (
            <>
                {
                    // content has to be an object
                    response
                        ? _.map(_.entries(response), ([label, value], index) => {
                            const key = ['dataId-content-', index].join('')

                            if (!DATA_ID_EXCLUSIONS.includes(_.toLower(label))) {
                                return displayUnpredictable(
                                    rowContent, key, label, value
                                )
                            }
                        })
                        : MESSAGE.DATA.EMPTY_DATA
                }
            </>
        )

        return (
            <Container bgIndex={2} className={'px-3 py-3'}>
                {
                    !getDataIdMutation.isLoading
                        ? getDataIdMutation.isSuccess
                            ? content
                            : JSON.stringify(getDataIdMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, undefined)

    /** create new leaflet map with markers */
    const mapEl = useRef<HTMLDivElement>(null)

    const isMobileDevice = useMediaQuery({
        query: (
            () => {
                const stylesheet = createStylesheet(style, mode)
                return [
                    '(max-width:', stylesheet.style.mediaQueries.xs, ')'
                ].join('')
            }
        )()
    })
    const [mapid] = useState(uniqueString())
    const [mapInstance, setMapInstance] = useState<L.Map>()

    useEffect(() => {
        const response = getDataIdMutation.data?.data
        const geoip = response?.geoip || {}

        setTimeout(() => {
            if (mapEl.current && descriptionEl.current) {
                mapEl.current.style.height = isMobileDevice
                    ? CHART_HEIGHT.md
                    : descriptionEl.current.clientHeight + 'px'
            }

            if (mapEl.current && Object.keys(geoip).length) {
                setMapInstance(L.map(mapid))
            }
        }, 1000)
    }, [getDataIdMutation])

    useEffect(() => {
        const response = getDataIdMutation.data?.data
        const geoip = response?.geoip || {}

        if (mapInstance && Object.keys(geoip).length) {
            mapInstance.setView([
                geoip.location?.[1] || geoip.location.lat,
                geoip.location?.[0] || geoip.location.lon
            ], 6)
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(mapInstance)
            L.marker([
                geoip.location?.[1] || geoip.location.lat,
                geoip.location?.[0] || geoip.location.lon
            ]).addTo(mapInstance)

            return () => {
                mapInstance.off()
                mapInstance.remove()
                setMapInstance(undefined)
            }
        }
    }, [getDataIdMutation, mapInstance])
    // MESSAGE.DATA.EMPTY_GEOIP

    const DataIdMap = useMemo(() => {
        return (
            <div>
                {
                    !mapInstance
                        ? <Text className={'position-absolute'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                        : ''
                }
                <div ref={mapEl} id={mapid} />
            </div>

        )
    }, [mapInstance])

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        if (getFlowIdMutation.data) {
            const filteredData = smartSearch(
                getFlowIdMutation.data.devicedata.results,
                flowTableData.filters,
                flowTableData.search
            ) || [] as Flow[]
            const orderedData = smartOrder(filteredData, flowTableData.columns) as Flow[]

            dispatch(setFiltered(orderedData))
        }
    }, DEBOUNCE_SEARCH_TIME)

    const paginateData = () => {
        const result = paginate(
            flowTableData.filtered,
            flowTableData.page,
            flowTableData.count
        ) as Flow[]

        dispatch(setPaginated(result))
    }

    useEffect(() => {
        setData()
    }, [
        getFlowIdMutation.data,
        flowTableData.filters,
        flowTableData.search,
        flowTableData.columns
    ])

    useEffect(() => {
        paginateData()
    }, [
        flowTableData.filtered,
        flowTableData.page,
        flowTableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        flowTableData.filters,
        flowTableData.search,
        flowTableData.count,
        flowTableData.columns
    ])

    /** ip address table with usual features. */
    const TableHead = useMemo(() => {
        return (
            _.map(flowTableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [flowTableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: Flow,
            property: keyof Flow
        ) => {
            let cellContent: Flow[keyof Flow] = ''
            let rawValue: Flow[keyof Flow] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'timestamp':
                    cellContent = format(new Date(dataObject[property]), DATE_FORMAT_TIME)
                    break

                case 'src_ip':
                    cellContent = [
                        dataObject[property], ' ',
                        dataObject.protocol, ':',
                        dataObject.src_port].join('')
                    break

                case 'dest_ip':
                    cellContent = [
                        dataObject[property], ' ',
                        dataObject.protocol, ':',
                        dataObject.dest_port].join('')
                    break

                default:
                    rawValue = cellContent = dataObject[property]
                    break
            }

            const addMultipleFilters = (
                include:boolean,
                filters:{
                    sort: keyof Flow, value: string | number
                }[]
            ) => {
                _.forEach(filters, (obj) => {
                    dispatch(addFilter({
                        not: include,
                        sort: obj.sort,
                        value: [obj.value as string]
                    }))
                })
            }

            const zoomAction = (not: boolean) => {
                if (property === 'src_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'src_ip',
                            value: dataObject.src_ip
                        },
                        {
                            sort: 'src_port',
                            value: dataObject.src_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else if (property === 'dest_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'dest_ip',
                            value: dataObject.dest_ip
                        },
                        {
                            sort: 'dest_port',
                            value: dataObject.dest_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else {
                    addMultipleFilters(not, [
                        {
                            sort: property,
                            value: rawValue
                        }
                    ])
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={flowTableData.columns.length} className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={flowTableData.columns.length}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(flowTableData.paginated, (dataObject, rowIndex) => {
                return (
                    <tr key={'flow-row-' + rowIndex}>
                        {
                            _.map(flowTableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'flow-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    >
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })
        )

        return (
            getFlowIdMutation.isLoading
                ? LoadingContent
                : getFlowIdMutation.isSuccess
                    ? (
                        flowTableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(getFlowIdMutation.error)
        )
    }, [flowTableData, getFlowIdMutation])

    useEffect(() => {
        return () => {
            dispatch(resetMdrDataId())
        }
    }, [])

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-end mb-3'}>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            <div className={'row mb-3'} ref={descriptionEl}>
                <div className={'col-md-6 col-12'}>
                    {DataIdContent}
                </div>
                <div className={'col-md-6 col-12'}>
                    {DataIdMap}
                </div>
            </div>
            <div className={'min-width-fix'}>
                <div className={'align-items-center justify-content-between row'}>
                    <div className={'col-12 col-md px-4'}>
                        <SearchRow className={'align-items-center row'}>
                            <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                                <FaSearch />
                            </label>
                            <input
                                className={'col'}
                                type={'text'}
                                id={TEXT.SEARCH.SEARCH.ID}
                                onChange={(e) => {
                                    setSearchDebounce(e.target.value)
                                }}
                                placeholder={'Search'}
                            />
                        </SearchRow>
                    </div>

                    {/* this is the dropdown for page count */}
                    <div className={'col-12 col-md-auto'}>
                        <div className={'align-items-center justify-content-between row'}>
                            <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                                <div className={'align-items-center justify-content-center row'}>
                                    <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                    <Select
                                        className={'col-auto'}
                                        options={ROW_COUNT_SELECTION}
                                        value={_.find(
                                            ROW_COUNT_SELECTION,
                                            (e) => e.value === flowTableData.count
                                        )}
                                        onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                            if (e) {
                                                dispatch(setCount(e.value))
                                            }
                                        }}
                                        styles={{
                                            ...reactSelect.styles,
                                            ...FIXED_REACT_SELECT_WIDTH.SMALL
                                        }}
                                        theme={reactSelect.theme}
                                    />
                                </div>
                            </div>
                            <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                                <Pagination

                                    current={flowTableData.page}
                                    total={flowTableData.filtered.length}
                                    pageSize={flowTableData.count}
                                    onChange={(current) => {
                                        dispatch(setPage(current))
                                    }}
                                    locale={EN_US}
                                />
                            </div>

                        </div>

                    </div>
                </div>
                <div className={'row mt-2'}>
                    {/* display columns aka a filter shere */}
                    <div className={'col ps-4'}>
                        <div className={'row'}>
                            {
                                _.map(flowTableData.filters, (filter, index) => {
                                    return (
                                        <FilterCell key={'filter-' + index} className={
                                            ['col-auto me-2 px-2 mt-1',
                                                filter.not ? 'not' : ''].join(' ')
                                        }>
                                            <Text size={'xs'}>
                                                {[
                                                    filter.not ? '!' : '',
                                                    filter.sort,
                                                    ': ',
                                                    filter.value
                                                ]
                                                    .join('')}
                                            </Text>
                                            <Text size={'xs'} className={'ps-2'} onClick={() => {
                                                dispatch(removeFilter(filter))
                                            }}>
                                                <FaTimes />
                                            </Text>
                                        </FilterCell>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <span
                        className={[
                            'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                            flowTableData.filters.length ? 'd-block' : 'd-none'
                        ].join(' ')}
                        onClick={() => {
                            dispatch(removeFilters())
                        }}
                    >
                        {/* clear all filters button. baseline, not center */}
                        <FaTimes />
                    </span>
                </div>
                <div className={'justify-content-center row mt-3'}>
                    <div className={'col'}>
                        <Table
                            className={'table-striped table-hover'}
                            height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                            bgIndex={2}
                        >
                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        {TableHead}
                                    </tr>
                                </thead>
                                <tbody>
                                    {TableBody}
                                </tbody>
                            </table>
                        </Table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MDRDataID

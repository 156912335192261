
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalMutation
} from '@apis/dashboard/tac/vmm-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as VMM_MESSAGE,
    TEXT as VMM_TEXT
} from '@constants/dashboard/tac/vmm'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import IssueOvertimeChart from '@features/dashboard/tac/vmm/IssueOvertimeChart'
import MeanMitigationTimeChart from '@features/dashboard/tac/vmm/MeanMitigationTimeChart'
import PersistentDataTable from '@features/dashboard/tac/vmm/print/PersistentDataTable'
import SeverityDoughnutChart from '@features/dashboard/tac/vmm/SeverityDoughnutChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    FixedCollapsibles,
    ModalRequest
} from '@interfaces/dashboard/tac/vmm'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'
import {
    selectCurrentParams,
    setCurrentParams,
    toggleCollapsible,
    selectFixedCollapsibles
} from '@slices/dashboard/tac/vmm'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer
} from '@styles/components'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectVmmMain,
    setCardDetails
} from '@slices/main/print/section'

import { useDebouncedCallback } from 'use-debounce'

const EVMModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    /** if expandDetails is false, display component as normal.
     * else, each control category will display the details
     * of the respective action types. For example,
     * (three gauges, the line chart astaciated with it and then
     * the details of each action type)
     */

    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const dashboardMain = useAppSelector(selectVmmMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModal, getModalMutation] = useGetModalMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModal()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                        dashboardMain.card &&
                        dashboardMain.searchParams
                    ) {
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            service_type: dashboardMain.card.serviceType,
                            in_face: dashboardMain.card.inFace,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalPromise = getModal(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = {
            title: dashboardCardDetails?.title || '',
            line_1: dashboardCardDetails?.line_1 || '',
            line_2: dashboardCardDetails?.line_2 || '',
            heading: dashboardCardDetails?.heading || '',
            state: dashboardCardDetails?.state || '',
            state_details: dashboardCardDetails?.state_details || '',
            colorType: dashboardCardDetails?.colorType || 'grey'
        }

        /** getting line_1 differently */

        const meanMitigation = getModalMutation.data?.data?.[0]?.meanMitigation || []

        const average = (meanMitigation.reduce((a, b) => a + b.timeNeeded, 0) /
                (86400 * meanMitigation.length)).toFixed(1)

        data.line_1 = average.concat(' ', 'Days Mean Mitigation Time')

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{VMM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalMutation.data?.data?.[0]?.colorType
        setBorderColor(
            data || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** create doughnut chart. */
    const SeverityDoughnut = useMemo(() => {
        return <div>
            <SeverityDoughnutChart
                data={getModalMutation.data?.data[0]?.severityDoughnut}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    /** timeline chart. */
    const MeanMitigationTime = useMemo(() => {
        return <div>
            <MeanMitigationTimeChart
                data={getModalMutation.data?.data[0]?.meanMitigation}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    /** issues overtime chart. stacked.  */
    const IssueOvertime = useMemo(() => {
        return <div>
            <IssueOvertimeChart
                data={getModalMutation.data?.data[0]?.issuesOverTime}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const DataTable = useMemo(() => {
        return <div>
            <PersistentDataTable
                data={getModalMutation.data?.data[0]?.persistIssuesList}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)
    /** gauge components should be on a separate page. Even if the vmm/modal
     * calls will be made repeatedly in each tab, at least the slice
     * for vmm details will be used separately. Also,
     * we will make 3 separate mutations for the actionType string
     * in each controlCategory.
     */

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>
                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col-6'}>
                        {Statcard}
                    </div>
                    <div className={'col-6'}>
                        {SeverityDoughnut}
                    </div>
                </div>

                <div className={'min-width-fix'}>
                    <div className={'row mb-3 align-items-center'}>
                        <div className={'col-12'}>
                            {MeanMitigationTime}
                        </div>
                    </div>
                </div>

                <PageBreakInside className={'min-width-fix'}>
                    <span className={'d-inline-block mb-2'}>
                        {VMM_TEXT.SECTIONS.ISSUES_OVERTIME}
                    </span>
                    {IssueOvertime}
                </PageBreakInside>

                <div className={'min-width-fix mt-2'}>
                    <span className={'d-inline-block mb-2'}>
                        {VMM_TEXT.SECTIONS.PERSISTENT_OVERVIEW}
                    </span>
                    {DataTable}
                </div>

            </PrintMargin>

        </div>
    )
}

export default EVMModal

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    INITIAL_SEARCH_PARAMS
} from '@constants/dashboard/grc/gdpr'
import {
    CalculatedChapterData,
    ExpandText,
    GdprMainState
} from '@interfaces/dashboard/grc/gdpr'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState : GdprMainState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    calculatedChapterData: [],
    expandText: {
        coverage: false,
        gdpr: false
    },
    /** this is just to provide printOptions optional properties
     * on start. If you want the properties to be mandatory,
     * modify them in the state's interface.
     */
    printOptions: {
        logo: ''
    }
}

export const slice = createSlice({
    name: 'gdprMain',
    initialState: initialState,
    reducers: {
        setStartDate: (state: GdprMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: GdprMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: GdprMainState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setLogo: (state: GdprMainState, action: PayloadAction<string>) => {
            state.printOptions.logo = action.payload
        },
        setCalculatedChapterData: (
            state: GdprMainState, action: PayloadAction<CalculatedChapterData[]>) => {
            state.calculatedChapterData = action.payload
        },
        setExpandText: (state: GdprMainState, action: PayloadAction<{
            key: keyof ExpandText,
            value: boolean
        }>) => {
            state.expandText[action.payload.key] = action.payload.value
        },
        setCurrentParams: (state: GdprMainState, action: PayloadAction<
            GdprMainState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        resetGdprMain: (state: GdprMainState) => {
            state.printOptions = initialState.printOptions
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setLogo,
    setCalculatedChapterData,
    setExpandText,
    setCurrentParams,
    resetGdprMain
} = slice.actions

export const selectSearchParams = (state: RootState) => state.gdprMain.searchParams
export const selectCurrentParams = (state: RootState) => state.gdprMain.currentParams
export const selectPrintOptions = (state: RootState) => state.gdprMain.printOptions
export const selectExpandText = (state: RootState) => state.gdprMain.expandText
export const selectCalculatedChapterData =
    (state: RootState) => state.gdprMain.calculatedChapterData
export default slice.reducer

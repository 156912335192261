/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    INITIAL_SEARCH_PARAMS
} from '@constants/dashboard/grc/pcidss'
import {
    PcidssDetailsState,
    FixedCollapsibles
} from '@interfaces/dashboard/grc/pcidss'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState : PcidssDetailsState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    fixedCollapsibles: {
        complianceScores: true,
        coverageScores: true
    }
}

export const slice = createSlice({
    name: 'pcidssDetails',
    initialState: initialState,
    reducers: {
        setStartDate: (state: PcidssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: PcidssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: PcidssDetailsState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setCurrentParams: (state: PcidssDetailsState, action: PayloadAction<
            PcidssDetailsState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        toggleCollapsible: (state: PcidssDetailsState, action: PayloadAction<{
            key: keyof FixedCollapsibles,
            value: boolean
        }>) => {
            state.fixedCollapsibles[action.payload.key] = action.payload.value
        },
        resetPcidssDetails: (state: PcidssDetailsState) => {
            state.fixedCollapsibles = initialState.fixedCollapsibles
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }

    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setCurrentParams,
    toggleCollapsible,
    resetPcidssDetails
} = slice.actions

export const selectSearchParams = (state: RootState) => state.pcidssDetails.searchParams
export const selectCurrentParams = (state: RootState) => state.pcidssDetails.currentParams
export const selectFixedCollapsibles = (state: RootState) => state.pcidssDetails.fixedCollapsibles

export default slice.reducer

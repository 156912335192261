import {
    useAppDispatch
} from '@app/hook'
import {
    TEXT
} from '@constants/main/root'
import {
    TEXT as CONFIG_TEXT
} from '@constants/watchdog/configuration/device-config'
import {
    DeviceConfigModal
} from '@interfaces/watchdog/configuration/device-config'
/** tables are created in separate components. */
import {
    Button
} from '@styles/components'
import React from 'react'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const M365UpdateSuccess = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const dispatch = useAppDispatch()

    return (<div>
        <h5>{CONFIG_TEXT.M365_UPDATE.SUCCESS.TITLE}</h5>
        <small className={'d-block my-4'}>
            {modal.formData.m365?.updateSuccess}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default M365UpdateSuccess

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MutationContext
} from '@root/MutationProvider'
import {
    setCurrentParams
} from '@slices/dashboard/soc/oss/main'
import {
    selectToken
} from '@slices/main/token'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
/** no need to use the details slice. */
import {
    TokenAuth
} from '@interfaces/main/root'
import {
    selectOssControlCategory,
    selectOssMain
} from '@slices/main/print/section'

import {
    useGetModalDataMutation,
    useGetModalDetailsMutation,
    useGetModalLineChartMutation
} from '@apis/dashboard/soc/oss-api'
import {
    TEXT as OSS_TEXT
} from '@constants/dashboard/soc/oss'
import {
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import DataGauge from '@features/dashboard/soc/oss/print/DataGauge'
import OSSDetails from '@features/dashboard/soc/oss/print/OSSDetails'
import SpecificHSS from '@features/dashboard/soc/oss/SpecificHSS'
import {
    GetModalDetailsRequest,
    GetModalRequest
} from '@interfaces/dashboard/soc/oss'
import {
    PrintMargin
} from '@styles/components'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const OSSControlCategory = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    /** if expandDetails is false, display component as normal.
     * else, each control category will display the details
     * of the respective action types. For example,
     * (three gauges, the line chart associated with it and then
     * the details of each action type)
     */

    const dashboardMain = useAppSelector(selectOssMain)
    const ossControlCategoryForm = useAppSelector(selectOssControlCategory)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)

    const [getModalLineChart, getModalLineChartMutation] = useGetModalLineChartMutation()
    const [getModalData, getModalDataMutation] = useGetModalDataMutation()

    /** we are going to create three separate mutations for the getModalDetails  */
    const [
        getBehaviorModalDetails,
        getBehaviorModalDetailsMutation
    ] = useGetModalDetailsMutation()
    const [
        getConfigModalDetails,
        getConfigModalDetailsMutation
    ] = useGetModalDetailsMutation()
    const [
        getReviewModalDetails,
        getReviewModalDetailsMutation
    ] = useGetModalDetailsMutation()

    useEffect(() => {
        if (getModalLineChartMutation.error) {
            console.error(getModalLineChartMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalLineChartMutation.error])

    useEffect(() => {
        if (getModalDataMutation.error) {
            console.error(getModalDataMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalDataMutation.error])

    useEffect(() => {
        if (getBehaviorModalDetailsMutation.error) {
            console.error(getBehaviorModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBehaviorModalDetailsMutation.error])

    useEffect(() => {
        if (getConfigModalDetailsMutation.error) {
            console.error(getConfigModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getConfigModalDetailsMutation.error])

    useEffect(() => {
        if (getReviewModalDetailsMutation.error) {
            console.error(getReviewModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getReviewModalDetailsMutation.error])

    const unsubscribeGetModalLineChart = () => {
        const unsubscribeMutation = getModalLineChart({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const unsubscribeGetModalData = () => {
        const unsubscribeMutation = getModalData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetBehaviorModalDetails = () => {
        const unsubscribeMutation = getBehaviorModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetConfigModalDetails = () => {
        const unsubscribeMutation = getConfigModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const unsubscribeGetReviewModalDetails = () => {
        const unsubscribeMutation = getReviewModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalLineChart()
        unsubscribeGetModalData()
        unsubscribeGetBehaviorModalDetails()
        unsubscribeGetConfigModalDetails()
        unsubscribeGetReviewModalDetails()

        let getModalLineChartPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getModalDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getBehaviorModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getConfigModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getReviewModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                        dashboardMain.card &&
                        dashboardMain.searchParams
                    ) {
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            in_face: dashboardMain.card.inFace,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalLineChartPromise = getModalLineChart(requestData)
                        getModalDataPromise = getModalData(requestData)

                        const detailsRequestData: GetModalDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            actionType: 'Behavior',
                            controlCategory: ossControlCategoryForm.controlCategory,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getBehaviorModalDetailsPromise = getBehaviorModalDetails({
                            ...detailsRequestData,
                            actionType: 'Behavior'
                        })

                        getConfigModalDetailsPromise = getConfigModalDetails({
                            ...detailsRequestData,
                            actionType: 'Config'
                        })

                        getReviewModalDetailsPromise = getReviewModalDetails({
                            ...detailsRequestData,
                            actionType: 'Review'
                        })
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalLineChartPromise && getModalLineChartPromise.abort()
            getModalDataPromise && getModalDataPromise.abort()
            getBehaviorModalDetailsPromise && getBehaviorModalDetailsPromise.abort()
            getConfigModalDetailsPromise && getConfigModalDetailsPromise.abort()
            getReviewModalDetailsPromise && getReviewModalDetailsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** go to components now. */

    const behaviorEl = useRef<HTMLCanvasElement>(null)
    const behaviorScore = useMemo(() => {
        return (
            <DataGauge
                chartEl={behaviorEl}
                isLoading={getModalDataMutation.isLoading}
                isSuccess={getModalDataMutation.isSuccess}
                error={getModalDataMutation.error}
                actionType={'Behavior'}
                controlCategory={ossControlCategoryForm.controlCategory}
                secureScore={getModalDataMutation.data?.data.securescore}
            />
        )
    }, undefined)

    const configEl = useRef<HTMLCanvasElement>(null)
    const configScore = useMemo(() => {
        return (
            <DataGauge
                chartEl={configEl}
                isLoading={getModalDataMutation.isLoading}
                isSuccess={getModalDataMutation.isSuccess}
                error={getModalDataMutation.error}
                actionType={'Config'}
                controlCategory={ossControlCategoryForm.controlCategory}
                secureScore={getModalDataMutation.data?.data.securescore}
            />
        )
    }, undefined)

    const reviewEl = useRef<HTMLCanvasElement>(null)
    const reviewScore = useMemo(() => {
        return (
            <DataGauge
                chartEl={reviewEl}
                isLoading={getModalDataMutation.isLoading}
                isSuccess={getModalDataMutation.isSuccess}
                error={getModalDataMutation.error}
                actionType={'Review'}
                controlCategory={ossControlCategoryForm.controlCategory}
                secureScore={getModalDataMutation.data?.data.securescore}
            />
        )
    }, undefined)

    const gaugeScoreContainers = useMemo(() => {
        let title = ''

        switch (ossControlCategoryForm.controlCategory) {
            case 'Apps':
                title = OSS_TEXT.SECTIONS.CATEGORIES.APPS
                break
            case 'Data':
                title = OSS_TEXT.SECTIONS.CATEGORIES.DATA
                break
            case 'Device':
                title = OSS_TEXT.SECTIONS.CATEGORIES.DEVICE
                break
            case 'Identity':
                title = OSS_TEXT.SECTIONS.CATEGORIES.IDENTITY
                break
            default:
                break
        }

        return (
            <div className={'row mb-3'}>
                <div className={'col'}>
                    <span className={'d-inline-block mb-2'}>
                        {title}
                    </span>
                    <div className={'row'}>
                        <div className={'col-4'}>
                            {behaviorScore}
                        </div>
                        <div className={'col-4'}>
                            {configScore}
                        </div>
                        <div className={'col-4'}>
                            {reviewScore}
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [
        ossControlCategoryForm.controlCategory,
        behaviorScore,
        configScore,
        reviewScore
    ])

    /** get historical secure scores chart only for one control category. */
    const lineChart = useMemo(() => {
        return <SpecificHSS
            controlCategory={ossControlCategoryForm.controlCategory}
            lineChartData={getModalLineChartMutation.data?.data || []}
        />
    }, [
        getModalLineChartMutation.data
    ])

    /** oss details for behavior, config and review respectively */
    const ossBehaviorDetail = useMemo(() => {
        return <OSSDetails
            controlCategory={ossControlCategoryForm.controlCategory}
            actionType={'Behavior'}
            data={getBehaviorModalDetailsMutation.data?.data || []}
            isLoading={getBehaviorModalDetailsMutation.isLoading}
            isSuccess={getBehaviorModalDetailsMutation.isSuccess}
            error={getBehaviorModalDetailsMutation.error}
        />
    }, [
        ossControlCategoryForm.controlCategory,
        getBehaviorModalDetailsMutation
    ])

    const ossConfigDetail = useMemo(() => {
        return <OSSDetails
            controlCategory={ossControlCategoryForm.controlCategory}
            actionType={'Config'}
            data={getConfigModalDetailsMutation.data?.data || []}
            isLoading={getConfigModalDetailsMutation.isLoading}
            isSuccess={getConfigModalDetailsMutation.isSuccess}
            error={getConfigModalDetailsMutation.error}
        />
    }, [
        ossControlCategoryForm.controlCategory,
        getConfigModalDetailsMutation
    ])

    const ossReviewDetail = useMemo(() => {
        return <OSSDetails
            controlCategory={ossControlCategoryForm.controlCategory}
            actionType={'Review'}
            data={getReviewModalDetailsMutation.data?.data || []}
            isLoading={getReviewModalDetailsMutation.isLoading}
            isSuccess={getReviewModalDetailsMutation.isSuccess}
            error={getReviewModalDetailsMutation.error}
        />
    }, [
        ossControlCategoryForm.controlCategory,
        getReviewModalDetailsMutation
    ])

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalLineChartMutation.data &&
            getModalDataMutation.data &&
            getBehaviorModalDetailsMutation.data &&
            getConfigModalDetailsMutation.data &&
            getReviewModalDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalLineChartMutation.data,
        getModalDataMutation.data,
        getBehaviorModalDetailsMutation.data,
        getConfigModalDetailsMutation.data,
        getReviewModalDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalLineChartMutation.error ||
            getModalDataMutation.error ||
            getBehaviorModalDetailsMutation.error ||
            getConfigModalDetailsMutation.error ||
            getReviewModalDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalLineChartMutation.error,
        getModalDataMutation.error,
        getBehaviorModalDetailsMutation.error,
        getConfigModalDetailsMutation.error,
        getReviewModalDetailsMutation.error
    ])

    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            <PrintMargin>

                <div className={'min-width-fix'}>
                    {gaugeScoreContainers}
                </div>

                <div className={'min-width-fix'}>
                    {lineChart}
                </div>

                {/* preferred to make a page-break-inside for description and similarHosts */}
                <div className={'min-width-fix'}>
                    {ossBehaviorDetail}
                </div>

                <div className={'min-width-fix'}>
                    {ossConfigDetail}
                </div>

                <div className={'min-width-fix'}>
                    {ossReviewDetail}
                </div>

            </PrintMargin>

        </div>
    )
}

export default OSSControlCategory

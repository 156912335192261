import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    ExtWssDetailsState,
    SimilarHost
} from '@interfaces/dashboard/soc/wss'

import { INITIAL_SEARCH_PARAMS } from '@constants/dashboard/soc/wss'
import _ from 'lodash'

const initialState : ExtWssDetailsState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    similarHosts: []
}

export const slice = createSlice({
    name: 'wssDesc',
    initialState: initialState,
    reducers: {
        setStartDate: (state: ExtWssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: ExtWssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: ExtWssDetailsState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setCurrentParams: (state: ExtWssDetailsState, action: PayloadAction<
            ExtWssDetailsState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        setSimilarHosts: (state: ExtWssDetailsState, action: PayloadAction<SimilarHost[]>) => {
            state.similarHosts = action.payload
        },
        resetWssDetails: (state: ExtWssDetailsState) => {
            state.searchParams = initialState.searchParams
            state.similarHosts = initialState.similarHosts
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setCurrentParams,
    setSimilarHosts,
    resetWssDetails
} = slice.actions

export const selectSearchParams = (state: RootState) => state.wssDesc.searchParams
export const selectCurrentParams = (state: RootState) => state.wssDesc.currentParams
export const selectSimilarHosts = (state: RootState) => state.wssDesc.similarHosts

export default slice.reducer


import {
    useAppDispatch
} from '@app/hook'
import {
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'

import {
    TEXT as PRINT_TEXT
} from '@constants/main/print'
/** tables are created in separate components. */
import {
    Button
} from '@styles/components'
import React from 'react'
import { toast } from 'react-toastify'

import {
    Report,
    ReportModal
} from '@interfaces/main/print'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const IncompleteConfirmation = ({ modal, addModal, closeModal, downloadPDF } : {
    modal: ReportModal,
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    closeModal: ActionCreatorWithPayload<ReportModal, string>,
    downloadPDF: (dataObject: Report, withoutCrash: boolean) => Promise<void>
}) => {
    const dispatch = useAppDispatch()

    return (<div>
        <h5>{PRINT_TEXT.DOWNLOAD.INCOMPLETE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {PRINT_TEXT.DOWNLOAD.INCOMPLETE.CONFIRMATION_MESSAGE}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                <Button
                    mode={'primary'}
                    onClick={() => {
                        // after making the call, close this modal too.
                        if (modal.report) {
                            downloadPDF(modal.report, true)
                            dispatch(closeModal(modal))
                        } else {
                            console.error(PRINT_TEXT.DOWNLOAD.MISSING_REPORT)
                            toast.error(
                                PRINT_TEXT.DOWNLOAD.MISSING_REPORT,
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }}
                >
                    {PRINT_TEXT.DOWNLOAD.SUBMIT_BUTTON}
                </Button>
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default IncompleteConfirmation

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    COMPARISON_OPERATORS,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    INTERVALS,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    ACTIONS,
    KEYS,
    TEXT as CONFIG_TEXT
} from '@constants/watchdog/configuration/device-config'
import {
    ConfigFormData,
    DeviceConfigModal,
    VAPTConfigDetail,
    VAPTConfigDetailKeys
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
import {
    addFilter,
    removeFilter,
    removeFilters,
    selectTableData,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setFiltered,
    setFilterKeys,
    setFilterValues,
    setIntervalObj,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    toggleFilterInterface
} from '@slices/watchdog/configuration/device-configuration/vapt/details'
/** tables are created in separate components. */
import {
    ComparisonOperator
} from '@interfaces/main/root'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import {
    Button,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import flatten from 'flat'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'

import Pagination from 'rc-pagination'

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdManageSearch,
    MdRefresh
} from 'react-icons/md'
import { useIdleTimer } from 'react-idle-timer'
import Select from 'react-select'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'

/** requires response data from api call */
interface ComponentProps {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,

    data: VAPTConfigDetail[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const DetailsTable = ({
    data, isLoading, isSuccess, error,
    modal, addModal, closeModal
} : ComponentProps) => {
    const rootContext = useContext(MutationContext)
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const tableData = useAppSelector(selectTableData)

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        const result = data || []

        if (data) {
            const filteredData = smartSearch(
                result,
                tableData.filters,
                tableData.search
            ) || [] as VAPTConfigDetail[]
            const orderedData = smartOrder(filteredData, tableData.columns) as VAPTConfigDetail[]

            dispatch(setFiltered(orderedData))
        }
    }, DEBOUNCE_SEARCH_TIME)

    const paginateData = () => {
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as VAPTConfigDetail[]

        dispatch(setPaginated(result))
    }

    useEffect(() => {
        setData()
    }, [
        data,
        tableData.filters,
        tableData.search,
        tableData.columns
    ])

    useEffect(() => {
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: VAPTConfigDetail,
            property: VAPTConfigDetailKeys
        ) => {
            let cellContent: string | number = ''
            let rawValue: string | number = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default: {
                    rawValue = cellContent = dataObject[property]
                    break
                }
            }

            const zoomAction = (not: boolean) => {
                dispatch(addFilter({
                    not: not,
                    sort: property,
                    value: [String(rawValue)]
                }))
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, rowIndex) => {
                const actionButtons =
                        _.map(ACTIONS.VAPT_DETAILS, (action, cellIndex) => {
                            const cellKey = [
                                'vaptDetail-cell-' + rowIndex +
                                '-' + (1 + cellIndex + tableData.columns.length)
                            ].join('')

                            const onClick = () => {
                                let formData: ConfigFormData = {}
                                /** archive is same as delete. */
                                if (action.value === 'VAPT_REMOVE_ASSET') {
                                    formData = {
                                        vapt: {
                                            removeConfirm: dataObject
                                        }
                                    }
                                } else if (action.value === 'VAPT_UPDATE_ASSET') {
                                    formData = {
                                        vapt: {
                                            updateConfirm: dataObject
                                        }
                                    }
                                }

                                dispatch(addModal({
                                    id: uniqueString(),
                                    open: true,
                                    operation: action.value,
                                    isBorderWide: false,
                                    formData: formData
                                }))
                            }

                            return (
                                <td key={cellKey}>
                                    <Button
                                        onClick={onClick}
                                        size={'sm'}
                                        mode={action.mode}>
                                        {action.label}
                                    </Button>
                                </td>
                            )
                        })

                return (
                    <tr key={'vaptDetail-row-' + rowIndex}>
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'vaptDetail-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    >
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                        {actionButtons}
                    </tr>
                )
            })
        )

        return (
            isLoading
                ? LoadingContent
                : isSuccess
                    ? (
                        tableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(error)
        )
    }, [
        tableData, isLoading, isSuccess, error
    ])

    /** reset data on unmount */

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const arr = data || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = arr[0]
            ? _.filter(_.keys(
                flatten(arr[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : KEYS.VAPT.DETAILS

        dispatch(
            setFilterKeys(result)
        )
    }, [
        data
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as VAPTConfigDetailKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])
    return (<div>
        {/* table stuff */}
        <div className={'align-items-center justify-content-between row'}>
            <div className={'col-12 col-md px-4'}>
                <SearchRow className={'align-items-center row'}>
                    <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                        <FaSearch />
                    </label>
                    <input
                        className={'col'}
                        type={'text'}
                        id={TEXT.SEARCH.SEARCH.ID}
                        onChange={(e) => {
                            setSearchDebounce(e.target.value)
                        }}
                        placeholder={'Search'}
                    />
                    {/* when this is clicked, a popup window will show up
                        it was previously a tippy but now it's an interface below
                         to utilize space. */}
                    <Tippy
                        className={'tippy-box'}
                        arrow
                        content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                        <Text size={'xl'}
                            className={'d-inline-block col-auto pe-0'}
                            onClick={() => {
                                dispatch(
                                    toggleFilterInterface(!tableData.filterInterface.show)
                                )
                            }}
                        >
                            <MdManageSearch />
                        </Text>
                    </Tippy>
                </SearchRow>
            </div>

            {/* this is the dropdown for page count */}
            <div className={'col-12 col-md-auto'}>
                <div className={'align-items-center justify-content-between row'}>
                    <div className={'col-12 col-sm-auto mt-3 mt-lg-0'}>
                        <div className={[
                            'align-items-center',
                            'justify-content-center',
                            'row',
                            'text-center'
                        ].join(' ')}>
                            <small className={'col col-md-auto'}>
                                {TEXT.TABLE.REFRESH}
                            </small>
                            <Select
                                className={'col-auto px-0'}
                                options={INTERVALS}
                                value={_.find(
                                    INTERVALS,
                                    (e) => e.value === tableData.interval.value
                                )}
                                onChange={(e) => {
                                    if (e) {
                                        dispatch(setIntervalObj(e))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles,
                                    ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                                }}
                                theme={reactSelect.theme}
                            />
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                                <span
                                    className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                                    onClick={() => {
                                        dispatch(setRefetch(true))
                                        refreshTimer.reset()
                                    }}>
                                    <MdRefresh />
                                </span>
                            </Tippy>
                        </div>
                    </div>
                    <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                        <div className={'align-items-center justify-content-center row'}>
                            <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                            <Select
                                className={'col-auto'}
                                options={ROW_COUNT_SELECTION}
                                value={_.find(
                                    ROW_COUNT_SELECTION,
                                    (e) => e.value === tableData.count
                                )}
                                onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                    if (e) {
                                        dispatch(setCount(e.value))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles,
                                    ...FIXED_REACT_SELECT_WIDTH.SMALL
                                }}
                                theme={reactSelect.theme}
                            />
                        </div>
                    </div>
                    <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                        <Pagination

                            current={tableData.page}
                            total={tableData.filtered.length}
                            pageSize={tableData.count}
                            onChange={(current) => {
                                dispatch(setPage(current))
                            }}
                            locale={EN_US}
                        />
                    </div>

                </div>

            </div>
        </div>
        {/* row to show when kubernetes search toggles */}
        {
            tableData.filterInterface.show
                ? (
                    <div className={'mt-2'}>
                        {FilterInterface}
                    </div>
                )
                : ''
        }
        <div className={'row mt-2'}>
            {/* display columns aka a filter shere */}
            <div className={'col ps-4'}>
                <div className={'row'}>
                    {
                        _.map(tableData.filters, (filter, index) => {
                            const comparisonString = filter.value.length > 1
                                ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                : ':'
                            return (
                                <FilterCell key={'filter-' + index} className={
                                    ['col-auto me-2 px-2 mt-1',
                                        filter.not ? 'not' : ''].join(' ')
                                }>
                                    <Text size={'xs'}>
                                        {[
                                            [
                                                filter.not ? '!' : '',
                                                filter.sort
                                            ].join(''),
                                            comparisonString,
                                            filter.value.join(', ')
                                        ].join(' ')}
                                    </Text>
                                    <Text size={'xs'} className={'ps-2'} onClick={() => {
                                        dispatch(removeFilter(filter))
                                    }}>
                                        <FaTimes />
                                    </Text>
                                </FilterCell>
                            )
                        })
                    }
                </div>
            </div>
            <span
                className={[
                    'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                    tableData.filters.length ? 'd-block' : 'd-none'
                ].join(' ')}
                onClick={() => {
                    dispatch(removeFilters())
                }}
            >
                {/* clear all filters button. baseline, not center */}
                <FaTimes />
            </span>
        </div>
        <div>
            <Button
                className={'col-auto mb-2 me-2'}
                onClick={() => {
                    const formData: ConfigFormData = {
                        vapt: {
                            doScheduleConfirm: {
                                which: 'ext-vss',
                                deviceId: modal.formData.vapt
                                    ?.details?.deviceId || '',
                                confirmationMessage: CONFIG_TEXT
                                    .VAPT_DO_SCHEDULE.FORM.EXT_VSS.MESSAGE
                            }
                        }
                    }
                    // show vss confirmation.
                    dispatch(addModal({
                        id: uniqueString(),
                        open: true,
                        operation: 'VAPT_DO_SCHEDULE',
                        isBorderWide: false,
                        formData: formData
                    }))
                }}
                size={'sm'}
                mode={'primary'}>
                {CONFIG_TEXT.VAPT_DO_SCHEDULE.FORM.EXT_VSS.LABEL}
            </Button>
            <Button
                className={'col-auto mb-2 me-2'}
                onClick={() => {
                    const formData: ConfigFormData = {
                        vapt: {
                            doScheduleConfirm: {
                                which: 'ext-wss',
                                deviceId: modal.formData.vapt
                                    ?.details?.deviceId || '',
                                confirmationMessage: CONFIG_TEXT
                                    .VAPT_DO_SCHEDULE.FORM.EXT_WSS.MESSAGE
                            }
                        }
                    }
                    // show vss confirmation.
                    dispatch(addModal({
                        id: uniqueString(),
                        open: true,
                        operation: 'VAPT_DO_SCHEDULE',
                        isBorderWide: false,
                        formData: formData
                    }))
                }}
                size={'sm'}
                mode={'primary'}>
                {CONFIG_TEXT.VAPT_DO_SCHEDULE.FORM.EXT_WSS.LABEL}
            </Button>
        </div>
        <div className={'justify-content-center row mt-3'}>
            <div className={'col'}>
                <Table
                    className={'table-striped table-hover px-0'}
                    height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                    bgIndex={1}
                >
                    <table className={'table'}>
                        <thead>
                            <tr>
                                {TableHead}
                            </tr>
                        </thead>
                        <tbody>
                            {TableBody}
                        </tbody>
                    </table>
                </Table>
            </div>
        </div>

    </div>)
}

export default DetailsTable


import { useDoValidateMFAMutation } from '@apis/main/login-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT,
    MFA_FORMIK_INITIAL_VALUES,
    MFA_VALIDATION_SCHEMA
} from '@constants/main/login'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { LOGIN_ROUTES } from '@constants/main/routes'
import {
    resetMfaToken,
    selectDefaultRoute,
    selectMfaToken
} from '@slices/main/login'
import { selectMode } from '@slices/main/settings'
import { setToken } from '@slices/main/token'
import {
    FormStyledComponents as Form,
    LoginStyledComponents as Login,
    SpinnerContainer
} from '@styles/components'
import { push } from 'connected-react-router'
import { useFormik } from 'formik'
import React, {
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'

const MFAPage = () => {
    const defaultRoute = useAppSelector(selectDefaultRoute)
    const mfaToken = useAppSelector(selectMfaToken)
    const dispatch = useAppDispatch()

    const mode = useAppSelector(selectMode)
    const [doValidateMFA, doValidateMFAMutation] = useDoValidateMFAMutation()

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: MFA_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MFA_VALIDATION_SCHEMA,
        onSubmit: (values) => {
            doValidateMFA({
                authToken: mfaToken,
                token: values.mfaCode,
                app: 'watchdog'
            })
        }
    })

    const Logo = useMemo(() => {
        return (
            <Login.Logo className={'col-auto'} src={mode === 'dark'
                ? '/media/dark-theme.svg'
                : '/media/light-theme.svg'}
            alt={'Bluedog Logo'} role={'button'} />
        )
    }, [mode])

    const CodeInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={TEXT.MFA.FORM.PIN_CODE.ID}>
                    {TEXT.MFA.FORM.PIN_CODE.LABEL}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.mfaCode)}
                    name={'mfaCode'}
                    id={TEXT.MFA.FORM.PIN_CODE.ID}
                    onChange={handleChange}
                    value={values.mfaCode}
                />
                <Form.Feedback errors={Boolean(errors.mfaCode)} >{
                    errors.mfaCode ? errors.mfaCode : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.mfaCode, errors.mfaCode])

    const SubmitButton = useMemo(() => {
        const buttonContent = doValidateMFAMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{TEXT.MFA.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : TEXT.MFA.FORM.SUBMIT_BUTTON

        return (
            <Form.Group className={'text-center'}>
                <Login.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={doValidateMFAMutation.isLoading}
                >{buttonContent}</Login.Button>
            </Form.Group>
        )
    }, [doValidateMFAMutation.isLoading])

    /** when this button is clicked, go to logout page */
    const CancelButton = useMemo(() => {
        return (
            <Form.Group className={'text-center'}>
                <Login.Button
                    onClick={() => {
                        dispatch(push(LOGIN_ROUTES.LOGOUT.link))
                    }}
                    mode={'secondary'}
                    type={'button'}
                >{TEXT.MFA.FORM.CANCEL_BUTTON}</Login.Button>
            </Form.Group>
        )
    }, [])

    useEffect(() => {
        if (doValidateMFAMutation.data) {
            const data = doValidateMFAMutation.data
            if (data.success && data.message === 'Successful login!') {
                if (data.token) {
                    /**
                     * set the token from mfa validation, empty mfa
                     * token and redirect to default route.
                     * */
                    dispatch(setToken({
                        value: data.token,
                        valid: true
                    }))
                    dispatch(resetMfaToken())
                    dispatch(push(defaultRoute))
                } else {
                    console.error("Token not set because it's falsy")
                }
            } else {
                toast.error(
                    data.message,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [doValidateMFAMutation.data])

    useEffect(() => {
        if (doValidateMFAMutation.error) {
            console.error(doValidateMFAMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doValidateMFAMutation.error])

    /**
     * render an MFA interface. you need a google authenticator
     * app and you can authenticate once more. Interface is inspired
     * by login page
    */
    return (
        <div className={'container-fluid'}>
            <div className={'align-items-center flex-column h-100 justify-content-center row'}>
                <div className={'col-auto'}>
                    {Logo}
                </div>
                <Login.Container className={'mt-3'}>
                    <h3 className={'text-center fw-bold'}>
                        {TEXT.MFA.TITLE}
                    </h3>
                    <small className={'text-center d-block mt-4'}>
                        {TEXT.MFA.MESSAGES[0]}
                    </small>
                    <small className={'text-center d-block mt-2'}>
                        {TEXT.MFA.MESSAGES[1]}
                    </small>

                    <Form.Main className={'p-0'} onSubmit={handleSubmit}>
                        {CodeInput}
                        {SubmitButton}
                        {CancelButton}
                    </Form.Main>
                </Login.Container>
            </div>
        </div>
    )
}
export default MFAPage

import {
    DashboardData,
    SharepointEventColumn,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    O365DetailedSearchParams,
    SharepointUserIdColumn,
    SourceFileNameColumn
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    Doc
} from '@interfaces/dashboard/monitor'
import { CHART_ZOOM } from '@constants/dashboard/monitor'
import _ from 'lodash'
import {
    getUnixTime,
    sub
} from 'date-fns'

export const EVENT_TYPES: EventType[] = ['OneDriveDetails.Activity', 'SharePoint.Events',
    'SharePointDetails.Activity', 'SharepointClientIP.Doughnut', 'SharepointUserActivity.Locations',
    'SharepointUserId.CountedTable', 'SiteUrl.Activity', 'SourceFileExtension.Activity',
    'SourceFileExtension.Doughnut', 'SourceFileName.CountedTable']

/** was _.Dictionary<EventTypeBooleans> but used type assertion. */
export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

export const DEFAULT_CHART_ZOOMS = _.zipObject(
    EVENT_TYPES,
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_SEARCH_PARAMS: O365DetailedSearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    /** note that the refetches are now from separate event types.
     * so if you change the parameters from date ranges and search queries,
     * then everything refreshes,
    */
    refetch: _.cloneDeep(DEFAULT_REFETCH),
    q: '',

    /** for displaying filters. */
    boolList: [],
    /** scripts won't be executed if deviceid IS an empty string, */
    card: {
        details: {},
        deviceid: '',
        in_face: '',
        service_type: 'bdg-bas'
    }
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'O365 Detailed call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        ONEDRIVE_DETAILS_ACTIVITY: 'Fetching One Drive Details Activity data...',
        SHAREPOINT_EVENTS: 'Fetching Sharepoint Events data...',
        SHAREPOINT_DETAILS_ACTIVITY: 'Fetching Sharepoint Details Activity data...',
        SHAREPOINT_CLIENT_IP_DOUGHNUT: 'Fetching Sharepoint Client IP data...',
        SHAREPOINT_USER_ACTIVITY_LOCATIONS: 'Fetching Sharepoint User Locations data...',
        SHAREPOINT_USER_ID_COUNTED_TABLE: 'Fetching Sharepoint User ID data...',
        SITE_URL_ACTIVITY: 'Fetching Site Url Activity data...',
        SOURCE_FILE_EXTENSION_ACTIVITY: 'Fetching Source File Extension Activity data...',
        SOURCE_FILE_EXTENSION_DOUGHNUT: 'Fetching Source File Extension data...',
        SOURCE_FILE_NAME_COUNTED_TABLE: 'Fetching Source File Name data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        ONEDRIVE_DETAILS_ACTIVITY: 'OneDrive',
        SHAREPOINT_EVENTS: 'All Office365 Events This Period',
        SHAREPOINT_DETAILS_ACTIVITY: 'Sharepoint',
        SHAREPOINT_CLIENT_IP_DOUGHNUT: 'Client IP Address',
        SHAREPOINT_USER_ACTIVITY_LOCATIONS: 'User Activity Locations',
        SHAREPOINT_USER_ID_COUNTED_TABLE: 'User IDs',
        SITE_URL_ACTIVITY: 'Site URLs',
        SOURCE_FILE_EXTENSION_ACTIVITY: 'Source File Extensions',
        SOURCE_FILE_EXTENSION_DOUGHNUT: 'File Extensions',
        SOURCE_FILE_NAME_COUNTED_TABLE: 'Filenames'
    }
}

export const SOURCE_FILE_EXTENSION_DOUGHNUT_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Extension',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const SHAREPOINT_CLIENT_IP_DOUGHNUT_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'IP',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const SHAREPOINT_DETAILS_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Operation',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const ONEDRIVE_DETAILS_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = SHAREPOINT_DETAILS_ACTIVITY_COLUMNS

export const SITE_URL_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = [
    {
        label: 'Site',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const SOURCE_FILE_EXTENSION_ACTIVITY_COLUMNS: {
    label: string, value: keyof Doc
}[] = [
    {
        label: 'Extension',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const SHAREPOINT_USER_ID_COLUMNS: SharepointUserIdColumn[] = (() => {
    const columnNames: Omit<SharepointUserIdColumn, 'arrange' | 'include'>[] = [
        {
            label: 'User',
            value: 'key'
        },
        {
            label: 'Count',
            value: 'doc_count'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: SharepointUserIdColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const SOURCE_FILE_NAME_ID_COLUMNS: SourceFileNameColumn[] = (() => {
    const columnNames: Omit<SourceFileNameColumn, 'arrange' | 'include'>[] = [
        {
            label: 'File',
            value: 'key'
        },
        {
            label: 'Count',
            value: 'doc_count'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: SourceFileNameColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const SHAREPOINT_EVENTS_COLUMNS: SharepointEventColumn[] = (() => {
    const columnNames: Omit<SharepointEventColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'Source File Name',
            value: 'SourceFileName'
        },
        {
            label: 'User',
            value: 'UserId'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        },
        {
            label: 'Operation',
            value: 'Operation'
        },
        {
            label: 'Source Relative URL',
            value: 'SourceRelativeUrl'
        },
        {
            label: 'Workload',
            value: 'Workload'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: SharepointEventColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const DASHBOARD_DATA: DashboardData = {
    'OneDriveDetails.Activity': undefined,
    'SharePoint.Events': undefined,
    'SharePointDetails.Activity': undefined,
    'SharepointClientIP.Doughnut': undefined,
    'SharepointUserActivity.Locations': undefined,
    'SharepointUserId.CountedTable': undefined,
    'SiteUrl.Activity': undefined,
    'SourceFileExtension.Activity': undefined,
    'SourceFileExtension.Doughnut': undefined,
    'SourceFileName.CountedTable': undefined
}

export const PRINT_REPORT_TITLE = 'Microsoft 365 Detailed Dashboard'

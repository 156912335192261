
import { FILTER_ROUTES } from '@constants/main/routes'
import FilterAdd from '@features/watchdog/soc-data/filter/FilterAdd'
import FilterMenu from '@features/watchdog/soc-data/filter/FilterMenu'
import React from 'react'

const FilterAddNoProps = () => {
    return <FilterAdd />
}

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: FILTER_ROUTES.MAIN.link,
        component: FilterMenu
    },
    {
        exact: true,
        path: FILTER_ROUTES.ADD.link,
        component: FilterAddNoProps
    }
]

export default Routes

import {
    useAppSelector
} from '@app/hook'
import {
    ISSUE_CATEGORY_COLUMNS,
    MESSAGE as WSS_MESSAGE,
    TEXT as WSS_TEXT
} from '@constants/dashboard/soc/wss'
import {
    hideChartTooltip,
    showChartTooltip
} from '@constants/main/method'
import {
    CHART_HEIGHT,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    CWEData
} from '@interfaces/dashboard/soc/wss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
/** slices from ext-wss to wss copied. */
import {
    selectFixedCollapsibles
} from '@slices/dashboard/soc/wss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    PageBreakInside,
    SpinnerContainer,
    Table
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'

interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>
    chartInstance: Chart<'bar', number[], string> | undefined,
    setChartInstance: React.Dispatch<React.SetStateAction<
        Chart<'bar', number[], string> | undefined>
    >
    data: CWEData[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
    isPrint?:boolean
}

const IssueCategoriesChart = ({
    isPrint,
    chartEl, chartInstance, setChartInstance,
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** for cleanliness, sort the data by count */
        const CWEData = _.cloneDeep((data || [])).sort((a, b) => {
            return b.count - a.count
        })

        let graph: Chart<'bar', number[], string>

        const labels = CWEData.map((obj) => obj.name)
        const datasets = [
            {
                data: CWEData.map((obj) => obj.count),
                backgroundColor: stylesheet.style.buttonTypeColors.primary,
                maxBarThickness: DEFAULT_BAR_THICKNESS,
                borderWidth: 0
                // doesn't work on bar charts but will on doughnut/pie charts.
                // parsing: {
                //     key: 'count'
                // }
                // apply a minimum bar length so we can hover on the smallest values
                // when a large data value is part of the chart.
            }
        ]

        if (chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: () => {
                                    return ''
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                display: false,
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        }
                    }
                }
            })

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.height = CHART_HEIGHT.md
            chartEl.current.style.maxHeight = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            graph && graph.destroy()
        }
    }, [data])

    const DataTable = useMemo(() => {
        const CWEData = _.cloneDeep((data || [])).sort((a, b) => {
            return b.count - a.count
        })

        const cellBody = (
            dataObject: CWEData,
            property: keyof CWEData
        ) => {
            let cellContent: CWEData[keyof CWEData] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(ISSUE_CATEGORY_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'CWEData-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(CWEData, (dataObject, rowIndex) => {
                            return (
                                <tr
                                    key={'CWEData-tr-' + rowIndex}
                                    onMouseOver={() => {
                                        showChartTooltip(0, rowIndex, chartInstance)
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(chartInstance)
                                    }}
                                >
                                    {
                                        _.map(ISSUE_CATEGORY_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'CWEData-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return CWEData.length
            ? content
            : EmptyCellContent
    }, [
        data,
        chartInstance
    ])

    const DataContent = useMemo(() => {
        /** have an isPrint mode prop instead of making another copy. */

        const content = !isPrint
            ? <Container bgIndex={2} className={'mb-3'}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.issueCategories
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
            : <div>
                <PageBreakInside className={'row'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {WSS_TEXT.SECTIONS.ISSUES_CATEGORIES}
                        </span>
                        <Container bgIndex={2} className={'mb-2'}>
                            <canvas ref={chartEl}/>
                        </Container>
                    </div>
                </PageBreakInside>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.issueCategories
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssueCategoriesChart.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssueCategoriesChart

/**
 * this component is needed for theme providers for the system.
 * required values are the style and mode variables after
 * a successful authentication. this becomes a function
 * default values will always be
 * "bluedog" and "dark".
 * */

import _ from 'lodash'
import { DefaultTheme } from 'styled-components'
import Color from 'color'

import {
    DefinedStylesheets,
    DefinedThemes,
    GetThemeParams,
    ModeProps,
    StyleProps
} from '@styles/styled'

/** all styling assignments in each stylesheet should be consistent */
export const STYLES: {
    // eslint-disable-next-line no-unused-vars
    [key in DefinedStylesheets]: StyleProps
} = {
    bluedog: {
        fontSize: '16px',
        fontFamily: 'Montserrat',

        /**
         * responsive media queries. normally for browser sizes
         * but can also be applied for elements like dashboard-cards
         * */
        mediaQueries: {
            dashboardCard: '430px',
            xs: '576px',
            sm: '767px',
            md: '992px',
            lg: '1200px'
        },

        /**
         * default color presets in case of emergencies
         * although it is encouraged to assign colors to element attributes,
         * most result data from api calls explicitly assign colors.
         * for example, statcards and modal data. NOTE: presets colors
         * and element colors should be separate from each other.
         * like how you can have a color blue BUT have a primary button of pink
        */
        colorPresets: {
            blue: '#1ca9de',
            green: '#5DB540',
            yellow: '#FFEC80',
            'amber-2': '#ffc018',
            'amber-1': '#d77900',
            red: '#D13831',
            white: '#ededed',
            lightGrey: '#d7d7d7',
            grey: '#9c9c9c',
            darkGrey: '#484848',
            black: '#000000'
        },

        buttonTypeColors: {
            primary: '#1ca9de',
            secondary: '#9c9c9c',
            success: '#5DB540',
            warning: '#ffc018',
            danger: '#D13831',
            info: '#9c9c9c'
        },

        errorColor: '#D13831',

        eventType: {
            flowColor: '#c4aaff',
            fileinfoColor: '#feb1d2',
            dnsColor: '#92d9ed',
            krb5Color: '#c2a1eb',
            httpColor: '#bbde91',
            tlsColor: '#aedede',
            sshColor: '#f5ccd2',
            alertColor: '#f2df72',
            smbColor: '#90d3a3',
            dhcpColor: '#ff90d6',
            snmpColor: '#88cada',
            ikev2Color: '#cfa88a'
        },

        severityLevel: {
            none: '#9c9c9c',
            low: '#FFEC80',
            medium: '#ffc018',
            high: '#d77900',
            critical: '#D13831'
        }
    }
}

/** dont choose #000000 because lighten won't work. */
export const generateLightColors: (range: number, hexColor: string) => Color[] =
    (range, hexColor) => {
        const strengthDivider = 5; const colors: Color[] = []

        for (let i = 0; i < range; i++) {
            colors.push(
                Color(hexColor)
                    .lighten(i / (range / strengthDivider))
            )
        }

        return colors
    }

export const generateDarkColors: (range: number, hexColor: string) => Color[] =
    (range, hexColor) => {
        const strengthDivider = 6.5; const colors: Color[] = []

        for (let i = range; i >= 0; i--) {
            colors.push(
                Color(hexColor)
                    .lighten(i / (range / strengthDivider))
            )
        }

        return colors
    }

/** all styling assignments in each theme should be consistent */
export const THEMES: {
    // eslint-disable-next-line no-unused-vars
    [key in DefinedStylesheets]: {
        // eslint-disable-next-line no-unused-vars
        [key in DefinedThemes]: ModeProps
    }
} = {
    bluedog: {
        dark: {
            backgroundColors: _.map(generateLightColors(11, '#262626'), (color) => {
                return color.hex()
            }),
            fontColor: '#FFFFFF'
        },
        light: {
            backgroundColors: _.map(generateDarkColors(11, '#262626'), (color) => {
                return color.hex()
            }),
            fontColor: '#000000'
        }
    }
}

// you want to get the stylesheet first.
//
export const createStylesheet = (
    userStyle:GetThemeParams['userStyle'],
    userMode:GetThemeParams['userMode']
) : DefaultTheme => {
    /**
     * object should be the stylesheet properties. Assign the mode afterwards
     * NOTE: both strings must be truthy since this function runs twice
     * as both a setter for mode and style are individually set.
     */
    const result:{
        style: StyleProps,
        mode: ModeProps
    } = {
        style: STYLES.bluedog,
        mode: THEMES.bluedog.dark
    }

    if (userStyle && userMode) {
        switch (userStyle) {
            case 'bluedog':
                result.style = STYLES.bluedog
                break
            default:
                /***/
                console.error('THEME.tsx: no stylesheet assigned. Default is retained')
                break
        }

        switch (userMode) {
            case 'light': {
                result.mode = THEMES.bluedog.light
                break
            }
            case 'dark': {
                result.mode = THEMES.bluedog.dark
                break
            }
            /** assign light if none is found */
            default: {
                console.error('THEME.tsx: no mode assigned. Default is retained')
                break
            }
        }
    // IF NOTHING IS retrieved at the end go with default.
    // snippet was made to cater to any localStorage data concerns.
    }

    return result
}


import {
    useGetOneQuestionMutation,
    useGetQTypesMutation,
    useDoFileUploadMutation
} from '@apis/watchdog/virtual-ciso/compliance-questionnaire-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    TEXT as COMPLIANCE_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/virtual-ciso/compliance-questionnaire'
import AnswerConfirmation from
    '@features/watchdog/virtual-ciso/compliance-questionnaire/answer/AnswerConfirmation'
import {
    QuestionParams,
    AnswerQuestionKeys,
    AnswerQuestionValues,
    QuestionFormData
} from '@interfaces/watchdog/virtual-ciso/compliance-questionnaire'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    addModal,
    closeModal,
    removeModal,
    selectModals
} from '@slices/watchdog/virtual-ciso/compliance-questionnaire/question'
import {
    Button,
    FormStyledComponents as Form,
    Table,
    Text
} from '@styles/components'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

const QuestionAnswer = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect
    const dispatch = useAppDispatch()

    const fileInputRefs = useRef<HTMLInputElement[]>([])

    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    /** useState to refetch all data. */
    const [refetch, setRefetch] = useState<boolean>(false)

    const { questionID, questionnaireID, customerID } = useParams<QuestionParams>()

    /** fetch other calls. */
    const [getQTypes, getQTypesMutation] = useGetQTypesMutation()
    const [getOneQuestion, getOneQuestionMutation] = useGetOneQuestionMutation()
    const [doFileUpload, doFileUploadMutation] = useDoFileUploadMutation()

    const questionFormik = useFormik({
        initialValues: INITIAL_VALUES.ANSWER,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.ANSWER,
        onSubmit: () => {
            const question = getOneQuestionMutation.data?.questions[0]

            if (question && customerID) {
                const formData: QuestionFormData = {
                    answerConfirm: {
                        formValues: questionFormik.values,
                        customer_id: customerID,
                        questionDetail: question
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'ANSWER_QUESTION',
                    formData: formData,
                    isBorderWide: false
                }))
            }
        }
    })

    const strictSetValue = useCallback((
        label: AnswerQuestionKeys,
        value: AnswerQuestionValues[AnswerQuestionKeys]
    ) => {
        questionFormik.setFieldValue(
            label,
            value
        )
    }, [])

    /** Button to refresh the question data. */
    const RefreshButton = useMemo(() => {
        return (
            <Form.Group className={'row'}>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'primary'}
                        onClick={() => {
                            setRefetch(true)
                        }}
                    >{COMPLIANCE_TEXT.ANSWER.FORM.REFRESH_BUTTON }</Button>
                </div>
            </Form.Group>
        )
    }, [])

    /** choice selection component */
    const ChoiceSelection = useMemo(() => {
        const question = getOneQuestionMutation.data?.questions[0]
        const qTypes = getQTypesMutation.data?.qTypes || []

        let content: JSX.Element = <></>

        if (question) {
            // get qType.
            const qType = _.find(qTypes, (obj) => {
                return obj.value === question.type_id
            })

            const fieldValue: AnswerQuestionKeys = 'answers'

            if (qType?.label === 'Dropdown') {
                content = (
                    <Form.Group>
                        <Select
                            errors={Boolean(questionFormik.errors.answers)}
                            options={_.map(
                                question.answers,
                                (obj) => ({
                                    label: obj.name,
                                    value: obj.value
                                })
                            )}
                            value={_.find(
                                question.answers,
                                (e) => e.value === questionFormik.values.answers[0]
                            )}
                            onChange={(e) => {
                                if (e) {
                                    questionFormik.setFieldValue(
                                        fieldValue,
                                        e.value
                                    )
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                        <Form.Feedback errors={Boolean(questionFormik.errors.answers)} >{
                            questionFormik.errors.answers ? questionFormik.errors.answers : null
                        }</Form.Feedback>
                    </Form.Group>
                )
            } else if (qType?.label === 'Radio buttons') {
                return (
                    _.map(question.answers, (obj) => {
                        return (
                            <div>
                                <input
                                    type={'radio'}
                                    name={'answers[]'}
                                    onChange={() => {
                                        questionFormik.setFieldValue(fieldValue, [obj.value])
                                    }}
                                    checked={obj.value === questionFormik.values.answers[0]}
                                />
                                <Text size={'xs'}>{obj.name}</Text>
                            </div>

                        )
                    })
                )
            } else if (qType?.label === 'Checkboxes') {
                return (
                    _.map(question.answers, (obj) => {
                        return (<div>
                            <input
                                type={'checkbox'}
                                onChange={() => {
                                    questionFormik.setFieldValue(
                                        fieldValue,
                                        _.uniq([...questionFormik.values.answers, obj.value])
                                    )
                                }}
                                checked={obj.value === questionFormik.values.answers[0]}
                            />
                            <Text size={'xs'}>{obj.name}</Text>
                        </div>)
                    })
                )
            }
        }

        return content
    }, [
        getOneQuestionMutation.data,
        getQTypesMutation.data
    ])

    // a method to upload a file and update the fileList property in the formik.
    const uploadFile = async (fileIndex: number) => {
        // revalidate the token first.
        const newToken = await revalidateToken()

        const file = fileInputRefs.current[fileIndex]?.files?.[0] || null

        if (file) {
            doFileUpload({
                authToken: newToken,
                fileData: file
            })
        }
    }

    useEffect(() => {
        const data = doFileUploadMutation.data
        if (data?.message) {
            const fieldValue: AnswerQuestionKeys = 'proofFiles'

            questionFormik.setFieldValue(
                fieldValue,
                _.uniq([...questionFormik.values.proofFiles, data.file_id])
            )
        }
    }, [doFileUploadMutation.data])

    const unsubscribeGetQTypesList = () => {
        const unsubscribeMutation = getQTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOneQuestionList = () => {
        const unsubscribeMutation = getOneQuestion({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetQTypesList()
        unsubscribeGetOneQuestionList()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getQTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOneQuestionPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && questionID && questionnaireID) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getQTypesPromise = getQTypes({
                        authToken: newToken
                    })
                    getOneQuestionPromise = getOneQuestion({
                        authToken: newToken,
                        id: questionID,
                        questionnaire_id: questionnaireID
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getQTypesPromise && getQTypesPromise.abort()
            getOneQuestionPromise && getOneQuestionPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [token.valid, questionID, questionnaireID])

    /** separate useEffect for refetch */
    useEffect(() => {
        if (refetch) {
            return fetchData()
        }
    }, [refetch])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (getQTypesMutation.error) {
            console.error(getQTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getQTypesMutation.error])

    useEffect(() => {
        if (getOneQuestionMutation.error) {
            console.error(getOneQuestionMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOneQuestionMutation.error])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                    >{COMPLIANCE_TEXT.ANSWER.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={() => {
                            questionFormik.resetForm()
                        }}
                    >{TEXT.FORM.RESET}</Button>
                </div>

            </Form.Group>
        )
    }, undefined)

    /** render modals component. Expecting only the confirmation window. */
    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'ANSWER_QUESTION') {
                    component = <AnswerConfirmation
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    const QuestionContent = useMemo(() => {
        const question = getOneQuestionMutation.data?.questions[0]

        return (
            <div>
                <span className={'d-inline-block mb-2'}>
                    {question?.title}
                </span>
                <span className={'d-inline-block mb-2'}>
                    {question?.description}
                </span>
                <span className={'d-inline-block mb-2'}>
                    {question?.question}
                </span>
            </div>
        )
    }, [getOneQuestionMutation.data])

    const CommentInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0'}
                    htmlFor={COMPLIANCE_TEXT.ANSWER.FORM.COMMENT.ID}>
                    {COMPLIANCE_TEXT.ANSWER.FORM.COMMENT.LABEL}
                </Form.Label>
                <Form.TextArea
                    errors={Boolean(questionFormik.errors.comment)}
                    className={'col'}
                    name={'comment'}
                    id={COMPLIANCE_TEXT.ANSWER.FORM.COMMENT.ID}
                    onChange={questionFormik.handleChange}
                    onBlur={(e) => {
                        const fieldValue: AnswerQuestionKeys = 'comment'
                        strictSetValue(fieldValue, e.target.value.trim())
                    }}
                    value={questionFormik.values.comment}
                />
                <Form.Feedback
                    className={'col-auto'}
                    errors={Boolean(questionFormik.errors.comment)} >
                    {
                        questionFormik.errors.comment ? questionFormik.errors.comment : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [questionFormik.values.comment, questionFormik.errors.comment])

    const ProofFiles = useMemo(() => {
        const renderRow = (proofFile: string, index: number) => {
            const removeButton = <Button
                onClick={() => {
                    const fieldValue: AnswerQuestionKeys = 'proofFiles'
                    const arr = [...questionFormik.values.proofFiles]
                    arr.splice(index, 1)
                    questionFormik.setFieldValue(fieldValue, arr)
                }}
                size={'sm'}
                mode={'danger'}
            >
                {COMPLIANCE_TEXT.ANSWER.FORM.REMOVE_PROOF_FILE}
            </Button>

            const addButton = <div>
                <input
                    ref={(e) => {
                        if (e) {
                            fileInputRefs.current[index] = e
                        }
                    }}
                    type={'file'}
                    id={COMPLIANCE_TEXT.ANSWER.FORM.UPLOAD_PROOF_FILE.ID}
                    className={'d-none'}
                    onChange={() => uploadFile(index)}
                />
                <Button
                    size={'sm'}
                    mode={'primary'}
                >
                    <label
                        className={'mb-0'}
                        htmlFor={COMPLIANCE_TEXT.ANSWER.FORM.UPLOAD_PROOF_FILE.ID}
                    >
                        {COMPLIANCE_TEXT.ANSWER.FORM.UPLOAD_PROOF_FILE.LABEL}
                    </label>
                </Button>
            </div>

            return (
                <tr>
                    <td>
                        {COMPLIANCE_TEXT.ANSWER.FORM.PROOFFILE_ID}
                    </td>
                    <td>
                        <input
                            readOnly
                            type={'text'}
                            value={proofFile}
                        />
                    </td>
                    <td>
                        {
                            proofFile && index
                                ? removeButton
                                : addButton
                        }
                    </td>

                </tr>
            )
        }

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    <tbody>
                        <tr>
                            <td colSpan={3}></td>
                            <td>
                                {<Button
                                    onClick={() => {
                                        const fieldValue: AnswerQuestionKeys = 'proofFiles'
                                        questionFormik.setFieldValue(
                                            fieldValue,
                                            [...questionFormik.values.proofFiles, '']
                                        )
                                    }}
                                    size={'sm'}
                                    mode={'primary'}
                                >
                                    {COMPLIANCE_TEXT.ANSWER.FORM.ADD_PROOF_FILE}
                                </Button>}
                            </td>
                        </tr>
                        {
                            _.map(questionFormik.values.proofFiles, renderRow)
                        }
                    </tbody>
                </table>
            </Table>
        )
    }, [
        questionFormik.values.proofFiles
    ])

    return (
        <div>
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    {RefreshButton}
                    {QuestionContent}
                    <Form.Main onSubmit={questionFormik.handleSubmit}>
                        {ChoiceSelection}
                        {CommentInput}
                        {ProofFiles}
                        {DetailsFormButtons}
                    </Form.Main>
                </div>
            </div>
            {renderModals}
        </div>
    )
}
export default QuestionAnswer

import { O365_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import O365Main from '@features/dashboard/soc/o365/detailed-dashboard/main/O365Main'
import O365Sharepoint from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/O365Sharepoint'
import O365Exchange from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/O365Exchange'
const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: O365_DETAILED_DASHBOARD_ROUTES.MAIN.link,
        component: O365Main
    },
    {
        exact: true,
        path: O365_DETAILED_DASHBOARD_ROUTES.SHAREPOINT.link,
        component: O365Sharepoint
    },
    {
        exact: true,
        path: O365_DETAILED_DASHBOARD_ROUTES.EXCHANGE.link,
        component: O365Exchange
    }
]

export default Routes

import {
    useAppSelector
} from '@app/hook'
import {
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    ControlState
} from '@interfaces/dashboard/soc/oss'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    createStylesheet
} from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartTypeRegistry,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'

/** will also be used in OSS Details. No click events. */
interface ComponentProps {
    controlState: ControlState['state'],
    totalScore: number,
    maxScore: number,
    chartEl: React.RefObject<HTMLCanvasElement>
}

const HorizontalGauge = ({
    controlState, totalScore, maxScore, chartEl
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** this chart can display a number OR an empty string */
        let graph: Chart<keyof ChartTypeRegistry, (number | undefined)[], string>

        let score: number | undefined

        if (totalScore === 0 && maxScore === 0) {
            // score = ''
        } else if (totalScore < 0) {
            // score = ''
        } else if (totalScore !== 0 && maxScore !== 0) {
            score = (((totalScore / maxScore) || 0) * 100)
        } else if (totalScore !== 0 && maxScore === 0) {
            score = totalScore
        } else if (totalScore === 0 && maxScore !== 0) {
            score = (((totalScore / maxScore) || 0) * 100)
        }

        let barColor: string = ''

        if (controlState === 'Ignore') {
            barColor = stylesheet.style.colorPresets.lightGrey
        } else {
            if (totalScore === 0 && maxScore === 0) {
                barColor = stylesheet.style.colorPresets.lightGrey
            } else if (totalScore < 0) {
            // immediate check on negative value.
                barColor = stylesheet.style.colorPresets.lightGrey
            } else if (totalScore !== 0 && maxScore !== 0) {
                barColor = stylesheet.style.colorPresets.red
            } else if (totalScore !== 0 && maxScore === 0) {
                barColor = stylesheet.style.colorPresets.red
            } else if (totalScore === 0 && maxScore !== 0) {
                barColor = stylesheet.style.colorPresets.red
            } else {
                barColor = stylesheet.style.colorPresets.green
            }
        }
        const datasets: typeof graph.data.datasets = [
            {
                data: [score],
                datalabels: {
                    anchor: 'end',
                    backgroundColor: stylesheet.style.colorPresets.grey,
                    borderRadius: 100,
                    padding: 6
                },
                backgroundColor: stylesheet.style.colorPresets.green
            },
            {
                data: [100.01],
                backgroundColor: barColor,
                datalabels: {
                    display: false
                }
            }
        ]

        if (chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: [''],
                    datasets: datasets
                },
                plugins: [
                    ChartDataLabels
                ],
                options: {
                    indexAxis: 'y',
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y / 2,
                            bottom: DEFAULT_CHART_PADDING.y / 2
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        },
                        title: {
                            display: false
                        },
                        datalabels: {
                            color: 'white',
                            // display: true,
                            display: function (context) {
                                const value = context?.dataset?.data[context.dataIndex]

                                return _.isNumber(value) && value <= 100
                            },
                            font: {
                                weight: 'bold'
                            },
                            formatter: function (value, _context) {
                                if (totalScore === 0 && maxScore === 0) {
                                    return '  '
                                } else if (totalScore < 0) {
                                    return '  '
                                } else if (totalScore !== 0 && maxScore !== 0) {
                                    return [value.toFixed(0), '%'].join('')
                                } else if (totalScore !== 0 && maxScore === 0) {
                                    return [value ? value.toFixed(2) : 0, '%'].join('')
                                } else if (totalScore === 0 && maxScore !== 0) {
                                    return [value.toFixed(0), '%'].join('')
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                color: stylesheet.mode.fontColor,
                                drawBorder: false,
                                display: false

                            },
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                stepSize: 25,
                                callback: function (value, _index, _ticks) {
                                    return [value, '%'].join('')
                                },
                                display: false
                            },
                            beginAtZero: true,
                            max: 100,
                            min: 0
                        },
                        y: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            stacked: true
                        }
                    }
                }

            })

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.maxHeight = CHART_HEIGHT.horizontalGauge
            chartEl.current.style.height = CHART_HEIGHT.horizontalGauge
        }

        return () => {
            graph && graph.destroy()
        }
    }, [controlState, totalScore, maxScore])

    const DataContent = useMemo(() => {
        const content = <canvas className={'col-auto'} ref={chartEl}/>

        return content
    }, undefined)

    return <div>{DataContent}</div>
}

HorizontalGauge.propTypes = {
    controlState: PropTypes.string,
    totalScore: PropTypes.number,
    maxScore: PropTypes.number
}

export default HorizontalGauge


import {
    useGetOneDriveDetailsActivityMutation,
    useGetSharePointDetailsActivityMutation,
    useGetSharePointEventsMutation,
    useGetSharepointClientIPDoughnutMutation,
    useGetSharepointUserActivityLocationsMutation,
    useGetSharepointUserIdCountedTableMutation,
    useGetSiteUrlActivityMutation,
    useGetSourceFileExtensionActivityMutation,
    useGetSourceFileExtensionDoughnutMutation,
    useGetSourceFileNameCountedTableMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    CHART_ZOOM,
    MESSAGE as MONITOR_MESSAGE,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    INITIAL_SEARCH_PARAMS,
    TEXT as O365_TEXT
} from '@constants/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    getUtcRanges,
    sendUnavailableModalMessage,
    turnIntoLuceneQuery,
    getLocalRanges,
    tryParseJSON
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    GetDetailedDashboardRequest,
    O365DetailedSearchParams
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    setLocalStorage,
    setStartDate,
    setEndDate,
    setRefetch,
    setLogo,
    setQ,
    removeBool,
    removeBoolList,
    setCurrentParams,
    setChartZoomLevel,
    setChartZoom,
    setTotalRecords,
    setIsComplete,
    setScrollId,
    setMapData,
    addModal,
    closeModal,
    removeModal,
    setSourceFileExtensionDoughnutData,
    setSourceFileExtensionActivityData,
    setSharepointClientIPDoughnutData,
    setSharePointDetailsActivityData,
    setOneDriveDetailsActivityData,
    setSiteUrlActivityData,
    setSharepointUserIdCountedTableData,
    setSourceFileNameCountedTableData,
    setSharepointUserActivityLocationsData,
    setSharePointEventsData,
    toggleCollapsible,
    resetO365Detailed,
    selectSearchParams,
    selectCurrentParams,
    selectPrintOptions,
    selectSharepointEventTableData,
    selectFixedCollapsibles,
    selectChartZooms,
    selectMapData,
    selectModals,
    selectDashboardData,
    setSearchParams
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import { selectToken } from '@slices/main/token'
import {
    Button,
    CircularProgressContainer,
    CollapsibleText,
    Container,
    DetailedDashboardLinks,
    FilterCell,
    MapInfoContainer,
    SearchRow,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaFileCsv,
    FaFilePdf,
    FaInfoCircle,
    FaMinus,
    FaPlus,
    FaSearch,
    FaSearchMinus,
    FaTimes
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import Modal from 'react-responsive-modal'
import O365DataID from '@features/dashboard/soc/o365/O365DataID'
import O365Details from '@features/dashboard/soc/o365/O365Details'
import { AiOutlineClose } from 'react-icons/ai'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import { O365_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import { selectRouter } from '@slices/main/router'

import { CircularProgressbar } from 'react-circular-progressbar'

import SharepointEventsDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/SharepointEventsDataTable'
import O365DoughnutChart from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/O365DoughnutChart'
import SharepointUserActivityMap from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/SharepointUserActivityMap'
import SharepointUserIdDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/SharepointUserIdDataTable'
import SourceFileNameDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/SourceFileNameDataTable'
import { LuceneQuery } from '@interfaces/dashboard/monitor'
import O365BarChart from '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/O365BarChart'
import { Buffer } from 'buffer'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'
import flatten from 'flat'
import DoEscalate from '@features/watchdog/soc-data/event/DoEscalate'
import { EventModal } from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const O365Sharepoint = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const dispatch = useAppDispatch()
    const router = useAppSelector(selectRouter)

    const token = useAppSelector(selectToken)

    /** we will always have a url parameter for the deviceid so IF and
     * only IF the deviceid is falsy, then we will use this value.
     */
    const params = useParams<{deviceid: string }>()

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const sharepointEventTableData = useAppSelector(selectSharepointEventTableData)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const modals = useAppSelector(selectModals)
    const chartZooms = useAppSelector(selectChartZooms)
    const mapData = useAppSelector(selectMapData)
    const dashboardData = useAppSelector(selectDashboardData)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    const [
        getOneDriveDetailsActivity,
        getOneDriveDetailsActivityMutation
    ] = useGetOneDriveDetailsActivityMutation()
    const [
        getSharePointDetailsActivity,
        getSharePointDetailsActivityMutation
    ] = useGetSharePointDetailsActivityMutation()
    const [
        getSharePointEvents,
        getSharePointEventsMutation
    ] = useGetSharePointEventsMutation()
    const [
        getSharepointClientIPDoughnut,
        getSharepointClientIPDoughnutMutation
    ] = useGetSharepointClientIPDoughnutMutation()
    const [
        getSharepointUserActivityLocations,
        getSharepointUserActivityLocationsMutation
    ] = useGetSharepointUserActivityLocationsMutation()
    const [
        getSharepointUserIdCountedTable,
        getSharepointUserIdCountedTableMutation
    ] = useGetSharepointUserIdCountedTableMutation()
    const [
        getSiteUrlActivity,
        getSiteUrlActivityMutation
    ] = useGetSiteUrlActivityMutation()
    const [
        getSourceFileExtensionActivity,
        getSourceFileExtensionActivityMutation
    ] = useGetSourceFileExtensionActivityMutation()
    const [
        getSourceFileExtensionDoughnut,
        getSourceFileExtensionDoughnutMutation
    ] = useGetSourceFileExtensionDoughnutMutation()
    const [
        getSourceFileNameCountedTable,
        getSourceFileNameCountedTableMutation
    ] = useGetSourceFileNameCountedTableMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    /** removed because deviceid alone is not enough to
     * query the detailed dashboard.
     */
    // useEffect(() => {
    //     // check if deviceid in searchParams is falsy.
    //     if (!(searchParams.card.deviceid) && params.deviceid) {
    //         dispatch(setDeviceId(params.deviceid))
    //     }
    // }, [params])

    useEffect(() => {
        /** we retrieve the localStorage key */
        const userJson = localStorage.getItem('o365-db-sharepoint-search-params')

        if (userJson !== null) {
            const result = tryParseJSON(userJson) as unknown as
             Omit<O365DetailedSearchParams, 'refetch'>[]

            /** check if the variable is an array. */
            if (_.isArray(result)) {
                /** then we find the object using the deviceid as our conditional check */
                const found = _.find(result, (obj) => {
                    return obj.card.deviceid === params.deviceid
                })

                if (found) {
                    /**
                     * once found, we want to check if the object has those keys.
                     * without this check, and because those properties are set
                     * to required, render crashes will occur.
                     *
                     * Update at October 7, 2022: Nested object properties
                     * are required to meet strict conditions.
                     */

                    const requiredKeys = _.keys(
                        flatten(
                            // exclude refetch property from checking.
                            _.omit(INITIAL_SEARCH_PARAMS, ['refetch', 'boolList'])
                        )
                    )

                    if (_.every(
                        requiredKeys,
                        _.partial(
                            _.has,
                            flatten(found, { maxDepth: 2 })
                        ))
                    ) {
                        dispatch(setSearchParams({
                            ...found,
                            refetch: INITIAL_SEARCH_PARAMS.refetch
                        }))
                    } else {
                        // display warning message that the found
                        // object is missing some properties that
                        // the component might crash.
                        toast.warning(
                            MONITOR_MESSAGE.MISSING_KEYS_ON_CARD,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                } else {
                    // otherwise, display error message that none was found
                    // and should go select a dashboard card from monitors.
                    toast.error(
                        MONITOR_MESSAGE.MISSING_CARD,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                }
            } else {
                toast.error(
                    MONITOR_MESSAGE.INCORRECT_DETAILED_DASHBOARD_DATA,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [params])

    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getOneDriveDetailsActivityMutation.error) {
            console.error(getOneDriveDetailsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'OneDriveDetails.Activity',
                value: false
            }))
        }
    }, [getOneDriveDetailsActivityMutation.error])

    useEffect(() => {
        if (getSharePointDetailsActivityMutation.error) {
            console.error(getSharePointDetailsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SharePointDetails.Activity',
                value: false
            }))
        }
    }, [getSharePointDetailsActivityMutation.error])

    useEffect(() => {
        if (getSharepointClientIPDoughnutMutation.error) {
            console.error(getSharepointClientIPDoughnutMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SharepointClientIP.Doughnut',
                value: false
            }))
        }
    }, [getSharepointClientIPDoughnutMutation.error])

    useEffect(() => {
        if (getSharepointUserActivityLocationsMutation.error) {
            console.error(getSharepointUserActivityLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SharepointUserActivity.Locations',
                value: false
            }))
        }
    }, [getSharepointUserActivityLocationsMutation.error])

    useEffect(() => {
        if (getSiteUrlActivityMutation.error) {
            console.error(getSiteUrlActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SiteUrl.Activity',
                value: false
            }))
        }
    }, [getSiteUrlActivityMutation.error])

    useEffect(() => {
        if (getSourceFileExtensionActivityMutation.error) {
            console.error(getSourceFileExtensionActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SourceFileExtension.Activity',
                value: false
            }))
        }
    }, [getSourceFileExtensionActivityMutation.error])

    useEffect(() => {
        if (getSourceFileExtensionDoughnutMutation.error) {
            console.error(getSourceFileExtensionDoughnutMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SourceFileExtension.Doughnut',
                value: false
            }))
        }
    }, [getSourceFileExtensionDoughnutMutation.error])

    useEffect(() => {
        if (getSourceFileNameCountedTableMutation.error) {
            console.error(getSourceFileNameCountedTableMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'SourceFileName.CountedTable',
                value: false
            }))
        }
    }, [getSourceFileNameCountedTableMutation.error])
    /** sharepoint events error throw is at the back. */

    /** useEffects to set the data. */

    /** a useEffect for searchParams to update the localStorage item. Changed dependency
     * to currentParams so when the page refreshes, you are using parameters
     * from the latest search instance.
     */
    useEffect(() => {
        /** BUG FOUND at October 11, 2021: default search params
         * on mount is also being added to localStorage. kindly
         * avoid this by checking if service type and inface
         * is lengthy.
         */

        if (currentParams.card.deviceid && currentParams.card.details.title) {
            const oldRanges = getLocalRanges({
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            })

            dispatch(setLocalStorage({
                ...currentParams,
                ranges: oldRanges
            }))
        }
    }, [currentParams])

    /** can also be from a modal card. */
    /** these are now separate fetchData scripts per client's requests. */
    const createRequestData: (newToken: string) => Omit<GetDetailedDashboardRequest, 'event_type'>
    & TokenAuth = (newToken) => {
        const newRanges = getUtcRanges(searchParams.ranges)

        const should: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => obj.not),
            turnIntoLuceneQuery)
        /**
         * Issue with search params where you update the ranges and q. It
         * will mess up the form data for fetching either o365 details or data id
         * when selecting the first OR last dataset
         * in the charts OR the tables below them.
         *
         * This can also confuse users who changed the searchParams ranges but
         * didn't execute fetch api calls. To fix this, set a dispatch where
         * you are setting the current range.
         */

        return {
            authToken: newToken,
            deviceid: searchParams.card.deviceid,
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: Buffer
                .from(searchParams.q)
                .toString('base64'),
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        const newRanges = getUtcRanges(searchParams.ranges)

        dispatch(setCurrentParams({
            ranges: newRanges,
            q: searchParams.q,
            card: searchParams.card,
            boolList: searchParams.boolList
        }))
    }

    /** i'm glad we check for searchParams.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetOneDriveDetailsActivity = () => {
        const unsubscribeMutation = getOneDriveDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setOneDriveDetailsActivityData(undefined))
    }

    const unsubscribeGetSharePointDetailsActivity = () => {
        const unsubscribeMutation = getSharePointDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharePointDetailsActivityData(undefined))
    }

    const unsubscribeGetSharePointEvents = () => {
        const unsubscribeMutation = getSharePointEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharePointEventsData(undefined))
    }

    const unsubscribeGetSharepointClientIPDoughnut = () => {
        const unsubscribeMutation = getSharepointClientIPDoughnut({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointClientIPDoughnutData(undefined))
    }

    const unsubscribeGetSharepointUserActivityLocations = () => {
        const unsubscribeMutation = getSharepointUserActivityLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointUserActivityLocationsData(undefined))
    }

    const unsubscribeGetSharepointUserIdCountedTable = () => {
        const unsubscribeMutation = getSharepointUserIdCountedTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointUserIdCountedTableData(undefined))
    }

    const unsubscribeGetSiteUrlActivity = () => {
        const unsubscribeMutation = getSiteUrlActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSiteUrlActivityData(undefined))
    }

    const unsubscribeGetSourceFileExtensionActivity = () => {
        const unsubscribeMutation = getSourceFileExtensionActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSourceFileExtensionActivityData(undefined))
    }

    const unsubscribeGetSourceFileExtensionDoughnut = () => {
        const unsubscribeMutation = getSourceFileExtensionDoughnut({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSourceFileExtensionDoughnutData(undefined))
    }

    const unsubscribeGetSourceFileNameCountedTable = () => {
        const unsubscribeMutation = getSourceFileNameCountedTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSourceFileNameCountedTableData(undefined))
    }

    const fetchOneDriveDetailsActivityData = () => {
        unsubscribeGetOneDriveDetailsActivity()
        let getOneDriveDetailsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getOneDriveDetailsActivityPromise = getOneDriveDetailsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOneDriveDetailsActivityPromise && getOneDriveDetailsActivityPromise.abort()
        }
    }

    const fetchSharePointDetailsActivityData = () => {
        unsubscribeGetSharePointDetailsActivity()
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        const unsubscribeMutation = getSharePointDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()

        let getSharePointDetailsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharePointDetailsActivityPromise = getSharePointDetailsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharePointDetailsActivityPromise && getSharePointDetailsActivityPromise.abort()
        }
    }

    const fetchSharePointEventsData = () => {
        unsubscribeGetSharePointEvents()
        let getSharePointEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharePointEventsPromise = getSharePointEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharePointEventsPromise && getSharePointEventsPromise.abort()
        }
    }

    const fetchSharepointClientIPDoughnutData = () => {
        unsubscribeGetSharepointClientIPDoughnut()
        let getSharepointClientIPDoughnutPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointClientIPDoughnutPromise = getSharepointClientIPDoughnut(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointClientIPDoughnutPromise && getSharepointClientIPDoughnutPromise.abort()
        }
    }

    const fetchSharepointUserActivityLocationsData = () => {
        unsubscribeGetSharepointUserActivityLocations()
        let getSharepointUserActivityLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointUserActivityLocationsPromise =
                    getSharepointUserActivityLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointUserActivityLocationsPromise &&
            getSharepointUserActivityLocationsPromise.abort()
        }
    }

    const fetchSharepointUserIdCountedTableData = () => {
        unsubscribeGetSharepointUserIdCountedTable()
        let getSharepointUserIdCountedTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointUserIdCountedTablePromise =
                    getSharepointUserIdCountedTable(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointUserIdCountedTablePromise && getSharepointUserIdCountedTablePromise.abort()
        }
    }

    const fetchSiteUrlActivityData = () => {
        unsubscribeGetSiteUrlActivity()
        let getSiteUrlActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSiteUrlActivityPromise = getSiteUrlActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSiteUrlActivityPromise && getSiteUrlActivityPromise.abort()
        }
    }

    const fetchSourceFileExtensionActivityData = () => {
        unsubscribeGetSourceFileExtensionActivity()
        let getSourceFileExtensionActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSourceFileExtensionActivityPromise = getSourceFileExtensionActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSourceFileExtensionActivityPromise && getSourceFileExtensionActivityPromise.abort()
        }
    }

    const fetchSourceFileExtensionDoughnutData = () => {
        unsubscribeGetSourceFileExtensionDoughnut()
        let getSourceFileExtensionDoughnutPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSourceFileExtensionDoughnutPromise = getSourceFileExtensionDoughnut(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSourceFileExtensionDoughnutPromise && getSourceFileExtensionDoughnutPromise.abort()
        }
    }

    const fetchSourceFileNameCountedTableData = () => {
        unsubscribeGetSourceFileNameCountedTable()
        let getSourceFileNameCountedTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSourceFileNameCountedTablePromise = getSourceFileNameCountedTable(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSourceFileNameCountedTablePromise && getSourceFileNameCountedTablePromise.abort()
        }
    }

    /** on token revalidation and if the searchParams.card dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */

    useEffect(() => {
        if (!dashboardData['OneDriveDetails.Activity']) {
            return fetchOneDriveDetailsActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SharePoint.Events']) {
            return fetchSharePointEventsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SharePointDetails.Activity']) {
            return fetchSharePointDetailsActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SharepointClientIP.Doughnut']) {
            return fetchSharepointClientIPDoughnutData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SharepointUserActivity.Locations']) {
            return fetchSharepointUserActivityLocationsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SharepointUserId.CountedTable']) {
            return fetchSharepointUserIdCountedTableData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SiteUrl.Activity']) {
            return fetchSiteUrlActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SourceFileExtension.Activity']) {
            return fetchSourceFileExtensionActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SourceFileExtension.Doughnut']) {
            return fetchSourceFileExtensionDoughnutData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['SourceFileName.CountedTable']) {
            return fetchSourceFileNameCountedTableData()
        }
    }, [token.valid, searchParams.card])

    /**
     * when component is mounted, do not execute calls if there is already
     * dashboard data.
     *
     * detailed dashboard soc has slowdowns when navigating AND refreshing.
     * fix is to empty the data when refetch is true.
     */

    useEffect(() => {
        if (searchParams.refetch['OneDriveDetails.Activity']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'OneDriveDetails.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchOneDriveDetailsActivityData()
        }
    }, [searchParams.refetch['OneDriveDetails.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getOneDriveDetailsActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['OneDriveDetails.Activity']) {
                dispatch(setRefetch({
                    key: 'OneDriveDetails.Activity',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setOneDriveDetailsActivityData(
                getOneDriveDetailsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getOneDriveDetailsActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SharePoint.Events']) {
            dispatch(setChartZoom({
                key: 'SharePoint.Events',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSharePointEventsData()
        }
    }, [searchParams.refetch['SharePoint.Events']])

    useEffect(() => {
        let isMounted = true
        if (getSharePointEventsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SharePoint.Events']) {
                dispatch(setRefetch({
                    key: 'SharePoint.Events',
                    value: false
                }))
            }
            dispatch(setSharePointEventsData(
                getSharePointEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharePointEventsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SharepointUserActivity.Locations']) {
            /** don't forget to empty the mapData. */
            dispatch(setTotalRecords(0))
            dispatch(setIsComplete(false))
            dispatch(setScrollId(''))
            dispatch(setMapData([]))
            return fetchSharepointUserActivityLocationsData()
        }
    }, [searchParams.refetch['SharepointUserActivity.Locations']])

    useEffect(() => {
        let isMounted = true
        if (getSharepointUserActivityLocationsMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SharepointUserActivity.Locations']) {
                dispatch(setRefetch({
                    key: 'SharepointUserActivity.Locations',
                    value: false
                }))
            }
            dispatch(setSharepointUserActivityLocationsData(
                getSharepointUserActivityLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointUserActivityLocationsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SharepointUserId.CountedTable']) {
            dispatch(setChartZoom({
                key: 'SharepointUserId.CountedTable',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSharepointUserIdCountedTableData()
        }
    }, [searchParams.refetch['SharepointUserId.CountedTable']])

    useEffect(() => {
        let isMounted = true
        if (getSharepointUserIdCountedTableMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SharepointUserId.CountedTable']) {
                dispatch(setRefetch({
                    key: 'SharepointUserId.CountedTable',
                    value: false
                }))
            }
            dispatch(setSharepointUserIdCountedTableData(
                getSharepointUserIdCountedTableMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointUserIdCountedTableMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SiteUrl.Activity']) {
            dispatch(setChartZoom({
                key: 'SiteUrl.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSiteUrlActivityData()
        }
    }, [searchParams.refetch['SiteUrl.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getSiteUrlActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SiteUrl.Activity']) {
                dispatch(setRefetch({
                    key: 'SiteUrl.Activity',
                    value: false
                }))
            }
            dispatch(setSiteUrlActivityData(
                getSiteUrlActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSiteUrlActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SourceFileExtension.Activity']) {
            dispatch(setChartZoom({
                key: 'SourceFileExtension.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSourceFileExtensionActivityData()
        }
    }, [searchParams.refetch['SourceFileExtension.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getSourceFileExtensionActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SourceFileExtension.Activity']) {
                dispatch(setRefetch({
                    key: 'SourceFileExtension.Activity',
                    value: false
                }))
            }
            dispatch(setSourceFileExtensionActivityData(
                getSourceFileExtensionActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSourceFileExtensionActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SourceFileExtension.Doughnut']) {
            dispatch(setChartZoom({
                key: 'SourceFileExtension.Doughnut',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSourceFileExtensionDoughnutData()
        }
    }, [searchParams.refetch['SourceFileExtension.Doughnut']])

    useEffect(() => {
        let isMounted = true
        if (getSourceFileExtensionDoughnutMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SourceFileExtension.Doughnut']) {
                dispatch(setRefetch({
                    key: 'SourceFileExtension.Doughnut',
                    value: false
                }))
            }
            dispatch(setSourceFileExtensionDoughnutData(
                getSourceFileExtensionDoughnutMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSourceFileExtensionDoughnutMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SourceFileName.CountedTable']) {
            dispatch(setChartZoom({
                key: 'SourceFileName.CountedTable',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSourceFileNameCountedTableData()
        }
    }, [searchParams.refetch['SourceFileName.CountedTable']])

    useEffect(() => {
        let isMounted = true
        if (getSourceFileNameCountedTableMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SourceFileName.CountedTable']) {
                dispatch(setRefetch({
                    key: 'SourceFileName.CountedTable',
                    value: false
                }))
            }
            dispatch(setSourceFileNameCountedTableData(
                getSourceFileNameCountedTableMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSourceFileNameCountedTableMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SharePointDetails.Activity']) {
            dispatch(setChartZoom({
                key: 'SharePointDetails.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSharePointDetailsActivityData()
        }
    }, [searchParams.refetch['SharePointDetails.Activity']])

    useEffect(() => {
        let isMounted = true
        if (getSharePointDetailsActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SharePointDetails.Activity']) {
                dispatch(setRefetch({
                    key: 'SharePointDetails.Activity',
                    value: false
                }))
            }
            dispatch(setSharePointDetailsActivityData(
                getSharePointDetailsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharePointDetailsActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch['SharepointClientIP.Doughnut']) {
            dispatch(setChartZoom({
                key: 'SharepointClientIP.Doughnut',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchSharepointClientIPDoughnutData()
        }
    }, [searchParams.refetch['SharepointClientIP.Doughnut']])

    useEffect(() => {
        let isMounted = true
        if (getSharepointClientIPDoughnutMutation.isSuccess && isMounted) {
            if (searchParams.refetch['SharepointClientIP.Doughnut']) {
                dispatch(setRefetch({
                    key: 'SharepointClientIP.Doughnut',
                    value: false
                }))
            }
            dispatch(setSharepointClientIPDoughnutData(
                getSharepointClientIPDoughnutMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointClientIPDoughnutMutation.isSuccess])
    /** if searchParams change, set all refetch values to true. */

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    /** refresh button where all fetchData scripts are executed
     * add a tippy warning just incase.
     */
    const RefreshWarningContent = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            dispatch(setRefetch({
                key: 'OneDriveDetails.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SharePoint.Events',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SharePointDetails.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SharepointClientIP.Doughnut',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SharepointUserActivity.Locations',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SharepointUserId.CountedTable',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SiteUrl.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SourceFileExtension.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SourceFileExtension.Doughnut',
                value: true
            }))
            dispatch(setRefetch({
                key: 'SourceFileName.CountedTable',
                value: true
            }))
        }

        return <div>
            <div className={'row'}>
                <small className={'col'}>
                    {MONITOR_MESSAGE.DETAILED_DASHBOARD_REFRESH_ALL}
                </small>
            </div>
            <div className={'row flex-row-reverse mt-1'}>
                <div className={'col-auto'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        onClick={confirmClickEvent}
                    >{TEXT.YES}</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        mode={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            toggleConfirm(false)
                        }}
                    >{TEXT.NO}</Button>
                </div>
            </div>
        </div>
    }, [showConfirm])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getOneDriveDetailsActivityMutation.isLoading ||
            getSharePointDetailsActivityMutation.isLoading ||
            getSharePointEventsMutation.isLoading ||
            getSharepointClientIPDoughnutMutation.isLoading ||
            getSharepointUserActivityLocationsMutation.isLoading ||
            getSharepointUserIdCountedTableMutation.isLoading ||
            getSiteUrlActivityMutation.isLoading ||
            getSourceFileExtensionActivityMutation.isLoading ||
            getSourceFileExtensionDoughnutMutation.isLoading ||
            getSourceFileNameCountedTableMutation.isLoading

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={RefreshWarningContent}
                onClickOutside={() => toggleConfirm(false)}
            >
                <span
                    className={[
                        'icon mb-2 d-inline-block ms-2',
                        disableRefresh ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }} >
                    <MdRefresh />
                </span>
            </Tippy>
        )
    }, [
        showConfirm,
        RefreshWarningContent,
        getOneDriveDetailsActivityMutation,
        getSharePointDetailsActivityMutation,
        getSharePointEventsMutation,
        getSharepointClientIPDoughnutMutation,
        getSharepointUserActivityLocationsMutation,
        getSharepointUserIdCountedTableMutation,
        getSiteUrlActivityMutation,
        getSourceFileExtensionActivityMutation,
        getSourceFileExtensionDoughnutMutation,
        getSourceFileNameCountedTableMutation
    ])

    /** csv exports detailed dashboard event data. */
    const CsvExportButton = useMemo(() => {
        const eventsData = sharepointEventTableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            currentParams.card.service_type,
            '_', currentParams.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = eventsData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(sharepointEventTableData.columns, (obj) => obj.value)}
                filename={filename}
                onClick={() => {
                    if (disableExport) return false
                }}
                data={eventsData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        sharepointEventTableData.columns,
        sharepointEventTableData.filtered,
        currentParams.card
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={O365_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {O365_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        /** there will be some reminders for printing reports
         * with the filter and search logic specific to detailed dashboards.
         * They will be defined in the monitor constants file.
         */

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getSharePointEventsMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <Text size={'sm'}>
                {MONITOR_TEXT.DETAILED_DASHBOARD.PRINT_REMINDERS}
            </Text>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */
                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: searchParams.card.deviceid,
                                        inFace: searchParams.card.in_face,
                                        serviceType: searchParams.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end,
                                        filters: currentParams.boolList,
                                        search: currentParams.q,
                                        detailedDashboard: 'sharepoint'
                                    }
                                },
                                /** detailed dashboard isn't a modal,
                                 * so we don't need it.
                                 *
                                 * To fill in the following properties for printOptions:
                                 * - detailedDashboard is provided at constants file of
                                 * this module.
                                 * - mustNot and should is already provided via
                                 *  useEffect with boolList.
                                 */
                                printOptions: printOptions
                            },
                            count: (getSharePointEventsMutation.data?.data || []).length
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        searchParams,
        getSharePointEventsMutation,
        currentParams,
        queueId,
        queues,
        printOptions
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update o365. */}
                <div className={'d-inline-block'}>{RefreshButton}</div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    useEffect(() => {
        return () => {
            dispatch(resetO365Detailed())
        }
    }, [])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
         * return modal with variable as a child.
         */
                let component: ReactElement<any, any> = <></>
                const message = sendUnavailableModalMessage(
                    modal.card.service_type,
                    modal.operation
                )
                if (modal.card.service_type === 'bdg-o365') {
                    if (modal.operation === 'DETAILS') {
                        component = <O365Details
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'DATA_ID') {
                        component = <O365DataID
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'EVENT_ESCALATE') {
                        // change modal values.
                        const updatedModal: EventModal = {
                            eventObj: modal.serviceTypeFormData?.o365?.eventEscalate?.eventObj,
                            id: modal.id,
                            open: modal.open,
                            operation: modal.operation,
                            isBorderWide: modal.isBorderWide
                        }

                        component = <DoEscalate
                            modal={updatedModal}
                            addModal={addModal as ActionCreatorWithPayload<EventModal, string>}
                            closeModal={closeModal as ActionCreatorWithPayload<EventModal, string>}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        return ''
                    }
                } else {
                    return ''
                }
                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['md',
                            modal.card.details.colorType || 'darkGrey',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    /** display statcard information at the right. */
    const CardInfo = useMemo(() => {
        return <div className={'text-nowrap'}>
            <div className={'row'}>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.DEVICE_ID}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.deviceid}</Text>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.IN_FACE}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.in_face}</Text>
            </div>
            <div className={'row'}>
                <Text size={'xs'} className={'col-auto'}>{
                    MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.LOCATION}
                </Text>
                <Text size={'xs'} className={'col'}>
                    {currentParams.card.details.location || ''}
                </Text>
            </div>
        </div>
    }, [currentParams.card])

    /* apply other components here */
    const OneDriveDetailsActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.ONEDRIVE_DETAILS_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'OneDriveDetails.Activity',
                        value: !fixedCollapsibles['OneDriveDetails.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['OneDriveDetails.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['OneDriveDetails.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'OneDriveDetails.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['OneDriveDetails.Activity'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['OneDriveDetails.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'OneDriveDetails.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'OneDriveDetails.Activity'}
                isLoading={getOneDriveDetailsActivityMutation.isLoading}
                isSuccess={getOneDriveDetailsActivityMutation.isSuccess}
                error={getOneDriveDetailsActivityMutation.error}
            />
        </div>
    }, undefined)

    const SharePointDetailsActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SHAREPOINT_DETAILS_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'SharePointDetails.Activity',
                        value: !fixedCollapsibles['SharePointDetails.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['SharePointDetails.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['SharePointDetails.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SharePointDetails.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['SharePointDetails.Activity'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['SharePointDetails.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'SharePointDetails.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'SharePointDetails.Activity'}
                isLoading={getSharePointDetailsActivityMutation.isLoading}
                isSuccess={getSharePointDetailsActivityMutation.isSuccess}
                error={getSharePointDetailsActivityMutation.error}
            />
        </div>
    }, undefined)

    const SharepointEventsTable = useMemo(() => {
        return (<div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SHAREPOINT_EVENTS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['SharePoint.Events'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SharePoint.Events',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <SharepointEventsDataTable
                addModal={addModal}
                closeModal={closeModal}
                isLoading={getSharePointEventsMutation.isLoading}
                isSuccess={getSharePointEventsMutation.isSuccess}
                error={getSharePointEventsMutation.error}
            />
        </div>)
    }, undefined)

    const SharepointClientIPDoughnut = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SHAREPOINT_CLIENT_IP_DOUGHNUT}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'SharepointClientIP.Doughnut',
                        value: !fixedCollapsibles['SharepointClientIP.Doughnut']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['SharepointClientIP.Doughnut'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['SharepointClientIP.Doughnut'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SharepointClientIP.Doughnut',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['SharepointClientIP.Doughnut'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['SharepointClientIP.Doughnut'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'SharepointClientIP.Doughnut',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365DoughnutChart
                eventType={'SharepointClientIP.Doughnut'}
                isLoading={getSharepointClientIPDoughnutMutation.isLoading}
                isSuccess={getSharepointClientIPDoughnutMutation.isSuccess}
                error={getSharepointClientIPDoughnutMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (dashboardData['SharepointUserActivity.Locations']) {
            dispatch(setTotalRecords(
                dashboardData['SharepointUserActivity.Locations'].totalRecords
            ))
            dispatch(setIsComplete(
                dashboardData['SharepointUserActivity.Locations'].isComplete
            ))
            dispatch(setScrollId(
                dashboardData['SharepointUserActivity.Locations'].scrollId
            ))
            dispatch(setMapData(
                dashboardData['SharepointUserActivity.Locations'].data
            ))
        }
    }, [dashboardData['SharepointUserActivity.Locations']])

    const ProgressBar = useMemo(() => {
        const value = mapData.data.length
        const maxValue = mapData.totalRecords || 1

        // console.log(value)
        // console.log(maxValue)
        // console.log([(value / (maxValue) * 100).toFixed(1), '%'].join(''))

        return mapData.isComplete
            ? <MapInfoContainer>
                <FaInfoCircle />
            </MapInfoContainer>
            : <CircularProgressContainer>{
                <CircularProgressbar
                    background={true}
                    strokeWidth={4}
                    value={value}
                    maxValue={maxValue}
                    text={[(value / (maxValue) * 100).toFixed(1), '%'].join('')}
                />}
            </CircularProgressContainer>
    }, [mapData])

    const SharepointLocations = useMemo(() => {
        const value = mapData.data.length
        const maxValue = mapData.totalRecords || 1

        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SHAREPOINT_USER_ACTIVITY_LOCATIONS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['SharepointUserActivity.Locations']
                        ? 'disabled'
                        : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SharepointUserActivity.Locations',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>

            {/* data empty message adjusted at 9/22/2022. display
            no geoip message if empty. Don't waste space */}

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{[
                                value, 'of', maxValue
                            ].join(' ')}</div>}>
                            {ProgressBar}
                        </Tippy>
                        <div className={'col'}>
                            <SharepointUserActivityMap/>
                        </div>
                    </div>

                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </div>
    }, undefined)

    const SharepointUserIdTable = useMemo(() => {
        return (<div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SHAREPOINT_USER_ID_COUNTED_TABLE}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['SharepointUserId.CountedTable'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SharepointUserId.CountedTable',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <SharepointUserIdDataTable
                addModal={addModal}
                closeModal={closeModal}
                isLoading={getSharepointUserIdCountedTableMutation.isLoading}
                isSuccess={getSharepointUserIdCountedTableMutation.isSuccess}
                error={getSharepointUserIdCountedTableMutation.error}
            />
        </div>)
    }, undefined)

    const SiteUrlActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SITE_URL_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'SiteUrl.Activity',
                        value: !fixedCollapsibles['SiteUrl.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['SiteUrl.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['SiteUrl.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SiteUrl.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['SiteUrl.Activity'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['SiteUrl.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'SiteUrl.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'SiteUrl.Activity'}
                isLoading={getSiteUrlActivityMutation.isLoading}
                isSuccess={getSiteUrlActivityMutation.isSuccess}
                error={getSiteUrlActivityMutation.error}
            />
        </div>
    }, undefined)

    const SourceFileExtensionActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SOURCE_FILE_EXTENSION_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'SourceFileExtension.Activity',
                        value: !fixedCollapsibles['SourceFileExtension.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['SourceFileExtension.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['SourceFileExtension.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SourceFileExtension.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['SourceFileExtension.Activity'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['SourceFileExtension.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'SourceFileExtension.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'SourceFileExtension.Activity'}
                isLoading={getSourceFileExtensionActivityMutation.isLoading}
                isSuccess={getSourceFileExtensionActivityMutation.isSuccess}
                error={getSourceFileExtensionActivityMutation.error}
            />
        </div>
    }, undefined)

    const SourceFileExtensionDoughnut = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SOURCE_FILE_EXTENSION_DOUGHNUT}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'SourceFileExtension.Doughnut',
                        value: !fixedCollapsibles['SourceFileExtension.Doughnut']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['SourceFileExtension.Doughnut'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['SourceFileExtension.Doughnut'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SourceFileExtension.Doughnut',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['SourceFileExtension.Doughnut'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['SourceFileExtension.Doughnut'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'SourceFileExtension.Doughnut',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365DoughnutChart
                eventType={'SourceFileExtension.Doughnut'}
                isLoading={getSourceFileExtensionDoughnutMutation.isLoading}
                isSuccess={getSourceFileExtensionDoughnutMutation.isSuccess}
                error={getSourceFileExtensionDoughnutMutation.error}
            />
        </div>
    }, undefined)

    const SourceFileNameCountedTable = useMemo(() => {
        return (<div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.SOURCE_FILE_NAME_COUNTED_TABLE}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['SourceFileName.CountedTable'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'SourceFileName.CountedTable',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <SourceFileNameDataTable
                addModal={addModal}
                closeModal={closeModal}
                isLoading={getSourceFileNameCountedTableMutation.isLoading}
                isSuccess={getSourceFileNameCountedTableMutation.isSuccess}
                error={getSourceFileNameCountedTableMutation.error}
            />
        </div>

        )
    }, undefined)

    const DashboardLinks = useMemo(() => {
        const links = _.map(_.toPairs(O365_DETAILED_DASHBOARD_ROUTES),
            ([key, link], index) => {
                const chunks = _.split(link.link, ':deviceid')
                const newRoute = [
                    chunks[0],
                    currentParams.card.deviceid,
                    chunks[1]
                ].join('')

                const match = newRoute === router.location.pathname
                return (
                    <li
                        key={['detailedDashboardTabs-', index].join('')}
                        className={[match ? 'selected' : ''].join(' ')}
                        onClick={() => {
                            !match && dispatch(push(newRoute))
                        }}
                    >
                        {key}
                    </li>
                )
            }
        )
        return (
            <DetailedDashboardLinks>
                <ul>
                    {links}
                </ul>
            </DetailedDashboardLinks>
        )
    }, [router.location.pathname, currentParams.card])

    return <div>
        <div className={'align-items-center justify-content-between mb-3 mt-3 row'}>
            <div className={'col-auto'}>
                {DashboardLinks}
            </div>
            <div className={'col-auto'}>
                {CardInfo}
            </div>
        </div>
        {/* we want to see the search parameters we edit before even doing the calls.
        the important thing is that we have the latest instance of the search parameters
        AFTER the latest api call */}
        <div className={'row'}>
            <div className={'col-12 col-md px-4'}>
                <SearchRow className={'align-items-center row mb-2'}>
                    <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                        <FaSearch />
                    </label>
                    <input
                        className={'col'}
                        type={'text'}
                        defaultValue={searchParams.q}
                        id={TEXT.SEARCH.SEARCH.ID}
                        onChange={(e) => {
                            dispatch(setQ(e.target.value))
                        }}
                        /** if enter is pressed, render refresh warning */
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                // always set to true because you are pressing enter on the input
                                toggleConfirm(true)
                            }
                        }}
                        placeholder={'Search'}
                    />
                </SearchRow>
            </div>
            <div className={'col-auto pe-5 pe-lg-2'}>
                {ActionButtons}
            </div>
        </div>
        {/* filter interface */}
        <div className={'row mb-2'}>
            {/* display columns aka a filter shere */}
            <div className={'col ps-4'}>
                <div className={'row'}>
                    {
                        _.map(searchParams.boolList, (bool, index) => {
                            return (
                                <FilterCell key={'filter-' + index} className={
                                    ['col-auto me-2 px-2 mt-1',
                                        bool.not ? 'not' : ''].join(' ')
                                }>
                                    <Text size={'xs'}>
                                        {[
                                            bool.not ? '!' : '',
                                            bool.sort,
                                            ': ',
                                            bool.value
                                        ]
                                            .join('')}
                                    </Text>
                                    <Text size={'xs'} className={'ps-2'} onClick={() => {
                                        dispatch(removeBool(bool))
                                    }}>
                                        <FaTimes />
                                    </Text>
                                </FilterCell>
                            )
                        })
                    }
                </div>
            </div>
            <span
                className={[
                    'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                    searchParams.boolList.length ? 'd-block' : 'd-none'
                ].join(' ')}
                onClick={() => {
                    dispatch(removeBoolList())
                }}
            >
                {/* clear all filters button. baseline, not center */}
                <FaTimes />
            </span>
        </div>
        <div className={'row'}>
            <div className={'col-12 col-md-6'}>
                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        {SourceFileExtensionDoughnut}
                    </div>
                    <div className={'col-12 col-md-6'}>
                        {SharepointClientIPDoughnut}
                    </div>
                </div>
                {SharePointDetailsActivity}
                {OneDriveDetailsActivity}
                {SourceFileExtensionActivity}
            </div>
            <div className={'col-12 col-md-6'}>
                {SharepointLocations}
                {SiteUrlActivity}
                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        {SharepointUserIdTable}
                    </div>
                    <div className={'col-12 col-md-6'}>
                        {SourceFileNameCountedTable}
                    </div>
                </div>
            </div>
        </div>
        <div className={'min-width-fix mb-3'}>
            {/* table data */}
            {SharepointEventsTable}
        </div>
        {
            renderModals
        }
    </div>
}

export default O365Sharepoint

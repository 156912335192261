import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    GetBoxStatusRequest,
    GetBoxStatusResponse
} from '@interfaces/watchdog/configuration/box'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const boxApi = createApi({
    reducerPath: 'boxApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getBoxStatus: builder.mutation<GetBoxStatusResponse, GetBoxStatusRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getBoxStatus',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {}
                }
            }
        })
    })
})

export const {
    useGetBoxStatusMutation
} = boxApi

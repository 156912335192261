import { PARTNER_ROUTES } from '@constants/main/routes'
import PartnerAdd from '@features/watchdog/account-data/partner/add/PartnerAdd'
import PartnerMenu from '@features/watchdog/account-data/partner/PartnerMenu'
import PartnerUpdate from '@features/watchdog/account-data/partner/update/PartnerUpdate'
import PartnerView from '@features/watchdog/account-data/partner/PartnerView'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: PARTNER_ROUTES.MAIN.link,
        component: PartnerMenu
    },
    {
        exact: true,
        path: PARTNER_ROUTES.ADD.link,
        component: PartnerAdd
    },
    {
        exact: true,
        path: [
            PARTNER_ROUTES.UPDATE.link,
            '/:partnerID'
        ].join(''),
        component: PartnerUpdate
    },
    {
        exact: true,
        path: [
            PARTNER_ROUTES.VIEW.link,
            '/:partnerID'
        ].join(''),
        component: PartnerView
    }
]

export default Routes

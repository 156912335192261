
import { useDoAuthMutation } from '@apis/main/login-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    LOGIN_FORMIK_INITIAL_VALUES,
    LOGIN_VALIDATION_SCHEMA,
    TEXT
} from '@constants/main/login'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    LOGIN_ROUTES

} from '@constants/main/routes'
import { resetGrc } from '@slices/dashboard/grc'
import { resetSoc } from '@slices/dashboard/soc'
import { resetRddMain } from '@slices/dashboard/soc/rdd/main'
import { resetRddDetails } from '@slices/dashboard/soc/rdd/details'
import { resetTac } from '@slices/dashboard/tac'
import {
    resetForcePasswordToken,
    resetMfaToken,
    setDefaultRoute,
    setForcePasswordToken,
    setMfaToken
} from '@slices/main/login'
import { resetProfile } from '@slices/main/profile'
import {
    selectMode,
    setSettings,
    resetSettings
} from '@slices/main/settings'
import { resetSidebar } from '@slices/main/sidebar'
import { setToken } from '@slices/main/token'
import { resetOrder } from '@slices/watchdog/account-data/order/main'
import { resetPartner } from '@slices/watchdog/account-data/partner'
import { resetBox } from '@slices/watchdog/configuration/box'
import { resetEvent } from '@slices/watchdog/soc-data/event/main'
import { resetFilter } from '@slices/watchdog/soc-data/filter'
import { resetFlow } from '@slices/watchdog/soc-data/flow'
import {
    FormStyledComponents as Form,
    LoginStyledComponents as Login,
    SpinnerContainer
} from '@styles/components'
import { push } from 'connected-react-router'
import React, {
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { resetMdrDataId } from '@slices/dashboard/soc/mdr/dataId'
import { resetMdrDetailed } from '@slices/dashboard/soc/mdr/detailedDashboard/main'
import { resetMdrDetails } from '@slices/dashboard/soc/mdr/details'
import { resetMdrMain } from '@slices/dashboard/soc/mdr/main'
import { resetExtVssMain } from '@slices/dashboard/soc/ext-vss/main'
import { resetO365Main } from '@slices/dashboard/soc/o365/main'
import { resetO365DataId } from '@slices/dashboard/soc/o365/dataId'
import { resetO365Detailed as resetO365MainDetailed } from
    '@slices/dashboard/soc/o365/detailedDashboard/main'
import { resetO365Detailed as resetO365SharepointDetailed } from
    '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import { resetO365Detailed as resetO365ExchangeDetailed } from
    '@slices/dashboard/soc/o365/detailedDashboard/exchange'
import { resetO365Details } from '@slices/dashboard/soc/o365/details'
import { resetVssMain } from '@slices/dashboard/soc/vss/main'
import { resetVssDetails } from '@slices/dashboard/soc/vss/vulnDesc'
import { resetExtVssDetails } from '@slices/dashboard/soc/ext-vss/vulnDesc'
import { resetGdprDetails } from '@slices/dashboard/grc/gdpr/details'
import { resetGdprMain } from '@slices/dashboard/grc/gdpr/main'
import { resetNistDetails } from '@slices/dashboard/grc/nist/details'
import { resetNistMain } from '@slices/dashboard/grc/nist/main'
import { resetPcidssDetails } from '@slices/dashboard/grc/pcidss/details'
import { resetPcidssMain } from '@slices/dashboard/grc/pcidss/main'
import { resetAzureDataId } from '@slices/dashboard/soc/azure/dataId'
import {
    resetAzureDetailed as resetAzureMainDetailed
} from '@slices/dashboard/soc/azure/detailedDashboard/main'
import {
    resetAzureDetailed as resetAzureKubernetesDetailed
} from '@slices/dashboard/soc/azure/detailedDashboard/kubernetes'
import { resetAzureDetails } from '@slices/dashboard/soc/azure/details'
import { resetAzureMain } from '@slices/dashboard/soc/azure/main'
import { resetOssDetails } from '@slices/dashboard/soc/oss/details'
import { resetOssMain } from '@slices/dashboard/soc/oss/main'
import { resetWssMain } from '@slices/dashboard/soc/wss/main'
import { resetWssDetails } from '@slices/dashboard/soc/wss/wssDesc'
import { resetEvm } from '@slices/dashboard/tac/evm'
import { resetImm } from '@slices/dashboard/tac/imm'
import { resetVmm } from '@slices/dashboard/tac/vmm'
import {
    resetDevice as resetDeviceConfigDevice
} from '@slices/watchdog/configuration/device-configuration/device-config'
import {
    resetM365 as resetDeviceConfigM365
} from '@slices/watchdog/configuration/device-configuration/m365'
import {
    resetMain as resetDeviceConfigMain
} from '@slices/watchdog/configuration/device-configuration/main'
import {
    resetVAPT as resetDeviceConfigVAPTDetails
} from '@slices/watchdog/configuration/device-configuration/vapt/details'
import {
    resetVAPT as resetDeviceConfigVAPTMain
} from '@slices/watchdog/configuration/device-configuration/vapt/main'
import { useFormik } from 'formik'

const LoginPage = () => {
    const dispatch = useAppDispatch()

    const mode = useAppSelector(selectMode)
    const [doAuth, doAuthMutation] = useDoAuthMutation()

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: LOGIN_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: LOGIN_VALIDATION_SCHEMA,
        onSubmit: (values) => {
            doAuth({
                email: values.email,
                password: values.password,
                app: 'watchdog'
            })
        }
    })

    // reset the state on every encounter to this page.
    useEffect(() => {
        // dispatch(resetSettings())
        // resetting token is performed when logged out OR invalidation.
        // dispatch(resetSidebar())
    }, [])

    /**
     * useEffect in the event that a formik hook is used.
     * just update the state according to what was set.
     */

    /**
     * expected data response also contains the token + other settings.
     * for the sake of explicity determining which properties are used
     * in the system, we are going to define those in the interface
     * in the login slice. we also have changePassword,
     * mfa, and token which are important for mfa authentication
     * and token storage in the store.
     */

    const EmailInput = useMemo(() => {
        return (
            <Form.Group>
                {/* label had to be added because of unit testing
                but directly the react-testing-library's ByLabelText */}
                <Form.Label htmlFor={TEXT.LOGIN.FORM.EMAIL.ID}>
                    {TEXT.LOGIN.FORM.EMAIL.LABEL}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.email)}
                    type={'text'}
                    name={'email'}
                    id={TEXT.LOGIN.FORM.EMAIL.ID}
                    onChange={handleChange}
                    value={values.email}
                />
                <Form.Feedback errors={Boolean(errors.email)} >{
                    errors.email ? errors.email : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.email, errors.email])

    const PasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={TEXT.LOGIN.FORM.PASSWORD.ID}>
                    {TEXT.LOGIN.FORM.PASSWORD.LABEL}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.password)}
                    type={'password'}
                    name={'password'}
                    id={TEXT.LOGIN.FORM.PASSWORD.ID}
                    onChange={handleChange}
                    value={values.password}
                />
                <Form.Feedback errors={Boolean(errors.password)} >{
                    errors.password ? errors.password : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.password, errors.password])

    const SubmitButton = useMemo(() => {
        // update. first instance of updating the code snippet of a component
        // because of a test suite to be clean. On the bright side, it may help
        // find loose ends.

        /** replace string with success message if the authentication was successful */
        const buttonContent = doAuthMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{TEXT.LOGIN.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : TEXT.LOGIN.FORM.SUBMIT_BUTTON

        return (
            <Form.Group className={'text-center'}>
                <Login.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={doAuthMutation.isLoading}
                >{buttonContent}</Login.Button>
            </Form.Group>
        )
    }, [
        doAuthMutation
    ])

    /** redirect user to reset password page */
    const ResetPasswordButton = useMemo(() => {
        return (
            <Form.Group className={'text-center'}>
                <Login.Button onClick={() => {
                    dispatch(push(LOGIN_ROUTES.RESET_PASSWORD.link))
                }}
                type={'button'}
                mode={'primary'}
                >{TEXT.RESET_PASSWORD.TITLE}</Login.Button>
            </Form.Group>
        )
    }, [])

    const Logo = useMemo(() => {
        return (
            <Login.Logo className={'col-auto'} src={mode === 'dark'
                ? '/media/dark-theme.svg'
                : '/media/light-theme.svg'}
            alt={'Bluedog Logo'} role={'button'} />
        )
    }, [mode])

    useEffect(() => {
        if (doAuthMutation.data) {
            /**
             * assumes that if token is truthy, the rest of the data
             * also exists. the user can go to three routes.
             * the default route;
             * IF mfa is true, go to mfa page;
             * IF forcePassword change is true, go to force password page
             */
            const data = doAuthMutation.data
            if (data.token) {
                dispatch(setToken({
                    value: data.token,
                    valid: true
                }))

                // set mfa token IF mfa is true.
                if (data.mfa) {
                    dispatch(setMfaToken(data.token))
                }

                // set force password token if changePassword is true.
                if (data.changePassword) {
                    dispatch(
                        setForcePasswordToken(data.token)
                    )
                }

                data.defaultRoute && dispatch(setDefaultRoute(
                    data.defaultRoute
                ))

                data.theme && data.stylesheet && dispatch(setSettings({
                    mode: data.theme,
                    style: data.stylesheet
                }))
            }

            if (data.success) {
                // check if authentication is right..
                // do history push AND set redirect
                data.defaultRoute && dispatch(push(data.defaultRoute))
            } else {
                if (data.mfa) {
                    dispatch(push(LOGIN_ROUTES.MFA.link))
                } else if (data.changePassword) {
                    dispatch(push(LOGIN_ROUTES.FORCE_PASSWORD.link))
                } else if (!data.mfa && !data.changePassword) {
                    toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                } else {
                    toast.info(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                }
            }
        }
    }, [doAuthMutation.data])

    useEffect(() => {
        if (doAuthMutation.error) {
            console.error(doAuthMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doAuthMutation.error])

    /** reset all slices on mount. */
    useEffect(() => {
        /** DASHBOARD */

        /** MONITORS */
        dispatch(resetGrc())
        dispatch(resetSoc())
        dispatch(resetTac())

        /** GDPR */
        dispatch(resetGdprDetails())
        dispatch(resetGdprMain())

        /** NIST */
        dispatch(resetNistDetails())
        dispatch(resetNistMain())

        /** PCIDSS */
        dispatch(resetPcidssDetails())
        dispatch(resetPcidssMain())

        /** AZURE */
        dispatch(resetAzureDataId())
        dispatch(resetAzureDetails())
        dispatch(resetAzureMain())
        dispatch(resetAzureMainDetailed())
        dispatch(resetAzureKubernetesDetailed())

        /** RDD */
        dispatch(resetRddMain())
        dispatch(resetRddDetails())

        /** MDR */
        dispatch(resetMdrMain())
        dispatch(resetMdrDetails())
        dispatch(resetMdrDataId())
        dispatch(resetMdrDetailed())

        /** EXTVSS */
        dispatch(resetExtVssMain())
        dispatch(resetExtVssDetails())

        /** VSS */
        dispatch(resetVssMain())
        dispatch(resetVssDetails())

        /** O365 */
        dispatch(resetO365Main())
        dispatch(resetO365DataId())
        dispatch(resetO365MainDetailed())
        dispatch(resetO365SharepointDetailed())
        dispatch(resetO365ExchangeDetailed())
        dispatch(resetO365Details())

        /** MAIN */
        dispatch(resetMfaToken())
        dispatch(resetForcePasswordToken())
        dispatch(resetProfile())
        dispatch(resetSettings())
        dispatch(resetSidebar())

        /** OSS */
        dispatch(resetOssDetails())
        dispatch(resetOssMain())

        /** WSS */
        dispatch(resetWssMain())
        dispatch(resetWssDetails())

        /** EVM */
        dispatch(resetEvm())

        /** IMM */
        dispatch(resetImm())

        /** VMM */
        dispatch(resetVmm())

        /** WATCHDOG */
        dispatch(resetBox())
        dispatch(resetEvent())
        dispatch(resetFilter())
        dispatch(resetFlow())
        dispatch(resetOrder())
        dispatch(resetPartner())

        /** DEVICE CONFIG */
        dispatch(resetDeviceConfigDevice())
        dispatch(resetDeviceConfigM365())
        dispatch(resetDeviceConfigMain())
        dispatch(resetDeviceConfigVAPTDetails())
        dispatch(resetDeviceConfigVAPTMain())

        /** localStorage contains detailed dashboard data so this will be commented out
         * until there are new items to store.
         */
        // localStorage.clear()
    }, [])

    return (
        // display a component that is fixed at the center.
        // i get the bootstrap grid system but my problem is
        // that i'll be inconvencied with excess indentation
        // just to suit the needs.
        <div className={'align-items-center flex-column h-100 justify-content-center row mx-0'}>
            {Logo}
            <Login.Container className={'mt-3'}>
                <h3 className={'text-center fw-bold'}>{TEXT.LOGIN.WELCOME}</h3>
                <small className={'text-center d-block mt-4'}>{TEXT.LOGIN.MESSAGE}</small>
                <Form.Main className={'p-0'} onSubmit={handleSubmit}>
                    {EmailInput}
                    {PasswordInput}
                    {SubmitButton}
                    {ResetPasswordButton}
                </Form.Main>
            </Login.Container>
        </div>
    )
}

export default LoginPage

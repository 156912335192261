
import { URL } from '@constants/main/root'
import {
    GetCardDetailsResponse,
    GetCardsDetailsRequest,
    GetCardsRequest,
    GetCardsResponse
} from '@interfaces/dashboard/monitor'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getCards: builder.mutation<GetCardsResponse, GetCardsRequest & TokenAuth>({
            query: (body) => {
                const formData: GetCardsRequest = {
                    modal_type: body.modal_type
                }

                return {
                    url: 'api/getCards',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getCardDetails: builder
            .mutation<GetCardDetailsResponse, GetCardsDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetCardsDetailsRequest = {
                        deviceid: body.deviceid,
                        in_face: body.in_face,
                        service_type: body.service_type
                    }

                    return {
                        url: 'api/getCards/details',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetCardsMutation,
    useGetCardDetailsMutation
} = dashboardApi

import {
    useAddQuestionMutation
} from '@apis/watchdog/virtual-ciso/questionnaire-information-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as QUESTION_MESSAGE,
    TEXT as QUESTION_TEXT
} from '@constants/watchdog/virtual-ciso/questionnaire-information/question'
import {
    QuestionModal
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/question'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const AddConfirmation = ({ modal, addModal, closeModal } : {
    modal: QuestionModal,
    addModal: ActionCreatorWithPayload<QuestionModal, string>,
    closeModal: ActionCreatorWithPayload<QuestionModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [addQuestion, addQuestionMutation] = useAddQuestionMutation()

    /** call formiks */

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData?.addConfirm) {
            const questionnaireID = modal.formData.addConfirm.questionnaire_id
            const values = modal.formData.addConfirm.formValues

            if (questionnaireID) {
                addQuestion({
                    authToken: newToken,
                    questionnaire_id: questionnaireID,
                    title: values.title,
                    description: values.description,
                    question: values.question,
                    type_id: values.type_id,
                    active: values.active,
                    complianceLines: JSON.stringify(values.complianceLines),
                    answerLines: JSON.stringify(values.answerLines)
                })
            }
        }
    }, [
        modal.formData
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (addQuestionMutation.data) {
            const data = addQuestionMutation.data
            if (data.statusMessage) {
                toast.success(data.statusMessage, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(closeModal(modal))
            } else {
                toast.error(
                    QUESTION_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [addQuestionMutation.data])

    useEffect(() => {
        if (addQuestionMutation.error) {
            console.error(addQuestionMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addQuestionMutation.error])

    /** confirmation window for both add locations AND add m365 */
    const SubmitButton = useMemo(() => {
        const buttonContent = addQuestionMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{QUESTION_TEXT.ADD.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : QUESTION_TEXT.ADD.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={submitData}
                disabled={addQuestionMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [addQuestionMutation.isLoading])

    return (<div>
        <h5>{QUESTION_TEXT.ADD.TITLE}</h5>
        <small className={'d-block my-4'}>
            {QUESTION_TEXT.ADD.CONFIRMATION_MESSAGE}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default AddConfirmation

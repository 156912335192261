import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    AddPartnerRequest,
    AddPartnerResponse,
    DeletePartnerRequest,
    DeletePartnerResponse,
    GetOrderListRequest,
    GetOrderListResponse,
    GetPartnerDetailsRequest,
    GetPartnerDetailsResponse,
    GetPartnersListRequest,
    GetPartnersListResponse,
    GetPartnersRequest,
    GetPartnersResponse,
    GetPartnerStateListRequest,
    GetPartnerStateListResponse,
    GetPartnerTypesListRequest,
    GetPartnerTypesListResponse,
    UpdatePartnerRequest,
    UpdatePartnerResponse
} from '@interfaces/watchdog/account-data/partner'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const partnerApi = createApi({
    reducerPath: 'partnerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getPartners: builder.mutation<GetPartnersResponse,
        GetPartnersRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getPartners',
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    }
                }
            }
        }),
        getPartnerDetails: builder
            .mutation<GetPartnerDetailsResponse, GetPartnerDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetPartnerDetailsRequest = {
                        partnerID: body.partnerID
                    }

                    return ({
                        url: 'be/getPartnerDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    })
                }
            }),
        getOrderList: builder.mutation<GetOrderListResponse, GetOrderListRequest & TokenAuth>({
            query: (body) => {
                const formData: GetOrderListRequest = {
                    partnerID: body.partnerID
                }

                return {
                    url: 'be/getOrderList',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        getPartnersList: builder
            .mutation<GetPartnersListResponse, GetPartnersListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getPartnersList',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            partner_type: body.partnerType
                        }
                    }
                }
            }),
        getPartnerTypesList: builder
            .mutation<GetPartnerTypesListResponse, GetPartnerTypesListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getPartnerTypesList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        getPartnerStateList: builder
            .mutation<GetPartnerStateListResponse, GetPartnerStateListRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/getPartnerStateList',
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        }
                    }
                }
            }),
        deletePartner: builder.mutation<DeletePartnerResponse, DeletePartnerRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/delPartner',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        partnerID: body.partnerID
                    }
                }
            }
        }),
        addPartner: builder.mutation<AddPartnerResponse, AddPartnerRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/addPartner',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        active: body.active,
                        company_name: body.companyName,
                        partner_state: body.partnerState,
                        partner_name: body.partnerName,
                        email: body.email,
                        street_name: body.streetName,
                        postalcode: body.postalCode,
                        city: body.city,
                        country: body.country,
                        discount: body.discount,
                        partner_type: body.partnerType,
                        partner_of: body.partnerOf,
                        partnerCurrency: body.currencies
                    }
                }
            }
        }),
        updatePartner: builder.mutation<UpdatePartnerResponse, UpdatePartnerRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/updatePartner',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        partnerID: body.partnerID,
                        active: body.active,
                        company_name: body.companyName,
                        partner_state: body.partnerState,
                        partner_name: body.partnerName,
                        email: body.email,
                        street_name: body.streetName,
                        postalcode: body.postalCode,
                        city: body.city,
                        country: body.country,
                        discount: body.discount,
                        partner_type: body.partnerType,
                        partner_of: body.partnerOf,
                        partnerCurrency: body.currencies

                    }
                }
            }
        })

    })
})

export const {
    useGetPartnersMutation,
    useAddPartnerMutation,
    useDeletePartnerMutation,
    useGetOrderListMutation,
    useGetPartnerDetailsMutation,
    useGetPartnerStateListMutation,
    useGetPartnerTypesListMutation,
    useGetPartnersListMutation,
    useUpdatePartnerMutation
} = partnerApi

import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    GAUGE_SIZES
} from '@constants/main/root'
import {
    MonitorModal,
    OssDetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import { ColorPresets } from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    createStylesheet
} from '@styles/themes'
import Gauge from 'gaugeJS'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/oss/main'

import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/oss/details'
import uniqueString from 'unique-string'
import {
    ActionType,
    ControlCategory
} from '@interfaces/dashboard/soc/oss'

/** will also be used in OSS Details. No click events. */
interface ComponentProps {
    totalScore: number,
    maxScore: number,
    chartEl: React.RefObject<HTMLCanvasElement>,
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    actionType: ActionType
    controlCategory: ControlCategory
}

const DataGauge = ({
    totalScore, maxScore, chartEl,
    modal, addModal, closeModal, changeModalColor,
    actionType, controlCategory
} : ComponentProps) => {
    const dispatch = useAppDispatch()

    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        let length = 0.45
        let strokeColor = stylesheet.style.colorPresets.lightGrey

        if (maxScore === 0) {
            length = 0
        } else if (maxScore > 0 && totalScore === 0) {
            length = 0.45
            strokeColor = stylesheet.style.colorPresets.red
        } else if (totalScore < 0) {
            length = 0
        }

        if (chartEl.current) {
            const opts: Gauge.GaugeOptions = {
                angle: -0.2,
                lineWidth: 0.2,
                radiusScale: 1,
                pointer: {
                    // length: 0.45, // // Relative to gauge radius
                    length: length,
                    strokeWidth: 0.024,
                    color: stylesheet.style.colorPresets.grey // Fill color: ;
                },
                // color changes based on percentage.
                percentColors: [
                    [0.0, stylesheet.style.colorPresets.red],
                    [0.50, stylesheet.style.colorPresets.yellow],
                    [1.0, stylesheet.style.colorPresets.green]
                ],
                limitMax: true,
                limitMin: true,
                strokeColor: strokeColor,
                generateGradient: true,
                highDpiSupport: true,

                // renderTicks is Optional
                renderTicks: {
                    divisions: 10,
                    divWidth: 0.6,
                    divLength: 0.43,
                    divColor: stylesheet.mode.fontColor,
                    subDivisions: 0,
                    subLength: 0.2,
                    subWidth: 0.5,
                    subColor: stylesheet.style.colorPresets.darkGrey
                },
                staticLabels: {
                    font: '11px arial',
                    labels: [0, 20, 40, 60, 80, 100],
                    color: stylesheet.mode.fontColor,
                    fractionDigits: 0 // Optional: Numerical precision. 0=round off.
                },
                colorStart: '',
                colorStop: ''
            }

            const gauge = new Gauge.Gauge(chartEl.current).setOptions(opts) // create sexy gauge!
            gauge.maxValue = 100 // set max gauge value
            gauge.setMinValue(0) // Prefer setter over gauge.minValue = 0
            gauge.animationSpeed = 16 // set animation speed (32 is default value)
            gauge.set((((totalScore / maxScore) || 0) * 100)) // set actual value

            chartEl.current.style.height = GAUGE_SIZES.NORMAL.HEIGHT
            chartEl.current.style.width = GAUGE_SIZES.NORMAL.WIDTH

            chartEl.current.addEventListener('click', () => {
                // add a modal.
                dispatch(setDetailsStartDate(currentParams.ranges.start))
                dispatch(setDetailsEndDate(currentParams.ranges.end))

                const detailsContent:OssDetailsForm = {
                    actionType: actionType,
                    controlCategory: controlCategory
                }

                const serviceTypeFormData:ServiceTypeFormData = {
                    oss: {
                        details: detailsContent
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    card: modal.card,
                    operation: 'DETAILS',
                    serviceTypeFormData: serviceTypeFormData,
                    isBorderWide: true
                }))
            })
        }
    }, [totalScore, maxScore])

    const DataContent = useMemo(() => {
        const content = <canvas ref={chartEl}/>
        return content
    }, undefined)

    return <div className={'w-100 text-center'}>{DataContent}</div>
}

DataGauge.propTypes = {
    controlState: PropTypes.string,
    totalScore: PropTypes.number,
    maxScore: PropTypes.number
}

export default DataGauge

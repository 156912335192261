
import {
    FilterAction,
    FilterColumn,
    SeverityType,
    FilterKeys,
    AddFilterValues,
    UpdateFilterValues,
    AddFilterKeys,
    UpdateFilterKeys
} from '@interfaces/watchdog/soc-data/filter'
import _ from 'lodash'
import * as Yup from 'yup'
import {
    MESSAGE as ROOT_MESSAGE
} from '@constants/main/root'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Filter call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when filterID is undefined */
    UNDEFINED_ID: 'Empty filterID detected.'

}

export const TEXT = {
    INTERFACE: 'Select the criteria for the filter to apply.',
    ADD: {
        /** confirmation label for adding a filter */
        TITLE: 'Add Filter Confirmation',
        /** confirmation message for adding a filter */
        MESSAGE: 'Are you sure you want to submit the filter data?',
        FORM: {
            EVENT_NAME: {
                LABEL: 'Event Name',
                ID: 'ADD__EVENT_NAME'
            },
            HOSTNAME: {
                LABEL: 'Host Name',
                ID: 'ADD__HOSTNAME'
            },
            BOXNAME: {
                LABEL: 'Box Name',
                ID: 'ADD__BOXNAME'
            },
            SRC_IP: {
                LABEL: 'Source I.P.',
                ID: 'ADD__SRC_IP'
            },
            DEST_IP: {
                LABEL: 'Destination I.P.',
                ID: 'ADD__DEST_IP'
            },
            TYPE: {
                LABEL: 'Remove/Adjust',
                ID: 'ADD__TYPE'
            },
            VALUE: {
                LABEL: 'Value',
                ID: 'ADD__VALUE'
            },
            LOADING_BUTTON: 'Adding',
            SUBMIT_BUTTON: 'Add Filter'
        }
    },
    UPDATE: {
        /** confirmation label for updating a filter */
        TITLE: 'Update Filter Confirmation',
        /** confirmation message for updating a filter */
        MESSAGE: 'Are you sure you want to submit the filter data?',
        FORM: {
            IS_ACTIVE: {
                LABEL: 'Is Active?',
                ID: 'UPDATE__IS_ACTIVE'
            },
            LOADING_BUTTON: 'Updating',
            SUBMIT_BUTTON: 'Update Filter'
        }
    },
    DELETE: {
        /** confirmation label for deleting a filter */
        TITLE: 'Delete Filter Confirmation',
        /** confirmation message for deleting a filter */
        MESSAGE: 'Are you sure you want to delete this filter?',
        FORM: {
            LOADING_BUTTON: 'Deleting',
            SUBMIT_BUTTON: 'Delete Filter'
        }
    }
}

export const ACTIONS: FilterAction[] = [
    // {
    //     label: 'View',
    //     value: 'FILTER_VIEW',
    //     mode: 'primary'
    // },
    {
        label: 'Update',
        value: 'FILTER_UPDATE',
        mode: 'primary'
    },
    {
        label: 'Delete',
        value: 'FILTER_DELETE',
        mode: 'danger'
    }
]

/** the value of property can only be the property names
 * of the Filters interface, so it should be throwing
 * errors if the string wasn't any of those keys.
 */

export const COLUMNS: FilterColumn[] = (() => {
    const columnNames: Omit<FilterColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Box Name',
            value: 'boxname'
        },
        {
            label: 'Event Name',
            value: 'eventName'
        },
        {
            label: 'Source I.P.',
            value: 'src_ip'
        },
        {
            label: 'Destination I.P.',
            value: 'dest_ip'
        },
        {
            label: 'Type',
            value: 'type'
        },
        {
            label: 'Value',
            value: 'value'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: FilterColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const SEVERITY_TYPES: SeverityType[] = [
    {
        label: 'Remove',
        value: 'filter'
    },
    {
        label: 'Adjust',
        value: 'adjust'
    }
]

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */

export const KEYS: {
    FILTER: FilterKeys[]
} = {
    FILTER: [
        'activationTime', 'active', 'boxname', 'dest_ip', 'eventName',
        'hostname', 'lastUpdate', 'src_ip', 'state', 'type', 'value'
    ]
}

export const INITIAL_VALUES: {
    ADD: AddFilterValues,
    UPDATE: UpdateFilterValues
} = {
    ADD: {
        eventname: '',
        hostname: '',
        boxname: '',
        srcIp: '',
        destIp: '',
        type: '',
        value: 0
    },
    UPDATE: {
        eventname: '',
        hostname: '',
        boxname: '',
        srcIp: '',
        destIp: '',
        type: '',
        value: 0,
        active: false
    }
}

export const VALIDATION_SCHEMA: {
    ADD: OptionalObjectSchema<Record<AddFilterKeys, Yup.AnySchema>>,
    UPDATE: OptionalObjectSchema<Record<UpdateFilterKeys, Yup.AnySchema>>,
} = {
    ADD: Yup.object({
        eventname: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        hostname: Yup.string(),
        boxname: Yup.string(),
        srcIp: Yup.string(),
        destIp: Yup.string(),
        type: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        value: Yup.number()
            .when(
                'type', {
                    is: 'filter',
                    then: Yup.number()
                        .lessThan(1, ROOT_MESSAGE.ERROR.FORM.SEVERITY_LS_1)
                        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
                    otherwise: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
                }
            )
            .when(
                'type', {
                    is: 'adjust',
                    then: Yup.number()
                        .moreThan(0, ROOT_MESSAGE.ERROR.FORM.SEVERITY_GS_0)
                        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
                    otherwise: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
                }
            )
    }),
    UPDATE: Yup.object({
        active: Yup.boolean(),
        eventname: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        hostname: Yup.string(),
        boxname: Yup.string(),
        srcIp: Yup.string(),
        destIp: Yup.string(),
        type: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
        value: Yup.number()
            .when(
                'type', {
                    is: 'filter',
                    then: Yup.number()
                        .lessThan(1, ROOT_MESSAGE.ERROR.FORM.SEVERITY_LS_1)
                        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
                    // otherwise: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
                }
            )
            .when(
                'type', {
                    is: 'adjust',
                    then: Yup.number()
                        .moreThan(0, ROOT_MESSAGE.ERROR.FORM.SEVERITY_GS_0)
                        .required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
                    // otherwise: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
                }
            )
    })
}

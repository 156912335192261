import {
    useGetModalMutation,
    useGetPortDetailsMutation
} from '@apis/dashboard/soc/ext-vss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { TEXT as MONITOR_TEXT } from '@constants/dashboard/monitor'
import {
    MESSAGE as EXT_VSS_MESSAGE,
    TEXT as EXT_VSS_TEXT
} from '@constants/dashboard/soc/ext-vss'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import ScanResultsDataTable from '@features/dashboard/soc/ext-vss/ScanResultsDataTable'
import VssClassificationChart from '@features/dashboard/soc/ext-vss/VssClassificationChart'
import VulnerabilityIndexChart from '@features/dashboard/soc/ext-vss/VulnerabilityIndexBarChart'
import IssuesPerCategoryChart from '@features/dashboard/soc/ext-vss/IssuesPerCategoryChart'
import IssuesPerIPChart from '@features/dashboard/soc/ext-vss/IssuesPerIPChart'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import ReadMore from '@features/main/ReadMore'
import {
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    ModalHeader,
    ModalRequest
} from '@interfaces/dashboard/soc/ext-vss'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    resetExtVssMain,
    selectCurrentParams,
    selectPrintOptions,
    selectSearchParams,
    selectTableData,
    setCurrentParams,
    setEndDate,
    setLogo,
    setMainReportOnly,
    setRefetch,
    setRemoveFilters,
    setStartDate,
    selectExpandText,
    setExpandText,
    setGroupIssues,
    setIssueDetails
} from '@slices/dashboard/soc/ext-vss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    Container,
    DashboardStyledComponents as Dashboard,
    SpinnerContainer,
    Table
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaFileCsv,
    FaFilePdf
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'
import {
    setFilters,
    setSearch
} from '@slices/dashboard/soc/vss/main'

const ExtVssModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const tableData = useAppSelector(selectTableData)
    const expandText = useAppSelector(selectExpandText)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    /** will be initiailized multiple times. */
    const [getModal, getModalMutation] = useGetModalMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalMutation()

    const [getPortDetails, getPortDetailsMutation] = useGetPortDetailsMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, {
                ...TOASTIFY_DEFAULT_OPTIONS
            })
            dispatch(setRefetch(false))
        }
    }, [getModalMutation.error])

    useEffect(() => {
        if (getPortDetailsMutation.error) {
            console.error(getPortDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, {
                ...TOASTIFY_DEFAULT_OPTIONS
            })
            dispatch(setRefetch(false))
        }
    }, [getPortDetailsMutation.error])

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPortDetails = () => {
        const unsubscribeMutation = getPortDetails({
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const newRanges = getUtcRanges(searchParams.ranges)
                    const requestData: ModalRequest & TokenAuth = {
                        authToken: token.value,
                        deviceid: modal.card.deviceid,
                        service_type: modal.card.service_type,
                        in_face: modal.card.in_face,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetModal()
        unsubscribeGetPortDetails()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPortDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            service_type: modal.card.service_type,
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalPromise = getModal(requestData)
                        getPortDetailsPromise = getPortDetails(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
            getPortDetailsPromise && getPortDetailsPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalMutation.isSuccess &&
            getPortDetailsMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalMutation.isSuccess,
        getPortDetailsMutation.isSuccess
    ])

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data

        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && (data.data || []).length <= 0) {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 30 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))
                dispatch(setRefetch(true))

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    /** to add a module to the print queue, an id is needed to check
     * if the queue is still ongoing or not.
     *
     * - You can disable the print button if the queue's isPrinting
     * value is 'TRUE' and isComplete is 'FALSE'. Please avoid using the
     * truthy conditional check for strict check.
     */

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalMutation.isLoading || getPortDetailsMutation.isLoading
        return (
            <span
                onClick={() => {
                // update ext_vss bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [getModalMutation, getPortDetailsMutation])

    const CsvExportButton = useMemo(() => {
        const ipAddressData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            modal.card.service_type,
            '_', modal.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = ipAddressData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                filename={filename}
                onClick={() => {
                    if (disableExport) return false
                }}
                data={ipAddressData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        modal.card,
        currentParams.ranges
    ])

    const PrintOptionsContent = useMemo(() => {
        const MainReportLabel = (
            <label
                className={'col-9'}
                htmlFor={EXT_VSS_TEXT.PRINT_OPTIONS.MAIN_REPORT_ONLY.ID}>
                {EXT_VSS_TEXT.PRINT_OPTIONS.MAIN_REPORT_ONLY.LABEL}
            </label>
        )
        const MainReportInput = (
            <input
                className={'pointer'}
                type={'checkbox'}
                onChange={() => {
                    dispatch(setMainReportOnly(
                        !printOptions.mainReportOnly
                    ))
                }}
                checked={printOptions.mainReportOnly}
            />
        )

        const RemoveFiltersLabel = (
            <label
                className={'col-9'}
                htmlFor={EXT_VSS_TEXT.PRINT_OPTIONS.REMOVE_FILTERS.ID}>
                {EXT_VSS_TEXT.PRINT_OPTIONS.REMOVE_FILTERS.LABEL}
            </label>
        )
        const RemoveFiltersInput = (
            <input
                type={'checkbox'}
                onChange={() => {
                    dispatch(setRemoveFilters(
                        !printOptions.removeFilters
                    ))
                }}
                checked={printOptions.removeFilters}
            />
        )

        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={EXT_VSS_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {EXT_VSS_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const GroupIssuesLabel = (
            <label
                className={'col-9'}
                htmlFor={EXT_VSS_TEXT.PRINT_OPTIONS.GROUP_ISSUES.ID}>
                {EXT_VSS_TEXT.PRINT_OPTIONS.GROUP_ISSUES.LABEL}
            </label>
        )
        const GroupIssuesInput = (
            <input
                type={'checkbox'}
                onChange={() => {
                    dispatch(setGroupIssues(
                        !printOptions.groupIssues
                    ))
                }}
                checked={printOptions.groupIssues}
                disabled={printOptions.mainReportOnly}
            />
        )

        const IssueDetailsLabel = (
            <label
                className={'col-9'}
                htmlFor={EXT_VSS_TEXT.PRINT_OPTIONS.ISSUE_DETAILS.ID}>
                {EXT_VSS_TEXT.PRINT_OPTIONS.ISSUE_DETAILS.LABEL}
            </label>
        )
        const IssueDetailsInput = (
            <input
                type={'checkbox'}
                onChange={() => {
                    dispatch(setIssueDetails(
                        !printOptions.addIssueDetails
                    ))
                }}
                checked={printOptions.addIssueDetails}
                disabled={printOptions.mainReportOnly}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <div className={'align-items-center row mb-2'}>
                {MainReportLabel}
                <div className={'col-3'}>
                    {MainReportInput}
                </div>

            </div>
            <div className={'align-items-center row mb-2'}>
                {RemoveFiltersLabel}
                <div className={'col-3'}>
                    {RemoveFiltersInput}
                </div>
            </div>
            <div className={'align-items-center row mb-2'}>
                {GroupIssuesLabel}
                <div className={'col-3'}>
                    {GroupIssuesInput}
                </div>
            </div>
            <div className={'align-items-center row mb-2'}>
                {IssueDetailsLabel}
                <div className={'col-3'}>
                    {IssueDetailsInput}
                </div>
            </div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end,
                                        filters: tableData.filters,
                                        search: tableData.search
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: tableData.filtered.length
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalMutation,
        currentParams,
        queueId,
        queues,
        printOptions,
        tableData
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    useEffect(() => {
        dispatch(setFilters(tableData.filters))
    }, [tableData.filters])

    useEffect(() => {
        dispatch(setSearch(tableData.search))
    }, [tableData.search])

    /** taken from ohs-dashboard. uncheck
     * issueDetails and groupIssues if this is checked.
     */
    useEffect(() => {
        if (printOptions.mainReportOnly) {
            dispatch(setGroupIssues(
                false
            ))
            dispatch(setIssueDetails(
                false
            ))
        }
    }, [printOptions.mainReportOnly])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update EXT_VSS. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = getModalMutation.data?.data[0]

        const statcardData: ModalHeader = data?.header || {
            title: modal.card.details.title || '',
            line_1: modal.card.details.line_1 || '',
            line_2: modal.card.details.line_2 || '',
            heading: modal.card.details.heading || '',
            colorType: modal.card.details.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={statcardData.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {statcardData.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {statcardData.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {statcardData.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ modal.card.details.state || 'NO STATE' }
                    : ${ modal.card.details.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalMutation.data?.data[0]
        dispatch(changeModalColor({
            modal: modal,
            colorType: data?.header.colorType || modal.card.details.colorType || 'grey'
        }))
    }, [
        modal.card,
        getModalMutation
    ])

    useEffect(() => {
        return () => {
            dispatch(resetExtVssMain())
        }
    }, [])

    /** create a vulnerability index bar chart. this was previously done
     * with a chartjs instance but... we can use div elements
     * where the marker's position can be determined by passing a prop.
     */

    /** vss classification chart */
    const VssClassification = useMemo(() => {
        return <div>
            <VssClassificationChart
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const VulnerabilityIndexBar = useMemo(() => {
        return <div>
            <VulnerabilityIndexChart
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    /** management summary. max lines should always be 6. */
    const ManagementSummary = useMemo(() => {
        const data = getModalMutation.data?.data[0]

        const content = (
            <Container bgIndex={2} className={'px-3 py-2'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            data && data.conclusionData.NumberScore
                                ? <ReadMore
                                    expandText={expandText}
                                    toggleExpandText={(toggle) => {
                                        dispatch(setExpandText(toggle))
                                    }}
                                >
                                    {data.conclusionData.NumberScore}<br /><br />
                                    {data.conclusionData.Conclusion}<br /><br />
                                    {data.conclusionData.LongTermRecommendation}<br /><br />
                                    {data.conclusionData.ShortTermRecommendation}
                                </ReadMore>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [getModalMutation.data, expandText])

    const PortsTable = useMemo(() => {
        const data = getPortDetailsMutation.data?.data

        const udp = data?.portsAssessed?.[0]?.UDP || ''
        const tcp = data?.portsAssessed?.[1].TCP || ''

        return (
            <Table className={'table-striped table-hover px-0'}>
                <table className={'table'}>
                    <tbody>
                        <tr key={'port-udp'}>
                            <td className={'border-top-0'}>{'UDP'}</td>
                            <td className={'border-top-0'}>{udp}</td>
                        </tr>
                        <tr key={'port-tcp'}>
                            <td>{'TCP'}</td>
                            <td>{tcp}</td>
                        </tr>
                    </tbody>
                </table>
            </Table>
        )
    }, [getPortDetailsMutation.data])

    const HostsTable = useMemo(() => {
        const data = getPortDetailsMutation.data?.data

        const groupedPorts = data?.portDetails || []

        return (
            <Table className={'table-striped table-hover px-0'}>
                <table className={'table'}>
                    <tbody>
                        {
                            groupedPorts.map((obj, index) => {
                                const key = [
                                    'port-asset-', index
                                ].join('')
                                const ports = obj.portDetails.map(({ port }) => port)
                                return (
                                    <tr key={key}>
                                        <td>
                                            {obj.asset}
                                        </td>
                                        <td>
                                            {ports.join(', ')}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </Table>
        )
    }, [getPortDetailsMutation.data])

    /** issues per category and then ip */

    /** no chart instance useState for these two since there's no other
     * component related to this.
     */
    const issuesPerIpElChartEl = useRef<HTMLCanvasElement>(null)
    const issuesPerCategoryChartEl = useRef<HTMLCanvasElement>(null)

    const IssuesPerCategory = useMemo(() => {
        return <div>
            <IssuesPerCategoryChart
                issuesPerIpChartEl={issuesPerIpElChartEl}
                issuesPerCategoryChartEl={issuesPerCategoryChartEl}
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const IssuesPerIP = useMemo(() => {
        return <div>
            <IssuesPerIPChart
                issuesPerIpChartEl={issuesPerIpElChartEl}
                issuesPerCategoryChartEl={issuesPerCategoryChartEl}
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const ScanResultsTable = useMemo(() => {
        return (
            <ScanResultsDataTable
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getModalMutation.data}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        )
    }, undefined)

    /** reset data on unmount */

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>

            <div className={'row mb-3 align-items-center'}>
                <div className={'col-12 col-xl-5 mb-3 mb-xl-0'}>
                    {Statcard}
                </div>
                <div className={'col-12 col-lg-9 col-xl-5 mb-3 mb-xl-0'}>
                    {VssClassification}
                </div>
                {/* ideally should be a fixed width in all screen sizes
                so we will add a div inside and apply that fixed width */}
                <div className={'col-12 col-lg-3 col-xl-2'}>
                    {VulnerabilityIndexBar}
                </div>
            </div>

            {/* layout for adding a title and the content changed because of collapse button
            line break on smaller devices */}
            <div className={'min-width-fix'}>
                <span className={'d-inline-block mb-2'}>
                    {EXT_VSS_TEXT.SECTIONS.MANAGEMENT_SUMMARY}
                </span>
                {ManagementSummary}
            </div>
            {/* min-width-fix issues IF toggle device toolbar is disabled and horizontal
            resize is used. use the device toolbar from now on for more accurate results.
            also found at existing modals. */}

            <div className={'min-width-fix mt-2'}>
                <span className={'d-inline-block mb-2'}>
                    {EXT_VSS_TEXT.SECTIONS.VULNERABILITY_OVERVIEW}
                </span>
                <div className={'row mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        <span className={'d-inline-block mb-2'}>
                            {EXT_VSS_TEXT.SECTIONS.ASSESSED_PORTS}
                        </span>
                        {PortsTable}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        <span className={'d-inline-block mb-2'}>
                            {EXT_VSS_TEXT.SECTIONS.ASSESSED_HOSTS}
                        </span>
                        {HostsTable}
                    </div>
                </div>
            </div>

            <div className={'row mb-2'}>
                <div className={'col-md-6 col-12'}>
                    <span className={'d-inline-block mb-2'}>
                        {EXT_VSS_TEXT.SECTIONS.ISSUES_PER_CATEGORY}
                    </span>
                    {IssuesPerCategory}
                </div>
                <div className={'col-md-6 col-12'}>
                    <span className={'d-inline-block mb-2'}>
                        {EXT_VSS_TEXT.SECTIONS.ISSUES_PER_IP}
                    </span>
                    {IssuesPerIP}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <span className={'d-inline-block mb-2'}>
                    {EXT_VSS_TEXT.SECTIONS.DISCOVERED_ISSUES}
                </span>
                {/* table stuff */}
                {ScanResultsTable}
            </div>

        </div>
    )
}

export default ExtVssModal

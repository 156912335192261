import {
    SortOption,
    ArrangeOption,
    ScrollIdResponse,
    Bucket
} from '@interfaces/dashboard/monitor'

import { TEXT as ROOT_TEXT } from '@constants/main/root'
import { ChartZoom } from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Dashboard call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when dashboard is undefined */
    UNDEFINED_ID: 'Empty dashboard ID detected.',
    /** when date is invalid when clicking a dashboard card */
    INVALID_DATE: 'Card has invalid date.',
    MODAL_UNAVAILABLE: 'Service type is unavailable',
    DETAILED_DASHBOARD_REFRESH_ALL: `Clicking this button will refresh the entire
    dashboard. Confirm that you want to do this.`,
    MISSING_CARD: `Card not found. Kindly go to your
    dashboard monitors and select a card that has a detailed dashboard.`,
    MISSING_KEYS_ON_CARD: `The found card is missing some properties. Caused
    by data manipulation on localStorage OR oversight from developer.`,
    INCORRECT_DETAILED_DASHBOARD_DATA: 'The parsed localStorage key is incorrect.',
    EVENT_NOT_FOUND: 'Event not found. Operation aborted.'
}

export const CHART_ZOOM: ChartZoom = {
    zoomLevel: 0,
    title: ROOT_TEXT.ZERO_ZOOM_LEVEL,
    bucket: undefined
}

export const SCROLL_ID_DATA: ScrollIdResponse = {
    response: '',
    totalRecords: 0,
    isComplete: false,
    scrollId: '',
    data: []
}

export const TEXT = {
    SEARCH: {
        HIDE_GREY: 'Hide Grey Cards',
        SORT: 'Sort',
        ARRANGE: 'Arrange',
        REFRESH_BUTTON: 'Refresh'
    },
    CARD: {
        DEVICEID: 'Device',
        LOCATION: 'Location',
        DETAILED_DASHBOARD: 'Detailed Dashboard'
    },
    REDIRECTS: {
        MDR_DETAILED: 'Go to MDR Detailed Dashboard',
        O365_DETAILED: 'Go to O365 Detailed Dashboard',
        AZURE_DETAILED: 'Go to Azure Detailed Dashboard'
    },
    DETAILED_DASHBOARD: {
        STATCARD: {
            DEVICE_ID: 'Device I.D.',
            IN_FACE: 'In Iface',
            LOCATION: 'Location'
        },
        CHART: {
            ZOOM_OUT: 'Zoom Out'
        },
        PRINT_REMINDERS: `The report will be strictly generated using the actively selected
        search parameters and filters. To assure that the report you are looking
        at is correct, kindly refetch the results.`
    },
    ESCALATE: {
        TITLE: 'Escalate Event',
        LABEL: 'Escalate',
        ID: 'DASHBOARD__ESCALATE'
    }
}

export const SORT_OPTIONS: SortOption[] = [
    {
        label: 'Title',
        value: 'title'
    },
    {
        label: 'Device ID',
        value: 'deviceid'
    },
    {
        label: 'Color Type',
        value: 'colorType'
    },
    {
        label: 'Location',
        value: 'location'
    }
]

export const ARRANGE_OPTIONS: ArrangeOption[] = [
    {
        label: 'Ascending',
        value: 'asc'
    },
    {
        label: 'Descending',
        value: 'desc'
    }
]

export const DATA_ID_EXCLUSIONS = ['payload',
    'payload_printable',
    'packet',
    'http_request_body',
    'http_response_body',
    'http_request_body_printable',
    'http_response_body_printable',
    'geoip',
    'id',
    'flow_id'
]

/** for each event type, there will be overall AND when you 'zoom in' */
export const OVERALL_COLUMNS: {label: string, value: keyof Bucket }[] = [
    {
        label: 'Timestamp',
        value: 'key_as_string'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

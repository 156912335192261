import {
    useAppSelector
} from '@app/hook'
import { MESSAGE as EVM_MESSAGE } from '@constants/dashboard/tac/evm'
import {
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING,
    DEFAULT_LEGEND_BOX_WIDTH
} from '@constants/main/root'
import {
    SeverityDoughnut,
    Severities
} from '@interfaces/dashboard/tac/evm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    SpinnerContainer,
    ErrorMessage
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    data: SeverityDoughnut | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const SeverityDoughnutChart = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const incidentCategoriesEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** evmediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        let graph: Chart<'doughnut', {threat: number}[], string>

        const severityData: SeverityDoughnut = data || {
            low: 0,
            medium: 0,
            high: 0,
            critical: 0
        }

        /** create classifications */
        const vulnerabilities: {
            label: string,
            threat: Severities,
            color: string
        }[] = [
            {
                label: 'Critical',
                threat: 'critical',
                color: stylesheet.style.colorPresets.red
            },
            {
                label: 'High',
                threat: 'high',
                color: stylesheet.style.colorPresets['amber-1']
            },
            {
                label: 'Medium',
                threat: 'medium',
                color: stylesheet.style.colorPresets['amber-2']
            },
            {
                label: 'Low',
                threat: 'low',
                color: stylesheet.style.colorPresets.yellow
            }
        ]

        const counts = _.map(vulnerabilities, ({ threat }) => {
            return {
                threat: severityData[threat]
            }
        })

        const labels = _.map(vulnerabilities, ({ label }) => label)

        const datasets: typeof graph.data.datasets = [{
            data: counts,
            // assign colors by severity
            backgroundColor: _.map(vulnerabilities, ({ color }) => {
                return color
            }),
            borderWidth: 0,
            parsing: {
                key: 'threat'
            }
        }]

        if (incidentCategoriesEl.current) {
            graph = new Chart(incidentCategoriesEl.current, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                boxWidth: DEFAULT_LEGEND_BOX_WIDTH,
                                color: stylesheet.mode.fontColor
                            },
                            align: 'start',
                            position: 'right'
                            /** BUGS found when updating chart elements inside
                             * event handlers for all plugins. They do not render
                             * properly even if the script is correct.
                             *
                             * Tested this vs using outside elements.
                             */
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                }
                            }
                        }
                    }
                }

            })

            /** changed from height to maxheight so this size is used in smaller devices */
            incidentCategoriesEl.current.style.height = CHART_HEIGHT.sm
            incidentCategoriesEl.current.style.maxHeight = CHART_HEIGHT.sm
        }

        return () => {
            graph && graph.destroy()
        }
    }, [data])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row my-2'}>
                    <canvas className={'col-auto'} ref={incidentCategoriesEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EVM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

SeverityDoughnutChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default SeverityDoughnutChart

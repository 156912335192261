import {
    INCIDENT_CATEGORIES,
    INCIDENT_CATEGORIES_HEADERS,
    MESSAGE as IMM_MESSAGE
} from '@constants/dashboard/tac/imm'
import {
    assignDelta,
    assignValue
} from '@constants/main/method'
import {
    IncidentCategoryCount,
    IncidentData
} from '@interfaces/dashboard/tac/imm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

interface ComponentProps {
    // current and previous data is required.
    current: IncidentData[] | undefined,
    previous: IncidentData[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IncidentCategoriesTable = ({
    current, previous, isLoading, isSuccess, error
} : ComponentProps) => {
    const DataTable = useMemo(() => {
        const totalCurrentCount: IncidentCategoryCount[] = _.map(INCIDENT_CATEGORIES, (str) => {
            return {
                key: str,
                value: _.filter(current, ({ category }) => {
                    return category === str
                }).length
            }
        })

        const totalPreviousCount: IncidentCategoryCount[] = _.map(INCIDENT_CATEGORIES, (str) => {
            return {
                key: str,
                value: _.filter(previous, ({ category }) => {
                    return category === str
                }).length
            }
        })

        // now we get the number of categories the same way we
        // did it with severities at the doughnut chart.

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(INCIDENT_CATEGORIES_HEADERS, ({ label }, index) => {
                                const key = [
                                    'incidentCategory-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(totalCurrentCount, (obj, index) => {
                            const key = [
                                'incidentCategory-td-', index
                            ].join('')

                            const previousCount: IncidentCategoryCount = _.find(
                                totalPreviousCount,
                                ({ key }) => {
                                    return key === obj.key
                                }
                            ) || {
                                key: 'Social Engineering',
                                value: 1
                            }

                            // calculate percentage.
                            const percentage = (
                                (obj.value - previousCount.value) / previousCount.value
                            ) * 100
                            const deltaStatus = assignDelta(percentage)

                            return (
                                <tr key={key}>
                                    <td>{obj.key}</td>
                                    <td className={'pe-4'}>{
                                        <div className={'row align-items-center'}>
                                            <span className={'col-6'}>
                                                {obj.value}
                                            </span>
                                            <span className={[
                                                'col-6 delta',
                                                deltaStatus
                                            ].join(' ')}>
                                                {assignValue(percentage)}
                                            </span>
                                        </div>
                                    }</td>
                                    <td>{previousCount.value}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        current,
        previous
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{IMM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IncidentCategoriesTable.propTypes = {
    current: PropTypes.array,
    previous: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IncidentCategoriesTable


import {
    useAppSelector
} from '@app/hook'
import {
    DEFAULT_CATEGORICAL_SCORE
} from '@constants/dashboard/soc/oss'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import { HssDetailsForm } from '@interfaces/dashboard/monitor'
import {
    CategoricalScore,
    ModalLineChart
} from '@interfaces/dashboard/soc/oss'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/oss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import {
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'
interface ComponentProps {
    controlCategory: HssDetailsForm['controlCategory'],
    lineChartData: ModalLineChart
}

const SpecificHSS = ({
    controlCategory, lineChartData
}: ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    const chartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(LineController, LineElement, Legend, PointElement,
            CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** what we need for the SpecificHSS.tsx is the LineDataPoint interface
     * we don't need a slice for this since we are passing this data
     * to the serviceTypeFormData
     */

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'line', { x: Date; y: number; }[], Date>

        const sections: (keyof Omit<CategoricalScore, 'maxScore' | 'totalScore'>)[] = [
            'Behavior', 'Config', 'Review'
        ]

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const datasets: typeof graph.data.datasets = controlCategory
                ? _.map(sections, (section, index) => {
                    const sectionScores = _.map(
                        _.uniqBy(lineChartData || [], 'timestamp'), lineChart => {
                            let obj = DEFAULT_CATEGORICAL_SCORE

                            switch (controlCategory) {
                                case 'Device':
                                    obj = lineChart.Device
                                    break
                                case 'Data':
                                    obj = lineChart.Data
                                    break
                                case 'Identity':
                                    obj = lineChart.Identity
                                    break
                                case 'Apps':
                                    obj = lineChart.Apps
                                    break
                                default: break
                            }

                            let value = 0
                            const currentScore = obj[section].totalScore
                            const maxScore = obj[section].maxScore

                            value = (((currentScore / maxScore) || 0) * 100)
                            return {
                                x: fromUnixTime(lineChart.timestamp),
                                y: value
                            }
                        })

                    return ({
                    /** include the current score here BUT it shouldn't be opening
                     * a new modal as it only has scores.
                     */
                        data: sectionScores,
                        label: section,
                        fill: false,
                        borderColor: CHART_COLORS[index],
                        backgroundColor: CHART_COLORS[index],
                        borderWidth: 1
                    })
                })
                : []

            graph = new Chart(chartEl.current, {
                type: 'line',
                data: {
                    labels: [
                        ranges.start,
                        ranges.end
                    ],
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: stylesheet.mode.fontColor
                            },
                            position: 'top',
                            align: 'center'
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            beginAtZero: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.0
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            // added this because the charts wouldn't stretch
            // in smaller responsive queries
            chartEl.current.style.width = '100vw'
            // setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        currentParams,
        controlCategory, lineChartData
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        return content
    }, undefined)

    return (
        <div className={'mb-3 mt-4 mt-lg-0'}>
            {DataContent}
        </div>
    )
}

export default SpecificHSS

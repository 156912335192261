
import SpecificHSS from '@features/dashboard/soc/oss/SpecificHSS'
import {
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    ActionCreatorWithPayload
} from '@reduxjs/toolkit'
import React from 'react'

const HSSDetails = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>
}) => {
    return (<div>
        <div className={'mb-3 mt-4 mt-lg-0'}>
            <SpecificHSS
                controlCategory={
                    modal.serviceTypeFormData?.oss?.hssDetails?.controlCategory || 'Data'
                }
                lineChartData={
                    modal.serviceTypeFormData?.oss?.hssDetails?.parentLineChartData || []
                }
            />
        </div>
    </div>
    )
}

export default HSSDetails

import { URL } from '@constants/main/root'
/** we are going to use the ext-vss interface because the type checks are the same. */
import {
    ModalRequest,
    ModalResponse
} from '@interfaces/dashboard/tac/evm'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const evmApi = createApi({
    reducerPath: 'evmApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** there should be 5 api calls. */
        getModal: builder
            .mutation<ModalResponse, ModalRequest & TokenAuth>({
                query: (body) => {
                    const formData: ModalRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/ext-vmm/modal',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetModalMutation
} = evmApi

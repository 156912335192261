import { MutationContext } from '@root/MutationProvider'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdManageSearch,
    MdRefresh
} from 'react-icons/md'
import { useIdleTimer } from 'react-idle-timer'
import { Modal } from 'react-responsive-modal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'

/** OTHER FILE IMPORTS */
import {
    useGetFiltersMutation
} from '@apis/watchdog/soc-data/filter-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    COMPARISON_OPERATORS,
    DATE_FORMAT_TIME,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    INTERVALS,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    ACTIONS,
    KEYS
} from '@constants/watchdog/soc-data/filter'
import MenuLinks from '@features/main/MenuLinks'
import FilterDelete from '@features/watchdog/soc-data/filter/FilterDelete'
import FilterUpdate from '@features/watchdog/soc-data/filter/FilterUpdate'
import FilterView from '@features/watchdog/soc-data/filter/FilterView'
import {
    ComparisonOperator
} from '@interfaces/main/root'
import {
    Filter,
    FilterKeys
} from '@interfaces/watchdog/soc-data/filter'
import { selectToken } from '@slices/main/token'
import {
    addFilter,
    addModal,
    closeModal,
    removeFilter,
    removeFilters,
    removeModal,
    selectDeviceid,
    selectModals,
    selectTableData,
    selectTabs,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setFiltered,
    setFilterKeys,
    setFilterValues,
    setIntervalObj,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    toggleFilterInterface
} from '@slices/watchdog/soc-data/filter'
import {
    Button,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime
} from 'date-fns'

import flatten from 'flat'

/** first component to attempt table pagination data */

const FilterMenu = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)
    const deviceid = useAppSelector(selectDeviceid)

    const tableData = useAppSelector(selectTableData)

    const [getFilters, getFiltersMutation] = useGetFiltersMutation()

    /**
     * MISSING FEATURES:
     * you have yet to finish DELETE AND UPDATE front-end.
     * for UPDATE, copy front end but pass in filter object as a prop.
     * instead of a filter id since there's no api call for it.
     * it is better for it to be a modal because, you can't restore
     * the data from the store if this was a redirect.
     *
     * you'd only use a redirect to another component in CRUD if the
     * parameter is an ID and only an ID.
     */

    /**
     * another request was to create an interval to refresh
     * the data but inherits idleTimer behavior. the idleTimer
     * would only be re-rendered if tableData.interval changes.
     */

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    /**
     * to further understand this, the callback uses the current value
     * of the store in that lifecycle. previous example was only data
     * from mount. any useEffect that calls this would always have
     * the same input.
     */
    const unsubscribeGetFilters = () => {
        const unsubscribeMutation = getFilters({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetFilters()
        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getFilters({
                        authToken: newToken,
                        deviceid: deviceid
                    })
                }
            }
        }
        call()
        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    /**
     * optimizing table data the following ways:
     * debounce filtering and pagination data. - check.
     */

    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: Filter,
            property: keyof Filter
        ) => {
            let cellContent: Filter[keyof Filter] = ''
            let rawValue: Filter[keyof Filter] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'activationTime':
                    cellContent = format(fromUnixTime(dataObject.activationTime), DATE_FORMAT_TIME)
                    break
                default:
                    rawValue = cellContent = dataObject[property]
                    break
            }

            const zoomAction = (not: boolean) => {
                if (property === 'activationTime') {
                    dispatch(addFilter({
                        not: not,
                        sort: property,
                        value: [dataObject.activationTime.toString()]
                    }))
                } else {
                    dispatch(addFilter({
                        not: not,
                        sort: property,
                        value: [String(rawValue)]
                    }))
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, rowIndex) => {
                const actionButtons = _.map(ACTIONS, (action, cellIndex) => {
                    const onClick = () => {
                        if (
                            action.value === 'FILTER_UPDATE' ||
                            action.value === 'FILTER_VIEW' ||
                            action.value === 'FILTER_DELETE'
                        ) {
                            /* when clicked, set object and the update
                             * modal will be shown
                             */
                            dispatch(addModal({
                                id: uniqueString(),
                                open: true,
                                filterObj: dataObject,
                                operation: action.value,
                                isBorderWide: false
                            }))
                        }
                    }

                    return (
                        <td key={[
                            'cell-',
                            rowIndex,
                            '-',
                            (cellIndex + tableData.columns.length)
                        ].join('')}>
                            <Button
                                onClick={onClick}
                                size={'sm'}
                                mode={action.mode}>
                                {action.label}
                            </Button>
                        </td>
                    )
                })

                return (
                    <tr key={'filter-row-' + rowIndex} onClick={(e) => {
                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            filterObj: dataObject,
                            operation: 'FILTER_VIEW',
                            isBorderWide: false
                        }))
                    }}>
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'filter-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}>
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                        {actionButtons}
                    </tr>
                )
            })
        )

        return (
            getFiltersMutation.isLoading
                ? LoadingContent
                : getFiltersMutation.isSuccess
                    ? (
                        tableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(getFiltersMutation.error)
        )
    }, [tableData, getFiltersMutation])

    /**
     * when mounted, perform fetchData api
     * but you also need to do this when you want to manually refresh.
     * use a property in a store for this.
     * */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (tableData.refetch) {
            return fetchData()
        }
    }, [tableData.refetch])

    useEffect(() => {
        /**
         * in case that the refetch value is true meaning that
         * you did a refresh, set it to false now. It should be
         * safe to abort the promise as the call finished.
         *
         * and just to make sure refetch is setting when it has to,
         * we are also going to check if refetch is truthy, even
         * when it's not added as a dependency.
         * */
        if (getFiltersMutation.isSuccess && tableData.refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getFiltersMutation.isSuccess
    ])

    /** please refer to smartSearch in method.ts to know why use-debounce
     * works in react compared to basic debounce packages.
     */

    /**
     * thanks to the react inspector's time travelling feature for redux store
     * we can assure that the setSearch dispatch will be performed after a debounce
     */

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        const filteredData = smartSearch(
            getFiltersMutation.data?.records || [],
            tableData.filters,
            tableData.search
        ) as Filter[]
        const orderedData = smartOrder(filteredData, tableData.columns) as Filter[]

        /**
         * set the filtered data to tableData.filtered.
         * any sort or pagination should be done in a separate useEffect
         * */
        dispatch(setFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    // const paginateData = useDebouncedCallback(() => {
    const paginateData = () => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as Filter[]

        dispatch(setPaginated(result))
    // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        setData()
    }, [
        /** refreshed data from fetch invocation */
        getFiltersMutation.data,
        /** when tableData.filters updates */
        tableData.filters,
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    useEffect(() => {
        if (getFiltersMutation.error) {
            console.error(getFiltersMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getFiltersMutation.error])

    useEffect(() => {
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    /** testing value of modals array with handlers. */
    // useEffect(() => {
    //     console.log('active modals: ', modals)
    // }, [modals])

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const data = getFiltersMutation.data?.records || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = data[0]
            ? _.filter(_.keys(
                flatten(data[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : KEYS.FILTER

        dispatch(
            setFilterKeys(result)
        )
    }, [
        getFiltersMutation.data
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as FilterKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    return (
        <div>
            <MenuLinks tabs={tabs} />
            {/* this row contains an interval selection */}
            <div className={'justify-content-end row mt-3'}>
                <div className={'col-auto'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <small className={'col col-md-auto'}>{TEXT.TABLE.REFRESH}</small>
                        <Select
                            className={'col-auto px-0'}
                            options={INTERVALS}
                            value={_.find(
                                INTERVALS,
                                (e) => e.value === tableData.interval.value
                            )}
                            onChange={(e) => {
                                if (e) {
                                    dispatch(setIntervalObj(e))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles,
                                ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                            }}
                            theme={reactSelect.theme}
                        />
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                            <span
                                className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                                onClick={() => {
                                    dispatch(setRefetch(true))
                                    refreshTimer.reset()
                                }}>
                                <MdRefresh />
                            </span>
                        </Tippy>
                    </div>
                </div>
            </div>
            {/* in this row, show the search bar on the left
            and the pagination on the right */}
            <div className={'align-items-center justify-content-between row mt-3'}>
                <div className={'col-12 col-md px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                            <FaSearch />
                        </label>
                        <input
                            className={'col'}
                            type={'text'}
                            id={TEXT.SEARCH.SEARCH.ID}
                            defaultValue={tableData.search}
                            onChange={(e) => {
                                setSearchDebounce(e.target.value)
                            }}
                            placeholder={'Search'}
                        />
                        {/* when this is clicked, a popup window will show up
                        it was previously a tippy but now it's an interface below
                         to utilize space. */}
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                            <Text size={'xl'}
                                className={'d-inline-block col-auto pe-0'}
                                onClick={() => {
                                    dispatch(
                                        toggleFilterInterface(!tableData.filterInterface.show)
                                    )
                                }}
                            >
                                <MdManageSearch />
                            </Text>
                        </Tippy>
                    </SearchRow>
                </div>

                {/* this is the dropdown for page count */}
                <div className={'col-12 col-md-auto'}>
                    <div className={'align-items-center justify-content-between row'}>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                <Select
                                    className={'col-auto'}
                                    options={ROW_COUNT_SELECTION}
                                    value={_.find(
                                        ROW_COUNT_SELECTION,
                                        (e) => e.value === tableData.count
                                    )}
                                    onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                        if (e) {
                                            dispatch(setCount(e.value))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.SMALL
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                            <Pagination

                                current={tableData.page}
                                total={tableData.filtered.length}
                                pageSize={tableData.count}
                                onChange={(current) => {
                                    dispatch(setPage(current))
                                }}
                                locale={EN_US}
                            />
                        </div>

                    </div>

                </div>
            </div>
            {/* row to show when kubernetes search toggles */}
            {
                tableData.filterInterface.show
                    ? (
                        <div className={'mt-2'}>
                            {FilterInterface}
                        </div>
                    )
                    : ''
            }
            {/*
                this row contains a filters where you can include or exclude. can also clear all
                since this will become a component shared, pass the removeFilter AND
                removeFilters actions as props.
            */}
            <div className={'row mt-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(tableData.filters, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                        <Text size={'xs'} className={'ps-2'} onClick={() => {
                                            dispatch(removeFilter(filter))
                                        }}>
                                            <FaTimes />
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
                <span
                    className={[
                        'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                        tableData.filters.length ? 'd-block' : 'd-none'
                    ].join(' ')}
                    onClick={() => {
                        dispatch(removeFilters())
                    }}
                >
                    {/* clear all filters button. baseline, not center */}
                    <FaTimes />
                </span>
            </div>
            {/*
                then populate tbody using the paginated result. attempting to
                iterate using the columns data. failed because typescript
                discourages implicit declaration, including calling property names
                of objects using variables INSTEAD of a coding in the exact name.
                it would be best to perform explicit declaration
                as the opposite defeats the purpose of using Typescript.

                but if there is a fair justification to use this, just use 'any' on the
                paginated object as long as you avoid expected crashes by adding conditions.

                if there is no padding at the table cells, the table would not wrap from inside
                a column.

            */}
            <div className={'justify-content-center row mt-3'}>
                <div className={'col'}>
                    <Table
                        className={'table-striped table-hover'}
                        height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                        bgIndex={1}
                    >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {TableHead}
                                    {/* action section is created here. */}
                                    {
                                        ACTIONS.length
                                            ? (<th colSpan={ACTIONS.length}>
                                                <small>{TEXT.TABLE.ACTIONS}</small>
                                            </th>)
                                            : <th className={'d-none'}></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TableBody}
                            </tbody>
                        </table>
                    </Table>
                </div>
            </div>

            {
                // using ids to select object to fetch data or close modals as one does fit.
                _.map(modals, (modal, index) => {
                    const key = [
                        'modal-', modal.operation, '-', index
                    ].join('')

                    let component: ReactElement<any, any> = <></>
                    let classNames = {
                        modal: ''
                    }

                    if (
                        modal.operation === 'FILTER_VIEW' &&
                        modal.filterObj
                    ) {
                        classNames = {
                            modal: ['sm', 'primary'].join(' ')
                        }
                        component = <FilterView
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'FILTER_UPDATE' &&
                        modal.filterObj
                    ) {
                        classNames = {
                            modal: ['sm', 'primary'].join(' ')
                        }
                        component = <FilterUpdate
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'FILTER_DELETE' &&
                        modal.filterObj
                    ) {
                        classNames = {
                            modal: ['xs', 'danger'].join(' ')
                        }
                        component = <FilterDelete
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else {
                        return ''
                    }

                    return <Modal
                        key={key}
                        classNames={classNames}
                        open={modal.open}
                        center focusTrapped={false}
                        onAnimationEnd={() => {
                            if (!modal.open) {
                                dispatch(removeModal(modal))
                            }
                        }}
                        onClose={() => {
                            dispatch(closeModal(modal))
                        }}
                        closeIcon={<AiOutlineClose />}>
                        {component}
                    </Modal>
                })
            }

        </div>
    )
}
export default FilterMenu

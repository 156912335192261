import {
    SOC_MONITOR_ROUTES,
    GRC_MONITOR_ROUTES,
    TACTICAL_MONITOR_ROUTES
} from '@constants/main/routes'
import SOCMonitors from '@features/dashboard/SOCMonitors'
import GRCMonitors from '@features/dashboard/GRCMonitors'
import TACMonitors from '@features/dashboard/TACMonitors'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: SOC_MONITOR_ROUTES.MAIN.link,
        component: SOCMonitors
    },
    {
        exact: true,
        path: GRC_MONITOR_ROUTES.MAIN.link,
        component: GRCMonitors
    },
    {
        exact: true,
        path: TACTICAL_MONITOR_ROUTES.MAIN.link,
        component: TACMonitors
    }
]

export default Routes

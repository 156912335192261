
import { useDoPasswordResetMutation } from '@apis/main/login-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT,
    RESET_PASSWORD_FORMIK_INITIAL_VALUES,
    RESET_PASSWORD_VALIDATION_SCHEMA
} from '@constants/main/login'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { LOGIN_ROUTES } from '@constants/main/routes'
import { selectMode } from '@slices/main/settings'
import {
    FormStyledComponents as Form,
    LoginStyledComponents as Login,
    SpinnerContainer
} from '@styles/components'
import { push } from 'connected-react-router'
import React, {
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const ForcePasswordPage = () => {
    const dispatch = useAppDispatch()

    const mode = useAppSelector(selectMode)

    const [doPasswordReset, doPasswordResetMutation] = useDoPasswordResetMutation()

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: RESET_PASSWORD_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: RESET_PASSWORD_VALIDATION_SCHEMA,
        onSubmit: (values) => {
            doPasswordReset({
                email: values.email
            })
        }
    })

    const Logo = useMemo(() => {
        return (
            <Login.Logo className={'col-auto'} src={mode === 'dark'
                ? '/media/dark-theme.svg'
                : '/media/light-theme.svg'}
            alt={'Bluedog Logo'} role={'button'} />
        )
    }, [mode])

    const EmailInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={TEXT.RESET_PASSWORD.FORM.EMAIL.ID}>
                    {TEXT.RESET_PASSWORD.FORM.EMAIL.LABEL}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.email)}
                    type={'text'}
                    name={'email'}
                    id={TEXT.RESET_PASSWORD.FORM.EMAIL.ID}
                    onChange={handleChange}
                    value={values.email}
                />
                <Form.Feedback errors={Boolean(errors.email)} >{
                    errors.email ? errors.email : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.email, errors.email])

    const SubmitButton = useMemo(() => {
        const buttonContent = doPasswordResetMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{TEXT.RESET_PASSWORD.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : TEXT.RESET_PASSWORD.FORM.SUBMIT_BUTTON

        return (
            <Form.Group className={'text-center'}>
                <Login.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={doPasswordResetMutation.isLoading}
                >{buttonContent}</Login.Button>
            </Form.Group>
        )
    }, [doPasswordResetMutation.isLoading])

    useEffect(() => {
        if (doPasswordResetMutation.data) {
            const data = doPasswordResetMutation.data
            if (data.status === 'OK') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(push(LOGIN_ROUTES.LOGOUT.link))
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [doPasswordResetMutation.data])

    useEffect(() => {
        if (doPasswordResetMutation.error) {
            console.error(doPasswordResetMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doPasswordResetMutation.error])

    /**
     * render an MFA interface. you need a google authenticator
     * app and you can authenticate once more. Interface is inspired
     * by login page
    */
    return (
        <div className={'container-fluid'}>
            <div className={'align-items-center flex-column h-100 justify-content-center row'}>
                <div className={'col-auto'}>
                    {Logo}
                </div>
                <Login.Container className={'mt-3'}>
                    <h3 className={'text-center fw-bold'}>{TEXT.RESET_PASSWORD.TITLE}</h3>
                    <Form.Main className={'p-0'} onSubmit={handleSubmit}>
                        {EmailInput}
                        {SubmitButton}
                    </Form.Main>
                </Login.Container>
            </div>
        </div>
    )
}
export default ForcePasswordPage

import { URL } from '@constants/main/root'
import {
    GetModalDetailsRequest,
    GetModalDetailsResponse,
    GetModalRequest,
    IssueCountResponse,
    IssueTableResponse,
    ModalHeaderResponse,
    IssueAlertedResponse,
    IssueHistoryResponse,
    GetDataIdResponse,
    GetDataIdRequest,
    EventType as MainEventType
} from '@interfaces/dashboard/soc/mdr/main'
import {
    GetDetailedDashboardRequest,
    RequestEventType as MdrMainEventType,
    MdrActivityResponse,
    MdrEventsResponse,
    MdrLocationsResponse,
    MdrDhcpResponse,
    MdrDnsResponse,
    MdrFileinfoResponse,
    MdrHttpResponse,
    MdrIkev2Response,
    MdrKrb5Response,
    MdrSmbResponse,
    MdrSnmpResponse,
    MdrSshResponse,
    MdrTlsResponse,
    ScrollIdRequest
} from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'
import { TokenAuth } from '@interfaces/main/root'
import {
    GetFlowIDRequest,
    GetFlowIDResponse
} from '@interfaces/watchdog/soc-data/flow'
import {
    createApi,
    fetchBaseQuery,
    FetchArgs
} from '@reduxjs/toolkit/query/react'
import { Base64 } from 'js-base64'
import { ScrollIdResponse } from '@interfaces/dashboard/monitor'

const getMdrModalData: (
    body:Omit<GetModalRequest, 'event_type'>,
    eventType:MainEventType
) => GetModalRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        q: body.q,
        in_face: body.in_face,
        time_from: body.time_from,
        time_to: body.time_to
    }
}

const createMdrQuery: (
    formData: GetModalRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/mdr/modal',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

const getMdrDetailedModalData: (
    body:Omit<GetDetailedDashboardRequest, 'event_type'>,
    eventType:MdrMainEventType
) => GetDetailedDashboardRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        in_face: body.in_face,
        time_from: body.time_from,
        time_to: body.time_to,
        mn: body.mn,
        q: body.q,
        s: body.s
    }
}

const createMdrDetailedQuery: (
    formData: GetDetailedDashboardRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/mdr/detailDashboard',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

export const mdrApi = createApi({
    reducerPath: 'mdrApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getModalHeader: builder
            .mutation<ModalHeaderResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getMdrModalData(body, 'modal.Header')
                    return createMdrQuery(formData, body.authToken)
                }
            }),
        getIssueHistory: builder
            .mutation<IssueHistoryResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getMdrModalData(body, 'issue.History')
                    return createMdrQuery(formData, body.authToken)
                }
            }),
        getIssueCount: builder
            .mutation<IssueCountResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getMdrModalData(body, 'issue.Count')
                    return createMdrQuery(formData, body.authToken)
                }
            }),
        getIssueAlerted: builder
            .mutation<IssueAlertedResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getMdrModalData(body, 'issues.Alerted')
                    return createMdrQuery(formData, body.authToken)
                }
            }),
        getIssueTable: builder
            .mutation<IssueTableResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getMdrModalData(body, 'issue.Table')
                    return createMdrQuery(formData, body.authToken)
                }
            }),
        getModalDetails: builder
            .mutation<GetModalDetailsResponse, GetModalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetModalDetailsRequest = {
                        deviceid: body.deviceid,
                        in_face: body.in_face,
                        signature: body.signature,
                        event_type: body.event_type,
                        src_ip: body.src_ip,
                        lat: body.lat,
                        lon: body.lon,
                        q: Base64.encode(body.q),
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/mdr/modalDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getDataId: builder
            .mutation<GetDataIdResponse, GetDataIdRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetDataIdRequest = {
                        deviceid: body.deviceid,
                        id: body.id,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/mdr/dataId',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getFlowId: builder
            .mutation<GetFlowIDResponse, GetFlowIDRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetFlowIDRequest = {
                        deviceid: body.deviceid,
                        flowid: body.flowid,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/mdr/flowId',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getMdrActivity: builder
            .mutation<MdrActivityResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.Activity')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrEvents: builder
            .mutation<MdrEventsResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.Events')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrLocations: builder
            .mutation<MdrLocationsResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.Locations')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrDhcp: builder
            .mutation<MdrDhcpResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.dhcp')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrDns: builder
            .mutation<MdrDnsResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.dns')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrFileInfo: builder
            .mutation<MdrFileinfoResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.fileinfo')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrHttp: builder
            .mutation<MdrHttpResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.http')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrIkev2: builder
            .mutation<MdrIkev2Response, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.ikev2')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrKrb5: builder
            .mutation<MdrKrb5Response, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.krb5')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrSmb: builder
            .mutation<MdrSmbResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.smb')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrSnmp: builder
            .mutation<MdrSnmpResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.snmp')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrSsh: builder
            .mutation<MdrSshResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.ssh')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getMdrTls: builder
            .mutation<MdrTlsResponse, Omit<GetDetailedDashboardRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardRequest =
                        getMdrDetailedModalData(body, 'MDR.tls')
                    return createMdrDetailedQuery(formData, body.authToken)
                }
            }),
        getScrollId: builder
            .mutation<ScrollIdResponse, Omit<ScrollIdRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: ScrollIdRequest = {
                        deviceid: body.deviceid,
                        event_type: 'MDR.Locations',
                        scrollId: body.scrollId
                    }

                    return {
                        url: 'api/getData/useScrollId',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetModalHeaderMutation,
    useGetIssueAlertedMutation,
    useGetIssueCountMutation,
    useGetIssueHistoryMutation,
    useGetIssueTableMutation,
    useGetModalDetailsMutation,
    useGetDataIdMutation,
    useGetFlowIdMutation,
    useGetMdrActivityMutation,
    useGetMdrDhcpMutation,
    useGetMdrDnsMutation,
    useGetMdrEventsMutation,
    useGetMdrFileInfoMutation,
    useGetMdrHttpMutation,
    useGetMdrIkev2Mutation,
    useGetMdrKrb5Mutation,
    useGetMdrLocationsMutation,
    useGetMdrSmbMutation,
    useGetMdrSnmpMutation,
    useGetMdrSshMutation,
    useGetMdrTlsMutation,
    useGetScrollIdMutation
} = mdrApi

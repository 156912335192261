import { URL } from '@constants/main/root'
import {
    DoToggleMFARequest,
    DoToggleMFAResponse,
    DoValidateMFAInitialRequest,
    DoValidateMFAInitialResponse,
    GetProfileRequest,
    GetProfileResponse,
    ToggleThemeRequest,
    ToggleThemeResponse
} from '@interfaces/main/profile'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getProfile: builder.mutation<GetProfileResponse, GetProfileRequest & TokenAuth>({
            query: (body) => {
                const formData: GetProfileRequest = {
                    app: body.app
                }

                return {
                    url: 'be/getProfile',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        doToggleMFA: builder.mutation<DoToggleMFAResponse, DoToggleMFARequest & TokenAuth>({
            query: (body) => {
                const formData: DoToggleMFARequest = {
                    toggle: body.toggle,
                    app: body.app
                }
                return {
                    url: 'be/doToggleMFA',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        doValidateMFAInitial: builder.mutation<
            DoValidateMFAInitialResponse,
            DoValidateMFAInitialRequest & TokenAuth
        >({
            query: (body) => {
                const formData: DoValidateMFAInitialRequest = {
                    token: Number(body.token),
                    app: body.app
                }

                return {
                    url: 'be/doValidateMFAInitial',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),
        toggleTheme: builder.mutation<ToggleThemeResponse, ToggleThemeRequest & TokenAuth>({
            query: (body) => {
                const formData: ToggleThemeRequest = {
                    theme: body.theme
                }
                return {
                    url: 'api/toggleTheme',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        })
    })
})

export const {
    useGetProfileMutation,
    useDoToggleMFAMutation,
    useDoValidateMFAInitialMutation,
    useToggleThemeMutation
} = profileApi

import { PROFILE_ROUTES } from '@constants/main/routes'
import MFA from '@features/main/profile/MFA'
import Password from '@features/main/profile/Password'
import Settings from '@features/main/profile/Settings'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: PROFILE_ROUTES.PASSWORD.link,
        component: Password
    },
    {
        exact: true,
        path: PROFILE_ROUTES.MFA.link,
        component: MFA
    },
    {
        exact: true,
        path: PROFILE_ROUTES.SETTINGS.link,
        component: Settings
    }
]

export default Routes

import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    ASVSCat,
    ASVSCats,
    CleanResultColumn,
    CWEData,
    IssueNameData,
    SeverityConfidence,
    SeverityConfidenceData,
    SimilarHostColumn,
    CleanResultKeys
} from '@interfaces/dashboard/soc/wss'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

/** wss will also use this. */

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 30 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    // FAULTY_CALL: 'EXT-VSS call returned faulty.',
    FAULTY_CALL: 'WSS call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MODAL: 'Fetching modal data...',
        ISSUE_DETAILS: 'Fetching issue details data...',
        SIMILAR_HOSTS: 'Fetching similar hosts data...',
        ISSUES_OVERTIME: 'Fetching issues over time data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        GROUP_ISSUES: {
            LABEL: 'Group Issues',
            ID: 'PRINT_OPTIONS__GROUP_ISSUES_ONLY'
        },
        ISSUE_DETAILS: {
            LABEL: 'Issue Details',
            ID: 'PRINT_OPTIONS__ISSUE_DETAILS_ONLY'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        SEVERITY_CONFIDENCE_TITLE: 'Severity',
        SEVERITY_INDEX: 'Severity Index',
        EXECUTIVE_SUMMARY: 'Executive Summary',
        ISSUES_CATEGORIES: 'Issues Categories',
        OWASP_ASVS_CLASSIFICATION: 'OWASP ASVS Classification',
        OWASP_ASVS_RISK_CLASSIFICATION: 'OWASP ASVS Risk Classification',
        ISSUES_OVERVIEW: 'Issues Overview',
        WEB_SECURITY_DESCRIPTION: 'Web Security Description',
        DESCRIPTION: 'Issue Details',
        ISSUES_OVERTIME: 'Issues Over Time',
        SIMILAR_HOSTS: 'Other Hosts with Same Issue',
        WSS_DESCRIPTION: {
            URL: 'URL',
            PATH: 'Path',
            SEVERITY: 'Severity',
            CONFIDENCE: 'Confidence',
            DESCRIPTION: 'Description',
            REMEDIATION: 'Remediation',
            CLASSIFICATION: 'Classification',
            REFERENCE: 'Reference'
        }
    }
}
export const SEVERITY_CONFIDENCE_COLUMNS: {label:string, value:keyof SeverityConfidence}[] = [
    {
        label: 'Tentative',
        value: 'tentative'
    },
    {
        label: 'Firm',
        value: 'firm'
    },
    {
        label: 'Certain',
        value: 'certain'
    }
]

export const SEVERITY_CONFIDENCE_ROWS: {label:string, value:keyof SeverityConfidenceData}[] = [
    {
        label: 'High Issues',
        value: 'high'
    }, {
        label: 'Medium Issues',
        value: 'medium'
    }, {
        label: 'Low Issues',
        value: 'low'
    }, {
        label: 'Info Issues',
        value: 'info'
    }
]

export const DEFAULT_SEVERITY_CONFIDENCE: SeverityConfidence = {
    certain: 0,
    firm: 0,
    tentative: 0
}

export const DEFAULT_SEVERITY_CONFIDENCE_DATA: SeverityConfidenceData = {
    high: _.cloneDeep(DEFAULT_SEVERITY_CONFIDENCE),
    medium: _.cloneDeep(DEFAULT_SEVERITY_CONFIDENCE),
    low: _.cloneDeep(DEFAULT_SEVERITY_CONFIDENCE),
    info: _.cloneDeep(DEFAULT_SEVERITY_CONFIDENCE)
}

export const ISSUE_CATEGORY_COLUMNS: {label:string, value:keyof CWEData}[] = [
    {
        label: 'Issue Name',
        value: 'name'
    }, {
        label: 'Count',
        value: 'count'
    }
]

export const DEFAULT_ASVS_CAT: ASVSCat = {
    info: 0,
    low: 0,
    medium: 0,
    high: 0,
    value: 0
}
export const DEFAULT_ASVS_CATS: ASVSCats = {
    'V9 - Communications': DEFAULT_ASVS_CAT,
    'V1 - Architecture': DEFAULT_ASVS_CAT,
    'V11 - BusLogic': DEFAULT_ASVS_CAT,
    'V13 - API': DEFAULT_ASVS_CAT,
    'V3 - Session': DEFAULT_ASVS_CAT,
    'V7 - Error': DEFAULT_ASVS_CAT,
    'V4 - Access': DEFAULT_ASVS_CAT,
    'V6 - Cryptography': DEFAULT_ASVS_CAT,
    'V10 - Malicious': DEFAULT_ASVS_CAT,
    'V12 - Files': DEFAULT_ASVS_CAT,
    'V14 - Config': DEFAULT_ASVS_CAT,
    'V2 - Authentication': DEFAULT_ASVS_CAT,
    'V5 - Validation': DEFAULT_ASVS_CAT,
    'V8 - Data': DEFAULT_ASVS_CAT
}

export const ASVS_CATS_COLUMNS: {label:string, value:string}[] = [
    {
        label: 'Classification',
        value: 'name'
    }, {
        label: 'Info',
        value: 'info'
    }, {
        label: 'Low',
        value: 'low'
    }, {
        label: 'Medium',
        value: 'medium'
    }, {
        label: 'High',
        value: 'high'
    }
]

export const ISSUE_OVERVIEW_COLUMNS: {label:string, value:keyof IssueNameData}[] = [
    {
        label: 'Issue Name',
        value: 'issueName'
    }, {
        label: 'Count',
        value: 'count'
    }
]

export const CLEAN_RESULT_COLUMNS: CleanResultColumn[] = (() => {
    const columnNames: Omit<CleanResultColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Issue Name',
            value: 'issueName'
        },
        {
            label: 'Severity',
            value: 'severity'
        },
        {
            label: 'Confidence',
            value: 'confidence'
        },
        {
            label: 'Vector URL',
            value: 'vectorUrl'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: CleanResultColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

/** to display a table of similar hosts but no code snippet for sorting
 * or searching
 */

export const SIMILAR_HOSTS_COLUMNS: SimilarHostColumn[] = [
    {
        label: 'Issue Name',
        value: 'issueName'
    },
    {
        label: 'Severity',
        value: 'severity'
    },
    {
        label: 'Confidence',
        value: 'confidence'
    },
    {
        label: 'Vector URL',
        value: 'vectorUrl'
    }
]

export const CLEAN_RESULT_KEYS: CleanResultKeys[] = [
    'CWE', 'confidence', 'issueName', 'severity', 'type_index', 'vectorUrl'
]

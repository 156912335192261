
import {
    useAppSelector
} from '@app/hook'
import {
    DETAILED_LINE_CHART_COLUMNS,
    MESSAGE as PCIDSS_MESSAGE
} from '@constants/dashboard/grc/pcidss'
import {
    hideChartTooltip,
    showChartTooltip
} from '@constants/main/method'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    CalculatedChapterData,
    ChapterIdentifierData,
    DetailedLineChart
} from '@interfaces/dashboard/grc/pcidss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams,
    selectFixedCollapsibles
} from '@slices/dashboard/grc/pcidss/details'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer,
    Table,
    TableColorCell
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import {
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'

interface ComponentProps {
    selectedChapter: CalculatedChapterData | undefined,
    nistDatasets: ChapterIdentifierData[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const DetailedCoverageLineChart = ({
    selectedChapter, nistDatasets, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    const [
        chartInstance,
        setChartInstance
    ] = useState<Chart<'line', { x: Date; y: number; }[], Date>>()

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(LineController, LineElement, Legend, PointElement,
            CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)
        let graph: Chart<'line', { x: Date; y: number; }[], Date>

        // create datasets by paragraphName.

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const datasets: typeof graph.data.datasets = _.map(
                _.cloneDeep(selectedChapter?.chapterData), (chapterDataObj, index) => {
                    _.forEach(chapterDataObj.paragraphData, (alineaObj) => {
                        alineaObj.pages = _.map(alineaObj.translationtoNist,
                            ({ subcat, page }) => {
                                return subcat || page || ''
                            })
                    })

                    const data = _.map(
                        nistDatasets, ({ lastupdate, nistData }) => {
                            _.forEach(chapterDataObj.paragraphData, (alineaObj) => {
                                const filtered = _.flattenDeep(
                                    _.map(nistData, ({ data }) => {
                                        return _.filter(data, ({ identifier }) => {
                                            return _.includes(alineaObj.pages, identifier)
                                        })
                                    })
                                )

                                alineaObj.alineaScore = filtered.length
                                    ? _.reduce(
                                        filtered,
                                        (a, b) => {
                                            a += b.score
                                            return a
                                        }
                                        , 0
                                    ) / filtered.length
                                    : 0
                            })

                            // get all alineaScores from each paragraphData array and flat after.
                            const groupedAlineaScores = _.flattenDeep(
                                _.map(chapterDataObj.paragraphData,
                                    ({ alineaScore }) => {
                                        return alineaScore
                                    }
                                )
                            )

                            const coverageScore = groupedAlineaScores.length
                                ? _.filter(groupedAlineaScores, (a) => {
                                    return a > 0
                                }).length / (groupedAlineaScores.length)
                                : 0

                            return {
                                x: fromUnixTime(lastupdate),
                                y: coverageScore * 100
                            }
                        }
                    )

                    // assign a color.
                    return ({
                        data: data,
                        label: chapterDataObj.paragraphName,
                        fill: false,
                        borderColor: CHART_COLORS[index],
                        backgroundColor: CHART_COLORS[index],
                        borderWidth: 1
                    })
                }
            )

            graph = new Chart(chartEl.current, {
                type: 'line',
                data: {
                    labels: [
                        ranges.start,
                        ranges.end
                    ],
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: stylesheet.mode.fontColor
                            },
                            position: 'top',
                            align: 'center'
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            beginAtZero: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.0
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        selectedChapter,
        nistDatasets
    ])

    const DataTable = useMemo(() => {
        const datasets: DetailedLineChart[] = _.map(
            _.cloneDeep(selectedChapter?.chapterData), (chapterDataObj, index) => {
                _.forEach(chapterDataObj.paragraphData, (alineaObj) => {
                    alineaObj.pages = _.map(alineaObj.translationtoNist,
                        ({ subcat, page }) => {
                            return subcat || page || ''
                        })
                })

                const result = _.map(
                    nistDatasets, ({ lastupdate, nistData }) => {
                        _.forEach(chapterDataObj.paragraphData, (alineaObj) => {
                            const filtered = _.flattenDeep(
                                _.map(nistData, ({ data }) => {
                                    return _.filter(data, ({ identifier }) => {
                                        return _.includes(alineaObj.pages, identifier)
                                    })
                                })
                            )

                            alineaObj.alineaScore = filtered.length
                                ? _.reduce(
                                    filtered,
                                    (a, b) => {
                                        a += b.score
                                        return a
                                    }
                                    , 0
                                ) / filtered.length
                                : 0
                        })

                        // get all alineaScores from each paragraphData array and flat after.
                        const groupedAlineaScores = _.flattenDeep(
                            _.map(chapterDataObj.paragraphData,
                                ({ alineaScore }) => {
                                    return alineaScore
                                }
                            )
                        )

                        const coverageScore = groupedAlineaScores.length
                            ? _.filter(groupedAlineaScores, (a) => {
                                return a > 0
                            }).length / (groupedAlineaScores.length)
                            : 0

                        return coverageScore * 100
                    }
                )

                // assign a color.
                return ({
                    data: result.length
                        ? _.reduce(result, (total, num) => {
                            total += num
                            return total
                        }, 0) / result.length
                        : 0,
                    label: chapterDataObj.paragraphName
                })
            }
        )

        const cellBody = (
            dataObject: DetailedLineChart,
            property: keyof DetailedLineChart
        ) => {
            let cellContent: DetailedLineChart[keyof DetailedLineChart] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'data':
                    cellContent = dataObject.data.toFixed(2)
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return (
                <div>
                    {cellContent}
                </div>
            )
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {/* table header cell for colors. */}
                        <th></th>
                        {
                            _.map(DETAILED_LINE_CHART_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'detailedCoverage-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(datasets, (dataObject, rowIndex, arr) => {
                            return (
                                <tr
                                    key={'detailedCoverage-tr-' + rowIndex}
                                    onMouseOver={() => {
                                        showChartTooltip(
                                            /** selecting specific dataset */
                                            rowIndex,
                                            /** since all datasets have the same length,
                                             * we want to select the middle point.
                                            */
                                            (nistDatasets?.length || 0) / 2,
                                            chartInstance
                                        )
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(chartInstance)
                                    }}
                                >
                                    <TableColorCell color={CHART_COLORS[rowIndex]} key={[
                                        'tableColorCell-', rowIndex
                                    ].join('')} />
                                    {
                                        _.map(DETAILED_LINE_CHART_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td className={'text-wrap'} key={[
                                                    'detailedCoverage-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            datasets.length
                ? content
                : EmptyCellContent
        )
    }, [
        selectedChapter,
        chartInstance
    ])
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.coverageScores
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{PCIDSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

DetailedCoverageLineChart.propTypes = {
    selectedChapter: PropTypes.object,
    nistDatasets: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default DetailedCoverageLineChart

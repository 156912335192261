import {
    useAppSelector
} from '@app/hook'
import {
    ASVS_CATS_COLUMNS,
    DEFAULT_ASVS_CATS,
    MESSAGE as WSS_MESSAGE,
    TEXT as WSS_TEXT
} from '@constants/dashboard/soc/wss'
import {
    CHART_HEIGHT,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    ASVSCat,
    ASVSCats
} from '@interfaces/dashboard/soc/wss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
/** slices from ext-wss to wss copied. */
import {
    selectFixedCollapsibles
} from '@slices/dashboard/soc/wss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    PageBreakInside,
    SpinnerContainer,
    Table
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'

interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>
    chartInstance: Chart<'bar', number[], string> | undefined,
    setChartInstance: React.Dispatch<React.SetStateAction<
        Chart<'bar', number[], string> | undefined>
    >
    data: ASVSCats | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
    isPrint?:boolean
}

const OWASPClassificationChart = ({
    isPrint,
    chartEl, chartInstance, setChartInstance,
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const ASVSCats = data || DEFAULT_ASVS_CATS

        // now get those keys and perform a custom sort.
        const orderedKeys = _.keys(ASVSCats).sort((a, b) => {
            if (
                _.parseInt(a.substring(1)) > _.parseInt(b.substring(1))
            ) {
                return 1
            } else if (
                _.parseInt(a.substring(1)) < _.parseInt(b.substring(1))
            ) {
                return -1
            } else {
                return 0
            }
        }) as (keyof ASVSCats)[]

        const vulnerabilities: {
            threat: keyof ASVSCat,
            color: string
        }[] = [
            {
                threat: 'high',
                color: stylesheet.style.colorPresets['amber-1']
            },
            {
                threat: 'medium',
                color: stylesheet.style.colorPresets['amber-2']
            },
            {
                threat: 'low',
                color: stylesheet.style.colorPresets.yellow
            },
            {
                threat: 'info',
                color: stylesheet.style.colorPresets.grey
            }
        ]

        let graph: Chart<'bar', number[], string>

        const labels = orderedKeys
        const datasets = _.map(vulnerabilities, (vulnObj) => {
            return ({
                // select by orderedKeys
                data: _.map(orderedKeys, (key) => {
                    const asvsCat = ASVSCats[key]
                    return asvsCat[vulnObj.threat]
                }),
                label: _.capitalize(vulnObj.threat),
                backgroundColor: vulnObj.color,
                borderWidth: 0,
                maxBarThickness: DEFAULT_BAR_THICKNESS
            })
        })

        if (chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: () => {
                                    return ''
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                display: false,
                                color: stylesheet.mode.fontColor
                            },
                            stacked: true
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            stacked: true
                        }
                    }
                }
            })

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.height = CHART_HEIGHT.md
            chartEl.current.style.maxHeight = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            graph && graph.destroy()
        }
    }, [data])

    const DataTable = useMemo(() => {
        const ASVSCats = data || DEFAULT_ASVS_CATS

        const orderedKeys = _.keys(ASVSCats).sort((a, b) => {
            if (
                _.parseInt(a.substring(1)) > _.parseInt(b.substring(1))
            ) {
                return 1
            } else if (
                _.parseInt(a.substring(1)) < _.parseInt(b.substring(1))
            ) {
                return -1
            } else {
                return 0
            }
        }) as (keyof ASVSCats)[]

        const cellBody = (
            dataObject: ASVSCat,
            property: keyof ASVSCat
        ) => {
            let cellContent: ASVSCat[keyof ASVSCat] = 0

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(ASVS_CATS_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'ASVSCat-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(orderedKeys, (key, rowIndex) => {
                            return (
                                <tr
                                    key={'ASVSCat-tr-' + rowIndex}
                                >
                                    <td>{key}</td>
                                    {
                                        _.map(
                                            _.slice(ASVS_CATS_COLUMNS, 1),
                                            (column, cellIndex) => {
                                                const value = column.value as keyof ASVSCat
                                                return (
                                                    <td key={[
                                                        'ASVSCat-td-' + rowIndex +
                                            '-' + cellIndex
                                                    ].join('')}
                                                    >
                                                        {cellBody(
                                                            ASVSCats[key],
                                                            value
                                                        )}
                                                    </td>
                                                )
                                            })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        data,
        chartInstance
    ])

    const DataContent = useMemo(() => {
        const content = !isPrint
            ? <Container bgIndex={2} className={'mb-3'}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.owaspAsvsClassification
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
            : <div>
                <PageBreakInside className={'row'}>
                    <div className={'col'}>
                        <span className={'d-inline-block mb-2'}>
                            {WSS_TEXT.SECTIONS.OWASP_ASVS_CLASSIFICATION}
                        </span>
                        <Container bgIndex={2} className={'mb-2'}>
                            <canvas ref={chartEl}/>
                        </Container>
                    </div>
                </PageBreakInside>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.owaspAsvsClassification
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

OWASPClassificationChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default OWASPClassificationChart

import {
    useAppDispatch
} from '@app/hook'
import {
    EVENT_STATE_GROUPINGS,
    TEXT as EVENT_TEXT
} from '@constants/watchdog/soc-data/event'
import {
    AnswerReportValues,
    DecodedReportParam,
    GetReportDataResponse,
    ReportFormData,
    ReportModal,
    ReportStepInput
} from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    CollapsibleText,
    FormStyledComponents as Form,
    Text,
    Button
} from '@styles/components'
import {
    FormikErrors,
    FormikProps
} from 'formik'
import _ from 'lodash'
import React, { useMemo } from 'react'
import {
    FaHistory,
    FaMinus,
    FaPlus
} from 'react-icons/fa'
import uniqueString from 'unique-string'

interface ComponentProps {
    decodedReportParams: DecodedReportParam
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    reportStepsFormik: FormikProps<AnswerReportValues>,
    responseData: GetReportDataResponse | undefined
    currentReportSection: number,
}

/** one thing we need to figure out, we need to have ids for inputs and collapsible buttons.
 * so we will be creating them in the formAnswerValues initialization.
 */

const Bullet = ({
    decodedReportParams, addModal, currentReportSection, reportStepsFormik, responseData
} : ComponentProps) => {
    const dispatch = useAppDispatch()

    /** let's make a useMemo to get the reportStep information */
    const reportStepInfo = useMemo(() => {
        return _.find(responseData?.reportSteps || [], (obj) => {
            return obj.reportSection === currentReportSection
        })
    }, [
        responseData, currentReportSection
    ])

    const stepIndex = useMemo(() => {
        return _.findIndex(reportStepsFormik.values.reportSteps, (obj) => {
            return obj.reportSection === currentReportSection
        })
    }, [
        reportStepsFormik.values, currentReportSection
    ])

    const stepInput = useMemo(() => {
        return reportStepsFormik.values.reportSteps[stepIndex]
    }, [
        reportStepsFormik.values, stepIndex
    ])

    const errors = useMemo(() => {
        return (reportStepsFormik.errors.reportSteps ||
            []) as FormikErrors<ReportStepInput>[]
    }, [
        reportStepsFormik.errors
    ])
    /** a button to add bullets. */
    const AddBulletLine = useMemo(() => {
        return (
            <Form.Button
                onClick={() => {
                    const answerGiven = _.cloneDeep(stepInput.reportStepInput.answerGiven)
                    const collapsible = _.cloneDeep(stepInput.reportStepInput.collapsible)
                    const id = _.cloneDeep(stepInput.reportStepInput.id)

                    reportStepsFormik.setFieldValue(
                        [
                            'reportSteps',
                            '[', stepIndex.toString(), '].',
                            'reportStepInput.',
                            'answerGiven'
                        ].join(''), [...answerGiven, '']
                    )

                    reportStepsFormik.setFieldValue(
                        [
                            'reportSteps',
                            '[', stepIndex.toString(), '].',
                            'reportStepInput.',
                            'collapsible'
                        ].join(''), [...collapsible, true]
                    )

                    reportStepsFormik.setFieldValue(
                        [
                            'reportSteps',
                            '[', stepIndex.toString(), '].',
                            'reportStepInput.',
                            'id'
                        ].join(''), [...id, uniqueString()]
                    )
                }}
                size={'sm'}
                mode={'primary'}
                type={'button'}
            >{EVENT_TEXT.REPORT_INTERFACE.BUTTONS.ADD_BULLET}</Form.Button>

        )
    }, undefined)

    const BulletQuestionLabel = useMemo(() => {
        return <CollapsibleText className={'row justify-content-between align-items-center mb-2'}>
            <Form.Label
                className={'col-auto pe-0 mb-0'}
            >
                {reportStepInfo?.reportQuestion}
            </Form.Label>
            <div className={'col-auto'}>
                {_.includes(
                    EVENT_STATE_GROUPINGS.GROUP_1,
                    decodedReportParams.eventState
                )
                    ? AddBulletLine
                    : ''}
            </div>
        </CollapsibleText>
    }, undefined)

    return <div>
        <Form.Group>
            {BulletQuestionLabel}
            {
                _.map(stepInput.reportStepInput?.answerGiven || [],
                    (answerStr, answerIndex) => {
                        const key = [
                            'reportQuestion-', answerIndex
                        ].join('')

                        const reviewNoteValue = stepInput.reportStepInput
                            .reviewNote[answerIndex]

                        const collapsible = stepInput.reportStepInput.collapsible[answerIndex]

                        const AnswerGivenLabel = <CollapsibleText className={
                            'row align-items-center mb-2'
                        }>
                            <Text size={'xxs'} className={'col-auto pe-0 icon pointer'}
                                onClick={() => {
                                    reportStepsFormik.setFieldValue(
                                        [
                                            'reportSteps',
                                            '[', stepIndex.toString(), '].',
                                            'reportStepInput.',
                                            'collapsible[',
                                            answerIndex.toString(),
                                            ']'
                                        ].join(''),
                                        !collapsible
                                    )
                                }}
                            >
                                {collapsible
                                    ? <FaMinus/>
                                    : <FaPlus/> }
                            </Text>
                            <Text size={'xxs'} className={'col-auto pe-0 icon pointer'}
                                onClick={() => {
                                    const formData: ReportFormData = {
                                        answerHistory: {
                                            data: responseData?.reportData || [],
                                            reportStep: {
                                                stepIndex: stepIndex,
                                                inputIndex: answerIndex,
                                                reportSection: reportStepInfo
                                                    ?.reportSection || 1
                                            }
                                        }
                                    }

                                    dispatch(addModal({
                                        id: uniqueString(),
                                        open: true,
                                        operation: 'ANSWER_HISTORY',
                                        formData: formData,
                                        isBorderWide: false
                                    }))
                                }}
                            >
                                <FaHistory/>
                            </Text>
                            <Form.Label
                                className={'col-auto pe-0 mb-0'}
                                htmlFor={stepInput.reportStepInput.id[answerIndex]}>
                                {[
                                    'Bullet',
                                    answerIndex + 1
                                ].join(' ')}
                            </Form.Label>
                            <div className={'col-auto'}>
                                {_.includes(
                                    EVENT_STATE_GROUPINGS.GROUP_1,
                                    decodedReportParams.eventState
                                )
                                    ? <Button
                                        type={'button'}
                                        size={'sm'}
                                        onClick={() => {
                                            const answerGiven = _.cloneDeep(
                                                stepInput.reportStepInput.answerGiven
                                            )
                                            answerGiven.splice(answerIndex, 1)

                                            const collapsible = _.cloneDeep(
                                                stepInput.reportStepInput.collapsible
                                            )
                                            collapsible.splice(answerIndex, 1)

                                            const id = _.cloneDeep(
                                                stepInput.reportStepInput.id
                                            )
                                            id.splice(answerIndex, 1)

                                            reportStepsFormik.setFieldValue(
                                                [
                                                    'reportSteps',
                                                    '[', stepIndex.toString(), '].',
                                                    'reportStepInput.',
                                                    'answerGiven'
                                                ].join(''), answerGiven
                                            )

                                            reportStepsFormik.setFieldValue(
                                                [
                                                    'reportSteps',
                                                    '[', stepIndex.toString(), '].',
                                                    'reportStepInput.',
                                                    'collapsible'
                                                ].join(''), collapsible
                                            )

                                            reportStepsFormik.setFieldValue(
                                                [
                                                    'reportSteps',
                                                    '[', stepIndex.toString(), '].',
                                                    'reportStepInput.',
                                                    'id'
                                                ].join(''), id
                                            )
                                        }}
                                        mode={'danger'}>
                                        {EVENT_TEXT.REPORT_INTERFACE.BUTTONS.REMOVE_BULLET}
                                    </Button>
                                    : ''}
                            </div>

                        </CollapsibleText>

                        const AnswerGivenInput = <Form.TextArea
                            errors={Boolean(
                                errors[stepIndex]?.reportStepInput
                                    ?.answerGiven?.[answerIndex]
                            )}
                            name={[
                                'reportSteps',
                                '[', stepIndex.toString(), '].',
                                'reportStepInput.',
                                'answerGiven[',
                                answerIndex.toString(),
                                ']'
                            ].join('')}
                            readOnly={
                                !_.includes(
                                    EVENT_STATE_GROUPINGS.GROUP_1,
                                    decodedReportParams.eventState
                                )
                            }
                            id={stepInput.reportStepInput.id[answerIndex]}
                            onChange={_.includes(
                                EVENT_STATE_GROUPINGS.GROUP_1,
                                decodedReportParams.eventState
                            )
                                ? reportStepsFormik.handleChange
                                : () => {}
                            }
                            onBlur={(e) => {
                                _.includes(
                                    EVENT_STATE_GROUPINGS.GROUP_1,
                                    decodedReportParams.eventState
                                ) && reportStepsFormik.setFieldValue(
                                    [
                                        'reportSteps',
                                        '[', stepIndex.toString(), '].',
                                        'reportStepInput.',
                                        'answerGiven[',
                                        answerIndex.toString(),
                                        ']'
                                    ].join(''),
                                    e.target.value.trim()
                                )
                            }}
                            value={answerStr}
                        />

                        const ReviewLabel = <div>
                            <CollapsibleText className={'row align-items-center mb-2'}>
                                <Text size={'xxs'} className={'col-auto pe-0'} >
                                    {EVENT_TEXT.REPORT_INTERFACE.LABELS.REVIEW_NOTE}
                                </Text>
                                <Text size={'xxs'} className={'col-auto pe-0 icon pointer'}
                                    onClick={() => {
                                        const formData: ReportFormData = {
                                            answerHistory: {
                                                data: responseData?.reportData || [],
                                                reportStep: {
                                                    stepIndex: stepIndex,
                                                    inputIndex: answerIndex,
                                                    reportSection: reportStepInfo
                                                        ?.reportSection || 1
                                                }
                                            }
                                        }

                                        dispatch(addModal({
                                            id: uniqueString(),
                                            open: true,
                                            operation: 'REVIEW_HISTORY',
                                            formData: formData,
                                            isBorderWide: false
                                        }))
                                    }}
                                >
                                    <FaHistory/>
                                </Text>
                            </CollapsibleText>
                        </div>

                        /** interface for reviewNotes. Yes they also have an answer history */
                        const ReviewInput = <Form.TextArea
                            name={[
                                'reportSteps',
                                '[', stepIndex.toString(), '].',
                                'reportStepInput.',
                                'reviewNotes[',
                                answerIndex.toString(),
                                ']'
                            ].join('')}
                            readOnly={
                                !_.includes(
                                    EVENT_STATE_GROUPINGS.GROUP_2,
                                    decodedReportParams.eventState
                                )
                            }
                            onChange={reportStepsFormik.handleChange}
                            onBlur={(e) => {
                                reportStepsFormik.setFieldValue(
                                    [
                                        'reportSteps',
                                        '[', stepIndex.toString(), '].',
                                        'reportStepInput.',
                                        'reviewNotes[',
                                        answerIndex.toString(),
                                        ']'
                                    ].join(''),
                                    e.target.value.trim()
                                )
                            }}
                            value={reviewNoteValue}
                        />

                        return <Form.Group key={key}>
                            {AnswerGivenLabel}
                            {collapsible
                                ? <>
                                    {AnswerGivenInput}
                                    {/* show review label */}
                                    {
                                        _.includes(
                                            EVENT_STATE_GROUPINGS.GROUP_2,
                                            decodedReportParams.eventState
                                        )
                                            ? <div>
                                                {ReviewLabel}
                                                {ReviewInput}
                                            </div>
                                            : ''
                                    }
                                </>
                                : ''}

                        </Form.Group>
                    })
            }
        </Form.Group>

    </div>
}

export default Bullet


import {
    useGetAzureAdMutation,
    useGetFilesAccessMutation,
    useGetFilesMaliciousMutation,
    useGetFilesSharedMutation,
    useGetIssuesDetectedMutation,
    useGetModalHeaderMutation,
    useGetOffice365UsersMutation,
    useGetPolicyRelatedMutation,
    useGetUserActivityMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    CHART_ZOOM,
    MESSAGE as MONITOR_MESSAGE,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as O365_MESSAGE,
    TEXT as O365_TEXT
} from '@constants/dashboard/soc/o365/main'
import {
    getLocalRanges,
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { O365_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import {
    DetailedSearchParams,
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/o365/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectPrintOptions,
    setEndDate,
    selectSearchParams,
    selectFixedCollapsibles,
    setLogo,
    setRefetch,
    setStartDate,
    toggleCollapsible,
    resetO365Main,
    setCurrentParams,
    selectCurrentParams,
    setChartZoom,
    selectChartZooms,
    setChartZoomLevel
} from '@slices/dashboard/soc/o365/main'
import { push } from 'connected-react-router'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    DashboardFooter as Footer,
    Container,
    DashboardStyledComponents as Dashboard,
    SpinnerContainer,
    Text,
    CollapsibleText
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    differenceInDays,
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import {
    FaDirections,
    FaFilePdf,
    FaSearchMinus,
    FaPlus,
    FaMinus
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import Office365UsersChart from '@features/dashboard/soc/o365/Office365UsersChart'
import O365BarChart from '@features/dashboard/soc/o365/O365BarChart'
import IssuesDetectedChart from '@features/dashboard/soc/o365/IssuesDetectedChart'
import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'

const O365Modal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const setO365DetailedDashboardParams = rootContext.setO365DetailedDashboardParams

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const chartZooms = useAppSelector(selectChartZooms)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalHeaderMutation()

    const [getAzureAd, getAzureAdMutation] = useGetAzureAdMutation()
    const [getFilesAccess, getFilesAccessMutation] = useGetFilesAccessMutation()
    const [getFilesMalicious, getFilesMaliciousMutation] = useGetFilesMaliciousMutation()
    const [getFilesShared, getFilesSharedMutation] = useGetFilesSharedMutation()
    const [getIssuesDetected, getIssuesDetectedMutation] = useGetIssuesDetectedMutation()
    const [getOffice365Users, getOffice365UsersMutation] = useGetOffice365UsersMutation()
    const [getPolicyRelated, getPolicyRelatedMutation] = useGetPolicyRelatedMutation()
    const [getUserActivity, getUserActivityMutation] = useGetUserActivityMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'modal.Header',
                value: false
            }))
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getAzureAdMutation.error) {
            console.error(getAzureAdMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.AD',
                value: false
            }))
        }
    }, [getAzureAdMutation.error])

    useEffect(() => {
        if (getFilesAccessMutation.error) {
            console.error(getFilesAccessMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Files.Access',
                value: false
            }))
        }
    }, [getFilesAccessMutation.error])

    useEffect(() => {
        if (getFilesMaliciousMutation.error) {
            console.error(getFilesMaliciousMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Files.Malicious',
                value: false
            }))
        }
    }, [getFilesMaliciousMutation.error])

    useEffect(() => {
        if (getFilesSharedMutation.error) {
            console.error(getFilesSharedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Files.Shared',
                value: false
            }))
        }
    }, [getFilesSharedMutation.error])

    useEffect(() => {
        if (getIssuesDetectedMutation.error) {
            console.error(getIssuesDetectedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Issues.Detected',
                value: false
            }))
        }
    }, [getIssuesDetectedMutation.error])

    useEffect(() => {
        if (getOffice365UsersMutation.error) {
            console.error(getOffice365UsersMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Office365.Users',
                value: false
            }))
        }
    }, [getOffice365UsersMutation.error])

    useEffect(() => {
        if (getPolicyRelatedMutation.error) {
            console.error(getPolicyRelatedMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Policy.Related',
                value: false
            }))
        }
    }, [getPolicyRelatedMutation.error])

    useEffect(() => {
        if (getUserActivityMutation.error) {
            console.error(getUserActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'User.Activity',
                value: false
            }))
        }
    }, [getUserActivityMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetModalRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        // cloneDeep searchParams.ranges for modification.
        const ranges = _.cloneDeep(searchParams.ranges)
        if (eventType && eventType === 'Office365.Users') {
            const dayDifference = differenceInDays(
                ranges.end, ranges.start
            )

            if (dayDifference < 30) {
                ranges.start = getUnixTime(sub(ranges.start, { days: 30 }))
            }
        }

        const newRanges = getUtcRanges(searchParams.ranges)

        dispatch(setCurrentParams({
            ranges: newRanges
        }))

        return {
            authToken: newToken,
            deviceid: modal.card.deviceid,
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString()
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureAd = () => {
        const unsubscribeMutation = getAzureAd({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFilesAccess = () => {
        const unsubscribeMutation = getFilesAccess({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFilesMalicious = () => {
        const unsubscribeMutation = getFilesMalicious({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetFilesShared = () => {
        const unsubscribeMutation = getFilesShared({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssuesDetected = () => {
        const unsubscribeMutation = getIssuesDetected({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOffice365Users = () => {
        const unsubscribeMutation = getOffice365Users({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPolicyRelated = () => {
        const unsubscribeMutation = getPolicyRelated({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetUserActivity = () => {
        const unsubscribeMutation = getUserActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const requestData: Omit<GetModalRequest, 'event_type'>
                    & TokenAuth = createRequestData(token.value)

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchAzureAdData = () => {
        unsubscribeGetAzureAd()
        let getAzureAdPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureAdPromise = getAzureAd(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureAdPromise && getAzureAdPromise.abort()
        }
    }

    const fetchFileAccessData = () => {
        unsubscribeGetFilesAccess()
        let getFileAccessPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFileAccessPromise = getFilesAccess(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFileAccessPromise && getFileAccessPromise.abort()
        }
    }

    const fetchFileMaliciousData = () => {
        unsubscribeGetFilesMalicious()
        let getFileMaliciousPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFileMaliciousPromise = getFilesMalicious(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFileMaliciousPromise && getFileMaliciousPromise.abort()
        }
    }

    const fetchFileSharedData = () => {
        unsubscribeGetFilesShared()
        let getFileSharedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getFileSharedPromise = getFilesShared(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getFileSharedPromise && getFileSharedPromise.abort()
        }
    }

    const fetchIssuesDetectedData = () => {
        unsubscribeGetIssuesDetected()
        let getIssuesDetectedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getIssuesDetectedPromise = getIssuesDetected(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getIssuesDetectedPromise && getIssuesDetectedPromise.abort()
        }
    }

    const fetchOffice365UsersData = () => {
        unsubscribeGetOffice365Users()
        let getOffice365UsersPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                /** The date range should be at least 30 days.
                 * Can check by date range difference by day count.
                */
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken, 'Office365.Users')
                getOffice365UsersPromise = getOffice365Users(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOffice365UsersPromise && getOffice365UsersPromise.abort()
        }
    }

    const fetchPolicyRelatedData = () => {
        unsubscribeGetPolicyRelated()
        let getPolicyRelatedPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getPolicyRelatedPromise = getPolicyRelated(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getPolicyRelatedPromise && getPolicyRelatedPromise.abort()
        }
    }

    const fetchUserActivityData = () => {
        unsubscribeGetUserActivity()
        let getUserActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getUserActivityPromise = getUserActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getUserActivityPromise && getUserActivityPromise.abort()
        }
    }

    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureAdData()
    }, [token.valid])
    useEffect(() => {
        return fetchFileAccessData()
    }, [token.valid])
    useEffect(() => {
        return fetchFileMaliciousData()
    }, [token.valid])
    useEffect(() => {
        return fetchFileSharedData()
    }, [token.valid])
    useEffect(() => {
        return fetchIssuesDetectedData()
    }, [token.valid])
    useEffect(() => {
        return fetchOffice365UsersData()
    }, [token.valid])
    useEffect(() => {
        return fetchPolicyRelatedData()
    }, [token.valid])
    useEffect(() => {
        return fetchUserActivityData()
    }, [token.valid])

    useEffect(() => {
        if (searchParams.refetch['modal.Header']) {
            return fetchModalHeaderData()
        }
    }, [searchParams.refetch['modal.Header']])

    useEffect(() => {
        if (searchParams.refetch['Azure.AD']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.AD',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureAdData()
        }
    }, [searchParams.refetch['Azure.AD']])

    useEffect(() => {
        if (searchParams.refetch['Files.Access']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Files.Access',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchFileAccessData()
        }
    }, [searchParams.refetch['Files.Access']])

    useEffect(() => {
        if (searchParams.refetch['Files.Malicious']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Files.Malicious',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchFileMaliciousData()
        }
    }, [searchParams.refetch['Files.Malicious']])

    useEffect(() => {
        if (searchParams.refetch['Files.Shared']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Files.Shared',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchFileSharedData()
        }
    }, [searchParams.refetch['Files.Shared']])

    useEffect(() => {
        if (searchParams.refetch['Issues.Detected']) {
            /** before fetching data, reset chart zoom levels. */
            return fetchIssuesDetectedData()
        }
    }, [searchParams.refetch['Issues.Detected']])

    useEffect(() => {
        if (searchParams.refetch['Office365.Users']) {
            /** before fetching data, reset chart zoom levels. */
            return fetchOffice365UsersData()
        }
    }, [searchParams.refetch['Office365.Users']])

    useEffect(() => {
        if (searchParams.refetch['Policy.Related']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Policy.Related',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchPolicyRelatedData()
        }
    }, [searchParams.refetch['Policy.Related']])

    useEffect(() => {
        if (searchParams.refetch['User.Activity']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'User.Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchUserActivityData()
        }
    }, [searchParams.refetch['User.Activity']])

    useEffect(() => {
        if (getModalHeaderMutation.isSuccess && searchParams.refetch['modal.Header']) {
            dispatch(setRefetch({
                key: 'modal.Header',
                value: false
            }))
        }
    }, [getModalHeaderMutation.isSuccess])

    useEffect(() => {
        if (getAzureAdMutation.isSuccess && searchParams.refetch['Azure.AD']) {
            dispatch(setRefetch({
                key: 'Azure.AD',
                value: false
            }))
        }
    }, [getAzureAdMutation.isSuccess])

    useEffect(() => {
        if (getFilesAccessMutation.isSuccess && searchParams.refetch['Files.Access']) {
            dispatch(setRefetch({
                key: 'Files.Access',
                value: false
            }))
        }
    }, [getFilesAccessMutation.isSuccess])

    useEffect(() => {
        if (getFilesMaliciousMutation.isSuccess && searchParams.refetch['Files.Malicious']) {
            dispatch(setRefetch({
                key: 'Files.Malicious',
                value: false
            }))
        }
    }, [getFilesMaliciousMutation.isSuccess])

    useEffect(() => {
        if (getFilesSharedMutation.isSuccess && searchParams.refetch['Files.Shared']) {
            dispatch(setRefetch({
                key: 'Files.Shared',
                value: false
            }))
        }
    }, [getFilesSharedMutation.isSuccess])

    useEffect(() => {
        if (getIssuesDetectedMutation.isSuccess && searchParams.refetch['Issues.Detected']) {
            dispatch(setRefetch({
                key: 'Issues.Detected',
                value: false
            }))
        }
    }, [getIssuesDetectedMutation.isSuccess])

    useEffect(() => {
        if (getOffice365UsersMutation.isSuccess && searchParams.refetch['Office365.Users']) {
            dispatch(setRefetch({
                key: 'Office365.Users',
                value: false
            }))
        }
    }, [getOffice365UsersMutation.isSuccess])

    useEffect(() => {
        if (getPolicyRelatedMutation.isSuccess && searchParams.refetch['Policy.Related']) {
            dispatch(setRefetch({
                key: 'Policy.Related',
                value: false
            }))
        }
    }, [getPolicyRelatedMutation.isSuccess])

    useEffect(() => {
        if (getUserActivityMutation.isSuccess && searchParams.refetch['User.Activity']) {
            dispatch(setRefetch({
                key: 'User.Activity',
                value: false
            }))
        }
    }, [getUserActivityMutation.isSuccess])

    /** end of fetch script. */

    /** detailed dashboard stuff. */
    const setParams = () => {
        /** keep in mind that you want to get the startDate and endDate values
         * of this modal.
        */
        const params: Omit<DetailedSearchParams, 'refetch'> = {
            /** use currentParams */
            ranges: {
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            },
            card: {
                details: {
                    ...modal.card.details,
                    colorType: getModalHeaderMutation.data?.data.colorType ||
                            modal.card.details.colorType || 'grey'
                },
                deviceid: modal.card.deviceid,
                in_face: modal.card.in_face,
                service_type: modal.card.service_type
            },
            q: '',
            boolList: []
        }

        const newRoute = _.split(O365_DETAILED_DASHBOARD_ROUTES.MAIN.link, ':deviceid')

        setO365DetailedDashboardParams(params)

        dispatch(push([
            newRoute[0],
            modal.card.deviceid,
            newRoute[1]
        ].join('')))
    }
    /** redirect button to detailed dashboard */

    const DetailedDashboardButton = (
        <span
            onClick={() => {
                setParams()
            }}
            className={'icon pointer mb-2 d-inline-block ms-2'}
        >
            <FaDirections/>
        </span>
    )

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data
        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && data?.data.heading === '0 Total Events') {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 1 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))

                const keys: EventType[] = [
                    'modal.Header', 'Azure.AD', 'Files.Access', 'Files.Malicious',
                    'Files.Shared', 'Issues.Detected', 'Office365.Users',
                    'Policy.Related', 'User.Activity'
                ]

                _.forEach(keys, (key) => {
                    dispatch(setRefetch({
                        key: key,
                        value: true
                    }))
                })

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshWarningContent = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            dispatch(setRefetch({
                key: 'modal.Header',
                value: true
            }))
            dispatch(setRefetch({
                key: 'User.Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Policy.Related',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Office365.Users',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Issues.Detected',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Files.Shared',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Files.Malicious',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Files.Access',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.AD',
                value: true
            }))
        }

        return <div>
            <div className={'row'}>
                <small className={'col'}>
                    {MONITOR_MESSAGE.DETAILED_DASHBOARD_REFRESH_ALL}
                </small>
            </div>
            <div className={'row flex-row-reverse mt-1'}>
                <div className={'col-auto'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        onClick={confirmClickEvent}
                    >{TEXT.YES}</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        mode={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            toggleConfirm(false)
                        }}
                    >{TEXT.NO}</Button>
                </div>
            </div>
        </div>
    }, [showConfirm])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalHeaderMutation.isLoading ||
            getAzureAdMutation.isLoading ||
            getFilesAccessMutation.isLoading ||
            getFilesMaliciousMutation.isLoading ||
            getFilesSharedMutation.isLoading ||
            getIssuesDetectedMutation.isLoading ||
            getOffice365UsersMutation.isLoading ||
            getPolicyRelatedMutation.isLoading ||
            getUserActivityMutation.isLoading

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={RefreshWarningContent}
                onClickOutside={() => toggleConfirm(false)}
            >
                <span
                    className={[
                        'icon mb-2 d-inline-block ms-2',
                        disableRefresh ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }} >
                    <MdRefresh />
                </span>
            </Tippy>
        )
    }, [
        showConfirm,
        RefreshWarningContent,
        getModalHeaderMutation,
        getAzureAdMutation,
        getFilesAccessMutation,
        getFilesMaliciousMutation,
        getFilesSharedMutation,
        getIssuesDetectedMutation,
        getOffice365UsersMutation,
        getPolicyRelatedMutation,
        getUserActivityMutation
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={O365_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {O365_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalHeaderMutation.data
        const hasNoData = data?.data.heading === '0 Total Events'

        return <div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalHeaderMutation,
        queueId,
        queues,
        printOptions,
        currentParams
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.REDIRECTS.O365_DETAILED}</div>}>
                    <div className={'d-inline-block'}>{DetailedDashboardButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        DetailedDashboardButton,
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        type Header = ModalHeaderResponse['data'] & { title: string }
        const data: Header = {
            title: modal.card.details.title || '',
            ...getModalHeaderMutation.data?.data || {
                line_1: modal.card.details.line_1 || '',
                line_2: modal.card.details.line_2 || '',
                heading: modal.card.details.heading || '',
                colorType: modal.card.details.colorType || 'grey'
            }
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ modal.card.details.state || 'NO STATE' }
                    : ${ modal.card.details.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                {/* forgot to display date range plus timezone here. */}
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        dispatch(changeModalColor({
            modal: modal,
            colorType: data?.colorType || modal.card.details.colorType || 'grey'
        }))
    }, [
        modal.card,
        getModalHeaderMutation
    ])

    /** now we create our charts. */
    const UserActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.USER_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'User.Activity',
                        value: !fixedCollapsibles['User.Activity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['User.Activity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['User.Activity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'User.Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['User.Activity'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['User.Activity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'User.Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'User.Activity'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getUserActivityMutation.data}
                isLoading={getUserActivityMutation.isLoading}
                isSuccess={getUserActivityMutation.isSuccess}
                error={getUserActivityMutation.error}
            />
        </div>
    }, undefined)

    const AzureAD = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.AZURE_AD}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.AD',
                        value: !fixedCollapsibles['Azure.AD']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.AD'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.AD'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.AD',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.AD'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.AD'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.AD',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Azure.AD'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureAdMutation.data}
                isLoading={getAzureAdMutation.isLoading}
                isSuccess={getAzureAdMutation.isSuccess}
                error={getAzureAdMutation.error}
            />
        </div>
    }, undefined)

    const Office365Users = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.OFFICE_365_USERS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch['Office365.Users'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Office365.Users',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <Office365UsersChart
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getOffice365UsersMutation.data}
                isLoading={getOffice365UsersMutation.isLoading}
                isSuccess={getOffice365UsersMutation.isSuccess}
                error={getOffice365UsersMutation.error}
            />
        </div>
    }, undefined)

    const IssuesDetected = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.ISSUES_DETECTED}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Issues.Detected',
                        value: !fixedCollapsibles['Issues.Detected']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Issues.Detected'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Issues.Detected'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Issues.Detected',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <IssuesDetectedChart
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getIssuesDetectedMutation.data}
                isLoading={getIssuesDetectedMutation.isLoading}
                isSuccess={getIssuesDetectedMutation.isSuccess}
                error={getIssuesDetectedMutation.error}
            />
        </div>
    }, undefined)

    const FilesAccess = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.FILES_ACCESS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Files.Access',
                        value: !fixedCollapsibles['Files.Access']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Files.Access'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Files.Access'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Files.Access',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Files.Access'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Files.Access'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Files.Access',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Files.Access'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getFilesAccessMutation.data}
                isLoading={getFilesAccessMutation.isLoading}
                isSuccess={getFilesAccessMutation.isSuccess}
                error={getFilesAccessMutation.error}
            />
        </div>
    }, undefined)

    const FilesShared = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.FILES_SHARED}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Files.Shared',
                        value: !fixedCollapsibles['Files.Shared']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Files.Shared'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Files.Shared'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Files.Shared',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Files.Shared'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Files.Shared'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Files.Shared',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Files.Shared'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getFilesSharedMutation.data}
                isLoading={getFilesSharedMutation.isLoading}
                isSuccess={getFilesSharedMutation.isSuccess}
                error={getFilesSharedMutation.error}
            />
        </div>
    }, undefined)

    const PolicyRelated = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.POLICY_RELATED}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Policy.Related',
                        value: !fixedCollapsibles['Policy.Related']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Policy.Related'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Policy.Related'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Policy.Related',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Policy.Related'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Policy.Related'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Policy.Related',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Policy.Related'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getPolicyRelatedMutation.data}
                isLoading={getPolicyRelatedMutation.isLoading}
                isSuccess={getPolicyRelatedMutation.isSuccess}
                error={getPolicyRelatedMutation.error}
            />
        </div>
    }, undefined)

    const FilesMalicious = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {O365_TEXT.SECTIONS.FILES_MALICIOUS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Files.Malicious',
                        value: !fixedCollapsibles['Files.Malicious']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Files.Malicious'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Files.Malicious'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Files.Malicious',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Files.Malicious'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Files.Malicious'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Files.Malicious',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <O365BarChart
                eventType={'Files.Malicious'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getFilesMaliciousMutation.data}
                isLoading={getFilesMaliciousMutation.isLoading}
                isSuccess={getFilesMaliciousMutation.isSuccess}
                error={getFilesMaliciousMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        return () => {
            dispatch(resetO365Main())
        }
    }, [])

    /** reset data on unmount */
    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {Statcard}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {UserActivity}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {AzureAD}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                {Office365Users}
            </div>

            <div className={'min-width-fix'}>
                {IssuesDetected}
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {FilesAccess}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {FilesShared}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {PolicyRelated}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {FilesMalicious}
                    </div>
                </div>
            </div>

            <Footer.Main className={'justify-content-center mb-3 row'}>
                <div
                    className={'col-12 col-md-6'}
                >
                    <Footer.DetailedDashboardButton
                        onClick={() => {
                            setParams()
                        }}
                        className={'pointer py-4 text-center text-uppercase'}
                    >
                        {MONITOR_TEXT.CARD.DETAILED_DASHBOARD}
                    </Footer.DetailedDashboardButton>
                </div>
            </Footer.Main>

        </div>
    )
}

export default O365Modal

import {
    useGetBoxTypesListMutation,
    useGetOrderDataMutation
} from '@apis/watchdog/account-data/order-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    COLUMNS,
    VALIDATION_SCHEMA,
    TEXT as ORDER_TEXT
} from '@constants/watchdog/account-data/order'
import MenuLinks from '@features/main/MenuLinks'
import DispatchConfirmation from
    '@features/watchdog/account-data/order/dispatch/DispatchConfirmation'
import {
    BoxType,
    DispatchOrder,
    DispatchOrderKeys,
    OrderFormData,
    OrderParams
} from '@interfaces/watchdog/account-data/order'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    addModal,
    closeModal,
    removeModal,
    selectModals,
    selectTabs
} from '@slices/watchdog/account-data/order/main'
import {
    Button,
    FormStyledComponents as Form,
    Table
} from '@styles/components'
import {
    FormikErrors,
    useFormik
} from 'formik'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import { useParams } from 'react-router'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { v4 as uuidv4 } from 'uuid'

const OrderDispatch = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    const { orderid } = useParams<OrderParams>()

    /** fetch other calls. */
    const [getBoxTypesList, getBoxTypesListMutation] = useGetBoxTypesListMutation()
    const [getOrderData, getOrderDataMutation] = useGetOrderDataMutation()

    const orderFormik = useFormik({
        initialValues: INITIAL_VALUES.DISPATCH,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.DISPATCH,
        onSubmit: () => {
            if (orderid) {
                const formData: OrderFormData = {
                    dispatchConfirm: {
                        formValues: orderFormik.values,
                        id: orderid
                    }
                }

                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'ORDER_DISPATCH',
                    formData: formData,
                    isBorderWide: false
                }))
            }
        }
    })

    const CompanyNameInput = useMemo(() => {
        const data = getOrderDataMutation.data

        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.DISPATCH.FORM.COMPANY_NAME.ID}>
                    {ORDER_TEXT.DISPATCH.FORM.COMPANY_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={false}
                    id={ORDER_TEXT.DISPATCH.FORM.COMPANY_NAME.ID}
                    readOnly={true}
                    value={data?.orderData?.[0]?.company_name || ''}
                />
            </Form.Group>
        )
    }, [getOrderDataMutation.data])

    const PartnerNameInput = useMemo(() => {
        const data = getOrderDataMutation.data

        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.DISPATCH.FORM.PARTNER_NAME.ID}>
                    {ORDER_TEXT.DISPATCH.FORM.PARTNER_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={false}
                    id={ORDER_TEXT.DISPATCH.FORM.PARTNER_NAME.ID}
                    readOnly={true}
                    value={data?.orderData?.[0]?.partner_name || ''}
                />
            </Form.Group>
        )
    }, [getOrderDataMutation.data])

    const OrderListTable = useMemo(() => {
        /** create interface no longer just a text field */
        const fieldValue: DispatchOrderKeys = 'orderLines'

        let boxTypes = [] as BoxType[]

        if (getBoxTypesListMutation.data) {
            boxTypes = getBoxTypesListMutation.data.boxTypes
        }

        const thead = (
            <thead>
                <tr>
                    <th>{TEXT.SEQUENCE}</th>
                    {
                        _.map(COLUMNS.DISPATCH, (column, index) => {
                            return (
                                <th key={'order-th-' + index}>
                                    {column.value}
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
        )

        const renderInput = (
            key: keyof DispatchOrder,
            value: DispatchOrder[keyof DispatchOrder],
            orderLineIndex:number
        ) => {
            const selectOnChange = (e: any) => {
                if (e) {
                    orderFormik.setFieldValue(
                        [fieldValue,
                            '[',
                            orderLineIndex.toString(),
                            '].',
                            key].join(''),
                        e.value
                    )
                }
            }

            const errors = (orderFormik.errors.orderLines ||
                []) as FormikErrors<DispatchOrder>[]

            switch (key) {
                case 'box_type': return (
                    <Select
                        errors={Boolean(
                            errors[orderLineIndex]?.box_type
                        )}
                        options={boxTypes}
                        value={_.find(
                            boxTypes,
                            (e) => e.value === value
                        )}
                        onChange={selectOnChange}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                    />
                )

                default: return (
                    <Form.TableInput
                        type={'text'}
                        name={[
                            'orderLines[',
                            orderLineIndex.toString(),
                            '].', key
                        ].join('')}
                        onChange={orderFormik.handleChange}
                        onBlur={(e) => {
                            orderFormik.setFieldValue(
                                [fieldValue,
                                    '[',
                                    orderLineIndex.toString(),
                                    '].',
                                    key].join(''),
                                e.target.value.trim()
                            )
                        }}
                        value={value}
                    />
                )
            }
        }

        const renderRow = (
            orderLine: DispatchOrder,
            orderLineIndex: number
        ) => {
            return <tr key={'orderline-row-' + orderLineIndex}>
                <td>{orderLineIndex + 1}</td>
                {
                    _.map(COLUMNS.DISPATCH, (column, columnIndex) => {
                        return (
                            <td key={'orderLine-' + orderLineIndex + 'input-' + columnIndex}>
                                {
                                    renderInput(
                                        column.value,
                                        orderLine[column.value],
                                        orderLineIndex)
                                }
                            </td>
                        )
                    })

                }
                {/* generate uuid */}
                <td>
                    <Button
                        onClick={() => {
                            const property: keyof DispatchOrder = 'config_code'

                            // remove current location line.
                            const orderLines = _.cloneDeep(orderFormik.values.orderLines)

                            orderLines.splice(orderLineIndex, 1)

                            orderFormik.setValues({
                                orderLines: orderLines
                            })

                            orderFormik.setFieldValue(
                                [fieldValue,
                                    '[',
                                    orderLineIndex.toString(),
                                    '].',
                                    property].join(''),
                                uuidv4()
                            )
                        }}
                        size={'sm'}
                        mode={'primary'}>
                        {ORDER_TEXT.DISPATCH.FORM.GENERATE_UUID_BUTTON}
                    </Button>
                </td>
                {/* remove button */}
                <td>
                    <Button
                        onClick={() => {
                            // remove current location line.
                            const orderLines = _.cloneDeep(orderFormik.values.orderLines)

                            orderLines.splice(orderLineIndex, 1)

                            orderFormik.setValues({
                                orderLines: orderLines
                            })
                        }}
                        size={'sm'}
                        mode={'danger'}>
                        {TEXT.TABLE.BUTTONS.REMOVE}
                    </Button>
                </td>
            </tr>
        }

        const tbody = (
            <tbody>
                {
                    _.map(orderFormik.values.orderLines, (orderLine, orderLineIndex) => {
                        return renderRow(orderLine, orderLineIndex)
                    })
                }
            </tbody>
        )

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    {thead}
                    {tbody}
                </table>
            </Table>
        )
    }, [
        orderFormik.values.orderLines,
        getBoxTypesListMutation.data
    ])

    const unsubscribeGetBoxTypes = () => {
        const unsubscribeMutation = getBoxTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnersDetails = () => {
        const unsubscribeMutation = getOrderData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetBoxTypes()
        unsubscribeGetPartnersDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getBoxTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOrderDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid && orderid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getBoxTypesPromise = getBoxTypesList({
                        authToken: newToken
                    })
                    getOrderDataPromise = getOrderData({
                        authToken: newToken,
                        orderId: orderid
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getBoxTypesPromise && getBoxTypesPromise.abort()
            getOrderDataPromise && getOrderDataPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getBoxTypesListMutation.error) {
            console.error(getBoxTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBoxTypesListMutation.error])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'ORDER_DISPATCH') {
                    component = <DispatchConfirmation
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    const setDefaultFormData = useCallback(() => {
        /**
         * if this is truthy, you need to setFieldValue into formik.
         */

        const data = getOrderDataMutation.data
        const boxTypes = getBoxTypesListMutation.data?.boxTypes || []

        if (data?.orderData?.[0]) {
            const result = data.orderData[0]

            const orderLines: DispatchOrder[] = _.map(
                _.cloneDeep(result.orderLines.orderData), (obj) => {
                    const result: DispatchOrder = {
                        box_name: '',
                        box_type: _.find(boxTypes, (boxObj) => {
                            return boxObj.label === obj.box_type
                        })?.value || '',
                        serial_code: '',
                        config_code: uuidv4(),
                        orderline_id: obj.orderline_id

                    }
                    return result
                }
            )

            orderFormik.setValues({
                orderLines: orderLines
            })
        }
    }, [
        getOrderDataMutation.data,
        getBoxTypesListMutation.data
    ])

    useEffect(() => {
        setDefaultFormData()
    }, [
        getOrderDataMutation.data,
        getBoxTypesListMutation.data
    ])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                    >{ORDER_TEXT.DISPATCH.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={() => {
                            setDefaultFormData()
                        }}
                    >{TEXT.FORM.RESET}</Button>
                </div>

            </Form.Group>
        )
    }, undefined)
    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={orderFormik.handleSubmit}>
                        {CompanyNameInput}
                        {PartnerNameInput}
                        {OrderListTable}
                        {DetailsFormButtons}
                    </Form.Main>
                </div>
            </div>
            {renderModals}
        </div>
    )
}
export default OrderDispatch


import {
    DEFAULT_SEVERITY_CONFIDENCE_DATA,
    MESSAGE as WSS_MESSAGE,
    SEVERITY_CONFIDENCE_COLUMNS,
    SEVERITY_CONFIDENCE_ROWS,
    TEXT as WSS_TEXT
} from '@constants/dashboard/soc/wss'
import {
    assignDelta,
    assignValue
} from '@constants/main/method'
import {
    ModalData
} from '@interfaces/dashboard/soc/wss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    Container,
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useMemo
} from 'react'

interface ComponentProps {
    /** a ext-wss/modal call can contain an empty array for the "data" property.
     * to represent an empty search result.
     */
    data: ModalData | [] | undefined,
    dataPrevious: ModalData | [] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const SeverityConfidenceTable = ({
    data, dataPrevious, isLoading, isSuccess, error
} : ComponentProps) => {
    const DataTable = useMemo(() => {
        const currentData = !_.isArray(data)
            ? data?.severityConfidenceData || DEFAULT_SEVERITY_CONFIDENCE_DATA
            : DEFAULT_SEVERITY_CONFIDENCE_DATA

        const previousData = !_.isArray(dataPrevious)
            ? dataPrevious?.severityConfidenceData || DEFAULT_SEVERITY_CONFIDENCE_DATA
            : DEFAULT_SEVERITY_CONFIDENCE_DATA

        const displaySeverityCells = (
            row: typeof SEVERITY_CONFIDENCE_ROWS[0],
            rowIndex: number
        ) => {
            return _.map(SEVERITY_CONFIDENCE_COLUMNS, (column, cellIndex) => {
                const key = [
                    'severity-confidence-td-', rowIndex, '-', cellIndex
                ].join('')

                // console.log('in row: ', rowIndex, '. cell number is: ', cellIndex)

                const currentVal = currentData[row.value][column.value]
                const previousVal = previousData[row.value][column.value]

                const percentage = (
                    (currentVal - previousVal) / previousVal
                ) * 100
                let deltaStatus = ''

                const deltaResult = assignDelta(percentage)
                switch (deltaResult) {
                    case 'positive' : deltaStatus = 'positive-inverse'; break
                    case 'negative' : deltaStatus = 'negative-inverse'; break
                    default: deltaStatus = deltaResult; break
                }
                return (<td className={'pe-4'} key={key}>
                    <div className={'row align-items-center'}>
                        <span className={'col-6'}>
                            {currentVal}
                        </span>
                        <span className={[
                            'col-6 delta',
                            deltaStatus
                        ].join(' ')}>
                            {assignValue(percentage)}
                        </span>
                    </div>
                </td>)
            })
        }

        const content = <Table
            className={'table-striped table-hover'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        <th key={'severity-confidence-th-title'}>
                            <small>{WSS_TEXT.SECTIONS.SEVERITY_CONFIDENCE_TITLE}</small>
                        </th>
                        {
                            _.map(SEVERITY_CONFIDENCE_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'severity-confidence-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(SEVERITY_CONFIDENCE_ROWS, (row, rowIndex) => {
                            const key = [
                                'severity-confidence-tr-', rowIndex
                            ].join('')

                            return (
                                <tr key={key}>
                                    <td key={
                                        'severity-confidence-td-label-' + rowIndex
                                    }>{row.label}</td>
                                    {displaySeverityCells(row, rowIndex)}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        data,
        dataPrevious
    ])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={-1}>
                {DataTable}
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

SeverityConfidenceTable.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default SeverityConfidenceTable

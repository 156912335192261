import {
    useAppSelector
} from '@app/hook'
import {
    GAUGE_SIZES
} from '@constants/main/root'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    createStylesheet
} from '@styles/themes'
import Gauge from 'gaugeJS'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    Container,
    GaugePercentage,
    SpinnerContainer,
    Text,
    ErrorMessage
} from '@styles/components'
import {
    ActionType,
    ControlCategory,
    SecureScore
} from '@interfaces/dashboard/soc/oss'
import {
    MESSAGE as OSS_MESSAGE,
    TEXT as OSS_TEXT
} from '@constants/dashboard/soc/oss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

/** will also be used in OSS Details. No click events. */
interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined,
    actionType: ActionType
    controlCategory: ControlCategory,
    secureScore: SecureScore | undefined
}

const DataGauge = ({
    chartEl,
    isLoading, isSuccess, error,
    actionType, controlCategory,
    secureScore
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    const [totalScore, setTotalScore] = useState(0)
    const [maxScore, setMaxScore] = useState(0)

    useEffect(() => {
        setTotalScore(
            secureScore?.[controlCategory][actionType].totalScore || 0
        )

        setMaxScore(
            secureScore?.[controlCategory][actionType].maxScore || 0
        )
    }, [secureScore, actionType, controlCategory])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        let length = 0.45
        let strokeColor = stylesheet.style.colorPresets.lightGrey

        if (maxScore === 0) {
            length = 0
        } else if (maxScore > 0 && totalScore === 0) {
            length = 0.45
            strokeColor = stylesheet.style.colorPresets.red
        } else if (totalScore < 0) {
            length = 0
        }

        if (chartEl.current) {
            const opts: Gauge.GaugeOptions = {
                angle: -0.2,
                lineWidth: 0.2,
                radiusScale: 1,
                pointer: {
                    // length: 0.45, // // Relative to gauge radius
                    length: length,
                    strokeWidth: 0.024,
                    color: stylesheet.style.colorPresets.grey // Fill color: ;
                },
                // color changes based on percentage.
                percentColors: [
                    [0.0, stylesheet.style.colorPresets.red],
                    [0.50, stylesheet.style.colorPresets.yellow],
                    [1.0, stylesheet.style.colorPresets.green]
                ],
                limitMax: true,
                limitMin: true,
                strokeColor: strokeColor,
                generateGradient: true,
                highDpiSupport: true,

                // renderTicks is Optional
                renderTicks: {
                    divisions: 10,
                    divWidth: 0.6,
                    divLength: 0.43,
                    divColor: stylesheet.mode.fontColor,
                    subDivisions: 0,
                    subLength: 0.2,
                    subWidth: 0.5,
                    subColor: stylesheet.style.colorPresets.darkGrey
                },
                staticLabels: {
                    font: '11px arial',
                    labels: [0, 20, 40, 60, 80, 100],
                    color: stylesheet.mode.fontColor,
                    fractionDigits: 0 // Optional: Numerical precision. 0=round off.
                },
                colorStart: '',
                colorStop: ''
            }

            const gauge = new Gauge.Gauge(chartEl.current).setOptions(opts) // create sexy gauge!
            gauge.maxValue = 100 // set max gauge value
            gauge.setMinValue(0) // Prefer setter over gauge.minValue = 0
            gauge.animationSpeed = 16 // set animation speed (32 is default value)
            gauge.set((((totalScore / maxScore) || 0) * 100)) // set actual value

            chartEl.current.style.height = GAUGE_SIZES.PRINT.HEIGHT
            chartEl.current.style.width = GAUGE_SIZES.PRINT.WIDTH
        }
    }, undefined)

    const DataContent = useMemo(() => {
        let title = ''

        switch (actionType) {
            case 'Behavior':
                title = OSS_TEXT.SECTIONS.SCORES.BEHAVIOR
                break
            case 'Config':
                title = OSS_TEXT.SECTIONS.SCORES.CONFIG
                break
            case 'Review':
                title = OSS_TEXT.SECTIONS.SCORES.REVIEW
                break
            default:
                break
        }

        const content = <Container bgIndex={2}>
            <Text size={'sm'} className={'d-block text-center pt-2 mb-2'}>
                {title}
            </Text>
            <canvas ref={chartEl}/>
            <GaugePercentage size={'sm'} className={'d-block text-center'}>
                {[
                    (((totalScore / maxScore) || 0) * 100).toFixed(0),
                    '%'
                ].join('')}
            </GaugePercentage>
        </Container>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div className={'w-100 text-center'}>{DataContent}</div>
}

DataGauge.propTypes = {
    controlState: PropTypes.string,
    totalScore: PropTypes.number,
    maxScore: PropTypes.number
}

export default DataGauge


import {
    useGetPartnersListMutation,
    useGetPartnerStateListMutation,
    useGetPartnerTypesListMutation
} from '@apis/watchdog/account-data/partner-api'
import {
    useGetCurrencyTypesListMutation
} from '@apis/watchdog/account-data/order-api'
import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    VALIDATION_SCHEMA,
    TEXT as PARTNER_TEXT
} from '@constants/watchdog/account-data/partner'
import MenuLinks from '@features/main/MenuLinks'
import AddConfirmation from '@features/watchdog/account-data/partner/add/AddConfirmation'
import {
    AddPartnerKeys,
    AddPartnerValues,
    PartnerFormData,
    PartnerList,
    PartnerStateList,
    PartnerTypeList
} from '@interfaces/watchdog/account-data/partner'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    selectTabs,
    selectModals,
    addModal,
    closeModal,
    removeModal,
    selectFormikValues,
    setAddFormik
} from '@slices/watchdog/account-data/partner'
import {
    FormStyledComponents as Form,
    Button
} from '@styles/components'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { CurrencyType } from '@interfaces/watchdog/account-data/order'

const PartnerAdd = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect
    const dispatch = useAppDispatch()

    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)
    const formikValues = useAppSelector(selectFormikValues)

    /** fetch other calls. */
    const [getPartnerStateList, getPartnerStateListMutation] = useGetPartnerStateListMutation()
    const [getPartnerTypesList, getPartnerTypesListMutation] = useGetPartnerTypesListMutation()
    const [getPartnersList, getPartnersListMutation] = useGetPartnersListMutation()
    const [getCurrencyTypesList, getCurrencyTypesListMutation] = useGetCurrencyTypesListMutation()

    const [isAddDone, setIsAddDone] = useState<boolean>(false)

    const partnerFormik = useFormik({
        initialValues: formikValues.add.initialValues,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.ADD,
        onSubmit: () => {
            const formData: PartnerFormData = {
                addConfirm: {
                    formValues: partnerFormik.values
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'PARTNER_ADD',
                formData: formData,
                isBorderWide: false
            }))
        }

    })

    useEffect(() => {
        dispatch(setAddFormik({
            formikValues: partnerFormik.values,
            property: 'values'
        }))
    }, [partnerFormik.values])

    // on mount only.
    useEffect(() => {
        partnerFormik.setValues(formikValues.add.values)
    }, [])

    const strictSetValue = useCallback((
        label: AddPartnerKeys,
        value: AddPartnerValues[AddPartnerKeys]
    ) => {
        partnerFormik.setFieldValue(
            label,
            value
        )
    }, [])

    // 5d9731e27f689b7e5bcae003 is partner id edit just to check.
    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={PARTNER_TEXT.ADD.FORM.ACTIVE.ID}>
                    {PARTNER_TEXT.ADD.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={PARTNER_TEXT.ADD.FORM.ACTIVE.ID}
                    onChange={partnerFormik.handleChange}
                    checked={partnerFormik.values.active}
                />
                <Form.Feedback
                    errors={Boolean(partnerFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        partnerFormik.errors.active ? partnerFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.active, partnerFormik.errors.active])

    const CompanyNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.COMPANY_NAME.ID}>
                    {PARTNER_TEXT.ADD.FORM.COMPANY_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.companyName)}
                    name={'companyName'}
                    id={PARTNER_TEXT.ADD.FORM.COMPANY_NAME.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('companyName', e.target.value.trim())
                    }}
                    value={partnerFormik.values.companyName}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.companyName)} >{
                    partnerFormik.errors.companyName ? partnerFormik.errors.companyName : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.companyName, partnerFormik.errors.companyName])

    const PartnerStateInput = useMemo(() => {
        let partnerStateTypes = [] as PartnerStateList[]

        if (getPartnerStateListMutation.data) {
            partnerStateTypes = getPartnerStateListMutation.data.partnerStateTypes
        }

        const fieldValue: AddPartnerKeys = 'partnerState'

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.PARTNER_STATE.ID}>
                    {PARTNER_TEXT.ADD.FORM.PARTNER_STATE.LABEL}
                </Form.Label>
                <Select
                    id={PARTNER_TEXT.ADD.FORM.PARTNER_STATE.ID}
                    errors={Boolean(partnerFormik.errors.partnerState)}
                    options={partnerStateTypes}
                    value={_.find(
                        partnerStateTypes,
                        (e) => e.value === partnerFormik.values.partnerState
                    )}
                    onChange={(e) => {
                        if (e) {
                            partnerFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerState)} >{
                    partnerFormik.errors.partnerState ? partnerFormik.errors.partnerState : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnerStateListMutation.data,
        partnerFormik.values.partnerState,
        partnerFormik.errors.partnerState
    ])

    /** when populated, the default value should always be active partner. but only do this
     * when partnerFomik.values.parternState is undefined
    */
    useEffect(() => {
        const fieldValue: AddPartnerKeys = 'partnerState'
        // console.log(partnerFormik.values.partnerState)
        if (getPartnerStateListMutation.data && !partnerFormik.values.partnerState) {
            const partnerStateTypes = getPartnerStateListMutation.data.partnerStateTypes

            const found = _.find(partnerStateTypes, (o) => {
                return o.value === '6'
            })

            // console.log(found)
            partnerFormik.setFieldValue(
                fieldValue,
                found?.value
            )
        }
    }, [
        getPartnerStateListMutation.data,
        partnerFormik.values.partnerState
    ])

    const PartnerNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.PARTNER_NAME.ID}>
                    {PARTNER_TEXT.ADD.FORM.PARTNER_NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.partnerName)}
                    name={'partnerName'}
                    id={PARTNER_TEXT.ADD.FORM.PARTNER_NAME.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('partnerName', e.target.value.trim())
                    }}
                    value={partnerFormik.values.partnerName}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerName)} >{
                    partnerFormik.errors.partnerName ? partnerFormik.errors.partnerName : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.partnerName, partnerFormik.errors.partnerName])

    const EmailInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.EMAIL.ID}>
                    {PARTNER_TEXT.ADD.FORM.EMAIL.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.email)}
                    name={'email'}
                    type={'email'}
                    id={PARTNER_TEXT.ADD.FORM.EMAIL.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('email', e.target.value.trim().toLowerCase())
                    }}
                    value={partnerFormik.values.email}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.email)} >{
                    partnerFormik.errors.email ? partnerFormik.errors.email : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.email, partnerFormik.errors.email])

    const StreetNameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.STREET_NAME.ID}>
                    {PARTNER_TEXT.ADD.FORM.STREET_NAME.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.streetName)}
                    name={'streetName'}
                    id={PARTNER_TEXT.ADD.FORM.STREET_NAME.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('streetName', e.target.value.trim())
                    }}
                    value={partnerFormik.values.streetName}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.streetName)} >{
                    partnerFormik.errors.streetName ? partnerFormik.errors.streetName : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.streetName, partnerFormik.errors.streetName])

    const PostalCodeInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.POSTAL_CODE.ID}>
                    {PARTNER_TEXT.ADD.FORM.POSTAL_CODE.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.postalCode)}
                    name={'postalCode'}
                    id={PARTNER_TEXT.ADD.FORM.POSTAL_CODE.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('postalCode', e.target.value.trim())
                    }}
                    value={partnerFormik.values.postalCode}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.postalCode)} >{
                    partnerFormik.errors.postalCode ? partnerFormik.errors.postalCode : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.postalCode, partnerFormik.errors.postalCode])

    const CityInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.CITY.ID}>
                    {PARTNER_TEXT.ADD.FORM.CITY.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.city)}
                    name={'city'}
                    id={PARTNER_TEXT.ADD.FORM.CITY.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('city', e.target.value.trim())
                    }}
                    value={partnerFormik.values.city}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.city)} >{
                    partnerFormik.errors.city ? partnerFormik.errors.city : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.city, partnerFormik.errors.city])

    const CountryInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.COUNTRY.ID}>
                    {PARTNER_TEXT.ADD.FORM.COUNTRY.LABEL}

                </Form.Label>
                <Form.Input
                    errors={Boolean(partnerFormik.errors.country)}
                    name={'country'}
                    id={PARTNER_TEXT.ADD.FORM.COUNTRY.ID}
                    onChange={partnerFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('country', e.target.value.trim())
                    }}
                    value={partnerFormik.values.country}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.country)} >{
                    partnerFormik.errors.country ? partnerFormik.errors.country : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [partnerFormik.values.country, partnerFormik.errors.country])

    // const DiscountInput = useMemo(() => {
    //     return (
    //         <Form.Group>
    //             <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.DISCOUNT.ID}>
    //                 {PARTNER_TEXT.ADD.FORM.DISCOUNT.LABEL}

    //             </Form.Label>
    //             <Form.Input
    //                 errors={Boolean(partnerFormik.errors.discount)}
    //                 name={'discount'}
    //                 id={PARTNER_TEXT.ADD.FORM.DISCOUNT.ID}
    //                 onChange={partnerFormik.handleChange}
    //                 onBlur={(e) => {
    //                     strictSetValue('discount', e.target.value.trim())
    //                 }}
    //                 value={partnerFormik.values.discount}
    //             />
    //             <Form.Feedback errors={Boolean(partnerFormik.errors.discount)} >{
    //                 partnerFormik.errors.discount ? partnerFormik.errors.discount : null
    //             }</Form.Feedback>
    //         </Form.Group>
    //     )
    // }, [partnerFormik.values.discount, partnerFormik.errors.discount])

    const PartnerTypeInput = useMemo(() => {
        let partnerTypes = [] as PartnerTypeList[]

        if (getPartnerTypesListMutation.data) {
            partnerTypes = getPartnerTypesListMutation.data.partnerTypes
        }

        const fieldValue: AddPartnerKeys = 'partnerType'

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.PARTNER_TYPE.ID}>
                    {PARTNER_TEXT.ADD.FORM.PARTNER_TYPE.LABEL}
                </Form.Label>
                <Select
                    id={PARTNER_TEXT.ADD.FORM.PARTNER_TYPE.ID}
                    errors={Boolean(partnerFormik.errors.partnerType)}
                    options={partnerTypes}
                    value={_.find(
                        partnerTypes,
                        (e) => e.value === partnerFormik.values.partnerType
                    )}
                    onChange={(e) => {
                        if (e) {
                            partnerFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerType)} >{
                    partnerFormik.errors.partnerType ? partnerFormik.errors.partnerType : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnerTypesListMutation.data,
        partnerFormik.values.partnerType,
        partnerFormik.errors.partnerType
    ])

    useEffect(() => {
        /** if reseller, partner of should be defined but only distributors are allowed.
         * if distributor, the partner of must be empty and disabled
         */
        if (partnerFormik.values.partnerType === '2') {
            /** formik should change */

            /** check if partnerof is defined or not */
            if (partnerFormik.values.partnerOf.length > 0) {
                const fieldValue: AddPartnerKeys = 'currencies'
                partnerFormik.setFieldValue(
                    fieldValue,
                    []
                )
            } else {
                // if partnerOf is an empty field, change formik
                // where currency should be defined.
            }
        } else if (partnerFormik.values.partnerType === '3') {
            const fieldValue: AddPartnerKeys = 'partnerOf'
            partnerFormik.setFieldValue(
                fieldValue,
                ''
            )
        }
    }, [
        partnerFormik.values.partnerType,
        partnerFormik.values.partnerOf
    ])

    const PartnerOfInput = useMemo(() => {
        let partners = [] as PartnerList[]

        if (getPartnersListMutation.data) {
            partners = getPartnersListMutation.data.partnerData
        }

        const fieldValue: AddPartnerKeys = 'partnerOf'

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.PARTNER_OF.ID}>
                    {PARTNER_TEXT.ADD.FORM.PARTNER_OF.LABEL}

                </Form.Label>
                <Select
                    isDisabled={partnerFormik.values.partnerType === '3'}
                    id={PARTNER_TEXT.ADD.FORM.PARTNER_OF.ID}
                    errors={Boolean(partnerFormik.errors.partnerOf)}
                    options={partners}
                    value={_.find(
                        partners,
                        (e) => e.value === partnerFormik.values.partnerOf
                    )}
                    onChange={(e) => {
                        if (e) {
                            partnerFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.partnerOf)} >{
                    partnerFormik.errors.partnerOf ? partnerFormik.errors.partnerOf : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnersListMutation.data,
        partnerFormik.values.partnerOf,
        partnerFormik.values.partnerType,
        partnerFormik.errors.partnerOf
    ])

    const CurrenciesSelect = useMemo(() => {
        let currencyTypes = [] as CurrencyType[]

        if (getCurrencyTypesListMutation.data) {
            currencyTypes = getCurrencyTypesListMutation.data.currencyTypes
        }

        const fieldValue: AddPartnerKeys = 'currencies'

        const isDisabled = partnerFormik.values.partnerType === '2' &&
        partnerFormik.values.partnerOf.length > 0

        return (
            <Form.Group>
                <Form.Label htmlFor={PARTNER_TEXT.ADD.FORM.CURRENCIES.ID}>
                    {PARTNER_TEXT.ADD.FORM.CURRENCIES.LABEL}

                </Form.Label>
                <Select
                    // isMulti
                    isDisabled={isDisabled}
                    id={PARTNER_TEXT.ADD.FORM.CURRENCIES.ID}
                    errors={Boolean(partnerFormik.errors.currencies)}
                    options={!isDisabled ? currencyTypes : []}
                    value={partnerFormik.values.currencies}
                    onChange={(e) => {
                        if (e) {
                            partnerFormik.setFieldValue(
                                fieldValue,
                                // to avoid nullable error from formik.
                                [e]
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(partnerFormik.errors.currencies)} >{
                    partnerFormik.errors.currencies ? partnerFormik.errors.currencies : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getCurrencyTypesListMutation.data,
        partnerFormik.values.currencies,
        partnerFormik.errors.currencies,
        partnerFormik.values.partnerType,
        partnerFormik.values.partnerOf
    ])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                        // disable the button if successful add operation.
                        disabled={isAddDone}
                    >{PARTNER_TEXT.ADD.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={() => {
                            partnerFormik.resetForm()
                            setIsAddDone(false)
                        }}
                    >{TEXT.FORM.RESET}</Button>
                </div>

            </Form.Group>
        )
    }, undefined)

    const unsubscribeGetCurrencyTypes = () => {
        const unsubscribeMutation = getCurrencyTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnerStateList = () => {
        const unsubscribeMutation = getPartnerStateList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnerTypesList = () => {
        const unsubscribeMutation = getPartnerTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetPartnersList = () => {
        const unsubscribeMutation = getPartnersList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetCurrencyTypes()
        unsubscribeGetPartnerStateList()
        unsubscribeGetPartnerTypesList()
        unsubscribeGetPartnersList()
        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getCurrencyTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnerStatePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnerTypesListPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getPartnersListPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getCurrencyTypesPromise = getCurrencyTypesList({
                        authToken: newToken
                    })
                    getPartnerStatePromise = getPartnerStateList({
                        authToken: newToken
                    })
                    getPartnerTypesListPromise = getPartnerTypesList({
                        authToken: newToken
                    })
                    getPartnersListPromise = getPartnersList({
                        authToken: newToken,
                        partnerType: [
                            /** from BDGDB-269 where we filter out the
                             * partners should only be distributors  */
                            // { type: '2' },
                            { type: '3' }
                        ]
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCurrencyTypesPromise && getCurrencyTypesPromise.abort()
            getPartnerStatePromise && getPartnerStatePromise.abort()
            getPartnerTypesListPromise && getPartnerTypesListPromise.abort()
            getPartnersListPromise && getPartnersListPromise.abort()
        }
    }, [token.valid])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (getCurrencyTypesListMutation.error) {
            console.error(getCurrencyTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCurrencyTypesListMutation.error])

    useEffect(() => {
        if (getPartnerStateListMutation.error) {
            console.error(getPartnerStateListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPartnerStateListMutation.error])

    useEffect(() => {
        if (getPartnerTypesListMutation.error) {
            console.error(getPartnerTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPartnerTypesListMutation.error])

    useEffect(() => {
        if (getPartnersListMutation.error) {
            console.error(getPartnersListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPartnersListMutation.error])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'PARTNER_ADD') {
                    component = <AddConfirmation
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                        isAddDone={isAddDone}
                        setIsAddDone={setIsAddDone}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    return (
        <div>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={partnerFormik.handleSubmit}>
                        {CompanyNameInput}
                        {PartnerNameInput}
                        {EmailInput}
                        {StreetNameInput}
                        {PostalCodeInput}
                        {CityInput}
                        {CountryInput}
                        {PartnerStateInput}
                        {PartnerTypeInput}
                        {PartnerOfInput}
                        {CurrenciesSelect}
                        {/* {DiscountInput} */}
                        {ActiveInput}
                        {DetailsFormButtons}
                    </Form.Main>
                </div>
            </div>
            {renderModals}
        </div>
    )
}
export default PartnerAdd

import { MutationContext } from '@root/MutationProvider'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
/** OTHER FILE IMPORTS */
import { useDoArchiveMutation } from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    MESSAGE as EVENT_MESSAGE,
    TEXT as EVENT_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/soc-data/event'
import { EventModal } from '@interfaces/watchdog/soc-data/event'
import {
    setRefetch
} from '@slices/watchdog/soc-data/event/main'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    Button,
    FormStyledComponents as Form,
    SpinnerContainer
} from '@styles/components'
import { useFormik } from 'formik'

const DoArchive = ({ modal, addModal, closeModal } : {
    modal: EventModal,
    addModal: ActionCreatorWithPayload<EventModal, string>,
    closeModal: ActionCreatorWithPayload<EventModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [doArchive, doArchiveMutation] = useDoArchiveMutation()

    const archiveFormik = useFormik({
        initialValues: INITIAL_VALUES.COMMENT,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.COMMENT,
        onSubmit: async () => {
            const newToken = await revalidateToken()

            if (modal.eventObj) {
                doArchive({
                    authToken: newToken,
                    id: modal.eventObj.id,
                    clusterid: modal.eventObj.clusterid,
                    comment: archiveFormik.values.comment
                })
            }
        }
    })

    const SubmitButton = useMemo(() => {
        const buttonContent = doArchiveMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EVENT_TEXT.ARCHIVE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : EVENT_TEXT.ARCHIVE.FORM.SUBMIT_BUTTON
        return (
            <Button
                type={'submit'}
                mode={'primary'}
                disabled={doArchiveMutation.isLoading}
            >{buttonContent}</Button>
        )
    }, [doArchiveMutation.isLoading])

    useEffect(() => {
        if (doArchiveMutation.data) {
            const data = doArchiveMutation.data
            if (data.status) {
                toast.success(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
                /** what you usually do is disable the validation, refetch the data
                 * and empty the selected object
                 */
                dispatch(closeModal(modal))
                if (modal.detailsModal) {
                    dispatch(closeModal(modal.detailsModal))
                }
                dispatch(setRefetch(true))
            } else {
                toast.error(
                    EVENT_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [doArchiveMutation.data])

    useEffect(() => {
        if (doArchiveMutation.error) {
            console.error(doArchiveMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doArchiveMutation.error])

    const CommentInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-12 col-md-3 ps-0'}
                    htmlFor={EVENT_TEXT.COMMENT.FORM.COMMENT.ID}>
                    {EVENT_TEXT.COMMENT.FORM.COMMENT.LABEL}
                </Form.Label>
                <Form.TextArea
                    errors={Boolean(archiveFormik.errors.comment)}
                    className={'col-md-9 col-12'}
                    name={'comment'}
                    id={EVENT_TEXT.COMMENT.FORM.COMMENT.ID}
                    onChange={archiveFormik.handleChange}
                    value={archiveFormik.values.comment}
                />
                <Form.Feedback
                    className={'col-md-9 offset-md-3'}
                    errors={Boolean(archiveFormik.errors.comment)} >
                    {
                        archiveFormik.errors.comment ? archiveFormik.errors.comment : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [archiveFormik.values.comment, archiveFormik.errors.comment])

    return (
        <div>
            <h5>{EVENT_TEXT.ARCHIVE.TITLE}</h5>
            <small className={'d-block my-2'}>
                {EVENT_TEXT.ARCHIVE.MESSAGE}
            </small>
            <Form.Main onSubmit={archiveFormik.handleSubmit} className={'my-0 px-0'}>
                {CommentInput}
                <div className={'row justify-content-end'}>
                    <div className={'col-auto mb-2 mb-md-0 text-center'}>
                        {SubmitButton}
                    </div>
                    <div className={'col-auto'}>
                        <Button mode={'secondary'} onClick={() => {
                            dispatch(closeModal(modal))
                        }}>{TEXT.MODAL.CLOSE}</Button>
                    </div>
                </div>
            </Form.Main>
        </div>
    )
}

export default DoArchive

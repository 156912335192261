
import {
    useGetDataIdMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useGetEventsMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    DATA_ID_EXCLUSIONS,
    MESSAGE as DASHBOARD_MESSAGE,
    TEXT as DASHBOARD_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as O365_MESSAGE /* TEXT as O365_TEXT */
} from '@constants/dashboard/soc/o365/main'
import { getUtcRanges } from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS,
    CHART_HEIGHT
} from '@constants/main/root'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { displayUnpredictable } from '@features/main/DisplayUnpredictable'
import {
    MonitorModal,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    GetDataIdRequest
} from '@interfaces/dashboard/soc/o365/main'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectSearchParams,
    setEndDate,
    setRefetch,
    setStartDate,
    resetO365DataId,
    setCurrentParams
} from '@slices/dashboard/soc/o365/dataId'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    SpinnerContainer,
    Text
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import Tippy from '@tippyjs/react'
import {
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import { MdRefresh } from 'react-icons/md'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import L from 'leaflet'
import 'leaflet-providers'
import 'leaflet.markercluster/dist/leaflet.markercluster-src'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { AiTwotoneAlert } from 'react-icons/ai'

const O365DataID = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    /** no use for currentParams yet unless there is an event that requires it. */
    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    /** will be initiailized multiple times. */
    const [getDataId, getDataIdMutation] = useGetDataIdMutation()
    const [getEvents] = useGetEventsMutation()

    useEffect(() => {
        if (getDataIdMutation.error) {
            console.error(getDataIdMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getDataIdMutation.error])

    /** WILL HAVE date ranges like in the parent modal. Starting ranges are the data
     *  of ip address being passed over */

    const unsubscribeGetDataId = () => {
        const unsubscribeMutation = getDataId({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetDataId()

        let getDataIdPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const dataIdRequestData: GetDataIdRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            id: modal.serviceTypeFormData
                                ?.o365?.dataId?.id || '',
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()

                        }

                        getDataIdPromise = getDataId(dataIdRequestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getDataIdPromise && getDataIdPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getDataIdMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getDataIdMutation.isSuccess
    ])

    /** button to escalate the selected data ID. Confirmation window needed. */
    const EscalateButton = useMemo(() => {
        const disableRefresh = getDataIdMutation.isLoading
        return (
            <span
                onClick={async () => {
                    const newRanges = getUtcRanges(searchParams.ranges)

                    // get other relevant data using search events api call.
                    const newToken = await revalidateToken()
                    const eventData = await getEvents({
                        authToken: newToken,
                        type: 'current',
                        filters: false,
                        fromTime: newRanges.start.toString(),
                        toTime: newRanges.end.toString(),
                        boxname: modal.card.deviceid,
                        eventid: modal.serviceTypeFormData
                            ?.azure?.dataId?.id || ''
                    }).unwrap()

                    if (eventData.eventData[0]) {
                        const serviceTypeFormData:ServiceTypeFormData = {
                            azure: {
                                eventEscalate: {
                                    eventObj: {
                                        clusterid: eventData.eventData[0].clusterid,
                                        timestamp: eventData.eventData[0].timestamp,
                                        hostname: eventData.eventData[0].hostname,
                                        eventname: eventData.eventData[0].eventname,
                                        src_ip: eventData.eventData[0].src_ip,
                                        src_port: eventData.eventData[0].src_port,
                                        dest_ip: eventData.eventData[0].dest_ip,
                                        dest_port: eventData.eventData[0].dest_port,
                                        protocol: eventData.eventData[0].protocol,
                                        flow_id: eventData.eventData[0].flow_id,
                                        id: eventData.eventData[0].id,
                                        severity: eventData.eventData[0].severity,
                                        domain: eventData.eventData[0].domain
                                    }
                                }
                            }
                        }

                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            card: modal.card,
                            operation: 'EVENT_ESCALATE',
                            serviceTypeFormData: serviceTypeFormData,
                            isBorderWide: true
                        }))
                    } else {
                        toast.error(
                            DASHBOARD_MESSAGE.EVENT_NOT_FOUND,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <AiTwotoneAlert/>
            </span>
        )
    }, [modal, getDataIdMutation, searchParams])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getDataIdMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update o365 bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [getDataIdMutation])

    const ActionButtons = useMemo(() => {
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{DASHBOARD_TEXT.ESCALATE.LABEL}</div>}>
                    <div className={'d-inline-block'}>{EscalateButton}</div>
                </Tippy>
            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        EscalateButton
    ])

    /** create two components. one to display the data id contents (ALL OF IT)
     * and a leaflet instance.
     */
    const descriptionEl = useRef<HTMLDivElement>(null)

    const DataIdContent = useMemo(() => {
        const response = getDataIdMutation.data?.data

        // recursive methods are necessary if the data is too dynamic
        // static typing will not work here.

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.DATA_ID}</span>
                </SpinnerContainer>
            </small>
        )

        // could be any value at this point.
        const rowContent = (key: any, label: string, value: any) => {
            return (
                <div key={key} className={'row mb-1 justify-content-between'}>
                    <Text size={'xs'} className={'col-6 text-capitalize'}>{label}</Text>
                    <Text size={'xs'} className={'col-6'}>{value || TEXT.NONE}</Text>
                </div>
            )
        }

        // show all except for ["payload","payload_printable","packet","http_request_body",
        // "http_response_body","http_request_body_printable","http_response_body_printable"]
        const content = (
            <>
                {
                    // content has to be an object
                    response
                        ? _.map(_.entries(response), ([label, value], index) => {
                            const key = ['dataId-content-', index].join('')

                            if (!DATA_ID_EXCLUSIONS.includes(_.toLower(label))) {
                                return displayUnpredictable(
                                    rowContent, key, label, value
                                )
                            }
                        })
                        : MESSAGE.DATA.EMPTY_DATA
                }
            </>
        )

        return (
            <Container bgIndex={2} className={'px-3 py-3'}>
                {
                    !getDataIdMutation.isLoading
                        ? getDataIdMutation.isSuccess
                            ? content
                            : JSON.stringify(getDataIdMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, undefined)

    /** create new leaflet map with markers */
    const mapEl = useRef<HTMLDivElement>(null)

    const isMobileDevice = useMediaQuery({
        query: (
            () => {
                const stylesheet = createStylesheet(style, mode)
                return [
                    '(max-width:', stylesheet.style.mediaQueries.xs, ')'
                ].join('')
            }
        )()
    })
    const [mapid] = useState(uniqueString())
    const [mapInstance, setMapInstance] = useState<L.Map>()

    useEffect(() => {
        const response = getDataIdMutation.data?.data
        const geoip = response?.geoip || {}

        setTimeout(() => {
            if (mapEl.current && descriptionEl.current) {
                mapEl.current.style.height = isMobileDevice
                    ? CHART_HEIGHT.md
                    : descriptionEl.current.clientHeight + 'px'
            }

            if (mapEl.current && Object.keys(geoip).length) {
                setMapInstance(L.map(mapid))
            }
        }, 1000)
    }, [getDataIdMutation])

    useEffect(() => {
        const response = getDataIdMutation.data?.data
        const geoip = response?.geoip || {}

        if (mapInstance && Object.keys(geoip).length) {
            mapInstance.setView([
                geoip.location?.[1] || geoip.location.lat,
                geoip.location?.[0] || geoip.location.lon
            ], 6)
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(mapInstance)
            L.marker([
                geoip.location?.[1] || geoip.location.lat,
                geoip.location?.[0] || geoip.location.lon
            ]).addTo(mapInstance)

            return () => {
                mapInstance.off()
                mapInstance.remove()
                setMapInstance(undefined)
            }
        }
    }, [getDataIdMutation, mapInstance])
    // MESSAGE.DATA.EMPTY_GEOIP

    const DataIdMap = useMemo(() => {
        return (
            <div>
                {
                    !mapInstance
                        ? <Text className={'position-absolute'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                        : ''
                }
                <div ref={mapEl} id={mapid} />
            </div>

        )
    }, [mapInstance])

    useEffect(() => {
        return () => {
            dispatch(resetO365DataId())
        }
    }, [])

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-end mb-3'}>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            <div className={'row mb-3'} ref={descriptionEl}>
                <div className={'col-md-6 col-12'}>
                    {DataIdContent}
                </div>
                <div className={'col-md-6 col-12'}>
                    {DataIdMap}
                </div>
            </div>

        </div>
    )
}

export default O365DataID

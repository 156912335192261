import { AZURE_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import AzureMain from '@features/dashboard/soc/azure/detailed-dashboard/main/AzureMain'
import AzureKubernetes from
    '@features/dashboard/soc/azure/detailed-dashboard/kubernetes/AzureKubernetes'
const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: AZURE_DETAILED_DASHBOARD_ROUTES.MAIN.link,
        component: AzureMain
    },
    {
        exact: true,
        path: AZURE_DETAILED_DASHBOARD_ROUTES.KUBERNETES.link,
        component: AzureKubernetes
    }
]

export default Routes

import { PRINT_ROUTES } from '@constants/main/routes'
import VssModal from '@features/dashboard/soc/vss/print/VssModal'
import VulnDesc from '@features/dashboard/soc/vss/print/VulnDesc'
import ExtVssModal from '@features/dashboard/soc/ext-vss/print/ExtVssModal'
import GdprModal from '@features/dashboard/grc/gdpr/print/GdprModal'
import ExtVssVulnDesc from '@features/dashboard/soc/ext-vss/print/VulnDesc'
import GdprDetails from '@features/dashboard/grc/gdpr/print/GdprDetails'
import NistModal from '@features/dashboard/grc/nist/print/NistModal'
import NistDetails from '@features/dashboard/grc/nist/print/NistDetails'
import PcidssModal from '@features/dashboard/grc/pcidss/print/PcidssModal'
import PcidssDetails from '@features/dashboard/grc/pcidss/print/PcidssDetails'
import O365Modal from '@features/dashboard/soc/o365/print/O365Modal'
import O365Main from
    '@features/dashboard/soc/o365/detailed-dashboard/main/print/O365Main'
import O365Sharepoint from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/print/O365Sharepoint'
import O365Exchange from
    '@features/dashboard/soc/o365/detailed-dashboard/exchange/print/O365Exchange'
import AzureModal from '@features/dashboard/soc/azure/print/AzureModal'
import AzureMain from
    '@features/dashboard/soc/azure/detailed-dashboard/main/print/AzureMain'
import AzureKubernetes from
    '@features/dashboard/soc/azure/detailed-dashboard/kubernetes/print/AzureKubernetes'
import MDRModal from '@features/dashboard/soc/mdr/print/MDRModal'
import MDRMain from
    '@features/dashboard/soc/mdr/detailed-dashboard/print/MDRMain'
import OSSModal from '@features/dashboard/soc/oss/print/OSSModal'
import OSSControlCategory from '@features/dashboard/soc/oss/print/OSSControlCategory'
import WssModal from '@features/dashboard/soc/wss/print/WssModal'
import WssVulnDesc from '@features/dashboard/soc/wss/print/WssDesc'
import RDDModal from '@features/dashboard/soc/rdd/print/RDDModal'
import ImmModal from '@features/dashboard/tac/imm/print/ImmModal'
import EvmModal from '@features/dashboard/tac/evm/print/EvmModal'
import VmmModal from '@features/dashboard/tac/vmm/print/VmmModal'

import DashboardReportMenu from '@features/dashboard/print/ReportMenu'
import EventReportMenu from '@features/watchdog/soc-data/print/ReportMenu'
import ContentPage from '@features/watchdog/soc-data/reports/print/ContentPage'
import CoverPage from '@features/watchdog/soc-data/reports/print/CoverPage'

const Routes: {
    exact: boolean;
    path: string;
    component: () => JSX.Element;
}[] = [
    {
        exact: true,
        path: PRINT_ROUTES.MENU.DASHBOARD,
        component: DashboardReportMenu
    }, {
        exact: true,
        path: PRINT_ROUTES.MENU.EVENT_REPORT,
        component: EventReportMenu
    },
    /** print routes for event-reporting. main and cover-page */
    {
        exact: true,
        path: [
            PRINT_ROUTES.EVENT_REPORT.COVER,
            '/:reportObj'
        ].join(''),
        component: CoverPage
    },
    {
        exact: true,
        path: [
            PRINT_ROUTES.EVENT_REPORT.CONTENT,
            '/:reportObj'
        ].join(''),
        component: ContentPage
    },
    /**
     * print routes and render report versions of dashboard modules.
     */
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.VSS.MAIN,
        component: VssModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.VSS.DETAILS,
        component: VulnDesc
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.EXT_VSS.MAIN,
        component: ExtVssModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.EXT_VSS.DETAILS,
        component: ExtVssVulnDesc
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.CSM_GDPR.MAIN,
        component: GdprModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.CSM_GDPR.DETAILS,
        component: GdprDetails
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.CSM_NIST.MAIN,
        component: NistModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.CSM_NIST.DETAILS,
        component: NistDetails
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.CSM_PCIDSS.MAIN,
        component: PcidssModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.CSM_PCIDSS.DETAILS,
        component: PcidssDetails
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365.MAIN,
        component: O365Modal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365_DETAILED.MAIN,
        component: O365Main
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365_DETAILED.SHAREPOINT,
        component: O365Sharepoint
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365_DETAILED.EXCHANGE,
        component: O365Exchange
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365_AZURE.MAIN,
        component: AzureModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365_AZURE_DETAILED.MAIN,
        component: AzureMain
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.O365_AZURE_DETAILED.KUBERNETES,
        component: AzureKubernetes
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.MDR.MAIN,
        component: MDRModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.MDR_DETAILED.MAIN,
        component: MDRMain
    }, {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.OSS.MAIN,
        component: OSSModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.OSS.DETAILS,
        component: OSSControlCategory
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.EXT_WSS.MAIN,
        component: WssModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.EXT_WSS.DETAILS,
        component: WssVulnDesc
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.RDD.MAIN,
        component: RDDModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.IMM.MAIN,
        component: ImmModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.EXT_VMM.MAIN,
        component: EvmModal
    },
    {
        exact: true,
        path: PRINT_ROUTES.DASHBOARD.VMM.MAIN,
        component: VmmModal
    }
]

export default Routes

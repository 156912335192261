import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as NIST_MESSAGE
} from '@constants/dashboard/grc/nist'
import {
    GAUGE_SIZES
} from '@constants/main/root'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import Gauge from 'gaugeJS'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'

/** will also be used in OSS Details. No click events. */
interface ComponentProps {
    activeValue: number,
    chartEl: React.RefObject<HTMLCanvasElement>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const DataGauge = ({
    activeValue, chartEl, isLoading, isSuccess, error
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const length = 0.45
        const strokeColor = stylesheet.style.colorPresets.lightGrey

        if (chartEl.current) {
            const opts: Gauge.GaugeOptions = {
                angle: -0.2,
                lineWidth: 0.2,
                radiusScale: 1,
                pointer: {
                    // length: 0.45, // // Relative to gauge radius
                    length: length,
                    strokeWidth: 0.024,
                    color: stylesheet.style.colorPresets.grey // Fill color: ;
                },
                // color changes based on percentage.
                percentColors: [
                    [0.0, stylesheet.style.colorPresets.red],
                    [0.50, stylesheet.style.colorPresets.yellow],
                    [1.0, stylesheet.style.colorPresets.green]
                ],
                limitMax: true,
                limitMin: true,
                strokeColor: strokeColor,
                generateGradient: true,
                highDpiSupport: true,

                // renderTicks is Optional
                renderTicks: {
                    divisions: 10,
                    divWidth: 0.6,
                    divLength: 0.43,
                    divColor: stylesheet.mode.fontColor,
                    subDivisions: 0,
                    subLength: 0.2,
                    subWidth: 0.5,
                    subColor: stylesheet.style.colorPresets.darkGrey
                },
                staticLabels: {
                    font: '11px arial',
                    labels: [0, 20, 40, 60, 80, 100],
                    color: stylesheet.mode.fontColor,
                    fractionDigits: 0 // Optional: Numerical precision. 0=round off.
                },
                colorStart: '',
                colorStop: ''
            }

            const gauge = new Gauge.Gauge(chartEl.current).setOptions(opts) // create sexy gauge!
            gauge.maxValue = 100 // set max gauge value
            gauge.setMinValue(0) // Prefer setter over gauge.minValue = 0
            gauge.animationSpeed = 16 // set animation speed (32 is default value)
            gauge.set(activeValue) // set actual value

            chartEl.current.style.height = GAUGE_SIZES.NORMAL.HEIGHT
            chartEl.current.style.width = GAUGE_SIZES.NORMAL.WIDTH
        }
        // }, [activeValue])
    /** PROBLEM with this in print is that this lifecycle will run once.
     * and that's when activeValue changes but in this case, it doesn't.
     */
    }, [activeValue, isLoading])

    const DataContent = useMemo(() => {
        const content = (
            <canvas ref={chartEl}/>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{NIST_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div className={'w-100 text-center'}>{DataContent}</div>
}

DataGauge.propTypes = {
    controlState: PropTypes.string,
    totalScore: PropTypes.number,
    maxScore: PropTypes.number
}

export default DataGauge


import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    DEVICE_TYPES_COLUMNS,
    MESSAGE as RDD_MESSAGE
} from '@constants/dashboard/soc/rdd'
import {
    assignDelta,
    assignValue
} from '@constants/main/method'
import {
    Aggregation,
    MonitorModal,
    RddDetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    IPAddressResponse,
    MacVendorResponse
} from '@interfaces/dashboard/soc/rdd'
import {
    ColorPresets
} from '@interfaces/main/root'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/rdd/details'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/rdd/main'
import {
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useMemo
} from 'react'
import uniqueString from 'unique-string'

interface ComponentProps {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    macVendorData: MacVendorResponse | undefined,
    macVendorDataPrevious: MacVendorResponse | undefined,
    ipAddressData: IPAddressResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const DeviceTypesTable = ({
    macVendorData, macVendorDataPrevious, ipAddressData,
    isLoading, isSuccess, error,
    modal, addModal, closeModal, changeModalColor
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)

    const dispatch = useAppDispatch()

    const DataTable = useMemo(() => {
        /** first time encountering delta symbols (denotes changes in time)
         * we have four states: positive, negative, neutral and dark
        */

        const currentData = macVendorData?.aggregations || {
            3: {
                doc_count_error_upper_bound: 0,
                sum_other_doc_count: 0,
                buckets: []
            }
        }

        const previousData = macVendorDataPrevious?.aggregations || {
            3: {
                doc_count_error_upper_bound: 0,
                sum_other_doc_count: 0,
                buckets: []
            }
        }

        // intersect macAddress and macNew data. unfortuntaely, _.intersection doesn't
        // work with objects as object initialization contains a unique address.
        // use _intersectionWith AND _.isEqual
        const ipAddresses = ipAddressData?.data || []

        const tableData: (Aggregation & {prevDocCount: number})[] = []

        _.forEach(
            _.intersectionWith(
                currentData['3'].buckets,
                ipAddresses,
                (arrVal, otherVal) => {
                    return arrVal.key === otherVal.mac_type
                }
            )
            , (bucket) => {
                tableData.push({
                    ...bucket,
                    prevDocCount: _.find(previousData['3'].buckets, (prevBucket) => {
                        return prevBucket.key === bucket.key
                    })?.doc_count || 0
                })
            })

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(DEVICE_TYPES_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'deviceType-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(tableData, (bucket, index) => {
                            const key = [
                                'deviceType-td-', index
                            ].join('')

                            // calculate percentage.
                            const percentage = (
                                (bucket.doc_count - bucket.prevDocCount) /
                             bucket.prevDocCount
                            ) * 100
                            const deltaStatus = assignDelta(percentage)

                            const onClick = () => {
                                dispatch(setDetailsStartDate(currentParams.ranges.start))
                                dispatch(setDetailsEndDate(currentParams.ranges.end))

                                const detailsContent:RddDetailsForm = {
                                    mac_type: bucket.key,
                                    mac_add: ''
                                }

                                const serviceTypeFormData:ServiceTypeFormData = {
                                    rdd: {
                                        details: detailsContent
                                    }
                                }

                                dispatch(addModal({
                                    id: uniqueString(),
                                    open: true,
                                    card: modal.card,
                                    operation: 'DETAILS',
                                    serviceTypeFormData: serviceTypeFormData,
                                    isBorderWide: true
                                }))
                            }

                            return (
                                <tr onClick={onClick} key={key}>
                                    <td>{bucket.key}</td>
                                    <td className={'pe-4'}>{
                                        <div className={'row align-items-center'}>
                                            <span className={'col-6'}>
                                                {bucket.doc_count}
                                            </span>
                                            <span className={[
                                                'col-6 delta',
                                                deltaStatus
                                            ].join(' ')}>
                                                {assignValue(percentage)}
                                            </span>
                                        </div>
                                    }</td>
                                    <td>{bucket.prevDocCount}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        macVendorData,
        macVendorDataPrevious,
        ipAddressData
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{RDD_MESSAGE.FETCH.MAC_VENDORS}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

DeviceTypesTable.propTypes = {
    macVendorData: PropTypes.object,
    macVendorDataPrevious: PropTypes.object,
    ipAddressData: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default DeviceTypesTable

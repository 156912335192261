/** be best done from scratch INSTEAD OF copy pasting from a print component
 * where it came from a modal
 */
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetExchangeActivityMutation,
    useGetGeneralActivityMutation,
    useGetLoginLocationsMutation,
    useGetModalHeaderMutation,
    useGetOffice365EventsMutation,
    useGetOperationsActivityMutation,
    useGetSecurityCenterActivityMutation,
    useGetSharepointActivityMutation,
    useGetWorkloadActivityMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    PRINT_REPORT_TITLE,
    TEXT as O365_MAIN_TEXT
} from '@constants/dashboard/soc/o365/detailedDashboard/main'
import {
    MESSAGE as O365_MESSAGE
} from '@constants/dashboard/soc/o365/main'
import {
    getUtcRanges,
    testImage,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import LoginLocationsMap from
    '@features/dashboard/soc/o365/detailed-dashboard/main/LoginLocationsMap'
import O365BarChart from '@features/dashboard/soc/o365/detailed-dashboard/main/print/O365BarChart'
import {
    CardDetails,
    LuceneQuery
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    FixedCollapsibles,
    GetDetailedDashboardRequest,
    Office365EventFilter
} from '@interfaces/dashboard/soc/o365/detailedDashboard/main'
import {
    GetModalRequest
    // ModalHeaderResponse
} from '@interfaces/dashboard/soc/o365/main'

import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'

import {
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles,
    selectMapData,
    setCurrentParams,
    setExchangeActivityData,
    setGeneralActivityData,
    setIsComplete,
    setLoginLocationsData,
    setMapData,
    setOffice365EventsData,
    setOperationsActivityData,
    setScrollId,
    setSecurityCenterActivityData,
    setSharepointActivityData,
    setTotalRecords,
    setWorkloadActivityData,
    toggleCollapsible
} from '@slices/dashboard/soc/o365/detailedDashboard/main'
// import {
//     selectMode,
//     selectStyle
// } from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    FilterCell,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import { Buffer } from 'buffer'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectO365DetailedMain,
    setCardDetails
} from '@slices/main/print/section'
import { useDebouncedCallback } from 'use-debounce'

const O365Modal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    // const mode = useAppSelector(selectMode)
    // const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)
    const mapData = useAppSelector(selectMapData)

    const dashboardMain = useAppSelector(selectO365DetailedMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [getExchangeActivity, getExchangeActivityMutation] = useGetExchangeActivityMutation()
    const [getGeneralActivity, getGeneralActivityMutation] = useGetGeneralActivityMutation()
    const [
        getOperationsActivity,
        getOperationsActivityMutation
    ] = useGetOperationsActivityMutation()
    const [getOffice365Events, getOffice365EventsMutation] = useGetOffice365EventsMutation()
    const [
        getSecurityCenterActivity,
        getSecurityCenterActivityMutation
    ] = useGetSecurityCenterActivityMutation()
    const [
        getSharepointActivity,
        getSharepointActivityMutation
    ] = useGetSharepointActivityMutation()
    const [getWorkloadActivity, getWorkloadActivityMutation] = useGetWorkloadActivityMutation()
    const [getLoginLocations, getLoginLocationsMutation] = useGetLoginLocationsMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getExchangeActivityMutation.error) {
            console.error(getExchangeActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getExchangeActivityMutation.error])

    useEffect(() => {
        if (getGeneralActivityMutation.error) {
            console.error(getGeneralActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getGeneralActivityMutation.error])

    useEffect(() => {
        if (getOperationsActivityMutation.error) {
            console.error(getOperationsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOperationsActivityMutation.error])

    useEffect(() => {
        if (getOffice365EventsMutation.error) {
            console.error(getOffice365EventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOffice365EventsMutation.error])

    useEffect(() => {
        if (getSecurityCenterActivityMutation.error) {
            console.error(getSecurityCenterActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSecurityCenterActivityMutation.error])

    useEffect(() => {
        if (getSharepointActivityMutation.error) {
            console.error(getSharepointActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSharepointActivityMutation.error])

    useEffect(() => {
        if (getWorkloadActivityMutation.error) {
            console.error(getWorkloadActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getWorkloadActivityMutation.error])

    useEffect(() => {
        if (getLoginLocationsMutation.error) {
            console.error(getLoginLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getLoginLocationsMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetDetailedDashboardRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        const should: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => obj.not),
            turnIntoLuceneQuery)

        /**
         * for the print version, makie sure that the printOptions properties are defined.
         */

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: dashboardMain.searchParams?.search || ''
                ? Buffer
                    .from(dashboardMain.searchParams?.search || '')
                    .toString('base64')
                : '',
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        if (
            dashboardMain.searchParams &&
            dashboardMain.card
        ) {
            const newRanges = getUtcRanges({
                start: dashboardMain.searchParams.timeFrom,
                end: dashboardMain.searchParams.timeTo
            })

            /** details will be {} as we will get
         * the details data from dashboardCardDetails
         */

            dispatch(setCurrentParams({
                ranges: newRanges,
                q: dashboardMain.searchParams.search || '',
                card: {
                    deviceid: dashboardMain.card.deviceid,
                    in_face: dashboardMain.card.inFace,
                    service_type: dashboardMain.card.serviceType,
                    details: {}
                },
                boolList: (dashboardMain.searchParams.filters || []) as Office365EventFilter[]
            }))
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** i'm glad we check for dashboardMain.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetExchangeActivity = () => {
        const unsubscribeMutation = getExchangeActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setExchangeActivityData(undefined))
    }

    const unsubscribeGetOperationsActivity = () => {
        const unsubscribeMutation = getOperationsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setOperationsActivityData(undefined))
    }

    const unsubscribeGetLoginLocations = () => {
        const unsubscribeMutation = getLoginLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setLoginLocationsData(undefined))
    }

    const unsubscribeGetGeneralActivity = () => {
        const unsubscribeMutation = getGeneralActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setGeneralActivityData(undefined))
    }

    const unsubscribeGetSecurityCenterActivity = () => {
        const unsubscribeMutation = getSecurityCenterActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSecurityCenterActivityData(undefined))
    }

    const unsubscribeGetSharepointActivity = () => {
        const unsubscribeMutation = getSharepointActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointActivityData(undefined))
    }

    const unsubscribeGetWorkloadActivity = () => {
        const unsubscribeMutation = getWorkloadActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setWorkloadActivityData(undefined))
    }

    const unsubscribeGetOffice365Events = () => {
        const unsubscribeMutation = getOffice365Events({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setOffice365EventsData(undefined))
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchExchangeActivityData = () => {
        unsubscribeGetExchangeActivity()
        let getExchangeActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getExchangeActivityPromise = getExchangeActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getExchangeActivityPromise && getExchangeActivityPromise.abort()
        }
    }

    const fetchOperationsActivityData = () => {
        unsubscribeGetOperationsActivity()
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        const unsubscribeMutation = getOperationsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()

        let getOperationsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getOperationsActivityPromise = getOperationsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOperationsActivityPromise && getOperationsActivityPromise.abort()
        }
    }

    const fetchLoginLocationsData = () => {
        unsubscribeGetLoginLocations()
        let getLoginLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getLoginLocationsPromise = getLoginLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getLoginLocationsPromise && getLoginLocationsPromise.abort()
        }
    }

    const fetchGeneralActivityData = () => {
        unsubscribeGetGeneralActivity()
        let getGeneralActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getGeneralActivityPromise = getGeneralActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getGeneralActivityPromise && getGeneralActivityPromise.abort()
        }
    }

    const fetchSecurityCenterActivityData = () => {
        unsubscribeGetSecurityCenterActivity()
        let getSecurityCenterActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSecurityCenterActivityPromise = getSecurityCenterActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSecurityCenterActivityPromise && getSecurityCenterActivityPromise.abort()
        }
    }

    const fetchSharepointActivityData = () => {
        unsubscribeGetSharepointActivity()
        let getSharepointActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointActivityPromise = getSharepointActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointActivityPromise && getSharepointActivityPromise.abort()
        }
    }

    const fetchWorkloadActivityData = () => {
        unsubscribeGetWorkloadActivity()
        let getWorkloadActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getWorkloadActivityPromise = getWorkloadActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getWorkloadActivityPromise && getWorkloadActivityPromise.abort()
        }
    }

    const fetchOffice365EventsData = () => {
        unsubscribeGetOffice365Events()
        let getOffice365EventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getOffice365EventsPromise = getOffice365Events(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOffice365EventsPromise && getOffice365EventsPromise.abort()
        }
    }

    /** on token revalidation and if the dashboardMain dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])
    useEffect(() => {
        if (!dashboardData['Exchange.Activity']) {
            return fetchExchangeActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Operations.Activity']) {
            return fetchOperationsActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Login.Locations']) {
            return fetchLoginLocationsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['General.Activity']) {
            return fetchGeneralActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SecurityCenter.Activity']) {
            return fetchSecurityCenterActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Sharepoint.Activity']) {
            return fetchSharepointActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Workload.Activity']) {
            return fetchWorkloadActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Office365.Events']) {
            return fetchOffice365EventsData()
        }
    }, [token.valid, dashboardMain])

    /** no need for chart zoom lifecycles. */

    useEffect(() => {
        let isMounted = true
        if (getExchangeActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setExchangeActivityData(
                getExchangeActivityMutation.data
            ))
        }

        return () => {
            isMounted = false
        }
    }, [getExchangeActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getOperationsActivityMutation.isSuccess && isMounted) {
            dispatch(setOperationsActivityData(
                getOperationsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getOperationsActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getLoginLocationsMutation.isSuccess && isMounted) {
            dispatch(setLoginLocationsData(
                getLoginLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getLoginLocationsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getGeneralActivityMutation.isSuccess && isMounted) {
            dispatch(setGeneralActivityData(
                getGeneralActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getGeneralActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSecurityCenterActivityMutation.isSuccess && isMounted) {
            dispatch(setSecurityCenterActivityData(
                getSecurityCenterActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSecurityCenterActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSharepointActivityMutation.isSuccess && isMounted) {
            dispatch(setSharepointActivityData(
                getSharepointActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getWorkloadActivityMutation.isSuccess && isMounted) {
            dispatch(setWorkloadActivityData(
                getWorkloadActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getWorkloadActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getOffice365EventsMutation.isSuccess && isMounted) {
            dispatch(setOffice365EventsData(
                getOffice365EventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getOffice365EventsMutation.isSuccess])

    /** if dashboardMain change, set all refetch values to true. */

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        // type Header = ModalHeaderResponse['data'] & { title: string }
        // const data: Header = {
        //     title: dashboardCardDetails?.title || '',
        //     ...getModalHeaderMutation.data?.data || {
        //         line_1: dashboardCardDetails?.line_1 || '',
        //         line_2: dashboardCardDetails?.line_2 || '',
        //         heading: dashboardCardDetails?.heading || '',
        //         colorType: dashboardCardDetails?.colorType || 'grey'
        //     }
        // }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        // const Heading = (
        //     <Dashboard.Heading
        //         color={data.colorType || 'darkGrey'}
        //         activeMode={mode}
        //         activeStyle={style}
        //         className={'d-block mb-3'}
        //     >
        //         {data.heading || 'NO HEADING'}
        //     </Dashboard.Heading>
        // )

        // const Subtitle1 = (
        //     <Dashboard.Subtitle className={'d-block mb-1'}>
        //         {data.line_1 || 'NO LINE 1'}
        //     </Dashboard.Subtitle>
        // )

        // const Subtitle2 = (
        //     <Dashboard.Subtitle className={'d-block mb-2'}>
        //         {data.line_2 || 'NO LINE 2'}
        //     </Dashboard.Subtitle>
        // )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {/* should be removed in detailed dashboard reports */}
                {/* {Heading}
                {Subtitle1}
                {Subtitle2} */}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** add a onClick event where all the charts below will
     * add a o365Details modal.
     */
    const ExchangeActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Exchange.Activity'}
                isLoading={getExchangeActivityMutation.isLoading}
                isSuccess={getExchangeActivityMutation.isSuccess}
                error={getExchangeActivityMutation.error}
            />
        </div>
    }, undefined)

    const OperationsActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Operations.Activity'}
                isLoading={getOperationsActivityMutation.isLoading}
                isSuccess={getOperationsActivityMutation.isSuccess}
                error={getOperationsActivityMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (dashboardData['Login.Locations']) {
            dispatch(setTotalRecords(dashboardData['Login.Locations'].totalRecords))
            dispatch(setIsComplete(dashboardData['Login.Locations'].isComplete))
            dispatch(setScrollId(dashboardData['Login.Locations'].scrollId))
            dispatch(setMapData(dashboardData['Login.Locations'].data))
        }
    }, [dashboardData['Login.Locations']])

    const O365Locations = useMemo(() => {
        return <PageBreakInside>
            <span className={'d-block mb-2'}>
                {O365_MAIN_TEXT.SECTIONS.O365_LOCATIONS}
            </span>

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <div className={'col'}>
                            <LoginLocationsMap
                                isPrint={true}
                            />
                        </div>
                    </div>
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </PageBreakInside>
    }, undefined)

    const GeneralActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'General.Activity'}
                isLoading={getGeneralActivityMutation.isLoading}
                isSuccess={getGeneralActivityMutation.isSuccess}
                error={getGeneralActivityMutation.error}
            />
        </div>
    }, undefined)

    const SecurityCenterActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'SecurityCenter.Activity'}
                isLoading={getSecurityCenterActivityMutation.isLoading}
                isSuccess={getSecurityCenterActivityMutation.isSuccess}
                error={getSecurityCenterActivityMutation.error}
            />
        </div>
    }, undefined)

    const SharepointActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Sharepoint.Activity'}
                isLoading={getSharepointActivityMutation.isLoading}
                isSuccess={getSharepointActivityMutation.isSuccess}
                error={getSharepointActivityMutation.error}
            />
        </div>
    }, undefined)

    const WorkloadActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'Workload.Activity'}
                isLoading={getWorkloadActivityMutation.isLoading}
                isSuccess={getWorkloadActivityMutation.isSuccess}
                error={getWorkloadActivityMutation.error}
            />
        </div>
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getExchangeActivityMutation.data &&
            getGeneralActivityMutation.data &&
            getOperationsActivityMutation.data &&
            getOffice365EventsMutation.data &&
            getSecurityCenterActivityMutation.data &&
            getSharepointActivityMutation.data &&
            getWorkloadActivityMutation.data &&
            getLoginLocationsMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getExchangeActivityMutation.data,
        getGeneralActivityMutation.data,
        getOperationsActivityMutation.data,
        getOffice365EventsMutation.data,
        getSecurityCenterActivityMutation.data,
        getSharepointActivityMutation.data,
        getWorkloadActivityMutation.data,
        getLoginLocationsMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getExchangeActivityMutation.error ||
            getGeneralActivityMutation.error ||
            getOperationsActivityMutation.error ||
            getOffice365EventsMutation.error ||
            getSecurityCenterActivityMutation.error ||
            getSharepointActivityMutation.error ||
            getWorkloadActivityMutation.error ||
            getLoginLocationsMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getExchangeActivityMutation.error,
        getGeneralActivityMutation.error,
        getOperationsActivityMutation.error,
        getOffice365EventsMutation.error,
        getSecurityCenterActivityMutation.error,
        getSharepointActivityMutation.error,
        getWorkloadActivityMutation.error,
        getLoginLocationsMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    const qComponent = useMemo(() => {
        return (
            <div className={'row justify-content-between'}>
                <Text size={'sm'} className={'col-auto'}>
                    {TEXT.SEARCH.QUERY.LABEL}
                </Text>
                <Text size={'sm'} className={'col ps-0'}>
                    {currentParams.q}
                </Text>
            </div>
        )
    }, [currentParams.q])

    const boolListComponent = useMemo(() => {
        return (
            <div className={'row mb-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(currentParams.boolList, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }, [currentParams.boolList])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                {/* for detailed dashboard reports, include the search
                query and filter queries at the very beginning */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col'}>
                        {
                            [
                                PRINT_REPORT_TITLE,
                                '-',
                                _.capitalize(
                                    dashboardMain
                                        .searchParams
                                        ?.detailedDashboard
                                )
                            ].join(' ')
                        }
                    </div>
                </div>
                {
                    currentParams.q
                        ? qComponent
                        : ''
                }
                {
                    currentParams.boolList.length
                        ? boolListComponent
                        : ''
                }
                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <div className={'min-width-fix'}>
                    {OperationsActivity}
                </div>

                <div className={'min-width-fix'}>
                    {SharepointActivity}
                </div>

                <div className={'min-width-fix'}>
                    {O365Locations}
                </div>

                <div className={'min-width-fix'}>
                    {WorkloadActivity}
                </div>

                <div className={'min-width-fix'}>
                    {ExchangeActivity}
                </div>

                <div className={'min-width-fix'}>
                    {GeneralActivity}
                </div>

                <div className={'min-width-fix'}>
                    {SecurityCenterActivity}
                </div>

            </PrintMargin>

        </div>
    )
}

export default O365Modal

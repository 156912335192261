import {
    DashboardData,
    AzureK8sEventColumn,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    AzureDetailedSearchParams
} from '@interfaces/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    Doc
} from '@interfaces/dashboard/monitor'
import { CHART_ZOOM } from '@constants/dashboard/monitor'
import _ from 'lodash'
import {
    getUnixTime,
    sub
} from 'date-fns'

export const EVENT_TYPES: EventType[] = ['Azure.KubeEvents', 'Azure.KubeMonAgentEvents',
    'Azure.k8s.Events']

/** was _.Dictionary<EventTypeBooleans> but used type assertion. */
export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

export const DEFAULT_CHART_ZOOMS = _.zipObject(
    EVENT_TYPES,
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_SEARCH_PARAMS: AzureDetailedSearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    /** note that the refetches are now from separate event types.
     * so if you change the parameters from date ranges and search queries,
     * then everything refreshes,
    */
    refetch: _.cloneDeep(DEFAULT_REFETCH),
    q: '',

    /** for displaying filters. */
    boolList: [],
    /** scripts won't be executed if deviceid IS an empty string, */
    card: {
        details: {},
        deviceid: '',
        in_face: '',
        service_type: 'bdg-bas'
    }
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Azure Detailed call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        KUBE_EVENTS: 'Fetching Kube Events data...',
        KUBE_MON_AGENT_EVENTS: 'Fetching Kube Mon Agent Events data...',
        AZURE_K8S_EVENTS: 'Fetching Azure Events data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        KUBE_EVENTS: 'Kube Events',
        KUBE_MON_AGENT_EVENTS: 'Kube Mon Agent Events',
        AZURE_K8S_EVENTS: 'All Azure Events This Period'
    }
}

// computer

export const KUBE_EVENTS_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Computer',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const KUBE_MON_AGENT_EVENTS_COLUMNS: {label: string
    , value: keyof Doc }[] = KUBE_EVENTS_COLUMNS

export const AZURE_K8S_EVENTS_COLUMNS: AzureK8sEventColumn[] = (() => {
    const columnNames: Omit<AzureK8sEventColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'Category',
            value: 'Category'
        },
        {
            label: 'Computer',
            value: 'Computer'
        },
        {
            label: 'Kube Event Type',
            value: 'KubeEventType'
        },
        {
            label: 'Level',
            value: 'Level'
        },
        {
            label: 'Message',
            value: 'Message'
        },
        {
            label: 'Reason',
            value: 'Reason'
        },
        {
            label: 'Source System',
            value: 'SourceSystem'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        }

    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: AzureK8sEventColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const DASHBOARD_DATA: DashboardData = {
    'Azure.KubeEvents': undefined,
    'Azure.KubeMonAgentEvents': undefined,
    'Azure.k8s.Events': undefined
}

export const PRINT_REPORT_TITLE = 'Microsoft 365 Azure Detailed Dashboard'

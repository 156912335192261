/** be best done from scratch INSTEAD OF copy pasting from a print component
 * where it came from a modal
 */
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalHeaderMutation,
    useGetOneDriveDetailsActivityMutation,
    useGetSharePointDetailsActivityMutation,
    useGetSharePointEventsMutation,
    useGetSharepointClientIPDoughnutMutation,
    useGetSharepointUserActivityLocationsMutation,
    useGetSharepointUserIdCountedTableMutation,
    useGetSiteUrlActivityMutation,
    useGetSourceFileExtensionActivityMutation,
    useGetSourceFileExtensionDoughnutMutation,
    useGetSourceFileNameCountedTableMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    PRINT_REPORT_TITLE,
    TEXT as O365_SHAREPOINT_TEXT
} from '@constants/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    MESSAGE as O365_MESSAGE
} from '@constants/dashboard/soc/o365/main'
import {
    getUtcRanges,
    testImage,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import SharepointUserActivityMap from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/SharepointUserActivityMap'
import O365BarChart from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/print/O365BarChart'
import {
    CardDetails,
    LuceneQuery
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    FixedCollapsibles,
    GetDetailedDashboardRequest,
    SharepointEventFilter
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    GetModalRequest
    // ModalHeaderResponse
} from '@interfaces/dashboard/soc/o365/main'

import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'

import {
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles,
    selectMapData,
    setCurrentParams,
    setIsComplete,
    setMapData,
    setScrollId,
    setTotalRecords,
    toggleCollapsible,
    setSourceFileExtensionDoughnutData,
    setSourceFileExtensionActivityData,
    setSharepointClientIPDoughnutData,
    setSharePointDetailsActivityData,
    setOneDriveDetailsActivityData,
    setSiteUrlActivityData,
    setSharepointUserIdCountedTableData,
    setSourceFileNameCountedTableData,
    setSharepointUserActivityLocationsData,
    setSharePointEventsData
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
// import {
//     selectMode,
//     selectStyle
// } from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    FilterCell,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import { Buffer } from 'buffer'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectO365DetailedSharepoint,
    setCardDetails
} from '@slices/main/print/section'
import { useDebouncedCallback } from 'use-debounce'
import O365DoughnutChart from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/print/O365DoughnutChart'
import SharepointUserIdDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/print/SharepointUserIdDataTable'

import SourceFileNameDataTable from
    '@features/dashboard/soc/o365/detailed-dashboard/sharepoint/print/SourceFileNameDataTable'

const O365Modal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    // const mode = useAppSelector(selectMode)
    // const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)
    const mapData = useAppSelector(selectMapData)

    const dashboardMain = useAppSelector(selectO365DetailedSharepoint)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [
        getOneDriveDetailsActivity,
        getOneDriveDetailsActivityMutation
    ] = useGetOneDriveDetailsActivityMutation()
    const [
        getSharePointDetailsActivity,
        getSharePointDetailsActivityMutation
    ] = useGetSharePointDetailsActivityMutation()
    const [
        getSharePointEvents,
        getSharePointEventsMutation
    ] = useGetSharePointEventsMutation()
    const [
        getSharepointClientIPDoughnut,
        getSharepointClientIPDoughnutMutation
    ] = useGetSharepointClientIPDoughnutMutation()
    const [
        getSharepointUserActivityLocations,
        getSharepointUserActivityLocationsMutation
    ] = useGetSharepointUserActivityLocationsMutation()
    const [
        getSharepointUserIdCountedTable,
        getSharepointUserIdCountedTableMutation
    ] = useGetSharepointUserIdCountedTableMutation()
    const [
        getSiteUrlActivity,
        getSiteUrlActivityMutation
    ] = useGetSiteUrlActivityMutation()
    const [
        getSourceFileExtensionActivity,
        getSourceFileExtensionActivityMutation
    ] = useGetSourceFileExtensionActivityMutation()
    const [
        getSourceFileExtensionDoughnut,
        getSourceFileExtensionDoughnutMutation
    ] = useGetSourceFileExtensionDoughnutMutation()
    const [
        getSourceFileNameCountedTable,
        getSourceFileNameCountedTableMutation
    ] = useGetSourceFileNameCountedTableMutation()

    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getOneDriveDetailsActivityMutation.error) {
            console.error(getOneDriveDetailsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOneDriveDetailsActivityMutation.error])

    useEffect(() => {
        if (getSharePointDetailsActivityMutation.error) {
            console.error(getSharePointDetailsActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSharePointDetailsActivityMutation.error])

    useEffect(() => {
        if (getSharepointClientIPDoughnutMutation.error) {
            console.error(getSharepointClientIPDoughnutMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSharepointClientIPDoughnutMutation.error])

    useEffect(() => {
        if (getSharepointUserActivityLocationsMutation.error) {
            console.error(getSharepointUserActivityLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSharepointUserActivityLocationsMutation.error])

    useEffect(() => {
        if (getSiteUrlActivityMutation.error) {
            console.error(getSiteUrlActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSiteUrlActivityMutation.error])

    useEffect(() => {
        if (getSourceFileExtensionActivityMutation.error) {
            console.error(getSourceFileExtensionActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSourceFileExtensionActivityMutation.error])

    useEffect(() => {
        if (getSourceFileExtensionDoughnutMutation.error) {
            console.error(getSourceFileExtensionDoughnutMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSourceFileExtensionDoughnutMutation.error])

    useEffect(() => {
        if (getSourceFileNameCountedTableMutation.error) {
            console.error(getSourceFileNameCountedTableMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getSourceFileNameCountedTableMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetDetailedDashboardRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        const should: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => obj.not),
            turnIntoLuceneQuery)

        /**
         * for the print version, makie sure that the printOptions properties are defined.
         */

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: dashboardMain.searchParams?.search || ''
                ? Buffer
                    .from(dashboardMain.searchParams?.search || '')
                    .toString('base64')
                : '',
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        if (
            dashboardMain.searchParams &&
            dashboardMain.card
        ) {
            const newRanges = getUtcRanges({
                start: dashboardMain.searchParams.timeFrom,
                end: dashboardMain.searchParams.timeTo
            })

            /** details will be {} as we will get
         * the details data from dashboardCardDetails
         */

            dispatch(setCurrentParams({
                ranges: newRanges,
                q: dashboardMain.searchParams.search || '',
                card: {
                    deviceid: dashboardMain.card.deviceid,
                    in_face: dashboardMain.card.inFace,
                    service_type: dashboardMain.card.serviceType,
                    details: {}
                },
                boolList: (dashboardMain.searchParams.filters || []) as SharepointEventFilter[]
            }))
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** i'm glad we check for dashboardMain.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetOneDriveDetailsActivity = () => {
        const unsubscribeMutation = getOneDriveDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setOneDriveDetailsActivityData(undefined))
    }

    const unsubscribeGetSharePointDetailsActivity = () => {
        const unsubscribeMutation = getSharePointDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharePointDetailsActivityData(undefined))
    }

    const unsubscribeGetSharePointEvents = () => {
        const unsubscribeMutation = getSharePointEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharePointEventsData(undefined))
    }

    const unsubscribeGetSharepointClientIPDoughnut = () => {
        const unsubscribeMutation = getSharepointClientIPDoughnut({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointClientIPDoughnutData(undefined))
    }

    const unsubscribeGetSharepointUserActivityLocations = () => {
        const unsubscribeMutation = getSharepointUserActivityLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointUserActivityLocationsData(undefined))
    }

    const unsubscribeGetSharepointUserIdCountedTable = () => {
        const unsubscribeMutation = getSharepointUserIdCountedTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSharepointUserIdCountedTableData(undefined))
    }

    const unsubscribeGetSiteUrlActivity = () => {
        const unsubscribeMutation = getSiteUrlActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSiteUrlActivityData(undefined))
    }

    const unsubscribeGetSourceFileExtensionActivity = () => {
        const unsubscribeMutation = getSourceFileExtensionActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSourceFileExtensionActivityData(undefined))
    }

    const unsubscribeGetSourceFileExtensionDoughnut = () => {
        const unsubscribeMutation = getSourceFileExtensionDoughnut({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSourceFileExtensionDoughnutData(undefined))
    }

    const unsubscribeGetSourceFileNameCountedTable = () => {
        const unsubscribeMutation = getSourceFileNameCountedTable({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setSourceFileNameCountedTableData(undefined))
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchOneDriveDetailsActivityData = () => {
        unsubscribeGetOneDriveDetailsActivity()
        let getOneDriveDetailsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getOneDriveDetailsActivityPromise = getOneDriveDetailsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getOneDriveDetailsActivityPromise && getOneDriveDetailsActivityPromise.abort()
        }
    }

    const fetchSharePointDetailsActivityData = () => {
        unsubscribeGetSharePointDetailsActivity()
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        const unsubscribeMutation = getSharePointDetailsActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()

        let getSharePointDetailsActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharePointDetailsActivityPromise = getSharePointDetailsActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharePointDetailsActivityPromise && getSharePointDetailsActivityPromise.abort()
        }
    }

    const fetchSharePointEventsData = () => {
        unsubscribeGetSharePointEvents()
        let getSharePointEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharePointEventsPromise = getSharePointEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharePointEventsPromise && getSharePointEventsPromise.abort()
        }
    }

    const fetchSharepointClientIPDoughnutData = () => {
        unsubscribeGetSharepointClientIPDoughnut()
        let getSharepointClientIPDoughnutPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointClientIPDoughnutPromise = getSharepointClientIPDoughnut(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointClientIPDoughnutPromise && getSharepointClientIPDoughnutPromise.abort()
        }
    }

    const fetchSharepointUserActivityLocationsData = () => {
        unsubscribeGetSharepointUserActivityLocations()
        let getSharepointUserActivityLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointUserActivityLocationsPromise =
                    getSharepointUserActivityLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointUserActivityLocationsPromise &&
            getSharepointUserActivityLocationsPromise.abort()
        }
    }

    const fetchSharepointUserIdCountedTableData = () => {
        unsubscribeGetSharepointUserIdCountedTable()
        let getSharepointUserIdCountedTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSharepointUserIdCountedTablePromise =
                    getSharepointUserIdCountedTable(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSharepointUserIdCountedTablePromise && getSharepointUserIdCountedTablePromise.abort()
        }
    }

    const fetchSiteUrlActivityData = () => {
        unsubscribeGetSiteUrlActivity()
        let getSiteUrlActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSiteUrlActivityPromise = getSiteUrlActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSiteUrlActivityPromise && getSiteUrlActivityPromise.abort()
        }
    }

    const fetchSourceFileExtensionActivityData = () => {
        unsubscribeGetSourceFileExtensionActivity()
        let getSourceFileExtensionActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSourceFileExtensionActivityPromise = getSourceFileExtensionActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSourceFileExtensionActivityPromise && getSourceFileExtensionActivityPromise.abort()
        }
    }

    const fetchSourceFileExtensionDoughnutData = () => {
        unsubscribeGetSourceFileExtensionDoughnut()
        let getSourceFileExtensionDoughnutPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSourceFileExtensionDoughnutPromise = getSourceFileExtensionDoughnut(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSourceFileExtensionDoughnutPromise && getSourceFileExtensionDoughnutPromise.abort()
        }
    }

    const fetchSourceFileNameCountedTableData = () => {
        unsubscribeGetSourceFileNameCountedTable()
        let getSourceFileNameCountedTablePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()
        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getSourceFileNameCountedTablePromise = getSourceFileNameCountedTable(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getSourceFileNameCountedTablePromise && getSourceFileNameCountedTablePromise.abort()
        }
    }
    /** on token revalidation and if the dashboardMain dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])

    useEffect(() => {
        if (!dashboardData['OneDriveDetails.Activity']) {
            return fetchOneDriveDetailsActivityData()
        }
    }, [token.valid, dashboardMain])

    useEffect(() => {
        if (!dashboardData['SharePoint.Events']) {
            return fetchSharePointEventsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SharePointDetails.Activity']) {
            return fetchSharePointDetailsActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SharepointClientIP.Doughnut']) {
            return fetchSharepointClientIPDoughnutData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SharepointUserActivity.Locations']) {
            return fetchSharepointUserActivityLocationsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SharepointUserId.CountedTable']) {
            return fetchSharepointUserIdCountedTableData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SiteUrl.Activity']) {
            return fetchSiteUrlActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SourceFileExtension.Activity']) {
            return fetchSourceFileExtensionActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SourceFileExtension.Doughnut']) {
            return fetchSourceFileExtensionDoughnutData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['SourceFileName.CountedTable']) {
            return fetchSourceFileNameCountedTableData()
        }
    }, [token.valid, dashboardMain])

    /** no need for chart zoom lifecycles. */

    useEffect(() => {
        let isMounted = true
        if (getOneDriveDetailsActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setOneDriveDetailsActivityData(
                getOneDriveDetailsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getOneDriveDetailsActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSharePointEventsMutation.isSuccess && isMounted) {
            dispatch(setSharePointEventsData(
                getSharePointEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharePointEventsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSharepointUserActivityLocationsMutation.isSuccess && isMounted) {
            dispatch(setSharepointUserActivityLocationsData(
                getSharepointUserActivityLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointUserActivityLocationsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSharepointUserIdCountedTableMutation.isSuccess && isMounted) {
            dispatch(setSharepointUserIdCountedTableData(
                getSharepointUserIdCountedTableMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointUserIdCountedTableMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSiteUrlActivityMutation.isSuccess && isMounted) {
            dispatch(setSiteUrlActivityData(
                getSiteUrlActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSiteUrlActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSourceFileExtensionActivityMutation.isSuccess && isMounted) {
            dispatch(setSourceFileExtensionActivityData(
                getSourceFileExtensionActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSourceFileExtensionActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSourceFileExtensionDoughnutMutation.isSuccess && isMounted) {
            dispatch(setSourceFileExtensionDoughnutData(
                getSourceFileExtensionDoughnutMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSourceFileExtensionDoughnutMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSourceFileNameCountedTableMutation.isSuccess && isMounted) {
            dispatch(setSourceFileNameCountedTableData(
                getSourceFileNameCountedTableMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSourceFileNameCountedTableMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSharePointDetailsActivityMutation.isSuccess && isMounted) {
            dispatch(setSharePointDetailsActivityData(
                getSharePointDetailsActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharePointDetailsActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getSharepointClientIPDoughnutMutation.isSuccess && isMounted) {
            dispatch(setSharepointClientIPDoughnutData(
                getSharepointClientIPDoughnutMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getSharepointClientIPDoughnutMutation.isSuccess])
    /** if searchParams change, set all refetch values to true. */

    /** if dashboardMain change, set all refetch values to true. */

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        // type Header = ModalHeaderResponse['data'] & { title: string }
        // const data: Header = {
        //     title: dashboardCardDetails?.title || '',
        //     ...getModalHeaderMutation.data?.data || {
        //         line_1: dashboardCardDetails?.line_1 || '',
        //         line_2: dashboardCardDetails?.line_2 || '',
        //         heading: dashboardCardDetails?.heading || '',
        //         colorType: dashboardCardDetails?.colorType || 'grey'
        //     }
        // }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        // const Heading = (
        //     <Dashboard.Heading
        //         color={data.colorType || 'darkGrey'}
        //         activeMode={mode}
        //         activeStyle={style}
        //         className={'d-block mb-3'}
        //     >
        //         {data.heading || 'NO HEADING'}
        //     </Dashboard.Heading>
        // )

        // const Subtitle1 = (
        //     <Dashboard.Subtitle className={'d-block mb-1'}>
        //         {data.line_1 || 'NO LINE 1'}
        //     </Dashboard.Subtitle>
        // )

        // const Subtitle2 = (
        //     <Dashboard.Subtitle className={'d-block mb-2'}>
        //         {data.line_2 || 'NO LINE 2'}
        //     </Dashboard.Subtitle>
        // )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {/* should be removed in detailed dashboard reports */}
                {/* {Heading}
                {Subtitle1}
                {Subtitle2} */}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** add a onClick event where all the charts below will
     * add a o365Details modal.
     */

    /* apply other components here */
    const OneDriveDetailsActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'OneDriveDetails.Activity'}
                isLoading={getOneDriveDetailsActivityMutation.isLoading}
                isSuccess={getOneDriveDetailsActivityMutation.isSuccess}
                error={getOneDriveDetailsActivityMutation.error}
            />
        </div>
    }, undefined)

    const SharePointDetailsActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'SharePointDetails.Activity'}
                isLoading={getSharePointDetailsActivityMutation.isLoading}
                isSuccess={getSharePointDetailsActivityMutation.isSuccess}
                error={getSharePointDetailsActivityMutation.error}
            />
        </div>
    }, undefined)

    const SharepointClientIPDoughnut = useMemo(() => {
        return <div>
            {
                dashboardData['SharepointClientIP.Doughnut']?.aggregations?.[2]?.buckets?.length
                    ? <O365DoughnutChart
                        eventType={'SharepointClientIP.Doughnut'}
                        isLoading={getSharepointClientIPDoughnutMutation.isLoading}
                        isSuccess={getSharepointClientIPDoughnutMutation.isSuccess}
                        error={getSharepointClientIPDoughnutMutation.error}
                    />
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_DATA}
                        </Text>
                    </Container>
            }
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (dashboardData['SharepointUserActivity.Locations']) {
            dispatch(setTotalRecords(
                dashboardData['SharepointUserActivity.Locations'].totalRecords
            ))
            dispatch(setIsComplete(
                dashboardData['SharepointUserActivity.Locations'].isComplete
            ))
            dispatch(setScrollId(
                dashboardData['SharepointUserActivity.Locations'].scrollId
            ))
            dispatch(setMapData(
                dashboardData['SharepointUserActivity.Locations'].data
            ))
        }
    }, [dashboardData['SharepointUserActivity.Locations']])

    const SharepointLocations = useMemo(() => {
        return <PageBreakInside>
            <span className={'d-block mb-2'}>
                {O365_SHAREPOINT_TEXT.SECTIONS.SHAREPOINT_USER_ACTIVITY_LOCATIONS}
            </span>

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <div className={'col'}>
                            <SharepointUserActivityMap
                                isPrint={true}
                            />
                        </div>
                    </div>
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </PageBreakInside>
    }, undefined)

    const SharepointUserIdTable = useMemo(() => {
        return (<PageBreakInside className={'mb-3'}>
            <span className={'d-block mb-2'}>
                {O365_SHAREPOINT_TEXT.SECTIONS.SHAREPOINT_USER_ID_COUNTED_TABLE}
            </span>
            <SharepointUserIdDataTable
                isLoading={getSharepointUserIdCountedTableMutation.isLoading}
                isSuccess={getSharepointUserIdCountedTableMutation.isSuccess}
                error={getSharepointUserIdCountedTableMutation.error}
            />
        </PageBreakInside>)
    }, undefined)

    const SiteUrlActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'SiteUrl.Activity'}
                isLoading={getSiteUrlActivityMutation.isLoading}
                isSuccess={getSiteUrlActivityMutation.isSuccess}
                error={getSiteUrlActivityMutation.error}
            />
        </div>
    }, undefined)

    const SourceFileExtensionActivity = useMemo(() => {
        return <div>
            <O365BarChart
                eventType={'SourceFileExtension.Activity'}
                isLoading={getSourceFileExtensionActivityMutation.isLoading}
                isSuccess={getSourceFileExtensionActivityMutation.isSuccess}
                error={getSourceFileExtensionActivityMutation.error}
            />
        </div>
    }, undefined)

    const SourceFileExtensionDoughnut = useMemo(() => {
        return <div>
            {
                dashboardData['SourceFileExtension.Doughnut']?.aggregations?.[2]?.buckets?.length
                    ? <O365DoughnutChart
                        eventType={'SourceFileExtension.Doughnut'}
                        isLoading={getSourceFileExtensionDoughnutMutation.isLoading}
                        isSuccess={getSourceFileExtensionDoughnutMutation.isSuccess}
                        error={getSourceFileExtensionDoughnutMutation.error}
                    />
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_DATA}
                        </Text>
                    </Container>
            }

        </div>
    }, undefined)

    const SourceFileNameCountedTable = useMemo(() => {
        return (<PageBreakInside className={'mb-3'}>
            <span className={'d-block mb-2'}>
                {O365_SHAREPOINT_TEXT.SECTIONS.SOURCE_FILE_NAME_COUNTED_TABLE}
            </span>
            <SourceFileNameDataTable
                isLoading={getSourceFileNameCountedTableMutation.isLoading}
                isSuccess={getSourceFileNameCountedTableMutation.isSuccess}
                error={getSourceFileNameCountedTableMutation.error}
            />
        </PageBreakInside>

        )
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getOneDriveDetailsActivityMutation.data &&
            getSharePointDetailsActivityMutation.data &&
            getSharePointEventsMutation.data &&
            getSharepointClientIPDoughnutMutation.data &&
            getSharepointUserActivityLocationsMutation.data &&
            getSharepointUserIdCountedTableMutation.data &&
            getSiteUrlActivityMutation.data &&
            getSourceFileExtensionActivityMutation.data &&
            getSourceFileExtensionDoughnutMutation.data &&
            getSourceFileNameCountedTableMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getOneDriveDetailsActivityMutation.data,
        getSharePointDetailsActivityMutation.data,
        getSharePointEventsMutation.data,
        getSharepointClientIPDoughnutMutation.data,
        getSharepointUserActivityLocationsMutation.data,
        getSharepointUserIdCountedTableMutation.data,
        getSiteUrlActivityMutation.data,
        getSourceFileExtensionActivityMutation.data,
        getSourceFileExtensionDoughnutMutation.data,
        getSourceFileNameCountedTableMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getOneDriveDetailsActivityMutation.error ||
            getSharePointDetailsActivityMutation.error ||
            getSharePointEventsMutation.error ||
            getSharepointClientIPDoughnutMutation.error ||
            getSharepointUserActivityLocationsMutation.error ||
            getSharepointUserIdCountedTableMutation.error ||
            getSiteUrlActivityMutation.error ||
            getSourceFileExtensionActivityMutation.error ||
            getSourceFileExtensionDoughnutMutation.error ||
            getSourceFileNameCountedTableMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getOneDriveDetailsActivityMutation.error,
        getSharePointDetailsActivityMutation.error,
        getSharePointEventsMutation.error,
        getSharepointClientIPDoughnutMutation.error,
        getSharepointUserActivityLocationsMutation.error,
        getSharepointUserIdCountedTableMutation.error,
        getSiteUrlActivityMutation.error,
        getSourceFileExtensionActivityMutation.error,
        getSourceFileExtensionDoughnutMutation.error,
        getSourceFileNameCountedTableMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    const qComponent = useMemo(() => {
        return (
            <div className={'row justify-content-between'}>
                <Text size={'sm'} className={'col-auto'}>
                    {TEXT.SEARCH.QUERY.LABEL}
                </Text>
                <Text size={'sm'} className={'col ps-0'}>
                    {currentParams.q}
                </Text>
            </div>
        )
    }, [currentParams.q])

    const boolListComponent = useMemo(() => {
        return (
            <div className={'row mb-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(currentParams.boolList, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }, [currentParams.boolList])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                {/* for detailed dashboard reports, include the search
                query and filter queries at the very beginning */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col'}>
                        {
                            [
                                PRINT_REPORT_TITLE,
                                '-',
                                _.capitalize(
                                    dashboardMain
                                        .searchParams
                                        ?.detailedDashboard
                                )
                            ].join(' ')
                        }
                    </div>
                </div>
                {
                    currentParams.q
                        ? qComponent
                        : ''
                }
                {
                    currentParams.boolList.length
                        ? boolListComponent
                        : ''
                }
                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <div className={'min-width-fix'}>
                    {SourceFileExtensionDoughnut}
                </div>

                <div className={'min-width-fix'}>
                    {SharepointClientIPDoughnut}
                </div>

                <div className={'min-width-fix'}>
                    {SharePointDetailsActivity}
                </div>

                <div className={'min-width-fix'}>
                    {SharepointLocations}
                </div>

                <div className={'min-width-fix'}>
                    {OneDriveDetailsActivity}
                </div>

                <div className={'min-width-fix'}>
                    {SiteUrlActivity}
                </div>

                <div className={'min-width-fix'}>
                    {SourceFileExtensionActivity}
                </div>

                <div className={'min-width-fix'}>
                    {SharepointUserIdTable}
                </div>

                <div className={'min-width-fix'}>
                    {SourceFileNameCountedTable}
                </div>

            </PrintMargin>

        </div>
    )
}

export default O365Modal

import { URL } from '@constants/main/root'
import {
    GetModalDetailsRequest,
    GetModalDetailsResponse,
    GetModalRequest,
    ModalHeaderResponse,
    MacVendorResponse,
    MacAddressResponse,
    MacNewResponse,
    MacReappearResponse,
    IPAddressResponse,
    EventType
} from '@interfaces/dashboard/soc/rdd'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    FetchArgs,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

const getRddModalData: (
    body:Omit<GetModalRequest, 'event_type'>,
    eventType:EventType
) => GetModalRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        in_face: body.in_face,
        time_from: body.time_from,
        time_to: body.time_to
    }
}

const createRddQuery: (
    formData: GetModalRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/rdd/modal',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

export const rddApi = createApi({
    reducerPath: 'rddApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** a lot was thought of when it comes to dealing with an api url that can
         * return different response data with formData properties, in this case, the
         * event_type parameter. There were attempts to use discriminating unions together
         * with transformResponse AND intersection types. Former failed but the latter was
         * effective for a while. Autocompleting the response data after making a call
         * led to confusion where type annotaton and assertion are both used once which
         * is not preferrable as it is more likely to throw a TypeError.
         *
         * So safest option is to create copies of the getModal api call with event_type
         * having a default value as it is a literal type. While it may look clean,
         * you'll deal with lots of lines of code.
         *
         * You don't need to recreate two instances of mac.Vendors just because the
         * time ranges are different. Use call that mutation twice.
         */
        getModalHeader: builder
            .mutation<ModalHeaderResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getRddModalData(body, 'modal.Header')
                    return createRddQuery(formData, body.authToken)
                }
            }),
        getMacVendor: builder
            .mutation<MacVendorResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getRddModalData(body, 'mac.Vendor')
                    return createRddQuery(formData, body.authToken)
                }
            }),
        getMacAddress: builder
            .mutation<MacAddressResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getRddModalData(body, 'mac.Address')
                    return createRddQuery(formData, body.authToken)
                }
            }),
        getMacNew: builder
            .mutation<MacNewResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getRddModalData(body, 'mac.New')
                    return createRddQuery(formData, body.authToken)
                }
            }),
        getMacReappear: builder
            .mutation<MacReappearResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getRddModalData(body, 'mac.Reappear')
                    return createRddQuery(formData, body.authToken)
                }
            }),
        getIPAddress: builder
            .mutation<IPAddressResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getRddModalData(body, 'ip.Address')
                    return createRddQuery(formData, body.authToken)
                }
            }),
        getModalDetails: builder
            .mutation<GetModalDetailsResponse, GetModalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetModalDetailsRequest = {
                        deviceid: body.deviceid,
                        in_face: body.in_face,
                        mac_add: body.mac_add,
                        mac_type: body.mac_type,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/rdd/modalDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetIPAddressMutation,
    useGetMacAddressMutation,
    useGetMacNewMutation,
    useGetMacReappearMutation,
    useGetMacVendorMutation,
    useGetModalHeaderMutation,
    useGetModalDetailsMutation
} = rddApi

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    INITIAL_SEARCH_PARAMS
} from '@constants/dashboard/soc/oss'
import {
    OssMainState
} from '@interfaces/dashboard/soc/oss'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState : OssMainState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    /** this is just to provide printOptions optional properties
     * on start. If you want the properties to be mandatory,
     * modify them in the state's interface.
     */
    printOptions: {
        logo: '',
        expandDetails: false
    }
}

export const slice = createSlice({
    name: 'ossMain',
    initialState: initialState,
    reducers: {
        setStartDate: (state: OssMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: OssMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: OssMainState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setLogo: (state: OssMainState, action: PayloadAction<string>) => {
            state.printOptions.logo = action.payload
        },
        setExpandDetails: (state: OssMainState, action: PayloadAction<boolean>) => {
            state.printOptions.expandDetails = action.payload
        },
        setCurrentParams: (state: OssMainState, action: PayloadAction<
            OssMainState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        resetOssMain: (state: OssMainState) => {
            state.printOptions = initialState.printOptions
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setLogo,
    setExpandDetails,
    setCurrentParams,
    resetOssMain
} = slice.actions

export const selectSearchParams = (state: RootState) => state.ossMain.searchParams
export const selectCurrentParams = (state: RootState) => state.ossMain.currentParams
export const selectPrintOptions = (state: RootState) => state.ossMain.printOptions

export default slice.reducer

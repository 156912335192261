import { SearchParams } from '@interfaces/dashboard/monitor'
import {
    IPAddColumn,
    RddDetailColumn,
    IPAddKeys,
    RddDetailKeys
} from '@interfaces/dashboard/soc/rdd'
import { LabelValuePair } from '@interfaces/main/root'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'RDD call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MODAL_HEADER: 'Fetching Header data...',
        MAC_VENDORS: 'Fetching Mac Vendor data...',
        MAC_NEW: 'Fetching Mac New data...',
        MAC_REAPPEAR: 'Fetching Mac Reappear data...',
        MODAL_DETAILS: 'Fetching modal details...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        REMOVE_FILTERS: {
            LABEL: 'Remove Filters',
            ID: 'PRINT_OPTIONS__REMOVE_FILTERS'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        MODAL_HEADER: 'Header Data',
        MAC_VENDOR: 'Device Types',
        MAC_ADDRESS: 'Addresses',
        MAC_NEW: 'First-timers',
        MAC_REAPPEARED: 'Reappeared',
        IP_ADDRESS: 'All Current Devices'
    }
}

export const DEVICE_TYPES_COLUMNS: Omit<LabelValuePair, 'value'>[] = [
    { label: 'Vendor' },
    { label: 'Current Period' },
    { label: 'Previous Period' }
]

export const MAC_NEW_COLUMNS: Omit<LabelValuePair, 'value'>[] = [
    { label: 'Address' },
    { label: 'Type' }
]

export const IP_ADDRESS_COLUMNS: IPAddColumn[] = (() => {
    const columnNames: Omit<IPAddColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Hostname',
            value: 'hostname'
        },
        {
            label: 'IP Address',
            value: 'ip_add'
        },
        {
            label: 'Mac Address',
            value: 'mac_addr'
        },
        {
            label: 'Mac Type',
            value: 'mac_type'
        },
        {
            label: 'Timestamp',
            value: 'timestamp'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: IPAddColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const RDD_DETAILS_COLUMNS: RddDetailColumn[] = (() => {
    const columnNames: Omit<RddDetailColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'Timestamp'
        },
        {
            label: 'Mac Address',
            value: 'MAC_Address'
        },
        {
            label: 'Vendor',
            value: 'Vendor'
        },
        {
            label: 'Hostname',
            value: 'Hostname'
        },
        {
            label: 'IP Address',
            value: 'IP_Address'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: RddDetailColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const IP_ADD_KEYS: IPAddKeys[] = [
    'hostname', 'ip_add', 'mac_addr', 'mac_type', 'timestamp'
]

// still created only to be consistent with the previous initialization.
export const RDD_DETAILS_KEYS: RddDetailKeys[] = []

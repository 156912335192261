import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    DoArchiveRequest,
    DoArchiveResponse,
    DoCommentRequest,
    DoCommentResponse,
    DoCreateReportRequest,
    DoCreateReportResponse,
    DoEscalateRequest,
    DoEscalateResponse,
    DoFileUploadRequest,
    DoFileUploadResponse,
    DoFinalizeReportRequest,
    DoFinalizeReportResponse,
    DoReportAnswerRequest,
    DoReportAnswerResponse,
    DoRequestReportRequest,
    DoRequestReportResponse,
    DoRequestReportReviewRequest,
    DoRequestReportReviewResponse,
    DoReviewReviewedRequest,
    DoReviewReviewedResponse,
    DoSubmitReviewedReportRequest,
    DoSubmitReviewedReportResponse,
    GetDevicesRequest,
    GetDevicesResponse,
    GetEventDetailsRequest,
    GetEventDetailsResponse,
    GetEventsRequest,
    GetEventsResponse,
    GetFileIdRequest,
    GetFileIdResponse,
    GetReportDataRequest,
    GetReportDataResponse,
    GetReportsRequest,
    GetReportsResponse
} from '@interfaces/watchdog/soc-data/event'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const eventApi = createApi({
    reducerPath: 'eventApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getEvents: builder.mutation<GetEventsResponse, GetEventsRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getEvents',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        type: body.type,
                        filters: body.filters,
                        fromTime: body.fromTime,
                        toTime: body.toTime
                    }
                }
            }
        }),
        searchEvents: builder.mutation<GetEventsResponse, GetEventsRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getEvents',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        type: body.type,
                        filters: body.filters,
                        fromTime: body.fromTime,
                        toTime: body.toTime,
                        /** i would guess that these properties need to
                         * be undefined in order to search EVENTS.
                         */
                        boxname: body.boxname,
                        eventid: body.eventid
                    }
                }
            }
        }),
        getEventDetails: builder.mutation<GetEventDetailsResponse,
        GetEventDetailsRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getEventDetails',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: body.id,
                        clusterid: body.clusterid
                    }
                }
            }
        }),
        getDevices: builder.mutation<GetDevicesResponse, GetDevicesRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/getDevices',
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    }
                }
            }
        }),
        doArchive: builder.mutation<DoArchiveResponse, DoArchiveRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/doArchive',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: body.id,
                        clusterid: body.clusterid,
                        comment: body.comment
                    }
                }
            }
        }),
        doComment: builder.mutation<DoCommentResponse, DoCommentRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/doComment',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: body.id,
                        clusterid: body.clusterid,
                        comment: body.comment
                    }
                }
            }
        }),
        doEscalate: builder.mutation<DoEscalateResponse, DoEscalateRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'be/doEscalate',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        id: body.id,
                        clusterid: body.clusterid,
                        comment: body.comment
                    }
                }
            }
        }),
        doRequestReport: builder
            .mutation<DoRequestReportResponse, DoRequestReportRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'be/doRequestReport',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            id: body.id,
                            clusterid: body.clusterid,
                            comment: body.comment
                        }
                    }
                }
            }),
        doCreateReport: builder
            .mutation<DoCreateReportResponse, DoCreateReportRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'api/doCreateReport',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            deviceId: body.deviceId,
                            id: body.id,
                            reportType: body.reportType,
                            timestamp: body.timestamp,
                            index: body.index,
                            signature: body.signature
                        }
                    }
                }
            }),
        getReports: builder.mutation<GetReportsResponse, GetReportsRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'api/getReports',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {}
                }
            }
        }),
        getReportData: builder.mutation<GetReportDataResponse, GetReportDataRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'api/getReportData',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        reportId: body.reportId
                    }
                }
            }
        }),
        doReportAnswer: builder
            .mutation<DoReportAnswerResponse, DoReportAnswerRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'api/doReportAnswer',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            reportId: body.reportId,
                            reportSection: body.reportSection,
                            reportQuestion: body.reportQuestion,
                            reportStep: body.reportStep,
                            reportAnswer: body.reportAnswer,
                            reviewNotes: body.reviewNotes,
                            fileIds: body.fileIds
                        }
                    }
                }
            }),
        doFileUpload: builder.mutation<DoFileUploadResponse, DoFileUploadRequest & TokenAuth>({
            query: (body) => {
                const formData: DoFileUploadRequest = {
                    fileData: body.fileData
                }

                return {
                    url: 'api/doFileUpload',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        formData: formData.fileData
                    }
                }
            }
        }),
        doRequestReportReview: builder
            .mutation<DoRequestReportReviewResponse, DoRequestReportReviewRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'api/doRequestReportReview',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            reportId: body.reportId
                        }
                    }
                }
            }),
        doReviewReviewed: builder
            .mutation<DoReviewReviewedResponse, DoReviewReviewedRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'api/doReviewReviewed',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            reportId: body.reportId
                        }
                    }
                }
            }),
        doSubmitReviewedReport: builder
            .mutation<DoSubmitReviewedReportResponse, DoSubmitReviewedReportRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'api/doSubmitReviewedReport',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            reportId: body.reportId
                        }
                    }
                }
            }),
        doFinalizeReport: builder
            .mutation< DoFinalizeReportResponse, DoFinalizeReportRequest & TokenAuth>({
                query: (body) => {
                    return {
                        url: 'api/doFinalizeReport',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: {
                            reportId: body.reportId
                        }
                    }
                }
            }),
        getFileId: builder.mutation<GetFileIdResponse, GetFileIdRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'api/getFileId',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        reportId: body.reportId,
                        fileId: body.fileId
                    }
                }
            }
        })
    })
})

export const {
    useDoArchiveMutation,
    useDoCommentMutation,
    useDoCreateReportMutation,
    useDoEscalateMutation,
    useDoFileUploadMutation,
    useDoFinalizeReportMutation,
    useDoReportAnswerMutation,
    useDoRequestReportMutation,
    useDoRequestReportReviewMutation,
    useDoReviewReviewedMutation,
    useDoSubmitReviewedReportMutation,
    useGetDevicesMutation,
    useGetEventDetailsMutation,
    useGetEventsMutation,
    useGetFileIdMutation,
    useGetReportDataMutation,
    useGetReportsMutation,
    useSearchEventsMutation
} = eventApi

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    INITIAL_SEARCH_PARAMS
} from '@constants/dashboard/soc/oss'
import {
    OssDetailsState
} from '@interfaces/dashboard/soc/oss'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState : OssDetailsState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    collapsibles: []
}

export const slice = createSlice({
    name: 'ossDetails',
    initialState: initialState,
    reducers: {
        setStartDate: (state: OssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: OssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: OssDetailsState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },

        setCurrentParams: (state: OssDetailsState, action: PayloadAction<
            OssDetailsState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        setCollapsibles: (state: OssDetailsState, action: PayloadAction<boolean[]>) => {
            state.collapsibles = action.payload
        },
        toggleCollapsible: (state: OssDetailsState, action: PayloadAction<{
            boolean: boolean,
            index: number
        }>) => {
            state.collapsibles[action.payload.index] = action.payload.boolean
        },
        resetOssDetails: (state: OssDetailsState) => {
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setCurrentParams,
    setCollapsibles,
    toggleCollapsible,
    resetOssDetails
} = slice.actions

export const selectSearchParams = (state: RootState) => state.ossDetails.searchParams
export const selectCurrentParams = (state: RootState) => state.ossDetails.currentParams
export const selectCollapsibles = (state: RootState) => state.ossDetails.collapsibles

export default slice.reducer

import {
    DashboardData,
    MdrEventColumn,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    MdrDetailedSearchParams
} from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'
import {
    Doc
} from '@interfaces/dashboard/monitor'

import { CHART_ZOOM } from '@constants/dashboard/monitor'
import _ from 'lodash'
import {
    getUnixTime,
    sub
} from 'date-fns'

export const EVENT_TYPES: EventType[] = ['Activity', 'snmp', 'dns', 'Locations',
    'dhcp', 'tls', 'smb', 'fileinfo', 'http', 'krb5',
    'ssh', 'ikev2', 'Events']

/** was _.Dictionary<EventTypeBooleans> but used type assertion. */
export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

export const DEFAULT_CHART_ZOOMS = _.zipObject(
    EVENT_TYPES,
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_SEARCH_PARAMS: MdrDetailedSearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    /** note that the refetches are now from separate event types.
     * so if you change the parameters from date ranges and search queries,
     * then everything refreshes,
    */
    refetch: _.cloneDeep(DEFAULT_REFETCH),
    q: '',

    /** for displaying filters. */
    boolList: [],
    /** scripts won't be executed if deviceid IS an empty string, */
    card: {
        details: {},
        deviceid: '',
        in_face: '',
        service_type: 'bdg-bas'
    }
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'MDR Detailed call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MDR_ACTIVITY: 'Fetching MDR Activity data...',
        MDR_DHCP: 'Fetching MDR Dhcp data...',
        MDR_SNMP: 'Fetching MDR Snmp data...',
        MDR_DNS: 'Fetching MDR Dns data...',
        MDR_LOCATIONS: 'Fetching MDR Locations data...',
        MDR_TLS: 'Fetching MDR Tls data...',
        MDR_SMB: 'Fetching MDR Smb data...',
        MDR_FILEINFO: 'Fetching MDR File Info data...',
        MDR_HTTP: 'Fetching MDR Http data...',
        MDR_KRB5: 'Fetching MDR Krb5 data...',
        MDR_SSH: 'Fetching MDR Ssh data...',
        MDR_IKEV2: 'Fetching MDR Ikev2 data...',
        MDR_EVENTS: 'Fetching MDR Events data...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        MDR_ACTIVITY: 'Activity',
        MDR_DHCP: 'DHCP',
        MDR_SNMP: 'SNMP',
        MDR_DNS: 'DNS',
        MDR_LOCATIONS: 'Locations',
        MDR_TLS: 'TLS',
        MDR_SMB: 'SMB',
        MDR_FILEINFO: 'File Info',
        MDR_HTTP: 'HTTP',
        MDR_KRB5: 'KRB5',
        MDR_SSH: 'SSH',
        MDR_IKEV2: 'IKEV2',
        MDR_EVENTS: 'Events'
    }
}

export const MDR_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Event Type',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const MDR_SNMP_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Source I.P.',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const MDR_DNS_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_DHCP_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_FILEINFO_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_HTTP_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_IKEV2_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_KRB5_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_SMB_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_SSH_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS
export const MDR_TLS_COLUMNS: {label: string, value: keyof Doc }[] = MDR_SNMP_COLUMNS

export const MDR_EVENTS_COLUMNS: MdrEventColumn[] = (() => {
    const columnNames: Omit<MdrEventColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Hostname',
            value: 'beat.hostname'
        },
        // { label: 'Flow ID', value: 'flowid' },
        {
            label: 'Geoip',
            value: 'geoip'
        },
        {
            label: 'Source IP',
            value: 'src_ip'
        },
        {
            label: 'Destination IP',
            value: 'dest_ip'
        },
        {
            label: 'Event Type',
            value: 'event_type'
        },
        {
            label: 'Timestamp',
            value: '@timestamp'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: MdrEventColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

export const DASHBOARD_DATA: DashboardData = {
    'MDR.Activity': undefined,
    'MDR.snmp': undefined,
    'MDR.dns': undefined,
    'MDR.Locations': undefined,
    'MDR.dhcp': undefined,
    'MDR.tls': undefined,
    'MDR.smb': undefined,
    'MDR.fileinfo': undefined,
    'MDR.http': undefined,
    'MDR.krb5': undefined,
    'MDR.ssh': undefined,
    'MDR.ikev2': undefined,
    'MDR.Events': undefined
}
export const PRINT_REPORT_TITLE = 'MDR Detailed Dashboard'

import {
    useGetModalDetailsMutation
} from '@apis/dashboard/grc/pcidss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as PCIDSS_TEXT
} from '@constants/dashboard/grc/pcidss'
import {
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import DetailedComplianceLineChart from '@features/dashboard/grc/pcidss/DetailedComplianceLineChart'
import DetailedCoverageLineChart from '@features/dashboard/grc/pcidss/DetailedCoverageLineChart'
import { ModalDetailsRequest } from '@interfaces/dashboard/grc/pcidss'
import {
    MonitorModal
} from '@interfaces/dashboard/monitor'
import { TokenAuth } from '@interfaces/main/root'
import {
    ActionCreatorWithPayload
} from '@reduxjs/toolkit'
import { MutationContext } from '@root/MutationProvider'
import {
    resetPcidssDetails,
    selectFixedCollapsibles,
    selectSearchParams,
    setCurrentParams,
    setRefetch,
    toggleCollapsible
} from '@slices/dashboard/grc/pcidss/details'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    CollapsibleText,
    Container,
    SectionCard,
    Text
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import parse from 'html-react-parser'
import colorGradient from 'javascript-color-gradient'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    FaMinus,
    FaPlus
} from 'react-icons/fa'
import {
    toast
} from 'react-toastify'

const PcidssDetails = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** NOTE: the response data from /modalDetails is exactly the same
     * from /modal. To reduce load time, just use the selectedChapter
     * variable. You still need the nistData though.
     */
    const [getModalDetails, getModalDetailsMutation] = useGetModalDetailsMutation()

    const [severityGradient, setSeverityGradient] = useState<string[]>([])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const result = colorGradient
            .setGradient(
                stylesheet.style.colorPresets.red,
                stylesheet.style.colorPresets.yellow,
                stylesheet.style.colorPresets.green
            )
            .setMidpoint(100)
            .getArray()

        setSeverityGradient(result)
    }, [])

    useEffect(() => {
        if (getModalDetailsMutation.error) {
            console.error(getModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalDetailsMutation.error])

    /** WILL HAVE date ranges like in the parent modal. Starting ranges are the data
     *  of ip address being passed over */
    const unsubscribeGetModalDetails = () => {
        const unsubscribeMutation = getModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalDetails()

        let getModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            service_type: modal.card.service_type,
                            chapterIdentifier: (modal.serviceTypeFormData
                                ?.pcidss?.details?.selectedChapter.chapterIdentifier || ''),
                            in_face: modal.card.in_face,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()

                        }

                        getModalDetailsPromise = getModalDetails(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalDetailsPromise && getModalDetailsPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalDetailsMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalDetailsMutation.isSuccess
    ])

    /** we just need the dataTable AND a bar chart */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)

        return () => {
            dispatch(resetPcidssDetails())
        }
    }, [])

    /** now we can do this. */
    const Title = useMemo(() => {
        const chapterName = modal.serviceTypeFormData?.pcidss
            ?.details.selectedChapter.chapterName

        const chapterIdentifier = modal.serviceTypeFormData?.pcidss
            ?.details.selectedChapter.chapterIdentifier

        return <span>{[
            chapterName,
            ['(', chapterIdentifier, ')'].join('')
        ].join(' ')}</span>
    }, [modal.serviceTypeFormData?.pcidss])

    /** show description */
    const Description = useMemo(() => {
        const description = parse(modal.serviceTypeFormData?.pcidss
            ?.details.selectedChapter.summaryText || '')

        return modal.serviceTypeFormData?.pcidss
            ?.details.selectedChapter.summaryText
            ? <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        <Text size={'sm'}>
                            {description}
                        </Text>
                    </div>
                </div>
            </Container>
            : ''
    }, [modal.serviceTypeFormData?.pcidss])

    /** show paragraph data */
    const Paragraphs = useMemo(() => {
        const chapterData = modal.serviceTypeFormData?.pcidss
            ?.details.selectedChapter.chapterData || []

        const nistData = getModalDetailsMutation.data?.data || []
        const stylesheet = createStylesheet(style, mode)

        return <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
            <div className={'row'}>{
                _.map(chapterData, ({
                    paragraphName,
                    paragraphIdentifier,
                    paragraphData
                }, paragraphIndex) => {
                    const key = ['paragraphContent', paragraphIndex].join('-')
                    return (
                        <div
                            className={'mb-2 col-lg-4 col-12'}
                            key={key}
                        >
                            <Text size={'xs'} className={'d-inline-block mb-2'}>
                                {paragraphName}
                            </Text>
                            <div className={'row'}>
                                {
                                    _.map(paragraphData, ({ alineaName, translationtoNist },
                                        sectionCardIndex) => {
                                        const sectionCardKey = [
                                            'sectionCard',
                                            paragraphIndex,
                                            sectionCardIndex
                                        ].join('-')
                                        // first thing to do is to map the array of
                                        // objects to array of strings.
                                        const simplified = _.map(translationtoNist, ({
                                            page, subcat
                                        }) => page || subcat || '')

                                        // color assignment. we use translationtoNist to
                                        // get the cards we want then get their scores.
                                        // get their average and proceed to the nextupdate
                                        // and average that as well.
                                        const score = nistData.length
                                            ? _.reduce(nistData, (total, { nistData }) => {
                                                const filtered = _.flattenDeep(
                                                    _.map(nistData, ({ data }) => {
                                                        return _.filter(data, ({ identifier }) => {
                                                            return _.includes(
                                                                simplified, identifier
                                                            )
                                                        })
                                                    })
                                                )

                                                const computed = filtered.length
                                                    ? _.reduce(
                                                        filtered, (score, obj) => {
                                                            score += obj.score
                                                            return score
                                                        }
                                                        , 0
                                                    ) / filtered.length
                                                    : 0

                                                total += computed
                                                return total
                                            }, 0) / nistData.length
                                            : 0

                                        // make sure the score is only between 0 and 100
                                        const colorIndex = _.floor((score / 4) * 100)

                                        const color = score
                                            ? severityGradient[
                                                colorIndex > 100
                                                    ? 100 - 1
                                                    : colorIndex < 0
                                                        ? 0
                                                        : colorIndex - 1
                                            ]
                                            : stylesheet.style.colorPresets.grey

                                        // return the statcard.
                                        return <div key={sectionCardKey}
                                            className={'col-3 col-lg-4 col-md-6 mb-2'}>
                                            <SectionCard
                                                color={color}
                                                activeMode={mode}
                                                activeStyle={style}
                                            >
                                                <Text size={'xs'}>{alineaName}</Text>
                                            </SectionCard>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </Container>
    }, [
        modal.serviceTypeFormData?.pcidss,
        getModalDetailsMutation.data
    ])

    /** now create two linecharts. */
    const DetailedCompliance = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {PCIDSS_TEXT.SECTIONS.COMPLIANCE_SCORES}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'complianceScores',
                        value: !fixedCollapsibles.complianceScores
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.complianceScores ? <FaMinus/> : <FaPlus/> }
                </Text>
            </CollapsibleText>
            <DetailedComplianceLineChart
                selectedChapter={modal.serviceTypeFormData?.pcidss?.details.selectedChapter}
                nistDatasets={getModalDetailsMutation.data?.data}
                isLoading={getModalDetailsMutation.isLoading}
                isSuccess={getModalDetailsMutation.isSuccess}
                error={getModalDetailsMutation.error}
            />
        </div>
    }, undefined)

    const DetailedCoverage = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {PCIDSS_TEXT.SECTIONS.COVERAGE_SCORES}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'coverageScores',
                        value: !fixedCollapsibles.coverageScores
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.coverageScores ? <FaMinus/> : <FaPlus/> }
                </Text>
            </CollapsibleText>
            <DetailedCoverageLineChart
                selectedChapter={modal.serviceTypeFormData?.pcidss?.details.selectedChapter}
                nistDatasets={getModalDetailsMutation.data?.data}
                isLoading={getModalDetailsMutation.isLoading}
                isSuccess={getModalDetailsMutation.isSuccess}
                error={getModalDetailsMutation.error}
            />
        </div>
    }, undefined)
    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col-auto'}>
                    {Title}
                </div>
            </div>

            <div className={'min-width-fix'}>
                {Description}
            </div>

            <div className={'min-width-fix'}>
                {Paragraphs}
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {DetailedCompliance}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {DetailedCoverage}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PcidssDetails

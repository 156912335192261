import {
    FlowAction,
    FlowColumn,
    FlowType,
    FlowKeys
} from '@interfaces/watchdog/soc-data/flow'
import _ from 'lodash'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Flow call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when flowID is undefined */
    UNDEFINED_ID: 'Empty flowID detected.',
    TYPE_UNAVAILABLE: [
        'Modal for flow type',
        'is unavailable'
    ]

}

export const TEXT = {
    SEARCH: {
        DEVICEID: {
            LABEL: 'Device ID',
            ID: 'SEARCH__DEVICEID'
        },
        FLOWID: {
            LABEL: 'Flow ID',
            ID: 'SEARCH__FLOWID'
        },
        SEARCH: 'Search',
        GENERATE_URL: 'Generate Shareable URL'
    },
    SHARE_URL: {
        COPY: 'Copy URL:'
    }
}

export const ACTIONS: FlowAction[] = [
]

export const ACTIVE_TYPES: FlowType[] = ['alert']

export const COLUMNS: FlowColumn[] = (() => {
    const columnNames: Omit<FlowColumn, 'arrange' | 'include'>[] = [
        {
            label: 'Timestamp',
            value: 'timestamp'
        },
        {
            label: 'Type',
            value: 'type'
        },
        {
            label: 'Source I.P.',
            value: 'src_ip'
        },
        {
            label: 'Destination I.P.',
            value: 'dest_ip'
        },
        {
            label: 'Description',
            value: 'description'
        }
    ]

    /**
     * it doesn't need to be these values all the time
     * so you can check the column.value property and apply
     * your default values.
     * */
    const result: FlowColumn[] = _.map(columnNames, (column) => {
        return {
            ...column,
            arrange: 'asc',
            include: false
        }
    })

    return result
})()

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */
export const FLOW_KEYS: FlowKeys[] = [
    'description', 'type',
    'dest_ip', 'dest_port', 'domain', 'eventname', 'hostname',
    'protocol', 'severity', 'src_ip', 'src_port', 'timestamp'

]

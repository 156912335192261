import {
    useM365ConfigUpdateMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as CONFIG_MESSAGE,
    TEXT as CONFIG_TEXT
} from '@constants/watchdog/configuration/device-config'
import {
    DeviceConfigModal
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const DoM365Update = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [m365ConfigUpdate, m365ConfigUpdateMutation] = useM365ConfigUpdateMutation()

    /** call formiks */

    const submitUpdateData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData.m365?.updateConfirm) {
            const id = modal.formData.m365.updateConfirm.id
            const values = modal.formData.m365.updateConfirm.formValues

            m365ConfigUpdate({
                id: id,
                active: values.active,
                clientId: values.clientId,
                deviceId: values.deviceId,
                tenant: values.tenant,
                tenantId: values.tenantId,
                thumbprint: values.thumbprint,
                authToken: newToken
            })
        }
    }, [
        modal.formData.m365
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (m365ConfigUpdateMutation.data) {
            const data = m365ConfigUpdateMutation.data
            if (data.status) {
                // toast.success(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
                /** when done, display the success message in a modal instead of
                 * a toast and remove this modal
                 */
                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    operation: 'M365_DETAILS_UPDATE_SUCCESS',
                    formData: {
                        m365: {
                            updateSuccess: data.message
                        }
                    },
                    isBorderWide: true
                }))
                dispatch(closeModal(modal))
            } else {
                toast.error(
                    CONFIG_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [m365ConfigUpdateMutation.data])

    useEffect(() => {
        if (m365ConfigUpdateMutation.error) {
            console.error(m365ConfigUpdateMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [m365ConfigUpdateMutation.error])

    /** confirmation window for both update locations AND update m365 */
    const SubmitButton = useMemo(() => {
        const buttonContent = m365ConfigUpdateMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{CONFIG_TEXT.M365_UPDATE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : CONFIG_TEXT.M365_UPDATE.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={submitUpdateData}
                disabled={m365ConfigUpdateMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [m365ConfigUpdateMutation.isLoading])

    return (<div>
        <h5>{CONFIG_TEXT.M365_UPDATE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {CONFIG_TEXT.M365_UPDATE.CONFIRMATION}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default DoM365Update

import {
    useAppDispatch
} from '@app/hook'
import {
    EVENT_STATE_GROUPINGS,
    TEXT as EVENT_TEXT
} from '@constants/watchdog/soc-data/event'
import {
    AnswerReportValues,
    DecodedReportParam,
    GetReportDataResponse,
    ReportFormData,
    ReportModal,
    ReportStepInput
} from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    CollapsibleText,
    FormStyledComponents as Form,
    Text
} from '@styles/components'
import {
    FormikErrors,
    FormikProps
} from 'formik'
import _ from 'lodash'
import React, { useMemo } from 'react'
import {
    FaHistory
} from 'react-icons/fa'
import uniqueString from 'unique-string'

interface ComponentProps {
    decodedReportParams: DecodedReportParam
    addModal: ActionCreatorWithPayload<ReportModal, string>,
    reportStepsFormik: FormikProps<AnswerReportValues>,
    responseData: GetReportDataResponse | undefined
    currentReportSection: number,
}

/** one thing we need to figure out, we need to have ids for inputs and collapsible buttons.
 * so we will be creating them in the formAnswerValues initialization.
 */

const Open = ({
    decodedReportParams, addModal, currentReportSection, reportStepsFormik, responseData
} : ComponentProps) => {
    const dispatch = useAppDispatch()

    /** let's make a useMemo to get the reportStep information */
    const reportStepInfo = useMemo(() => {
        return _.find(responseData?.reportSteps || [], (obj) => {
            return obj.reportSection === currentReportSection
        })
    }, [
        responseData, currentReportSection
    ])

    const stepIndex = useMemo(() => {
        return _.findIndex(reportStepsFormik.values.reportSteps, (obj) => {
            return obj.reportSection === currentReportSection
        })
    }, [
        reportStepsFormik.values, currentReportSection
    ])

    const stepInput = useMemo(() => {
        return reportStepsFormik.values.reportSteps[stepIndex]
    }, [
        reportStepsFormik.values, stepIndex
    ])

    const errors = useMemo(() => {
        return (reportStepsFormik.errors.reportSteps ||
            []) as FormikErrors<ReportStepInput>[]
    }, [
        reportStepsFormik.errors
    ])

    const answerGivenValue = useMemo(() => {
        return stepInput.reportStepInput.answerGiven[0]
    }, [stepInput])

    const reviewNoteValue = useMemo(() => {
        return stepInput.reportStepInput.reviewNote[0]
    }, [stepInput])

    const collapsible = useMemo(() => {
        return stepInput.reportStepInput.collapsible[0]
    }, [stepInput])

    const AnswerGivenLabel = useMemo(() => {
        return <CollapsibleText className={
            'row align-items-center mb-2'
        }>
            <Text size={'xxs'} className={'col-auto pe-0 icon pointer'}
                onClick={() => {
                    const formData: ReportFormData = {
                        answerHistory: {
                            data: responseData?.reportData || [],
                            reportStep: {
                                stepIndex: stepIndex,
                                inputIndex: 0,
                                reportSection: reportStepInfo
                                    ?.reportSection || 1
                            }
                        }
                    }

                    dispatch(addModal({
                        id: uniqueString(),
                        open: true,
                        operation: 'ANSWER_HISTORY',
                        formData: formData,
                        isBorderWide: false
                    }))
                }}
            >
                <FaHistory/>
            </Text>
            <Form.Label
                className={'col-auto pe-0 mb-0'}
                htmlFor={stepInput.reportStepInput.id[0]}>
                {reportStepInfo?.reportQuestion}
            </Form.Label>
        </CollapsibleText>
    }, undefined)

    const AnswerGivenInput = useMemo(() => {
        return <Form.TextArea
            errors={Boolean(
                errors[stepIndex]?.reportStepInput
                    ?.answerGiven?.[0]
            )}
            name={[
                'reportSteps',
                '[', stepIndex.toString(), '].',
                'reportStepInput.',
                'answerGiven[0]'
            ].join('')}
            readOnly={
                !_.includes(
                    EVENT_STATE_GROUPINGS.GROUP_1,
                    decodedReportParams.eventState
                )
            }
            id={stepInput.reportStepInput.id[0]}
            onChange={_.includes(
                EVENT_STATE_GROUPINGS.GROUP_1,
                decodedReportParams.eventState
            )
                ? reportStepsFormik.handleChange
                : () => {}
            }
            onBlur={(e) => {
                _.includes(
                    EVENT_STATE_GROUPINGS.GROUP_1,
                    decodedReportParams.eventState
                ) && reportStepsFormik.setFieldValue(
                    [
                        'reportSteps',
                        '[', stepIndex.toString(), '].',
                        'reportStepInput.',
                        'answerGiven[0]'
                    ].join(''),
                    e.target.value.trim()
                )
            }}
            value={answerGivenValue}
        />
    }, undefined)

    const ReviewLabel = useMemo(() => {
        return <CollapsibleText className={'row align-items-center mb-2'}>
            <Text size={'xxs'} className={'col-auto pe-0'} >
                {EVENT_TEXT.REPORT_INTERFACE.LABELS.REVIEW_NOTE}
            </Text>
            <Text size={'xxs'} className={'col-auto pe-0 icon pointer'}
                onClick={() => {
                    const formData: ReportFormData = {
                        answerHistory: {
                            data: responseData?.reportData || [],
                            reportStep: {
                                stepIndex: stepIndex,
                                inputIndex: 0,
                                reportSection: reportStepInfo
                                    ?.reportSection || 1
                            }
                        }
                    }

                    dispatch(addModal({
                        id: uniqueString(),
                        open: true,
                        operation: 'REVIEW_HISTORY',
                        formData: formData,
                        isBorderWide: false
                    }))
                }}
            >
                <FaHistory/>
            </Text>
        </CollapsibleText>
    }, undefined)

    const ReviewInput = useMemo(() => {
        return <Form.TextArea
            name={[
                'reportSteps',
                '[', stepIndex.toString(), '].',
                'reportStepInput.',
                'reviewNotes[0]'
            ].join('')}
            readOnly={
                !_.includes(
                    EVENT_STATE_GROUPINGS.GROUP_2,
                    decodedReportParams.eventState
                )
            }
            onChange={reportStepsFormik.handleChange}
            onBlur={(e) => {
                reportStepsFormik.setFieldValue(
                    [
                        'reportSteps',
                        '[', stepIndex.toString(), '].',
                        'reportStepInput.',
                        'reviewNotes[0]'
                    ].join(''),
                    e.target.value.trim()
                )
            }}
            value={reviewNoteValue}
        />
    }, undefined)

    return <div>
        <Form.Group className={'mt-0'}>
            {/* display answer history, collapsible AND the title */}
            {collapsible
                ? <div>
                    {AnswerGivenLabel}
                    {AnswerGivenInput}
                    {/* show review label */}
                    {
                        _.includes(
                            EVENT_STATE_GROUPINGS.GROUP_2,
                            decodedReportParams.eventState
                        )
                            ? <div>
                                {ReviewLabel}
                                {ReviewInput}
                            </div>
                            : ''
                    }
                </div>
                : ''}

        </Form.Group>

    </div>
}

export default Open

import { SearchParams } from '@interfaces/dashboard/monitor'
import { CategoricalScore } from '@interfaces/dashboard/soc/oss'
import {
    getUnixTime,
    sub
} from 'date-fns'

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    /** it's important that we assign defaults when the localStorage is empty. */
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 30 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    // FAULTY_CALL: 'EXT-VSS call returned faulty.',
    FAULTY_CALL: 'OSS call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    EMPTY_RECOMMENDATIONS: 'No recommendations found for this section...',
    FETCH: {
        MODAL: 'Fetching modal data...',
        LINE_CHART: 'Fetching line chart data...',
        DETAILS: 'Fetching details...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        EXPAND_DETAILS: {
            LABEL: 'Expand Details',
            ID: 'PRINT_OPTIONS__EXPAND_DETAILS'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        COMPARISON: {
            TITLE: 'Comparison',
            OVERALL_SCORE: 'My Tenant',
            ALL_TENANTS: 'Worldwide',
            TOTAL_SEATS: 'Similar Size',
            INDUSTRY_TYPES: 'Similar Industry'
        },
        DATA_SEGMENTS: {
            TITLE: 'Data Segments',
            DATA: 'Data',
            IDENTITY: 'Identity',
            APPS: 'Apps',
            DEVICE: 'Device'
        },
        HISTORICAL_SECURE_SCORES: 'Historical Secure Scores',
        CATEGORIES: {
            DATA: 'Data',
            IDENTITY: 'Identity',
            APPS: 'Apps',
            DEVICE: 'Device'
        },
        SCORES: {
            BEHAVIOR: 'Behavior',
            CONFIG: 'Config',
            REVIEW: 'Review'
        },
        DETAILS: {
            DESCRIPTION: 'Description',
            REMEDIATION: 'Recommendation'
        }
    }
}

export const DEFAULT_CATEGORY_SCORE = {
    maxScore: 0,
    totalScore: 0
}

export const DEFAULT_CATEGORICAL_SCORE: CategoricalScore = {
    ...DEFAULT_CATEGORY_SCORE,
    Behavior: DEFAULT_CATEGORY_SCORE,
    Config: DEFAULT_CATEGORY_SCORE,
    Review: DEFAULT_CATEGORY_SCORE
}

import { RootState } from '@app/store'
import { tryParseJSON } from '@constants/main/method'
import {
    GdprResultData,
    GdprSectionFormData
} from '@interfaces/dashboard/grc/gdpr'
import {
    NistResultData,
    NistSectionFormData
} from '@interfaces/dashboard/grc/nist'
import {
    PcidssResultData,
    PcidssSectionFormData
} from '@interfaces/dashboard/grc/pcidss'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    AzureDetailedKubernetesSectionFormData
} from '@interfaces/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    AzureDetailedMainSectionFormData
} from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import {
    AzureSectionFormData
} from '@interfaces/dashboard/soc/azure/main'
import {
    VssResultData,
    VssSectionFormData
} from '@interfaces/dashboard/soc/ext-vss'
import {
    MdrDetailedMainSectionFormData
} from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'
import { MdrSectionFormData } from '@interfaces/dashboard/soc/mdr/main'
import {
    O365DetailedExchangeSectionFormData
} from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import {
    O365DetailedMainSectionFormData
} from '@interfaces/dashboard/soc/o365/detailedDashboard/main'
import {
    O365DetailedSharepointSectionFormData
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    O365SectionFormData
} from '@interfaces/dashboard/soc/o365/main'
import {
    OssResultData,
    OssSectionFormData
} from '@interfaces/dashboard/soc/oss'
import {
    RddSectionFormData
} from '@interfaces/dashboard/soc/rdd'
import {
    WssResultData,
    WssSectionFormData
} from '@interfaces/dashboard/soc/wss'
import {
    EvmSectionFormData
} from '@interfaces/dashboard/tac/evm'
import {
    ImmSectionFormData
} from '@interfaces/dashboard/tac/imm'
import {
    VmmSectionFormData
} from '@interfaces/dashboard/tac/vmm'
import {
    SectionMain,
    SectionPrintState
} from '@interfaces/main/print'
import { EventReportSectionFormData } from '@interfaces/watchdog/soc-data/event'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
const getFromSessionStorage: () => SectionPrintState = () => {
    // this should contain 'main' and 'resultData' properties.
    const userJson = sessionStorage.getItem('dashboardPrint')
    /**
     * we need to check if the userJSON is not an empty string,
     * if the string as a parameter in tryParseJSON returns true,
     * BUT also check if the theme and style properties exist.
     */

    let main: SectionMain = {
        card: {
            deviceid: '',
            inFace: '',
            serviceType: 'bdg-bas'
        },
        searchParams: {
            timeFrom: 0,
            timeTo: 0
        },
        printOptions: {},
        isPrinting: false
        // stylesheet: 'bluedog',
        // theme: 'light'
    }

    const cardDetails: CardDetails = {
        colorType: 'darkGrey',
        heading: '',
        lastUpdate: 0,
        line_1: '',
        line_2: '',
        location: '',
        state: '',
        state_details: '',
        title: ''
    }

    let resultData = {}

    if (userJson !== null) {
        const obj = tryParseJSON(userJson) as unknown as SectionPrintState

        /** it got more complicated so I decided to remove it due to more
         * print module types.
         */
        // // these options are required when generating a report.
        // const requiredMainKeys: SectionMainKeys[] = [
        //     'card', 'searchParams', 'printOptions'
        // ]

        // if (
        //     _.every(requiredMainKeys, _.partial(_.has, obj.main))
        // ) {
        //     // now check if this object has the required properties of
        //     // card and searchParams

        //     const requiredCardKeys: (keyof typeof obj.main.card)[] = [
        //         'deviceid', 'inFace', 'serviceType'
        //     ]

        //     const requiredSearchParamKeys: (keyof typeof obj.main.searchParams)[] = [
        //         'timeFrom', 'timeTo'
        //     ]

        //     if (
        //         _.every(
        //             requiredCardKeys, _.partial(_.has, obj.main.card)
        //         ) &&
        //         _.every(
        //             requiredSearchParamKeys, _.partial(_.has, obj.main.searchParams)
        //         )
        //     ) {
        //         main = obj.main
        //     }
        // }

        main = obj.main

        // set resultData value anyway.
        resultData = obj.resultData
    }

    return {
        main: main,
        cardDetails: cardDetails,
        resultData: resultData
    }
}

const initialState : SectionPrintState = getFromSessionStorage()

/**
 * there won't be any reducers in the slice unless necessary.
 */
export const slice = createSlice({
    name: 'sectionPrint',
    initialState: initialState,
    reducers: {
        setCardDetails: (state: SectionPrintState, action: PayloadAction<CardDetails>) => {
            state.cardDetails = action.payload
        }

    }
})

export const {
    setCardDetails
} = slice.actions

/** to utilize the Required type for each dashboard module, have a selectMain dispatch
 * for each with their printOptions annotation.
 */
export const selectHomeMain = (state: RootState) => state.sectionPrint.main
export const selectCardDetails = (state: RootState) => state.sectionPrint.cardDetails
/** do type assertion for different service_type.  */
/** for vss AND ext-vss. */
export const selectVssMain = (state: RootState) => {
    return state.sectionPrint.main as VssSectionFormData
}
export const selectVssVulnDesc = (state: RootState) => {
    return state.sectionPrint.resultData as VssResultData
}
/** for gdpr */
export const selectGdprMain = (state: RootState) => {
    return state.sectionPrint.main as GdprSectionFormData
}
export const selectGdprDetails = (state: RootState) => {
    return state.sectionPrint.resultData as GdprResultData
}
/** for nist */
export const selectNistMain = (state: RootState) => {
    return state.sectionPrint.main as NistSectionFormData
}
export const selectNistDetails = (state: RootState) => {
    return state.sectionPrint.resultData as NistResultData
}
/** for pcidss */
export const selectPcidssMain = (state: RootState) => {
    return state.sectionPrint.main as PcidssSectionFormData
}
export const selectPcidssDetails = (state: RootState) => {
    return state.sectionPrint.resultData as PcidssResultData
}
export const selectO365Main = (state: RootState) => {
    return state.sectionPrint.main as O365SectionFormData
}
export const selectO365DetailedMain = (state: RootState) => {
    return state.sectionPrint.main as O365DetailedMainSectionFormData
}
export const selectO365DetailedSharepoint = (state: RootState) => {
    return state.sectionPrint.main as O365DetailedSharepointSectionFormData
}
export const selectO365DetailedExchange = (state: RootState) => {
    return state.sectionPrint.main as O365DetailedExchangeSectionFormData
}
export const selectAzureMain = (state: RootState) => {
    return state.sectionPrint.main as AzureSectionFormData
}
export const selectAzureDetailedMain = (state: RootState) => {
    return state.sectionPrint.main as AzureDetailedMainSectionFormData
}
export const selectAzureDetailedKubernetes = (state: RootState) => {
    return state.sectionPrint.main as AzureDetailedKubernetesSectionFormData
}
export const selectMdrMain = (state: RootState) => {
    return state.sectionPrint.main as MdrSectionFormData
}
export const selectMdrDetailedMain = (state: RootState) => {
    return state.sectionPrint.main as MdrDetailedMainSectionFormData
}
export const selectOssMain = (state: RootState) => {
    return state.sectionPrint.main as OssSectionFormData
}
export const selectOssControlCategory = (state: RootState) => {
    return state.sectionPrint.resultData as OssResultData
}
export const selectWssMain = (state: RootState) => {
    return state.sectionPrint.main as WssSectionFormData
}
export const selectWssDesc = (state: RootState) => {
    return state.sectionPrint.resultData as WssResultData
}
export const selectRddMain = (state: RootState) => {
    return state.sectionPrint.main as RddSectionFormData
}
export const selectEvmMain = (state: RootState) => {
    return state.sectionPrint.main as EvmSectionFormData
}
export const selectImmMain = (state: RootState) => {
    return state.sectionPrint.main as ImmSectionFormData
}
export const selectVmmMain = (state: RootState) => {
    return state.sectionPrint.main as VmmSectionFormData
}
/**
 * in the previous version of watchdog 2.0, we have a reducer that retrieves the localStorage
 * items and passes it over to the appropriate component.
 *
 * We already have done this on initialization and rely on the print route component
 */
export const selectEventReport = (state: RootState) => {
    return state.sectionPrint.main as EventReportSectionFormData
}
export default slice.reducer

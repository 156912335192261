
import {
    MESSAGE as OSS_MESSAGE,
    TEXT as OSS_TEXT
} from '@constants/dashboard/soc/oss'
import {
    TEXT
} from '@constants/main/root'
import HorizontalGauge from '@features/dashboard/soc/oss/HorizontalGauge'
import {
    ActionType,
    ControlCategory,
    ControlState,
    SecureScoreDetail
} from '@interfaces/dashboard/soc/oss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    Container,
    PageBreakInside,
    PrewrapAll,
    SpinnerContainer,
    Text
} from '@styles/components'
import parse from 'html-react-parser'
import _ from 'lodash'
import React, {
    createRef,
    useMemo,
    useRef
} from 'react'
import {
    FaExclamationTriangle
} from 'react-icons/fa'

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

interface ComponentProps {
    controlCategory: ControlCategory,
    actionType: ActionType
    data: SecureScoreDetail[]
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined,
}

const OSSDetails = ({
    controlCategory, actionType,
    data, isLoading, isSuccess, error
}: ComponentProps) => {
    const horizontalGaugeEls = useRef<React.RefObject<HTMLCanvasElement>[]>([])

    const SecureScoreDetails = useMemo(() => {
        const secureScores = data

        return (
            isLoading
                ? <small className={'d-block text-center py-2'}>
                    <SpinnerContainer>
                        <span className={'spinner-border spinner-border-sm'}></span>
                        <span className={'ms-2'}>{OSS_MESSAGE.FETCH.DETAILS}</span>
                    </SpinnerContainer>
                </small>
                : secureScores.length
                    ? _.map(secureScores, (secureScore, index) => {
                        const highRiskIcon = secureScore.highRisk
                            ? <Text size={'md'} mode={'warning'} className={'ps-3'}>
                                <FaExclamationTriangle />
                            </Text>
                            : ''

                        const title = <Text size={'xs'}>
                            {secureScore.title}
                        </Text>

                        let controlState: ControlState['state'] = 'Default'
                        /** sort the controlState array by timestamp */
                        const controlStatesSorted = secureScore.controlState.sort((a, b) => {
                            return (a.updatedDateTime || 0) - (b.updatedDateTime || 0)
                        }).filter((obj) => {
                            return obj.updatedDateTime
                        })

                        if (controlStatesSorted.length > 0) {
                            controlState = controlStatesSorted[0].state
                        }

                        /** you want to filter out empty date times too */
                        horizontalGaugeEls.current[index] = createRef()
                        const chartEl = horizontalGaugeEls.current[index]

                        const DescriptionSegment = parse(secureScore.description || TEXT.NONE)
                        const RemediationSegment = parse(secureScore.remediation || TEXT.NONE)

                        const key = [
                            'ossSecureScore-', index
                        ].join('')

                        return <PageBreakInside key={key}>
                            <Container bgIndex={2} className={'mb-2'}>
                                <div className={'row align-items-center justify-content-between'}>
                                    <div className={'col-6'}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col-auto'}>
                                                {highRiskIcon}
                                            </div>
                                            <div className={'col'}>
                                                {title}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-6'}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col'}>
                                                <HorizontalGauge
                                                    controlState={controlState}
                                                    totalScore={secureScore.score}
                                                    maxScore={secureScore.maxScore}
                                                    chartEl={chartEl}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* row of html content */}
                                <Container bgIndex={2} className={'px-3'}>
                                    <div className={'row justify-content-center'}>
                                        <div className={'col'}>
                                            <div className={'mt-2 mb-3 px-3'}>
                                                <span className={'d-block mb-2 text-capitalize'}>
                                                    {OSS_TEXT.SECTIONS.DETAILS.DESCRIPTION}
                                                </span>
                                                <Text size={'xs'} className={'d-block mb-2'}>
                                                    <PrewrapAll>{DescriptionSegment}</PrewrapAll>
                                                </Text>
                                            </div>
                                            <div className={'mt-2 px-3'}>
                                                <span className={'d-block mb-2 text-capitalize'}>
                                                    {OSS_TEXT.SECTIONS.DETAILS.REMEDIATION}
                                                </span>
                                                <Text size={'xs'} className={'d-block mb-2'}>
                                                    <PrewrapAll>{RemediationSegment}</PrewrapAll>
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </Container>
                        </PageBreakInside>
                    })
                    : <PageBreakInside>
                        <Container bgIndex={2}>
                            <Text size={'xs'} className={'d-inline-block px-3 py-2'}>
                                {OSS_MESSAGE.EMPTY_RECOMMENDATIONS}
                            </Text>
                        </Container>
                    </PageBreakInside>
        )
    }, [data, isLoading])

    return (
        <div className={'mt-4 mt-lg-0'}>
            <span className={'d-inline-block mb-2'}>
                {[
                    controlCategory,
                    '-',
                    actionType
                ].join(' ')}
            </span>
            {SecureScoreDetails}
        </div>
    )
}

export default OSSDetails

import { useAppDispatch } from '@app/hook'
import { LOGIN_ROUTES } from '@constants/main/routes'
import { resetToken } from '@slices/main/token'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'

const LogoutPage = () => {
    const dispatch = useAppDispatch()

    // when this page is mounted, reset settings and token data.
    // finally redirect to login page
    useEffect(() => {
        dispatch(resetToken())
        dispatch(push(LOGIN_ROUTES.LOGIN.link))
    }, [])

    return <div></div>
}
export default LogoutPage

import {
    ColorPresets,
    Column,
    DeltaIndicator,
    Filter,
    MonitorActionValue
} from '@interfaces/main/root'
import { Severity } from '@interfaces/watchdog/soc-data/event'
import {
    StyleProps
} from '@styles/styled'
import { createStylesheet } from '@styles/themes'
import {
    add,
    addDays,
    addHours,
    addMinutes,
    addMonths,
    addSeconds,
    addWeeks,
    addYears,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInYears,
    eachDayOfInterval,
    eachHourOfInterval,
    eachMinuteOfInterval,
    format,
    fromUnixTime,
    getUnixTime,
    subDays,
    subHours,
    subMinutes,
    subMonths,
    subWeeks,
    subYears
} from 'date-fns'
import flatten from 'flat'
import _, {
    Dictionary,
    Many
} from 'lodash'
import {
    Chart
} from 'chart.js'
import {
    LuceneQuery,
    Card
} from '@interfaces/dashboard/monitor'
import { CHART_INTERVAL_LIMIT } from '@constants/main/root'
import {
    PARTNER_ROUTES,
    ORDER_ROUTES,
    DASHBOARD_ACCOUNT_ROUTES,
    CONFIGURATION_STATUS_ROUTES,
    SOC_MONITOR_ROUTES,
    TACTICAL_MONITOR_ROUTES,
    GRC_MONITOR_ROUTES,
    PROFILE_ROUTES,
    EVENT_ROUTES,
    REPORT_ROUTES,
    FILTER_ROUTES,
    MONITORING_STATUS_ROUTES,
    MDR_DETAILED_DASHBOARD_ROUTES,
    O365_DETAILED_DASHBOARD_ROUTES,
    AZURE_DETAILED_DASHBOARD_ROUTES,
    COMPLIANCE_ROUTES,
    QUESTIONNAIRE_ROUTES,
    QUESTION_ROUTES
} from '@constants/main/routes'

// methods shared by the entire system
/**
 * @function tryParseJSON - method for parsing JSON string objects.
 * @param {string} jsonString - Should be a JSON stringified value.
 * @return an Object. would become falsy afterward through a key length check
 * */
export const tryParseJSON :
    (jsonString: string) => Record<string, unknown> =
    (jsonString: string) => {
        try {
            const obj = JSON.parse(jsonString)
            if (obj && typeof obj === 'object') {
                return obj as Record<string, unknown>
            }
        } catch (e) {}

        return {}
    }

/**
 * new cleaner search function. from 125 to 50 lines.
 * for performance reasons, you are better off
 * performing a debounce in case the data could be 10k
 * rows. Update: If you are using this in regular javascript,
 * it's ok but if you are doing this inside react especially
 * knowing lifecycles, it would run its own debounce separately
 * tested during the first search test session.
 *
 * solution is to use a react based debounce hook, use-debounce
 * same thing for throttling in the near future.
 * */

/**
 * @function smartSearch - client side search method for now until
 * we have access to backend to create a server-side query with pagination, sorting
 * and filtering similar to Kibana Lucene query
 * @param tableData - usually an array of objects but regular arrays work too.
 * @param filters - to filter results based on this value after search string filtering
 * @param search - uses _.some to return a truthy result
 * @returns - filtered tableData
 */
export const smartSearch: (
    tableData : unknown[],
    filters: Filter[],
    search: string
) => unknown[] = (
    tableData, filters, search
) => {
    /**
     * first thing to do is filter the results via string.
     * iterate tableData
     */
    const result = _.filter(tableData, (tableDataObj) => {
        // then iterate over the object's values.

        /**
         * before doing the searches, to take care of nested arrays and objects
         * use the npm flat function.
         * */

        /** we have to lowercase both values to be compared. use regexp operator */

        const flattenedObj = flatten(tableDataObj) as Dictionary<unknown>

        /**
         * do a _.includes (SameValueZero search). the commented out code
         * didn't work since we are expecting a regExp kind of search
         *
         * did _.some AND _.method for a substring search. _.method
         * uses match and a regExp expression as parameters
         */
        // const searchFound = _.includes(flattenedObj, search)
        const searchFound = _.some(
            flattenedObj,
            _.method('match', new RegExp(search, 'ig'))
        )
        // console.log('search string found result: ', searchFound)

        /**
         * then perform a filters iteration to filter at a time.
         * the main goal is to meet ALL the current needs of the
         * filters. it's either an array of values or one to use
         * _.includes as above.
         *
         * IF there aren't any filters, then the data would be empty.
         * Perform a filters.length check to prevent this
         */

        const filterMatches = _.map(filters, (filterObj, _index) => {
            let match = false

            /** if filterObj.sort is NOT truthy, find in all properties */
            if (!filterObj.sort) {
                /** checks the object if it strictly has the string.
                 */
                match = _.includes(flattenedObj, filterObj.value)
                /**
                 * could be loose but that would also return results where
                 * the filter value can be a substring
                 * */

                // match = _.some(
                //     flattenedObj,
                //     _.method('match', new RegExp(filterObj.value, 'ig'))
                // )

                /** if truthy, let's check if the value of
                 * that property even exists in the object. Strict equality is used.
                 * To deal with NESTED object properties that have been flattened,
                 * when adding that filter, specify the sort string as if it was
                 * flattened. You can see some examples in event/dashboard modules.
                 *
                 * UPDATE form September 11, 2022. You can compare stringified numbers
                 * with actual numbers.
                 *
                 * If no results pop out, kindly check your filterObj.sort value. It
                 * should be strictly compared to FLATTENED keys. Kindly navigate
                 * to AzureDetails.tsx zoomAction method on conditional statements.
                 *  */
            } else if (_.has(flattenedObj, filterObj.sort)) {
                match = _.includes(
                    filterObj.value.toString(),
                    ((flattenedObj[filterObj.sort]) as any).toString()
                )
            }

            /** and if filterObj.not is true, get the reverse results. */
            return filterObj.not ? !match : match
        })

        // console.log('is filter array empty: ', filters.length)
        // console.log('search filters result: ', filterMatches)

        /**
         * the following cases should return a possible truthy condition
         * if search string returns TRUTHY, return true and proceed to filter check
         * otherwise, return false
         * in the array of filters, make sure ALL of the elements are true booleans
         * */
        return searchFound
            ? (filters.length
                ? !_.includes(filterMatches, false)
                : true
            )
            : false
    })

    // console.log('smartSearch result: ', result)

    return result
}

/**
 * there are multiple kinds of sorts that currently exist in the system
 * the tabular smartSort, colorSort for dashboard cards or any data with
 * severity, AND groupSort for grouping events.
 */

/**
 * @function smartOrder - cleaner sort function. recommnded to have filtered data.
 * starting from zero will the data be sorted. Also caters to nested properties.
 * If by any case it fails with a structure like {id: string, section: Section[]},
 * you are sorting the sections of that one report and not the collective.
 * @param tableData
 * @param columns
 * @returns any[]
 */
export const smartOrder = (
    tableData : any[],
    columns: Column[]
) => {
    const includedColumns = _.filter(columns, 'include')
    let result = tableData

    if (includedColumns.length) {
        // ['partner_name','partner_type'], ['asc', 'asc']
        const fields = _.map(includedColumns, (column) => column.value)
        const arranges = _.map(includedColumns, (column) => {
            return _.lowerCase(column.arrange)
        }) as Many<'asc' | 'desc'>

        result = _.orderBy(tableData, fields, arranges)
    }

    return result
}

export type GroupedData = [string, {
    data: any[]
}]

/**
 * @function smartGroup - this should return an objects with grouped results.
 * it can be used outside the event modules one day. keep intact.
 * @param tableData
 * @param columns
 * @returns {GroupedData[]} an array of GroupedData
 */
export const smartGroup: (
    tableData : any[],
    /** predefined on invocation */
    columns: Column[]) => GroupedData[] = (
        tableData,
        columns
    ) => {
        const groupedData = _.groupBy(tableData, (tableDataObj) => {
            const flattenedObj = flatten(tableDataObj) as Dictionary<any>
            return _.map(columns, (o) => flattenedObj[o.value])
        })

        const mappedKeys = _.mapKeys(
            groupedData,
            function (_value, key) {
                return _.replace(key, ',', '.')
            }
        )

        const mappedValues = _.mapValues(
            mappedKeys,
            function (value) {
                return {
                    data: value
                }
            }
        )
        const entries = _.entries(mappedValues)

        // map the keys.

        return entries
    }

/**
 * paginate finalized data
 */
export const paginate = (
    tableData: any[],
    index: number,
    count:number
) => {
    // start with zero when using lodash slice. so decrement by 1.
    const start = (count * (index - 1))
    const end = index * count

    return _.slice(tableData, start, end)
}

/** UPDATE: Also include theme  */
/** * */

export const getBGColor = (
    activeStyle: string,
    activeMode: string,
    // index would only have 10 indexes
    bgIndex: number
) : string => {
    const stylesheet = createStylesheet(activeStyle, activeMode)

    if (stylesheet && bgIndex < 10) {
        return stylesheet.mode.backgroundColors[bgIndex]
    }

    return ''
}

export const getColorPreset = (
    activeStyle: string,
    // index would only have 10 indexes
    colorPreset: ColorPresets
) : string => {
    const stylesheet = createStylesheet(activeStyle, 'dark')
    if (stylesheet) {
        return stylesheet.style.colorPresets[colorPreset]
    }

    return ''
}

// ranges are necessary.
export const createIntervals: (ranges: {
    start: Date,
    end: Date,
    }, fixedInterval: string) => Date[] =
    (ranges, fixedInterval) => {
        let intervals: Array<Date> = []

        /**
         * UPDATE (November 25, 2021): because the response data has
         * some timestamps what is not within the intervals, extend both ranges
         * by an extra interval.
         *
         * UPDATE (January 24, 2022): because the start and ends of the interval
         * array are extended by one, hovering the chart tooltip via index number
         * is ineffective. You are better off with a timestamp comparison.
         */

        // substring the number part of the string. if there isn't any,
        // also, return an empty array. we don't want any unwanted arrays.
        const matches = fixedInterval.match(/\d+/ig)

        // immediately return an empty string IF the range is incomplete.
        if (matches !== null && matches.length) {
            // let step = Number(matches?.[0] || '1')
            let step = Number(matches[0])

            // console.log('method.ts -> step: ', step)
            // console.log('method.ts -> fixedInterval: ', fixedInterval)
            // console.log('ends with s: ', _.endsWith(fixedInterval, 's'))

            if (_.endsWith(fixedInterval, 's')) {
                const difference = Math.abs(differenceInSeconds(
                    ranges.start,
                    ranges.end
                ))

                // console.log('method.ts -> ranges.start: ', ranges.start)
                // console.log('method.ts -> ranges.end: ', ranges.end)

                // console.log('method.ts -> difference: ', difference)
                // console.log('method.ts -> iterations: ', difference / step)

                // a fix to avoid VERY VERY LARGE iterations. if it's greater than
                // the globally assigned iteration limit, determine the right
                // interval to use so it would be less than that value.
                // perform recursive function until limit is no longer reached.
                let iterations = difference / step + 1

                while (iterations > CHART_INTERVAL_LIMIT) {
                    console.warn('iteration limit exceeded.')
                    step = step * 2
                    iterations = difference / step + 1
                }

                // console.log("iteration limit:", iterations);

                for (let i = -1; i <= iterations; i++) {
                    intervals.push(addSeconds(ranges.start, step * i))
                }

                // console.log('method.ts -> intervals: ', intervals)
            }

            if (_.endsWith(fixedInterval, 'm')) {
                intervals = eachMinuteOfInterval({
                    start: subMinutes(ranges.start, step),
                    end: addMinutes(ranges.end, step)
                }, {
                    step: step
                })
            }

            if (_.endsWith(fixedInterval, 'h')) {
                intervals = eachHourOfInterval({
                    start: subHours(ranges.start, step),
                    end: addHours(ranges.end, step)
                }, {
                    step: step
                })
            }

            if (_.endsWith(fixedInterval, 'd')) {
                intervals = eachDayOfInterval({
                    start: subDays(ranges.start, step),
                    end: addDays(ranges.end, step)
                }, {
                    step: step
                })
            }

            if (_.endsWith(fixedInterval, 'w')) {
                // intervals = eachWeekOfInterval({
                //     start: ranges.start,
                //     end: ranges.end
                // })
                intervals = eachDayOfInterval({
                    start: subWeeks(ranges.start, step),
                    end: addWeeks(ranges.end, step)
                }, {
                    step: step * 7
                })
            }

            if (_.endsWith(fixedInterval, 'mo')) {
                // intervals = eachMonthOfInterval({
                //     start: ranges.start,
                //     end: ranges.end
                // })
                intervals = eachDayOfInterval({
                    start: subMonths(ranges.start, step),
                    end: addMonths(ranges.end, step)
                }, {
                    step: step * 28
                })
            }

            if (_.endsWith(fixedInterval, 'y')) {
                // intervals = eachYearOfInterval({
                //     start: ranges.start,
                //     end: ranges.end
                // })
                intervals = eachDayOfInterval({
                    start: subYears(ranges.start, step),
                    end: addYears(ranges.end, step)
                }, {
                    step: step * 365
                })
            }
        }

        return intervals
    }

export const assignIntervalTick: (val: number, fixedInterval: string, intervals: string[])
=> string = (val, fixedInterval, intervals) => {
    let str = ''

    if (intervals[val]) {
        if (_.some(
            ['s', 'm', 'h'], (e) => _.endsWith(fixedInterval, e)
        )) {
            str = format(new Date(intervals[val]), 'hh:mm:ss aa')
        } else if (_.some(
            ['y', 'mo', 'd'], (e) => _.endsWith(fixedInterval, e)
        )) {
            str = format(new Date(intervals[val]), 'yyyy-MM-dd')
        }
    }

    return str
}

export const generateInterval = (startDate: Date, endDate: Date) => {
    if (differenceInYears(endDate, startDate)) {
        return '1y'
    } else if (differenceInMonths(endDate, startDate)) {
        return '1mo'
    } else if (differenceInDays(endDate, startDate)) {
        return '1d'
    } else if (differenceInHours(endDate, startDate)) {
        return '1h'
    } else if (differenceInMinutes(endDate, startDate)) {
        return '1m'
    } else if (differenceInSeconds(endDate, startDate)) {
        return '1s'
    } else {
        return '1h'
    }
}

export const assignDelta: (value: number | string) => DeltaIndicator = (value) => {
    if (typeof value === 'number') {
        if (isFinite(value)) {
            if (Math.trunc(value) > 0) {
                return 'positive'
            } else if (Math.trunc(value) < 0) {
                return 'negative'
            } else {
                return 'neutral'
            }
        } else {
            return 'infinite'
        }
    } else {
        return 'nonnumerical'
    }
}

export const assignValue: (value: number | string) => string = (value) => {
    if (typeof value === 'number') {
        if (isFinite(value)) {
            return Math.trunc(value).toString().concat('%')
        } else {
            return '0%'
        }
    } else {
        return ''
    }
}

export const getColorWithSeverity: (severity: Severity) => ColorPresets = (severity) => {
    let color:ColorPresets = 'darkGrey'

    switch (severity) {
        case 'informational':
            color = 'blue'
            break
        case 'low':
            color = 'yellow'
            break
        case 'medium':
            color = 'amber-2'
            break
        case 'high':
            color = 'amber-1'
            break
        case 'critical':
            color = 'red'
            break
        case 'severe':
            color = 'red'
            break
        default:break
    }

    return color
}

export const getColorFromStylesheet: (style: StyleProps | undefined, color: ColorPresets) =>
    string = (style, color) => {
        let result = '#000'

        if (style) {
            switch (color) {
                case 'amber-1':
                    result = style.colorPresets['amber-1']
                    break
                case 'amber-2':
                    result = style.colorPresets['amber-2']
                    break
                case 'black':
                    result = style.colorPresets.black
                    break
                case 'blue':
                    result = style.colorPresets.blue
                    break
                case 'darkGrey':
                    result = style.colorPresets.darkGrey
                    break
                case 'green':
                    result = style.colorPresets.green
                    break
                case 'grey':
                    result = style.colorPresets.grey
                    break
                case 'lightGrey':
                    result = style.colorPresets.lightGrey
                    break
                case 'red':
                    result = style.colorPresets.red
                    break
                case 'white':
                    result = style.colorPresets.white
                    break
                case 'yellow':
                    result = style.colorPresets.yellow
                    break
                default:break
            }
        }

        return result
    }

export const showChartTooltip = (
    datasetIndex: number, index: number,
    chart?: Chart<any, any[], any>
) => {
    if (chart && chart.tooltip) {
        const tooltip = chart.tooltip

        tooltip.setActiveElements(
            [{
                datasetIndex,
                index
            }],
            {
                x: 0,
                y: 0
            }
        )
        chart.update()
    } else {
        console.error("Chart instance doesn't exist")
    }
}

export const hideChartTooltip = (
    chart?: Chart<any, any[], any>
) => {
    if (chart && chart.tooltip) {
        const tooltip = chart.tooltip
        tooltip.setActiveElements([], {
            x: 0,
            y: 0
        })
        chart.update()
    }
}

export const sendUnavailableModalMessage = (
    serviceType: Card['service_type'], operation?: MonitorActionValue
) => {
    return operation
        ? `${ serviceType } operation: ${ operation } is unavailable`
        : `${ serviceType } is unavailable`
}

export const getUtcRanges: (ranges: {start: number, end: number }) => {
    start: number, end: number
} = (ranges) => {
    // UPDATE: get timezone offset before setting both the
    // range values.
    const timezoneOffset = new Date().getTimezoneOffset()

    const rangeStart = getUnixTime(
        add(
            fromUnixTime(ranges.start),
            { minutes: timezoneOffset }
        )
    )

    const rangeEnd = getUnixTime(
        add(
            fromUnixTime(ranges.end),
            { minutes: timezoneOffset }
        )
    )

    return {
        start: rangeStart,
        end: rangeEnd
    }
}

/** created after print report dilemma. Will convert utc to local ranges */
export const getLocalRanges: (ranges: {start: number, end: number }) => {
    start: number, end: number
} = (ranges) => {
    // UPDATE: get timezone offset before setting both the
    // range values.
    const timezoneOffset = new Date().getTimezoneOffset()

    const rangeStart = getUnixTime(
        add(
            fromUnixTime(ranges.start),
            { minutes: timezoneOffset * -1 }
        )
    )

    const rangeEnd = getUnixTime(
        add(
            fromUnixTime(ranges.end),
            { minutes: timezoneOffset * -1 }
        )
    )

    return {
        start: rangeStart,
        end: rangeEnd
    }
}

export const turnIntoLuceneQuery = (obj: Filter) => {
    const result: LuceneQuery = {
        bool: {
            should: obj.value.map((str) => {
                // can be a number too so check if it's a number.
                return ({
                    match_phrase: {
                        [obj.sort]: isNaN(Number(str))
                            ? str
                            : Number(str)
                    }
                })
            }),
            minimum_should_match: 1
        }
    }
    return result
}

export const turnIntoBoolItem: (
    obj: LuceneQuery,
    not: boolean
) => Filter = (obj, not) => {
    const result:Filter = {
        not: not,
        sort: '',
        value: []
    }

    if (obj.match_phrase) {
        const [key, value] = _.entries(obj.match_phrase)[0]
        result.sort = key
        result.value = [value]
    } else if (obj.bool) {
        const key = Object.keys(obj.bool.should[0].match_phrase)[0]
        const value = (obj.bool.should).map((obj) => {
            return obj.match_phrase[key]
        })
        result.sort = key
        result.value = value
    }

    return result
}

export const testImage = (
    url : string,
    setImgUrl: React.Dispatch<React.SetStateAction<string>>
) => {
    const timeout = 5000

    let timer: any = null

    const img = new Image()

    img.onerror = img.onabort = function () {
        clearTimeout(timer)
        setImgUrl('')
    }

    img.onload = function () {
        clearTimeout(timer)
        setImgUrl(url)
    }

    timer = setTimeout(function () {
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = '//!!!!/test.jpg'
        setImgUrl('')
    }, timeout)

    img.src = url
}

export function getUrlName (url: string): string | undefined {
    const routes = [
        ...Object.entries(PARTNER_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(ORDER_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(DASHBOARD_ACCOUNT_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(CONFIGURATION_STATUS_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(SOC_MONITOR_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(TACTICAL_MONITOR_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(GRC_MONITOR_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(PROFILE_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(EVENT_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(REPORT_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(FILTER_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(MONITORING_STATUS_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(MDR_DETAILED_DASHBOARD_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(O365_DETAILED_DASHBOARD_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(AZURE_DETAILED_DASHBOARD_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(COMPLIANCE_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(QUESTIONNAIRE_ROUTES).map(([key, value]) => ({
            key,
            ...value
        })),
        ...Object.entries(QUESTION_ROUTES).map(([key, value]) => ({
            key,
            ...value
        }))
    ]

    const matchingKey = routes.find((obj) => obj.link === url
    )

    if (matchingKey) {
        return matchingKey.name
    }

    return undefined
}

export const truncateString = (str: string, maxLength: number) => {
    return _.truncate(str, {
        length: maxLength,
        omission: '...' // The ellipsis (...) to be added at the end
    })
}

export const formatTableCellText = (text: string) => {
    // Replace any new lines, tabs, and multiple spaces with a single space
    const formattedText = text.replace(/[\n\t]+| {2,}/g, ' ')

    // Replace any encoded characters like '%20' with their respective characters
    const decodedText = decodeURIComponent(formattedText)

    return decodedText
}

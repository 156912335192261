
import { RootState } from '@app/store'
import {
    DeviceConfigModal,
    MainConfigState
} from '@interfaces/watchdog/configuration/device-config'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: MainConfigState = {
    modals: []
}

export const slice = createSlice({
    name: 'configMain',
    initialState: initialState,
    reducers: {
        addModal: (state: MainConfigState, action: PayloadAction<DeviceConfigModal>) => {
            state.modals.push(action.payload)
        },
        closeModal: (state: MainConfigState, action: PayloadAction<DeviceConfigModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.open = false
            }
        },
        removeModal: (state: MainConfigState, action: PayloadAction<DeviceConfigModal>) => {
            _.remove(state.modals, modal => {
                return (
                    modal.id === action.payload.id
                )
            })
        },
        resetMain: (state: MainConfigState) => {
            state.modals = initialState.modals
        }
    }
})

export const {
    addModal,
    closeModal,
    removeModal,
    resetMain
} = slice.actions

export const selectModals = (state: RootState) => state.configMain.modals

export default slice.reducer

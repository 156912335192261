
import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'

import {
    CHART_HEIGHT
} from '@constants/main/root'
import {
    EventType
} from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import {
    AzureDetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    addModal,
    selectCurrentParams,
    selectMapData
} from '@slices/dashboard/soc/azure/detailedDashboard/main'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/azure/details'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import PropTypes from 'prop-types'

import uniqueString from 'unique-string'
import L from 'leaflet'
import {
    add,
    fromUnixTime,
    getUnixTime
} from 'date-fns'

const AzureLocationsMap = ({ isPrint }: {isPrint?:boolean}) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const mapData = useAppSelector(selectMapData)

    const dispatch = useAppDispatch()

    const mapEl = useRef<HTMLDivElement>(null)
    const [mapid] = useState(uniqueString())
    const [mapInstance, setMapInstance] = useState<L.Map>()

    /** when this component mounts, you only have the first piece of the azure locations
     * data. You need to execute scrollId and update the values at the redux store
     * so that this can resume upon remount.
     *
     * conditions: recursive useEffect until isComplete is true
     *
     * you want to add a button where you cancel AND resume the calls.
     */

    useEffect(() => {
        if (mapEl.current && !mapInstance) {
            mapEl.current.style.height = CHART_HEIGHT.xl
            /** included in printOptions, remove zoom interface on print mode. */
            setMapInstance(L.map(mapid, {
                attributionControl: !isPrint,
                zoomControl: !isPrint
            }))
        }
    }, [mapInstance])

    useEffect(() => {
        if (mapInstance) {
            /** adjusted pan view from 2 to 1. Floats had no effect.
             * 2 if print mode is false,
             * 1 if print mode is true
            */
            mapInstance.setView([0, 0], isPrint ? 1 : 2)
            L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(mapInstance)

            return () => {
                mapInstance.off()
                mapInstance.remove()
                setMapInstance(undefined)
            }
        }
    }, [mapInstance])
    // MESSAGE.DATA.EMPTY_GEOIP

    useEffect(() => {
        if (mapInstance) {
            const geoipData = mapData?.data || []

            if ((geoipData)) {
                // console.log(mapInstance)
                // console.log('setting up data: ', geoipData)

                const markers = L.markerClusterGroup()

                const fields = _.compact(_.uniq(
                    _.map(geoipData, (obj) => obj.fields?.['geoip.location']?.[0] || '')
                ))

                _.forEach(fields, (coord) => {
                    const [lat, long] = _.split(coord, ',')

                    const marker = L.marker(
                        new L.LatLng(Number(lat), Number(long))
                    ).on('click', () => {
                        const eventType: EventType = 'Azure.Locations'
                        const detailsContent:AzureDetailsForm = {
                            event_type: eventType,
                            lat: _.trim(lat),
                            lon: _.trim(long),
                            q: currentParams.q
                        }

                        const serviceTypeFormData:ServiceTypeFormData = {
                            azure: {
                                details: detailsContent
                            }
                        }

                        const timezoneOffset = new Date().getTimezoneOffset()

                        const startDate = getUnixTime(
                            add(
                                fromUnixTime(currentParams.ranges.start),
                                { minutes: timezoneOffset * -1 }
                            )
                        )

                        const endDate = getUnixTime(
                            add(
                                fromUnixTime(currentParams.ranges.end),
                                { minutes: timezoneOffset * -1 }
                            )
                        )

                        dispatch(setDetailsStartDate(startDate))
                        dispatch(setDetailsEndDate(endDate))

                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            card: currentParams.card,
                            operation: 'DETAILS',
                            serviceTypeFormData: serviceTypeFormData,
                            isBorderWide: true
                        }))
                    })

                    markers.addLayer(marker)
                })

                // mapInstance.removeLayer(markers)
                mapInstance.addLayer(markers)

                return () => {
                    // console.log('updating marker cluster again')
                    mapInstance.removeLayer(markers)
                }
            }
        }
    }, [mapData, mapInstance, currentParams])

    const LeafletInstance = useMemo(() => {
        return <div ref={mapEl} id={mapid} />
    }, undefined)

    return <div>
        {LeafletInstance}
    </div>
}

AzureLocationsMap.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default AzureLocationsMap

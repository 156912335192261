import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalMutation
} from '@apis/dashboard/grc/gdpr-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as GDPR_MESSAGE
} from '@constants/dashboard/grc/gdpr'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import ComplianceIndexChart from '@features/dashboard/grc/gdpr/ComplianceIndexChart'
import ComplianceLineChart from '@features/dashboard/grc/gdpr/ComplianceLineChart'
import {
    CalculatedChapterData,
    Identifier,
    ModalRequest
} from '@interfaces/dashboard/grc/gdpr'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    resetGdprMain,
    selectCalculatedChapterData,
    selectCurrentParams,
    setCalculatedChapterData,
    setCurrentParams
} from '@slices/dashboard/grc/gdpr/main'
import {
    selectCardDetails,
    selectGdprMain,
    setCardDetails
} from '@slices/main/print/section'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const GdprModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const calculatedChapterData = useAppSelector(selectCalculatedChapterData)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectGdprMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    /** will be initiailized multiple times. */
    const [getModal, getModalMutation] = useGetModalMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    /** after useEffect to throw errors, create fetchData script.
     * NEW CHANGE from old system. make each call a separate script.
     * Was done because client requested to individually have refresh button on
     * each chart.
     *
     * UPDATE: only apply this case WHEN it needs to be. Because this module
     * has one refresh button that executes all api calls at once, put it all in once place.
    */

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const fetchData = () => {
        unsubscribeGetModal()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (
                    isMounted &&
                    dashboardMain.card &&
                    dashboardMain.searchParams
                ) {
                    /** at this point we will no longer use the default
                         * values for searchParams.
                         */
                    const newRanges = getUtcRanges({
                        start: dashboardMain.searchParams.timeFrom,
                        end: dashboardMain.searchParams.timeTo
                    })

                    dispatch(setCurrentParams({
                        ranges: newRanges
                    }))

                    const requestData: ModalRequest & TokenAuth = {
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        service_type: dashboardMain.card.serviceType,
                        chapterIdentifier: 'all',
                        in_face: dashboardMain.card.inFace,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()
                    }

                    getModalPromise = getModal(requestData)
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (isMounted && dashboardMain.card) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = {
            title: dashboardCardDetails?.title || '',
            line_1: dashboardCardDetails?.line_1 || '',
            line_2: dashboardCardDetails?.line_2 || '',
            heading: dashboardCardDetails?.heading || '',
            state: dashboardCardDetails?.state || '',
            state_details: dashboardCardDetails?.state_details || '',
            colorType: dashboardCardDetails?.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{GDPR_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ data.state || 'NO STATE' }
                    : ${ data.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        return () => {
            dispatch(resetGdprMain())
        }
    }, [])

    // a method to return a ColorPreset based on the score.
    const setStatcardColor: (value: number) => ColorPresets = (value) => {
        if (value < 30) return 'red'
        else if (value < 50) return 'amber-1'
        else if (value < 75) return 'amber-2'
        else if (value >= 75) return 'green'
        else return 'grey'
    }

    /** useEffect to merge chapterData and nistDatasets */
    useEffect(() => {
        const nistDatasets = getModalMutation.data?.data || []
        const chapterData = getModalMutation.data?.chapterData || []

        const result: CalculatedChapterData[] = _.map(
            _.cloneDeep(chapterData) as CalculatedChapterData[], (chapterDataObj, index, arr) => {
                // in each chapterDataObj, we want to get the scores of the alineas found in
                // paragraphData. beacuse each alineas has multiple pages, we need to get
                // their averages too.

                if (chapterDataObj.chapterIdentifier === 'gdpr') {
                    // in this case, you want to go through the chapterData array
                    // and get all the pages each.

                    const uniquePages: string[] = _.uniq(
                        _.reduce(arr, (storage: string[], { chapterData }) => {
                            _.forEach(chapterData, ({ paragraphData }) => {
                                _.forEach(paragraphData, ({ translationtoNist }) => {
                                    _.forEach(translationtoNist, ({ subcat, page }) => {
                                        storage.push(subcat || page || '')
                                    })
                                })
                            })

                            return storage
                        }, [])
                    )

                    // adding a new property called scoreData
                    // console.log(`got all the pages from all
                    //  chapters for the gdpr calculations`, uniquePages)
                    chapterDataObj.scoreData = _.map(
                        nistDatasets, ({ lastupdate, nistData }) => {
                            // replace map with creating simplified data from scratch.
                            type NistData = {lastupdate: number, data: Identifier[]}
                            const filtered: NistData[] = []
                            const simplified: NistData[] = []
                            // issue counts are all the instance found but the score is NOT 4.

                            let complianceNumerator: number = 0
                            let complianceDenominator: number = 0
                            let complianceScore: number = 0

                            let coverageNumerator: number = 0
                            let coverageDenominator: number = 0
                            let coverageScore: number = 0

                            /** push more objects to nistData property. */
                            _.forEach(nistData, ({ data }) => {
                                const pages = _.filter(
                                    data,
                                    ({ identifier }) => _.includes(uniquePages, identifier)
                                )

                                filtered.push({
                                    lastupdate: lastupdate,
                                    data: pages
                                })
                            })

                            _.forEach(filtered, ({ data }) => {
                                const removeZeroes = _.filter(
                                    data, ({ score }) => score > 0
                                )

                                simplified.push({
                                    lastupdate: lastupdate,
                                    data: removeZeroes
                                })
                            })

                            // console.log(`data has been filtered to get the pages from all
                            // chapters' paragraphs at timestamp ${ lastupdate }: `, filtered)
                            // console.log('filtered data with removed zeroes: ', simplified)

                            // not using simplified data here.
                            complianceNumerator = _.reduce(simplified, (total, { data }) => {
                                const chapterSum = _.reduce(data, (a, b) => {
                                    a += b.score
                                    return a
                                }, 0)
                                total += chapterSum
                                return total
                            }, 0)

                            complianceDenominator = _.reduce(simplified, (total, { data }) => {
                                const chapterSum = data.length * 4
                                total += chapterSum
                                return total
                            }, 0)

                            // nested reduce with complaince formula,
                            // then average it after adding all value.
                            complianceScore = complianceNumerator / complianceDenominator

                            coverageNumerator = _.reduce(filtered, (total, { data }) => {
                                const chapterTotal = _.filter(data, (a) => Boolean(a.score)).length
                                total += chapterTotal
                                return total
                            }, 0)

                            coverageDenominator = _.reduce(filtered, (total, { data }) => {
                                const chapterSum = data.length
                                total += chapterSum
                                return total
                            }, 0)

                            coverageScore = coverageNumerator / coverageDenominator

                            return ({
                                lastupdate: lastupdate,
                                complianceScore: complianceScore * 100,
                                coverageScore: coverageScore * 100
                            })

                            // end of script.
                        }
                    )

                    chapterDataObj.averageTotalComplianceScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.complianceScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0

                    chapterDataObj.averageTotalCoverageScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.coverageScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0
                } else {
                    _.forEach(chapterDataObj.chapterData, ({ paragraphData }) => {
                        _.forEach(paragraphData, (alineaObj) => {
                            alineaObj.pages = _.map(alineaObj.translationtoNist,
                                ({ subcat, page }) => {
                                    return subcat || page || ''
                                })
                        })
                    })

                    // from here you already have a new property called
                    // .paragraphData[index].pages where its an array of pages
                    // expected value for chapter 2 is 4 alinea scores to be
                    // computed to complianceScore and coverage score
                    chapterDataObj.scoreData = _.map(
                        nistDatasets, ({ lastupdate, nistData }) => {
                            let alineaScores: number[] = []
                            // from here, you need to get the scores
                            // with these pages above.
                            // filter -> flat for each paragraphData
                            // object. paragraphObj.alineaScore

                            _.forEach(
                                chapterDataObj.chapterData,
                                (paragraphObj) => {
                                    _.forEach(paragraphObj.paragraphData, (alineaObj) => {
                                        const filtered: Identifier[] = _.flattenDeep(
                                            _.map(nistData, ({ data }) => {
                                                return _.filter(data, ({ identifier }) => {
                                                    return _.includes(alineaObj.pages, identifier)
                                                })
                                            })
                                        )

                                        // console.log(`Scores retrieved
                                        // with pages flattened: `, filtered)

                                        alineaObj.alineaScore = filtered.length
                                            ? _.reduce(filtered, (a, b) => {
                                                a += b.score
                                                return a
                                            }, 0) / filtered.length
                                            : 0
                                        // you now have the score of oneAlinea
                                        // in each paragraphObj.paragraphData array.
                                    })

                                    // get all alineaScores from each paragraphObj.
                                    // paragraphData array and flat after.
                                    paragraphObj.groupedAlineaScores = _.map(
                                        paragraphObj.paragraphData,
                                        ({ alineaScore }) => {
                                            return alineaScore
                                        }
                                    )

                                    // console.log(`All alineaScores from
                                    // paragraph is grouped into one`,
                                    // paragraphObj.groupedAlineaScores)
                                }
                            )

                            _.forEach(
                                chapterDataObj.chapterData,
                                ({ groupedAlineaScores }) => {
                                    alineaScores = _.concat(alineaScores, groupedAlineaScores)
                                }
                            )

                            // console.log('alineaScores are: ', alineaScores)

                            // calculate complaince and coverage scores;
                            const complianceScore = alineaScores.length
                                ? _.reduce(alineaScores, (a, b) => {
                                    a += b
                                    return a
                                }, 0) / (alineaScores.length * 4)
                                : 0

                            const coverageScore = alineaScores.length
                                ? _.filter(alineaScores, (a) => {
                                    return Boolean(a)
                                }).length / (alineaScores.length)
                                : 0

                            return ({
                                lastupdate: lastupdate,
                                complianceScore: complianceScore * 100,
                                coverageScore: coverageScore * 100
                            })
                        }
                    )

                    chapterDataObj.averageTotalComplianceScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.complianceScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0

                    chapterDataObj.averageTotalCoverageScore = chapterDataObj.scoreData.length
                        ? _.reduce(
                            chapterDataObj.scoreData,
                            (a, b) => {
                                a += b.coverageScore
                                return a
                            }
                            , 0
                        ) / chapterDataObj.scoreData.length
                        : 0
                }
                return chapterDataObj
            })

        dispatch(setCalculatedChapterData(result))
    }, [
        getModalMutation.data
    ])

    /** useEffect to update statcard header color */
    useEffect(() => {
        if (calculatedChapterData.length) {
            const gdprData = _.find(calculatedChapterData,
                ({ chapterIdentifier }) => {
                    return chapterIdentifier === 'gdpr'
                }
            )

            // BUG: score already set to zero before loading the data.
            // change modal colors ONLY after.
            const score = gdprData?.averageTotalComplianceScore || 0

            setBorderColor(
                setStatcardColor(score)
            )
        }
    }, [calculatedChapterData])

    /** compliance index bar chart */
    const complianceIndexBarEl = useRef<HTMLCanvasElement>(null)

    const ComplianceIndexBar = useMemo(() => {
        const gdprObj = _.find(
            calculatedChapterData,
            ({ chapterIdentifier }) => chapterIdentifier === 'gdpr'
        )
        const score = gdprObj?.averageTotalComplianceScore || 0

        return <div>
            <ComplianceIndexChart
                score={score}
                chartEl={complianceIndexBarEl}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    /** retrieving summary data from two sources. */
    const GdprSummary = useMemo(() => {
        const overallObj = calculatedChapterData.find(
            ({ chapterIdentifier }) => (chapterIdentifier === 'gdpr')
        )

        const content = <div>
            <span className={'d-inline-block mb-2'}>
                {overallObj?.titleName || overallObj?.chapterName}
            </span>
            <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            overallObj?.summaryText
                                ? <Text size={'sm'}>
                                    {overallObj.summaryText}
                                </Text>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{GDPR_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [
        getModalMutation.data,
        calculatedChapterData
    ])

    const CoverageSummary = useMemo(() => {
        const coverageObj = calculatedChapterData.find(
            ({ chapterIdentifier }) => (chapterIdentifier === 'coverage')
        )

        const content = <div>
            <span className={'d-inline-block mb-2'}>
                {coverageObj?.titleName || coverageObj?.chapterName}
            </span>
            <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            coverageObj?.summaryText
                                ? <Text size={'sm'}>
                                    {coverageObj.summaryText}
                                </Text>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        </div>

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{GDPR_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [
        getModalMutation.data,
        calculatedChapterData
    ])

    /** create a line chart */
    const ComplianceScoresLineChart = useMemo(() => {
        return (
            <ComplianceLineChart
                calculatedChapterData={calculatedChapterData}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        )
    }, [
        calculatedChapterData,
        getModalMutation.data
    ])

    /** now create an array of containers containing a gauge
     * and an index bar chart.
     */

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}

            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>
            {/* the rest of the content will be surrounded by a container with padding. */}
            <PrintMargin>
                {/* header render */}
                <div className={'row justify-content-between mb-3'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>
                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col-9'}>
                        {Statcard}
                    </div>
                    <div className={'col-3'}>
                        {ComplianceIndexBar}
                    </div>
                </div>

                <PageBreakInside className={'min-width-fix'}>
                    {GdprSummary}
                </PageBreakInside>

                <PageBreakInside className={'min-width-fix'}>
                    {CoverageSummary}
                </PageBreakInside>

                <PageBreakInside className={'min-width-fix'}>
                    {ComplianceScoresLineChart}
                </PageBreakInside>

            </PrintMargin>

        </div>
    )
}

export default GdprModal

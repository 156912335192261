import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalMutation
} from '@apis/dashboard/soc/vss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { TEXT as MONITOR_TEXT } from '@constants/dashboard/monitor'
import {
    MESSAGE as EXT_VSS_MESSAGE,
    TEXT as EXT_VSS_TEXT
} from '@constants/dashboard/soc/ext-vss'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import IssuesPerCategoryChart from '@features/dashboard/soc/vss/IssuesPerCategoryChart'
import IssuesPerIPChart from '@features/dashboard/soc/vss/IssuesPerIPChart'
import ScanResultsDataTable from '@features/dashboard/soc/vss/print/ScanResultsDataTable'
import VssClassificationChart from '@features/dashboard/soc/vss/VssClassificationChart'
import VulnerabilityIndexChart from '@features/dashboard/soc/vss/VulnerabilityIndexBarChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    ModalHeader,
    ModalRequest,
    ScanResultFilter
} from '@interfaces/dashboard/soc/ext-vss'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectCardDetails,
    selectVssMain,
    setCardDetails
} from '@slices/main/print/section'
import {
    resetVssMain,
    selectCurrentParams,
    setCurrentParams,
    setFilters,
    setSearch
} from '@slices/dashboard/soc/vss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const VssModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectVssMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    /** will be initiailized multiple times. */
    const [getModal, getModalMutation] = useGetModalMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, {
                ...TOASTIFY_DEFAULT_OPTIONS
            })
        }
    }, [getModalMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const unsubscribeGetModal = () => {
        const unsubscribeMutation = getModal({
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetModal()

        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                    dashboardMain.card &&
                    dashboardMain.searchParams
                    ) {
                    /** at this point we will no longer use the default
                         * values for searchParams.
                         */
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: ModalRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            service_type: dashboardMain.card.serviceType,
                            in_face: dashboardMain.card.inFace,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalPromise = getModal(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalPromise && getModalPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data = getModalMutation.data?.data[0]

        const statcardData: ModalHeader = data?.header || {
            title: dashboardCardDetails?.title || '',
            line_1: dashboardCardDetails?.line_1 || '',
            line_2: dashboardCardDetails?.line_2 || '',
            heading: dashboardCardDetails?.heading || '',
            colorType: dashboardCardDetails?.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={statcardData.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {statcardData.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {statcardData.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {statcardData.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalMutation.isLoading
                        ? getModalMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalMutation.data?.data[0]
        setBorderColor(
            data?.header.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalMutation
    ])

    useEffect(() => {
        return () => {
            dispatch(resetVssMain())
        }
    }, [])

    /** create a vulnerability index bar chart. this was previously done
     * with a chartjs instance but... we can use div elements
     * where the marker's position can be determined by passing a prop.
     */

    /** vss classification chart */
    const VssClassification = useMemo(() => {
        return <div>
            <VssClassificationChart
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const VulnerabilityIndexBar = useMemo(() => {
        return <div>
            <VulnerabilityIndexChart
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    /** management summary. max lines should always be 6. */
    const ManagementSummary = useMemo(() => {
        const data = getModalMutation.data?.data[0]

        const content = (
            <Container bgIndex={2} className={'px-3 py-2'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {
                            data && data.conclusionData.NumberScore
                                ? <Text size={'sm'}>
                                    {data.conclusionData.NumberScore}<br /><br />
                                    {data.conclusionData.Conclusion}<br /><br />
                                    {data.conclusionData.LongTermRecommendation}<br /><br />
                                    {data.conclusionData.ShortTermRecommendation}
                                </Text>
                                : ''
                        }
                    </div>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalMutation.isLoading
                ? getModalMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalMutation.error)
                : LoadingContent
        )
    }, [getModalMutation.data])

    /** issues per category and then ip */

    /** no chart instance useState for these two since there's no other
     * component related to this.
     */
    const issuesPerIpElChartEl = useRef<HTMLCanvasElement>(null)
    const issuesPerCategoryChartEl = useRef<HTMLCanvasElement>(null)

    /** added an optional prop to disable height adjustments
     * if the issue per ip chart is too tall.
     */
    const IssuesPerCategory = useMemo(() => {
        return <div>
            <IssuesPerCategoryChart
                issuesPerIpChartEl={issuesPerIpElChartEl}
                issuesPerCategoryChartEl={issuesPerCategoryChartEl}
                data={getModalMutation.data?.data[0]}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const IssuesPerIP = useMemo(() => {
        return <div>
            <IssuesPerIPChart
                issuesPerIpChartEl={issuesPerIpElChartEl}
                issuesPerCategoryChartEl={issuesPerCategoryChartEl}
                data={getModalMutation.data?.data[0]}
                isPrint={true}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        </div>
    }, undefined)

    const ScanResultsTable = useMemo(() => {
        return (
            <ScanResultsDataTable
                printOptions={dashboardMain.printOptions}
                data={getModalMutation.data}
                isLoading={getModalMutation.isLoading}
                isSuccess={getModalMutation.isSuccess}
                error={getModalMutation.error}
            />
        )
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** useEffect to apply searchParams.filterList to tableData.filters
     * Currently printOptions has no retain order option but will be added
     * once requested as a feature ticket
     */
    useEffect(() => {
        dispatch(setFilters(
            (dashboardMain.searchParams?.filters || []) as ScanResultFilter[]
        ))
    }, [dashboardMain.searchParams?.filters])

    /** useEffect to apply searchParams.search to tableData.search */
    useEffect(() => {
        dispatch(setSearch(
            dashboardMain.searchParams?.search || ''
        ))
    }, [dashboardMain.searchParams?.search])

    return (

        // NOTE: no need for responsiveness here anymore. Go with one width class.
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* the rest of the content will be surrounded by a container with padding. */}

            <PrintMargin>

                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                <div className={'row mb-2 align-items-center'}>
                    <div className={'col-12 mb-2'}>
                        {Statcard}
                    </div>
                    <div className={'col-9'}>
                        {VssClassification}
                    </div>
                    {/* ideally should be a fixed width in all screen sizes
                so we will add a div inside and apply that fixed width */}
                    <div className={'col-3'}>
                        {VulnerabilityIndexBar}
                    </div>
                </div>

                {/* layout for adding a title and the content changed because of collapse button
            line break on smaller devices */}
                <PageBreakInside className={'min-width-fix mb-3'}>
                    <span className={'d-inline-block mb-2'}>
                        {EXT_VSS_TEXT.SECTIONS.MANAGEMENT_SUMMARY}
                    </span>
                    {ManagementSummary}
                </PageBreakInside>

                {/* min-width-fix issues IF toggle device toolbar is disabled and horizontal
            resize is used. use the device toolbar from now on for more accurate results.
            also found at existing modals. */}
                <PageBreakInside className={'row mb-2'}>
                    <span className={'d-inline-block mb-2'}>
                        {EXT_VSS_TEXT.SECTIONS.VULNERABILITY_OVERVIEW}
                    </span>
                    {/* to avoid tall issues per category chart because
                    of issues per ip, just change the col class to 12
                    instead of 6. */}
                    <div className={
                        (getModalMutation.data?.data[0]?.ScanResults || []).length >= 100
                            ? 'col-12'
                            : 'col-6'
                    }>
                        <span className={'d-inline-block mb-2'}>
                            {EXT_VSS_TEXT.SECTIONS.ISSUES_PER_CATEGORY}
                        </span>
                        {IssuesPerCategory}
                    </div>
                    <div className={
                        (getModalMutation.data?.data[0]?.ScanResults || []).length >= 100
                            ? 'col-12'
                            : 'col-6'
                    }>
                        <span className={'d-inline-block mb-2'}>
                            {EXT_VSS_TEXT.SECTIONS.ISSUES_PER_IP}
                        </span>
                        {IssuesPerIP}
                    </div>
                </PageBreakInside>

                {
                    !dashboardMain.printOptions.mainReportOnly
                        ? <PageBreakInside className={'min-width-fix'}>
                            <span className={'d-inline-block mb-2'}>
                                {EXT_VSS_TEXT.SECTIONS.DISCOVERED_ISSUES}
                            </span>
                            {/* table stuff */}
                            {ScanResultsTable}
                        </PageBreakInside>
                        : ''
                }
            </PrintMargin>

        </div>
    )
}

export default VssModal

import {
    useO365SetTrustedLocationMutation
} from '@apis/watchdog/configuration/device-config-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as CONFIG_MESSAGE,
    TEXT as CONFIG_TEXT
} from '@constants/watchdog/configuration/device-config'
import {
    DeviceConfigModal
} from '@interfaces/watchdog/configuration/device-config'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const DoM365UpdateLocations = ({ modal, addModal, closeModal } : {
    modal: DeviceConfigModal,
    addModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
    closeModal: ActionCreatorWithPayload<DeviceConfigModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [
        m365SetTrustedLocation, m365SetTrustedLocationMutation
    ] = useO365SetTrustedLocationMutation()

    /** call formiks */

    const submitUpdateData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData.m365?.updateLocationConfirm) {
            const id = modal.formData.m365.updateLocationConfirm.id
            const deviceId = modal.formData.m365.updateLocationConfirm.deviceId
            const values = modal.formData.m365.updateLocationConfirm.formValues

            m365SetTrustedLocation({
                id: id,
                locations: JSON.stringify(values.locations),
                deviceid: deviceId,
                authToken: newToken
            })
        }
    }, [
        modal.formData.m365
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (m365SetTrustedLocationMutation.data) {
            const data = m365SetTrustedLocationMutation.data
            if (data.result) {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(
                    CONFIG_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [m365SetTrustedLocationMutation.data])

    useEffect(() => {
        if (m365SetTrustedLocationMutation.error) {
            console.error(m365SetTrustedLocationMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [m365SetTrustedLocationMutation.error])

    /** confirmation window for both update locations AND update m365 */
    const SubmitButton = useMemo(() => {
        const buttonContent = m365SetTrustedLocationMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        CONFIG_TEXT.M365_LOCATION_UPDATE.FORM.LOADING_BUTTON
                    }</span>
                </SpinnerContainer>
            )
            : CONFIG_TEXT.M365_LOCATION_UPDATE.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={submitUpdateData}
                disabled={m365SetTrustedLocationMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [m365SetTrustedLocationMutation.isLoading])

    return (<div>
        <h5>{CONFIG_TEXT.M365_LOCATION_UPDATE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {CONFIG_TEXT.M365_LOCATION_UPDATE.CONFIRMATION}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default DoM365UpdateLocations

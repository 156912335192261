import {
    useUpdatePartnerMutation
} from '@apis/watchdog/account-data/partner-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as PARTNER_MESSAGE,
    TEXT as PARTNER_TEXT
} from '@constants/watchdog/account-data/partner'
import {
    PartnerModal
} from '@interfaces/watchdog/account-data/partner'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const UpdateConfirmation = ({ modal, addModal, closeModal } : {
    modal: PartnerModal,
    addModal: ActionCreatorWithPayload<PartnerModal, string>,
    closeModal: ActionCreatorWithPayload<PartnerModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [updatePartner, updatePartnerMutation] = useUpdatePartnerMutation()

    /** call formiks */

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData?.updateConfirm) {
            const id = modal.formData.updateConfirm.id
            const values = modal.formData.updateConfirm.formValues

            updatePartner({
                partnerID: id,
                authToken: newToken,
                active: values.active,
                companyName: values.companyName,
                partnerState: values.partnerState,
                partnerName: values.partnerName,
                email: values.email,
                streetName: values.streetName,
                postalCode: values.postalCode,
                city: values.city,
                country: values.country,
                discount: values.discount,
                partnerType: values.partnerType,
                partnerOf: values.partnerOf,
                currencies: values.currencies
            })
        }
    }, [
        modal.formData
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (updatePartnerMutation.data) {
            const data = updatePartnerMutation.data
            if (data.statusMessage) {
                toast.success(data.statusMessage, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(closeModal(modal))
            } else {
                toast.error(
                    PARTNER_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [updatePartnerMutation.data])

    useEffect(() => {
        if (updatePartnerMutation.error) {
            console.error(updatePartnerMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updatePartnerMutation.error])

    /** confirmation window for both update locations AND update m365 */
    const SubmitButton = useMemo(() => {
        const buttonContent = updatePartnerMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{PARTNER_TEXT.UPDATE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : PARTNER_TEXT.UPDATE.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={submitData}
                disabled={updatePartnerMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [updatePartnerMutation.isLoading])

    return (<div>
        <h5>{PARTNER_TEXT.UPDATE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {PARTNER_TEXT.UPDATE.CONFIRMATION_MESSAGE}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default UpdateConfirmation

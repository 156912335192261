import {
    INCIDENT_COSTS_HEADERS,
    MESSAGE as IMM_MESSAGE
} from '@constants/dashboard/tac/imm'
import {
    assignDelta,
    assignValue
} from '@constants/main/method'
import {
    IncidentCost,
    IncidentData
} from '@interfaces/dashboard/tac/imm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    SpinnerContainer,
    Table
} from '@styles/components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

interface ComponentProps {
    // current and previous data is required.
    current: IncidentData[] | undefined,
    previous: IncidentData[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IncidentCostsTable = ({
    current, previous, isLoading, isSuccess, error
} : ComponentProps) => {
    const DataTable = useMemo(() => {
        // initialize default values for totalCosts and meanCosts.
        const totalCosts: IncidentCost = {
            type: 'Total Incident Cost',
            cost: 0,
            delta: '',
            percentage: '',
            costPrevious: 0
        }
        const meanCosts: IncidentCost = {
            type: 'Mean Incident Cost',
            cost: 0,
            delta: '',
            percentage: '',
            costPrevious: 0
        }

        totalCosts.cost = _.reduce(
            _.map(current, (obj) => {
                return obj.hours_spent * obj.hourly_cost
            }),
            (a, b) => {
                a += b
                return a
            }, 0
        )

        totalCosts.costPrevious = _.reduce(
            _.map(previous, (obj) => {
                return obj.hours_spent * obj.hourly_cost
            }),
            (a, b) => {
                a += b
                return a
            }, 0
        )
        // don't show percentage if there is no history.
        if (previous && previous.length) {
            totalCosts.percentage = assignValue((
                (
                    (totalCosts.cost - totalCosts.costPrevious) / totalCosts.cost
                ) * 100
            ))
        }

        totalCosts.delta = assignDelta(totalCosts.percentage)

        meanCosts.cost = current?.length
            ? (totalCosts.cost / current.length)
            : 0
        meanCosts.costPrevious = previous?.length
            ? (totalCosts.cost / previous.length)
            : 0

        if (previous && previous.length) {
            meanCosts.percentage = assignValue((
                (
                    (meanCosts.cost - meanCosts.costPrevious) / meanCosts.cost
                ) * 100
            ))
        }
        meanCosts.delta = assignDelta(meanCosts.percentage)

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(INCIDENT_COSTS_HEADERS, ({ label }, index) => {
                                const key = [
                                    'incidentCategory-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{totalCosts.type}</td>
                        <td className={'pe-4'}>{
                            <div className={'row align-items-center'}>
                                <span className={'col-6'}>
                                    {totalCosts.cost.toFixed(2)}
                                </span>
                                <span className={[
                                    'col-6 delta',
                                    totalCosts.delta
                                ].join(' ')}>
                                    {assignValue(totalCosts.percentage)}
                                </span>
                            </div>
                        }</td>
                        <td>{totalCosts.costPrevious.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>{meanCosts.type}</td>
                        <td className={'pe-4'}>{
                            <div className={'row align-items-center'}>
                                <span className={'col-6'}>
                                    {meanCosts.cost.toFixed(2)}
                                </span>
                                <span className={[
                                    'col-6 delta',
                                    meanCosts.delta
                                ].join(' ')}>
                                    {assignValue(meanCosts.percentage)}
                                </span>
                            </div>
                        }</td>
                        <td>{meanCosts.costPrevious.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </Table>

        return content
    }, [
        current,
        previous
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{IMM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IncidentCostsTable.propTypes = {
    current: PropTypes.array,
    previous: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IncidentCostsTable


import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetAzureAzureActivityMutation,
    useGetAzureCommonSecurityLogMutation,
    useGetAzureEventTypesMutation,
    useGetAzureKubeEventsMutation,
    useGetAzureKubeMonAgentEventsMutation,
    useGetAzureProtectionStatusMutation,
    useGetAzureSecurityEventMutation,
    useGetAzureSysLogComputerMutation,
    useGetAzureSysLogProcessNameMutation,
    useGetAzureUpdateMutation,
    useGetModalHeaderMutation
} from '@apis/dashboard/soc/azure-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as AZURE_MESSAGE
} from '@constants/dashboard/soc/azure/main'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import AzureBarChart from '@features/dashboard/soc/azure/print/AzureBarChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    FixedCollapsibles,
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/azure/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectCurrentParams,
    selectFixedCollapsibles,
    setCurrentParams,
    toggleCollapsible
} from '@slices/dashboard/soc/azure/main'
import {
    selectCardDetails,
    selectAzureMain,
    setCardDetails
} from '@slices/main/print/section'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer
} from '@styles/components'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { toast } from 'react-toastify'

import { useDebouncedCallback } from 'use-debounce'

const AzureModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectAzureMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [getAzureUpdate, getAzureUpdateMutation] = useGetAzureUpdateMutation()
    const [getAzureSecurityEvent,
        getAzureSecurityEventMutation] = useGetAzureSecurityEventMutation()
    const [getAzureEventTypes,
        getAzureEventTypesMutation] = useGetAzureEventTypesMutation()
    const [getAzureKubeEvents,
        getAzureKubeEventsMutation] = useGetAzureKubeEventsMutation()
    const [getAzureAzureActivity,
        getAzureAzureActivityMutation] = useGetAzureAzureActivityMutation()
    const [getAzureCommonSecurityLog,
        getAzureCommonSecurityLogMutation] = useGetAzureCommonSecurityLogMutation()
    const [getAzureSysLogComputer,
        getAzureSysLogComputerMutation] = useGetAzureSysLogComputerMutation()
    const [getAzureProtectionStatus,
        getAzureProtectionStatusMutation] = useGetAzureProtectionStatusMutation()
    const [getAzureSysLogProcessName,
        getAzureSysLogProcessNameMutation] = useGetAzureSysLogProcessNameMutation()
    const [getAzureKubeMonAgentEvents,
        getAzureKubeMonAgentEventsMutation] = useGetAzureKubeMonAgentEventsMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getAzureUpdateMutation.error) {
            console.error(getAzureUpdateMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureUpdateMutation.error])

    useEffect(() => {
        if (getAzureSecurityEventMutation.error) {
            console.error(getAzureSecurityEventMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureSecurityEventMutation.error])

    useEffect(() => {
        if (getAzureEventTypesMutation.error) {
            console.error(getAzureEventTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureEventTypesMutation.error])

    useEffect(() => {
        if (getAzureKubeEventsMutation.error) {
            console.error(getAzureKubeEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureKubeEventsMutation.error])

    useEffect(() => {
        if (getAzureAzureActivityMutation.error) {
            console.error(getAzureAzureActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureAzureActivityMutation.error])

    useEffect(() => {
        if (getAzureCommonSecurityLogMutation.error) {
            console.error(getAzureCommonSecurityLogMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureCommonSecurityLogMutation.error])

    useEffect(() => {
        if (getAzureSysLogComputerMutation.error) {
            console.error(getAzureSysLogComputerMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureSysLogComputerMutation.error])

    useEffect(() => {
        if (getAzureProtectionStatusMutation.error) {
            console.error(getAzureProtectionStatusMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureProtectionStatusMutation.error])

    useEffect(() => {
        if (getAzureSysLogProcessNameMutation.error) {
            console.error(getAzureSysLogProcessNameMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureSysLogProcessNameMutation.error])

    useEffect(() => {
        if (getAzureKubeMonAgentEventsMutation.error) {
            console.error(getAzureKubeMonAgentEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getAzureKubeMonAgentEventsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetModalRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        dispatch(setCurrentParams({
            ranges: newRanges
        }))

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString()
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureUpdate = () => {
        const unsubscribeMutation = getAzureUpdate({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureSecurityEvent = () => {
        const unsubscribeMutation = getAzureSecurityEvent({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureEventTypes = () => {
        const unsubscribeMutation = getAzureEventTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureKubeEvents = () => {
        const unsubscribeMutation = getAzureKubeEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureAzureActivity = () => {
        const unsubscribeMutation = getAzureAzureActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureCommonSecurityLog = () => {
        const unsubscribeMutation = getAzureCommonSecurityLog({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureSysLogComputer = () => {
        const unsubscribeMutation = getAzureSysLogComputer({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureProtectionStatus = () => {
        const unsubscribeMutation = getAzureProtectionStatus({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureSysLogProcessName = () => {
        const unsubscribeMutation = getAzureSysLogProcessName({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureKubeMonAgentEvents = () => {
        const unsubscribeMutation = getAzureKubeMonAgentEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchAzureUpdateData = () => {
        unsubscribeGetAzureUpdate()
        let getAzureUpdatePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureUpdatePromise = getAzureUpdate(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureUpdatePromise && getAzureUpdatePromise.abort()
        }
    }

    const fetchAzureSecurityEventData = () => {
        unsubscribeGetAzureSecurityEvent()
        let getAzureSecurityEventPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureSecurityEventPromise = getAzureSecurityEvent(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureSecurityEventPromise && getAzureSecurityEventPromise.abort()
        }
    }

    const fetchAzureEventTypesData = () => {
        unsubscribeGetAzureEventTypes()
        let getAzureEventTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureEventTypesPromise = getAzureEventTypes(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureEventTypesPromise && getAzureEventTypesPromise.abort()
        }
    }

    const fetchAzureKubeEventsData = () => {
        unsubscribeGetAzureKubeEvents()
        let getAzureKubeEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeEventsPromise = getAzureKubeEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeEventsPromise && getAzureKubeEventsPromise.abort()
        }
    }

    const fetchAzureAzureActivityData = () => {
        unsubscribeGetAzureAzureActivity()
        let getAzureAzureActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureAzureActivityPromise = getAzureAzureActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureAzureActivityPromise && getAzureAzureActivityPromise.abort()
        }
    }

    const fetchAzureCommonSecurityLogData = () => {
        unsubscribeGetAzureCommonSecurityLog()
        let getAzureCommonSecurityLogPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureCommonSecurityLogPromise = getAzureCommonSecurityLog(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureCommonSecurityLogPromise && getAzureCommonSecurityLogPromise.abort()
        }
    }

    const fetchAzureSysLogComputerData = () => {
        unsubscribeGetAzureSysLogComputer()
        let getAzureSysLogComputerPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureSysLogComputerPromise = getAzureSysLogComputer(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureSysLogComputerPromise && getAzureSysLogComputerPromise.abort()
        }
    }

    const fetchAzureProtectionStatusData = () => {
        unsubscribeGetAzureProtectionStatus()
        let getAzureProtectionStatusPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureProtectionStatusPromise = getAzureProtectionStatus(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureProtectionStatusPromise && getAzureProtectionStatusPromise.abort()
        }
    }

    const fetchAzureSysLogProcessName = () => {
        unsubscribeGetAzureSysLogProcessName()
        let getAzureSysLogProcessNamePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureSysLogProcessNamePromise = getAzureSysLogProcessName(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureSysLogProcessNamePromise && getAzureSysLogProcessNamePromise.abort()
        }
    }

    const fetchAzureKubeMonAgentEvents = () => {
        unsubscribeGetAzureKubeMonAgentEvents()
        let getAzureKubeMonAgentEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeMonAgentEventsPromise = getAzureKubeMonAgentEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeMonAgentEventsPromise && getAzureKubeMonAgentEventsPromise.abort()
        }
    }
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureUpdateData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureSecurityEventData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureEventTypesData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureKubeEventsData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureAzureActivityData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureCommonSecurityLogData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureSysLogComputerData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureProtectionStatusData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureSysLogProcessName()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureKubeMonAgentEvents()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Azure.Header data
         * than detailed card data.
         */

        type Header = ModalHeaderResponse['data'] & { title: string }
        const data: Header = {
            title: dashboardCardDetails?.title || '',
            ...getModalHeaderMutation.data?.data || {
                line_1: dashboardCardDetails?.line_1 || '',
                line_2: dashboardCardDetails?.line_2 || '',
                heading: dashboardCardDetails?.heading || '',
                colorType: dashboardCardDetails?.colorType || 'grey'
            }
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{AZURE_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** now we create our charts. */
    const AzureProtectionStatus = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.ProtectionStatus'}
                data={getAzureProtectionStatusMutation.data}
                isLoading={getAzureProtectionStatusMutation.isLoading}
                isSuccess={getAzureProtectionStatusMutation.isSuccess}
                error={getAzureProtectionStatusMutation.error}
            />
        </div>
    }, undefined)

    const AzureUpdate = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.Update'}
                data={getAzureUpdateMutation.data}
                isLoading={getAzureUpdateMutation.isLoading}
                isSuccess={getAzureUpdateMutation.isSuccess}
                error={getAzureUpdateMutation.error}
            />
        </div>
    }, undefined)

    const AzureCommonSecurityLog = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.CommonSecurityLog'}
                data={getAzureCommonSecurityLogMutation.data}
                isLoading={getAzureCommonSecurityLogMutation.isLoading}
                isSuccess={getAzureCommonSecurityLogMutation.isSuccess}
                error={getAzureCommonSecurityLogMutation.error}
            />
        </div>
    }, undefined)

    const AzureAzureActivity = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.AzureActivity'}
                data={getAzureAzureActivityMutation.data}
                isLoading={getAzureAzureActivityMutation.isLoading}
                isSuccess={getAzureAzureActivityMutation.isSuccess}
                error={getAzureAzureActivityMutation.error}
            />
        </div>
    }, undefined)

    const AzureSecurityEvent = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.SecurityEvent'}
                data={getAzureSecurityEventMutation.data}
                isLoading={getAzureSecurityEventMutation.isLoading}
                isSuccess={getAzureSecurityEventMutation.isSuccess}
                error={getAzureSecurityEventMutation.error}
            />
        </div>
    }, undefined)

    const AzureKubeEvents = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.KubeEvents'}
                data={getAzureKubeEventsMutation.data}
                isLoading={getAzureKubeEventsMutation.isLoading}
                isSuccess={getAzureKubeEventsMutation.isSuccess}
                error={getAzureKubeEventsMutation.error}
            />
        </div>
    }, undefined)

    const AzureSysLogComputer = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.SysLog.Computer'}
                data={getAzureSysLogComputerMutation.data}
                isLoading={getAzureSysLogComputerMutation.isLoading}
                isSuccess={getAzureSysLogComputerMutation.isSuccess}
                error={getAzureSysLogComputerMutation.error}
            />
        </div>
    }, undefined)

    const AzureEventTypes = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.EventTypes'}
                data={getAzureEventTypesMutation.data}
                isLoading={getAzureEventTypesMutation.isLoading}
                isSuccess={getAzureEventTypesMutation.isSuccess}
                error={getAzureEventTypesMutation.error}
            />
        </div>
    }, undefined)

    const AzureSysLogProcessName = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.SysLog.ProcessName'}
                data={getAzureSysLogProcessNameMutation.data}
                isLoading={getAzureSysLogProcessNameMutation.isLoading}
                isSuccess={getAzureSysLogProcessNameMutation.isSuccess}
                error={getAzureSysLogProcessNameMutation.error}
            />
        </div>
    }, undefined)

    const AzureKubeMonAgentEvents = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.KubeMonAgentEvents'}
                data={getAzureKubeMonAgentEventsMutation.data}
                isLoading={getAzureKubeMonAgentEventsMutation.isLoading}
                isSuccess={getAzureKubeMonAgentEventsMutation.isSuccess}
                error={getAzureKubeMonAgentEventsMutation.error}
            />
        </div>
    }, undefined)

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getAzureUpdateMutation.data &&
            getAzureSecurityEventMutation.data &&
            getAzureEventTypesMutation.data &&
            getAzureKubeEventsMutation.data &&
            getAzureAzureActivityMutation.data &&
            getAzureCommonSecurityLogMutation.data &&
            getAzureSysLogComputerMutation.data &&
            getAzureProtectionStatusMutation.data &&
            getAzureSysLogProcessNameMutation.data &&
            getAzureKubeMonAgentEventsMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getAzureUpdateMutation.data,
        getAzureSecurityEventMutation.data,
        getAzureEventTypesMutation.data,
        getAzureKubeEventsMutation.data,
        getAzureAzureActivityMutation.data,
        getAzureCommonSecurityLogMutation.data,
        getAzureSysLogComputerMutation.data,
        getAzureProtectionStatusMutation.data,
        getAzureSysLogProcessNameMutation.data,
        getAzureKubeMonAgentEventsMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getAzureUpdateMutation.error ||
            getAzureSecurityEventMutation.error ||
            getAzureEventTypesMutation.error ||
            getAzureKubeEventsMutation.error ||
            getAzureAzureActivityMutation.error ||
            getAzureCommonSecurityLogMutation.error ||
            getAzureSysLogComputerMutation.error ||
            getAzureProtectionStatusMutation.error ||
            getAzureSysLogProcessNameMutation.error ||
            getAzureKubeMonAgentEventsMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getAzureUpdateMutation.error,
        getAzureSecurityEventMutation.error,
        getAzureEventTypesMutation.error,
        getAzureKubeEventsMutation.error,
        getAzureAzureActivityMutation.error,
        getAzureCommonSecurityLogMutation.error,
        getAzureSysLogComputerMutation.error,
        getAzureProtectionStatusMutation.error,
        getAzureSysLogProcessNameMutation.error,
        getAzureKubeMonAgentEventsMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>
                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <div className={'min-width-fix'}>
                    {AzureEventTypes}
                </div>

                <div className={'min-width-fix'}>
                    {AzureCommonSecurityLog}
                </div>

                <div className={'min-width-fix'}>
                    {AzureSysLogComputer}
                </div>

                <div className={'min-width-fix'}>
                    {AzureSysLogProcessName}
                </div>

                <div className={'min-width-fix'}>
                    {AzureProtectionStatus}
                </div>

                <div className={'min-width-fix'}>
                    {AzureAzureActivity}
                </div>

                <div className={'min-width-fix'}>
                    {AzureKubeEvents}
                </div>

                <div className={'min-width-fix'}>
                    {AzureKubeMonAgentEvents}
                </div>

                <div className={'min-width-fix'}>
                    {AzureSecurityEvent}
                </div>

                <div className={'min-width-fix'}>
                    {AzureUpdate}
                </div>

            </PrintMargin>

        </div>
    )
}

export default AzureModal

import {
    PrintAction,
    PrintQueue,
    SectionColumn,
    SectionKeys,
    SectionMain
} from '@interfaces/main/print'
import { Column } from '@interfaces/main/root'
import _ from 'lodash'

export const TEXT = {
    PDF_REPORT_LIMIT: {
        TITLE: 'PDF Report Warning',
        MESSAGE: (num: number) => `Printing ${ num } issues individually 
        can create a very large report and will take a long time. Are you sure you 
        want to continue? We recommend using filters and create smaller reports instead.
        If no report is generated, you can go to the reports menu and search for that specific
        report so you can generate the missing sections.`
    },
    /** When creating report sections */
    CREATE_SECTIONS: {
        START: 'PDF Report generation started.',
        ERROR: {
            EMPTY_SECTIONS: 'Section data is empty'
        }
    },
    /** when retrieving the main key of the report */
    MAIN_KEY: {
        EMPTY: 'No key name found in this report.'
    },
    /** when generating sections using puppeteer */
    GENERATING_SECTIONS: {
        PROGRESS: (sum: number) => [
            sum.toFixed(0), '% of report complete'
        ].join(' '),
        FAULTY_SECTIONS: `Report has not completely been generated. You can view 
        failed pages at the Report Menu.`
    },
    /** when selecting a report to download */
    DOWNLOAD: {
        /** define which type is being create (event, card, detailed dashboard) for
         * console log.
         */
        MODE: {
            EVENT_REPORT: 'Generating report from a events reports.',
            DASHBOARD_CARD: 'Generating report from a dashboard card',
            DETAILED_DASHBOARD: 'Generating report from a detailed dashboard'
        },
        /** downloading a report that is complete */
        COMPLETE: {
            TITLE: 'Download Report',
            CONFIRMATION_MESSAGE: 'Do you want to download this report?',
            START: 'Retrieving complete report.'
        },
        /** downloading a report that has crashed sections */
        INCOMPLETE: {
            TITLE: 'Download Incomplete Report',
            CONFIRMATION_MESSAGE: 'Do you want to download this report without faulty pages?',
            START: 'Retrieving incomplete report.'
        },
        ONE: {
            START: 'Downloading section'
        },
        /** common on both cases. */
        LOADING_BUTTON: 'Downloading',
        SUBMIT_BUTTON: 'Download',
        /** needed in functions from developer oversight. */
        MISSING_PARAMETERS: 'Report is missing parameters. Operation aborted.',
        /** from modal checking if report is truthy. */
        MISSING_REPORT: 'Report is required to download a report. Operation aborted.',
        /** from SectionDetails.tsx */
        DOWNLOAD_ALL: 'Download Entire Report'
    },
    REPAIR_SECTION: {
        START: 'Repairing section'
    },
    REPAIR_SECTIONS: {
        PROGRESS: (count: number, done: number) => `Repairing ${ done }/${ count } sections`,
        ALREADY_REPAIRED: 'This report doesn\'t need to be repaired.',
        SUCCESS: 'Sections have been repaired'
    },
    SECTION_DETAILS: {
        FAILED_SECTIONS: 'Show Failed Sections',
        MAIN_PAGE: 'Main Page'
    },
    PRINT_REPORT: {
        TITLE: 'Incident Report',
        EVENT_SUMMARY: 'Event Summary',
        DOCUMENT: {
            MAIN: 'Document',
            TITLE: 'Title',
            SUBTITLE: 'Subtitle',
            CLASSIFICATION: 'Classification',
            AUTHOR: 'Author',
            VERSION: 'Version',
            VERSION_DATE: 'Version Date'
        },
        VERSION_CONTROL: {
            MAIN: 'Version Control',
            AUTHOR: 'Author',
            VERSION: 'Version',
            CHANGE: 'Change'

        },
        AUTHOR: {
            MAIN: 'Author',
            NAME: 'Name',
            FUNCTION: 'Function',
            EMAIL: 'Email',
            ORGANIZATION: 'Organization',
            ADDRESS: 'Address',
            TELEPHONE: 'Telephone',
            SIGNATURE: 'Signature'
        },
        REQUESTER: {
            MAIN: 'Requester'
        }
    }
}

export const ACTIONS: PrintAction[] = [
    /** an avaiable option to download the entire pdf
     * in ReportMenu.tsx AND individually in SectionDetails.tsx
     */
    {
        label: 'Download',
        value: 'DOWNLOAD_PDF',
        mode: 'primary'
    },
    /** will be performed when clicking on the table row instead
     * of a new button. Will view the details of the report. No calls needed.
     */
    // {
    //     label: 'View',
    //     value: 'REPORT_VIEW',
    //     mode: 'primary'
    // },
    /** an avaiable option to repair all sections that have failed
     * in ReportMenu.tsx AND individually in SectionDetails.tsx
     */
    {
        label: 'Repair',
        value: 'REPAIR_SECTIONS',
        mode: 'primary'
    }
]

export const COLUMNS: {
    DASHBOARD_MAIN: SectionColumn[],
    DASHBOARD_DETAILS: Column[],
    /** because the event reports will always be the Q/A portion and the cover page.
     * There's no need to display details.
     */
    EVENT_REPORTS_MAIN: SectionColumn[],
} = {
    DASHBOARD_MAIN: (() => {
        const columnNames: Omit<SectionColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Device I.D.',
                value: 'deviceid'
            }, {
                label: 'Service Type',
                value: 'serviceType'
            }, {
                label: 'In Face',
                value: 'inFace'
            }, {
                label: 'Start Date',
                value: 'timeFrom'
            }, {
                label: 'End Date',
                value: 'timeTo'
            }, {
                label: 'Creation Date',
                value: 'creationDate'
            }
            /** there was a plan to create a table within a table but
             * that ended up to be complicated. We don't want to see
             * the same properties above especially when the report
             * is very large. So to remedy this, click on the parent row
             * to display more details.
             *
             * No need to display the fields above.
             * Prioritize failed records by putting the fields below first.
             * Also create a switch case to make use of the details table
             * based on service type. If there isn't a service type, just display
             * the three fields below.
             *
             * Actions are "download complete sections at once", "view details",
             * "repair/generate section again".
             */
            // {
            //     label: 'Order',
            //     value: 'order'
            // }, {
            //     label: 'Is Finished?',
            //     value: 'isComplete'
            // }, {
            //     label: 'Has Crashed?',
            //     value: 'hasCrashed'
            // }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: SectionColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    DASHBOARD_DETAILS: (() => {
        const columnNames: Omit<Column, 'arrange' | 'include'>[] = [
            {
                label: 'Order',
                value: 'order'
            }, {
                label: 'Is Finished?',
                value: 'isComplete'
            }, {
                label: 'Has Crashed?',
                value: 'hasCrashed'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: Column[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    EVENT_REPORTS_MAIN: (() => {
        const columnNames: Omit<SectionColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Report Name',
                value: 'reportName'
            }, {
                label: 'Creation Date',
                value: 'creationDate'
            }
            /** there was a plan to create a table within a table but
             * that ended up to be complicated. We don't want to see
             * the same properties above especially when the report
             * is very large. So to remedy this, click on the parent row
             * to display more details.
             *
             * No need to display the fields above.
             * Prioritize failed records by putting the fields below first.
             * Also create a switch case to make use of the details table
             * based on service type. If there isn't a service type, just display
             * the three fields below.
             *
             * Actions are "download complete sections at once", "view details",
             * "repair/generate section again".
             */
            // {
            //     label: 'Order',
            //     value: 'order'
            // }, {
            //     label: 'Is Finished?',
            //     value: 'isComplete'
            // }, {
            //     label: 'Has Crashed?',
            //     value: 'hasCrashed'
            // }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: SectionColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })()
}

export const KEYS: {
    DASHBOARD_MAIN: (SectionKeys |
        keyof Required<SectionMain>['card'] |
        keyof Required<SectionMain>['searchParams'])[],
    DASHBOARD_DETAILS: (SectionKeys)[],
    EVENT_REPORTS_MAIN: (SectionKeys | keyof Required<SectionMain>)[]
} = {
    DASHBOARD_MAIN: [
        'creationDate', 'deviceid', 'inFace',
        'isComplete', 'key', 'serviceType'
    ],
    DASHBOARD_DETAILS: [
        'order', 'isComplete', 'hasCrashed'
    ],
    EVENT_REPORTS_MAIN: ['reportName', 'creationDate']
}

/** 2 (cells to show the statuses of the report as a whole) */
export const TABLE_COL_SPAN: number = 1

export const EXTRA_HEADERS = ['Is Complete?']

export const DEFAULT_QUEUE: PrintQueue = {
    id: '',
    reportId: '',
    isLoading: false,
    isSuccess: false,
    isUninitialized: true,
    isFaulty: false,
    details: {},
    count: 0,
    toast: ''
}

/** test run with printing 95 sections using dashboard type bdg-vss bluedog-0x98.
 * Time elapsed in 10 minutes so lowering the report limit by half.
 */
export const PDF_REPORT_LIMIT = 50

/** 30(seconds) * 1000(millseconds) */
export const RECHECK_SECTION_INTERVAL = 30 * 1000


import {
    useAppSelector,
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE as O365_MESSAGE
} from '@constants/dashboard/soc/o365/main'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    O365DetailsForm,
    ServiceTypeFormData,
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    Office365UsersResponse,
    EventType,
    Office365User
} from '@interfaces/dashboard/soc/o365/main'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/o365/main'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/o365/details'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import {
    Container,
    SpinnerContainer,
    ErrorMessage
} from '@styles/components'
import {
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    Tooltip,
    TimeScale,
    Title,
    LineElement,
    LineController,
    PointElement
} from 'chart.js'
import {
    add,
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'
import { createStylesheet } from '@styles/themes'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

import uniqueString from 'unique-string'
import { ColorPresets } from '@interfaces/main/root'

interface ComponentProps {
    modal?: MonitorModal,
    addModal?: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal?: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor?: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    data: Office365UsersResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const Office365UsersChart = ({
    data, isLoading, isSuccess, error,
    modal, addModal, closeModal, changeModalColor
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    const dispatch = useAppDispatch()

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)
    // const [
    //     chartInstance,
    //     setChartInstance
    // ] = useState<Chart<'line', { user: Office365User, x: Date; y: number; }[], Date>>()

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(LineController, LineElement, Legend, PointElement,
            CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    const setDetailsModal = (
        startKey: Date,
        endKey: Date,
        serviceTypeFormData: ServiceTypeFormData
    ) => {
        const timezoneOffset = new Date().getTimezoneOffset()

        // don't fetch if either keys are undefined.
        if (startKey && endKey) {
            const startDate = getUnixTime(
                add(
                    startKey,
                    { minutes: timezoneOffset * -1 }
                )
            )

            const endDate = getUnixTime(
                add(
                    endKey,
                    { minutes: timezoneOffset * -1 }
                )
            )

            dispatch(setDetailsStartDate(startDate))
            dispatch(setDetailsEndDate(endDate))

            if (addModal && modal) {
                dispatch(addModal({
                    id: uniqueString(),
                    open: true,
                    card: modal.card,
                    operation: 'DETAILS',
                    serviceTypeFormData: serviceTypeFormData,
                    isBorderWide: true
                }))
            }
        } else {
            console.error('Date keys are missing. Not adding modal')
        }
    }

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const userData = data?.data[0] || []
        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'line', { userData: Office365User, x: Date; y: number; }[], Date>

        const categories: (keyof Office365User)[] = ['activeAccounts', 'groups', 'roles', 'users']

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const datasets: typeof graph.data.datasets = _.map(categories, (category, index) => {
                return ({
                    data: _.map(userData, obj => {
                        return {
                            userData: obj,
                            x: fromUnixTime(obj.timestamp),
                            y: obj[category]
                        }
                    }),
                    label: category,
                    fill: false,
                    borderColor: CHART_COLORS[index],
                    backgroundColor: CHART_COLORS[index],
                    borderWidth: 1
                })
            })

            graph = new Chart(chartEl.current, {
                type: 'line',
                data: {
                    labels: [
                        ranges.start,
                        ranges.end
                    ],
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: stylesheet.mode.fontColor
                            },
                            position: 'top',
                            align: 'center'
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            beginAtZero: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.0
                        }
                    }
                }
            })

            graph.options.onClick = (event) => {
                if (event.native) {
                    const points = graph.getElementsAtEventForMode(
                        event.native, 'nearest', { intersect: true }, true
                    )

                    if (points.length) {
                        const firstPoint = points[0]
                        /**
                         * get timestamp and other values from userData,
                         */
                        const dataset = graph.data.datasets[firstPoint.datasetIndex]
                        const data = dataset.data
                        const value = data[firstPoint.index]
                        // can be undefined due to index selector
                        const nextValue = data[firstPoint.index + 1]

                        if (value.userData && dataset.label) {
                            const startKey = value?.userData?.timestamp
                                ? value?.userData?.timestamp
                                : currentParams.ranges.start
                            const endKey = nextValue?.userData?.timestamp
                                ? nextValue?.userData?.timestamp
                                : currentParams.ranges.end

                            /** Azure.* is the event type name. */
                            let eventType: EventType = 'Office365.Users'
                            // _.capitalize(dataset.label)
                            switch (dataset.label as keyof Office365User) {
                                case 'activeAccounts':
                                    eventType = 'Azure.ActiveAccounts'
                                    break
                                case 'groups':
                                    eventType = 'Azure.Groups'
                                    break
                                case 'roles':
                                    eventType = 'Azure.Roles'
                                    break
                                case 'users':
                                    eventType = 'Azure.Users'
                                    break
                                default: eventType = 'Office365.Users'
                            }

                            const detailsContent:O365DetailsForm = {
                                event_type: eventType,
                                q: ''
                            }

                            const serviceTypeFormData:ServiceTypeFormData = {
                                o365: {
                                    details: detailsContent
                                }
                            }

                            setDetailsModal(
                                fromUnixTime(startKey),
                                fromUnixTime(endKey),
                                serviceTypeFormData
                            )
                        }
                    }
                }
            }

            chartEl.current.style.height = CHART_HEIGHT.md
            // setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        data
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.ISSUES_DETECTED}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

Office365UsersChart.propTypes = {
    modal: PropTypes.object,
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default Office365UsersChart

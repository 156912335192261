
import {
    useGetIssueDetailsMutation,
    useGetIssuesOvertimeMutation,
    useGetModalMutation
} from '@apis/dashboard/soc/wss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as WSS_MESSAGE,
    SIMILAR_HOSTS_COLUMNS,
    TEXT as WSS_TEXT
} from '@constants/dashboard/soc/wss'
import {
    assignIntervalTick,
    createIntervals,
    generateInterval,
    getUtcRanges
} from '@constants/main/method'

import {
    ACTION_MUTATION_PROMISE,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    IssueDetailsRequest,
    Segment,
    SimilarHost
} from '@interfaces/dashboard/soc/wss'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectWssMain,
    selectWssDesc
} from '@slices/main/print/section'
import {
    resetWssDetails,
    selectCurrentParams,
    selectSimilarHosts,
    setCurrentParams,
    setRefetch,
    setSimilarHosts
} from '@slices/dashboard/soc/wss/wssDesc'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    PageBreakInside,
    PrintMargin,
    SpinnerContainer,
    Table,
    Text,
    PrewrapAll
} from '@styles/components'
import {
    createStylesheet
} from '@styles/themes'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import {
    format,
    fromUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'
import parse from 'html-react-parser'

const WssDesc = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const similarHosts = useAppSelector(selectSimilarHosts)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectWssMain)
    const wssDescForm = useAppSelector(selectWssDesc)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)

    /** now execute api call for vulnerability descriptions AND issuesOvertime. */
    const [getIssueDetails, getIssueDetailsMutation] = useGetIssueDetailsMutation()
    const [getIssuesOvertime, getIssuesOvertimeMutation] = useGetIssuesOvertimeMutation()
    /** similarHosts api call won't work here so just use the regular api call and get the
     *  cleanResults and filter */
    const [getModal, getModalMutation] = useGetModalMutation()

    /** if an error is caught in the process, display a component to show that an error occured
     * so the section will set hasCrashed to true.
      */

    useEffect(() => {
        if (getIssueDetailsMutation.error) {
            console.error(getIssueDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueDetailsMutation.error])

    useEffect(() => {
        if (getIssuesOvertimeMutation.error) {
            console.error(getIssuesOvertimeMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssuesOvertimeMutation.error])

    useEffect(() => {
        if (getModalMutation.error) {
            console.error(getModalMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalMutation.error])

    const unsubscribeGetIssueDetails = () => {
        const unsubscribeMutation = getIssueDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssuesOvertime = () => {
        const unsubscribeMutation = getIssuesOvertime({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetSimilarHosts = () => {
        const unsubscribeMutation = getModal({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetIssueDetails()
        unsubscribeGetIssuesOvertime()
        unsubscribeGetSimilarHosts()
        let getIssueDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssuesOvertimePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getModalPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                        dashboardMain.card &&
                        dashboardMain.searchParams
                    ) {
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const issueDetailsRequestData: IssueDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            service_type: dashboardMain.card.serviceType,
                            in_face: dashboardMain.card.inFace,
                            lastupdate: wssDescForm.lastupdate || 0,
                            id: wssDescForm.id || '',
                            name: wssDescForm.issueName || ''
                        }

                        getIssueDetailsPromise = getIssueDetails(issueDetailsRequestData)
                        getIssuesOvertimePromise = getIssuesOvertime(issueDetailsRequestData)
                        getModalPromise = getModal({
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            service_type: dashboardMain.card.serviceType,
                            in_face: dashboardMain.card.inFace,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        })
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getIssueDetailsPromise && getIssueDetailsPromise.abort()
            getIssuesOvertimePromise && getIssuesOvertimePromise.abort()
            getModalPromise && getModalPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (getModalMutation.data) {
            const cleanResults = !_.isArray(getModalMutation.data?.data)
                ? getModalMutation.data?.data?.cleanResults || []
                : []

            const similarHostGroup: SimilarHost[] = _.map(
                _.filter(cleanResults, (obj) => {
                    return obj.vectorUrl === wssDescForm.vectorUrl
                }), (obj) => {
                    return {
                        issueName: obj.issueName,
                        severity: obj.severity,
                        confidence: obj.confidence,
                        vectorUrl: obj.vectorUrl
                    }
                }
            )

            dispatch(setSimilarHosts(similarHostGroup))
        }
    }, [
        getModalMutation.data
    ])

    /** there are no date pickers and refresh buttons here yet.
     * just a title. proceed with contents of vulnerability description
     */

    const Description = useMemo(() => {
        const data = getIssueDetailsMutation.data

        const DescriptionSegment = parse(data?.data.description || TEXT.NONE)
        const RemediationSegment = parse(data?.data.remediation || TEXT.NONE)
        const ClassificationSegment = parse(
            _.map(
                (data?.data.classifications || []),
                ({ CWELink }) => CWELink
            ).join('<br/>') || TEXT.NONE
        )
        const ReferenceSegment = parse(
            _.map(
                (data?.data.references || []),
                ({ reference }) => reference
            ).join('<br/>') || TEXT.NONE
        )

        // now print evidences where it is supposed to be an array of elements.
        const Urls = _.map(data?.data.evidence, (evidenceObj, index) => {
            const key = [
                'evidence-', index
            ].join('')

            let content = ''

            switch (evidenceObj.type) {
                case 'DiffableEvidence':
                    content =
                    [
                        evidenceObj?.first_evidence?.request_response?.url || '',
                        evidenceObj?.second_evidence?.request_response?.url || ''
                    ].join('\n')
                    break
                case 'TimingBasedEvidence':
                    content = evidenceObj?.evidence?.request_response?.url || ''
                    break
                case 'StaticJavascriptAnalysisEvidence':
                    content = evidenceObj?.composable_evidence?.request_response?.url || ''
                    break
                case 'CollaboratorEvidence':
                case 'InformationListEvidence':
                case 'FirstOrderEvidence':
                    content = evidenceObj?.request_response?.url || ''
                    break
                default:
                    break
            }

            return <Text key={key} size={'xs'} className={'d-block mb-2'}>
                {content}
            </Text>
        })

        const content = (
            <Container bgIndex={2} className={'px-3 py-2'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {/* URL */}
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.URL}
                            </span>
                            {/* also include origin property */}
                            {
                                (data?.data.evidence || []).length
                                    ? Urls
                                    : <Text size={'xs'} className={'d-block mb-2'}>
                                        {TEXT.NONE}
                                    </Text>
                            }

                        </div>
                        {/* PATH */}
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.PATH}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                {data?.data.path || TEXT.NONE}
                            </Text>
                        </div>
                        {/* SEVERITY */}
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.SEVERITY}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                {data?.data.severity || TEXT.NONE}
                            </Text>
                        </div>
                        {/* CONFIDENCE */}
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.CONFIDENCE}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                {data?.data.confidence || TEXT.NONE}
                            </Text>
                        </div>
                        {/* make sure all the elements in here ARE prewrapped. */}
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.DESCRIPTION}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                <PrewrapAll>{DescriptionSegment}</PrewrapAll>
                            </Text>
                        </div>
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.REMEDIATION}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                <PrewrapAll>{RemediationSegment}</PrewrapAll>
                            </Text>
                        </div>
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.CLASSIFICATION}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                <PrewrapAll>{ClassificationSegment}</PrewrapAll>
                            </Text>
                        </div>
                        <div className={'mt-2 mb-3'}>
                            <span className={'d-block mb-2 text-capitalize'}>
                                {WSS_TEXT.SECTIONS.WSS_DESCRIPTION.REFERENCE}
                            </span>
                            <Text size={'xs'} className={'d-block mb-2'}>
                                <PrewrapAll>{ReferenceSegment}</PrewrapAll>
                            </Text>
                        </div>
                    </div>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getIssueDetailsMutation.isLoading
                ? getIssueDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getIssueDetailsMutation.error)
                : LoadingContent
        )
    }, [getIssueDetailsMutation])

    /** issuesovertime bar chart and similar hosts. */

    /** create issuesovertime chart AND similarHosts table. */
    const issuesOvertimeChartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)

        return () => {
            dispatch(resetWssDetails())
        }
    }, [])

    useEffect(() => {
        const issuesOvertime = getIssuesOvertimeMutation.data?.data

        let graph: Chart<'bar', { x: string; y: number; }[], string>

        /** get stylesheet object to assign color to dataset */
        const stylesheet = createStylesheet(style, mode)

        if (issuesOvertime && issuesOvertimeChartEl.current) {
            /** timestamps for issuesovertime is the first and last elements. */
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            // these timestamps need fromUnixTime as they aren't strings.
            // also switch the selections because the data is in ascending
            // order
            if (issuesOvertime.length >= 2) {
                ranges.start = fromUnixTime(issuesOvertime[0].timestamp)
                ranges.end = fromUnixTime(
                    issuesOvertime[issuesOvertime.length - 1].timestamp
                )
            }

            const fixedInterval = generateInterval(ranges.start, ranges.end)

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            const datasets: typeof graph.data.datasets = [{
                /** should be in x and y format */
                data: _.map(intervals, (interval, index) => {
                    // iterate issuesOvertime array and increment x for each
                    // match.
                    let count = 0

                    _.forEach(issuesOvertime, (obj) => {
                        if (isValid(new Date(intervals[index + 1])) && isWithinInterval(
                            fromUnixTime(obj.timestamp),
                            {
                                start: new Date(interval),
                                end: intervals[index + 1]
                                    ? new Date(intervals[index + 1])
                                    : new Date()
                            }
                        )) {
                            count += obj.count
                        } else {
                            // it means we've reached the end. add it anyway.
                            // count += obj.count
                        }
                    })

                    return {
                        x: interval,
                        y: count
                    }
                }),
                backgroundColor: stylesheet.style.buttonTypeColors.primary,
                normalized: true,
                /** parsing property causes an empty chart.
                 * refer to chart's internal format for data property */
                parsing: false,
                maxBarThickness: DEFAULT_BAR_THICKNESS,
                barThickness: 'flex',
                /** apply minimum distance between each bar */
                barPercentage: 0.7
            }]

            graph = new Chart(issuesOvertimeChartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        }
                    }
                }
            })

            issuesOvertimeChartEl.current.style.height = CHART_HEIGHT.md
        }

        // assign chart height,

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [getIssuesOvertimeMutation.data, currentParams.ranges])

    const IssuesOvertimeBarChart = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={issuesOvertimeChartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{WSS_MESSAGE.FETCH.ISSUES_OVERTIME}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getIssuesOvertimeMutation.isLoading
                ? getIssuesOvertimeMutation.isSuccess
                    ? content
                    : JSON.stringify(getIssuesOvertimeMutation.error)
                : LoadingContent
        )
    }, undefined)

    /** similar hosts table. no search and anything like that. */
    const SimilarHostsTable = useMemo(() => {
        const cellBody = (
            dataObject: SimilarHost,
            property: keyof SimilarHost
        ) => {
            let cellContent: SimilarHost[keyof SimilarHost] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover px-0'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(SIMILAR_HOSTS_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'similarHosts-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(similarHosts, (dataObject, rowIndex) => {
                            return (
                                <tr
                                    key={'similarHosts-tr-' + rowIndex}
                                >
                                    {
                                        _.map(SIMILAR_HOSTS_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'similarHosts-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            similarHosts.length
                ? content
                : EmptyCellContent
        )
    }, [similarHosts])

    const generateCode = (segments: Segment[]) => {
        const result = segments.map((obj) => {
            // console.log(obj)

            switch (obj.type) {
                case 'SnipSegment':
                    return <span><code><br/>{'[...]'}<br/></code></span>
                case 'HighlightSegment':
                    return <mark><code >{atob(obj.data || '')}</code></mark>
                default: // dataSegment
                    return <code>{atob(obj.data || '')}</code>
            }
        })

        return result
    }

    /** EVIDENCES COMPONENT */
    const Evidences = useMemo(() => {
        const data = getIssueDetailsMutation.data

        // should be an array of evidences.
        const evidences: {
            type: string, request: JSX.Element[], response: JSX.Element[]
        }[] = []
        // each object should look like this.
        // {type: "", request: "", response: ""}

        _.forEach(data?.data.evidence, (obj) => {
            switch (obj.type) {
                case 'DiffableEvidence':
                    evidences.push({
                        type: obj.type,
                        request: generateCode(obj.first_evidence?.request_response?.request || []),
                        response: generateCode(obj.first_evidence?.request_response?.response || [])
                    })

                    evidences.push({
                        type: obj.type,
                        request: generateCode(obj.second_evidence?.request_response?.request || []),
                        response: generateCode(
                            obj.second_evidence?.request_response?.response || []
                        )
                    })

                    break
                case 'TimingBasedEvidence':

                    evidences.push({
                        type: obj.type,
                        request: generateCode(obj.evidence?.request_response?.request || []),
                        response: generateCode(obj.evidence?.request_response?.response || [])
                    })

                    break
                case 'StaticJavascriptAnalysisEvidence':

                    evidences.push({
                        type: obj.type,
                        request: generateCode(
                            obj.composable_evidence?.request_response?.request || []
                        ),
                        response: generateCode(
                            obj.composable_evidence?.request_response?.response || []
                        )
                    })

                    break
                case 'CollaboratorEvidence':
                case 'InformationListEvidence':
                case 'FirstOrderEvidence':

                    evidences.push({
                        type: obj.type,
                        request: generateCode(obj?.request_response?.request || []),
                        response: generateCode(obj?.request_response?.response || [])
                    })

                    break
                default:
                    break
            }
        })

        return <div>
            {
                /** for print versions, it will be displayed differently. */
                _.map(evidences, (evidence) => {
                    return (
                        <div className={'mt-2 mb-1'}>
                            <PageBreakInside>
                                <span className={'d-block mb-2 text-capitalize'}>
                                    {evidence.type}
                                </span>
                            </PageBreakInside>
                            <PageBreakInside className={'mb-3'}>
                                <Container bgIndex={2} className={'px-3'}>
                                    <pre className={'py-3'}>{evidence.request}</pre>
                                </Container>
                            </PageBreakInside>
                            <PageBreakInside>
                                <Container bgIndex={2} className={'px-3'}>
                                    <pre className={'py-3'}>{evidence.response}</pre>
                                </Container>
                            </PageBreakInside>
                        </div>
                    )
                })
            }
        </div>
    }, [getIssueDetailsMutation])

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getIssueDetailsMutation.data &&
            getIssuesOvertimeMutation.data &&
            getModalMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getIssueDetailsMutation.data,
        getIssuesOvertimeMutation.data,
        getModalMutation.data
    ])

    useEffect(() => {
        if (
            getIssueDetailsMutation.error ||
            getIssuesOvertimeMutation.error ||
            getModalMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getIssueDetailsMutation.error,
        getIssuesOvertimeMutation.error,
        getModalMutation.error
    ])

    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed22222'}
                ></div>
                : ''}
            <PrintMargin>

                {/* preferred to make a page-break-inside for description and similarHosts */}
                <PageBreakInside className={'row mb-3'}>
                    <div className={'col-12'}>
                        {Description}
                    </div>
                </PageBreakInside>

                <PageBreakInside className={'row mb-3'}>
                    <div className={'col-12'}>
                        <Container bgIndex={2} className={'px-3'}>
                            <span className={'d-inline-block mb-2 mt-3'}>
                                {WSS_TEXT.SECTIONS.ISSUES_OVERTIME}
                            </span>
                            {IssuesOvertimeBarChart}
                        </Container>
                    </div>
                </PageBreakInside>

                <div className={'min-width-fix mb-3'}>
                    <span className={'d-inline-block mt-3 mb-2'}>
                        {WSS_TEXT.SECTIONS.SIMILAR_HOSTS}
                    </span>
                    {SimilarHostsTable}
                </div>

                <div className={'min-width-fix mb-3'}>
                    {Evidences}
                </div>

            </PrintMargin>

        </div>
    )
}

export default WssDesc


import {
    useGetIssueDetailsMutation,
    useGetIssuesOvertimeMutation,
    useGetSimilarHostsMutation
} from '@apis/dashboard/soc/vss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as EXT_VSS_MESSAGE,
    SIMILAR_HOSTS_COLUMNS,
    TEXT as EXT_VSS_TEXT
} from '@constants/dashboard/soc/ext-vss'
import {
    assignIntervalTick,
    createIntervals,
    generateInterval,
    getUtcRanges
} from '@constants/main/method'

import {
    ACTION_MUTATION_PROMISE,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    IssueDetailsRequest,
    SimilarHost
} from '@interfaces/dashboard/soc/ext-vss'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectVssMain,
    selectVssVulnDesc
} from '@slices/main/print/section'
import {
    resetVssDetails,
    selectCurrentParams,
    selectSimilarHosts,
    setCurrentParams,
    setRefetch,
    setSimilarHosts
} from '@slices/dashboard/soc/vss/vulnDesc'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    PageBreakInside,
    PrintMargin,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import {
    format,
    fromUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const VulnDesc = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const similarHosts = useAppSelector(selectSimilarHosts)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectVssMain)
    const vulnDescForm = useAppSelector(selectVssVulnDesc)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)

    /** now execute api call for vulnerability descriptions AND issuesOvertime. */
    const [getIssueDetails, getIssueDetailsMutation] = useGetIssueDetailsMutation()
    const [getIssuesOvertime, getIssuesOvertimeMutation] = useGetIssuesOvertimeMutation()
    const [getSimilarHosts, getSimilarHostsMutation] = useGetSimilarHostsMutation()

    useEffect(() => {
        if (getIssueDetailsMutation.error) {
            console.error(getIssueDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueDetailsMutation.error])

    useEffect(() => {
        if (getIssuesOvertimeMutation.error) {
            console.error(getIssuesOvertimeMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssuesOvertimeMutation.error])

    useEffect(() => {
        if (getSimilarHostsMutation.error) {
            console.error(getSimilarHostsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getSimilarHostsMutation.error])

    const unsubscribeGetIssueDetails = () => {
        const unsubscribeMutation = getIssueDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssuesOvertime = () => {
        const unsubscribeMutation = getIssuesOvertime({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetSimilarHosts = () => {
        const unsubscribeMutation = getSimilarHosts({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetIssueDetails()
        unsubscribeGetIssuesOvertime()
        unsubscribeGetSimilarHosts()
        let getIssueDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssuesOvertimePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getSimilarHostsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                        dashboardMain.card &&
                        dashboardMain.searchParams
                    ) {
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const issueDetailsRequestData: IssueDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            service_type: dashboardMain.card.serviceType,
                            in_face: dashboardMain.card.inFace,
                            lastupdate: vulnDescForm.lastupdate || 0,
                            port: vulnDescForm.Port || '',
                            proto: vulnDescForm.Protocol || '',
                            ip: vulnDescForm.IP || '',
                            oid: vulnDescForm.OID || ''
                        }

                        getIssueDetailsPromise = getIssueDetails(issueDetailsRequestData)
                        getIssuesOvertimePromise = getIssuesOvertime(issueDetailsRequestData)
                        getSimilarHostsPromise = getSimilarHosts(issueDetailsRequestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getIssueDetailsPromise && getIssueDetailsPromise.abort()
            getIssuesOvertimePromise && getIssuesOvertimePromise.abort()
            getSimilarHostsPromise && getSimilarHostsPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (getSimilarHostsMutation.data) {
            const result: SimilarHost[] = _.map(
                getSimilarHostsMutation.data.similarHosts, obj => {
                    return {
                        IP: obj.ip,
                        Port: obj.port,
                        Protocol: obj.proto,
                        CVSS: obj.rate,
                        Severity: obj.severity
                    }
                })

            dispatch(setSimilarHosts(result))
        }
    }, [
        getSimilarHostsMutation.data
    ])

    /** there are no date pickers and refresh buttons here yet.
     * just a title. proceed with contents of vulnerability description
     */

    const Description = useMemo(() => {
        const issueDetails = getIssueDetailsMutation.data?.detailData[0]

        const tags = issueDetails?.tags || ''
        const labels = tags.match(/(\|\w+=)/igm)

        const strings = _.map(labels, (tag, index, array) => {
            const start = tags.indexOf(array[index]) + array[index].length
            const end = array[index + 1] ? tags.indexOf(array[index + 1]) : tags.length
            let label = array[index].replace(/[|=]/ig, '').replace(/[_]/ig, ' ')

            switch (label) {
                case 'vuldetect': label = 'Vulnerability Detection'; break
                case 'qod type': label = 'Quality of Delivery Type'; break
                default: break
            }
            return {
                title: label,
                content: tags.substring(start, end)
            }
        })

        const description = (
            <div className={'mt-2 mb-3'}>
                <span className={'d-block mb-2 text-capitalize'}>
                    {EXT_VSS_TEXT.SECTIONS.DESCRIPTION}
                </span>
                <Text size={'xs'} className={'d-block mb-2'}>
                    {vulnDescForm.Description}
                </Text>
            </div>
        )

        const output = _.map(strings, (obj, index) => {
            const key = [
                'vulnDesc-', index
            ].join('')

            return (
                <div className={'my-3'} key={key}>
                    <span className={'d-block mb-2 text-capitalize'}>
                        {obj.title}
                    </span>
                    <Text size={'xs'} className={'d-block mb-2'}>
                        {obj.content || TEXT.NONE}
                    </Text>
                </div>
            )
        })

        const content = (
            <Container bgIndex={2} className={'px-3 py-2'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {description}
                        {output}
                    </div>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getIssueDetailsMutation.isLoading
                ? getIssueDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getIssueDetailsMutation.error)
                : LoadingContent
        )
    }, [getIssueDetailsMutation])

    /** create issuesovertime chart AND similarHosts table. */
    const issuesOvertimeChartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)

        return () => {
            dispatch(resetVssDetails())
        }
    }, [])

    useEffect(() => {
        const issuesOvertime = getIssuesOvertimeMutation.data?.issueOverTime

        let graph: Chart<'bar', { x: string; y: number; }[], string>

        /** get stylesheet object to assign color to dataset */
        const stylesheet = createStylesheet(style, mode)

        if (issuesOvertime && issuesOvertimeChartEl.current) {
            /** timestamps for issuesovertime is the first and last elements. */
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            // these timestamps need fromUnixTime as they aren't strings.
            // also switch the selections because the data is in ascending
            // order
            if (issuesOvertime.length >= 2) {
                ranges.start = fromUnixTime(issuesOvertime[0].timestamp)
                ranges.end = fromUnixTime(
                    issuesOvertime[issuesOvertime.length - 1].timestamp
                )
            }

            const fixedInterval = generateInterval(ranges.start, ranges.end)

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            const datasets: typeof graph.data.datasets = [{
                /** should be in x and y format */
                data: _.map(intervals, (interval, index) => {
                    // iterate issuesOvertime array and increment x for each
                    // match.
                    let count = 0

                    _.forEach(issuesOvertime, (obj) => {
                        if (isValid(new Date(intervals[index + 1])) && isWithinInterval(
                            fromUnixTime(obj.timestamp),
                            {
                                start: new Date(interval),
                                end: intervals[index + 1]
                                    ? new Date(intervals[index + 1])
                                    : new Date()
                            }
                        )) {
                            count += obj.count
                        } else {
                            // it means we've reached the end. add it anyway.
                            // count += obj.count
                        }
                    })

                    return {
                        x: interval,
                        y: count
                    }
                }),
                backgroundColor: stylesheet.style.buttonTypeColors.primary,
                normalized: true,
                /** parsing property causes an empty chart.
                 * refer to chart's internal format for data property */
                parsing: false,
                maxBarThickness: DEFAULT_BAR_THICKNESS,
                barThickness: 'flex',
                /** apply minimum distance between each bar */
                barPercentage: 0.7
            }]

            graph = new Chart(issuesOvertimeChartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        }
                    }
                }
            })

            issuesOvertimeChartEl.current.style.height = CHART_HEIGHT.md
        }

        // assign chart height,

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [getIssuesOvertimeMutation.data, currentParams.ranges])

    const IssuesOvertimeBarChart = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={issuesOvertimeChartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.ISSUES_OVERTIME}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getIssuesOvertimeMutation.isLoading
                ? getIssuesOvertimeMutation.isSuccess
                    ? content
                    : JSON.stringify(getIssuesOvertimeMutation.error)
                : LoadingContent
        )
    }, undefined)

    /** similar hosts table. no search and anything like that. */
    const SimilarHostsTable = useMemo(() => {
        const cellBody = (
            dataObject: SimilarHost,
            property: keyof SimilarHost
        ) => {
            let cellContent: SimilarHost[keyof SimilarHost] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover px-0'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(SIMILAR_HOSTS_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'similarHosts-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(similarHosts, (dataObject, rowIndex) => {
                            return (
                                <tr
                                    key={'similarHosts-tr-' + rowIndex}
                                >
                                    {
                                        _.map(SIMILAR_HOSTS_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'similarHosts-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            similarHosts.length
                ? content
                : EmptyCellContent
        )
    }, [similarHosts])

    const scanResultDetails = useMemo(() => {
        const labelValue = (label: string, value: string | number) => {
            return <div className={'row'}>
                <b className={'col-auto'}>{label}</b>
                <span className={'col'}>{value}</span>
            </div>
        }

        const contents = <div className={'px-2'}>
            <div className={'row'}>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('Protocol', vulnDescForm.Protocol)
                }</Text>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('OID', vulnDescForm.OID)
                }</Text>
            </div>
            <div className={'row'}>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('Name', vulnDescForm.Name)
                }</Text>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('Port', vulnDescForm.Port)
                }</Text>
            </div>
            <div className={'row'}>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('Service', vulnDescForm.Service)
                }</Text>
            </div>
            <div className={'row'}>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('Family', vulnDescForm.Family)
                }</Text>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('Threat', vulnDescForm.Threat)
                }</Text>
            </div>
            <div className={'row'}>
                <Text size={'sm'} className={'col-6'}>{
                    labelValue('CVSS', vulnDescForm.CVSS)
                }</Text>
            </div>
            {/** best to place description in its own row as previous pdf reports
             * shown wasted space. Also realized that's there a description component
             * after this
             * */}
            {/* <div className={'row'}>
                <Text size={'sm'} className={'col'}>{
                    labelValue('Description', vulnDescForm.Description)
                }</Text>
            </div> */}
        </div>

        return (
            <Container bgIndex={2} className={'px-3 py-3'}>
                {/* title row */}
                <div className={'row'}>
                    <Text className={'col-12 mb-2'}>
                        {
                            labelValue('IP', vulnDescForm.IP)
                        }
                    </Text>
                </div>
                {/* contents row */}
                <div className={'row'}>
                    <div className={'col-12'}>
                        {contents}
                    </div>
                </div>
            </Container>
        )
    }, [])

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getIssueDetailsMutation.data &&
            getIssuesOvertimeMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getIssueDetailsMutation.data,
        getIssuesOvertimeMutation.data
    ])

    useEffect(() => {
        if (
            getIssueDetailsMutation.error ||
            getIssuesOvertimeMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getIssueDetailsMutation.error,
        getIssuesOvertimeMutation.error
    ])

    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            <PrintMargin>

                {/* we are going to add the scan result data here. */}
                <div className={'row mb-3'}>
                    <div className={'col-12'}>
                        {scanResultDetails}
                    </div>
                </div>

                {/* preferred to make a page-break-inside for description and similarHosts */}
                <PageBreakInside className={'row mb-3'}>
                    <div className={'col-12'}>
                        {Description}
                    </div>
                </PageBreakInside>

                <PageBreakInside className={'row mb-3'}>
                    <div className={'col-12'}>
                        <Container bgIndex={2} className={'px-3'}>
                            <span className={'d-inline-block mb-2 mt-3'}>
                                {EXT_VSS_TEXT.SECTIONS.ISSUES_OVERTIME}
                            </span>
                            {IssuesOvertimeBarChart}

                        </Container>
                    </div>
                </PageBreakInside>

                <div className={'min-width-fix mb-3'}>
                    <span className={'d-inline-block mt-3 mb-2'}>
                        {EXT_VSS_TEXT.SECTIONS.SIMILAR_HOSTS}
                    </span>
                    {SimilarHostsTable}
                </div>

            </PrintMargin>

        </div>
    )
}

export default VulnDesc

import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    ExtVssDetailsState,
    SimilarHost
} from '@interfaces/dashboard/soc/ext-vss'

import { INITIAL_SEARCH_PARAMS } from '@constants/dashboard/soc/ext-vss'
import _ from 'lodash'

const initialState : ExtVssDetailsState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    ),
    similarHosts: []
}

export const slice = createSlice({
    name: 'extVssVulnDesc',
    initialState: initialState,
    reducers: {
        setStartDate: (state: ExtVssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: ExtVssDetailsState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: ExtVssDetailsState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setCurrentParams: (state: ExtVssDetailsState, action: PayloadAction<
            ExtVssDetailsState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        setSimilarHosts: (state: ExtVssDetailsState, action: PayloadAction<SimilarHost[]>) => {
            state.similarHosts = action.payload
        },
        resetExtVssDetails: (state: ExtVssDetailsState) => {
            state.searchParams = initialState.searchParams
            state.similarHosts = initialState.similarHosts
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setCurrentParams,
    setSimilarHosts,
    resetExtVssDetails
} = slice.actions

export const selectSearchParams = (state: RootState) => state.extVssVulnDesc.searchParams
export const selectCurrentParams = (state: RootState) => state.extVssVulnDesc.currentParams
export const selectSimilarHosts = (state: RootState) => state.extVssVulnDesc.similarHosts

export default slice.reducer

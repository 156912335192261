
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    MESSAGE,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    EventType,
    SharepointUserId
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import { MutationContext } from '@root/MutationProvider'
import {
    selectSharepointUserIdTableData,
    setSharepointUserIdFiltered,
    setSharepointUserIdPage,
    setSharepointUserIdPaginated,
    setSharepointUserIdSearch,
    selectCurrentParams,
    setSharepointUserIdColumnArrange,
    setSharepointUserIdColumnInclude,
    addBool,
    setSharepointUserIdCount,
    selectDashboardData
} from '@slices/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/o365/details'
import {
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    add,
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus
} from 'react-icons/fa'
import { useDebouncedCallback } from 'use-debounce'
import {
    MonitorModal,
    O365DetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import uniqueString from 'unique-string'
import Select from 'react-select'
import Pagination from 'rc-pagination'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

interface ComponentProps {
    // modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    // changeModalColor: ActionCreatorWithPayload<{
    //     modal: MonitorModal,
    //     colorType: ColorPresets
    // }, string>,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const SharepointUserIdsDataTable = ({
    isLoading, isSuccess, error,
    addModal, closeModal
} : ComponentProps) => {
    const rootContext = useContext(MutationContext)
    const dispatch = useAppDispatch()
    const reactSelect = rootContext.reactSelect

    const currentParams = useAppSelector(selectCurrentParams)
    const tableData = useAppSelector(selectSharepointUserIdTableData)
    const dashboardData = useAppSelector(selectDashboardData)

    const setDetailsModal = (
        startKey: Date,
        endKey: Date,
        serviceTypeFormData: ServiceTypeFormData
    ) => {
        /** ERROR: you'll get more data than the current doc_count
         * because it will use the timezone offset again and
         * change these values. You don't want this.
         *
         * Fix is to add the key_as_string values with the timezone
         * offset multiplying it by -1 first.
         */

        const timezoneOffset = new Date().getTimezoneOffset()

        // don't fetch if either keys are undefined.
        if (startKey && endKey) {
            const startDate = getUnixTime(
                add(
                    startKey,
                    { minutes: timezoneOffset * -1 }
                )
            )

            const endDate = getUnixTime(
                add(
                    endKey,
                    { minutes: timezoneOffset * -1 }
                )
            )

            dispatch(setDetailsStartDate(startDate))
            dispatch(setDetailsEndDate(endDate))

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                card: currentParams.card,
                operation: 'DETAILS',
                serviceTypeFormData: serviceTypeFormData,
                isBorderWide: true
            }))
        } else {
            console.error('Date keys are missing. Not adding modal')
        }
    }

    const setSharepointUserIdSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSharepointUserIdSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        const filteredData = smartSearch(
            dashboardData['SharepointUserId.CountedTable']
                ?.aggregations?.[2]?.buckets || [],
            [],
            tableData.search
        ) as SharepointUserId[]
        const orderedData = smartOrder(filteredData,
            tableData.columns) as SharepointUserId[]

        /**
         * set the filtered data to tableData.filtered.
         * any sort or pagination should be done in a separate useEffect
         * */
        dispatch(setSharepointUserIdFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    // const paginateData = useDebouncedCallback(() => {
    const paginateData = () => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as SharepointUserId[]

        dispatch(setSharepointUserIdPaginated(result))
    // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        setData()
    }, [
        /** refreshed data from fetch invocation */
        dashboardData['SharepointUserId.CountedTable'],
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    useEffect(() => {
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setSharepointUserIdPage(PAGE_START))
    }, [
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    /** ip address table with usual features. */
    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setSharepointUserIdColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setSharepointUserIdColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: SharepointUserId,
            property: keyof SharepointUserId
        ) => {
            let cellContent: SharepointUserId[keyof SharepointUserId] = ''
            let rawValue: SharepointUserId[keyof SharepointUserId] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    rawValue = cellContent = dataObject[property]
                    break
            }

            const zoomAction = (not: boolean) => {
                dispatch(addBool({
                    not: not,
                    sort: 'UserId',
                    value: [String(rawValue)]
                }))
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length} className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>
        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length} className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, rowIndex) => {
                const onClick = () => {
                    const ranges: { start:Date, end: Date } = {
                        // these default values are never going to be used anyway
                        // start: new Date(), end: new Date()
                        start: fromUnixTime(currentParams.ranges.start),
                        end: fromUnixTime(currentParams.ranges.end)
                    }

                    const eventType: EventType = 'SharepointUserId.CountedTable'
                    const detailsContent:O365DetailsForm = {
                        event_type: eventType,
                        user: dataObject.key.toString() || '',
                        q: currentParams.q
                    }

                    const serviceTypeFormData:ServiceTypeFormData = {
                        o365: {
                            details: detailsContent
                        }
                    }

                    setDetailsModal(
                        ranges.start,
                        ranges.end,
                        serviceTypeFormData
                    )
                }

                return (
                    <tr
                        key={'o365Event-row-' + rowIndex}
                        onClick={onClick}
                    >
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'o365Event-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    >
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })
        )

        const content = (
            tableData.paginated.length
                ? cellContent
                : EmptyCellContent
        )

        return (
            !dashboardData['SharepointUserId.CountedTable']
                ? isLoading
                    ? LoadingContent
                    : isSuccess
                        ? content
                        : JSON.stringify(error)
                : content
        )
    }, [
        tableData, currentParams.ranges,
        isLoading, isSuccess, error
    ])

    return <div>
        <div className={'align-items-center justify-content-between row'}>
            <div className={'col-12 mb-3 px-4'}>
                <SearchRow className={'align-items-center row'}>
                    <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                        <FaSearch />
                    </label>
                    <input
                        className={'col'}
                        type={'text'}
                        id={TEXT.SEARCH.SEARCH.ID}
                        onChange={(e) => {
                            setSharepointUserIdSearchDebounce(e.target.value)
                        }}
                        placeholder={'Search'}
                    />
                </SearchRow>
            </div>

            {/* this is the dropdown for page count */}
            <div className={'col-12'}>
                <div className={'align-items-center justify-content-between row'}>
                    <div className={'col-12 mb-3'}>
                        <div className={'align-items-center justify-content-center row'}>
                            <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                            <Select
                                className={'col-auto'}
                                options={ROW_COUNT_SELECTION}
                                value={_.find(
                                    ROW_COUNT_SELECTION,
                                    (e) => e.value === tableData.count
                                )}
                                onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                    if (e) {
                                        dispatch(setSharepointUserIdCount(e.value))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles,
                                    ...FIXED_REACT_SELECT_WIDTH.SMALL
                                }}
                                theme={reactSelect.theme}
                            />
                        </div>
                    </div>
                    <div className={
                        'col-12 text-center ps-0'
                    } >
                        <Pagination

                            current={tableData.page}
                            total={tableData.filtered.length}
                            pageSize={tableData.count}
                            onChange={(current) => {
                                dispatch(setSharepointUserIdPage(current))
                            }}
                            locale={EN_US}
                        />
                    </div>

                </div>

            </div>
        </div>
        <div className={'justify-content-center row mt-3'}>
            <div className={'col'}>
                <Table
                    className={'table-striped table-hover'}
                    height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                    bgIndex={2}
                >
                    <table className={'table'}>
                        <thead>
                            <tr>
                                {TableHead}
                            </tr>
                        </thead>
                        <tbody>
                            {TableBody}
                        </tbody>
                    </table>
                </Table>
            </div>
        </div>
    </div>
}

export default SharepointUserIdsDataTable

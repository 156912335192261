
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ISSUE_COUNT_COLUMNS,
    MESSAGE as MDR_MESSAGE
} from '@constants/dashboard/soc/mdr/main'
import {
    hideChartTooltip,
    showChartTooltip
} from '@constants/main/method'
import {
    CHART_HEIGHT,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import {
    MdrDetailsForm,
    MonitorModal,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    IssueCount,
    IssueCountResponse
} from '@interfaces/dashboard/soc/mdr/main'
import { ColorPresets } from '@interfaces/main/root'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/mdr/details'
import {
    selectCurrentParams,
    selectFixedCollapsibles
} from '@slices/dashboard/soc/mdr/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer,
    Table
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    DoughnutController,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'
import uniqueString from 'unique-string'

interface ComponentProps {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    chartEl: React.RefObject<HTMLCanvasElement>
    chartInstance: Chart<'bar', number[], string> | undefined,
    setChartInstance: React.Dispatch<React.SetStateAction<
        Chart<'bar', number[], string> | undefined>
    >
    issueCountData: IssueCountResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}
const IssueCountChart = ({
    chartEl, chartInstance, setChartInstance,
    issueCountData, isLoading, isSuccess, error,
    modal, addModal, closeModal, changeModalColor
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    const dispatch = useAppDispatch()

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(DoughnutController, BarController, ArcElement, BarElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const currentData = issueCountData?.data || []

        let graph: Chart<'bar', number[], string>

        const datasets: typeof graph.data.datasets = [{
            data: _.map(currentData, (obj) => obj.count),
            backgroundColor: stylesheet.style.buttonTypeColors.primary,
            borderWidth: 0,
            maxBarThickness: DEFAULT_BAR_THICKNESS
            // doesn't work on bar charts but will on doughnut/pie charts.
            // parsing: {
            //     key: 'count'
            // }
            // apply a minimum bar length so we can hover on the smallest values
            // when a large data value is part of the chart.

        }]

        if (chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: _.map(currentData, (obj) => obj.issue),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: () => {
                                    return ''
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                display: false,
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            },
                            ticks: {
                                display: false,
                                color: stylesheet.mode.fontColor
                            }
                        }
                    }
                }

            })

            graph.options.onClick = (event) => {
                if (event.native) {
                    const points = graph.getElementsAtEventForMode(
                        event.native, 'nearest', { intersect: true }, true
                    )

                    if (points.length) {
                        const firstPoint = points[0]
                        const label = (graph.data.labels || [])[firstPoint.index] || ''
                        // const value = graph.data
                        //     .datasets[firstPoint.datasetIndex].data[firstPoint.index]
                        const detailsContent:MdrDetailsForm = {
                            event_type: '',
                            lat: '',
                            lon: '',
                            q: '',
                            signature: label,
                            src_ip: ''
                        }

                        const serviceTypeFormData:ServiceTypeFormData = {
                            mdr: {
                                details: detailsContent
                            }
                        }

                        dispatch(setDetailsStartDate(currentParams.ranges.start))
                        dispatch(setDetailsEndDate(currentParams.ranges.end))

                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            card: modal.card,
                            operation: 'DETAILS',
                            serviceTypeFormData: serviceTypeFormData,
                            isBorderWide: true
                        }))
                    }
                }
            }

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.height = CHART_HEIGHT.md
            chartEl.current.style.maxHeight = CHART_HEIGHT.md
            setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [issueCountData])

    const DataTable = useMemo(() => {
        const currentData = issueCountData?.data || []

        const cellBody = (
            dataObject: IssueCount,
            property: keyof IssueCount
        ) => {
            let cellContent: IssueCount[keyof IssueCount] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(ISSUE_COUNT_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'issueCount-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(currentData, (dataObject, rowIndex) => {
                            const onClick = () => {
                                dispatch(setDetailsStartDate(currentParams.ranges.start))
                                dispatch(setDetailsEndDate(currentParams.ranges.end))

                                const detailsContent:MdrDetailsForm = {
                                    event_type: '',
                                    lat: '',
                                    lon: '',
                                    q: '',
                                    signature: dataObject.issue,
                                    src_ip: ''
                                }

                                const serviceTypeFormData:ServiceTypeFormData = {
                                    mdr: {
                                        details: detailsContent
                                    }
                                }

                                dispatch(addModal({
                                    id: uniqueString(),
                                    open: true,
                                    card: modal.card,
                                    operation: 'DETAILS',
                                    serviceTypeFormData: serviceTypeFormData,
                                    isBorderWide: true
                                }))
                            }

                            return (
                                <tr
                                    key={'issueCount-tr-' + rowIndex}
                                    onMouseOver={() => {
                                        showChartTooltip(0, rowIndex, chartInstance)
                                    }}
                                    onMouseOut={() => {
                                        hideChartTooltip(chartInstance)
                                    }}
                                    onClick={onClick}
                                >
                                    {
                                        _.map(ISSUE_COUNT_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'issueCount-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return currentData.length
            ? content
            : EmptyCellContent
    }, [
        issueCountData,
        chartInstance
    ])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={'mb-3'}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
                {/* first instance of including a table version of this chart. */}
                {fixedCollapsibles.issueCount
                    ? <div className={'row'}>
                        <div className={'col pb-3'}>{DataTable}</div>
                    </div>
                    : ''}
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.ISSUE_COUNT}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssueCountChart.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssueCountChart

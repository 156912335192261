
import {
    useGetModalDetailsMutation
} from '@apis/dashboard/soc/o365-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as O365_MESSAGE,
    O365_DETAIL_KEYS
} from '@constants/dashboard/soc/o365/main'
import {
    paginate,
    smartOrder,
    smartSearch,
    getUtcRanges,
    assignIntervalTick,
    createIntervals
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    DEFAULT_INTERVAL,
    COMPARISON_OPERATORS,
    NOT_OPERATORS,
    MULTI_SELECTION_OPERATORS
} from '@constants/main/root'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'

import {
    O365DataIdForm,
    MonitorModal,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    GetModalDetailsRequest,
    O365Detail,
    O365DetailColumn,
    O365DetailKeys
} from '@interfaces/dashboard/soc/o365/main'
import {
    TokenAuth,
    ComparisonOperator
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    addFilter,
    removeFilter,
    removeFilters,
    resetO365Details,
    selectCurrentParams,
    selectSearchParams,
    selectTableData,
    setColumnArrange,
    setColumnInclude,
    setColumns,
    setCount,
    setCurrentParams,
    setEndDate,
    setFiltered,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setStartDate,
    toggleFilterInterface,
    setFilterKeys,
    setFilterValues,
    setSelectedKey,
    setSelectedComparison,
    setSelectedValues
} from '@slices/dashboard/soc/o365/details'
import {
    setEndDate as setDataIdEndDate,
    setStartDate as setDataIdStartDate
} from '@slices/dashboard/soc/o365/dataId'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    Container,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import Tippy from '@tippyjs/react'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import {
    compareAsc,
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useRef
} from 'react'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import {
    FaArrowDown,
    FaArrowUp,
    FaFileCsv,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdRefresh,
    MdManageSearch
} from 'react-icons/md'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'
import uniqueString from 'unique-string'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

import flatten from 'flat'
const O365Details = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const tableData = useAppSelector(selectTableData)
    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    const o365BarChartEl = useRef<HTMLCanvasElement>(null)

    /** will be initiailized multiple times. */
    const [getModalDetails, getModalDetailsMutation] = useGetModalDetailsMutation()

    useEffect(() => {
        if (getModalDetailsMutation.error) {
            console.error(getModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalDetailsMutation.error])

    /** WILL HAVE date ranges like in the parent modal. Starting ranges are the data
     *  of ip address being passed over */
    const unsubscribeGetModalDetails = () => {
        const unsubscribeMutation = getModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalDetails()
        let getModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: GetModalDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            q: modal.serviceTypeFormData?.o365?.details?.q || '',
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString(),
                            event_type: modal.serviceTypeFormData
                                ?.o365?.details?.event_type || '',
                            title: modal.serviceTypeFormData
                                ?.o365?.details?.title || '',
                            operation: modal.serviceTypeFormData
                                ?.o365?.details?.operation || '',
                            detectionmethod: modal.serviceTypeFormData
                                ?.o365?.details?.detectionmethod || '',
                            workload: modal.serviceTypeFormData
                                ?.o365?.details?.workload || '',
                            country: modal.serviceTypeFormData
                                ?.o365?.details?.country || '',
                            city: modal.serviceTypeFormData
                                ?.o365?.details?.city || '',
                            lat: modal.serviceTypeFormData
                                ?.o365?.details?.lat || '',
                            lon: modal.serviceTypeFormData
                                ?.o365?.details?.lon || '',
                            extension: modal.serviceTypeFormData
                                ?.o365?.details?.extension || '',
                            ip: modal.serviceTypeFormData
                                ?.o365?.details?.ip || '',
                            site: modal.serviceTypeFormData
                                ?.o365?.details?.site || '',
                            user: modal.serviceTypeFormData
                                ?.o365?.details?.user || '',
                            file: modal.serviceTypeFormData
                                ?.o365?.details?.file || '',
                            from: modal.serviceTypeFormData
                                ?.o365?.details?.from || '',
                            to: modal.serviceTypeFormData
                                ?.o365?.details?.to || '',
                            attachments: modal.serviceTypeFormData
                                ?.o365?.details?.attachments || '',
                            useragent: modal.serviceTypeFormData
                                ?.o365?.details?.useragent || '',
                            subject: modal.serviceTypeFormData
                                ?.o365?.details?.subject || '',
                            rule: modal.serviceTypeFormData
                                ?.o365?.details?.rule || ''
                        }

                        getModalDetailsPromise = getModalDetails(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalDetailsPromise && getModalDetailsPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we had icons at the upper right of the screen with Tippy containers.
     * there should date range selection, refresh button, export csv for ipaddress
     * AND a print report button.
     */
    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getModalDetailsMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getModalDetailsMutation.isSuccess
    ])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalDetailsMutation.isLoading
        return (
            <span
                onClick={() => {
                    // update o365 bar chart
                    dispatch(setRefetch(true))
                }}
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableRefresh ? 'disabled' : 'pointer'
                ].join(' ')}
            >
                <MdRefresh/>
            </span>
        )
    }, [getModalDetailsMutation])

    const CsvExportButton = useMemo(() => {
        const o365DetailData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            modal.card.service_type,
            '_', modal.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = o365DetailData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                onClick={() => {
                    if (disableExport) return false
                }}
                filename={filename}
                data={o365DetailData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        modal.card,
        currentParams.ranges
    ])

    const ActionButtons = useMemo(() => {
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton
    ])

    /** we just need the dataTable AND a bar chart */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)

        return () => {
            dispatch(resetO365Details())
        }
    }, [])

    /** you don't want to add searchParams as part of the dependencies as it won't reflect
     * the data visualizations if said value updates.
     */
    useEffect(() => {
        const modalDetails = getModalDetailsMutation.data

        let aggreggation : _.Dictionary<O365Detail[]> = {}
        let graph: Chart<'bar', { o365Details: O365Detail, x: string; y: number; }[], string>

        /** get stylesheet object to assign color to dataset */
        const stylesheet = createStylesheet(style, mode)

        if (modalDetails && o365BarChartEl.current) {
            const fixedInterval = modalDetails.fixedInterval || DEFAULT_INTERVAL
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            /** bug found: sometimes, a timestamp cannot be inside
             * the interval from the searchParams ranges.
             *
             * for the o365, you need to check if they have
             * a timestamp property.
             */

            if (modalDetails.data.length >= 2) {
                const indexOne = modalDetails.data[0]
                const indexTwo = modalDetails.data[modalDetails.data.length - 1]

                /**
                 * order of data is not guaranteed (descending/ascending) so
                 * timestamps will be compared.
                 *
                 * much better than sorting a possibly large dataset.
                 */

                /** indexOne.Timestamp should be before indexTwo.Timestamp */
                if (indexOne.Timestamp && indexTwo.Timestamp) {
                    if (compareAsc(
                        new Date(indexOne.Timestamp),
                        new Date(indexTwo.Timestamp)
                    ) === -1) {
                        ranges.start = new Date(indexOne.Timestamp)
                        ranges.end = new Date(indexTwo.Timestamp)
                    }
                } else {
                    ranges.start = new Date(indexTwo.Timestamp)
                    ranges.end = new Date(indexOne.Timestamp)
                }
            }

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            /** if there really isn't a timestamp, just return null as it shouldn't
             * be part of the bar chart.
             */
            aggreggation = _.groupBy(modalDetails.data, (obj) => {
                if (obj.Timestamp) {
                    /** we can reduce the iterations by checking if the timestamp
                 * is greater than the first index of intervals array.
                 */
                    for (let index = 0; index < intervals.length; index++) {
                        const value = intervals[index]

                        const intervalOne = new Date(value)
                        const intervalTwo = new Date(intervals[index + 1])

                        // console.log(
                        //     'interval one: ', intervalOne,
                        //     'interval two: ', intervalTwo,
                        //     'timestamp: ', new Date(obj.Timestamp)
                        // )

                        if (
                            isValid(intervalTwo) &&
                        isWithinInterval(
                            new Date(obj.Timestamp),
                            {
                                start: intervalOne,
                                end: intervalTwo
                            }
                        )
                        ) {
                            // console.log('Timestamp is within interval')
                            return format(intervalOne, DATE_FORMAT_TIME)
                        }
                    }

                    // display a console error where the timestamp is not included in the interaval
                    console.warn('Timestamp is not within interval')
                    return format(new Date(obj.Timestamp), DATE_FORMAT_TIME)
                } else {
                    return null
                }
            })

            // console.log(aggreggation)

            const datasets: typeof graph.data.datasets = [{
                /** should be in x and y format */
                // data: _.map(aggreggation, (value, key) => {
                //     return {
                //         o365Details: value[0],
                //         x: new Date(key),
                //         y: value.length
                //     }
                // }),
                data: _.map(intervals, (interval) => {
                    const found = _.find(aggreggation, (value, key) => {
                        return isValid(new Date(key)) && _.isEqual(
                            format(new Date(key), DATE_FORMAT_TIME),
                            interval
                        )
                    }) || []

                    return {
                        o365Details: found?.[0] || {},
                        x: interval,
                        y: found.length
                    }
                }),
                backgroundColor: stylesheet.style.buttonTypeColors.primary,
                normalized: true,
                /** parsing property causes an empty chart.
                 * refer to chart's internal format for data property */
                parsing: false,
                maxBarThickness: DEFAULT_BAR_THICKNESS,
                barThickness: 'flex',
                /** apply minimum distance between each bar */
                barPercentage: 0.7
            }]

            // console.log(ranges)
            // console.log(modalDetails.fixedInterval)
            // console.log(intervals)
            // console.log(datasets)

            graph = new Chart(o365BarChartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        }
                    }
                }
            })

            o365BarChartEl.current.style.height = CHART_HEIGHT.md
        }

        // assign chart height,

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [getModalDetailsMutation.data, currentParams.ranges])

    const O365BarChart = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={o365BarChartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{O365_MESSAGE.FETCH.MODAL_DETAILS}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getModalDetailsMutation.isLoading
                ? getModalDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalDetailsMutation.error)
                : LoadingContent
        )
    }, undefined)

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        if (getModalDetailsMutation.data) {
            const filteredData = smartSearch(
                getModalDetailsMutation.data.data,
                tableData.filters,
                tableData.search
            ) || [] as O365Detail[]
            const orderedData = smartOrder(filteredData, tableData.columns) as O365Detail[]

            dispatch(setFiltered(orderedData))
        }
    }, DEBOUNCE_SEARCH_TIME)

    const paginateData = () => {
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as O365Detail[]

        dispatch(setPaginated(result))
    }

    useEffect(() => {
        setData()
    }, [
        getModalDetailsMutation.data,
        tableData.filters,
        tableData.search,
        tableData.columns
    ])

    useEffect(() => {
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    useEffect(() => {
        const modalDetails = getModalDetailsMutation.data

        if (modalDetails && modalDetails.data[0]) {
            /** do a property check to select which columns to use. */
            const obj = modalDetails.data[0]

            // exclude id property.
            const columns: O365DetailColumn[] = _.map(
                _.pull(_.keys(obj), 'id'),
                (str) => {
                    return {
                        label: str,
                        value: str,
                        arrange: 'asc',
                        include: false
                    }
                })

            dispatch(setColumns(columns))
        }
    }, [getModalDetailsMutation.data])

    /** ip address table with usual features. */
    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: O365Detail,
            property: keyof O365Detail
        ) => {
            let cellContent: O365Detail[keyof O365Detail] = ''
            let rawValue: O365Detail[keyof O365Detail] = ''

            /** all properties are optional so provide default values */

            /** switch case if you want to display something differently */
            switch (property) {
                case 'Timestamp':
                    if (dataObject.Timestamp) {
                        cellContent = format(new Date(dataObject.Timestamp), DATE_FORMAT_TIME)
                    }
                    break
                case 'Location':
                    cellContent = [
                        dataObject.Location?.Country || '',
                        dataObject.Location?.City || ''
                    ].join(' ')
                    break
                default:
                    /** convert objects to strings. */
                    rawValue = cellContent = String(dataObject[property])
                    break
            }

            const zoomAction = (not: boolean) => {
                if (property === 'Timestamp') {
                    dispatch(addFilter({
                        not: not,
                        sort: property,
                        value: [dataObject.Timestamp as string]
                    }))
                } else if (property === 'Location') {
                    dispatch(addFilter({
                        not: not,
                        sort: 'geoip.timezone',
                        value: [
                            [
                                dataObject.Location?.Country || '',
                                dataObject.Location?.City || ''
                            ].join(' ')
                        ]
                    }))
                } else {
                    dispatch(addFilter({
                        not: not,
                        sort: String(property),
                        value: [String(rawValue)]
                    }))
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>
        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, rowIndex) => {
                const onClick = () => {
                    dispatch(setDataIdStartDate(currentParams.ranges.start))
                    dispatch(setDataIdEndDate(currentParams.ranges.end))

                    if (dataObject) {
                        const dataIdContent:O365DataIdForm = {
                            id: dataObject.id || ''
                        }

                        const serviceTypeFormData:ServiceTypeFormData = {
                            o365: {
                                dataId: dataIdContent
                            }
                        }

                        /**
                         * we passed in the addModal slice as a prop.
                         */

                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            card: modal.card,
                            operation: 'DATA_ID',
                            serviceTypeFormData: serviceTypeFormData,
                            isBorderWide: true
                        }))
                    }
                }

                return (
                    <tr
                        key={'o365detail-row-' + rowIndex}
                        onClick={onClick}
                    >
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                return (
                                    <td key={[
                                        'o365detail-cell-' + rowIndex +
                                        '-' + cellIndex
                                    ].join('')}
                                    >
                                        {cellBody(dataObject, column.value)}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })
        )

        return (
            getModalDetailsMutation.isLoading
                ? LoadingContent
                : getModalDetailsMutation.isSuccess
                    ? (
                        tableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(getModalDetailsMutation.error)
        )
    }, [tableData, getModalDetailsMutation, currentParams])

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const data = getModalDetailsMutation.data?.data || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = data[0]
            ? _.filter(_.keys(
                flatten(data[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : O365_DETAIL_KEYS

        dispatch(
            setFilterKeys(result)
        )
    }, [
        getModalDetailsMutation.data
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as O365DetailKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-end mb-3'}>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {O365BarChart}
                </div>
            </div>
            <div className={'min-width-fix'}>
                <div className={'align-items-center justify-content-between row'}>
                    <div className={'col-12 col-md px-4'}>
                        <SearchRow className={'align-items-center row'}>
                            <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                                <FaSearch />
                            </label>
                            <input
                                className={'col'}
                                type={'text'}
                                id={TEXT.SEARCH.SEARCH.ID}
                                onChange={(e) => {
                                    setSearchDebounce(e.target.value)
                                }}
                                placeholder={'Search'}
                            />
                            {/* when this is clicked, a popup window will show up
                            it was previously a tippy but now it's an interface below
                            to utilize space. */}
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                                <Text size={'xl'}
                                    className={'d-inline-block col-auto pe-0'}
                                    onClick={() => {
                                        dispatch(
                                            toggleFilterInterface(!tableData.filterInterface.show)
                                        )
                                    }}
                                >
                                    <MdManageSearch />
                                </Text>
                            </Tippy>
                        </SearchRow>
                    </div>

                    {/* this is the dropdown for page count */}
                    <div className={'col-12 col-md-auto'}>
                        <div className={'align-items-center justify-content-between row'}>
                            <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                                <div className={'align-items-center justify-content-center row'}>
                                    <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                    <Select
                                        className={'col-auto'}
                                        options={ROW_COUNT_SELECTION}
                                        value={_.find(
                                            ROW_COUNT_SELECTION,
                                            (e) => e.value === tableData.count
                                        )}
                                        onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                            if (e) {
                                                dispatch(setCount(e.value))
                                            }
                                        }}
                                        styles={{
                                            ...reactSelect.styles,
                                            ...FIXED_REACT_SELECT_WIDTH.SMALL
                                        }}
                                        theme={reactSelect.theme}
                                    />
                                </div>
                            </div>
                            <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                                <Pagination

                                    current={tableData.page}
                                    total={tableData.filtered.length}
                                    pageSize={tableData.count}
                                    onChange={(current) => {
                                        dispatch(setPage(current))
                                    }}
                                    locale={EN_US}
                                />
                            </div>

                        </div>

                    </div>
                </div>
                {/* row to show when kubernetes search toggles */}
                {
                    tableData.filterInterface.show
                        ? (
                            <div className={'mt-2'}>
                                {FilterInterface}
                            </div>
                        )
                        : ''
                }
                <div className={'row mt-2'}>
                    {/* display columns aka a filter shere */}
                    <div className={'col ps-4'}>
                        <div className={'row'}>
                            {
                                _.map(tableData.filters, (filter, index) => {
                                    const comparisonString = filter.value.length > 1
                                        ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                        : ':'
                                    return (
                                        <FilterCell key={'filter-' + index} className={
                                            ['col-auto me-2 px-2 mt-1',
                                                filter.not ? 'not' : ''].join(' ')
                                        }>
                                            <Text size={'xs'}>
                                                {[
                                                    [
                                                        filter.not ? '!' : '',
                                                        filter.sort
                                                    ].join(''),
                                                    comparisonString,
                                                    filter.value.join(', ')
                                                ].join(' ')}
                                            </Text>
                                            <Text size={'xs'} className={'ps-2'} onClick={() => {
                                                dispatch(removeFilter(filter))
                                            }}>
                                                <FaTimes />
                                            </Text>
                                        </FilterCell>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <span
                        className={[
                            'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                            tableData.filters.length ? 'd-block' : 'd-none'
                        ].join(' ')}
                        onClick={() => {
                            dispatch(removeFilters())
                        }}
                    >
                        {/* clear all filters button. baseline, not center */}
                        <FaTimes />
                    </span>
                </div>
                <div className={'justify-content-center row mt-3'}>
                    <div className={'col'}>
                        <Table
                            className={'table-striped table-hover'}
                            height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                            bgIndex={2}
                        >
                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        {TableHead}
                                    </tr>
                                </thead>
                                <tbody>
                                    {TableBody}
                                </tbody>
                            </table>
                        </Table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default O365Details


import {
    EventAction,
    EventColumn,
    EventType,
    BoxRecord,
    EventObjKeys,
    DoCommentValues,
    DoCommentKeys,
    ReportColumn,
    ReportKeys,
    AnswerReportValues,
    AnswerReportKeys,
    AnswerHistoryColumn,
    ReviewNotesHistoryColumn,
    FileHistoryColumn
} from '@interfaces/watchdog/soc-data/event'
import _ from 'lodash'
import * as Yup from 'yup'
import {
    MESSAGE as ROOT_MESSAGE
} from '@constants/main/root'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Event call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when eventID is undefined */
    UNDEFINED_ID: 'Empty eventID detected.',
    REPORT_STEP_INVALID: 'There are step/s that didn\'t pass validation.',
    NO_REPORT_STEP: 'No report step with specified report section found.'

}

export const ALL_DEVICES_OPTION: BoxRecord = {
    deviceid: 'All',
    available: false,
    online: false
}

/** 2 (1 is from a checkbox and the other is from a
 * toggle button for seeing groups of events) */
export const TABLE_COL_SPAN: number = 2

export const TEXT = {
    DETAILS: {
        TITLE: 'Event Details',
        BUTTONS: {
            ESCALATE: 'Escalate',
            ARCHIVE: 'Archive',
            REQUEST_REPORT: 'Request Report',
            CREATE_REPORT: 'Create Report',
            CREATE_FILTER: 'Create Filter'
        },
        SEARCH: {
            UPDATE_MDR_CHART_BUTTON: 'Update'
        },
        SECTIONS: {
            GENERAL_INFO: 'General Info',
            MDR_BAR_ACTIVITY: 'MDR Bar Activity',
            HISTORY: 'History',
            GEO_IP: 'Geo I.P.',
            JSON_DATA: 'JSON Data',
            FLOW_DATA: 'Flow Data'
        }
    },
    TABLE: {
        FILTERED_COUNT: 'Filtered Result Count:',
        TOTAL_COUNT: 'Total Result Count:',
        ARCHIVE_SELECTED: 'Archive Selected',
        UNCHECK_ALL: 'Uncheck All'
    },
    SEARCH: {
        BOXNAME: {
            LABEL: 'Boxname',
            ID: 'SEARCH__BOXNAME'
        },
        DATE_RANGE: {
            LABEL: 'Date Range',
            ID: 'SEARCH__DATE_RANGE'
        },
        EVENT_TYPE: {
            LABEL: 'Event Type',
            ID: 'SEARCH__EVENT_TYPE'
        },
        EVENT_ID: {
            LABEL: 'Event ID',
            ID: 'SEARCH__EVENT_ID'
        },
        FILTERS: {
            LABEL: 'Filters',
            ID: 'SEARCH__FILTERS'
        },
        SEARCH: {
            LABEL: 'Search',
            ID: 'SEARCH__SEARCH'
        }
    },
    GROUP_ARCHIVE: {
        /** confirmation label for archiving an event */
        TITLE: 'Group Archive Event Confirmation',
        MESSAGE: 'Are you sure you want to group archive this event?',
        FORM: {
            PROGRESS_LABEL: 'Archiving',
            LOADING_BUTTON: 'Archiving',
            SUBMIT_BUTTON: 'Group Archive Event'
        }
    },
    ARCHIVE: {
        /** confirmation label for archiving an event */
        TITLE: 'Archive Event Confirmation',
        MESSAGE: 'Are you sure you want to archive this event?',
        FORM: {
            LOADING_BUTTON: 'Archiving',
            SUBMIT_BUTTON: 'Archive Event'
        }
    },
    ESCALATE: {
        /** confirmation label for escalating an event */
        TITLE: 'Escalate Event Confirmation',
        MESSAGE: 'Are you sure you want to escalate this event?',
        FORM: {
            LOADING_BUTTON: 'Escalating',
            SUBMIT_BUTTON: 'Escalate Event'
        }
    },
    COMMENT: {
        /** confirmation label for commenting an event */
        TITLE: 'Add Comment Confirmation',
        MESSAGE: 'Are you sure you want to add a comment in this event?',
        FORM: {
            COMMENT: {
                LABEL: 'Comment',
                ID: 'COMMENT__COMMENT'
            },
            LOADING_BUTTON: 'Commenting',
            SUBMIT_BUTTON: 'Add Comment'
        }
    },
    REPORT_INTERFACE: {
        TITLE: 'Steps',
        HISTORY_HINT: '* Select a record to set a value to.',
        FILE_HINT: '* To tag your file to your answer, use [id=*fileId*]',
        LABELS: {
            REVIEW_NOTE: 'Review Answer'
        },
        BUTTONS: {
            ANSWER_HISTORY_SET: 'Set Answer',
            REVIEW_NOTE_HISTORY_SET: 'Set Review Note',
            FILE_HISTORY_BUTTON: 'File History',
            FILE_HISTORY_SET: 'Set File ID',
            ADD_BULLET: 'Add Bullet Line',
            REMOVE_BULLET: 'Remove Bullet Line',
            PREVIOUS_REPORT_STEP: 'Prev',
            NEXT_REPORT_STEP: 'Next',
            SUBMIT_REPORT_FOR_REVIEW: 'Submit Report for Review',
            SUBMIT_REVISIONS: 'Submit Revisions',
            FINALIZE_REPORT: 'Finalize Report',
            SUBMIT_REVIEWED_REPORT_FOR_REVIEW: 'Submit Reviewed Report for Review',
            GENERATE_PDF: 'Generate PDF'
        }

    },
    REQUEST_REPORT: {
        /**  confirmation label for requesting a report for an event */
        TITLE: 'Request Report Confirmation',
        MESSAGE: 'Are you sure you want to request a report for this event?',
        FORM: {
            LOADING_BUTTON: 'Requesting Report',
            SUBMIT_BUTTON: 'Request Report'
        }
    },
    CREATE_REPORT: {
        /**  confirmation label for creating a report for an event */
        TITLE: 'Create Report Confirmation',
        MESSAGE: 'Are you sure you want to create a report for this event?',
        FORM: {
            LOADING_BUTTON: 'Creating Report',
            SUBMIT_BUTTON: 'Create Report'
        }
    },
    REQUEST_REPORT_REVIEW: {
        /**  confirmation label for creating a report for an event */
        TITLE: 'Request Report for Review Confirmation',
        MESSAGE: 'Are you sure you want to request this report data for review?',
        FORM: {
            LOADING_BUTTON: 'Requesting Report for Review',
            SUBMIT_BUTTON: 'Request Report for Review'
        }
    },
    REVIEW_REVIEWED: {
        /**  confirmation label for creating a report for an event */
        TITLE: 'Send Report for Review Confirmation',
        MESSAGE: 'Are you sure you want to send this report for another review?',
        FORM: {
            LOADING_BUTTON: 'Sending Reviewed Report for Review',
            SUBMIT_BUTTON: 'Send Reviewed Report for Review'
        }
    },
    SUBMIT_REVIEWED_REPORT: {
        /**  confirmation label for creating a report for an event */
        TITLE: 'Send Reviewed Report Confirmation',
        MESSAGE: 'Are you sure you want to send this reviewed report back to author?',
        FORM: {
            LOADING_BUTTON: 'Sending Revisions to Author',
            SUBMIT_BUTTON: 'Send Revisions to Author'
        }
    },
    FINALIZE_REPORT: {
        /**  confirmation label for creating a report for an event */
        TITLE: 'Submit Finalized Report Confirmation',
        MESSAGE: 'Are you sure you want to finalize this report?',
        FORM: {
            LOADING_BUTTON: 'Finalizing Report',
            SUBMIT_BUTTON: 'Finalize Report'
        }
    }
}

/** can filter out if chosen to. */
export const ACTIONS: EventAction[] = [

    /** a quick way to archive a record */
    {
        label: 'Group Archive',
        value: 'GROUP_ARCHIVE',
        mode: 'danger'
    },
    /** singular archive for no groups */
    {
        label: 'Archive',
        value: 'ARCHIVE',
        mode: 'danger'
    }
]

/** the value of property can only be the property names
 * of the Events interface, so it should be throwing
 * errors if the string wasn't any of those keys.
 */
export const COLUMNS: {
    MAIN: EventColumn[],
    REPORT: ReportColumn[],
    ANSWER_HISTORY: AnswerHistoryColumn[],
    REVIEW_NOTES_HISTORY: ReviewNotesHistoryColumn[],
    FILE_HISTORY: FileHistoryColumn[],
} = {
    MAIN: (() => {
        const columnNames: Omit<EventColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Timestamp',
                value: 'timestamp'
            },
            {
                label: 'Event Name',
                value: 'eventname'
            },
            {
                label: 'Source I.P.',
                value: 'src_ip'
            },
            {
                label: 'Destination I.P.',
                value: 'dest_ip'
            },
            {
                label: 'Domain Name',
                value: 'domain'
            },
            {
                label: 'Box Name',
                value: 'hostname'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: EventColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    REPORT: (() => {
        const columnNames: Omit<ReportColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Timestamp',
                value: 'eventTimestamp'
            },
            {
                label: 'Report Name',
                value: 'reportName'
            },
            {
                label: 'Report Version',
                value: 'reportVersion'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: ReportColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    ANSWER_HISTORY: [
        {
            label: 'Timestamp',
            value: 'answerTimestamp'
        },
        {
            label: 'Answer',
            value: 'answerGiven'
        }
    ],
    REVIEW_NOTES_HISTORY: [
        {
            label: 'Timestamp',
            value: 'answerTimestamp'
        },
        {
            label: 'Review Note',
            value: 'reviewNotes'
        }
    ],
    FILE_HISTORY: [
        {
            label: 'Timestamp',
            value: 'answerTimestamp'
        },
        {
            label: 'File ID',
            value: 'fileIds'
        }
    ]
}

export const EVENT_TYPES : EventType[] = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Current',
        value: 'current'
    },
    {
        label: 'Escalated',
        value: 'escalated'
    },
    {
        label: 'Report-Requested',
        value: 'report-requested'
    },
    {
        label: 'Comment',
        value: 'comment'
    },
    {
        label: 'Archived',
        value: 'archived'
    }
]

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */

export const KEYS: {
    EVENT: EventObjKeys[],
    REPORT: ReportKeys[]
} = {
    EVENT: [
        'dest_ip', 'dest_port', 'domain', 'eventname', 'hostname',
        'protocol', 'severity', 'src_ip', 'src_port', 'timestamp'
    ],
    REPORT: [
        'deviceId', 'eventState', 'eventStateString', 'eventTimestamp',
        'reportName', 'reportVersion'
    ]
}

export const REPORT_ANSWER = {
    fileIds: [],
    reportSection: 0,
    reportStepInput: {
        answerGiven: [],
        collapsible: [],
        errors: [],
        id: [],
        isValidated: [],
        reviewNote: []
    }
}

export const INITIAL_VALUES: {
    COMMENT: DoCommentValues,
    REPORT_ANSWER: AnswerReportValues
} = {
    COMMENT: {
        comment: ''
    },
    REPORT_ANSWER: {
        reportSteps: [],
        submissionMode: 0
    }
}

export const ANSWER_GIVEN_SCHEMA = Yup.string().test(
    'Stricter string test',
    ROOT_MESSAGE.ERROR.FORM.EMPTY,
    async (value) => {
        if (
            await Yup.string()
                .required()
                .isValid(
                    _.trim(value)
                )
        ) {
            return true
        }

        return false
    }
)

export const REPORT_ANSWER_SCHEMA = Yup.object({
    /** adding files is optional */
    fileIds: Yup.array().notRequired(),
    /** all steps of the report should be answered */
    reportStepInput: Yup.object({
        answerGiven: Yup.array()
            .required(ROOT_MESSAGE.ERROR.FORM.EMPTY).of(ANSWER_GIVEN_SCHEMA),
        collapsible: Yup.array().notRequired(),
        errors: Yup.array().notRequired(),
        id: Yup.array().notRequired(),
        isValidated: Yup.array().notRequired(),
        reviewNote: Yup.array().notRequired()
    }),
    /** should be required. */
    reportSection: Yup.number().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
})

export const VALIDATION_SCHEMA: {
    COMMENT: OptionalObjectSchema<Record<DoCommentKeys, Yup.AnySchema>>,
    REPORT_ANSWER:OptionalObjectSchema<Record<AnswerReportKeys, Yup.AnySchema>>
    ,
} = {
    COMMENT: Yup.object({
        comment: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
    }),
    REPORT_ANSWER: Yup.object({
        reportSteps: Yup.array().required().of(
            REPORT_ANSWER_SCHEMA
        ),
        submissionMode: Yup.number().notRequired()
    })

}

export const EVENT_STATE_GROUPINGS = {
    GROUP_1: [0, 6],
    GROUP_2: [5, 6, 7],
    GROUP_3: [5, 7]
}

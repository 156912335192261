import {
    useDoToggleMFAMutation,
    useDoValidateMFAInitialMutation,
    useGetProfileMutation
} from '@apis/main/profile-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as PROFILE_MESSAGE,
    TEXT as PROFILE_TEXT,
    DO_INITIAL_MFA_FORMIK_INITIAL_VALUES,
    DO_INITIAL_MFA_VALIDATION_SCHEMA
} from '@constants/main/profile'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import MenuLinks from '@features/main/MenuLinks'
import { MutationContext } from '@root/MutationProvider'
import {
    selectMfaMessage,
    selectTabs,
    selectUserData,
    setImageData,
    setMfa,
    setMfaInitial,
    setMfaMessage,
    setUserData
} from '@slices/main/profile'
import { selectToken } from '@slices/main/token'
import {
    Button,
    FormStyledComponents as Form,
    ProfileStyledComponents as Profile,
    SpinnerContainer,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import _ from 'lodash'
import Switch from 'rc-switch'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const MFA = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    /** expected data is: changePassword formik. that's it. */
    const dispatch = useAppDispatch()

    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const userData = useAppSelector(selectUserData)
    const mfaMessage = useAppSelector(selectMfaMessage)
    /**
     * best practice to abort data fetching mutations
     * and unwrap form submission calls. sidebar api is only an example
     * to demonstrate how to code it. in the case for doToggleMFA
     * there are two different cases where after unwrapping, they set
     * different properties in the settings store.
     * */
    const [doToggleMFA] = useDoToggleMFAMutation()
    const [getProfile, getProfileMutation] = useGetProfileMutation()
    const [doValidateMFAInitial, doValidateMFAInitialMutation] = useDoValidateMFAInitialMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: DO_INITIAL_MFA_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DO_INITIAL_MFA_VALIDATION_SCHEMA,
        onSubmit: async (values) => {
            const newToken = await revalidateToken()

            doValidateMFAInitial({
                authToken: newToken,
                token: Number(values.pinCode),
                app: 'watchdog'
            })
        }
    })

    const unsubscribeGetProfile = () => {
        const unsubscribeMutation = getProfile({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetProfile()
        /**
         * onmount, execute getProfile and update userData.
         */
        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()

                if (isMounted) {
                    promise = getProfile({
                        authToken: newToken,
                        app: 'watchdog'
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    useEffect(() => {
        if (doValidateMFAInitialMutation.data) {
            const data = doValidateMFAInitialMutation.data
            if (data.success) {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // once successful, fetch getProfile once again.
                fetchData()
            // then empty form.
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [doValidateMFAInitialMutation.data])

    useEffect(() => {
        if (doValidateMFAInitialMutation.error) {
            console.error(doValidateMFAInitialMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doValidateMFAInitialMutation.error])

    /**
     * memory leak detected when attempting to do async tasks inside a useEffect
     * use a variable unmounted to do a check.
     * */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        const data = getProfileMutation.data
        if (data) {
            if (data.status === 'OK') {
                dispatch(setMfa(data.userData.mfa))
                dispatch(setMfaInitial(data.userData.mfa_initial))
            } else {
                toast.error(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [getProfileMutation.data])

    useEffect(() => {
        const error = getProfileMutation.error
        if (error) {
            console.error(error)
            toast.error(
                MESSAGE.ERROR.DATA.CALL_FAILED,
                { ...TOASTIFY_DEFAULT_OPTIONS }
            )
        }
    }, [getProfileMutation.error])

    useEffect(() => {
        /** setter conditions for mfaMessage */
        let message = ''

        if (userData.mfa && !userData.mfaInitial) {
            message = PROFILE_MESSAGE.MFA.ENABLED
        } else if (userData.mfa && userData.mfaInitial) {
            message = PROFILE_MESSAGE.MFA.ONGOING
            /**
             * reload doToggleMFA IF mfa is false AND mfaInitial is true
             * because we need to get the imageData blob again.
             * drawback is that when you enable mfa through toggle,
             * this call will be made twice due to depedency check.
             * solution is to create a global setter for userData object
             * so only react lifecycle is ran instead of 3
             */
            if (!userData.imageData) {
                const call = async () => {
                    const newToken = await revalidateToken()

                    doToggleMFA({
                        authToken: newToken,
                        toggle: userData.mfa,
                        app: 'watchdog'
                    })
                        .unwrap()
                        .then((data) => {
                            if (data.status === 'The setting is updated') {
                                dispatch(setImageData(data.image_data))
                            } else {
                                toast.error(
                                    PROFILE_MESSAGE.MFA.TOGGLE_FAULTY,
                                    { ...TOASTIFY_DEFAULT_OPTIONS }
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            toast.error(
                                MESSAGE.ERROR.DATA.CALL_FAILED,
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        })
                }

                call()
            }
        } else if (!userData.mfa && !userData.mfaInitial) {
            message = PROFILE_MESSAGE.MFA.DISABLED
        }

        dispatch(setMfaMessage(message))
    }, [userData])

    const ToggleSwitch = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            /** this is if you don't plan on aborting calls. */
            const newToken = await revalidateToken()

            doToggleMFA({
                authToken: newToken,
                toggle: !userData.mfa,
                app: 'watchdog'
            })
                .unwrap()
                .then((data) => {
                    if (data.status === 'The setting is updated') {
                        dispatch(setUserData({
                            /** set mfa response value */
                            mfa: data.mfa,
                            /** if mfa is true, set to true as well. */
                            mfaInitial: data.mfa,
                            imageData: data.image_data
                        }))
                        toast.success(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
                    } else {
                        toast.error(
                            PROFILE_MESSAGE.MFA.TOGGLE_FAULTY,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                })
                .catch((error) => {
                    console.error(error)
                    toast.error(
                        MESSAGE.ERROR.DATA.CALL_FAILED,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                })
        }

        const confirmationMessage = userData.mfa
            ? PROFILE_TEXT.MFA.ENABLE_MFA
            : PROFILE_TEXT.MFA.DISABLE_MFA

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={
                    <div className={'container-fluid px-0'}>
                        <div className={'row'}>
                            <div className={'col'}>
                                <small>{confirmationMessage}</small>
                            </div>
                        </div>
                        <div className={'row flex-row-reverse mt-1'}>
                            <div className={'col-auto'}>
                                <Button
                                    mode={'primary'}
                                    size={'sm'}
                                    onClick={confirmClickEvent}
                                >{TEXT.YES}</Button>
                            </div>
                            <div className={'col-auto'}>
                                <Button
                                    mode={'secondary'}
                                    size={'sm'}
                                    onClick={() => {
                                        toggleConfirm(false)
                                    }}
                                >{TEXT.NO}</Button>
                            </div>
                        </div>
                    </div>
                }
                onClickOutside={() => toggleConfirm(false)}
            >
                <Switch
                    /** is checked if mfa is true from getProfile fetch */
                    checked={userData.mfa}
                    /**
                     * show a tippy with a confirmation message based on the
                     * current value. if to confirm, a call event is invoked.
                     * Execute doToggleMFA with opposite value.
                     * */
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }}

                />
            </Tippy>
        )
    }, [userData, showConfirm])

    const Instructions = useMemo(() => {
        /* only render these when mfa is true and mfaInitial is true */
        let result
        if (userData.mfa && userData.mfaInitial) {
            result = <div className={'col-auto'}>
                <Text size={'xs'} className={'d-block mb-2'} >
                    {PROFILE_TEXT.MFA.INSTRUCTIONS[0]}
                </Text>
                <Text size={'xs'} className={'d-block ms-4 mt-1'}>
                    {PROFILE_TEXT.MFA.INSTRUCTIONS[1]}
                </Text>
                <Text size={'xs'} className={'d-block ms-4 mt-1'}>
                    {PROFILE_TEXT.MFA.INSTRUCTIONS[2]}
                </Text>
            </div>
        }
        return result
    }, [userData])

    const QrCodeImg = useMemo(() => {
        /* only render these when mfa is true and mfaInitial is true */

        let result

        if (userData.imageData) {
            result =
                <Profile.QRImg
                    className={'mx-3'}
                    src={`data:image/png;base64, ${ userData.imageData }`}
                    alt={PROFILE_MESSAGE.MFA.ONGOING}
                />
        }

        return result
    }, [userData])

    const QrCodeForm = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={PROFILE_TEXT.MFA.FORM.PIN_CODE.ID}>
                    {PROFILE_TEXT.MFA.FORM.PIN_CODE.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(errors.pinCode)}
                    type={'text'}
                    name={'pinCode'}
                    id={PROFILE_TEXT.MFA.FORM.PIN_CODE.ID}
                    onChange={handleChange}
                    value={values.pinCode}
                />
                <Form.Feedback errors={Boolean(errors.pinCode)} >{
                    errors.pinCode ? errors.pinCode : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.pinCode, errors.pinCode])

    const SubmitButton = useMemo(() => {
        const buttonContent = doValidateMFAInitialMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{PROFILE_TEXT.MFA.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : PROFILE_TEXT.MFA.FORM.SUBMIT_BUTTON

        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={doValidateMFAInitialMutation.isLoading}
                >
                    {buttonContent}
                </Form.Button>
            </Form.Group>
        )
    }, [doValidateMFAInitialMutation.isLoading])

    return (
        <div className={'container-fluid'}>
            {/*
            this row contains links to others except the one currently in.
            UPDATE: this becomes a component
            */}
            <MenuLinks tabs={tabs} />

            <div className={'row my-3'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    {/* first row is a switch toggle if their mfa is enabled or not. */}
                    {
                        !getProfileMutation.isUninitialized && (
                            <div className={'row'}>
                                <div className={'col-auto'}>
                                    <small>
                                        {PROFILE_TEXT.MFA.TITLE}
                                    </small>
                                </div>
                                <div className={'col-auto'}>
                                    {ToggleSwitch}
                                </div>
                                <div className={'col-auto'}>
                                    <small >
                                        {`* ${ mfaMessage }`}
                                    </small>
                                </div>
                            </div>
                        )
                    }
                    <div className={'row mt-3'}>
                        {Instructions}
                    </div>
                    <div className={`align-items-center 
                    justify-content-center justify-content-md-between mt-3 row`}>
                        <div className={'col-auto'}>
                            {QrCodeImg}
                        </div>
                        {
                            userData.imageData && (
                                <div className={'col-auto'}>
                                    <Form.Main
                                        className={'my-0'}
                                        onSubmit={handleSubmit}>
                                        {QrCodeForm}
                                        {SubmitButton}
                                    </Form.Main>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MFA

import { URL } from '@constants/main/root'
/** we are going to use the ext-vss interface because the type checks are the same. */
import {
    ModalRequest,
    ModalResponse,
    ModalDetailsRequest,
    ModalDetailsResponse
} from '@interfaces/dashboard/grc/gdpr'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const gdprApi = createApi({
    reducerPath: 'gdprApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** there should be 5 api calls. */
        getModal: builder
            .mutation<ModalResponse, ModalRequest & TokenAuth>({
                query: (body) => {
                    const formData: ModalRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        chapterIdentifier: body.chapterIdentifier,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/csm-gdpr/modal',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getModalDetails: builder
            /** same request as above */
            .mutation<ModalDetailsResponse, ModalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: ModalDetailsRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        chapterIdentifier: body.chapterIdentifier,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/csm-gdpr/modalDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetModalDetailsMutation,
    useGetModalMutation
} = gdprApi

/** thought of an organized way to initialize messages/labels in one place
 * 1.) split into MESSAGES and LABELS. MESSAGES are usually responses from toast
 * containers, or html elements with dynamic text. Examples are mfa and api calls
 * response. LABELS are html elements with static texts like titles, button text
 * and descriptions.
 */
import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'
import * as Yup from 'yup'

import {
    DoValidateMFAInitialValues,
    ToggleThemeValues
} from '@interfaces/main/profile'

export const MESSAGE = {
    MFA: {
    /** user has MFA enabled (after entering pin code) */
        ENABLED: 'Currently enabled',
        /** when user toggles MFA to false */
        DISABLED: 'Currently disabled',
        /** when user enables MFA but didn't enter QR Code */
        ONGOING: 'QR Code needed',
        /** when no image64/blob exists in the response call */
        NO_QR_CODE: 'No QR Code yet.',
        /** faulty response data from doToggleMFA */
        TOGGLE_FAULTY: 'Faulty response data from toggling MFA'
    }
}

export const TEXT = {
    MFA: {
        TITLE: 'Multi Factor Authentication Status:',
        /** confirmation labels */
        ENABLE_MFA: 'Do you want to enable multi-factor authentication?',
        DISABLE_MFA: 'Do you want to disable multi-factor authentication?',
        /** insturctions for how to use qr code */
        INSTRUCTIONS: [
            'To use authentificator app go through the following steps:',
        `1.) Download and install Google authenticator from the Play Store
        for Android and from the App store for IOS`,
        '2.) Scan the QR code or enter the private key into your authenticator app.'
        ],
        FORM: {
            PIN_CODE: {
                LABEL: 'Private key (manual code)',
                ID: 'MFA__PIN_CODE'
            },
            LOADING_BUTTON: 'Registering',
            SUBMIT_BUTTON: 'Register'
        }

    },
    SETTINGS: {
        TITLE: 'Theme',
        FORM: {
            LOADING_BUTTON: 'Saving',
            SUBMIT_BUTTON: 'Save'
        }

    }

}

/**
 * the following api calls require a formik
 * doValidateMFAInitial - pinCode must be truthy
 * toggleTheme - selection of theme should be truthy.
 */

/** change password apis and interfaces were taken from login module */

export const DO_INITIAL_MFA_FORMIK_INITIAL_VALUES: DoValidateMFAInitialValues = {
    pinCode: ''
}

export const DO_INITIAL_MFA_VALIDATION_SCHEMA = Yup.object({
    pinCode: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
})

export const TOGGLE_THEME_FORMIK_INITIAL_VALUES: ToggleThemeValues = {
    mode: ''
}

export const TOGGLE_THEME_VALIDATION_SCHEMA = Yup.object({
    mode: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
})

/** be best done from scratch INSTEAD OF copy pasting from a print component
 * where it came from a modal
 */
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalHeaderMutation,
    useGetMdrActivityMutation,
    useGetMdrDhcpMutation,
    useGetMdrDnsMutation,
    useGetMdrEventsMutation,
    useGetMdrFileInfoMutation,
    useGetMdrHttpMutation,
    useGetMdrIkev2Mutation,
    useGetMdrKrb5Mutation,
    useGetMdrLocationsMutation,
    useGetMdrSmbMutation,
    useGetMdrSnmpMutation,
    useGetMdrSshMutation,
    useGetMdrTlsMutation
} from '@apis/dashboard/soc/mdr-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    PRINT_REPORT_TITLE,
    TEXT as MDR_MAIN_TEXT
} from '@constants/dashboard/soc/mdr/detailedDashboard/main'
import {
    MESSAGE as MDR_MESSAGE
} from '@constants/dashboard/soc/mdr/main'
import {
    getUtcRanges,
    testImage,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import MDRLocationsMap from '@features/dashboard/soc/mdr/detailed-dashboard/MDRLocationsMap'
import MDRBarChart from
    '@features/dashboard/soc/mdr/detailed-dashboard/print/MDRBarChart'
import {
    CardDetails,
    LuceneQuery
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    FixedCollapsibles,
    GetDetailedDashboardRequest,
    MdrEventFilter
} from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'
import {
    GetModalRequest
    // ModalHeaderResponse
} from '@interfaces/dashboard/soc/mdr/main'

import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'

import {
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles,
    selectMapData,
    setCurrentParams,

    setIsComplete,

    setMapData,

    setScrollId,

    setTotalRecords,
    setMdrActivityData,
    setMdrDhcpData,
    setMdrDnsData,
    setMdrEventsData,
    setMdrFileinfoData,
    setMdrHttpData,
    setMdrIkev2Data,
    setMdrKrb5Data,
    setMdrLocationsData,
    setMdrSmbData,
    setMdrSnmpData,
    setMdrSshData,
    setMdrTlsData,
    toggleCollapsible
} from '@slices/dashboard/soc/mdr/detailedDashboard/main'
// import {
//     selectMode,
//     selectStyle
// } from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    FilterCell,
    PageBreakInside,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import { Buffer } from 'buffer'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectMdrDetailedMain,
    setCardDetails
} from '@slices/main/print/section'
import { useDebouncedCallback } from 'use-debounce'

const MDRMain = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    // const mode = useAppSelector(selectMode)
    // const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)
    const mapData = useAppSelector(selectMapData)

    const dashboardMain = useAppSelector(selectMdrDetailedMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [getMdrActivity, getMdrActivityMutation] = useGetMdrActivityMutation()
    const [getMdrDhcp, getMdrDhcpMutation] = useGetMdrDhcpMutation()
    const [getMdrDns, getMdrDnsMutation] = useGetMdrDnsMutation()
    const [getMdrEvents, getMdrEventsMutation] = useGetMdrEventsMutation()
    const [getMdrFileInfo, getMdrFileInfoMutation] = useGetMdrFileInfoMutation()
    const [getMdrHttp, getMdrHttpMutation] = useGetMdrHttpMutation()
    const [getMdrIkev2, getMdrIkev2Mutation] = useGetMdrIkev2Mutation()
    const [getMdrKrb5, getMdrKrb5Mutation] = useGetMdrKrb5Mutation()
    const [getMdrLocations, getMdrLocationsMutation] = useGetMdrLocationsMutation()
    const [getMdrSmb, getMdrSmbMutation] = useGetMdrSmbMutation()
    const [getMdrSnmp, getMdrSnmpMutation] = useGetMdrSnmpMutation()
    const [getMdrSsh, getMdrSshMutation] = useGetMdrSshMutation()
    const [getMdrTls, getMdrTlsMutation] = useGetMdrTlsMutation()

    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getMdrActivityMutation.error) {
            console.error(getMdrActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrActivityMutation.error])

    useEffect(() => {
        if (getMdrDhcpMutation.error) {
            console.error(getMdrDhcpMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrDhcpMutation.error])

    useEffect(() => {
        if (getMdrDnsMutation.error) {
            console.error(getMdrDnsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrDnsMutation.error])

    useEffect(() => {
        if (getMdrEventsMutation.error) {
            console.error(getMdrEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrEventsMutation.error])

    useEffect(() => {
        if (getMdrFileInfoMutation.error) {
            console.error(getMdrFileInfoMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrFileInfoMutation.error])

    useEffect(() => {
        if (getMdrHttpMutation.error) {
            console.error(getMdrHttpMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrHttpMutation.error])

    useEffect(() => {
        if (getMdrIkev2Mutation.error) {
            console.error(getMdrIkev2Mutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrIkev2Mutation.error])

    useEffect(() => {
        if (getMdrKrb5Mutation.error) {
            console.error(getMdrKrb5Mutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrKrb5Mutation.error])

    useEffect(() => {
        if (getMdrLocationsMutation.error) {
            console.error(getMdrLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrLocationsMutation.error])

    useEffect(() => {
        if (getMdrSmbMutation.error) {
            console.error(getMdrSmbMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrSmbMutation.error])

    useEffect(() => {
        if (getMdrSnmpMutation.error) {
            console.error(getMdrSnmpMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrSnmpMutation.error])

    useEffect(() => {
        if (getMdrSshMutation.error) {
            console.error(getMdrSshMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrSshMutation.error])

    useEffect(() => {
        if (getMdrTlsMutation.error) {
            console.error(getMdrTlsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMdrTlsMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetDetailedDashboardRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        const should: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => obj.not),
            turnIntoLuceneQuery)

        /**
         * for the print version, makie sure that the printOptions properties are defined.
         */

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            in_face: dashboardMain.card?.inFace || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: dashboardMain.searchParams?.search || ''
                ? Buffer
                    .from(dashboardMain.searchParams?.search || '')
                    .toString('base64')
                : '',
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        if (
            dashboardMain.searchParams &&
            dashboardMain.card
        ) {
            const newRanges = getUtcRanges({
                start: dashboardMain.searchParams.timeFrom,
                end: dashboardMain.searchParams.timeTo
            })

            /** details will be {} as we will get
         * the details data from dashboardCardDetails
         */

            dispatch(setCurrentParams({
                ranges: newRanges,
                q: dashboardMain.searchParams.search || '',
                card: {
                    deviceid: dashboardMain.card.deviceid,
                    in_face: dashboardMain.card.inFace,
                    service_type: dashboardMain.card.serviceType,
                    details: {}
                },
                boolList: (dashboardMain.searchParams.filters || []) as MdrEventFilter[]
            }))
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** i'm glad we check for dashboardMain.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetMdrActivity = () => {
        const unsubscribeMutation = getMdrActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrActivityData(undefined))
    }

    const unsubscribeGetMdrDhcp = () => {
        const unsubscribeMutation = getMdrDhcp({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrDhcpData(undefined))
    }

    const unsubscribeGetMdrDns = () => {
        const unsubscribeMutation = getMdrDns({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrDnsData(undefined))
    }

    const unsubscribeGetMdrEvents = () => {
        const unsubscribeMutation = getMdrEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrEventsData(undefined))
    }

    const unsubscribeGetMdrFileInfo = () => {
        const unsubscribeMutation = getMdrFileInfo({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrFileinfoData(undefined))
    }

    const unsubscribeGetMdrHttp = () => {
        const unsubscribeMutation = getMdrHttp({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrHttpData(undefined))
    }

    const unsubscribeGetMdrIkev2 = () => {
        const unsubscribeMutation = getMdrIkev2({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrIkev2Data(undefined))
    }

    const unsubscribeGetMdrKrb5 = () => {
        const unsubscribeMutation = getMdrKrb5({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrKrb5Data(undefined))
    }

    const unsubscribeGetMdrLocations = () => {
        const unsubscribeMutation = getMdrLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrLocationsData(undefined))
    }

    const unsubscribeGetMdrSmb = () => {
        const unsubscribeMutation = getMdrSmb({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrSmbData(undefined))
    }

    const unsubscribeGetMdrSnmp = () => {
        const unsubscribeMutation = getMdrSnmp({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrSnmpData(undefined))
        dispatch(setMdrSnmpData(undefined))
    }

    const unsubscribeGetMdrSsh = () => {
        const unsubscribeMutation = getMdrSsh({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrSshData(undefined))
    }

    const unsubscribeGetMdrTls = () => {
        const unsubscribeMutation = getMdrTls({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrTlsData(undefined))
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchMdrActivityData = () => {
        unsubscribeGetMdrActivity()
        let getMdrActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrActivityPromise = getMdrActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrActivityPromise && getMdrActivityPromise.abort()
        }
    }

    const fetchMdrSnmpData = () => {
        unsubscribeGetMdrSnmp()
        let getMdrSnmpPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrSnmpPromise = getMdrSnmp(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrSnmpPromise && getMdrSnmpPromise.abort()
        }
    }

    const fetchMdrDnsData = () => {
        unsubscribeGetMdrDns()
        let getMdrDnsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrDnsPromise = getMdrDns(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrDnsPromise && getMdrDnsPromise.abort()
        }
    }

    const fetchMdrLocationsData = () => {
        unsubscribeGetMdrLocations()
        let getMdrLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrLocationsPromise = getMdrLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrLocationsPromise && getMdrLocationsPromise.abort()
        }
    }

    const fetchMdrDhcpData = () => {
        unsubscribeGetMdrDhcp()
        let getMdrDhcpPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrDhcpPromise = getMdrDhcp(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrDhcpPromise && getMdrDhcpPromise.abort()
        }
    }

    const fetchMdrTlsData = () => {
        unsubscribeGetMdrTls()
        let getMdrTlsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrTlsPromise = getMdrTls(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrTlsPromise && getMdrTlsPromise.abort()
        }
    }

    const fetchMdrSmbData = () => {
        unsubscribeGetMdrSmb()
        let getMdrSmbPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrSmbPromise = getMdrSmb(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrSmbPromise && getMdrSmbPromise.abort()
        }
    }

    const fetchMdrFileInfoData = () => {
        unsubscribeGetMdrFileInfo()
        let getMdrFileInfoPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrFileInfoPromise = getMdrFileInfo(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrFileInfoPromise && getMdrFileInfoPromise.abort()
        }
    }

    const fetchMdrHttpData = () => {
        unsubscribeGetMdrHttp()
        let getMdrHttpPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrHttpPromise = getMdrHttp(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrHttpPromise && getMdrHttpPromise.abort()
        }
    }

    const fetchMdrKrb5Data = () => {
        unsubscribeGetMdrKrb5()
        let getMdrKrb5Promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrKrb5Promise = getMdrKrb5(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrKrb5Promise && getMdrKrb5Promise.abort()
        }
    }

    const fetchMdrSshData = () => {
        unsubscribeGetMdrSsh()
        let getMdrSshPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrSshPromise = getMdrSsh(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrSshPromise && getMdrSshPromise.abort()
        }
    }

    const fetchMdrIkev2Data = () => {
        unsubscribeGetMdrIkev2()
        let getMdrIkev2Promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrIkev2Promise = getMdrIkev2(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrIkev2Promise && getMdrIkev2Promise.abort()
        }
    }

    const fetchMdrEventsData = () => {
        unsubscribeGetMdrEvents()
        let getMdrEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrEventsPromise = getMdrEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrEventsPromise && getMdrEventsPromise.abort()
        }
    }

    /** on token revalidation and if the dashboardMain dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])
    useEffect(() => {
        if (!dashboardData['MDR.Activity']) {
            return fetchMdrActivityData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.snmp']) {
            return fetchMdrSnmpData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.dns']) {
            return fetchMdrDnsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.Locations']) {
            return fetchMdrLocationsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.dhcp']) {
            return fetchMdrDhcpData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.tls']) {
            return fetchMdrTlsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.smb']) {
            return fetchMdrSmbData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.fileinfo']) {
            return fetchMdrFileInfoData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.http']) {
            return fetchMdrHttpData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.krb5']) {
            return fetchMdrKrb5Data()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.ssh']) {
            return fetchMdrSshData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.ikev2']) {
            return fetchMdrIkev2Data()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['MDR.Events']) {
            return fetchMdrEventsData()
        }
    }, [token.valid, dashboardMain])

    /** no need for chart zoom lifecycles. */

    useEffect(() => {
        let isMounted = true
        if (getMdrActivityMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrActivityData(
                getMdrActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrActivityMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrSnmpMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrSnmpData(
                getMdrSnmpMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrSnmpMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrDnsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrDnsData(
                getMdrDnsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrDnsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrLocationsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrLocationsData(
                getMdrLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrLocationsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrDhcpMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrDhcpData(
                getMdrDhcpMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrDhcpMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrTlsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrTlsData(
                getMdrTlsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrTlsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrSmbMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrSmbData(
                getMdrSmbMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrSmbMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrFileInfoMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrFileinfoData(
                getMdrFileInfoMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrFileInfoMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrHttpMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrHttpData(
                getMdrHttpMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrHttpMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrKrb5Mutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrKrb5Data(
                getMdrKrb5Mutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrKrb5Mutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrSshMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrSshData(
                getMdrSshMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrSshMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrIkev2Mutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrIkev2Data(
                getMdrIkev2Mutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrIkev2Mutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getMdrEventsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setMdrEventsData(
                getMdrEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrEventsMutation.isSuccess])

    /** if dashboardMain change, set all refetch values to true. */

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        // type Header = ModalHeaderResponse['data'] & { title: string }
        // const data: Header = {
        //     title: dashboardCardDetails?.title || '',
        //     ...getModalHeaderMutation.data?.data || {
        //         line_1: dashboardCardDetails?.line_1 || '',
        //         line_2: dashboardCardDetails?.line_2 || '',
        //         heading: dashboardCardDetails?.heading || '',
        //         colorType: dashboardCardDetails?.colorType || 'grey'
        //     }
        // }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        // const Heading = (
        //     <Dashboard.Heading
        //         color={data.colorType || 'darkGrey'}
        //         activeMode={mode}
        //         activeStyle={style}
        //         className={'d-block mb-3'}
        //     >
        //         {data.heading || 'NO HEADING'}
        //     </Dashboard.Heading>
        // )

        // const Subtitle1 = (
        //     <Dashboard.Subtitle className={'d-block mb-1'}>
        //         {data.line_1 || 'NO LINE 1'}
        //     </Dashboard.Subtitle>
        // )

        // const Subtitle2 = (
        //     <Dashboard.Subtitle className={'d-block mb-2'}>
        //         {data.line_2 || 'NO LINE 2'}
        //     </Dashboard.Subtitle>
        // )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {/* should be removed in detailed dashboard reports */}
                {/* {Heading}
                {Subtitle1}
                {Subtitle2} */}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** add a onClick event where all the charts below will
     * add a mdrDetails modal.
     */

    /** add a onClick event where all the charts below will
     * add a mdrDetails modal.
     */
    const MDRActivity = useMemo(() => {
        return <div>
            <MDRBarChart
                eventType={'Activity'}
                requestEventType={'MDR.Activity'}
                isLoading={getMdrActivityMutation.isLoading}
                isSuccess={getMdrActivityMutation.isSuccess}
                error={getMdrActivityMutation.error}
            />
        </div>
    }, undefined)

    const MDRSnmp = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'snmp'}
                requestEventType={'MDR.snmp'}
                isLoading={getMdrSnmpMutation.isLoading}
                isSuccess={getMdrSnmpMutation.isSuccess}
                error={getMdrSnmpMutation.error}
            />
        </div>
    }, undefined)

    const MDRDns = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'dns'}
                requestEventType={'MDR.dns'}
                isLoading={getMdrDnsMutation.isLoading}
                isSuccess={getMdrDnsMutation.isSuccess}
                error={getMdrDnsMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (getMdrLocationsMutation.data) {
            dispatch(setTotalRecords(getMdrLocationsMutation.data.totalRecords))
            dispatch(setIsComplete(getMdrLocationsMutation.data.isComplete))
            dispatch(setScrollId(getMdrLocationsMutation.data.scrollId))
            dispatch(setMapData(getMdrLocationsMutation.data.data))
        }
    }, [getMdrLocationsMutation.data])

    const MDRLocations = useMemo(() => {
        return <PageBreakInside>
            <span className={'d-block mb-2'}>
                {MDR_MAIN_TEXT.SECTIONS.MDR_LOCATIONS}
            </span>

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <div className={'col'}>
                            <MDRLocationsMap
                                isPrint={true}
                            />
                        </div>
                    </div>
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </PageBreakInside>
    }, undefined)

    const MDRDhcp = useMemo(() => {
        return <div>
            <MDRBarChart
                eventType={'dhcp'}
                requestEventType={'MDR.dhcp'}
                isLoading={getMdrDhcpMutation.isLoading}
                isSuccess={getMdrDhcpMutation.isSuccess}
                error={getMdrDhcpMutation.error}
            />
        </div>
    }, undefined)

    const MDRFileInfo = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'fileinfo'}
                requestEventType={'MDR.fileinfo'}
                isLoading={getMdrFileInfoMutation.isLoading}
                isSuccess={getMdrFileInfoMutation.isSuccess}
                error={getMdrFileInfoMutation.error}
            />
        </div>
    }, undefined)

    const MDRHttp = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'http'}
                requestEventType={'MDR.http'}
                isLoading={getMdrHttpMutation.isLoading}
                isSuccess={getMdrHttpMutation.isSuccess}
                error={getMdrHttpMutation.error}
            />
        </div>
    }, undefined)

    const MDRIkev2 = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'ikev2'}
                requestEventType={'MDR.ikev2'}
                isLoading={getMdrIkev2Mutation.isLoading}
                isSuccess={getMdrIkev2Mutation.isSuccess}
                error={getMdrIkev2Mutation.error}
            />
        </div>
    }, undefined)

    const MDRKrb5 = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'krb5'}
                requestEventType={'MDR.krb5'}
                isLoading={getMdrKrb5Mutation.isLoading}
                isSuccess={getMdrKrb5Mutation.isSuccess}
                error={getMdrKrb5Mutation.error}
            />
        </div>
    }, undefined)

    const MDRSmb = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'smb'}
                requestEventType={'MDR.smb'}
                isLoading={getMdrSmbMutation.isLoading}
                isSuccess={getMdrSmbMutation.isSuccess}
                error={getMdrSmbMutation.error}
            />
        </div>
    }, undefined)

    const MDRSsh = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'ssh'}
                requestEventType={'MDR.ssh'}
                isLoading={getMdrSshMutation.isLoading}
                isSuccess={getMdrSshMutation.isSuccess}
                error={getMdrSshMutation.error}
            />
        </div>
    }, undefined)

    const MDRTls = useMemo(() => {
        return <div>

            <MDRBarChart
                eventType={'tls'}
                requestEventType={'MDR.tls'}
                isLoading={getMdrTlsMutation.isLoading}
                isSuccess={getMdrTlsMutation.isSuccess}
                error={getMdrTlsMutation.error}
            />
        </div>
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getMdrActivityMutation.data &&
            getMdrDhcpMutation.data &&
            getMdrDnsMutation.data &&
            getMdrEventsMutation.data &&
            getMdrFileInfoMutation.data &&
            getMdrHttpMutation.data &&
            getMdrIkev2Mutation.data &&
            getMdrKrb5Mutation.data &&
            getMdrLocationsMutation.data &&
            getMdrSmbMutation.data &&
            getMdrSnmpMutation.data &&
            getMdrSshMutation.data &&
            getMdrTlsMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getMdrActivityMutation.data,
        getMdrDhcpMutation.data,
        getMdrDnsMutation.data,
        getMdrEventsMutation.data,
        getMdrFileInfoMutation.data,
        getMdrHttpMutation.data,
        getMdrIkev2Mutation.data,
        getMdrKrb5Mutation.data,
        getMdrLocationsMutation.data,
        getMdrSmbMutation.data,
        getMdrSnmpMutation.data,
        getMdrSshMutation.data,
        getMdrTlsMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getMdrActivityMutation.error ||
            getMdrDhcpMutation.error ||
            getMdrDnsMutation.error ||
            getMdrEventsMutation.error ||
            getMdrFileInfoMutation.error ||
            getMdrHttpMutation.error ||
            getMdrIkev2Mutation.error ||
            getMdrKrb5Mutation.error ||
            getMdrLocationsMutation.error ||
            getMdrSmbMutation.error ||
            getMdrSnmpMutation.error ||
            getMdrSshMutation.error ||
            getMdrTlsMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getMdrActivityMutation.error,
        getMdrDhcpMutation.error,
        getMdrDnsMutation.error,
        getMdrEventsMutation.error,
        getMdrFileInfoMutation.error,
        getMdrHttpMutation.error,
        getMdrIkev2Mutation.error,
        getMdrKrb5Mutation.error,
        getMdrLocationsMutation.error,
        getMdrSmbMutation.error,
        getMdrSnmpMutation.error,
        getMdrSshMutation.error,
        getMdrTlsMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    const qComponent = useMemo(() => {
        return (
            <div className={'row justify-content-between'}>
                <Text size={'sm'} className={'col-auto'}>
                    {TEXT.SEARCH.QUERY.LABEL}
                </Text>
                <Text size={'sm'} className={'col ps-0'}>
                    {currentParams.q}
                </Text>
            </div>
        )
    }, [currentParams.q])

    const boolListComponent = useMemo(() => {
        return (
            <div className={'row mb-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(currentParams.boolList, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }, [currentParams.boolList])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                {/* for detailed dashboard reports, include the search
                query and filter queries at the very beginning */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col'}>
                        {
                            [
                                PRINT_REPORT_TITLE,
                                '-',
                                _.capitalize(
                                    dashboardMain
                                        .searchParams
                                        ?.detailedDashboard
                                )
                            ].join(' ')
                        }
                    </div>
                </div>
                {
                    currentParams.q
                        ? qComponent
                        : ''
                }
                {
                    currentParams.boolList.length
                        ? boolListComponent
                        : ''
                }
                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                {/* in all charts, apply page-break-inside to the charts only
                NOT the legends. Added color coding too. If the table is empty,
                don't show it anyway. */}
                <div className={'min-width-fix'}>
                    {MDRActivity}
                </div>

                <div className={'min-width-fix'}>
                    {MDRSnmp}
                </div>

                <div className={'min-width-fix'}>
                    {MDRDns}
                </div>

                <div className={'min-width-fix'}>
                    {MDRDhcp}
                </div>

                <div className={'min-width-fix'}>
                    {MDRSmb}
                </div>

                <div className={'min-width-fix'}>
                    {MDRHttp}
                </div>

                <div className={'min-width-fix'}>
                    {MDRLocations}
                </div>

                <div className={'min-width-fix'}>
                    {MDRTls}
                </div>

                <div className={'min-width-fix'}>
                    {MDRFileInfo}
                </div>

                <div className={'min-width-fix'}>
                    {MDRKrb5}
                </div>

                <div className={'min-width-fix'}>
                    {MDRIkev2}
                </div>

                <div className={'min-width-fix'}>
                    {MDRSsh}
                </div>

            </PrintMargin>

        </div>
    )
}

export default MDRMain

import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    GetQListRequest,
    GetQListResponse,
    GetQTypesResponse,
    GetQTypesRequest,
    GetComplianceListRequest,
    GetComplianceListResponse,
    GetQuestionsRequest,
    GetQuestionsResponse,
    GetQuestionRequest,
    GetQuestionResponse,
    DoFileUploadRequest,
    DoFileUploadResponse,
    DoAnswerQuestionRequest,
    DoAnswerQuestionResponse
} from '@interfaces/watchdog/virtual-ciso/compliance-questionnaire'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const complianceApi = createApi({
    reducerPath: 'complianceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getQList: builder.mutation<GetQListResponse,
        GetQListRequest & TokenAuth>({
            query: (body) => {
                const formData: GetQListRequest = {}

                return {
                    url: 'q/getQList',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),

        getQTypes: builder.mutation<GetQTypesResponse,
        GetQTypesRequest & TokenAuth>({
            query: (body) => {
                const formData: GetQTypesRequest = {}

                return {
                    url: 'q/getQTypes',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),

        getQuestions: builder.mutation<GetQuestionsResponse, GetQuestionsRequest & TokenAuth>({
            query: (body) => {
                const formData: GetQuestionsRequest = {
                    questionnaire_id: body.questionnaire_id
                }

                return {
                    url: 'q/getQuestions',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),

        getComplianceList: builder.mutation<GetComplianceListResponse,
        GetComplianceListRequest & TokenAuth>({
            query: (body) => {
                const formData: GetComplianceListRequest = {
                    compliance_id: body.compliance_id
                }

                return {
                    url: 'q/getComplianceList',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),

        getOneQuestion: builder.mutation<GetQuestionResponse,
        GetQuestionRequest & TokenAuth>({
            query: (body) => {
                const formData: GetQuestionRequest = {
                    questionnaire_id: body.questionnaire_id,
                    id: body.id
                }

                return {
                    url: 'q/getQuestions',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: formData
                }
            }
        }),

        doFileUpload: builder.mutation<DoFileUploadResponse,
        DoFileUploadRequest & TokenAuth>({
            query: (body) => {
                const formData: DoFileUploadRequest = {
                    fileData: body.fileData
                }

                return {
                    url: 'q/doFileUpload',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`
                    },
                    body: formData.fileData
                }
            }
        }),
        doAnswerQuestion: builder.mutation<DoAnswerQuestionResponse,
        DoAnswerQuestionRequest & TokenAuth>({
            query: (body) => {
                const formData: DoAnswerQuestionRequest = {
                    questionnaire_id: body.questionnaire_id,
                    complianceArticles: body.complianceArticles,
                    question_id: body.question_id,
                    customer_id: body.customer_id,
                    file_ids: body.file_ids,
                    comment: body.comment,
                    answers: body.answers
                }

                return {
                    url: 'q/doAnswerQuestion',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`
                    },
                    body: formData
                }
            }
        })
    })
})

export const {
    useGetQListMutation,
    useGetComplianceListMutation,
    useGetQuestionsMutation,
    useGetOneQuestionMutation,
    useGetQTypesMutation,
    useDoAnswerQuestionMutation,
    useDoFileUploadMutation
} = complianceApi

import {
    useGetBillingTypesListMutation,
    useGetBoxTypesListMutation,
    // useGetCurrencyTypesListMutation,
    useGetOrderTypesListMutation,
    useGetPartnerDiscountMutation,
    useGetPartnersListMutation,
    useGetProductTypesListMutation
} from '@apis/watchdog/account-data/order-api'
import {
    useGetPartnerDetailsMutation
} from '@apis/watchdog/account-data/partner-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    COLUMNS,
    TEXT as ORDER_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/account-data/order'
import MenuLinks from '@features/main/MenuLinks'
import AddConfirmation from '@features/watchdog/account-data/order/add/AddConfirmation'
import {
    AddOrderKeys,
    AddOrderValues,
    BillingType,
    BoxType,
    CurrencyType,
    FormOrderLine,
    OrderFormData,
    // OrderType,
    ProductType
} from '@interfaces/watchdog/account-data/order'
import {
    PartnerList
} from '@interfaces/watchdog/account-data/partner'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    addModal,
    closeModal,
    removeModal,
    selectFormikValues,
    selectModals,
    selectTabs,
    setAddFormik
} from '@slices/watchdog/account-data/order/main'
import {
    Button,
    FormStyledComponents as Form,
    Table,
    Text
} from '@styles/components'
import {
    getUnixTime
} from 'date-fns'
// import {
//     fromUnixTime,
//     getUnixTime
// } from 'date-fns'
import {
    FormikErrors,
    useFormik
} from 'formik'
import _ from 'lodash'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-responsive-modal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

const OrderAdd = () => {
    /** expected data is: changePassword  that's it. */
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)
    const formikValues = useAppSelector(selectFormikValues)

    /** fetch other calls. */
    const [getPartnersList, getPartnersListMutation] = useGetPartnersListMutation()
    // const [getCurrencyTypesList, getCurrencyTypesListMutation] =
    // useGetCurrencyTypesListMutation()
    const [getOrderTypesList, getOrderTypesListMutation] = useGetOrderTypesListMutation()
    const [getBillingTypesList, getBillingTypesListMutation] = useGetBillingTypesListMutation()
    const [getProductTypesList, getProductTypesListMutation] = useGetProductTypesListMutation()
    const [getBoxTypesList, getBoxTypesListMutation] = useGetBoxTypesListMutation()
    const [getCustomerDiscount, getCustomerDiscountMutation] = useGetPartnerDiscountMutation()
    const [getCustomerDetails, getCustomerDetailsMutation] = useGetPartnerDetailsMutation()
    const [getPartnerDetails, getPartnerDetailsMutation] = useGetPartnerDetailsMutation()

    const rootRef = useRef<HTMLElement | null | undefined>()

    const [isAddDone, setIsAddDone] = useState<boolean>(false)

    const orderFormik = useFormik({
        // to neatly store values in the global state, create a dispatch for it.
        // initial values will be from the slice.
        initialValues: formikValues.add.initialValues,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.ADD,
        onSubmit: (values) => {
            // when submitting the form, get today of the activationDate.

            const today = getUnixTime(new Date())

            orderFormik.setFieldValue(
                'activation_date',
                today
            )

            const formData: OrderFormData = {
                addConfirm: {
                    formValues: {
                        ...values,
                        activation_date: today
                    }
                }
            }

            dispatch(addModal({
                id: uniqueString(),
                open: true,
                operation: 'ORDER_ADD',
                formData: formData,
                isBorderWide: false
            }))
        }
    })

    /// you stopped here. you know how to fix the formiks reset button concern.
    // you just have to keep initialValues its default on add.
    // as for update, it should be whatever the initial value

    useEffect(() => {
        dispatch(setAddFormik({
            formikValues: orderFormik.values,
            property: 'values'
        }))
    }, [orderFormik.values])

    // on mount only.
    useEffect(() => {
        orderFormik.setValues(formikValues.add.values)
    }, [])

    const strictSetValue = useCallback((
        label: AddOrderKeys,
        value: AddOrderValues[AddOrderKeys]
    ) => {
        orderFormik.setFieldValue(
            label,
            value
        )
    }, [])

    // const OrderTypeInput = useMemo(() => {
    //     let orderStates = [] as OrderType[]
    //     const fieldValue: AddOrderKeys = 'order_type'

    //     if (getOrderTypesListMutation.data) {
    //         orderStates = getOrderTypesListMutation.data.orderTypes
    //     }

    //     return (
    //         <Form.Group>
    //             <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.ORDER_STATE.ID}>
    //                 {ORDER_TEXT.ADD.FORM.ORDER_STATE.LABEL}
    //             </Form.Label>
    //             <Select
    //                 id={ORDER_TEXT.ADD.FORM.ORDER_STATE.ID}
    //                 errors={Boolean(orderFormik.errors.order_type)}
    //                 options={orderStates}
    //                 value={_.find(
    //                     orderStates,
    //                     (e) => Number(e.value) === orderFormik.values.order_type
    //                 )}
    //                 onChange={(e) => {
    //                     if (e) {
    //                         orderFormik.setFieldValue(
    //                             fieldValue,
    //                             e.value
    //                         )
    //                     }
    //                 }}
    //                 styles={{
    //                     ...reactSelect.styles
    //                 }}
    //                 theme={reactSelect.theme}
    //             />
    //             <Form.Feedback errors={Boolean(orderFormik.errors.order_type)} >{
    //                 orderFormik.errors.order_type ? orderFormik.errors.order_type : null
    //             }</Form.Feedback>
    //         </Form.Group>
    //     )
    // }, [
    //     getOrderTypesListMutation.data,
    //     orderFormik.values.order_type,
    //     orderFormik.errors.order_type
    // ])

    const NameInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.NAME.ID}>
                    {ORDER_TEXT.ADD.FORM.NAME.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(orderFormik.errors.name)}
                    name={'name'}
                    id={ORDER_TEXT.ADD.FORM.NAME.ID}
                    onChange={orderFormik.handleChange}
                    value={orderFormik.values.name}
                    onBlur={(e) => {
                        strictSetValue('name', e.target.value.trim())
                    }}
                />
                <Form.Feedback errors={Boolean(orderFormik.errors.name)} >{
                    orderFormik.errors.name ? orderFormik.errors.name : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [orderFormik.values.name, orderFormik.errors.name])

    const SetupInstructionsInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.SETUP_INSTRUCTIONS.ID}>
                    {ORDER_TEXT.ADD.FORM.SETUP_INSTRUCTIONS.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(orderFormik.errors.setupInstructions)}
                    name={'setupInstructions'}
                    id={ORDER_TEXT.ADD.FORM.SETUP_INSTRUCTIONS.ID}
                    onChange={orderFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('setupInstructions', e.target.value.trim())
                    }}
                    value={orderFormik.values.setupInstructions}
                />
                <Form.Feedback errors={Boolean(orderFormik.errors.setupInstructions)} >{
                    orderFormik.errors.setupInstructions
                        ? orderFormik.errors.setupInstructions
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [orderFormik.values.setupInstructions, orderFormik.errors.setupInstructions])

    const EmailInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.EMAIL.ID}>
                    {ORDER_TEXT.ADD.FORM.EMAIL.LABEL}
                </Form.Label>
                <Form.Input
                    errors={Boolean(orderFormik.errors.email)}
                    name={'email'}
                    type={'email'}
                    id={ORDER_TEXT.ADD.FORM.EMAIL.ID}
                    onChange={orderFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('email', e.target.value.trim())
                    }}
                    value={orderFormik.values.email}
                />
                <Form.Feedback errors={Boolean(orderFormik.errors.email)} >{
                    orderFormik.errors.email ? orderFormik.errors.email : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [orderFormik.values.email, orderFormik.errors.email])

    const CommentInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-12 ps-0'}
                    htmlFor={ORDER_TEXT.ADD.FORM.COMMENT.ID}>
                    {ORDER_TEXT.ADD.FORM.COMMENT.LABEL}
                </Form.Label>
                <Form.TextArea
                    errors={Boolean(orderFormik.errors.comment)}
                    className={'col'}
                    name={'comment'}
                    id={ORDER_TEXT.ADD.FORM.COMMENT.ID}
                    onChange={orderFormik.handleChange}
                    onBlur={(e) => {
                        strictSetValue('comment', e.target.value.trim())
                    }}
                    value={orderFormik.values.comment}
                />
                <Form.Feedback
                    className={'col-auto'}
                    errors={Boolean(orderFormik.errors.comment)} >
                    {
                        orderFormik.errors.comment ? orderFormik.errors.comment : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [orderFormik.values.comment, orderFormik.errors.comment])

    const ActiveInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-auto ps-0 mb-0'}
                    htmlFor={ORDER_TEXT.ADD.FORM.ACTIVE.ID}>
                    {ORDER_TEXT.ADD.FORM.ACTIVE.LABEL}
                </Form.Label>
                <input
                    className={'col-auto px-0'}
                    name={'active'}
                    type={'checkbox'}
                    id={ORDER_TEXT.ADD.FORM.ACTIVE.ID}
                    onChange={orderFormik.handleChange}
                    checked={orderFormik.values.active}
                />
                <Form.Feedback
                    errors={Boolean(orderFormik.errors.active)}
                    className={'col-auto'}
                >
                    {
                        orderFormik.errors.active ? orderFormik.errors.active : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [orderFormik.values.active, orderFormik.errors.active])

    // const ActivationDateInput = useMemo(() => {
    //     const fieldValue: AddOrderKeys = 'activation_date'

    //     return (
    //         <Form.Group>
    //             <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.ACTIVATION_DATE.ID}>
    //                 {ORDER_TEXT.ADD.FORM.ACTIVATION_DATE.LABEL}
    //             </Form.Label>
    //             <Form.DatePicker
    //                 errors={Boolean(orderFormik.errors.activation_date)}
    //                 id={ORDER_TEXT.ADD.FORM.ACTIVATION_DATE.ID}
    //                 selected={fromUnixTime(orderFormik.values.activation_date)}
    //                 onChange={(date) => {
    //                     if (date && !_.isArray(date)) {
    //                         strictSetValue(
    //                             fieldValue,
    //                             getUnixTime(date)
    //                         )
    //                     }
    //                 }}
    //             />
    //             <Form.Feedback errors={Boolean(orderFormik.errors.activation_date)} >{
    //                 orderFormik.errors.activation_date
    //                     ? orderFormik.errors.activation_date
    //                     : null
    //             }</Form.Feedback>
    //         </Form.Group>
    //     )
    // }, [orderFormik.values.activation_date, orderFormik.errors.activation_date])

    // const CurrencyInput = useMemo(() => {
    //     /** FROM BDGDB-267, can't use currency types here anymore but
    //      * the billed to's currencies instead.
    //      */
    //     let currencyTypes = [] as CurrencyType[]

    //     if (getPartnerDetailsMutation.data) {
    //         currencyTypes = getPartnerDetailsMutation.data?.partnerDetails.currencies
    //     }

    //     const fieldValue: AddOrderKeys = 'currency'

    //     return (
    //         <Form.Group>
    //             <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.CURRENCY.ID}>
    //                 {ORDER_TEXT.ADD.FORM.CURRENCY.LABEL}
    //             </Form.Label>
    //             <Select
    //                 id={ORDER_TEXT.ADD.FORM.CURRENCY.ID}
    //                 errors={Boolean(orderFormik.errors.currency)}
    //                 options={currencyTypes}
    //                 value={_.find(
    //                     currencyTypes,
    //                     (e) => Number(e.value) === orderFormik.values.currency
    //                 )}
    //                 onChange={(e) => {
    //                     if (e) {
    //                         orderFormik.setFieldValue(
    //                             fieldValue,
    //                             e.value
    //                         )
    //                     }
    //                 }}
    //                 styles={{
    //                     ...reactSelect.styles
    //                 }}
    //                 theme={reactSelect.theme}
    //             />
    //             <Form.Feedback errors={Boolean(orderFormik.errors.currency)} >{
    //                 orderFormik.errors.currency ? orderFormik.errors.currency : null
    //             }</Form.Feedback>
    //         </Form.Group>
    //     )
    // }, [
    //     getPartnerDetailsMutation.data,
    //     orderFormik.values.currency,
    //     orderFormik.errors.currency
    // ])

    // const CurrencyInput = useMemo(() => {
    //     /** FROM BDGDB-267, can't use currency types here anymore but
    //      * the billed to's currencies instead.
    //      */
    //     let currencyTypes = [] as CurrencyType[]

    //     if (getPartnerDetailsMutation.data) {
    //         currencyTypes = getPartnerDetailsMutation.data?.partnerDetails.partnerCurrency
    //     }

    //     return (
    //         <Form.Group>
    //             <Form.Label>
    //                 {ORDER_TEXT.UPDATE.FORM.CURRENCY.LABEL}
    //             </Form.Label>
    //             <Select
    //                 options={currencyTypes}
    //                 value={_.find(
    //                     currencyTypes,
    //                     (e) => Number(e.value) === orderFormik.values.currency
    //                 )}
    //                 styles={{
    //                     ...reactSelect.styles
    //                 }}
    //                 theme={reactSelect.theme}
    //                 isDisabled={true}
    //             />
    //         </Form.Group>
    //     )
    // }, [
    //     getPartnerDetailsMutation.data,
    //     orderFormik.values.currency,
    //     orderFormik.errors.currency
    // ])

    const CustomerNameInput = useMemo(() => {
        let partners = [] as PartnerList[]

        if (getPartnersListMutation.data) {
            partners = getPartnersListMutation.data.partnerData
        }

        const fieldValue: AddOrderKeys = 'customer_name'

        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.CUSTOMER_NAME.ID}>
                    {ORDER_TEXT.ADD.FORM.CUSTOMER_NAME.LABEL}
                </Form.Label>
                <Select
                    id={ORDER_TEXT.ADD.FORM.CUSTOMER_NAME.ID}
                    errors={Boolean(orderFormik.errors.customer_name)}
                    options={partners}
                    value={_.find(
                        partners,
                        (e) => e.value === orderFormik.values.customer_name
                    )}
                    onChange={(e) => {
                        if (e) {
                            orderFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(orderFormik.errors.currency)} >{
                    orderFormik.errors.currency
                        ? `Currency: ${ orderFormik.errors.currency }`
                        : null
                }</Form.Feedback>
                <Form.Feedback errors={Boolean(orderFormik.errors.customer_name)} >{
                    orderFormik.errors.customer_name
                        ? `Customer Name: ${ orderFormik.errors.customer_name }`
                        : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnersListMutation.data,
        orderFormik.values.customer_name,
        orderFormik.errors.customer_name,
        orderFormik.values.currency,
        orderFormik.errors.currency
    ])

    const PartnerNameInput = useMemo(() => {
        let partners = [] as PartnerList[]

        if (getPartnersListMutation.data) {
            partners = getPartnersListMutation.data.partnerData
        }

        const fieldValue: AddOrderKeys = 'partner_name'

        // from BDGDB-267
        return (
            <Form.Group>
                <Form.Label htmlFor={ORDER_TEXT.ADD.FORM.PARTNER_NAME.ID}>
                    {ORDER_TEXT.ADD.FORM.PARTNER_NAME.LABEL}
                </Form.Label>
                <Select
                    isDisabled={true}
                    id={ORDER_TEXT.ADD.FORM.PARTNER_NAME.ID}
                    errors={Boolean(orderFormik.errors.partner_name)}
                    options={partners}
                    value={_.find(
                        partners,
                        (e) => e.value === orderFormik.values.partner_name
                    )}
                    onChange={(e) => {
                        if (e) {
                            orderFormik.setFieldValue(
                                fieldValue,
                                e.value
                            )
                        }
                    }}
                    styles={{
                        ...reactSelect.styles
                    }}
                    theme={reactSelect.theme}
                />
                <Form.Feedback errors={Boolean(orderFormik.errors.partner_name)} >{
                    orderFormik.errors.partner_name ? orderFormik.errors.partner_name : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [
        getPartnersListMutation.data,
        orderFormik.values.partner_name,
        orderFormik.errors.partner_name
    ])

    const OrderListTable = useMemo(() => {
        /** create interface no longer just a text field */

        let boxTypes = [] as BoxType[]
        let productTypes = [] as ProductType[]
        let billingTypes = [] as BillingType[]

        const fieldValue: AddOrderKeys = 'orderLines'

        if (getBoxTypesListMutation.data) {
            boxTypes = getBoxTypesListMutation.data.boxTypes
        }

        if (getProductTypesListMutation.data) {
            productTypes = getProductTypesListMutation.data.productTypes
        }

        if (getBillingTypesListMutation.data) {
            billingTypes = getBillingTypesListMutation.data.billingTypes
        }

        const thead = (
            <thead>
                <tr>
                    <th>{TEXT.SEQUENCE}</th>
                    {
                        _.map(COLUMNS.ORDERLINE, (column, index) => {
                            // logic to add fixed widths so react-select can be seen

                            let width: string | number = 'auto'

                            if (column.value === 'which_box') {
                                width = 150
                            } else if (column.value === 'which_product') {
                                width = 150
                            } else if (column.value === 'billing_type') {
                                width = 150
                            }

                            return (
                                <th key={'order-th-' + index} style={{
                                    minWidth: width
                                }}>
                                    <Text size={'xs'}>
                                        {column.label}
                                    </Text>
                                </th>
                            )
                        })
                    }
                    {/* for remove button */}
                    <th></th>
                </tr>
            </thead>
        )

        const renderInput = (
            key: keyof FormOrderLine,
            value: FormOrderLine[keyof FormOrderLine],
            orderLineIndex:number
        ) => {
            const selectOnChange = (e: any) => {
                if (e) {
                    orderFormik.setFieldValue(
                        [fieldValue,
                            '[',
                            orderLineIndex.toString(),
                            '].',
                            key].join(''),
                        e.value
                    )
                }
            }

            const errors = (orderFormik.errors.orderLines ||
                []) as FormikErrors<FormOrderLine>[]

            switch (key) {
                case 'which_box': return (
                    <Select
                        menuPlacement={'top'}
                        menuPortalTarget={rootRef.current}
                        errors={Boolean(
                            errors[orderLineIndex]?.which_box
                        )}
                        options={boxTypes}
                        value={_.find(
                            boxTypes,
                            (e) => e.value === value
                        )}
                        onChange={selectOnChange}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                    />
                )

                case 'which_product' : return (
                    <Select
                        menuPlacement={'top'}
                        menuPortalTarget={rootRef.current}
                        errors={Boolean(
                            errors[orderLineIndex]?.which_product
                        )}
                        options={productTypes}
                        value={_.find(
                            productTypes,
                            (e) => e.value === value
                        )}
                        onChange={selectOnChange}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                    />
                )

                case 'billing_type' : return (
                    <Select
                        menuPlacement={'top'}
                        menuPortalTarget={rootRef.current}
                        errors={Boolean(
                            errors[orderLineIndex]?.billing_type
                        )}
                        options={billingTypes}
                        value={_.find(
                            billingTypes,
                            (e) => e.value === value
                        )}
                        onChange={selectOnChange}
                        styles={{
                            ...reactSelect.styles
                        }}
                        theme={reactSelect.theme}
                    />
                )

                default: return (
                    <Form.TableInput
                        errors={Boolean(
                            errors[orderLineIndex]?.[key] || false
                        )}
                        type={'text'}
                        name={[
                            'orderLines[',
                            orderLineIndex.toString(),
                            '].', key
                        ].join('')}
                        onChange={orderFormik.handleChange}
                        onBlur={(e) => {
                            orderFormik.setFieldValue(
                                [
                                    'orderLines[',
                                    orderLineIndex.toString(),
                                    '].', key
                                ].join(''),
                                e.target.value.trim()
                            )
                        }}
                        value={value}
                    />
                )
            }
        }

        const renderRow = (
            orderLine: FormOrderLine,
            orderLineIndex: number
        ) => {
            return <tr key={'orderline-row-' + orderLineIndex}>
                <td>{orderLineIndex + 1}</td>
                {
                    _.map(COLUMNS.ORDERLINE, (column, columnIndex) => {
                        return (
                            <td key={'orderLine-' + orderLineIndex + 'input-' + columnIndex}>
                                {
                                    renderInput(
                                        column.value,
                                        orderLine[column.value],
                                        orderLineIndex)
                                }
                            </td>
                        )
                    })
                }
                <td>
                    <Button
                        onClick={() => {
                            const fieldValue: AddOrderKeys = 'orderLines'
                            const arr = [...orderFormik.values.orderLines]
                            arr.splice(orderLineIndex, 1)
                            orderFormik.setFieldValue(fieldValue, arr)
                        }}
                        size={'sm'}
                        mode={'danger'}>
                        {ORDER_TEXT.ADD.FORM.REMOVE_ORDER_LINE}
                    </Button>
                </td>
            </tr>
        }

        const tbody = (
            <tbody>
                {
                    _.map(orderFormik.values.orderLines, (orderLine, orderLineIndex) => {
                        return renderRow(orderLine, orderLineIndex)
                    })
                }
            </tbody>
        )

        return (
            <Table
                className={' table-striped table-hover table-sm'}
            >
                <table className={'table table-sm'}>
                    {thead}
                    {tbody}
                </table>
            </Table>
        )
    }, [
        orderFormik.values.orderLines,
        orderFormik.errors.orderLines,
        getBoxTypesListMutation.data,
        getProductTypesListMutation.data,
        getBillingTypesListMutation.data
    ])

    const AddOrderLineButton = useMemo(() => {
        const fieldValue: AddOrderKeys = 'orderLines'
        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    onClick={() => {
                        const orderLines = _.cloneDeep(orderFormik.values.orderLines)

                        const defaultObj: FormOrderLine = {
                            orderline_id: '',
                            which_box: '4',
                            billing_type: '1',
                            which_product: '',
                            qty: '0',
                            discount: '0'
                        }

                        if (getCustomerDiscountMutation.data) {
                            const data = getCustomerDiscountMutation.data
                            defaultObj.discount = data.partnerDetails.discount.toString()
                        }

                        orderLines.push(defaultObj)

                        orderFormik.setFieldValue(fieldValue, orderLines)
                    }}
                    mode={'primary'}
                    type={'button'}
                >{ORDER_TEXT.ADD.FORM.ADD_ORDER_LINE}</Form.Button>
            </Form.Group>
        )
    }, [
        getCustomerDiscountMutation.data,
        orderFormik.values.orderLines
    ])

    const DetailsFormButtons = useMemo(() => {
        return (
            <Form.Group className={'row justify-content-end'}>
                <div className={'col-auto'}>
                    <Button
                        type={'submit'}
                        mode={'primary'}
                        // disable the button if successful add operation.
                        disabled={isAddDone}

                    >{ORDER_TEXT.ADD.FORM.SUBMIT_BUTTON }</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        type={'button'}
                        mode={'secondary'}
                        onClick={() => {
                            // since resetForm is for setting the initial values.
                            // make sure it's actually the empty template but
                            // you should be able to retain whatever values
                            // were before unmounting and coming back
                            orderFormik.resetForm()
                            setIsAddDone(false)
                        }}
                    >{TEXT.FORM.RESET}</Button>
                </div>

            </Form.Group>
        )
    }, undefined)

    const unsubscribeGetPartners = () => {
        const unsubscribeMutation = getPartnersList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    // const unsubscribeGetCurrencyTypes = () => {
    //     const unsubscribeMutation = getCurrencyTypesList({} as any)
    //     unsubscribeMutation.abort()
    //     unsubscribeMutation.unsubscribe()
    // }

    const unsubscribeGetOrderTypes = () => {
        const unsubscribeMutation = getOrderTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetBillingTypes = () => {
        const unsubscribeMutation = getBillingTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetProductTypes = () => {
        const unsubscribeMutation = getProductTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetBoxTypes = () => {
        const unsubscribeMutation = getBoxTypesList({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetPartners()
        // unsubscribeGetCurrencyTypes()
        unsubscribeGetOrderTypes()
        unsubscribeGetBillingTypes()
        unsubscribeGetProductTypes()
        unsubscribeGetBoxTypes()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getPartnersPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        // let getCurrencyTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOrderTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getBillingTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getProductTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getBoxTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getPartnersPromise = getPartnersList({
                        authToken: newToken,
                        partner_type: [
                            { type: '1' },
                            { type: '2' },
                            { type: '3' },
                            { type: '4' }
                        ]
                    })
                    // getCurrencyTypesPromise = getCurrencyTypesList({
                    //     authToken: newToken
                    // })
                    getOrderTypesPromise = getOrderTypesList({
                        authToken: newToken
                    })
                    getBillingTypesPromise = getBillingTypesList({
                        authToken: newToken
                    })
                    getProductTypesPromise = getProductTypesList({
                        authToken: newToken
                    })
                    getBoxTypesPromise = getBoxTypesList({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPartnersPromise && getPartnersPromise.abort()
            // getCurrencyTypesPromise && getCurrencyTypesPromise.abort()
            getOrderTypesPromise && getOrderTypesPromise.abort()
            getBillingTypesPromise && getBillingTypesPromise.abort()
            getProductTypesPromise && getProductTypesPromise.abort()
            getBoxTypesPromise && getBoxTypesPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        /** if dependency updates, all discount rows will update the default value. */
        let getCustomerDiscountPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getCustomerDiscountPromise = getCustomerDiscount({
                        authToken: newToken,
                        partnerID: orderFormik.values.partner_name
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCustomerDiscountPromise && getCustomerDiscountPromise.abort()
        }
    }, [orderFormik.values.partner_name])

    // also, get the currency of the partner_name and set it to currency.
    useEffect(() => {
        let currencyTypes = [] as CurrencyType[]

        if (getPartnerDetailsMutation.data) {
            currencyTypes = getPartnerDetailsMutation.data?.partnerDetails.partnerCurrency
        }

        const fieldValue: AddOrderKeys = 'currency'
        orderFormik.setFieldValue(
            fieldValue,
            currencyTypes?.[0]?.value || ''
        )
    }, [
        getPartnerDetailsMutation.data?.partnerDetails.partnerCurrency,
        orderFormik.values.partner_name
    ])

    /**
     * we don't need property names for these response data.
     * */

    useEffect(() => {
        if (getCustomerDiscountMutation.data) {
            const data = getCustomerDiscountMutation.data
            /** iterate each orderList and update those orderFormik.values IF empty, NOT zero. */
            const orderLines = _.cloneDeep(orderFormik.values.orderLines)
            const fieldValue: AddOrderKeys = 'orderLines'

            _.forEach(orderLines, (orderLine) => {
                if (!orderLine.discount) {
                    orderLine.discount = data.partnerDetails.discount.toString()
                }
            })

            orderFormik.setFieldValue(fieldValue, orderLines)

            /** also, no need to set or anything but, when you add an
             *  orderline, this becomes the default value */
        }
    }, [getCustomerDiscountMutation.data])

    // useEffect(() => {
    //     if (getCurrencyTypesListMutation.error) {
    //         console.error(getCurrencyTypesListMutation.error)
    //         toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
    //     }
    // }, [getCurrencyTypesListMutation.error])

    useEffect(() => {
        if (getOrderTypesListMutation.error) {
            console.error(getOrderTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getOrderTypesListMutation.error])

    useEffect(() => {
        if (getBillingTypesListMutation.error) {
            console.error(getBillingTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBillingTypesListMutation.error])

    useEffect(() => {
        if (getProductTypesListMutation.error) {
            console.error(getProductTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getProductTypesListMutation.error])

    useEffect(() => {
        if (getBoxTypesListMutation.error) {
            console.error(getBoxTypesListMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBoxTypesListMutation.error])

    useEffect(() => {
        if (getCustomerDiscountMutation.error) {
            console.error(getCustomerDiscountMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCustomerDiscountMutation.error])

    /** lifecycle to prevent an orderState value of zero. */
    useEffect(() => {
        if (orderFormik.values.order_type <= 0) {
            // default order will always be 3.
            strictSetValue('order_type', 3)
        }
    }, [orderFormik.values.order_type])

    // when values.customerName is updated, automatically retrieve its partnerid
    // and fetch its partnerData to retrieve its partnerOf value.

    const unsubscribeCustomerDetails = () => {
        const unsubscribeMutation = getCustomerDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeCustomerDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getCustomerDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid && orderFormik.values.customer_name
            ) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getCustomerDetailsPromise = getCustomerDetails({
                        authToken: newToken,
                        // looks like the call is associated with partner_name and not customer_name
                        // so far
                        partnerID: orderFormik.values.customer_name
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getCustomerDetailsPromise && getCustomerDetailsPromise.abort()
        }
    }, [
        token.valid,
        orderFormik.values.customer_name
    ])

    const unsubscribePartnerDetails = () => {
        const unsubscribeMutation = getCustomerDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribePartnerDetails()

        // an example of doing multiple calls at once. neat
        // all 3 calls can share the revalidated token
        let getPartnerDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (
                token.valid && orderFormik.values.partner_name
            ) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getPartnerDetailsPromise = getPartnerDetails({
                        authToken: newToken,
                        // looks like the call is associated with partner_name and not customer_name
                        // so far
                        partnerID: orderFormik.values.partner_name
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPartnerDetailsPromise && getPartnerDetailsPromise.abort()
        }
    }, [
        token.valid,
        orderFormik.values.partner_name
    ])

    useEffect(() => {
        // when sold to partner is Reseller, i need to get the partner of value and
        // populate the billed to input dynamically.
        // if partner_of is falsy, sold to value string is reassigned to billed to as well.
        // and if soldTo is a distributor, same thing.

        if (
            getCustomerDetailsMutation.data?.partnerDetails &&
            getPartnersListMutation.data?.partnerData.length
        ) {
            const partnerList = getPartnersListMutation.data?.partnerData
            const customerData = getCustomerDetailsMutation.data?.partnerDetails
            const partnerData = getPartnerDetailsMutation.data?.partnerDetails

            const partnerOf = _.find(partnerList, (obj) => {
                return obj.value === customerData.partner_of
            })

            const partnerName = _.find(partnerList, (obj) => {
                return obj.label === customerData.company_name
            })

            if (customerData.partner_type === 'Reseller') {
                console.log('Case 1')
                strictSetValue(
                    'partner_name',
                    partnerOf?.value || partnerName?.value || ''
                )
            } else if (customerData.partner_type === 'Distributor') {
                console.log('Case 2')
                strictSetValue(
                    'partner_name',
                    partnerName?.value || ''
                )
            } else {
                console.log('Case 3')
                strictSetValue(
                    'partner_name',
                    partnerOf?.value || partnerName?.value || ''
                )
            }

            // also set currency to the first result. IF partnerCurrency is undefined OR an empty
            // array, do nothing.

            strictSetValue('currency', partnerData?.partnerCurrency?.[0]?.value || '')
        }
    }, [
        getPartnersListMutation.data,
        getCustomerDetailsMutation.data
    ])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
             * return modal with variable as a child.
             */
                let component: ReactElement<any, any> = <></>

                if (modal.operation === 'ORDER_ADD') {
                    component = <AddConfirmation
                        modal={modal}
                        addModal={addModal}
                        closeModal={closeModal}
                        isAddDone={isAddDone}
                        setIsAddDone={setIsAddDone}
                    />
                } else {
                    return ''
                }

                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['lg',
                            'blue',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    const ErrorLogs = <div className={'error-messages'}>
        {Object.entries(orderFormik.errors).map(([key, value], index) => (
            <div key={index} className={'error-message'}>
                <span>{key}</span>
                <span>{value}</span>
            </div>
        ))}
    </div>

    return (
        <div ref={(e) => {
            rootRef.current = e
        }}>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />
            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <Form.Main onSubmit={orderFormik.handleSubmit}>
                        {ErrorLogs}
                        {CustomerNameInput}
                        {PartnerNameInput}
                        {/* {CurrencyInput}
                        {OrderTypeInput}
                        {ActivationDateInput} */}
                        {ActiveInput}
                        {NameInput}
                        {SetupInstructionsInput}
                        {EmailInput}
                        {CommentInput}
                        {AddOrderLineButton}
                        {OrderListTable}
                        {DetailsFormButtons}
                    </Form.Main>
                </div>
            </div>
            {renderModals}
        </div>
    )
}
export default OrderAdd

import {
    MutationContext
} from '@root/MutationProvider'
import {
    selectToken
} from '@slices/main/token'
import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'

import {
    useGetReportDataMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppSelector
} from '@app/hook'
import {
    tryParseJSON
} from '@constants/main/method'
import {
    TEXT as PRINT_TEXT
} from '@constants/main/print'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    PRINT_CHECK_TIMER
} from '@constants/main/root'
import {
    DecodedReportParam,
    ReportParams
} from '@interfaces/watchdog/soc-data/event'
import {
    EventCoverPage,
    PrintLogo
} from '@styles/components'
import {
    Base64
} from 'js-base64'
import { useDebouncedCallback } from 'use-debounce'

import _ from 'lodash'

import { format } from 'date-fns'
import { useParams } from 'react-router'
const CoverPage = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const token = useAppSelector(selectToken)

    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl] = useState<string>('')

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    const [getReportData, getReportDataMutation] = useGetReportDataMutation()
    const { reportObj } = useParams<ReportParams>()

    /** fetch report data */
    const unsubscribeGetReportData = () => {
        const unsubscribeMutation = getReportData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = useCallback(() => {
        unsubscribeGetReportData()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        const decodedObj = tryParseJSON(
            Base64.decode(reportObj)
        ) as unknown as DecodedReportParam

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getReportData({
                        authToken: newToken,
                        reportId: decodedObj.id
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }, [token.valid, reportObj])

    useEffect(() => {
        return fetchData()
    }, [token.valid, reportObj])

    useEffect(() => {
        if (
            getReportDataMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getReportDataMutation.data
    ])

    useEffect(() => {
        if (
            getReportDataMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getReportDataMutation.error
    ])

    return <div>
        {isPrintComplete
            ? <div
                id={'printComplete'}
            ></div>
            : ''}
        {hasCrashed
            ? <div
                id={'hasCrashed'}
            ></div>
            : ''}

        <EventCoverPage colorType={'blue'}>
            {/* image container */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
                {/* report content */}
                <div className={'row flex-column-reverse h-75 w-100 pl-5'} >
                    <div className={'col-auto'}>
                        <div className={'annotation'}>
                            <div className={'row align-items-center'}>
                                <div className={'col-auto pl-0'}>
                                    <h5>{PRINT_TEXT.PRINT_REPORT.AUTHOR.MAIN}</h5>
                                    <h5>
                                        {
                                            getReportDataMutation.data?.reportContent
                                                ?.createdBy?.[0]?.userId
                                        }
                                    </h5>
                                </div>
                                <div className={'col-auto'}>
                                    <h5>
                                        {

                                            getReportDataMutation.data?.reportContent
                                                ?.approvedBy?.[0]?.reportVersion
                                        }
                                    </h5>
                                </div>
                                <div className={'col-auto'}>
                                    <h5>
                                        {
                                            getReportDataMutation.data?.reportContent
                                                ?.approvedBy?.[0]?.createdOn
                                                ? format(
                                                    getReportDataMutation.data.reportContent
                                                        .approvedBy[0].createdOn,
                                                    DATE_FORMAT_TIME
                                                )
                                                : ''
                                        }</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className={'col-auto subtitle'}>
                        {getReportDataMutation.data?.reportContent?.reportName}
                    </span>
                    <span className={'col-auto title'}>{
                        PRINT_TEXT.PRINT_REPORT.TITLE
                    }</span>
                </div>

            </div>
        </EventCoverPage>
    </div>
}

export default CoverPage

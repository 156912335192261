
import { useToggleThemeMutation } from '@apis/main/profile-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { tryParseJSON } from '@constants/main/method'
import {
    TEXT,
    TOGGLE_THEME_FORMIK_INITIAL_VALUES,
    TOGGLE_THEME_VALIDATION_SCHEMA
} from '@constants/main/profile'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import MenuLinks from '@features/main/MenuLinks'
import { ToggleThemeKeys } from '@interfaces/main/profile'
import { SettingsState } from '@interfaces/main/settings'
import { MutationContext } from '@root/MutationProvider'
import {
    selectTabs
} from '@slices/main/profile'
import {
    selectStyle,
    selectMode,
    setMode
} from '@slices/main/settings'
import {
    FormStyledComponents as Form,
    ProfileStyledComponents as Profile,
    SidebarStyledComponents as Sidebar,
    SpinnerContainer
} from '@styles/components'
import { THEMES } from '@styles/themes'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const Password = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    /** expected data is: changePassword formik. that's it. */
    const dispatch = useAppDispatch()
    const tabs = useAppSelector(selectTabs)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    const [toggleTheme, toggleThemeMutation] = useToggleThemeMutation()

    const {
        handleSubmit, handleChange, values, errors, setFieldValue
    } = useFormik({
        initialValues: TOGGLE_THEME_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: TOGGLE_THEME_VALIDATION_SCHEMA,
        onSubmit: async (values) => {
            const newToken = await revalidateToken()

            await toggleTheme({
                authToken: newToken,
                theme: values.mode
            })
        }
    })

    useEffect(() => {
        // perform setFieldValue based on theme.
        /** discovered how to static typing the field property for setFieldValue
         * so that errors are thrown when that key in the formik's initialValues
         * type doesn't exist.
         *
         * ':' is an annotation meaning that the assignment is valid that it should
         * be comptaible with the type operator.
         *
         * don't use 'as' unless you want the value of said variable to be compatible
         * with the selected type operator
         */
        const fieldValue: ToggleThemeKeys = 'mode'
        setFieldValue(fieldValue, mode)

        return () => {
            /**
             * revert select theme back thanks to mount lifecycle.
             * but you are better off getting the value from localStorage
             * furthermore, if the theme doesn't exist, just use the theme
             * in this lifecycle
             * */
            const userJson = localStorage.getItem('settings')

            if (userJson) {
                const obj = tryParseJSON(userJson) as unknown as SettingsState
                if (
                    _.has(obj, 'mode') && _.has(obj, 'style')
                ) {
                    if (obj.mode) {
                        dispatch(setMode(obj.mode))
                    }
                }
            } else {
                /**
                 * in the event that the localStorage data was tampered
                 * we'll create a new item named settings.
                 * */
                dispatch(setMode(mode))
                localStorage.setItem('settings', JSON.stringify({
                    mode: mode,
                    style: style
                }))
            }
        }
    }, [])

    useEffect(() => {
        // change the theme but as a preview only.
        // will be reverted back to original when this component is unmounted
        if (values.mode) {
            dispatch(setMode(values.mode))
        }
    }, [values.mode])

    useEffect(() => {
        if (toggleThemeMutation.data) {
            const data = toggleThemeMutation.data
            if (data.status === 'OK') {
                toast.success(data.description, { ...TOASTIFY_DEFAULT_OPTIONS })
                /** set the theme to state AND the localStorage. */
                const userJson = localStorage.getItem('settings')

                if (userJson) {
                    const obj = tryParseJSON(userJson) as unknown as SettingsState
                    if (
                        _.has(obj, 'mode') && _.has(obj, 'style')
                    ) {
                        obj.mode = values.mode
                        localStorage.setItem('settings', JSON.stringify(obj))
                    }
                } else {
                    /**
                     * in the event that the localStorage data was tampered
                     * we'll create a new item named settings.
                     * */
                    localStorage.setItem('settings', JSON.stringify({
                        mode: values.mode,
                        style: style
                    }))
                }

            // then empty form.
            } else {
                toast.error(data.description, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [toggleThemeMutation.data])

    useEffect(() => {
        if (toggleThemeMutation.error) {
            console.error(toggleThemeMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [toggleThemeMutation.error])

    const RadioSelections = useMemo(() => {
        // get the property names under THEMES and selecting a name.
        const activeStyle = _.find(THEMES, (_value, key) => key === style)
        const modes = (_.keys(activeStyle) || []) as string[]

        const selections = (value : string, index: number) => {
            return (
                <Profile.ThemeSelect
                    key={'theme-' + index}
                    className={'row align-items-center mb-2'}
                >
                    <div className={'col-auto mt-2 mt-md-0'}>
                        <input
                            checked={values.mode === value}
                            name={'mode'}
                            type={'radio'}
                            id={'theme-' + index}
                            onChange={handleChange}
                            value={value}
                        />
                    </div>
                    <div className={'col-auto mt-2 mt-md-0'}>
                        <Profile.ThemeLabel
                            className={'text-capitalize'}
                            htmlFor={'theme-' + index}
                        >
                            {value}
                        </Profile.ThemeLabel>
                    </div>
                    {/** this colummn content contains the footer logo
                     *  and three bars with the first three background colors
                     *  you'll only have options from the selected style.
                     * */}
                    <div className={'col-12 col-md-auto ms-0 ms-md-auto'}>
                        <Profile.ThemePreview
                            activeMode={value}
                            activeStyle={style}
                            className={'row justify-content-center mt-2 mt-md-0 align-items-center'}
                        >
                            <div className={'col-auto d-none d-md-block pe-0'}>
                                <Sidebar.FooterLogo className={''} src={'/media/logo192.png'}
                                    alt={'Bluedog Logo'} role={'button'} />
                            </div>
                            <Profile.ThemeBars
                                activeMode={value}
                                activeStyle={style}
                                className={'col-auto'}
                            >
                                <div className={'my-1'}></div>
                                <div className={'my-1'}></div>
                                <div className={'my-1'}></div>
                            </Profile.ThemeBars>
                        </Profile.ThemePreview>
                    </div>

                </Profile.ThemeSelect>
            )
        }

        return (
            <Form.Group>
                {
                    _.map(modes, selections)
                }
                <Form.Feedback errors={Boolean(errors.mode)} >{
                    errors.mode ? errors.mode : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.mode])

    const SubmitButton = useMemo(() => {
        const buttonContent = toggleThemeMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{TEXT.SETTINGS.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : TEXT.SETTINGS.FORM.SUBMIT_BUTTON

        return (
            <Form.Group className={'text-center'}>
                <Form.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={toggleThemeMutation.isLoading}
                >{buttonContent}</Form.Button>
            </Form.Group>
        )
    }, [toggleThemeMutation.isLoading])
    return (
        <div className={'container-fluid'}>
            {/* this row contains links to others except the one currently in. */}
            <MenuLinks tabs={tabs} />

            <div className={'row'}>
                <div className={'col-xl-6 col-lg-8 col-12'}>
                    <h6 className={'mt-3'}>{TEXT.SETTINGS.TITLE}</h6>
                    <Form.Main onSubmit={handleSubmit} className={'mt-0'}>
                        <div className={'container-fluid'}>
                            {RadioSelections}
                        </div>
                        {SubmitButton}
                    </Form.Main>
                </div>
            </div>
        </div>
    )
}
export default Password

import { CONFIGURATION_STATUS_ROUTES } from '@constants/main/routes'
import BoxMenu from '@features/watchdog/configuration/box-status/BoxMenu'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: CONFIGURATION_STATUS_ROUTES.BOX.link,
        component: BoxMenu
    }
]

export default Routes


import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetModalDataMutation,
    useGetModalLineChartMutation
} from '@apis/dashboard/soc/oss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as OSS_MESSAGE,
    TEXT as OSS_TEXT
} from '@constants/dashboard/soc/oss'
import {
    getUtcRanges,
    testImage
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import HorizontalGauge from '@features/dashboard/soc/oss/HorizontalGauge'
import HistoricalSecureScoresChart from
    '@features/dashboard/soc/oss/print/HistoricalSecureScoresChart'
import {
    CardDetails
} from '@interfaces/dashboard/monitor'
import {
    GetModalRequest,
    Header
} from '@interfaces/dashboard/soc/oss'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import {
    MutationContext
} from '@root/MutationProvider'
import {
    selectCurrentParams,
    setCurrentParams
} from '@slices/dashboard/soc/oss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'

import {
    selectCardDetails,
    selectOssMain,
    setCardDetails
} from '@slices/main/print/section'

import { useDebouncedCallback } from 'use-debounce'

const OSSModal = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    /** if expandDetails is false, display component as normal.
     * else, each control category will display the details
     * of the respective action types. For example,
     * (three gauges, the line chart associated with it and then
     * the details of each action type)
     */

    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectOssMain)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalLineChart, getModalLineChartMutation] = useGetModalLineChartMutation()
    const [getModalData, getModalDataMutation] = useGetModalDataMutation()
    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalLineChartMutation.error) {
            console.error(getModalLineChartMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalLineChartMutation.error])

    useEffect(() => {
        if (getModalDataMutation.error) {
            console.error(getModalDataMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalDataMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const unsubscribeGetModalLineChart = () => {
        const unsubscribeMutation = getModalLineChart({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetModalData = () => {
        const unsubscribeMutation = getModalData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalLineChart()
        unsubscribeGetModalData()

        let getModalLineChartPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getModalDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (dashboardMain.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (
                        isMounted &&
                        dashboardMain.card &&
                        dashboardMain.searchParams
                    ) {
                        const newRanges = getUtcRanges({
                            start: dashboardMain.searchParams.timeFrom,
                            end: dashboardMain.searchParams.timeTo
                        })

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const requestData: Omit<GetModalRequest, 'event_type'> & TokenAuth = {
                            authToken: newToken,
                            deviceid: dashboardMain.card.deviceid,
                            in_face: dashboardMain.card.inFace,
                            time_from: newRanges.start.toString(),
                            time_to: newRanges.end.toString()
                        }

                        getModalLineChartPromise = getModalLineChart(requestData)
                        getModalDataPromise = getModalData(requestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalLineChartPromise && getModalLineChartPromise.abort()
            getModalDataPromise && getModalDataPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        const data: Header = getModalDataMutation.data?.data.header || {
            title: dashboardCardDetails?.title || '',
            line_1: dashboardCardDetails?.line_1 || '',
            line_2: dashboardCardDetails?.line_2 || '',
            heading: dashboardCardDetails?.heading || '',
            state: dashboardCardDetails?.state || '',
            state_details: dashboardCardDetails?.state_details || '',
            colorType: dashboardCardDetails?.colorType || 'grey'
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalDataMutation.isLoading
                        ? getModalDataMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalDataMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalDataMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalDataMutation.data?.data.header
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalDataMutation
    ])

    const overallScoresEl = useRef<HTMLCanvasElement>(null)
    const allTenantsEl = useRef<HTMLCanvasElement>(null)
    const totalSeatsEl = useRef<HTMLCanvasElement>(null)
    const industryTypesEl = useRef<HTMLCanvasElement>(null)

    const ComparisonScores = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore
        /** get numbers immediately */
        const overallScoreCurrent = secureScore?.scores.currentScore.currentScore || 0
        const overallScoreMax = secureScore?.scores.currentScore.maxScore || 0
        const allTenantsScore = secureScore?.scores.allTenants.averageScore || 0
        const totalSeatsScore = secureScore?.scores.TotalSeats.averageScore || 0
        const industryTypesScore = secureScore?.scores.IndustryTypes.averageScore || 0

        return <Container bgIndex={2}>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.OVERALL_SCORE}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={overallScoreCurrent}
                        maxScore={overallScoreMax}
                        chartEl={overallScoresEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.ALL_TENANTS}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={allTenantsScore}
                        maxScore={0}
                        chartEl={allTenantsEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.TOTAL_SEATS}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={totalSeatsScore}
                        maxScore={0}
                        chartEl={totalSeatsEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.COMPARISON.INDUSTRY_TYPES}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={industryTypesScore}
                        maxScore={0}
                        chartEl={industryTypesEl}
                    />
                </div>
            </div>
        </Container>
    }, [getModalDataMutation.data])

    const dataEl = useRef<HTMLCanvasElement>(null)
    const identityEl = useRef<HTMLCanvasElement>(null)
    const appsEl = useRef<HTMLCanvasElement>(null)
    const deviceEl = useRef<HTMLCanvasElement>(null)

    const DataScores = useMemo(() => {
        const secureScore = getModalDataMutation.data?.data.securescore
        /** get numbers immediately */
        const dataTotalScore = secureScore?.Data.totalScore || 0
        const dataMaxScore = secureScore?.Data.maxScore || 0

        const identityTotalScore = secureScore?.Identity.totalScore || 0
        const identityMaxScore = secureScore?.Identity.maxScore || 0

        const appsTotalScore = secureScore?.Apps.totalScore || 0
        const appsMaxScore = secureScore?.Apps.maxScore || 0

        const deviceTotalScore = secureScore?.Device.totalScore || 0
        const deviceMaxScore = secureScore?.Device.maxScore || 0

        return <Container bgIndex={2}>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.DATA}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={dataTotalScore}
                        maxScore={dataMaxScore}
                        chartEl={dataEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.IDENTITY}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={identityTotalScore}
                        maxScore={identityMaxScore}
                        chartEl={identityEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.APPS}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={appsTotalScore}
                        maxScore={appsMaxScore}
                        chartEl={appsEl}
                    />
                </div>
            </div>
            <div className={'row align-items-center'}>
                <div className={'col-5 pe-0'}>
                    <Text size={'xs'} className={'px-3'}>
                        {OSS_TEXT.SECTIONS.DATA_SEGMENTS.DEVICE}
                    </Text>
                </div>
                <div className={'col-7 ps-0'}>
                    <HorizontalGauge
                        controlState={'Default'}
                        totalScore={deviceTotalScore}
                        maxScore={deviceMaxScore}
                        chartEl={deviceEl}
                    />
                </div>
            </div>
        </Container>
    }, [getModalDataMutation.data])

    /** line chart */
    const HistoricalSecureScores = useMemo(() => {
        return <HistoricalSecureScoresChart
            data={getModalLineChartMutation.data?.data}
            isLoading={getModalLineChartMutation.isLoading}
            isSuccess={getModalLineChartMutation.isSuccess}
            error={getModalLineChartMutation.error}
        />
    }, undefined)

    /** gauge components should be on a separate page. Even if the oss/modal
     * calls will be made repeatedly in each tab, at least the slice
     * for oss details will be used separately. Also,
     * we will make 3 separate mutations for the actionType string
     * in each controlCategory.
     */

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalLineChartMutation.data &&
            getModalDataMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalLineChartMutation.data,
        getModalDataMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalLineChartMutation.error ||
            getModalDataMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalLineChartMutation.error,
        getModalDataMutation.error,
        getCardDetailsMutation.error
    ])

    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    /** reset data on unmount */
    return (
        <div>
            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}
            {/* main modals will have a border */}
            <PrintBorder id={'printBorder'} colorType={borderColor} />

            {/* row to place your image */}
            <div className={'row flex-row-reverse mx-1'}>
                <PrintLogo className={'col-auto'}
                    alt={'Image Logo'}
                    src={imgUrl || '/media/light-theme.svg'}
                />
            </div>

            {/* these page breaks don't work here.
            use the pre existing modules that were tested as reference.
            use headless mode false on browser to quicken print checks

            CULPRIT FOUND: when your parent elements either explicitly
            contains position: absolute OR display:flex, page-break
            properties will conflict therefore no page break occured.
            */}

            <PrintMargin>

                {/* header render */}
                <div className={'row justify-content-between mb-1'}>
                    <div className={'col mb-2'}>
                        {dashboardCardDetails?.title || ''}
                    </div>
                </div>

                {/* display statcard data */}
                <div className={'row mb-3'}>
                    <div className={'col'}>
                        {Statcard}
                    </div>
                </div>

                <div className={'min-width-fix'}>
                    <div className={'row mb-3'}>
                        <div className={'col-6'}>
                            <span className={'d-inline-block mb-2'}>
                                {OSS_TEXT.SECTIONS.COMPARISON.TITLE}
                            </span>
                            {ComparisonScores}
                        </div>
                        <div className={'col-6'}>
                            <span className={'d-inline-block mb-2'}>
                                {OSS_TEXT.SECTIONS.DATA_SEGMENTS.TITLE}
                            </span>
                            {DataScores}
                        </div>
                    </div>
                </div>

                <div className={'min-width-fix'}>
                    <span className={'d-inline-block mb-2'}>
                        {OSS_TEXT.SECTIONS.HISTORICAL_SECURE_SCORES}
                    </span>
                    {HistoricalSecureScores}
                </div>
            </PrintMargin>

        </div>
    )
}

export default OSSModal

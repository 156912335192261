/** be best done from scratch INSTEAD OF copy pasting from a print component
 * where it came from a modal
 */
import { useGetCardDetailsMutation } from '@apis/dashboard/dashboard-api'
import {
    useGetDetailedAzureK8sEventsMutation,
    useGetDetailedAzureKubeEventsMutation,
    useGetDetailedAzureKubeMonAgentEventsMutation,
    useGetModalHeaderMutation
} from '@apis/dashboard/soc/azure-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'

import {
    PRINT_REPORT_TITLE
} from '@constants/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    MESSAGE as AZURE_MESSAGE
} from '@constants/dashboard/soc/azure/main'
import {
    getUtcRanges,
    testImage,
    turnIntoLuceneQuery
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import AzureBarChart from
    '@features/dashboard/soc/azure/detailed-dashboard/kubernetes/print/AzureBarChart'
import {
    CardDetails,
    LuceneQuery
} from '@interfaces/dashboard/monitor'
import {
    AzureK8sEventFilter,
    FixedCollapsibles,
    GetDetailedDashboardRequest
} from '@interfaces/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    GetModalRequest
    // ModalHeaderResponse
} from '@interfaces/dashboard/soc/azure/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles,
    setAzureK8sEventsData,
    setAzureKubeEventsData,
    setAzureKubeMonAgentEventsData,
    setCurrentParams,
    toggleCollapsible
} from '@slices/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    selectAzureDetailedKubernetes,
    selectCardDetails,
    setCardDetails
} from '@slices/main/print/section'
import { selectToken } from '@slices/main/token'
import {
    Container,
    DashboardStyledComponents as Dashboard,
    FilterCell,
    PrintBorder,
    PrintLogo,
    PrintMargin,
    SpinnerContainer,
    Text
} from '@styles/components'
import {
    Buffer
} from 'buffer'
import {
    format,
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const AzureKubernetes = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const currentParams = useAppSelector(selectCurrentParams)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    // const mode = useAppSelector(selectMode)
    // const style = useAppSelector(selectStyle)
    const dashboardData = useAppSelector(selectDashboardData)

    const dashboardMain = useAppSelector(selectAzureDetailedKubernetes)
    const dashboardCardDetails = useAppSelector(selectCardDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>('')
    const [borderColor, setBorderColor] = useState<ColorPresets>('lightGrey')

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()

    const [getDetailedAzureKubeEvents,
        getDetailedAzureKubeEventsMutation
    ] = useGetDetailedAzureKubeEventsMutation()
    const [getDetailedAzureKubeMonAgentEvents,
        getDetailedAzureKubeMonAgentEventsMutation
    ] = useGetDetailedAzureKubeMonAgentEventsMutation()
    const [getDetailedAzureK8sEvents,
        getDetailedAzureK8sEventsMutation
    ] = useGetDetailedAzureK8sEventsMutation()

    const [getCardDetails, getCardDetailsMutation] = useGetCardDetailsMutation()

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getModalHeaderMutation.error])

    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */

    useEffect(() => {
        if (getDetailedAzureKubeEventsMutation.error) {
            console.error(getDetailedAzureKubeEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDetailedAzureKubeEventsMutation.error])

    useEffect(() => {
        if (getDetailedAzureKubeMonAgentEventsMutation.error) {
            console.error(getDetailedAzureKubeMonAgentEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDetailedAzureKubeMonAgentEventsMutation.error])

    useEffect(() => {
        if (getDetailedAzureK8sEventsMutation.error) {
            console.error(getDetailedAzureK8sEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDetailedAzureK8sEventsMutation.error])

    useEffect(() => {
        if (getCardDetailsMutation.error) {
            console.error(getCardDetailsMutation.error)
        }
    }, [getCardDetailsMutation.error])

    const createRequestData: (newToken: string) => Omit<GetDetailedDashboardRequest, 'event_type'>
    & TokenAuth = (newToken) => {
        const newRanges = getUtcRanges({
            start: dashboardMain.searchParams?.timeFrom || 0,
            end: dashboardMain.searchParams?.timeTo || 0
        })

        const should: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(
                dashboardMain.searchParams?.filters || [],
                (obj) => obj.not),
            turnIntoLuceneQuery)

        /**
         * for the print version, makie sure that the printOptions properties are defined.
         */

        return {
            authToken: newToken,
            deviceid: dashboardMain.card?.deviceid || '',
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: dashboardMain.searchParams?.search || ''
                ? Buffer
                    .from(dashboardMain.searchParams?.search || '')
                    .toString('base64')
                : '',
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        if (
            dashboardMain.searchParams &&
            dashboardMain.card
        ) {
            const newRanges = getUtcRanges({
                start: dashboardMain.searchParams.timeFrom,
                end: dashboardMain.searchParams.timeTo
            })

            /** details will be {} as we will get
             * the details data from dashboardCardDetails
             */

            dispatch(setCurrentParams({
                ranges: newRanges,
                q: dashboardMain.searchParams.search || '',
                card: {
                    deviceid: dashboardMain.card.deviceid,
                    in_face: dashboardMain.card.inFace,
                    service_type: dashboardMain.card.serviceType,
                    details: {}
                },
                boolList: (dashboardMain.searchParams.filters || []) as AzureK8sEventFilter[]
            }))
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** i'm glad we check for dashboardMain.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetAzureKubeEvents = () => {
        const unsubscribeMutation = getDetailedAzureKubeEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        /** make sure you empty the data */
        dispatch(setAzureKubeEventsData(undefined))
    }

    const unsubscribeGetAzureKubeMonAgentEvents = () => {
        const unsubscribeMutation = getDetailedAzureKubeMonAgentEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setAzureKubeMonAgentEventsData(undefined))
    }

    const unsubscribeGetAzureK8sEvents = () => {
        const unsubscribeMutation = getDetailedAzureK8sEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setAzureK8sEventsData(undefined))
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (dashboardMain && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchAzureKubeEventsData = () => {
        unsubscribeGetAzureKubeEvents()
        let getAzureKubeEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeEventsPromise = getDetailedAzureKubeEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeEventsPromise && getAzureKubeEventsPromise.abort()
        }
    }

    const fetchAzureKubeMonAgentEventsData = () => {
        unsubscribeGetAzureKubeMonAgentEvents()
        let getAzureKubeMonAgentEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeMonAgentEventsPromise = getDetailedAzureKubeMonAgentEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeMonAgentEventsPromise && getAzureKubeMonAgentEventsPromise.abort()
        }
    }

    const fetchAzureK8sEventsData = () => {
        unsubscribeGetAzureK8sEvents()
        let getAzureK8sEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureK8sEventsPromise = getDetailedAzureK8sEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureK8sEventsPromise && getAzureK8sEventsPromise.abort()
        }
    }

    /** on token revalidation and if the dashboardMain dependency is modified
     * AS A RESULT of a url value change.
     *
     * UPDATE: no need to remove token.valid since it doesn't run twice. tested
     * on first page navigation, refresh and deviceid value change.
     */
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])

    useEffect(() => {
        if (!dashboardData['Azure.KubeEvents']) {
            return fetchAzureKubeEventsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Azure.KubeMonAgentEvents']) {
            return fetchAzureKubeMonAgentEventsData()
        }
    }, [token.valid, dashboardMain])
    useEffect(() => {
        if (!dashboardData['Azure.k8s.Events']) {
            return fetchAzureK8sEventsData()
        }
    }, [token.valid, dashboardMain])

    /**
     * when component is mounted, do not execute calls if there is already
     * dashboard data.
     *
     * detailed dashboard soc has slowdowns when navigating AND refreshing.
     * fix is to empty the data when refetch is true.
     */

    useEffect(() => {
        let isMounted = true
        if (getDetailedAzureKubeEventsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setAzureKubeEventsData(
                getDetailedAzureKubeEventsMutation.data
            ))
        }

        return () => {
            isMounted = false
        }
    }, [getDetailedAzureKubeEventsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getDetailedAzureKubeMonAgentEventsMutation.isSuccess && isMounted) {
            /** make sure you empty the data */
            dispatch(setAzureKubeMonAgentEventsData(
                getDetailedAzureKubeMonAgentEventsMutation.data
            ))
        }

        return () => {
            isMounted = false
        }
    }, [getDetailedAzureKubeMonAgentEventsMutation.isSuccess])

    useEffect(() => {
        let isMounted = true
        if (getDetailedAzureK8sEventsMutation.isSuccess && isMounted) {
            dispatch(setAzureK8sEventsData(
                getDetailedAzureK8sEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getDetailedAzureK8sEventsMutation.isSuccess])

    /** if dashboardMain change, set all refetch values to true. */

    /** we will need a useEffect to get card details. */
    useEffect(() => {
        let getCardDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                if (dashboardMain.card && isMounted) {
                    getCardDetailsPromise = getCardDetails({
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        in_face: dashboardMain.card.inFace,
                        service_type: dashboardMain.card.serviceType
                    })
                }
            }
            call()
        }

        return () => {
            isMounted = false
            getCardDetailsPromise && getCardDetailsPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (getCardDetailsMutation.data) {
            const data = getCardDetailsMutation.data
            const result: CardDetails = {
                colorType: data.data[0]?.colorType || 'grey',
                heading: data.data[0]?.heading || '',
                lastUpdate: data.data[0]?.lastUpdate || '',
                line_1: data.data[0]?.line_1 || '',
                line_2: data.data[0]?.line_2 || '',
                location: data.data[0]?.location || '',
                state: data.data[0]?.state || '',
                state_details: data.data[0]?.state_details || '',
                title: data.data[0]?.title || ''
            }
            dispatch(setCardDetails(result))
        }
    }, [getCardDetailsMutation.data])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Header data
         * than detailed card data.
         */

        // type Header = ModalHeaderResponse['data'] & { title: string }
        // const data: Header = {
        //     title: dashboardCardDetails?.title || '',
        //     ...getModalHeaderMutation.data?.data || {
        //         line_1: dashboardCardDetails?.line_1 || '',
        //         line_2: dashboardCardDetails?.line_2 || '',
        //         heading: dashboardCardDetails?.heading || '',
        //         colorType: dashboardCardDetails?.colorType || 'grey'
        //     }
        // }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{AZURE_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        // const Heading = (
        //     <Dashboard.Heading
        //         color={data.colorType || 'darkGrey'}
        //         activeMode={mode}
        //         activeStyle={style}
        //         className={'d-block mb-3'}
        //     >
        //         {data.heading || 'NO HEADING'}
        //     </Dashboard.Heading>
        // )

        // const Subtitle1 = (
        //     <Dashboard.Subtitle className={'d-block mb-1'}>
        //         {data.line_1 || 'NO LINE 1'}
        //     </Dashboard.Subtitle>
        // )

        // const Subtitle2 = (
        //     <Dashboard.Subtitle className={'d-block mb-2'}>
        //         {data.line_2 || 'NO LINE 2'}
        //     </Dashboard.Subtitle>
        // )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ dashboardMain.card?.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ dashboardCardDetails?.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ dashboardCardDetails?.state || 'NO STATE' }
                    : ${ dashboardCardDetails?.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {/* should be removed in detailed dashboard reports */}
                {/* {Heading}
                {Subtitle1}
                {Subtitle2} */}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        dashboardMain,
        dashboardCardDetails,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        setBorderColor(
            data?.colorType || dashboardCardDetails?.colorType || 'grey'
        )
    }, [
        dashboardCardDetails,
        getModalHeaderMutation
    ])

    /** all fixed collapsibles will be toggled true. */
    useEffect(() => {
        _.forEach(_.keys(fixedCollapsibles), (key) => {
            dispatch(toggleCollapsible({
                key: key as keyof FixedCollapsibles,
                value: true
            }))
        })
    }, [])

    /** add a onClick event where all the charts below will
     * add a azureDetails modal.
     */
    const AzureKubeEvents = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.KubeEvents'}
                isLoading={getDetailedAzureKubeEventsMutation.isLoading}
                isSuccess={getDetailedAzureKubeEventsMutation.isSuccess}
                error={getDetailedAzureKubeEventsMutation.error}
            />
        </div>
    }, undefined)

    const AzureKubeMonAgentEvents = useMemo(() => {
        return <div>
            <AzureBarChart
                eventType={'Azure.KubeMonAgentEvents'}
                isLoading={getDetailedAzureKubeMonAgentEventsMutation.isLoading}
                isSuccess={getDetailedAzureKubeMonAgentEventsMutation.isSuccess}
                error={getDetailedAzureKubeMonAgentEventsMutation.error}
            />
        </div>
    }, undefined)

    /** reset data on unmount */

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalHeaderMutation.data &&
            getDetailedAzureKubeEventsMutation.data &&
            getDetailedAzureKubeMonAgentEventsMutation.data &&
            getDetailedAzureK8sEventsMutation.data &&
            getCardDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalHeaderMutation.data,
        getDetailedAzureKubeEventsMutation.data,
        getDetailedAzureKubeMonAgentEventsMutation.data,
        getDetailedAzureK8sEventsMutation.data,
        getCardDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalHeaderMutation.error ||
            getDetailedAzureKubeEventsMutation.error ||
            getDetailedAzureKubeMonAgentEventsMutation.error ||
            getDetailedAzureK8sEventsMutation.error ||
            getCardDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalHeaderMutation.error,
        getDetailedAzureKubeEventsMutation.error,
        getDetailedAzureKubeMonAgentEventsMutation.error,
        getDetailedAzureK8sEventsMutation.error,
        getCardDetailsMutation.error
    ])
    /** script to load image and check if it exists. if not, a default image will be used */

    useEffect(() => {
        if (dashboardMain.printOptions.logo) {
            testImage(
                dashboardMain.printOptions.logo,
                setImgUrl
            )
        }
    }, [dashboardMain.printOptions.logo])

    const qComponent = useMemo(() => {
        return (
            <div className={'row justify-content-between'}>
                <Text size={'sm'} className={'col-auto'}>
                    {TEXT.SEARCH.QUERY.LABEL}
                </Text>
                <Text size={'sm'} className={'col ps-0'}>
                    {currentParams.q}
                </Text>
            </div>
        )
    }, [currentParams.q])

    const boolListComponent = useMemo(() => {
        return (
            <div className={'row mb-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(currentParams.boolList, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }, [currentParams.boolList])

    /** reset data on unmount */
    return <div>
        {isPrintComplete
            ? <div
                id={'printComplete'}
            ></div>
            : ''}
        {hasCrashed
            ? <div
                id={'hasCrashed'}
            ></div>
            : ''}
        {/* main modals will have a border */}
        <PrintBorder id={'printBorder'} colorType={borderColor} />

        {/* row to place your image */}
        <div className={'row flex-row-reverse mx-1'}>
            <PrintLogo className={'col-auto'}
                alt={'Image Logo'}
                src={imgUrl || '/media/light-theme.svg'}
            />
        </div>

        <PrintMargin>

            {/* header render */}
            {/* for detailed dashboard reports, include the search
            query and filter queries at the very beginning */}
            <div className={'row justify-content-between mb-1'}>
                <div className={'col'}>
                    {
                        [
                            PRINT_REPORT_TITLE,
                            '-',
                            _.capitalize(
                                dashboardMain
                                    .searchParams
                                    ?.detailedDashboard
                            )
                        ].join(' ')
                    }
                </div>
            </div>
            {
                currentParams.q
                    ? qComponent
                    : ''
            }
            {
                currentParams.boolList.length
                    ? boolListComponent
                    : ''
            }
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {Statcard}
                </div>
            </div>

            {/* in all charts, apply page-break-inside to the charts only
            NOT the legends. Added color coding too. If the table is empty,
            don't show it anyway. */}
            <div className={'min-width-fix'}>
                {AzureKubeEvents}
            </div>
            <div className={'min-width-fix'}>
                {AzureKubeMonAgentEvents}
            </div>
        </PrintMargin>
    </div>
}

export default AzureKubernetes

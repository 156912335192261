
import {
    useGetIssueDetailsMutation,
    useGetIssuesOvertimeMutation
} from '@apis/dashboard/soc/ext-vss-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as EXT_VSS_MESSAGE,
    SIMILAR_HOSTS_COLUMNS,
    TEXT as EXT_VSS_TEXT
} from '@constants/dashboard/soc/ext-vss'
import {
    getUtcRanges,
    createIntervals,
    assignIntervalTick,
    generateInterval
} from '@constants/main/method'

import {
    ACTION_MUTATION_PROMISE,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS,
    TABLE_CONTAINER_HEIGHT
} from '@constants/main/root'
import { MonitorModal } from '@interfaces/dashboard/monitor'
import {
    IssueDetailsRequest,
    SimilarHost
} from '@interfaces/dashboard/soc/ext-vss'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    setRefetch,
    setCurrentParams,
    resetExtVssDetails,
    selectSearchParams,
    selectCurrentParams,
    selectSimilarHosts
} from '@slices/dashboard/soc/ext-vss/vulnDesc'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Container,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import {
    Chart,
    TimeScale,
    Legend,
    LinearScale,
    Tooltip,
    BarController,
    BarElement,
    CategoryScale
} from 'chart.js'
import {
    format,
    fromUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo,
    useRef
} from 'react'
import { toast } from 'react-toastify'
import { createStylesheet } from '@styles/themes'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const VulnDesc = ({ modal, addModal, closeModal } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const similarHosts = useAppSelector(selectSimilarHosts)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** now execute api call for vulnerability descriptions AND issuesOvertime. */
    const [getIssueDetails, getIssueDetailsMutation] = useGetIssueDetailsMutation()
    const [getIssuesOvertime, getIssuesOvertimeMutation] = useGetIssuesOvertimeMutation()

    useEffect(() => {
        if (getIssueDetailsMutation.error) {
            console.error(getIssueDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssueDetailsMutation.error])

    useEffect(() => {
        if (getIssuesOvertimeMutation.error) {
            console.error(getIssuesOvertimeMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getIssuesOvertimeMutation.error])

    const unsubscribeGetIssueDetails = () => {
        const unsubscribeMutation = getIssueDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetIssuesOvertime = () => {
        const unsubscribeMutation = getIssuesOvertime({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetIssueDetails()
        unsubscribeGetIssuesOvertime()
        let getIssueDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getIssuesOvertimePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        if (modal.card) {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        const newRanges = getUtcRanges(searchParams.ranges)

                        dispatch(setCurrentParams({
                            ranges: newRanges
                        }))

                        const issueDetailsRequestData: IssueDetailsRequest & TokenAuth = {
                            authToken: newToken,
                            deviceid: modal.card.deviceid,
                            service_type: modal.card.service_type,
                            in_face: modal.card.in_face,
                            lastupdate: modal.serviceTypeFormData
                                ?.extVss?.vulnDesc?.lastupdate || 0,
                            port: modal.serviceTypeFormData
                                ?.extVss?.vulnDesc?.Port || '',
                            proto: modal.serviceTypeFormData
                                ?.extVss?.vulnDesc?.Protocol || '',
                            ip: modal.serviceTypeFormData
                                ?.extVss?.vulnDesc?.IP || '',
                            oid: modal.serviceTypeFormData
                                ?.extVss?.vulnDesc?.OID || ''
                        }

                        getIssueDetailsPromise = getIssueDetails(issueDetailsRequestData)
                        getIssuesOvertimePromise = getIssuesOvertime(issueDetailsRequestData)
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getIssueDetailsPromise && getIssueDetailsPromise.abort()
            getIssuesOvertimePromise && getIssuesOvertimePromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (searchParams.refetch) {
            return fetchData()
        }
    }, [searchParams.refetch])

    useEffect(() => {
        if (
            getIssueDetailsMutation.isSuccess &&
            getIssuesOvertimeMutation.isSuccess &&
            searchParams.refetch
        ) {
            dispatch(setRefetch(false))
        }
    }, [
        getIssueDetailsMutation.isSuccess,
        getIssuesOvertimeMutation.isSuccess
    ])

    /** there are no date pickers and refresh buttons here yet.
     * just a title. proceed with contents of vulnerability description
     */

    const Description = useMemo(() => {
        const issueDetails = getIssueDetailsMutation.data?.detailData[0]

        const tags = issueDetails?.tags || ''
        const labels = tags.match(/(\|\w+=)/igm)

        const strings = _.map(labels, (tag, index, array) => {
            const start = tags.indexOf(array[index]) + array[index].length
            const end = array[index + 1] ? tags.indexOf(array[index + 1]) : tags.length
            let label = array[index].replace(/[|=]/ig, '').replace(/[_]/ig, ' ')

            switch (label) {
                case 'vuldetect': label = 'Vulnerability Detection'; break
                case 'qod type': label = 'Quality of Delivery Type'; break
                default: break
            }
            return {
                title: label,
                content: tags.substring(start, end)
            }
        })

        const description = (
            <div className={'mt-2 mb-3'}>
                <span className={'d-block mb-2 text-capitalize'}>
                    {EXT_VSS_TEXT.SECTIONS.DESCRIPTION}
                </span>
                <Text size={'xs'} className={'d-block mb-2'}>
                    {modal.serviceTypeFormData?.extVss?.vulnDesc?.Description}
                </Text>
            </div>
        )

        const output = _.map(strings, (obj, index) => {
            const key = [
                'vulnDesc-', index
            ].join('')

            return (
                <div className={'my-3'} key={key}>
                    <span className={'d-block mb-2 text-capitalize'}>
                        {obj.title}
                    </span>
                    <Text size={'xs'} className={'d-block mb-2'}>
                        {obj.content || TEXT.NONE}
                    </Text>
                </div>
            )
        })

        const content = (
            <Container bgIndex={2} className={'px-3 py-2'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        {description}
                        {output}
                    </div>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getIssueDetailsMutation.isLoading
                ? getIssueDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getIssueDetailsMutation.error)
                : LoadingContent
        )
    }, [getIssueDetailsMutation])

    /** create issuesovertime chart AND similarHosts table. */
    const issuesOvertimeChartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)

        return () => {
            dispatch(resetExtVssDetails())
        }
    }, [])

    useEffect(() => {
        const issuesOvertime = getIssuesOvertimeMutation.data?.issueOverTime

        let graph: Chart<'bar', { x: string; y: number; }[], string>

        /** get stylesheet object to assign color to dataset */
        const stylesheet = createStylesheet(style, mode)

        if (issuesOvertime && issuesOvertimeChartEl.current) {
            /** timestamps for issuesovertime is the first and last elements. */
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            // these timestamps need fromUnixTime as they aren't strings.
            // also switch the selections because the data is in ascending
            // order
            if (issuesOvertime.length >= 2) {
                ranges.start = fromUnixTime(issuesOvertime[0].timestamp)
                ranges.end = fromUnixTime(
                    issuesOvertime[issuesOvertime.length - 1].timestamp
                )
            }

            const fixedInterval = generateInterval(ranges.start, ranges.end)

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            const datasets: typeof graph.data.datasets = [{
                /** should be in x and y format */
                data: _.map(intervals, (interval, index) => {
                    // iterate issuesOvertime array and increment x for each
                    // match.
                    let count = 0

                    _.forEach(issuesOvertime, (obj) => {
                        if (isValid(new Date(intervals[index + 1])) && isWithinInterval(
                            fromUnixTime(obj.timestamp),
                            {
                                start: new Date(interval),
                                end: intervals[index + 1]
                                    ? new Date(intervals[index + 1])
                                    : new Date()
                            }
                        )) {
                            count += obj.count
                        } else {
                            // it means we've reached the end. add it anyway.
                            // count += obj.count
                        }
                    })

                    return {
                        x: interval,
                        y: count
                    }
                }),
                backgroundColor: stylesheet.style.buttonTypeColors.primary,
                normalized: true,
                /** parsing property causes an empty chart.
                 * refer to chart's internal format for data property */
                parsing: false,
                maxBarThickness: DEFAULT_BAR_THICKNESS,
                barThickness: 'flex',
                /** apply minimum distance between each bar */
                barPercentage: 0.7
            }]

            graph = new Chart(issuesOvertimeChartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        }
                    }
                }
            })

            issuesOvertimeChartEl.current.style.height = CHART_HEIGHT.md
        }

        // assign chart height,

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [getIssuesOvertimeMutation.data, currentParams.ranges])

    const IssuesOvertimeBarChart = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={issuesOvertimeChartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EXT_VSS_MESSAGE.FETCH.ISSUES_OVERTIME}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !getIssuesOvertimeMutation.isLoading
                ? getIssuesOvertimeMutation.isSuccess
                    ? content
                    : JSON.stringify(getIssuesOvertimeMutation.error)
                : LoadingContent
        )
    }, undefined)

    /** similar hosts table. no search and anything like that. */
    const SimilarHostsTable = useMemo(() => {
        const cellBody = (
            dataObject: SimilarHost,
            property: keyof SimilarHost
        ) => {
            let cellContent: SimilarHost[keyof SimilarHost] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover px-0'}
            height={TABLE_CONTAINER_HEIGHT.SMALL}
            bgIndex={2}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(SIMILAR_HOSTS_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'similarHosts-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(similarHosts, (dataObject, rowIndex) => {
                            return (
                                <tr
                                    key={'similarHosts-tr-' + rowIndex}
                                >
                                    {
                                        _.map(SIMILAR_HOSTS_COLUMNS, (column, cellIndex) => {
                                            return (
                                                <td key={[
                                                    'similarHosts-td-' + rowIndex +
                                            '-' + cellIndex
                                                ].join('')}
                                                >
                                                    {cellBody(dataObject, column.value)}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Table>

        const EmptyCellContent = (
            <small className={'d-block text-center py-2'}>
                {MESSAGE.TABLE.EMPTY}
            </small>
        )

        return (
            similarHosts.length
                ? content
                : EmptyCellContent
        )
    }, [similarHosts])

    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col-auto'}>
                    {
                        [
                            EXT_VSS_TEXT.SECTIONS.VULNERABILITY_DESCRIPTION,
                            ':',
                            modal.serviceTypeFormData?.extVss?.vulnDesc?.Name
                        ].join(' ')
                    }
                </div>
            </div>

            <div className={'row mb-3'}>
                <div className={'col-12 col-md-6 mb-3 mb-md-0'}>
                    {Description}
                </div>
                <div className={'col-md-6 col-12'}>
                    <Container bgIndex={2} className={'px-3'}>
                        <span className={'d-inline-block mb-2 mt-3'}>
                            {EXT_VSS_TEXT.SECTIONS.ISSUES_OVERTIME}
                        </span>
                        {IssuesOvertimeBarChart}
                        <span className={'d-inline-block mt-3 mb-2'}>
                            {EXT_VSS_TEXT.SECTIONS.SIMILAR_HOSTS}
                        </span>
                        {SimilarHostsTable}
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default VulnDesc

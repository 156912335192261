import { useGetModalDetailsMutation } from '@apis/dashboard/soc/mdr-api'
/** can use the same calls as alert is eventDetails copy. */
import {
    useDoCommentMutation,
    useGetEventDetailsMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    assignIntervalTick,
    createIntervals
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING,
    DEFAULT_INTERVAL,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
/** can use the same formik AND constants as alert is eventDetails copy. */
import {
    MESSAGE as EVENT_MESSAGE,
    TEXT as EVENT_TEXT,
    INITIAL_VALUES,
    VALIDATION_SCHEMA
} from '@constants/watchdog/soc-data/event'
import {
    MdrDetail
} from '@interfaces/dashboard/soc/mdr/main'

/** just import the same interface for event details since alert type is a copy of this. */
import {
    DoCommentKeys,
    EventDetailsActions,
    EventDetailsState,
    EventObj
} from '@interfaces/watchdog/soc-data/event'
import {
    FlowModal,
    FlowType
} from '@interfaces/watchdog/soc-data/flow'
import { MutationContext } from '@root/MutationProvider'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
/** any state in flow modals should have a copy of its own.
 * comment validation was an example just to avoid confusion.
 */
import {
    refreshDetailsModal
} from '@slices/watchdog/soc-data/flow'
import {
    Button,
    Container,
    FormStyledComponents as Form,
    SpinnerContainer,
    Text
} from '@styles/components'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import {
    format,
    getTime,
    isWithinInterval,
    sub,
    add,
    fromUnixTime,
    getUnixTime,
    isValid
} from 'date-fns'
import produce from 'immer'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef
} from 'react'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { Buffer } from 'buffer'
import Tippy from '@tippyjs/react'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { createStylesheet } from '@styles/themes'
import { useFormik } from 'formik'

const AlertDetails = ({ modal } : {modal: FlowModal}) => {
    const dispatch = useAppDispatch()

    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const token = useAppSelector(selectToken)
    const style = useAppSelector(selectStyle)
    const mode = useAppSelector(selectMode)

    const mdrBarChartEl = useRef<HTMLCanvasElement>(null)

    const [doComment, doCommentMutation] = useDoCommentMutation()

    const commentFormik = useFormik({
        initialValues: INITIAL_VALUES.COMMENT,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.COMMENT,
        onSubmit: async () => {
            const newToken = await revalidateToken()

            if (modal.flowObj) {
                doComment({
                    authToken: newToken,
                    id: modal.flowObj.id,
                    clusterid: modal.flowObj.clusterid,
                    comment: commentFormik.values.comment
                })
            }
        }
    })

    /** call getEventDetails mutation */
    const [getEventDetails, getEventDetailsMutation] = useGetEventDetailsMutation()
    const [getModalDetails, getModalDetailsMutation] = useGetModalDetailsMutation()

    const [eventDetailsState, eventDetailsDispatch] = useReducer(
        (state: EventDetailsState, action: EventDetailsActions) => {
            switch (action.type) {
                case 'SET_END_RANGE': {
                    return produce(state, draft => { draft.ranges.end = action.value })
                }
                case 'SET_START_RANGE': {
                    return produce(state, draft => { draft.ranges.start = action.value })
                }
                case 'SET_REFETCHMDR': {
                    return produce(state, draft => { draft.refetchMdr = action.value })
                }
            }
        }, {
            ranges: {
                start: getUnixTime(sub(new Date(), { days: 1 })),
                end: getUnixTime(new Date())
            },
            refetchMdr: false
        }
    )

    useEffect(() => {
        if (modal.flowObj) {
            eventDetailsDispatch({
                type: 'SET_START_RANGE',
                value: getUnixTime(sub(
                    new Date(modal.flowObj.timestamp)
                    , { days: 1 }
                ))
            })

            eventDetailsDispatch({
                type: 'SET_END_RANGE',
                value: getTime(
                    new Date(modal.flowObj.timestamp)
                )
            })
        }
    }, [])

    /** call with abort */

    const unsubscribeGetEventDetails = () => {
        const unsubscribeMutation = getEventDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetEventDetails()
        let getEventDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        if (modal.flowObj) {
            const call = async (eventObj: EventObj) => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        getEventDetailsPromise = getEventDetails({
                            authToken: newToken,
                            id: eventObj.id,
                            clusterid: eventObj.clusterid
                        })
                    }
                }
            }

            call(modal.flowObj)
        }

        return () => {
            isMounted = false
            getEventDetailsPromise && getEventDetailsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (modal.detailRefresh) {
            return fetchData()
        }
    }, [modal.detailRefresh])

    /** perform mdr modalDetails api call if eventObj is truthy too */
    const unsubscribeGetModalDetails = () => {
        const unsubscribeMutation = getModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetModalDetails()
        let getModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const eventDetail = getEventDetailsMutation.data?.eventDetail

        if (eventDetail) {
            const deviceid = eventDetail._source.beat?.name || ''
            const eventType = eventDetail._source.event_type as FlowType
            const inIface = eventDetail._source.in_iface
            const lat = ''
            const lon = ''
            const q = ''
            const signature = eventDetail._source.alert?.signature || ''
            const srcIp = eventDetail._source.src_ip

            // UPDATE: get timezone offset before setting both the
            // range commentFormik.values.
            const timezoneOffset = new Date().getTimezoneOffset()

            const rangeStart = format(
                add(
                    eventDetailsState.ranges.start,
                    { minutes: timezoneOffset }
                )
                , 't')

            const rangeEnd = format(
                add(
                    eventDetailsState.ranges.end,
                    { minutes: timezoneOffset }
                )
                , 't')

            /** select time ranges from useReducer */

            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken()
                    if (isMounted) {
                        getModalDetailsPromise = getModalDetails({
                            authToken: newToken,
                            deviceid: deviceid,
                            event_type: eventType || '',
                            in_face: inIface,
                            lat: lat,
                            lon: lon,
                            q: q,
                            signature: signature,
                            src_ip: srcIp,
                            time_from: rangeStart,
                            time_to: rangeEnd

                        })
                    }
                }
            }

            call()
        }

        return () => {
            isMounted = false
            getModalDetailsPromise && getModalDetailsPromise.abort()
        }
    }, [token.valid, getEventDetailsMutation.data, eventDetailsState.refetchMdr])

    /** set data if successful. no need to refresh but can be implemented if wanted to.
     * this is where useMemo comes into play. the data is already there. no need to set it again
     * elsewhere.
    */
    useEffect(() => {
        if (getEventDetailsMutation.error) {
            console.error(getEventDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getEventDetailsMutation.error])

    useEffect(() => {
        if (getModalDetailsMutation.data) {
            eventDetailsDispatch({
                type: 'SET_REFETCHMDR',
                value: false
            })
        }
    }, [getModalDetailsMutation.data])

    useEffect(() => {
        if (getModalDetailsMutation.error) {
            console.error(getModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            eventDetailsDispatch({
                type: 'SET_REFETCHMDR',
                value: false
            })
        }
    }, [getModalDetailsMutation.error])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                eventDetailsDispatch({
                    type: 'SET_START_RANGE',
                    value: getUnixTime(date)
                })
            } else {
                eventDetailsDispatch({
                    type: 'SET_START_RANGE',
                    value: 0
                })
            }
        }

        const startDate = fromUnixTime(eventDetailsState.ranges.start)
        const endDate = fromUnixTime(eventDetailsState.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [eventDetailsState.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                eventDetailsDispatch({
                    type: 'SET_END_RANGE',
                    value: getUnixTime(date)
                })
            } else {
                eventDetailsDispatch({
                    type: 'SET_END_RANGE',
                    value: 0
                })
            }
        }

        const startDate = fromUnixTime(eventDetailsState.ranges.start)
        const endDate = fromUnixTime(eventDetailsState.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [eventDetailsState.ranges])

    /** first layout is title + date selector to refresh mdr details + a button to print a report */
    const HeaderOne = useMemo(() => {
        return (
            <div className={'align-items-center justify-content-between row'}>
                <span className={'col-auto'}>{EVENT_TEXT.DETAILS.TITLE}</span>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    <Tippy
                        className={'tippy-box'}
                        arrow
                        content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                        <div className={'d-inline-block'}>{StartDatePicker}</div>
                    </Tippy>
                    <Tippy
                        className={'tippy-box'}
                        arrow
                        content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                        <div className={'d-inline-block'}>{EndDatePicker}</div>
                    </Tippy>
                    <Button
                        onClick={() => {
                            // update mdr bar chart
                            eventDetailsDispatch({
                                type: 'SET_REFETCHMDR',
                                value: true
                            })
                        }}
                        size={'sm'}
                        className={'ms-2'}
                        mode={'info'}>
                        {EVENT_TEXT.DETAILS.SEARCH.UPDATE_MDR_CHART_BUTTON}
                    </Button>
                </div>
            </div>
        )
    }, [
        eventDetailsState.ranges
    ])

    const LoadingContent = useMemo(() => {
        return (
            <Container bgIndex={2} className={'px-3 py-3'}>
                <div className={'row'}>
                    <SpinnerContainer className={'small col text-center'}>
                        <span className={'spinner-border spinner-border-sm'}></span>
                        <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                    </SpinnerContainer>
                </div>
            </Container>
        )
    }, [])

    const GeneralInfo = useMemo(() => {
        const _source = getEventDetailsMutation.data?.eventDetail._source

        const data = {
            timestamp: _source
                ? format(
                    new Date(_source.timestamp)
                    , DATE_FORMAT_TIME
                )
                : '',
            signature: _source?.alert?.signature || '',
            protocol: _source?.proto || '',
            boxname: _source?.beat?.hostname || '',
            source: _source?.src_ip,
            hostname: _source?.http?.hostname ||
            (_source?.dns?.query || [])?.[0]?.rrname || _source?.tls?.sni || '',
            destination: _source?.dest_ip || '',
            category: _source?.alert?.category || '',
            inInterface: _source?.in_iface,
            signatureId: _source?.alert?.signature_id || 0,
            flowId: _source?.flow_id || 0,
            severity: _source?.alert?.severity || 0
        }

        type Data = typeof data

        const columnContent = (label: string, value: Data[keyof Data]) => {
            return (
                <div className={'col-12 col-md-6 mb-2'}>
                    <div className={'row justify-content-between'}>
                        <Text size={'xs'} className={'col-6'}>{label}</Text>
                        <Text size={'xs'} className={'col-6'}>{value || TEXT.NONE}</Text>
                    </div>
                </div>
            )
        }

        const content = (
            <Container bgIndex={2} className={'px-3 py-3'}>
                <div className={'row'}>
                    {columnContent('Timestamp', data.timestamp)}
                    {columnContent('Signature', data.signature)}
                </div>
                <div className={'row'}>
                    {columnContent('Protocol', data.protocol)}
                    {columnContent('Box Name', data.boxname)}
                </div>
                <div className={'row'}>
                    {columnContent('Source I.P.', data.source)}
                    {columnContent('Host Name', data.hostname)}
                </div>
                <div className={'row'}>
                    {columnContent('Destination I.P.', data.destination)}
                    {columnContent('Category', data.category)}
                </div>
                <div className={'row'}>
                    {columnContent('In Interface', data.inInterface)}
                    {columnContent('Signature ID', data.signatureId)}
                </div>
                <div className={'row'}>
                    {columnContent('Flow ID', data.flowId)}
                    {columnContent('Severity', data.severity)}
                </div>
            </Container>
        )

        return (
            !getEventDetailsMutation.isLoading
                ? getEventDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getEventDetailsMutation.error)
                : LoadingContent
        )
    }, [
        getEventDetailsMutation
    ])

    /** same as general info but geoip data. */
    const GeoIP = useMemo(() => {
        const geoip = getEventDetailsMutation.data?.eventDetail._source.geoip

        const data = {
            cityName: geoip?.city_name || '',
            continentCode: geoip?.continent_code || '',
            countryCode2: geoip?.country_code2 || '',
            countryCode3: geoip?.country_code3 || '',
            countryName: geoip?.country_name || '',
            dmaCode: geoip?.dma_code || '',
            ip: geoip?.ip || '',
            latitude: geoip?.latitude || 0,
            locationLat: geoip?.location?.lat || 0,
            locationLong: geoip?.location?.lon || 0,
            longitude: geoip?.longitude || 0,
            postalCode: geoip?.postal_code || '',
            regionCode: geoip?.region_code || '',
            regionName: geoip?.region_name || '',
            timezone: geoip?.timezone || ''
        }

        type Data = typeof data

        const columnContent = (label: string, value: Data[keyof Data]) => {
            return (
                <div className={'col-12 col-md-4 mb-2'}>
                    <div className={'row justify-content-between'}>
                        <Text size={'xs'} className={'col-6'}>{label}</Text>
                        <Text size={'xs'} className={'col-6'}>{value}</Text>
                    </div>
                </div>
            )
        }

        const content = (
            <Container bgIndex={2} className={'px-3 py-3'}>
                <div className={'row'}>
                    {columnContent('City Name', data.cityName)}
                    {columnContent('Continent Code', data.continentCode)}
                    {columnContent('Country Code 2', data.countryCode2)}
                </div>
                <div className={'row'}>
                    {columnContent('Country Code 3', data.countryCode3)}
                    {columnContent('Country Name', data.countryName)}
                    {columnContent('DMA Code', data.dmaCode)}
                </div>
                <div className={'row'}>
                    {columnContent('IP', data.ip)}
                    {columnContent('Latitude', data.latitude)}
                    {columnContent('Location.Lat', data.locationLat)}
                </div>
                <div className={'row'}>
                    {columnContent('Location.Lon', data.locationLong)}
                    {columnContent('Longitude', data.longitude)}
                    {columnContent('Postal Code', data.postalCode)}
                </div>
                <div className={'row'}>
                    {columnContent('Region Code', data.regionCode)}
                    {columnContent('Region Name', data.regionName)}
                    {columnContent('Timezone', data.timezone)}
                </div>
            </Container>
        )

        return (
            !getEventDetailsMutation.isLoading
                ? getEventDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getEventDetailsMutation.error)
                : LoadingContent
        )
    }, [
        getEventDetailsMutation
    ])

    /** next is a mdr bar chart. display each count by timestamp and group them accordingly.
     * perform a zoom-in and zoom-out logic on datasets. As previously discussed, you are
     * better off performing an api call with the selected time ranges. for example, if a data
     * index is clicked, the time range between those values are going to be the selected ranges
     * for the call. Useful for very large datasets to avoid many canvas graphics drawn.
    */

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const modalDetails = getModalDetailsMutation.data

        let aggreggation : _.Dictionary<MdrDetail[]> = {}
        let graph: Chart<'bar', { mdrDetails: MdrDetail, x: string; y: number; }[], string>

        /** get stylesheet object to assign color to dataset */
        const stylesheet = createStylesheet(style, mode)

        if (modalDetails && mdrBarChartEl.current) {
            const fixedInterval = modalDetails.fixedInterval || DEFAULT_INTERVAL
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(eventDetailsState.ranges.start),
                end: fromUnixTime(eventDetailsState.ranges.end)
            }

            if (modalDetails.data.length >= 2) {
                ranges.start = new Date(
                    modalDetails.data[modalDetails.data.length - 1]?.Timestamp
                )
                ranges.end = new Date(modalDetails.data[0]?.Timestamp)
            }

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            aggreggation = _.groupBy(modalDetails.data, (obj) => {
                for (let index = 0; index < intervals.length; index++) {
                    const value = intervals[index]

                    const intervalOne = new Date(value)
                    const intervalTwo = new Date(intervals[index + 1])

                    if (
                        isValid(intervalTwo) &&
                        isWithinInterval(
                            new Date(obj.Timestamp),
                            {
                                start: intervalOne,
                                end: intervalTwo
                            }
                        )
                    ) {
                        return format(intervalOne, DATE_FORMAT_TIME)
                    }
                }

                // display a console error where the timestamp is not included in the interaval
                console.warn('Timestamp is not within interval')
                return format(new Date(obj.Timestamp), DATE_FORMAT_TIME)
            })

            const datasets: typeof graph.data.datasets = [{
                /** should be in x and y format */
                data: _.map(intervals, (interval) => {
                    const found = _.find(aggreggation, (value, key) => {
                        return isValid(new Date(key)) && _.isEqual(
                            format(new Date(key), DATE_FORMAT_TIME),
                            interval
                        )
                    }) || []

                    return {
                        mdrDetails: found[0] || {},
                        x: interval,
                        y: found.length
                    }
                }),
                backgroundColor: stylesheet.style.buttonTypeColors.primary,
                normalized: true,
                /** parsing property causes an empty chart.
                 * refer to chart's internal format for data property */
                parsing: false,
                maxBarThickness: DEFAULT_BAR_THICKNESS,
                barThickness: 'flex',
                /** apply minimum distance between each bar */
                barPercentage: 0.7
            }]

            graph = new Chart(mdrBarChartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        }
                    }
                }
            })

            mdrBarChartEl.current.style.height = CHART_HEIGHT.md
        }

        // assign chart height,

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [getModalDetailsMutation.data])

    const MDRBarChart = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={mdrBarChartEl}/>
                </div>
            </Container>
        )

        return (
            !getModalDetailsMutation.isLoading
                ? getModalDetailsMutation.isSuccess
                    ? content
                    : JSON.stringify(getModalDetailsMutation.error)
                : LoadingContent
        )
    }, undefined)

    const CommentInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.TextArea
                    errors={Boolean(commentFormik.errors.comment)}
                    className={'col-12'}
                    name={'comment'}
                    id={EVENT_TEXT.COMMENT.FORM.COMMENT.ID}
                    onChange={commentFormik.handleChange}
                    value={commentFormik.values.comment}
                />
                <Form.Feedback
                    className={'col-12'}
                    errors={Boolean(commentFormik.errors.comment)} >
                    {
                        commentFormik.errors.comment
                            ? commentFormik.errors.comment
                            : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [commentFormik.values.comment, commentFormik.errors.comment])

    const SubmitButton = useMemo(() => {
        const buttonContent = doCommentMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EVENT_TEXT.COMMENT.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : EVENT_TEXT.COMMENT.FORM.SUBMIT_BUTTON
        return (
            <Button
                size={'sm'}
                type={'submit'}
                mode={'primary'}
                disabled={doCommentMutation.isLoading}
            >{buttonContent}</Button>
        )
    }, [doCommentMutation.isLoading])

    useEffect(() => {
        if (doCommentMutation.data) {
            const data = doCommentMutation.data
            if (data.status) {
                toast.success(data.status, { ...TOASTIFY_DEFAULT_OPTIONS })
                // empty comment field value
                const fieldValue: DoCommentKeys = 'comment'
                commentFormik.setFieldValue(fieldValue, '')
                // then refresh alertDetails
                dispatch(refreshDetailsModal(modal))
            } else {
                toast.error(
                    EVENT_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [doCommentMutation.data])

    useEffect(() => {
        if (doCommentMutation.error) {
            console.error(doCommentMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doCommentMutation.error])

    const History = useMemo(() => {
        const history = getEventDetailsMutation
            .data?.eventDetail._source
            .evebox?.history || []

        const content = _.map(history, (obj, index) => {
            const key = [
                'history-', index
            ].join('')

            return (
                <div key={key} className={'row'}>
                    <Text size={'xs'} className={'col'}>
                        {format(new Date(obj.timestamp), DATE_FORMAT_TIME)}
                    </Text>
                    <Text size={'xs'} className={'col'}>
                        {decodeURIComponent(obj.comment)}
                    </Text>
                    <Text size={'xs'} className={'col'}>
                        {obj.action + ' by ' + obj.username}
                    </Text>
                </div>
            )
        })

        const emptyContent = (
            <div className={'row'}>
                <Text size={'xs'} className={'col'}>
                    {MESSAGE.DATA.EMPTY_RECORDS}
                </Text>
            </div>

        )

        const commentInterface = (
            /* in this row, initate an add comment interface. */
            <div className={'row'}>
                <form onSubmit={commentFormik.handleSubmit} className={'my-0 col-12'}>
                    {CommentInput}
                    <div className={'mb-2 mb-md-0'}>
                        {SubmitButton}
                    </div>
                </form>

            </div>
        )

        return (
            !getEventDetailsMutation.isLoading
                ? getEventDetailsMutation.isSuccess
                    ? (
                        <Container bgIndex={2} className={'px-4 py-2'}>
                            { history.length
                                ? content
                                : emptyContent}
                            {commentInterface}
                        </Container>
                    )
                    : JSON.stringify(getEventDetailsMutation.error)
                : LoadingContent
        )
    }, [
        getEventDetailsMutation,
        commentFormik.values.comment, commentFormik.errors.comment
    ])

    /** next is to display an array of payloads. */
    const Payloads = useMemo(() => {
        const _source = getEventDetailsMutation.data?.eventDetail._source

        const payloads: {
            title: string,
            printable: string,
            payload: string
        }[] = []

        if (_source) {
            payloads.push({
                title: 'Payload',
                printable: _source.payload_printable || TEXT.NONE,
                payload: _source.payload || TEXT.NONE
            })

            payloads.push({
                title: 'Packet',
                printable: _source.packet
                    ? Buffer.from(_source.packet, 'base64')
                        .toString('ascii')
                        .replace(/[^ -~]+/g, '.')
                    : TEXT.NONE,
                payload: _source.packet || TEXT.NONE
            })

            payloads.push({
                title: 'HTTP Request Body',
                printable: _source.http?.http_request_body_printable || TEXT.NONE,
                payload: _source.http?.http_request_body || TEXT.NONE
            })

            payloads.push({
                title: 'HTTP Response Body',
                printable: _source.http?.http_response_body_printable || TEXT.NONE,
                payload: _source.http?.http_response_body || TEXT.NONE
            })
        }

        return _.map(payloads, (obj, index) => {
            const key = [
                'payload-', index
            ].join('')

            const content = (
                <Container bgIndex={2} className={'px-3 py-3'}>
                    <div className={'row'}>
                        <div className={'col-md-6 col-12 mb-3 mb-md-0'}>
                            <textarea readOnly value={obj.printable} />
                        </div>
                        <div className={'col-md-6 col-12 mb-3 mb-md-0'}>
                            <textarea readOnly value={obj.payload} />
                        </div>
                    </div>
                </Container>
            )

            return (
                <div key={key}>
                    <div className={'row mb-2'}>
                        <span className={'col'}>{obj.title}</span>
                    </div>
                    <div className={'row mb-4'}>
                        <div className={'col'}>
                            {content}
                        </div>
                    </div>
                </div>
            )
        })
    }, [
        getEventDetailsMutation
    ])

    const JsonData = useMemo(() => {
        const data = getEventDetailsMutation.data || {}

        return (
            <Container bgIndex={2} className={'px-3 py-3'}>
                <textarea readOnly value={
                    JSON.stringify(data, undefined, 4)}
                />
            </Container>
        )
    }, [
        getEventDetailsMutation
    ])

    /** now create flow details table, selecting a row will populate the mdr
     * data id modal. Please note that this flow table functions differently
     * from the Flow ID module where it populates content based on event type.
     */

    return (
        <div>
            {HeaderOne}
            <div className={'row mt-1 mb-2'}>
                <span className={'col'}>{EVENT_TEXT.DETAILS.SECTIONS.GENERAL_INFO}</span>
            </div>
            <div className={'row mb-4'}>
                <div className={'col'}>
                    {GeneralInfo}
                </div>
            </div>
            <div className={'row mb-2'}>
                <span className={'col'}>{EVENT_TEXT.DETAILS.SECTIONS.MDR_BAR_ACTIVITY}</span>
            </div>
            <div className={'row mb-4'}>
                <div className={'col'}>
                    {MDRBarChart}
                </div>
            </div>
            <div className={'row mb-2'}>
                <span className={'col'}>{EVENT_TEXT.DETAILS.SECTIONS.HISTORY}</span>
            </div>
            <div className={'row mb-4'}>
                <div className={'col'}>
                    {History}
                </div>
            </div>
            <div className={'row mb-2'}>
                <span className={'col'}>{EVENT_TEXT.DETAILS.SECTIONS.GEO_IP}</span>
            </div>
            <div className={'row mb-4'}>
                <div className={'col'}>
                    {GeoIP}
                </div>
            </div>
            {Payloads}
            <div className={'row mb-2'}>
                <span className={'col'}>{EVENT_TEXT.DETAILS.SECTIONS.JSON_DATA}</span>
            </div>
            <div className={'row mb-4'}>
                <div className={'col'}>
                    {JsonData}
                </div>
            </div>
        </div>
    )
}
export default AlertDetails


import {
    useGetModalHeaderMutation,
    useGetAzureUpdateMutation,
    useGetAzureSecurityEventMutation,
    useGetAzureEventTypesMutation,
    useGetAzureKubeEventsMutation,
    useGetAzureAzureActivityMutation,
    useGetAzureCommonSecurityLogMutation,
    useGetAzureSysLogComputerMutation,
    useGetAzureProtectionStatusMutation,
    useGetAzureSysLogProcessNameMutation,
    useGetAzureKubeMonAgentEventsMutation
} from '@apis/dashboard/soc/azure-api'
import {
    getLocalRanges
} from '@constants/main/method'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    CHART_ZOOM,
    MESSAGE as MONITOR_MESSAGE,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    MESSAGE as AZURE_MESSAGE,
    TEXT as AZURE_TEXT
} from '@constants/dashboard/soc/azure/main'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { AZURE_DETAILED_DASHBOARD_ROUTES } from '@constants/main/routes'
import {
    DetailedSearchParams,
    MonitorModal
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    GetModalRequest,
    ModalHeaderResponse
} from '@interfaces/dashboard/soc/azure/main'
import {
    ColorPresets,
    TokenAuth
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    selectPrintOptions,
    setEndDate,
    selectSearchParams,
    selectFixedCollapsibles,
    setLogo,
    setRefetch,
    setStartDate,
    toggleCollapsible,
    resetAzureMain,
    setCurrentParams,
    selectCurrentParams,
    setChartZoom,
    selectChartZooms,
    setChartZoomLevel
} from '@slices/dashboard/soc/azure/main'
import { push } from 'connected-react-router'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import { selectToken } from '@slices/main/token'
import {
    Button,
    DashboardFooter as Footer,
    Container,
    DashboardStyledComponents as Dashboard,
    SpinnerContainer,
    Text,
    CollapsibleText
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime,
    isValid,
    sub
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import {
    FaDirections,
    FaFilePdf,
    FaSearchMinus,
    FaPlus,
    FaMinus
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import AzureBarChart from '@features/dashboard/soc/azure/AzureBarChart'

import uniqueString from 'unique-string'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { DEFAULT_QUEUE } from '@constants/main/print'

const AzureModal = ({ modal, addModal, closeModal, changeModalColor } : {
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const setAzureDetailedDashboardParams = rootContext.setAzureDetailedDashboardParams

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const chartZooms = useAppSelector(selectChartZooms)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    const [getModalHeader, getModalHeaderMutation] = useGetModalHeaderMutation()
    const [checkLatestData, checkLatestDataMutation] = useGetModalHeaderMutation()

    const [getAzureUpdate, getAzureUpdateMutation] = useGetAzureUpdateMutation()
    const [getAzureSecurityEvent,
        getAzureSecurityEventMutation] = useGetAzureSecurityEventMutation()
    const [getAzureEventTypes,
        getAzureEventTypesMutation] = useGetAzureEventTypesMutation()
    const [getAzureKubeEvents,
        getAzureKubeEventsMutation] = useGetAzureKubeEventsMutation()
    const [getAzureAzureActivity,
        getAzureAzureActivityMutation] = useGetAzureAzureActivityMutation()
    const [getAzureCommonSecurityLog,
        getAzureCommonSecurityLogMutation] = useGetAzureCommonSecurityLogMutation()
    const [getAzureSysLogComputer,
        getAzureSysLogComputerMutation] = useGetAzureSysLogComputerMutation()
    const [getAzureProtectionStatus,
        getAzureProtectionStatusMutation] = useGetAzureProtectionStatusMutation()
    const [getAzureSysLogProcessName,
        getAzureSysLogProcessNameMutation] = useGetAzureSysLogProcessNameMutation()
    const [getAzureKubeMonAgentEvents,
        getAzureKubeMonAgentEventsMutation] = useGetAzureKubeMonAgentEventsMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    useEffect(() => {
        if (getModalHeaderMutation.error) {
            console.error(getModalHeaderMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'modal.Azure.Header',
                value: false
            }))
        }
    }, [getModalHeaderMutation.error])

    useEffect(() => {
        if (getAzureUpdateMutation.error) {
            console.error(getAzureUpdateMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.Update',
                value: false
            }))
        }
    }, [getAzureUpdateMutation.error])

    useEffect(() => {
        if (getAzureSecurityEventMutation.error) {
            console.error(getAzureSecurityEventMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.SecurityEvent',
                value: false
            }))
        }
    }, [getAzureSecurityEventMutation.error])

    useEffect(() => {
        if (getAzureEventTypesMutation.error) {
            console.error(getAzureEventTypesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.EventTypes',
                value: false
            }))
        }
    }, [getAzureEventTypesMutation.error])

    useEffect(() => {
        if (getAzureKubeEventsMutation.error) {
            console.error(getAzureKubeEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.KubeEvents',
                value: false
            }))
        }
    }, [getAzureKubeEventsMutation.error])

    useEffect(() => {
        if (getAzureAzureActivityMutation.error) {
            console.error(getAzureAzureActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.AzureActivity',
                value: false
            }))
        }
    }, [getAzureAzureActivityMutation.error])

    useEffect(() => {
        if (getAzureCommonSecurityLogMutation.error) {
            console.error(getAzureCommonSecurityLogMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.CommonSecurityLog',
                value: false
            }))
        }
    }, [getAzureCommonSecurityLogMutation.error])

    useEffect(() => {
        if (getAzureSysLogComputerMutation.error) {
            console.error(getAzureSysLogComputerMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.SysLog.Computer',
                value: false
            }))
        }
    }, [getAzureSysLogComputerMutation.error])

    useEffect(() => {
        if (getAzureProtectionStatusMutation.error) {
            console.error(getAzureProtectionStatusMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.ProtectionStatus',
                value: false
            }))
        }
    }, [getAzureProtectionStatusMutation.error])

    useEffect(() => {
        if (getAzureSysLogProcessNameMutation.error) {
            console.error(getAzureSysLogProcessNameMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.SysLog.ProcessName',
                value: false
            }))
        }
    }, [getAzureSysLogProcessNameMutation.error])

    useEffect(() => {
        if (getAzureKubeMonAgentEventsMutation.error) {
            console.error(getAzureKubeMonAgentEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Azure.KubeMonAgentEvents',
                value: false
            }))
        }
    }, [getAzureKubeMonAgentEventsMutation.error])

    const createRequestData: (newToken: string, eventType?: EventType) =>
    Omit<GetModalRequest, 'event_type'> & TokenAuth = (newToken, eventType) => {
        // cloneDeep searchParams.ranges for modification.
        const ranges = _.cloneDeep(searchParams.ranges)

        dispatch(setCurrentParams({
            ranges: ranges
        }))

        return {
            authToken: newToken,
            deviceid: modal.card.deviceid,
            time_from: ranges.start.toString(),
            time_to: ranges.end.toString()
        }
    }

    const unsubscribeGetModalHeader = () => {
        const unsubscribeMutation = getModalHeader({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureUpdate = () => {
        const unsubscribeMutation = getAzureUpdate({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureSecurityEvent = () => {
        const unsubscribeMutation = getAzureSecurityEvent({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureEventTypes = () => {
        const unsubscribeMutation = getAzureEventTypes({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureKubeEvents = () => {
        const unsubscribeMutation = getAzureKubeEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureAzureActivity = () => {
        const unsubscribeMutation = getAzureAzureActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureCommonSecurityLog = () => {
        const unsubscribeMutation = getAzureCommonSecurityLog({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureSysLogComputer = () => {
        const unsubscribeMutation = getAzureSysLogComputer({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureProtectionStatus = () => {
        const unsubscribeMutation = getAzureProtectionStatus({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureSysLogProcessName = () => {
        const unsubscribeMutation = getAzureSysLogProcessName({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetAzureKubeMonAgentEvents = () => {
        const unsubscribeMutation = getAzureKubeMonAgentEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** the workaround from version 2.0 was to run this lifecycle on mount.
     * Create a separate fetch data script to check if the default date range
     * returns data. If nothing is returned, dispatch the "date range" according to
     * lastUpdate and "refetch" to true.
     *
     * NOTE: make sure you create a separate mutation for the same call if purpose
     * for fetching the data is different.
     *
     * It's best not to squeeze in new code especially with unwanted dispatches.
     */

    const unsubscribeCheckLatestData = () => {
        const unsubscribeMutation = checkLatestData({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const checkData = () => {
        unsubscribeCheckLatestData()

        let checkLatestDataPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card) {
            // we aren't doing token validation since another call
            // is doing it for us (getModal)
            const call = async () => {
                if (isMounted) {
                    const requestData: Omit<GetModalRequest, 'event_type'>
                    & TokenAuth = createRequestData(token.value)

                    checkLatestDataPromise = checkLatestData(requestData)
                }
            }
            call()
        }

        return () => {
            isMounted = false
            checkLatestDataPromise && checkLatestDataPromise.abort()
        }
    }

    const fetchModalHeaderData = () => {
        unsubscribeGetModalHeader()
        let getModalHeaderPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getModalHeaderPromise = getModalHeader(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getModalHeaderPromise && getModalHeaderPromise.abort()
        }
    }

    const fetchAzureUpdateData = () => {
        unsubscribeGetAzureUpdate()
        let getAzureUpdatePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureUpdatePromise = getAzureUpdate(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureUpdatePromise && getAzureUpdatePromise.abort()
        }
    }

    const fetchAzureSecurityEventData = () => {
        unsubscribeGetAzureSecurityEvent()
        let getAzureSecurityEventPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureSecurityEventPromise = getAzureSecurityEvent(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureSecurityEventPromise && getAzureSecurityEventPromise.abort()
        }
    }

    const fetchAzureEventTypesData = () => {
        unsubscribeGetAzureEventTypes()
        let getAzureEventTypesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureEventTypesPromise = getAzureEventTypes(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureEventTypesPromise && getAzureEventTypesPromise.abort()
        }
    }

    const fetchAzureKubeEventsData = () => {
        unsubscribeGetAzureKubeEvents()
        let getAzureKubeEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeEventsPromise = getAzureKubeEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeEventsPromise && getAzureKubeEventsPromise.abort()
        }
    }

    const fetchAzureAzureActivityData = () => {
        unsubscribeGetAzureAzureActivity()
        let getAzureAzureActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureAzureActivityPromise = getAzureAzureActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureAzureActivityPromise && getAzureAzureActivityPromise.abort()
        }
    }

    const fetchAzureCommonSecurityLogData = () => {
        unsubscribeGetAzureCommonSecurityLog()
        let getAzureCommonSecurityLogPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureCommonSecurityLogPromise = getAzureCommonSecurityLog(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureCommonSecurityLogPromise && getAzureCommonSecurityLogPromise.abort()
        }
    }

    const fetchAzureSysLogComputerData = () => {
        unsubscribeGetAzureSysLogComputer()
        let getAzureSysLogComputerPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureSysLogComputerPromise = getAzureSysLogComputer(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureSysLogComputerPromise && getAzureSysLogComputerPromise.abort()
        }
    }

    const fetchAzureProtectionStatusData = () => {
        unsubscribeGetAzureProtectionStatus()
        let getAzureProtectionStatusPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureProtectionStatusPromise = getAzureProtectionStatus(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureProtectionStatusPromise && getAzureProtectionStatusPromise.abort()
        }
    }

    const fetchAzureSysLogProcessName = () => {
        unsubscribeGetAzureSysLogProcessName()
        let getAzureSysLogProcessNamePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureSysLogProcessNamePromise = getAzureSysLogProcessName(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureSysLogProcessNamePromise && getAzureSysLogProcessNamePromise.abort()
        }
    }

    const fetchAzureKubeMonAgentEvents = () => {
        unsubscribeGetAzureKubeMonAgentEvents()
        let getAzureKubeMonAgentEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        if (modal.card && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetModalRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getAzureKubeMonAgentEventsPromise = getAzureKubeMonAgentEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getAzureKubeMonAgentEventsPromise && getAzureKubeMonAgentEventsPromise.abort()
        }
    }
    useEffect(() => {
        return fetchModalHeaderData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureUpdateData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureSecurityEventData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureEventTypesData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureKubeEventsData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureAzureActivityData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureCommonSecurityLogData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureSysLogComputerData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureProtectionStatusData()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureSysLogProcessName()
    }, [token.valid])
    useEffect(() => {
        return fetchAzureKubeMonAgentEvents()
    }, [token.valid])

    useEffect(() => {
        if (searchParams.refetch['modal.Azure.Header']) {
            return fetchModalHeaderData()
        }
    }, [searchParams.refetch['modal.Azure.Header']])

    useEffect(() => {
        if (searchParams.refetch['Azure.Update']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.Update',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureUpdateData()
        }
    }, [searchParams.refetch['Azure.Update']])

    useEffect(() => {
        if (searchParams.refetch['Azure.SecurityEvent']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.SecurityEvent',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureSecurityEventData()
        }
    }, [searchParams.refetch['Azure.SecurityEvent']])

    useEffect(() => {
        if (searchParams.refetch['Azure.EventTypes']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.EventTypes',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureEventTypesData()
        }
    }, [searchParams.refetch['Azure.EventTypes']])

    useEffect(() => {
        if (searchParams.refetch['Azure.KubeEvents']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.KubeEvents',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureKubeEventsData()
        }
    }, [searchParams.refetch['Azure.KubeEvents']])

    useEffect(() => {
        if (searchParams.refetch['Azure.AzureActivity']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.AzureActivity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureAzureActivityData()
        }
    }, [searchParams.refetch['Azure.AzureActivity']])

    useEffect(() => {
        if (searchParams.refetch['Azure.CommonSecurityLog']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.CommonSecurityLog',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureCommonSecurityLogData()
        }
    }, [searchParams.refetch['Azure.CommonSecurityLog']])

    useEffect(() => {
        if (searchParams.refetch['Azure.SysLog.Computer']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.SysLog.Computer',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureSysLogComputerData()
        }
    }, [searchParams.refetch['Azure.SysLog.Computer']])

    useEffect(() => {
        if (searchParams.refetch['Azure.ProtectionStatus']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.ProtectionStatus',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureProtectionStatusData()
        }
    }, [searchParams.refetch['Azure.ProtectionStatus']])

    useEffect(() => {
        if (searchParams.refetch['Azure.SysLog.ProcessName']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.SysLog.ProcessName',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureSysLogProcessName()
        }
    }, [searchParams.refetch['Azure.SysLog.ProcessName']])

    useEffect(() => {
        if (searchParams.refetch['Azure.KubeMonAgentEvents']) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Azure.KubeMonAgentEvents',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchAzureKubeMonAgentEvents()
        }
    }, [searchParams.refetch['Azure.KubeMonAgentEvents']])

    useEffect(() => {
        if (getModalHeaderMutation.isSuccess && searchParams.refetch['modal.Azure.Header']) {
            dispatch(setRefetch({
                key: 'modal.Azure.Header',
                value: false
            }))
        }
    }, [getModalHeaderMutation.isSuccess])

    useEffect(() => {
        if (getAzureUpdateMutation.isSuccess && searchParams.refetch['Azure.Update']) {
            dispatch(setRefetch({
                key: 'Azure.Update',
                value: false
            }))
        }
    }, [getAzureUpdateMutation.isSuccess])

    useEffect(() => {
        if (getAzureSecurityEventMutation.isSuccess &&
            searchParams.refetch['Azure.SecurityEvent']) {
            dispatch(setRefetch({
                key: 'Azure.SecurityEvent',
                value: false
            }))
        }
    }, [getAzureSecurityEventMutation.isSuccess])

    useEffect(() => {
        if (getAzureEventTypesMutation.isSuccess && searchParams.refetch['Azure.EventTypes']) {
            dispatch(setRefetch({
                key: 'Azure.EventTypes',
                value: false
            }))
        }
    }, [getAzureEventTypesMutation.isSuccess])

    useEffect(() => {
        if (getAzureKubeEventsMutation.isSuccess && searchParams.refetch['Azure.KubeEvents']) {
            dispatch(setRefetch({
                key: 'Azure.KubeEvents',
                value: false
            }))
        }
    }, [getAzureKubeEventsMutation.isSuccess])

    useEffect(() => {
        if (getAzureAzureActivityMutation.isSuccess &&
            searchParams.refetch['Azure.AzureActivity']) {
            dispatch(setRefetch({
                key: 'Azure.AzureActivity',
                value: false
            }))
        }
    }, [getAzureAzureActivityMutation.isSuccess])

    useEffect(() => {
        if (getAzureCommonSecurityLogMutation.isSuccess &&
            searchParams.refetch['Azure.CommonSecurityLog']) {
            dispatch(setRefetch({
                key: 'Azure.CommonSecurityLog',
                value: false
            }))
        }
    }, [getAzureCommonSecurityLogMutation.isSuccess])

    useEffect(() => {
        if (getAzureSysLogComputerMutation.isSuccess &&
            searchParams.refetch['Azure.SysLog.Computer']) {
            dispatch(setRefetch({
                key: 'Azure.SysLog.Computer',
                value: false
            }))
        }
    }, [getAzureSysLogComputerMutation.isSuccess])

    useEffect(() => {
        if (getAzureProtectionStatusMutation.isSuccess &&
            searchParams.refetch['Azure.ProtectionStatus']) {
            dispatch(setRefetch({
                key: 'Azure.ProtectionStatus',
                value: false
            }))
        }
    }, [getAzureProtectionStatusMutation.isSuccess])

    useEffect(() => {
        if (getAzureSysLogProcessNameMutation.isSuccess &&
            searchParams.refetch['Azure.SysLog.ProcessName']) {
            dispatch(setRefetch({
                key: 'Azure.SysLog.ProcessName',
                value: false
            }))
        }
    }, [getAzureSysLogProcessNameMutation.isSuccess])

    useEffect(() => {
        if (getAzureKubeMonAgentEventsMutation.isSuccess &&
            searchParams.refetch['Azure.KubeMonAgentEvents']) {
            dispatch(setRefetch({
                key: 'Azure.KubeMonAgentEvents',
                value: false
            }))
        }
    }, [getAzureKubeMonAgentEventsMutation.isSuccess])

    /** end of fetch script. */

    /** detailed dashboard stuff. */
    const setParams = () => {
        /** keep in mind that you want to get the startDate and endDate values
         * of this modal.
        */
        const params: Omit<DetailedSearchParams, 'refetch'> = {
            /** use currentParams */
            ranges: {
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            },
            card: {
                details: {
                    ...modal.card.details,
                    colorType: getModalHeaderMutation.data?.data.colorType ||
                            modal.card.details.colorType || 'grey'
                },
                deviceid: modal.card.deviceid,
                in_face: modal.card.in_face,
                service_type: modal.card.service_type
            },
            q: '',
            boolList: []
        }

        const newRoute = _.split(AZURE_DETAILED_DASHBOARD_ROUTES.MAIN.link, ':deviceid')
        setAzureDetailedDashboardParams(params)
        dispatch(push([
            newRoute[0],
            modal.card.deviceid,
            newRoute[1]
        ].join('')))
    }
    /** redirect button to detailed dashboard */

    const DetailedDashboardButton = (
        <span
            onClick={() => {
                setParams()
            }}
            className={'icon pointer mb-2 d-inline-block ms-2'}
        >
            <FaDirections/>
        </span>
    )

    useEffect(() => {
        checkData()
    }, [])

    useEffect(() => {
        const data = checkLatestDataMutation.data
        /** this lifecycle will trigger twice. 1 onmount AND 2 when you actually fetch the data.
         * to fix this, you want the data to be defined.
         */
        if (data && data?.data.heading === '0 Total Events') {
            const lastUpdate = Number(modal.card.details.lastUpdate || 0)

            if (isValid(lastUpdate) && lastUpdate > 0) {
                // do dispatch.
                const fromTime = getUnixTime(
                    sub(
                        fromUnixTime(lastUpdate),
                        { days: 1 }
                    )
                )
                dispatch(setStartDate(fromTime))
                dispatch(setEndDate(lastUpdate))

                const keys: EventType[] = [
                    'Azure.AzureActivity', 'Azure.CommonSecurityLog',
                    'Azure.EventTypes', 'Azure.KubeEvents', 'Azure.KubeMonAgentEvents',
                    'Azure.ProtectionStatus', 'Azure.SecurityEvent',
                    'Azure.SysLog.Computer', 'Azure.SysLog.ProcessName', 'Azure.Update',
                    'modal.Azure.Header'
                ]

                _.forEach(keys, (key) => {
                    dispatch(setRefetch({
                        key: key,
                        value: true
                    }))
                })

                /** display toast message. */
                toast.warning([
                    MESSAGE.DATA.EMPTY_DATA,
                    MESSAGE.DATA.LASTUPDATE_FETCH
                ].join(' '), { ...TOASTIFY_DEFAULT_OPTIONS })
            } else {
                toast.error(MESSAGE.DATA.LASTUPDATE_INVALID, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [checkLatestDataMutation.data])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    const RefreshWarningContent = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            dispatch(setRefetch({
                key: 'modal.Azure.Header',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.ProtectionStatus',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.SysLog.Computer',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.CommonSecurityLog',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.AzureActivity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.KubeEvents',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.EventTypes',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.SecurityEvent',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.Update',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.SysLog.ProcessName',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Azure.KubeMonAgentEvents',
                value: true
            }))
        }

        return <div>
            <div className={'row'}>
                <small className={'col'}>
                    {MONITOR_MESSAGE.DETAILED_DASHBOARD_REFRESH_ALL}
                </small>
            </div>
            <div className={'row flex-row-reverse mt-1'}>
                <div className={'col-auto'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        onClick={confirmClickEvent}
                    >{TEXT.YES}</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        mode={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            toggleConfirm(false)
                        }}
                    >{TEXT.NO}</Button>
                </div>
            </div>
        </div>
    }, [showConfirm])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getModalHeaderMutation.isLoading ||
            getAzureUpdateMutation.isLoading ||
            getAzureSecurityEventMutation.isLoading ||
            getAzureEventTypesMutation.isLoading ||
            getAzureKubeEventsMutation.isLoading ||
            getAzureAzureActivityMutation.isLoading ||
            getAzureCommonSecurityLogMutation.isLoading ||
            getAzureSysLogComputerMutation.isLoading ||
            getAzureProtectionStatusMutation.isLoading ||
            getAzureSysLogProcessNameMutation.isLoading ||
            getAzureKubeMonAgentEventsMutation.isLoading

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={RefreshWarningContent}
                onClickOutside={() => toggleConfirm(false)}
            >
                <span
                    className={[
                        'icon mb-2 d-inline-block ms-2',
                        disableRefresh ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }} >
                    <MdRefresh />
                </span>
            </Tippy>
        )
    }, [
        showConfirm,
        RefreshWarningContent,
        getModalHeaderMutation,
        getAzureUpdateMutation,
        getAzureSecurityEventMutation,
        getAzureEventTypesMutation,
        getAzureKubeEventsMutation,
        getAzureAzureActivityMutation,
        getAzureCommonSecurityLogMutation,
        getAzureSysLogComputerMutation,
        getAzureProtectionStatusMutation,
        getAzureSysLogProcessNameMutation,
        getAzureKubeMonAgentEventsMutation
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={AZURE_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {AZURE_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )

        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getModalHeaderMutation.data
        const hasNoData = data?.data.heading === '0 Total Events'

        return <div>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */

                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: modal.card.deviceid,
                                        inFace: modal.card.in_face,
                                        serviceType: modal.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end
                                    }
                                },
                                printOptions: printOptions
                            },
                            count: 0
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        modal.card,
        getModalHeaderMutation,
        queueId,
        queues,
        printOptions,
        currentParams
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.REDIRECTS.AZURE_DETAILED}</div>}>
                    <div className={'d-inline-block'}>{DetailedDashboardButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                    <div className={'d-inline-block'}>{RefreshButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        DetailedDashboardButton,
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        PrintOptionsButton
    ])

    const Statcard = useMemo(() => {
        /** hold higher priority to modal.Azure.Header data
         * than detailed card data.
         */

        type Header = ModalHeaderResponse['data'] & { title: string }
        const data: Header = {
            title: modal.card.details.title || '',
            ...getModalHeaderMutation.data?.data || {
                line_1: modal.card.details.line_1 || '',
                line_2: modal.card.details.line_2 || '',
                heading: modal.card.details.heading || '',
                colorType: modal.card.details.colorType || 'grey'
            }
        }

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{AZURE_MESSAGE.FETCH.MODAL_HEADER}</span>
                </SpinnerContainer>
            </small>
        )

        const Heading = (
            <Dashboard.Heading
                color={data.colorType || 'darkGrey'}
                activeMode={mode}
                activeStyle={style}
                className={'d-block mb-3'}
            >
                {data.heading || 'NO HEADING'}
            </Dashboard.Heading>
        )

        const Subtitle1 = (
            <Dashboard.Subtitle className={'d-block mb-1'}>
                {data.line_1 || 'NO LINE 1'}
            </Dashboard.Subtitle>
        )

        const Subtitle2 = (
            <Dashboard.Subtitle className={'d-block mb-2'}>
                {data.line_2 || 'NO LINE 2'}
            </Dashboard.Subtitle>
        )

        const Footer1 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ MONITOR_TEXT.CARD.DEVICEID }: ${ modal.card.deviceid }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {`${ MONITOR_TEXT.CARD.LOCATION }
            : ${ modal.card.details.location || 'NO LOCATION' }`}
                </Dashboard.Footer>
            </div>
        )

        /** to get timezone, use Intl.DateTimeFormat
         * ALSO, it is preferrable to retrieve the timezone directly from the UNICODE
         * CLDR and not IANA to avoid misinterpretations from different users,
         */
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const Footer2 = (
            <div className={'row'}>
                <Dashboard.Footer className={'col mb-1'}>
                    {`${ modal.card.details.state || 'NO STATE' }
                    : ${ modal.card.details.state_details ||
                    'NO STATE DETAILS' }`}
                </Dashboard.Footer>
                <Dashboard.Footer className={'col text-end mb-1'}>
                    {
                        [
                            format(
                                fromUnixTime(currentParams.ranges.start),
                                DATE_FORMAT_TIME
                            ),
                            '-',
                            format(
                                fromUnixTime(currentParams.ranges.end),
                                DATE_FORMAT_TIME
                            ),
                            timezone
                        ].join(' ')
                    }
                </Dashboard.Footer>
            </div>
        )

        const content = (
            <div className={'px-3 py-2'}
            >
                {Heading}
                {Subtitle1}
                {Subtitle2}
                {Footer1}
                {Footer2}
            </div>
        )

        return (
            <Container bgIndex={2} >
                {
                    !getModalHeaderMutation.isLoading
                        ? getModalHeaderMutation.isSuccess
                            ? content
                            : JSON.stringify(getModalHeaderMutation.error)
                        : LoadingContent
                }
            </Container>
        )
    }, [
        modal.card,
        getModalHeaderMutation,
        currentParams
    ])

    useEffect(() => {
        /** update statcard color. */
        const data = getModalHeaderMutation.data?.data
        dispatch(changeModalColor({
            modal: modal,
            colorType: data?.colorType || modal.card.details.colorType || 'grey'
        }))
    }, [
        modal.card,
        getModalHeaderMutation
    ])

    /** now we create our charts. */
    const AzureProtectionStatus = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.PROTECTION_STATUS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.ProtectionStatus',
                        value: !fixedCollapsibles['Azure.ProtectionStatus']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.ProtectionStatus'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.ProtectionStatus'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.ProtectionStatus',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.ProtectionStatus'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.ProtectionStatus'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.ProtectionStatus',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.ProtectionStatus'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureProtectionStatusMutation.data}
                isLoading={getAzureProtectionStatusMutation.isLoading}
                isSuccess={getAzureProtectionStatusMutation.isSuccess}
                error={getAzureProtectionStatusMutation.error}
            />
        </div>
    }, undefined)

    const AzureUpdate = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.UPDATE}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.Update',
                        value: !fixedCollapsibles['Azure.Update']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.Update'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.Update'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.Update',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.Update'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.Update'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.Update',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.Update'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureUpdateMutation.data}
                isLoading={getAzureUpdateMutation.isLoading}
                isSuccess={getAzureUpdateMutation.isSuccess}
                error={getAzureUpdateMutation.error}
            />
        </div>
    }, undefined)

    const AzureCommonSecurityLog = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.COMMON_SECURITY_LOG}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.CommonSecurityLog',
                        value: !fixedCollapsibles['Azure.CommonSecurityLog']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.CommonSecurityLog'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.CommonSecurityLog'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.CommonSecurityLog',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.CommonSecurityLog'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.CommonSecurityLog'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.CommonSecurityLog',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.CommonSecurityLog'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureCommonSecurityLogMutation.data}
                isLoading={getAzureCommonSecurityLogMutation.isLoading}
                isSuccess={getAzureCommonSecurityLogMutation.isSuccess}
                error={getAzureCommonSecurityLogMutation.error}
            />
        </div>
    }, undefined)

    const AzureAzureActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.AZURE_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.AzureActivity',
                        value: !fixedCollapsibles['Azure.AzureActivity']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.AzureActivity'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.AzureActivity'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.AzureActivity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.AzureActivity'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                    // no need to set the title and bucket3 since
                    // the component will render either of the two.
                        if (chartZooms['Azure.AzureActivity'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.AzureActivity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.AzureActivity'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureAzureActivityMutation.data}
                isLoading={getAzureAzureActivityMutation.isLoading}
                isSuccess={getAzureAzureActivityMutation.isSuccess}
                error={getAzureAzureActivityMutation.error}
            />
        </div>
    }, undefined)

    const AzureSecurityEvent = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.SECURTIY_EVENT}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.SecurityEvent',
                        value: !fixedCollapsibles['Azure.SecurityEvent']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.SecurityEvent'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.SecurityEvent'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.SecurityEvent',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.SecurityEvent'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.SecurityEvent'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.SecurityEvent',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.SecurityEvent'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureSecurityEventMutation.data}
                isLoading={getAzureSecurityEventMutation.isLoading}
                isSuccess={getAzureSecurityEventMutation.isSuccess}
                error={getAzureSecurityEventMutation.error}
            />
        </div>
    }, undefined)

    const AzureKubeEvents = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.KUBE_EVENTS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.KubeEvents',
                        value: !fixedCollapsibles['Azure.KubeEvents']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.KubeEvents'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.KubeEvents'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.KubeEvents',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.KubeEvents'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.KubeEvents'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.KubeEvents',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.KubeEvents'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureKubeEventsMutation.data}
                isLoading={getAzureKubeEventsMutation.isLoading}
                isSuccess={getAzureKubeEventsMutation.isSuccess}
                error={getAzureKubeEventsMutation.error}
            />
        </div>
    }, undefined)

    const AzureSysLogComputer = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.SYS_LOG_COMPUTER}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.SysLog.Computer',
                        value: !fixedCollapsibles['Azure.SysLog.Computer']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.SysLog.Computer'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.SysLog.Computer'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.SysLog.Computer',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.SysLog.Computer'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.SysLog.Computer'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.SysLog.Computer',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.SysLog.Computer'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureSysLogComputerMutation.data}
                isLoading={getAzureSysLogComputerMutation.isLoading}
                isSuccess={getAzureSysLogComputerMutation.isSuccess}
                error={getAzureSysLogComputerMutation.error}
            />
        </div>
    }, undefined)

    const AzureEventTypes = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.EVENT_TYPES}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.EventTypes',
                        value: !fixedCollapsibles['Azure.EventTypes']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.EventTypes'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.EventTypes'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.EventTypes',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.EventTypes'].zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.EventTypes'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.EventTypes',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.EventTypes'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureEventTypesMutation.data}
                isLoading={getAzureEventTypesMutation.isLoading}
                isSuccess={getAzureEventTypesMutation.isSuccess}
                error={getAzureEventTypesMutation.error}
            />
        </div>
    }, undefined)

    const AzureSysLogProcessName = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.SYS_LOG_PROCESS_NAME}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.SysLog.ProcessName',
                        value: !fixedCollapsibles['Azure.SysLog.ProcessName']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.SysLog.ProcessName'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.SysLog.ProcessName'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.SysLog.ProcessName',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.SysLog.ProcessName'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.SysLog.ProcessName'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.SysLog.ProcessName',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.SysLog.ProcessName'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureSysLogProcessNameMutation.data}
                isLoading={getAzureSysLogProcessNameMutation.isLoading}
                isSuccess={getAzureSysLogProcessNameMutation.isSuccess}
                error={getAzureSysLogProcessNameMutation.error}
            />
        </div>
    }, undefined)

    const AzureKubeMonAgentEvents = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {AZURE_TEXT.SECTIONS.KUBE_MON_AGENT_EVENTS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Azure.KubeMonAgentEvents',
                        value: !fixedCollapsibles['Azure.KubeMonAgentEvents']
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles['Azure.KubeMonAgentEvents'] ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch['Azure.KubeMonAgentEvents'] ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Azure.KubeMonAgentEvents',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms['Azure.KubeMonAgentEvents'].zoomLevel === 0
                            ? 'disabled'
                            : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms['Azure.KubeMonAgentEvents'].zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Azure.KubeMonAgentEvents',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <AzureBarChart
                eventType={'Azure.KubeMonAgentEvents'}
                modal={modal}
                addModal={addModal}
                closeModal={closeModal}
                changeModalColor={changeModalColor}
                data={getAzureKubeMonAgentEventsMutation.data}
                isLoading={getAzureKubeMonAgentEventsMutation.isLoading}
                isSuccess={getAzureKubeMonAgentEventsMutation.isSuccess}
                error={getAzureKubeMonAgentEventsMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        return () => {
            dispatch(resetAzureMain())
        }
    }, [])

    /** reset data on unmount */
    return (
        <div>
            {/* header render */}
            <div className={'row justify-content-between mb-3'}>
                <div className={'col mb-2'}>
                    {modal.card.details.title || ''}
                </div>
                <div className={'col-auto pe-5 pe-lg-2'}>
                    {ActionButtons}
                </div>
            </div>
            {/* display statcard data */}
            <div className={'row mb-3'}>
                <div className={'col'}>
                    {Statcard}
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {AzureEventTypes}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {AzureCommonSecurityLog}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {AzureSysLogComputer}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {AzureSysLogProcessName}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {AzureProtectionStatus}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {AzureAzureActivity}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {AzureKubeEvents}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {AzureKubeMonAgentEvents}
                    </div>
                </div>
            </div>

            <div className={'min-width-fix'}>
                <div className={'row  mb-2'}>
                    <div className={'col-md-6 col-12'}>
                        {AzureSecurityEvent}
                    </div>
                    <div className={'col-md-6 col-12'}>
                        {AzureUpdate}
                    </div>
                </div>
            </div>

            <Footer.Main className={'justify-content-center mb-3 row'}>
                <div
                    className={'col-12 col-md-6'}
                >
                    <Footer.DetailedDashboardButton
                        onClick={() => {
                            setParams()
                        }}
                        className={'pointer py-4 text-center text-uppercase'}
                    >
                        {MONITOR_TEXT.CARD.DETAILED_DASHBOARD}
                    </Footer.DetailedDashboardButton>
                </div>
            </Footer.Main>

        </div>
    )
}

export default AzureModal

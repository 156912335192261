import { CHART_ZOOM } from '@constants/dashboard/monitor'
import {
    Doc,
    SearchParams
} from '@interfaces/dashboard/monitor'
import {
    AzureSearchParams,
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    AzureDetailKeys
} from '@interfaces/dashboard/soc/azure/main'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

/** even if they are in the main modal, you want to individually refresh these charts. */
export const EVENT_TYPES: EventType[] = ['Azure.AzureActivity', 'Azure.CommonSecurityLog',
    'Azure.EventTypes', 'Azure.KubeEvents', 'Azure.KubeMonAgentEvents', 'Azure.ProtectionStatus',
    'Azure.SecurityEvent', 'Azure.SysLog.Computer', 'Azure.SysLog.ProcessName', 'Azure.Update',
    'modal.Azure.Header']

export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

/** modal header doesn't have a chart so remove it. */
export const DEFAULT_CHART_ZOOMS = _.zipObject(
    _.without(EVENT_TYPES, 'modal.Azure.Header'),
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_MAIN_SEARCH_PARAMS: AzureSearchParams = {
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: _.cloneDeep(DEFAULT_REFETCH)
}

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Azure call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MODAL_HEADER: 'Fetching Header data...',
        AZURE_ACTIVITY: 'Fetching Azure Activity data...',
        COMMON_SECURITY_LOG: 'Fetching Common Security Log data...',
        EVENT_TYPES: 'Fetching Event Types data...',
        KUBE_EVENTS: 'Fetching Kube Events data...',
        KUBE_MON_AGENT_EVENTS: 'Fetching Kube Mon Agent Events data...',
        PROTECTION_STATUS: 'Fetching Protection Status data...',
        SECURTIY_EVENT: 'Fetching Security Event data...',
        SYS_LOG_COMPUTER: 'Fetching Sys Log Computer data...',
        SYS_LOG_PROCESS_NAME: 'Fetching Sys Log Process Name data...',
        UPDATE: 'Fetching Update data...',
        MODAL_DETAILS: 'Fetching modal details...',
        DATA_ID: 'Fetching data id...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        MODAL_HEADER: 'Header',
        AZURE_ACTIVITY: 'Azure Activity',
        COMMON_SECURITY_LOG: 'Common Security Log',
        EVENT_TYPES: 'Event Types',
        KUBE_EVENTS: 'Kube Events',
        KUBE_MON_AGENT_EVENTS: 'Kube Mon Agent Events',
        PROTECTION_STATUS: 'Protection Status',
        SECURTIY_EVENT: 'Security Events',
        SYS_LOG_COMPUTER: 'System Log Computer',
        SYS_LOG_PROCESS_NAME: 'System Log Process Name',
        UPDATE: 'Update'

    }
}

/** for charts. */
// resourcegroup
export const AZURE_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Resource Group',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

// computer
export const COMMON_SECURITY_LOG_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Computer',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]
export const KUBE_EVENTS_COLUMNS: {label: string, value: keyof Doc }[] = COMMON_SECURITY_LOG_COLUMNS
export const KUBE_MON_AGENT_EVENTS_COLUMNS: {label: string
    , value: keyof Doc }[] = COMMON_SECURITY_LOG_COLUMNS
export const PROTECTION_STATUS_COLUMNS: {label: string
    , value: keyof Doc }[] = COMMON_SECURITY_LOG_COLUMNS
export const SECURTIY_EVENT_COLUMNS: {label: string
    , value: keyof Doc }[] = COMMON_SECURITY_LOG_COLUMNS
export const SYS_LOG_COMPUTER_COLUMNS: {label: string
    , value: keyof Doc }[] = COMMON_SECURITY_LOG_COLUMNS
export const UPDATE_COLUMNS: {label: string
    , value: keyof Doc }[] = COMMON_SECURITY_LOG_COLUMNS

// processname
export const SYS_LOG_PROCESS_NAME_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Process Name',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

// type
export const EVENT_TYPES_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Type',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const AZURE_DETAIL_KEYS: AzureDetailKeys[] = [
]

import { RootState } from '@app/store'
import { tryParseJSON } from '@constants/main/method'
import {
    NavigationState,
    NavigationTab
} from '@interfaces/main/root'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import _ from 'lodash'

const getFromLocalStorage: () => NavigationState = () => {
    /** Coming from print pdf operations using sessionStorage instead of localStorage.
     * If localStorage token is undefined, get it from sessionStorage otherwise
     * initialize an empty string.
     */
    const tokenObj = tryParseJSON(
        localStorage.getItem('headerNavState') || ''
    ) as unknown as NavigationState

    return {
        activeTabs: tokenObj.activeTabs || []
    }
}

const initialState: NavigationState = getFromLocalStorage()

export const slice = createSlice({
    name: 'headerNav',
    initialState: initialState,
    reducers: {
        addNewTab: (state: NavigationState, action: PayloadAction<NavigationTab>) => {
            let found = _.find(state.activeTabs, (obj) => {
                return obj.link === action.payload.link
            })

            if (!found) {
                state.activeTabs.push({
                    link: action.payload.link,
                    name: action.payload.name
                })
            } else {
                found = {
                    link: action.payload.link,
                    name: action.payload.name
                }
            }
        },
        removeTab: (state: NavigationState, action: PayloadAction<NavigationTab>) => {
            _.remove(state.activeTabs, obj => {
                return (
                    obj.link === action.payload.link
                )
            })
        },
        storeActiveTabs: (state: NavigationState) => {
            localStorage.setItem('headerNavState', JSON.stringify(state.activeTabs))
        },
        resetActiveTabs: (state: NavigationState) => {
            state.activeTabs = initialState.activeTabs
        }
    }
})

export const { addNewTab, removeTab, storeActiveTabs, resetActiveTabs } = slice.actions

export const selectActiveTabs = (state: RootState) => state.headerNav.activeTabs

export default slice.reducer

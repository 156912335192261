
import { MutationContext } from '@root/MutationProvider'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import {
    useGetBoxStatusMutation
} from '@apis/watchdog/configuration/box-api'
import {
    useGetDevicesMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    DEBOUNCE_SEARCH_TIME,
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { Box } from '@interfaces/watchdog/configuration/box'
import {
    BoxRecord
} from '@interfaces/watchdog/soc-data/event'
import { LabelValuePair } from '@interfaces/main/root'
import { selectToken } from '@slices/main/token'
import {
    selectArrange,
    selectBoxData,
    selectBoxname,
    selectFilter,
    selectRefetch,
    selectSort,
    setArrange,
    setBoxData,
    setBoxname,
    setFilter,
    setRefetch,
    setSort
} from '@slices/watchdog/configuration/box'
import {
    ARRANGE_OPTIONS,
    FILTER_OPTIONS,
    SORT_OPTIONS,
    TEXT as BOX_TEXT
} from '@constants/watchdog/configuration/box'
import {
    ALL_DEVICES_OPTION
} from '@constants/watchdog/soc-data/event'
import {
    selectStyle,
    selectMode
} from '@slices/main/settings'
import {
    SearchRow,
    Button,
    Text,
    Container,
    DashboardStyledComponents as Dashboard
} from '@styles/components'

const BoxMenu = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    const boxname = useAppSelector(selectBoxname)
    const sort = useAppSelector(selectSort)
    const filter = useAppSelector(selectFilter)
    const arrange = useAppSelector(selectArrange)
    const refetch = useAppSelector(selectRefetch)
    const boxData = useAppSelector(selectBoxData)

    const [getBoxStatus, getBoxStatusMutation] = useGetBoxStatusMutation()
    const [getDevices, getDevicesMutation] = useGetDevicesMutation()

    const unsubscribeGetBoxStatus = () => {
        const unsubscribeMutation = getBoxStatus({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetDevices = () => {
        const unsubscribeMutation = getDevices({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetBoxStatus()
        let getBoxStatusPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getBoxStatusPromise = getBoxStatus({
                        authToken: newToken
                    })
                }
            }
        }
        call()
        return () => {
            isMounted = false
            getBoxStatusPromise && getBoxStatusPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        unsubscribeGetDevices()
        let getDevicesPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    getDevicesPromise = getDevices({
                        authToken: newToken
                    })
                }
            }
        }
        call()
        return () => {
            isMounted = false
            getDevicesPromise && getDevicesPromise.abort()
        }
    }, [token.valid])

    useEffect(() => {
        if (refetch) {
            return fetchData()
        }
    }, [refetch])

    useEffect(() => {
        if (getBoxStatusMutation.isSuccess && refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getBoxStatusMutation.isSuccess
    ])

    /** no filters or pagination here. just a constrained sort and arrange. */
    const setData = useDebouncedCallback(() => {
        const data = getBoxStatusMutation.data?.boxStatus || [] as Box[]

        // custom filter by boxname AND filter.
        const filteredData = _.filter(data, (obj) => {
            // check if the deviceid matches or if null (ALL), return true.
            const foundDeviceid = (
                boxname.deviceid !== ALL_DEVICES_OPTION.deviceid
                    ? obj.deviceid === boxname.deviceid
                    : true
            )
            // check if the value of the selected filter string is TRUE.
            if (filter === 'online') {
                return obj.online === true && foundDeviceid
            } else if (filter === 'available') {
                return obj.available === true && foundDeviceid
            } else {
                return foundDeviceid
            }
        })

        let orderedData: Box[] = []

        if (sort === 'boxStatus') {
            orderedData = _.sortBy(filteredData, (box) => {
                switch (box.boxStatus) {
                    case 'green': return 0
                    case 'yellow': return 1
                    case 'amber-2': return 2
                    case 'amber-1': return 3
                    case 'red': return 4
                    case 'grey': return 5
                    default: return 6
                }
            })
            orderedData = arrange === 'desc'
                ? _.reverse(orderedData)
                : orderedData
        } else {
            orderedData = _.orderBy(filteredData, [sort], [arrange]) as Box[]
        }

        dispatch(setBoxData(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    useEffect(() => {
        setData()
    }, [
        getBoxStatusMutation.data,
        boxname,
        filter,
        sort,
        arrange
    ])

    useEffect(() => {
        if (getBoxStatusMutation.error) {
            console.error(getBoxStatusMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getBoxStatusMutation.error])

    useEffect(() => {
        if (getDevicesMutation.error) {
            console.error(getDevicesMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDevicesMutation.error])

    const BoxnameInput = useMemo(() => {
        let records = [] as BoxRecord[]
        let selection = [] as LabelValuePair[]
        let deviceid = '' as string

        if (boxname) {
            deviceid = boxname.deviceid
        }

        if (getDevicesMutation.data) {
            records = getDevicesMutation.data.records
            selection = _.concat([{
                label: ALL_DEVICES_OPTION.deviceid,
                value: ALL_DEVICES_OPTION.deviceid
            }], _.map(getDevicesMutation.data.records, record => {
                return {
                    label: record.deviceid,
                    value: record.deviceid
                }
            }))
        }

        const onChange = (e: any) => {
            if (e) {
                // had to add a case where to set to null.

                if (e.value !== ALL_DEVICES_OPTION.deviceid) {
                    const found = _.find(records, record => {
                        return record.deviceid === e.value
                    })

                    if (found) {
                        dispatch(setBoxname(found))
                    }
                } else {
                    dispatch(setBoxname(ALL_DEVICES_OPTION))
                }
            }
        }

        return (
            <Select
                id={BOX_TEXT.SEARCH.BOXNAME.ID}
                options={selection}
                value={_.find(
                    selection,
                    (e) => e.value === deviceid
                )}
                onChange={onChange}
                styles={{
                    ...reactSelect.styles
                }}
                theme={reactSelect.theme}
                borderOnly={'none'}
                removePadding={true}
            />
        )
    }, [
        getDevicesMutation.data,
        boxname
    ])

    const SortInput = useMemo(() => {
        const onChange = (e: any) => {
            if (e) {
                const found = _.find(SORT_OPTIONS, sort => {
                    return sort.value === e.value
                })

                if (found) {
                    dispatch(setSort(found.value))
                }
            }
        }

        return (
            <Select
                id={BOX_TEXT.SEARCH.SORT.ID}
                options={SORT_OPTIONS}
                value={_.find(
                    SORT_OPTIONS,
                    (e) => e.value === sort
                )}
                onChange={onChange}
                styles={{
                    ...reactSelect.styles
                }}
                theme={reactSelect.theme}
                borderOnly={'none'}
                removePadding={true}
            />
        )
    }, [
        getDevicesMutation.data,
        sort
    ])

    const FilterInput = useMemo(() => {
        const onChange = (e: any) => {
            if (e) {
                const found = _.find(FILTER_OPTIONS, filter => {
                    return filter.value === e.value
                })

                if (found) {
                    dispatch(setFilter(found.value))
                }
            }
        }

        return (
            <Select
                id={BOX_TEXT.SEARCH.FILTER.ID}
                options={FILTER_OPTIONS}
                value={_.find(
                    FILTER_OPTIONS,
                    (e) => e.value === filter
                )}
                onChange={onChange}
                styles={{
                    ...reactSelect.styles
                }}
                theme={reactSelect.theme}
                borderOnly={'none'}
                removePadding={true}
            />
        )
    }, [
        getDevicesMutation.data,
        filter
    ])

    const ArrangeInput = useMemo(() => {
        const onChange = (e: any) => {
            if (e) {
                const found = _.find(ARRANGE_OPTIONS, arrange => {
                    return arrange.value === e.value
                })

                if (found) {
                    dispatch(setArrange(found.value))
                }
            }
        }

        return (
            <Select
                id={BOX_TEXT.SEARCH.ARRANGE.ID}
                options={ARRANGE_OPTIONS}
                value={_.find(
                    ARRANGE_OPTIONS,
                    (e) => e.value === arrange
                )}
                onChange={onChange}
                styles={{
                    ...reactSelect.styles
                }}
                theme={reactSelect.theme}
                borderOnly={'none'}
                removePadding={true}
            />
        )
    }, [
        getDevicesMutation.data,
        arrange
    ])

    const refreshByClick = () => {
        dispatch(setRefetch(true))
    }

    const SearchInterface = useMemo(() => {
        return (
            <Container className={'pb-2 pt-1 px-2 mt-1'}>
                <div className={'align-items-center row'}>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <label
                                className={'small col-4 pe-0'}
                                htmlFor={BOX_TEXT.SEARCH.BOXNAME.ID}
                            >
                                {BOX_TEXT.SEARCH.BOXNAME.LABEL}
                            </label>
                            <div className={'col pe-4'}>
                                <SearchRow className={'align-items-center row'}>
                                    {BoxnameInput}
                                </SearchRow>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <label
                                className={'small col-4 pe-0'}
                                htmlFor={BOX_TEXT.SEARCH.SORT.ID}
                            >
                                {BOX_TEXT.SEARCH.SORT.LABEL}
                            </label>
                            <div className={'col pe-4'}>
                                <SearchRow className={'align-items-center row'}>
                                    {SortInput}
                                </SearchRow>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'align-items-center row'}>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <label
                                className={'small col-4 pe-0'}
                                htmlFor={BOX_TEXT.SEARCH.FILTER.ID}
                            >
                                {BOX_TEXT.SEARCH.FILTER.LABEL}
                            </label>
                            <div className={'col pe-4'}>
                                <SearchRow className={'align-items-center row'}>
                                    {FilterInput}
                                </SearchRow>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <label
                                className={'small col-4 pe-0'}
                                htmlFor={BOX_TEXT.SEARCH.ARRANGE.ID}
                            >
                                {BOX_TEXT.SEARCH.ARRANGE.LABEL}
                            </label>
                            <div className={'col pe-4'}>
                                <SearchRow className={'align-items-center row'}>
                                    {ArrangeInput}
                                </SearchRow>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'align-items-center row mt-2'}>
                    <div className={'col-12 col-md'}>
                    </div>
                    <div className={'col-12 col-md'}>
                        <div className={'row align-items-center'}>
                            <div className={'col-4 pe-0'} ></div>
                            <div className={'col px-0'}>
                                <Button
                                    mode={'primary'}
                                    size={'sm'}
                                    onClick={refreshByClick}
                                >
                                    {BOX_TEXT.SEARCH.SEARCH}

                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }, [
        BoxnameInput,
        SortInput,
        FilterInput,
        ArrangeInput
    ])

    return (
        <div>
            {/* adding search interface here. */}
            <div className={'align-items-center row mt-1'}>
                <div className={'col-12'}>
                    {SearchInterface}
                </div>
            </div>

            <div className={'justify-content-center row mt-3'}>
                {/* map box data to cards */}
                {
                    _.map(boxData, (box, index) => {
                        const key = [
                            'dashboard-card-', index
                        ].join('')

                        return (
                            <Dashboard.Container
                                size={'sm'}
                                key={key}
                                className={'col-auto mb-2'}
                            >
                                <Dashboard.Card
                                    color={box.boxStatus}
                                    activeMode={mode}
                                    activeStyle={style}
                                >
                                    <Text size={'xs'} className={'d-block mb-1'}>
                                        {box.boxData}
                                    </Text>
                                    <Text size={'xs'} className={'d-block text-right'}>
                                        {box.deviceid}
                                    </Text>
                                </Dashboard.Card>
                            </Dashboard.Container>
                        )
                    })
                }
            </div>

        </div>
    )
}
export default BoxMenu

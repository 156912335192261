import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    paginate,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    COMPARISON_OPERATORS,
    DATE_FORMAT_TIME,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT
} from '@constants/main/root'
import {
    ACTIONS,
    TEXT as EVENT_TEXT,
    KEYS,
    TABLE_COL_SPAN
} from '@constants/watchdog/soc-data/event'
import {
    ComparisonOperator
} from '@interfaces/main/root'
import {
    EventModal,
    EventObj,
    EventObjKeys
} from '@interfaces/watchdog/soc-data/event'
import { MutationContext } from '@root/MutationProvider'
import {
    addFilter,
    removeFilter,
    removeFilters,
    resetEvent,
    selectTableData,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setFilterKeys,
    setFilterValues,
    setFiltered,
    setPage,
    setPaginated,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    toggleFilterInterface
} from '@slices/watchdog/soc-data/event/group'
import {
    addEventToMultiple,
    emptyEventGroup,
    removeEventFromMultiple,
    selectMultipleArchive
} from '@slices/watchdog/soc-data/event/main'
import {
    Button,
    FilterCell,
    HoverFilter,
    SearchRow,
    Table,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format
} from 'date-fns'
import flatten from 'flat'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdManageSearch
} from 'react-icons/md'
import Select from 'react-select'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

/** first component to attempt table pagination data */

const GroupedEventData = ({ modal, addModal, closeModal } : {
    modal: EventModal,
    addModal: ActionCreatorWithPayload<EventModal, string>,
    closeModal: ActionCreatorWithPayload<EventModal, string>
}) => {
    const rootContext = useContext(MutationContext)
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const multipleArchive = useAppSelector(selectMultipleArchive)

    const tableData = useAppSelector(selectTableData)
    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        /** displays each row of data from EventGroup[1].data */

        const cellCount = TABLE_COL_SPAN + tableData.columns.length + ACTIONS.length

        const cellBody = (
            dataObject: EventObj,
            property: keyof EventObj
        ) => {
            /** when adding use cases for including/excluding a filter
             * codebase was lengthy and developer didn't want that.
             * only thing different was the not property. use a loop
             * also if you want to add more than one filter in each case.
             */

            let cellContent: EventObj[keyof EventObj] = ''
            let rawValue: EventObj[keyof EventObj] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'timestamp':
                    cellContent = format(new Date(dataObject.timestamp), DATE_FORMAT_TIME)
                    rawValue = dataObject.timestamp
                    break

                case 'src_ip':
                    cellContent = [
                        dataObject.src_ip, ' ',
                        dataObject.protocol, ':',
                        dataObject.src_port].join('')
                    break

                case 'dest_ip':
                    cellContent = [
                        dataObject.dest_ip, ' ',
                        dataObject.protocol, ':',
                        dataObject.dest_port].join('')
                    break

                default:
                    rawValue = cellContent = dataObject[property]
                    break
            }

            const addMultipleFilters = (
                not:boolean,
                filters:{
                    sort: keyof EventObj, value: string | number
                }[]
            ) => {
                _.forEach(filters, (obj) => {
                    dispatch(addFilter({
                        not: not,
                        sort: obj.sort,
                        value: [obj.value as string]
                    }))
                })
            }

            const zoomAction = (not: boolean) => {
                if (property === 'src_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'src_ip',
                            value: dataObject.src_ip
                        },
                        {
                            sort: 'src_port',
                            value: dataObject.src_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else if (property === 'dest_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'dest_ip',
                            value: dataObject.dest_ip
                        },
                        {
                            sort: 'dest_port',
                            value: dataObject.dest_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else {
                    addMultipleFilters(not, [
                        {
                            sort: property,
                            value: rawValue
                        }
                    ])
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={cellCount}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (data, rowIndex) => {
                /** now iterate the data array and map. */
                const actionButtons = () => {
                    // if case where you show buttons based on data content.
                    const filteredActions = _.filter(ACTIONS, (action) => {
                        if (
                            action.value === 'ARCHIVE'
                        ) {
                            return true
                        } else {
                            return false
                        }
                    })

                    return (
                        _.map(filteredActions, (action, cellIndex) => {
                            const cellKey = [
                                'event-cell-' + rowIndex +
                                        '-' + (1 + cellIndex + tableData.columns.length)
                            ].join('')

                            const onClick = () => {
                                /** archive is same as delete. */
                                if (action.value === 'ARCHIVE') {
                                    /** no need to check if data[0] is defined since the
                                         * button will never render if the length of the data
                                         * array is exactly one.
                                         */
                                    dispatch(addModal({
                                        id: uniqueString(),
                                        open: true,
                                        eventObj: data,
                                        operation: action.value,
                                        isBorderWide: false
                                    }))
                                }
                            }

                            return (
                                <td key={cellKey}>
                                    <Button
                                        onClick={onClick}
                                        size={'sm'}
                                        mode={action.mode}>
                                        {action.label}
                                    </Button>
                                </td>
                            )
                        })
                    )
                }

                const checkbox = (
                    <input type={'checkbox'} onChange={(e) => {
                        if (!e.target.checked) {
                            dispatch(removeEventFromMultiple(data))
                        } else {
                            dispatch(addEventToMultiple(data))
                        }
                    }} checked={
                        Boolean(_.find(multipleArchive, (obj) => {
                            return obj.id === data.id
                        }))
                    } />
                )

                return (
                    <tr key={'event-row-' + rowIndex}>
                        <td colSpan={2}>
                            {checkbox}
                        </td>
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                const cellKey = [
                                    'cell-', rowIndex, '-', cellIndex
                                ].join('')

                                // apply onClick event to populate event details.
                                return (
                                    <td
                                        className={'pointer'}
                                        key={cellKey}
                                        onClick={() => {
                                            dispatch(addModal({
                                                open: true,
                                                id: uniqueString(),
                                                eventObj: data,
                                                operation: 'EVENT_DETAILS',
                                                isBorderWide: false
                                            }))
                                        }}
                                    >
                                        {cellBody(data, column.value)}
                                    </td>
                                )
                            })
                        }
                        {/* only display the action
                        button at the first element of the group */}
                        {actionButtons()}
                    </tr>
                )
            })
        )

        return (
            tableData.paginated.length
                ? cellContent
                : EmptyCellContent
        )
    }, [tableData, multipleArchive])

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const filterData = useDebouncedCallback(() => {
        const filteredData = smartSearch(
            modal.eventGroup || [],
            tableData.filters,
            tableData.search
        ) as EventObj[]
        const orderedData = smartOrder(filteredData, tableData.columns) as EventObj[]

        /**
         * set the filtered data to tableData.filtered.
         * any sort or pagination should be done in a separate useEffect
         * */
        dispatch(setFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    const paginateData = () => {
    // const paginateData = useDebouncedCallback(() => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as EventObj[]

        dispatch(setPaginated(result))
    // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        filterData()
    }, [
        /** refreshed data from getPartners invocation */
        modal.eventGroup,
        /** when tableData.filters updates */
        tableData.filters,
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    useEffect(() => {
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    /** reset event slice on unmount when navigating other event type search components */
    useEffect(() => {
        return () => {
            dispatch(resetEvent())
        }
    }, [])

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const data = modal.eventGroup || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = data[0]
            ? _.filter(_.keys(
                flatten(data[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : KEYS.EVENT

        dispatch(
            setFilterKeys(result)
        )
    }, [
        modal.eventGroup
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as EventObjKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    return (
        <div>
            <div className={'justify-content-end align-items-end row mt-4 mb-3'}>
                {/* Add a button named, "Archive Selected Events" */}
                <div className={'col-12 col-sm-auto pe-sm-4 ps-sm-0 px-4'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        disabled={!multipleArchive.length}
                        onClick={() => {
                            dispatch(addModal({
                                id: uniqueString(),
                                open: true,
                                eventGroup: multipleArchive,
                                operation: 'MULTIPLE_ARCHIVE',
                                isBorderWide: false
                            }))
                        }}
                    >
                        {EVENT_TEXT.TABLE.ARCHIVE_SELECTED}
                    </Button>
                </div>
            </div>
            {/* in this row, show the search bar on the left
            and the pagination on the right */}
            <div className={'min-width-fix'}>
                <div className={'align-items-center justify-content-between row'}>
                    <div className={'col-12 col-md px-4'}>
                        <SearchRow className={'align-items-center row'}>
                            <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                                <FaSearch />
                            </label>
                            <input
                                className={'col'}
                                type={'text'}
                                id={TEXT.SEARCH.SEARCH.ID}
                                onChange={(e) => {
                                    setSearchDebounce(e.target.value)
                                }}
                                placeholder={'Search'}
                            />
                            {/* when this is clicked, a popup window will show up
                            it was previously a tippy but now it's an interface below
                            to utilize space. */}
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                                <Text size={'xl'}
                                    className={'d-inline-block col-auto pe-0'}
                                    onClick={() => {
                                        dispatch(
                                            toggleFilterInterface(!tableData.filterInterface.show)
                                        )
                                    }}
                                >
                                    <MdManageSearch />
                                </Text>
                            </Tippy>
                        </SearchRow>
                    </div>

                    {/* this is the dropdown for page count */}
                    <div className={'col-12 col-md-auto'}>
                        <div className={'align-items-center justify-content-between row'}>
                            <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                                <div className={'align-items-center justify-content-center row'}>
                                    <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                    <Select
                                        className={'col-auto'}
                                        options={ROW_COUNT_SELECTION}
                                        value={_.find(
                                            ROW_COUNT_SELECTION,
                                            (e) => e.value === tableData.count
                                        )}
                                        onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                            if (e) {
                                                dispatch(setCount(e.value))
                                            }
                                        }}
                                        styles={{
                                            ...reactSelect.styles,
                                            ...FIXED_REACT_SELECT_WIDTH.SMALL
                                        }}
                                        theme={reactSelect.theme}
                                    />
                                </div>
                            </div>
                            <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                                <Pagination

                                    current={tableData.page}
                                    total={tableData.filtered.length}
                                    pageSize={tableData.count}
                                    onChange={(current) => {
                                        dispatch(setPage(current))
                                    }}
                                    locale={EN_US}
                                />
                            </div>

                        </div>

                    </div>
                </div>
                {/* row to show when kubernetes search toggles */}
                {
                    tableData.filterInterface.show
                        ? (
                            <div className={'mt-2'}>
                                {FilterInterface}
                            </div>
                        )
                        : ''
                }
                <div className={'row mt-2'}>
                    {/* display columns aka a filter shere */}
                    <div className={'col ps-4'}>
                        <div className={'row'}>
                            {
                                _.map(tableData.filters, (filter, index) => {
                                    const comparisonString = filter.value.length > 1
                                        ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                        : ':'

                                    return (
                                        <FilterCell key={'filter-' + index} className={
                                            ['col-auto me-2 px-2 mt-1',
                                                filter.not ? 'not' : ''].join(' ')
                                        }>
                                            <Text size={'xs'}>
                                                {[
                                                    [
                                                        filter.not ? '!' : '',
                                                        filter.sort
                                                    ].join(''),
                                                    comparisonString,
                                                    filter.value.join(', ')
                                                ].join(' ')}
                                            </Text>
                                            <Text size={'xs'} className={'ps-2'} onClick={() => {
                                                dispatch(removeFilter(filter))
                                            }}>
                                                <FaTimes />
                                            </Text>
                                        </FilterCell>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <span
                        className={[
                            'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                            tableData.filters.length ? 'd-block' : 'd-none'
                        ].join(' ')}
                        onClick={() => {
                            dispatch(removeFilters())
                        }}
                    >
                        {/* clear all filters button. baseline, not center */}
                        <FaTimes />
                    </span>
                </div>
                <div className={'justify-content-center row mt-3'}>
                    <div className={'col'}>
                        <Table
                            className={'table-striped table-hover'}
                            height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                            bgIndex={1}
                        >
                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        {/* a table header cell for showing groupings. */}
                                        <th colSpan={TABLE_COL_SPAN} className={'border-bottom'}>
                                            <Button
                                                disabled={multipleArchive.length === 0}
                                                onClick={() => {
                                                    dispatch(emptyEventGroup())
                                                }}
                                                size={'sm'}
                                                mode={'primary'}>
                                                {EVENT_TEXT.TABLE.UNCHECK_ALL}
                                            </Button>
                                        </th>
                                        {TableHead}
                                        {/* action section is created here. */}
                                        {
                                            ACTIONS.length
                                                ? (<th colSpan={ACTIONS.length}>
                                                    <small>{TEXT.TABLE.ACTIONS}</small>
                                                </th>)
                                                : <th className={'d-none'}></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {TableBody}
                                </tbody>
                            </table>
                        </Table>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default GroupedEventData

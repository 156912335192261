import { URL } from '@constants/main/root'
import {
    GetDetailedDashboardRequest as GetDetailedDashboardMainRequest,
    EventType as DetailedMainEventType,
    OperationsActivityResponse,
    GeneralActivityResponse,
    ExchangeActivityResponse,
    WorkloadActivityResponse,
    SharepointActivityResponse,
    SecurityCenterActivityResponse,
    Office365EventsResponse,
    LoginLocationsResponse
} from '@interfaces/dashboard/soc/o365/detailedDashboard/main'
import {
    GetDetailedDashboardRequest as GetDetailedDashboardSharepointRequest,
    EventType as DetailedSharepointEventType,
    SourceFileExtensionDoughnutResponse,
    OneDriveDetailsActivityResponse,
    SharepointClientIPDoughnutResponse,
    SharePointDetailsActivityResponse,
    SharePointEventsResponse,
    SharepointUserActivityLocationsResponse,
    SharepointUserIdCountedTableResponse,
    SiteUrlActivityResponse,
    SourceFileExtensionActivityResponse,
    SourceFileNameCountedTableResponse
} from '@interfaces/dashboard/soc/o365/detailedDashboard/sharepoint'
import {
    GetDetailedDashboardRequest as GetDetailedDashboardExchangeRequest,
    EventType as DetailedExchangeEventType,
    AttachmentDoughnutResponse,
    ExchangeClientIPDoughnutResponse,
    ExchangeDetailsActivityResponse,
    ExchangeUserActivityLocationsResponse,
    FromActivityResponse,
    ToActivityResponse,
    ClientInfoActivityResponse,
    ExchangeDetailsRulesResponse,
    SubjectCountedTableResponse,
    ExchangeUserIdCountedTableResponse,
    ExchangeEventsResponse
} from '@interfaces/dashboard/soc/o365/detailedDashboard/exchange'
import {
    GetModalRequest,
    ModalHeaderResponse,
    UserActivityResponse,
    AzureAdResponse,
    IssuesDetectedResponse,
    FilesAccessResponse,
    FilesSharedResponse,
    PolicyRelatedResponse,
    FilesMaliciousResponse,
    EventType as MainEventType,
    Office365UsersResponse,
    GetDataIdRequest,
    GetDataIdResponse,
    GetModalDetailsRequest,
    GetModalDetailsResponse
} from '@interfaces/dashboard/soc/o365/main'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery,
    FetchArgs
} from '@reduxjs/toolkit/query/react'
import { Base64 } from 'js-base64'

const getO365ModalData: (
    body:Omit<GetModalRequest, 'event_type'>,
    eventType:MainEventType
) => GetModalRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to
    }
}

const createO365Query: (
    formData: GetModalRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/o365/modal',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

const getO365DetailedMainModalData: (
    body:Omit<GetDetailedDashboardMainRequest, 'event_type'>,
    eventType:DetailedMainEventType
) => GetDetailedDashboardMainRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to,
        mn: body.mn,
        q: body.q,
        s: body.s
    }
}

const getO365DetailedSharepointModalData: (
    body:Omit<GetDetailedDashboardSharepointRequest, 'event_type'>,
    eventType:DetailedSharepointEventType
) => GetDetailedDashboardSharepointRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to,
        mn: body.mn,
        q: body.q,
        s: body.s
    }
}

const getO365DetailedExchangeModalData: (
    body:Omit<GetDetailedDashboardExchangeRequest, 'event_type'>,
    eventType:DetailedExchangeEventType
) => GetDetailedDashboardExchangeRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to,
        mn: body.mn,
        q: body.q,
        s: body.s
    }
}

const createO365DetailedMainQuery: (
    formData: GetDetailedDashboardMainRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/o365/detailDashboard',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

const createO365DetailedSharepointQuery: (
    formData: GetDetailedDashboardSharepointRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/o365/sharepointDashboard',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

const createO365DetailedExchangeQuery: (
    formData: GetDetailedDashboardExchangeRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/o365/exchangeDashboard',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

export const o365Api = createApi({
    reducerPath: 'o365Api',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** there should be 5 api calls. */
        getModalHeader: builder
            .mutation<ModalHeaderResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'modal.Header')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getAzureAd: builder
            .mutation<AzureAdResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Azure.AD')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getFilesAccess: builder
            .mutation<FilesAccessResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Files.Access')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getFilesMalicious: builder
            .mutation<FilesMaliciousResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Files.Malicious')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getFilesShared: builder
            .mutation<FilesSharedResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Files.Shared')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getIssuesDetected: builder
            .mutation<IssuesDetectedResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Issues.Detected')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getOffice365Users: builder
            .mutation<Office365UsersResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Office365.Users')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getPolicyRelated: builder
            .mutation<PolicyRelatedResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'Policy.Related')
                    return createO365Query(formData, body.authToken)
                }
            }),
        getUserActivity: builder
            .mutation<UserActivityResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getO365ModalData(body, 'User.Activity')
                    return createO365Query(formData, body.authToken)
                }
            }),
        /** get o365 details */
        getModalDetails: builder
            .mutation<GetModalDetailsResponse, GetModalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetModalDetailsRequest = {
                        deviceid: body.deviceid,
                        event_type: body.event_type,
                        q: Base64.encode(body.q),
                        time_from: body.time_from,
                        time_to: body.time_to,
                        /** all of these are optional */
                        title: body.title,
                        operation: body.operation,
                        detectionmethod: body.detectionmethod,
                        workload: body.workload,
                        country: body.country,
                        city: body.city,
                        lat: body.lat,
                        lon: body.lon,
                        extension: body.extension,
                        ip: body.ip,
                        site: body.site,
                        // user: body.user?.replace(/\\/g, '\\\\'),
                        // data already has a double backslash.
                        user: body.user,
                        file: body.file,
                        /** email data */
                        from: body.from,
                        to: body.to,
                        attachments: body.attachments,
                        useragent: body.useragent,
                        subject: body.subject,
                        rule: body.rule
                    }

                    return {
                        url: 'api/getData/o365/modalDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getDataId: builder
            .mutation<GetDataIdResponse, GetDataIdRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetDataIdRequest = {
                        deviceid: body.deviceid,
                        id: body.id,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/o365/dataId',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getOperationsActivity: builder
            .mutation<OperationsActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'Operations.Activity')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getGeneralActivity: builder
            .mutation<GeneralActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'General.Activity')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getExchangeActivity: builder
            .mutation<ExchangeActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'Exchange.Activity')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getWorkloadActivity: builder
            .mutation<WorkloadActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'Workload.Activity')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getSharepointActivity: builder
            .mutation<SharepointActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'Sharepoint.Activity')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getSecurityCenterActivity: builder
            .mutation<SecurityCenterActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'SecurityCenter.Activity')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getOffice365Events: builder
            .mutation<Office365EventsResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'Office365.Events')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        getLoginLocations: builder
            .mutation<LoginLocationsResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getO365DetailedMainModalData(body, 'Login.Locations')
                    return createO365DetailedMainQuery(formData, body.authToken)
                }
            }),
        /** sharepoint */
        getSourceFileExtensionDoughnut: builder
            .mutation<SourceFileExtensionDoughnutResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SourceFileExtension.Doughnut')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSharepointClientIPDoughnut: builder
            .mutation<SharepointClientIPDoughnutResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SharepointClientIP.Doughnut')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSharePointDetailsActivity: builder
            .mutation<SharePointDetailsActivityResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SharePointDetails.Activity')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getOneDriveDetailsActivity: builder
            .mutation<OneDriveDetailsActivityResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'OneDriveDetails.Activity')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSiteUrlActivity: builder
            .mutation<SiteUrlActivityResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SiteUrl.Activity')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSourceFileExtensionActivity: builder
            .mutation<SourceFileExtensionActivityResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SourceFileExtension.Activity')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSharepointUserIdCountedTable: builder
            .mutation<SharepointUserIdCountedTableResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SharepointUserId.CountedTable')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSourceFileNameCountedTable: builder
            .mutation<SourceFileNameCountedTableResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SourceFileName.CountedTable')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSharePointEvents: builder
            .mutation<SharePointEventsResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SharePoint.Events')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        getSharepointUserActivityLocations: builder
            .mutation<SharepointUserActivityLocationsResponse,
            Omit<GetDetailedDashboardSharepointRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardSharepointRequest =
                        getO365DetailedSharepointModalData(body, 'SharepointUserActivity.Locations')
                    return createO365DetailedSharepointQuery(formData, body.authToken)
                }
            }),
        /** exchange */
        getAttachmentDoughnut: builder
            .mutation<AttachmentDoughnutResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'Attachments.Doughnut')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getExchangeClientIPDoughnut: builder
            .mutation<ExchangeClientIPDoughnutResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'ExchangeClientIP.Doughnut')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getExchangeDetailsActivity: builder
            .mutation<ExchangeDetailsActivityResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'ExchangeDetails.Activity')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getExchangeUserActivityLocations: builder
            .mutation<ExchangeUserActivityLocationsResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'ExchangeUserActivity.Locations')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getFromActivity: builder
            .mutation<FromActivityResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'From.Activity')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getToActivity: builder
            .mutation<ToActivityResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'To.Activity')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getClientInfoActivity: builder
            .mutation<ClientInfoActivityResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'ClientInfo.Activity')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getExchangeDetailsRules: builder
            .mutation<ExchangeDetailsRulesResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'ExchangeDetails.Rules')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getSubjectCountedTable: builder
            .mutation<SubjectCountedTableResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'Subject.CountedTable')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getExchangeUserIdCountedTable: builder
            .mutation<ExchangeUserIdCountedTableResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'ExchangeUserId.CountedTable')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            }),
        getExchangeEvents: builder
            .mutation<ExchangeEventsResponse,
            Omit<GetDetailedDashboardExchangeRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardExchangeRequest =
                        getO365DetailedExchangeModalData(body, 'Exchange.Events')
                    return createO365DetailedExchangeQuery(formData, body.authToken)
                }
            })

    })
})

export const {
    useGetModalHeaderMutation,
    useGetAzureAdMutation,
    useGetFilesAccessMutation,
    useGetFilesMaliciousMutation,
    useGetFilesSharedMutation,
    useGetIssuesDetectedMutation,
    useGetOffice365UsersMutation,
    useGetPolicyRelatedMutation,
    useGetUserActivityMutation,
    useGetModalDetailsMutation,
    useGetDataIdMutation,
    /** main calls */
    useGetExchangeActivityMutation,
    useGetGeneralActivityMutation,
    useGetOffice365EventsMutation,
    useGetOperationsActivityMutation,
    useGetSecurityCenterActivityMutation,
    useGetSharepointActivityMutation,
    useGetWorkloadActivityMutation,
    useGetLoginLocationsMutation,
    /** sharepoint calls */
    useGetOneDriveDetailsActivityMutation,
    useGetSharePointDetailsActivityMutation,
    useGetSharePointEventsMutation,
    useGetSharepointClientIPDoughnutMutation,
    useGetSharepointUserActivityLocationsMutation,
    useGetSharepointUserIdCountedTableMutation,
    useGetSiteUrlActivityMutation,
    useGetSourceFileExtensionActivityMutation,
    useGetSourceFileExtensionDoughnutMutation,
    useGetSourceFileNameCountedTableMutation,
    /** exchange calls */
    useGetAttachmentDoughnutMutation,
    useGetClientInfoActivityMutation,
    useGetExchangeClientIPDoughnutMutation,
    useGetExchangeDetailsActivityMutation,
    useGetExchangeDetailsRulesMutation,
    useGetExchangeEventsMutation,
    useGetExchangeUserActivityLocationsMutation,
    useGetExchangeUserIdCountedTableMutation,
    useGetFromActivityMutation,
    useGetSubjectCountedTableMutation,
    useGetToActivityMutation
} = o365Api


import {
    useAppSelector
} from '@app/hook'

import DashboardPrintReport from '@features/dashboard/print/create/PrintReport'
import EventReportingPrintReport from '@features/watchdog/soc-data/print/create/PrintReport'
import {
    selectQueues
} from '@slices/main/print/queue'
import _ from 'lodash'
import React, { useEffect } from 'react'

const QueueController = () => {
    const queues = useAppSelector(selectQueues)

    /** A main concern with performance is what to expect when a lifecycle
     * is about to happen. For example, in an array of queues where only
     * one object has updated, you don't want to iterate through the array
     * again just to perform some checks even though that's the expected
     * behavior. To remedy this, we will pass each queue to a separate component
     * as props instead of this being part of the global state.
     *
     * This was a thoroughly checked conclusion since a draft was made where
     * one observes one of the similar cases as above.
    */

    useEffect(() => {
        // console.log('Queues currently are: ', queues)
    }, [queues])

    return <div>
        {
            _.map(queues, (queue) => {
                const key = [
                    'queue-', queue.id
                ].join('')

                if (queue.details.dashboardCard) {
                    return <DashboardPrintReport key={key} queue={queue} />
                } else if (queue.details.eventReports) {
                    return <EventReportingPrintReport key={key} queue={queue} />
                }

                return <></>
            })
        }
    </div>
}

export default QueueController

import {
    TEXT,
    DATE_FORMAT_TIME
} from '@constants/main/root'
import {
    format,
    parse,
    set,
    getMonth,
    getDate,
    getYear,
    getHours,
    getMinutes
} from 'date-fns'
import _ from 'lodash'
import React from 'react'
import { Text } from '@styles/components'

export const displayUnpredictable = (
    displayRenderer: (key: any, label:string, value:any) => JSX.Element,
    key:any, label:any, value: any
) => {
    const arrayRecursion = (item: unknown, nestedIndex: number) => {
        const nestedKey = [key, '-', nestedIndex].join('')
        return displayUnpredictable(
            displayRenderer, nestedKey, '', item
        )
    }

    const objectRecursion = (arr: [string, unknown], nestedIndex: number) => {
        const [nestedLabel, nestedValue] = arr
        const nestedKey = [key, '-', nestedIndex].join('')
        return displayUnpredictable(
            displayRenderer, nestedKey, nestedLabel, nestedValue
        )
    }

    if (!value) return displayRenderer(key, label, TEXT.NONE)
    else if (['string', 'number'].includes(typeof value)) {
        if (label.toLowerCase().includes('timestamp')) {
            return (
                displayRenderer(key, label, format(new Date(value), DATE_FORMAT_TIME))
            )
        } else return (displayRenderer(key, label, value))
    } else if (Array.isArray(value) && value.length) {
        return (
            <div key={key}>
                <Text size={'sm'} className={'col-12'}>{label}</Text>
                <div className={'my-2'}>
                    {
                        _.map(value, arrayRecursion)
                    }
                </div>
            </div>
        )
    } else if (
        ['object'].includes(typeof value) && Object.keys(value).length
    ) {
        if (label === 'MessageTime') {
            return (displayRenderer(key, label, format(new Date(value), DATE_FORMAT_TIME)))
        } else if (label === 'Subject') {
            const arr = value.split(' ')
            arr[1] = parse(arr[1], '[LL.dd.yyyy]', new Date())
            arr[3] = parse([arr[3], arr[4]].join(' '), 'hh:mm a', new Date())
            const parsedDate = set(
                new Date(), {
                    month: getMonth(arr[1]),
                    date: getDate(arr[1]),
                    year: getYear(arr[1]),
                    hours: getHours(arr[3]),
                    minutes: getMinutes(arr[3]),
                    seconds: 0
                }
            )
            return displayRenderer(key,
                label,
                format(parsedDate, DATE_FORMAT_TIME)
            )
        } else {
            return (
                <div key={key}>
                    <Text size={'sm'} className={'col-12'}>{label}</Text>
                    <div className={'my-2 ps-2'}>
                        {
                            _.map(_.entries(value), objectRecursion)
                        }
                    </div>
                </div>
            )
        }
    }
}

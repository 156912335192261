import {
    useAppSelector
} from '@app/hook'
import {
    ISSUE_ALERTED_COLUMNS,
    MESSAGE as MDR_MESSAGE
} from '@constants/dashboard/soc/mdr/main'
import {
    DATE_FORMAT_TIME,
    MESSAGE
} from '@constants/main/root'
import {
    IssueAlert,
    IssueAlertedResponse
} from '@interfaces/dashboard/soc/mdr/main'
import {
    SerializedError
} from '@reduxjs/toolkit'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/mdr/main'
import {
    Container,
    ErrorMessage,
    SpinnerContainer,
    Table
} from '@styles/components'
import { format } from 'date-fns'
import _ from 'lodash'
import React, {
    useMemo
} from 'react'

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'

interface ComponentProps {
    data: IssueAlertedResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssueAlertedTable = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)

    const DataTable = useMemo(() => {
        const currentData = data?.data || []

        const cellBody = (
            dataObject: IssueAlert,
            property: keyof IssueAlert
        ) => {
            let cellContent: IssueAlert[keyof IssueAlert] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'timestamp':
                    cellContent = format(new Date(dataObject[property]), DATE_FORMAT_TIME)
                    break
                default:
                    cellContent = dataObject[property]
                    break
            }

            return cellContent
        }

        const content = <Table
            className={'table-striped table-hover px-0'}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        {
                            _.map(ISSUE_ALERTED_COLUMNS, ({ label }, index) => {
                                const key = [
                                    'issueAlerted-th-', index
                                ].join('')
                                return <th key={key}><small>{label}</small></th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        currentData.length
                            ? _.map(currentData, (dataObject, rowIndex) => {
                                return (
                                    <tr
                                        key={'issueAlerted-row-' + rowIndex}
                                    >
                                        {
                                            _.map(ISSUE_ALERTED_COLUMNS, (column, cellIndex) => {
                                                return (
                                                    <td key={[
                                                        'issueAlerted-cell-' + rowIndex +
                                            '-' + cellIndex
                                                    ].join('')}
                                                    >
                                                        {cellBody(dataObject, column.value)}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                            : <tr>
                                <td
                                    colSpan={ISSUE_ALERTED_COLUMNS.length}
                                    className={'text-center'}>
                                    {MESSAGE.DATA.EMPTY_DATA}
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </Table>

        return content
    }, [
        data,
        currentParams.ranges
    ])

    const DataContent = useMemo(() => {
        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{MDR_MESSAGE.FETCH.ISSUES_ALERTED}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? DataTable
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

IssueAlertedTable.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssueAlertedTable

import { URL } from '@constants/main/root'
import { TokenAuth } from '@interfaces/main/root'
import {
    GetFlowIDRequest,
    GetFlowIDResponse
} from '@interfaces/watchdog/soc-data/flow'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const flowApi = createApi({
    reducerPath: 'flowApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        getFlowID: builder.mutation<GetFlowIDResponse, GetFlowIDRequest & TokenAuth>({
            query: (body) => {
                return {
                    url: 'api/getFlowID',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${ body.authToken }`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        deviceid: body.deviceid,
                        flowid: body.flowid
                    }
                }
            }
        })
    })
})

export const {
    useGetFlowIDMutation
} = flowApi


import {
    useGetMdrActivityMutation,
    useGetMdrDhcpMutation,
    useGetMdrDnsMutation,
    useGetMdrEventsMutation,
    useGetMdrFileInfoMutation,
    useGetMdrHttpMutation,
    useGetMdrIkev2Mutation,
    useGetMdrKrb5Mutation,
    useGetMdrLocationsMutation,
    useGetMdrSmbMutation,
    useGetMdrSnmpMutation,
    useGetMdrSshMutation,
    useGetMdrTlsMutation
} from '@apis/dashboard/soc/mdr-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    CHART_ZOOM,
    MESSAGE as MONITOR_MESSAGE,
    TEXT as MONITOR_TEXT
} from '@constants/dashboard/monitor'
import {
    INITIAL_SEARCH_PARAMS,
    TEXT as MDR_TEXT
} from '@constants/dashboard/soc/mdr/detailedDashboard/main'
import {
    getLocalRanges,
    getUtcRanges,
    sendUnavailableModalMessage,
    tryParseJSON,
    turnIntoLuceneQuery
} from '@constants/main/method'
import { DEFAULT_QUEUE } from '@constants/main/print'
import {
    ACTION_MUTATION_PROMISE,
    DATE_FORMAT_TIME,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import MDRBarChart from '@features/dashboard/soc/mdr/detailed-dashboard/MDRBarChart'
import MDREventsDataTable from '@features/dashboard/soc/mdr/detailed-dashboard/MDREventsDataTable'
import MDRLocationsMap from '@features/dashboard/soc/mdr/detailed-dashboard/MDRLocationsMap'
import MDRDataID from '@features/dashboard/soc/mdr/MDRDataID'
import MDRDetails from '@features/dashboard/soc/mdr/MDRDetails'
import {
    DatePickerEndIcon,
    DatePickerStartIcon
} from '@features/main/DatePickerIcon'
import { LuceneQuery } from '@interfaces/dashboard/monitor'
import {
    GetDetailedDashboardRequest,
    MdrDetailedSearchParams
} from '@interfaces/dashboard/soc/mdr/detailedDashboard/main'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    addModal,
    closeModal,
    removeBool,
    removeBoolList,
    removeModal,
    resetMdrDetailed,
    selectChartZooms,
    selectCurrentParams,
    selectDashboardData,
    selectFixedCollapsibles,
    selectMapData,
    selectModals,
    selectPrintOptions,
    selectSearchParams,
    selectTableData,
    setChartZoom,
    setChartZoomLevel,
    setCurrentParams,
    setEndDate,
    setIsComplete,
    setLocalStorage,
    setLogo,
    setMapData,
    setMdrActivityData,
    setMdrDhcpData,
    setMdrDnsData,
    setMdrEventsData,
    setMdrFileinfoData,
    setMdrHttpData,
    setMdrIkev2Data,
    setMdrKrb5Data,
    setMdrLocationsData,
    setMdrSmbData,
    setMdrSnmpData,
    setMdrSshData,
    setMdrTlsData,
    setQ,
    setRefetch,
    setScrollId,
    setSearchParams,
    setStartDate,
    setTotalRecords,
    toggleCollapsible
} from '@slices/dashboard/soc/mdr/detailedDashboard/main'
import {
    addQueue,
    selectQueues
} from '@slices/main/print/queue'
import { selectToken } from '@slices/main/token'
import {
    Button,
    CircularProgressContainer,
    CollapsibleText,
    Container,
    FilterCell,
    MapInfoContainer,
    SearchRow,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import _ from 'lodash'
import React, {
    HTMLProps,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    CircularProgressbar
} from 'react-circular-progressbar'
import { CSVLink } from 'react-csv'
import ReactDatePicker from 'react-datepicker'
import { AiOutlineClose } from 'react-icons/ai'
import {
    FaFileCsv,
    FaFilePdf,
    FaInfoCircle,
    FaMinus,
    FaPlus,
    FaSearch,
    FaSearchMinus,
    FaTimes
} from 'react-icons/fa'
import { MdRefresh } from 'react-icons/md'
import Modal from 'react-responsive-modal'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'

import { Buffer } from 'buffer'
import flatten from 'flat'
import DoEscalate from '@features/watchdog/soc-data/event/DoEscalate'
import { EventModal } from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const MDRMain = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    /** we will always have a url parameter for the deviceid so IF and
     * only IF the deviceid is falsy, then we will use this value.
     */
    const params = useParams<{deviceid: string }>()

    const searchParams = useAppSelector(selectSearchParams)
    const currentParams = useAppSelector(selectCurrentParams)
    const printOptions = useAppSelector(selectPrintOptions)
    const tableData = useAppSelector(selectTableData)
    const fixedCollapsibles = useAppSelector(selectFixedCollapsibles)
    const modals = useAppSelector(selectModals)
    const chartZooms = useAppSelector(selectChartZooms)
    const mapData = useAppSelector(selectMapData)
    const dashboardData = useAppSelector(selectDashboardData)

    /** while this modal is active, you can have an id to disable the printing */
    const [queueId, setQueueId] = useState<string>('')

    const queues = useAppSelector(selectQueues)

    const [getMdrActivity, getMdrActivityMutation] = useGetMdrActivityMutation()
    const [getMdrDhcp, getMdrDhcpMutation] = useGetMdrDhcpMutation()
    const [getMdrDns, getMdrDnsMutation] = useGetMdrDnsMutation()
    const [getMdrEvents, getMdrEventsMutation] = useGetMdrEventsMutation()
    const [getMdrFileInfo, getMdrFileInfoMutation] = useGetMdrFileInfoMutation()
    const [getMdrHttp, getMdrHttpMutation] = useGetMdrHttpMutation()
    const [getMdrIkev2, getMdrIkev2Mutation] = useGetMdrIkev2Mutation()
    const [getMdrKrb5, getMdrKrb5Mutation] = useGetMdrKrb5Mutation()
    const [getMdrLocations, getMdrLocationsMutation] = useGetMdrLocationsMutation()
    const [getMdrSmb, getMdrSmbMutation] = useGetMdrSmbMutation()
    const [getMdrSnmp, getMdrSnmpMutation] = useGetMdrSnmpMutation()
    const [getMdrSsh, getMdrSshMutation] = useGetMdrSshMutation()
    const [getMdrTls, getMdrTlsMutation] = useGetMdrTlsMutation()

    const [showConfirm, toggleConfirm] = useState(false)

    /** removed because deviceid alone is not enough to
     * query the detailed dashboard.
     */
    // useEffect(() => {
    //     // check if deviceid in searchParams is falsy.
    //     if (!(searchParams.card.deviceid) && params.deviceid) {
    //         dispatch(setDeviceId(params.deviceid))
    //     }
    // }, [params])

    useEffect(() => {
        /** we retrieve the localStorage key */
        const userJson = localStorage.getItem('mdr-db-main-search-params')

        if (userJson !== null) {
            const result = tryParseJSON(userJson) as unknown as
            Omit<MdrDetailedSearchParams, 'refetch'>[]

            /** check if the variable is an array. */
            if (_.isArray(result)) {
                /** then we find the object using the deviceid as our conditional check */
                const found = _.find(result, (obj) => {
                    return obj.card.deviceid === params.deviceid
                })

                if (found) {
                    /**
                     * once found, we want to check if the object has those keys.
                     * without this check, and because those properties are set
                     * to required, render crashes will occur.
                     *
                     * Update at October 7, 2022: Attempted to add nested object properties
                     * to strictly check the data from localStorage. It will work with maxDepth 2.
                     *
                     */

                    const requiredKeys = _.keys(
                        flatten(
                            // exclude refetch property from checking.
                            _.omit(INITIAL_SEARCH_PARAMS, ['refetch', 'boolList'])
                        )
                    )

                    // console.log(requiredKeys)
                    // console.log(_.keys(flatten(found, { maxDepth: 2 })))

                    if (_.every(
                        requiredKeys,
                        _.partial(
                            _.has,
                            flatten(found, { maxDepth: 2 })
                        ))
                    ) {
                        /** make sure you include the default refetch values. */
                        dispatch(setSearchParams({
                            ...found,
                            refetch: INITIAL_SEARCH_PARAMS.refetch
                        }))
                    } else {
                        // display warning message that the found
                        // object is missing some properties that
                        // the component might crash.
                        toast.warning(
                            MONITOR_MESSAGE.MISSING_KEYS_ON_CARD,
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                } else {
                    // otherwise, display error message that none was found
                    // and should go select a dashboard card from monitors.
                    toast.error(
                        MONITOR_MESSAGE.MISSING_CARD,
                        { ...TOASTIFY_DEFAULT_OPTIONS }
                    )
                }
            } else {
                toast.error(
                    MONITOR_MESSAGE.INCORRECT_DETAILED_DASHBOARD_DATA,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [params])
    /** for all errors thrown that have refetch properties,
     * don't forget to set it to false as well. */
    useEffect(() => {
        if (getMdrActivityMutation.error) {
            console.error(getMdrActivityMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Activity',
                value: false
            }))
        }
    }, [getMdrActivityMutation.error])

    useEffect(() => {
        if (getMdrDhcpMutation.error) {
            console.error(getMdrDhcpMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'dhcp',
                value: false
            }))
        }
    }, [getMdrDhcpMutation.error])

    useEffect(() => {
        if (getMdrDnsMutation.error) {
            console.error(getMdrDnsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'dns',
                value: false
            }))
        }
    }, [getMdrDnsMutation.error])

    useEffect(() => {
        if (getMdrEventsMutation.error) {
            console.error(getMdrEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Events',
                value: false
            }))
        }
    }, [getMdrEventsMutation.error])

    useEffect(() => {
        if (getMdrFileInfoMutation.error) {
            console.error(getMdrFileInfoMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'fileinfo',
                value: false
            }))
        }
    }, [getMdrFileInfoMutation.error])

    useEffect(() => {
        if (getMdrHttpMutation.error) {
            console.error(getMdrHttpMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'http',
                value: false
            }))
        }
    }, [getMdrHttpMutation.error])

    useEffect(() => {
        if (getMdrIkev2Mutation.error) {
            console.error(getMdrIkev2Mutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'ikev2',
                value: false
            }))
        }
    }, [getMdrIkev2Mutation.error])

    useEffect(() => {
        if (getMdrKrb5Mutation.error) {
            console.error(getMdrKrb5Mutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'krb5',
                value: false
            }))
        }
    }, [getMdrKrb5Mutation.error])

    useEffect(() => {
        if (getMdrLocationsMutation.error) {
            console.error(getMdrLocationsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'Locations',
                value: false
            }))
        }
    }, [getMdrLocationsMutation.error])

    useEffect(() => {
        if (getMdrSmbMutation.error) {
            console.error(getMdrSmbMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'smb',
                value: false
            }))
        }
    }, [getMdrSmbMutation.error])

    useEffect(() => {
        if (getMdrSnmpMutation.error) {
            console.error(getMdrSnmpMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'snmp',
                value: false
            }))
        }
    }, [getMdrSnmpMutation.error])

    useEffect(() => {
        if (getMdrSshMutation.error) {
            console.error(getMdrSshMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'ssh',
                value: false
            }))
        }
    }, [getMdrSshMutation.error])

    useEffect(() => {
        if (getMdrTlsMutation.error) {
            console.error(getMdrTlsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch({
                key: 'tls',
                value: false
            }))
        }
    }, [getMdrTlsMutation.error])

    /** useEffects to set the data. */

    /** a useEffect for searchParams to update the localStorage item. Changed dependency
     * to currentParams so when the page refreshes, you are using parameters
     * from the latest search instance.
     */
    useEffect(() => {
        /** BUG FOUND at October 11, 2021: default search params
         * on mount is also being added to localStorage. kindly
         * avoid this by checking if service type and inface
         * is lengthy.
         *
         * Also utc issues because of date ranges. Convert to local
         * before setting into localStorage
         */

        if (currentParams.card.deviceid && currentParams.card.details.title) {
            const oldRanges = getLocalRanges({
                start: currentParams.ranges.start,
                end: currentParams.ranges.end
            })

            dispatch(setLocalStorage({
                ...currentParams,
                ranges: oldRanges
            }))
        }
    }, [currentParams])

    /** can also be from a modal card. */
    /** these are now separate fetchData scripts per client's requests. */
    const createRequestData: (newToken: string) => Omit<GetDetailedDashboardRequest, 'event_type'>
    & TokenAuth = (newToken) => {
        const newRanges = getUtcRanges(searchParams.ranges)

        const should: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => !obj.not),
            turnIntoLuceneQuery)

        const mustNot: LuceneQuery[] = _.map(
            _.filter(searchParams.boolList, (obj) => obj.not),
            turnIntoLuceneQuery)
        /**
         * Issue with search params where you update the ranges and q. It
         * will mess up the form data for fetching either mdr details or data id
         * when selecting the first OR last dataset
         * in the charts OR the tables below them.
         *
         * This can also confuse users who changed the searchParams ranges but
         * didn't execute fetch api calls. To fix this, set a dispatch where
         * you are setting the current range.
         */

        return {
            authToken: newToken,
            deviceid: searchParams.card.deviceid,
            in_face: searchParams.card.in_face,
            time_from: newRanges.start.toString(),
            time_to: newRanges.end.toString(),
            q: Buffer
                .from(searchParams.q)
                .toString('base64'),
            mn: Buffer
                .from(JSON.stringify(mustNot))
                .toString('base64'),
            s: Buffer
                .from(JSON.stringify(should))
                .toString('base64')
        }
    }

    const updateCurrentParams = () => {
        const newRanges = getUtcRanges(searchParams.ranges)

        dispatch(setCurrentParams({
            ranges: newRanges,
            q: searchParams.q,
            card: searchParams.card,
            boolList: searchParams.boolList
        }))
    }

    /** i'm glad we check for searchParams.card.deviceid so we don't execute the data
     * to waste time.
     */

    const unsubscribeGetMdrActivity = () => {
        const unsubscribeMutation = getMdrActivity({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrActivityData(undefined))
    }

    const unsubscribeGetMdrDhcp = () => {
        const unsubscribeMutation = getMdrDhcp({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrDhcpData(undefined))
    }

    const unsubscribeGetMdrDns = () => {
        const unsubscribeMutation = getMdrDns({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrDnsData(undefined))
    }

    const unsubscribeGetMdrEvents = () => {
        const unsubscribeMutation = getMdrEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrEventsData(undefined))
    }

    const unsubscribeGetMdrFileInfo = () => {
        const unsubscribeMutation = getMdrFileInfo({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrFileinfoData(undefined))
    }

    const unsubscribeGetMdrHttp = () => {
        const unsubscribeMutation = getMdrHttp({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrHttpData(undefined))
    }

    const unsubscribeGetMdrIkev2 = () => {
        const unsubscribeMutation = getMdrIkev2({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrIkev2Data(undefined))
    }

    const unsubscribeGetMdrKrb5 = () => {
        const unsubscribeMutation = getMdrKrb5({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrKrb5Data(undefined))
    }

    const unsubscribeGetMdrLocations = () => {
        const unsubscribeMutation = getMdrLocations({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrLocationsData(undefined))
    }

    const unsubscribeGetMdrSmb = () => {
        const unsubscribeMutation = getMdrSmb({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrSmbData(undefined))
    }

    const unsubscribeGetMdrSnmp = () => {
        const unsubscribeMutation = getMdrSnmp({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrSnmpData(undefined))
        dispatch(setMdrSnmpData(undefined))
    }

    const unsubscribeGetMdrSsh = () => {
        const unsubscribeMutation = getMdrSsh({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrSshData(undefined))
    }

    const unsubscribeGetMdrTls = () => {
        const unsubscribeMutation = getMdrTls({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
        dispatch(setMdrTlsData(undefined))
    }

    const fetchMdrActivityData = () => {
        unsubscribeGetMdrActivity()
        let getMdrActivityPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrActivityPromise = getMdrActivity(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrActivityPromise && getMdrActivityPromise.abort()
        }
    }

    const fetchMdrSnmpData = () => {
        unsubscribeGetMdrSnmp()
        let getMdrSnmpPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrSnmpPromise = getMdrSnmp(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrSnmpPromise && getMdrSnmpPromise.abort()
        }
    }

    const fetchMdrDnsData = () => {
        unsubscribeGetMdrDns()
        let getMdrDnsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrDnsPromise = getMdrDns(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrDnsPromise && getMdrDnsPromise.abort()
        }
    }

    const fetchMdrLocationsData = () => {
        unsubscribeGetMdrLocations()
        let getMdrLocationsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrLocationsPromise = getMdrLocations(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrLocationsPromise && getMdrLocationsPromise.abort()
        }
    }

    const fetchMdrDhcpData = () => {
        unsubscribeGetMdrDhcp()
        let getMdrDhcpPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrDhcpPromise = getMdrDhcp(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrDhcpPromise && getMdrDhcpPromise.abort()
        }
    }

    const fetchMdrTlsData = () => {
        unsubscribeGetMdrTls()
        let getMdrTlsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrTlsPromise = getMdrTls(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrTlsPromise && getMdrTlsPromise.abort()
        }
    }

    const fetchMdrSmbData = () => {
        unsubscribeGetMdrSmb()
        let getMdrSmbPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrSmbPromise = getMdrSmb(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrSmbPromise && getMdrSmbPromise.abort()
        }
    }

    const fetchMdrFileInfoData = () => {
        unsubscribeGetMdrFileInfo()
        let getMdrFileInfoPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrFileInfoPromise = getMdrFileInfo(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrFileInfoPromise && getMdrFileInfoPromise.abort()
        }
    }

    const fetchMdrHttpData = () => {
        unsubscribeGetMdrHttp()
        let getMdrHttpPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrHttpPromise = getMdrHttp(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrHttpPromise && getMdrHttpPromise.abort()
        }
    }

    const fetchMdrKrb5Data = () => {
        unsubscribeGetMdrKrb5()
        let getMdrKrb5Promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrKrb5Promise = getMdrKrb5(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrKrb5Promise && getMdrKrb5Promise.abort()
        }
    }

    const fetchMdrSshData = () => {
        unsubscribeGetMdrSsh()
        let getMdrSshPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrSshPromise = getMdrSsh(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrSshPromise && getMdrSshPromise.abort()
        }
    }

    const fetchMdrIkev2Data = () => {
        unsubscribeGetMdrIkev2()
        let getMdrIkev2Promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrIkev2Promise = getMdrIkev2(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrIkev2Promise && getMdrIkev2Promise.abort()
        }
    }

    const fetchMdrEventsData = () => {
        unsubscribeGetMdrEvents()
        let getMdrEventsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true
        /** dispatching currentParams and setting localStorage moved to here */
        updateCurrentParams()

        if (searchParams.card.deviceid && isMounted && token.valid) {
            const call = async () => {
                const newToken = await revalidateToken()
                const requestData: Omit<GetDetailedDashboardRequest, 'event_type'>
                        & TokenAuth = createRequestData(newToken)
                getMdrEventsPromise = getMdrEvents(requestData)
            }
            call()
        }

        return () => {
            isMounted = false
            getMdrEventsPromise && getMdrEventsPromise.abort()
        }
    }

    useEffect(() => {
        if (!dashboardData['MDR.Activity']) {
            return fetchMdrActivityData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.snmp']) {
            return fetchMdrSnmpData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.dns']) {
            return fetchMdrDnsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.Locations']) {
            return fetchMdrLocationsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.dhcp']) {
            return fetchMdrDhcpData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.tls']) {
            return fetchMdrTlsData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.smb']) {
            return fetchMdrSmbData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.fileinfo']) {
            return fetchMdrFileInfoData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.http']) {
            return fetchMdrHttpData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.krb5']) {
            return fetchMdrKrb5Data()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.ssh']) {
            return fetchMdrSshData()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.ikev2']) {
            return fetchMdrIkev2Data()
        }
    }, [token.valid, searchParams.card])
    useEffect(() => {
        if (!dashboardData['MDR.Events']) {
            return fetchMdrEventsData()
        }
    }, [token.valid, searchParams.card])

    useEffect(() => {
        if (searchParams.refetch.Activity) {
            /** before fetching data, reset chart zoom levels. */
            dispatch(setChartZoom({
                key: 'Activity',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrActivityData()
        }
    }, [searchParams.refetch.Activity])

    useEffect(() => {
        let isMounted = true
        if (getMdrActivityMutation.isSuccess && isMounted) {
            if (searchParams.refetch.Activity) {
                dispatch(setRefetch({
                    key: 'Activity',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrActivityData(
                getMdrActivityMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrActivityMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.snmp) {
            dispatch(setChartZoom({
                key: 'snmp',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrSnmpData()
        }
    }, [searchParams.refetch.snmp])

    useEffect(() => {
        let isMounted = true
        if (getMdrSnmpMutation.isSuccess && isMounted) {
            if (searchParams.refetch.snmp) {
                dispatch(setRefetch({
                    key: 'snmp',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrSnmpData(
                getMdrSnmpMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrSnmpMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.dns) {
            dispatch(setChartZoom({
                key: 'dns',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrDnsData()
        }
    }, [searchParams.refetch.dns])

    useEffect(() => {
        let isMounted = true
        if (getMdrDnsMutation.isSuccess && isMounted) {
            if (searchParams.refetch.dns) {
                dispatch(setRefetch({
                    key: 'dns',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrDnsData(
                getMdrDnsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrDnsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.Locations) {
            /** don't forget to empty the mapData. */
            dispatch(setTotalRecords(0))
            dispatch(setIsComplete(false))
            dispatch(setScrollId(''))
            dispatch(setMapData([]))
            return fetchMdrLocationsData()
        }
    }, [searchParams.refetch.Locations])

    useEffect(() => {
        let isMounted = true
        if (getMdrLocationsMutation.isSuccess && isMounted) {
            if (searchParams.refetch.Locations) {
                dispatch(setRefetch({
                    key: 'Locations',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrLocationsData(
                getMdrLocationsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrLocationsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.dhcp) {
            dispatch(setChartZoom({
                key: 'dhcp',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrDhcpData()
        }
    }, [searchParams.refetch.dhcp])

    useEffect(() => {
        let isMounted = true
        if (getMdrDhcpMutation.isSuccess && isMounted) {
            if (searchParams.refetch.dhcp) {
                dispatch(setRefetch({
                    key: 'dhcp',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrDhcpData(
                getMdrDhcpMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrDhcpMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.tls) {
            dispatch(setChartZoom({
                key: 'tls',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrTlsData()
        }
    }, [searchParams.refetch.tls])

    useEffect(() => {
        let isMounted = true
        if (getMdrTlsMutation.isSuccess && isMounted) {
            if (searchParams.refetch.tls) {
                dispatch(setRefetch({
                    key: 'tls',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrTlsData(
                getMdrTlsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrTlsMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.smb) {
            dispatch(setChartZoom({
                key: 'smb',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrSmbData()
        }
    }, [searchParams.refetch.smb])

    useEffect(() => {
        let isMounted = true
        if (getMdrSmbMutation.isSuccess && isMounted) {
            if (searchParams.refetch.smb) {
                dispatch(setRefetch({
                    key: 'smb',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrSmbData(
                getMdrSmbMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrSmbMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.fileinfo) {
            dispatch(setChartZoom({
                key: 'fileinfo',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrFileInfoData()
        }
    }, [searchParams.refetch.fileinfo])

    useEffect(() => {
        let isMounted = true
        if (getMdrFileInfoMutation.isSuccess && isMounted) {
            if (searchParams.refetch.fileinfo) {
                dispatch(setRefetch({
                    key: 'fileinfo',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrFileinfoData(
                getMdrFileInfoMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrFileInfoMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.http) {
            dispatch(setChartZoom({
                key: 'http',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrHttpData()
        }
    }, [searchParams.refetch.http])

    useEffect(() => {
        let isMounted = true
        if (getMdrHttpMutation.isSuccess && isMounted) {
            if (searchParams.refetch.http) {
                dispatch(setRefetch({
                    key: 'http',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrHttpData(
                getMdrHttpMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrHttpMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.krb5) {
            dispatch(setChartZoom({
                key: 'krb5',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrKrb5Data()
        }
    }, [searchParams.refetch.krb5])

    useEffect(() => {
        let isMounted = true
        if (getMdrKrb5Mutation.isSuccess && isMounted) {
            if (searchParams.refetch.krb5) {
                dispatch(setRefetch({
                    key: 'krb5',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrKrb5Data(
                getMdrKrb5Mutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrKrb5Mutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.ssh) {
            dispatch(setChartZoom({
                key: 'ssh',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrSshData()
        }
    }, [searchParams.refetch.ssh])

    useEffect(() => {
        let isMounted = true
        if (getMdrSshMutation.isSuccess && isMounted) {
            if (searchParams.refetch.ssh) {
                dispatch(setRefetch({
                    key: 'ssh',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrSshData(
                getMdrSshMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrSshMutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.ikev2) {
            dispatch(setChartZoom({
                key: 'ikev2',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrIkev2Data()
        }
    }, [searchParams.refetch.ikev2])

    useEffect(() => {
        let isMounted = true
        if (getMdrIkev2Mutation.isSuccess && isMounted) {
            if (searchParams.refetch.ikev2) {
                dispatch(setRefetch({
                    key: 'ikev2',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrIkev2Data(
                getMdrIkev2Mutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrIkev2Mutation.isSuccess])

    useEffect(() => {
        if (searchParams.refetch.Events) {
            dispatch(setChartZoom({
                key: 'Events',
                value: _.cloneDeep(CHART_ZOOM)
            }))
            return fetchMdrEventsData()
        }
    }, [searchParams.refetch.Events])

    useEffect(() => {
        let isMounted = true
        if (getMdrEventsMutation.isSuccess && isMounted) {
            if (searchParams.refetch.Events) {
                dispatch(setRefetch({
                    key: 'Events',
                    value: false
                }))
            }
            /** make sure you empty the data */
            dispatch(setMdrEventsData(
                getMdrEventsMutation.data
            ))
        }
        return () => {
            isMounted = false
        }
    }, [getMdrEventsMutation.isSuccess])

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setStartDate(getUnixTime(date)))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.START.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerStartIcon />}
            />
        )
    }, [searchParams.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(getUnixTime(date)))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(searchParams.ranges.start)
        const endDate = fromUnixTime(searchParams.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}
                calendarContainer={(props: HTMLProps<Element>) => {
                    return <div className={props.className}>
                        <div className={'title text-center pt-2'}>
                            {TEXT.SEARCH.END.TITLE}
                        </div>
                        <div className={'position-relative'}>
                            {props.children}
                        </div>
                    </div>
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode={'select'}
                showTimeInput={true}
                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
                customInput={<DatePickerEndIcon />}
            />
        )
    }, [searchParams.ranges])

    /** refresh button where all fetchData scripts are executed
     * add a tippy warning just incase.
     */
    const RefreshWarningContent = useMemo(() => {
        const confirmClickEvent = async () => {
            toggleConfirm(false)

            dispatch(setRefetch({
                key: 'Activity',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Events',
                value: true
            }))
            dispatch(setRefetch({
                key: 'Locations',
                value: true
            }))
            dispatch(setRefetch({
                key: 'dhcp',
                value: true
            }))
            dispatch(setRefetch({
                key: 'dns',
                value: true
            }))
            dispatch(setRefetch({
                key: 'fileinfo',
                value: true
            }))
            dispatch(setRefetch({
                key: 'http',
                value: true
            }))
            dispatch(setRefetch({
                key: 'ikev2',
                value: true
            }))
            dispatch(setRefetch({
                key: 'krb5',
                value: true
            }))
            dispatch(setRefetch({
                key: 'smb',
                value: true
            }))
            dispatch(setRefetch({
                key: 'snmp',
                value: true
            }))
            dispatch(setRefetch({
                key: 'ssh',
                value: true
            }))
            dispatch(setRefetch({
                key: 'tls',
                value: true
            }))
        }

        return <div>
            <div className={'row'}>
                <small className={'col'}>
                    {MONITOR_MESSAGE.DETAILED_DASHBOARD_REFRESH_ALL}
                </small>
            </div>
            <div className={'row flex-row-reverse mt-1'}>
                <div className={'col-auto'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        onClick={confirmClickEvent}
                    >{TEXT.YES}</Button>
                </div>
                <div className={'col-auto'}>
                    <Button
                        mode={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            toggleConfirm(false)
                        }}
                    >{TEXT.NO}</Button>
                </div>
            </div>
        </div>
    }, [showConfirm])

    const RefreshButton = useMemo(() => {
        const disableRefresh = getMdrActivityMutation.isLoading ||
            getMdrDhcpMutation.isLoading ||
            getMdrDnsMutation.isLoading ||
            getMdrEventsMutation.isLoading ||
            getMdrFileInfoMutation.isLoading ||
            getMdrHttpMutation.isLoading ||
            getMdrIkev2Mutation.isLoading ||
            getMdrKrb5Mutation.isLoading ||
            getMdrLocationsMutation.isLoading ||
            getMdrSmbMutation.isLoading ||
            getMdrSnmpMutation.isLoading ||
            getMdrSshMutation.isLoading ||
            getMdrTlsMutation.isLoading

        return (
            <Tippy
                className={'tippy-box'}
                visible={showConfirm}
                arrow
                interactive
                content={RefreshWarningContent}
                onClickOutside={() => toggleConfirm(false)}
            >
                <span
                    className={[
                        'icon mb-2 d-inline-block ms-2',
                        disableRefresh ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        toggleConfirm(!showConfirm)
                    }} >
                    <MdRefresh />
                </span>
            </Tippy>
        )
    }, [
        showConfirm,
        RefreshWarningContent,
        getMdrActivityMutation,
        getMdrDhcpMutation,
        getMdrDnsMutation,
        getMdrEventsMutation,
        getMdrFileInfoMutation,
        getMdrHttpMutation,
        getMdrIkev2Mutation,
        getMdrKrb5Mutation,
        getMdrLocationsMutation,
        getMdrSmbMutation,
        getMdrSnmpMutation,
        getMdrSshMutation,
        getMdrTlsMutation
    ])

    /** csv exports detailed dashboard event data. */
    const CsvExportButton = useMemo(() => {
        const eventsData = tableData.filtered
        const newRanges = getUtcRanges(currentParams.ranges)
        const filename = [
            currentParams.card.service_type,
            '_', currentParams.card.deviceid, '_report_',
            newRanges.start, '.csv'
        ].join('')

        const disableExport = eventsData.length === 0

        return (
            <CSVLink
                className={[
                    'icon mb-2 d-inline-block ms-2',
                    disableExport ? 'disabled' : 'pointer'
                ].join(' ')}
                headers={_.map(tableData.columns, (obj) => obj.value)}
                filename={filename}
                onClick={() => {
                    if (disableExport) return false
                }}
                data={eventsData}>
                <FaFileCsv />
            </CSVLink>
        )
    }, [
        tableData.columns,
        tableData.filtered,
        currentParams.card
    ])

    const PrintOptionsContent = useMemo(() => {
        const LogoLabel = (
            <label
                className={'col-auto'}
                htmlFor={MDR_TEXT.PRINT_OPTIONS.LOGO.ID}>
                {MDR_TEXT.PRINT_OPTIONS.LOGO.LABEL}
            </label>
        )
        const LogoInput = (
            <input
                onChange={(e) => {
                    dispatch(setLogo(e.target.value))
                }}
                value={printOptions.logo}
            />
        )

        /** there will be some reminders for printing reports
         * with the filter and search logic specific to detailed dashboards.
         * They will be defined in the monitor constants file.
         */

        const queue = _.find(queues, ({ id }) => {
            return queueId === id
        })

        const data = getMdrEventsMutation.data
        const hasNoData = (data?.data || []).length <= 0

        return <div>
            <Text size={'sm'}>
                {MONITOR_TEXT.DETAILED_DASHBOARD.PRINT_REMINDERS}
            </Text>
            <div className={'align-items-center row mb-2'}>
                {LogoLabel}
                <div className={'col'}>
                    {LogoInput}
                </div>
            </div>
            {/* you don't have a print report button yet. */}
            <div className={'row'}>
                <div className={'col text-center'}>
                    {/* when this button is clicked, it will be added to the queue
                    where we will be rendering PrintReport. After which will
                    be deinitialized if report is finished technically if the
                    toast has been dismissed. */}
                    <Button mode={'primary'} onClick={() => {
                        /** generate id and pass it over to the queue.
                         * the component will have a lifecycle to no longer
                         * render components that both isPrinting
                         * and isComplete is true.
                         *
                         * make sure the properties in the state
                         * are added as dependencies in this memoized
                         * component.
                         *
                         * to disable the button while this modal is active,
                         * keep the reference for the id in here.
                         * */
                        const id = uniqueString()
                        setQueueId(id)

                        const oldRanges = getLocalRanges({
                            start: currentParams.ranges.start,
                            end: currentParams.ranges.end
                        })

                        dispatch(addQueue({
                            ...DEFAULT_QUEUE,
                            id: id,
                            details: {
                                dashboardCard: {
                                    card: {
                                        deviceid: searchParams.card.deviceid,
                                        inFace: searchParams.card.in_face,
                                        serviceType: searchParams.card.service_type
                                    },
                                    searchParams: {
                                        timeFrom: oldRanges.start,
                                        timeTo: oldRanges.end,
                                        filters: currentParams.boolList,
                                        search: currentParams.q,
                                        detailedDashboard: 'main'
                                    }
                                },
                                /** detailed dashboard isn't a modal,
                                 * so we don't need it.
                                 *
                                 * To fill in the following properties for printOptions:
                                 * - detailedDashboard is provided at constants file of
                                 * this module.
                                 * - mustNot and should is already provided via
                                 *  useEffect with boolList.
                                 */
                                printOptions: printOptions
                            },
                            count: (getMdrEventsMutation.data?.data || []).length
                        }))
                    }} disabled={queue?.isLoading === true || hasNoData}>
                        {TEXT.PRINT}
                    </Button>
                </div>
            </div>
        </div>
    }, [
        searchParams,
        getMdrEventsMutation,
        currentParams,
        queueId,
        queues,
        printOptions
    ])

    const PrintOptionsButton = useMemo(() => {
        return (
            <Tippy
                className={'tippy-box py-0'}
                interactive
                arrow
                hideOnClick
                trigger={'click'}
                content={PrintOptionsContent}
            >
                {/* had to add d-block so tippy pointer is centered */}
                <span className={'icon pointer  mb-2 d-inline-block ms-2'} >
                    <FaFilePdf />
                </span>
            </Tippy>
        )
    }, [
        PrintOptionsContent,
        printOptions
    ])

    const ActionButtons = useMemo(() => {
        // date selectors should be created first.
        // create refresh button here.
        // create csv export button
        return (
            <div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.START.TITLE}</div>}>
                    <div className={'d-inline-block'}>{StartDatePicker}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.SEARCH.END.TITLE}</div>}>
                    <div className={'d-inline-block'}>{EndDatePicker}</div>
                </Tippy>
                {/* a button named update mdr. */}
                <div className={'d-inline-block'}>{RefreshButton}</div>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.CSV_EXPORT}</div>}>
                    <div className={'d-inline-block'}>{CsvExportButton}</div>
                </Tippy>
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{TEXT.MODAL.PRINT_OPTIONS}</div>}>
                    <div className={'d-inline-block'}>{PrintOptionsButton}</div>
                </Tippy>

            </div>
        )
    }, [
        StartDatePicker,
        EndDatePicker,
        RefreshButton,
        CsvExportButton,
        PrintOptionsButton
    ])

    useEffect(() => {
        return () => {
            dispatch(resetMdrDetailed())
        }
    }, [])

    const renderModals = useMemo(() => {
        return (// using ids to select object to fetch data or close modals as one does fit.
            _.map(modals, (modal, index) => {
                const key = [
                    'modal-', modal.operation, '-', index
                ].join('')

                /** to reduce code duplication, assign component instead and
         * return modal with variable as a child.
         */
                let component: ReactElement<any, any> = <></>
                const message = sendUnavailableModalMessage(
                    modal.card.service_type,
                    modal.operation
                )
                if (modal.card.service_type === 'bdg-mdr') {
                    if (modal.operation === 'DETAILS') {
                        component = <MDRDetails
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'DATA_ID') {
                        component = <MDRDataID
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (modal.operation === 'EVENT_ESCALATE') {
                        // change modal values.
                        const updatedModal: EventModal = {
                            eventObj: modal.serviceTypeFormData?.mdr?.eventEscalate?.eventObj,
                            id: modal.id,
                            open: modal.open,
                            operation: modal.operation,
                            isBorderWide: modal.isBorderWide
                        }

                        component = <DoEscalate
                            modal={updatedModal}
                            addModal={addModal as ActionCreatorWithPayload<EventModal, string>}
                            closeModal={closeModal as ActionCreatorWithPayload<EventModal, string>}
                        />
                    } else {
                        console.error(message)
                        toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        return ''
                    }
                } else {
                    return ''
                }
                /** this is to assume that all modals will have the same props. */
                return <Modal
                    key={key}
                    classNames={{
                        modal: ['md',
                            modal.card.details.colorType || 'darkGrey',
                            modal.isBorderWide ? 'wide-border-top' : ''
                        ].join(' ')
                    }}
                    open={modal.open}
                    center focusTrapped={false}
                    onAnimationEnd={() => {
                        if (!modal.open) {
                            dispatch(removeModal(modal))
                        }
                    }}
                    onClose={() => {
                        dispatch(closeModal(modal))
                    }}
                    closeIcon={<AiOutlineClose />}>
                    {component}
                </Modal>
            })
        )
    }, [modals])

    /** display statcard information at the right. */
    const CardInfo = useMemo(() => {
        return <div className={'text-nowrap'}>
            <div className={'row'}>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.DEVICE_ID}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.deviceid}</Text>
                <Text size={'xs'} className={'col'}>
                    {MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.IN_FACE}
                </Text>
                <Text size={'xs'} className={'col-auto'}>{currentParams.card.in_face}</Text>
            </div>
            <div className={'row'}>
                <Text size={'xs'} className={'col-auto'}>{
                    MONITOR_TEXT.DETAILED_DASHBOARD.STATCARD.LOCATION}
                </Text>
                <Text size={'xs'} className={'col'}>
                    {currentParams.card.details.location || ''}
                </Text>
            </div>
        </div>
    }, [currentParams.card])

    /** add a onClick event where all the charts below will
     * add a mdrDetails modal.
     */
    const MDRActivity = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_ACTIVITY}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'Activity',
                        value: !fixedCollapsibles.Activity
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.Activity ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.Activity ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Activity',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.Activity.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.Activity.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'Activity',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'Activity'}
                requestEventType={'MDR.Activity'}
                isLoading={getMdrActivityMutation.isLoading}
                isSuccess={getMdrActivityMutation.isSuccess}
                error={getMdrActivityMutation.error}
            />
        </div>
    }, undefined)

    const MDRSnmp = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_SNMP}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'snmp',
                        value: !fixedCollapsibles.snmp
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.snmp ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.snmp ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'snmp',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.snmp.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.snmp.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'snmp',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'snmp'}
                requestEventType={'MDR.snmp'}
                isLoading={getMdrSnmpMutation.isLoading}
                isSuccess={getMdrSnmpMutation.isSuccess}
                error={getMdrSnmpMutation.error}
            />
        </div>
    }, undefined)

    const MDRDns = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_DNS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'dns',
                        value: !fixedCollapsibles.dns
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.dns ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.dns ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'dns',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.dns.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.dns.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'dns',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'dns'}
                requestEventType={'MDR.dns'}
                isLoading={getMdrDnsMutation.isLoading}
                isSuccess={getMdrDnsMutation.isSuccess}
                error={getMdrDnsMutation.error}
            />
        </div>
    }, undefined)

    useEffect(() => {
        /** initial set */
        if (getMdrLocationsMutation.data) {
            dispatch(setTotalRecords(getMdrLocationsMutation.data.totalRecords))
            dispatch(setIsComplete(getMdrLocationsMutation.data.isComplete))
            dispatch(setScrollId(getMdrLocationsMutation.data.scrollId))
            dispatch(setMapData(getMdrLocationsMutation.data.data))
        }
    }, [getMdrLocationsMutation.data])

    const ProgressBar = useMemo(() => {
        const value = mapData.data.length
        const maxValue = mapData.totalRecords || 1

        // console.log(value)
        // console.log(maxValue)
        // console.log([(value / (maxValue) * 100).toFixed(1), '%'].join(''))

        return mapData.isComplete
            ? <MapInfoContainer>
                <FaInfoCircle />
            </MapInfoContainer>
            : <CircularProgressContainer>{
                <CircularProgressbar
                    background={true}
                    strokeWidth={4}
                    value={value}
                    maxValue={maxValue}
                    text={[(value / (maxValue) * 100).toFixed(1), '%'].join('')}
                />}
            </CircularProgressContainer>
    }, [mapData])

    const MDRLocations = useMemo(() => {
        const value = mapData.data.length
        const maxValue = mapData.totalRecords || 1

        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_LOCATIONS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch.Locations ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Locations',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>

            {/* data empty message adjusted at 9/22/2022. display
            no geoip message if empty. Don't waste space */}

            {
                mapData.data.length
                    ? <div className={'row justify-content-end position-relative mb-3'}>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{[
                                value, 'of', maxValue
                            ].join(' ')}</div>}>
                            {ProgressBar}
                        </Tippy>
                        <div className={'col'}>
                            <MDRLocationsMap/>
                        </div>
                    </div>
                    : <Container bgIndex={2} className={'mb-2'}>
                        <Text className={'d-block py-1 text-center'} size={'md'}>
                            {MESSAGE.DATA.EMPTY_GEOIP}
                        </Text>
                    </Container>
            }

        </div>
    }, undefined)

    const MDRDhcp = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_DHCP}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'dhcp',
                        value: !fixedCollapsibles.dhcp
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.dhcp ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.dhcp ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'dhcp',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.dhcp.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.dhcp.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'dhcp',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'dhcp'}
                requestEventType={'MDR.dhcp'}
                isLoading={getMdrDhcpMutation.isLoading}
                isSuccess={getMdrDhcpMutation.isSuccess}
                error={getMdrDhcpMutation.error}
            />
        </div>
    }, undefined)

    const MDRFileInfo = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_FILEINFO}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'fileinfo',
                        value: !fixedCollapsibles.fileinfo
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.fileinfo ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.fileinfo ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'fileinfo',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.fileinfo.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.fileinfo.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'fileinfo',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'fileinfo'}
                requestEventType={'MDR.fileinfo'}
                isLoading={getMdrFileInfoMutation.isLoading}
                isSuccess={getMdrFileInfoMutation.isSuccess}
                error={getMdrFileInfoMutation.error}
            />
        </div>
    }, undefined)

    const MDRHttp = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_HTTP}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'http',
                        value: !fixedCollapsibles.http
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.http ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.http ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'http',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.http.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.http.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'http',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'http'}
                requestEventType={'MDR.http'}
                isLoading={getMdrHttpMutation.isLoading}
                isSuccess={getMdrHttpMutation.isSuccess}
                error={getMdrHttpMutation.error}
            />
        </div>
    }, undefined)

    const MDRIkev2 = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_IKEV2}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'ikev2',
                        value: !fixedCollapsibles.ikev2
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.ikev2 ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.ikev2 ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'ikev2',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.ikev2.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.ikev2.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'ikev2',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'ikev2'}
                requestEventType={'MDR.ikev2'}
                isLoading={getMdrIkev2Mutation.isLoading}
                isSuccess={getMdrIkev2Mutation.isSuccess}
                error={getMdrIkev2Mutation.error}
            />
        </div>
    }, undefined)

    const MDRKrb5 = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_KRB5}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'krb5',
                        value: !fixedCollapsibles.krb5
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.krb5 ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.krb5 ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'krb5',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.krb5.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.krb5.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'krb5',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'krb5'}
                requestEventType={'MDR.krb5'}
                isLoading={getMdrKrb5Mutation.isLoading}
                isSuccess={getMdrKrb5Mutation.isSuccess}
                error={getMdrKrb5Mutation.error}
            />
        </div>
    }, undefined)

    const MDRSmb = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_SMB}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'smb',
                        value: !fixedCollapsibles.smb
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.smb ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.smb ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'smb',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.smb.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.smb.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'smb',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'smb'}
                requestEventType={'MDR.smb'}
                isLoading={getMdrSmbMutation.isLoading}
                isSuccess={getMdrSmbMutation.isSuccess}
                error={getMdrSmbMutation.error}
            />
        </div>
    }, undefined)

    const MDRSsh = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_SSH}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'ssh',
                        value: !fixedCollapsibles.ssh
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.ssh ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.ssh ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'ssh',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.ssh.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.ssh.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'ssh',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'ssh'}
                requestEventType={'MDR.ssh'}
                isLoading={getMdrSshMutation.isLoading}
                isSuccess={getMdrSshMutation.isSuccess}
                error={getMdrSshMutation.error}
            />
        </div>
    }, undefined)

    const MDRTls = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_TLS}
                </span>
                <Text size={'xs'} onClick={() => {
                    dispatch(toggleCollapsible({
                        key: 'tls',
                        value: !fixedCollapsibles.tls
                    }))
                }} className={'icon pointer '}>
                    {fixedCollapsibles.tls ? <FaMinus/> : <FaPlus/> }
                </Text>
                <span className={[
                    'icon ',
                    searchParams.refetch.tls ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'tls',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
                {/* zoom out icon for the chart when in zoom in. */}
                <Tippy
                    className={'tippy-box'}
                    arrow
                    content={<div>{MONITOR_TEXT.DETAILED_DASHBOARD.CHART.ZOOM_OUT}</div>}>
                    <div className={[
                        'icon small ',
                        chartZooms.tls.zoomLevel === 0 ? 'disabled' : 'pointer'
                    ].join(' ')}
                    onClick={() => {
                        // no need to set the title and bucket3 since
                        // the component will render either of the two.
                        if (chartZooms.tls.zoomLevel === 0) return false

                        dispatch(setChartZoomLevel({
                            key: 'tls',
                            value: 0
                        }))
                    }}>
                        <FaSearchMinus />
                    </div>
                </Tippy>
            </CollapsibleText>
            <MDRBarChart
                eventType={'tls'}
                requestEventType={'MDR.tls'}
                isLoading={getMdrTlsMutation.isLoading}
                isSuccess={getMdrTlsMutation.isSuccess}
                error={getMdrTlsMutation.error}
            />
        </div>
    }, undefined)

    /** moved to a separate file. Minimal requirement to pass other data. */
    const MDREventsTable = useMemo(() => {
        return (<div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {MDR_TEXT.SECTIONS.MDR_EVENTS}
                </span>
                <span className={[
                    'icon ',
                    searchParams.refetch.Events ? 'disabled' : 'pointer'
                ].join(' ')}
                onClick={() => {
                    dispatch(setRefetch({
                        key: 'Events',
                        value: true
                    }))
                }}>
                    <MdRefresh />
                </span>
            </CollapsibleText>
            <MDREventsDataTable
                addModal={addModal}
                closeModal={closeModal}
                data={getMdrEventsMutation.data}
                isLoading={getMdrEventsMutation.isLoading}
                isSuccess={getMdrEventsMutation.isSuccess}
                error={getMdrEventsMutation.error}
            />
        </div>)
    }, undefined)

    return <div>
        <div className={'justify-content-end row mt-3 mb-3'}>
            <div className={'col-auto'}>
                {CardInfo}
            </div>
        </div>
        {/* we want to see the search parameters we edit before even doing the calls.
        the important thing is that we have the latest instance of the search parameters
        AFTER the latest api call */}
        <div className={'row'}>
            <div className={'col-12 col-md px-4'}>
                <SearchRow className={'align-items-center row mb-2'}>
                    <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                        <FaSearch />
                    </label>
                    <input
                        className={'col'}
                        type={'text'}
                        defaultValue={searchParams.q}
                        id={TEXT.SEARCH.SEARCH.ID}
                        onChange={(e) => {
                            dispatch(setQ(e.target.value))
                        }}
                        /** if enter is pressed, render refresh warning */
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                // always set to true because you are pressing enter on the input
                                toggleConfirm(true)
                            }
                        }}
                        placeholder={'Search'}
                    />
                </SearchRow>
            </div>
            <div className={'col-auto pe-5 pe-lg-2'}>
                {ActionButtons}
            </div>
        </div>
        {/* filter interface */}
        <div className={'row mb-2'}>
            {/* display columns aka a filter shere */}
            <div className={'col ps-4'}>
                <div className={'row'}>
                    {
                        _.map(searchParams.boolList, (bool, index) => {
                            return (
                                <FilterCell key={'filter-' + index} className={
                                    ['col-auto me-2 px-2 mt-1',
                                        bool.not ? 'not' : ''].join(' ')
                                }>
                                    <Text size={'xs'}>
                                        {[
                                            bool.not ? '!' : '',
                                            bool.sort,
                                            ': ',
                                            bool.value
                                        ]
                                            .join('')}
                                    </Text>
                                    <Text size={'xs'} className={'ps-2'} onClick={() => {
                                        dispatch(removeBool(bool))
                                    }}>
                                        <FaTimes />
                                    </Text>
                                </FilterCell>
                            )
                        })
                    }
                </div>
            </div>
            <span
                className={[
                    'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                    searchParams.boolList.length ? 'd-block' : 'd-none'
                ].join(' ')}
                onClick={() => {
                    dispatch(removeBoolList())
                }}
            >
                {/* clear all filters button. baseline, not center */}
                <FaTimes />
            </span>
        </div>
        <div className={'row'}>
            <div className={'col-12 col-md-6'}>
                {MDRActivity}
                {MDRSnmp}
                {MDRDns}
                {MDRDhcp}
                {MDRSmb}
                {MDRHttp}
            </div>
            <div className={'col-12 col-md-6'}>
                {MDRLocations}
                {MDRTls}
                {MDRFileInfo}
                {MDRKrb5}
                {MDRIkev2}
                {MDRSsh}
            </div>
        </div>
        <div className={'min-width-fix mb-3'}>
            {/* table stuff */}
            {MDREventsTable}
        </div>
        {
            renderModals
        }
    </div>
}

export default MDRMain

// STARTING CONSTANT FOR ABORTING MUTATIONS from rtk-query.
import {
    FixedSelectWidth,
    RefreshTime,
    TableData,
    ComparisonOperator
} from '@interfaces/main/root'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'
import { ToastOptions } from 'react-toastify'
import * as Yup from 'yup'

export interface RouteConfig {
    link: string,
    name: string
}

/** formatting methods */

/**
 * format date to desired string format
    format(
        getTime(new Date('2021-09-28 06:30:00 PM')),
        "yyyy-mm-dd hh:mm:ss a"
    )
 *
    format(
        zonedTimeToUtc(
            fromUnixTime(1632825000),
            'Netherlands'
        )
        ,"yyyy-mm-dd hh:mm:ss a"
    )
 */

export const ACTION_MUTATION_PROMISE = null as unknown as MutationActionCreatorResult<any>

// URLS. for the redux toolkit query, add slash at end of string.
export const URL = {
    API: 'https://api.k8s.test.bluedogsec.com/',
    // API: 'https://api.bluedogsec.com/',
    PRINT_API: 'https://pdf-3.k8s.dev.bluedogsec.com/'
    // PRINT_API: 'https://thebackend.bluedogsec.com:8000/'
}
// MESSAGES for forms and toasts.

export const MESSAGE = {
    ERROR: {
        FORM: {
            EMAIL: '* E-mail format is incorrect.',
            EMPTY: '* Field is empty.',
            PASSWORD_MATCH: '* Passwords must match.',
            /** when an input from an order line row is empty */
            ORDER_LINE_EMPTY: '* Please have at least one order line.',
            ORDER_STATE_LS_5: '* State has to be less than equal to four.',
            SEVERITY_LS_1: '* Severity value can only be less than one',
            SEVERITY_GS_0: '* Severity value can only be greater than zero',
            FUTURE_DATE: '* Date must be in the future or today',
            ANSWER_LINE_EMPTY: '* Please select an answer.',
            ANSWER_LINE_INCOMPLETE: '* Make sure that all fields in the answer lines are filled.'
        },
        DATA: {
            CALL_FAILED: 'Error response in call.'
        }
    },
    TABLE: {
        EMPTY: 'Data is empty',
        FETCH: 'Fetching Data'
    },
    CARD: {
        LOADING: 'Getting Details'
    },
    DATA: {
        TOKEN_EXPIRED: 'Session has expired. Redirecting to login page.',
        EMPTY_DATA: 'No data found',
        EMPTY_RECORDS: 'No records',
        EMPTY_GEOIP: 'NO GEOIP DATA...',
        LASTUPDATE_FETCH: 'Fetching data from its last update.',
        LASTUPDATE_INVALID: 'Last update timestamp not found / invalid.'
    }
}

export const TEXT = {
    SIDEBAR: {
        TITLE: 'The Boss',
        FOOTER: 'Powered By'
    },
    OK: 'OK',
    YES: 'Yes',
    NO: 'No',
    NONE: 'None',
    IDLE: {
        TITLE: 'Idle Warning',
        MESSAGE: 'You have been idle for a while. Do you want to stay logged in?',
        CONTINUE: 'Continue Session'
    },
    SESSION: {
        TITLE: 'Session Expired',
        MESSAGE: 'You went beyond the session limit. Go to the login page.'
    },
    AUTHENTICATE: {
        LOG_OUT: 'Log Out'
    },
    MENU_LINKS: {
        TITLE: 'Links:'
    },
    SEQUENCE: '#',
    MODAL: {
        CLOSE: 'Close',
        CSV_EXPORT: 'Export CSV',
        PRINT_OPTIONS: 'Show Print Options'
    },
    SEARCH: {
        REFRESH: {
            LABEL: 'Refresh',
            ID: 'SEARCH__REFRESH'
        },
        SEARCH: {
            LABEL: 'Search',
            ID: 'SEARCH__SEARCH'
        },
        /** added for detailed dashboard reports */
        QUERY: {
            LABEL: 'Search Query:',
            ID: 'SEARCH__QUERY'
        },
        START: {
            TITLE: 'Select Start Date',
            LABEL: 'Start',
            ID: 'SEARCH__START_DATE'
        },
        END: {
            TITLE: 'Select End Date',
            LABEL: 'End',
            ID: 'SEARCH__END_DATE'
        }
    },
    TABLE: {
        REFRESH: 'Refresh in:',
        PAGE: 'Page Count',
        ACTIONS: 'Actions',
        BUTTONS: {
            // a button common in most interfaces.
            REMOVE: 'Remove'
        }
    },
    FORM: {
        RESET: 'Reset'
    },
    FILTER: {
        INCLUDE: 'Include Filter',
        EXCLUDE: 'Exclude Filter',
        REMOVE: 'Remove Filter',
        CLEAR_ALL: 'Clear All Filters',
        ADD: 'Add Filter',
        TOGGLE: 'Toggle Filter Interface',
        FIELDS: 'Fields',
        COMPARISON_OPERATORS: 'Comparison Operators',
        VALUES: 'Values',
        EXCLUSION: 'Add Exclusion?'
    },
    PRINT: 'Print Report',
    ZERO_ZOOM_LEVEL: 'General View',
    /** for methods that check for falsy parameters */
    MISSING_PARAMS: 'Parameters are missing... Operation aborted.'
}

/** assigning a fixed px width for expanding and collapsing the sidebar */
export const SIDEBAR_EXPANDED = 300
export const SIDEBAR_COLLAPSED = 60

/** height to enable sticky table headers and tbody scrolling
 * if no height is assigned, sticky table headers wouldn't be affected.
 * VALUE should always be relevant to the screen size height
 *
 * UPDATE: November 4, 2021 - remember not to remove/set optional for header
 * convenience when scrolling a lot of data. Nobody has the time to scroll
 * over a large table these days since people prefer filtering the results
 * and sorting before doing so.
 */
export const TABLE_CONTAINER_HEIGHT = {
    SMALL: 25,
    MEDIUM: 50
}

/** default debounce time for searching unknown number of table data */
export const DEBOUNCE_SEARCH_TIME = 500

// OPTIONS. for components to share by default.
export const TOASTIFY_DEFAULT_OPTIONS: ToastOptions = {
    position: 'top-right',
    rtl: false,
    draggable: true,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false
}

export const TOASTIFY_PRINT_OPTIONS: {
    ONGOING : ToastOptions,
    END : ToastOptions,
} = {
    ONGOING: {
        position: 'bottom-left',
        rtl: false,
        draggable: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        autoClose: false,
        progress: undefined,
        closeButton: false
    },
    END: {
        position: 'bottom-left',
        rtl: false,
        draggable: true,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false
    }
}

/** 10(minutes) * 60(seconds) unit * 1000(milliseconds) unit */
export const TIMER = {
    IDLE: 10 * 60 * 1000,
    EXPIRED: 20 * 60 * 1000
}

/** default date format */
export const DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_FORMAT_TIME = 'yyyy-MM-dd hh:mm:ss aa'

/** date format retrieved from dashboard data
 * T is a literal to separate the Date and Time.
 * 'Z' means zero hour offset. Zulu Time UTC
*/
// 'yyyy-MM-dd\'T\'hh:mm:ss.SSS\'Z\''

/** index to start the pagination */
export const PAGE_START = 1

export const ROW_COUNT_SELECTION = [
    {
        label: 10,
        value: 10
    },
    {
        label: 25,
        value: 25
    }
    // to avoid server query crashes.
    // {
    //     label: 50,
    //     value: 50
    // },
    // {
    //     label: 100,
    //     value: 100
    // },
    // {
    //     label: 150,
    //     value: 150
    // },
    // {
    //     label: 200,
    //     value: 200
    // },
    // {
    //     label: 250,
    //     value: 250
    // }
]

export const INTERVALS : RefreshTime[] = [
    // says that null works though but it does a huge loop. replaced with big number
    {
        label: 'NO REFRESH',
        value: 900000000
    },
    {
        label: '1 MINUTE',
        value: 60000
    },
    {
        label: '5 MINUTES',
        value: 300000
    },
    {
        label: '10 MINUTES',
        value: 600000
    },
    {
        label: '15 MINUTES',
        value: 900000
    },
    {
        label: '30 MINUTES',
        value: 1800000
    },
    {
        label: '1 HOUR',
        value: 3600000
    }
]

export const DEFAULT_TABLE_DATA : TableData = {
    // with rc-pagination, start with 1 instead of 0
    page: PAGE_START,
    count: ROW_COUNT_SELECTION[0].value,
    columns: [],
    interval: INTERVALS[0],
    search: '',
    filters: [],
    refetch: false,
    filtered: [],
    paginated: []

}

/** for react pagination component to replace chinese locale with english */
export const EN_US = {
    // Options.jsx
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: '',
    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages'
}
export const FIXED_REACT_SELECT_WIDTH : FixedSelectWidth = {
    SMALL: {
        container: (provided) => ({
            ...provided,
            fontSize: '0.8em',
            width: '100px'
        })
    },
    MEDIUM: {
        container: (provided) => ({
            ...provided,
            fontSize: '0.8em',
            width: '200px'
        })
    },
    LONG: {
        container: (provided) => ({
            ...provided,
            fontSize: '0.8em',
            width: '300px'
        })
    }
}

/** default preset for chart instances IF style is undefined
 * in a react lifecycle
 */
export const DEFAULT_BAR_THICKNESS = 15
export const DEFAULT_LEGEND_BOX_WIDTH = 12
export const DEFAULT_BAR_PERCENTAGE = 0.6
export const DEFAULT_CHART_PADDING = {
    x: 20,
    y: 20
}
export const DEFAULT_CHART_COLOR = 'grey'

export const GAUGE_SIZES = {
    NORMAL: {
        HEIGHT: '150px',
        WIDTH: '300px'
    },
    PRINT: {
        HEIGHT: '110px',
        WIDTH: '200PX'
    }
}

export const CHART_HEIGHT = {
    horizontalGauge: '50px',
    xs: '130px',
    sm: '150px',
    md: '250px',
    lg: '350px',
    xl: '450px'
}

export const DATASET_HEIGHT = 23

export const INTEGER_CHART_HEIGHT = {
    xs: 130,
    sm: 150,
    md: 250,
    lg: 350,
    xl: 450
}

export const CHART_INTERVAL_LIMIT = 300
export const DEFAULT_INTERVAL = '30m'
export const MAX_LINES = 6

/** i looked up chart-friendly colors. remembered what colors the client liked.
 * These are 200 elements.
 */
export const CHART_COLORS = ['#ce0e61', '#ea254c', '#a82e2a', '#cc6945', '#eaa764', '#e5b412',
    '#f9f92a', '#9aba30', '#95d64a', '#8cea67',
    '#1edd13', '#2dfc5a', '#34c173', '#4fddb0', '#6aede2', '#16c4e2', '#165075', '#2c55a0',
    '#485bc4', '#1902cc', '#661af2',
    '#5e1d91', '#c340dd', '#ea59e0', '#ed04a7', '#fc1e73', '#ad2444', '#d1413e', '#e87b5a',
    '#f27c07', '#ceb01a', '#ddd830',
    '#bfdd44', '#aeef5f', '#53f209', '#209b15', '#2dc649', '#48e288', '#63f2c0', '#0cfce0',
    '#147987', '#2a81af', '#457cd3',
    '#6470ef', '#0e0668', '#4419a0', '#8b31c4', '#d252f2', '#f96be9', '#c60d86', '#db257a',
    '#d83855', '#ef5e53', '#cc7c59',
    '#c1740f', '#d39f26', '#e9ef40', '#cdf759', '#a5d660', '#36a30e', '#35ce27', '#41ea5b',
    '#5cf9a5', '#017f4f', '#11ada2',
    '#26b4c1', '#42ace5', '#395896', '#02197f', '#1a12a8', '#592bce', '#9e47ef', '#bc54d8',
    '#ce06cb', '#e01aab', '#ef3492',
    '#f94d7b', '#db57ba', '#b22f07', '#dd761c', '#efc437', '#d6cc44', '#c3d858', '#63b208',
    '#5cd81e', '#40f43a', '#41c65c',
    '#5ddd8e', '#0bbc81', '#22e2c6', '#3dd8f7', '#3676a0', '#5588c6', '#0d22bf', '#3a24e2',
    '#4b2591', '#843fb5', '#c766e8',
    '#e812d6', '#f72ab6', '#d3398b', '#d34c69', '#e86870', '#e03d14', '#fc982d', '#bf9135',
    '#e8d955', '#aac401', '#9be817',
    '#4fa31f', '#4ecc3b', '#56e264', '#04d14b', '#1af298', '#22ad93', '#34a3ad', '#50a6ce',
    '#0657d8', '#1d4df9', '#1f1d8c',
    '#5838b7', '#9b57db', '#c709f2', '#c119c4', '#d82fb4', '#e54994', '#ed618b', '#f4100c',
    '#9e3816', '#cc722e', '#e0b54a',
    '#f7f267', '#ccfc0f', '#64a518', '#70ce31', '#59e84e', '#6af781', '#087f2e', '#1cb27b',
    '#35d6b8', '#4dd6dd', '#6cc4f7',
    '#093e77', '#1b3ca0', '#3336c6', '#7752e5', '#834caa', '#9712cc', '#d728e0', '#ed40cd',
    '#f75bb3', '#89012c', '#ad111b',
    '#cc4226', '#e88543', '#f9c15e', '#c9af04', '#9baf13', '#97d62a', '#7fef47', '#64cc4f',
    '#048e0d', '#16ba52', '#2fe090',
    '#4bf4de', '#4097a0', '#05668c', '#175bb5', '#2f51d8', '#574ff7', '#6a4ab5', '#4d08a3',
    '#ba20e5', '#f438f1', '#d345be',
    '#dd5d9f', '#bc0b49', '#d82032', '#f2623a', '#c67343', '#e2b961', '#e2bf0f', '#cfe224',
    '#abf93e', '#82ce48', '#7de263',
    '#10c916', '#27ea5b', '#2fb576', '#4cd3b4', '#5ecfd1', '#129dcc', '#2a8fed', '#25428c',
    '#4244b5', '#2801b2', '#7b15db',
    '#b92ff9', '#c93bd6', '#e557c6']

export const COMPARISON_OPERATORS: {label: string, value:ComparisonOperator}[] = [
    {
        label: 'IS',
        value: 'IS'
    },
    {
        label: 'IS NOT',
        value: 'IS NOT'
    },
    {
        label: 'IS NOT ONE OF',
        value: 'IS NOT ONE OF'
    },
    {
        label: 'IS ONE OF',
        value: 'IS ONE OF'
    }
]

export const NOT_OPERATORS: ComparisonOperator[] = [
    'IS NOT', 'IS NOT ONE OF'
]

export const MULTI_SELECTION_OPERATORS: ComparisonOperator[] = [
    'IS NOT ONE OF', 'IS ONE OF'
]

export const ASTERISK_SCHEMA = Yup.string().required(
    MESSAGE.ERROR.FORM.EMPTY
).matches(/(^\*$)/)
export const EMAIL_SCHEMA = Yup.string().required(MESSAGE.ERROR.FORM.EMPTY).email()

export const PRINT_CHECK_TIMER = 4000

import {
    useDeleteQuestionnaireMutation
} from '@apis/watchdog/virtual-ciso/questionnaire-information-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    MESSAGE as QUESTIONNAIRE_MESSAGE,
    TEXT as QUESTIONNAIRE_TEXT
} from '@constants/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    QuestionnaireModal
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import { MutationContext } from '@root/MutationProvider'
/** tables are created in separate components. */
import {
    Button,
    SpinnerContainer
} from '@styles/components'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const DeleteConfirmation = ({ modal, addModal, closeModal } : {
    modal: QuestionnaireModal,
    addModal: ActionCreatorWithPayload<QuestionnaireModal, string>,
    closeModal: ActionCreatorWithPayload<QuestionnaireModal, string>,
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const [deleteQuestionnaire, deleteQuestionnaireMutation] = useDeleteQuestionnaireMutation()

    /** call formiks */

    const submitData = useCallback(async () => {
        const newToken = await revalidateToken()

        if (modal.formData?.deleteConfirm) {
            const id = modal.formData.deleteConfirm.id

            deleteQuestionnaire({
                id: id,
                authToken: newToken
            })
        }
    }, [
        modal.formData
    ])

    /** don't close the modal since there are two forms. */
    useEffect(() => {
        if (deleteQuestionnaireMutation.data) {
            const data = deleteQuestionnaireMutation.data
            if (data.statusMessage) {
                toast.success(data.statusMessage, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(closeModal(modal))
            } else {
                toast.error(
                    QUESTIONNAIRE_MESSAGE.FAULTY_CALL,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [deleteQuestionnaireMutation.data])

    useEffect(() => {
        if (deleteQuestionnaireMutation.error) {
            console.error(deleteQuestionnaireMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [deleteQuestionnaireMutation.error])

    /** confirmation window for both delete locations AND delete m365 */
    const SubmitButton = useMemo(() => {
        const buttonContent = deleteQuestionnaireMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{QUESTIONNAIRE_TEXT.DELETE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : QUESTIONNAIRE_TEXT.DELETE.FORM.SUBMIT_BUTTON

        return (
            <Button
                mode={'primary'}
                onClick={submitData}
                disabled={deleteQuestionnaireMutation.isLoading}
            >
                {buttonContent}
            </Button>

        )
    }, [deleteQuestionnaireMutation.isLoading])

    return (<div>
        <h5>{QUESTIONNAIRE_TEXT.DELETE.TITLE}</h5>
        <small className={'d-block my-4'}>
            {QUESTIONNAIRE_TEXT.DELETE.CONFIRMATION_MESSAGE}
        </small>
        <div className={'row justify-content-end'}>
            <div className={'col-auto mb-2 mb-md-0 text-center'}>
                {SubmitButton}
            </div>
            <div className={'col-auto'}>
                <Button mode={'secondary'} onClick={() => {
                    dispatch(closeModal(modal))
                }}>{TEXT.MODAL.CLOSE}</Button>
            </div>
        </div>
    </div>)
}

export default DeleteConfirmation

import {
    M365ConfigColumn,
    VAPTConfigColumn,
    DeviceConfigColumn,
    VAPTConfigDetailColumn,
    M365ConfigKeys,
    VAPTConfigKeys,
    DeviceConfigKeys,
    VAPTConfigDetailKeys,
    M365LocationLineColumn,
    EmailAlertLineColumn,
    ConfigAction,
    DeviceConfigUpdateValues,
    SetAlertEmailsValues,
    DeviceConfigUpdateKeys,
    SetAlertEmailsKeys,
    M365ConfigUpdateValues,
    O365SetTrustedLocationValues,
    M365ConfigUpdateKeys,
    O365SetTrustedLocationKeys,
    VAPTConfigAddValues,
    VAPTConfigUpdateValues,
    VAPTConfigUpdateKeys
} from '@interfaces/watchdog/configuration/device-config'
import _ from 'lodash'
import * as Yup from 'yup'
import {
    MESSAGE as ROOT_MESSAGE,
    ASTERISK_SCHEMA,
    EMAIL_SCHEMA
} from '@constants/main/root'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Device config call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.'
}

export const TEXT = {
    SEARCH: {
        M365: 'M365 Data',
        VAPT: 'VAPT Data',
        DEVICE_CONFIG: 'Device Config Data'
    },
    /** two separate forms for updating m365 data AND locations */
    M365_UPDATE: {
        TITLE: 'M365 Details',
        CONFIRMATION: 'Are you sure you want to submit the M365 data?',
        SUCCESS: {
            TITLE: 'Update Success'
        },
        FORM: {
            DEVICEID: {
                LABEL: 'Device I.D.',
                ID: 'UPDATE__DEVICEID'
            },
            TENANTID: {
                LABEL: 'Tenant I.D.',
                ID: 'UPDATE__TENANTID'
            },
            TENANT: {
                LABEL: 'Tenant',
                ID: 'UPDATE__TENANT'
            },
            CLIENTID: {
                LABEL: 'Client I.D.',
                ID: 'UPDATE__CLIENTID'
            },
            THUMBPRINT: {
                LABEL: 'Thumbprint',
                ID: 'UPDATE__THUMBPRINT'
            },
            ACTIVE: {
                LABEL: 'Is Active?',
                ID: 'ADD__ACTIVE'
            },
            LOADING_BUTTON: 'Updating',
            SUBMIT_BUTTON: 'Update'
        }
    },
    M365_LOCATION_UPDATE: {
        TITLE: 'Locations',
        CONFIRMATION: 'Are you sure you want to submit the M365 data?',
        FORM: {
            ADD_LOCATION_LINE: 'Add Location',
            LOADING_BUTTON: 'Updating',
            SUBMIT_BUTTON: 'Update'

        }
    },
    /** add asset operation at vapt config though we need to add
     *  a condition for disabling the add button.
    */
    VAPT_ADD_ASSET: {
        TITLE: 'VAPT Details',
        /** no confirmation needed */
        FORM: {
            ASSET: {
                LABEL: 'Asset',
                ID: 'ADD__ASSET'
            },
            LOADING_BUTTON: 'Adding',
            SUBMIT_BUTTON: 'Add'
        }
    },
    /**
     * No need for a formik when performing schedules for vss and wss.
     * add a confirmation window to prevent accidental clicks.
     */
    /**
     * we do need a formik for updating an asset name
     */
    VAPT_UPDATE_ASSET: {
        TITLE: 'Update Asset',
        /** no confirmation needed */
        CONFIRMATION: 'Are you sure you want to update the asset name',
        FORM: {
            ASSET: {
                LABEL: 'Asset',
                ID: 'UPDATE__ASSET'
            },
            LOADING_BUTTON: 'Updating',
            SUBMIT_BUTTON: 'Update'
        }
    },
    VAPT_DO_SCHEDULE: {
        TITLE: 'VAPT Schdedule',
        /** no confirmation needed */
        FORM: {
            EXT_VSS: {
                LABEL: 'VSS',
                MESSAGE: 'Do you want to perform a schedule for VSS?'
            },
            EXT_WSS: {
                LABEL: 'WSS',
                MESSAGE: 'Do you want to perform a schedule for WSS?'

            },
            LOADING_BUTTON: 'Scheduling',
            SUBMIT_BUTTON: 'Schedule'
        }
    },
    /**
     * no need for a formik to remove an asset name.
     * add a confirmation window to prevent accidental clicks.
     */
    VAPT_REMOVE: {
        TITLE: 'VAPT Remove',
        CONFIRMATION: [
            'Are you sure you want to remove asset', '?'
        ],
        FORM: {
            LOADING_BUTTON: 'Removing',
            SUBMIT_BUTTON: 'Remove'
        }
    },
    /**
     * same as m365 but for device config
     */
    DEVICE_CONFIG_UPDATE: {
        TITLE: 'Device Config Details',
        CONFIRMATION: 'Are you sure you want to submit the data?',
        SUCCESS: {
            TITLE: 'Update Success'
        },
        FORM: {
            DEVICEID: {
                LABEL: 'Device I.D.',
                ID: 'UPDATE__DEVICEID'
            },
            LOCATION: {
                LABEL: 'Location',
                ID: 'UPDATE__LOCATION'
            },
            COUNTRY: {
                LABEL: 'Country',
                ID: 'UPDATE__COUNTRY'
            },
            CITY: {
                LABEL: 'City',
                ID: 'UPDATE__CITY'
            },
            LOADING_BUTTON: 'Updating',
            SUBMIT_BUTTON: 'Update'
        }
    },
    DEVICE_CONFIG_EMAIL_ALERT_UPDATE: {
        TITLE: 'Email Alerts',
        CONFIRMATION: 'Are you sure you want to submit the data?',
        FORM: {
            ADD_EMAIL_ALERT: 'Add Email Alert',
            LOADING_BUTTON: 'Updating',
            SUBMIT_BUTTON: 'Update'
        }
    }

}

export const ACTIONS: {
    /** creating ACTION buttons for VAPT_DETAILS module  */
    VAPT_DETAILS: ConfigAction[]
} = {
    VAPT_DETAILS: [
        {
            label: 'Update',
            value: 'VAPT_UPDATE_ASSET',
            mode: 'primary'
        },
        {
            label: 'Remove',
            value: 'VAPT_REMOVE_ASSET',
            mode: 'danger'
        }
    ]
}

/** columns in M365, VAPT and Device Config main data tables. */
export const COLUMNS: {
    M365: {
        MAIN: M365ConfigColumn[],
        LOCATION_LINE: M365LocationLineColumn[]
    },
    VAPT: {
        MAIN: VAPTConfigColumn[],
        DETAILS: VAPTConfigDetailColumn[]
    },
    DEVICE_CONFIG: {
        MAIN: DeviceConfigColumn[],
        EMAIL_ALERT: EmailAlertLineColumn[]
    }
} = {
    M365: {
        MAIN: (() => {
            const columnNames: Omit<M365ConfigColumn, 'arrange' | 'include'>[] = [
                {
                    label: 'Device I.D.',
                    value: 'deviceId'
                }, {
                    label: 'Location',
                    value: 'location.location'
                }, {
                    label: 'Country',
                    value: 'location.country'
                },
                {
                    label: 'City',
                    value: 'location.city'
                }
            ]

            /**
             * it doesn't need to be these values all the time
             * so you can check the column.value property and apply
             * your default values.
             * */
            const result: M365ConfigColumn[] = _.map(columnNames, (column) => {
                return {
                    ...column,
                    arrange: 'asc',
                    include: false
                }
            })

            return result
        })(),
        LOCATION_LINE: (() => {
            const columnNames: Omit<M365LocationLineColumn, 'arrange' | 'include'>[] = [
                {
                    label: 'Country',
                    value: 'country'
                }, {
                    label: 'City',
                    value: 'city'
                }, {
                    label: 'Email',
                    value: 'user'
                }
            ]

            /**
             * it doesn't need to be these values all the time
             * so you can check the column.value property and apply
             * your default values.
             * */
            const result: M365LocationLineColumn[] = _.map(columnNames, (column) => {
                return {
                    ...column,
                    arrange: 'asc',
                    include: false
                }
            })

            return result
        })()
    },
    VAPT: {
        MAIN: (() => {
            const columnNames: Omit<VAPTConfigColumn, 'arrange' | 'include'>[] = [
                {
                    label: 'Device I.D.',
                    value: 'deviceId'
                }, {
                    label: 'Type',
                    value: 'theType'
                }, {
                    label: 'Location',
                    value: 'location.location'
                }, {
                    label: 'Country',
                    value: 'location.country'
                },
                {
                    label: 'City',
                    value: 'location.city'
                }
            ]

            /**
             * it doesn't need to be these values all the time
             * so you can check the column.value property and apply
             * your default values.
             * */
            const result: VAPTConfigColumn[] = _.map(columnNames, (column) => {
                return {
                    ...column,
                    arrange: 'asc',
                    include: false
                }
            })

            return result
        })(),
        DETAILS: (() => {
            const columnNames: Omit<VAPTConfigDetailColumn, 'arrange' | 'include'>[] = [
                {
                    label: 'Device I.D.',
                    value: 'deviceId'
                }, {
                    label: 'Asset Name',
                    value: 'assetName'
                }
            ]

            /**
             * it doesn't need to be these values all the time
             * so you can check the column.value property and apply
             * your default values.
             * */
            const result: VAPTConfigDetailColumn[] = _.map(columnNames, (column) => {
                return {
                    ...column,
                    arrange: 'asc',
                    include: false
                }
            })

            return result
        })()
    },
    DEVICE_CONFIG: {
        MAIN: (() => {
            const columnNames: Omit<DeviceConfigColumn, 'arrange' | 'include'>[] = [
                {
                    label: 'Device I.D.',
                    value: 'deviceId'
                }, {
                    label: 'Type',
                    value: 'theType'
                }, {
                    label: 'Location',
                    value: 'location.location'
                }, {
                    label: 'Country',
                    value: 'location.country'
                },
                {
                    label: 'City',
                    value: 'location.city'
                }
            ]

            /**
             * it doesn't need to be these values all the time
             * so you can check the column.value property and apply
             * your default values.
             * */
            const result: DeviceConfigColumn[] = _.map(columnNames, (column) => {
                return {
                    ...column,
                    arrange: 'asc',
                    include: false
                }
            })

            return result
        })(),
        EMAIL_ALERT: (() => {
            const columnNames: Omit<EmailAlertLineColumn, 'arrange' | 'include'>[] = [
                {
                    label: 'Name',
                    value: 'name'
                }, {
                    label: 'Email',
                    value: 'email'
                }, {
                    label: 'Active',
                    value: 'active'
                }
            ]

            /**
             * it doesn't need to be these values all the time
             * so you can check the column.value property and apply
             * your default values.
             * */
            const result: EmailAlertLineColumn[] = _.map(columnNames, (column) => {
                return {
                    ...column,
                    arrange: 'asc',
                    include: false
                }
            })

            return result
        })()
    }
}

export const KEYS: {
    M365:{
        MAIN: M365ConfigKeys[]
    },
    DEVICE_CONFIG:{
        MAIN: DeviceConfigKeys[]

    },
    VAPT:{
        MAIN: VAPTConfigKeys[],
        DETAILS: VAPTConfigDetailKeys[]

    }
} = {
    DEVICE_CONFIG: {
        MAIN: [
            'deviceId', 'theType', 'location.location', 'location.country', 'location.city'
        ]
    },
    M365: {
        MAIN: [
            'deviceId', 'location.location', 'location.country', 'location.city'
        ]

    },
    VAPT: {
        MAIN: [
            'deviceId', 'theType', 'location.location', 'location.country', 'location.city'
        ],
        DETAILS: [
            'deviceId', 'assetName'
        ]
    }
}

export const INITIAL_VALUES: {
    DEVICE_CONFIG: {
        UPDATE: DeviceConfigUpdateValues,
        EMAIL_ALERT:SetAlertEmailsValues
    },
    M365: {
        UPDATE: M365ConfigUpdateValues,
        LOCATIONS: O365SetTrustedLocationValues
    },
    VAPT: {
        ADD: VAPTConfigAddValues,
        UPDATE: VAPTConfigUpdateValues
    }
} = {
    DEVICE_CONFIG: {
        UPDATE: {
            deviceId: '', /** readonly */
            city: '',
            country: '',
            location: ''
        },
        EMAIL_ALERT: {
            emailAlerts: []
        }
    },
    M365: {
        UPDATE: {
            active: false,
            clientId: '',
            deviceId: '',
            tenant: '',
            tenantId: '',
            thumbprint: ''
        },
        LOCATIONS: {
            locations: []
        }
    },
    VAPT: {
        ADD: {
            assetName: '',
            deviceId: ''
        },
        UPDATE: {
            assetName: '',
            deviceId: ''
        }
    }
}

export const VALIDATION_SCHEMA: {
    DEVICE_CONFIG: {
        UPDATE: OptionalObjectSchema<Record<DeviceConfigUpdateKeys, Yup.AnySchema>>
        EMAIL_ALERT: OptionalObjectSchema<Record<SetAlertEmailsKeys, Yup.AnySchema>>
    },
    M365: {
        UPDATE: OptionalObjectSchema<Record<M365ConfigUpdateKeys, Yup.AnySchema>>,
        LOCATIONS: OptionalObjectSchema<Record<O365SetTrustedLocationKeys, Yup.AnySchema>>
    },
    VAPT: {
        ADD: OptionalObjectSchema<Record<VAPTConfigUpdateKeys, Yup.AnySchema>>,
        UPDATE: OptionalObjectSchema<Record<VAPTConfigUpdateKeys, Yup.AnySchema>>
    }
} = {
    DEVICE_CONFIG: {
        EMAIL_ALERT: Yup.object({
            emailAlerts: Yup.array().of(
                Yup.object().shape({
                    active: Yup.boolean().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
                    name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
                    /** wildcard exceptions. */
                    // email: Yup.string().required(MESSAGE.ERROR.FORM.EMPTY),
                    email: Yup.string().test(async (value) => {
                        if (
                            (await ASTERISK_SCHEMA.isValid(value)) ||
                          (await EMAIL_SCHEMA.isValid(value))
                        ) {
                            return true
                        } else {
                            return false
                        }
                    })
                    // email: Yup.string()
                    // .email(MUST_BE_EMAIL_MESSAGE)
                    // .required(MESSAGE.ERROR.FORM.EMPTY),
                })
            )
        }),
        UPDATE: Yup.object({
            deviceId: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            location: Yup.string()
        })
    },
    M365: {
        LOCATIONS: Yup.object({
            locations: Yup.array().of(
                Yup.object().shape({
                    // city can be optional. the rest are mandatory
                    city: Yup.string(),
                    country: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
                    /** wildcard exceptions. */
                    email: Yup.string().test(async (value) => {
                        if (
                            (await ASTERISK_SCHEMA.isValid(value)) ||
                          (await EMAIL_SCHEMA.isValid(value))
                        ) {
                            return true
                        } else {
                            return false
                        }
                    })
                    // email: Yup.string().email(MUST_BE_EMAIL_MESSAGE)
                    // .required(MESSAGE.ERROR.FORM.EMPTY),
                })
            )
        }),
        UPDATE: Yup.object({
            deviceId: Yup.string(),
            tenantId: Yup.string(),
            tenant: Yup.string(),
            clientId: Yup.string(),
            thumbprint: Yup.string(),
            active: Yup.boolean().default(false)
        })
    },
    VAPT: {
        ADD: Yup.object({
            assetName: Yup.string(),
            deviceId: Yup.string()
        }),
        UPDATE: Yup.object({
            assetName: Yup.string(),
            deviceId: Yup.string()
        })
    }
}

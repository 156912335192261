import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT,
    TIMER
} from '@constants/main/root'
import { LOGIN_ROUTES } from '@constants/main/routes'
import { MutationContext } from '@root/MutationProvider'
import {
    selectShowExpire,
    selectShowIdle,
    selectToken,
    setShowExpire,
    setShowIdle
} from '@slices/main/token'
import { Button } from '@styles/components'
import { push } from 'connected-react-router'
import React, {
    useContext,
    useEffect
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useIdleTimer } from 'react-idle-timer'
import Modal from 'react-responsive-modal'

const IdleWindow = () => {
    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)
    const showIdle = useAppSelector(selectShowIdle)
    const showExpire = useAppSelector(selectShowExpire)

    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    /**
     * gist is that when idle timer expires, show a modal where users are given an option
     * to continue the session OR log out of the page. if expiration timer expires, the
     * user is deauthenticated and redirected to the login page.
     *
     * to continue session, simply invoke doAuth api call.
     * to deauthenticate, just redirect to logout page.
     */

    const idleSession = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: TIMER.IDLE,
        // timeout: 3000,
        onIdle: () => {
            dispatch(setShowIdle(true))
        }
    })

    const expireSession = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: TIMER.EXPIRED,
        // timeout: 6000,
        onIdle: () => {
            dispatch(setShowIdle(false))
            dispatch(setShowExpire(true))
            dispatch(push(LOGIN_ROUTES.LOGOUT.link))
        }
    })

    /** happens for every token revalidation. */
    useEffect(() => {
        if (token.valid) {
            // start the idle and expiration timers.
            idleSession.reset()
            expireSession.reset()
        } else {
            /** pause the timers */
            idleSession.pause()
            expireSession.pause()
        }
    }, [token.valid])

    return (
        <div>
            <Modal open={Boolean(showIdle)} center focusTrapped={false}
                classNames={{
                    modal: ['xs', 'warning'].join(' ')
                }}
                onClose={async () => {
                    // assumes that you also want to revalidate.
                    dispatch(setShowIdle(false))
                    await revalidateToken()
                }}
                closeIcon={<AiOutlineClose />}>
                {/* content changes if idle / expire sessions expired. */}
                <div className={'container-fluid'}>
                    <h5>{TEXT.IDLE.TITLE}</h5>
                    <small className={'d-block my-4'}>
                        {TEXT.IDLE.MESSAGE}
                    </small>
                    <div className={'row justify-content-end'}>
                        <div className={'col-auto mb-2 mb-md-0'}>
                            <Button mode={'primary'} onClick={async () => {
                                dispatch(setShowIdle(false))
                                await revalidateToken()
                            }}>
                                {TEXT.IDLE.CONTINUE}
                            </Button>
                        </div>
                        <div className={'col-auto'}>
                            <Button mode={'secondary'} onClick={() => {
                                dispatch(setShowIdle(false))
                                dispatch(push(LOGIN_ROUTES.LOGOUT.link))
                            }}>{TEXT.AUTHENTICATE.LOG_OUT}</Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal open={Boolean(showExpire)} center focusTrapped={false}
                classNames={{
                    modal: ['xs', 'warning'].join(' ')
                }}
                onClose={() => {
                    dispatch(setShowExpire(false))
                }}
                closeIcon={<AiOutlineClose />}>
                {/* content changes if idle / expire sessions expired. */}
                <div className={'container-fluid'}>
                    <h5>{TEXT.SESSION.TITLE}</h5>
                    <small className={'d-block my-4'}>
                        {TEXT.SESSION.MESSAGE}
                    </small>
                    <div className={'row justify-content-end'}>
                        <div className={'col-auto'}>
                            <Button mode={'primary'} onClick={() => {
                                dispatch(setShowExpire(false))
                            }}>{TEXT.OK}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default IdleWindow

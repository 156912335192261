
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import { TEXT } from '@constants/main/root'
import { NavigationTab } from '@interfaces/main/root'
import { selectRouter } from '@slices/main/router'
import { LinksRow } from '@styles/components'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React from 'react'

const MenuLinks = ({ tabs }: { tabs: NavigationTab[] }) => {
    const dispatch = useAppDispatch()

    const router = useAppSelector(selectRouter)

    return (
        <LinksRow className={'row'}>
            <ul>
                <li>{TEXT.MENU_LINKS.TITLE}</li>
                {
                    _.map(tabs, ({ name, link }, index) => {
                        /** add selected className AND remove onClick event if link matches url */
                        const match = link === router.location.pathname

                        return (
                            <li
                                key={['passwordTabs-', index].join('')}
                                className={[match ? 'selected' : ''].join(' ')}
                                onClick={() => {
                                    !match && dispatch(push(link))
                                }}
                            >
                                {'[ '}{name} {']'}
                            </li>
                        )
                    })
                }
            </ul>
        </LinksRow>
    )
}

export default MenuLinks

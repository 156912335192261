import { CHART_ZOOM } from '@constants/dashboard/monitor'
import {
    Doc,
    SearchParams
} from '@interfaces/dashboard/monitor'
import {
    EventType,
    EventTypeBooleans,
    EventTypeChartZooms,
    IssueAlert,
    O365SearchParams,
    O365DetailKeys
} from '@interfaces/dashboard/soc/o365/main'
import {
    getUnixTime,
    sub
} from 'date-fns'
import _ from 'lodash'

/** even if they are in the main modal, you want to individually refresh these charts. */
export const EVENT_TYPES: EventType[] = ['modal.Header', 'User.Activity', 'Azure.AD',
    'Issues.Detected', 'Files.Access', 'Files.Shared', 'Policy.Related', 'Files.Malicious',
    'Office365.Users']

export const DEFAULT_REFETCH = _.zipObject(
    EVENT_TYPES, Array(EVENT_TYPES.length).fill(false)
) as EventTypeBooleans

/** modal header doesn't have a chart so remove it. */
export const DEFAULT_CHART_ZOOMS = _.zipObject(
    _.without(EVENT_TYPES, 'modal.Header'),
    _.map(EVENT_TYPES, () => {
        return _.cloneDeep(CHART_ZOOM)
    })
) as EventTypeChartZooms

export const INITIAL_MAIN_SEARCH_PARAMS: O365SearchParams = {
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: _.cloneDeep(DEFAULT_REFETCH)
}

export const INITIAL_SEARCH_PARAMS: SearchParams = {
    ranges: {
        start: getUnixTime(sub(new Date(), { days: 1 })),
        end: getUnixTime(new Date())
    },
    refetch: false
}

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'O365 call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    FETCH: {
        MODAL_HEADER: 'Fetching Header data...',
        USER_ACTIVITY: 'Fetching User Activity data...',
        AZURE_AD: 'Fetching Azure Active Directory data...',
        ISSUES_DETECTED: 'Fetching Issues Detected data...',
        FILES_ACCESS: 'Fetching Files Access data...',
        FILES_SHARED: 'Fetching Files Shared data...',
        POLICY_RELATED: 'Fetching Policy Related data...',
        FILES_MALICIOUS: 'Fetching Files Malicious data...',
        OFFICE_365_USERS: 'Fetching Office 365 Users data...',
        MODAL_DETAILS: 'Fetching modal details...',
        DATA_ID: 'Fetching data id...'
    }
}

export const TEXT = {
    PRINT_OPTIONS: {
        MAIN_REPORT_ONLY: {
            LABEL: 'Main Report Only',
            ID: 'PRINT_OPTIONS__MAIN_REPORT_ONLY'
        },
        LOGO: {
            LABEL: 'Image URL',
            ID: 'PRINT_OPTIONS__LOGO'
        }
    },
    SECTIONS: {
        MODAL_HEADER: 'Header',
        USER_ACTIVITY: 'User Access',
        AZURE_AD: 'Active Directory Activity',
        ISSUES_DETECTED: 'Detected Issues',
        FILES_ACCESS: 'Sharepoint And OneDrive File Access',
        FILES_SHARED: 'Shared Files',
        POLICY_RELATED: 'Policy Related Events',
        FILES_MALICIOUS: 'Potentially Malicious Files',
        OFFICE_365_USERS: 'Active Directory Users'
    }
}

/** for charts. */
export const AZURE_AD_COLUMNS: {label: string, value: keyof Doc }[] = [
    {
        label: 'Operation',
        value: 'key'
    },
    {
        label: 'Count',
        value: 'doc_count'
    }
]

export const FILES_ACCESS_COLUMNS: {label: string, value: keyof Doc }[] = AZURE_AD_COLUMNS
export const FILES_MALICIOUS_COLUMNS: {label: string, value: keyof Doc }[] = AZURE_AD_COLUMNS
export const FILES_SHARED_COLUMNS: {label: string, value: keyof Doc }[] = AZURE_AD_COLUMNS
export const POLICY_RELATED_COLUMNS: {label: string, value: keyof Doc }[] = AZURE_AD_COLUMNS
export const USER_ACTIVITY_COLUMNS: {label: string, value: keyof Doc }[] = AZURE_AD_COLUMNS

export const ISSUES_DETECTED_COLUMNS: {label: string, value: keyof IssueAlert['_source'] }[] = [
    /** will check if there is 'Title', 'operation' and 'title' soon */
    {
        label: 'Name',
        value: 'Operation'
    },
    {
        label: 'Timestamp',
        value: '@timestamp'
    }
]

export const DEFAULT_ISSUE_ALERT: IssueAlert = {
    _id: '',
    _index: '',
    _score: 0,
    _source: {
        CreationTime: '',
        Id: '',
        Operation: '',
        OrganizationId: '',
        RecordType: 0,
        ResultStatus: '',
        UserKey: '',
        UserType: 0,
        Version: 0,
        Workload: '',
        ObjectId: '',
        UserId: '',
        AzureActiveDirectoryEventType: 0,
        ExtendedProperties: [],
        ModifiedProperties: [],
        Actor: [],
        ActorContextId: '',
        InterSystemsId: '',
        IntraSystemId: '',
        SupportTicketId: '',
        Target: [],
        TargetContextId: '',
        contentUri: '',
        contentId: '',
        contentType: '',
        contentCreated: '',
        contentExpiration: '',
        '@timestamp': '',
        event_type: '',
        type: '',
        in_iface: ''
    },
    _type: ''

}

/** DEFAULT TEMPLATES FOR DISPLAYING DATA. _.cloneDeep */
export const O365_DETAIL_KEYS: O365DetailKeys[] = [
]

import { CONFIGURATION_STATUS_ROUTES } from '@constants/main/routes'
import ConfigMenu from
    '@features/watchdog/configuration/device-configuration/ConfigMenu'

const Routes: {exact:boolean, path:string, component: () => JSX.Element} [] = [
    {
        exact: true,
        path: CONFIGURATION_STATUS_ROUTES.DEVICE_CONFIG.link,
        component: ConfigMenu
    }
]

export default Routes

import {
    QuestionnaireColumn,
    QuestionnaireAction,
    QuestionnaireKeys,
    QuestionKeys,
    QuestionColumn,
    AnswerQuestionValues,
    AnswerQuestionKeys
} from '@interfaces/watchdog/virtual-ciso/compliance-questionnaire'
import _ from 'lodash'
import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'

import * as Yup from 'yup'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Question call returned faulty.',
    ANSWER: {
        EMPTY: '* Please select an answer.',
        INCOMPLETE: '* Make sure that all fields in the answer lines are filled.'
    }
}

export const TEXT = {
    ANSWER: {
        /** confirmation label for answering a question */
        TITLE: 'Answer Question Confirmation',
        /** confirmation message for answering a question */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit your answer?',
        FORM: {
            COMMENT: {
                LABEL: 'Comment',
                ID: 'ADD__COMMENT'
            },
            PROOFFILE_ID: 'ID:',
            REMOVE_PROOF_FILE: 'Remove',
            UPLOAD_PROOF_FILE: {
                LABEL: 'Upload',
                ID: 'UPLOAD__BUTTON'
            },
            ADD_PROOF_FILE: 'Add',
            LOADING_BUTTON: 'Submitting Answer',
            SUBMIT_BUTTON: 'Submit',
            REFRESH_BUTTON: 'Refresh Data'
        }
    }
}

export const ACTIONS: {
    QUESTIONNAIRE: QuestionnaireAction[],
    QUESTION: QuestionnaireAction[],
} = {
    QUESTIONNAIRE: [
        {
            label: 'Questions',
            value: 'COMPLIANCE_VIEW',
            mode: 'primary'
        }
    ],
    QUESTION: [
        {
            label: 'Answer',
            value: 'COMPLIANCE_ANSWER',
            mode: 'primary'
        }
    ]
}

export const COLUMNS: {
    QUESTIONNAIRE: QuestionnaireColumn[],
    QUESTION: QuestionColumn[],
} = {
    QUESTIONNAIRE: (() => {
        const columnNames: Omit<QuestionnaireColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Questionnaire Name',
                value: 'questionnaire_name'
            }, {
                label: 'User Name',
                value: 'user_name'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: QuestionnaireColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })(),
    QUESTION: (() => {
        const columnNames: Omit<QuestionColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Subcategories',
                value: 'compliance'
            }, {
                label: 'Title',
                value: 'title'
            }, {
                label: 'Question',
                value: 'question'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: QuestionColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })()

}

export const KEYS: {
    QUESTIONNAIRE: QuestionnaireKeys[],
    QUESTION: QuestionKeys[],
} = {
    QUESTIONNAIRE: [
        'questionnaire_name', 'user_name'
    ],
    QUESTION: [
        'compliance', 'title', 'question'
    ]
}

export const INITIAL_VALUES: {
    ANSWER: AnswerQuestionValues
} = {
    ANSWER: {
        // answer from the dropdown/checkbox/radio button. a question
        // can have multiple answers. (because of the checkboxes choice)
        answers: [],
        comment: '',
        // an array of proof files. contains an array of fileids are each upload.
        proofFiles: []
    }
}

export const VALIDATION_SCHEMA: {
    ANSWER: OptionalObjectSchema<Record<AnswerQuestionKeys, Yup.AnySchema>>
} = {
    ANSWER: Yup.object({
        answers: Yup.array().required(MESSAGE.ANSWER).of(
            Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
        ),
        comment: Yup.string(),
        proofFiles: Yup.array().notRequired()
        // proofFiles: Yup.array().required(MISSING_FIELD_MESSAGE).of(
        //     Yup.string().required(MISSING_FIELD_MESSAGE)
        // ),
    })
}

import { TEXT } from '@constants/main/root'
import React, {
    forwardRef,
    HTMLProps
} from 'react'
import { FaCalendar } from 'react-icons/fa'
import PropTypes from 'prop-types'

import { Text } from '@styles/components'

const DatePickerStartIcon = forwardRef<HTMLLabelElement, HTMLProps<HTMLLabelElement>>(
    (props, ref) => (
        <label
            className={'icon small pointer ms-2 mb-2'}
            htmlFor={TEXT.SEARCH.START.ID}
            onClick={props.onClick}
        >
            <Text size={'xs'} className={'pe-1'}>
                {TEXT.SEARCH.START.LABEL}
            </Text>
            <FaCalendar />
        </label>
    )
)
DatePickerStartIcon.displayName = 'DatePickerStartIcon'
DatePickerStartIcon.propTypes = {
    onClick: PropTypes.func
}

const DatePickerEndIcon = forwardRef<HTMLSpanElement, HTMLProps<HTMLSpanElement>>(
    (props, ref) => (
        <label
            className={'icon small pointer ms-2 mb-2'}
            htmlFor={TEXT.SEARCH.END.ID}
            onClick={props.onClick}
        >
            <Text size={'xs'} className={'pe-1'}>
                {TEXT.SEARCH.END.LABEL}
            </Text>
            <FaCalendar />
        </label>
    )
)
DatePickerEndIcon.displayName = 'DatePickerEndIcon'
DatePickerEndIcon.propTypes = {
    onClick: PropTypes.func
}

export { DatePickerStartIcon, DatePickerEndIcon }

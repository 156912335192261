import { useGetMenuMutation } from '@apis/main/sidebar-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import { PAGE_TAB_SELECTIONS } from '@constants/main/sidebar'
import {
    MenuList,
    MenuListValue
} from '@interfaces/main/sidebar'
import { MutationContext } from '@root/MutationProvider'
import { selectRouter } from '@slices/main/router'
import {
    selectMode
} from '@slices/main/settings'
import {
    selectActiveTab,
    selectMenu,
    selectShowSidebar,
    setActiveTab,
    setMenu,
    toggleSidebar
} from '@slices/main/sidebar'
import { selectToken } from '@slices/main/token'
import { SidebarStyledComponents as Sidebar } from '@styles/components'
import Tippy from '@tippyjs/react'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React, {
    useContext,
    useEffect,
    useMemo
} from 'react'
import * as Ri from 'react-icons/ri'
import * as Fa from 'react-icons/fa'
import { toast } from 'react-toastify'
import {
    getUnixTime,
    sub
} from 'date-fns'

import { EVENT_ROUTES } from '@constants/main/routes'
import { DetailedSearchParams } from '@interfaces/dashboard/monitor'
/**
 * create a sidebar component with menu items.
 * making an example of adding interface to response data
 * instead of doing it in rtk-query api initializations.
 */

const SidebarComponent = () => {
    const dispatch = useAppDispatch()

    const menu = useAppSelector(selectMenu)
    const token = useAppSelector(selectToken)
    const showSidebar = useAppSelector(selectShowSidebar)
    const activeTab = useAppSelector(selectActiveTab)
    const router = useAppSelector(selectRouter)
    const mode = useAppSelector(selectMode)

    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const setMdrDetailedDashboardParams = rootContext.setMdrDetailedDashboardParams
    const setO365DetailedDashboardParams = rootContext.setO365DetailedDashboardParams
    const setAzureDetailedDashboardParams = rootContext.setAzureDetailedDashboardParams

    /**
     * note: this instance is completely different from anything else
     * since we are using unwrap, result data from destruction is not used here.
     * */
    const [getMenu, getMenuResult] = useGetMenuMutation()

    /**
     * this is the case where you end up using 'any'
     * in a feasible route if enforcing typescript.
     */
    const getFolder:any = (subfolder: string) => {
        switch (subfolder) {
            case 'ri': return Ri
            case 'fa': return Fa
            default: return Ri
        }
    }
    /**
     * expected parameters are object entries of a MenuListItem type
     * and the object entries array of the menuListObj type.
     * */
    const renderMenuItemRow = (
        menuObj: MenuListValue,
        menuTitle: string,
        menuCollection: MenuList
    ) => {
        /** select the directory and use it to retrive the icon */
        const directory = getFolder(menuObj.categoryIconFolder)
        const icon = directory[menuObj.categoryIcon]()

        /**
         * create a container that populates subcategories
         * show tippy in small responsive sizes.
         * */

        const expandRow = () => {
            /**
             * click event will perform the following:
             * 1.) collapse all menu tabs except the selected one
             * 2.) toggle the selected tab.
             */

            _.forEach(
                _.filter(menuCollection, (_value, key) => {
                    return key !== menuTitle
                }), (collectionValue) => {
                    // collectionValue.expand = false
                    collectionValue.expand = true
                })

            // menuObj.expand = !menuObj.expand
            menuObj.expand = true
            /**
             * now update the menu list with the changes. if you didn't cloneDeep
             * the menu list, we would have gotten so many unwanted render lifecycles
             * which can hamper performance PLUS a read-only property restriction.
             */
            dispatch(setMenu(menuCollection))
        }

        const row = (
            <Sidebar.MenuItemRow
                onClick={expandRow}
                type={'subtitle'}
                className={'row align-items-center'}>
                <div className={'col-auto'}>{icon}</div>
                {
                    showSidebar
                        ? (
                            <>
                                <div className={'col pe-0'}>
                                    <span>{menuTitle}</span>
                                </div>
                                <div className={'col-auto ps-0 text-end'}>
                                    {/* {menuObj.expand ? <Ri.RiArrowUpSLine/> :
                                    <Ri.RiArrowDownSLine/>} */}
                                </div>
                            </>
                        )
                        : ''
                }

            </Sidebar.MenuItemRow>
        )

        const collapsedRow = (
            <Tippy
                className={'tippy-box'}
                // interactive
                arrow
                // hideOnClick
                // trigger={'click'}
                content={<div>{menuTitle}</div>}>
                {row}
            </Tippy>
        )
        return (
            <div key={
                menuTitle.replace(' ', '-')
                    .toLowerCase()
            }>
                {showSidebar ? row : collapsedRow}
                {/* if menuObj.expand is true, also iterate the menu items. */}
                {menuObj.expand
                    ? (
                        _.map(menuObj.pages, ({ pageIcon, pageIconFolder, pageLink, pageName }) => {
                            const outputDirectory = getFolder(pageIconFolder)
                            const outputIcon = outputDirectory[pageIcon]()

                            const onClick = () => {
                                /**
                                 * click event will perform the following:
                                 * 1.) push to history
                                 * 2.) set a selected tab based on the link.
                                 * a tab can be determined as selected by multiple
                                 * links. this can be done by using the pageName
                                 * and populating hardcoded links to compare
                                 * ex: {
                                 *  pageName: "Events",
                                 *  links: [
                                 *   "/current-events",
                                 *   "escalated-events"
                                 *  ]
                                 * }
                                 * for detailed dashboard with a specified deviceid:
                                 * call the slice to set the localStorage.
                                 * */

                                /** a method to set default search parameters
                                 *  at detailed dashboards. */
                                const startDate = getUnixTime(
                                    sub(
                                        new Date(),
                                        { days: 1 }
                                    )
                                )
                                const endDate = getUnixTime(new Date())

                                /**
                                 * BUG: detailedDashboard has previous searchParams
                                 * navigating to soc doesn't overwrite the previous data.
                                 */

                                const params: Omit<DetailedSearchParams, 'refetch'> = {
                                    ranges: {
                                        start: startDate,
                                        end: endDate
                                    },
                                    card: {
                                        details: {
                                            colorType: 'blue'
                                        },
                                        /** required if you are clicking from the
                                         * sidebar component.
                                         */
                                        deviceid: 'soc',
                                        in_face: '',
                                        service_type: 'bdg-bas'
                                    },
                                    q: '',
                                    boolList: []
                                }

                                if (pageLink.includes('/mdr/detailed-dashboard')) {
                                    params.card.service_type = 'bdg-mdr'
                                    setMdrDetailedDashboardParams(params)
                                } else if (pageLink.includes('/o365/detailed-dashboard')) {
                                    params.card.service_type = 'bdg-o365'
                                    setO365DetailedDashboardParams(params)
                                } else if (pageLink.includes('/azure/detailed-dashboard')) {
                                    params.card.service_type = 'bdg-o365-azure'
                                    setAzureDetailedDashboardParams(params)
                                }

                                dispatch(push(pageLink))
                            }

                            const linkRow = (
                                <Sidebar.MenuItemRow
                                    selected={_.includes(activeTab.links, pageLink)}
                                    onClick={onClick}
                                    type={'subtitle'}
                                    className={'row align-items-center ps-2'}>
                                    <div className={'col-auto'}>{outputIcon}</div>
                                    {
                                        showSidebar
                                            ? (
                                                <>
                                                    <div className={'col pe-0'}>
                                                        <span>{pageName}</span>
                                                    </div>
                                                </>
                                            )
                                            : ''
                                    }
                                </Sidebar.MenuItemRow>
                            )

                            const collapsedLinkRow = (
                                <Tippy
                                    className={'tippy-box'}
                                    // interactive
                                    arrow
                                    // hideOnClick
                                    // trigger={'click'}
                                    content={<div>{pageName}</div>}>
                                    {linkRow}
                                </Tippy>
                            )

                            return (
                                <div key={
                                    pageName.replace(' ', '-')
                                        .toLowerCase()
                                }>
                                    {showSidebar ? linkRow : collapsedLinkRow}
                                </div>
                            )
                        })
                    )
                    : ''}
            </div>
        )
    }

    const MenuList = useMemo(() => {
        let entireMenuList : MenuList = {}

        if (Object.keys(menu).length) {
            entireMenuList = _.cloneDeep(menu)
        }

        return _.map(entireMenuList, renderMenuItemRow)
    }, [menu, showSidebar, activeTab])

    const Footer = useMemo(() => {
        const expand = <>
            <div className={'col-auto'}>
                <Sidebar.Footer>
                    {TEXT.SIDEBAR.FOOTER}
                </Sidebar.Footer>
            </div>
            <div className={'col-auto'}>
                <Sidebar.FooterLogo className={''} src={mode === 'dark'
                    ? '/media/dark-theme.svg'
                    : '/media/light-theme.svg'}
                alt={'Bluedog Logo'} role={'button'} />
            </div>
        </>

        const collapse = <>
            <div className={'col-auto'}>
                <Sidebar.FooterLogo className={''} src={'/media/logo192.png'}
                    alt={'Bluedog Logo'} role={'button'} />
            </div>
        </>

        return (
            <div className={'align-items-center flex-column mb-4 row'}>
                {showSidebar ? expand : collapse}
            </div>
        )
    }, [mode, showSidebar])

    /**
     * 1.) fetch menu list only once after mount.
     * 2.) ideally you'd want to revalidate the token before making the call.
     * so even if async or not, when you call tokenValid, the getMenu api
     * call will always use the previous token, because the current lifecycle
     * uses that.
     * 3.) you can't abort calls if they are going to be in an async/await
     * catch block from the rtk-query api
     * 4.) all react lifecycles in each component on a browser refresh
     * are run simultanetously. Because of this, you can't check for
     * tokenValid.state.isUninitialized to not make the call.
     * 5.) just wanted to see if RTK Query hooks also observe the
     * same lifecycle with the Redux store. In this case, the token data
     * from the latter (should be invalidated after a recent token
     * validation call ) should be different from the former. the mutation
     * follows the react flow. You are better off using unwrap, no longer
     * relying on one reference to make validation calls.
     * 6.) create another instance of the tokenValidMutation and use unwrap
     * and set the token only after the wanted call is finished.
     *
     * */

    /**
     * dependency is token.valid where we only accept valid tokens. token string
     * doesn't matter. so after authentication, the token at the lifecycle
     * revalidates subsequently making the sidebar api call. The token should have been
     * set before doing this
     */

    const unsubscribeGetMenu = () => {
        const unsubscribeMutation = getMenu({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        unsubscribeGetMenu()
        /**
         * mutationActionCreatorResult is a promise therefore an object.
         * This will be clonedeep to avoid unwanted abort calls from other
         * components' cleanup functions
         * */
        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        // let's check if the token is valid.
        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                /**
                 * if the new token is valid, you can call the sidebar api
                 * while passing that token to it. while that is happening
                 * you can set the token to the state.even if the api
                 * throws an error due to abort, connection issues, the
                 * new token is set
                 */
                if (isMounted) {
                    promise = getMenu({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }, [token.valid])

    /**
     * even if activeTab isn't added as a dependency, there will be a value
     * by the time, the api call is finished.
     * */
    useEffect(() => {
        if (getMenuResult.data) {
            const entireMenuList = _.cloneDeep(getMenuResult.data)

            /** also, add a new property called 'expand' to each value pair */
            _.forEach(_.values(entireMenuList), (menuListObj) => {
                // const found = _.find(menuListObj.pages, (pageObj) =>
                //     pageObj.pageName === activeTab.name
                // )
                // menuListObj.expand = Boolean(found)
                menuListObj.expand = true
            })

            dispatch(
                setMenu(entireMenuList)
            )
        }
    }, [getMenuResult.data])

    useEffect(() => {
        if (getMenuResult.error) {
            console.error(getMenuResult.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getMenuResult.error])

    /** set activeTab via router link. */
    useEffect(() => {
        const foundPageTab = _.find(PAGE_TAB_SELECTIONS, (pageObj) => {
            return _.find(pageObj.links, (e) => {
                return e === router.location.pathname
            })
        }) as typeof PAGE_TAB_SELECTIONS[0]

        foundPageTab
            ? dispatch(setActiveTab(foundPageTab))
            : dispatch(setActiveTab({
                name: '',
                links: []
            }))
    }, [router])

    /**
     * when an activeTab is selected, automatically expand the parent row
     * usually the case when you navigating the site besides the sidebar component
     * dependency is also the menuList when a new one is populated even after
     * session expiration. I remember why I shouldn't be deleting this.
     * This is because you might want to have more links than what was on the
     * pages array in the menuList response data. For example, the CRUD pages for
     * partners and orders are not in their respective tabs BUT you want to highlight
     * the page.
     * */

    /**
     * Infinite lifecycles can occur if you add the menu as a dependency.
     * and can conflict with other hooks like the menuList onClick events.
     * So, it won't expand upon browser refresh but will when the router pathname
     * changes via browser navigation.
     * */

    useEffect(() => {
        let entireMenuList : MenuList = {}

        if (Object.keys(menu).length) {
            entireMenuList = _.cloneDeep(menu)
        }

        _.forEach(entireMenuList, (menuListObj) => {
            // const found = _.find(menuListObj.pages, (pageObj) =>
            //     pageObj.pageName === activeTab.name
            // )
            // menuListObj.expand = Boolean(found)
            menuListObj.expand = true
        })

        dispatch(setMenu(entireMenuList))
    }, [activeTab])

    return (
        /**
         * this component goes into position-absolute in smaller screen sizes
         * with a fixed width
         * */
        <Sidebar.MenuColumn toggle={showSidebar} className={'col-auto'}>
            <div className={'row flex-column justify-content-between h-100'}>
                <div className={'col-auto'}>
                    <div className={'row flex-column'}>
                        <div className={'col-auto'}>
                            <Sidebar.MenuItemRow
                                type={'title'}
                                className={'row align-items-center'}
                            >
                                <div
                                    className={'col-auto'}
                                    onClick={() => {
                                        dispatch(toggleSidebar(!showSidebar))
                                    }}
                                >
                                    {showSidebar ? <Ri.RiMenu3Line /> : <Ri.RiMenu2Line />}
                                </div>
                                {
                                    showSidebar
                                        ? (
                                            <div className={'col-auto'} onClick={() => {
                                                dispatch(push(EVENT_ROUTES.FLOW.link))
                                            }}>
                                                <span>{TEXT.SIDEBAR.TITLE}</span>
                                            </div>
                                        )
                                        : ''
                                }
                            </Sidebar.MenuItemRow>
                        </div>
                        <div className={'col-auto'} style={{
                            height: '85vh',
                            overflowY: 'scroll'
                        }}>
                            {MenuList}
                        </div>
                    </div>

                </div>

                <div className={'col-auto'}>
                    {/* add a footer for logo */}
                    {Footer}
                </div>
            </div>
        </Sidebar.MenuColumn>
    )
}

export default SidebarComponent


import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as RDD_MESSAGE
} from '@constants/dashboard/soc/rdd'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    Aggregation,
    MonitorModal,
    RddDetailsForm,
    ServiceTypeFormData
} from '@interfaces/dashboard/monitor'
import {
    IPAddressResponse,
    MacVendorResponse
} from '@interfaces/dashboard/soc/rdd'
import {
    ColorPresets
} from '@interfaces/main/root'
import {
    ActionCreatorWithPayload,
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    setEndDate as setDetailsEndDate,
    setStartDate as setDetailsStartDate
} from '@slices/dashboard/soc/rdd/details'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/rdd/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    SpinnerContainer
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    ArcElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    PieController,
    Tooltip
} from 'chart.js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo
} from 'react'
import uniqueString from 'unique-string'

interface ComponentProps {
    chartEl: React.RefObject<HTMLCanvasElement>
    modal: MonitorModal,
    addModal: ActionCreatorWithPayload<MonitorModal, string>,
    closeModal: ActionCreatorWithPayload<MonitorModal, string>,
    changeModalColor: ActionCreatorWithPayload<{
        modal: MonitorModal,
        colorType: ColorPresets
    }, string>,
    macVendorData: MacVendorResponse | undefined,
    macVendorDataPrevious: MacVendorResponse | undefined,
    ipAddressData: IPAddressResponse | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const DeviceTypesChart = ({
    chartEl, macVendorData, macVendorDataPrevious, ipAddressData,
    isLoading, isSuccess, error,
    modal, addModal, closeModal, changeModalColor
} : ComponentProps) => {
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const currentParams = useAppSelector(selectCurrentParams)

    const dispatch = useAppDispatch()

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(PieController, ArcElement,
            Legend, CategoryScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const currentData = macVendorData?.aggregations || {
            3: {
                doc_count_error_upper_bound: 0,
                sum_other_doc_count: 0,
                buckets: []
            }
        }

        const ipAddresses = ipAddressData?.data || []

        const data = _.intersectionWith(
            currentData['3'].buckets,
            ipAddresses,
            (arrVal, otherVal) => {
                return arrVal.key === otherVal.mac_type
            }
        )

        let graph: Chart<'pie', {rddDetails: Aggregation}[], string>

        const datasets: typeof graph.data.datasets = [{
            data: _.map(data, (bucket) => {
                return {
                    rddDetails: bucket
                }
            }),
            // random color because of each key.
            backgroundColor: _.map(
                data,
                (_i, index) => CHART_COLORS[index]
            ),
            borderWidth: 0,
            parsing: {
                key: 'rddDetails.doc_count'
            }
        }]

        if (data.length && chartEl.current) {
            graph = new Chart(chartEl.current, {
                type: 'pie',
                data: {
                    labels: _.map(data, (bucket) => bucket.key),
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            display: false,
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            display: false,
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        }
                    }
                }

            })

            graph.options.onClick = (_event, elements) => {
                if (elements[0]) {
                    const dataset = datasets[elements[0].datasetIndex]
                    const data = dataset.data[elements[0].index]

                    dispatch(setDetailsStartDate(currentParams.ranges.start))
                    dispatch(setDetailsEndDate(currentParams.ranges.end))

                    const detailsContent:RddDetailsForm = {
                        mac_type: data.rddDetails.key,
                        mac_add: ''
                    }

                    const serviceTypeFormData:ServiceTypeFormData = {
                        rdd: {
                            details: detailsContent
                        }
                    }

                    dispatch(addModal({
                        id: uniqueString(),
                        open: true,
                        card: modal.card,
                        operation: 'DETAILS',
                        serviceTypeFormData: serviceTypeFormData,
                        isBorderWide: true
                    }))
                }
            }

            /** changed from height to maxheight so this size is used in smaller devices */
            chartEl.current.style.height = CHART_HEIGHT.md
            chartEl.current.style.maxHeight = CHART_HEIGHT.md
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            graph && graph.destroy()
        }
    }, [
        macVendorData,
        ipAddressData
    ])

    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row my-2'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{RDD_MESSAGE.FETCH.MAC_VENDORS}</span>
                </SpinnerContainer>
            </small>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : JSON.stringify(error)
                : LoadingContent
        )
    }, undefined)

    return <div>{DataContent}</div>
}

DeviceTypesChart.propTypes = {
    macVendorData: PropTypes.object,
    macVendorDataPrevious: PropTypes.object,
    ipAddressData: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default DeviceTypesChart

import {
    useGetEventsMutation
} from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    paginate,
    smartGroup,
    smartOrder,
    smartSearch
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    COMPARISON_OPERATORS,
    DATE_FORMAT_TIME,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    INTERVALS,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    ACTIONS,
    COLUMNS,
    KEYS,
    TABLE_COL_SPAN,
    TEXT as EVENT_TEXT
} from '@constants/watchdog/soc-data/event'
import MenuLinks from '@features/main/MenuLinks'
import DoArchive from '@features/watchdog/soc-data/event/DoArchive'
import DoCreateReport from '@features/watchdog/soc-data/event/DoCreateReport'
import DoEscalate from '@features/watchdog/soc-data/event/DoEscalate'
import DoGroupArchive from '@features/watchdog/soc-data/event/DoGroupArchive'
import DoMultipleArchive from '@features/watchdog/soc-data/event/DoMultipleArchive'
import DoRequestReport from '@features/watchdog/soc-data/event/DoRequestReport'
import EventDetails from '@features/watchdog/soc-data/event/EventDetails'
import GroupedEventData from '@features/watchdog/soc-data/event/GroupedEventData'
import FilterAdd from '@features/watchdog/soc-data/filter/FilterAdd'
import {
    ComparisonOperator
} from '@interfaces/main/root'
import {
    EventColumn,
    EventGroup,
    EventObj,
    EventObjKeys
} from '@interfaces/watchdog/soc-data/event'
import { MutationContext } from '@root/MutationProvider'
import { selectToken } from '@slices/main/token'
import {
    addFilter,
    addModal,
    closeModal,
    removeFilter,
    removeFilters,
    removeModal,
    resetEvent,
    selectModals,
    selectTableData,
    selectTabs,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setEndDate,
    setFiltered,
    setFilterKeys,
    setFilterValues,
    setGrouped,
    setIntervalObj,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    setStartDate,
    toggleFilterInterface,
    addEventToMultiple,
    removeEventFromMultiple,
    selectMultipleArchive,
    emptyEventGroup
} from '@slices/watchdog/soc-data/event/main'
import {
    Button,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import {
    format,
    fromUnixTime,
    getUnixTime
} from 'date-fns'
import flatten from 'flat'
import _ from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import ReactDatePicker from 'react-datepicker'
import { AiOutlineClose } from 'react-icons/ai'
import {
    FaArrowDown,
    FaArrowUp,
    FaCalendar,
    FaPlus,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdManageSearch,
    MdRefresh
} from 'react-icons/md'
import { useIdleTimer } from 'react-idle-timer'
import Modal from 'react-responsive-modal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'

/** first component to attempt table pagination data */

const EventReporting = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const tabs = useAppSelector(selectTabs)
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)
    const multipleArchive = useAppSelector(selectMultipleArchive)

    const tableData = useAppSelector(selectTableData)

    /** in the old systems, you were allowed to have an array of components to render
     * but that wasn't allowed because functions/components are non-serializable values
     * Instead, you can make it an array where each element is an object.
     * {id: uniqueString(), eventObj: null, operation: ""}
     * operation string is responsible to render what modal to show.
     * create interface starting at root and then extend to be omitted as one sees fit
     */

    const [groupKeys] = useState<EventColumn[]>([COLUMNS.MAIN[1], COLUMNS.MAIN[3]])

    const [getEvents, getEventsMutation] = useGetEventsMutation()

    /**
     * another request was to create an interval to refresh
     * the data but inherits idleTimer behavior. the idleTimer
     * would only be re-rendered if tableData.interval changes.
     */

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    /**
     * to further understand this, the callback uses the current value
     * of the store in that lifecycle. previous example was only data
     * from mount. any useEffect that calls this would always have
     * the same input.
     */
    // const fetchData = useCallback(() => {

    const unsubscribeGetEvents = () => {
        const unsubscribeMutation = getEvents({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetEvents()
        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        /** always convert to unix time. if values are null just use
         * default value that was initialized from the event store
         */

        const fromTime = tableData.ranges.start
        const toTime = tableData.ranges.end

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getEvents({
                        authToken: newToken,
                        type: 'report-requested',
                        filters: true,
                        fromTime: fromTime.toString(),
                        toTime: toTime.toString()
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    // }, [token.valid])
    }

    // a method where you remove an item from an array that
    // contains the list of ids to perform a group archive operation.

    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        /** displays each row of data from EventGroup[1].data */

        const cellCount = TABLE_COL_SPAN + tableData.columns.length + ACTIONS.length

        const cellBody = (
            dataObject: EventObj,
            property: keyof EventObj
        ) => {
            /** when adding use cases for including/excluding a filter
             * codebase was lengthy and developer didn't want that.
             * only thing different was the not property. use a loop
             * also if you want to add more than one filter in each case.
             */

            let cellContent: EventObj[keyof EventObj] = ''
            let rawValue: EventObj[keyof EventObj] = ''

            /** switch case if you want to display something differently */
            switch (property) {
                case 'timestamp':
                    cellContent = format(new Date(dataObject.timestamp), DATE_FORMAT_TIME)
                    rawValue = dataObject.timestamp
                    break

                case 'src_ip':
                    cellContent = [
                        dataObject.src_ip, ' ',
                        dataObject.protocol, ':',
                        dataObject.src_port].join('')
                    break

                case 'dest_ip':
                    cellContent = [
                        dataObject.dest_ip, ' ',
                        dataObject.protocol, ':',
                        dataObject.dest_port].join('')
                    break

                default:
                    rawValue = cellContent = dataObject[property]
                    break
            }

            const addMultipleFilters = (
                not:boolean,
                filters:{
                     sort: keyof EventObj, value: string | number
                 }[]
            ) => {
                _.forEach(filters, (obj) => {
                    dispatch(addFilter({
                        not: not,
                        sort: obj.sort,
                        value: [obj.value as string]
                    }))
                })
            }

            const zoomAction = (not: boolean) => {
                if (property === 'src_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'src_ip',
                            value: dataObject.src_ip
                        },
                        {
                            sort: 'src_port',
                            value: dataObject.src_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else if (property === 'dest_ip') {
                    addMultipleFilters(not, [
                        {
                            sort: 'dest_ip',
                            value: dataObject.dest_ip
                        },
                        {
                            sort: 'dest_port',
                            value: dataObject.dest_port
                        },
                        {
                            sort: 'protocol',
                            value: dataObject.protocol
                        }
                    ])
                } else {
                    addMultipleFilters(not, [
                        {
                            sort: property,
                            value: rawValue
                        }
                    ])
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                /** if cell content is empty, just wrap it in a div container */
                cellContent
                    ? <HoverFilter bgIndex={2}>
                        <div className={'pe-2 py-1'}>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                                {zoomIn}
                            </Tippy>
                            <Tippy
                                className={'tippy-box'}
                                arrow
                                content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                                {zoomOut}
                            </Tippy>
                        </div>
                        <div>
                            {cellContent}
                        </div>
                    </HoverFilter>
                    : (
                        <div>
                            {cellContent}
                        </div>
                    )
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={cellCount}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={cellCount}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (eventGroup, groupIndex) => {
                /** stats off with EventGroup */

                /** type error found. we can't guarantee that these values will be defined
                 * especially those with no groupings (a row with only one group member aka itself)
                 */
                const key = eventGroup[0] || ''
                const data: EventObj[] = eventGroup[1].data || []

                const tableRow = (dataObj: EventObj, rowIndex: number) => {
                    // key is the row index AND the groupIndex
                    const rowKey = [
                        'group-', groupIndex, '-row-', rowIndex
                    ].join('')
                    // the only action button here is a group archive
                    const collapseCell = () => {
                        const cellKey = [
                            'cell-',
                            groupIndex,
                            '-',
                            0
                        ].join('')

                        const onClick = () => {
                            // add a modal to the list passing in the group.
                            dispatch(addModal({
                                id: uniqueString(),
                                open: true,
                                eventGroup: data,
                                operation: 'GROUP_EVENT_DETAILS',
                                isBorderWide: false
                            }))
                        }

                        if (!rowIndex) {
                            return (
                                data.length > 1
                                    ? <td
                                        onClick={onClick}
                                        key={cellKey}
                                        className={'text-center pointer'}
                                    >
                                        <small className={'d-block'} >{
                                        // display only if data is greater than one
                                            <FaPlus />
                                        }</small>
                                        <small>{data.length}</small>
                                    </td>
                                    : <td></td>
                            )
                        }

                        return <td></td>
                    }

                    const checkbox = (
                        <input type={'checkbox'} onChange={(e) => {
                            /** check if !rowIndex is true */
                            if (!rowIndex) {
                                /** check if e.target.checked */
                                if (!e.target.checked) {
                                    // iterate through the entire eventGroup
                                    // and remove the event from the list.
                                    _.forEach(data, (obj) => {
                                        dispatch(removeEventFromMultiple(obj))
                                    })
                                } else {
                                    /** if unchecked, check all. */
                                    _.forEach(data, (obj) => {
                                        dispatch(addEventToMultiple(obj))
                                    })
                                }
                            } else {
                                if (!e.target.checked) {
                                    dispatch(removeEventFromMultiple(dataObj))
                                } else {
                                    dispatch(addEventToMultiple(dataObj))
                                }
                            }
                        }} checked={
                            Boolean(_.find(multipleArchive, (obj) => {
                                return obj.id === dataObj.id
                            }))
                        } />
                    )

                    /** now iterate the data array and map. */
                    const actionButtons = () => {
                        // if case where you show buttons based on data content.
                        const filteredActions = _.filter(ACTIONS, (action) => {
                            if (
                                action.value === 'GROUP_ARCHIVE' &&
                                data.length > 1
                            ) {
                                return true
                            } else if (
                                action.value === 'ARCHIVE' &&
                                data.length === 1
                            ) {
                                return true
                            } else {
                                return false
                            }
                        })

                        return (
                            _.map(filteredActions, (action, cellIndex) => {
                                const cellKey = [
                                    'cell-',
                                    groupIndex,
                                    '-',
                                    (1 + cellIndex + tableData.columns.length)
                                ].join('')

                                if (!rowIndex) {
                                    const onClick = () => {
                                        /** archive is same as delete. */
                                        if (action.value === 'GROUP_ARCHIVE') {
                                            dispatch(addModal({
                                                id: uniqueString(),
                                                open: true,
                                                eventGroup: data,
                                                operation: action.value,
                                                isBorderWide: false
                                            }))
                                        } else if (action.value === 'ARCHIVE') {
                                            /** no need to check if data[0] is defined since the
                                             * button will never render if the length of the data
                                             * array is exactly one.
                                             */
                                            dispatch(addModal({
                                                id: uniqueString(),
                                                open: true,
                                                eventObj: data[0],
                                                operation: action.value,
                                                isBorderWide: false
                                            }))
                                        }
                                    }

                                    return (
                                        <td key={cellKey}>
                                            <Button
                                                onClick={onClick}
                                                size={'sm'}
                                                mode={action.mode}>
                                                {action.label}
                                            </Button>
                                        </td>
                                    )
                                } else return <td key={key}></td>
                            })
                        )
                    }

                    /**
                     * hide rows based on the group's expand value.
                     * the first elements of each group ARE unaffected
                     * FIRST PERFORMANCE SLOWDOWN. Change from d-none to
                     * not rendering the row at all.
                     */
                    const rowDisplay = rowIndex
                        ? 'group-child'
                        : 'group-parent'

                    // also, if rowIndex > 0, add a class that changes background color

                    const content = (
                        <tr key={rowKey} className={rowDisplay}>
                            {/* only display the action
                            button at the first element of the group */}
                            <td>
                                {checkbox}
                            </td>
                            {collapseCell()}
                            {
                                _.map(tableData.columns, (column, cellIndex) => {
                                    const cellKey = [
                                        'cell-', rowIndex, '-', cellIndex
                                    ].join('')

                                    // apply onClick event to populate event details.
                                    return (
                                        <td
                                            className={'pointer'}
                                            key={cellKey}
                                            onClick={() => {
                                                dispatch(addModal({
                                                    open: true,
                                                    id: uniqueString(),
                                                    eventObj: dataObj,
                                                    operation: 'EVENT_DETAILS',
                                                    isBorderWide: false
                                                }))
                                            }}
                                        >
                                            {cellBody(dataObj, column.value)}
                                        </td>
                                    )
                                })
                            }
                            {/* only display the action
                        button at the first element of the group */}
                            {actionButtons()}
                        </tr>
                    )

                    return (
                        rowIndex
                            ? <tr key={rowKey}></tr>
                            : content
                    )
                }

                return (_.map(data, tableRow))
            })
        )

        return (
            getEventsMutation.isLoading
                ? LoadingContent
                : getEventsMutation.isSuccess
                    ? (
                        tableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(getEventsMutation.error)
        )
    }, [tableData, getEventsMutation, multipleArchive])

    /**
     * when mounted, perform getPartners api
     * but you also need to do this when you want to manually refresh.
     * use a property in a store for this.
     *
     * UPDATE: instead of executing fetchData immediately,
     *  Bug found where said call wasn't aborted from initial mount.
     * */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (tableData.refetch) {
            return fetchData()
        }
    }, [tableData.refetch])

    useEffect(() => {
        /**
         * in case that the refetch value is true meaning that
         * you did a refresh, set it to false now. It should be
         * safe to abort the promise as the call finished.
         *
         * and just to make sure refetch is setting when it has to,
         * we are also going to check if refetch is truthy, even
         * when it's not added as a dependency.
         * */
        if (getEventsMutation.isSuccess && tableData.refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getEventsMutation.isSuccess
    ])

    /** please refer to smartSearch in method.ts to know why use-debounce
     * works in react compared to basic debounce packages.
     */

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const filterData = useDebouncedCallback(() => {
        const filteredData = smartSearch(
            getEventsMutation.data?.eventData || [],
            tableData.filters,
            tableData.search
        ) as EventObj[]
        const orderedData = smartOrder(filteredData, tableData.columns) as EventObj[]

        /**
         * set the filtered data to tableData.filtered.
         * any sort or pagination should be done in a separate useEffect
         * */
        dispatch(setFiltered(orderedData))
    }, DEBOUNCE_SEARCH_TIME)

    const groupData = () => {
        const groupedData = smartGroup(
            tableData.filtered,
            groupKeys
        ) as EventGroup[]
        dispatch(setGrouped(groupedData))
    }

    const paginateData = () => {
    // const paginateData = useDebouncedCallback(() => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.grouped,
            tableData.page,
            tableData.count
        ) as EventGroup[]

        dispatch(setPaginated(result))
    // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        filterData()
    }, [
        /** refreshed data from getPartners invocation */
        getEventsMutation.data,
        /** when tableData.filters updates */
        tableData.filters,
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    useEffect(() => {
        if (getEventsMutation.error) {
            console.error(getEventsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getEventsMutation.error])

    useEffect(() => {
        groupData()
    }, [
        tableData.filtered
    ])

    useEffect(() => {
        paginateData()
    }, [
        tableData.grouped,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    /** date picker has conflict when you combine range and time selection together
     * decided to split into two AND add a hyphen in between
     */

    const StartDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                console.log(date)
                dispatch(setStartDate(
                    getUnixTime(date)
                ))
            } else {
                dispatch(setStartDate(0))
            }
        }

        const startDate = fromUnixTime(tableData.ranges.start)
        const endDate = fromUnixTime(tableData.ranges.end)

        return (
            <ReactDatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.START.ID}

                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
            />
        )
    }, [tableData.ranges])

    const EndDatePicker = useMemo(() => {
        const onChange = (date: Date | [Date | null, Date | null] | null) => {
            /** expected value should be a date */
            if (_.isDate(date)) {
                dispatch(setEndDate(
                    getUnixTime(date)
                ))
            } else {
                dispatch(setEndDate(0))
            }
        }

        const startDate = fromUnixTime(tableData.ranges.start)
        const endDate = fromUnixTime(tableData.ranges.end)

        return (
            <ReactDatePicker
                selectsEnd
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                id={TEXT.SEARCH.END.ID}

                onChange={onChange}
                dateFormat={DATE_FORMAT_TIME}
            />
        )
    }, [tableData.ranges])

    const CountLabels = useMemo(() => {
        const filtered = getEventsMutation.data?.eventCount?.filteredNumber || 0
        const total = getEventsMutation.data?.eventCount?.totalNumber || 0

        return (
            <div className={'row justify-content-end mt-3'}>
                <Text size={'xs'} className={'col-auto'}>
                    {EVENT_TEXT.TABLE.FILTERED_COUNT} {filtered}
                </Text>
                <Text size={'xs'} className={'col-auto'}>
                    {EVENT_TEXT.TABLE.TOTAL_COUNT} {total}
                </Text>
            </div>
        )
    }, [getEventsMutation.data])

    /** reset event slice on unmount when navigating other event type search components */
    useEffect(() => {
        return () => {
            dispatch(resetEvent())
        }
    }, [])

    /** a hook to update filterInterface.keys with the api response
     * as a dependency
     */

    useEffect(() => {
        const data = getEventsMutation.data?.eventData || []
        /** NOTE: There was a request by SOC team to predefine field names.
         * While there IS empty/undefined data regardless of mutation's initialization
         * status, assign an array of keys of an object that you can take
         * from the module's interface.
         *
         * also remove any keys that have an 'id' substring.
         *
         * Developer expressed concern that there should be a separate
         * query for retrieving field names accepting being unable
         * to autofill property names because the response data
         * should be a string array.
         */

        const result = data[0]
            ? _.filter(_.keys(
                flatten(data[0])
            ), (str) => {
                return !_.includes(str, 'id')
            })
            : KEYS.EVENT

        dispatch(
            setFilterKeys(result)
        )
    }, [
        getEventsMutation.data
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as EventObjKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    return (
        <div>
            <MenuLinks tabs={tabs} />
            {/* this row contains an interval selection */}
            {CountLabels}
            <div className={'justify-content-end align-items-end row mt-1'}>
                {/* Add a button named, "Archive Selected Events" */}
                <div className={'col-12 col-sm-auto pe-sm-4 ps-sm-0 px-4'}>
                    <Button
                        mode={'primary'}
                        size={'sm'}
                        disabled={!multipleArchive.length}
                        onClick={() => {
                            dispatch(addModal({
                                id: uniqueString(),
                                open: true,
                                eventGroup: multipleArchive,
                                operation: 'MULTIPLE_ARCHIVE',
                                isBorderWide: false
                            }))
                        }}
                    >
                        {EVENT_TEXT.TABLE.ARCHIVE_SELECTED}
                    </Button>

                </div>
                {/* adding date range container here. */}
                <div className={'col-12 col-sm-auto px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label
                            className={'col-auto pe-0'}
                            htmlFor={TEXT.SEARCH.START.ID}
                        >
                            <Text size={'xs'} className={'pe-1'}>
                                {TEXT.SEARCH.START.LABEL}
                            </Text>
                            <FaCalendar />
                        </label>
                        <div className={'col ps-0'}>
                            {StartDatePicker}
                        </div>
                    </SearchRow>
                </div>
                <div className={'col-12 col-sm-auto px-4'}>
                    <SearchRow className={'align-items-center row mt-2 mt-sm-0'}>
                        <label
                            className={'col-auto pe-0'}
                            htmlFor={TEXT.SEARCH.END.ID}
                        >
                            <Text size={'xs'} className={'pe-1'}>
                                {TEXT.SEARCH.END.LABEL}
                            </Text>
                            <FaCalendar />
                        </label>
                        <div className={'col ps-0'}>
                            {EndDatePicker}
                        </div>
                    </SearchRow>
                </div>
                <div className={'col-auto mt-3 mt-lg-0'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <small className={'col col-md-auto'}>
                            {TEXT.TABLE.REFRESH}
                        </small>
                        <Select
                            className={'col-auto px-0'}
                            options={INTERVALS}
                            value={_.find(
                                INTERVALS,
                                (e) => e.value === tableData.interval.value
                            )}
                            onChange={(e) => {
                                if (e) {
                                    dispatch(setIntervalObj(e))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles,
                                ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                            }}
                            theme={reactSelect.theme}
                        />
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                            <span
                                className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                                onClick={() => {
                                    dispatch(setRefetch(true))
                                    refreshTimer.reset()
                                }}>
                                <MdRefresh />
                            </span>
                        </Tippy>
                    </div>
                </div>
            </div>
            {/* in this row, show the search bar on the left
            and the pagination on the right */}
            <div className={'align-items-center justify-content-between row mt-3'}>
                <div className={'col-12 col-md px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.LABEL}>
                            <FaSearch />
                        </label>
                        <input
                            className={'col'}
                            type={'text'}
                            id={TEXT.SEARCH.SEARCH.ID}
                            defaultValue={tableData.search}
                            onChange={(e) => {
                                setSearchDebounce(e.target.value)
                            }}
                            placeholder={'Search'}
                        />
                        {/* when this is clicked, a popup window will show up
                        it was previously a tippy but now it's an interface below
                         to utilize space. */}
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                            <Text size={'xl'}
                                className={'d-inline-block col-auto pe-0'}
                                onClick={() => {
                                    dispatch(
                                        toggleFilterInterface(!tableData.filterInterface.show)
                                    )
                                }}
                            >
                                <MdManageSearch />
                            </Text>
                        </Tippy>
                    </SearchRow>
                </div>

                {/* this is the dropdown for page count */}
                <div className={'col-12 col-md-auto'}>
                    <div className={'align-items-center justify-content-between row'}>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col-auto'}>
                                    {TEXT.TABLE.PAGE}
                                </small>
                                <Select
                                    className={'col-auto'}
                                    options={ROW_COUNT_SELECTION}
                                    value={_.find(
                                        ROW_COUNT_SELECTION,
                                        (e) => e.value === tableData.count
                                    )}
                                    onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                        if (e) {
                                            dispatch(setCount(e.value))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.SMALL
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                            <Pagination
                                /** pagination count is determined by the group counts,
                                 * not the individual rows of data because you'll end up
                                 * paginating empty results at the last many page numbers
                                 */
                                current={tableData.page}
                                total={tableData.grouped.length}
                                pageSize={tableData.count}
                                onChange={(current) => {
                                    dispatch(setPage(current))
                                }}
                                locale={EN_US}
                            />
                        </div>

                    </div>

                </div>
            </div>
            {/* row to show when kubernetes search toggles */}
            {
                tableData.filterInterface.show
                    ? (
                        <div className={'mt-2'}>
                            {FilterInterface}
                        </div>
                    )
                    : ''
            }
            {/*
                this row contains a filters where you can include or exclude. can also clear all
                since this will become a component shared, pass the removeFilter AND
                removeFilters actions as props.
            */}
            <div className={'row mt-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(tableData.filters, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                        <Text size={'xs'} className={'ps-2'} onClick={() => {
                                            dispatch(removeFilter(filter))
                                        }}>
                                            <FaTimes />
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
                <span
                    className={[
                        'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                        tableData.filters.length ? 'd-block' : 'd-none'
                    ].join(' ')}
                    onClick={() => {
                        dispatch(removeFilters())
                    }}
                >
                    {/* clear all filters button. baseline, not center */}
                    <FaTimes />
                </span>
            </div>
            {/*
                then populate tbody using the paginated result. attempting to
                iterate using the columns data. failed because typescript
                discourages implicit declaration, including calling property names
                of objects using variables INSTEAD of a coding in the exact name.
                it would be best to perform explicit declaration
                as the opposite defeats the purpose of using Typescript.

                but if there is a fair justification to use this, just use 'any' on the
                paginated object as long as you avoid expected crashes by adding conditions.

            */}
            <div className={'justify-content-center row mt-3'}>
                <div className={'col'}>
                    <Table
                        className={'table-striped table-hover'}
                        height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                        bgIndex={1}
                    >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {/* a table header cell for showing groupings. */}
                                    <th colSpan={TABLE_COL_SPAN}>
                                        <Button
                                            disabled={multipleArchive.length === 0}
                                            onClick={() => {
                                                dispatch(emptyEventGroup())
                                            }}
                                            size={'sm'}
                                            mode={'primary'}>
                                            {EVENT_TEXT.TABLE.UNCHECK_ALL}
                                        </Button>
                                    </th>
                                    {TableHead}
                                    {/* action section is created here. */}
                                    {
                                        ACTIONS.length
                                            ? (<th colSpan={ACTIONS.length}>
                                                <small>{TEXT.TABLE.ACTIONS}</small>
                                            </th>)
                                            : <th className={'d-none'}></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TableBody}
                            </tbody>
                        </table>
                    </Table>
                </div>
            </div>

            {
                // using ids to select object to fetch data or close modals as one does fit.
                _.map(modals, (modal, index) => {
                    const key = [
                        'modal-', modal.operation, '-', index
                    ].join('')

                    /** going to shorten this. create string for classNames prop, */
                    let component: ReactElement<any, any> = <></>
                    let classNames = {
                        modal: ''
                    }

                    if (modal.operation === 'EVENT_DETAILS' && modal.eventObj) {
                        classNames = {
                            modal: ['md', 'primary'].join(' ')
                        }
                        component = <EventDetails
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'GROUP_EVENT_DETAILS' &&
                        modal.eventGroup
                    ) {
                        classNames = {
                            modal: ['xs', 'primary'].join(' ')
                        }
                        component = <GroupedEventData
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'GROUP_ARCHIVE' &&
                        modal.eventGroup
                    ) {
                        classNames = {
                            modal: ['xs', 'danger'].join(' ')
                        }
                        component = <DoGroupArchive
                            removeEventToGroup={removeEventFromMultiple}
                            setRefetch={setRefetch}
                            modal={modal}
                            groupKeys={groupKeys}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'MULTIPLE_ARCHIVE' &&
                        multipleArchive.length
                    ) {
                        classNames = {
                            modal: ['xs', 'danger'].join(' ')
                        }
                        component = <DoMultipleArchive
                            removeEventFromMultiple={removeEventFromMultiple}
                            setRefetch={setRefetch}
                            modal={modal}
                            groupKeys={groupKeys}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'ARCHIVE' &&
                        modal.eventObj && modal.detailsModal
                    ) {
                        classNames = {
                            modal: ['xs', 'danger'].join(' ')
                        }
                        component = <DoArchive
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'ESCALATE' &&
                        modal.eventObj && modal.detailsModal
                    ) {
                        classNames = {
                            modal: ['xs', 'primary'].join(' ')
                        }
                        component = <DoEscalate
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'REQUEST_REPORT' &&
                        modal.eventObj && modal.detailsModal
                    ) {
                        classNames = {
                            modal: ['xs', 'primary'].join(' ')
                        }
                        component = <DoRequestReport
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'CREATE_REPORT' &&
                        modal.eventDetails
                    ) {
                        classNames = {
                            modal: ['xs', 'primary'].join(' ')
                        }
                        component = <DoCreateReport
                            modal={modal}
                            addModal={addModal}
                            closeModal={closeModal}
                        />
                    } else if (
                        modal.operation === 'FILTER_ADD' &&
                        modal.filterObj && modal.detailsModal
                    ) {
                        classNames = {
                            modal: ['sm', 'primary'].join(' ')
                        }
                        component = <FilterAdd
                            modal={{
                                id: modal.id,
                                open: modal.open,
                                operation: modal.operation,
                                filterObj: modal.filterObj,
                                detailsModal: modal.detailsModal,
                                isBorderWide: modal.isBorderWide
                            }}
                        />
                    } else {
                        return ''
                    }

                    return <Modal
                        key={key}
                        classNames={classNames}
                        open={modal.open}
                        center focusTrapped={false}
                        onAnimationEnd={() => {
                            if (!modal.open) {
                                dispatch(removeModal(modal))
                            }
                        }}
                        onClose={() => {
                            dispatch(closeModal(modal))
                        }}
                        closeIcon={<AiOutlineClose />}>
                        {component}
                    </Modal>
                })
            }

        </div>
    )
}
export default EventReporting


import {
    useDownloadCompleteReportMutation,
    useGenerateSectionMutation,
    useGetMainKeyMutation,
    useGetReportsMutation
} from '@apis/dashboard/pdf-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    paginate,
    smartSearch
} from '@constants/main/method'
import {
    ACTIONS,
    COLUMNS,
    EXTRA_HEADERS,
    KEYS,
    TEXT as PRINT_TEXT
} from '@constants/main/print'
import {
    ACTION_MUTATION_PROMISE,
    COMPARISON_OPERATORS,
    DATE_FORMAT_TIME,
    DEBOUNCE_SEARCH_TIME,
    EN_US,
    FIXED_REACT_SELECT_WIDTH,
    INTERVALS,
    MESSAGE,
    MULTI_SELECTION_OPERATORS,
    NOT_OPERATORS,
    PAGE_START,
    ROW_COUNT_SELECTION,
    TABLE_CONTAINER_HEIGHT,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS,
    TOASTIFY_PRINT_OPTIONS
} from '@constants/main/root'
import IncompleteConfirmation from '@features/main/print/IncompleteConfirmation'
import SectionDetails from '@features/dashboard/print/SectionDetails'
import {
    Report,
    Section,
    SectionFilter,
    SectionMain,
    SectionKeys
} from '@interfaces/main/print'
import {
    ComparisonOperator
} from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    removeLoadingId,
    addLoadingId,
    addFilter,
    addModal,
    closeModal,
    removeFilter,
    removeFilters,
    removeModal,
    selectModals,
    selectTableData,
    setColumnArrange,
    setColumnInclude,
    setCount,
    setFiltered,
    setFilterValues,
    setIntervalObj,
    setPage,
    setPaginated,
    setRefetch,
    setSearch,
    setSelectedComparison,
    setSelectedKey,
    setSelectedValues,
    toggleFilterInterface,
    selectLoadingQueue,
    setColumns,
    setFilterKeys
} from '@slices/main/print/reportsMenu/main'
import { selectToken } from '@slices/main/token'
import {
    Button,
    FilterCell,
    HoverFilter,
    SearchRow,
    SpinnerContainer,
    Table,
    Text
} from '@styles/components'
import Tippy from '@tippyjs/react'
import flatten from 'flat'
import _, { Many } from 'lodash'
import Pagination from 'rc-pagination'
import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import {
    FaArrowDown,
    FaArrowUp,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
    FaTimes
} from 'react-icons/fa'
import {
    MdManageSearch,
    MdRefresh
} from 'react-icons/md'
import { useIdleTimer } from 'react-idle-timer'
import Modal from 'react-responsive-modal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import uniqueString from 'unique-string'
import { useDebouncedCallback } from 'use-debounce'
import { Buffer } from 'buffer'
import {
    fromUnixTime,
    format
} from 'date-fns'

/** first component to attempt table pagination data */

const ReportMenu = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken
    const reactSelect = rootContext.reactSelect

    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const modals = useAppSelector(selectModals)

    const tableData = useAppSelector(selectTableData)
    const loadingQueue = useAppSelector(selectLoadingQueue)

    const [getReports, getReportsMutation] = useGetReportsMutation()

    /** for getting the main key */
    const [getMainKey] = useGetMainKeyMutation()

    /** calls for downloading the report as a whole. If there is at least one
     * section that has crashed, display an warning window to proceed without
     * them. You can do this in stack as long as you are using the method.
     */
    const [downloadCompleteReport] = useDownloadCompleteReportMutation()
    /** call for repairing faulty sections. Same as above, just use the method */
    const [generateSection] = useGenerateSectionMutation()

    const refreshTimer = useIdleTimer({
        startOnMount: false,
        startManually: true,
        timeout: tableData.interval.value,
        onIdle: () => {
            dispatch(setRefetch(true))
            refreshTimer.reset()
        }
    })

    /** set COLUMNS and KEYS */
    useEffect(() => {
        dispatch(setColumns(
            COLUMNS.DASHBOARD_MAIN
        ))
        dispatch(setFilterKeys(
            KEYS.DASHBOARD_MAIN
        ))
    }, [])

    /**
     * to further understand this, the callback uses the current value
     * of the store in that lifecycle. previous example was only data
     * from mount. any useEffect that calls this would always have
     * the same input.
     */
    // const fetchData = useCallback(() => {

    const unsubscribeGetReports = () => {
        const unsubscribeMutation = getReports({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetReports()
        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (isMounted) {
                    promise = getReports({
                        authToken: newToken
                    })
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    // }, [token.valid])
    }

    /** template for entire toast. */
    const messageTemplate = useCallback((content: JSX.Element) => {
        return <div className={'row align-items-center'}>
            <SpinnerContainer className={'col-auto pe-2'}>
                <span className={'spinner-border'}></span>
            </SpinnerContainer>
            <div className={'col'}>
                <span>{content}</span>
            </div>
        </div>
    }, [])

    /** handler to downloadCompleteReport whether complete/incomplete */
    const downloadPDF = async (
        dataObject: Report,
        withoutCrash: boolean
    ) => {
        /** before anything else, make sure that the download button associated
         * in that is disabled to avoid spamming. This includes all toast instances.
         */
        dispatch(addLoadingId(dataObject.id))

        /** set progress toast. */
        const id = toast.info(
            messageTemplate(
                <>{withoutCrash
                    ? PRINT_TEXT.DOWNLOAD.INCOMPLETE.START
                    : PRINT_TEXT.DOWNLOAD.COMPLETE.START}</>
            ), {
                ...TOASTIFY_PRINT_OPTIONS.ONGOING
            }
        )

        const newToken = await revalidateToken()

        const mainKey = await getMainKey({
            reportId: dataObject.id,
            authToken: newToken
        }).unwrap()

        downloadCompleteReport({
            reportId: dataObject.id,
            authToken: newToken,
            withoutCrash: withoutCrash
        }).unwrap().then((data) => {
            dispatch(removeLoadingId(dataObject.id))

            if (data.success) {
                const buffer = Buffer.from(data.reportContents, 'base64')

                const blob = new Blob([buffer], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)

                link.download = mainKey.data
                link.click()

                toast.update(id, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'success'
                })
            } else {
                // display failure message.
                toast.update(id, {
                    render: data.message,
                    ...TOASTIFY_PRINT_OPTIONS.END,
                    type: 'error'
                })
            }
        })
    }

    const repairSections = async (
        dataObject: Report
    ) => {
        /** before anything else, make sure that the download button associated
         * in that is disabled to avoid spamming. This includes all toast instances.
         */
        dispatch(addLoadingId(dataObject.id))

        let done: number = 0
        const sections = _.filter(dataObject.sections, (obj) => {
            /** will check for one of the two conditions:
             * 1.) will check if hasCrashed is set to true
             * 2.) if wasn't set for generation, check if
             * hasCrashed is false AND
             */
            return obj.hasCrashed === true ||
            (obj.hasCrashed === false && obj.isComplete === false)
        })

        if (sections.length === 0) {
            toast.info(
                messageTemplate(
                    <>{PRINT_TEXT.REPAIR_SECTIONS.ALREADY_REPAIRED}</>
                ), {
                    ...TOASTIFY_PRINT_OPTIONS.END
                }
            )

            dispatch(removeLoadingId(dataObject.id))
        } else {
            /** create a progressBarToast for this. Show
         * how many sections to repair and update accordingly
         */
            const id = toast.info(
                messageTemplate(
                    <>{PRINT_TEXT.REPAIR_SECTIONS.PROGRESS(sections.length, done)}</>
                ), {
                    ...TOASTIFY_PRINT_OPTIONS.ONGOING
                }
            )

            for (const chunk of _.chunk(sections, 6)) {
                await Promise.all(_.map(chunk, (obj) => {
                    const promise = generateSection({
                        authToken: token.value,
                        sectionId: obj.id
                    })

                    promise.unwrap()
                        .then((data) => {
                            if (data.success) {
                                done += 1

                                /** report was generated. increment done and
                                 * update the toast.
                                 */
                                toast.update(id, {
                                    render: () => messageTemplate(
                                        <>
                                            {PRINT_TEXT.REPAIR_SECTIONS.PROGRESS(
                                                sections.length, done
                                            )}
                                        </>
                                    ),
                                    ...TOASTIFY_PRINT_OPTIONS.ONGOING
                                })
                            }
                        })

                    // make sure your map iteration is returning a value.
                    // otherwise, await will be useless here.
                    return promise
                }))

                // console.log('done repairing: ', done)

                /** if all is repaired, display success message. */
                if (done >= sections.length) {
                    toast.update(id, {
                        render: PRINT_TEXT.REPAIR_SECTIONS.SUCCESS,
                        ...TOASTIFY_PRINT_OPTIONS.END,
                        type: 'success'
                    })

                    // remove from queue since repair is finished.
                    dispatch(removeLoadingId(dataObject.id))

                    /** refetch data */
                    fetchData()
                }
            }
        }
    }

    const TableHead = useMemo(() => {
        return (
            _.map(tableData.columns, (column, index) => {
                const toggleArrange = () => {
                    dispatch(setColumnArrange({
                        value: column.value,
                        arrange: column.arrange === 'asc' ? 'desc' : 'asc'
                    }))
                }

                const toggleInclude = () => {
                    dispatch(setColumnInclude({
                        value: column.value,
                        boolean: !column.include
                    }))
                }

                const checkbox = (
                    /* show label, arrow and toggle. */
                    <input className={'me-2'} type={'checkbox'}
                        onChange={toggleInclude}
                        checked={column.include}
                    />
                )

                const label = (
                    <small onClick={toggleArrange}>
                        {column.label}
                    </small>
                )

                const arrange = (
                    /* change from previous. instead of not rendering,
                            it's just adding a disabled class */
                    /* show label, arrow and toggle. */
                    <span onClick={toggleArrange} className={[
                        'ms-2 pointer icon small',
                        !column.include ? 'disabled' : ''
                    ].join(' ')}>
                        {
                            column.arrange === 'asc'
                                ? <FaArrowUp />
                                : <FaArrowDown />
                        }
                    </span>
                )

                return (
                    <th key={'column-' + index}>
                        {checkbox}
                        {label}
                        {arrange}
                    </th>
                )
            })

        )
    }, [tableData])

    const TableBody = useMemo(() => {
        const cellBody = (
            dataObject: Section,
            property: SectionKeys |
            keyof Required<SectionMain> |
            keyof Required<SectionMain>['card'] |
            keyof Required<SectionMain>['searchParams']
        ) => {
            let cellContent: Section[keyof Section] = ''
            let rawValue: Section[keyof Section] = ''

            /** This is an array of Reports so grouping is already done
             * at the backend. cellBody method is slightly modified.
             *
             * UPDATE in October 14, 2022: Update property names since the
             * schema was updated.
             */

            const formDataCardKeys: (typeof property)[] = [
                'deviceid', 'serviceType', 'inFace'
            ]

            const formDataSearchParamsKeys: (typeof property)[] = [
                'timeFrom', 'timeTo'
            ]

            const main = dataObject.requestData.formData.main
            if (main.card && main.searchParams) {
            /** no default value this time. */

                switch (property) {
                    case 'deviceid':
                        rawValue = cellContent = main.card.deviceid
                        break
                    case 'serviceType':
                        rawValue = cellContent = main.card.serviceType
                        break
                    case 'inFace':
                        rawValue = cellContent = main.card.inFace
                        break
                    case 'timeFrom':
                        cellContent = format(
                            fromUnixTime(main.searchParams.timeFrom),
                            DATE_FORMAT_TIME
                        )
                        rawValue = main.searchParams.timeFrom
                        break
                    case 'timeTo':
                        cellContent = format(
                            fromUnixTime(main.searchParams.timeTo),
                            DATE_FORMAT_TIME
                        )
                        rawValue = main.searchParams.timeTo
                        break
                    case 'creationDate':
                        cellContent = format(
                            fromUnixTime(dataObject.creationDate),
                            DATE_FORMAT_TIME
                        )
                        rawValue = dataObject.creationDate
                        break
                    default:
                        rawValue = cellContent = ''
                        break
                }
            }
            /** make sure you are using the original value
             * and not the one for displaying.
             */
            const zoomAction = (not: boolean) => {
                if (_.includes(formDataCardKeys, property)) {
                    dispatch(addFilter({
                        not: not,
                        sort: [
                            'requestData.formData.main.card.',
                            property
                        ].join('') as SectionFilter['sort'],
                        value: [String(rawValue)]
                    }))
                } else if (_.includes(formDataSearchParamsKeys, property)) {
                    dispatch(addFilter({
                        not: not,
                        sort: [
                            'requestData.formData.main.searchParams.',
                            property
                        ].join('') as SectionFilter['sort'],
                        value: [String(rawValue)]
                    }))
                } else {
                    dispatch(addFilter({
                        not: not,
                        sort: property,
                        value: [String(rawValue)]
                    }))
                }
            }

            const zoomIn = <div className={'d-inline-block'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(false)
                }}>
                <FaSearchPlus />
            </div>

            const zoomOut = <div className={'d-inline-block ms-1'}
                onClick={(e) => {
                    e.stopPropagation()
                    zoomAction(true)
                }}>
                <FaSearchMinus />
            </div>

            return (
                // hyphens are not part of the filter.
                <HoverFilter>
                    <div className={'pe-2 py-1'}>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.INCLUDE}</div>}>
                            {zoomIn}
                        </Tippy>
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.EXCLUDE}</div>}>
                            {zoomOut}
                        </Tippy>
                    </div>
                    <div>
                        {
                            cellContent
                        }
                    </div>
                </HoverFilter>
            )
        }

        const LoadingContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        <SpinnerContainer>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>{MESSAGE.TABLE.FETCH}</span>
                        </SpinnerContainer>
                    </small>
                </td>
            </tr>

        )

        const EmptyCellContent = (
            <tr className={'message'}>
                <td colSpan={tableData.columns.length + ACTIONS.length}
                    className={'position-relative'}>
                    <small className={'d-block text-center'}>
                        {MESSAGE.TABLE.EMPTY}
                    </small>
                </td>
            </tr>
        )

        const cellContent = (
            _.map(tableData.paginated, (dataObject, groupIndex) => {
                // step 1: just display the section that has the lowest order value.

                /** don't forget to include actionButtons. */

                const mainSection: Section | undefined = _.sortBy(
                    dataObject.sections, 'order'
                )[0]

                const hasCrashed = _.filter(dataObject.sections, (obj) => {
                    return obj.hasCrashed === true
                })

                const actionButtons =
                        _.map(ACTIONS, (action, cellIndex) => {
                            const cellKey = [
                                'report-cell-' + groupIndex +
                                '-' + (1 + cellIndex + tableData.columns.length)
                            ].join('')

                            const onClick = (
                                e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                            ) => {
                                e.stopPropagation()

                                if (action.value === 'DOWNLOAD_PDF') {
                                    // if there is a crashed segment, show a warning first.
                                    if (hasCrashed.length) {
                                        dispatch(addModal({
                                            id: uniqueString(),
                                            open: true,
                                            operation: 'INCOMPLETE_REPORT',
                                            isBorderWide: true,
                                            report: dataObject
                                        }))
                                    } else {
                                        downloadPDF(dataObject, false)
                                    }
                                } else if (action.value === 'REPAIR_SECTIONS') {
                                    repairSections(dataObject)
                                }
                            }

                            /** now to show which buttons to disable AND remove functionality
                             * - for the downloadCompleteReport, it's ok for all cases.
                             * - for the repair sections, only render if there is at least
                             * one crashed section.
                             */

                            const disableRepair: boolean = (
                                action.value === 'REPAIR_SECTIONS' &&
                                hasCrashed.length <= 0
                            )

                            return (
                                <td key={cellKey}>
                                    <Button
                                        onClick={disableRepair ? () => {} : onClick}
                                        size={'sm'}
                                        mode={action.mode}
                                        // disable if said id exists in the loading array.
                                        disabled={disableRepair
                                            ? true
                                            : _.some(
                                                loadingQueue,
                                                queue => queue === dataObject.id
                                            )
                                        }
                                    >
                                        {action.label}
                                    </Button>
                                </td>

                            )
                        })

                const rowKey = [
                    'group-row', groupIndex, 'section', 0
                ].join('-')

                return (
                    <tr key={rowKey} onClick={() => {
                        dispatch(addModal({
                            id: uniqueString(),
                            open: true,
                            operation: 'REPORT_VIEW',
                            isBorderWide: true,
                            report: dataObject
                        }))
                    }}>
                        {
                            _.map(tableData.columns, (column, cellIndex) => {
                                const cellKey = [
                                    rowKey,
                                    'cell', cellIndex
                                ].join('-')

                                return (
                                    <td key={cellKey}>
                                        {cellBody(mainSection, column.value)}
                                    </td>
                                )
                            })
                        }
                        <td>
                            {
                                hasCrashed.length ? TEXT.NO : TEXT.YES
                            }
                        </td>
                        {actionButtons}
                    </tr>
                )
            })

        )

        return (
            getReportsMutation.isLoading
                ? LoadingContent
                : getReportsMutation.isSuccess
                    ? (
                        tableData.paginated.length
                            ? cellContent
                            : EmptyCellContent
                    )
                    : JSON.stringify(getReportsMutation.error)
        )
    }, [tableData, getReportsMutation, loadingQueue])

    /**
     * when mounted, perform getOrders api
     * but you also need to do this when you want to manually refresh.
     * use a property in a store for this.
     * */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    useEffect(() => {
        if (tableData.refetch) {
            return fetchData()
        }
    }, [tableData.refetch])

    useEffect(() => {
        /**
         * in case that the refetch value is true meaning that
         * you did a refresh, set it to false now. It should be
         * safe to abort the promise as the call finished.
         *
         * and just to make sure refetch is setting when it has to,
         * we are also going to check if refetch is truthy, even
         * when it's not added as a dependency.
         * */
        if (getReportsMutation.isSuccess && tableData.refetch) {
            dispatch(setRefetch(false))
        }
    }, [
        getReportsMutation.isSuccess
    ])

    /** please refer to smartSearch in method.ts to know why use-debounce
     * works in react compared to basic debounce packages.
     */

    const setSearchDebounce = useDebouncedCallback((value: string) => {
        dispatch(setSearch(value))
    }, DEBOUNCE_SEARCH_TIME)

    const setData = useDebouncedCallback(() => {
        if (getReportsMutation.data) {
            /** this will be done differently with groups. You cannot flatten
             * nested arrays which is what the response of getReports
             * contains. Smartsearch is designed as intended when one
             * wants to display a specific section.
             */

            const modifiedColumns = _.filter(_.map(
                _.cloneDeep(tableData.columns), (column) => {
                    switch (column.value) {
                        case 'deviceid': {
                            column.value = 'sections[0].requestData.formData.main.card.deviceid' as
                            keyof Section
                            break
                        }
                        case 'serviceType': {
                            column.value =
                            'sections[0].requestData.formData.main.card.serviceType' as
                            keyof Section
                            break
                        }
                        case 'inFace': {
                            column.value = 'sections[0].requestData.formData.main.card.inFace' as
                            keyof Section
                            break
                        }
                        case 'timeFrom': {
                            column.value =
                            'sections[0].requestData.formData.main.searchParams.timeFrom' as
                            keyof Section
                            break
                        }
                        case 'timeTo': {
                            column.value =
                            'sections[0].requestData.formData.main.searchParams.timeTo' as
                            keyof Section
                            break
                        }
                        case 'creationDate': {
                            column.value = 'sections[0].creationDate' as
                            keyof Section
                            break
                        }
                    }

                    return column
                }
            ), 'include')

            let data = _.map(_.cloneDeep(getReportsMutation.data.data), (obj) => {
                const filteredSections = (smartSearch(
                    obj.sections,
                    tableData.filters,
                    tableData.search
                ) || []) as Section[]

                obj.sections = filteredSections

                return obj
            })

            /** if the sections.length is empty, filter it out from the list */
            data = _.filter(data, (obj) => {
                return obj.sections.length > 0
            })

            // now change the value of the tableData.columns
            // to that of a property name in the response object.
            // for example, if you are sorting deviceid, you
            // need to specify requestData.formData.main.card.deviceid

            // smartOrder can't be used here so we will be using a custom sort.
            const orderedData = _.orderBy(
                data, _.map(modifiedColumns, (column) => column.value),
                _.map(modifiedColumns, (column) => {
                    return _.lowerCase(column.arrange)
                }) as Many<'asc' | 'desc'>
            )

            /**
             * set the filtered data to tableData.filtered.
             * any sort or pagination should be done in a separate useEffect
             * */
            dispatch(setFiltered(orderedData))
        }
    }, DEBOUNCE_SEARCH_TIME)

    // const paginateData = useDebouncedCallback(() => {
    const paginateData = () => {
        /** you now will paginate your data here. */
        const result = paginate(
            tableData.filtered,
            tableData.page,
            tableData.count
        ) as Report[]

        dispatch(setPaginated(result))
    // }, DEBOUNCE_SEARCH_TIME)
    }

    useEffect(() => {
        setData()
    }, [
        /** refreshed data from getOrders invocation */
        getReportsMutation.data,
        /** when tableData.filters updates */
        tableData.filters,
        /** when tableData.search updates */
        tableData.search,
        /** when tableData.columns updates */
        tableData.columns
    ])

    useEffect(() => {
        if (getReportsMutation.error) {
            console.error(getReportsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getReportsMutation.error])

    useEffect(() => {
        /** you now will paginate your data here. */
        paginateData()
    }, [
        tableData.filtered,
        tableData.page,
        tableData.count
    ])

    /** updated at all table data code. add a useEffect to reset the page index */
    useEffect(() => {
        dispatch(setPage(PAGE_START))
    }, [
        tableData.filters,
        tableData.search,
        tableData.count,
        tableData.columns
    ])

    /** a hook to update filterInterface.values with filterInterface.selected.key
     * as a dependency. this can only proceed if the response data is a lengthy
     * array.
     */
    useEffect(() => {
        /** iterate through the entire array, mapping all the values via
         * property type AND then removing duplicates.
         */

        /** _.has also accepts nested properties like a.b.c */
        const key = tableData.filterInterface.selected.key || ''
        /**
         * NOTE: opt out property names that aren't string and number data types.
         * Remove falsy values in the array using _.compact
         */
        const values = _.uniq(
            _.compact(
                _.map(tableData.filtered, (obj) => {
                    /**
                     * also flatten object to selected nested properties.
                     * had to deal with using any just so we can selected
                     * nested properties of an object.
                     *
                     * because we have compact, we can filter out falsy values
                     */

                    const flattenedObj = flatten(obj) as any
                    const result = flattenedObj[key]

                    if (_.isObject(result)) {
                        return false
                    } else {
                        return result
                    }
                })
            )
        )

        dispatch(
            setFilterValues(values)
        )

        // if the key is selected, also empty values
        dispatch(
            setSelectedValues([])
        )
    }, [
        tableData.filtered,
        tableData.filterInterface.selected.key
    ])

    const FilterInterface = useMemo(() => {
        /** a dropdown to show the list of available properties */
        const keyDropdown = _.map(tableData.filterInterface.keys, (key) => {
            return {
                label: key,
                value: key
            }
        })

        /** a dropdown for selecting values from a property */
        const valuesDropdown = _.map(tableData.filterInterface.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selectedValues = _.map(tableData.filterInterface.selected.values, (key) => {
            return {
                label: key,
                value: key
            }
        })

        const selected = tableData.filterInterface.selected

        // don't add a filter IF comparison, key
        // OR values are undefined or empty.
        const disableButton = !selected.comparison || !selected.key ||
        (!selected.values?.length)

        const selectMulti = _.includes(MULTI_SELECTION_OPERATORS, selected.comparison)
        return (
            <div>
                <div className={'row mb-2 align-items-end'}>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.FIELDS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={keyDropdown}
                            value={_.find(
                                keyDropdown,
                                (e) => e.value === tableData.filterInterface.selected.key
                            )}
                            onChange={(e: {
                        label: string,
                        value: string
                    }) => {
                                if (e) {
                                    dispatch(setSelectedKey(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    <div className={'col-12 col-md mb-2'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.COMPARISON_OPERATORS}
                        </Text>
                        <Select
                            className={'col-auto'}
                            options={COMPARISON_OPERATORS}
                            value={_.find(
                                COMPARISON_OPERATORS,
                                (e) => e.value === tableData.filterInterface
                                    .selected.comparison
                            )}
                            onChange={(e: {
                                label: string,
                                value: ComparisonOperator
                            }) => {
                                if (e) {
                                    dispatch(setSelectedComparison(e.value))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles
                            }}
                            theme={reactSelect.theme}
                        />
                    </div>
                    {/* now add the filter. */}
                    <div className={'col-12 col-md-auto mb-2'}>
                        <Button mode={'primary'} size={'sm'}
                            disabled={disableButton}
                            onClick={() => {
                                const comparison = tableData.filterInterface
                                    .selected.comparison
                                const sort = tableData.filterInterface.selected.key || ''
                                let values = tableData.filterInterface.selected.values

                                const not = _.includes(NOT_OPERATORS, comparison)

                                // check comparison.value is ":" or "IS ONE OF"
                                if (!_.includes(
                                    MULTI_SELECTION_OPERATORS, comparison
                                )) {
                                    values = _.slice(values, 0, 1)
                                }

                                dispatch(
                                    addFilter({
                                        not: not,
                                        sort: String(sort) as SectionKeys,
                                        value: _.map(values, (a) => a)
                                    })
                                )
                            }}>
                            {TEXT.FILTER.ADD}
                        </Button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Text size={'sm'} className={'d-inline-block mb-2'}>
                            {TEXT.FILTER.VALUES}
                        </Text>
                        {selectMulti
                            ? <Select
                                className={'col-auto'}
                                isMulti
                                options={valuesDropdown}
                                value={selectedValues}
                                onChange={(e: {
                                    label: string,
                                    value: string
                                }[]) => {
                                    if (e) {
                                        dispatch(
                                            setSelectedValues(_.map(e, (obj) => {
                                                return obj.value
                                            }))
                                        )
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />
                            : <Select
                                className={'col-auto'}
                                options={valuesDropdown}
                                value={_.find(
                                    valuesDropdown,
                                    (e) => e.value === tableData.filterInterface
                                        .selected.comparison
                                )}
                                onChange={(e: {
                                label: string,
                                value: string
                            }) => {
                                    if (e) {
                                        dispatch(setSelectedValues([e.value]))
                                    }
                                }}
                                styles={{
                                    ...reactSelect.styles
                                }}
                                theme={reactSelect.theme}
                            />}
                    </div>

                </div>

            </div>
        )
    }, [tableData.filterInterface])

    return (
        <div>
            {/* this row contains an interval selection */}
            <div className={'justify-content-end row mt-3'}>
                <div className={'col-auto'}>
                    <div className={'align-items-center justify-content-center row text-center'}>
                        <small className={'col col-md-auto'}>{TEXT.TABLE.REFRESH}</small>
                        <Select
                            className={'col-auto px-0'}
                            options={INTERVALS}
                            value={_.find(
                                INTERVALS,
                                (e) => e.value === tableData.interval.value
                            )}
                            onChange={(e) => {
                                if (e) {
                                    dispatch(setIntervalObj(e))
                                }
                            }}
                            styles={{
                                ...reactSelect.styles,
                                ...FIXED_REACT_SELECT_WIDTH.MEDIUM
                            }}
                            theme={reactSelect.theme}
                        />
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.SEARCH.REFRESH.LABEL}</div>}>
                            <span
                                className={'col col-md-auto icon mt-2 mt-sm-0 pointer'}
                                onClick={() => {
                                    dispatch(setRefetch(true))
                                    refreshTimer.reset()
                                }}>
                                <MdRefresh />
                            </span>
                        </Tippy>

                    </div>
                </div>
            </div>
            {/* in this row, show the search bar on the left
        and the pagination on the right */}
            <div className={'align-items-center justify-content-between row mt-3'}>
                <div className={'col-12 col-md px-4'}>
                    <SearchRow className={'align-items-center row'}>
                        <label className={'col-auto pe-0'} htmlFor={TEXT.SEARCH.SEARCH.ID}>
                            <FaSearch />
                        </label>
                        <input
                            className={'col'}
                            type={'text'}
                            id={TEXT.SEARCH.SEARCH.ID}
                            defaultValue={tableData.search}
                            onChange={(e) => {
                                setSearchDebounce(e.target.value)
                            }}
                            placeholder={'Search'}
                        />
                        {/* when this is clicked, a popup window will show up
                        it was previously a tippy but now it's an interface below
                         to utilize space. */}
                        <Tippy
                            className={'tippy-box'}
                            arrow
                            content={<div>{TEXT.FILTER.TOGGLE}</div>}>
                            <Text size={'xl'}
                                className={'d-inline-block col-auto pe-0'}
                                onClick={() => {
                                    dispatch(
                                        toggleFilterInterface(!tableData.filterInterface.show)
                                    )
                                }}
                            >
                                <MdManageSearch />
                            </Text>
                        </Tippy>
                    </SearchRow>
                </div>

                {/* this is the dropdown for page count */}
                <div className={'col-12 col-md-auto'}>
                    <div className={'align-items-center justify-content-between row'}>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0'}>
                            <div className={'align-items-center justify-content-center row'}>
                                <small className={'col-auto'}>{TEXT.TABLE.PAGE}</small>
                                <Select
                                    className={'col-auto'}
                                    options={ROW_COUNT_SELECTION}
                                    value={_.find(
                                        ROW_COUNT_SELECTION,
                                        (e) => e.value === tableData.count
                                    )}
                                    onChange={(e: typeof ROW_COUNT_SELECTION[0]) => {
                                        if (e) {
                                            dispatch(setCount(e.value))
                                        }
                                    }}
                                    styles={{
                                        ...reactSelect.styles,
                                        ...FIXED_REACT_SELECT_WIDTH.SMALL
                                    }}
                                    theme={reactSelect.theme}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-sm-auto mt-3 mt-md-0 text-center ps-0'} >
                            <Pagination

                                current={tableData.page}
                                total={tableData.filtered.length}
                                pageSize={tableData.count}
                                onChange={(current) => {
                                    dispatch(setPage(current))
                                }}
                                locale={EN_US}
                            />
                        </div>

                    </div>

                </div>
            </div>
            {/* row to show when kubernetes search toggles */}
            {
                tableData.filterInterface.show
                    ? (
                        <div className={'mt-2'}>
                            {FilterInterface}
                        </div>
                    )
                    : ''
            }
            {/*
            this row contains a filters where you can include or exclude. can also clear all
            since this will become a component shared, pass the removeFilter AND
            removeFilters actions as props.
        */}
            <div className={'row mt-2'}>
                {/* display columns aka a filter shere */}
                <div className={'col ps-4'}>
                    <div className={'row'}>
                        {
                            _.map(tableData.filters, (filter, index) => {
                                const comparisonString = filter.value.length > 1
                                    ? filter.not ? 'IS NOT ONE OF' : 'IS ONE OF'
                                    : ':'
                                return (
                                    <FilterCell key={'filter-' + index} className={
                                        ['col-auto me-2 px-2 mt-1',
                                            filter.not ? 'not' : ''].join(' ')
                                    }>
                                        <Text size={'xs'}>
                                            {[
                                                [
                                                    filter.not ? '!' : '',
                                                    filter.sort
                                                ].join(''),
                                                comparisonString,
                                                filter.value.join(', ')
                                            ].join(' ')}
                                        </Text>
                                        <Text size={'xs'} className={'ps-2'} onClick={() => {
                                            dispatch(removeFilter(filter))
                                        }}>
                                            <FaTimes />
                                        </Text>
                                    </FilterCell>
                                )
                            })
                        }
                    </div>
                </div>
                <span
                    className={[
                        'col-auto icon mt-2 mt-sm-0 pointer ps-0',
                        tableData.filters.length ? 'd-block' : 'd-none'
                    ].join(' ')}
                    onClick={() => {
                        dispatch(removeFilters())
                    }}
                >
                    {/* clear all filters button. baseline, not center */}
                    <FaTimes />
                </span>
            </div>
            {/*
                then populate tbody using the paginated result. attempting to
                iterate using the columns data. failed because typescript
                discourages implicit declaration, including calling property names
                of objects using variables INSTEAD of a coding in the exact name.
                it would be best to perform explicit declaration
                as the opposite defeats the purpose of using Typescript.

                but if there is a fair justification to use this, just use 'any' on the
                paginated object as long as you avoid expected crashes by adding conditions.
            */}
            <div className={'justify-content-center row mt-3'}>
                <div className={'col'}>
                    <Table
                        className={'table-striped table-hover'}
                        height={TABLE_CONTAINER_HEIGHT.MEDIUM}
                        bgIndex={1}
                    >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {TableHead}
                                    {/* add a table header cell to check if
                                    the report is complete as a whole. Yes means
                                    download the report. No would require you
                                    to generate the broken/missing sections again. */}
                                    {
                                        _.map(EXTRA_HEADERS, (o, index) => {
                                            return <th key={index}>{o}</th>
                                        })
                                    }
                                    {/* action section is created here. */}
                                    {
                                        ACTIONS.length
                                            ? (<th colSpan={ACTIONS.length}>
                                                <small>{TEXT.TABLE.ACTIONS}</small>
                                            </th>)
                                            : <th className={'d-none'}></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TableBody}
                            </tbody>
                        </table>
                    </Table>
                </div>
            </div>

            {
                // using ids to select object to fetch data or close modals as one does fit.
                _.map(modals, (modal, index) => {
                    const key = [
                        'modal-', modal.operation, '-', index
                    ].join('')

                    /** going to shorten this. create string for classNames prop, */
                    let component: ReactElement<any, any> = <></>
                    let classNames = {
                        modal: ''
                    }

                    /** show a confirmation window ONLY if modal.report?.sections
                     * has at least one faulty section.
                     */

                    if (modal.operation === 'INCOMPLETE_REPORT') {
                        if (modal.report) {
                            classNames = {
                                modal: ['xs', 'warning'].join(' ')
                            }
                            component = <IncompleteConfirmation
                                modal={modal}
                                addModal={addModal}
                                closeModal={closeModal}
                                downloadPDF={downloadPDF}
                            />
                        } else {
                            console.error(PRINT_TEXT.DOWNLOAD.MISSING_REPORT)
                            toast.error(
                                PRINT_TEXT.DOWNLOAD.MISSING_REPORT,
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                            return ''
                        }
                    } else if (
                        modal.operation === 'REPORT_VIEW'
                    ) {
                        if (modal.report?.sections.length) {
                            classNames = {
                                modal: ['lg', 'primary'].join(' ')
                            }
                            component = <SectionDetails
                                modal={modal}
                                addModal={addModal}
                                closeModal={closeModal}
                            />
                        } else {
                            console.error(PRINT_TEXT.CREATE_SECTIONS.ERROR.EMPTY_SECTIONS)
                            toast.error(
                                PRINT_TEXT.CREATE_SECTIONS.ERROR.EMPTY_SECTIONS,
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                            return ''
                        }
                    } else {
                        return ''
                    }

                    return <Modal
                        key={key}
                        classNames={classNames}
                        open={modal.open}
                        center focusTrapped={false}
                        onAnimationEnd={() => {
                            if (!modal.open) {
                                dispatch(removeModal(modal))
                            }
                        }}
                        onClose={() => {
                            dispatch(closeModal(modal))
                        }}
                        closeIcon={<AiOutlineClose />}>
                        {component}
                    </Modal>
                })
            }
        </div>
    )
}
export default ReportMenu

import { MutationContext } from '@root/MutationProvider'
import produce from 'immer'
import _ from 'lodash'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer
} from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

/** OTHER FILE IMPORTS */
import { useDoArchiveMutation } from '@apis/watchdog/soc-data/event-api'
import {
    useAppDispatch
} from '@app/hook'
import {
    DEBOUNCE_SEARCH_TIME,
    TEXT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    INITIAL_VALUES,
    TEXT as EVENT_TEXT,
    VALIDATION_SCHEMA
} from '@constants/watchdog/soc-data/event'
import {
    EventColumn,
    EventModal,
    EventObj,
    /** can be reused from group archive. */
    GroupArchive,
    GroupArchiveActions,
    GroupArchiveState
} from '@interfaces/watchdog/soc-data/event'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
    Button,
    FormStyledComponents as Form,
    SpinnerContainer,
    Text
} from '@styles/components'
import { useFormik } from 'formik'

const DoMultipleArchive = ({
    removeEventFromMultiple, setRefetch, modal, addModal, closeModal, groupKeys
} : {
    removeEventFromMultiple: ActionCreatorWithPayload<EventObj, string>,
    setRefetch: ActionCreatorWithPayload<boolean, string>
    modal: EventModal,
    addModal: ActionCreatorWithPayload<EventModal, string>,
    closeModal: ActionCreatorWithPayload<EventModal, string>,
    groupKeys: EventColumn[]
}) => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()
    const [doArchive] = useDoArchiveMutation()

    const [groupArchiveState, groupArchiveDispatch] = useReducer(
        (state: GroupArchiveState, action: GroupArchiveActions) => {
            switch (action.type) {
                case 'SET_DATA': {
                    return produce(state, draft => {
                        const data = _.map(action.value, (event) => {
                            return {
                                eventObj: event,
                                isLoading: false,
                                status: '',
                                isInitialized: false,
                                success: null
                            } as GroupArchive
                        })

                        draft.data = data
                    })
                }
                case 'SET_IS_LOADING': {
                    return produce(state, draft => {
                        const found = _.find(
                            draft.data,
                            (obj) => obj.eventObj.id === action.value.id
                        )
                        if (found) {
                            found.isLoading = action.value.boolean
                        }
                    })
                }
                case 'SET_INITIALIZED': {
                    return produce(state, draft => {
                        const found = _.find(
                            draft.data,
                            (obj) => obj.eventObj.id === action.value.id
                        )
                        if (found) {
                            found.isInitialized = action.value.boolean
                        }
                    })
                }
                case 'SET_STATUS': {
                    return produce(state, draft => {
                        const found = _.find(
                            draft.data,
                            (obj) => obj.eventObj.id === action.value.id
                        )
                        if (found) {
                            found.status = action.value.status
                        }
                    })
                }
                case 'SET_SUCCESS': {
                    return produce(state, draft => {
                        const found = _.find(
                            draft.data,
                            (obj) => obj.eventObj.id === action.value.id
                        )
                        if (found) {
                            found.success = action.value.boolean
                        }
                    })
                }
            }
        }, {
            data: []
        }
    )

    const archiveFormik = useFormik({
        initialValues: INITIAL_VALUES.COMMENT,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA.COMMENT,
        onSubmit: async () => {
            const newToken = await revalidateToken()

            _.map(groupArchiveState.data, (groupArchive) => {
                groupArchiveDispatch({
                    type: 'SET_INITIALIZED',
                    value: {
                        id: groupArchive.eventObj.id,
                        boolean: true
                    }
                })

                groupArchiveDispatch({
                    type: 'SET_IS_LOADING',
                    value: {
                        id: groupArchive.eventObj.id,
                        boolean: true
                    }
                })

                doArchive({
                    authToken: newToken,
                    id: groupArchive.eventObj.id,
                    clusterid: groupArchive.eventObj.clusterid,
                    comment: archiveFormik.values.comment
                })
                    .unwrap()
                    .then((data) => {
                        if (data.status) {
                            groupArchiveDispatch({
                                type: 'SET_SUCCESS',
                                value: {
                                    id: groupArchive.eventObj.id,
                                    boolean: true
                                }
                            })

                            groupArchiveDispatch({
                                type: 'SET_STATUS',
                                value: {
                                    id: groupArchive.eventObj.id,
                                    status: data.status
                                }
                            })
                        } else {
                            groupArchiveDispatch({
                                type: 'SET_SUCCESS',
                                value: {
                                    id: groupArchive.eventObj.id,
                                    boolean: false
                                }
                            })
                        }

                        groupArchiveDispatch({
                            type: 'SET_IS_LOADING',
                            value: {
                                id: groupArchive.eventObj.id,
                                boolean: false
                            }
                        })
                    })
                    .catch((error) => {
                        console.error(error)

                        groupArchiveDispatch({
                            type: 'SET_IS_LOADING',
                            value: {
                                id: groupArchive.eventObj.id,
                                boolean: false
                            }
                        })
                    })
            })
        }
    })

    useEffect(() => {
        if (modal.eventGroup) {
            groupArchiveDispatch({
                type: 'SET_DATA',
                value: modal.eventGroup
            })
        }
    }, [modal.eventGroup])

    const displayEventDetail = useCallback((obj: EventObj) => {
        return (
            _.map(groupKeys, (column, colIndex) => {
                const rowKey = [
                    'columnKey-', colIndex
                ].join('')

                const value = obj[column.value]

                return (
                    <div className={
                        ['row', colIndex ? 'mt-2' : ''].join(' ')
                    } key={rowKey}>
                        <Text size={'xs'} className={'col-3'}>{
                            column.label
                        }</Text>
                        <Text size={'xs'} className={'col'}>{
                            value
                        }</Text>
                    </div>
                )
            })
        )
    }, [])

    const GroupDetails = useMemo(() => {
        if (groupKeys.length &&
            modal.eventGroup &&
            modal.eventGroup.length
        ) {
            // in the array of events, use uniqBy with the event name

            return _.map(
                _.uniqBy(modal.eventGroup, 'eventname'),
                (obj, index) => {
                    const key = [
                        'eventObj-', index
                    ].join('')

                    return <div className={'mb-2'} key={key}>
                        {displayEventDetail(obj)}
                    </div>
                }
            )
        } else {
            return ''
        }
    }, [modal.eventGroup, groupKeys])

    // progress bar increments only when it is finished loading. regardless of result
    const ProgressBar = useMemo(() => {
        /** we need to check if the array has at least one initialized value of true */
        const isInitialized = _.find(groupArchiveState.data, (groupArchive) => {
            return groupArchive.isInitialized === true
        })

        const notLoading = _.filter(groupArchiveState.data, (groupArchive) => {
            return groupArchive.isLoading === false
        })

        const progressBarWidth = notLoading.length / groupArchiveState.data.length * 100

        return (
            isInitialized
                ? <div className={'progress mt-2'}>
                    <div className={'progress-bar'} style={
                        { width: [progressBarWidth, '%'].join('') }
                    }>
                        {EVENT_TEXT.GROUP_ARCHIVE.FORM.PROGRESS_LABEL}
                        {' ('}{[progressBarWidth, '%'].join('')}{')\r'}
                    </div>
                </div>
                : ''
        )
    }, [groupArchiveState])

    const debounceToastMessage = useDebouncedCallback((status: string) => {
        toast.success(status, { ...TOASTIFY_DEFAULT_OPTIONS })
        /** after this is done, refresh data and close modal */
        dispatch(closeModal(modal))
        dispatch(setRefetch(true))
    }, DEBOUNCE_SEARCH_TIME)

    // display success message IF there is no false value in success property.
    useEffect(() => {
        const allSuccess = _.filter(groupArchiveState.data, (groupArchive) => {
            // value can either be true/false/null
            return groupArchive.success === true
        })

        if (
            groupArchiveState.data.length &&
            allSuccess.length === groupArchiveState.data.length
        ) {
            debounceToastMessage(groupArchiveState.data[0].status)

            // and refresh the event slice to check if the events are actually archived.
            dispatch(setRefetch(true))
            // Make sure you uncheck any events that might have been a part of a separate
            // archive operation but was archived by GROUP.
            _.forEach(groupArchiveState.data, (obj) => {
                dispatch(removeEventFromMultiple(obj.eventObj))
            })
            // after setting a successful group archive message, close this modal
            // when this component closes the reducer will be deinitialized.
            dispatch(closeModal(modal))
        }
    }, [groupArchiveState])

    /** so this is one is going to be a little more different.
     * once we confirm, immediately populate it with a list of
     * event ids and a status update from the doArchive api calls.
     * We have to perform an unwrap to individually
     * retrieve the result
     */
    const CommentInput = useMemo(() => {
        return (
            <Form.Group className={'row align-items-center'}>
                <Form.Label
                    className={'col-12 col-md-3 ps-0'}
                    htmlFor={EVENT_TEXT.COMMENT.FORM.COMMENT.ID}>
                    {EVENT_TEXT.COMMENT.FORM.COMMENT.LABEL}
                </Form.Label>
                <Form.TextArea
                    errors={Boolean(archiveFormik.errors.comment)}
                    className={'col-md-9 col-12'}
                    name={'comment'}
                    id={EVENT_TEXT.COMMENT.FORM.COMMENT.ID}
                    onChange={archiveFormik.handleChange}
                    value={archiveFormik.values.comment}
                />
                <Form.Feedback
                    className={'col-md-9 offset-md-3'}
                    errors={Boolean(archiveFormik.errors.comment)} >
                    {
                        archiveFormik.errors.comment ? archiveFormik.errors.comment : null
                    }
                </Form.Feedback>
            </Form.Group>
        )
    }, [archiveFormik.values.comment, archiveFormik.errors.comment])

    const SubmitButton = useMemo(() => {
        /** disable as long as there is at least one isLoading
         * property that has a value of true
         */

        const hasLoading = _.some(groupArchiveState.data, 'isLoading')

        const buttonContent = hasLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{EVENT_TEXT.GROUP_ARCHIVE.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : EVENT_TEXT.GROUP_ARCHIVE.FORM.SUBMIT_BUTTON

        return (
            <Button
                type={'submit'}
                mode={'primary'}
                disabled={hasLoading}
            >{buttonContent}</Button>
        )
    }, [groupArchiveState])
    return (
        <div>
            <h5>{EVENT_TEXT.GROUP_ARCHIVE.TITLE}</h5>
            <small className={'d-block my-2'}>
                {EVENT_TEXT.GROUP_ARCHIVE.MESSAGE}
            </small>
            {GroupDetails}
            {ProgressBar}
            <Form.Main onSubmit={archiveFormik.handleSubmit} className={'px-0 mt-0'}>
                {CommentInput}
                <div className={'row justify-content-end'}>
                    <div className={'col-auto mb-2 mb-md-0 text-center'}>
                        {SubmitButton}
                    </div>
                    <div className={'col-auto'}>
                        <Button
                            type={'button'}
                            mode={'secondary'} onClick={() => {
                                dispatch(closeModal(modal))
                            }}
                        >{TEXT.MODAL.CLOSE}</Button>
                    </div>
                </div>
            </Form.Main>

        </div>
    )
}

export default DoMultipleArchive

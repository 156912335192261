import {
    useGetModalDetailsMutation
} from '@apis/dashboard/grc/nist-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT as NIST_TEXT
} from '@constants/dashboard/grc/nist'
import {
    getUtcRanges
} from '@constants/main/method'
import {
    ACTION_MUTATION_PROMISE,
    MESSAGE,
    PRINT_CHECK_TIMER,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import ComplianceIndexChart from '@features/dashboard/grc/nist/ComplianceIndexChart'
import CoverageGauge from '@features/dashboard/grc/nist/CoverageGauge'
import DetailedComplianceLineChart from '@features/dashboard/grc/nist/DetailedComplianceLineChart'
import DetailedCoverageLineChart from '@features/dashboard/grc/nist/DetailedCoverageLineChart'
import { ModalDetailsRequest } from '@interfaces/dashboard/grc/nist'
import { TokenAuth } from '@interfaces/main/root'
import { MutationContext } from '@root/MutationProvider'
import {
    resetNistDetails,
    setCurrentParams,
    setRefetch
} from '@slices/dashboard/grc/nist/details'
import {
    selectNistDetails,
    selectNistMain
} from '@slices/main/print/section'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    selectToken
} from '@slices/main/token'
import {
    CollapsibleText,
    Container,
    PageBreakInside,
    PrintMargin,
    SectionCard,
    Text
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import parse from 'html-react-parser'
import colorGradient from 'javascript-color-gradient'
import _ from 'lodash'
import React, {
    createRef,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import {
    toast
} from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const NistDetails = () => {
    const rootContext = useContext(MutationContext)
    const revalidateToken = rootContext.revalidateToken

    const dispatch = useAppDispatch()

    const token = useAppSelector(selectToken)

    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)
    const dashboardMain = useAppSelector(selectNistMain)
    const nistDetailsForm = useAppSelector(selectNistDetails)
    const [isPrintComplete, setIsPrintComplete] = useState<boolean>(false)
    const [hasCrashed, setHasCrashed] = useState<boolean>(false)

    /** NOTE: the response data from /modalDetails is exactly the same
     * from /modal. To reduce load time, just use the selectedChapter
     * variable. You still need the nistData though.
     */
    const [getModalDetails, getModalDetailsMutation] = useGetModalDetailsMutation()

    const [severityGradient, setSeverityGradient] = useState<string[]>([])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        const result = colorGradient
            .setGradient(
                stylesheet.style.colorPresets.red,
                stylesheet.style.colorPresets.yellow,
                stylesheet.style.colorPresets.green
            )
            .setMidpoint(100)
            .getArray()

        setSeverityGradient(result)
    }, [])

    useEffect(() => {
        if (getModalDetailsMutation.error) {
            console.error(getModalDetailsMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
            dispatch(setRefetch(false))
        }
    }, [getModalDetailsMutation.error])

    /** WILL HAVE date ranges like in the parent modal. Starting ranges are the data
     *  of ip address being passed over */
    const unsubscribeGetModalDetails = () => {
        const unsubscribeMutation = getModalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const fetchData = () => {
        unsubscribeGetModalDetails()

        let getModalDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken()
                if (
                    isMounted &&
                    dashboardMain.card &&
                    dashboardMain.searchParams
                ) {
                    const newRanges = getUtcRanges({
                        start: dashboardMain.searchParams.timeFrom,
                        end: dashboardMain.searchParams.timeTo
                    })

                    dispatch(setCurrentParams({
                        ranges: newRanges
                    }))

                    const requestData: ModalDetailsRequest & TokenAuth = {
                        authToken: newToken,
                        deviceid: dashboardMain.card.deviceid,
                        service_type: dashboardMain.card.serviceType,
                        chapterIdentifier: (
                            nistDetailsForm.chapterIdentifier || ''),
                        in_face: dashboardMain.card.inFace,
                        time_from: newRanges.start.toString(),
                        time_to: newRanges.end.toString()

                    }

                    getModalDetailsPromise = getModalDetails(requestData)
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getModalDetailsPromise && getModalDetailsPromise.abort()
        }
    }

    /** All useEffects are triggered simultaneously. */
    useEffect(() => {
        return fetchData()
    }, [token.valid])

    /** we just need the dataTable AND a bar chart */
    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)

        return () => {
            dispatch(resetNistDetails())
        }
    }, [])

    /** now we can do this. */
    const Title = useMemo(() => {
        const chapterName = nistDetailsForm.chapterName

        const chapterIdentifier = nistDetailsForm.chapterIdentifier

        return <span>{[
            chapterName,
            ['(', chapterIdentifier, ')'].join('')
        ].join(' ')}</span>
    }, [nistDetailsForm])

    /** show description */
    const Description = useMemo(() => {
        const description = parse(nistDetailsForm.summaryText || '')

        return nistDetailsForm.summaryText
            ? <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
                <div className={'row justify-content-center'}>
                    <div className={'col'}>
                        <Text size={'sm'}>
                            {description}
                        </Text>
                    </div>
                </div>
            </Container>
            : ''
    }, [nistDetailsForm])

    /** show paragraph data */
    const Paragraphs = useMemo(() => {
        const chapterData = nistDetailsForm.chapterData || []

        const nistData = getModalDetailsMutation.data?.data || []
        const stylesheet = createStylesheet(style, mode)

        return <Container bgIndex={2} className={'px-3 py-2 mb-3'}>
            <div className={'row'}>{
                _.map(chapterData, ({
                    paragraphName,
                    paragraphIdentifier,
                    paragraphData
                }, paragraphIndex) => {
                    const key = ['paragraphContent', paragraphIndex].join('-')
                    return (
                        <div
                            className={'mb-2 col-4'}
                            key={key}
                        >
                            <Text size={'xs'} className={'d-inline-block mb-2'}>
                                {paragraphName}
                            </Text>
                            <div className={'row'}>
                                {
                                    _.map(paragraphData, ({ alineaName, translationtoNist },
                                        sectionCardIndex) => {
                                        const sectionCardKey = [
                                            'sectionCard',
                                            paragraphIndex,
                                            sectionCardIndex
                                        ].join('-')
                                        // first thing to do is to map the array of
                                        // objects to array of strings.
                                        const simplified = _.map(translationtoNist, ({
                                            page, subcat
                                        }) => page || subcat || '')

                                        // color assignment. we use translationtoNist to
                                        // get the cards we want then get their scores.
                                        // get their average and proceed to the nextupdate
                                        // and average that as well.
                                        const score = nistData.length
                                            ? _.reduce(nistData, (total, { nistData }) => {
                                                const filtered = _.flattenDeep(
                                                    _.map(nistData, ({ data }) => {
                                                        return _.filter(data, ({ identifier }) => {
                                                            return _.includes(
                                                                simplified, identifier
                                                            )
                                                        })
                                                    })
                                                )

                                                const computed = filtered.length
                                                    ? _.reduce(
                                                        filtered, (score, obj) => {
                                                            score += obj.score
                                                            return score
                                                        }
                                                        , 0
                                                    ) / filtered.length
                                                    : 0

                                                total += computed
                                                return total
                                            }, 0) / nistData.length
                                            : 0

                                        // make sure the score is only between 0 and 100
                                        const colorIndex = _.floor((score / 4) * 100)

                                        const color = score
                                            ? severityGradient[
                                                colorIndex > 100
                                                    ? 100 - 1
                                                    : colorIndex < 0
                                                        ? 0
                                                        : colorIndex - 1
                                            ]
                                            : stylesheet.style.colorPresets.grey

                                        // return the statcard.
                                        return <div key={sectionCardKey}
                                            className={'col-6 mb-2'}>
                                            <SectionCard
                                                color={color}
                                                activeMode={mode}
                                                activeStyle={style}
                                            >
                                                <Text size={'xs'}>{alineaName}</Text>
                                            </SectionCard>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </Container>
    }, [
        nistDetailsForm,
        getModalDetailsMutation.data,
        severityGradient
    ])

    /** now create two linecharts. */
    const DetailedCompliance = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {NIST_TEXT.SECTIONS.COMPLIANCE_SCORES}
                </span>
            </CollapsibleText>
            <DetailedComplianceLineChart
                selectedChapter={nistDetailsForm}
                nistDatasets={getModalDetailsMutation.data?.data}
                isLoading={getModalDetailsMutation.isLoading}
                isSuccess={getModalDetailsMutation.isSuccess}
                error={getModalDetailsMutation.error}
            />
        </div>
    }, undefined)

    const DetailedCoverage = useMemo(() => {
        return <div>
            <CollapsibleText className={'mb-2'}>
                <span className={''}>
                    {NIST_TEXT.SECTIONS.COVERAGE_SCORES}
                </span>
            </CollapsibleText>
            <DetailedCoverageLineChart
                selectedChapter={nistDetailsForm}
                nistDatasets={getModalDetailsMutation.data?.data}
                isLoading={getModalDetailsMutation.isLoading}
                isSuccess={getModalDetailsMutation.isSuccess}
                error={getModalDetailsMutation.error}
            />
        </div>
    }, undefined)

    /** add in the charts from NISTModal */

    /** now create an array of containers containing a gauge
     * and an index bar chart.
     */

    // an array of objects with the gaugeEl and a indexEl
    const scoreContainersEl = useRef<{
        gaugeEl: React.RefObject<HTMLCanvasElement>,
        indexEl: React.RefObject<HTMLCanvasElement>,
    }>({
        gaugeEl: createRef(),
        indexEl: createRef()
    })

    const GaugeScoreContainers = useMemo(() => {
        const CoverageContainer = <div>
            <Text size={'sm'} className={'d-inline-block mb-2'}>
                {NIST_TEXT.SECTIONS.GAUGE_CONTAINERS.COVERAGE}
            </Text>
            <CoverageGauge
                activeValue={nistDetailsForm.averageTotalCoverageScore}
                chartEl={scoreContainersEl.current.gaugeEl}
                isLoading={getModalDetailsMutation.isLoading}
                isSuccess={getModalDetailsMutation.isSuccess}
                error={getModalDetailsMutation.error}
            />
        </div>

        const ComplianceContainer = <div>
            <Text size={'sm'} className={'d-inline-block mb-2'}>
                {NIST_TEXT.SECTIONS.GAUGE_CONTAINERS.COMPLIANCE}
            </Text>
            <ComplianceIndexChart
                score={nistDetailsForm.averageTotalComplianceScore}
                chartEl={scoreContainersEl.current.indexEl}
                isLoading={getModalDetailsMutation.isLoading}
                isSuccess={getModalDetailsMutation.isSuccess}
                error={getModalDetailsMutation.error}
            />
        </div>

        return <Container bgIndex={2} className={'px-3 py-2'}>
            <div className={'row'}>
                <div className={'col-8'}>
                    {CoverageContainer}
                </div>
                <div className={'col-4'}>
                    {ComplianceContainer}
                </div>
            </div>
        </Container>
    }, undefined)

    /** NOTE: this element is necessary to close the browser instance
     * <div> #printComplete </div>. Show component once calls are complete.
     * In this component, check if getModalMutation.data is truthy.
     *
     * Ideally, condition check should be performed AFTER everything
     * has been rendered. Because most components have a debounced callback hook,
     * Initialize a PRINT_CHECK_TIMER with a value of 3 seconds where the
     * target component will be shown.
     *
     */

    const completePrintFlag = useDebouncedCallback(() => {
        setIsPrintComplete(true)
    }, PRINT_CHECK_TIMER)

    const completeHasCrashedFlag = useDebouncedCallback(() => {
        setHasCrashed(true)
    }, PRINT_CHECK_TIMER)

    useEffect(() => {
        if (
            getModalDetailsMutation.data
        ) {
            completePrintFlag()
        }
    }, [
        getModalDetailsMutation.data
    ])

    useEffect(() => {
        if (
            getModalDetailsMutation.error
        ) {
            completeHasCrashedFlag()
        }
    }, [
        getModalDetailsMutation.error
    ])

    return (
        <div>

            {isPrintComplete
                ? <div
                    id={'printComplete'}
                ></div>
                : ''}
            {hasCrashed
                ? <div
                    id={'hasCrashed'}
                ></div>
                : ''}

            <PrintMargin>
                {/* header render */}
                <div className={'row justify-content-between mb-3'}>
                    <div className={'col-auto'}>
                        {Title}
                    </div>
                </div>

                <div className={'row mb-3'}>
                    <div className={'col-12'}>
                        {GaugeScoreContainers}
                    </div>
                </div>

                <PageBreakInside className={'min-width-fix'}>
                    {Description}
                </PageBreakInside>

                <PageBreakInside className={'min-width-fix'}>
                    {Paragraphs}
                </PageBreakInside>

                <PageBreakInside className={'min-width-fix'}>
                    <div className={'row mb-2'}>
                        <div className={'col'}>
                            {DetailedCompliance}
                        </div>
                    </div>
                </PageBreakInside>

                <PageBreakInside className={'min-width-fix'}>
                    <div className={'row mb-2'}>
                        <div className={'col'}>
                            {DetailedCoverage}
                        </div>
                    </div>
                </PageBreakInside>

            </PrintMargin>

        </div>
    )
}

export default NistDetails

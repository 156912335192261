import { RootState } from '@app/store'
import {
    DEFAULT_TABLE_DATA,
    COMPARISON_OPERATORS
} from '@constants/main/root'
import {
    EVENT_ROUTES,
    REPORT_ROUTES
} from '@constants/main/routes'
import { COLUMNS } from '@constants/watchdog/soc-data/event'
import {
    RefreshTime,
    FilterInterface
} from '@interfaces/main/root'
import {
    EventColumn,
    EventFilter,
    EventGroup,
    EventModal,
    EventObj,
    EventState,
    EventTableData
} from '@interfaces/watchdog/soc-data/event'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import {
    getUnixTime,
    sub
} from 'date-fns'
import deepEqual from 'deep-equal'
import _ from 'lodash'

/** there is going to be multiple components where the ONLY difference is the event type.
 * make sure on unmount, you reset the event slice.
 *
 * Note: there was an attempt to create slices for each event type (current, escalated,
 * reporting and event search) but developer opposed to avoid code duplication. Might
 * be the case if problems arise. (April 13, 2022)
 */
const initialState : EventState = {
    deviceid: '',
    tableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        ranges: {
            start: getUnixTime(sub(new Date(), { days: 1 })),
            end: getUnixTime(new Date())
        },
        columns: COLUMNS.MAIN,
        filters: [],
        filtered: [],
        /** best to make one so I can use this to toggle expand property.
         * so that we don't need to filter the data when there's no need to.
         */
        grouped: [],
        paginated: [],
        filterInterface: {
            show: false,
            keys: [],
            comparisonOperators: _.map(COMPARISON_OPERATORS, ({ value }) => value),
            values: [],
            selected: {
                key: '',
                comparison: COMPARISON_OPERATORS[0].value,
                values: []
            }
        }
    },
    tabs: [
        {
            name: 'Current',
            link: EVENT_ROUTES.CURRENT.link
        },
        {
            name: 'Escalated',
            link: EVENT_ROUTES.ESCALATED.link
        },
        {
            name: 'Report Requested',
            link: REPORT_ROUTES.MAIN.link
        },
        {
            name: 'Unclassified',
            link: EVENT_ROUTES.UNCLASSIFIED.link
        },
        {
            name: 'Search',
            link: EVENT_ROUTES.SEARCH.link
        },
        {
            name: 'Flow',
            link: EVENT_ROUTES.FLOW.link
        },
        {
            name: 'Created Reports',
            link: REPORT_ROUTES.CREATED_REPORTS.link
        }
    ],
    modals: [],
    multipleArchive: []

}

export const slice = createSlice({
    name: 'event',
    initialState: initialState,
    reducers: {
        setDeviceId: (state: EventState, action: PayloadAction<string>) => {
            state.deviceid = action.payload
        },
        setPage: (state: EventState, action: PayloadAction<number>) => {
            state.tableData.page = action.payload
        },
        setCount: (state: EventState, action: PayloadAction<number>) => {
            state.tableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: EventState, action: PayloadAction<RefreshTime>) => {
            state.tableData.interval = action.payload
        },
        setSearch: (state: EventState, action: PayloadAction<string>) => {
            state.tableData.search = action.payload
        },
        setFilters: (state: EventState, action: PayloadAction<EventFilter[]>) => {
            state.tableData.filters = action.payload
        },
        /** adds a filter */
        addFilter: (state: EventState, action: PayloadAction<EventFilter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                    filter.sort === action.payload.sort &&
                    filter.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.tableData.filters.push(action.payload)
            }
        },
        /** removes a filter */
        removeFilter: (state: EventState, action: PayloadAction<EventFilter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                deepEqual(filter.value, action.payload.value)
                )
            })
        },
        /** removes ALL filters */
        removeFilters: (state: EventState) => {
            state.tableData.filters = []
        },
        setRefetch: (state: EventState, action: PayloadAction<boolean>) => {
            state.tableData.refetch = action.payload
        },
        setStartDate: (
            state: EventState, action: PayloadAction<EventTableData['ranges']['start']>) => {
            state.tableData.ranges.start = action.payload
        },
        setEndDate: (state: EventState, action: PayloadAction<EventTableData['ranges']['end']>) => {
            state.tableData.ranges.end = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setColumns: (state: EventState, action: PayloadAction<EventColumn[]>) => {
            state.tableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setColumnInclude: (state: EventState, action: PayloadAction<{
            value: EventColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setColumnArrange: (state: EventState, action: PayloadAction<{
            value: EventColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setFiltered: (state: EventState, action: PayloadAction<EventObj[]>) => {
            state.tableData.filtered = action.payload
        },
        setGrouped: (state: EventState, action: PayloadAction<EventGroup[]>) => {
            state.tableData.grouped = action.payload
        },
        setPaginated: (state: EventState, action: PayloadAction<EventGroup[]>) => {
            state.tableData.paginated = action.payload
        },
        addModal: (state: EventState, action: PayloadAction<EventModal>) => {
            state.modals.push(action.payload)
        },
        closeModal: (state: EventState, action: PayloadAction<EventModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.open = false
            }
        },
        removeModal: (state: EventState, action: PayloadAction<EventModal>) => {
            _.remove(state.modals, modal => {
                return (
                    modal.id === action.payload.id
                )
            })
        },
        refreshDetailsModal: (state: EventState, action: PayloadAction<EventModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.detailRefresh = true
            }
        },
        toggleFilterInterface: (
            state: EventState, action: PayloadAction<FilterInterface['show']>) => {
            state.tableData.filterInterface.show = action.payload
        },
        /**
         * assigns an array of strings to represent key names from one object of
         * a response call.
         */
        setFilterKeys: (state: EventState, action: PayloadAction<FilterInterface['keys']>) => {
            state.tableData.filterInterface.keys = action.payload
        },
        /**
         * assigns an array of strings to represent values of a selected key name above
         */
        setFilterValues: (state: EventState, action: PayloadAction<FilterInterface['values']>) => {
            state.tableData.filterInterface.values = action.payload
        },
        /**
         * assign selected.key a value for filtering.
         */
        setSelectedKey: (
            state: EventState, action: PayloadAction<FilterInterface['selected']['key']>
        ) => {
            state.tableData.filterInterface.selected.key = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedComparison: (
            state: EventState, action: PayloadAction<FilterInterface['selected']['comparison']>
        ) => {
            state.tableData.filterInterface.selected.comparison = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedValues: (
            state: EventState, action: PayloadAction<FilterInterface['selected']['values']>
        ) => {
            state.tableData.filterInterface.selected.values = action.payload
        },
        addEventToMultiple: (state: EventState, action: PayloadAction<EventObj>) => {
            /** we need to find a filter object that matches. */
            const found = _.find(state.multipleArchive, event => {
                return (
                    event.id === action.payload.id
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                             * determined if said object has a match. **/
                state.multipleArchive.push(action.payload)
            }
        },
        removeEventFromMultiple: (state: EventState, action: PayloadAction<EventObj>) => {
            /** we need to find a filter object that matches. */
            _.remove(state.multipleArchive, event => {
                return (
                    event.id === action.payload.id
                )
            })
        },
        /** removes ALL filters */
        emptyEventGroup: (state: EventState) => {
            state.multipleArchive = []
        },
        resetEvent: (state: EventState) => {
            state.deviceid = initialState.deviceid
            state.modals = initialState.modals
            state.tableData = initialState.tableData
            state.tabs = initialState.tabs
            state.multipleArchive = initialState.multipleArchive
        }
    }
})

export const {
    setDeviceId,
    setFiltered,
    setGrouped,
    setPaginated,
    setPage,
    setCount,
    setColumns,
    setColumnInclude,
    setColumnArrange,
    setIntervalObj,
    setSearch,
    setFilters,
    addFilter,
    removeFilter,
    removeFilters,
    setRefetch,
    setStartDate,
    setEndDate,
    addModal,
    closeModal,
    removeModal,
    refreshDetailsModal,
    toggleFilterInterface,
    setFilterKeys,
    setFilterValues,
    setSelectedKey,
    setSelectedComparison,
    setSelectedValues,
    addEventToMultiple,
    removeEventFromMultiple,
    emptyEventGroup,
    resetEvent
} = slice.actions

export const selectTabs = (state: RootState) => state.event.tabs
export const selectTableData = (state: RootState) => state.event.tableData
export const selectDeviceId = (state: RootState) => state.event.deviceid
export const selectModals = (state: RootState) => state.event.modals
export const selectMultipleArchive = (state: RootState) => state.event.multipleArchive

export default slice.reducer


import { useChangePasswordMutation } from '@apis/main/login-api'
import {
    useAppDispatch,
    useAppSelector
} from '@app/hook'
import {
    TEXT,
    CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
    CHANGE_PASSWORD_VALIDATION_SCHEMA
} from '@constants/main/login'
import {
    MESSAGE,
    TOASTIFY_DEFAULT_OPTIONS
} from '@constants/main/root'
import {
    LOGIN_ROUTES

} from '@constants/main/routes'
import {
    resetForcePasswordToken,
    selectForcePasswordToken
} from '@slices/main/login'
import { selectMode } from '@slices/main/settings'
import {
    FormStyledComponents as Form,
    LoginStyledComponents as Login,
    SpinnerContainer
} from '@styles/components'
import { push } from 'connected-react-router'
import React, {
    useEffect,
    useMemo
} from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const ForcePasswordPage = () => {
    const dispatch = useAppDispatch()

    const mode = useAppSelector(selectMode)
    const forcePasswordToken = useAppSelector(selectForcePasswordToken)

    const [changePassword, changePasswordMutation] = useChangePasswordMutation()

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: CHANGE_PASSWORD_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA,
        onSubmit: (values) => {
            changePassword({
                authToken: forcePasswordToken,
                currentPassword: values.oldPassword,
                newPassword: values.newPassword,
                newPassword2: values.retypePassword
            })
        }
    })

    const Logo = useMemo(() => {
        return (
            <Login.Logo className={'col-auto'} src={mode === 'dark'
                ? '/media/dark-theme.svg'
                : '/media/light-theme.svg'}
            alt={'Bluedog Logo'} role={'button'} />
        )
    }, [mode])

    const OldPasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={TEXT.FORCE_PASSWORD.FORM.OLD_PASSWORD.ID}>
                    {TEXT.FORCE_PASSWORD.FORM.OLD_PASSWORD.LABEL}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.oldPassword)}
                    name={'oldPassword'}
                    type={'password'}
                    id={TEXT.FORCE_PASSWORD.FORM.OLD_PASSWORD.ID}
                    onChange={handleChange}
                    value={values.oldPassword}
                />
                <Form.Feedback errors={Boolean(errors.oldPassword)} >{
                    errors.oldPassword ? errors.oldPassword : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.oldPassword, errors.oldPassword])

    const NewPasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={TEXT.FORCE_PASSWORD.FORM.NEW_PASSWORD.ID}>
                    {TEXT.FORCE_PASSWORD.FORM.NEW_PASSWORD.ID}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.newPassword)}
                    name={'newPassword'}
                    type={'password'}
                    id={TEXT.FORCE_PASSWORD.FORM.NEW_PASSWORD.ID}
                    onChange={handleChange}
                    value={values.newPassword}
                />
                <Form.Feedback errors={Boolean(errors.newPassword)} >{
                    errors.newPassword ? errors.newPassword : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.newPassword, errors.newPassword])

    const RetypePasswordInput = useMemo(() => {
        return (
            <Form.Group>
                <Form.Label htmlFor={TEXT.FORCE_PASSWORD.FORM.RETYPE_PASSWORD.ID}>
                    {TEXT.FORCE_PASSWORD.FORM.RETYPE_PASSWORD.ID}
                </Form.Label>
                <Login.Input
                    errors={Boolean(errors.retypePassword)}
                    name={'retypePassword'}
                    type={'password'}
                    id={TEXT.FORCE_PASSWORD.FORM.RETYPE_PASSWORD.ID}
                    onChange={handleChange}
                    value={values.retypePassword}
                />
                <Form.Feedback errors={Boolean(errors.retypePassword)} >{
                    errors.retypePassword ? errors.retypePassword : null
                }</Form.Feedback>
            </Form.Group>
        )
    }, [values.retypePassword, errors.retypePassword])

    const SubmitButton = useMemo(() => {
        const buttonContent = changePasswordMutation.isLoading
            ? (
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{TEXT.FORCE_PASSWORD.FORM.LOADING_BUTTON}</span>
                </SpinnerContainer>
            )
            : TEXT.FORCE_PASSWORD.FORM.SUBMIT_BUTTON

        return (
            <Form.Group className={'text-center'}>
                <Login.Button
                    type={'submit'}
                    mode={'primary'}
                    disabled={changePasswordMutation.isLoading}
                >{buttonContent}</Login.Button>
            </Form.Group>
        )
    }, [changePasswordMutation.isLoading])

    useEffect(() => {
        if (changePasswordMutation.data) {
            const data = changePasswordMutation.data
            if (data.status === 'success') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                dispatch(resetForcePasswordToken())
                dispatch(push(LOGIN_ROUTES.LOGOUT.link))
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [changePasswordMutation.data])

    useEffect(() => {
        if (changePasswordMutation.error) {
            console.error(changePasswordMutation.error)
            toast.error(MESSAGE.ERROR.DATA.CALL_FAILED, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [changePasswordMutation.error])

    /**
     * render an MFA interface. you need a google authenticator
     * app and you can authenticate once more. Interface is inspired
     * by login page
    */
    return (
        <div className={'container-fluid'}>
            <div className={'align-items-center flex-column h-100 justify-content-center row'}>
                <div className={'col-auto'}>
                    {Logo}
                </div>
                <Login.Container className={'mt-3'}>
                    <h3 className={'text-center fw-bold'}>{TEXT.FORCE_PASSWORD.TITLE}</h3>
                    <small className={'text-center d-block mt-4'}>
                        {TEXT.FORCE_PASSWORD.MESSAGE}
                    </small>
                    <Form.Main className={'p-0'} onSubmit={handleSubmit}>
                        {OldPasswordInput}
                        {NewPasswordInput}
                        {RetypePasswordInput}
                        {SubmitButton}
                    </Form.Main>
                </Login.Container>
            </div>
        </div>
    )
}
export default ForcePasswordPage


import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as VMM_MESSAGE
} from '@constants/dashboard/tac/vmm'
import {
    assignIntervalTick,
    createIntervals,
    generateInterval
} from '@constants/main/method'
import {
    CHART_HEIGHT,
    DATE_FORMAT_TIME,
    DEFAULT_BAR_THICKNESS,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    IssuesOverTime,
    Severities
} from '@interfaces/dashboard/tac/vmm'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams
} from '@slices/dashboard/tac/vmm'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    TimeScale,
    Tooltip
} from 'chart.js'
import {
    format,
    fromUnixTime,
    isValid,
    isWithinInterval
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'

interface ComponentProps {
    data: IssuesOverTime[] | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const IssuesOverTimeChart = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    /** chart error where they are the same instance but... i don't know why this is. */
    const chartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** vmmediately register chartjs plugins */
        Chart.register(BarController, BarElement, Legend,
            CategoryScale, TimeScale, LinearScale, Tooltip)
    }, [])

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'bar', { x: string; y: number; }[], string>

        const meanMitigation = data || []

        const vulnerabilities: {
            label: string,
            threat: Severities,
            color: string
        }[] = [
            {
                label: 'Critical',
                threat: 'critical',
                color: stylesheet.style.colorPresets.red
            },
            {
                label: 'High',
                threat: 'high',
                color: stylesheet.style.colorPresets['amber-1']
            },
            {
                label: 'Medium',
                threat: 'medium',
                color: stylesheet.style.colorPresets['amber-2']
            },
            {
                label: 'Low',
                threat: 'low',
                color: stylesheet.style.colorPresets.yellow
            }
        ]

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const ranges: { start:Date, end: Date } = {
                // these default values are never going to be used anyway
                // start: new Date(), end: new Date()
                start: fromUnixTime(currentParams.ranges.start),
                end: fromUnixTime(currentParams.ranges.end)
            }

            // these timestamps need fromUnixTime as they aren't strings.
            // also switch the selections because the data is in ascending
            // order
            if (meanMitigation.length >= 2) {
                ranges.start = fromUnixTime(meanMitigation[0].timestamp)
                ranges.end = fromUnixTime(
                    meanMitigation[meanMitigation.length - 1].timestamp
                )
            }

            const fixedInterval = generateInterval(ranges.start, ranges.end)

            const intervals = _.map(
                createIntervals(ranges, fixedInterval),
                (date) => format(date, DATE_FORMAT_TIME)
            )

            const datasets: typeof graph.data.datasets = _.map(
                vulnerabilities,
                (category, index) => {
                    return ({
                        data: _.map(intervals, (interval, index) => {
                            // iterate meanMitigation array and increment x for each
                            // match.
                            let count = 0

                            _.forEach(meanMitigation, (obj) => {
                                if (isValid(new Date(intervals[index + 1])) && isWithinInterval(
                                    fromUnixTime(obj.timestamp),
                                    {
                                        start: new Date(interval),
                                        end: intervals[index + 1]
                                            ? new Date(intervals[index + 1])
                                            : new Date()
                                    }
                                )) {
                                    count += obj[category.threat]
                                } else {
                                    // it means we've reached the end. add it anyway.
                                    // count += obj.count
                                }
                            })

                            return {
                                x: interval,
                                y: count
                            }
                        }),
                        label: category.label,
                        fill: false,
                        borderColor: category.color,
                        backgroundColor: category.color,
                        borderWidth: 1,
                        maxBarThickness: DEFAULT_BAR_THICKNESS,
                        barThickness: 'flex',
                        /** apply minimum distance between each bar */
                        barPercentage: 0.7
                    })
                })

            graph = new Chart(chartEl.current, {
                type: 'bar',
                data: {
                    labels: intervals,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const formattedValue = tooltipItem.formattedValue
                                    return formattedValue
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: stylesheet.mode.fontColor,
                                callback: (value) => {
                                    return assignIntervalTick(
                                        Number(value), fixedInterval, intervals
                                    )
                                }
                            },
                            stacked: true,
                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        },
                        y: {
                            type: 'linear',
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            stacked: true,

                            grid: {
                                borderColor: stylesheet.mode.fontColor,
                                display: false
                            }
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            // setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        data
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2}>
                <div className={'row my-2'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{VMM_MESSAGE.FETCH.MODAL}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

IssuesOverTimeChart.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default IssuesOverTimeChart

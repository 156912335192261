
import { RootState } from '@app/store'
import {
    DEFAULT_TABLE_DATA,
    COMPARISON_OPERATORS
} from '@constants/main/root'
import { QUESTIONNAIRE_ROUTES } from '@constants/main/routes'
import { COLUMNS } from '@constants/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    RefreshTime,
    FilterInterface
} from '@interfaces/main/root'
import {
    Questionnaire,
    QuestionnaireColumn,
    QuestionnaireFilter,
    QuestionnaireState,
    QuestionnaireModal
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import deepEqual from 'deep-equal'
import _ from 'lodash'

const initialState : QuestionnaireState = {
    questionnaireTableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        columns: COLUMNS.QUESTIONNAIRE,
        filters: [],
        filtered: [],
        paginated: [],
        filterInterface: {
            show: false,
            keys: [],
            comparisonOperators: _.map(COMPARISON_OPERATORS, ({ value }) => value),
            values: [],
            selected: {
                key: '',
                comparison: COMPARISON_OPERATORS[0].value,
                values: []
            }
        }
    },
    tabs: [
        {
            name: 'Menu',
            link: QUESTIONNAIRE_ROUTES.MAIN.link
        }
    ],
    modals: []
}

export const slice = createSlice({
    name: 'questionInfoQuestionnaire',
    initialState: initialState,
    reducers: {
        setPage: (state: QuestionnaireState, action: PayloadAction<number>) => {
            state.questionnaireTableData.page = action.payload
        },
        setCount: (state: QuestionnaireState, action: PayloadAction<number>) => {
            state.questionnaireTableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: QuestionnaireState, action: PayloadAction<RefreshTime>) => {
            state.questionnaireTableData.interval = action.payload
        },
        setSearch: (state: QuestionnaireState, action: PayloadAction<string>) => {
            state.questionnaireTableData.search = action.payload
        },
        setFilters: (state: QuestionnaireState, action: PayloadAction<QuestionnaireFilter[]>) => {
            state.questionnaireTableData.filters = action.payload
        },
        /** adds a filter */
        addFilter: (state: QuestionnaireState, action: PayloadAction<QuestionnaireFilter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.questionnaireTableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                filter.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.questionnaireTableData.filters.push(action.payload)
            }
        },
        /** removes a filter */
        removeFilter: (state: QuestionnaireState, action: PayloadAction<QuestionnaireFilter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.questionnaireTableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                deepEqual(filter.value, action.payload.value)
                )
            })
        },
        /** removes ALL filters */
        removeFilters: (state: QuestionnaireState) => {
            state.questionnaireTableData.filters = []
        },
        setRefetch: (state: QuestionnaireState, action: PayloadAction<boolean>) => {
            state.questionnaireTableData.refetch = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setColumns: (state: QuestionnaireState, action: PayloadAction<QuestionnaireColumn[]>) => {
            state.questionnaireTableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setColumnInclude: (state: QuestionnaireState, action: PayloadAction<{
            value: QuestionnaireColumn['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.questionnaireTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setColumnArrange: (state: QuestionnaireState, action: PayloadAction<{
            value: QuestionnaireColumn['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.questionnaireTableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setFiltered: (state: QuestionnaireState, action: PayloadAction<Questionnaire[]>) => {
            state.questionnaireTableData.filtered = action.payload
        },
        setPaginated: (state: QuestionnaireState, action: PayloadAction<Questionnaire[]>) => {
            state.questionnaireTableData.paginated = action.payload
        },
        toggleFilterInterface: (
            state: QuestionnaireState, action: PayloadAction<FilterInterface['show']>) => {
            state.questionnaireTableData.filterInterface.show = action.payload
        },
        /**
         * assigns an array of strings to represent key names from one object of
         * a response call.
         */
        setFilterKeys: (
            state: QuestionnaireState,
            action: PayloadAction<FilterInterface['keys']>
        ) => {
            state.questionnaireTableData.filterInterface.keys = action.payload
        },
        /**
         * assigns an array of strings to represent values of a selected key name above
         */
        setFilterValues: (
            state: QuestionnaireState, action: PayloadAction<FilterInterface['values']>) => {
            state.questionnaireTableData.filterInterface.values = action.payload
        },
        /**
         * assign selected.key a value for filtering.
         */
        setSelectedKey: (
            state: QuestionnaireState, action: PayloadAction<FilterInterface['selected']['key']>
        ) => {
            state.questionnaireTableData.filterInterface.selected.key = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedComparison: (
            state: QuestionnaireState,
            action: PayloadAction<FilterInterface['selected']['comparison']
            >
        ) => {
            state.questionnaireTableData.filterInterface.selected.comparison = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedValues: (
            state: QuestionnaireState, action: PayloadAction<FilterInterface['selected']['values']>
        ) => {
            state.questionnaireTableData.filterInterface.selected.values = action.payload
        },
        addModal: (state: QuestionnaireState, action: PayloadAction<QuestionnaireModal>) => {
            state.modals.push(action.payload)
        },
        closeModal: (state: QuestionnaireState, action: PayloadAction<QuestionnaireModal>) => {
            const modal = _.find(
                state.modals,
                (modal) => modal.id === action.payload.id
            )
            if (modal) {
                modal.open = false
            }
        },
        removeModal: (state: QuestionnaireState, action: PayloadAction<QuestionnaireModal>) => {
            _.remove(state.modals, modal => {
                return (
                    modal.id === action.payload.id
                )
            })
        },
        resetQuestionnaire: (state: QuestionnaireState) => {
            state.questionnaireTableData = initialState.questionnaireTableData
            state.tabs = initialState.tabs
        }
    }
})

export const {
    setFiltered,
    setPaginated,
    setPage,
    setCount,
    setColumns,
    setColumnInclude,
    setColumnArrange,
    setIntervalObj,
    setSearch,
    setFilters,
    addFilter,
    removeFilter,
    removeFilters,
    setRefetch,
    toggleFilterInterface,
    setFilterKeys,
    setFilterValues,
    setSelectedKey,
    setSelectedComparison,
    setSelectedValues,
    addModal,
    closeModal,
    removeModal,
    resetQuestionnaire
} = slice.actions

export const selectTabs = (state: RootState) => state.questionInfoQuestionnaire.tabs
export const selectTableData = (state: RootState) =>
    state.questionInfoQuestionnaire.questionnaireTableData
export const selectModals = (state: RootState) => state.questionInfoQuestionnaire.modals

export default slice.reducer

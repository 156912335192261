import _ from 'lodash'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    DEFAULT_TABLE_DATA,
    COMPARISON_OPERATORS
} from '@constants/main/root'

import {
    ReportDetailsState,
    Section
} from '@interfaces/main/print'

import {
    RefreshTime,
    FilterInterface,
    Column,
    Filter
} from '@interfaces/main/root'
import deepEqual from 'deep-equal'
import { KEYS } from '@constants/main/print'

const initialState : ReportDetailsState = {
    tableData: {
        ..._.cloneDeep(DEFAULT_TABLE_DATA),
        // columns: O365_DETAILS_COLUMNS,
        /** will be taken from service type interfaces and
         * include 3 new fields from print constants */
        columns: [],
        filters: [],
        filtered: [],
        paginated: [],
        showFailed: false,
        filterInterface: {
            show: false,
            keys: KEYS.DASHBOARD_DETAILS,
            comparisonOperators: _.map(COMPARISON_OPERATORS, ({ value }) => value),
            values: [],
            selected: {
                key: '',
                comparison: COMPARISON_OPERATORS[0].value,
                values: []
            }
        }
    },
    loading: []
}

export const slice = createSlice({
    name: 'printReportDetails',
    initialState: initialState,
    reducers: {
        /** dispatch to add and remove loading array. */
        removeLoadingId: (state: ReportDetailsState, action: PayloadAction<string>) => {
            _.remove(state.loading, id => {
                return id === action.payload
            })
        },
        addLoadingId: (state: ReportDetailsState, action: PayloadAction<string>) => {
            state.loading.push(action.payload)
        },
        setPage: (state: ReportDetailsState, action: PayloadAction<number>) => {
            state.tableData.page = action.payload
        },
        setCount: (state: ReportDetailsState, action: PayloadAction<number>) => {
            state.tableData.count = action.payload
        },
        /** sets interval object. */
        setIntervalObj: (state: ReportDetailsState, action: PayloadAction<RefreshTime>) => {
            state.tableData.interval = action.payload
        },
        setSearch: (state: ReportDetailsState, action: PayloadAction<string>) => {
            state.tableData.search = action.payload
        },
        setShowFailed: (state: ReportDetailsState, action: PayloadAction<boolean>) => {
            state.tableData.showFailed = action.payload
        },
        setFilters: (state: ReportDetailsState, action: PayloadAction<Filter[]>) => {
            state.tableData.filters = action.payload
        },
        /** adds a filter */
        addFilter: (state: ReportDetailsState, action: PayloadAction<Filter>) => {
        /** we need to find a filter object that matches. */
            const found = _.find(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                    filter.sort === action.payload.sort &&
                    filter.value === action.payload.value
                )
            })

            if (!found) {
                /** _,union won't work. so stick with array push since we already
                 * determined if said object has a match. **/
                state.tableData.filters.push(action.payload)
            }
        },
        /** removes a filter */
        removeFilter: (state: ReportDetailsState, action: PayloadAction<Filter>) => {
        /** we need to find a filter object that matches. */
            _.remove(state.tableData.filters, filter => {
                return (
                    filter.not === action.payload.not &&
                filter.sort === action.payload.sort &&
                deepEqual(filter.value, action.payload.value)
                )
            })
        },
        /** removes ALL filters */
        removeFilters: (state: ReportDetailsState) => {
            state.tableData.filters = []
        },
        setRefetch: (state: ReportDetailsState, action: PayloadAction<boolean>) => {
            state.tableData.refetch = action.payload
        },
        /**
         * the actual search logic is done outside this action.
         * other things could be done besides smartSearch.
        */
        setColumns: (state: ReportDetailsState, action: PayloadAction<Column[]>) => {
            state.tableData.columns = action.payload
        },
        /**
         * individually setting properties of Column and Filter interfaces
         * Column interface requires value to find.
         * */
        /** toggling the include property */
        setColumnInclude: (state: ReportDetailsState, action: PayloadAction<{
            value: Column['value'],
            /** value would usually be !Column.include. */
            boolean: boolean
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.include = action.payload.boolean
            }
        },
        /** toggling the arrange property */
        setColumnArrange: (state: ReportDetailsState, action: PayloadAction<{
            value: Column['value'],
            /** value would usually be either "ASC" or "DESC" */
            arrange: 'asc' | 'desc'
        }>) => {
            /** first we have to find the column using Column.value */
            const column = _.find(
                state.tableData.columns,
                (col) => col.value === action.payload.value
            )
            if (column) {
                column.arrange = action.payload.arrange
            }
        },
        setFiltered: (state: ReportDetailsState, action: PayloadAction<Section[]>) => {
            state.tableData.filtered = action.payload
        },
        setPaginated: (state: ReportDetailsState, action: PayloadAction<Section[]>) => {
            state.tableData.paginated = action.payload
        },
        toggleFilterInterface: (
            state: ReportDetailsState, action: PayloadAction<FilterInterface['show']>) => {
            state.tableData.filterInterface.show = action.payload
        },
        /**
         * assigns an array of strings to represent key names from one object of
         * a response call.
         */
        setFilterKeys: (
            state: ReportDetailsState, action: PayloadAction<FilterInterface['keys']>) => {
            state.tableData.filterInterface.keys = action.payload
        },
        /**
         * assigns an array of strings to represent values of a selected key name above
         */
        setFilterValues: (
            state: ReportDetailsState, action: PayloadAction<FilterInterface['values']>) => {
            state.tableData.filterInterface.values = action.payload
        },
        /**
         * assign selected.key a value for filtering.
         */
        setSelectedKey: (
            state: ReportDetailsState,
            action: PayloadAction<FilterInterface['selected']['key']>
        ) => {
            state.tableData.filterInterface.selected.key = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedComparison: (
            state: ReportDetailsState,
            action: PayloadAction<FilterInterface['selected']['comparison']>
        ) => {
            state.tableData.filterInterface.selected.comparison = action.payload
        },
        /**
         * assign selected.comparison a value for filtering.
         */
        setSelectedValues: (
            state: ReportDetailsState,
            action: PayloadAction<FilterInterface['selected']['values']>
        ) => {
            state.tableData.filterInterface.selected.values = action.payload
        },
        resetSections: (state: ReportDetailsState) => {
            state.tableData = initialState.tableData
            state.loading = initialState.loading
        }
    }
})

export const {
    removeLoadingId,
    addLoadingId,
    setRefetch,
    setPage,
    setCount,
    setSearch,
    setFilters,
    addFilter,
    removeFilter,
    removeFilters,
    setColumns,
    setColumnInclude,
    setColumnArrange,
    setFiltered,
    setPaginated,
    toggleFilterInterface,
    setFilterKeys,
    setFilterValues,
    setSelectedKey,
    setSelectedComparison,
    setSelectedValues,
    resetSections,
    setShowFailed
} = slice.actions

export const selectTableData = (state: RootState) => state.printReportDetails.tableData
export const selectLoadingQueue = (state: RootState) => state.printReportDetails.loading

export default slice.reducer

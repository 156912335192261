import {
    QuestionnaireAction,
    QuestionnaireColumn,
    QuestionnaireKeys,
    AddQuestionnaireKeys,
    AddQuestionnaireValues,
    UpdateQuestionnaireKeys,
    UpdateQuestionnaireValues
} from '@interfaces/watchdog/virtual-ciso/questionnaire-information/questionnaire'
import _ from 'lodash'
import { MESSAGE as ROOT_MESSAGE } from '@constants/main/root'

import * as Yup from 'yup'
import { OptionalObjectSchema } from 'yup/lib/object'

export const MESSAGE = {
    /** throw message when there is no statusMessage */
    FAULTY_CALL: 'Questionnaire call returned faulty.',
    /** when an operation is unknown */
    UNKNOWN_OPERATION: 'Unknown operation detected.',
    /** when questionnaire is undefined */
    UNDEFINED_ID: 'Empty questionnaire ID detected.'

}

const ADD_FORM = {
    NAME: {
        LABEL: 'Name',
        ID: 'ADD__NAME'
    },
    ACTIVE: {
        LABEL: 'Is Active?',
        ID: 'ADD__ACTIVE'
    },
    COMPLIANCE_TYPE: {
        LABEL: 'Compliance Type',
        ID: 'ADD__COMPLIANCE_TYPE'
    }
}

export const TEXT = {
    ADD: {
        /** confirmation label for adding a questionnaire */
        TITLE: 'Add Questionnaire Confirmation',
        /** confirmation message for adding a questionnaire */
        CONFIRMATION_MESSAGE: 'Are you sure you want to submit the questionnaire data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Adding Questionnaire',
            SUBMIT_BUTTON: 'Add Questionnaire'
        }
    },
    UPDATE: {
        /** confirmation label for updating a questionnaire */
        TITLE: 'Update Questionnaire Confirmation',
        /** confirmation message for updating a questionnaire */
        CONFIRMATION_MESSAGE: 'Are you sure you want to update the questionnaire data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Updating Questionnaire',
            SUBMIT_BUTTON: 'Update Questionnaire'
        }
    },
    DELETE: {
        /** confirmation label for updating a questionnaire */
        TITLE: 'Delete Questionnaire Confirmation',
        /** confirmation message for updating a questionnaire */
        CONFIRMATION_MESSAGE: 'Are you sure you want to delete the questionnaire data?',
        FORM: {
            ...ADD_FORM,
            LOADING_BUTTON: 'Deleting Questionnaire',
            SUBMIT_BUTTON: 'Delete Questionnaire'
        }
    }
}
export const ACTIONS: {
    QUESTIONNAIRE: QuestionnaireAction[],
} = {
    QUESTIONNAIRE: [
        // {
        //     label: 'View',
        //     value: 'QUESTIONNAIRE_INFO_VIEW',
        //     mode: 'primary'
        // },
        {
            label: 'Edit',
            value: 'QUESTIONNAIRE_INFO_EDIT',
            mode: 'primary'
        },
        {
            label: 'Delete',
            value: 'QUESTIONNAIRE_INFO_DELETE',
            mode: 'primary'
        },
        {
            label: 'Questions',
            value: 'VIEW_QUESTIONS',
            mode: 'primary'
        }
    ]
}

export const COLUMNS: {
    QUESTIONNAIRE: QuestionnaireColumn[],
} = {
    QUESTIONNAIRE: (() => {
        const columnNames: Omit<QuestionnaireColumn, 'arrange' | 'include'>[] = [
            {
                label: 'Questionnaire Name',
                value: 'label'
            }
        ]

        /**
         * it doesn't need to be these values all the time
         * so you can check the column.value property and apply
         * your default values.
         * */
        const result: QuestionnaireColumn[] = _.map(columnNames, (column) => {
            return {
                ...column,
                arrange: 'asc',
                include: false
            }
        })

        return result
    })()
}

export const KEYS: {
    QUESTIONNAIRE: QuestionnaireKeys[],
} = {
    QUESTIONNAIRE: [
        'label'
    ]
}

const DEFAULT_FORM_VALUES: AddQuestionnaireValues = {
    active: false,
    complianceLines: '',
    name: ''
}

export const INITIAL_VALUES: {
    ADD: AddQuestionnaireValues,
    UPDATE: UpdateQuestionnaireValues,
} = {
    ADD: _.cloneDeep(DEFAULT_FORM_VALUES),
    UPDATE: _.cloneDeep(DEFAULT_FORM_VALUES)
}

const DEFAULT_VALIDATION_SCHEMA = {
    active: Yup.boolean().default(false),
    complianceLines: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY),
    name: Yup.string().required(ROOT_MESSAGE.ERROR.FORM.EMPTY)
}

export const VALIDATION_SCHEMA: {
    ADD: OptionalObjectSchema<Record<AddQuestionnaireKeys, Yup.AnySchema>>,
    UPDATE: OptionalObjectSchema<Record<UpdateQuestionnaireKeys, Yup.AnySchema>>,
} = {
    ADD: Yup.object(_.cloneDeep(DEFAULT_VALIDATION_SCHEMA)),
    UPDATE: Yup.object(_.cloneDeep(DEFAULT_VALIDATION_SCHEMA))
}

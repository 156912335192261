import { URL } from '@constants/main/root'
import {
    IssueDetailsRequest,
    IssueDetailsResponse,
    IssuesOvertimeRequest,
    IssuesOvertimeResponse,
    ModalRequest,
    ModalResponse,
    PortDetailRequest,
    PortDetailResponse,
    SimilarHostsRequest,
    SimilarHostsResponse
} from '@interfaces/dashboard/soc/ext-vss'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

export const extVssApi = createApi({
    reducerPath: 'extVssApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** there should be 5 api calls. */
        getModal: builder
            .mutation<ModalResponse, ModalRequest & TokenAuth>({
                query: (body) => {
                    const formData: ModalRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/ext-vss/modal',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getPortDetails: builder
            /** same request as above */
            .mutation<PortDetailResponse, PortDetailRequest & TokenAuth>({
                query: (body) => {
                    const formData: PortDetailRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/ext-vss/portdetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getIssueDetails: builder
            /** same request as above */
            .mutation<IssueDetailsResponse, IssueDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: IssueDetailsRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        // number
                        lastupdate: body.lastupdate,
                        port: body.port,
                        proto: body.proto,
                        ip: body.ip,
                        oid: body.oid
                    }

                    return {
                        url: 'api/getData/ext-vss/issuedetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getSimilarHosts: builder
            /** same request as above */
            .mutation<SimilarHostsResponse, SimilarHostsRequest & TokenAuth>({
                query: (body) => {
                    const formData: SimilarHostsRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        // number
                        lastupdate: body.lastupdate,
                        port: body.port,
                        proto: body.proto,
                        ip: body.ip,
                        oid: body.oid
                    }

                    return {
                        url: 'api/getData/ext-vss/similarhosts',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getIssuesOvertime: builder
            /** same request as above */
            .mutation<IssuesOvertimeResponse, IssuesOvertimeRequest & TokenAuth>({
                query: (body) => {
                    const formData: IssuesOvertimeRequest = {
                        deviceid: body.deviceid,
                        service_type: body.service_type,
                        in_face: body.in_face,
                        // number
                        lastupdate: body.lastupdate,
                        port: body.port,
                        proto: body.proto,
                        ip: body.ip,
                        oid: body.oid
                    }

                    return {
                        url: 'api/getData/ext-vss/issuesovertime',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            })
    })
})

export const {
    useGetIssueDetailsMutation,
    useGetIssuesOvertimeMutation,
    useGetModalMutation,
    useGetPortDetailsMutation,
    useGetSimilarHostsMutation
} = extVssApi

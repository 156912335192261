import { URL } from '@constants/main/root'
import {
    GetDetailedDashboardRequest as GetDetailedDashboardMainRequest,
    EventType as DetailedMainEventType,
    AzureEventsResponse as DetailedAzureEventsResponse,
    AzureUpdateResponse as DetailedAzureUpdateResponse,
    AzureSecurityEventResponse as DetailedAzureSecurityEventResponse,
    AzureLocationsResponse as DetailedAzureLocationsResponse,
    AzureEventTypesResponse as DetailedAzureEventTypesResponse,
    AzureAzureActivityResponse as DetailedAzureAzureActivityResponse,
    AzureCommonSecurityLogResponse as DetailedAzureCommonSecurityLogResponse,
    AzureSysLogComputerResponse as DetailedAzureSysLogComputerResponse,
    AzureProtectionStatusResponse as DetailedAzureProtectionStatusResponse,
    AzureSysLogProcessNameResponse as DetailedAzureSysLogProcessNameResponse
} from '@interfaces/dashboard/soc/azure/detailedDashboard/main'
import {
    GetDetailedDashboardRequest as GetDetailedDashboardKubernetesRequest,
    EventType as DetailedKubernetesEventType,
    AzureK8sEventsResponse as DetailedAzureK8sEventsResponse,
    AzureKubeEventsResponse as DetailedAzureKubeEventsResponse,
    AzureKubeMonAgentEventsResponse as DetailedAzureKubeMonAgentEventsResponse
} from '@interfaces/dashboard/soc/azure/detailedDashboard/kubernetes'
import {
    GetModalRequest,
    ModalHeaderResponse,
    EventType as MainEventType,
    GetDataIdRequest,
    GetDataIdResponse,
    GetModalDetailsRequest,
    GetModalDetailsResponse,
    AzureUpdateResponse,
    AzureSecurityEventResponse,
    AzureEventTypesResponse,
    AzureKubeEventsResponse,
    AzureAzureActivityResponse,
    AzureCommonSecurityLogResponse,
    AzureSysLogComputerResponse,
    AzureProtectionStatusResponse,
    AzureSysLogProcessNameResponse,
    AzureKubeMonAgentEventsResponse
} from '@interfaces/dashboard/soc/azure/main'
import { TokenAuth } from '@interfaces/main/root'
import {
    createApi,
    fetchBaseQuery,
    FetchArgs
} from '@reduxjs/toolkit/query/react'
import { Base64 } from 'js-base64'

const getAzureModalData: (
    body:Omit<GetModalRequest, 'event_type'>,
    eventType:MainEventType
) => GetModalRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to
    }
}

const createAzureQuery: (
    formData: GetModalRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/azure/modal',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

const getAzureDetailedMainModalData: (
    body:Omit<GetDetailedDashboardMainRequest, 'event_type'>,
    eventType:DetailedMainEventType
) => GetDetailedDashboardMainRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to,
        mn: body.mn,
        q: body.q,
        s: body.s
    }
}

const getAzureDetailedKubernetesModalData: (
    body:Omit<GetDetailedDashboardKubernetesRequest, 'event_type'>,
    eventType:DetailedKubernetesEventType
) => GetDetailedDashboardKubernetesRequest = (body, eventType) => {
    return {
        deviceid: body.deviceid,
        event_type: eventType,
        time_from: body.time_from,
        time_to: body.time_to,
        mn: body.mn,
        q: body.q,
        s: body.s
    }
}

const createAzureDetailedMainQuery: (
    formData: GetDetailedDashboardMainRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/azure/detailDashboard',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

const createAzureDetailedKubernetesQuery: (
    formData: GetDetailedDashboardKubernetesRequest,
    token: string
) => string | FetchArgs = (formData, token) => {
    return {
        url: 'api/getData/azure/detailDashboard',
        method: 'POST',
        headers: {
            Authorization: `Bearer ${ token }`,
            'Content-Type': 'application/json'
        },
        body: formData
    }
}

export const azureApi = createApi({
    reducerPath: 'azureApi',
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API
    }),
    endpoints: (builder) => ({
        /** there should be 5 api calls. */
        getModalHeader: builder
            .mutation<ModalHeaderResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(body, 'modal.Azure.Header')
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureUpdate: builder
            .mutation<AzureUpdateResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(body, 'Azure.Update')
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureSecurityEvent: builder
            .mutation<AzureSecurityEventResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(body, 'Azure.SecurityEvent')
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureEventTypes: builder
            .mutation<AzureEventTypesResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(body, 'Azure.EventTypes')
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureKubeEvents: builder
            .mutation<AzureKubeEventsResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(body, 'Azure.KubeEvents')
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureAzureActivity: builder
            .mutation<AzureAzureActivityResponse, Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(body, 'Azure.AzureActivity')
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureCommonSecurityLog: builder
            .mutation<AzureCommonSecurityLogResponse,
            Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(
                        body, 'Azure.CommonSecurityLog'
                    )
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureSysLogComputer: builder
            .mutation<AzureSysLogComputerResponse,
            Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(
                        body, 'Azure.SysLog.Computer'
                    )
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureProtectionStatus: builder
            .mutation<AzureProtectionStatusResponse,
            Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(
                        body, 'Azure.ProtectionStatus'
                    )
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureSysLogProcessName: builder
            .mutation<AzureSysLogProcessNameResponse,
            Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(
                        body, 'Azure.SysLog.ProcessName'
                    )
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        getAzureKubeMonAgentEvents: builder
            .mutation<AzureKubeMonAgentEventsResponse,
            Omit<GetModalRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetModalRequest = getAzureModalData(
                        body, 'Azure.KubeMonAgentEvents'
                    )
                    return createAzureQuery(formData, body.authToken)
                }
            }),
        /** get azure details */
        getModalDetails: builder
            .mutation<GetModalDetailsResponse, GetModalDetailsRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetModalDetailsRequest = {
                        deviceid: body.deviceid,
                        event_type: body.event_type,
                        q: Base64.encode(body.q),
                        time_from: body.time_from,
                        time_to: body.time_to,
                        /** all of these are optional */
                        title: body.title,
                        operation: body.operation,
                        detectionmethod: body.detectionmethod,
                        workload: body.workload,
                        country: body.country,
                        city: body.city,
                        lat: body.lat,
                        lon: body.lon,
                        extension: body.extension,
                        ip: body.ip,
                        site: body.site,
                        // user: body.user?.replace(/\\/g, '\\\\'),
                        // data already has a double backslash.
                        user: body.user,
                        file: body.file,
                        /** email data */
                        from: body.from,
                        to: body.to,
                        attachments: body.attachments,
                        useragent: body.useragent,
                        subject: body.subject,
                        rule: body.rule,
                        type: body.type,
                        computer: body.computer,
                        processname: body.processname,
                        resourcegroup: body.resourcegroup
                    }

                    return {
                        url: 'api/getData/azure/modalDetails',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        getDataId: builder
            .mutation<GetDataIdResponse, GetDataIdRequest & TokenAuth>({
                query: (body) => {
                    const formData: GetDataIdRequest = {
                        deviceid: body.deviceid,
                        id: body.id,
                        time_from: body.time_from,
                        time_to: body.time_to
                    }

                    return {
                        url: 'api/getData/azure/dataId',
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${ body.authToken }`,
                            'Content-Type': 'application/json'
                        },
                        body: formData
                    }
                }
            }),
        // MAIN
        getDetailedAzureEvents: builder
            .mutation<DetailedAzureEventsResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.Events')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureUpdate: builder
            .mutation<DetailedAzureUpdateResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.Update')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureSecurityEvent: builder
            .mutation<DetailedAzureSecurityEventResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.SecurityEvent')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureLocations: builder
            .mutation<DetailedAzureLocationsResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.Locations')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureEventTypes: builder
            .mutation<DetailedAzureEventTypesResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.EventTypes')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureAzureActivity: builder
            .mutation<DetailedAzureAzureActivityResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.AzureActivity')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureCommonSecurityLog: builder
            .mutation<DetailedAzureCommonSecurityLogResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.CommonSecurityLog')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureSysLogComputer: builder
            .mutation<DetailedAzureSysLogComputerResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.SysLog.Computer')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureProtectionStatus: builder
            .mutation<DetailedAzureProtectionStatusResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.ProtectionStatus')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureSysLogProcessName: builder
            .mutation<DetailedAzureSysLogProcessNameResponse, Omit<GetDetailedDashboardMainRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardMainRequest =
                        getAzureDetailedMainModalData(body, 'Azure.SysLog.ProcessName')
                    return createAzureDetailedMainQuery(formData, body.authToken)
                }
            }),

        // KUBERNETES
        getDetailedAzureK8sEvents: builder
            .mutation<DetailedAzureK8sEventsResponse, Omit<GetDetailedDashboardKubernetesRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardKubernetesRequest =
                        getAzureDetailedKubernetesModalData(body, 'Azure.k8s.Events')
                    return createAzureDetailedKubernetesQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureKubeEvents: builder
            .mutation<DetailedAzureKubeEventsResponse, Omit<GetDetailedDashboardKubernetesRequest,
            'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardKubernetesRequest =
                        getAzureDetailedKubernetesModalData(body, 'Azure.KubeEvents')
                    return createAzureDetailedKubernetesQuery(formData, body.authToken)
                }
            }),
        getDetailedAzureKubeMonAgentEvents: builder
            .mutation<DetailedAzureKubeMonAgentEventsResponse, Omit<
            GetDetailedDashboardKubernetesRequest, 'event_type'> & TokenAuth>({
                query: (body) => {
                    const formData: GetDetailedDashboardKubernetesRequest =
                        getAzureDetailedKubernetesModalData(body, 'Azure.KubeMonAgentEvents')
                    return createAzureDetailedKubernetesQuery(formData, body.authToken)
                }
            })
    })
})

export const {
    useGetModalHeaderMutation,
    useGetAzureAzureActivityMutation,
    useGetAzureCommonSecurityLogMutation,
    useGetAzureEventTypesMutation,
    useGetAzureKubeEventsMutation,
    useGetAzureKubeMonAgentEventsMutation,
    useGetAzureProtectionStatusMutation,
    useGetAzureSecurityEventMutation,
    useGetAzureSysLogComputerMutation,
    useGetAzureSysLogProcessNameMutation,
    useGetAzureUpdateMutation,
    useGetModalDetailsMutation,
    useGetDataIdMutation,
    useGetDetailedAzureAzureActivityMutation,
    useGetDetailedAzureCommonSecurityLogMutation,
    useGetDetailedAzureEventTypesMutation,
    useGetDetailedAzureEventsMutation,
    useGetDetailedAzureK8sEventsMutation,
    useGetDetailedAzureKubeEventsMutation,
    useGetDetailedAzureKubeMonAgentEventsMutation,
    useGetDetailedAzureLocationsMutation,
    useGetDetailedAzureProtectionStatusMutation,
    useGetDetailedAzureSecurityEventMutation,
    useGetDetailedAzureSysLogComputerMutation,
    useGetDetailedAzureSysLogProcessNameMutation,
    useGetDetailedAzureUpdateMutation
} = azureApi


import {
    useAppSelector
} from '@app/hook'
import {
    MESSAGE as OSS_MESSAGE
} from '@constants/dashboard/soc/oss'
import {
    CHART_COLORS,
    CHART_HEIGHT,
    DEFAULT_CHART_PADDING
} from '@constants/main/root'
import {
    LineDataPoint,
    ModalLineChart
} from '@interfaces/dashboard/soc/oss'
import {
    SerializedError
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import {
    selectCurrentParams
} from '@slices/dashboard/soc/oss/main'
import {
    selectMode,
    selectStyle
} from '@slices/main/settings'
import {
    Container,
    ErrorMessage,
    SpinnerContainer
} from '@styles/components'
import { createStylesheet } from '@styles/themes'
import {
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import {
    fromUnixTime
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
    useEffect,
    useMemo,
    useRef
} from 'react'

/** when clicking on a datapoint, a modal containing a detailed version
 * of the line chart will be rendered.
 */

interface ComponentProps {
    data: ModalLineChart | undefined,
    isLoading: boolean,
    isSuccess: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
}

const LineDataPointChart = ({
    data, isLoading, isSuccess, error
} : ComponentProps) => {
    const currentParams = useAppSelector(selectCurrentParams)
    const mode = useAppSelector(selectMode)
    const style = useAppSelector(selectStyle)

    const chartEl = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        /** immediately register chartjs plugins */
        Chart.register(LineController, LineElement, Legend, PointElement,
            CategoryScale, LinearScale, Tooltip, TimeScale, Title)
    }, [])

    /** what we need for the HSSDetails.tsx is the LineDataPoint interface
     * we don't need a slice for this since we are passing this data
     * to the serviceTypeFormData
     */

    useEffect(() => {
        const stylesheet = createStylesheet(style, mode)

        /** this will only be a categorical bar chart with different colors. */
        let graph: Chart<'line', { x: Date; y: number; }[], Date>

        const sections: (keyof Omit<LineDataPoint, 'timestamp'>)[] = [
            'Device', 'Apps', 'Data', 'Identity', 'currentScore'
        ]

        const ranges: { start:Date, end: Date } = {
            // these default values are never going to be used anyway
            // start: new Date(), end: new Date()
            start: fromUnixTime(currentParams.ranges.start),
            end: fromUnixTime(currentParams.ranges.end)
        }

        /** you only want this chart to be created when there's data. */
        if (chartEl.current) {
            const datasets: typeof graph.data.datasets = _.map(sections, (section, index) => {
                /** bug: duplicate objects. make unique */
                const sectionScores = _.map(
                    _.uniqBy(data || [], 'timestamp')
                    , obj => {
                        let value = 0
                        let currentScore = 0
                        const maxScore = obj[section].maxScore

                        // value is determined by the ff formula:
                        // (( (totalScore || currentScore) /maxScore) || 0) * 100
                        if (section === 'currentScore') {
                            currentScore = obj[section].currentScore
                        } else {
                            currentScore = obj[section].totalScore
                        }

                        value = (((currentScore / maxScore) || 0) * 100)
                        return {
                            x: fromUnixTime(obj.timestamp),
                            y: value
                        }
                    })

                return ({
                    /** include the current score here BUT it shouldn't be opening
                     * a new modal as it only has scores.
                     */
                    data: sectionScores,
                    label: section,
                    fill: false,
                    borderColor: CHART_COLORS[index],
                    backgroundColor: CHART_COLORS[index],
                    borderWidth: 1
                })
            })

            graph = new Chart(chartEl.current, {
                type: 'line',
                data: {
                    labels: [
                        ranges.start,
                        ranges.end
                    ],
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: DEFAULT_CHART_PADDING.x,
                            right: DEFAULT_CHART_PADDING.x,
                            top: DEFAULT_CHART_PADDING.y,
                            bottom: DEFAULT_CHART_PADDING.y
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: stylesheet.mode.fontColor
                            },
                            position: 'top',
                            align: 'center'
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const label = tooltipItem.label
                                    const formattedValue = tooltipItem.formattedValue
                                    return label.concat(': ', formattedValue)
                                },
                                title: (context) => {
                                    return ''
                                }
                            }
                        },
                        /** will be needed when selecting a dataset. */
                        title: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            }
                        },
                        y: {
                            type: 'linear',
                            grid: {
                                borderColor: stylesheet.mode.fontColor
                            },
                            ticks: {
                                color: stylesheet.mode.fontColor
                            },
                            beginAtZero: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.0
                        }
                    }
                }
            })

            chartEl.current.style.height = CHART_HEIGHT.md
            // setChartInstance(graph)
        }

        return () => {
            // make sure you deinitialize the chart instance if it exists first.
            // setChartInstance(undefined)
            graph && graph.destroy()
        }
    }, [
        data
    ])

    /** i don't want to memoize this. */
    const DataContent = useMemo(() => {
        const content = (
            <Container bgIndex={2} className={[
                'mb-3'
            ].join(' ')}>
                <div className={'row'}>
                    <canvas className={'col-auto'} ref={chartEl}/>
                </div>
            </Container>
        )

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <SpinnerContainer>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{OSS_MESSAGE.FETCH.LINE_CHART}</span>
                </SpinnerContainer>
            </small>
        )

        const ErrorContent = (
            <Container bgIndex={2}>
                <ErrorMessage className={'px-3 py-2'}>
                    {JSON.stringify(error)}
                </ErrorMessage>
            </Container>
        )

        return (
            !isLoading
                ? isSuccess
                    ? content
                    : error ? ErrorContent : ''
                : LoadingContent
        )
    }, undefined)

    return <div>
        {DataContent}
    </div>
}

LineDataPointChart.propTypes = {
    modal: PropTypes.object,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.object
}

export default LineDataPointChart

import _ from 'lodash'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    DEFAULT_CHART_ZOOMS,
    DEFAULT_REFETCH,
    INITIAL_MAIN_SEARCH_PARAMS
} from '@constants/dashboard/soc/azure/main'
import {
    AzureMainState,
    FixedCollapsibles,
    EventTypeBooleans,
    EventTypeChartZooms,
    ChartZoom,
    ChartZoomBucket
} from '@interfaces/dashboard/soc/azure/main'

const initialState : AzureMainState = {
    searchParams: _.cloneDeep(INITIAL_MAIN_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_MAIN_SEARCH_PARAMS), ['refetch']
    ),
    /** this is just to provide printOptions optional properties
     * on start. If you want the properties to be mandatory,
     * modify them in the state's interface.
     */
    printOptions: {
        logo: ''
    },
    fixedCollapsibles: _.cloneDeep(DEFAULT_REFETCH),
    /** for chartjs instances when zooming in or zooming out.
     * but we don't need to do it since we will just unmount the entire
     * thing anyway.
     */
    chartZooms: _.cloneDeep(DEFAULT_CHART_ZOOMS)
}

export const slice = createSlice({
    name: 'azureMain',
    initialState: initialState,
    reducers: {
        setStartDate: (state: AzureMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: AzureMainState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: AzureMainState, action: PayloadAction<{
            key: keyof EventTypeBooleans,
            value: boolean
        }>) => {
            state.searchParams.refetch[action.payload.key] = action.payload.value
        },
        setLogo: (state: AzureMainState, action: PayloadAction<string>) => {
            state.printOptions.logo = action.payload
        },
        setCurrentParams: (state: AzureMainState, action: PayloadAction<
            AzureMainState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        toggleCollapsible: (state: AzureMainState, action: PayloadAction<{
            key: keyof FixedCollapsibles,
            value: boolean
        }>) => {
            state.fixedCollapsibles[action.payload.key] = action.payload.value
        },
        setChartZoomLevel: (state: AzureMainState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: number
        }>) => {
            state.chartZooms[action.payload.key].zoomLevel = action.payload.value
        },
        setChartTitle: (state: AzureMainState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: string
        }>) => {
            state.chartZooms[action.payload.key].title = action.payload.value
        },
        setChartBucket3: (state: AzureMainState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: ChartZoomBucket
        }>) => {
            state.chartZooms[action.payload.key].bucket = action.payload.value
        },
        setChartZoom: (state: AzureMainState, action: PayloadAction<{
            key: keyof EventTypeChartZooms,
            value: ChartZoom
        }>) => {
            state.chartZooms[action.payload.key] = action.payload.value
        },
        resetAzureMain: (state: AzureMainState) => {
            state.fixedCollapsibles = initialState.fixedCollapsibles
            state.printOptions = initialState.printOptions
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setLogo,
    setCurrentParams,
    toggleCollapsible,
    setChartZoomLevel,
    setChartTitle,
    setChartBucket3,
    setChartZoom,
    resetAzureMain
} = slice.actions

export const selectSearchParams = (state: RootState) => state.azureMain.searchParams
export const selectCurrentParams = (state: RootState) => state.azureMain.currentParams
export const selectPrintOptions = (state: RootState) => state.azureMain.printOptions
export const selectFixedCollapsibles = (state: RootState) => state.azureMain.fixedCollapsibles
export const selectChartZooms = (state: RootState) => state.azureMain.chartZooms

export default slice.reducer

import _ from 'lodash'
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'

/** OTHER FILE IMPORTS */
import { RootState } from '@app/store'
import {
    AzureDataIdState
} from '@interfaces/dashboard/soc/azure/main'

import { INITIAL_SEARCH_PARAMS } from '@constants/dashboard/soc/azure/main'

const initialState : AzureDataIdState = {
    searchParams: _.cloneDeep(INITIAL_SEARCH_PARAMS),
    currentParams: _.omit(
        _.cloneDeep(INITIAL_SEARCH_PARAMS), ['refetch']
    )
}

export const slice = createSlice({
    name: 'azureDataId',
    initialState: initialState,
    reducers: {
        setStartDate: (state: AzureDataIdState, action: PayloadAction<number>) => {
            state.searchParams.ranges.start = action.payload
        },
        setEndDate: (state: AzureDataIdState, action: PayloadAction<number>) => {
            state.searchParams.ranges.end = action.payload
        },
        setRefetch: (state: AzureDataIdState, action: PayloadAction<boolean>) => {
            state.searchParams.refetch = action.payload
        },
        setCurrentParams: (state: AzureDataIdState, action: PayloadAction<
            AzureDataIdState['currentParams']>
        ) => {
            state.currentParams = action.payload
        },
        resetAzureDataId: (state: AzureDataIdState) => {
            state.searchParams = initialState.searchParams
            state.currentParams = initialState.currentParams
        }
    }
})

export const {
    setStartDate,
    setEndDate,
    setRefetch,
    setCurrentParams,
    resetAzureDataId
} = slice.actions

export const selectSearchParams = (state: RootState) => state.azureDataId.searchParams
export const selectCurrentParams = (state: RootState) => state.azureDataId.currentParams

export default slice.reducer
